import { Component, Input, OnInit } from '@angular/core';
import { SchedulerService } from './scheduler.service';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { MessageService } from 'primeng/api';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { HttpParams } from '@angular/common/http';
import { AppComponentService } from '../app-component.service';
declare var d3: any;
@Component({
  selector: 'app-scheduler',
  templateUrl: './scheduler.component.html',
  styleUrls: ['./scheduler.component.css'],
  providers: [SchedulerService, DatePipe],
})
export class SchedulerComponent implements OnInit {
  @Input() chartData5: any;
  states: any;
  showParamenter: boolean = false;
  data = {
    type: '',
    id: '',
    label: '',
    options: '',
    errosMsg: '',
    displayFormat: '',
    mandatory: '',
    selectedData: [],
    selected: true,
    showBool: true,
    loading: false,
    selectBool: false,
    paraBool: true
  };
  mainData: any = [];
  month: any = [];
  week: any = [];
  mindate1: any;
  mindate2: any;
  mindate3: any;
  agent: any;
  fromdate: string;
  thrudate: string;
  job: any;
  filename: any;
  FileName: any;
  ToValue: any = localStorage.getItem('userEmail');
  CCValue: any;
  SubjectValue: any;
  SubjectDate: any;
  SubjectDate1: any;
  message: any;
  para: any = [];
  rangeDate: any;
  startType: any;
  mailTo: any;
  ccTo: any;
  bccValue: any;
  BCCValue: any;
  mailSubject: any;
  mailMessage: any;
  now: boolean = false;
  label: any;
  alert2: boolean = true;
  mandatory: any = [];
  requestPayloadParameterObj = { parameters: {} };
  disabledData: boolean = true;
  currentDate = new Date();
  JobId: any;
  Type: any;
  reportNameLabel: string;
  DataToEdit: any;
  selectedValue: any = '';
  selectedValuee: any = '';
  alert11: boolean = true;
  count: number;
  scheduleOccurrenceValue: any;
  scheduleValue: any;
  timeZoneDaily: any;
  everyDays: any;
  weekList: any;
  buttonName: string;
  startDatetime: any;
  endDatetime: any;
  triggerType: any;
  recurrenceInterval: any;
  advMinutes: any;
  advHours: any;
  weekDays: any;
  // monthDays: any;
  improvedTriggerType: any;
  isEveryWeekday: any;
  isDayOrDate: any;
  dayUnit: any;
  dayUnitValue: any;
  dateVal: any;
  paramsAlertBool: boolean = false;
  isLoading: boolean = false;
  isLoading1: boolean = false;
  monthlyOccurDrop1: any;
  monthlyOccurDrop2: any;
  //minDate1: any;
  minDate11: any;
  maxDate: Date;
  payData: any;
  count1: number = 1;
  cascadeBool: boolean = false;
  values: any;
  value2Disable: boolean = false;
  value1Disable: boolean = false;
  NewParameter: any = {};
  cascade: boolean = false;
  btnSpinnerIcon = '';
  disableBtn: boolean = false;
  paramDates: any = [];
  ShowElementBool: boolean = true;
  SelectedRequestedPage: any = '';
  clientId: any = '';
  SubGroupId: any = '';
  loadings: boolean = false;
  isLoading2: boolean = false;
  clientFlag: string;
  parentFlag: string;
  lobData: any;
  policiesData: any;
  policyId: any;
  classId: any;
  key1: any = '';
  fileAlertBool: boolean = true;
  runReport: any;
  specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
  classBON: any;
  showError: any = 'val';
  showError1: any = 'val';
  showError2: any = 'val';
  SecureId: any = 1;
  ShowSftpSetting: boolean = false;
  ShowSftpSetting1: boolean = false;
  folderPath: any;
  serverName: any;
  isPassword: any = false;
  isSftp: any = false;
  pgpEncryption: any = false;
  sftpConfigId: any;
  showSftp: any = false;
  showPassword: any = false;
  sftpClientCode: any;
  component: any = '';
  originalApi: any;
  boolVal: boolean = false;
  showreport: boolean = false;
  searchMetri: boolean = false;
  params: any = [];
  showReport: any;
  maxDateMonthly: Date;
  maxDateWeekly: Date;
  maxDateDaily: Date;
  restricted: boolean = false;
  runReport1: any;
  runReport2: any;

  //return specialChars.test(str);

  constructor(
    public activatedRoute: ActivatedRoute,
    private schedulerService: SchedulerService,
    private appComponentService: AppComponentService,
    private messageService: MessageService,
    private router: Router,
    private datePipe: DatePipe
  ) { }

  reportId?: any;
  reportName?: any;
  authDesc?: any;
  parameterElementsArray = [];
  stateOptions: any[] = [];
  Options: any[];
  fileFormatOptions: any = [];
  fileValue = '';
  reportOutputType: any = [];
  minDate: any;
  todayDate: Date
  monthlyOccurList1?: any;
  monthlyOccurList2?: any = [];
  weeklyOccurList2?: any = [];
  userEmail = '';
  parameters = {};
  dailyOccurrenceDayVal = 1;
  nowOccurance = {
    advHours: 4,
    advMinutes: 0,
    improvedTriggerType: '',
    isEveryWeekday: '',
    recurrenceInterval: 1,
    startDatetime: '',
    endDatetime: '',
  };
  dailyOccurrence = {
    advHours: 4,
    advMinutes: 0,
    improvedTriggerType: 'daily',
    isEveryWeekday: '',
    recurrenceInterval: 1,
    startDatetime: '',
    endDatetime: '',
  };
  weeklyOccurrence = {
    advHours: 4,
    advMinutes: 0,
    improvedTriggerType: 'weekly',
    isEveryWeekday: 'Y',
    startDatetime: '',
    endDatetime: '',
    weekDays: [],
  };
  dailyOccurrenceDayVal1 = 1;
  monthlyOccurrenceDayVal1 = 0;
  monthlyOccurrenceMonthVal = 0;
  monthlyOccurrenceMonthVal2 = 0;
  monthlyOccurrence = {
    advHours: 4,
    advMinutes: 0,
    improvedTriggerType: 'monthly',
    isEveryWeekday: 'Y',
    isDayOrDate: '',
    monthDays: 0,
    recurrenceInterval: 0,
    startDatetime: '',
    endDatetime: '',
  };

  reportIdSubscrip: Subscription;
  scheFormSerSubscrip: Subscription;
  reportParamSerSubscrip: Subscription;
  scheRepoSerSubscrip: Subscription;
  scheRepoSerSubscrip1: Subscription;

  alertSeverity: string = 'error';
  alertMsg: string = '';
  Jobvalue = '';
  dailyScheduler: boolean = false;
  weeklyScheduler: boolean = false;
  monthlyScheduler: boolean = false;
  startDateDaily: any;
  endDateDaily: any;
  //timeZoneDaily:any;
  startDateWeekly: any;
  endDateWeekly: any;
  timeZoneWeekly: any;
  startDateMonthly: any;
  endDateMonthly: any;
  timeZoneMonthly: any;
  isDisabled: boolean = false;
  Disabled: boolean = false;
  date: any;
  payload: any = {};
  alert: boolean = true;
  alert1: boolean = false;
  parameter1: any;
  monthlyDays: any;
  addSubjectDate: any;
  addSubjectDate1: any;
  // DateRangeMandatoryBoo: boolean = true;
  summary = '';
  separatorExp: RegExp = /,| /;
  //timeZone:any;
  ngOnInit(): void {
    this.appComponentService.menuListGetService().subscribe({
      next: (res: any) => {
        this.boolVal = false;
        if (res?.body?.length > 0) {
          this.boolVal = res.body.some(item => item.menuName === "Scheduled Reports");
          //console.log(this.boolVal)
        }
      },
      error: (err) => { },
    });
    this.sftpClientCode = ''
    this.pgpEncryption = false;
    this.folderPath = '';
    this.serverName = '';
    this.ShowSftpSetting = false;
    this.ShowSftpSetting1 = false;
    this.isPassword = false
    this.isSftp = false;
    this.maxDate = new Date(2030, 0, 1);
    this.minDate = new Date();
    this.todayDate = new Date();
    this.runReport = '';
    this.key1 = '';
    this.alert1 = true;
    this.alert2 = true;
    this.paramsAlertBool = true;
    this.disabledData = true;
    this.policiesData = '';
    this.lobData = '';
    this.clientFlag = '';
    this.parentFlag = '';
    this.clientId = '';
    this.SubGroupId = '';
    this.agent = '';
    this.fromdate = '';
    this.thrudate = '';
    this.job = '';
    this.filename = '';
    this.FileName = '';
    this.date = '';
    this.mailTo = localStorage.getItem('userEmail');;
    this.ccTo = '';
    this.BCCValue = '';
    this.CCValue = '';
    this.mailSubject = '';
    this.mailMessage = '';
    this.fileValue = '';
    this.selectedValue = '';
    this.reportId = '';
    //this.scheduleOccurrenceValue = ""
    this.JobId = '';
    this.everyDays = '';
    this.startDateDaily = '';
    this.endDateDaily = '';
    this.timeZoneDaily = new Date();
    this.timeZoneDaily.setHours(4, 0, 0);
    this.startDateWeekly = '';
    this.endDateWeekly = '';
    // this.timeZoneWeekly = '';
    this.timeZoneWeekly = new Date();
    this.timeZoneWeekly.setHours(4, 0, 0);
    this.startDateMonthly = '';
    this.endDateMonthly = '';
    // this.timeZoneMonthly = '';
    this.timeZoneMonthly = new Date();
    this.timeZoneMonthly.setHours(4, 0, 0);
    this.weekList = [];
    this.monthlyDays = '';
    this.buttonName = 'Schedule';
    this.DataToEdit = [];
    this.startDatetime = '';
    this.endDatetime = '';
    this.triggerType = '';
    this.recurrenceInterval = 1;
    this.advMinutes = '';
    this.advHours = '';
    this.weekDays = [];
    //this.monthDays = 1;
    this.improvedTriggerType = '';
    // this.isEveryWeekday = '';
    this.isDayOrDate = '';
    this.dayUnit = '';
    this.dayUnitValue = '';
    this.dailyScheduler = false;
    this.weeklyScheduler = false;
    this.monthlyScheduler = false;
    this.monthlyOccurDrop1 = '';
    this.monthlyOccurDrop2 = '';
    this.NewParameter = {};
    this.parameters = {}
    //this.message="please enter message"
    d3.select('.tibo-topbar-title').text('Home/Scheduler');
    this.userEmail = localStorage.getItem('userEmail');
    this.minDate = new Date();
    this.minDate11 = new Date();
    this.monthlyOccurList1 = [
      { label: 'First', value: 'First' },
      { label: 'Second', value: 'Second' },
      { label: 'Third', value: 'Third' },
      { label: 'Fourth', value: 'Fourth' },
      { label: 'Last', value: 'Last' },
    ];
    this.weeklyOccurList2 = [
      { label: 'Sunday', value: 1 },
      { label: 'Monday', value: 2 },
      { label: 'Tuesday', value: 3 },
      { label: 'Wednesday', value: 4 },
      { label: 'Thursday', value: 5 },
      { label: 'Friday', value: 6 },
      { label: 'Saturday', value: 7 },
    ];
    this.monthlyOccurList2 = [
      { label: 'Day', value: 'Day' },
      { label: 'Sunday', value: 'Sunday' },
      { label: 'Monday', value: 'Monday' },
      { label: 'Tuesday', value: 'Tuesday' },
      { label: 'Wednesday', value: 'Wednesday' },
      { label: 'Thursday', value: 'Thursday' },
      { label: 'Friday', value: 'Friday' },
      { label: 'Saturday', value: 'Saturday' },
    ];
    // this.startDateDaily = '';
    // this.endDateDaily = '';
    // this.timeZoneDaily = '';
    // this.startDateWeekly = '';
    // this.endDateWeekly = '';
    // this.timeZoneWeekly = '';
    // this.startDateMonthly = '';
    // this.endDateMonthly = '';
    // this.timeZoneMonthly = '';
    if (this.chartData5 != undefined) {
      this.params = this.chartData5;
      this.component = this.chartData5?.component ? this.chartData5?.component : '';
      this.reportId = this.chartData5?.reportId;
      this.SecureId = this.chartData5?.id;
      this.Type = this.chartData5?.type;
      this.searchMetri = this.chartData5?.searchMetric;
      this.showreport = this.chartData5?.showReport;
    }
    else {
      this.reportIdSubscrip = this.activatedRoute.queryParams.subscribe(
        (params) => {
          this.params = params;
          this.component = params?.component ? params?.component : '';
          this.reportId = params?.reportId;
          this.SecureId = params?.id;
          this.Type = params?.type;

        });
    }
    this.isLoading = true;
    this.scheFormSerSubscrip = this.schedulerService
      .scheduleFormService(this.reportId)
      .subscribe(
        (response: any) => {
          // report parameters
          this.dailyScheduler = false;
          this.weeklyScheduler = false;
          this.monthlyScheduler = false;
          this.states = response.body.localeList;
          this.month = response.body.monthsList;
          this.reportOutputType = response.body.outputFormatsList;
          this.reportOutputType.forEach(e => e.replace(/[^a-zA-Z ]/g, ""))
          response.body.outputFormatsList.forEach((d) => {
            let temp = {
              filelabel: d.toUpperCase(),
              filevalue: d,
            };
            this.fileFormatOptions.push(temp);
          });
          this.fileValue = this.fileFormatOptions[0].filevalue;
          this.scheduleValue = response.body.triggerStartTypeList[1].code;
          this.stateOptions[0] = response.body.triggerStartTypeList[0];
          this.stateOptions[0]['label'] = 'Run Now';
          this.scheduleOccurrenceValue = this.stateOptions[0]?.label;
          this.startType = this.stateOptions[0]?.code;
          this.now = true;
          let i = 1;
          response.body.daysTypeList.forEach((d) => {
            if (d.description == 'ALL') {
              d['label'] = 'Daily';
              d['code'] = this.scheduleValue
              this.stateOptions[i] = d;
              i++;
            } else if (d.description == 'WEEK') {
              d['label'] = 'Weekly';
              d['code'] = this.scheduleValue
              this.stateOptions[i] = d;
              i++;
            } else if (d.description == 'MONTH') {
              d['label'] = 'Monthly';
              d['code'] = this.scheduleValue
              this.stateOptions[i] = d;
              i++;
            }
          });
          // response.body.weekDaysList.forEach((d) => {
          //   let temp = { label: d.labelMessage, value: d.labelMessage };
          //   this.monthlyOccurList2.push(temp);
          // });
          this.reportNameLabel =
            'Add Schedule For ' + this.params?.name.replace('.rptdesign', '').replace('.sql', '').replace('.RPTDESIGN', '').replace('.SQL', '');
          this.reportName = this.params?.name.replace('.rptdesign', '').replace('.sql', '').replace('.RPTDESIGN', '').replace('.SQL', '');
          this.authDesc = this.params?.authDesc;
          this.SubjectValue = this.reportName;
          this.SubjectDate = this.datePipe.transform(
            this.currentDate,
            'MM/dd/yyyy'
          );
          this.SubjectDate1 = this.datePipe.transform(
            this.currentDate,
            'MM-dd-yyyy'
          );
          this.message = 'Please Find ' + this.reportName.replace('.rptdesign', '').replace('.sql', '').replace('.RPTDESIGN', '').replace('.SQL', '');
          this.Jobvalue = this.reportName.replace('.rptdesign', '').replace('.sql', '').replace('.RPTDESIGN', '').replace('.SQL', '');
          //this.filename = this.reportName;
          // specialChars.test(str)
          // if (this.specialChars.test(this.reportName)) {
          //   this.fileAlertBool = true;
          // }
          // else {
          //   this.fileAlertBool = false;
          this.filename = this.reportName.replace('.rptdesign', '').replace('.sql', '').replace('.RPTDESIGN', '').replace('.SQL', '');
          // }
          const userEmail = localStorage.getItem('userEmail');
          //console.log(userEmail)
          this.ToValue = userEmail
          this.selectedValue = '';
          this.scheduleOccurrenceValue = this.stateOptions[0]?.label;
          this.triggerType = 'NONE';
          this.isLoading = false;
        },
        (error) => {
          this.isLoading = false;
          this.alertSeverity = 'error';
          if (Number(error.status) == 401) {
            this.alertMsg = 'Please Try After Some Time!';
          } else if (Number(error.status) == 500) {
            this.alertMsg = 'Internal Error';
          } else {
            this.alertMsg = 'Please Try After Some Time.';
          }
          this.addSingle();
        },
        () => { }
      );
    this.loadings = true;
    this.reportParamSerSubscrip = this.schedulerService
      .reportParameterService(this.reportId)
      .subscribe(
        (response: any) => {
          this.loadings = false;
          // report parameters
          this.SelectedRequestedPage = '';
          this.mainData = [];
          //this.parameterElementsArray = response.body?.reportInputControl;
          response.body?.reportInputControl.forEach((e, i) => {
            this.originalApi = response.body?.reportInputControl;
            if (e.id == "CustomParameterPage") {
              //this.ShowElementBool = false;
              this.SelectedRequestedPage = e.state.value;
            }
            if (e.visible == true) {
              this.parameterElementsArray.push(e);
            }
          })
          this.count = 1;
          this.parameterElementsArray.forEach(e => {
            this.payload[e.id] = [];
          })
          this.count = 0;
          if (!this.parameterElementsArray) {
            this.showParamenter = false;
          } else {
            this.showParamenter = true;
            let j = 0;

            this.parameterElementsArray.forEach((e) => {
              if (e.state?.options?.length > 0) {
                e.state?.options.forEach((el, i) => {
                  if (el['value'] == '' || el['value'] == 'empty' || el['value'] == "") {
                    e.state?.options.splice(i, 1);
                  }
                })
              }
            })
            this.paramDates = [];
            let index = 0;
            this.parameterElementsArray.forEach((element) => {
              if (element.id.replace('_', '').replace(' ', '').toLowerCase() == 'daterange') { this.key1 = element.id; }
              this.data = {
                type: '',
                id: '',
                label: '',
                options: '',
                errosMsg: '',
                displayFormat: '',
                mandatory: '',
                selectedData: [""],
                selected: true,
                showBool: true,
                loading: false,
                selectBool: false,
                paraBool: true
              };
              if (element.type == 'singleSelect') {
                this.data.type = 'singleSelect';
                this.data.label = element.label;
                this.data.id = element.id;
                element.state.options = element.state.options.filter(option => {
                  if (typeof option === 'object' && option !== null) {
                    return option.value !== null && option.value !== ''; // Adjust 'value' as needed
                  }
                  return option !== null && option !== '';
                });
                this.data.options = element.state.options;
                this.data.mandatory = element.mandatory;
                this.data.selectedData = [''];
                this.data.loading = false;
                if (element.mandatory) {
                  this.data.selected = false;
                }
                if (element.displayFormat == null) {
                  this.data.displayFormat = '';
                } else {
                  this.data.displayFormat = element.displayFormat;
                }
                if (element.state.error == null) {
                  this.data.errosMsg = '';
                } else {
                  this.data.errosMsg = element.state.error;
                }
                //if (this.ShowElementBool == true) { this.data.showBool = true; } else { this.data.showBool = false; }
                this.parameters[this.data.id] = [];
                this.data.selectBool = false;
                this.data.paraBool = true;
                this.mainData[j] = this.data;
                j++;
              }
              if (element.type == 'multiSelect') {
                this.data.type = 'multiSelect';
                this.data.label = element.label;
                this.data.id = element.id;
                element.state.options = element.state.options.filter(option => {
                  if (typeof option === 'object' && option !== null) {
                    return option.value !== null && option.value !== ''; // Adjust 'value' as needed
                  }
                  return option !== null && option !== '';
                });
                this.data.options = element.state.options;
                this.data.mandatory = element.mandatory;
                this.data.selectedData = [];
                this.data.loading = false;
                //this.data.selected=false
                if (element.mandatory) {
                  this.data.selected = false;
                }
                if (element.displayFormat == null) {
                  this.data.displayFormat = '';
                } else {
                  this.data.displayFormat = element.displayFormat;
                }
                if (element.state.error == null) {
                  this.data.errosMsg = '';
                } else {
                  this.data.errosMsg = element.state.error;
                }
                //if (this.ShowElementBool == true) { this.data.showBool = true; } else { this.data.showBool = false; }
                this.parameters[this.data.id] = [];
                this.data.selectBool = false;
                this.data.paraBool = true;
                this.mainData[j] = this.data;
                j++;
              }
              if (element.type == 'singleSelectRadio') {
                this.data.type = 'singleSelectRadio';
                this.data.label = element.label;
                this.data.id = element.id;
                this.data.options = element.state.options;
                this.data.mandatory = element.mandatory;
                this.data.selectedData = [];
                this.data.loading = false;
                //this.data.selected=false
                if (element.mandatory) {
                  this.data.selected = false;
                }
                if (element.displayFormat == null) {
                  this.data.displayFormat = '';
                } else {
                  this.data.displayFormat = element.displayFormat;
                }
                if (element.state.error == null) {
                  this.data.errosMsg = '';
                } else {
                  this.data.errosMsg = element.state.error;
                }
                //if (this.ShowElementBool == true) { this.data.showBool = true; } else { this.data.showBool = false; }
                this.parameters[this.data.id] = '';
                this.data.selectBool = false;
                this.data.paraBool = true;
                this.mainData[j] = this.data;
                j++;
              }
              if (element.type == 'singleValueText') {
                if (element.id.toLowerCase().replace(/[_ ]/g, '') == "reportpath") {
                  this.data.type = 'singleValueText';
                  this.data.label = element.label;
                  this.data.id = element.id;
                  this.data.options = element.state.options;
                  this.data.mandatory = element.mandatory;
                  this.data.selectedData = element.state.value;
                  this.data.loading = false;
                  //this.data.selected=false
                  if (element.mandatory) {
                    this.data.selected = false;
                  }
                  if (element.displayFormat == null) {
                    this.data.displayFormat = '';
                  } else {
                    this.data.displayFormat = element.displayFormat;
                  }
                  if (element.state.error == null) {
                    this.data.errosMsg = '';
                  } else {
                    this.data.errosMsg = element.state.error;
                  }
                  //if (this.ShowElementBool == true) { this.data.showBool = true; } else { this.data.showBool = false; }
                  this.parameters[this.data.id] = element.state.value;
                  this.data.selectBool = true;
                  this.data.paraBool = false;
                  this.mainData[j] = this.data;
                  j++;
                }
                else {
                  this.data.type = 'singleValueText';
                  this.data.label = element.label;
                  this.data.id = element.id;
                  this.data.options = element.state.options;
                  this.data.mandatory = element.mandatory;
                  this.data.selectedData = [];
                  this.data.loading = false;
                  //this.data.selected=false
                  if (element.mandatory) {
                    this.data.selected = false;
                  }
                  if (element.displayFormat == null) {
                    this.data.displayFormat = '';
                  } else {
                    this.data.displayFormat = element.displayFormat;
                  }
                  if (element.state.error == null) {
                    this.data.errosMsg = '';
                  } else {
                    this.data.errosMsg = element.state.error;
                  }
                  //if (this.ShowElementBool == true) { this.data.showBool = true; } else { this.data.showBool = false; }
                  this.parameters[this.data.id] = '';
                  this.data.selectBool = false;
                  this.data.paraBool = true;
                  this.mainData[j] = this.data;
                  j++;
                }
              }
              if (element.type == 'singleValueNumber') {
                this.data.type = 'singleValueNumber';
                this.data.label = element.label;
                this.data.id = element.id;
                this.data.options = element.state.options;
                this.data.mandatory = element.mandatory;
                this.data.selectedData = [];
                this.data.loading = false;
                //this.data.selected=false
                if (element.mandatory) {
                  this.data.selected = false;
                }
                if (element.displayFormat == null) {
                  this.data.displayFormat = '';
                } else {
                  this.data.displayFormat = element.displayFormat;
                }
                if (element.state.error == null) {
                  this.data.errosMsg = '';
                } else {
                  this.data.errosMsg = element.state.error;
                }
                //if (this.ShowElementBool == true) { this.data.showBool = true; } else { this.data.showBool = false; }
                this.parameters[this.data.id] = '';
                this.data.selectBool = false;
                this.data.paraBool = true;
                this.mainData[j] = this.data;
                j++;
              }
              if (element.type == 'combobox') {
                this.data.type = 'combobox';
                this.data.label = element.label;
                this.data.id = element.id;
                this.data.options = element.state.options;
                this.data.mandatory = element.mandatory;
                this.data.selectedData = [];
                this.data.loading = false;
                //this.data.selected=false
                if (element.mandatory) {
                  this.data.selected = false;
                }
                if (element.displayFormat == null) {
                  this.data.displayFormat = '';
                } else {
                  this.data.displayFormat = element.displayFormat;
                }
                if (element.state.error == null) {
                  this.data.errosMsg = '';
                } else {
                  this.data.errosMsg = element.state.error;
                }
                //if (this.ShowElementBool == true) { this.data.showBool = true; } else { this.data.showBool = false; }
                this.parameters[this.data.id] = '';
                this.data.selectBool = false;
                this.data.paraBool = true;
                this.mainData[j] = this.data;
                j++;
              }
              else if (element.type.toLowerCase().includes('date') || element.type == 'singleValueDate') {
                this.data.type = 'date';
                this.data.label = element.label;
                this.data.id = element.id;
                this.data.options = element.state.options;
                this.data.mandatory = element.mandatory;
                this.data.selectedData[0] =element.state.value==null?'':element.state.value;
                this.data.loading = false;
                //this.data.selected=false
                if (element.mandatory) {
                  this.data.selected = false;
                }
                if (element.displayFormat == null) {
                  this.data.displayFormat = '';
                } else {
                  this.data.displayFormat = element.displayFormat;
                }
                if (element.state.error == null) {
                  this.data.errosMsg = '';
                } else {
                  this.data.errosMsg = element.state.error;
                }
                // if (this.ShowElementBool == true) { this.data.showBool = true; } else { this.data.showBool = false; }
                this.parameters[this.data.id] = '';
                this.data.selectBool = false;
                this.data.paraBool = true;
                this.mainData[j] = this.data;
                if (element.id.toLowerCase().replace(/[_ ]/g, '') == 'startdate' || element.id.toLowerCase().replace(/[_ ]/g, '') == 'enddate' || element.id.toLowerCase().replace(/[_ ]/g, '') == 'fromdate' || element.id.toLowerCase().replace(/[_ ]/g, '') == 'thrudate') {
                  this.paramDates[index] = this.data;
                  this.paramDates[index].selectedData[0] = element.state.value==null?'':element.state.value;
                  index++;
                }
                j++;

              }
            });
            this.mainData.forEach(e =>{
              this.parameterElementsArray.forEach((e1) => {
                if(e.id==e1.id){
                      let selectedOption=[];
                      console.log(e.options)
                      if(e.options.length > 0){
                          selectedOption = e.options.filter(option => option.selected);
                      }
                      if (selectedOption.length>0) {
                        this.parameters[e.id]=  selectedOption.length > 1 || e.type=='multiSelect'?selectedOption.map(option => option.value):selectedOption[0].value;
                        e.selectedData =selectedOption.length > 1 ||e.type=='multiSelect'? selectedOption.map(option => option.value):selectedOption[0].value;
                        if(e.mandatory){
                          e.paraBool=false;
                          e.selected = true;
                          e.selectBool = true;
                        }
                      } 
                      else {
                        this.parameters[e.id] =e.options.length>0 ? []:e1.state.value? e1.state.value: '';
                        if(e.type=="date"){
                          e.selectedData[0]=e1.state.value && e1.state.value!=null? e1.state.value: '';
                          if(e.mandatory){
                            e.paraBool=e.selectedData.length>0 ? true:e1.state.value? false:true;
                            e.selected=e.paraBool?false:true;
                            e.selectBool =e.paraBool?false:true;
                        }
                        } 
                        else{
                          e.selectedData =e.options.length>0 ? []:e1.state.value? e1.state.value: [];
                          if(e.mandatory){
                            e.paraBool=e.options.length>0 ? true:e1.state.value? false:true;
                            e.selected=e.paraBool?false:true;
                            e.selectBool =e.paraBool?false:true;
                        }
                        }
                        
                      }
                      console.log(this.mainData)
                    }
              })
            })
            console.log(this.mainData)
            // this.mainData.forEach(element =>{
            //   if (element.id.toLowerCase().replace(/[_ ]/g, '') == 'startdate' || element.id.toLowerCase().replace(/[_ ]/g, '') == 'enddate' || element.id.toLowerCase().replace(/[_ ]/g, '') == 'fromdate' || element.id.toLowerCase().replace(/[_ ]/g, '') == 'thrudate') {
            //     this.paramDates[index] = this.data;
            //     index++;
            //   }
            // })
              // this.paramDates.forEach(e => {
              //   if( e.selectedData.length==0)
              //     e.selectedData[0] = null;
              // });
            this.mainData.forEach(e => {
              if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'clicode' && e.type != 'singleValueText') {
                //console.log(this.mainData)
                e.loading = true;
                this.schedulerService.getClientNameCode(this.reportId).subscribe((responses: any) => {
                  e.loading = false;
                  responses?.body?.forEach(c => {
                    c.value = c.clientCode;
                    delete c.clientCode;
                    c.label = c.clientName;
                    delete c.clientName;
                    c.selected = false;
                  })
                  e.options = responses?.body;
                  e.selectedData = e.options[0].value;
                  if (this.SecureId == 2) {
                    this.getSftp(e.selectedData)
                  }
                  this.parameters[e.id] = e.options[0].value;
                  this.clientId = e.options[0].value;
                  if (e.mandatory) {
                    e.paraBool = false;
                    e.selected = true;
                    e.selectBool = true;
                  }
                  this.parameterElementsArray.forEach(el => {
                    if (el.id == e.id) {
                      if (el.slaveDependencies.length > 0) {
  
                        this.cascadeFunc(e.id, this.clientId);
  
                      }
                    }
                  });
                });
                
              }
            })
              this.mainData.forEach(e => {
              if (this.SelectedRequestedPage == 'LOA') {
                if (e.id.toLowerCase() == 'clients' || e.id.toLowerCase().replace(/[_ ]/g, '') == 'runreportby' || e.id.toLowerCase() == 'subgroup' || e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroupvalues') { e.showBool = false } else { e.showBool = true }
              }
              if (this.SelectedRequestedPage == 'ADA_REQ') {
                if (e.id.toLowerCase() == 'clients' || e.id.toLowerCase() == 'subgroup' || e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroupvalues') { e.showBool = false } else { e.showBool = true }
              }
              if (this.SelectedRequestedPage == 'PACS') {
                if (e.id.toLowerCase() == 'clients' || e.id.toLowerCase().replace(/[_ ]/g, '') == 'lineofbusiness' || e.id.toLowerCase() == 'policy' || e.id.toLowerCase() == 'class' || e.id.toLowerCase().replace(/[_ ]/g, '') == 'plantype' || e.id.toLowerCase().replace(/[_ ]/g, '') == 'policytype' || e.id.toLowerCase() == 'subgroup' || e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroupvalues') { e.showBool = false } else { e.showBool = true }
              }
              if (this.SelectedRequestedPage == 'ADA') {
                if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'clientnames' || e.id.toLowerCase() == 'subgroup' || e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroupvalues') { e.showBool = false } else { e.showBool = true }
              }
              if (this.SelectedRequestedPage == 'CD2') {
                if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'clientnames' || e.id.toLowerCase() == 'subgroup' || e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroupvalues' || e.id.toLowerCase().replace(/[_ ]/g, '') == 'stdpolicy' || e.id.toLowerCase().replace(/[_ ]/g, '') == 'loapolicy' || e.id.toLowerCase().replace(/[_ ]/g, '') == 'ltdpolicy' || e.id.toLowerCase().replace(/[_ ]/g, '') == 'wcpolicy') { e.showBool = false } else { e.showBool = true }
              }
              if (this.SelectedRequestedPage == 'WC') {
                if (e.id.toLowerCase() == 'clients' || e.id.toLowerCase().replace(/[_ ]/g, '') == 'runreportby' || e.id.toLowerCase() == 'subgroup' || e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroupvalues' || e.id.toLowerCase() == 'policies' || e.id.toLowerCase().replace(/[_ ]/g, '') == 'sitesbenefitstates') { e.showBool = false } else { e.showBool = true }
              }
              if (this.SelectedRequestedPage == 'WC_All') {
                if (e.id.toLowerCase() == 'clients' || e.id.toLowerCase() == 'subgroup' || e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroupvalues' || e.id.toLowerCase() == 'policies') { e.showBool = false } else { e.showBool = true }
              }
              if (this.SelectedRequestedPage == 'BCD') {
                if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'clientnames' || e.id.toLowerCase() == 'subgroup' || e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroupvalues' || e.id.toLowerCase().replace(/[_ ]/g, '') == 'stdpolicy' || e.id.toLowerCase().replace(/[_ ]/g, '') == 'loapolicy' || e.id.toLowerCase().replace(/[_ ]/g, '') == 'ltdpolicy' || e.id.toLowerCase().replace(/[_ ]/g, '') == 'wcpolicy') { e.showBool = false } else { e.showBool = true }
              }
              if (this.SelectedRequestedPage == 'CD3') {
                if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'clientnames' || e.id.toLowerCase() == 'subgroup' || e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroupvalues' || e.id.toLowerCase().replace(/[_ ]/g, '') == 'stdpolicy' || e.id.toLowerCase().replace(/[_ ]/g, '') == 'loapolicy' || e.id.toLowerCase().replace(/[_ ]/g, '') == 'ltdpolicy' || e.id.toLowerCase().replace(/[_ ]/g, '') == 'wcpolicy') { e.showBool = false } else { e.showBool = true }
              }
              if (this.SelectedRequestedPage == 'PCR') {
                if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'clientcode' || e.id.toLowerCase() == 'lineofbusiness' || e.id.toLowerCase().replace(/[_ ]/g, '') == 'bankaccountnumber') { e.showBool = false } else { e.showBool = true }
              }
              if (this.SelectedRequestedPage == 'SC') {
                if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'clientnames' || e.id.toLowerCase() == 'subgroup' || e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroupvalues' || e.id.toLowerCase().replace(/[_ ]/g, '') == 'stdpolicy' || e.id.toLowerCase().replace(/[_ ]/g, '') == 'loapolicy' || e.id.toLowerCase().replace(/[_ ]/g, '') == 'ltdpolicy') { e.showBool = false } else { e.showBool = true }
              }
              if (this.SelectedRequestedPage == 'PACS_All_Vendors2') {
                if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'clients' || e.id.toLowerCase().replace(/[_ ]/g, '') == 'policy') { e.showBool = false } else { e.showBool = true }
              }
            });
            // this.mainData.forEach(e => {
            //   if(e.selectedData!='') {
            //     this.mainData[index].paraBool = false;
            //     this.mainData[index].selected = true;
            //   }})
          }

          // this.selectchange(
          //   '',
          //   this.mainData[0]?.id,
          //   0,
          //   this.mainData[0]?.type
          // );
          if (this.Type === undefined) {
            this.reportNameLabel =
              'Add Schedule For ' + this.params.name.replace('.rptdesign', '').replace('.sql', '').replace('.RPTDESIGN', '').replace('.SQL', '');
            this.reportName = this.params.name.replace('.rptdesign', '').replace('.sql', '').replace('.RPTDESIGN', '').replace('.SQL', '');
            this.authDesc = this.params.authDesc;
            // this.SubjectValue=this.reportName + " "+this.datePipe.transform(this.currentDate, 'MM/dd/YYYY');
            this.SubjectValue = this.reportName.replace('.rptdesign', '').replace('.sql', '').replace('.RPTDESIGN', '').replace('.SQL', '');
            this.SubjectDate = this.datePipe.transform(
              this.currentDate,
              'MM/dd/yyyy'
            );
            this.SubjectDate1 = this.datePipe.transform(
              this.currentDate,
              'MM-dd-yyyy'
            );
            this.message = 'Please find ' + this.reportName.replace('.rptdesign', '').replace('.sql', '').replace('.RPTDESIGN', '').replace('.SQL', '');
            this.Jobvalue = this.reportName.replace('.rptdesign', '').replace('.sql', '').replace('.RPTDESIGN', '').replace('.SQL', '');
            // if (this.specialChars.test(this.reportName)) {
            //   this.fileAlertBool = true;
            // }
            // else {
            //   this.fileAlertBool = false;
            this.filename = this.reportName.replace('.rptdesign', '').replace('.sql', '').replace('.RPTDESIGN', '').replace('.SQL', '');
            // }
            this.ToValue = localStorage.getItem('userEmail');;
            this.selectedValue = '';
            this.scheduleOccurrenceValue = this.stateOptions[0]?.label;
            this.triggerType = 'NONE';
            this.isLoading = false;
            if (this.SelectedRequestedPage == 'LOA') {
              this.mainData.filter(e => {
                if (e.id.toLowerCase() == 'clients') {
                  e.showBool = true;
                  e.loading = true;
                  this.schedulerService.getClientNamesList(true, this.reportId).subscribe((response: any) => {
                    e.loading = false;
                    if (Array.isArray(response.body)) {
                      const filteredBody = response.body.filter(c => c !== null);
                      filteredBody.forEach(c => {
                        c.value = c.clientCode;
                        delete c.clientCode;
                        c.label = c.clientName;
                        delete c.clientName;
                        c.selected = false;
                      });
                      e.options = filteredBody;
                      e.selectedData = e.options[0].value;
                      if (this.SecureId == 2) {
                        this.getSftp(e.selectedData)
                      }
                      this.parameters[e.id] = e.options[0].value;
                      this.clientId = e.options[0].value;
                      if (e.mandatory) {
                        e.paraBool = false;
                        e.selected = true;
                        e.selectBool = true;
                      }
                      let updatedArray = []
                      this.schedulerService.getSubgroupList(this.reportId, this.clientId).subscribe((response: any) => {
                        response?.body?.forEach(c => {
                          if (c.displayName !== null && c.value !== null) {
                            updatedArray.push({
                              label: c.displayName ? c.displayName : '',
                              value: c.value,
                              selected: false
                            });
                          }
                        });
                        updatedArray.forEach(d => { if (d.value.toLowerCase() == 'restricted') { this.restricted = true } })
                        this.mainData.filter(e => {
                          if (e.id.toLowerCase() == 'subgroup') { e.options = updatedArray }
                        })
                      })
                    }
                  });
                }
                if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'runreportby') {
                  e.showBool = true;
                  e.loading = true;
                  this.schedulerService.getRunBy(this.reportId).subscribe((response: any) => {
                    e.loading = false;
                    let outputArray = response?.body.map(item => {
                      return { value: item, label: item, selected: false };
                    });
                    e.options = outputArray;

                  })
                }
                // if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroup') {
                //   let updatedArray = []
                //   setTimeout(() => {
                //     this.schedulerService.getSubgroupList(this.reportId, this.clientId).subscribe((response: any) => {
                //       response?.body?.forEach(c => {
                //         if (c.displayName !== null && c.value !== null) {
                //             updatedArray.push({
                //               label: c.displayName ? c.displayName : '',
                //               value: c.value,
                //               selected: false
                //             });
                //         }
                //       });
                //       e.options = updatedArray;
                //       e.options.forEach(d=>{if(d.value.toLowerCase()=='restricted'){this.restricted=true}})
                //     })
                //   }, 500);

                // }
              })
            }
            if (this.SelectedRequestedPage == 'ADA_REQ') {
              this.mainData.filter(e => {
                if (e.id.toLowerCase() == 'clients') {
                  e.showBool = true;
                  e.loading = true;
                  this.schedulerService.getAdaClientReport(this.reportId).subscribe((response: any) => {
                    e.loading = false;
                    response?.body?.forEach(c => {
                      c.value = c.clientCode;
                      delete c.clientCode;
                      c.label = c.clientName;
                      delete c.clientName;
                      c.selected = false;
                    })
                    e.options = response?.body;
                    e.selectedData = e.options[0].value;
                    if (this.SecureId == 2) {
                      this.getSftp(e.selectedData)
                    }
                    this.parameters[e.id] = e.options[0].value;
                    this.clientId = e.options[0].value;
                    if (e.mandatory) {
                      e.paraBool = false;
                      e.selected = true;
                      e.selectBool = true;
                    }
                    if (this.clientId) {
                      this.mainData.filter(e => {
                        if (e.id.toLowerCase() == 'subgroup') {
                          e.selectedData = [];
                          this.parameters[e.label] = '';
                          e.options = [];
                          e.showBool = true;
                          e.loading = true
                          this.schedulerService.getSubgroupList(this.reportId, this.clientId).subscribe((response: any) => {
                            e.loading = false;
                            response?.body?.forEach(c => {
                              c.label = c.displayName;
                              delete c.displayName;
                              c.selected = false;
                            })
                            e.options = response?.body;
                            e.options.forEach(d => { if (d.value.toLowerCase() == 'restricted') { this.restricted = true; } })
                              if (this.restricted) {
                                e.mandatory = true;
                                e.selectedData = e.options[0].value;
                                e.showBool = true;
                                e.disabled = true;
                                this.parameters[e.id] = e.options[0].value;
                                this.SubGroupId = e.options[0].value;
                                e.paraBool = false;
                                this.mainData.filter(d => {
                                  if (d.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroupvalues') {
                                    d.showBool = true;
                                    d.loading = true;
                                    let updatedArray = [];
                                    const selectedValues = [];
                                    this.schedulerService.getAdareqSubGroupValue(this.reportId, this.clientId, this.SubGroupId, this.SelectedRequestedPage).subscribe((response: any) => {
                                      d.loading = false;
                                      response?.body?.forEach(c => {
                                        if (c.displayName !== null && c.value !== null) {
                                          updatedArray.push({
                                            label: c.displayName ? c.displayName : '',
                                            value: c.value,
                                            selected: true
                                          });
                                          selectedValues.push(c.value);
                                        }
                                      });
                                      d.options = updatedArray;
                                      d.mandatory = true;
                                      d.selectedData = selectedValues;
                                      d.showBool = true;
                                      d.paraBool = false;
                                      this.parameters[d.id] = selectedValues;
                                    })
                                  }
                                })
                              }
                          })
                        }
                        if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroupvalues') { e.selectedData = []; e.showBool = false; delete this.parameters[e.id]; }
                      })
                    }
                  })

                }
              })
            }
            if (this.SelectedRequestedPage == 'PACS') {
              this.mainData.filter(e => {
                if (e.id.toLowerCase() == 'clients') {
                  e.showBool = true;
                  e.loading = true;
                  this.schedulerService.getClientNamesList(true, this.reportId).subscribe((response: any) => {
                    e.loading = false;
                    response?.body?.forEach(c => {
                      c.value = c.clientCode;
                      delete c.clientCode;
                      c.label = c.clientName;
                      delete c.clientName;
                      c.selected = false;
                    })
                    e.options = response?.body;
                    e.selectedData = e.options[0].value;
                    if (this.SecureId == 2) {
                      this.getSftp(e.selectedData)
                    }
                    this.parameters[e.id] = e.options[0].value;
                    this.clientId = e.options[0].value;
                    if (e.mandatory) {
                      e.paraBool = false;
                      e.selected = true;
                      e.selectBool = true;
                    }
                    if (this.clientId) {
                      this.mainData.filter(e => {
                        if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'lineofbusiness') {
                          e.selectedData = [];
                          this.parameters[e.label] = '';
                          e.options = [];
                          e.showBool = true;
                          e.loading = true
                          this.clientFlag = "C";
                          this.parentFlag = "Y";
                          let updatedArray = [];
                          this.schedulerService.getLOBList(this.reportId, this.clientId, this.clientFlag, this.parentFlag).subscribe((response: any) => {
                            e.loading = false;
                            response?.body?.forEach((c: any) => {
                              updatedArray.push({
                                label: c,
                                value: c,
                                selected: false
                              });
                            });
                            e.options = updatedArray;
                          })//e.id.toLowerCase() == 'policy'||e.id.toLowerCase() == 'class'||e.id.toLowerCase() == 'plan type'||e.id.toLowerCase()=='policy type'
                        }
                        // if (e.id.toLowerCase() == 'policies') { e.selectedData = []; e.options.filter(o => { if (o.selected == true) { o.selected = false } }); delete this.parameters[e.id]; }
                        if (e.id.toLowerCase() == 'policy') { e.selectedData = []; e.showBool = false; delete this.parameters[e.id]; }
                        if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'plantype') { e.selectedData = []; e.showBool = false; delete this.parameters[e.id]; }
                        if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'policytype') { e.selectedData = []; e.showBool = false; delete this.parameters[e.id]; }
                        if (e.id.toLowerCase() == 'class') { e.selectedData = []; e.showBool = false; delete this.parameters[e.id]; }
                        if (e.id.toLowerCase() == 'subgroup') {
                          e.selectedData = [];
                          this.parameters[e.label] = '';
                          e.options = [];
                          e.showBool = true;
                          e.loading = true
                          this.schedulerService.getSubgroupList(this.reportId, this.clientId).subscribe((response: any) => {
                            e.loading = false;
                            response?.body?.forEach(c => {
                              c.label = c.displayName;
                              delete c.displayName;
                              c.selected = false;
                            })
                            e.options = response?.body;
                            e.options.forEach(d => { if (d.value.toLowerCase() == 'restricted') { this.restricted = true; } })
                            if (this.restricted) {
                              e.mandatory = true;
                              e.selectedData = e.options[0].value;
                              e.showBool = true;
                              e.disabled = true;
                              this.parameters[e.id] = e.options[0].value;
                              this.SubGroupId = e.options[0].value;
                              e.paraBool = false;
                              this.mainData.filter(d => {
                                if (d.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroupvalues') {
                                  d.showBool = true;
                                  d.loading = true;
                                  let updatedArray = [];
                                  const selectedValues = [];
                                  this.schedulerService.getSubGroupValue(this.reportId, this.clientId, this.SubGroupId, this.SelectedRequestedPage).subscribe((response: any) => {
                                    d.loading = false;
                                    response?.body?.forEach(c => {
                                      if (c.displayName !== null && c.value !== null) {
                                        updatedArray.push({
                                          label: c.displayName ? c.displayName : '',
                                          value: c.value,
                                          selected: true
                                        });
                                        selectedValues.push(c.value);
                                      }
                                    });
                                    d.options = updatedArray;
                                    d.mandatory = true;
                                    d.selectedData = selectedValues;
                                    d.showBool = true;
                                    d.paraBool = false;
                                    this.parameters[d.id] = selectedValues;
                                  })
                                }
                              })
                            }
                          })
                        }
                        // if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroupvalues') { e.selectedData = []; e.showBool = false; delete this.parameters[e.id]; }
                      })
                    }
                  })
                }
                if (e.id.toLowerCase() == 'policies') {
                  // if (e.options[0].value == "Policy") { e.options[0].value = ["P", "o", "l", "i", "c", "y"] };
                  // if (e.options[1].value == "Policy Type") { e.options[1].value = ["P", "o", "l", "i", "c", "y", " ", "T", "p", "e"] }
                }
              })
            }
            if (this.SelectedRequestedPage == 'ADA') {
              this.mainData.filter(e => {
                if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'clientnames') {
                  e.showBool = true;
                  e.loading = true;
                  this.schedulerService.getAdaClient(this.reportId).subscribe((response: any) => {
                    e.loading = false;
                    response?.body?.forEach(c => {
                      c.value = c.clientCode;
                      delete c.clientCode;
                      c.label = c.clientName;
                      delete c.clientName;
                      c.selected = false;
                    })
                    e.options = response?.body;
                    e.selectedData = e.options[0].value;
                    if (this.SecureId == 2) {
                      this.getSftp(e.selectedData)
                    }
                    this.parameters[e.id] = e.options[0].value;
                    this.clientId = e.options[0].value;
                    if (e.mandatory) {
                      e.paraBool = false;
                      e.selected = true;
                      e.selectBool = true;
                    }
                    if (this.clientId) {
                      this.mainData.filter(e => {
                        if (e.id.toLowerCase() == 'subgroup') {
                          e.selectedData = [];
                          this.parameters[e.label] = '';
                          e.options = [];
                          e.showBool = true;
                          e.loading = true;
                          let updatedArray = []
                          this.schedulerService.getAdaSubgroupList(this.reportId, this.clientId).subscribe((response: any) => {
                            e.loading = false;
                            response?.body?.forEach((c: any) => {
                              updatedArray.push({
                                label: c.displayName,
                                value: c.value,
                                selected: false
                              });
                            });
                            e.options = updatedArray;
                            e.options.forEach(d => { if (d.value.toLowerCase() == 'restricted') { this.restricted = true; } })
                              if (this.restricted) {
                                e.mandatory = true;
                                e.selectedData = e.options[0].value;
                                e.showBool = true;
                                e.disabled = true;
                                this.parameters[e.id] = e.options[0].value;
                                this.SubGroupId = e.options[0].value;
                                e.paraBool = false;
                                this.mainData.filter(d => {
                                  if (d.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroupvalues') {
                                    d.showBool = true;
                                    d.loading = true;
                                    let updatedArray = [];
                                    const selectedValues = [];
                                    this.schedulerService.getAdaSubGroupValue(this.reportId, this.clientId, this.SubGroupId, this.SelectedRequestedPage).subscribe((response: any) => {
                                      d.loading = false;
                                      response?.body?.forEach(c => {
                                        if (c.displayName !== null && c.value !== null) {
                                          updatedArray.push({
                                            label: c.displayName ? c.displayName : '',
                                            value: c.value,
                                            selected: true
                                          });
                                          selectedValues.push(c.value);
                                        }
                                      });
                                      d.options = updatedArray;
                                      d.mandatory = true;
                                      d.selectedData = selectedValues;
                                      d.showBool = true;
                                      d.paraBool = false;
                                      this.parameters[d.id] = selectedValues;
                                    })
                                  }
                                })
                              }
                          })
                        }
                        if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroupvalues') { e.selectedData = []; e.showBool = false; delete this.parameters[e.id]; }
                      })
                    }
                  })

                }
              })
            }
            if (this.SelectedRequestedPage == 'CD2') {
              this.mainData.filter(e => {
                if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'clientnames') {
                  e.showBool = true;
                  e.loading = true;
                  this.schedulerService.getCD2Clients(this.reportId).subscribe((response: any) => {
                    e.loading = false;
                    response?.body?.forEach(c => {
                      c.value = c.clientCode;
                      delete c.clientCode;
                      c.label = c.clientName;
                      delete c.clientName;
                      c.selected = false;
                    })
                    e.options = response?.body;
                    e.selectedData = e.options[0].value;
                    if (this.SecureId == 2) {
                      this.getSftp(e.selectedData)
                    }
                    this.parameters[e.id] = e.options[0].value;
                    this.clientId = e.options[0].value;
                    if (e.mandatory) {
                      e.paraBool = false;
                      e.selected = true;
                      e.selectBool = true;
                    }
                    if (this.clientId) {
                      this.mainData.filter(e => {
                        if (e.id.toLowerCase() == 'subgroup') {
                          e.selectedData = [];
                          this.parameters[e.label] = '';
                          e.options = [];
                          e.showBool = true;
                          e.loading = true;
                          let updatedArray = []
                          this.schedulerService.getCD2Subgroup(this.reportId, this.clientId).subscribe((response: any) => {
                            e.loading = false;
                            response?.body?.forEach(c => {
                              c.label = c.displayName;
                              delete c.displayName;
                              c.selected = false;
                            })
                            e.options = response?.body;
                            e.options.forEach(d => { if (d.value.toLowerCase() == 'restricted') { this.restricted = true; } })
                              if (this.restricted) {
                                e.mandatory = true;
                                e.selectedData = e.options[0].value;
                                e.showBool = true;
                                e.disabled = true;
                                this.parameters[e.id] = e.options[0].value;
                                this.SubGroupId = e.options[0].value;
                                e.paraBool = false;
                                this.mainData.filter(d => {
                                  if (d.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroupvalues') {
                                    d.showBool = true;
                                    d.loading = true;
                                    let updatedArray = [];
                                    const selectedValues = [];
                                    this.schedulerService.getCD2SubgroupVlaue(this.reportId, this.clientId, this.SubGroupId, this.SelectedRequestedPage).subscribe((response: any) => {
                                      d.loading = false;
                                      response?.body?.forEach(c => {
                                        if (c.displayName !== null && c.value !== null) {
                                          updatedArray.push({
                                            label: c.displayName ? c.displayName : '',
                                            value: c.value,
                                            selected: true
                                          });
                                          selectedValues.push(c.value);
                                        }
                                      });
                                      d.options = updatedArray;
                                      d.mandatory = true;
                                      d.selectedData = selectedValues;
                                      d.showBool = true;
                                      d.paraBool = false;
                                      this.parameters[d.id] = selectedValues;
                                    })
                                  }
                                })
                              }
                          })
                        }
                        if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'loapolicy') {
                          e.selectedData = [];
                          this.parameters[e.label] = '';
                          e.options = [];
                          e.showBool = true;
                          e.loading = true;
                          let updatedArray = []
                          this.schedulerService.getCD2LoaPolicy(this.reportId, this.clientId).subscribe((response: any) => {
                            e.loading = false;
                            response?.body?.forEach((c: any) => {
                              updatedArray.push({
                                label: c,
                                value: c,
                                selected: false
                              });
                            });
                            e.options = updatedArray;
                          })
                        }
                        if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'stdpolicy') {
                          e.selectedData = [];
                          this.parameters[e.label] = '';
                          e.options = [];
                          e.showBool = true;
                          e.loading = true;
                          let updatedArray = []
                          this.schedulerService.getCD2StdPolicy(this.reportId, this.clientId).subscribe((response: any) => {
                            e.loading = false;
                            response?.body?.forEach((c: any) => {
                              updatedArray.push({
                                label: c,
                                value: c,
                                selected: false
                              });
                            });
                            e.options = updatedArray;
                          })
                        }
                        if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'wcpolicy') {
                          e.selectedData = [];
                          this.parameters[e.label] = '';
                          e.options = [];
                          e.showBool = true;
                          e.loading = true;
                          let updatedArray = []
                          this.schedulerService.getCD2WcPolicy(this.reportId, this.clientId).subscribe((response: any) => {
                            e.loading = false;
                            response?.body?.forEach((c: any) => {
                              updatedArray.push({
                                label: c,
                                value: c,
                                selected: false
                              });
                            });
                            e.options = updatedArray;
                          })
                        }
                        if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'ltdpolicy') {
                          e.selectedData = [];
                          this.parameters[e.label] = '';
                          e.options = [];
                          e.showBool = true;
                          e.loading = true;
                          let updatedArray = []
                          this.schedulerService.getCD2LtdPolicy(this.reportId, this.clientId).subscribe((response: any) => {
                            e.loading = false;
                            response?.body?.forEach((c: any) => {
                              updatedArray.push({
                                label: c,
                                value: c,
                                selected: false
                              });
                            });
                            e.options = updatedArray;
                          })
                        }
                        if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroupvalues') { e.selectedData = []; e.showBool = false; delete this.parameters[e.id]; }
                      })
                    }
                  })

                }
              })
            }
            if (this.SelectedRequestedPage == 'WC') {
              this.mainData.filter(e => {
                if (e.id.toLowerCase() == 'clients') {
                  e.showBool = true;
                  e.loading = true;
                  this.schedulerService.getWcClient(this.reportId).subscribe((response: any) => {
                    e.loading = false;
                    response?.body?.forEach(c => {
                      c.value = c.clientCode;
                      delete c.clientCode;
                      c.label = c.clientName;
                      delete c.clientName;
                      c.selected = false;
                    })
                    e.options = response?.body;
                    e.selectedData = e.options[0].value;
                    if (this.SecureId == 2) {
                      this.getSftp(e.selectedData)
                    }
                    this.parameters[e.id] = e.options[0].value;
                    this.clientId = e.options[0].value;
                    if (e.mandatory) {
                      e.paraBool = false;
                      e.selected = true;
                      e.selectBool = true;
                    }
                    if (this.clientId) { }
                    this.mainData.filter(e => {
                      if (e.id.toLowerCase() == 'subgroup' || e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroupvalues' || e.id.toLowerCase() == 'policies' || e.id.toLowerCase().replace(/[_ ]/g, '') == 'sitesbenefitstates') { e.showBool = false } else { e.showBool = true }

                      if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'runreportby') {
                        e.selectedData = [];
                        e.showBool = true;
                        e.loading = true;
                        let updatedArray = []
                        this.schedulerService.getRunByOptions(this.reportId).subscribe((response: any) => {
                          e.loading = false;
                          response?.body?.forEach((c: any) => {
                            updatedArray.push({
                              label: c,
                              value: c,
                              selected: false
                            });
                          });
                          e.options = updatedArray;
                        })

                      }
                    })
                  })

                }
              })
            }
            if (this.SelectedRequestedPage == 'WC_All') {
              this.mainData.filter(e => {
                if (e.id.toLowerCase() == 'clients') {
                  e.showBool = true;
                  e.loading = true;
                  this.schedulerService.getWcClient(this.reportId).subscribe((response: any) => {
                    e.loading = false;
                    response?.body?.forEach(c => {
                      c.value = c.clientCode;
                      delete c.clientCode;
                      c.label = c.clientName;
                      delete c.clientName;
                      c.selected = false;
                    })
                    e.options = response?.body;
                    e.selectedData = e.options[0].value;
                    if (this.SecureId == 2) {
                      this.getSftp(e.selectedData)
                    }
                    this.parameters[e.id] = e.options[0].value;
                    this.clientId = e.options[0].value;
                    if (e.mandatory) {
                      e.paraBool = false;
                      e.selected = true;
                      e.selectBool = true;
                    }
                  })

                }
              })
            }
            if (this.SelectedRequestedPage == 'BCD') {
              this.mainData.filter(e => {
                if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'clientnames') {
                  e.showBool = true;
                  e.loading = true;
                  this.schedulerService.getBCDclient(this.reportId).subscribe((response: any) => {
                    e.loading = false;
                    response?.body?.forEach(c => {
                      c.value = c.clientCode;
                      delete c.clientCode;
                      c.label = c.clientName;
                      delete c.clientName;
                      c.selected = false;
                    })
                    e.options = response?.body;
                    e.selectedData = e.options[0].value;
                    if (this.SecureId == 2) {
                      this.getSftp(e.selectedData)
                    }
                    this.parameters[e.id] = e.options[0].value;
                    this.clientId = e.options[0].value;
                    if (e.mandatory) {
                      e.paraBool = false;
                      e.selected = true;
                      e.selectBool = true;
                    }
                    if (this.clientId) {
                      this.mainData.filter(e => {
                        if (e.id.toLowerCase() == 'subgroup') {
                          e.selectedData = [];
                          this.parameters[e.label] = '';
                          e.options = [];
                          e.showBool = true;
                          e.loading = true;
                          let updatedArray = []
                          this.schedulerService.getBCDsubgroup(this.clientId, this.reportId).subscribe((response: any) => {
                            e.loading = false;
                            response?.body?.forEach(c => {
                              c.label = c.displayName;
                              delete c.displayName;
                              c.selected = false;
                            })
                            e.options = response?.body;
                            e.options.forEach(d => { if (d.value.toLowerCase() == 'restricted') { this.restricted = true; } })
                              if (this.restricted) {
                                e.mandatory = true;
                                e.selectedData = e.options[0].value;
                                e.showBool = true;
                                e.disabled = true;
                                this.parameters[e.id] = e.options[0].value;
                                this.SubGroupId = e.options[0].value;
                                e.paraBool = false;
                                this.mainData.filter(d => {
                                  if (d.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroupvalues') {
                                    d.showBool = true;
                                    d.loading = true;
                                    let updatedArray = [];
                                    const selectedValues = [];
                                    this.schedulerService.getBCDsubgroupValues(this.reportId, this.clientId, this.SubGroupId).subscribe((response: any) => {
                                      d.loading = false;
                                      response?.body?.forEach(c => {
                                        if (c.displayName !== null && c.value !== null) {
                                          updatedArray.push({
                                            label: c.displayName ? c.displayName : '',
                                            value: c.value,
                                            selected: true
                                          });
                                          selectedValues.push(c.value);
                                        }
                                      });
                                      d.options = updatedArray;
                                      d.mandatory = true;
                                      d.selectedData = selectedValues;
                                      d.showBool = true;
                                      d.paraBool = false;
                                      this.parameters[d.id] = selectedValues;
                                    })
                                  }
                                })
                              }
                          })
                        }
                        if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'loapolicy') {
                          e.selectedData = [];
                          this.parameters[e.label] = '';
                          e.options = [];
                          e.showBool = true;
                          e.loading = true;
                          let updatedArray = []
                          this.schedulerService.getBcdLOApolicy(this.clientId, this.reportId).subscribe((response: any) => {
                            e.loading = false;
                            response?.body?.forEach((c: any) => {
                              updatedArray.push({
                                label: c,
                                value: c,
                                selected: false
                              });
                            });
                            e.options = updatedArray;
                          })
                        }
                        if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'stdpolicy') {
                          e.selectedData = [];
                          this.parameters[e.label] = '';
                          e.options = [];
                          e.showBool = true;
                          e.loading = true;
                          let updatedArray = []
                          this.schedulerService.getBcdSTDpolicy(this.clientId, this.reportId).subscribe((response: any) => {
                            e.loading = false;
                            response?.body?.forEach((c: any) => {
                              updatedArray.push({
                                label: c,
                                value: c,
                                selected: false
                              });
                            });
                            e.options = updatedArray;
                          })
                        }
                        if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'wcpolicy') {
                          e.selectedData = [];
                          this.parameters[e.label] = '';
                          e.options = [];
                          e.showBool = true;
                          e.loading = true;
                          let updatedArray = []
                          this.schedulerService.getBcdWCpolicy(this.clientId, this.reportId).subscribe((response: any) => {
                            e.loading = false;
                            response?.body?.forEach((c: any) => {
                              updatedArray.push({
                                label: c,
                                value: c,
                                selected: false
                              });
                            });
                            e.options = updatedArray;
                          })
                        }
                        if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'ltdpolicy') {
                          e.selectedData = [];
                          this.parameters[e.label] = '';
                          e.options = [];
                          e.showBool = true;
                          e.loading = true;
                          let updatedArray = []
                          this.schedulerService.getBcdLTDpolicy(this.clientId, this.reportId).subscribe((response: any) => {
                            e.loading = false;
                            response?.body?.forEach((c: any) => {
                              updatedArray.push({
                                label: c,
                                value: c,
                                selected: false
                              });
                            });
                            e.options = updatedArray;
                          })
                        }
                        if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroupvalues') { e.selectedData = []; e.showBool = false; delete this.parameters[e.id]; }
                      })
                    }
                  })

                }
              })
            }
            if (this.SelectedRequestedPage == 'CD3') {
              this.mainData.filter(e => {
                if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'clientnames') {
                  e.showBool = true;
                  e.loading = true;
                  this.schedulerService.getCD3Clients(this.reportId).subscribe((response: any) => {
                    e.loading = false;
                    response?.body?.forEach(c => {
                      c.value = c.clientCode;
                      delete c.clientCode;
                      c.label = c.clientName;
                      delete c.clientName;
                      c.selected = false;
                    })
                    e.options = response?.body;
                    e.selectedData = e.options[0].value;
                    if (this.SecureId == 2) {
                      this.getSftp(e.selectedData)
                    }
                    this.parameters[e.id] = e.options[0].value;
                    this.clientId = e.options[0].value;
                    if (e.mandatory) {
                      e.paraBool = false;
                      e.selected = true;
                      e.selectBool = true;
                    }
                    if (this.clientId) {
                      this.mainData.filter(e => {
                        if (e.id.toLowerCase() == 'subgroup') {
                          e.selectedData = [];
                          this.parameters[e.label] = '';
                          e.options = [];
                          e.showBool = true;
                          e.loading = true;
                          let updatedArray = []
                          this.schedulerService.getCD3Subgroup(this.reportId, this.clientId).subscribe((response: any) => {
                            e.loading = false;
                            response?.body?.forEach(c => {
                              c.label = c.displayName;
                              delete c.displayName;
                              c.selected = false;
                            })
                            e.options = response?.body;
                            e.options.forEach(d => { if (d.value.toLowerCase() == 'restricted') { this.restricted = true; } })
                              if (this.restricted) {
                                e.mandatory = true;
                                e.selectedData = e.options[0].value;
                                e.showBool = true;
                                e.disabled = true;
                                this.parameters[e.id] = e.options[0].value;
                                this.SubGroupId = e.options[0].value;
                                e.paraBool = false;
                                this.mainData.filter(d => {
                                  if (d.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroupvalues') {
                                    d.showBool = true;
                                    d.loading = true;
                                    let updatedArray = [];
                                    const selectedValues = [];
                                    this.schedulerService.getCD2SubgroupVlaue(this.reportId, this.clientId, this.SubGroupId, this.SelectedRequestedPage).subscribe((response: any) => {
                                      d.loading = false;
                                      response?.body?.forEach(c => {
                                        if (c.displayName !== null && c.value !== null) {
                                          updatedArray.push({
                                            label: c.displayName ? c.displayName : '',
                                            value: c.value,
                                            selected: true
                                          });
                                          selectedValues.push(c.value);
                                        }
                                      });
                                      d.options = updatedArray;
                                      d.mandatory = true;
                                      d.selectedData = selectedValues;
                                      d.showBool = true;
                                      d.paraBool = false;
                                      this.parameters[d.id] = selectedValues;
                                    })
                                  }
                                })
                              }
                          })
                        }
                        if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'loapolicy') {
                          e.selectedData = [];
                          this.parameters[e.label] = '';
                          e.options = [];
                          e.showBool = true;
                          e.loading = true;
                          let updatedArray = []
                          this.schedulerService.getCD3LoaPolicy(this.reportId, this.clientId).subscribe((response: any) => {
                            e.loading = false;
                            response?.body?.forEach((c: any) => {
                              updatedArray.push({
                                label: c,
                                value: c,
                                selected: false
                              });
                            });
                            e.options = updatedArray;
                          })
                        }
                        if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'stdpolicy') {
                          e.selectedData = [];
                          this.parameters[e.label] = '';
                          e.options = [];
                          e.showBool = true;
                          e.loading = true;
                          let updatedArray = []
                          this.schedulerService.getCD3StdPolicy(this.reportId, this.clientId).subscribe((response: any) => {
                            e.loading = false;
                            response?.body?.forEach((c: any) => {
                              updatedArray.push({
                                label: c,
                                value: c,
                                selected: false
                              });
                            });
                            e.options = updatedArray;
                          })
                        }
                        if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'wcpolicy') {
                          e.selectedData = [];
                          this.parameters[e.label] = '';
                          e.options = [];
                          e.showBool = true;
                          e.loading = true;
                          let updatedArray = []
                          this.schedulerService.getCD3WcPolicy(this.reportId, this.clientId).subscribe((response: any) => {
                            e.loading = false;
                            response?.body?.forEach((c: any) => {
                              updatedArray.push({
                                label: c,
                                value: c,
                                selected: false
                              });
                            });
                            e.options = updatedArray;
                          })
                        }
                        if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'ltdpolicy') {
                          e.selectedData = [];
                          this.parameters[e.label] = '';
                          e.options = [];
                          e.showBool = true;
                          e.loading = true;
                          let updatedArray = []
                          this.schedulerService.getCD3LtdPolicy(this.reportId, this.clientId).subscribe((response: any) => {
                            e.loading = false;
                            response?.body?.forEach((c: any) => {
                              updatedArray.push({
                                label: c,
                                value: c,
                                selected: false
                              });
                            });
                            e.options = updatedArray;
                          })
                        }
                        if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroupvalues') { e.selectedData = []; e.showBool = false; delete this.parameters[e.id]; }
                      })
                    }
                  })

                }
              })
            }
            if (this.SelectedRequestedPage == 'PCR') {
              this.mainData.filter(e => {
                if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'clientcode') {
                  e.showBool = true;
                  e.loading = true;
                  this.schedulerService.getPCRclient(this.reportId).subscribe((response: any) => {
                    e.loading = false;
                    response?.body?.forEach(c => {
                      c.value = c.clientCode;
                      delete c.clientCode;
                      c.label = c.clientName;
                      delete c.clientName;
                      c.selected = false;
                    })
                    e.options = response?.body;
                    e.selectedData = e.options[0].value;
                    if (this.SecureId == 2) {
                      this.getSftp(e.selectedData)
                    }
                    this.parameters[e.id] = e.options[0].value;
                    this.clientId = e.options[0].value;
                    if (e.mandatory) {
                      e.paraBool = false;
                      e.selected = true;
                      e.selectBool = true;
                    }
                    if (this.clientId) {
                      this.mainData.filter(e => {
                        if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'lineofbusiness') {
                          e.selectedData = [];
                          this.parameters[e.label] = '';
                          e.options = [];
                          e.showBool = true;
                          e.loading = true
                          this.clientFlag = "C";
                          this.parentFlag = "Y";
                          let updatedArray = [];
                          this.schedulerService.getPcrLOBList(this.reportId, this.clientId, this.clientFlag, this.parentFlag).subscribe((response: any) => {
                            e.loading = false;
                            response?.body?.forEach((c: any) => {
                              updatedArray.push({
                                label: c,
                                value: c,
                                selected: false
                              });
                            });
                            e.options = updatedArray;
                          })//e.id.toLowerCase() == 'policy'||e.id.toLowerCase() == 'class'||e.id.toLowerCase() == 'plan type'||e.id.toLowerCase()=='policy type'
                        }
                        if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'bankaccountnumber') { e.selectedData = []; e.showBool = false; delete this.parameters[e.id]; }
                      })
                    }
                  })

                }
              })
            }
            if (this.SelectedRequestedPage == 'SC') {
              this.mainData.filter(e => {
                if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'clientnames') {
                  e.showBool = true;
                  e.loading = true;
                  this.schedulerService.getSCclient(this.reportId).subscribe((response: any) => {
                    e.loading = false;
                    response?.body?.forEach(c => {
                      c.value = c.clientCode;
                      delete c.clientCode;
                      c.label = c.clientName;
                      delete c.clientName;
                      c.selected = false;
                    })
                    e.options = response?.body;
                    e.selectedData = e.options[0].value;
                    if (this.SecureId == 2) {
                      this.getSftp(e.selectedData)
                    }
                    this.parameters[e.id] = e.options[0].value;
                    this.clientId = e.options[0].value;
                    if (e.mandatory) {
                      e.paraBool = false;
                      e.selected = true;
                      e.selectBool = true;
                    }
                    if (this.clientId) {
                      this.mainData.filter(e => {
                        if (e.id.toLowerCase() == 'subgroup') {
                          e.selectedData = [];
                          this.parameters[e.label] = '';
                          e.options = [];
                          e.showBool = true;
                          e.loading = true;
                          let updatedArray = []
                          this.schedulerService.getSCsubgroup(this.reportId, this.clientId).subscribe((response: any) => {
                            e.loading = false;
                            response?.body?.forEach(c => {
                              c.label = c.displayName;
                              delete c.displayName;
                              c.selected = false;
                            })
                            e.options = response?.body;
                            e.options.forEach(d => { if (d.value.toLowerCase() == 'restricted') { this.restricted = true; } })
                              if (this.restricted) {
                                e.mandatory = true;
                                e.selectedData = e.options[0].value;
                                e.showBool = true;
                                e.disabled = true;
                                this.parameters[e.id] = e.options[0].value;
                                this.SubGroupId = e.options[0].value;
                                e.paraBool = false;
                                this.mainData.filter(d => {
                                  if (d.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroupvalues') {
                                    d.showBool = true;
                                    d.loading = true;
                                    let updatedArray = [];
                                    const selectedValues = [];
                                    this.schedulerService.getSCsubgroupValues(this.reportId, this.clientId, this.SubGroupId).subscribe((response: any) => {
                                      d.loading = false;
                                      response?.body?.forEach(c => {
                                        if (c.displayName !== null && c.value !== null) {
                                          updatedArray.push({
                                            label: c.displayName ? c.displayName : '',
                                            value: c.value,
                                            selected: true
                                          });
                                          selectedValues.push(c.value);
                                        }
                                      });
                                      d.options = updatedArray;
                                      d.mandatory = true;
                                      d.selectedData = selectedValues;
                                      d.showBool = true;
                                      d.paraBool = false;
                                      this.parameters[d.id] = selectedValues;
                                    })
                                  }
                                })
                              }
                          })
                        }
                        if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'loapolicy') {
                          e.selectedData = [];
                          this.parameters[e.label] = '';
                          e.options = [];
                          e.showBool = true;
                          e.loading = true;
                          let updatedArray = []
                          this.schedulerService.getScLOApolicy(this.reportId, this.clientId).subscribe((response: any) => {
                            e.loading = false;
                            response?.body?.forEach((c: any) => {
                              updatedArray.push({
                                label: c,
                                value: c,
                                selected: false
                              });
                            });
                            e.options = updatedArray;
                          })
                        }
                        if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'stdpolicy') {
                          e.selectedData = [];
                          this.parameters[e.label] = '';
                          e.options = [];
                          e.showBool = true;
                          e.loading = true;
                          let updatedArray = []
                          this.schedulerService.getScSTDpolicy(this.reportId, this.clientId).subscribe((response: any) => {
                            e.loading = false;
                            response?.body?.forEach((c: any) => {
                              updatedArray.push({
                                label: c.displayName,
                                value: c.value,
                                selected: false
                              });
                            });
                            e.options = updatedArray;
                          })
                        }
                        if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'ltdpolicy') {
                          e.selectedData = [];
                          this.parameters[e.label] = '';
                          e.options = [];
                          e.showBool = true;
                          e.loading = true;
                          let updatedArray = []
                          this.schedulerService.getScLTDpolicy(this.reportId, this.clientId).subscribe((response: any) => {
                            e.loading = false;
                            response?.body?.forEach((c: any) => {
                              updatedArray.push({
                                label: c.displayName,
                                value: c.value,
                                selected: false
                              });
                            });
                            e.options = updatedArray;
                          })
                        }
                        if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroupvalues') { e.selectedData = []; e.showBool = false; delete this.parameters[e.id]; }
                      })
                    }
                  })

                }
              })
            }
            if (this.SelectedRequestedPage == 'PACS_All_Vendors2') {
              this.mainData.filter(e => {
                if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'clients') {
                  e.showBool = true;
                  e.loading = true;
                  this.schedulerService.getPACSV2client(this.reportId).subscribe((response: any) => {
                    e.loading = false;
                    response?.body?.forEach(c => {
                      c.value = c.clientCode;
                      delete c.clientCode;
                      c.label = c.clientName;
                      delete c.clientName;
                      c.selected = false;
                    })
                    e.options = response?.body;
                    e.selectedData = e.options[0].value;
                    if (this.SecureId == 2) {
                      this.getSftp(e.selectedData)
                    }
                    this.parameters[e.id] = e.options[0].value;
                    this.clientId = e.options[0].value;
                    if (e.mandatory) {
                      e.paraBool = false;
                      e.selected = true;
                      e.selectBool = true;
                    }
                    if (this.clientId) {
                      this.mainData.filter(e => {
                        if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'policy') {
                          e.selectedData = [];
                          this.parameters[e.label] = '';
                          e.options = [];
                          e.showBool = true;
                          e.loading = true
                          let updatedArray = [];
                          this.schedulerService.getPACSV2policyt(this.reportId, this.clientId).subscribe((response: any) => {
                            e.loading = false;
                            response?.body?.forEach((c: any) => {
                              updatedArray.push({
                                label: c.policyNumber + " - " + c.policyType + " - " + c.effectiveDate + " - " + c.expiryDate,
                                value: c.policyNumber + "|" + c.policyBON,
                                selected: false
                              });
                            });
                            e.options = updatedArray;
                          })
                        }
                      })
                    }
                  })

                }
              })
            }
          }
          else if (this.Type === 'Edit' || 'Copy') {
            this.mainData.forEach(item => item.selectedData = [])
            this.isLoading1 = true;
            this.DataToEdit = [];
            if (this.Type === 'Edit') {
              this.buttonName = 'Update Schedule';
            }
            if (this.Type === 'Copy') {
              this.buttonName = 'Copy Schedule';
            }
            this.scheduleOccurrenceValue = '';
            this.reportNameLabel =
              this.params.type + ' Schedule For ' + this.params.name;
            this.reportName = this.params.name;
            this.JobId = this.params.jobid;
            let queryParams = new HttpParams();
            if (this.Type === 'Edit') {
              queryParams = queryParams.append('type', 'edit');
            } else if (this.Type === 'Copy') {
              queryParams = queryParams.append('type', 'clone');
            }

            this.schedulerService
              .EditScheduledReport(this.JobId, queryParams)
              .subscribe((response: any) => {
                this.DataToEdit = response.body;
                if (!this.DataToEdit) {
                  return
                }
                else {

                  if (this.parameterElementsArray[0]?.slaveDependencies?.length > 0) {
                    // this.parameterElementsArray.forEach(item=>{
                    //   if(item.slaveDependencies?.length > 0){
                    this.cascade = true;
                    // this.isLoading1=true;
                    for (const k in this.DataToEdit.parameters) {
                      this.parameterElementsArray.forEach(e => {
                        if (e.id == k) {
                          if (e.slaveDependencies.length > 0) {

                            this.cascadeFunc(k, this.DataToEdit.parameters[k]);

                          }
                        }
                      });
                    }
                    // }
                    // })

                    // this.isLoading1=false;
                  }
                  this.message = this.DataToEdit?.mailMessage;
                  this.mailMessage = this.DataToEdit?.mailMessage;
                  this.SubjectValue = this.DataToEdit?.mailSubject;
                  this.mailSubject = this.DataToEdit?.mailSubject;
                  this.Jobvalue = this.DataToEdit?.jobName;
                  if (this.DataToEdit.isSftp.toLowerCase() == 'y' || this.DataToEdit.isPasswordProtect.toLowerCase() == 'y' || this.DataToEdit.sftpOutDirectoryPath || this.DataToEdit.sftpHostName) {
                    // this.ShowSftpSetting=true;
                    this.sftpClientCode = this.DataToEdit.clientCode;
                    if (this.DataToEdit.isSftp.toLowerCase() == 'y') {
                      this.ShowSftpSetting = true;
                      this.showSftp = true;
                      this.isSftp = this.DataToEdit.isSftp.toLowerCase() == 'y' ? true : false;
                    }
                    if (this.DataToEdit.isPasswordProtect.toLowerCase() == 'y') {
                      this.ShowSftpSetting = true;
                      this.showPassword = true;
                      this.isPassword = this.DataToEdit.isPasswordProtect.toLowerCase() == 'y' ? true : false;
                    }
                    if (this.DataToEdit.sftpOutDirectoryPath || this.DataToEdit.sftpHostName) {
                      this.ShowSftpSetting1 = true;
                      this.folderPath = this.DataToEdit.sftpOutDirectoryPath ? this.DataToEdit.sftpOutDirectoryPath : '';
                      this.serverName = this.DataToEdit.sftpHostName ? this.DataToEdit.sftpHostName : '';
                    }
                    // if(this.DataToEdit.sftpHostName){
                    //   this.ShowSftpSetting1=true;
                    //   this.serverName=this.DataToEdit.sftpHostName;
                    // }
                  }
                  // if (this.reportName.match("[^\\/:\x22*?<>|]+")) {
                  //   alert("File Name connot contain Spacial characters");
                  //   this.fileAlertBool = true;
                  // }
                  // else {
                  //   this.fileAlertBool = false;
                  //   this.filename = this.reportName;
                  // }
                  this.filename = this.DataToEdit?.outputName;
                  this.ToValue = this.DataToEdit?.mailTo;
                  this.mailTo = this.DataToEdit?.mailTo;
                  this.CCValue = this.DataToEdit?.mailCc;
                  this.ccTo = this.DataToEdit?.mailCc;
                  this.BCCValue = this.DataToEdit?.mailBcc;
                  this.bccValue = this.DataToEdit?.mailBcc;
                  this.triggerType = this.DataToEdit?.triggerType;
                  this.fileValue = this.DataToEdit?.outputType[0].toLowerCase();

                  if (this.DataToEdit.improvedTriggerType == '' || this.DataToEdit.improvedTriggerType == null) {
                    this.scheduleOccurrenceValue = this.stateOptions[0]?.label;
                    // this.changeSchedulerView(this.stateOptions[0]?.label, this.stateOptions[0]?.code);
                  }
                  else {
                    this.stateOptions.forEach((d) => {
                      if (
                        d.label.toLowerCase() ==
                        this.DataToEdit.improvedTriggerType
                      )

                        this.scheduleOccurrenceValue = d.label;
                    });
                    const today = new Date();
                    const todayWithoutTime = new Date(today.getFullYear(), today.getMonth(), today.getDate());
                    const startDate = this.DataToEdit.startDatetime;
                    const [year1, month1, day1] = startDate.split('-').map(Number);
                    const sDate = new Date(year1, month1 - 1, day1);
                    const endDate = this.DataToEdit.endDatetime;
                    const [year, month, day] = endDate.split('-').map(Number);
                    const eDate = new Date(year, month - 1, day);
                    this.startDateDaily = sDate <= todayWithoutTime ? todayWithoutTime : sDate;
                    this.endDateDaily = eDate < this.startDateDaily ? '' : eDate;
                    const maxDate = new Date(this.startDateDaily);
                    maxDate.setFullYear(this.startDateDaily.getFullYear() + 5);
                    this.maxDateDaily = maxDate;
                    this.timeZoneDaily = new Date();
                    this.timeZoneDaily.setHours(
                      parseInt(this.DataToEdit.advHours)
                    );
                    this.timeZoneDaily.setMinutes(
                      parseInt(this.DataToEdit.advMinutes)
                    );
                    this.dailyOccurrence.startDatetime = this.startDateDaily.getFullYear() +
                      '-' +
                      ('0' + (this.startDateDaily.getMonth() + 1)).slice(-2) +
                      '-' +
                      ('0' + this.startDateDaily.getDate()).slice(-2) +
                      ' ' +
                      '00:00:00';
                    this.dailyOccurrence.endDatetime = this.endDateDaily ? this.endDateDaily.getFullYear() +
                      '-' +
                      ('0' + (this.endDateDaily.getMonth() + 1)).slice(-2) +
                      '-' +
                      ('0' + this.endDateDaily.getDate()).slice(-2) +
                      ' ' +
                      '23:59:59' : '';
                    this.startDateWeekly = sDate <= todayWithoutTime ? todayWithoutTime : sDate;
                    this.endDateWeekly = eDate < this.startDateWeekly ? '' : eDate;
                    const maxDate1 = new Date(this.startDateWeekly);
                    maxDate1.setFullYear(this.startDateWeekly.getFullYear() + 5);
                    this.maxDateWeekly = maxDate1;
                    this.weeklyOccurrence.startDatetime =
                      this.startDateWeekly.getFullYear() +
                      '-' +
                      ('0' + (this.startDateWeekly.getMonth() + 1)).slice(-2) +
                      '-' +
                      ('0' + this.startDateWeekly.getDate()).slice(-2) +
                      ' ' +
                      '00:00:00';
                    this.weeklyOccurrence.endDatetime = this.endDateWeekly ? this.endDateWeekly.getFullYear() +
                      '-' +
                      ('0' + (this.endDateWeekly.getMonth() + 1)).slice(-2) +
                      '-' +
                      ('0' + this.endDateWeekly.getDate()).slice(-2) +
                      ' ' +
                      '23:59:59' : '';
                    this.timeZoneWeekly = new Date();
                    this.timeZoneWeekly.setHours(
                      parseInt(this.DataToEdit.advHours)
                    );
                    this.timeZoneWeekly.setMinutes(
                      parseInt(this.DataToEdit.advMinutes)
                    );
                    this.startDateMonthly = sDate <= todayWithoutTime ? todayWithoutTime : sDate;
                    this.endDateMonthly = eDate < this.startDateMonthly ? '' : eDate;
                    const maxDate2 = new Date(this.startDateMonthly);
                    maxDate2.setFullYear(this.startDateMonthly.getFullYear() + 5);
                    this.maxDateMonthly = maxDate2;
                    this.monthlyOccurrence.startDatetime =
                      this.startDateMonthly.getFullYear() +
                      '-' +
                      ('0' + (this.startDateMonthly.getMonth() + 1)).slice(-2) +
                      '-' +
                      ('0' + this.startDateMonthly.getDate()).slice(-2) +
                      ' ' +
                      '00:00:00';
                    this.monthlyOccurrence.endDatetime = this.endDateMonthly ? this.endDateMonthly.getFullYear() +
                      '-' +
                      ('0' + (this.endDateMonthly.getMonth() + 1)).slice(-2) +
                      '-' +
                      ('0' + this.endDateMonthly.getDate()).slice(-2) +
                      ' ' +
                      '23:59:59' : '';
                    this.timeZoneMonthly = new Date();
                    this.timeZoneMonthly.setHours(
                      parseInt(this.DataToEdit.advHours)
                    );
                    this.timeZoneMonthly.setMinutes(
                      parseInt(this.DataToEdit.advMinutes)
                    );
                  }
                  this.weeklyOccurrence.advHours = this.DataToEdit.advHours;
                  this.weeklyOccurrence.advMinutes = this.DataToEdit.advMinutes;
                  this.monthlyOccurrence.advHours = this.DataToEdit.advHours;
                  this.monthlyOccurrence.advMinutes = this.DataToEdit.advMinutes;
                  this.dailyOccurrence.advMinutes = this.DataToEdit.advMinutes;
                  this.dailyOccurrence.advHours = this.DataToEdit.advHours;
                  if (this.DataToEdit.improvedTriggerType == 'daily') {
                    this.dailyScheduler = true;
                    this.startType = this.DataToEdit?.startType;
                    this.dailyOccurrenceDayVal1 =
                      this.DataToEdit?.recurrenceInterval;
                    if (this.DataToEdit.isEveryWeekday == 'N') {
                      if (this.dailyOccurrenceDayVal1 > 1) {
                        this.everyDays = 'every days(s)';
                        this.dailyOccurrence.isEveryWeekday = 'N';
                      } else {
                        this.everyDays = 'every day';
                        this.dailyOccurrence.isEveryWeekday = 'N';
                        this.dailyOccurrence.recurrenceInterval = 1;
                      }
                    } else {
                      this.everyDays = 'every week day';
                      this.dailyOccurrence.isEveryWeekday = 'Y';
                      this.dailyOccurrence.recurrenceInterval = 1;
                    }
                    this.triggerType = this.DataToEdit.triggerType;
                    // const today = new Date();
                    // const todayWithoutTime = new Date(today.getFullYear(), today.getMonth(), today.getDate());
                    // const startDate = this.DataToEdit.startDatetime; 
                    // const [year1, month1, day1] = startDate.split('-').map(Number);
                    // const sDate = new Date(year1, month1 - 1, day1);
                    // this.startDateDaily = sDate <= todayWithoutTime ? todayWithoutTime : sDate;
                    // const endDate = this.DataToEdit.endDatetime; 
                    // const [year, month, day] = endDate.split('-').map(Number);
                    // const eDate = new Date(year, month - 1, day);
                    // this.endDateDaily = eDate < this.startDateDaily ? '' : eDate;
                    // const maxDate = new Date(this.startDateDaily);
                    // maxDate.setFullYear(this.startDateDaily.getFullYear() + 5);
                    // this.maxDateDaily = maxDate;
                    // this.timeZoneDaily = new Date();
                    // this.timeZoneDaily.setHours(
                    //   parseInt(this.DataToEdit.advHours)
                    // );
                    // this.timeZoneDaily.setMinutes(
                    //   parseInt(this.DataToEdit.advMinutes)
                    // );
                    this.dailyOccurrence.advHours = this.DataToEdit.advHours;
                    this.dailyOccurrence.advMinutes =
                      this.DataToEdit.advMinutes;
                    this.dailyOccurrence.improvedTriggerType =
                      this.DataToEdit.improvedTriggerType;
                    // this.dailyOccurrence.startDatetime = this.startDateDaily.getFullYear() +
                    //   '-' +
                    //   ('0' + (this.startDateDaily.getMonth() + 1)).slice(-2) +
                    //   '-' +
                    //   ('0' + this.startDateDaily.getDate()).slice(-2) +
                    //   ' ' +
                    //   '00:00:00';
                    // this.dailyOccurrence.endDatetime = this.endDateDaily ? this.endDateDaily.getFullYear() +
                    //   '-' +
                    //   ('0' + (this.endDateDaily.getMonth() + 1)).slice(-2) +
                    //   '-' +
                    //   ('0' + this.endDateDaily.getDate()).slice(-2) +
                    //   ' ' +
                    //   '23:59:59' : '';
                    //console.log(this.endDateDaily, this.startDateDaily)
                  }
                  if (this.DataToEdit.improvedTriggerType == 'weekly') {
                    this.weeklyScheduler = true;
                    this.triggerType = this.DataToEdit.triggerType;
                    this.startType = this.DataToEdit.startType
                    this.weekList = this.DataToEdit.weekDays;
                    // this.timeZoneWeekly = new Date();
                    // this.timeZoneWeekly.setHours(
                    //   parseInt(this.DataToEdit.advHours)
                    // );
                    // this.timeZoneWeekly.setMinutes(
                    //   parseInt(this.DataToEdit.advMinutes)
                    // );
                    this.weeklyOccurrence.advHours = this.DataToEdit.advHours;
                    this.weeklyOccurrence.advMinutes =
                      this.DataToEdit.advMinutes;
                    this.weeklyOccurrence.improvedTriggerType =
                      this.DataToEdit.improvedTriggerType;
                    // const today = new Date();
                    // const todayWithoutTime = new Date(today.getFullYear(), today.getMonth(), today.getDate());
                    // const startDate = this.DataToEdit.startDatetime;
                    // const [year1, month1, day1] = startDate.split('-').map(Number);
                    // const sDate = new Date(year1, month1 - 1, day1);
                    // this.startDateWeekly = sDate <= todayWithoutTime ? todayWithoutTime : sDate;
                    // const endDate = this.DataToEdit.endDatetime;
                    // const [year, month, day] = endDate.split('-').map(Number);
                    // const eDate = new Date(year, month - 1, day);
                    // this.endDateWeekly = eDate < this.startDateWeekly ? '' : eDate;
                    // const maxDate = new Date(this.startDateWeekly);
                    // maxDate.setFullYear(this.startDateWeekly.getFullYear() + 5);
                    // this.maxDateWeekly = maxDate;
                    // this.weeklyOccurrence.startDatetime =
                    //   this.startDateWeekly.getFullYear() +
                    //   '-' +
                    //   ('0' + (this.startDateWeekly.getMonth() + 1)).slice(-2) +
                    //   '-' +
                    //   ('0' + this.startDateWeekly.getDate()).slice(-2) +
                    //   ' ' +
                    //   '00:00:00';
                    // this.weeklyOccurrence.endDatetime = this.endDateWeekly ? this.endDateWeekly.getFullYear() +
                    //   '-' +
                    //   ('0' + (this.endDateWeekly.getMonth() + 1)).slice(-2) +
                    //   '-' +
                    //   ('0' + this.endDateWeekly.getDate()).slice(-2) +
                    //   ' ' +
                    //   '23:59:59' : '';
                    this.weeklyOccurrence.weekDays = this.weekList;
                  }
                  if (this.DataToEdit.improvedTriggerType == 'monthly') {
                    this.monthlyScheduler = true;
                    this.startType = this.DataToEdit.startType
                    this.triggerType = this.DataToEdit.triggerType;
                    // this.monthlyOccurrenceMonthVal =
                    //   this.DataToEdit.recurrenceInterval;
                    // this.monthlyOccurrenceMonthVal2 =
                    //   this.DataToEdit.recurrenceInterval;
                    this.monthlyDays = this.DataToEdit.weekDays;
                    // this.timeZoneMonthly = new Date();
                    // this.timeZoneMonthly.setHours(
                    //   parseInt(this.DataToEdit.advHours)
                    // );
                    // this.timeZoneMonthly.setMinutes(
                    //   parseInt(this.DataToEdit.advMinutes)
                    // );
                    // const today = new Date();
                    // const todayWithoutTime = new Date(today.getFullYear(), today.getMonth(), today.getDate());
                    // const startDate = this.DataToEdit.startDatetime;
                    // const [year1, month1, day1] = startDate.split('-').map(Number);
                    // const sDate = new Date(year1, month1 - 1, day1);
                    // this.startDateMonthly = sDate <= todayWithoutTime ? todayWithoutTime : sDate;
                    // const endDate = this.DataToEdit.endDatetime;
                    // const [year, month, day] = endDate.split('-').map(Number);
                    // const eDate = new Date(year, month - 1, day);
                    // this.endDateMonthly = eDate < this.startDateMonthly ? '' : eDate;
                    // const maxDate = new Date(this.startDateMonthly);
                    // maxDate.setFullYear(this.startDateMonthly.getFullYear() + 5);
                    // this.maxDateMonthly = maxDate;
                    this.monthlyOccurrence.advHours =
                      this.DataToEdit.advHours;
                    this.monthlyOccurrence.advMinutes =
                      this.DataToEdit.advMinutes;
                    this.monthlyOccurrence.improvedTriggerType =
                      this.DataToEdit.improvedTriggerType;
                    // this.monthlyOccurrence.startDatetime =
                    //   this.startDateMonthly.getFullYear() +
                    //   '-' +
                    //   ('0' + (this.startDateMonthly.getMonth() + 1)).slice(-2) +
                    //   '-' +
                    //   ('0' + this.startDateMonthly.getDate()).slice(-2) +
                    //   ' ' +
                    //   '00:00:00';
                    // this.monthlyOccurrence.endDatetime = this.endDateMonthly ? this.endDateMonthly.getFullYear() +
                    //   '-' +
                    //   ('0' + (this.endDateMonthly.getMonth() + 1)).slice(-2) +
                    //   '-' +
                    //   ('0' + this.endDateMonthly.getDate()).slice(-2) +
                    //   ' ' +
                    //   '23:59:59' : '';
                    this.monthlyOccurrence.monthDays =
                      this.DataToEdit.monthDays;
                    if (this.DataToEdit.isDayOrDate == 'date') {
                      this.monthlyDays = 'first';
                      this.monthlyOccurrenceMonthVal =
                        this.DataToEdit.recurrenceInterval;

                    }
                    if (this.DataToEdit.isDayOrDate == 'day') {
                      this.monthlyDays = 'second';
                      this.monthlyOccurrenceMonthVal2 =
                        this.DataToEdit.recurrenceInterval;
                      this.monthlyOccurDrop1 = this.DataToEdit.dayUnit
                      this.monthlyOccurDrop2 = this.DataToEdit.dayUnitValue;
                      this.monthlyOccurrence["dayUnit"] = this.DataToEdit.dayUnit;
                      this.monthlyOccurrence["dayUnitValue"] = this.DataToEdit.dayUnitValue;
                    }
                    this.monthlyOccurrence.isDayOrDate =
                      this.DataToEdit.isDayOrDate;
                    this.monthlyOccurrence.recurrenceInterval =
                      this.DataToEdit.recurrenceInterval;
                    this.monthlyOccurrenceDayVal1 = this.DataToEdit.monthDays;
                  }
                  //this.main
                  for (let key in this.DataToEdit.parameters) {
                    for (let k in this.parameters) {
                      if (k == key) {
                        this.parameters[k] = this.DataToEdit.parameters[key];
                      }
                    }
                    this.mainData.forEach
                  }
                  // for (let key in this.parameters) {
                  //   if (this.parameters[key] == '') { delete this.parameters[key] }
                  // }
                  if (this.cascade) {
                    setTimeout(() => {
                      this.isLoading1 = false;
                      this.getData();
                    }, 6000);
                  }
                  else if (this.SelectedRequestedPage != '') {
                    if (this.SelectedRequestedPage == 'LOA') {
                      for (let key in this.DataToEdit.parameters) {
                        if (key.toLowerCase() == 'clients') {
                          this.clientId = this.DataToEdit.parameters[key];
                        }
                        if (key.toLowerCase() == 'subgroup') {
                          this.SubGroupId = this.DataToEdit.parameters[key];
                          if (this.DataToEdit.parameters[key]!=null && this.DataToEdit.parameters[key].toLowerCase() == 'restricted') { this.restricted = true; }
                        }
                        if (key.toLowerCase().replace(/[_ ]/g, '') == 'runreportby') {
                          this.runReport = this.DataToEdit.parameters[key];
                        }
                      }
                      for (let key in this.DataToEdit.parameters) {
                        this.mainData.forEach(e => {
                          if (e.id == key) {
                            if (e.id.toLowerCase() == 'clients') {
                              e.showBool = true;
                              e.loading = true;
                              this.schedulerService.getClientNamesList(true, this.reportId).subscribe((response: any) => {
                                e.loading = false;
                                response?.body?.forEach(c => {
                                  c.value = c.clientCode;
                                  delete c.clientCode;
                                  c.label = c.clientName;
                                  delete c.clientName;
                                  c.selected = false;
                                })
                                e.options = response?.body;
                              })
                            }
                            if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'runreportby') {
                              let updatedArray = []
                              this.schedulerService.getRunBy(this.reportId).subscribe((response: any) => {
                                e.loading = false;
                                response?.body?.forEach((c: any) => {
                                  updatedArray.push({
                                    label: c,
                                    value: c,
                                    selected: false
                                  });
                                });
                                e.options = updatedArray;
                              })
                              if (this.DataToEdit.parameters[e.id].toLowerCase().replace(/[_ ]/g, '') == 'employeename') {
                                e.showBool = true;
                                e.loading = true;
                                this.mainData.filter(e => {
                                  if (e.id.toLowerCase() == 'subgroup') {
                                    // this.SubGroupId = this.DataToEdit.parameters[key];
                                    e.showBool = true;
                                    e.loading = true;
                                    let updatedArray = []
                                    this.schedulerService.getSubgroupList(this.reportId, this.clientId).subscribe((response: any) => {
                                      e.loading = false;
                                      response?.body?.forEach(c => {
                                        if (c.displayName !== null && c.value !== null) {
                                          updatedArray.push({
                                            label: c.displayName ? c.displayName : '',
                                            value: c.value,
                                            selected: false
                                          });
                                        }
                                      });
                                      e.options = updatedArray;
                                      updatedArray.forEach(d => { if (d.value.toLowerCase() == 'restricted') { this.restricted = true } })
                                      if (this.restricted) {
                                        e.mandatory = true;
                                        e.selectedData = e.options[0].value;
                                        e.showBool = true;
                                        e.disabled = true;
                                        this.parameters[e.id] = e.options[0].value;
                                        this.SubGroupId = e.options[0].value;
                                        e.paraBool = false;
                                      }
                                      else {
                                        e.selectedData = []; e.showBool = false; delete this.parameters[e.id];
                                      }
                                    })
                                  }
                                  if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroupvalues') {
                                    if (this.restricted) {
                                      e.mandatory = true;
                                      let updatedArray = [];
                                      const selectedValues = [];
                                      this.schedulerService.getSubGroupValue(this.reportId, this.clientId, this.SubGroupId, this.SelectedRequestedPage).subscribe((response: any) => {
                                        e.loading = false;
                                        e.showBool = true;
                                        response?.body?.forEach(c => {
                                          if (c.displayName !== null && c.value !== null) {
                                            updatedArray.push({
                                              label: c.displayName ? c.displayName : '',
                                              value: c.value,
                                              selected: true
                                            });
                                            selectedValues.push(c.value)
                                          }
                                        });
                                        e.options = updatedArray;
                                        e.selectedData = selectedValues;
                                        this.parameters[e.id] = selectedValues;
                                        e.paraBool = false;
                                      })
                                    }
                                    else {
                                      e.selectedData = []; e.showBool = false; delete this.parameters[e.id];
                                    }
                                  }
                                })
                              }
                              if (this.DataToEdit.parameters[e.id].toLowerCase().replace(/[_ ]/g, '') == 'subgroup') {
                                e.showBool = true;
                                this.mainData.filter(e => {
                                  // if (e.id.toLowerCase() == 'subgroup') { e.selectedData = []; e.showBool = false; delete this.parameters[e.id]; }
                                  //   if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroupvalues') { e.selectedData = []; e.showBool = false; delete this.parameters[e.id]; }
                                  // })

                                  if (e.id.toLowerCase() == 'subgroup') {
                                    // this.SubGroupId = this.DataToEdit.parameters[key];
                                    e.showBool = true;
                                    e.loading = true;
                                    let updatedArray = []
                                    this.schedulerService.getSubgroupList(this.reportId, this.clientId).subscribe((response: any) => {
                                      e.loading = false;
                                      response?.body?.forEach(c => {
                                        if (c.displayName !== null && c.value !== null) {
                                          updatedArray.push({
                                            label: c.displayName ? c.displayName : '',
                                            value: c.value,
                                            selected: false
                                          });
                                        }
                                      });
                                      e.options = updatedArray;
                                      updatedArray.forEach(d => { if (d.value.toLowerCase() == 'restricted') { this.restricted = true } })
                                      if (this.restricted) {
                                        e.mandatory = true;
                                        e.selectedData = e.options[0].value;
                                        e.showBool = true;
                                        e.disabled = true;
                                        this.parameters[e.id] = e.options[0].value;
                                        this.SubGroupId = e.options[0].value;
                                        e.paraBool = false;
                                      }
                                    })
                                  }
                                  if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroupvalues') {
                                    e.showBool = true;
                                    //e.loading = true;
                                    let updatedArray = []
                                    const selectedValues = []
                                    this.schedulerService.getSubGroupValue(this.reportId, this.clientId, this.SubGroupId, this.SelectedRequestedPage).subscribe((response: any) => {
                                      //e.loading = false;
                                      response?.body?.forEach(c => {
                                        if (c.displayName !== null && c.value !== null) {
                                          updatedArray.push({
                                            label: c.displayName ? c.displayName : '',
                                            value: c.value,
                                            selected: this.restricted ? true : false
                                          });
                                          selectedValues.push(c.value)
                                        }
                                      });
                                      e.options = updatedArray;
                                      if (this.restricted) {
                                        e.options = updatedArray;
                                        e.selectedData = selectedValues;
                                        this.parameters[e.id] = selectedValues;
                                        e.paraBool = false;
                                      }
                                    })
                                  }
                                })
                              }
                            }
                          }

                        })

                      }
                      setTimeout(() => {
                        this.isLoading1 = false;
                        this.getData();
                      }, 9000);
                    }
                    else if (this.SelectedRequestedPage == 'ADA_REQ') {
                      for (let key in this.DataToEdit.parameters) {
                        if (key.toLowerCase() == 'clients') {
                          this.clientId = this.DataToEdit.parameters[key];
                        }
                        if (key.toLowerCase() == 'subgroup') {
                          this.SubGroupId = this.DataToEdit.parameters[key];
                          if (this.DataToEdit.parameters[key]!=null && this.DataToEdit.parameters[key].toLowerCase() == 'restricted') { this.restricted = true; }
                        }
                      }
                      for (let key in this.DataToEdit.parameters) {
                        this.mainData.forEach(e => {
                          if (e.id == key) {
                            if (e.id.toLowerCase() == 'clients') {
                              e.showBool = true;
                              e.loading = true;
                              this.schedulerService.getAdaClientReport(this.reportId).subscribe((response: any) => {
                                e.loading = false;
                                response?.body?.forEach(c => {
                                  c.value = c.clientCode;
                                  delete c.clientCode;
                                  c.label = c.clientName;
                                  delete c.clientName;
                                  c.selected = false;
                                })
                                e.options = response?.body;
                              })
                            }
                            if (e.id.toLowerCase() == 'subgroup') {
                              this.SubGroupId = this.DataToEdit.parameters[key];
                              e.showBool = true;
                              e.loading = true;
                              let updatedArray = []
                              this.schedulerService.getSubgroupList(this.reportId, this.clientId).subscribe((response: any) => {
                                e.loading = false;
                                response?.body?.forEach(c => {
                                  if (c.displayName !== null && c.value !== null) {
                                    updatedArray.push({
                                      label: c.displayName ? c.displayName : '',
                                      value: c.value,
                                      selected: false
                                    });
                                  }
                                });
                                e.options = updatedArray;
                                if (this.restricted) {
                                  e.mandatory = true;
                                  e.selectedData = e.options[0].value;
                                  e.showBool = true;
                                  e.disabled = true;
                                  this.parameters[e.id] = e.options[0].value;
                                  this.SubGroupId = e.options[0].value;
                                  e.paraBool = false;
                                }
                              })
                            }
                            if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroupvalues') {
                              e.showBool = true;
                              e.loading = true;
                              let updatedArray = [];
                              const selectedValues = []
                              this.schedulerService.getAdareqSubGroupValue(this.reportId, this.clientId, this.SubGroupId, this.SelectedRequestedPage).subscribe((response: any) => {
                                e.loading = false;
                                response?.body?.forEach(c => {
                                  if (c.displayName !== null && c.value !== null) {
                                    updatedArray.push({
                                      label: c.displayName ? c.displayName : '',
                                      value: c.value,
                                      selected: this.restricted ? true : false
                                    });
                                    selectedValues.push(c.value)
                                  }
                                });
                                e.options = updatedArray;
                                if (this.restricted) {
                                  e.options = updatedArray;
                                  e.selectedData = selectedValues;
                                  this.parameters[e.id] = selectedValues;
                                  e.paraBool = false;
                                }
                              })
                            }
                          }

                        })

                      }
                      setTimeout(() => {
                        this.isLoading1 = false;
                        this.getData();
                      }, 9000);
                    }
                    else if (this.SelectedRequestedPage == 'PACS') {
                      for (let key in this.DataToEdit.parameters) {
                        if (key.toLowerCase() == 'clients') {
                          this.clientId = this.DataToEdit.parameters[key];
                        }
                        if (key.toLowerCase() == 'subgroup') {
                          this.SubGroupId = this.DataToEdit.parameters[key];
                          if (this.DataToEdit.parameters[key]!=null && this.DataToEdit.parameters[key].toLowerCase() == 'restricted') { this.restricted = true; }
                        }
                        if (key.toLowerCase().replace(/[_ ]/g, '') == 'lineofbusiness') {
                          this.lobData = this.DataToEdit.parameters[key];
                        }
                        if (key.toLowerCase() == 'policy') {
                          if (this.DataToEdit.parameters[key].length > 1) {
                            this.policyId = "";
                          }
                          else if (this.DataToEdit.parameters[key].length == 0) {
                            this.policyId = "";
                          }
                          else {
                            let numbers = []
                            numbers = this.DataToEdit.parameters[key][0].split("|")
                            this.policyId = numbers[1];
                          }
                        }
                        if (key.toLowerCase() == 'class') {
                          if (this.DataToEdit.parameters[key].length > 1) {
                            this.classBON = "";
                            this.classId = "";
                          }
                          else if (this.DataToEdit.parameters[key].length == 0) {
                            this.classBON = "";
                            this.classId = "";
                          }
                          else {
                            const match = this.DataToEdit.parameters[key][0].match(/\((\d+),\d+\)/);
                            this.classBON = match ? match[1] : null;
                            const match1 = this.DataToEdit.parameters[key][0].match(/\((\d+),(\d+)\)/);
                            this.classId = match1 ? match1[2] : null;
                          }
                        }

                      }
                      for (let key in this.DataToEdit.parameters) {
                        this.mainData.forEach(e => {
                          if (e.id == key) {
                            if (e.id.toLowerCase() == 'clients') {
                              e.showBool = true;
                              e.loading = true;
                              this.schedulerService.getClientNamesList(true, this.reportId).subscribe((response: any) => {
                                e.loading = false;
                                response?.body?.forEach(c => {
                                  c.value = c.clientCode;
                                  delete c.clientCode;
                                  c.label = c.clientName;
                                  delete c.clientName;
                                  c.selected = false;
                                })
                                e.options = response?.body;

                              })
                            }
                            if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'lineofbusiness') {
                              e.showBool = true;
                              e.loading = true;
                              this.clientFlag = "C";
                              this.parentFlag = "Y";
                              let updatedArray = [];
                              this.schedulerService.getLOBList(this.reportId, this.clientId, this.clientFlag, this.parentFlag).subscribe((response: any) => {
                                e.loading = false;
                                response?.body?.forEach((c: any) => {
                                  updatedArray.push({
                                    label: c,
                                    value: c,
                                    selected: false
                                  });
                                });
                                e.options = updatedArray;
                              })
                            }
                            if (e.id.toLowerCase() == 'policies') {
                              // if (this.DataToEdit.parameters[key] == "Policy Type" ||
                              //   this.DataToEdit.parameters[key] == '["P","o","l","i", "c","y", " ","T","p","e"]') {
                              // if (this.DataToEdit.parameters[key] == "Policy Type") {
                                e.options.forEach(e1=>{
                                  if(e1.value==this.DataToEdit.parameters[key]){
                                    e1.selected=true;
                                    e.selectedData = e1.value;
                                  }
                                })
                                  // e.options[1].selected = true;
                                  // e.selectedData = e.options[1].value;
                              // }
                              // if (this.DataToEdit.parameters[key] == "Policy") {
                              //   e.options.filter((e1)=>{
                              //     if(e1.value==this.DataToEdit.parameters[key]){
                              //       e1.selected=true;
                              //       e.selectedData = e1.value;
                              //     }
                              //   })
                              //   // e.options[0].selected=true;
                              //   // e.selectedData = e.options[0].value;
                              // }
                            }
                            if (e.id.toLowerCase() == 'policy') {
                              e.showBool = true;
                              e.loading = true;
                              this.schedulerService.getPolicyList(this.reportId, this.clientId, this.lobData).subscribe((response: any) => {
                                e.loading = false;
                                let convertedData = [];
                                response?.body?.map((obj, i) => {
                                  const formattedLabel = obj.policyNumber + " - " + obj.effectiveDate + " - " + obj.expiryDate +" - "+ obj.policyType + " - " + obj.policyDesc;
                                  convertedData[i] = { label: formattedLabel, value: obj.policyNumber + "|" + obj.policyBON, value1: obj.policyBON, selected: false };
                                });
                                e.options = convertedData;
                              })
                            }
                            if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'policytype') {
                              e.showBool = true;
                              e.loading = true;
                              let updatedArray = [];
                              this.schedulerService.getPolicyTypeList(this.reportId, this.clientId, this.lobData).subscribe((response: any) => {
                                e.loading = false;
                                response?.body?.forEach((c: any) => {
                                  updatedArray.push({
                                    label: c,
                                    value: c,
                                    selected: false
                                  });
                                });
                                e.options = updatedArray;
                              })
                            }
                            if (e.id.toLowerCase() == 'class') {
                              e.showBool = true;
                              e.loading = true;
                              this.schedulerService.getClassList(this.reportId, this.policyId).subscribe((response: any) => {
                                e.loading = false;
                                response?.body?.forEach(c => {
                                  c.value = '(' + c.contractClassBON + ',' + c.contractClassID + ')';
                                  c.label = c.contractClassID;
                                  delete c.contractClassID;
                                  c.value1 = c.contractClassBON;
                                  delete c.contractClassBON;
                                  c.selected = false;
                                })
                                e.options = response?.body;
                              })
                            }
                            if (e.id.toLowerCase() == 'subgroup') {
                              let updatedArray = [];
                              this.SubGroupId = this.DataToEdit.parameters[key];
                              e.showBool = true;
                              e.loading = true;
                              this.schedulerService.getSubgroupList(this.reportId, this.clientId).subscribe((response: any) => {
                                e.loading = false;
                                response?.body?.forEach((c: any) => {
                                  if (c.displayName !== null && c.value !== null) {
                                    updatedArray.push({
                                      label: c.displayName ? c.displayName : '',
                                      value: c.value,
                                      selected: false
                                    });
                                  }
                                });
                                e.options = updatedArray;
                                if (this.restricted) {
                                  e.mandatory = true;
                                  e.selectedData = e.options[0].value;
                                  e.showBool = true;
                                  e.disabled = true;
                                  this.parameters[e.id] = e.options[0].value;
                                  this.SubGroupId = e.options[0].value;
                                  e.paraBool = false;
                                }
                              })
                            }
                            if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroupvalues') {
                              e.showBool = true;
                              e.loading = true;
                              let updatedArray = [];
                              const selectedValues=[];
                              this.schedulerService.getSubGroupValue(this.reportId, this.clientId, this.SubGroupId, this.SelectedRequestedPage).subscribe((response: any) => {
                                e.loading = false;
                                response?.body?.forEach(c => {
                                  if (c.displayName !== null && c.value !== null) {
                                    updatedArray.push({
                                      label: c.displayName ? c.displayName : '',
                                      value: c.value,
                                      selected: this.restricted ? true : false
                                    });
                                    selectedValues.push(c.value)
                                  }
                                });
                                e.options = updatedArray;
                                if (this.restricted) {
                                  e.mandatory = true;
                                  e.options = updatedArray;
                                  e.selectedData = selectedValues;
                                  this.parameters[e.id] = selectedValues;
                                  e.paraBool = false;
                                }
                              })
                            }
                            if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'plantype') {
                              e.showBool = true;
                              e.loading = true;
                              this.schedulerService.getPlanTypeList(this.reportId, this.classBON, this.classId).subscribe((response: any) => {
                                e.loading = false;
                                response?.body?.forEach(c => {
                                  c.label = c.planDescription;
                                  delete c.planDescription;
                                  c.value = '(' + c.planDesignBON + ',' + c.planType + ')';
                                  delete c.planType;
                                  c.selected = false;
                                })
                                e.options = response?.body;
                              })
                            }
                          }
                        })
                      }
                      setTimeout(() => {
                        this.isLoading1 = false;
                        this.getData();
                      }, 10000);
                    }
                    else if (this.SelectedRequestedPage == 'ADA') {
                      for (let key in this.DataToEdit.parameters) {
                        if (key.toLowerCase().replace(/[_ ]/g, '') == 'clientnames') {
                          this.clientId = this.DataToEdit.parameters[key];
                        }
                        if (key.toLowerCase() == 'subgroup') {
                          this.SubGroupId = this.DataToEdit.parameters[key];
                          if (this.DataToEdit.parameters[key]!=null && this.DataToEdit.parameters[key].toLowerCase() == 'restricted') { this.restricted = true; }
                        }
                      }
                      for (let key in this.DataToEdit.parameters) {
                        this.mainData.forEach(e => {
                          if (e.id == key) {
                            if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'clientnames') {
                              e.showBool = true;
                              //let updatedArray=[];
                              e.loading = true;
                              this.schedulerService.getAdaClient(this.reportId).subscribe((response: any) => {
                                e.loading = false;
                                response?.body?.forEach(c => {
                                  c.value = c.clientCode;
                                  delete c.clientCode;
                                  c.label = c.clientName;
                                  delete c.clientName;
                                  c.selected = false;
                                })
                                e.options = response?.body;
                              })
                            }
                            if (e.id.toLowerCase() == 'subgroup') {
                              let updatedArray = [];
                              this.SubGroupId = this.DataToEdit.parameters[key];
                              e.showBool = true;
                              e.loading = true;
                              // let updatedArray=[]
                              this.schedulerService.getAdaSubgroupList(this.reportId, this.clientId).subscribe((response: any) => {
                                e.loading = false;
                                response?.body?.forEach((c: any) => {
                                  if (c.displayName !== null && c.value !== null) {
                                    updatedArray.push({
                                      label: c.displayName ? c.displayName : '',
                                      value: c.value,
                                      selected: false
                                    });
                                  }
                                });
                                e.options = updatedArray;
                                if (this.restricted) {
                                  e.mandatory = true;
                                  e.selectedData = e.options[0].value;
                                  e.showBool = true;
                                  e.disabled = true;
                                  this.parameters[e.id] = e.options[0].value;
                                  this.SubGroupId = e.options[0].value;
                                  e.paraBool = false;
                                }
                              })
                            }
                            if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroupvalues') {
                              e.showBool = true;
                              e.loading = true;
                              let updatedArray = [];
                              const selectedValues=[];
                              this.schedulerService.getAdaSubGroupValue(this.reportId, this.clientId, this.SubGroupId, this.SelectedRequestedPage).subscribe((response: any) => {
                                e.loading = false;
                                response?.body?.forEach(c => {
                                  if (c.displayName !== null && c.value !== null) {
                                    updatedArray.push({
                                      label: c.displayName ? c.displayName : '',
                                      value: c.value,
                                      selected: this.restricted ? true : false
                                    });
                                    selectedValues.push(c.value)
                                  }
                                });
                                e.options = updatedArray;
                                if (this.restricted) {
                                  e.mandatory = true;
                                  e.options = updatedArray;
                                  e.selectedData = selectedValues;
                                  this.parameters[e.id] = selectedValues;
                                  e.paraBool = false;
                                }
                              })
                            }
                          }

                        })

                      }
                      setTimeout(() => {
                        this.isLoading1 = false;
                        this.getData();
                      }, 9000);
                    }
                    else if (this.SelectedRequestedPage == 'CD2') {
                      for (let key in this.DataToEdit.parameters) {
                        if (key.toLowerCase().replace(/[_ ]/g, '') == 'clientnames') {
                          this.clientId = this.DataToEdit.parameters[key];
                        }
                        if (key.toLowerCase() == 'subgroup') {
                          this.SubGroupId = this.DataToEdit.parameters[key];
                          if (this.DataToEdit.parameters[key]!=null && this.DataToEdit.parameters[key].toLowerCase() == 'restricted') { this.restricted = true; }
                        }
                      }
                      for (let key in this.DataToEdit.parameters) {
                        this.mainData.forEach(e => {
                          if (e.id == key) {
                            if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'clientnames') {
                              e.showBool = true;
                              e.loading = true;
                              this.schedulerService.getCD2Clients(this.reportId).subscribe((response: any) => {
                                e.loading = false;
                                response?.body?.forEach(c => {
                                  c.value = c.clientCode;
                                  delete c.clientCode;
                                  c.label = c.clientName;
                                  delete c.clientName;
                                  c.selected = false;
                                })
                                e.options = response?.body;
                              })
                            }
                            if (e.id.toLowerCase() == 'subgroup') {
                              let updatedArray = [];
                              // this.SubGroupId = this.DataToEdit.parameters[key];
                              e.showBool = true;
                              e.loading = true;
                              this.schedulerService.getCD2Subgroup(this.reportId, this.clientId).subscribe((response: any) => {
                                e.loading = false;
                                response?.body?.forEach(c => {
                                  if (c.displayName !== null && c.value !== null) {
                                    updatedArray.push({
                                      label: c.displayName ? c.displayName : '',
                                      value: c.value,
                                      selected: false
                                    });
                                  }
                                });
                                e.options = updatedArray;
                                if (this.restricted) {
                                  e.mandatory = true;
                                  e.selectedData = e.options[0].value;
                                  e.showBool = true;
                                  e.disabled = true;
                                  this.parameters[e.id] = e.options[0].value;
                                  this.SubGroupId = e.options[0].value;
                                  e.paraBool = false;
                                }
                              })
                            }
                            if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'loapolicy') {
                              let updatedArray = [];
                              // this.SubGroupId = this.DataToEdit.parameters[key];
                              e.showBool = true;
                              e.loading = true;
                              this.schedulerService.getCD2LoaPolicy(this.reportId, this.clientId).subscribe((response: any) => {
                                e.loading = false;
                                response?.body?.forEach((c: any) => {
                                  updatedArray.push({
                                    label: c,
                                    value: c,
                                    selected: false
                                  });
                                });
                                e.options = updatedArray;
                              })
                            }
                            if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'stdpolicy') {
                              let updatedArray = [];
                              // this.SubGroupId = this.DataToEdit.parameters[key];
                              e.showBool = true;
                              e.loading = true;
                              this.schedulerService.getCD2StdPolicy(this.reportId, this.clientId).subscribe((response: any) => {
                                e.loading = false;
                                response?.body?.forEach((c: any) => {
                                  updatedArray.push({
                                    label: c,
                                    value: c,
                                    selected: false
                                  });
                                });
                                e.options = updatedArray;
                              })
                            }
                            if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'ltdpolicy') {
                              let updatedArray = [];
                              // this.SubGroupId = this.DataToEdit.parameters[key];
                              e.showBool = true;
                              e.loading = true;
                              this.schedulerService.getCD2LtdPolicy(this.reportId, this.clientId).subscribe((response: any) => {
                                e.loading = false;
                                response?.body?.forEach((c: any) => {
                                  updatedArray.push({
                                    label: c,
                                    value: c,
                                    selected: false
                                  });
                                });
                                e.options = updatedArray;
                              })
                            }
                            if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'wcpolicy') {
                              let updatedArray = [];
                              // this.SubGroupId = this.DataToEdit.parameters[key];
                              e.showBool = true;
                              e.loading = true;
                              this.schedulerService.getCD2WcPolicy(this.reportId, this.clientId).subscribe((response: any) => {
                                e.loading = false;
                                response?.body?.forEach((c: any) => {
                                  updatedArray.push({
                                    label: c,
                                    value: c,
                                    selected: false
                                  });
                                });
                                e.options = updatedArray;
                              })
                            }
                            if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroupvalues') {
                              e.showBool = true;
                              e.loading = true;
                              let updatedArray = [];
                              const selectedValues=[];
                              this.schedulerService.getCD2SubgroupVlaue(this.reportId, this.clientId, this.SubGroupId, this.SelectedRequestedPage).subscribe((response: any) => {
                                e.loading = false;
                                response?.body?.forEach(c => {
                                  if (c !== null) {
                                    updatedArray.push({
                                      label: c,
                                      value: c,
                                      selected: this.restricted ? true : false
                                    });
                                    selectedValues.push(c.value)
                                  }
                                });
                                e.options = updatedArray;
                                if (this.restricted) {
                                  e.options = updatedArray;
                                  e.mandatory = true;
                                  e.selectedData = selectedValues;
                                  this.parameters[e.id] = selectedValues;
                                  e.paraBool = false;
                                }
                              })
                            }
                          }

                        })

                      }
                      setTimeout(() => {
                        this.isLoading1 = false;
                        this.getData();
                      }, 9000);
                    }
                    else if (this.SelectedRequestedPage == 'WC') {
                      for (let key in this.DataToEdit.parameters) {
                        if (key.toLowerCase() == 'clients') {
                          this.clientId = this.DataToEdit.parameters[key];
                        }
                        if (key.toLowerCase() == 'subgroup') {
                          this.SubGroupId = this.DataToEdit.parameters[key];
                          if (this.DataToEdit.parameters[key]!=null && this.DataToEdit.parameters[key].toLowerCase() == 'restricted') { this.restricted = true; }
                        }
                        if (key.toLowerCase().replace(/[_ ]/g, '') == 'runreportby') {
                          this.runReport1=this.DataToEdit.parameters[key];
                        }
                      }
                      for (let key in this.DataToEdit.parameters) {
                        this.mainData.forEach(e => {
                          if (e.id == key) {
                            if (e.id.toLowerCase() == 'clients') {
                              e.showBool = true;
                              e.loading = true;
                              this.schedulerService.getWcClient(this.reportId).subscribe((response: any) => {
                                e.loading = false;
                                response?.body?.forEach(c => {
                                  c.value = c.clientCode;
                                  delete c.clientCode;
                                  c.label = c.clientName;
                                  delete c.clientName;
                                  c.selected = false;
                                })
                                e.options = response?.body;

                              })
                            }
                            if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'runreportby') {
                              // if (e.id.toLowerCase() == 'run report by') {
                              e.selectedData = [];
                              e.showBool = true;
                              e.loading = true;
                              let updatedArray = []
                              this.schedulerService.getRunByOptions(this.reportId).subscribe((response: any) => {
                                e.loading = false;
                                response?.body?.forEach((c: any) => {
                                  updatedArray.push({
                                    label: c,
                                    value: c,
                                    selected: false
                                  });
                                });
                                e.options = updatedArray;
                              })
                              // }
                              if (this.DataToEdit.parameters[key].toLowerCase().replace(/[_ ]/g, '') === 'policyonly') {
                                this.mainData.filter(e => {
                                  if (e.id.toLowerCase() == 'policies') {
                                    e.selectedData = [];
                                    e.showBool = true;
                                    e.loading = true;
                                    this.schedulerService.getWCPolicyList(this.reportId, this.clientId).subscribe((response: any) => {
                                      e.loading = false;
                                      response?.body?.forEach(c => {
                                        c.value = c.policyNumber;
                                        c.label = c.policyNumber + '-' + c.effectiveDate + '-' + c.expiryDate;
                                        c.selected = false;
                                      })
                                      e.options = response?.body;
                                    })

                                  }
                                  if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'sitesbenefitstates') { e.selectedData = []; e.showBool = false; delete this.parameters[e.id]; }
                                  if (e.id.toLowerCase() == 'subgroup') { e.selectedData = []; e.showBool = false; delete this.parameters[e.id]; }
                                  if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroupvalues') { e.selectedData = []; e.showBool = false; delete this.parameters[e.id]; }
                                })
                              }
                              if (this.DataToEdit.parameters[key].toLowerCase().replace(/[_ ]/g, '') === 'benefitstateonly') {
                                this.mainData.filter(e => {
                                  if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'sitesbenefitstates') {
                                    e.selectedData = [];
                                    e.showBool = true;
                                    e.loading = true;
                                    this.schedulerService.getWCBenefitList(this.reportId, this.clientId).subscribe((response: any) => {
                                      e.loading = false;
                                      response?.body?.forEach(c => {
                                        c.label = c.benefitState;
                                        c.value = c.ncciStateJurisdictionCode;
                                        c.selected = false;
                                      })
                                      e.options = response?.body;
                                    })
                                  }
                                  if (e.id.toLowerCase() == 'policies') { e.selectedData = []; e.showBool = false; delete this.parameters[e.id]; }
                                  if (e.id.toLowerCase() == 'subgroup') { e.selectedData = []; e.showBool = false; delete this.parameters[e.id]; }
                                  if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroupvalues') { e.selectedData = []; e.showBool = false; delete this.parameters[e.id]; }
                                })
                              }
                              if (this.DataToEdit.parameters[key].toLowerCase().replace(/[_ ]/g, '') === 'siteonly') {
                                this.mainData.filter(e => {
                                  if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'sitesbenefitstates') {
                                    e.selectedData = [];
                                    e.showBool = true;
                                    e.loading = true;
                                    this.schedulerService.getWCSiteList(this.reportId, this.clientId).subscribe((response: any) => {
                                      e.loading = false;
                                      response?.body?.forEach(c => {
                                        c.label = c.siteCode + '-' + c.siteDesc;
                                        c.value = c.siteCode;
                                        c.selected = false;
                                      })
                                      e.options = response?.body;
                                    })

                                  }
                                  if (e.id.toLowerCase() == 'policies') { e.selectedData = []; e.showBool = false; delete this.parameters[e.id]; }
                                  if (e.id.toLowerCase() == 'subgroup') { e.selectedData = []; e.showBool = false; delete this.parameters[e.id]; }
                                  if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroupvalues') { e.selectedData = []; e.showBool = false; delete this.parameters[e.id]; }
                                })
                              }
                              if (this.DataToEdit.parameters[key].toLowerCase().replace(/[_ ]/g, '') === 'policy,benefitstate') {
                                this.mainData.filter(e => {
                                  if (e.id.toLowerCase() == 'policies') {
                                    e.selectedData = [];
                                    e.showBool = true;
                                    e.loading = true;
                                    this.schedulerService.getWCPolicyList(this.reportId, this.clientId).subscribe((response: any) => {
                                      e.loading = false;
                                      response?.body?.forEach(c => {
                                        c.value = c.policyNumber;
                                        c.label = c.policyNumber + '-' + c.effectiveDate + '-' + c.expiryDate;
                                        c.selected = false;
                                      })
                                      e.options = response?.body;
                                    })

                                  }
                                  if (e.id.toLowerCase().replace(/[_ ]/g, '') === 'sitesbenefitstates') {
                                    e.selectedData = [];
                                    e.showBool = true;
                                    e.loading = true;
                                    this.schedulerService.getWCBenefitList(this.reportId, this.clientId).subscribe((response: any) => {
                                      e.loading = false;
                                      response?.body?.forEach(c => {
                                        c.label = c.benefitState;
                                        c.value = c.ncciStateJurisdictionCode;
                                        c.selected = false;
                                      })
                                      e.options = response?.body;
                                    })

                                  }
                                  if (e.id.toLowerCase() === 'subgroup') { e.selectedData = []; e.showBool = false; delete this.parameters[e.id]; }
                                  if (e.id.toLowerCase().replace(/[_ ]/g, '') === 'subgroupvalues') { e.selectedData = []; e.showBool = false; delete this.parameters[e.id]; }
                                })
                              }
                              if (this.DataToEdit.parameters[key].toLowerCase().replace(/[_ ]/g, '') === 'policy,site') {
                                this.mainData.filter(e => {
                                  if (e.id.toLowerCase() == 'policies') {
                                    e.selectedData = [];
                                    e.showBool = true;
                                    e.loading = true;
                                    this.schedulerService.getWCPolicyList(this.reportId, this.clientId).subscribe((response: any) => {
                                      e.loading = false;
                                      response?.body?.forEach(c => {
                                        c.value = c.policyNumber;
                                        c.label = c.policyNumber + '-' + c.effectiveDate + '-' + c.expiryDate;
                                        c.selected = false;
                                      })
                                      e.options = response?.body;
                                    })

                                  }
                                  if (e.id.toLowerCase().replace(/[_ ]/g, '') === 'sitesbenefitstates') {
                                    e.selectedData = [];
                                    e.showBool = true;
                                    e.loading = true;
                                    this.schedulerService.getWCSiteList(this.reportId, this.clientId).subscribe((response: any) => {
                                      e.loading = false;
                                      response?.body?.forEach(c => {
                                        c.label = c.siteCode + '-' + c.siteDesc;
                                        c.value = c.siteCode;
                                        c.selected = false;
                                      })
                                      e.options = response?.body;
                                    })

                                  }
                                  if (e.id.toLowerCase() == 'subgroup') { e.selectedData = []; e.showBool = false; delete this.parameters[e.id]; }
                                  if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroupvalues') { e.selectedData = []; e.showBool = false; delete this.parameters[e.id]; }
                                })
                              }
                              if (this.DataToEdit.parameters[key].toLowerCase().replace(/[_ ]/g, '') === 'policy,benefitstate,subgroup') {
                                this.mainData.filter(e => {
                                  if (e.id.toLowerCase() == 'policies') {
                                    e.selectedData = [];
                                    e.showBool = true;
                                    e.loading = true;
                                    this.schedulerService.getWCPolicyList(this.reportId, this.clientId).subscribe((response: any) => {
                                      e.loading = false;
                                      response?.body?.forEach(c => {
                                        c.value = c.policyNumber;
                                        c.label = c.policyNumber + '-' + c.effectiveDate + '-' + c.expiryDate;
                                        c.selected = false;
                                      })
                                      e.options = response?.body;
                                    })

                                  }
                                  if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'sitesbenefitstates') {
                                    e.selectedData = [];
                                    e.showBool = true;
                                    e.loading = true;
                                    this.schedulerService.getWCBenefitList(this.reportId, this.clientId).subscribe((response: any) => {
                                      e.loading = false;
                                      response?.body?.forEach(c => {
                                        c.label = c.benefitState;
                                        c.value = c.ncciStateJurisdictionCode;
                                        c.selected = false;
                                      })
                                      e.options = response?.body;
                                    })

                                  }
                                  if (e.id.toLowerCase() == 'subgroup') {
                                    e.selectedData = [];
                                    this.parameters[e.label] = '';
                                    e.options = [];
                                    e.showBool = true;
                                    e.loading = true;
                                    let updatedArray = []
                                    this.schedulerService.getWCSubgroup(this.reportId, this.clientId).subscribe((response: any) => {
                                      e.loading = false;
                                      response?.body?.forEach(c => {
                                        if (c.displayName !== null && c.value !== null) {
                                          updatedArray.push({
                                            label: c.displayName ? c.displayName : '',
                                            value: c.value,
                                            selected: false
                                          });
                                        }
                                      });
                                      e.options = updatedArray;
                                    })
                                  }
                                  if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroupvalues') { e.selectedData = []; e.showBool = false; delete this.parameters[e.id]; }
                                })
                              }
                              if (this.DataToEdit.parameters[key].toLowerCase().replace(/[_ ]/g, '') === 'policy,site,subgroup') {
                                this.mainData.filter(e => {
                                  if (e.id.toLowerCase() == 'policies') {
                                    e.selectedData = [];
                                    e.showBool = true;
                                    e.loading = true;
                                    this.schedulerService.getWCPolicyList(this.reportId, this.clientId).subscribe((response: any) => {
                                      e.loading = false;
                                      response?.body?.forEach(c => {
                                        c.value = c.policyNumber;
                                        c.label = c.policyNumber + '-' + c.effectiveDate + '-' + c.expiryDate;
                                        c.selected = false;
                                      })
                                      e.options = response?.body;
                                    })

                                  }
                                  if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'sitesbenefitstates') {
                                    e.selectedData = [];
                                    e.showBool = true;
                                    e.loading = true;
                                    this.schedulerService.getWCSiteList(this.reportId, this.clientId).subscribe((response: any) => {
                                      e.loading = false;
                                      response?.body?.forEach(c => {
                                        c.label = + c.siteCode + '-' + c.siteDesc;
                                        c.value = c.siteCode;
                                        c.selected = false;
                                      })
                                      e.options = response?.body;
                                    })

                                  }
                                  if (e.id.toLowerCase() == 'subgroup') {
                                    e.selectedData = [];
                                    this.parameters[e.label] = '';
                                    e.options = [];
                                    e.showBool = true;
                                    e.loading = true;
                                    let updatedArray = []
                                    this.schedulerService.getWCSubgroup(this.reportId, this.clientId).subscribe((response: any) => {
                                      e.loading = false;
                                      response?.body?.forEach(c => {
                                        if (c.displayName !== null && c.value !== null) {
                                          updatedArray.push({
                                            label: c.displayName ? c.displayName : '',
                                            value: c.value,
                                            selected: false
                                          });
                                        }
                                      });
                                      e.options = updatedArray;
                                    })
                                  }
                                  if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroupvalues') { e.selectedData = []; e.showBool = false; delete this.parameters[e.id]; }
                                })
                              }
                              if (this.DataToEdit.parameters[key].toLowerCase().replace(/[_ ]/g, '') === 'benefitstate,subgroup') {
                                this.mainData.filter(e => {
                                  if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'sitesbenefitstates') {
                                    e.selectedData = [];
                                    e.showBool = true;
                                    e.loading = true;
                                    this.schedulerService.getWCBenefitList(this.reportId, this.clientId).subscribe((response: any) => {
                                      e.loading = false;
                                      response?.body?.forEach(c => {
                                        c.label = c.benefitState;
                                        c.value = c.ncciStateJurisdictionCode;
                                        c.selected = false;
                                      })
                                      e.options = response?.body;
                                    })

                                  }
                                  if (e.id.toLowerCase() == 'subgroup') {
                                    e.selectedData = [];
                                    this.parameters[e.label] = '';
                                    e.options = [];
                                    e.showBool = true;
                                    e.loading = true;
                                    let updatedArray = []
                                    this.schedulerService.getWCSubgroup(this.reportId, this.clientId).subscribe((response: any) => {
                                      e.loading = false;
                                      response?.body?.forEach(c => {
                                        if (c.displayName !== null && c.value !== null) {
                                          updatedArray.push({
                                            label: c.displayName ? c.displayName : '',
                                            value: c.value,
                                            selected: false
                                          });
                                        }
                                      });
                                      e.options = updatedArray;
                                    })
                                  }
                                  if (e.id.toLowerCase() == 'policies') { e.selectedData = []; e.showBool = false; delete this.parameters[e.id]; }
                                  if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroupvalues') { e.selectedData = []; e.showBool = false; delete this.parameters[e.id]; }
                                })
                              }
                              if (this.DataToEdit.parameters[key].toLowerCase().replace(/[_ ]/g, '') === 'site,subgroup') {
                                this.mainData.filter(e => {

                                  if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'sitesbenefitstates') {
                                    e.selectedData = [];
                                    e.showBool = true;
                                    e.loading = true;
                                    this.schedulerService.getWCSiteList(this.reportId, this.clientId).subscribe((response: any) => {
                                      e.loading = false;
                                      response?.body?.forEach(c => {
                                        c.label = + c.siteCode + '-' + c.siteDesc;
                                        c.value = c.siteCode;
                                        c.selected = false;
                                      })
                                      e.options = response?.body;
                                    })

                                  }
                                  if (e.id.toLowerCase() == 'subgroup') {
                                    e.selectedData = [];
                                    this.parameters[e.label] = '';
                                    e.options = [];
                                    e.showBool = true;
                                    e.loading = true;
                                    let updatedArray = []
                                    this.schedulerService.getWCSubgroup(this.reportId, this.clientId).subscribe((response: any) => {
                                      e.loading = false;
                                      response?.body?.forEach(c => {
                                        if (c.displayName !== null && c.value !== null) {
                                          updatedArray.push({
                                            label: c.displayName ? c.displayName : '',
                                            value: c.value,
                                            selected: false
                                          });
                                        }
                                      });
                                      e.options = updatedArray;
                                    })
                                  }
                                  if (e.id.toLowerCase() == 'policies') { e.selectedData = []; e.showBool = false; delete this.parameters[e.id]; }
                                  if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroupvalues') { e.selectedData = []; e.showBool = false; delete this.parameters[e.id]; }
                                })
                              }
                            }
                            if (e.id.toLowerCase() == 'subgroup') {
                              e.selectedData = [];
                              this.parameters[e.label] = '';
                              e.options = [];
                              e.showBool = true;
                              e.loading = true;
                              let updatedArray = []
                              this.schedulerService.getWCSubgroup(this.reportId, this.clientId).subscribe((response: any) => {
                                e.loading = false;
                                response?.body?.forEach(c => {
                                  if (c.displayName !== null && c.value !== null) {
                                    updatedArray.push({
                                      label: c.displayName ? c.displayName : '',
                                      value: c.value,
                                      selected: false
                                    });
                                  }
                                });
                                e.options = updatedArray;
                                if (this.restricted) {
                                  e.mandatory = true;
                                  e.selectedData = e.options[0].value;
                                  e.showBool = true;
                                  e.disabled = true;
                                  this.parameters[e.id] = e.options[0].value;
                                  this.SubGroupId = e.options[0].value;
                                  e.paraBool = false;
                                }
                              })
                            }
                            if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroupvalues') {
                              e.showBool = true;
                              e.loading = true;
                              let updatedArray = [];
                              const selectedValues=[];
                              this.schedulerService.getSubGroupValue(this.reportId, this.clientId, this.SubGroupId, this.SelectedRequestedPage).subscribe((response: any) => {
                                e.loading = false;
                                response?.body?.forEach(c => {
                                  if (c.displayName !== null && c.value !== null) {
                                    updatedArray.push({
                                      label: c.displayName ? c.displayName : '',
                                      value: c.value,
                                      selected: this.restricted ? true : false
                                    });
                                    selectedValues.push(c.value)
                                  }
                                });
                                e.options = updatedArray;
                                if (this.restricted) {
                                  e.mandatory = true;
                                  e.options = updatedArray;
                                  e.selectedData = selectedValues;
                                  this.parameters[e.id] = selectedValues;
                                  e.paraBool = false;
                                }
                              })
                            }

                          }

                        })

                      }
                      setTimeout(() => {
                        this.isLoading1 = false;
                        this.getData();
                      }, 9000);
                    }
                    else if (this.SelectedRequestedPage == 'WC_All') {
                      for (let key in this.DataToEdit.parameters) {
                        if (key.toLowerCase() == 'clients') {
                          this.clientId = this.DataToEdit.parameters[key];
                        }
                        if (key.toLowerCase() == 'subgroup') {
                          this.SubGroupId = this.DataToEdit.parameters[key];
                          if (this.DataToEdit.parameters[key]!=null && this.DataToEdit.parameters[key].toLowerCase() == 'restricted') { this.restricted = true; }
                        }
                        if (key.toLowerCase().replace(/[_ ]/g, '') == 'runreportby') {
                          this.runReport2 = this.DataToEdit.parameters[key];
                        }

                      }
                      for (let key in this.DataToEdit.parameters) {
                        this.mainData.forEach(e => {
                          if (e.id == key) {
                            if (e.id.toLowerCase() == 'clients') {
                              e.showBool = true;
                              e.loading = true;
                              this.schedulerService.getWcClient(this.reportId).subscribe((response: any) => {
                                e.loading = false;
                                response?.body?.forEach(c => {
                                  c.value = c.clientCode;
                                  delete c.clientCode;
                                  c.label = c.clientName;
                                  delete c.clientName;
                                  c.selected = false;
                                })
                                e.options = response?.body;

                              })
                            }
                            if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'runreportby') {
                              if (this.DataToEdit.parameters[key].toLowerCase().replace(/[_ ]/g, '') === 'allpolicy') {
                                this.mainData.filter(e => {
                                  if (e.id.toLowerCase() == 'subgroup') {
                                    e.selectedData = [];
                                    this.parameters[e.label] = '';
                                    e.options = [];
                                    e.showBool = true;
                                    e.loading = true;
                                    let updatedArray = []
                                    this.schedulerService.getWCSubgroup(this.reportId, this.clientId).subscribe((response: any) => {
                                      e.loading = false;
                                      response?.body?.forEach(c => {
                                        if (c.displayName !== null && c.value !== null) {
                                          updatedArray.push({
                                            label: c.displayName ? c.displayName : '',
                                            value: c.value,
                                            selected: false
                                          });
                                        }
                                      });
                                      e.options = updatedArray;
                                      if (this.restricted) {
                                        e.mandatory = true;
                                        e.selectedData = e.options[0].value;
                                        e.showBool = true;
                                        e.disabled = true;
                                        this.parameters[e.id] = e.options[0].value;
                                        this.SubGroupId = e.options[0].value;
                                        e.paraBool = false;
                                      }
                                    })
                                  }
                                  if (e.id.toLowerCase() == 'policies') { e.selectedData = []; e.showBool = false; delete this.parameters[e.id]; }
                                })
                              }
                              if (this.DataToEdit.parameters[key].toLowerCase().replace(/[_ ]/g, '') === 'policyonly') {
                                this.mainData.filter(e => {
                                  if (e.id.toLowerCase() == 'policies') {
                                    e.selectedData = [];
                                    e.showBool = true;
                                    e.loading = true;
                                    this.schedulerService.getWCPolicyList(this.reportId, this.clientId).subscribe((response: any) => {
                                      e.loading = false;
                                      response?.body?.forEach(c => {
                                        c.value = c.policyNumber;
                                        c.label = c.policyNumber + '-' + c.effectiveDate + '-' + c.expiryDate;
                                        c.selected = false;
                                      })
                                      e.options = response?.body;
                                    })
                                  }
                                  if (e.id.toLowerCase() == 'subgroup') {
                                    e.selectedData = [];
                                    this.parameters[e.label] = '';
                                    e.options = [];
                                    e.showBool = true;
                                    e.loading = true;
                                    let updatedArray = []
                                    this.schedulerService.getWCSubgroup(this.reportId, this.clientId).subscribe((response: any) => {
                                      e.loading = false;
                                      response?.body?.forEach(c => {
                                        if (c.displayName !== null && c.value !== null) {
                                          updatedArray.push({
                                            label: c.displayName ? c.displayName : '',
                                            value: c.value,
                                            selected: false
                                          });
                                        }
                                      });
                                      e.options = updatedArray;
                                      if (this.restricted) {
                                        e.mandatory = true;
                                        e.selectedData = e.options[0].value;
                                        e.showBool = true;
                                        e.disabled = true;
                                        this.parameters[e.id] = e.options[0].value;
                                        this.SubGroupId = e.options[0].value;
                                        e.paraBool = false;
                                      }
                                    })
                                  }
                                  if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroupvalues') { e.selectedData = []; e.showBool = false; delete this.parameters[e.id]; }
                                })
                              }
                            }
                            // if (e.id.toLowerCase() == 'policies') {
                            //   e.selectedData = [];
                            //   e.showBool = true;
                            //   e.loading = true;
                            //   this.schedulerService.getWCPolicyList(this.reportId, this.clientId).subscribe((response: any) => {
                            //     e.loading = false;
                            //     response?.body?.forEach(c => {
                            //       c.value = c.policyNumber;
                            //       c.label = c.policyNumber + '-' + c.effectiveDate + '-' + c.expiryDate;
                            //       c.selected = false;
                            //     })
                            //     e.options = response?.body;
                            //   })
                            // }
                            // if (e.id.toLowerCase() == 'subgroup') {
                            //   e.selectedData = [];
                            //   this.parameters[e.label] = '';
                            //   e.options = [];
                            //   e.showBool = true;
                            //   e.loading = true;
                            //   let updatedArray = []
                            //   this.schedulerService.getWCSubgroup(this.reportId, this.clientId).subscribe((response: any) => {
                            //     e.loading = false;
                            //     response?.body?.forEach(c => {
                            //       c.label = c.displayName;
                            //       delete c.displayName;
                            //       c.selected = false;
                            //     })
                            //     e.options = response?.body;
                            //   })
                            // }
                            if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroupvalues') {
                              e.showBool = true;
                              e.loading = true;
                              let updatedArray = [];
                              const selectedValues=[]
                              this.schedulerService.getSubGroupValue(this.reportId, this.clientId, this.SubGroupId, this.SelectedRequestedPage).subscribe((response: any) => {
                                e.loading = false;
                                response?.body?.forEach(c => {
                                  if (c.displayName !== null && c.value !== null) {
                                    updatedArray.push({
                                      label: c.displayName ? c.displayName : '',
                                      value: c.value,
                                      selected: this.restricted ? true : false
                                    });
                                    selectedValues.push(c.value)
                                  }
                                });
                                e.options = updatedArray;
                                if (this.restricted) {
                                  e.mandatory = true;
                                  e.options = updatedArray;
                                  e.selectedData = selectedValues;
                                  this.parameters[e.id] = selectedValues;
                                  e.paraBool = false;
                                }
                              })
                            }

                          }

                        })

                      }
                      setTimeout(() => {
                        this.isLoading1 = false;
                        this.getData();
                      }, 9000);
                    }
                    else if (this.SelectedRequestedPage == 'BCD') {
                      for (let key in this.DataToEdit.parameters) {
                        if (key.toLowerCase().replace(/[_ ]/g, '') == 'clientnames') {
                          this.clientId = this.DataToEdit.parameters[key];
                        }
                        if (key.toLowerCase() == 'subgroup') {
                          this.SubGroupId = this.DataToEdit.parameters[key];
                          if (this.DataToEdit.parameters[key]!=null && this.DataToEdit.parameters[key].toLowerCase() == 'restricted') { this.restricted = true; }
                        }
                      }
                      for (let key in this.DataToEdit.parameters) {
                        this.mainData.forEach(e => {
                          if (e.id == key) {
                            if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'clientnames') {
                              e.showBool = true;
                              e.loading = true;
                              this.schedulerService.getBCDclient(this.reportId).subscribe((response: any) => {
                                e.loading = false;
                                response?.body?.forEach(c => {
                                  c.value = c.clientCode;
                                  delete c.clientCode;
                                  c.label = c.clientName;
                                  delete c.clientName;
                                  c.selected = false;
                                })
                                e.options = response?.body;
                              })
                            }
                            if (e.id.toLowerCase() == 'subgroup') {
                              let updatedArray = [];
                              this.SubGroupId = this.DataToEdit.parameters[key];
                              e.showBool = true;
                              e.loading = true;
                              this.schedulerService.getBCDsubgroup(this.clientId, this.reportId).subscribe((response: any) => {
                                e.loading = false;
                                response?.body?.forEach(c => {
                                  if (c.displayName !== null && c.value !== null) {
                                    updatedArray.push({
                                      label: c.displayName ? c.displayName : '',
                                      value: c.value,
                                      selected: false
                                    });
                                  }
                                });
                                e.options = updatedArray;
                                if (this.restricted) {
                                  e.mandatory = true;
                                  e.selectedData = e.options[0].value;
                                  e.showBool = true;
                                  e.disabled = true;
                                  this.parameters[e.id] = e.options[0].value;
                                  this.SubGroupId = e.options[0].value;
                                  e.paraBool = false;
                                }
                              })
                            }
                            if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'loapolicy') {
                              let updatedArray = [];
                              // this.SubGroupId = this.DataToEdit.parameters[key];
                              e.showBool = true;
                              e.loading = true;
                              this.schedulerService.getBcdLOApolicy(this.clientId, this.reportId).subscribe((response: any) => {
                                e.loading = false;
                                response?.body?.forEach((c: any) => {
                                  updatedArray.push({
                                    label: c,
                                    value: c,
                                    selected: false
                                  });
                                });
                                e.options = updatedArray;
                              })
                            }
                            if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'stdpolicy') {
                              let updatedArray = [];
                              // this.SubGroupId = this.DataToEdit.parameters[key];
                              e.showBool = true;
                              e.loading = true;
                              this.schedulerService.getBcdSTDpolicy(this.clientId, this.reportId).subscribe((response: any) => {
                                e.loading = false;
                                response?.body?.forEach((c: any) => {
                                  updatedArray.push({
                                    label: c,
                                    value: c,
                                    selected: false
                                  });
                                });
                                e.options = updatedArray;
                              })
                            }
                            if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'ltdpolicy') {
                              let updatedArray = [];
                              // this.SubGroupId = this.DataToEdit.parameters[key];
                              e.showBool = true;
                              e.loading = true;
                              this.schedulerService.getBcdLTDpolicy(this.clientId, this.reportId).subscribe((response: any) => {
                                e.loading = false;
                                response?.body?.forEach((c: any) => {
                                  updatedArray.push({
                                    label: c,
                                    value: c,
                                    selected: false
                                  });
                                });
                                e.options = updatedArray;
                              })
                            }
                            if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'wcpolicy') {
                              let updatedArray = [];
                              // this.SubGroupId = this.DataToEdit.parameters[key];
                              e.showBool = true;
                              e.loading = true;
                              this.schedulerService.getBcdWCpolicy(this.clientId, this.reportId).subscribe((response: any) => {
                                e.loading = false;
                                response?.body?.forEach((c: any) => {
                                  updatedArray.push({
                                    label: c,
                                    value: c,
                                    selected: false
                                  });
                                });
                                e.options = updatedArray;
                              })
                            }
                            if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroupvalues') {
                              e.showBool = true;
                              e.loading = true;
                              let updatedArray = [];
                              const selectedValues=[]
                              this.schedulerService.getBCDsubgroupValues(this.clientId, this.reportId, this.SubGroupId).subscribe((response: any) => {
                                e.loading = false;
                                response?.body?.forEach(c => {
                                  if (c !== null) {
                                    updatedArray.push({
                                      label: c,
                                      value: c,
                                      selected: this.restricted ? true : false
                                    });
                                    selectedValues.push(c.value)
                                  }
                                });
                                e.options = updatedArray;
                                if (this.restricted) {
                                  e.mandatory = true;
                                  e.options = updatedArray;
                                  e.selectedData = selectedValues;
                                  this.parameters[e.id] = selectedValues;
                                  e.paraBool = false;
                                }
                              })
                            }
                          }

                        })

                      }
                      setTimeout(() => {
                        this.isLoading1 = false;
                        this.getData();
                      }, 9000);
                    }
                    else if (this.SelectedRequestedPage == 'CD3') {
                      for (let key in this.DataToEdit.parameters) {
                        if (key.toLowerCase().replace(/[_ ]/g, '') == 'clientnames') {
                          this.clientId = this.DataToEdit.parameters[key];
                        }
                        if (key.toLowerCase() == 'subgroup') {
                          this.SubGroupId = this.DataToEdit.parameters[key];
                          if (this.DataToEdit.parameters[key]!=null && this.DataToEdit.parameters[key].toLowerCase() == 'restricted') { this.restricted = true; }
                        }
                      }
                      for (let key in this.DataToEdit.parameters) {
                        this.mainData.forEach(e => {
                          if (e.id == key) {
                            if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'clientnames') {
                              e.showBool = true;
                              e.loading = true;
                              this.schedulerService.getCD3Clients(this.reportId).subscribe((response: any) => {
                                e.loading = false;
                                response?.body?.forEach(c => {
                                  c.value = c.clientCode;
                                  delete c.clientCode;
                                  c.label = c.clientName;
                                  delete c.clientName;
                                  c.selected = false;
                                })
                                e.options = response?.body;
                              })
                            }
                            if (e.id.toLowerCase() == 'subgroup') {
                              let updatedArray = [];
                              this.SubGroupId = this.DataToEdit.parameters[key];
                              e.showBool = true;
                              e.loading = true;
                              this.schedulerService.getCD3Subgroup(this.reportId, this.clientId).subscribe((response: any) => {
                                e.loading = false;
                                response?.body?.forEach(c => {
                                  if (c.displayName !== null && c.value !== null) {
                                    updatedArray.push({
                                      label: c.displayName ? c.displayName : '',
                                      value: c.value,
                                      selected: false
                                    });
                                  }
                                });
                                e.options = updatedArray;
                                if (this.restricted) {
                                  e.mandatory = true;
                                  e.selectedData = e.options[0].value;
                                  e.showBool = true;
                                  e.disabled = true;
                                  this.parameters[e.id] = e.options[0].value;
                                  this.SubGroupId = e.options[0].value;
                                  e.paraBool = false;
                                }
                              })
                            }
                            if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'loapolicy') {
                              let updatedArray = [];
                              // this.SubGroupId = this.DataToEdit.parameters[key];
                              e.showBool = true;
                              e.loading = true;
                              this.schedulerService.getCD3LoaPolicy(this.reportId, this.clientId).subscribe((response: any) => {
                                e.loading = false;
                                response?.body?.forEach((c: any) => {
                                  updatedArray.push({
                                    label: c,
                                    value: c,
                                    selected: false
                                  });
                                });
                                e.options = updatedArray;
                              })
                            }
                            if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'stdpolicy') {
                              let updatedArray = [];
                              // this.SubGroupId = this.DataToEdit.parameters[key];
                              e.showBool = true;
                              e.loading = true;
                              this.schedulerService.getCD3StdPolicy(this.reportId, this.clientId).subscribe((response: any) => {
                                e.loading = false;
                                response?.body?.forEach((c: any) => {
                                  updatedArray.push({
                                    label: c,
                                    value: c,
                                    selected: false
                                  });
                                });
                                e.options = updatedArray;
                              })
                            }
                            if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'ltdpolicy') {
                              let updatedArray = [];
                              // this.SubGroupId = this.DataToEdit.parameters[key];
                              e.showBool = true;
                              e.loading = true;
                              this.schedulerService.getCD3LtdPolicy(this.reportId, this.clientId).subscribe((response: any) => {
                                e.loading = false;
                                response?.body?.forEach((c: any) => {
                                  updatedArray.push({
                                    label: c,
                                    value: c,
                                    selected: false
                                  });
                                });
                                e.options = updatedArray;
                              })
                            }
                            if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'wcpolicy') {
                              let updatedArray = [];
                              // this.SubGroupId = this.DataToEdit.parameters[key];
                              e.showBool = true;
                              e.loading = true;
                              this.schedulerService.getCD3WcPolicy(this.reportId, this.clientId).subscribe((response: any) => {
                                e.loading = false;
                                response?.body?.forEach((c: any) => {
                                  updatedArray.push({
                                    label: c,
                                    value: c,
                                    selected: false
                                  });
                                });
                                e.options = updatedArray;
                              })
                            }
                            if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroupvalues') {
                              e.showBool = true;
                              e.loading = true;
                              let updatedArray = [];
                              const selectedValues=[];
                              this.schedulerService.getCD3SubgroupVlaue(this.reportId, this.clientId, this.SubGroupId, this.SelectedRequestedPage).subscribe((response: any) => {
                                e.loading = false;
                                response?.body?.forEach(c => {
                                  if (c !== null) {
                                    updatedArray.push({
                                      label: c,
                                      value: c,
                                      selected: this.restricted ? true : false
                                    });
                                    selectedValues.push(c.value)
                                  }
                                });
                                e.options = updatedArray;
                                if (this.restricted) {
                                  e.mandatory = true;
                                  e.options = updatedArray;
                                  e.selectedData = selectedValues;
                                  this.parameters[e.id] = selectedValues;
                                  e.paraBool = false;
                                }
                              })
                            }
                          }

                        })

                      }
                      setTimeout(() => {
                        this.isLoading1 = false;
                        this.getData();
                      }, 9000);
                    }
                    else if (this.SelectedRequestedPage == 'PCR') {
                      for (let key in this.DataToEdit.parameters) {
                        if (key.toLowerCase().replace(/[_ ]/g, '') == 'clientcode') {
                          this.clientId = this.DataToEdit.parameters[key];
                        }
                        if (key.toLowerCase() == 'lineofbusiness') {
                          this.SubGroupId = this.DataToEdit.parameters[key];
                        }
                      }
                      for (let key in this.DataToEdit.parameters) {
                        this.mainData.forEach(e => {
                          if (e.id == key) {
                            if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'clientcode') {
                              e.showBool = true;
                              e.loading = true;
                              this.schedulerService.getPCRclient(this.reportId).subscribe((response: any) => {
                                e.loading = false;
                                response?.body?.forEach(c => {
                                  c.value = c.clientCode;
                                  delete c.clientCode;
                                  c.label = c.clientName;
                                  delete c.clientName;
                                  c.selected = false;
                                })
                                e.options = response?.body;
                              })
                            }
                            if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'lineofbusiness') {
                              e.selectedData = [];
                              this.parameters[e.label] = '';
                              e.options = [];
                              e.showBool = true;
                              e.loading = true
                              this.clientFlag = "C";
                              this.parentFlag = "Y";
                              let updatedArray = [];
                              this.schedulerService.getPcrLOBList(this.reportId, this.clientId, this.clientFlag, this.parentFlag).subscribe((response: any) => {
                                e.loading = false;
                                response?.body?.forEach((c: any) => {
                                  updatedArray.push({
                                    label: c,
                                    value: c,
                                    selected: false
                                  });
                                });
                                e.options = updatedArray;
                              })//e.id.toLowerCase() == 'policy'||e.id.toLowerCase() == 'class'||e.id.toLowerCase() == 'plan type'||e.id.toLowerCase()=='policy type'
                            }
                            if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'bankaccountnumber') {
                              e.showBool = true;
                              e.loading = true
                              this.schedulerService.getPcrBANList(this.reportId, this.clientId, this.SubGroupId).subscribe((response: any) => {
                                e.loading = false;
                                response?.body?.forEach(c => {
                                  c.label = c.displayName;
                                  delete c.displayName;
                                  c.selected = false;
                                })
                                e.options = response?.body;
                              })
                            }
                          }

                        })

                      }
                      setTimeout(() => {
                        this.isLoading1 = false;
                        this.getData();
                      }, 10000);
                    }
                    else if (this.SelectedRequestedPage == 'SC') {
                      for (let key in this.DataToEdit.parameters) {
                        if (key.toLowerCase().replace(/[_ ]/g, '') == 'clientnames') {
                          this.clientId = this.DataToEdit.parameters[key];
                        }
                        if (key.toLowerCase() == 'subgroup') {
                          this.SubGroupId = this.DataToEdit.parameters[key];
                          if (this.DataToEdit.parameters[key]!=null && this.DataToEdit.parameters[key].toLowerCase() == 'restricted') { this.restricted = true; }
                        }
                      }
                      for (let key in this.DataToEdit.parameters) {
                        this.mainData.forEach(e => {
                          if (e.id == key) {
                            if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'clientnames') {
                              e.showBool = true;
                              e.loading = true;
                              this.schedulerService.getSCclient(this.reportId).subscribe((response: any) => {
                                e.loading = false;
                                response?.body?.forEach(c => {
                                  c.value = c.clientCode;
                                  delete c.clientCode;
                                  c.label = c.clientName;
                                  delete c.clientName;
                                  c.selected = false;
                                })
                                e.options = response?.body;
                              })
                            }
                            if (e.id.toLowerCase() == 'subgroup') {
                              let updatedArray = [];
                              this.SubGroupId = this.DataToEdit.parameters[key];
                              e.showBool = true;
                              e.loading = true;
                              this.schedulerService.getSCsubgroup(this.reportId, this.clientId).subscribe((response: any) => {
                                e.loading = false;
                                response?.body?.forEach(c => {
                                  if (c.displayName !== null && c.value !== null) {
                                    updatedArray.push({
                                      label: c.displayName ? c.displayName : '',
                                      value: c.value,
                                      selected: false
                                    });
                                  }
                                });
                                e.options = updatedArray;
                                if (this.restricted) {
                                  e.mandatory = true;
                                  e.selectedData = e.options[0].value;
                                  e.showBool = true;
                                  e.disabled = true;
                                  this.parameters[e.id] = e.options[0].value;
                                  this.SubGroupId = e.options[0].value;
                                  e.paraBool = false;
                                }
                              })
                            }
                            if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'loapolicy') {
                              let updatedArray = [];
                              // this.SubGroupId = this.DataToEdit.parameters[key];
                              e.showBool = true;
                              e.loading = true;
                              this.schedulerService.getScLOApolicy(this.reportId, this.clientId).subscribe((response: any) => {
                                e.loading = false;
                                response?.body?.forEach((c: any) => {
                                  updatedArray.push({
                                    label: c,
                                    value: c,
                                    selected: false
                                  });
                                });
                                e.options = updatedArray;
                              })
                            }
                            if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'stdpolicy') {
                              let updatedArray = [];
                              // this.SubGroupId = this.DataToEdit.parameters[key];
                              e.showBool = true;
                              e.loading = true;
                              this.schedulerService.getScSTDpolicy(this.reportId, this.clientId).subscribe((response: any) => {
                                e.loading = false;
                                response?.body?.forEach((c: any) => {
                                  if (c.displayName !== null && c.value !== null) {
                                    updatedArray.push({
                                      label: c.displayName ? c.displayName : '',
                                      value: c.value,
                                      selected: false
                                    });
                                  }
                                });
                                e.options = updatedArray;
                              })
                            }
                            if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'ltdpolicy') {
                              let updatedArray = [];
                              // this.SubGroupId = this.DataToEdit.parameters[key];
                              e.showBool = true;
                              e.loading = true;
                              this.schedulerService.getScLTDpolicy(this.reportId, this.clientId).subscribe((response: any) => {
                                e.loading = false;
                                response?.body?.forEach((c: any) => {
                                  if (c.displayName !== null && c.value !== null) {
                                    updatedArray.push({
                                      label: c.displayName ? c.displayName : '',
                                      value: c.value,
                                      selected: false
                                    });
                                  }
                                });
                                e.options = updatedArray;
                              })
                            }
                            if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroupvalues') {
                              e.showBool = true;
                              e.loading = true;
                              let updatedArray = [];
                              const selectedValues=[];
                              this.schedulerService.getSCsubgroupValues(this.reportId, this.clientId, this.SubGroupId).subscribe((response: any) => {
                                e.loading = false;
                                response?.body?.forEach(c => {
                                  if (c !== null) {
                                    updatedArray.push({
                                      label: c,
                                      value: c,
                                      selected: this.restricted ? true : false
                                    });
                                    selectedValues.push(c.value)
                                  }
                                });
                                e.options = updatedArray;
                                if (this.restricted) {
                                  e.mandatory = true;
                                  e.options = updatedArray;
                                  e.selectedData = selectedValues;
                                  this.parameters[e.id] = selectedValues;
                                  e.paraBool = false;
                                }
                              })
                            }
                          }

                        })

                      }
                      setTimeout(() => {
                        this.isLoading1 = false;
                        this.getData();
                      }, 9000);
                    }
                    else if (this.SelectedRequestedPage == 'PACS_All_Vendors2') {
                      for (let key in this.DataToEdit.parameters) {
                        if (key.toLowerCase().replace(/[_ ]/g, '') == 'clients') {
                          this.clientId = this.DataToEdit.parameters[key];
                        }
                      }
                      for (let key in this.DataToEdit.parameters) {
                        this.mainData.forEach(e => {
                          if (e.id == key) {
                            if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'clients') {
                              e.showBool = true;
                              e.loading = true;
                              this.schedulerService.getSCclient(this.reportId).subscribe((response: any) => {
                                e.loading = false;
                                response?.body?.forEach(c => {
                                  c.value = c.clientCode;
                                  delete c.clientCode;
                                  c.label = c.clientName;
                                  delete c.clientName;
                                  c.selected = false;
                                })
                                e.options = response?.body;
                              })
                            }
                            if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'policy') {
                              e.selectedData = [];
                              this.parameters[e.label] = '';
                              e.options = [];
                              e.showBool = true;
                              e.loading = true
                              let updatedArray = [];
                              this.schedulerService.getPACSV2policyt(this.reportId, this.clientId).subscribe((response: any) => {
                                e.loading = false;
                                response?.body?.forEach((c: any) => {
                                  updatedArray.push({
                                    label: c.policyNumber + " - " + c.policyType + " - " + c.effectiveDate + " - " + c.expiryDate,
                                    value: c.policyNumber + "|" + c.policyBON,
                                    selected: false
                                  });
                                });
                                e.options = updatedArray;
                              })
                            }
                          }

                        })

                      }
                      setTimeout(() => {
                        this.isLoading1 = false;
                        this.getData();
                      }, 9000);
                    }
                    this.parameterElementsArray.forEach(item => {
                      if (item.slaveDependencies?.length > 0) {
                        this.cascade = true;
                        // this.isLoading1=true;
                        for (const k in this.DataToEdit.parameters) {
                          this.parameterElementsArray.forEach(e => {
                            if (e.id == k) {
                              if (e.slaveDependencies.length > 0) {

                                this.cascadeFunc(k, this.DataToEdit.parameters[k]);

                              }
                            }
                          });
                        }
                      }
                    })
                  }
                  else {
                    setTimeout(() => {
                      this.isLoading1 = false;
                      this.getData();
                    }, 6000);
                  }
                }
              });
            this.isLoading = false;
          }
        },
        (error) => {
          this.alertSeverity = 'error';
          if (Number(error.status) == 401) {
            this.alertMsg = 'Please Try After Some Time!';
          } else if (Number(error.status) == 500) {
            this.alertMsg = 'Internal Error';
          } else if (Number(error.status) == 304) {
            this.alertMsg = 'Report Parameter Not Found';
            this.mainData = [];
          } else {
            this.alertMsg = 'Please Try After Some Time.';
          }
          this.addSingle();
        },
        () => { }
      );

    // );
  }
  getSftp(id) {
    this.ShowSftpSetting = false;
    this.ShowSftpSetting1 = false;
    this.isSftp = false;
    this.isPassword = false;
    this.pgpEncryption = false;
    this.sftpConfigId = null;
    this.showSftp = false;
    this.showPassword = false;
    // if (label.toLowerCase().replace(/[_ ]/g, '') == "clicode" || label.toLowerCase().replace(/[_ ]/g, '') == "client" || label.toLowerCase().replace(/[_ ]/g, '') == "clients" || label.toLowerCase().replace(/[_ ]/g, '') == "clientnames" || label.toLowerCase().replace(/[_ ]/g, '') == "clientcode" || label.toLowerCase().replace(/[_ ]/g, '') == "clientname") {
    //   let dat = this.mainData[index].options.filter(
    //     (t) => t.value === event);
    //   let val = dat[0]?.value
    this.schedulerService.getSftpConfig(id).subscribe((res: any) => {
      if (res && res.body.length > 0) {
        this.ShowSftpSetting = true;
        this.folderPath = res?.body?.sftpInDirectoryPath || "";
        this.serverName = res?.body?.sftpHostName || "";
        this.ShowSftpSetting1 = this.folderPath.trim() !== "" || this.serverName.trim() !== "";
        if (res?.body?.isSftp == 'Y') { this.isSftp = true; this.showSftp = true } else { this.isSftp = false; this.showSftp = false };
        if (res?.body?.isPasswordProtect == 'Y') { this.isPassword = true; this.showPassword = true } else { this.isPassword = false; this.showPassword = false };
        if (res?.body?.isSftpPgpEncrypt == 'Y') { this.pgpEncryption = true } else { this.pgpEncryption = false };
        this.sftpConfigId = res?.body?.sftpConfigId;
      }
      else {
        this.ShowSftpSetting = false;
        this.ShowSftpSetting1 = false;
        this.isSftp = false;
        this.isPassword = false;
        this.pgpEncryption = false;
        this.sftpConfigId = null;
        this.showSftp = false;
        this.showPassword = false;
      }
    })
  }
  getData() {
    this.mainData.forEach((d, i) => {
      for (let key in this.DataToEdit.parameters) {
        if (d.id == key) {
          if (key == this.paramDates[0]?.id) {
            if (this.DataToEdit.parameters[key] == '') {
              d.options = ''
              this.parameters[key] =
                this.DataToEdit.parameters[key];
              this.mainData[i].selectedData[0] = ''
            } else {
              d.options = new Date(
                this.DataToEdit.parameters[key]
              );
              delete this.parameters[this.key1];
              this.isDisabled = true;
              this.Disabled = false;
              // (
              //   document.getElementById(
              //     d.id
              //   ) as HTMLInputElement
              // ).value = this.datePipe.transform(
              //   d.options,
              //   'YYYY-MM-dd'
              // );
              this.parameters[key] =
                this.DataToEdit.parameters[key];
              this.mainData[i].selectedData[0] = new Date(
                this.DataToEdit.parameters[key]
              );
              //   this.minDate=new Date(
              //     this.DataToEdit.parameters[key]
              // );
            }
          }
          else if (key == this.paramDates[1]?.id) {
            if (this.DataToEdit.parameters[key] == '') {
              d.options = ''
              this.parameters[key] =
                this.DataToEdit.parameters[key];
              this.mainData[i].selectedData[0] = ''
            } else {
              d.options = new Date(
                this.DataToEdit.parameters[key]
              );
              delete this.parameters[this.key1];
              this.isDisabled = true;
              this.Disabled = false;
              // (
              //   document.getElementById(
              //     d.id
              //   ) as HTMLInputElement
              // ).value = this.datePipe.transform(
              //   d.options,
              //   'YYYY-MM-dd'
              // );
              this.parameters[key] =
                this.DataToEdit.parameters[key];
              this.mainData[i].selectedData[0] = new Date(
                this.DataToEdit.parameters[key]);
              // this.maxDate=new Date(
              //   this.DataToEdit.parameters[key]
              // );
              this.mainData[i].selectBool = true;
            }
          }
          else if (d.type == 'date' && key != this.paramDates[0]?.id && key != this.paramDates[1]?.id) {
            if (this.DataToEdit.parameters[key] == '') {
              d.options = ''
              this.parameters[key] =
                this.DataToEdit.parameters[key];
              this.mainData[i].selectedData[0] = ''
            } else {
              d.options = new Date(
                this.DataToEdit.parameters[key]
              );
              //delete this.parameters[this.key1];
              this.isDisabled = true;
              this.Disabled = false;
              // (
              //   document.getElementById(
              //     d.id
              //   ) as HTMLInputElement
              // ).value = this.datePipe.transform(
              //   d.options,
              //   'YYYY-MM-dd'
              // );
              this.parameters[key] =
                this.DataToEdit.parameters[key];
              this.mainData[i].selectedData[0] = new Date(
                this.DataToEdit.parameters[key]);
              this.mainData[i].selectBool = true;
            }
          }
          else if (d.type == 'singleSelectRadio') {
            let data = {};
            d.options?.forEach((o) => {
              // if(this.DataToEdit.parameters[key].join("") == "Policy"){
              //   if (o.value == this.DataToEdit.parameters[key].join("")){
              //     o.selected = true;
              //     data = o;
              //   }
              // }
              // else if (o.value == this.DataToEdit.parameters[key]){
              //   o.selected = true;
              //   data = o;
              // }
              // else {
              //   o.selected = false;
              // }
              // if (Array.isArray(this.DataToEdit.parameters[key])) {
              //   if (this.DataToEdit.parameters[key].join("") == "Policy") {
              //     if (o.value == this.DataToEdit.parameters[key].join("")) {
              //       o.selected = true;
              //       data = o;
              //     }
              //   } else {
              //     o.selected = false;
              //   }
              // } else {
                if (o.value == this.DataToEdit.parameters[key]) {
                  o.selected = true;
                  data = o.value;
                } else {
                  o.selected = false;
                }
              // }
            });

            this.parameters[key] =
              this.DataToEdit.parameters[key];
            this.mainData[i].selectedData = data;
            this.mainData[i].selectBool = true;

          }
          else if (d.type == 'multiSelect') {
            if(this.DataToEdit.parameters[key]!='' || this.DataToEdit.parameters[key].length>0){
            let data = {};
            d.options?.forEach((o) => {
              this.DataToEdit.parameters[key].forEach(e => {
                if (o.value == e) {
                  o.selected = true;
                  // data=o;
                } else {
                  o.selected = false;
                }
              })
            });

            this.parameters[key] =
              this.DataToEdit.parameters[key];
            this.mainData[i].selectedData = this.DataToEdit.parameters[key];
            this.mainData[i].selectBool = true;
          }
          }
          else if (d.type == 'singleSelect') {
            if (key.replace('_', '').replace(' ', '').toLowerCase() == 'daterange') {
              d.options.filter((o) => {
                if (o.value == this.DataToEdit.parameters[key]) {
                  o.selected = true;
                } else {
                  o.selected = false;
                }
              });
              this.paramDates.forEach(e => {
                delete this.parameters[e.id];
              })
              // delete this.parameters['FromDate'];
              // delete this.parameters['ThruDate'];
              this.isDisabled = false;
              this.Disabled = true;
              this.parameters[key] =
                this.DataToEdit.parameters[key];
              this.mainData[i].selectedData =
                this.DataToEdit.parameters[key];
              this.mainData[i].selectBool = true;
            }
            else {
              let data = {};
              d.options?.filter((o) => {
                if (o.value == this.DataToEdit.parameters[key]) {
                  o.selected = true;
                  data = o;
                } else {
                  o.selected = false;
                }
              });
              this.parameters[key] =
                this.DataToEdit.parameters[key];
              this.mainData[i].selectedData =
                this.DataToEdit.parameters[key];
              this.mainData[i].selectBool = true;
            }
          }
          else if (d.type == 'combobox') {
            d.options?.forEach((o) => {
              if (o.value == this.DataToEdit.parameters[key]) {
                o.selected = true;
              } else {
                o.selected = false;
              }
            });
            this.parameters[key] =
              this.DataToEdit.parameters[key];
            this.mainData[i].selectedData =
              this.DataToEdit.parameters[key];
            this.mainData[i].selectBool = true;
          }
          else if (d.type == 'singleValueText' || d.type == 'singleValueNumber') {
            if (this.DataToEdit.parameters[key] == '') {
              d.options = ''
              this.parameters[key] =
                this.DataToEdit.parameters[key];
              this.mainData[i].selectedData = ''
            } else {
              d.options = this.DataToEdit.parameters[key];
              this.parameters[key] =
                this.DataToEdit.parameters[key];
              this.mainData[i].selectedData = this.DataToEdit.parameters[key];
              this.mainData[i].selectBool = true;
            }
          }
        }
      }
    });
    if (this.Type == 'Edit' || 'Copy') {
      this.mainData.forEach((d, i) => {
        if (d.mandatory) {
          if (d.id == this.paramDates[0]?.id) {
            var x = (
              document.getElementById(
                d.id
              ) as HTMLInputElement
            ).value;
            if (x == '') {
              this.mainData[i].selected = false;
              this.mainData[i].paraBool = true;
              this.mainData[i].selectBool = false;
            } else {
              this.mainData[i].selected = true;
              this.mainData[i].paraBool = false;
              this.mainData[i].selectBool = true;

            }
          } else if (d.id == this.paramDates[1]?.id) {
            var x = (
              document.getElementById(
                d.id
              ) as HTMLInputElement
            ).value;
            if (x == '') {
              this.mainData[i].selected = false;
              this.mainData[i].paraBool = true;
              this.mainData[i].selectBool = false;
            } else {
              this.mainData[i].selected = true;
              this.mainData[i].paraBool = false;
              this.mainData[i].selectBool = true;
            }
          }
          else if (d.type == 'date' && d.id !== this.paramDates[1]?.id && d.id !== this.paramDates[0]?.id) {
            var x = (
              document.getElementById(
                d.id
              ) as HTMLInputElement
            ).value;
            if (x == '') {
              this.mainData[i].selected = false;
              this.mainData[i].paraBool = true;
              this.mainData[i].selectBool = false;
            } else {
              this.mainData[i].selected = true;
              this.mainData[i].paraBool = false;
              this.mainData[i].selectBool = true;
            }
          }
          else if (d.type == 'singleValueText' || d.type == 'singleValueNumber') {
            let x = d.selectedData
            // (
            //   document.getElementById(
            //     d.id
            //   ) as HTMLInputElement
            // ).value;
            if (x == '') {
              this.mainData[i].selected = false;
              this.mainData[i].paraBool = true;
              this.mainData[i].selectBool = false;
            } else {
              this.mainData[i].selected = true;
              this.mainData[i].paraBool = false;
              this.mainData[i].selectBool = true;
            }
          }
          else {
            d.options?.forEach((element) => {
              if (element.selected == true) {
                if (element.label == '') {
                  this.mainData[i].selected = false;
                  this.mainData[i].paraBool = true;
                  this.mainData[i].selectBool = false;
                } else {
                  this.mainData[i].selected = true;
                  this.mainData[i].paraBool = false;
                  this.mainData[i].selectBool = true;
                }
              }
            });
          }
        }
      });
    }
  }
  onManualChange(event, label, index, refEl) {
    //console.log(event, label, index, refEl)
    if (event != '' && event != null) {
      this.selectchange(event, label, index, refEl)
    }
    else {
      this.paramDates.forEach((e1, i) => {
        if (e1.id == label) {
          this.parameters[e1.id] = '';
          this.paramDates[i].selectedData[0] = '';
        }
      });
    }
  }
  selectchange(event, label, index, refEl) {
    //console.log(event, label, index, refEl)
    if (this.SecureId == 2) {

      if (label.toLowerCase().replace(/[_ ]/g, '') == "clicode" || label.toLowerCase().replace(/[_ ]/g, '') == "client" || label.toLowerCase().replace(/[_ ]/g, '') == "clients" || label.toLowerCase().replace(/[_ ]/g, '') == "clientnames" || label.toLowerCase().replace(/[_ ]/g, '') == "clientcode" || label.toLowerCase().replace(/[_ ]/g, '') == "clientname") {
        this.ShowSftpSetting = false;
        this.ShowSftpSetting1 = false;
        this.isSftp = false;
        this.isPassword = false;
        this.pgpEncryption = false;
        this.sftpConfigId = null;
        this.showSftp = false;
        this.showPassword = false;
        let dat = this.mainData[index].options.filter(
          (t) => t.value === event);
        let val = dat[0]?.value
        this.schedulerService.getSftpConfig(val).subscribe((res: any) => {
          if (res.body.length != 0) {
            this.ShowSftpSetting = true;
            this.folderPath = res?.body?.sftpInDirectoryPath || "";
            this.serverName = res?.body?.sftpHostName || "";
            this.ShowSftpSetting1 = this.folderPath.trim() !== "" || this.serverName.trim() !== "";
            if (res?.body?.isSftp == 'Y') { this.isSftp = true; this.showSftp = true } else { this.isSftp = false; this.showSftp = false };
            if (res?.body?.isPasswordProtect == 'Y') { this.isPassword = true; this.showPassword = true } else { this.isPassword = false; this.showPassword = false };
            if (res?.body?.isSftpPgpEncrypt == 'Y') { this.pgpEncryption = true } else { this.pgpEncryption = false };
            this.sftpConfigId = res?.body?.sftpConfigId;
          }
          else {
            this.ShowSftpSetting = false;
            this.ShowSftpSetting1 = false;
            this.isSftp = false;
            this.isPassword = false;
            this.pgpEncryption = false;
            this.sftpConfigId = null;
            this.showSftp = false;
            this.showPassword = false;
          }
        })
      }
    }
    this.parameterElementsArray.filter(e => {
      if (e.id == label) {
        if (e.slaveDependencies.length > 0) {
          this.cascadeFunc(e.id, event);
        }
      }
    });
    if (refEl == 'singleSelect') {
      if (event == '' || event == null) {
        this.parameters[label] = '';
        this.alert = true;
        if (label == this.key1) {
          delete this.parameters[this.paramDates[0].id];
          delete this.parameters[this.paramDates[1].id];
          this.paramDates[0].selectedData[0] = '';
          this.paramDates[1].selectedData[0] = '';
          this.mainData.filter(e => {
            if (e.id == this.paramDates[0].id) { e.selectedData[0] = ''; }
            if (e.id == this.paramDates[1].id) { e.selectedData[0] = ''; }
          })
          this.isDisabled = false;
          this.Disabled = false;
        }
        if (this.mainData[index].mandatory) {
          this.mainData[index].paraBool = true;
          this.mainData[index].selected = false;
          this.mainData[index].selectedData = '';
          this.mainData[index].selectBool = false;
        }
      } else {
        if (label === this.key1) {
          // this.paramDates.forEach(e1 => {
          // delete this.parameters[e1.id];
          if(this.paramDates.length>0){
            delete this.parameters[this.paramDates[0].id];
            delete this.parameters[this.paramDates[1].id];
            this.paramDates[0].selectedData[0] = '';
            this.paramDates[1].selectedData[0] = '';
            this.mainData.filter(e => {
              if (e.id == this.paramDates[0].id) { e.selectedData[0] = ''; }
              if (e.id == this.paramDates[1].id) { e.selectedData[0] = ''; }
            })
          }
          //this.mainData.filter(e => { if (e.label == 'ThruDate') { e.selectedData[0]= ''; } })
          // })
          this.isDisabled = false;
          this.Disabled = true;
          let dat = this.mainData[index].options.filter(
            (t) => t.value === event
          );
          this.parameters[label] = dat[0].value;
          if (this.mainData[index].mandatory) {
            this.mainData[index].paraBool = false;
            this.mainData[index].selected = true;
            this.mainData[index].selectBool = true;
          }
        } else {
          let dat = this.mainData[index].options.filter(
            (t) => t.value === event
          );
          this.parameters[label] = dat[0].value;
          if (this.mainData[index].mandatory) {
            this.mainData[index].paraBool = false;
            this.mainData[index].selected = true;
            this.mainData[index].selectBool = true;
          }
        }
      }
    }
    else if (refEl == 'multiSelect') {
      if (event.value.length == 0 || event.value == null) {
        this.parameters[label] = '';
      }
      else {
        this.mainData[index].options?.forEach((o) => {
          event.value.forEach(e => {
            if (o.value == e) {
              o.selected = true;
              // data=o;
            } else {
              o.selected = false;
            }
          })
        })
        this.parameters[label] = event.value;
        if (this.mainData[index].mandatory) {
          this.mainData[index].paraBool = false;
          this.mainData[index].selected = true;
          this.mainData[index].selectBool = true;
        }
      }
    }
    else if (refEl == 'singleSelectRadio') {
      if (event == '' || event == null) {
        this.parameters[label] = '';
      }
      else {
        this.parameters[label] = event;
        if (this.mainData[index].mandatory) {
          this.mainData[index].paraBool = false;
          this.mainData[index].selected = true;
          this.mainData[index].selectBool = true;
        }
      }
    }
    else if (refEl == 'singleValueText') {
      if (event == '' || event == null) {
        this.parameters[label] = '';
      }

      else {
        this.parameters[label] = event.target.value;
        if (this.mainData[index].mandatory) {
          this.mainData[index].paraBool = false;
          this.mainData[index].selected = true;
        }
      }
    }
    else if (refEl == 'singleValueNumber') {
      if (event == '' || event == null) {
        this.parameters[label] = '';
      }
      else {
        this.parameters[label] = event.target.value;
        if (this.mainData[index].mandatory) {
          this.mainData[index].paraBool = false;
          this.mainData[index].selected = true;
        }
      }
    }
    else if (refEl == 'combobox') {
      if (this.values == 1) {
        this.value2Disable = true;
        if (event == '' || event == null) {
          this.parameters[label] = '';
        }
        else {
          let dat = this.mainData[index].options.filter(
            (t) => t.value === event
          );
          this.parameters[label] = dat[0].value;
          if (this.mainData[index].mandatory) {
            this.mainData[index].paraBool = false;
            this.mainData[index].selected = true;
            this.mainData[index].selectBool = true;
          }
        }
      }
      else if (this.values == 2) {
        this.value1Disable = true;
        if (event == '' || event == null) {
          this.parameters[label] = '';
        }
        else {
          this.parameters[label] = event?.target?.value;
          if (this.mainData[index].mandatory) {
            this.mainData[index].paraBool = false;
            this.mainData[index].selected = true;
            this.mainData[index].selectBool = true;
          }
        }
      }
    }
    else if (refEl == 'date') {
      if (event == '' || event == null || event == undefined) {
        this.paramDates.forEach((e1, i) => {
          if (e1.id == label) {
            this.parameters[e1.id] = '';
            this.paramDates[i].selectedData[0] = '';
          }
        });
        // this.parameters['FromDate'] = '';
        // this.parameters['ThruDate'] = '';
        this.isDisabled = false;
        this.Disabled = false;
        this.parameters[this.key1] = '';
        this.mainData[index].selectedData[0] = '';
        if (this.mainData[index].mandatory) {
          this.mainData[index].paraBool = true;
          this.mainData[index].selected = false;
          this.mainData[index].selectBool = false;
        }
      } else {
        if (label == this.paramDates[0]?.id || label == this.paramDates[1]?.id) {
          delete this.parameters[this.key1];
          this.mainData.filter(e => { if (e.id == this.key1) { e.selectedData = ''; } })
        }
        this.isDisabled = true;
        this.Disabled = false;
        if (label == this.paramDates[0]?.id) {
          if (!Array.isArray(this.paramDates[0].selectedData)) {
            this.paramDates[0].selectedData = []; // Initialize selectedData as an empty array
          }
          this.paramDates[0].selectedData[0] = event;
          // var tempD = event.split("-");
          // this.fromdate= tempD[1] + "/" + tempD[2] + "/" + tempD[0];
          this.fromdate =
            ('0' + (event.getMonth() + 1)).slice(-2) +
            '/' +
            ('0' + event.getDate()).slice(-2) +
            '/' +
            event.getFullYear();
          this.parameters[label] = this.fromdate;
          if (this.mainData[index].mandatory) {
            this.mainData[index].paraBool = false;
            this.mainData[index].selected = true;
            this.mainData[index].selectBool = true;
          }
          // this.minDate = new Date();
          this.mainData.forEach(element => {
            if (element.id == this.paramDates[0]?.id) {
              //this.minDate11 = element.selectedData[0];
              this.minDate = element.selectedData[0];
              //this.maxDate = new Date(2030, 0, 1);
            }

          });
        }
        if (label == this.paramDates[1]?.id) {
          if (!Array.isArray(this.paramDates[1].selectedData)) {
            this.paramDates[1].selectedData = []; // Initialize selectedData as an empty array
          }
          this.paramDates[1].selectedData[0] = event;
          // var tempD = event.split("-");
          // this.thrudate=  tempD[1] + "/" + tempD[2] + "/" + tempD[0];
          this.thrudate =
            ('0' + (event.getMonth() + 1)).slice(-2) +
            '/' +
            ('0' + event.getDate()).slice(-2) +
            '/' +
            event.getFullYear();
          this.parameters[label] = this.thrudate;
          if (this.mainData[index].mandatory) {
            this.mainData[index].paraBool = false;
            this.mainData[index].selected = true;
            this.mainData[index].selectBool = true;
          }
          this.mainData.forEach(element => {
            if (element.id == this.paramDates[1]?.id) {
              // this.minDate11 = element.selectedData[0];
              // this.minDate = new Date(1990, 0, 1);
              this.maxDate = element.selectedData[0];
            }

          });

        }
        else {
          const date1 =
            ('0' + (event.getMonth() + 1)).slice(-2) +
            '/' +
            ('0' + event.getDate()).slice(-2) +
            '/' +
            event.getFullYear();
          this.parameters[label] = date1;
          if (this.mainData[index].mandatory) {
            this.mainData[index].paraBool = false;
            this.mainData[index].selected = true;
            this.mainData[index].selectBool = true;
          }
          // // this.minDate = new Date();
          // this.mainData.forEach(element => {
          //   if (element.id == this.paramDates[0]?.id) {
          //     //this.minDate11 = element.selectedData[0];
          //     this.minDate = element.selectedData[0];;
          //     this.maxDate = new Date(2030, 0, 1);
          //   }

          // });
        }
        if (this.mainData[index].mandatory) {
          this.mainData[index].paraBool = false;
          this.mainData[index].selected = true;
          this.mainData[index].selectBool = true;
        }
      }
    }
    if (this.SelectedRequestedPage == 'LOA') {
      if (this.mainData[index].id.toLowerCase() == 'clients') {
        this.clientId == '';
        let dat = this.mainData[index].options?.filter((t) => t.value === event)
        this.clientId = dat[0]?.value;
        this.mainData.filter(e => {
          if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroupvalues') { e.selectedData = []; e.showBool = false; delete this.parameters[e.id]; }
          if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroup') { e.selectedData = []; e.showBool = false; delete this.parameters[e.id]; }
        })
        if (this.runReport != '') {
          if (this.runReport.toLowerCase().replace(/[_ ]/g, '') == 'employeename') {
            this.mainData.filter(e => {
              if (e.id.toLowerCase() == 'subgroup') { e.selectedData = []; e.showBool = false; delete this.parameters[e.id]; }
              if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroupvalues') { e.selectedData = []; e.showBool = false; delete this.parameters[e.id]; }
            })
          }
          if (this.runReport.toLowerCase() == 'subgroup') {
            this.isLoading2 = true;
            this.mainData.filter(e => {
              if (e.id.toLowerCase() == 'subgroup') {
                e.showBool = true;
                e.loading = true;
                let updatedArray = []
                this.schedulerService.getSubgroupList(this.reportId, this.clientId).subscribe((response: any) => {
                  e.loading = false;
                  response?.body?.forEach(c => {
                    if (c.displayName !== null && c.value !== null) {
                      updatedArray.push({
                        label: c.displayName ? c.displayName : '',
                        value: c.value,
                        selected: false
                      });
                    }
                  });
                  e.options = updatedArray;

                })
              }
            })
          }
        }
      }
      // if (this.mainData[index].id.toLowerCase() == 'clients') {
      let dat = this.mainData[index].options?.filter((t) => t.value === event);
      if (dat[0]?.value.toLowerCase().replace(/[_ ]/g, '') == 'employeename') {
        this.runReport = dat[0]?.value;
        this.mainData.filter(e => {
          if (e.id.toLowerCase() == 'subgroup') {
            if (this.restricted) {
              e.mandatory = true;
              e.selectedData = e.options[0].value;
              e.showBool = true;
              e.disabled = true;
              this.parameters[e.id] = e.options[0].value;
              this.SubGroupId = e.options[0].value;
              e.paraBool = false;
            }
            else {
              e.selectedData = []; e.showBool = false; delete this.parameters[e.id];
            }
          }
          if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroupvalues') {
            if (this.restricted) {
              e.mandatory = true;
              let updatedArray = [];
              const selectedValues = [];
              this.schedulerService.getSubGroupValue(this.reportId, this.clientId, this.SubGroupId, this.SelectedRequestedPage).subscribe((response: any) => {
                e.loading = false;
                e.showBool = true;
                response?.body?.forEach(c => {
                  if (c.displayName !== null && c.value !== null) {
                    updatedArray.push({
                      label: c.displayName ? c.displayName : '',
                      value: c.value,
                      selected: true
                    });
                    selectedValues.push(c.value)
                  }
                });
                e.options = updatedArray;
                e.selectedData = selectedValues;
                this.parameters[e.id] = selectedValues;
                e.paraBool = false;
              })
            }
            else {
              e.selectedData = []; e.showBool = false; delete this.parameters[e.id];
            }
          }
        })
      }
      if (dat[0]?.value.toLowerCase() == 'subgroup') {
        this.runReport = dat[0]?.value;
        this.isLoading2 = true;
        this.mainData.filter(e => {
          if (e.id.toLowerCase() == 'subgroup') {
            e.showBool = true;
            e.loading = true;
            let updatedArray = []
            this.schedulerService.getSubgroupList(this.reportId, this.clientId).subscribe((response: any) => {
              e.loading = false;
              response?.body?.forEach(c => {
                if (c.displayName !== null && c.value !== null) {
                  updatedArray.push({
                    label: c.displayName ? c.displayName : '',
                    value: c.value,
                    selected: false
                  });
                }
              });
              e.options = updatedArray;
              if (this.restricted) {
                e.mandatory = true;
                e.selectedData = e.options[0].value;
                e.showBool = true;
                e.disabled = true;
                this.parameters[e.id] = e.options[0].value;
                this.SubGroupId = e.options[0].value;
                e.paraBool = false;
              }
            })
          }
          if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroupvalues') {
            if (this.restricted) {
              e.mandatory = true;
              let updatedArray = [];
              const selectedValues = [];
              this.schedulerService.getSubGroupValue(this.reportId, this.clientId, this.SubGroupId, this.SelectedRequestedPage).subscribe((response: any) => {
                e.loading = false;
                e.showBool = true;
                response?.body?.forEach(c => {
                  if (c.displayName !== null && c.value !== null) {
                    updatedArray.push({
                      label: c.displayName ? c.displayName : '',
                      value: c.value,
                      selected: true
                    });
                    selectedValues.push(c.value)
                  }
                });
                e.options = updatedArray;
                e.selectedData = selectedValues;
                this.parameters[e.id] = selectedValues;
                e.paraBool = false;
              })
            }
            else {
              e.selectedData = []; e.showBool = false; delete this.parameters[e.id];
            }
          }

        })
      }
      // }
      if (this.mainData[index].id.toLowerCase() == 'subgroup') {

        let dat = this.mainData[index].options?.filter((t) => t.value === event)
        this.SubGroupId = dat[0]?.value;
        this.isLoading2 = true;

        this.mainData.filter(e => {
          if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroupvalues') {
            e.selectedData = []; delete this.parameters[e.id];
            e.showBool = true;
            e.loading = true;
            let updatedArray = []
            this.schedulerService.getSubGroupValue(this.reportId, this.clientId, this.SubGroupId, this.SelectedRequestedPage).subscribe((response: any) => {
              e.loading = false;
              response?.body?.forEach(c => {
                if (c.displayName !== null && c.value !== null) {
                  updatedArray.push({
                    label: c.displayName ? c.displayName : '',
                    value: c.value,
                    selected: false
                  });
                }
              });
              e.options = updatedArray;

            })
          }
        })
      }
    }
    if (this.SelectedRequestedPage == 'ADA_REQ') {
      if (this.mainData[index].id.toLowerCase() == 'clients') {
        let dat = this.mainData[index].options?.filter((t) => t.value === event)
        this.clientId = dat[0]?.value;
        this.mainData.filter(e => {
          if (e.id.toLowerCase() == 'subgroup') {
            e.selectedData = [];
            this.parameters[e.label] = '';
            e.options = [];
            e.showBool = true;
            e.loading = true
            let updatedArray = []
            this.schedulerService.getSubgroupList(this.reportId, this.clientId).subscribe((response: any) => {
              e.loading = false;
              response?.body?.forEach(c => {
                if (c.displayName !== null && c.value !== null) {
                  updatedArray.push({
                    label: c.displayName ? c.displayName : '',
                    value: c.value,
                    selected: false
                  });
                }
              });
              e.options = updatedArray;
              e.options.forEach(d=>{if(d.value.toLowerCase()=='restricted'){this.restricted=true}})
                if (this.restricted) {
                  e.mandatory = true;
                  e.selectedData = e.options[0].value;
                  e.showBool = true;
                  e.disabled = true;
                  this.parameters[e.id] = e.options[0].value;
                  this.SubGroupId = e.options[0].value;
                  e.paraBool = false;
                }
            })
          }
          if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroupvalues') {
            if (this.restricted) {
              e.mandatory = true;
              let updatedArray = [];
              const selectedValues = [];
              this.schedulerService.getAdareqSubGroupValue(this.reportId, this.clientId, this.SubGroupId, this.SelectedRequestedPage).subscribe((response: any) => {
                e.loading = false;
                e.showBool = true;
                response?.body?.forEach(c => {
                  if (c.displayName !== null && c.value !== null) {
                    updatedArray.push({
                      label: c.displayName ? c.displayName : '',
                      value: c.value,
                      selected: true
                    });
                    selectedValues.push(c.value)
                  }
                });
                e.options = updatedArray;
                e.selectedData = selectedValues;
                this.parameters[e.id] = selectedValues;
                e.paraBool = false;
              })
            }
            else {
              e.selectedData = []; e.showBool = false; delete this.parameters[e.id];
            }
          }
        })
      }
      if (this.mainData[index].id.toLowerCase() == 'subgroup') {
        let dat = this.mainData[index].options?.filter((t) => t.value === event)
        this.SubGroupId = dat[0]?.value;
        this.mainData.filter(e => {
          if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroupvalues') {
            e.showBool = true;
            e.loading = true
            let updatedArray = [];
            e.selectedData = [];
            this.parameters[e.id] = [];
            this.schedulerService.getAdareqSubGroupValue(this.reportId, this.clientId, this.SubGroupId, this.SelectedRequestedPage).subscribe((response: any) => {
              e.loading = false;
              response?.body?.forEach(c => {
                if (c.displayName !== null && c.value !== null) {
                  updatedArray.push({
                    label: c.displayName ? c.displayName : '',
                    value: c.value,
                    selected: false
                  });
                }
              });
              e.options = updatedArray;
            })
          }
        })
      }
    }
    if (this.SelectedRequestedPage == 'PACS') {
      if (this.mainData[index].id.toLowerCase() == 'clients') {
        let dat = this.mainData[index].options?.filter((t) => t.value === event)
        this.clientId = dat[0]?.value;
        this.lobData='';
        this.mainData.filter(e => {
          if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'lineofbusiness') {
            e.selectedData = [];
            this.parameters[e.label] = '';
            e.options = [];
            e.showBool = true;
            e.loading = true
            this.clientFlag = "C";
            this.parentFlag = "Y";
            let updatedArray = [];
            this.schedulerService.getLOBList(this.reportId, this.clientId, this.clientFlag, this.parentFlag).subscribe((response: any) => {
              e.loading = false;
              response?.body?.forEach((c: any) => {
                updatedArray.push({
                  label: c,
                  value: c,
                  selected: false
                });
              });
              e.options = updatedArray;
            })//e.id.toLowerCase() == 'policy'||e.id.toLowerCase() == 'class'||e.id.toLowerCase() == 'plan type'||e.id.toLowerCase()=='policy type'
          }
          // if (e.id.toLowerCase() == 'policies') { e.selectedData = []; e.options.filter(o => { if (o.selected == true) { o.selected = false } }); delete this.parameters[e.id]; }
          if (e.id.toLowerCase() == 'policy') { e.selectedData = []; e.showBool = false; delete this.parameters[e.id]; }
          if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'plantype') { e.selectedData = []; e.showBool = false; delete this.parameters[e.id]; }
          if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'policytype') { e.selectedData = []; e.showBool = false; delete this.parameters[e.id]; }
          if (e.id.toLowerCase() == 'class') { e.selectedData = []; e.showBool = false; delete this.parameters[e.id]; }
          if (e.id.toLowerCase() == 'subgroup') {
            e.selectedData = [];
            this.parameters[e.label] = '';
            e.options = [];
            e.showBool = true;
            e.loading = true
            let updatedArray = []
            this.schedulerService.getSubgroupList(this.reportId, this.clientId).subscribe((response: any) => {
              e.loading = false;
              response?.body?.forEach(c => {
                if (c.displayName !== null && c.value !== null) {
                  updatedArray.push({
                    label: c.displayName ? c.displayName : '',
                    value: c.value,
                    selected: false
                  });
                }
              });
              e.options = updatedArray;
              e.options.forEach(d=>{if(d.value.toLowerCase()=='restricted'){this.restricted=true}})
                if (this.restricted) {
                  e.mandatory = true;
                  e.selectedData = e.options[0].value;
                  e.showBool = true;
                  e.disabled = true;
                  this.parameters[e.id] = e.options[0].value;
                  this.SubGroupId = e.options[0].value;
                  e.paraBool = false;
                }
            })
          }
          if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroupvalues') {
            if (this.restricted) {
              e.mandatory = true;
              let updatedArray = [];
              const selectedValues = [];
              this.schedulerService.getSubGroupValue(this.reportId, this.clientId, this.SubGroupId, this.SelectedRequestedPage).subscribe((response: any) => {
                e.loading = false;
                e.showBool = true;
                response?.body?.forEach(c => {
                  if (c.displayName !== null && c.value !== null) {
                    updatedArray.push({
                      label: c.displayName ? c.displayName : '',
                      value: c.value,
                      selected: true
                    });
                    selectedValues.push(c.value)
                  }
                });
                e.options = updatedArray;
                e.selectedData = selectedValues;
                this.parameters[e.id] = selectedValues;
                e.paraBool = false;
              })
            }
            else {
              e.selectedData = []; e.showBool = false; delete this.parameters[e.id];
            }
          }
        })
      }
      if (this.mainData[index].id.toLowerCase().replace(/[_ ]/g, '') == 'lineofbusiness') {
        let dat = this.mainData[index].options?.filter((t) => t.value === event)
        this.lobData = dat[0]?.value;
        this.mainData.filter(e => {
          if (e.id.toLowerCase() == 'policies') { 
            // e.selectedData = []; e.options.filter(o => { if (o.selected == true) { o.selected = false } }); delete this.parameters[e.id]; 
            if (e.selectedData.toLowerCase() == 'policy') {
              this.mainData.filter(e => {
                if (e.id.toLowerCase() == 'policy') {
                  e.showBool = true;
                  e.loading = true
                  this.schedulerService.getPolicyList(this.reportId, this.clientId, this.lobData).subscribe((response: any) => {
                    e.loading = false;
                    let convertedData = [];
                    response?.body?.map((obj, i) => {
                      const formattedLabel = obj.policyNumber +" - "+ obj.effectiveDate + " - " + obj.expiryDate + " - " + obj.policyType + " - " + obj.policyDesc;
                      convertedData[i] = { label: formattedLabel, value: obj.policyNumber + "|" + obj.policyBON, value1: obj.policyBON, selected: false };
                    });
                    e.options = convertedData;
                  })
                }
                if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'plantype') { e.selectedData = []; e.showBool = false; delete this.parameters[e.id]; }
                if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'policytype') { e.selectedData = []; e.showBool = false; delete this.parameters[e.id]; }
                if (e.id.toLowerCase() == 'class') { e.selectedData = []; e.showBool = false; delete this.parameters[e.id]; }
              })
            }
            if (e.selectedData.toLowerCase().replace(/[_ ]/g, '') == 'policytype') {
              this.mainData.filter(e => {
                if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'policytype') {
                  e.showBool = true;
                  e.loading = true;
                  let updatedArray = [];
                  this.schedulerService.getPolicyTypeList(this.reportId, this.clientId, this.lobData).subscribe((response: any) => {
                    e.loading = false;
                    response?.body?.forEach((c: any) => {
                      updatedArray.push({
                        label: c,
                        value: c,
                        selected: false
                      });
                    });
                    e.options = updatedArray;
                  })
                }
                if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'plantype') { e.selectedData = []; e.showBool = false; delete this.parameters[e.id]; }
                if (e.id.toLowerCase() == 'policy') { e.selectedData = []; e.showBool = false; delete this.parameters[e.id]; }
                if (e.id.toLowerCase() == 'class') { e.selectedData = []; e.showBool = false; delete this.parameters[e.id]; }
              })
            }
          }
          // if (e.id.toLowerCase() == 'policy') { e.selectedData = []; e.showBool = false; delete this.parameters[e.id]; }
          // if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'plantype') { e.selectedData = []; e.showBool = false; delete this.parameters[e.id]; }
          // if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'policytype') { e.selectedData = []; e.showBool = false; delete this.parameters[e.id]; }
          // if (e.id.toLowerCase() == 'class') { e.selectedData = []; e.showBool = false; delete this.parameters[e.id]; }
        })
      }
      if (this.mainData[index].id.toLowerCase() == 'policies') {
        let dat = this.mainData[index].options?.filter((t) => t.value === event)
        this.policiesData = dat[0]?.label;
        if(this.lobData!='' && this.lobData!=null && this.lobData!=undefined){
        if (dat[0]?.label.toLowerCase() == 'policy') {
          this.mainData.filter(e => {
            if (e.id.toLowerCase() == 'policy') {
              e.showBool = true;
              e.loading = true
              this.schedulerService.getPolicyList(this.reportId, this.clientId, this.lobData).subscribe((response: any) => {
                e.loading = false;
                let convertedData = [];
                response?.body?.map((obj, i) => {
                  const formattedLabel = obj.policyNumber +" - "+ obj.effectiveDate + " - " + obj.expiryDate + " - " + obj.policyType + " - " + obj.policyDesc;
                  convertedData[i] = { label: formattedLabel, value: obj.policyNumber + "|" + obj.policyBON, value1: obj.policyBON, selected: false };
                });
                e.options = convertedData;
              })
            }
            if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'plantype') { e.selectedData = []; e.showBool = false; delete this.parameters[e.id]; }
            if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'policytype') { e.selectedData = []; e.showBool = false; delete this.parameters[e.id]; }
            if (e.id.toLowerCase() == 'class') { e.selectedData = []; e.showBool = false; delete this.parameters[e.id]; }
          })
        }
        if (dat[0]?.label.toLowerCase().replace(/[_ ]/g, '') == 'policytype') {
          this.mainData.filter(e => {
            if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'policytype') {
              e.showBool = true;
              e.loading = true;
              let updatedArray = [];
              this.schedulerService.getPolicyTypeList(this.reportId, this.clientId, this.lobData).subscribe((response: any) => {
                e.loading = false;
                response?.body?.forEach((c: any) => {
                  updatedArray.push({
                    label: c,
                    value: c,
                    selected: false
                  });
                });
                e.options = updatedArray;
              })
            }
            if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'plantype') { e.selectedData = []; e.showBool = false; delete this.parameters[e.id]; }
            if (e.id.toLowerCase() == 'policy') { e.selectedData = []; e.showBool = false; delete this.parameters[e.id]; }
            if (e.id.toLowerCase() == 'class') { e.selectedData = []; e.showBool = false; delete this.parameters[e.id]; }
          })
        }
      }
      }
      if (this.mainData[index].id.toLowerCase() == 'subgroup') {
        let dat = this.mainData[index].options?.filter((t) => t.value === event)
        this.SubGroupId = dat[0]?.value;
        this.mainData.filter(e => {
          if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroupvalues') {
            e.showBool = true;
            e.loading = true
            let updatedArray = [];
            e.selectedData = [];
            this.parameters[e.id] = [];
            this.schedulerService.getSubGroupValue(this.reportId, this.clientId, this.SubGroupId, this.SelectedRequestedPage).subscribe((response: any) => {
              e.loading = false;
              response?.body?.forEach(c => {
                if (c.displayName !== null && c.value !== null) {
                  updatedArray.push({
                    label: c.displayName ? c.displayName : '',
                    value: c.value,
                    selected: false
                  });
                }
              });
              e.options = updatedArray;
            })
          }
        })
      }
      if (this.mainData[index].id.toLowerCase() == 'policy') {
        this.policyId = '';
        let dat = this.mainData[index].selectedData;
        let data1 = [];
        if (dat.length > 1) {
          this.policyId = '';

        }
        else {
          data1 = this.mainData[index].options?.filter((t) => t.value === event.value[0])
          this.policyId = data1[0]['value1'];
        }

        let checkbool = false;

        // if (!checkbool) {
        this.mainData.filter(e => {
          if (e.id.toLowerCase() == 'class') {
            // const containsEmptyString = this.policyId?.includes(' ');
            // this.policyId = this.policyId.filter(item => item !== '');
            if (this.policyId == '') {
              checkbool = true;
              e.selectedData = [];
              e.showBool = false;
              delete this.parameters[e.id];
            }
            else {
              e.showBool = true;
              e.loading = true
              this.schedulerService.getClassList(this.reportId, this.policyId).subscribe((response: any) => {
                e.loading = false;
                response?.body?.forEach(c => {
                  c.value = '(' + c.contractClassBON + ',' + c.contractClassID + ')';
                  c.label = c.contractClassID;
                  delete c.contractClassID;
                  c.value1 = c.contractClassBON;
                  delete c.contractClassBON;
                  c.selected = false;
                })
                e.options = response?.body;
              })
            }
          }
        })
        // }
      }
      if (this.mainData[index].id.toLowerCase() == 'class') {
        let obj = this.mainData[index];
        this.classBON = '';
        this.classId = '';
        let dat = this.mainData[index].selectedData;
        let data1;
        if (dat.length > 1) {
          this.classBON = '';
          this.classId = '';
        }
        else {
          data1 = this.mainData[index].options?.filter((t) => {
            if (t.value === event.value[0]) {
              this.classBON = t.value1
              this.classId = t.label;
              //this.requestPayloadParameterObj.parameters[obj.id]=t.value;
            }
          })
        }
        this.mainData.filter(e => {
          if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'plantype') {
            if (this.classBON == '' && this.classId == '') {
              e.selectedData = [];
              e.showBool = false;
              delete this.parameters[e.id];
            }
            else {
              e.showBool = true;
              e.loading = true
              this.schedulerService.getPlanTypeList(this.reportId, this.classBON, this.classId).subscribe((response: any) => {
                e.loading = false;
                response?.body?.forEach(c => {
                  c.label = c.planDescription;
                  delete c.planDescription;
                  c.value = '(' + c.planDesignBON + ',' + c.planType + ')';
                  delete c.planType;
                  c.selected = false;
                })
                e.options = response?.body;
              })
            }
          }
        })
      }
    }
    if (this.SelectedRequestedPage == 'ADA') {
      if (this.mainData[index].id.toLowerCase().replace(/[_ ]/g, '') == 'clientnames') {
        this.clientId = '';
        let dat = this.mainData[index].options?.filter((t) => t.value === event)
        this.clientId = dat[0]?.value;
        this.mainData.filter(e => {
          if (e.id.toLowerCase() == 'subgroup') {
            e.selectedData = [];
            this.parameters[e.label] = '';
            e.options = [];
            e.showBool = true;
            e.loading = true;
            let updatedArray = []
            this.schedulerService.getAdaSubgroupList(this.reportId, this.clientId).subscribe((response: any) => {
              e.loading = false;
              response?.body?.forEach((c: any) => {
                if (c.displayName !== null && c.value !== null) {
                  updatedArray.push({
                    label: c.displayName ? c.displayName : '',
                    value: c.value,
                    selected: false
                  });
                }
              });
              e.options = updatedArray;
              e.options.forEach(d=>{if(d.value.toLowerCase()=='restricted'){this.restricted=true}})
                if (this.restricted) {
                  e.mandatory = true;
                  e.selectedData = e.options[0].value;
                  e.showBool = true;
                  e.disabled = true;
                  this.parameters[e.id] = e.options[0].value;
                  this.SubGroupId = e.options[0].value;
                  e.paraBool = false;
                }
            })
          }
          if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroupvalues') {
            e.selectedData = [];
            this.parameters[e.label] = '';
            e.options = [];
            if (this.restricted) {
              e.mandatory = true;
              let updatedArray = [];
              const selectedValues = [];
              this.parameters[e.label] = '';
              this.schedulerService.getAdaSubGroupValue(this.reportId, this.clientId, this.SubGroupId, this.SelectedRequestedPage).subscribe((response: any) => {
                e.loading = false;
                e.showBool = true;
                response?.body?.forEach(c => {
                  if (c.displayName !== null && c.value !== null) {
                    updatedArray.push({
                      label: c.displayName ? c.displayName : '',
                      value: c.value,
                      selected: true
                    });
                    selectedValues.push(c.value)
                  }
                });
                e.options = updatedArray;
                e.selectedData = selectedValues;
                this.parameters[e.id] = selectedValues;
                e.paraBool = false;
              })
            }
            else {
              e.selectedData = []; e.showBool = false; this.parameters[e.label] = ''; delete this.parameters[e.id];
            }
          }
        })
      }
      if (this.mainData[index].id.toLowerCase() == 'subgroup') {
        this.SubGroupId = '';
        let dat = this.mainData[index].options?.filter((t) => t.value === event)
        this.SubGroupId = dat[0]?.value;
        this.mainData.filter(e => {
          if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroupvalues') {
            e.selectedData = [];
            let updatedArray = []
            e.showBool = true;
            e.loading = true;
            e.selectedData = [];
            this.parameters[e.id] = [];
            this.schedulerService.getAdaSubGroupValue(this.reportId, this.clientId, this.SubGroupId, this.SelectedRequestedPage).subscribe((response: any) => {
              e.loading = false;
              response?.body?.forEach(c => {
                if (c.displayName !== null && c.value !== null) {
                  updatedArray.push({
                    label: c.displayName ? c.displayName : '',
                    value: c.value,
                    selected: false
                  });
                }
              });
              e.options = updatedArray;
            })
          }
        })
      }
    }
    if (this.SelectedRequestedPage == 'CD2') {
      if (this.mainData[index].id.toLowerCase().replace(/[_ ]/g, '') == 'clientnames') {
        this.clientId = '';
        let dat = this.mainData[index].options?.filter((t) => t.value === event)
        this.clientId = dat[0]?.value;
        this.mainData.filter(e => {
          if (e.id.toLowerCase() == 'subgroup') {
            e.selectedData = [];
            this.parameters[e.label] = '';
            e.options = [];
            e.showBool = true;
            e.loading = true;
            let updatedArray = []
            this.schedulerService.getCD2Subgroup(this.reportId, this.clientId).subscribe((response: any) => {
              e.loading = false;
              response?.body?.forEach(c => {
                if (c.displayName !== null && c.value !== null) {
                  updatedArray.push({
                    label: c.displayName ? c.displayName : '',
                    value: c.value,
                    selected: false
                  });
                }
              });
              e.options = updatedArray;
              e.options.forEach(d=>{if(d.value.toLowerCase()=='restricted'){this.restricted=true}})
                if (this.restricted) {
                  e.mandatory = true;
                  e.selectedData = e.options[0].value;
                  e.showBool = true;
                  e.disabled = true;
                  this.parameters[e.id] = e.options[0].value;
                  this.SubGroupId = e.options[0].value;
                  e.paraBool = false;
                }
            })
          }
          if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'loapolicy') {
            e.selectedData = [];
            this.parameters[e.label] = '';
            e.options = [];
            e.showBool = true;
            e.loading = true;
            let updatedArray = []
            this.schedulerService.getCD2LoaPolicy(this.reportId, this.clientId).subscribe((response: any) => {
              e.loading = false;
              response?.body?.forEach((c: any) => {
                updatedArray.push({
                  label: c,
                  value: c,
                  selected: false
                });
              });
              e.options = updatedArray;
            })
          }
          if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'stdpolicy') {
            e.selectedData = [];
            this.parameters[e.label] = '';
            e.options = [];
            e.showBool = true;
            e.loading = true;
            let updatedArray = []
            this.schedulerService.getCD2StdPolicy(this.reportId, this.clientId).subscribe((response: any) => {
              e.loading = false;
              response?.body?.forEach((c: any) => {
                updatedArray.push({
                  label: c,
                  value: c,
                  selected: false
                });
              });
              e.options = updatedArray;
            })
          }
          if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'wcpolicy') {
            e.selectedData = [];
            this.parameters[e.label] = '';
            e.options = [];
            e.showBool = true;
            e.loading = true;
            let updatedArray = []
            this.schedulerService.getCD2WcPolicy(this.reportId, this.clientId).subscribe((response: any) => {
              e.loading = false;
              response?.body?.forEach((c: any) => {
                updatedArray.push({
                  label: c,
                  value: c,
                  selected: false
                });
              });
              e.options = updatedArray;
            })
          }
          if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'ltdpolicy') {
            e.selectedData = [];
            this.parameters[e.label] = '';
            e.options = [];
            e.showBool = true;
            e.loading = true;
            let updatedArray = []
            this.schedulerService.getCD2LtdPolicy(this.reportId, this.clientId).subscribe((response: any) => {
              e.loading = false;
              response?.body?.forEach((c: any) => {
                updatedArray.push({
                  label: c,
                  value: c,
                  selected: false
                });
              });
              e.options = updatedArray;
            })
          }
          if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroupvalues') {
            e.selectedData = [];
            this.parameters[e.label] = '';
            e.options = [];
            if (this.restricted) {
              e.mandatory = true;
              let updatedArray = [];
              const selectedValues = [];
              this.schedulerService.getCD2SubgroupVlaue(this.reportId, this.clientId, this.SubGroupId, this.SelectedRequestedPage).subscribe((response: any) => {
                e.loading = false;
                e.showBool = true;
                response?.body?.forEach(c => {
                  if (c.displayName !== null && c.value !== null) {
                    updatedArray.push({
                      label: c.displayName ? c.displayName : '',
                      value: c.value,
                      selected: true
                    });
                    selectedValues.push(c.value)
                  }
                });
                e.options = updatedArray;
                e.selectedData = selectedValues;
                this.parameters[e.id] = selectedValues;
                e.paraBool = false;
              })
            }
            else {
              e.selectedData = []; e.showBool = false; delete this.parameters[e.id];
            }
          }
        })
      }
      if (this.mainData[index].id.toLowerCase() == 'subgroup') {
        this.SubGroupId = '';
        let updatedArray = [];
        let dat = this.mainData[index].options?.filter((t) => t.value === event)
        this.SubGroupId = dat[0]?.value;
        this.mainData.filter(e => {
          if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroupvalues') {
            e.showBool = true;
            e.loading = true;
            e.selectedData = [];
            this.parameters[e.id] = [];
            this.schedulerService.getCD2SubgroupVlaue(this.reportId, this.clientId, this.SubGroupId, this.SelectedRequestedPage).subscribe((response: any) => {
              e.loading = false;
              response?.body?.forEach(c => {
                if (c !== null) {
                  updatedArray.push({
                    label: c,
                    value: c,
                    selected: false
                  });
                }
              })
              e.options = updatedArray;
            })
          }
        })
      }
    }
    if (this.SelectedRequestedPage == 'WC') {
      if (this.mainData[index].id.toLowerCase() == 'clients') {
        this.clientId = '';
        let dat = this.mainData[index].options?.filter((t) => t.value === event)
        this.clientId = dat[0]?.value;
        this.mainData.filter(e => {
          if (e.id.toLowerCase() == 'subgroup' || e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroupvalues' || e.id.toLowerCase() == 'policies' || e.id.toLowerCase().replace(/[_ ]/g, '') == 'sitesbenefitstates') 
            { e.selectedData = []; e.showBool = false; delete this.parameters[e.id];  }

          // if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'runreportby') {
          //   e.selectedData = [];
          //   e.showBool = true;
          //   e.loading = true;
          //   e.paraBool = true;
          //   let updatedArray = []
          //   this.schedulerService.getRunByOptions(this.reportId).subscribe((response: any) => {
          //     e.loading = false;
          //     response?.body?.forEach((c: any) => {
          //       updatedArray.push({
          //         label: c,
          //         value: c,
          //         selected: false
          //       });
          //     });
          //     e.options = updatedArray;
          // })

          // }
        })
        if(this.runReport1!=''){
          if (this.runReport1.toLowerCase().replace(/[_ ]/g, '') === 'policyonly') {
            this.mainData.filter(e => {
              if (e.id.toLowerCase() == 'policies') {
                e.selectedData = [];
                e.showBool = true;
                e.loading = true;
                this.schedulerService.getWCPolicyList(this.reportId, this.clientId).subscribe((response: any) => {
                  e.loading = false;
                  response?.body?.forEach(c => {
                    c.value = c.policyNumber;
                    c.label = c.policyNumber + '-' + c.effectiveDate + '-' + c.expiryDate;
                    c.selected = false;
                  })
                  e.options = response?.body;
                })
  
              }
              if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'sitesbenefitstates') { e.selectedData = []; e.showBool = false; delete this.parameters[e.id]; }
              if (e.id.toLowerCase() == 'subgroup') { e.selectedData = []; e.showBool = false; delete this.parameters[e.id]; }
              if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroupvalues') { e.selectedData = []; e.showBool = false; delete this.parameters[e.id]; }
            })
          }
          if (this.runReport1.toLowerCase().replace(/[_ ]/g, '') === 'benefitstateonly') {
            this.mainData.filter(e => {
              if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'sitesbenefitstates') {
                e.selectedData = [];
                e.showBool = true;
                e.loading = true;
                this.schedulerService.getWCBenefitList(this.reportId, this.clientId).subscribe((response: any) => {
                  e.loading = false;
                  response?.body?.forEach(c => {
                    c.label = c.benefitState;
                    c.value = c.ncciStateJurisdictionCode;
                    c.selected = false;
                  })
                  e.options = response?.body;
                })
              }
              if (e.id.toLowerCase() == 'policies') { e.selectedData = []; e.showBool = false; delete this.parameters[e.id]; }
              if (e.id.toLowerCase() == 'subgroup') { e.selectedData = []; e.showBool = false; delete this.parameters[e.id]; }
              if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroupvalues') { e.selectedData = []; e.showBool = false; delete this.parameters[e.id]; }
            })
          }
          if (this.runReport1.toLowerCase().replace(/[_ ]/g, '') === 'siteonly') {
            this.mainData.filter(e => {
              if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'sitesbenefitstates') {
                e.selectedData = [];
                e.showBool = true;
                e.loading = true;
                this.schedulerService.getWCSiteList(this.reportId, this.clientId).subscribe((response: any) => {
                  e.loading = false;
                  response?.body?.forEach(c => {
                    c.label = c.siteCode + '-' + c.siteDesc;
                    c.value = c.siteCode;
                    c.selected = false;
                  })
                  e.options = response?.body;
                })
  
              }
              if (e.id.toLowerCase() == 'policies') { e.selectedData = []; e.showBool = false; delete this.parameters[e.id]; }
              if (e.id.toLowerCase() == 'subgroup') { e.selectedData = []; e.showBool = false; delete this.parameters[e.id]; }
              if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroupvalues') { e.selectedData = []; e.showBool = false; delete this.parameters[e.id]; }
            })
          }
          if (this.runReport1.toLowerCase().replace(/[_ ]/g, '') === 'policy,benefitstate') {
            this.mainData.filter(e => {
              if (e.id.toLowerCase() == 'policies') {
                e.selectedData = [];
                e.showBool = true;
                e.loading = true;
                this.schedulerService.getWCPolicyList(this.reportId, this.clientId).subscribe((response: any) => {
                  e.loading = false;
                  response?.body?.forEach(c => {
                    c.value = c.policyNumber;
                    c.label = c.policyNumber + '-' + c.effectiveDate + '-' + c.expiryDate;
                    c.selected = false;
                  })
                  e.options = response?.body;
                })
  
              }
              if (e.id.toLowerCase().replace(/[_ ]/g, '') === 'sitesbenefitstates') {
                e.selectedData = [];
                e.showBool = true;
                e.loading = true;
                this.schedulerService.getWCBenefitList(this.reportId, this.clientId).subscribe((response: any) => {
                  e.loading = false;
                  response?.body?.forEach(c => {
                    c.label = c.benefitState;
                    c.value = c.ncciStateJurisdictionCode;
                    c.selected = false;
                  })
                  e.options = response?.body;
                })
  
              }
              if (e.id.toLowerCase() === 'subgroup') { e.selectedData = []; e.showBool = false; delete this.parameters[e.id]; }
              if (e.id.toLowerCase().replace(/[_ ]/g, '') === 'subgroupvalues') { e.selectedData = []; e.showBool = false; delete this.parameters[e.id]; }
            })
          }
          if (this.runReport1.toLowerCase().replace(/[_ ]/g, '') === 'policy,site') {
            this.mainData.filter(e => {
              if (e.id.toLowerCase() == 'policies') {
                e.selectedData = [];
                e.showBool = true;
                e.loading = true;
                this.schedulerService.getWCPolicyList(this.reportId, this.clientId).subscribe((response: any) => {
                  e.loading = false;
                  response?.body?.forEach(c => {
                    c.value = c.policyNumber;
                    c.label = c.policyNumber + '-' + c.effectiveDate + '-' + c.expiryDate;
                    c.selected = false;
                  })
                  e.options = response?.body;
                })
  
              }
              if (e.id.toLowerCase().replace(/[_ ]/g, '') === 'sitesbenefitstates') {
                e.selectedData = [];
                e.showBool = true;
                e.loading = true;
                this.schedulerService.getWCSiteList(this.reportId, this.clientId).subscribe((response: any) => {
                  e.loading = false;
                  response?.body?.forEach(c => {
                    c.label = c.siteCode + '-' + c.siteDesc;
                    c.value = c.siteCode;
                    c.selected = false;
                  })
                  e.options = response?.body;
                })
  
              }
              if (e.id.toLowerCase() == 'subgroup') { e.selectedData = []; e.showBool = false; delete this.parameters[e.id]; }
              if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroupvalues') { e.selectedData = []; e.showBool = false; delete this.parameters[e.id]; }
            })
          }
          if (this.runReport1.toLowerCase().replace(/[_ ]/g, '') === 'policy,benefitstate,subgroup') {
            this.mainData.filter(e => {
              if (e.id.toLowerCase() == 'policies') {
                e.selectedData = [];
                e.showBool = true;
                e.loading = true;
                this.schedulerService.getWCPolicyList(this.reportId, this.clientId).subscribe((response: any) => {
                  e.loading = false;
                  response?.body?.forEach(c => {
                    c.value = c.policyNumber;
                    c.label = c.policyNumber + '-' + c.effectiveDate + '-' + c.expiryDate;
                    c.selected = false;
                  })
                  e.options = response?.body;
                })
  
              }
              if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'sitesbenefitstates') {
                e.selectedData = [];
                e.showBool = true;
                e.loading = true;
                this.schedulerService.getWCBenefitList(this.reportId, this.clientId).subscribe((response: any) => {
                  e.loading = false;
                  response?.body?.forEach(c => {
                    c.label = c.benefitState;
                    c.value = c.ncciStateJurisdictionCode;
                    c.selected = false;
                  })
                  e.options = response?.body;
                })
  
              }
              if (e.id.toLowerCase() == 'subgroup') {
                e.selectedData = [];
                this.parameters[e.label] = '';
                e.options = [];
                e.showBool = true;
                e.loading = true;
                let updatedArray = []
                this.schedulerService.getWCSubgroup(this.reportId, this.clientId).subscribe((response: any) => {
                  e.loading = false;
                  response?.body?.forEach(c => {
                    if (c.displayName !== null && c.value !== null) {
                      updatedArray.push({
                        label: c.displayName ? c.displayName : '',
                        value: c.value,
                        selected: false
                      });
                    }
                  });
                  e.options = updatedArray;
                  e.options.forEach(d=>{if(d.value.toLowerCase()=='restricted'){this.restricted=true}})
                  if (this.restricted) {
                    e.mandatory = true;
                    e.selectedData = e.options[0].value;
                    e.showBool = true;
                    e.disabled = true;
                    this.parameters[e.id] = e.options[0].value;
                    this.SubGroupId = e.options[0].value;
                    e.paraBool = false;
                  }
                })
              }
              if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroupvalues') { 
                if (this.restricted) {
                      e.mandatory = true;
                      e.showBool = true;
                      e.loading = true;
                      let updatedArray = [];
                      const selectedValues=[];
                      e.selectedData = [];
                      this.parameters[e.id] = [];
                      this.schedulerService.getSubGroupValue(this.reportId, this.clientId, this.SubGroupId, this.SelectedRequestedPage).subscribe((response: any) => {
                        e.loading = false;
                        response?.body?.forEach(c => {
                          if (c.displayName !== null && c.value !== null) {
                            updatedArray.push({
                              label: c.displayName ? c.displayName : '',
                              value: c.value,
                              selected: true
                            });
                            selectedValues.push(c.value);
                          }
                        });
                        e.options = updatedArray;
                        e.selectedData = selectedValues;
                        this.parameters[e.id] = selectedValues;
                        e.paraBool = false;
                      })
                   
                }
                else{
                  e.selectedData = []; e.showBool = false; delete this.parameters[e.id];
                }
                 
              }
            })
          }
          if (this.runReport1.toLowerCase().replace(/[_ ]/g, '') === 'policy,site,subgroup') {
            this.mainData.filter(e => {
              if (e.id.toLowerCase() == 'policies') {
                e.selectedData = [];
                e.showBool = true;
                e.loading = true;
                this.schedulerService.getWCPolicyList(this.reportId, this.clientId).subscribe((response: any) => {
                  e.loading = false;
                  response?.body?.forEach(c => {
                    c.value = c.policyNumber;
                    c.label = c.policyNumber + '-' + c.effectiveDate + '-' + c.expiryDate;
                    c.selected = false;
                  })
                  e.options = response?.body;
                })
  
              }
              if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'sitesbenefitstates') {
                e.selectedData = [];
                e.showBool = true;
                e.loading = true;
                this.schedulerService.getWCSiteList(this.reportId, this.clientId).subscribe((response: any) => {
                  e.loading = false;
                  response?.body?.forEach(c => {
                    c.label = + c.siteCode + '-' + c.siteDesc;
                    c.value = c.siteCode;
                    c.selected = false;
                  })
                  e.options = response?.body;
                })
  
              }
              if (e.id.toLowerCase() == 'subgroup') {
                e.selectedData = [];
                this.parameters[e.label] = '';
                e.options = [];
                e.showBool = true;
                e.loading = true;
                let updatedArray = []
                this.schedulerService.getWCSubgroup(this.reportId, this.clientId).subscribe((response: any) => {
                  e.loading = false;
                  response?.body?.forEach(c => {
                    if (c.displayName !== null && c.value !== null) {
                      updatedArray.push({
                        label: c.displayName ? c.displayName : '',
                        value: c.value,
                        selected: false
                      });
                    }
                  });
                  e.options = updatedArray;
                  e.options.forEach(d=>{if(d.value.toLowerCase()=='restricted'){this.restricted=true}})
                    if (this.restricted) {
                      e.mandatory = true;
                      e.selectedData = e.options[0].value;
                      e.showBool = true;
                      e.disabled = true;
                      this.parameters[e.id] = e.options[0].value;
                      this.SubGroupId = e.options[0].value;
                      e.paraBool = false;
                    }
                })
              }
              if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroupvalues') { 
                if (this.restricted) {
                      e.mandatory = true;
                      e.showBool = true;
                      e.loading = true;
                      let updatedArray = [];
                      const selectedValues=[];
                      e.selectedData = [];
                      this.parameters[e.id] = [];
                      this.schedulerService.getSubGroupValue(this.reportId, this.clientId, this.SubGroupId, this.SelectedRequestedPage).subscribe((response: any) => {
                        e.loading = false;
                        response?.body?.forEach(c => {
                          if (c.displayName !== null && c.value !== null) {
                            updatedArray.push({
                              label: c.displayName ? c.displayName : '',
                              value: c.value,
                              selected: true
                            });
                            selectedValues.push(c.value);
                          }
                        });
                        e.options = updatedArray;
                        e.selectedData = selectedValues;
                        this.parameters[e.id] = selectedValues;
                        e.paraBool = false;
                      })
                   
                }
                else{
                  e.selectedData = []; e.showBool = false; delete this.parameters[e.id];
                }
                 
              }
            })
          }
          if (this.runReport1.toLowerCase().replace(/[_ ]/g, '') === 'benefitstate,subgroup') {
            this.mainData.filter(e => {
              if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'sitesbenefitstates') {
                e.selectedData = [];
                e.showBool = true;
                e.loading = true;
                this.schedulerService.getWCBenefitList(this.reportId, this.clientId).subscribe((response: any) => {
                  e.loading = false;
                  response?.body?.forEach(c => {
                    c.label = c.benefitState;
                    c.value = c.ncciStateJurisdictionCode;
                    c.selected = false;
                  })
                  e.options = response?.body;
                })
  
              }
              if (e.id.toLowerCase() == 'subgroup') {
                e.selectedData = [];
                this.parameters[e.label] = '';
                e.options = [];
                e.showBool = true;
                e.loading = true;
                let updatedArray = []
                this.schedulerService.getWCSubgroup(this.reportId, this.clientId).subscribe((response: any) => {
                  e.loading = false;
                  response?.body?.forEach(c => {
                    if (c.displayName !== null && c.value !== null) {
                      updatedArray.push({
                        label: c.displayName ? c.displayName : '',
                        value: c.value,
                        selected: false
                      });
                    }
                  });
                  e.options = updatedArray;
                  e.options.forEach(d=>{if(d.value.toLowerCase()=='restricted'){this.restricted=true}})
                    if (this.restricted) {
                      e.mandatory = true;
                      e.selectedData = e.options[0].value;
                      e.showBool = true;
                      e.disabled = true;
                      this.parameters[e.id] = e.options[0].value;
                      this.SubGroupId = e.options[0].value;
                      e.paraBool = false;
                    }
                })
              }
              if (e.id.toLowerCase() == 'policies') { e.selectedData = []; e.showBool = false; delete this.parameters[e.id]; }
              if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroupvalues') { 
                if (this.restricted) {
                      e.mandatory = true;
                      e.showBool = true;
                      e.loading = true;
                      let updatedArray = [];
                      const selectedValues=[];
                      e.selectedData = [];
                      this.parameters[e.id] = [];
                      this.schedulerService.getSubGroupValue(this.reportId, this.clientId, this.SubGroupId, this.SelectedRequestedPage).subscribe((response: any) => {
                        e.loading = false;
                        response?.body?.forEach(c => {
                          if (c.displayName !== null && c.value !== null) {
                            updatedArray.push({
                              label: c.displayName ? c.displayName : '',
                              value: c.value,
                              selected: true
                            });
                            selectedValues.push(c.value);
                          }
                        });
                        e.options = updatedArray;
                        e.selectedData = selectedValues;
                        this.parameters[e.id] = selectedValues;
                        e.paraBool = false;
                      })
                   
                }
                else{
                  e.selectedData = []; e.showBool = false; delete this.parameters[e.id];
                }
                 
              }
            })
          }
          if (this.runReport1.toLowerCase().replace(/[_ ]/g, '') === 'site,subgroup') {
            this.mainData.filter(e => {
  
              if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'sitesbenefitstates') {
                e.selectedData = [];
                e.showBool = true;
                e.loading = true;
                this.schedulerService.getWCSiteList(this.reportId, this.clientId).subscribe((response: any) => {
                  e.loading = false;
                  response?.body?.forEach(c => {
                    c.label = + c.siteCode + '-' + c.siteDesc;
                    c.value = c.siteCode;
                    c.selected = false;
                  })
                  e.options = response?.body;
                })
  
              }
              if (e.id.toLowerCase() == 'subgroup') {
                e.selectedData = [];
                this.parameters[e.label] = '';
                e.options = [];
                e.showBool = true;
                e.loading = true;
                let updatedArray = []
                this.schedulerService.getWCSubgroup(this.reportId, this.clientId).subscribe((response: any) => {
                  e.loading = false;
                  response?.body?.forEach(c => {
                    if (c.displayName !== null && c.value !== null) {
                      updatedArray.push({
                        label: c.displayName ? c.displayName : '',
                        value: c.value,
                        selected: false
                      });
                    }
                  });
                  e.options = updatedArray;
                  e.options.forEach(d=>{if(d.value.toLowerCase()=='restricted'){this.restricted=true}})
                    if (this.restricted) {
                      e.mandatory = true;
                      e.selectedData = e.options[0].value;
                      e.showBool = true;
                      e.disabled = true;
                      this.parameters[e.id] = e.options[0].value;
                      this.SubGroupId = e.options[0].value;
                      e.paraBool = false;
                    }
                })
              }
              if (e.id.toLowerCase() == 'policies') { e.selectedData = []; e.showBool = false; delete this.parameters[e.id]; }
              if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroupvalues') { 
                // this.mainData.filter(e => {
                //   if(e.id.toLowerCase() == 'subgroup'){e.options[0].value.toLowerCase()=='restricted'?this.restricted}
                // })
                if (this.restricted) {
                      e.mandatory = true;
                      e.showBool = true;
                      e.loading = true;
                      let updatedArray = [];
                      const selectedValues=[];
                      e.selectedData = [];
                      this.parameters[e.id] = [];
                      this.schedulerService.getSubGroupValue(this.reportId, this.clientId, this.SubGroupId, this.SelectedRequestedPage).subscribe((response: any) => {
                        e.loading = false;
                        response?.body?.forEach(c => {
                          if (c.displayName !== null && c.value !== null) {
                            updatedArray.push({
                              label: c.displayName ? c.displayName : '',
                              value: c.value,
                              selected: true
                            });
                            selectedValues.push(c.value);
                          }
                        });
                        e.options = updatedArray;
                        e.selectedData = selectedValues;
                        this.parameters[e.id] = selectedValues;
                        e.paraBool = false;
                      })
                   
                }
                else{
                  e.selectedData = []; e.showBool = false; delete this.parameters[e.id];
                }
                 
              }
            })
          }
        }
      }
      if (this.mainData[index].id.toLowerCase().replace(/[_ ]/g, '') == 'runreportby') {
        let updatedArray = [];
        this.runReport1='';
        this.runReport1=event;
        this.schedulerService.getWCSubgroup(this.reportId, this.clientId).subscribe((response: any) => {
          response?.body?.forEach(c => {
            if (c.displayName !== null && c.value !== null) {
              updatedArray.push({
                label: c.displayName ? c.displayName : '',
                value: c.value,
                selected: false
              });
            }
          });
          updatedArray.forEach(d=>{if(d.value.toLowerCase()=='restricted'){this.restricted=true}})
            this.mainData.filter(e => {
              if (e.id.toLowerCase() == 'subgroup' || e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroupvalues' || e.id.toLowerCase() == 'policies' || e.id.toLowerCase().replace(/[_ ]/g, '') == 'sitesbenefitstates') 
                { e.selectedData = []; e.showBool = false; delete this.parameters[e.id];  }
            })
     
        if (event.toLowerCase().replace(/[_ ]/g, '') === 'policyonly') {
          this.mainData.filter(e => {
            if (e.id.toLowerCase() == 'policies') {
              e.selectedData = [];
              e.showBool = true;
              e.loading = true;
              this.schedulerService.getWCPolicyList(this.reportId, this.clientId).subscribe((response: any) => {
                e.loading = false;
                response?.body?.forEach(c => {
                  c.value = c.policyNumber;
                  c.label = c.policyNumber + '-' + c.effectiveDate + '-' + c.expiryDate;
                  c.selected = false;
                })
                e.options = response?.body;
              })

            }
            if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'sitesbenefitstates') { e.selectedData = []; e.showBool = false; delete this.parameters[e.id]; }
            if (e.id.toLowerCase() == 'subgroup') { e.selectedData = []; e.showBool = false; delete this.parameters[e.id]; }
            if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroupvalues') { e.selectedData = []; e.showBool = false; delete this.parameters[e.id]; }
          })
        }
        if (event.toLowerCase().replace(/[_ ]/g, '') === 'benefitstateonly') {
          this.mainData.filter(e => {
            if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'sitesbenefitstates') {
              e.selectedData = [];
              e.showBool = true;
              e.loading = true;
              this.schedulerService.getWCBenefitList(this.reportId, this.clientId).subscribe((response: any) => {
                e.loading = false;
                response?.body?.forEach(c => {
                  c.label = c.benefitState;
                  c.value = c.ncciStateJurisdictionCode;
                  c.selected = false;
                })
                e.options = response?.body;
              })
            }
            if (e.id.toLowerCase() == 'policies') { e.selectedData = []; e.showBool = false; delete this.parameters[e.id]; }
            if (e.id.toLowerCase() == 'subgroup') { e.selectedData = []; e.showBool = false; delete this.parameters[e.id]; }
            if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroupvalues') { e.selectedData = []; e.showBool = false; delete this.parameters[e.id]; }
          })
        }
        if (event.toLowerCase().replace(/[_ ]/g, '') === 'siteonly') {
          this.mainData.filter(e => {
            if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'sitesbenefitstates') {
              e.selectedData = [];
              e.showBool = true;
              e.loading = true;
              this.schedulerService.getWCSiteList(this.reportId, this.clientId).subscribe((response: any) => {
                e.loading = false;
                response?.body?.forEach(c => {
                  c.label = c.siteCode + '-' + c.siteDesc;
                  c.value = c.siteCode;
                  c.selected = false;
                })
                e.options = response?.body;
              })

            }
            if (e.id.toLowerCase() == 'policies') { e.selectedData = []; e.showBool = false; delete this.parameters[e.id]; }
            if (e.id.toLowerCase() == 'subgroup') { e.selectedData = []; e.showBool = false; delete this.parameters[e.id]; }
            if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroupvalues') { e.selectedData = []; e.showBool = false; delete this.parameters[e.id]; }
          })
        }
        if (event.toLowerCase().replace(/[_ ]/g, '') === 'policy,benefitstate') {
          this.mainData.filter(e => {
            if (e.id.toLowerCase() == 'policies') {
              e.selectedData = [];
              e.showBool = true;
              e.loading = true;
              this.schedulerService.getWCPolicyList(this.reportId, this.clientId).subscribe((response: any) => {
                e.loading = false;
                response?.body?.forEach(c => {
                  c.value = c.policyNumber;
                  c.label = c.policyNumber + '-' + c.effectiveDate + '-' + c.expiryDate;
                  c.selected = false;
                })
                e.options = response?.body;
              })

            }
            if (e.id.toLowerCase().replace(/[_ ]/g, '') === 'sitesbenefitstates') {
              e.selectedData = [];
              e.showBool = true;
              e.loading = true;
              this.schedulerService.getWCBenefitList(this.reportId, this.clientId).subscribe((response: any) => {
                e.loading = false;
                response?.body?.forEach(c => {
                  c.label = c.benefitState;
                  c.value = c.ncciStateJurisdictionCode;
                  c.selected = false;
                })
                e.options = response?.body;
              })

            }
            if (e.id.toLowerCase() === 'subgroup') { e.selectedData = []; e.showBool = false; delete this.parameters[e.id]; }
            if (e.id.toLowerCase().replace(/[_ ]/g, '') === 'subgroupvalues') { e.selectedData = []; e.showBool = false; delete this.parameters[e.id]; }
          })
        }
        if (event.toLowerCase().replace(/[_ ]/g, '') === 'policy,site') {
          this.mainData.filter(e => {
            if (e.id.toLowerCase() == 'policies') {
              e.selectedData = [];
              e.showBool = true;
              e.loading = true;
              this.schedulerService.getWCPolicyList(this.reportId, this.clientId).subscribe((response: any) => {
                e.loading = false;
                response?.body?.forEach(c => {
                  c.value = c.policyNumber;
                  c.label = c.policyNumber + '-' + c.effectiveDate + '-' + c.expiryDate;
                  c.selected = false;
                })
                e.options = response?.body;
              })

            }
            if (e.id.toLowerCase().replace(/[_ ]/g, '') === 'sitesbenefitstates') {
              e.selectedData = [];
              e.showBool = true;
              e.loading = true;
              this.schedulerService.getWCSiteList(this.reportId, this.clientId).subscribe((response: any) => {
                e.loading = false;
                response?.body?.forEach(c => {
                  c.label = c.siteCode + '-' + c.siteDesc;
                  c.value = c.siteCode;
                  c.selected = false;
                })
                e.options = response?.body;
              })

            }
            if (e.id.toLowerCase() == 'subgroup') { e.selectedData = []; e.showBool = false; delete this.parameters[e.id]; }
            if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroupvalues') { e.selectedData = []; e.showBool = false; delete this.parameters[e.id]; }
          })
        }
        if (event.toLowerCase().replace(/[_ ]/g, '') === 'policy,benefitstate,subgroup') {
          this.mainData.filter(e => {
            if (e.id.toLowerCase() == 'policies') {
              e.selectedData = [];
              e.showBool = true;
              e.loading = true;
              this.schedulerService.getWCPolicyList(this.reportId, this.clientId).subscribe((response: any) => {
                e.loading = false;
                response?.body?.forEach(c => {
                  c.value = c.policyNumber;
                  c.label = c.policyNumber + '-' + c.effectiveDate + '-' + c.expiryDate;
                  c.selected = false;
                })
                e.options = response?.body;
              })

            }
            if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'sitesbenefitstates') {
              e.selectedData = [];
              e.showBool = true;
              e.loading = true;
              this.schedulerService.getWCBenefitList(this.reportId, this.clientId).subscribe((response: any) => {
                e.loading = false;
                response?.body?.forEach(c => {
                  c.label = c.benefitState;
                  c.value = c.ncciStateJurisdictionCode;
                  c.selected = false;
                })
                e.options = response?.body;
              })

            }
            if (e.id.toLowerCase() == 'subgroup') {
              e.selectedData = [];
              this.parameters[e.label] = '';
              e.options = [];
              e.showBool = true;
              e.loading = true;
              let updatedArray = []
              this.schedulerService.getWCSubgroup(this.reportId, this.clientId).subscribe((response: any) => {
                e.loading = false;
                response?.body?.forEach(c => {
                  if (c.displayName !== null && c.value !== null) {
                    updatedArray.push({
                      label: c.displayName ? c.displayName : '',
                      value: c.value,
                      selected: false
                    });
                  }
                });
                e.options = updatedArray;
                e.options.forEach(d=>{if(d.value.toLowerCase()=='restricted'){this.restricted=true}})
                if (this.restricted) {
                  e.mandatory = true;
                  e.selectedData = e.options[0].value;
                  e.showBool = true;
                  e.disabled = true;
                  this.parameters[e.id] = e.options[0].value;
                  this.SubGroupId = e.options[0].value;
                  e.paraBool = false;
                }
              })
            }
            if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroupvalues') { 
              if (this.restricted) {
                    e.mandatory = true;
                    e.showBool = true;
                    e.loading = true;
                    let updatedArray = [];
                    const selectedValues=[];
                    e.selectedData = [];
                    this.parameters[e.id] = [];
                    this.schedulerService.getSubGroupValue(this.reportId, this.clientId, this.SubGroupId, this.SelectedRequestedPage).subscribe((response: any) => {
                      e.loading = false;
                      response?.body?.forEach(c => {
                        if (c.displayName !== null && c.value !== null) {
                          updatedArray.push({
                            label: c.displayName ? c.displayName : '',
                            value: c.value,
                            selected: true
                          });
                          selectedValues.push(c.value);
                        }
                      });
                      e.options = updatedArray;
                      e.selectedData = selectedValues;
                      this.parameters[e.id] = selectedValues;
                      e.paraBool = false;
                    })
                 
              }
              else{
                e.selectedData = []; e.showBool = false; delete this.parameters[e.id];
              }
               
            }
          })
        }
        if (event.toLowerCase().replace(/[_ ]/g, '') === 'policy,site,subgroup') {
          this.mainData.filter(e => {
            if (e.id.toLowerCase() == 'policies') {
              e.selectedData = [];
              e.showBool = true;
              e.loading = true;
              this.schedulerService.getWCPolicyList(this.reportId, this.clientId).subscribe((response: any) => {
                e.loading = false;
                response?.body?.forEach(c => {
                  c.value = c.policyNumber;
                  c.label = c.policyNumber + '-' + c.effectiveDate + '-' + c.expiryDate;
                  c.selected = false;
                })
                e.options = response?.body;
              })

            }
            if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'sitesbenefitstates') {
              e.selectedData = [];
              e.showBool = true;
              e.loading = true;
              this.schedulerService.getWCSiteList(this.reportId, this.clientId).subscribe((response: any) => {
                e.loading = false;
                response?.body?.forEach(c => {
                  c.label = + c.siteCode + '-' + c.siteDesc;
                  c.value = c.siteCode;
                  c.selected = false;
                })
                e.options = response?.body;
              })

            }
            if (e.id.toLowerCase() == 'subgroup') {
              e.selectedData = [];
              this.parameters[e.label] = '';
              e.options = [];
              e.showBool = true;
              e.loading = true;
              let updatedArray = []
              this.schedulerService.getWCSubgroup(this.reportId, this.clientId).subscribe((response: any) => {
                e.loading = false;
                response?.body?.forEach(c => {
                  if (c.displayName !== null && c.value !== null) {
                    updatedArray.push({
                      label: c.displayName ? c.displayName : '',
                      value: c.value,
                      selected: false
                    });
                  }
                });
                e.options = updatedArray;
                e.options.forEach(d=>{if(d.value.toLowerCase()=='restricted'){this.restricted=true}})
                  if (this.restricted) {
                    e.mandatory = true;
                    e.selectedData = e.options[0].value;
                    e.showBool = true;
                    e.disabled = true;
                    this.parameters[e.id] = e.options[0].value;
                    this.SubGroupId = e.options[0].value;
                    e.paraBool = false;
                  }
              })
            }
            if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroupvalues') { 
              if (this.restricted) {
                    e.mandatory = true;
                    e.showBool = true;
                    e.loading = true;
                    let updatedArray = [];
                    const selectedValues=[];
                    e.selectedData = [];
                    this.parameters[e.id] = [];
                    this.schedulerService.getSubGroupValue(this.reportId, this.clientId, this.SubGroupId, this.SelectedRequestedPage).subscribe((response: any) => {
                      e.loading = false;
                      response?.body?.forEach(c => {
                        if (c.displayName !== null && c.value !== null) {
                          updatedArray.push({
                            label: c.displayName ? c.displayName : '',
                            value: c.value,
                            selected: true
                          });
                          selectedValues.push(c.value);
                        }
                      });
                      e.options = updatedArray;
                      e.selectedData = selectedValues;
                      this.parameters[e.id] = selectedValues;
                      e.paraBool = false;
                    })
                 
              }
              else{
                e.selectedData = []; e.showBool = false; delete this.parameters[e.id];
              }
               
            }
          })
        }
        if (event.toLowerCase().replace(/[_ ]/g, '') === 'benefitstate,subgroup') {
          this.mainData.filter(e => {
            if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'sitesbenefitstates') {
              e.selectedData = [];
              e.showBool = true;
              e.loading = true;
              this.schedulerService.getWCBenefitList(this.reportId, this.clientId).subscribe((response: any) => {
                e.loading = false;
                response?.body?.forEach(c => {
                  c.label = c.benefitState;
                  c.value = c.ncciStateJurisdictionCode;
                  c.selected = false;
                })
                e.options = response?.body;
              })

            }
            if (e.id.toLowerCase() == 'subgroup') {
              e.selectedData = [];
              this.parameters[e.label] = '';
              e.options = [];
              e.showBool = true;
              e.loading = true;
              let updatedArray = []
              this.schedulerService.getWCSubgroup(this.reportId, this.clientId).subscribe((response: any) => {
                e.loading = false;
                response?.body?.forEach(c => {
                  if (c.displayName !== null && c.value !== null) {
                    updatedArray.push({
                      label: c.displayName ? c.displayName : '',
                      value: c.value,
                      selected: false
                    });
                  }
                });
                e.options = updatedArray;
                e.options.forEach(d=>{if(d.value.toLowerCase()=='restricted'){this.restricted=true}})
                  if (this.restricted) {
                    e.mandatory = true;
                    e.selectedData = e.options[0].value;
                    e.showBool = true;
                    e.disabled = true;
                    this.parameters[e.id] = e.options[0].value;
                    this.SubGroupId = e.options[0].value;
                    e.paraBool = false;
                  }
              })
            }
            if (e.id.toLowerCase() == 'policies') { e.selectedData = []; e.showBool = false; delete this.parameters[e.id]; }
            if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroupvalues') { 
              if (this.restricted) {
                    e.mandatory = true;
                    e.showBool = true;
                    e.loading = true;
                    let updatedArray = [];
                    const selectedValues=[];
                    e.selectedData = [];
                    this.parameters[e.id] = [];
                    this.schedulerService.getSubGroupValue(this.reportId, this.clientId, this.SubGroupId, this.SelectedRequestedPage).subscribe((response: any) => {
                      e.loading = false;
                      response?.body?.forEach(c => {
                        if (c.displayName !== null && c.value !== null) {
                          updatedArray.push({
                            label: c.displayName ? c.displayName : '',
                            value: c.value,
                            selected: true
                          });
                          selectedValues.push(c.value);
                        }
                      });
                      e.options = updatedArray;
                      e.selectedData = selectedValues;
                      this.parameters[e.id] = selectedValues;
                      e.paraBool = false;
                    })
                 
              }
              else{
                e.selectedData = []; e.showBool = false; delete this.parameters[e.id];
              }
               
            }
          })
        }
        if (event.toLowerCase().replace(/[_ ]/g, '') === 'site,subgroup') {
          this.mainData.filter(e => {

            if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'sitesbenefitstates') {
              e.selectedData = [];
              e.showBool = true;
              e.loading = true;
              this.schedulerService.getWCSiteList(this.reportId, this.clientId).subscribe((response: any) => {
                e.loading = false;
                response?.body?.forEach(c => {
                  c.label = + c.siteCode + '-' + c.siteDesc;
                  c.value = c.siteCode;
                  c.selected = false;
                })
                e.options = response?.body;
              })

            }
            if (e.id.toLowerCase() == 'subgroup') {
              e.selectedData = [];
              this.parameters[e.label] = '';
              e.options = [];
              e.showBool = true;
              e.loading = true;
              let updatedArray = []
              this.schedulerService.getWCSubgroup(this.reportId, this.clientId).subscribe((response: any) => {
                e.loading = false;
                response?.body?.forEach(c => {
                  if (c.displayName !== null && c.value !== null) {
                    updatedArray.push({
                      label: c.displayName ? c.displayName : '',
                      value: c.value,
                      selected: false
                    });
                  }
                });
                e.options = updatedArray;
                e.options.forEach(d=>{if(d.value.toLowerCase()=='restricted'){this.restricted=true}})
                  if (this.restricted) {
                    e.mandatory = true;
                    e.selectedData = e.options[0].value;
                    e.showBool = true;
                    e.disabled = true;
                    this.parameters[e.id] = e.options[0].value;
                    this.SubGroupId = e.options[0].value;
                    e.paraBool = false;
                  }
              })
            }
            if (e.id.toLowerCase() == 'policies') { e.selectedData = []; e.showBool = false; delete this.parameters[e.id]; }
            if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroupvalues') { 
              // this.mainData.filter(e => {
              //   if(e.id.toLowerCase() == 'subgroup'){e.options[0].value.toLowerCase()=='restricted'?this.restricted}
              // })
              if (this.restricted) {
                    e.mandatory = true;
                    e.showBool = true;
                    e.loading = true;
                    let updatedArray = [];
                    const selectedValues=[];
                    e.selectedData = [];
                    this.parameters[e.id] = [];
                    this.schedulerService.getSubGroupValue(this.reportId, this.clientId, this.SubGroupId, this.SelectedRequestedPage).subscribe((response: any) => {
                      e.loading = false;
                      response?.body?.forEach(c => {
                        if (c.displayName !== null && c.value !== null) {
                          updatedArray.push({
                            label: c.displayName ? c.displayName : '',
                            value: c.value,
                            selected: true
                          });
                          selectedValues.push(c.value);
                        }
                      });
                      e.options = updatedArray;
                      e.selectedData = selectedValues;
                      this.parameters[e.id] = selectedValues;
                      e.paraBool = false;
                    })
                 
              }
              else{
                e.selectedData = []; e.showBool = false; delete this.parameters[e.id];
              }
               
            }
          })
        }
      })
      }
      if (this.mainData[index].id.toLowerCase() == 'subgroup') {
        let dat = this.mainData[index].options?.filter((t) => t.value === event)
        this.SubGroupId = dat[0]?.value;
        this.isLoading2 = true;
        this.mainData.filter(e => {
          if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroupvalues') {
            e.showBool = true;
            e.loading = true;
            let updatedArray = [];
            e.selectedData = [];
            this.parameters[e.id] = [];
            this.schedulerService.getSubGroupValue(this.reportId, this.clientId, this.SubGroupId, this.SelectedRequestedPage).subscribe((response: any) => {
              e.loading = false;
              response?.body?.forEach(c => {
                if (c.displayName !== null && c.value !== null) {
                  updatedArray.push({
                    label: c.displayName ? c.displayName : '',
                    value: c.value,
                    selected: false
                  });
                }
              });
              e.options = updatedArray;

            })
          }
        })
      }
    }
    if (this.SelectedRequestedPage == 'WC_All') {
      if (this.mainData[index].id.toLowerCase() == 'clients') {
        this.clientId = '';
        let dat = this.mainData[index].options?.filter((t) => t.value === event)
        this.clientId = dat[0]?.value;
        this.mainData.filter(e => {
          if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroupvalues' || e.id.toLowerCase().replace(/[_ ]/g, '') == 'policies' ||e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroup') {
            e.selectedData = []; e.showBool = false; delete this.parameters[e.id];
          }})
          if(this.runReport2!=''){
            if (this.runReport2.toLowerCase().replace(/[_ ]/g, '') === 'allpolicy') {
              this.mainData.filter(e => {
                if (e.id.toLowerCase() == 'subgroup') {
                  e.selectedData = [];
                  this.parameters[e.label] = '';
                  e.options = [];
                  e.showBool = true;
                  e.loading = true;
                  let updatedArray = []
                  this.schedulerService.getWCSubgroup(this.reportId, this.clientId).subscribe((response: any) => {
                    e.loading = false;
                    response?.body?.forEach(c => {
                      if (c.displayName !== null && c.value !== null) {
                        updatedArray.push({
                          label: c.displayName ? c.displayName : '',
                          value: c.value,
                          selected: false
                        });
                      }
                    });
                    e.options = updatedArray;
                    e.options.forEach(d=>{if(d.value.toLowerCase()=='restricted'){this.restricted=true}})
                      if (this.restricted) {
                        e.mandatory = true;
                        e.selectedData = e.options[0].value;
                        e.showBool = true;
                        e.disabled = true;
                        this.parameters[e.id] = e.options[0].value;
                        this.SubGroupId = e.options[0].value;
                        e.paraBool = false;
                      }
                  })
                }
                if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroupvalues') {
                  if (this.restricted) {
                    e.mandatory = true;
                    let updatedArray = [];
                    const selectedValues = [];
                    this.schedulerService.getSubGroupValue(this.reportId, this.clientId, this.SubGroupId, this.SelectedRequestedPage).subscribe((response: any) => {
                      e.loading = false;
                      e.showBool = true;
                      response?.body?.forEach(c => {
                        if (c.displayName !== null && c.value !== null) {
                          updatedArray.push({
                            label: c.displayName ? c.displayName : '',
                            value: c.value,
                            selected: true
                          });
                          selectedValues.push(c.value)
                        }
                      });
                      e.options = updatedArray;
                      e.selectedData = selectedValues;
                      this.parameters[e.id] = selectedValues;
                      e.paraBool = false;
                    })
                  }
                  else {
                    e.selectedData = []; e.showBool = false; delete this.parameters[e.id];
                  }
                }
               
              })
            }
            if (this.runReport2.toLowerCase().replace(/[_ ]/g, '') === 'policyonly') {
              this.mainData.filter(e => {
                if (e.id.toLowerCase() == 'policies') {
                  e.selectedData = [];
                  e.showBool = true;
                  e.loading = true;
                  this.schedulerService.getWCPolicyList(this.reportId, this.clientId).subscribe((response: any) => {
                    e.loading = false;
                    response?.body?.forEach(c => {
                      c.value = c.policyNumber;
                      c.label = c.policyNumber + '-' + c.effectiveDate + '-' + c.expiryDate;
                      c.selected = false;
                    })
                    e.options = response?.body;
                  })
                }
                if (e.id.toLowerCase() == 'subgroup') {
                  e.selectedData = [];
                  this.parameters[e.label] = '';
                  e.options = [];
                  e.showBool = true;
                  e.loading = true;
                  let updatedArray = []
                  this.schedulerService.getWCSubgroup(this.reportId, this.clientId).subscribe((response: any) => {
                    e.loading = false;
                    response?.body?.forEach(c => {
                      if (c.displayName !== null && c.value !== null) {
                        updatedArray.push({
                          label: c.displayName ? c.displayName : '',
                          value: c.value,
                          selected: false
                        });
                      }
                    });
                    e.options = updatedArray;
                    e.options.forEach(d=>{if(d.value.toLowerCase()=='restricted'){this.restricted=true}})
                      if (this.restricted) {
                        e.mandatory = true;
                        e.selectedData = e.options[0].value;
                        e.showBool = true;
                        e.disabled = true;
                        this.parameters[e.id] = e.options[0].value;
                        this.SubGroupId = e.options[0].value;
                        e.paraBool = false;
                      }
                  })
                }
                if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroupvalues') {
                  if (this.restricted) {
                    e.mandatory = true;
                    let updatedArray = [];
                    const selectedValues = [];
                    this.schedulerService.getSubGroupValue(this.reportId, this.clientId, this.SubGroupId, this.SelectedRequestedPage).subscribe((response: any) => {
                      e.loading = false;
                      e.showBool = true;
                      response?.body?.forEach(c => {
                        if (c.displayName !== null && c.value !== null) {
                          updatedArray.push({
                            label: c.displayName ? c.displayName : '',
                            value: c.value,
                            selected: true
                          });
                          selectedValues.push(c.value)
                        }
                      });
                      e.options = updatedArray;
                      e.selectedData = selectedValues;
                      this.parameters[e.id] = selectedValues;
                      e.paraBool = false;
                    })
                  }
                  else {
                    e.selectedData = []; e.showBool = false; delete this.parameters[e.id];
                  }
                }
              })
            }
          }
      }
      if (this.mainData[index].id.toLowerCase().replace(/[_ ]/g, '') == 'runreportby') {
        this.runReport2=event;
        if (event.toLowerCase().replace(/[_ ]/g, '') === 'allpolicy') {
          this.mainData.filter(e => {
            if (e.id.toLowerCase() == 'subgroup') {
              e.selectedData = [];
              this.parameters[e.label] = '';
              e.options = [];
              e.showBool = true;
              e.loading = true;
              let updatedArray = []
              this.schedulerService.getWCSubgroup(this.reportId, this.clientId).subscribe((response: any) => {
                e.loading = false;
                response?.body?.forEach(c => {
                  if (c.displayName !== null && c.value !== null) {
                    updatedArray.push({
                      label: c.displayName ? c.displayName : '',
                      value: c.value,
                      selected: false
                    });
                  }
                });
                e.options = updatedArray;
                e.options.forEach(d=>{if(d.value.toLowerCase()=='restricted'){this.restricted=true}})
                  if (this.restricted) {
                    e.mandatory = true;
                    e.selectedData = e.options[0].value;
                    e.showBool = true;
                    e.disabled = true;
                    this.parameters[e.id] = e.options[0].value;
                    this.SubGroupId = e.options[0].value;
                    e.paraBool = false;
                  }
              })
            }
            if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroupvalues') {
              if (this.restricted) {
                e.mandatory = true;
                let updatedArray = [];
                const selectedValues = [];
                this.schedulerService.getSubGroupValue(this.reportId, this.clientId, this.SubGroupId, this.SelectedRequestedPage).subscribe((response: any) => {
                  e.loading = false;
                  e.showBool = true;
                  response?.body?.forEach(c => {
                    if (c.displayName !== null && c.value !== null) {
                      updatedArray.push({
                        label: c.displayName ? c.displayName : '',
                        value: c.value,
                        selected: true
                      });
                      selectedValues.push(c.value)
                    }
                  });
                  e.options = updatedArray;
                  e.selectedData = selectedValues;
                  this.parameters[e.id] = selectedValues;
                  e.paraBool = false;
                })
              }
              else {
                e.selectedData = []; e.showBool = false; delete this.parameters[e.id];
              }
            }
            if (e.id.toLowerCase() == 'policies') { e.selectedData = []; e.showBool = false; delete this.parameters[e.id]; }
          })
        }
        if (event.toLowerCase().replace(/[_ ]/g, '') === 'policyonly') {
          this.mainData.filter(e => {
            if (e.id.toLowerCase() == 'policies') {
              e.selectedData = [];
              e.showBool = true;
              e.loading = true;
              this.schedulerService.getWCPolicyList(this.reportId, this.clientId).subscribe((response: any) => {
                e.loading = false;
                response?.body?.forEach(c => {
                  c.value = c.policyNumber;
                  c.label = c.policyNumber + '-' + c.effectiveDate + '-' + c.expiryDate;
                  c.selected = false;
                })
                e.options = response?.body;
              })
            }
            if (e.id.toLowerCase() == 'subgroup') {
              e.selectedData = [];
              this.parameters[e.label] = '';
              e.options = [];
              e.showBool = true;
              e.loading = true;
              let updatedArray = []
              this.schedulerService.getWCSubgroup(this.reportId, this.clientId).subscribe((response: any) => {
                e.loading = false;
                response?.body?.forEach(c => {
                  if (c.displayName !== null && c.value !== null) {
                    updatedArray.push({
                      label: c.displayName ? c.displayName : '',
                      value: c.value,
                      selected: false
                    });
                  }
                });
                e.options = updatedArray;
                e.options.forEach(d=>{if(d.value.toLowerCase()=='restricted'){this.restricted=true}})
                  if (this.restricted) {
                    e.mandatory = true;
                    e.selectedData = e.options[0].value;
                    e.showBool = true;
                    e.disabled = true;
                    this.parameters[e.id] = e.options[0].value;
                    this.SubGroupId = e.options[0].value;
                    e.paraBool = false;
                  }
              })
            }
            if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroupvalues') {
              if (this.restricted) {
                e.mandatory = true;
                let updatedArray = [];
                const selectedValues = [];
                this.schedulerService.getSubGroupValue(this.reportId, this.clientId, this.SubGroupId, this.SelectedRequestedPage).subscribe((response: any) => {
                  e.loading = false;
                  e.showBool = true;
                  response?.body?.forEach(c => {
                    if (c.displayName !== null && c.value !== null) {
                      updatedArray.push({
                        label: c.displayName ? c.displayName : '',
                        value: c.value,
                        selected: true
                      });
                      selectedValues.push(c.value)
                    }
                  });
                  e.options = updatedArray;
                  e.selectedData = selectedValues;
                  this.parameters[e.id] = selectedValues;
                  e.paraBool = false;
                })
              }
              else {
                e.selectedData = []; e.showBool = false; delete this.parameters[e.id];
              }
            }
          })
        }
      }
      if (this.mainData[index].id.toLowerCase() == 'subgroup') {
        let dat = this.mainData[index].options?.filter((t) => t.value === event)
        this.SubGroupId = dat[0]?.value;
        this.isLoading2 = true;
        this.mainData.filter(e => {
          if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroupvalues') {
            e.showBool = true;
            e.loading = true;
            let updatedArray = [];
            e.selectedData = [];
            this.parameters[e.id] = [];
            this.schedulerService.getSubGroupValue(this.reportId, this.clientId, this.SubGroupId, this.SelectedRequestedPage).subscribe((response: any) => {
              e.loading = false;
              response?.body?.forEach(c => {
                if (c.displayName !== null && c.value !== null) {
                  updatedArray.push({
                    label: c.displayName ? c.displayName : '',
                    value: c.value,
                    selected: false
                  });
                }
              });
              e.options = updatedArray;
            })
          }
        })
      }
    }
    if (this.SelectedRequestedPage == 'BCD') {
      if (this.mainData[index].id.toLowerCase().replace(/[_ ]/g, '') == 'clientnames') {
        this.clientId = '';
        let dat = this.mainData[index].options?.filter((t) => t.value === event)
        this.clientId = dat[0]?.value;
        this.mainData.filter(e => {
          if (e.id.toLowerCase() == 'subgroup') {
            e.selectedData = [];
            this.parameters[e.label] = '';
            e.options = [];
            e.showBool = true;
            e.loading = true;
            let updatedArray = []
            this.schedulerService.getBCDsubgroup(this.clientId, this.reportId).subscribe((response: any) => {
              e.loading = false;
              response?.body?.forEach(c => {
                if (c.displayName !== null && c.value !== null) {
                  updatedArray.push({
                    label: c.displayName ? c.displayName : '',
                    value: c.value,
                    selected: false
                  });
                }
              });
              e.options = updatedArray;
              e.options.forEach(d=>{if(d.value.toLowerCase()=='restricted'){this.restricted=true}})
                if (this.restricted) {
                  e.mandatory = true;
                  e.selectedData = e.options[0].value;
                  e.showBool = true;
                  e.disabled = true;
                  this.parameters[e.id] = e.options[0].value;
                  this.SubGroupId = e.options[0].value;
                  e.paraBool = false;
                }
            })
          }
          if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'loapolicy') {
            e.selectedData = [];
            this.parameters[e.label] = '';
            e.options = [];
            e.showBool = true;
            e.loading = true;
            let updatedArray = []
            this.schedulerService.getBcdLOApolicy(this.clientId, this.reportId).subscribe((response: any) => {
              e.loading = false;
              response?.body?.forEach((c: any) => {
                updatedArray.push({
                  label: c,
                  value: c,
                  selected: false
                });
              });
              e.options = updatedArray;
            })
          }
          if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'stdpolicy') {
            e.selectedData = [];
            this.parameters[e.label] = '';
            e.options = [];
            e.showBool = true;
            e.loading = true;
            let updatedArray = []
            this.schedulerService.getBcdSTDpolicy(this.clientId, this.reportId).subscribe((response: any) => {
              e.loading = false;
              response?.body?.forEach((c: any) => {
                updatedArray.push({
                  label: c,
                  value: c,
                  selected: false
                });
              });
              e.options = updatedArray;
            })
          }
          if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'wcpolicy') {
            e.selectedData = [];
            this.parameters[e.label] = '';
            e.options = [];
            e.showBool = true;
            e.loading = true;
            let updatedArray = []
            this.schedulerService.getBcdWCpolicy(this.clientId, this.reportId).subscribe((response: any) => {
              e.loading = false;
              response?.body?.forEach((c: any) => {
                updatedArray.push({
                  label: c,
                  value: c,
                  selected: false
                });
              });
              e.options = updatedArray;
            })
          }
          if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'ltdpolicy') {
            e.selectedData = [];
            this.parameters[e.label] = '';
            e.options = [];
            e.showBool = true;
            e.loading = true;
            let updatedArray = []
            this.schedulerService.getBcdLTDpolicy(this.clientId, this.reportId).subscribe((response: any) => {
              e.loading = false;
              response?.body?.forEach((c: any) => {
                updatedArray.push({
                  label: c,
                  value: c,
                  selected: false
                });
              });
              e.options = updatedArray;
            })
          }
          if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroupvalues') {
            e.selectedData = [];
            this.parameters[e.label] = '';
            e.options = [];
            if (this.restricted) {
              e.mandatory = true;
              let updatedArray = [];
              const selectedValues = [];
              this.schedulerService.getBCDsubgroupValues(this.reportId, this.clientId, this.SubGroupId).subscribe((response: any) => {
                e.loading = false;
                e.showBool = true;
                response?.body?.forEach(c => {
                  if (c.displayName !== null && c.value !== null) {
                    updatedArray.push({
                      label: c.displayName ? c.displayName : '',
                      value: c.value,
                      selected: true
                    });
                    selectedValues.push(c.value)
                  }
                });
                e.options = updatedArray;
                e.selectedData = selectedValues;
                this.parameters[e.id] = selectedValues;
                e.paraBool = false;
              })
            }
            else {
              e.selectedData = []; e.showBool = false; delete this.parameters[e.id];
            }
          }
        })
      }
      if (this.mainData[index].id.toLowerCase() == 'subgroup') {
        this.SubGroupId = '';
        let dat = this.mainData[index].options?.filter((t) => t.value === event)
        this.SubGroupId = dat[0]?.value;
        this.mainData.filter(e => {
          if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroupvalues') {
            e.showBool = true;
            e.loading = true
            let updatedArray = [];
            e.selectedData = [];
            this.parameters[e.id] = [];
            this.schedulerService.getBCDsubgroupValues(this.clientId, this.reportId, this.SubGroupId).subscribe((response: any) => {
              e.loading = false;
              response?.body?.forEach(c => {
                if (c !== null) {
                  updatedArray.push({
                    label: c,
                    value: c,
                    selected: false
                  });
                }
              });
              e.options = updatedArray;
            })
          }
        })
      }
    }
    if (this.SelectedRequestedPage == 'CD3') {
      if (this.mainData[index].id.toLowerCase().replace(/[_ ]/g, '') == 'clientnames') {
        this.clientId = '';
        let dat = this.mainData[index].options?.filter((t) => t.value === event)
        this.clientId = dat[0]?.value;
        this.mainData.filter(e => {
          if (e.id.toLowerCase() == 'subgroup') {
            e.selectedData = [];
            this.parameters[e.label] = '';
            e.options = [];
            e.showBool = true;
            e.loading = true;
            let updatedArray = []
            this.schedulerService.getCD3Subgroup(this.reportId, this.clientId).subscribe((response: any) => {
              e.loading = false;
              response?.body?.forEach(c => {
                if (c.displayName !== null && c.value !== null) {
                  updatedArray.push({
                    label: c.displayName ? c.displayName : '',
                    value: c.value,
                    selected: false
                  });
                }
              });
              e.options = updatedArray;
              e.options.forEach(d=>{if(d.value.toLowerCase()=='restricted'){this.restricted=true}})
                if (this.restricted) {
                  e.mandatory = true;
                  e.selectedData = e.options[0].value;
                  e.showBool = true;
                  e.disabled = true;
                  this.parameters[e.id] = e.options[0].value;
                  this.SubGroupId = e.options[0].value;
                  e.paraBool = false;
                }
            })
          }
          if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'loapolicy') {
            e.selectedData = [];
            this.parameters[e.label] = '';
            e.options = [];
            e.showBool = true;
            e.loading = true;
            let updatedArray = []
            this.schedulerService.getCD3LoaPolicy(this.reportId, this.clientId).subscribe((response: any) => {
              e.loading = false;
              response?.body?.forEach((c: any) => {
                updatedArray.push({
                  label: c,
                  value: c,
                  selected: false
                });
              });
              e.options = updatedArray;
            })
          }
          if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'stdpolicy') {
            e.selectedData = [];
            this.parameters[e.label] = '';
            e.options = [];
            e.showBool = true;
            e.loading = true;
            let updatedArray = []
            this.schedulerService.getCD3StdPolicy(this.reportId, this.clientId).subscribe((response: any) => {
              e.loading = false;
              response?.body?.forEach((c: any) => {
                updatedArray.push({
                  label: c,
                  value: c,
                  selected: false
                });
              });
              e.options = updatedArray;
            })
          }
          if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'wcpolicy') {
            e.selectedData = [];
            this.parameters[e.label] = '';
            e.options = [];
            e.showBool = true;
            e.loading = true;
            let updatedArray = []
            this.schedulerService.getCD3WcPolicy(this.reportId, this.clientId).subscribe((response: any) => {
              e.loading = false;
              response?.body?.forEach((c: any) => {
                updatedArray.push({
                  label: c,
                  value: c,
                  selected: false
                });
              });
              e.options = updatedArray;
            })
          }
          if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'ltdpolicy') {
            e.selectedData = [];
            this.parameters[e.label] = '';
            e.options = [];
            e.showBool = true;
            e.loading = true;
            let updatedArray = []
            this.schedulerService.getCD3LtdPolicy(this.reportId, this.clientId).subscribe((response: any) => {
              e.loading = false;
              response?.body?.forEach((c: any) => {
                updatedArray.push({
                  label: c,
                  value: c,
                  selected: false
                });
              });
              e.options = updatedArray;
            })
          }
          if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroupvalues') {
            e.selectedData = [];
            this.parameters[e.label] = '';
            e.options = [];
            if (this.restricted) {
              e.mandatory = true;
              let updatedArray = [];
              const selectedValues = [];
              this.schedulerService.getCD2SubgroupVlaue(this.reportId, this.clientId, this.SubGroupId, this.SelectedRequestedPage).subscribe((response: any) => {
                e.loading = false;
                e.showBool = true;
                response?.body?.forEach(c => {
                  if (c.displayName !== null && c.value !== null) {
                    updatedArray.push({
                      label: c.displayName ? c.displayName : '',
                      value: c.value,
                      selected: true
                    });
                    selectedValues.push(c.value)
                  }
                });
                e.options = updatedArray;
                e.selectedData = selectedValues;
                this.parameters[e.id] = selectedValues;
                e.paraBool = false;
              })
            }
            else {
              e.selectedData = []; e.showBool = false; delete this.parameters[e.id];
            }
          }
        })
      }
      if (this.mainData[index].id.toLowerCase() == 'subgroup') {
        this.SubGroupId = '';
        let dat = this.mainData[index].options?.filter((t) => t.value === event)
        this.SubGroupId = dat[0]?.value;
        this.mainData.filter(e => {
          if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroupvalues') {
            e.showBool = true;
            e.loading = true
            let updatedArray = [];
            e.selectedData = [];
            this.parameters[e.id] = [];
            this.schedulerService.getCD2SubgroupVlaue(this.reportId, this.clientId, this.SubGroupId, this.SelectedRequestedPage).subscribe((response: any) => {
              e.loading = false;
              response?.body?.forEach(c => {
                if (c !== null) {
                  updatedArray.push({
                    label: c,
                    value: c,
                    selected: false
                  });
                }
              });
              e.options = updatedArray;
            })
          }
        })
      }
    }
    if (this.SelectedRequestedPage == 'PCR') {
      if (this.mainData[index].id.toLowerCase().replace(/[_ ]/g, '') == 'clientcode') {
        this.clientId = '';
        let dat = this.mainData[index].options?.filter((t) => t.value === event)
        this.clientId = dat[0]?.value;
        this.mainData.filter(e => {
          if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'lineofbusiness') {
            e.selectedData = [];
            this.parameters[e.label] = '';
            e.options = [];
            e.showBool = true;
            e.loading = true
            this.clientFlag = "C";
            this.parentFlag = "Y";
            let updatedArray = [];
            this.schedulerService.getPcrLOBList(this.reportId, this.clientId, this.clientFlag, this.parentFlag).subscribe((response: any) => {
              e.loading = false;
              response?.body?.forEach((c: any) => {
                updatedArray.push({
                  label: c,
                  value: c,
                  selected: false
                });
              });
              e.options = updatedArray;
            })//e.id.toLowerCase() == 'policy'||e.id.toLowerCase() == 'class'||e.id.toLowerCase() == 'plan type'||e.id.toLowerCase()=='policy type'
          }
          if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'bankaccountnumber') { e.selectedData = []; e.showBool = false; delete this.parameters[e.id]; }
        })
      }
      if (this.mainData[index].id.toLowerCase().replace(/[_ ]/g, '') == 'lineofbusiness') {
        this.SubGroupId = '';
        let dat = event.value;
        this.mainData.filter(e => {
          if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'bankaccountnumber') {
            e.showBool = true;
            e.loading = true
            this.schedulerService.getPcrBANList(this.reportId,this.clientId, dat).subscribe((response: any) => {
              e.loading = false;
              response?.body?.forEach(c => {
                c.label = c.displayName;
                delete c.displayName;
                c.selected = false;
              })
              e.options = response?.body;
            })
          }
        })
      }
    }
    if (this.SelectedRequestedPage == 'SC') {
      if (this.mainData[index].id.toLowerCase().replace(/[_ ]/g, '') == 'clientnames') {
        this.clientId = '';
        let dat = this.mainData[index].options?.filter((t) => t.value === event)
        this.clientId = dat[0]?.value;
        this.mainData.filter(e => {
          if (e.id.toLowerCase() == 'subgroup') {
            e.selectedData = [];
            this.parameters[e.label] = '';
            e.options = [];
            e.showBool = true;
            e.loading = true;
            let updatedArray = []
            this.schedulerService.getSCsubgroup(this.reportId, this.clientId).subscribe((response: any) => {
              e.loading = false;
              response?.body?.forEach((c: any) => {
                if (c.displayName !== null && c.value !== null) {
                  updatedArray.push({
                    label: c.displayName ? c.displayName : '',
                    value: c.value,
                    selected: false
                  });
                }
              });
              e.options = updatedArray;
              e.options.forEach(d=>{if(d.value.toLowerCase()=='restricted'){this.restricted=true}})
                if (this.restricted) {
                  e.mandatory = true;
                  e.selectedData = e.options[0].value;
                  e.showBool = true;
                  e.disabled = true;
                  this.parameters[e.id] = e.options[0].value;
                  this.SubGroupId = e.options[0].value;
                  e.paraBool = false;
                }
            })
          }
          if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'loapolicy') {
            e.selectedData = [];
            this.parameters[e.label] = '';
            e.options = [];
            e.showBool = true;
            e.loading = true;
            let updatedArray = []
            this.schedulerService.getScLOApolicy(this.reportId, this.clientId).subscribe((response: any) => {
              e.loading = false;
              response?.body?.forEach((c: any) => {
                updatedArray.push({
                  label: c,
                  value: c,
                  selected: false
                });
              });
              e.options = updatedArray;
            })
          }
          if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'stdpolicy') {
            e.selectedData = [];
            this.parameters[e.label] = '';
            e.options = [];
            e.showBool = true;
            e.loading = true;
            let updatedArray = []
            this.schedulerService.getScSTDpolicy(this.reportId, this.clientId).subscribe((response: any) => {
              e.loading = false;
              response?.body?.forEach((c: any) => {
                updatedArray.push({
                  label: c.displayName,
                  value: c.value,
                  selected: false
                });
              });
              e.options = updatedArray;
            })
          }
          if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'ltdpolicy') {
            e.selectedData = [];
            this.parameters[e.label] = '';
            e.options = [];
            e.showBool = true;
            e.loading = true;
            let updatedArray = []
            this.schedulerService.getScLTDpolicy(this.reportId, this.clientId).subscribe((response: any) => {
              e.loading = false;
              response?.body?.forEach((c: any) => {
                updatedArray.push({
                  label: c.displayName,
                  value: c.value,
                  selected: false
                });
              });
              e.options = updatedArray;
            })
          }
          if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroupvalues') {
            if (this.restricted) {
              e.mandatory = true;
              let updatedArray = [];
              const selectedValues = [];
              this.schedulerService.getSCsubgroupValues(this.reportId, this.clientId, this.SubGroupId).subscribe((response: any) => {
                e.loading = false;
                e.showBool = true;
                response?.body?.forEach(c => {
                  if (c.displayName !== null && c.value !== null) {
                    updatedArray.push({
                      label: c.displayName ? c.displayName : '',
                      value: c.value,
                      selected: true
                    });
                    selectedValues.push(c.value)
                  }
                });
                e.options = updatedArray;
                e.selectedData = selectedValues;
                this.parameters[e.id] = selectedValues;
                e.paraBool = false;
              })
            }
            else {
              e.selectedData = []; e.showBool = false; delete this.parameters[e.id];
            }
          }
        })
      }
      if (this.mainData[index].id.toLowerCase() == 'subgroup') {
        this.SubGroupId = '';
        let dat = this.mainData[index].options?.filter((t) => t.value === event)
        this.SubGroupId = dat[0]?.value;
        this.mainData.filter(e => {
          if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroupvalues') {
            e.showBool = true;
            e.loading = true
            let updatedArray = [];
            e.selectedData = [];
            this.parameters[e.id] = [];
            this.schedulerService.getSCsubgroupValues(this.reportId, this.clientId, this.SubGroupId).subscribe((response: any) => {
              e.loading = false;
              response?.body?.forEach(c => {
                if (c !== null) {
                  updatedArray.push({
                    label: c,
                    value: c,
                    selected: false
                  });
                }
              });
              e.options = updatedArray;
            })
          }
        })
      }
    }
    if (this.SelectedRequestedPage == 'PACS_All_Vendors2') {
      if (this.mainData[index].id.toLowerCase().replace(/[_ ]/g, '') == 'clients') {
        this.clientId = '';
        let dat = this.mainData[index].options?.filter((t) => t.value === event)
        this.clientId = dat[0]?.value;
        this.mainData.filter(e => {
          if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'policy') {
            e.selectedData = [];
            this.parameters[e.label] = '';
            e.options = [];
            e.showBool = true;
            e.loading = true
            let updatedArray = [];
            this.schedulerService.getPACSV2policyt(this.reportId, this.clientId).subscribe((response: any) => {
              e.loading = false;
              response?.body?.forEach((c: any) => {
                updatedArray.push({
                  label: c.policyNumber + " - " + c.policyType + " - " + c.effectiveDate + " - " + c.expiryDate,
                  value: c.policyNumber + "|" + c.policyBON,
                  selected: false
                });
              });
              e.options = updatedArray;
            })
          }
        })
      }
    }
    //this.onChange();
  }
  cascadeFunc(label, event) {
    for (let k in this.payload) {
      if (k == label) {
        this.payload[k] = event;
      }

    }
    this.parameterElementsArray.forEach(e => {
      if (e.id == label) {
        this.payData = e.slaveDependencies.join(';')
      }
    })
    let idNames = this.payData.split(';')
    this.mainData.forEach(item => {
      if (idNames.includes(item.id)) {
        item.loading = true;
        item.selectedData =  (item.type=='multiSelect' ||item.type=='date')?[]:'';
        this.parameters[item.id]=(item.type=='multiSelect' ||item.type=='date')?[]:'';
        item.selected=false;
      }
    });
    this.schedulerService.cascadeParameterService(this.reportId, this.payData, this.payload).subscribe((res: any) => {
      //console.log(this.mainData);
      //console.log(res.body.records);
      this.mainData.forEach(e => {
        // res.body.records.filter(element => {
        //   if (element.id == e.id) {
        //     e.loading = false;
        //     e.options = element.options.length>1?element.options:e.options;
        //     e.options.forEach((el, i) => {
        //       if (el['value'] == '' || el['value'] == 'empty' || el['value'] == "") {
        //         e.options.splice(i, 1);
        //       }
        //     })
        //   }
        // });
        if (idNames.includes(e.id)) {
          res?.body?.records.filter(element => {
            if (element.id == e.id) {
              element.options?.forEach((el, i) => {
                if (el['value'] == '' || el['value'] == 'empty' || el['value'] == ""|| el['value'] == null) {
                  element.options.splice(i, 1);
                }
              })
              e.options = element.options;
              e.loading = false;

            }
          });
        }
      })
    })
  }
  jobChange(val) {
    if (val == '') {
      this.Jobvalue = '';
    } else {
      this.Jobvalue = val;
    }
  }
  fileChange(val) {
    //"/\><*"
    if (this.filename.invalid) {
    }
    if (val == '') {
      this.filename = '';
    } else {
      // if (this.specialChars.test(val)) {
      //   this.fileAlertBool = true;
      // }
      // else {
      //   this.fileAlertBool = false;
      this.filename = val;
      // }
    }
  }
  changeSchedulerView(event, val) {
    if (event == 'Run Now') {
      this.dailyScheduler = false;
      this.weeklyScheduler = false;
      this.monthlyScheduler = false;
      this.now = true;
      this.startType = this.stateOptions[0]?.code;
      // if (this.Type !== "Edit" || this.Type !== "Copy") {
      // this.startDateDaily = '';
      // this.endDateDaily = '';
      // this.timeZoneDaily = new Date();
      // this.timeZoneDaily.setHours(4, 0, 0);
      // this.startDateWeekly = '';
      // this.endDateWeekly = '';
      // this.timeZoneWeekly = new Date();
      // this.timeZoneWeekly.setHours(4, 0, 0);
      // this.startDateMonthly = '';
      // this.endDateMonthly = '';
      // this.timeZoneMonthly = new Date();
      // this.timeZoneMonthly.setHours(4, 0, 0);
      // this.everyDays = '';
      // this.dailyOccurrenceDayVal1 = 1;
      // this.weekList = [];
      // this.monthlyDays = '';
      // this.monthlyOccurrenceDayVal1 = 0;
      // this.monthlyOccurrenceMonthVal = 0;
      // this.monthlyOccurDrop1 = '';
      // this.monthlyOccurDrop2 = '';
      // this.monthlyOccurrenceMonthVal2 = 0;
      // }
    }
    else if (event == 'Daily') {
      this.now = false;
      this.dailyScheduler = true;
      this.weeklyScheduler = false;
      this.monthlyScheduler = false;
      this.startType = this.scheduleValue;
      // if (this.Type !== "Edit" && this.Type !== "Copy") {
      // this.startDateWeekly = '';
      // this.endDateWeekly = '';
      // this.timeZoneWeekly = new Date();
      // this.timeZoneWeekly.setHours(4, 0, 0);
      // this.startDateMonthly = '';
      // this.endDateMonthly = '';
      // this.timeZoneMonthly = new Date();
      // this.timeZoneMonthly.setHours(4, 0, 0);
      // this.weekList = [];
      // this.monthlyDays = '';
      // this.monthlyOccurrenceDayVal1 = 0;
      // this.monthlyOccurrenceMonthVal = 0;
      // this.monthlyOccurDrop1 = '';
      // this.monthlyOccurDrop2 = '';
      // this.monthlyOccurrenceMonthVal2 = 0;
      // }
    }
    else if (event == 'Weekly') {
      this.now = false;
      this.dailyScheduler = false;
      this.weeklyScheduler = true;
      this.monthlyScheduler = false;
      this.startType = this.scheduleValue;
      // if (this.Type !== "Edit" && this.Type !== "Copy") {
      //   this.startDateDaily = '';
      //   this.endDateDaily = '';
      //   this.timeZoneDaily = new Date();
      //   this.timeZoneDaily.setHours(4, 0, 0);
      //   this.startDateMonthly = '';
      //   this.endDateMonthly = '';
      //   this.timeZoneMonthly = new Date();
      //   this.timeZoneMonthly.setHours(4, 0, 0);
      // this.everyDays = '';
      // this.dailyOccurrenceDayVal1 = 1;
      // this.monthlyDays = '';
      // this.monthlyOccurrenceDayVal1 = 0;
      // this.monthlyOccurrenceMonthVal = 0;
      // this.monthlyOccurDrop1 = '';
      // this.monthlyOccurDrop2 = '';
      // this.monthlyOccurrenceMonthVal2 = 0;
      // }
    }
    else if (event == 'Monthly') {
      this.now = false;
      this.dailyScheduler = false;
      this.weeklyScheduler = false;
      this.monthlyScheduler = true;
      this.startType = this.scheduleValue;
      // if (this.Type !== "Edit" && this.Type !== "Copy") {
      //   this.startDateDaily = '';
      //   this.endDateDaily = '';
      //   this.timeZoneDaily = new Date();
      //   this.timeZoneDaily.setHours(4, 0, 0);
      //   this.startDateWeekly = '';
      //   this.endDateWeekly = '';
      //   this.timeZoneWeekly = new Date();
      //   this.timeZoneWeekly.setHours(4, 0, 0);
      // this.everyDays = '';
      // this.dailyOccurrenceDayVal1 = 1;
      // this.weekList = [];
      // }
    }
  }
  fileFormatChange(event) {
    if (event.value == '') {
      this.reportOutputType = [];
    } else {
      this.reportOutputType = event.value;
    }
  }
  dailyOccurSchedFunc(event, refEl) {
    //console.log(event, refEl)
    if (

      this.timeZoneDaily == null ||
      this.startDateDaily == null ||
      this.endDateDaily == null
    ) {
      return;
    } else {
      this.triggerType = 'improved';
      if (refEl == undefined) {
        if (event == 'every days(s)') {
          this.dailyOccurrence.isEveryWeekday = 'N';
        } else if (event == 'every week day') {
          this.dailyOccurrence.isEveryWeekday = 'Y';
          this.dailyOccurrenceDayVal1 = 1;
          this.dailyOccurrence.recurrenceInterval = 1;
        } else if (event == 'every day') {
          this.dailyOccurrenceDayVal1 = 1;
          this.dailyOccurrence.isEveryWeekday = 'N';
          this.dailyOccurrence.recurrenceInterval = 1;
        }
      } else {
        if (refEl.el.nativeElement.nodeName == 'P-INPUTNUMBER') {
          this.dailyOccurrence.recurrenceInterval = event.value;
        } else {
          if (refEl.el.nativeElement.id == 'daily-occurrence-report-time') {
            this.dailyOccurrence.advHours = event.getHours();
            this.dailyOccurrence.advMinutes = event.getMinutes();
            this.weeklyOccurrence.advHours = event.getHours();
            this.weeklyOccurrence.advMinutes = event.getMinutes();
            this.monthlyOccurrence.advHours = event.getHours();
            this.monthlyOccurrence.advMinutes = event.getMinutes();
            this.timeZoneWeekly = this.timeZoneDaily;
            this.timeZoneMonthly = this.timeZoneDaily;
            //this.timeZoneDaily=this.dailyOccurrence.advHours +this.dailyOccurrence.advMinutes;}
          } else if (
            refEl.el.nativeElement.id == 'daily-occurrence-report-start-date'
          ) {
            this.dailyOccurrence.startDatetime =
              event.getFullYear() +
              '-' +
              ('0' + (event.getMonth() + 1)).slice(-2) +
              '-' +
              ('0' + event.getDate()).slice(-2) +
              ' ' +
              '00:00:00';
            const date1 = new Date(this.startDateDaily);
            const maxDate = new Date(date1.getFullYear(), date1.getMonth(), date1.getDate());
            maxDate.setFullYear(this.startDateDaily.getFullYear() + 5);
            this.maxDateDaily = maxDate;
            const date2 = new Date(this.startDateDaily);
            const endDate = new Date(date2.getFullYear(), date2.getMonth(), date2.getDate());
            endDate.setFullYear(this.startDateDaily.getFullYear() + 5);
            this.endDateDaily = endDate;
            this.dailyOccurrence.endDatetime =
              endDate.getFullYear() +
              '-' +
              ('0' + (endDate.getMonth() + 1)).slice(-2) +
              '-' +
              ('0' + endDate.getDate()).slice(-2) +
              ' ' +
              '23:59:59';
            this.startDateWeekly = this.startDateDaily;
            this.endDateWeekly = this.endDateDaily;
            this.maxDateWeekly = maxDate;
            this.startDateMonthly = this.startDateDaily;
            this.endDateMonthly = this.endDateDaily;
            this.maxDateMonthly = maxDate;
            this.weeklyOccurrence.startDatetime = this.dailyOccurrence.startDatetime;
            this.weeklyOccurrence.endDatetime = this.dailyOccurrence.endDatetime;
            this.monthlyOccurrence.startDatetime = this.dailyOccurrence.startDatetime;
            this.monthlyOccurrence.endDatetime = this.dailyOccurrence.endDatetime;
            //this.mindate1 = this.startDateDaily;
            // this.startDateDaily=this.dailyOccurrence.startDatetime;
          }
          if (refEl.el.nativeElement.id == 'daily-occurrence-report-end-date') {
            this.dailyOccurrence.endDatetime =
              event.getFullYear() +
              '-' +
              ('0' + (event.getMonth() + 1)).slice(-2) +
              '-' +
              ('0' + event.getDate()).slice(-2) +
              ' ' +
              '23:59:59';
            this.endDateWeekly = this.endDateDaily;
            this.endDateMonthly = this.endDateDaily;
            this.weeklyOccurrence.endDatetime = this.dailyOccurrence.endDatetime;
            this.monthlyOccurrence.endDatetime = this.dailyOccurrence.endDatetime;
            // this.endDateDaily= this.dailyOccurrence.endDatetime
          }
        }
      }
    }
  }
  weeklyOccurSchedFunc(event, refEl) {
    if (

      this.timeZoneWeekly == null ||
      this.startDateWeekly == null ||
      this.endDateWeekly == null
    ) {
      return;
    } else {
      if (refEl.inputViewChild != undefined) {
        // to check if it's check box
        if (refEl.checked) {
          // if true
          //this.weeklyOccurrence.weekDays.push(Number(refEl.value));
          this.weeklyOccurrence.weekDays = refEl.model;
        } else {
          // if false
          let index = this.weeklyOccurrence.weekDays.indexOf(refEl.value);
          this.weeklyOccurrence.weekDays.splice(index, 1);
        }
      } else {
        if (refEl.el.nativeElement.id == 'weekly-occurrence-report-time') {
          this.weeklyOccurrence.advHours = event.getHours();
          this.weeklyOccurrence.advMinutes = event.getMinutes();
          this.dailyOccurrence.advHours = event.getHours();
          this.dailyOccurrence.advMinutes = event.getMinutes();
          this.monthlyOccurrence.advHours = event.getHours();
          this.monthlyOccurrence.advMinutes = event.getMinutes();
          this.timeZoneMonthly = this.timeZoneWeekly;
          this.timeZoneDaily = this.timeZoneWeekly;
          // this.timeZoneWeekly =
          //   this.weeklyOccurrence.advHours + this.weeklyOccurrence.advMinutes;
        } else if (
          refEl.el.nativeElement.id == 'weekly-occurrence-report-start-date'
        ) {
          this.weeklyOccurrence.startDatetime =
            event.getFullYear() +
            '-' +
            ('0' + (event.getMonth() + 1)).slice(-2) +
            '-' +
            ('0' + event.getDate()).slice(-2) +
            ' ' +
            '00:00:00';
          const date1 = new Date(this.startDateWeekly);
          const maxDate = new Date(date1.getFullYear(), date1.getMonth(), date1.getDate());
          maxDate.setFullYear(this.startDateWeekly.getFullYear() + 5);
          this.maxDateWeekly = maxDate;
          const date2 = new Date(this.startDateWeekly);
          const endDate = new Date(date2.getFullYear(), date2.getMonth(), date2.getDate());
          endDate.setFullYear(this.startDateWeekly.getFullYear() + 5);
          this.endDateWeekly = endDate;
          this.weeklyOccurrence.endDatetime =
            endDate.getFullYear() +
            '-' +
            ('0' + (endDate.getMonth() + 1)).slice(-2) +
            '-' +
            ('0' + endDate.getDate()).slice(-2) +
            ' ' +
            '23:59:59';
          this.startDateDaily = this.startDateWeekly;
          this.endDateDaily = this.endDateWeekly;
          this.maxDateDaily = maxDate;
          this.startDateMonthly = this.startDateWeekly;
          this.endDateMonthly = this.endDateWeekly;
          this.maxDateMonthly = maxDate;
          this.dailyOccurrence.startDatetime = this.weeklyOccurrence.startDatetime;
          this.dailyOccurrence.endDatetime = this.weeklyOccurrence.endDatetime;
          this.monthlyOccurrence.startDatetime = this.weeklyOccurrence.startDatetime;
          this.monthlyOccurrence.endDatetime = this.weeklyOccurrence.endDatetime;
          //this.startDateWeekly=this.weeklyOccurrence.startDatetime ;
          //this.mindate2 = this.startDateWeekly;
        }
        if (refEl.el.nativeElement.id == 'weekly-occurrence-report-end-date') {
          this.weeklyOccurrence.endDatetime =
            event.getFullYear() +
            '-' +
            ('0' + (event.getMonth() + 1)).slice(-2) +
            '-' +
            ('0' + event.getDate()).slice(-2) +
            ' ' +
            '23:59:59';
          this.endDateDaily = this.endDateWeekly;
          this.endDateMonthly = this.endDateWeekly;
          this.dailyOccurrence.endDatetime = this.weeklyOccurrence.endDatetime;
          this.monthlyOccurrence.endDatetime = this.weeklyOccurrence.endDatetime;
        }
      }
    }
  }
  monthlyOccurSchedFunc(event, refEl) {
    if (

      this.timeZoneMonthly == null ||
      this.startDateMonthly == null ||
      this.endDateMonthly == null
    ) {
      return;
    } else {
      this.triggerType = 'improved';
      if (refEl == undefined) {
        if (event.target.value == 'first') {
          this.monthlyOccurDrop1 = '';
          this.monthlyOccurDrop2 = '';
          this.monthlyOccurrenceMonthVal2 = 0;
          this.monthlyOccurrence.isDayOrDate = 'date';
          delete this.monthlyOccurrence['dayUnit'];
          delete this.monthlyOccurrence['dayUnitValue'];
        } else if (event.target.value == 'second') {
          this.monthlyOccurrenceMonthVal = 0;
          this.monthlyOccurrenceDayVal1 = 0;
          this.monthlyOccurrence.isDayOrDate = 'day';
          this.monthlyOccurrence.monthDays = null;
          this.monthlyOccurrence['dayUnit'] = this.monthlyOccurDrop1;
          this.monthlyOccurrence['dayUnitValue'] = this.monthlyOccurDrop2;
        }
      } else {

        if (refEl.el.nativeElement.nodeName == 'P-INPUTNUMBER') {
          if (refEl.el.nativeElement.id == 'refElmval1') {
            this.monthlyOccurrence.monthDays = event.value;
          } else if (refEl.el.nativeElement.id == 'refElmval2') {
            this.monthlyOccurrence.recurrenceInterval = event.value;
          } else if (refEl.el.nativeElement.id == 'refElmval3') {
            this.monthlyOccurrence.recurrenceInterval = event.value;
          }
        }
        if (refEl.el.nativeElement.nodeName == 'P-DROPDOWN') {
          if (refEl.el.nativeElement.id == 'monthly-occurrence-drop-one') {
            this.monthlyOccurrence['dayUnit'] = event.value;
            this.monthlyOccurDrop1 = event.value;
          } else if (
            refEl.el.nativeElement.id == 'monthly-occurrence-drop-two'
          ) {
            this.monthlyOccurrence['dayUnitValue'] = event.value;
            this.monthlyOccurDrop2 = event.value;
          }
        } else {
          if (refEl.el.nativeElement.id == 'monthly-occurrence-report-time') {
            this.monthlyOccurrence.advHours = event.getHours();
            this.monthlyOccurrence.advMinutes = event.getMinutes();
            this.timeZoneWeekly = this.timeZoneMonthly;
            this.timeZoneDaily = this.timeZoneMonthly;
            this.dailyOccurrence.advHours = event.getHours();
            this.dailyOccurrence.advMinutes = event.getMinutes();
            this.weeklyOccurrence.advHours = event.getHours();
            this.weeklyOccurrence.advMinutes = event.getMinutes();
          } else if (
            refEl.el.nativeElement.id == 'monthly-occurrence-report-start-date'
          ) {
            this.monthlyOccurrence.startDatetime =
              event.getFullYear() +
              '-' +
              ('0' + (event.getMonth() + 1)).slice(-2) +
              '-' +
              ('0' + event.getDate()).slice(-2) +
              ' ' +
              '00:00:00';
            // this.mindate3 = this.startDateMonthly;
            const date1 = new Date(this.startDateMonthly);
            const maxDate = new Date(date1.getFullYear(), date1.getMonth(), date1.getDate());
            maxDate.setFullYear(this.startDateMonthly.getFullYear() + 5);
            this.maxDateMonthly = maxDate;
            const date2 = new Date(this.startDateMonthly);
            const endDate = new Date(date2.getFullYear(), date2.getMonth(), date2.getDate());
            endDate.setFullYear(this.startDateMonthly.getFullYear() + 5);
            this.endDateMonthly = endDate;
            this.monthlyOccurrence.endDatetime =
              endDate.getFullYear() +
              '-' +
              ('0' + (endDate.getMonth() + 1)).slice(-2) +
              '-' +
              ('0' + endDate.getDate()).slice(-2) +
              ' ' +
              '23:59:59';
            this.startDateDaily = this.startDateMonthly;
            this.endDateDaily = this.endDateMonthly;
            this.maxDateDaily = maxDate;
            this.dailyOccurrence.startDatetime = this.monthlyOccurrence.startDatetime;
            this.dailyOccurrence.endDatetime = this.monthlyOccurrence.endDatetime
            this.startDateWeekly = this.startDateMonthly;
            this.endDateWeekly = this.endDateMonthly;
            this.maxDateWeekly = maxDate;
            this.weeklyOccurrence.startDatetime = this.monthlyOccurrence.startDatetime;
            this.weeklyOccurrence.endDatetime = this.monthlyOccurrence.endDatetime
          }
          if (
            refEl.el.nativeElement.id == 'monthly-occurrence-report-end-date'
          ) {
            this.monthlyOccurrence.endDatetime =
              event.getFullYear() +
              '-' +
              ('0' + (event.getMonth() + 1)).slice(-2) +
              '-' +
              ('0' + event.getDate()).slice(-2) +
              ' ' +
              '23:59:59';
            this.endDateDaily = this.endDateMonthly;
            this.endDateWeekly = this.endDateMonthly;
            this.dailyOccurrence.endDatetime = this.monthlyOccurrence.endDatetime
            this.weeklyOccurrence.endDatetime = this.monthlyOccurrence.endDatetime
          }
        }
      }
    }
    //this.startDateMonthly = '';
  }
  manualChangeDaily(event, refEl) {
    //console.log(event, refEl)
    if (event != null && event != '') {
      this.dailyOccurSchedFunc(event, refEl)
    }
    else {
      this.dailyOccurrence.advHours == null ||
        this.dailyOccurrence.advMinutes == null ||
        this.dailyOccurrence.isEveryWeekday == '' ||
        this.dailyOccurrence.startDatetime == '' ||
        this.dailyOccurrence.endDatetime == ''
      if (refEl == undefined) {
        if (event == 'every days(s)') {
          this.dailyOccurrence.isEveryWeekday = '';
        } else if (event == 'every week day') {
          this.dailyOccurrence.isEveryWeekday = '';
          this.dailyOccurrenceDayVal1 = 0;
          this.dailyOccurrence.recurrenceInterval = 1;
        } else if (event == 'every day') {
          this.dailyOccurrenceDayVal1 = 0;
          this.dailyOccurrence.isEveryWeekday = '';
          this.dailyOccurrence.recurrenceInterval = 1;
        }
      } else {
        if (refEl.el.nativeElement.nodeName == 'P-INPUTNUMBER') {
          this.dailyOccurrence.recurrenceInterval = 1;
        } else {
          if (refEl.el.nativeElement.id == 'daily-occurrence-report-time') {
            this.dailyOccurrence.advHours = null;
            this.dailyOccurrence.advMinutes = null;
            this.weeklyOccurrence.advHours = null;
            this.weeklyOccurrence.advMinutes = null;
            this.monthlyOccurrence.advHours = null;
            this.monthlyOccurrence.advMinutes = null;
            this.timeZoneMonthly = null;
            this.timeZoneWeekly = null;
            //this.timeZoneDaily=this.dailyOccurrence.advHours +this.dailyOccurrence.advMinutes;}
          } else if (
            refEl.el.nativeElement.id == 'daily-occurrence-report-start-date'
          ) {
            this.dailyOccurrence.startDatetime = '';
            this.maxDateDaily = null;
            this.startDateDaily = '';
            this.weeklyOccurrence.startDatetime = '';
            this.startDateWeekly = ''
            this.maxDateWeekly = null;
            this.monthlyOccurrence.startDatetime = '';
            this.maxDateMonthly = null;
            this.startDateMonthly = '';
          }
          if (refEl.el.nativeElement.id == 'daily-occurrence-report-end-date') {
            this.dailyOccurrence.endDatetime = '';
            this.endDateDaily = '';
            this.weeklyOccurrence.endDatetime = '';
            this.endDateWeekly = '';
            this.monthlyOccurrence.endDatetime = '';
            this.endDateMonthly = '';
          }
        }
      }
    }
  }
  manualChangeWeekly(event, refEl) {
    //console.log(event, refEl)
    if (event != null && event != '') {
      this.weeklyOccurSchedFunc(event, refEl)
    }
    else {
      if (refEl.inputViewChild != undefined) {
        this.weeklyOccurrence.weekDays = [];
      } else {
        if (refEl.el.nativeElement.id == 'weekly-occurrence-report-time') {
          this.dailyOccurrence.advHours = null;
          this.dailyOccurrence.advMinutes = null;
          this.weeklyOccurrence.advHours = null;
          this.weeklyOccurrence.advMinutes = null;
          this.monthlyOccurrence.advHours = null;
          this.monthlyOccurrence.advMinutes = null;
          this.timeZoneDaily = null;
          this.timeZoneMonthly = null;
        } else if (
          refEl.el.nativeElement.id == 'weekly-occurrence-report-start-date'
        ) {
          this.weeklyOccurrence.startDatetime = '';
          this.startDateWeekly = ''
          this.maxDateWeekly = null;
          this.dailyOccurrence.startDatetime = '';
          this.maxDateDaily = null;
          this.startDateDaily = '';
          this.monthlyOccurrence.startDatetime = '';
          this.maxDateMonthly = null;
          this.startDateMonthly = '';
        }
        if (refEl.el.nativeElement.id == 'weekly-occurrence-report-end-date') {
          this.weeklyOccurrence.endDatetime = '';
          this.endDateWeekly = '';
          this.dailyOccurrence.endDatetime = '';
          this.endDateDaily = '';
          this.monthlyOccurrence.endDatetime = '';
          this.endDateMonthly = '';
        }
      }
    }
  }
  manualChangeMonthly(event, refEl) {
    //console.log(event, refEl)
    if (event != null && event != '') {
      this.monthlyOccurSchedFunc(event, refEl)
    }
    else {
      if (refEl == undefined) {
        if (event.target.value == 'first') {
          this.monthlyOccurDrop1 = '';
          this.monthlyOccurDrop2 = '';
          this.monthlyOccurrenceMonthVal2 = 0;
          this.monthlyOccurrence.isDayOrDate = '';
          delete this.monthlyOccurrence['dayUnit'];
          delete this.monthlyOccurrence['dayUnitValue'];
        } else if (event.target.value == 'second') {
          this.monthlyOccurrenceMonthVal = 0;
          this.monthlyOccurrenceDayVal1 = 0;
          this.monthlyOccurrence.isDayOrDate = '';
          this.monthlyOccurrence.monthDays = null;
          this.monthlyOccurrence['dayUnit'] = this.monthlyOccurDrop1;
          this.monthlyOccurrence['dayUnitValue'] = this.monthlyOccurDrop2;
        }
      } else {
        if (refEl.el.nativeElement.nodeName == 'P-INPUTNUMBER') {
          if (refEl.el.nativeElement.id == 'refElmval1') {
            this.monthlyOccurrence.monthDays = null;
          } else if (refEl.el.nativeElement.id == 'refElmval2') {
            this.monthlyOccurrence.recurrenceInterval = 1;
          } else if (refEl.el.nativeElement.id == 'refElmval3') {
            this.monthlyOccurrence.recurrenceInterval = 1;
          }
        }
        if (refEl.el.nativeElement.nodeName == 'P-DROPDOWN') {
          if (refEl.el.nativeElement.id == 'monthly-occurrence-drop-one') {
            this.monthlyOccurrence['dayUnit'] = null;
            this.monthlyOccurDrop1 = '';
          } else if (
            refEl.el.nativeElement.id == 'monthly-occurrence-drop-two'
          ) {
            this.monthlyOccurrence['dayUnitValue'] = null;
            this.monthlyOccurDrop2 = '';
          }
        } else {
          if (refEl.el.nativeElement.id == 'monthly-occurrence-report-time') {
            this.dailyOccurrence.advHours = null;
            this.dailyOccurrence.advMinutes = null;
            this.weeklyOccurrence.advHours = null;
            this.weeklyOccurrence.advMinutes = null;
            this.monthlyOccurrence.advHours = null;
            this.monthlyOccurrence.advMinutes = null;
            this.timeZoneDaily = null;
            this.timeZoneWeekly = null;
          } else if (
            refEl.el.nativeElement.id == 'monthly-occurrence-report-start-date'
          ) {
            this.monthlyOccurrence.startDatetime = '';
            this.maxDateMonthly = null;
            this.startDateMonthly = '';
            this.weeklyOccurrence.startDatetime = '';
            this.startDateWeekly = ''
            this.maxDateWeekly = null;
            this.dailyOccurrence.startDatetime = '';
            this.maxDateDaily = null;
            this.startDateDaily = '';
          }
          if (
            refEl.el.nativeElement.id == 'monthly-occurrence-report-end-date'
          ) {
            this.monthlyOccurrence.endDatetime = '';
            this.endDateMonthly = '';
            this.dailyOccurrence.endDatetime = '';
            this.endDateDaily = '';
            this.weeklyOccurrence.endDatetime = '';
            this.endDateWeekly = '';
          }
        }
      }
    }
  }
  mailChange(message, msg) {
    // let regex = new RegExp('[a-z0-9]+@[a-z]+.[a-z]{2,3}');
    // //console.log(message)
    if (msg == 'to') {
      let regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      // //console.log(regex.test(message))
      const emailArray = message.split(',').map(email => email.trim());
      let arr = [];
      if (emailArray.length > 1) {
        for (const email of emailArray) {

          if (!regex.test(email)) {
            arr.push(true)
          }
          else {
            arr.push(false);
          }
        }
        if (arr.includes(true)) {
          this.alert1 = true;
          //this.ToValue=''
          this.mailTo = '';
        } else {
          this.alert1 = false;
          this.mailTo = this.ToValue;
        }
      }

      else if (!regex.test(message) && message != '') {
        this.alert1 = true;
        this.mailTo = "";
      }
      else
        if (regex.test(message) && message != '') {
          this.alert1 = false;
          this.mailTo = this.ToValue;
        }
        else if (message == '') {
          this.alert1 = true;
          this.mailTo = "";
        }
      // else {
      //   if (message == '' || !regex.test(message)) {
      //     this.alert1 = true;
      //     this.mailTo = '';
      //   } else {
      //     this.alert1 = false;
      //     this.mailTo = this.ToValue;
      //   }
      // }
    }
    if (msg == 'subject') {
      this.mailSubject = this.SubjectValue;
    }
    if (msg == 'cc') {
      let regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      //console.log(regex.test(message))
      const emailArray = message.split(',').map(email => email.trim());
      let arr = [];
      if (emailArray.length > 1) {
        for (const email of emailArray) {
          if (!regex.test(email)) {
            arr.push(true)
          }
          else {
            arr.push(false);
          }
        }
        if (arr.includes(true)) {
          this.showError = '';
          this.ccTo = '';
        } else {
          this.showError = 'val';
          this.ccTo = this.CCValue;
        }
      }
      else
        if (!regex.test(message) && message != '') {
          this.showError = '';
          this.ccTo = this.CCValue;
        }
        else
          if (regex.test(message) && message != '') {
            this.showError = 'val';
            this.ccTo = this.CCValue;
          }
          else if (message == '') {
            this.showError = 'val';
            this.ccTo = this.CCValue;
          }
    }
    if (msg == 'Bcc') {
      let regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      //console.log(regex.test(message))
      const emailArray = message.split(',').map(email => email.trim());
      let arr = [];
      if (emailArray.length > 1) {
        for (const email of emailArray) {
          if (!regex.test(email)) {
            arr.push(true)
          }
          else {
            arr.push(false);
          }
        }
        if (arr.includes(true)) {
          this.showError1 = '';
          this.bccValue = '';
        } else {
          this.showError1 = 'val';
          this.bccValue = this.BCCValue;
          //this.showError = false;
        }
      }
      else if (!regex.test(message) && message != '') {
        this.showError1 = '';
        this.bccValue = this.BCCValue;
      }
      else
        if (regex.test(message) && message != '') {
          this.showError1 = 'val';
          this.bccValue = this.BCCValue;
        }
        else if (message == '') {
          this.showError1 = 'val';
          this.bccValue = this.BCCValue;
        }
    }
    if (msg == 'msg') {
      if (message == '') {
        this.alert2 = true;
      } else {
        this.alert2 = false;
        this.mailMessage = this.message;
      }
    }
    //this.onChange();
  }

  onScheduleBtnClick() {
    //console.log(this.showError1, this.showError);
    //console.log(this.parameters);
    let mandatoryFieldsError = false;
    let arr = this.mainData.filter(d => d.mandatory).map(d => d.paraBool);
    if (!arr.every(v => v === false || v === undefined)) {
      this.messageService.add({
        severity: 'error',
        summary: 'Mandatory',
        detail: 'Please Fill Mandatory Fields!',
      });
      mandatoryFieldsError = true;
    }
    let para = [];
    for (const key in this.parameters) {
      const isEmpty = this.parameters[key] === '';
      const isMandatory = this.mainData.find(d => d.id == key && d.mandatory);
      para.push(isMandatory && isEmpty);
    }

    if (para.some(v => v)) {
      this.messageService.add({
        severity: 'error',
        summary: 'Mandatory',
        detail: 'Please Fill Mandatory Fields!',
      });
      mandatoryFieldsError = true;
    }

    // Additional validation checks
    if (!this.message) {
      this.messageService.add({
        severity: 'error',
        summary: 'Mandatory',
        detail: 'Please Fill Message Field!',
      });
      mandatoryFieldsError = true;
    }
    if (!this.mailTo) {
      this.messageService.add({
        severity: 'error',
        summary: 'Mandatory',
        detail: 'Please Fill Mail To Field!',
      });
      mandatoryFieldsError = true;
    }
    if (!this.showError || !this.showError1) {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Please Enter Valid Id!',
      });
      mandatoryFieldsError = true;
    }

    // Check dates && this.paramDates.length ==2 
    if (this.paramDates.length > 0 && this.key1 == '' && this.paramDates[0].mandatory && this.paramDates[1].mandatory) {

      if (this.paramDates[0].selectedData[0] && !this.paramDates[1].selectedData[0]) {
        this.messageService.add({
          severity: 'error',
          summary: 'Mandatory',
          detail: `${this.paramDates[1].id} is mandatory`,
        });
        mandatoryFieldsError = true;
      } else if (this.paramDates[1].selectedData[0] && !this.paramDates[0].selectedData[0]) {
        this.messageService.add({
          severity: 'error',
          summary: 'Mandatory',
          detail: `${this.paramDates[0].id} is mandatory`,
        });
        mandatoryFieldsError = true;
      }
      else if ((this.paramDates[0].selectedData[0] === "" ||this.paramDates[0].selectedData[0] === null) && (this.paramDates[1].selectedData[0] === "" ||this.paramDates[1].selectedData[0] === null)) {
        this.messageService.add({
          severity: 'error',
          summary: 'Mandatory',
          detail: `${this.paramDates[1].id} and ${this.paramDates[0].id} Is Mandatory`,
        });
        mandatoryFieldsError = true;
      }
      if (this.paramDates[1].selectedData[0] < this.paramDates[0].selectedData[0]) {
        this.messageService.add({
          severity: 'error',
          summary: 'Mandatory',
          detail: 'Date Error',
        });
        mandatoryFieldsError = true;
      }
    }

//  && this.paramDates.length ==2 
    if (this.paramDates.length > 0 && this.key1 != '') {
      const key = this.mainData.filter(e => e.id === this.key1)
      if ((this.paramDates[0].selectedData[0] === "" ||this.paramDates[0].selectedData[0] === null) && (this.paramDates[1].selectedData[0] === "" ||this.paramDates[1].selectedData[0] === null)&& key[0]['selectedData'] == '') {
        this.messageService.add({
          severity: 'error',
          summary: 'Mandatory',
          detail: 'Date Or Date Range Is Mandatory',
        });
        mandatoryFieldsError = true;
      }
      if (this.paramDates[0].selectedData[0] && !this.paramDates[1].selectedData[0]) {
        this.messageService.add({
          severity: 'error',
          summary: 'Mandatory',
          detail: `${this.paramDates[1].id} is mandatory`,
        });
        mandatoryFieldsError = true;
      } else if (this.paramDates[1].selectedData[0] && !this.paramDates[0].selectedData[0]) {
        this.messageService.add({
          severity: 'error',
          summary: 'Mandatory',
          detail: `${this.paramDates[0].id} is mandatory`,
        });
        mandatoryFieldsError = true;
      }
      if (this.paramDates[0].selectedData[0] && this.paramDates[1].selectedData[0] && key[0]['selectedData'] == '') {
        if (this.paramDates[1].selectedData[0] < this.paramDates[0].selectedData[0]) {
          this.messageService.add({
            severity: 'error',
            summary: 'Mandatory',
            detail: 'Date Error',
          });
          mandatoryFieldsError = true;
        }
      }
      //   else if (this.paramDates[0].selectedData[0]=== "" && this.paramDates[1].selectedData[0]=== "" && key[0]['selectedData'] == '') {
      //     this.messageService.add({
      //       severity: 'error',
      //       summary: 'Mandatory',
      //       detail: 'Date Or Date Range Is Mandatory',
      //   });
      //   mandatoryFieldsError = true;
      // } 
    }
    let valDate='';
    let range=this.mainData.filter(e=>e.id==this.key1);
     this.mainData.forEach(e=>{
      if(e.id.toLowerCase().replace(/[_ ]/g, '') =='valuationdate'){
        if(e.type.toLowerCase()=="singlevaluetext"){valDate=e.selectedData?e.selectedData:''}
        if(e.type.toLowerCase()=="date"){valDate=e.selectedData[0]?e.selectedData[0]:''}
        if(this.paramDates.length == 0 && this.key1 != '' && valDate==""){
         if(valDate=="" && range[0].selectedData==""){
          this.messageService.add({
            severity: 'error',
            summary: 'Mandatory',
            detail: `${e.id} Or Date Range Is Mandatory`,
          });
          mandatoryFieldsError = true;
        }
        }
      }
    })
    
    let minDate, maxDate;
    let minName,maxName;
    this.mainData.forEach(e=>{
      if(e.id.toLowerCase().replace(/[_ ]/g, '') =='fromdate' ||e.id.toLowerCase().replace(/[_ ]/g, '') =='startdate' ){
        if(e.type=="singleValueText"){minDate=e.selectedData;}else{ minDate=e.selectedData[0];}
        minName=e.id;
      }
      if(e.id.toLowerCase().replace(/[_ ]/g, '') =='enddate' ||e.id.toLowerCase().replace(/[_ ]/g, '') =='thrudate' ){
        if(e.type=="singleValueText"){maxDate=e.selectedData;}else{ maxDate=e.selectedData[0];}
        maxName=e.id;
      }
    })
    if( new Date(minDate)>new Date(maxDate)){
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: `${minName} Cannot be greater than ${maxName}`,
      });
      mandatoryFieldsError = true;
    }
    if (!this.Jobvalue) {
      this.messageService.add({
        severity: 'error',
        summary: 'Mandatory',
        detail: 'Please Fill Job Name',
      });
      mandatoryFieldsError = true;
    }

    if (!this.filename) {
      this.messageService.add({
        severity: 'error',
        summary: 'Mandatory',
        detail: 'Please Fill File Name',
      });
      mandatoryFieldsError = true;
    }

    // If there were errors, stop execution
    if (mandatoryFieldsError) return;

    // No errors, proceed
    console.log(this.parameters)
    // this.mainData.forEach(e => {
    //   for (const key in this.parameters) {
    //     if (e.id == key) {
    //       this.NewParameter[e.id] = this.parameters[key];
    //     }
    //   }
    // });
    //console.log(this.dailyOccurrence)
    //console.log(this.weeklyOccurrence)
    //console.log(this.monthlyOccurrence)
    this.disableBtn = true;
    this.onScheduleBtnClick1();
  }
  resetTime(date: Date): Date {
    const newDate = new Date(date);
    newDate.setHours(0, 0, 0, 0); // Reset hours, minutes, seconds, and milliseconds
    return newDate;
  }
  onScheduleBtnClick1() {

    this.btnSpinnerIcon = "pi pi-spin pi-spinner";
    console.log(this.parameters)
    // this.mainData.forEach(e => {
    //   for (const key in this.parameters) {
    //     if (e.id == key) {
    //       this.NewParameter[e.id] = this.parameters[key]
    //     }
    //   }
    // })
    // this.originalApi?.forEach((e) => {
    //   if (!e.visible && e.state && e.options && e.options.length > 0) {
    //     this.NewParameter[e.id] = e.options[0].value;
    //   }
    // })
    this.originalApi?.forEach((e) => {
      if (!e.visible && e.state && e.state.options) {
        let selectedOption;
         if(e.state.options.length > 0){
              selectedOption = e.state.options.find(option => option.selected);
          }
        // this.NewParameter[e.id] = e.state.options[0].value;
        if (selectedOption) {
          this.parameters[e.id] = selectedOption.value;
        } else {
          this.parameters[e.id] =
           e.state.options.length>0 
            ? []
            : 
            e.state.value 
              ? e.state.value 
              : '';
        }
      }
    })
    this.NewParameter=this.parameters;
    if (this.SelectedRequestedPage == 'WC') {
      this.NewParameter['CustomParameterPage'] = "WC";
    }
    let scheOccurrenceBool: Boolean = false;
    let payload: any = {};
    this.btnSpinnerIcon = 'pi pi-spin pi-spinner';
    if (this.dailyScheduler == true) {
      if (
        this.dailyOccurrence.advHours == null ||
        this.dailyOccurrence.advMinutes == null ||
        this.dailyOccurrence.isEveryWeekday == '' ||
        this.dailyOccurrence.startDatetime == '' ||
        this.dailyOccurrence.endDatetime == ''
      ) {
        scheOccurrenceBool = true;
        this.btnSpinnerIcon = '';
        this.disableBtn = false;
        this.messageService.add({
          severity: 'error',
          summary: 'Mandatory',
          detail: 'Please Fill Mandatory Fields!',
        });
      }
      else if (this.resetTime(this.startDateDaily) > this.resetTime(this.endDateDaily)) {
        this.btnSpinnerIcon = '';
        this.disableBtn = false;
        this.messageService.add({
          severity: 'error',
          summary: '',
          detail: 'Schedule start date cannot be greater than end date',
        });
      }
      else {
        this.alertSeverity = 'success';
        this.alertMsg = 'Report Scheduled Successfully!';
        this.addSingle();
        this.router.navigate(['./my-reports']);
        // scheOccurrenceBool = false;
        // payload = {
        //   scheduleJobModelId: this.JobId,
        //   reportId: this.reportId,
        //   haveAttachments: 'Y',
        //   triggerType: 'improved',
        //   startType: this.startType,
        //   ocurrenceCount: -1,
        //   advMonths: [],
        //   weekDays: [],
        //   improvedTriggerType: 'daily',
        //   jobName: this.Jobvalue,
        //   outputLocale: 0,
        //   comment: '',
        //   outputName: this.filename,
        //   mailTo: this.ToValue,
        //   mailCc: this.CCValue,
        //   mailBcc: this.BCCValue,
        //   mailSubject: this.SubjectValue,
        //   timeZone: 'America/Los_Angeles',
        //   monthDays: '',
        //   isEveryWeekday: this.dailyOccurrence.isEveryWeekday,
        //   mailMessage: this.message,
        //   outputType: [this.fileValue],
        //   recurrenceInterval: this.dailyOccurrence.recurrenceInterval,
        //   startDatetime: this.dailyOccurrence.startDatetime,
        //   endDatetime: this.dailyOccurrence.endDatetime,
        //   advHours: this.dailyOccurrence.advHours,
        //   advMinutes: this.dailyOccurrence.advMinutes,
        //   parameters: this.NewParameter,
        //   isSftp: this.isSftp ? 'Y' : 'N',
        //   isPasswordProtect: this.isPassword ? 'Y' : 'N',
        //   clientCode: this.sftpClientCode,
        //   isPgpEncrypt: this.pgpEncryption ? 'Y' : 'N',
        //   sftpConfigId: this.sftpConfigId,
        //   sftpOutDirectoryPath: this.folderPath,
        //   sftpHostName: this.serverName,
        // };
        // if (this.Type == 'Edit') {
        //   this.scheRepoSerSubscrip = this.schedulerService
        //     .UpdateScheduledReport(this.JobId, payload)
        //     .subscribe(
        //       (response: any) => {
        //         // schedule report
        //         this.btnSpinnerIcon = '';
        //         this.disableBtn = false;
        //         if (response.status == 200) {
        //           this.alertSeverity = 'success';
        //           this.alertMsg = 'Schedule Updated Successfully!';
        //           this.addSingle();
        //           if (this.Type == 'Copy' || 'Edit') {
        //             if (this.boolVal) {
        //               this.router.navigate(['./scheduled-reports']);
        //             }
        //             else {
        //               this.router.navigate(['./my-reports']);
        //             }
        //           } else {
        //             this.router.navigate(['./my-reports']);
        //           }
        //         }
        //       },
        //       (error) => {
        //         this.btnSpinnerIcon = '';
        //         this.disableBtn = false;
        //         this.alertSeverity = 'error';
        //         if (Number(error.status) == 401) {
        //           this.alertMsg = 'Error While Creating The Schedule! Please Try After Some Time.';
        //         } else if (Number(error.status) == 500) {
        //           this.alertMsg = 'Internal Error';
        //         } else {
        //           this.alertMsg = 'Error While Creating The Schedule! Please Try After Some Time.';
        //         }
        //         this.addSingle();
        //       },
        //       () => { }
        //     );
        // } else if (this.Type == 'Copy') {
        //   this.scheRepoSerSubscrip = this.schedulerService
        //     .copyScheduledReport(this.reportId, payload)
        //     .subscribe(
        //       (response: any) => {
        //         // schedule report
        //         this.btnSpinnerIcon = '';
        //         this.disableBtn = false;
        //         if (response.status == 200) {
        //           this.alertSeverity = 'success';
        //           this.alertMsg = 'Schedule Copied Successfully!';
        //           this.addSingle();
        //           if (this.Type == 'Copy' || 'Edit') {
        //             if (this.boolVal) {
        //               this.router.navigate(['./scheduled-reports']);
        //             }
        //             else {
        //               this.router.navigate(['./my-reports']);
        //             }
        //           } else {
        //             this.router.navigate(['./my-reports']);
        //           }
        //         }
        //       },
        //       (error) => {
        //         this.btnSpinnerIcon = '';
        //         this.disableBtn = false;
        //         this.alertSeverity = 'error';
        //         if (Number(error.status) == 401) {
        //           this.alertMsg = 'Error While ' + this.Type + 'ing The Schedule! Please Try After Some Time!';
        //         } else if (Number(error.status) == 500) {
        //           this.alertMsg = 'Internal Error';
        //         } else {
        //           this.alertMsg = 'Error While ' + this.Type + 'ing The Schedule! Please Try After Some Time!';
        //         }
        //         this.addSingle();
        //       },
        //       () => { }
        //     );
        // } else {
        //   this.scheRepoSerSubscrip = this.schedulerService
        //     .scheduleReportService(this.reportId, payload)
        //     .subscribe(
        //       (response: any) => {
        //         // schedule report
        //         this.btnSpinnerIcon = '';
        //         this.disableBtn = false;
        //         if (response.status == 200) {
        //           this.alertSeverity = 'success';
        //           this.alertMsg = 'Report Scheduled Successfully!';
        //           this.addSingle();
        //           if (this.Type == 'Copy' || 'Edit') {
        //             if (this.boolVal) {
        //               this.router.navigate(['./scheduled-reports']);
        //             }
        //             else {
        //               this.router.navigate(['./my-reports']);
        //             }
        //           } else {
        //             this.router.navigate(['./my-reports']);
        //           }
        //         }
        //       },
        //       (error) => {
        //         this.btnSpinnerIcon = '';
        //         this.disableBtn = false;
        //         this.alertSeverity = 'error';
        //         if (Number(error.status) == 401) {
        //           this.alertMsg = 'Error While Creating The Schedule! Please Try After Some Time!';
        //         } else if (Number(error.status) == 500) {
        //           this.alertMsg = 'Internal Error';
        //         } else {
        //           this.alertMsg = 'Error While Creating The Schedule! Please Try After Some Time.';
        //         }
        //         this.addSingle();
        //       },
        //       () => { }
        //     );
        // }
      }
    }
    else if (this.weeklyScheduler == true) {
      if (
        this.weeklyOccurrence.advHours == null ||
        this.weeklyOccurrence.advMinutes == null ||
        this.weeklyOccurrence.weekDays.length == 0 ||
        this.weeklyOccurrence.startDatetime == '' ||
        this.weeklyOccurrence.endDatetime == ''
      ) {
        scheOccurrenceBool = true;
        this.messageService.add({
          severity: 'error',
          summary: 'Mandatory',
          detail: 'Please Fill Mandatory Fields!',
        });
        this.disableBtn = false;
        this.btnSpinnerIcon = '';
      } else if (this.resetTime(this.startDateWeekly) > this.resetTime(this.endDateWeekly)) {
        this.btnSpinnerIcon = '';
        this.disableBtn = false;
        this.messageService.add({
          severity: 'error',
          summary: '',
          detail: 'Schedule start date cannot be greater than end date',
        });
      } else {
        this.alertSeverity = 'success';
        this.alertMsg = 'Report Scheduled Successfully!';
        this.addSingle();
        this.router.navigate(['./my-reports']);
        scheOccurrenceBool = false;
        // payload = {
        //   scheduleJobModelId: this.JobId,
        //   reportId: this.reportId,
        //   haveAttachments: 'Y',
        //   triggerType: 'improved',
        //   startType: this.startType,
        //   ocurrenceCount: -1,
        //   advMonths: [],
        //   weekDays: this.weeklyOccurrence.weekDays,
        //   improvedTriggerType: 'weekly',
        //   jobName: this.Jobvalue,
        //   outputLocale: 0,
        //   comment: '',
        //   outputName: this.filename,
        //   mailTo: this.ToValue,
        //   mailBcc: this.BCCValue,
        //   mailCc: this.CCValue,
        //   mailSubject: this.SubjectValue,
        //   timeZone: 'America/Los_Angeles',
        //   monthDays: '',
        //   isEveryWeekday: 'Y',
        //   mailMessage: this.message,
        //   outputType: [this.fileValue],
        //   startDatetime: this.weeklyOccurrence.startDatetime,
        //   endDatetime: this.weeklyOccurrence.endDatetime,
        //   advHours: this.weeklyOccurrence.advHours,
        //   advMinutes: this.weeklyOccurrence.advMinutes,
        //   parameters: this.NewParameter,
        //   isSftp: this.isSftp ? 'Y' : 'N',
        //   isPasswordProtect: this.isPassword ? 'Y' : 'N',
        //   clientCode: this.sftpClientCode,
        //   isPgpEncrypt: this.pgpEncryption ? 'Y' : 'N',
        //   sftpConfigId: this.sftpConfigId,
        //   sftpOutDirectoryPath: this.folderPath,
        //   sftpHostName: this.serverName,
        // };
        // if (this.Type == 'Edit') {
        //   this.scheRepoSerSubscrip = this.schedulerService
        //     .UpdateScheduledReport(this.JobId, payload)
        //     .subscribe(
        //       (response: any) => {
        //         // schedule report
        //         this.btnSpinnerIcon = '';
        //         this.disableBtn = false;
        //         if (response.status == 200) {
        //           this.alertSeverity = 'success';
        //           this.alertMsg = 'Schedule Updated Successfully!';
        //           this.addSingle();
        //           if (this.Type == 'Copy' || 'Edit') {
        //             if (this.boolVal) {
        //               this.router.navigate(['./scheduled-reports']);
        //             }
        //             else {
        //               this.router.navigate(['./my-reports']);
        //             }
        //           } else {
        //             this.router.navigate(['./my-reports']);
        //           }
        //         }
        //         d3.select('.tibo-myrepo-output-spinner').style(
        //           'display',
        //           'none'
        //         );
        //       },
        //       (error) => {
        //         this.btnSpinnerIcon = '';
        //         this.disableBtn = false;
        //         this.alertSeverity = 'error';
        //         if (Number(error.status) == 401) {
        //           this.alertMsg = 'Error While ' + this.Type + 'ing The Schedule! Please Try After Some Time!';
        //         } else if (Number(error.status) == 500) {
        //           this.alertMsg = 'Internal Error';
        //         } else {
        //           this.alertMsg = 'Error While ' + this.Type + 'ing The Schedule! Please Try After Some Time!';
        //         }
        //         this.addSingle();
        //       },
        //       () => { }
        //     );
        // } else if (this.Type == 'Copy') {
        //   this.scheRepoSerSubscrip = this.schedulerService
        //     .copyScheduledReport(this.reportId, payload)
        //     .subscribe(
        //       (response: any) => {
        //         // schedule report
        //         this.btnSpinnerIcon = '';
        //         this.disableBtn = false;
        //         if (response.status == 200) {
        //           this.alertSeverity = 'success';
        //           this.alertMsg = 'Schedule Copied Successfully!';
        //           this.addSingle();
        //           if (this.Type == 'Copy' || 'Edit') {
        //             if (this.boolVal) {
        //               this.router.navigate(['./scheduled-reports']);
        //             }
        //             else {
        //               this.router.navigate(['./my-reports']);
        //             }
        //           } else {
        //             this.router.navigate(['./my-reports']);
        //           }
        //         }
        //       },
        //       (error) => {
        //         this.btnSpinnerIcon = '';
        //         this.disableBtn = false;
        //         this.alertSeverity = 'error';
        //         if (Number(error.status) == 401) {
        //           this.alertMsg = 'Error While ' + this.Type + 'ing The Schedule! Please Try After Some Time!';
        //         } else if (Number(error.status) == 500) {
        //           this.alertMsg = 'Internal Error';
        //         } else {
        //           this.alertMsg = 'Error While ' + this.Type + 'ing The Schedule! Please Try After Some Time!';
        //         }
        //         this.addSingle();
        //       },
        //       () => { }
        //     );
        // } else {
        //   this.scheRepoSerSubscrip = this.schedulerService
        //     .scheduleReportService(this.reportId, payload)
        //     .subscribe(
        //       (response: any) => {
        //         // schedule report
        //         this.btnSpinnerIcon = '';
        //         this.disableBtn = false;
        //         if (response.status == 200) {
        //           this.alertSeverity = 'success';
        //           this.alertMsg = 'Report Scheduled Successfully!';
        //           this.addSingle();
        //           if (this.Type == 'Copy' || 'Edit') {
        //             if (this.boolVal) {
        //               if (this.boolVal) {
        //                 this.router.navigate(['./scheduled-reports']);
        //               }
        //               else {
        //                 this.router.navigate(['./my-reports']);
        //               }
        //             }
        //           } else {
        //             this.router.navigate(['./my-reports']);
        //           }
        //         }
        //       },
        //       (error) => {
        //         this.btnSpinnerIcon = '';
        //         this.disableBtn = false;
        //         this.alertSeverity = 'error';
        //         if (Number(error.status) == 401) {
        //           this.alertMsg = 'Error While Creating The Schedule!Please Try After Some Time!';
        //         } else if (Number(error.status) == 500) {
        //           this.alertMsg = 'Internal Error';
        //         } else {
        //           this.alertMsg = 'Error While Creating The Schedule!Please Try After Some Time.';
        //         }
        //         this.addSingle();
        //       },
        //       () => { }
        //     );
        // }
      }
    } else if (this.monthlyScheduler == true) {
      if (
        this.monthlyOccurrence.advHours == null ||
        this.monthlyOccurrence.advMinutes == null ||
        this.monthlyOccurrence.isDayOrDate == '' ||
        this.monthlyOccurrence.startDatetime == '' ||
        this.monthlyOccurrence.endDatetime == ''
      ) {
        scheOccurrenceBool = true;
        this.btnSpinnerIcon = '';
        this.disableBtn = false;
        this.messageService.add({
          severity: 'error',
          summary: 'Mandatory',
          detail: 'Please Fill Mandatory Fields!',
        });
      } else if (this.resetTime(this.startDateMonthly) > this.resetTime(this.endDateMonthly)) {
        this.btnSpinnerIcon = '';
        this.disableBtn = false;
        this.messageService.add({
          severity: 'error',
          summary: '',
          detail: 'Schedule start date cannot be greater than end date',
        });
      } else {
        
        scheOccurrenceBool = false;
        payload = {
          scheduleJobModelId: this.JobId,
          reportId: this.reportId,
          haveAttachments: 'Y',
          triggerType: 'improved',
          startType: this.startType,
          ocurrenceCount: -1,
          advMonths: [],
          weekDays: [],
          improvedTriggerType: 'monthly',
          jobName: this.Jobvalue,
          outputLocale: 0,
          comment: '',
          outputType: [this.fileValue],
          outputName: this.filename,
          mailTo: this.ToValue,
          mailBcc: this.BCCValue,
          mailCc: this.CCValue,
          mailSubject: this.SubjectValue,
          timeZone: 'America/Los_Angeles',
          monthDays: this.monthlyOccurrence.monthDays,
          isEveryWeekday: 'Y',
          mailMessage: this.message,
          isDayOrDate: 'date',
          recurrenceInterval: this.monthlyOccurrence.recurrenceInterval,
          startDatetime: this.monthlyOccurrence.startDatetime,
          endDatetime: this.monthlyOccurrence.endDatetime,
          advHours: this.monthlyOccurrence.advHours,
          advMinutes: this.monthlyOccurrence.advMinutes,
          parameters: this.NewParameter,
          isSftp: this.isSftp ? 'Y' : 'N',
          isPasswordProtect: this.isPassword ? 'Y' : 'N',
          clientCode: this.sftpClientCode,
          isPgpEncrypt: this.pgpEncryption ? 'Y' : 'N',
          sftpConfigId: this.sftpConfigId,
          sftpOutDirectoryPath: this.folderPath,
          sftpHostName: this.serverName,
        };
        if (!this.monthlyOccurrence.hasOwnProperty('dayUnit')) {
          if (this.monthlyOccurrence.monthDays == 0 || this.monthlyOccurrence.recurrenceInterval == 0) {
            scheOccurrenceBool = true;
            this.btnSpinnerIcon = '';
            this.disableBtn = false;
            this.messageService.add({
              severity: 'error',
              summary: 'Mandatory',
              detail: 'Please Fill Mandatory Fields!',
            });
          } else {
            this.alertSeverity = 'success';
          this.alertMsg = 'Report Scheduled Successfully!';
          this.addSingle();
          this.router.navigate(['./my-reports']);
            // if (this.Type == 'Edit') {
            //   this.scheRepoSerSubscrip = this.schedulerService
            //     .UpdateScheduledReport(this.JobId, payload)
            //     .subscribe(
            //       (response: any) => {
            //         // schedule report
            //         this.btnSpinnerIcon = '';
            //         this.disableBtn = false;
            //         this.disableBtn = false;
            //         if (response.status == 200) {
            //           this.alertSeverity = 'success';
            //           this.alertMsg = 'Schedule Updated Successfully!';
            //           this.addSingle();
            //           if (this.Type == 'Copy' || 'Edit') {
            //             if (this.boolVal) {
            //               this.router.navigate(['./scheduled-reports']);
            //             }
            //             else {
            //               this.router.navigate(['./my-reports']);
            //             }
            //           } else {
            //             this.router.navigate(['./my-reports']);
            //           }
            //         }
            //       },
            //       (error) => {
            //         this.btnSpinnerIcon = '';
            //         this.disableBtn = false;
            //         this.alertSeverity = 'error';
            //         if (Number(error.status) == 401) {
            //           this.alertMsg = 'Error While ' + this.Type + 'ing The Schedule! Please Try After Some Time!';
            //         } else if (Number(error.status) == 500) {
            //           this.alertMsg = 'Internal Error';
            //         } else {
            //           this.alertMsg = 'Error While ' + this.Type + 'ing The Schedule! Please Try After Some Time!';
            //         }
            //         this.addSingle();
            //       },
            //       () => { }
            //     );
            // } else if (this.Type == 'Copy') {
            //   this.scheRepoSerSubscrip = this.schedulerService
            //     .copyScheduledReport(this.reportId, payload)
            //     .subscribe(
            //       (response: any) => {
            //         // schedule report
            //         this.btnSpinnerIcon = '';
            //         this.disableBtn = false;
            //         this.disableBtn = false;
            //         if (response.status == 200) {
            //           this.alertSeverity = 'success';
            //           this.alertMsg = 'Schedule Copied Successfully!';
            //           this.addSingle();
            //           if (this.Type == 'Copy' || 'Edit') {
            //             if (this.boolVal) {
            //               this.router.navigate(['./scheduled-reports']);
            //             }
            //             else {
            //               this.router.navigate(['./my-reports']);
            //             }
            //           } else {
            //             this.router.navigate(['./my-reports']);
            //           }
            //         }
            //       },
            //       (error) => {
            //         this.btnSpinnerIcon = '';
            //         this.disableBtn = false;
            //         this.alertSeverity = 'error';
            //         if (Number(error.status) == 401) {
            //           this.alertMsg = 'Error While ' + this.Type + ' The Schedule!Please Try After Some Time!';
            //         } else if (Number(error.status) == 500) {
            //           this.alertMsg = 'Internal Error';
            //         } else {
            //           this.alertMsg = 'Error While ' + this.Type + ' The Schedule!Please Try After Some Time!';
            //         }
            //         this.addSingle();
            //       },
            //       () => { }
            //     );
            // } else {
            //   this.scheRepoSerSubscrip = this.schedulerService
            //     .scheduleReportService(this.reportId, payload)
            //     .subscribe(
            //       (response: any) => {
            //         // schedule report
            //         this.btnSpinnerIcon = '';
            //         this.disableBtn = false;
            //         if (response.status == 200) {
            //           this.alertSeverity = 'success';
            //           this.alertMsg = 'Report Scheduled Successfully!';
            //           this.addSingle();
            //           if (this.Type == 'Copy' || 'Edit') {
            //             if (this.boolVal) {
            //               this.router.navigate(['./scheduled-reports']);
            //             }
            //             else {
            //               this.router.navigate(['./my-reports']);
            //             }
            //           } else {
            //             this.router.navigate(['./my-reports']);
            //           }
            //         }
            //       },
            //       (error) => {
            //         this.btnSpinnerIcon = '';
            //         this.disableBtn = false;
            //         this.alertSeverity = 'error';
            //         if (Number(error.status) == 401) {
            //           this.alertMsg = 'Error While Creating The Schedule!Please Try After Some Time!';
            //         } else if (Number(error.status) == 500) {
            //           this.alertMsg = 'Internal Error';
            //         } else {
            //           this.alertMsg = 'Error While Creating The Schedule!Please Try After Some Time.';
            //         }
            //         this.addSingle();
            //       },
            //       () => { }
            //     );
            // }
          }
        }
        if (this.monthlyOccurrence.hasOwnProperty('dayUnit')) {
          if (
            this.monthlyOccurrence['dayUnit'] == '' ||
            this.monthlyOccurrence['dayUnitValue'] == '' || this.monthlyOccurrenceMonthVal2 == 0
          ) {
            scheOccurrenceBool = true;
            this.btnSpinnerIcon = '';
            this.disableBtn = false;
            this.messageService.add({
              severity: 'error',
              summary: 'Mandatory',
              detail: 'Please Fill Mandatory Fields!',
            });
          } else {
            this.alertSeverity = 'success';
            this.alertMsg = 'Report Scheduled Successfully!';
            this.addSingle();
            this.router.navigate(['./my-reports']);
            // payload = {
            //   scheduleJobModelId: this.JobId,
            //   reportId: this.reportId,
            //   haveAttachments: 'Y',
            //   triggerType: 'improved',
            //   startType: this.startType,
            //   ocurrenceCount: -1,
            //   advMonths: [],
            //   weekDays: [],
            //   improvedTriggerType: 'monthly',
            //   jobName: this.Jobvalue,
            //   outputLocale: 0,
            //   comment: '',
            //   outputName: this.filename,
            //   mailTo: this.ToValue,
            //   mailCc: this.CCValue,
            //   mailBcc: this.BCCValue,
            //   mailSubject: this.SubjectValue,
            //   timeZone: 'America/Los_Angeles',
            //   monthDays: this.monthlyOccurrence.monthDays,
            //   isEveryWeekday: 'Y',
            //   mailMessage: this.message,
            //   outputType: [this.fileValue],
            //   isDayOrDate: 'day',
            //   dayUnit: this.monthlyOccurDrop1,
            //   dayUnitValue: this.monthlyOccurDrop2,
            //   recurrenceInterval: this.monthlyOccurrenceMonthVal2,
            //   startDatetime: this.monthlyOccurrence.startDatetime,
            //   endDatetime: this.monthlyOccurrence.endDatetime,
            //   advHours: this.monthlyOccurrence.advHours,
            //   advMinutes: this.monthlyOccurrence.advMinutes,
            //   parameters: this.NewParameter,
            //   isSftp: this.isSftp ? 'Y' : 'N',
            //   isPasswordProtect: this.isPassword ? 'Y' : 'N',
            //   clientCode: this.sftpClientCode,
            //   isPgpEncrypt: this.pgpEncryption ? 'Y' : 'N',
            //   sftpConfigId: this.sftpConfigId,
            //   sftpOutDirectoryPath: this.folderPath,
            //   sftpHostName: this.serverName,
            // };

            // if (this.Type == 'Edit') {
            //   this.scheRepoSerSubscrip = this.schedulerService
            //     .UpdateScheduledReport(this.JobId, payload)
            //     .subscribe(
            //       (response: any) => {
            //         // schedule report
            //         this.btnSpinnerIcon = '';
            //         this.disableBtn = false;
            //         this.disableBtn = false;
            //         if (response.status == 200) {
            //           this.alertSeverity = 'success';
            //           this.alertMsg = 'Schedule Updated Successfully!';
            //           this.addSingle();
            //           if (this.Type == 'Copy' || 'Edit') {
            //             if (this.boolVal) {
            //               this.router.navigate(['./scheduled-reports']);
            //             }
            //             else {
            //               this.router.navigate(['./my-reports']);
            //             }
            //           } else {
            //             this.router.navigate(['./my-reports']);
            //           }
            //         }
            //       },
            //       (error) => {
            //         this.btnSpinnerIcon = '';
            //         this.disableBtn = false;
            //         this.alertSeverity = 'error';
            //         if (Number(error.status) == 401) {
            //           this.alertMsg = 'Error While ' + this.Type + 'ing The Schedule! Please Try After Some Time!';
            //         } else if (Number(error.status) == 500) {
            //           this.alertMsg = 'Internal Error';
            //         } else {
            //           this.alertMsg = 'Error While ' + this.Type + 'ing The Schedule! Please Try After Some Time!';
            //         }
            //         this.addSingle();
            //       },
            //       () => { }
            //     );
            // } else if (this.Type == 'Copy') {
            //   this.scheRepoSerSubscrip = this.schedulerService
            //     .copyScheduledReport(this.reportId, payload)
            //     .subscribe(
            //       (response: any) => {
            //         // schedule report
            //         this.btnSpinnerIcon = '';
            //         this.disableBtn = false;
            //         this.disableBtn = false;
            //         if (response.status == 200) {
            //           this.alertSeverity = 'success';
            //           this.alertMsg = 'Schedule Copied Successfully!';
            //           this.addSingle();
            //           if (this.Type == 'Copy' || 'Edit') {
            //             if (this.boolVal) {
            //               this.router.navigate(['./scheduled-reports']);
            //             }
            //             else {
            //               this.router.navigate(['./my-reports']);
            //             }
            //           } else {
            //             this.router.navigate(['./my-reports']);
            //           }
            //         }
            //       },
            //       (error) => {
            //         this.btnSpinnerIcon = '';
            //         this.disableBtn = false;
            //         this.disableBtn = false;
            //         this.alertSeverity = 'error';
            //         if (Number(error.status) == 401) {
            //           this.alertMsg = 'Error While ' + this.Type + 'ing The Schedule! Please Try After Some Time!';
            //         } else if (Number(error.status) == 500) {
            //           this.alertMsg = 'Internal Error';
            //         } else {
            //           this.alertMsg = 'Error While ' + this.Type + 'ing The Schedule! Please Try After Some Time!';
            //         }
            //         this.addSingle();
            //       },
            //       () => { }
            //     );
            // } else {
            //   this.scheRepoSerSubscrip = this.schedulerService
            //     .scheduleReportService(this.reportId, payload)
            //     .subscribe(
            //       (response: any) => {
            //         // schedule report
            //         this.btnSpinnerIcon = '';
            //         this.disableBtn = false;
            //         if (response.status == 200) {
            //           this.alertSeverity = 'success';
            //           this.alertMsg = 'Report Scheduled Successfully!';
            //           this.addSingle();
            //           if (this.Type == 'Copy' || 'Edit') {
            //             if (this.boolVal) {
            //               this.router.navigate(['./scheduled-reports']);
            //             }
            //             else {
            //               this.router.navigate(['./my-reports']);
            //             }
            //           } else {
            //             this.router.navigate(['./my-reports']);
            //           }
            //         }
            //       },
            //       (error) => {
            //         this.btnSpinnerIcon = '';
            //         this.disableBtn = false;
            //         this.alertSeverity = 'error';
            //         if (Number(error.status) == 401) {
            //           this.alertMsg = 'Error While Creating The Schedule!Please Try After Some Time!';
            //         } else if (Number(error.status) == 500) {
            //           this.alertMsg = 'Internal Error';
            //         } else {
            //           this.alertMsg = 'Error While Creating The Schedule!Please Try After Some Time.';
            //         }
            //         this.addSingle();
            //       },
            //       () => { }
            //     );
            // }
          }
        }
      }
    } else if (this.now == true) {
      this.alertSeverity = 'success';
        this.alertMsg = 'Report Scheduled Successfully!';
        this.addSingle();
        this.router.navigate(['./my-reports']);
      // payload = {
      //   scheduleJobModelId: this.JobId,
      //   reportId: this.reportId,
      //   haveAttachments: 'Y',
      //   triggerType: 'NONE',
      //   startType: this.startType,
      //   ocurrenceCount: -1,
      //   advMonths: [],
      //   weekDays: [],
      //   improvedTriggerType: '',
      //   jobName: this.Jobvalue,
      //   outputLocale: 0,
      //   comment: '',
      //   outputName: this.filename,
      //   mailTo: this.ToValue,
      //   mailCc: this.CCValue,
      //   mailBcc: this.BCCValue,
      //   mailSubject: this.SubjectValue,
      //   timeZone: 'America/Los_Angeles',
      //   monthDays: '',
      //   isEveryWeekday: 'Y',
      //   mailMessage: this.message,
      //   outputType: [this.fileValue],
      //   startDatetime: '',
      //   endDatetime: '',
      //   parameters: this.NewParameter,
      //   isSftp: this.isSftp ? 'Y' : 'N',
      //   isPasswordProtect: this.isPassword ? 'Y' : 'N',
      //   clientCode: this.sftpClientCode,
      //   isPgpEncrypt: this.pgpEncryption ? 'Y' : 'N',
      //   sftpConfigId: this.sftpConfigId,
      //   sftpOutDirectoryPath: this.folderPath,
      //   sftpHostName: this.serverName,
      // };

      // if (this.Type == 'Edit') {
      //   this.scheRepoSerSubscrip = this.schedulerService
      //     .UpdateScheduledReport(this.JobId, payload)
      //     .subscribe(
      //       (response: any) => {
      //         // schedule report
      //         this.btnSpinnerIcon = '';
      //         this.disableBtn = false;
      //         if (response.status == 200) {
      //           this.alertSeverity = 'success';
      //           this.alertMsg = 'Schedule Updated Successfully!';
      //           this.addSingle();
      //           if (this.Type == 'Copy' || 'Edit') {
      //             if (this.boolVal) {
      //               this.router.navigate(['./scheduled-reports']);
      //             }
      //             else {
      //               this.router.navigate(['./my-reports']);
      //             }
      //           } else {
      //             this.router.navigate(['./my-reports']);
      //           }
      //         }
      //       },
      //       (error) => {
      //         this.btnSpinnerIcon = '';
      //         this.disableBtn = false;
      //         this.alertSeverity = 'error';
      //         if (Number(error.status) == 401) {
      //           this.alertMsg = 'Please Try After Some Time!';
      //         } else if (Number(error.status) == 500) {
      //           this.alertMsg = 'Internal Error';
      //         } else {
      //           this.alertMsg = 'Please Try After Some Time.';
      //         }
      //         this.addSingle();
      //       },
      //       () => { }
      //     );
      // } else if (this.Type == 'Copy') {
      //   this.scheRepoSerSubscrip = this.schedulerService
      //     .copyScheduledReport(this.reportId, payload)
      //     .subscribe(
      //       (response: any) => {
      //         // schedule report
      //         this.btnSpinnerIcon = '';
      //         this.disableBtn = false;
      //         if (response.status == 200) {
      //           this.alertSeverity = 'success';
      //           this.alertMsg = 'Schedule Copied Successfully!';
      //           this.addSingle();
      //           if (this.Type == 'Copy' || 'Edit') {
      //             if (this.boolVal) {
      //               if (this.boolVal) {
      //                 this.router.navigate(['./scheduled-reports']);
      //               }
      //               else {
      //                 this.router.navigate(['./my-reports']);
      //               }
      //             }
      //           } else {
      //             this.router.navigate(['./my-reports']);
      //           }
      //         }
      //       },
      //       (error) => {
      //         this.btnSpinnerIcon = '';
      //         this.disableBtn = false;
      //         this.alertSeverity = 'error';
      //         if (Number(error.status) == 401) {
      //           this.alertMsg = 'Please Try After Some Time!';
      //         } else if (Number(error.status) == 500) {
      //           this.alertMsg = 'Internal Error';
      //         } else {
      //           this.alertMsg = 'Please Try After Some Time.';
      //         }
      //         this.addSingle();
      //       },
      //       () => { }
      //     );
      // } else {
      //   this.scheRepoSerSubscrip = this.schedulerService
      //     .scheduleReportService(this.reportId, payload)
      //     .subscribe(
      //       (response: any) => {
      //         this.btnSpinnerIcon = '';
      //         this.disableBtn = false;
      //         if (response.status == 200) {
      //           this.alertSeverity = 'success';
      //           this.summary = 'Scheduled';
      //           this.alertMsg = 'Report Scheduled Successfully!';
      //           this.addSingle();
      //           if (this.Type == 'Copy' || 'Edit') {
      //             if (this.boolVal) {
      //               this.router.navigate(['./scheduled-reports']);
      //             }
      //             else {
      //               this.router.navigate(['./my-reports']);
      //             }
      //           } else {
      //             this.router.navigate(['./my-reports']);
      //           }
      //         }
      //       },
      //       (error) => {
      //         this.btnSpinnerIcon = '';
      //         this.disableBtn = false;
      //         this.alertSeverity = 'error';
      //         if (Number(error.status) == 401) {
      //           this.alertMsg = 'Please Try After Some Time!';
      //         } else if (Number(error.status) == 500) {
      //           this.alertMsg = 'Internal Error';
      //         } else {
      //           this.alertMsg = 'Please Try After Some Time.';
      //         }
      //         this.addSingle();
      //       },
      //       () => { }
      //     );
      // }
    }
    //}
  }

  cancel(event) {
    // event.preventDefault()
    if (this.component == "favourite") {
      this.router.navigate(['./my-favorites']);
    }
    else if (this.component == "adhoc") {
      this.router.navigate(['./adhoc']);
    }
    else if (this.component == "build") {
      this.router.navigate(['./build-report']);
    }
    else if (this.component == "my-reports") {
      this.router.navigate(['./my-reports']);
    }
    else if (this.component == "my-favorites") {
      this.router.navigate(['./my-favorites']);
    }
    else if (this.component == "discovery") {
      this.router.navigate(['./discovery'], { queryParams: { search: this.searchMetri } });
      this.showreport = false;
    }
    else {
      this.router.navigate(['./scheduled-reports']);
    }

  }

  addSingle() {
    this.messageService.add({
      severity: this.alertSeverity,
      summary: this.summary,
      detail: this.alertMsg,
    });
    this.summary = '';

  }

  clear() {
    this.messageService.clear();
  }

  addSubjectDateFun(event) {
    if (this.addSubjectDate) {
      this.SubjectValue = this.SubjectValue + ' ' + this.SubjectDate;
    } else {
      this.SubjectValue = this.SubjectValue.replace(
        this.SubjectDate,
        ''
      ).trim();
    }
    this.SubjectValue = this.SubjectValue;
    this.addSubjectDate = event;
  }
  addSubjectDateFun1(event) {
    if (this.addSubjectDate1) {
      this.filename = this.filename + ' ' + this.SubjectDate1;
    } else {
      this.filename = this.SubjectValue.replace(
        this.SubjectDate,
        ''
      ).trim();
    }
    this.filename = this.filename;
    this.addSubjectDate1 = event;
  }
  // testMail(event) {
  //  // this.errorMessage = ''; // reinitialize error message

  //   if(!this.validateEmail(event.value)) {
  //     this.errorMessage = event.value + ' is not a valid mail address !'; // display message
  //     this.emails.pop(); // remove last entry from emails array of strings
  //   }
  // }
  back() {
    this.router.navigate(['/discovery'], { queryParams: { search: this.searchMetri } });
    this.showreport = false;
    // window.history.back();
  }
}

// const flags = "gm";
// const pattern = "[A-Za-z0-9\._%+\-]+@[A-Za-z0-9\.\-]+\.[A-Za-z]{2,}"
// const regexPattern = new RegExp(pattern, flags);
// const text =``;
// const result = text.match(regexPattern);
// //console.log("Matches:", result);