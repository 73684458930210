<div class="tibo-mydash-wrapper tibo-right-container create-dashboard-parent-container">
    <div class="domain-name-section" *ngIf="!showCreateDash">
        <div class="p-d-flex">
            <div class="first-domain-name">
                <div class="drop-down-div">
                    <p-dropdown class="dd-dropdown" [options]="listDomains" appendTo="body"
                        optionLabel="domainName" optionValue="domainId" [(ngModel)]="selectedDomain"
                        (onChange)="domainChange($event)" placeholder="--Select Domain Name--" [filter]="true" 
                        [dropdownIcon]="listDomainsIsLoading ? 'pi pi-spinner pi-spin' : 'pi pi-chevron-down'">
                    </p-dropdown>
                </div>
            </div>
            <div class="second-domain-name">
                <div class="head-button head-button">
                    <button class="btn" (click)="editDashboard('create')" type="button" pButton icon="pi pi-plus"
                        pTooltip="Add Dashboard" tooltipPosition="top"></button>
                    <!-- <button class="btn" (click)="editDashboard('edit')" (click)="getParameterListData()" type="button"
                        [disabled]="disableBtn" pButton icon="fa fa-pencil-square-o" pTooltip="Edit Dashboard"
                        tooltipPosition="top"></button> -->
                    <button class="btn" type="button" [disabled]="disableBtn" (click)="deleteDialogVisible = true"
                        pButton icon="pi pi-trash" pTooltip="Delete Dashboard" tooltipPosition="top"></button>
                </div>
            </div>
            <!-- <p-selectButton [options]="stateOptions" [(ngModel)]="listOrTileView" optionLabel="label"
                optionValue="value" (onChange)="changeViewFunc($event)" class="tile-list-view-btn"></p-selectButton> -->
                <!-- <p-selectButton [options]="stateOptions" [(ngModel)]="listOrTileView" optionLabel="label"
                    optionValue="value" class="tile-list-view-btn" (onChange)="changeViewFunc($event)">
                    <ng-template let-option pTemplate="item">
                        <span [pTooltip]="option.tooltip">
                            <i [class]="option.icon"></i> 
                        </span>
                    </ng-template>
                </p-selectButton> -->
        </div>
        <p-card class="table-card" [ngClass]="{'empty-table-card':widgetTableData.length === 0}">
            <p-table #scheduledreportstable11 [value]="widgetTableData" [scrollable]="true"
                scrollHeight="calc(100vh - 255px)" [customSort]="true" selectionMode="single"  [sortField]="tableSortColumn"
                [sortOrder]="tableSortDir"
                (sortFunction)="customSort($event)"
                [tableStyle]="{'min-width':'50rem'}">
                <ng-template pTemplate="header">
                    <tr>
                        <th pSortableColumn="widgetName" class="firsh-table-header" (click)="theadClick($event)">
                            <div class="p-d-flex p-jc-between p-ai-center">
                                Created Dashboard List
                                <p-sortIcon field="widgetName"></p-sortIcon>
                            </div>
                            <i class="pi pi-spin pi-spinner table-header-spinner" *ngIf="widgetTableDataIsLoading"></i>
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-col let-i="rowIndex">
                    <tr (click)="onTileSelect(i)" [ngClass]="{'p-highlight':getTableRowIndex === i}">
                        <td>{{col?.widgetName}}</td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td class="firsh-table-td">{{ tableEmptyMessage }}</td>
                    </tr>
                </ng-template>
            </p-table>
            <div class="p-d-flex pagination-container">
                <div class="pagination-first-part">
                    <p class="p-mx-2">Showing <b>{{startIndex}}-{{endIndex}}</b> of <b>{{totalRecords}}</b>
                        Records
                    </p>
                </div>
                <div class="pagination-second-part">
                    <p-paginator #paginator styleClass="tibo-schedrepo-listView-p-paginator" [rows]="150"
                        [totalRecords]="totalRecords" (onPageChange)="paginate($event)" [showJumpToPageDropdown]="true"
                        [showPageLinks]="false"></p-paginator>
                </div>
            </div>
        </p-card>
    </div>
    <div class="container-create-dashboard p-d-flex" *ngIf="showCreateDash">
        <div class="first-section">
            <p-dialog [(visible)]="saveDashDialBoxDisplayBool" styleClass="tibo-mydash-sharedash-box" [modal]="true"
                [draggable]="false" [resizable]="false" (onHide)="dialogBoxOnCloseFunc($event)">
                <ng-template pTemplate="header">
                    <label class="tibo-mydash-sharedash-box-sharelabel">Dashboard Details</label>
                </ng-template>
                <div class="p-grid p-col-12 p-ml-1 p-p-0">
                    <div class="p-grid p-col-12 p-mr-3 p-pt-4 p-xl-6 p-lg-12 p-md-12 p-sm-12">
                        <label class="p-col-12" style="font-family: Roboto; font-size:12px;">Select Domain <span
                                class="tibo-mydash-paramdash-param-title-mand">*</span></label>
                        <p-dropdown styleClass="dropdown" class="p-col-12 p-p-0" appendTo="body" [options]="domainsList"
                            optionLabel="label" optionValue="value" placeholder="Select a domain"  
                            (onChange)="onDomainSelect($event)">
                        </p-dropdown>
                    </div>
                    <div class="p-grid p-col-12 p-pt-4 p-xl-6 p-lg-12 p-md-12 p-sm-12">
                        <label class="p-col-12" style="font-family: Roboto; font-size:12px;">Dashboard Name <span
                                class="tibo-mydash-paramdash-param-title-mand">*</span></label>
                        <input id="dashboard-name-id" type="text" class="tibo-myrepo-schelr-elements-inputtext"
                            pInputText [(ngModel)]="dashboardName" placeholder="Enter dashboard name">
                    </div>
                </div>
                <ng-template pTemplate="footer">
                    <p-button label="Save" (click)="createDashboard()"></p-button>
                </ng-template>
            </p-dialog>
            <button id="resizeBtn" class="tibo-mydash-resize-btn" type="button">Resize All</button>
            <div class="tibo-mydash-charts-container">
                <div id="dashboard-parameterized-scroll-wrapper" class="tibo-mydash-paramdash-wrapper">
                    <div id="dashboard-parameterized-filter" class="tibo-mydash-paramdash-filtrpanl">
                        <div class="p-grid p-col-11 p-ai-center vertical-container p-mx-2 p-mt-2 detail-conainer">
                            <span class="p-my-2"
                                style="padding: 0px; background-color: rgb(255, 255, 255); font-family: Roboto; font-size: 12px;">
                                <input class="p-mr-1" type="radio" name="createdashmetric"
                                    (change)="radioSwitch('search metric')">
                                <label style="position:relative; top:-2px; font-family:Roboto; font-size: 12px;">Search
                                    Metric</label>
                            </span>
                            <span *ngIf="metricSearchBool" class="p-input-span searchClass">
                                <i class="pi pi-search piSearch2" *ngIf="!isLoading1" (click)="search()"></i>
                                <i class="pi pi-spin pi-spinner piSearch2" *ngIf="isLoading1"></i>
                                <input class="form-input" pInputText type="text" [(ngModel)]="text" (keyup.enter)="search()"
                                    
                                    [disabled]="searchFieldDisable" placeholder="Search Metric" />
                            </span>
                            <!-- <span *ngIf="metricSearchBool" class="p-col-12"
                                style="padding: 5px; display: flex;background-color: rgb(255, 255, 255); font-family: Roboto; font-size: 12px;">
                                <input type="text" class="tibo-myrepo-schelr-elements-inputtext" pInputText
                                    [(ngModel)]="text" placeholder="Metric name"><i class="pi pi-spin pi-spinner spinClass" *ngIf="isLoading1"></i>
                                <button class="buttonClass" (click)="search()"> <i class="pi pi-search"></i></button>
                                <!-- <div class="p-col-12 p-md-4">
                                    <div class="p-inputgroup">
                                        <input type="text" style="width:100px" pInputText  [(ngModel)]="text" placeholder="Metric name"> 
                                        <button type="button" pButton (click)="search()"><i class="pi pi-search"></i></button>
                                                
                                    </div>
                                </div> -->
                            <!-- </span> -->
                        </div>
                        <div class="p-grid p-col-11 p-ai-center vertical-container p-mx-2 p-mb-2 detail-conainer">
                            <span class="p-my-2"
                                style="padding: 0px; background-color: rgb(255, 255, 255); font-family: Roboto; font-size: 12px;">
                                <input class="p-mr-1" type="radio" name="createdashmetric"
                                    (change)="radioSwitch('metric from dashboards')">
                                <label style="position:relative; top:-2px; font-family:Roboto; font-size: 12px;" placeholder="Select Metric Dashboard" >Metric
                                    From Dashboards</label>
                            </span>
                            <span *ngIf="metricFrmDashBool" class="p-input-span searchClass">
                                <!-- <i class="pi pi-chevron-down piSearch" style="font-size: 1rem;float: right;"
                                    (click)="showList=!showList"></i> -->
                                    <span class="p-input-icon-right">
                                        <input class="selectedName" type="buton" pInputText placeholder="Select Metric Dashboard" [(ngModel)]="selectedDashboard.name" disabled="true">
                                        <i class="pi pi-chevron-down" (click)="showList=!showList"></i>
                                    </span>
                                    <!-- <p-chip class="tibo-mydash-openeddashlist-chips" placeholder="Select Metric Dashboard"  label="{{selectedDashboard?.name}}"></p-chip> -->
                                     <!-- <input class="form-input" pInputText type="text" [(ngModel)]="selectedDashboard.name"
                                    placeholder="Select Metric Dashboard" /> -->
                                <p-panelMenu [model]="items" styleClass="tibo-mydash-dashlist-panel" [multiple]=false appendTo="body"
                                    *ngIf="showList" (click)="!showList"></p-panelMenu>
                            </span>
                        </div>
                       
                        <div *ngIf="showSearch">
                            <img src="././assets/images/dualBallSpinner.svg" alt="" class="spinner-img" *ngIf="isLoading1">
                            <!-- <img src="././assets/images/dualBallSpinner.svg" alt="" class="spinner-img" *ngIf="isLoading1"> -->
                            <div *ngIf="showSearch1 && !showError" class="listClass" [ngClass]="{'listClass': showSearch1}">List
                                of Widgets</div>
                            <div *ngIf="showSearch1 && showError" class="listClass" [ngClass]="{'listClass': showSearch1}">No Result Found</div>
                            <p-scrollPanel id="tibo-mydash-nonparamdash-scrollpanel" *ngIf="showSearch1"
                                styleClass="p-my-4 tibo-mydash-nonparamdash-scrollpanel custombar2 ">
                                <div [id]="'draggable-'+ widget?.widgetControlId"
                                    class="tibo-mydash-widget p-grid p-col-11 p-ai-center vertical-container"
                                    [ngClass]='parameterized-widget' draggable="true" (dragstart)="dragStart($event,i)"
                                    (dragend)="dragEnd($event)" *ngFor="let widget of widgetsList let i = index">
                                    <!-- {{ searchOrDashResult == "search result || 'dashboard result " ? widget?.chartTitle: "No Title" }} -->
                                    <span style="display:contents"><img
                                            src="{{'././assets/images/chart-icon/chart'+ widget['controlMasterId']+'.svg'}}"
                                            style='width: 33px;border: 1px solid #f0f0f0;position: absolute;margin-top: -3px;margin-left: 15px;'>
                                        <p style="padding-left: 50px;text-align: left;">{{ widget?.chartTitle ? widget?.chartTitle : "No
                                            Title" }} </p>
                                    </span>

                                </div>
                                <!--</div>-->
                            </p-scrollPanel>
                            <!-- <div class="listClass" *ngIf="showMessage">No Widgets</div> -->
                        </div>
                       
                    </div>
                </div>
            </div>
        </div>
        <div class="second-section">
            <div id="dashboard-parameterized-scroll-view" class="placeholder-scroll-class">
                <p-scrollPanel styleClass="tibo-mydash-paramdash-scrollpanel custombar2">
                    <div class="tibo-mydash-paramdash-widget" *ngFor="let row of rows let i = index">
                        <div class="widget-class create-dashboard-widget-container" id="widgetClass-{{i+1}}-{{j+1}}"  [ngClass]="'widget-class-3'"
                            [attr.objectCordinates]="(i+1)+','+(j+1)" (drop)="drop($event, j, i)"
                            (dragover)="allowDrop($event, j, i)" *ngFor="let column of columns; let j = index;">
                            <!--<p *ngIf="i+','+j == '0,0'">click{{i+","+j}}</p>-->
                            <!--{{ i+','+j == '0,0' ? '0,0' : ''}}-->
                            <p-progressSpinner styleClass="tibo-mydash-widget-spinner1 custom-spinner" strokeWidth="4"
                                fill="#FFFFFF" animationDuration="1.5s">
                            </p-progressSpinner>
                            <i class="pi pi-plus" id="{{'loader'+(i+1)+(j+1)}}"></i>

                        </div>
                    </div>
                </p-scrollPanel>
            </div>
            <div class="btn-class">
                <button label="{{buttonLable}}" pButton class="btn save-btn" style="float:right;margin-right: 35px;"
                styleClass="tibo-discovery-submit" (click)="saveDashboard()"></button>
                <button label="Cancel" pButton class="btn save-btn" style="float:right;margin-right: 20px;"
                    styleClass="tibo-discovery-submit" (click)="cancel()"></button>
               
            </div>
        </div>
    </div>
    <p-dialog header=" " [draggable]="false" class="delete-dialog" [position]="'top'" [modal]="true"
        [(visible)]="deleteDialogVisible" [style]="{ width: '800px' }">
        <i class='pi pi-exclamation-triangle stop-modal-bx-error'></i> <span class="header-text">Are You Sure You Want To Delete
            The Dashboard - "<b>{{dashboardName}}</b>" ?</span>
        <ng-template pTemplate="footer">
            <p-button class="cancel-btn" (click)="deleteDialogVisible = false" label="Cancel"></p-button>
            <p-button class="save-btn" (click)="deleteDashboardList(getTableRowIndex)"
                (click)="deleteDialogVisible = false" label="Delete"></p-button>
        </ng-template>
    </p-dialog>
</div>
<p-toast></p-toast>