<!-- <div class="main-body" [ngClass]="{'main-body-2':!show}">
    <app-login *ngIf="!show" (boolVal)="enterAppFunc($event)"></app-login>
    <div class="internal-body-one" *ngIf="show && !isLoading">
        <app-menu-panel (toggleSidebar)="toggleSidebarAppCom($event)"
            [getTodayFailedCountSideBar]="getTodayFailedCountSideBar"></app-menu-panel>
    </div>
    <div class="internal-body-two" *ngIf="show && !isLoading">
        <app-top-bar></app-top-bar>
        <router-outlet></router-outlet>
    </div>
</div> -->
<div class="main-body" [ngClass]="{'main-body-2':!isLoggedIn}">
    <app-login *ngIf="!isLoggedIn" (boolVal)="enterAppFunc($event)"></app-login>
    <div class="internal-body-one" *ngIf="isLoggedIn">
        <app-menu-panel (toggleSidebar)="toggleSidebarAppCom($event)"
            [getTodayFailedCountSideBar]="getTodayFailedCountSideBar"></app-menu-panel>
    </div>
    <!-- <app-progress-bar></app-progress-bar> -->
    <div class="internal-body-two" *ngIf="isLoggedIn">
        <app-top-bar></app-top-bar>
        <router-outlet></router-outlet>
    </div>
    
</div>
<div>
    <div class="footer-body"></div><app-footer></app-footer>
</div>

<!-- <p-toast></p-toast> -->
