<p-menubar class="top-menu-bar">
	<ng-template pTemplate="start">
		<!-- <span class="tibo-topbar-title" (click)="routeComp()">Home</span> -->
		<div class="tibo-topbar-title2" (click)="routeComp(i)" *ngFor="let path of storePath; index as i">
			<div [ngClass]="i == 0 ? 'animate-path': 'animate-path2'">{{path}}</div>
		</div>
	</ng-template>
	<ng-template pTemplate="end">
		<div class="top-menu-bar-end">
			<!-- <button class="switchTb" type="button" pButton label="Switch to Old Timebound"
				(click)="switchFunc()" *ngIf="!showLogout"></button> -->
			<p class="tibo-topbar-username">{{userName}}</p>
			<button class="tibo-topbar-button" type="button" pButton icon="pi pi-user"
				(click)="usermenu.toggle($event)"  *ngIf="showLogout"></button>
			<p-tieredMenu #usermenu [model]="userButtonList" [popup]="true" my="left top"
				at="left bottom"></p-tieredMenu>
		</div>
	</ng-template>
</p-menubar>
<p-confirmDialog styleClass="tibo-logout-confirm-dialog" acceptLabel="Log Out" acceptIcon="" rejectLabel="Cancel"
	[position]="'top'" rejectIcon="" defaultFocus="none" rejectButtonStyleClass="p-button-outlined"></p-confirmDialog>

<!-- logout modal -->
<div class="top-bar-dialog-container">
	<p-dialog [modal]="true" [draggable]="false" [style]="{ width: '800px' }" class="delete-dialog"
		[(visible)]="showLogoutModal" header=" " [position]="'top'">
		<span class="header-text">
			<i class="pi pi-fw pi-sign-out sign-out-icon-top-bar"></i>
			Are You Sure You Want To Log Out?</span>
		<ng-template pTemplate="footer">
			<p-button class="cancel-btn" type="close" (click)="showLogoutModal = false" label="Cancel"></p-button>
			<p-button class="save-btn" type="submit" (click)="logoutFunc()" (click)="showLogoutModal = false"
				label="Log Out"></p-button>
		</ng-template>
	</p-dialog>
</div>
<p-dialog header="Profile" [draggable]="false" class="confirm-dialog" [position]="'top'" [modal]="true"
[(visible)]="showProfileDialog" [style]="{ width: '780px' }"
[ngClass]="{'label-class-width': tabHeaderName === 'Change Password'}">
<div class="card">
	<div class="details1">
		<div class="adminUser">
			<i class="fa fa-user fa-userss1" aria-hidden="true"></i>
		</div>
		<div class="data3">{{this.userName}}</div>
	</div>
	<p-tabView (onChange)="switchHeaders($event);">
		<p-tabPanel header="Account Information">
			<form [formGroup]="accountForm" class="formClass" novalidate>
				<div class="p-field">
					<label for="first-name" class="labelClass">First Name <span
							style="color:red">*</span></label>
					<input id="first-name" pInputText type="text" required formControlName="firstName">
				</div>
				<div class="p-field">
					<label for="last-name" class="labelClass">Last Name</label>
					<input id="last-name" pInputText type="text" formControlName="lastName">
				</div>
				<div class="p-field">
					<label for="email" class="labelClass">Email Address <span style="color:red">*</span></label>
					<input id="email" pInputText type="email" required formControlName="email"
						pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$">
				</div>
				<div class="p-field">
					<label for="phone" class="labelClass">Phone Number </label>
					<input id="phone" pInputText type="text" formControlName="phone"
						placeholder="Ex +XXXXXXXXXX">
				</div>
			</form>
		</p-tabPanel>
		<p-tabPanel header="Change Password" class="tab1">
			<form [formGroup]="passwordForm" class="formClass" novalidate>
				<div class="p-field" style="display: flex;">
					<label for="oldPassword" class="labelClass">Enter Old Password <span
							style="color:red">*</span></label>
					<input id="oldPassword" pInputText required [type]="isPasswordVisible ? 'text' : 'password'"
						formControlName="oldPassword">
					<i style="position: relative;padding-top: 8px;right: 24px"
						[class]="!isPasswordVisible ?hideIcon : showIcon"
						(click)="togglePasswordVisibility()"></i>
				</div>
				<div class="p-field" style="display: flex;">
					<label for="newPassword" class="labelClass">Enter New Password <span
							style="color:red">*</span></label>
					<input id="newPassword" pInputText required
						[type]="isPasswordVisible1 ? 'text' : 'password'" formControlName="newPassword">
					<i style="position: relative;padding-top: 8px;right: 24px"
						[class]="!isPasswordVisible1 ? hideIcon : showIcon"
						(click)="togglePasswordVisibility1()"></i>
				</div>
				<div class="p-field" style="display: flex;">
					<label for="reEnter" class="labelClass">Re-Enter New Password <span
							style="color:red">*</span></label>
					<input id="reEnter" pInputText required [type]="isPasswordVisible2 ? 'text' : 'password'"
						formControlName="reEnter">
					<i style="position: relative;padding-top: 8px;right: 24px"
						[class]="!isPasswordVisible2 ? hideIcon : showIcon"
						(click)="togglePasswordVisibility2()"></i>
				</div>
				<!-- <div *ngIf="showError" style="color:red">Please Enter Correct Password</div> -->
			</form>
		</p-tabPanel>
	</p-tabView>
</div>
<ng-template pTemplate="footer">
	<p-button class="updateButton" label="Update" [disabled]="accountForm.invalid" (click)="updateAccount()"
		*ngIf="tabHeaderName === 'Account Information'"></p-button>
	<p-button label="Update" class="updateButton" [disabled]="passwordForm.invalid" (click)="updatePassword()"
		*ngIf="tabHeaderName === 'Change Password'"></p-button>
</ng-template>
</p-dialog>