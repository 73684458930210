import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ConfirmationService, MessageService, SortEvent, } from 'primeng/api';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators, } from '@angular/forms';
import { ModalService } from '../modal/modal.service';
import { Message } from '@angular/compiler/src/i18n/i18n_ast';
import { HttpClient, HttpParams } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { DialogService } from 'primeng/dynamicdialog';
import { Table } from 'primeng/table';
import { BuildReportService } from './build-report.service';
import { Subject, Subscription } from 'rxjs';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { bufferTime, debounceTime, tap } from 'rxjs/operators';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import { Paginator } from 'primeng/paginator';
import { TourGuideService } from '../tour-guide.service';
declare var d3: any;
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-build-report',
  templateUrl: './build-report.component.html',
  styleUrls: ['./build-report.component.css'],
  providers: [ConfirmationService],
  animations: [
    trigger('smoothDragIn', [
      state('in', style({ opacity: 1, transform: 'translateX(0)' })),
      transition(':enter', [
        style({ opacity: 0, transform: 'translateX(-50px)' }),
        animate('300ms ease-out')
      ]),
      transition(':leave', animate('300ms ease-in', style({ opacity: 0, transform: 'translateX(-50px)' }))),
    ]),
  ],
})
export class BuildReportComponent implements OnInit {
  @Input() chartData4: any;
  @ViewChild('buildAreportTable') buildAreportTable: Table;
  @ViewChild('paginator', { static: false }) paginator: Paginator;

  selectedValue;
  requestPayload = {
    sort: [],
    filters: [],
    filterType: 'and',
    domainId: '',
    domainName: '',
    viewId: '',
    subgroupConfigColumns: [],
    qa: [],
    answerId: '',
    columns: [],
  };
  downloadPayload = {
    domainId: '',
    domainName: '',
    viewId: '',
    subgroupConfigColumns: [],
    qa: [],
    answerId: '',
    columns: [],
  }
  folderCollapse = false;
  folderPanelToggleBool: boolean = false;
  panel = false;
  save: boolean = false;
  Is: boolean = false;
  errorMsg = '';
  alertMessage: Message[];
  tableEmptyMessage: string = '';

  //Ag grid
  selectedColumn: string[] = [];
  coldata: any = [];
  coldataTemp: any = [];
  coldataSelectAll: any = [];
  public rowData: any = [];
  arr: any = [];
  data1: any = [];
  private gridApi;
  coldata1: any = [];
  value1: any = false;
  value2: any = false;
  selectedCol: boolean;
  value;
  searchText;
  globalSearch;
  showdropdown: boolean = false;
  showmenu: boolean = false;
  dropDownData2: any;
  disabledStored: boolean = false;
  isChecked: any = false;
  Hdata: any;
  Rdata: any;
  selectedDomain: any;
  listOfFilter: boolean = false;
  savedData = [];
  savedData1 = [];
  selectedcompany: any;
  selectedDRp: any;
  selectedDisability: any;
  selectedLeave: any = 'Detail';
  leaveSummaryData: any;
  coldata11: any;
  listDomains: any = [];
  showbool: boolean = false;
  dropdata: any = [];
  first: any;
  qaValue: any = [];
  showGrid: boolean = false;
  view: any = [];
  form: FormGroup;
  MockForm: FormData[];
  lab: any[];
  opti: any[];
  mainData: any = [];
  selectedName: any;
  selectedDate: any = new Date();
  formdata = {
    formname: '',
    MainLabel: '',
    inputtype: '',
    id: '',
    label: '',
    options: '',
    selectedData: '',
    conditionalAnswer: ''
  };
  sideMenuCollapse: boolean = false;
  savedFilterData: any = [];
  showSaveDialog: boolean = false;
  fileName: any;
  Id: any;
  showDeleteDialog: boolean;
  dataToBeDeleted: any;
  isLoading!: boolean;
  isLoading2!: boolean;
  //rows: any = [];
  rowsPerPage = 150;

  submit: boolean = false;
  selectedRow: any;
  HeaderData: any;
  dropval: any;
  minDate: any;
  maxDate: Date;
  showText: boolean = false;
  showDemand: boolean = false;
  selectedDemandValue: any = ''
  scheduleShow: boolean = false;
  condition: any = "";
  condData: any = [];
  runBool: boolean = true;
  showScheduleBox: boolean = false;
  qaValue1: any = [];
  showFilter: boolean = false;
  totalRecords: any = [];
  pageNum: number = 1;
  apiData: any = [];
  pageSize: any = 150;
  originalApiData: any = [];
  globalFilter: any = '';
  tableSearchUpdate = new Subject<string>();
  tableWidth: string = '99%';
  getRecordsDownloadFileName!: string;
  selectedDropdown: any = '';
  showParameter: boolean = false;
  rundataSubscription: Subscription;
  showFilterFieldsBoo: boolean = false;
  load: boolean = false;
  laodings: any = false;
  downloadButtonList: { label: string; icon: string; id: string; command: () => void; }[];
  sqlTableSortColumn = undefined;
  sqlTableSortDir?: any;
  alertSeverity: string = 'error';
  alertMsg: string = '';
  FilteredData: any[];
  tableReduceHeight = 260;
  tableSearchUpdate1 = new Subject<string>();
  startIndex: number = 0;
  endIndex: number = 0;
  currentPageIndex: number;
  columnData: any[];
  dateError: boolean=false;
  fileDesc:any='';
  reportIdSubscrip: Subscription;
  component: string='';
  savedFilterId: any;
  showFileNameError: boolean=false;
  showreport: boolean=false;
  searchMetri: any;
  filterToolTip: string= 'Show';

  constructor(
    private customerService: BuildReportService,
    private datePipe: DatePipe,
    private confirmationService: ConfirmationService,
    private modalService: ModalService,
    private activatedRoute:ActivatedRoute,
    private messageService: MessageService,
    private router: Router,
    private fb: FormBuilder,
    private http: HttpClient,
    public dialogService: DialogService,
    private tourGuideService: TourGuideService
  ) {
    this.tableSearchUpdate.pipe(debounceTime(3000)).subscribe((value) => {
      this.triggerGlobalFilter(value);
    });
    this.tableSearchUpdate1.pipe(debounceTime(3000)).subscribe((value) => {
      //console.log(this.coldata)
      this.run1(value[0], value[1], value[2]);
    });
  }

  ngOnInit(): void {
    this.FilteredData = [];
    this.coldata = [];
    this.coldataTemp = []
    this.downloadButtonList = [
      {
        label: 'All Records',
        icon: 'pi pi-file-excel',
        id: '',
        command: () => {
          this.download1(1);
        },
      }
      // ,
      // {
      //   label: 'Filtered Records',
      //   icon: 'pi pi-file-excel',
      //   id: '',
      //   command: () => {
      //     this.download1(2);
      //   },
      // },
    ];
    this.showParameter = false;
    this.minDate = new Date;
    this.maxDate = new Date;
    this.showFilter = false;
    this.selectedDomain = '';
    this.scheduleShow = false;
    this.showDemand = false;
    this.selectedDemandValue = '';
    this.load = true
    d3.select('.tibo-topbar-title').text('Home / Build A Report');
    this.customerService.getReportData().subscribe((data: any) => {
      this.listDomains = data.body.records;
      this.load = false
      this.selectedDomain = this.listDomains[0].authorizationName;
      this.tourGuideService.startTour('buildareport');
    }, (error) => {
      this.listDomains = []
      this.load = false;
    });
    this.value1 = false;
    this.value2 = false;
    this.rowData = 0;
    this.showdropdown = false;
    this.showmenu = false;
    this.selectedValue = '';
    this.first = '';
    this.selectedDate = '';
    this.savedFilterData = [];
    this.showSaveDialog = false;
    this.Id = '';
    this.fileName = '';
    this.fileDesc='';
    this.showDeleteDialog = false;
    //this.rows = [];
    this.showDemand = false;
    this.component='';
    this.savedFilterId='';
   
        if(this.chartData4!=undefined){
          this.customerService.getReportData().subscribe((data: any) => {
            this.listDomains = data.body.records;
            this.load = false
            // this.selectedDomain = this.listDomains[0].authorizationName;
           // console.log(this.chartData4);
            this.component=this.chartData4.component;
            this.savedFilterId=this.chartData4.filter;
            this.selectedDropdown=this.chartData4.reportId;
            this.searchMetri = this.chartData4['searchMetric'];
            this.listDomains.filter(item => {
              if (item.authorizationId == this.chartData4.reportId) {
                this.selectedDomain =item.authorizationId;
                let event={value:item.authorizationId}
                this.dropdownChange1(event)
                if (item.authorizationName.toLowerCase() == 'leave' || item.authorizationName.toLowerCase() == "workers' compensation" || item.authorizationName.toLowerCase() == 'disability' || item.authorizationName.toLowerCase() == 'ada') {
                  this.showDemand = true;
                  this.selectedDemandValue = "onDemand";
                  this.DemandChange(this.selectedDemandValue);
                }
                else {
                  this.showDemand = false;
                  this.DemandChange('')
                }
              }
              
            })
          }, (error) => {
            this.listDomains = []
            this.load = false;
          });
        
          // this.selectedDemandValue = "onDemand";
        }
  }
  ngOnDestroy() {
    if (this.rundataSubscription) {
      this.rundataSubscription.unsubscribe();
    }
  }
  folderPanelToggle() {
    this.sideMenuCollapse = !this.sideMenuCollapse;
    if (this.folderPanelToggleBool == false) {
      d3.selectAll('#myreport-folder-panel-scroll-div')
        .transition()
        .delay(200)
        .duration(400)
        .style('display', 'none');
      d3.select('#myreports-folderview-panel')
        .transition(500)
        .style('width', '40px');
      d3.select('#myreports-folderview-panel')
        .select('button')
        .style('transform', 'rotate(180deg)');
      d3.select('#all-reports-id')
        .transition()
        .delay(100)
        .duration(100)
        .style({ left: '-51px', top: '80px' })
        .style('transform', 'rotate(-90deg)')
        .style('position', 'relative');
      this.folderPanelToggleBool = true;
    } else {
      d3.select('#myreport-folder-panel-scroll-div')
        .transition()
        .delay(300)
        .duration(400)
        .style('display', 'block');
      d3.select('#myreports-folderview-panel')
        .transition(500)
        .style('width', '350px');
      d3.select('#myreports-folderview-panel')
        .select('button')
        .style('transform', 'rotate(0deg)');
      d3.select('#all-reports-id')
        .transition()
        .delay(250)
        .duration(100)
        .style({ left: '10px', top: '10px' })
        .style('transform', 'rotate(0deg)');
      this.folderPanelToggleBool = false;
    }
  }
  onGridReady(params) {
    this.gridApi = params.api;
  }

  dropdownChange1(event) {
    this.selectedDemandValue = '';
    this.showParameter = false;
    this.showDemand = false;
    this.showText = false;
    this.selectedDropdown = '';
    this.savedFilterData = [];
    this.selectedDropdown = event.value;
    this.requestPayload.filters=[];
    this.listDomains.filter(item => {
      if (item.authorizationId == event.value) {
        if (item.authorizationName.toLowerCase() == 'leave' || item.authorizationName.toLowerCase() == "workers' compensation" || item.authorizationName.toLowerCase() == 'disability' || item.authorizationName.toLowerCase() == 'ada') {
          this.showDemand = true;
          this.selectedDemandValue = '';
          if(localStorage.getItem('isbuildareportTourDone') == 'false'){
            localStorage.setItem('isbuildareporttypeTourDone','false')
            setTimeout(() => {
              this.tourGuideService.startTour('buildareporttype');
            }, 500);
          }
        }
        else {
          this.showDemand = false;
          this.DemandChange('')
        }
      }
    })

    this.showGrid = false;
    this.qaValue = [];

  }
  SelectBuildReportTour(){
    if(localStorage.getItem('isbuildareportTourDone') == 'false'){
      localStorage.setItem('isrunreportTourDone','false')
      setTimeout(() => {
        this.tourGuideService.startTour('runreport');
      }, 100);
    }
  }
  DemandChange(value) {
   // console.log(this.selectedDomain)
    this.selectedDemandValue = value;
    this.columnData = [];
    this.isLoading = true;
    let name;
    this.listDomains.filter(item => {
      if (item.authorizationId == this.selectedDropdown){
        name=item.authorizationName;
      }})
    this.customerService.getApiData(name).subscribe(
      (data: any) => {
        this.showbool = false;
        // if (data.body?.qa == null || data.body?.qa?.length == 0) { this.showDemand = false; } else { this.showDemand = true; }
        this.listDomains.filter(e => {
          if (e.authorizationId == this.selectedDomain) {
            if (e.authorizationName.toLowerCase() == "disability") {
              this.requestPayload.domainName = "DIS";
              // this.SelectBuildReportTour();
            } else if (e.authorizationName.toLowerCase() == "leave") {
              this.requestPayload.domainName = "LOA";
              // this.SelectBuildReportTour();
            } else if (e.authorizationName.toLowerCase() == "workers compensation") {
              this.requestPayload.domainName = "WC";
              // this.SelectBuildReportTour();
            } else if (e.authorizationName.toLowerCase() == "ada") {
              this.requestPayload.domainName = "ADA";
              // this.SelectBuildReportTour();
            }
          }
        });
        this.showText = false;
        this.Id = this.selectedDropdown;
        this.mainData = [];
        this.requestPayload.domainId = this.selectedDropdown;
        this.requestPayload.subgroupConfigColumns = [];
        this.view = data.body.views;

        if (this.view.length == 0) {
          this.requestPayload.viewId = '';
        }
        else {
          this.view.forEach(e => {
            e['label'] = e['label'].replace(/[^a-z0-9\s]/gi, ' ')
            e['label'] = e['label'].charAt(0).toUpperCase() + e['label'].slice(1);
          })
          this.selectedRow = this.view[0].label
          this.view.forEach(e => {
            if (e.label == this.selectedRow) {
              this.requestPayload.viewId = e.id;
            }
          });
          this.customerService.headerData(this.view[0].dataSourceId, { query: "", table_name: this.view[0].viewName }).subscribe(
            (response: any) => {
              if (response.body.headers == null || response.body.headers.length == 0) {
                this.HeaderData = []
              }
              else {
                this.HeaderData = response.body.headers;
                let arr = [];
                this.HeaderData.forEach(h => {
                  arr.push(h.header)
                })
                this.requestPayload.columns = arr;
                this.columnData = arr;
              }
            },
            (error) => {
              this.alertSeverity = 'error';
              // this.tableEmptyMessage = 'No Records Found';
              if (Number(error.status) == 401) {
                this.alertMsg = 'Please Try After Some Time!';
              } else if (Number(error.status) == 500) {
                this.alertMsg = 'Internal Error';
              } else {
                this.alertMsg = 'Please Try After Some Time.';
              }
              this.addSingle();
            },
            () => { }
          );
          this.customerService.getSavedFilter(this.selectedDropdown).subscribe(
            (response: any) => {
              if (response?.body?.records != null && response?.body?.records.length > 0) {
                this.savedFilterData = response?.body?.records;
                this.showFilter = true;
                if(this.chartData4 != undefined){
                  this.savedFilterData.filter((item,i)=>{
                    if(item.adhocSaveFilterId==this.savedFilterId){
                      this.FillFilterToForm(i)
                    }})
                }
              }
            },
            (error) => {
              this.alertSeverity = 'error';
              // this.tableEmptyMessage = 'No Records Found';
              if (Number(error.status) == 401) {
                this.alertMsg = 'Please Try After Some Time!';
              } else if (Number(error.status) == 500) {
                this.alertMsg = 'Internal Error';
              } else {
                this.alertMsg = 'Please Try After Some Time.';
              }
              this.addSingle();
            },
            () => { }
          );
          // if (this.selectedDemandValue === 'onDemand') {
          //   this.showFilter = true;
          // }
          // else{
          // this.showFilter = false;}
        }
        this.dropdata = data.body.qa;
        if (this.dropdata == null || this.dropdata.length == 0) {
          this.showParameter = false;
          this.showbool = false;
          this.requestPayload.qa = [];
          this.requestPayload.answerId = '';
          this.showText = true;
        } else {
          this.showParameter = true;
          this.showText = false;
          let j = 0;
          const formGroup = {};
          this.dropdata.forEach((element, i) => {

            //this.mainData[i]=[]
            let option: any = [];
            this.formdata = {
              formname: '',
              MainLabel: '',
              inputtype: '',
              id: '',
              label: '',
              options: '',
              selectedData: '',
              conditionalAnswer: ''
            };
            for (let k = 0; k < element.a.length; k++) {
              // if()
              this.formdata = {
                formname: '',
                MainLabel: '',
                inputtype: '',
                id: '',
                label: '',
                options: '',
                selectedData: '',
                conditionalAnswer: ''
              };
              if (element.a[k].inputType === 'select') {
                this.formdata.formname =
                  element.qlabel.replace(' ', '') + 'Name';
                this.formdata.MainLabel = element.qlabel;
                this.formdata.inputtype = element.a[k].inputType;
                this.formdata.id = element.a[k].aid;
                this.formdata.label = element.a[k].label;
                this.formdata.options = element.a[k].value;
                this.formdata.selectedData = '';
                this.formdata.conditionalAnswer = element.a[k].conditionalAnswerId
                let val = element.qlabel.replace(' ', '') + 'Name';
                formGroup[val] = new FormControl('', Validators.required);
                this.mainData[j] = this.formdata;
                j++;
                this.requestPayload.answerId = element.a[k].aid;
              }
              if (element.a[k].inputType === 'radio') {
                option.push({
                  value: element.a[k].label,
                  id: element.a[k].aid,
                });

                if (k === element.a.length - 1) {
                  this.formdata.formname =
                    element.qlabel.replace(' ', '') + 'Name';
                  this.formdata.MainLabel = element.qlabel;
                  this.formdata.inputtype = element.a[k].inputType;
                  this.formdata.id = '';
                  this.formdata.label = '';
                  this.formdata.options = option;
                  this.formdata.selectedData = option[0].val;
                  this.formdata.conditionalAnswer = element.a[k].conditionalAnswerId;
                  let val = element.qlabel.replace(' ', '') + 'Name';
                  formGroup[val] = new FormControl('', Validators.required);
                  this.mainData[j] = this.formdata;
                  j++;
                }
              }
              if (element.a[k].inputType === 'text') {
                this.formdata.formname =
                  element.a[k].label.replace(' ', '') + 'Name';
                this.formdata.MainLabel = element.qlabel;
                this.formdata.inputtype = element.a[k].inputType;
                this.formdata.id = element.a[k].aid;
                this.formdata.label = element.a[k].label;
                this.formdata.options = element.a[k].value;
                this.formdata.selectedData = '';
                this.formdata.conditionalAnswer = element.a[k].conditionalAnswerId
                let val = element.a[k].label.replace(' ', '') + 'Name';
                formGroup[val] = new FormControl('', Validators.required);
                this.mainData[j] = this.formdata;
                j++;
                this.requestPayload.answerId = element.a[k].aid;
              }
              if (element.a[k].inputType === 'date') {
                this.formdata.formname =
                  element.a[k].label.replace(' ', '') + 'Name';
                this.formdata.MainLabel = element.a[k].label;
                this.formdata.inputtype = element.a[k].inputType;
                this.formdata.id = element.a[k].aid;
                this.formdata.label = element.a[k].label;
                this.formdata.options = element.a[k].value;
                this.formdata.selectedData = '';
                this.formdata.conditionalAnswer = element.a[k].conditionalAnswerId
                let val = element.a[k].label.replace(' ', '') + 'Name';
                formGroup[val] = new FormControl('', Validators.required);
                this.mainData[j] = this.formdata;
                j++;
                this.requestPayload.answerId = element.a[k].aid;
              }
            }
            this.form = new FormGroup(formGroup);
          });
          this.mainData.forEach(e => {
            if (e.conditionalAnswer === "" || e.conditionalAnswer === null) {
              e.showElement = true; // Set the showElement property to true if the condition matches
            } else {
              e.showElement = false; // Set the showElement property to false if the condition doesn't match
            }
          });
          this.mainData.forEach((e,i) => {
            if ((e.label.toLowerCase()=="clients" ||e.label.toLowerCase()=="client") && e.conditionalAnswer == "" && e.options.length==1 && (e.options[0].value!='' || e.options[0].value!="" || e.options[0].value!=null ||e.options[0].value!='empty')) {
              e.selectedData = e.options[0].value;
              this.onSelected(e.id,e.selectedData,i,e.inputtype)
            }
          });
          this.showbool = true;
        }
        this.isLoading = false;
       // console.log(this.mainData)
        if(this.mainData.length > 0){
          this.SelectBuildReportTour();
        }
        else{
          localStorage.setItem('isbuildareportTourDone','true')
        }
        this.mainData.forEach((e, i) => {
          if(e.selectedData!=''){
            this.qaValue[i] ={
              aid: e.id,
              value: e.selectedData,
            };
          }
          else{
            this.qaValue[i] = {}
          }
        })
        //console.log(this.qaValue)
      },
      (error) => {
        this.isLoading = false;
        if (Number(error.status) == 401) alert('Please Try After Some Time!');
        else if (Number(error.status) == 500) {
          alert('Internal Error ');
        } else {
          alert('Please Try After Some Time.');
        }
      },
      () => { }
    );
    // if (this.selectedDemandValue === 'onDemand') {
    //   this.showFilter = true;
    // }
    // else {
    //   this.showFilter = false;
    // }
  }
  addSingle() {
    this.messageService.add({
      severity: this.alertSeverity ,
      summary: '',
      detail:  this.alertMsg ,
    });
  }
  onManualInputChange(event, data, ind, type) {
   // console.log(event, data, ind, type);
    if(data!='' && data!=null){
    this.onSelected(event, data, ind, type)
    }
    else{
      this.runBool = true;
      this.qaValue[ind] ={};
    }
  }
  onSelected(event, data, ind, type) {
    this.dateError=false;
  //  console.log(event, data, ind, type)
    this.showGrid = false;
    if (data == '') {
      this.runBool = true;
    }
    if(data!='' && data!=null){
    if (this.mainData[ind].label == "Clients") {
      this.customerService.getClientData(this.mainData[ind].selectedData).subscribe((res: any) => {
        this.requestPayload.subgroupConfigColumns = res.body;
      })
    }
    this.runBool = false;
    for (let i = ind + 1; i < this.mainData.length; i++) {
      if (this.mainData[i].conditionalAnswer?.split(',').map(Number).includes(event) || this.mainData[i].conditionalAnswer == "" || this.mainData[i].conditionalAnswer == null) {
        this.runBool = true;
        this.mainData[i].showElement = true;
        this.mainData[i].selectedData = '';
      }
      else {

        if (type == 'date') {
          if (this.mainData[i].inputType == 'date') {
            this.mainData[i].showElement = true;
          }
        }
        else {
          this.mainData[i].showElement = false;
          this.qaValue.splice((ind + 1), 1);
          this.mainData[i].selectedData = '';
        }
      }
     
    }
    if (type === 'date') {
      this.qaValue[ind] = {
        aid: event,
        value:
          ('0' + (data.getMonth() + 1)).slice(-2) +
          '/' +
          ('0' + data.getDate()).slice(-2) +
          '/' +
          data.getFullYear(),
      };
    } else if (type === 'text') {
      this.qaValue[ind] = {
        aid: event,
        value: this.form.get(data).value,
      };
    } else if (type === 'radio') {
      this.qaValue[ind] = {
        aid: event,
        value: event,
      };
    } else {
      this.qaValue[ind] = {
        aid: event,
        value: data,
      };
    }
    this.selectedValue = '';
    this.first = '';
    this.selectedName = '';
    this.selectedDate = '';
    if (type == 'select') {
      this.requestPayload.answerId = event;
    }
  }
  else{
    this.runBool = true;
      this.qaValue[ind] ={};
  }

  }
  run() {
   // console.log(this.qaValue,this.mainData)
    this.dateError=false;
    this.globalFilter = '';
    this.totalRecords = 0;
    this.pageNum = 1;
    this.qaValue1 = []
    this.minDate = new Date;
    this.maxDate = new Date;
    const filteredData = this.mainData.filter(
      (item) => item.showElement === true && item.selectedData === ""
    );
    this.qaValue1 = this.qaValue.filter(item => item !== null && Object.keys(item).length !== 0);
    if (this.runBool == true || this.qaValue.some(obj => Object.keys(obj).length === 0) || filteredData.length>0) {
      alert("Please Select Mandatory Field")
    } else {
      this.isLoading2 = true;
      this.rowData = [];
      this.coldata = [];
      this.apiData = [];
      this.coldataTemp = [];
      this.coldataSelectAll = [];
      this.originalApiData = [];
      this.qaValue1 = this.qaValue.filter(item => item !== null && Object.keys(item).length !== 0);
      this.mainData.forEach(e => {
        this.qaValue1.forEach(val => {
          if (e.label.toLowerCase().replace(/[_ ]/g, '') == 'startdate' || e.label.toLowerCase().replace(/[_ ]/g, '') == 'fromdate') {
            if (e.id == val.aid) {
              this.minDate = val.value
            }
          }
          else if (e.label.toLowerCase().replace(/[_ ]/g, '') == "enddate" || e.label.toLowerCase().replace(/[_ ]/g, '') == "thrudate") {
            if (e.id == val.aid) {
              this.maxDate = val.value
            }
          }
        })
      })
      let min = new Date(this.maxDate);
      let max = new Date(this.minDate);
      if (min.getTime() < max.getTime()) {
        // alert("end date should be greater than or equal to start date");
        this.dateError=true;
        this.isLoading2 = false;
      }
      else {
        this.sideMenuCollapse = true;
        this.pageNum = 1;
        this.requestPayload.qa = this.qaValue1;
        let name=this.listDomains.filter(item=>item.authorizationId==this.selectedDropdown)
        this.rundataSubscription = this.customerService
          .getRunData(name[0].authorizationName, this.pageNum, this.pageSize)
          .subscribe((data: any) => {
            if(localStorage.getItem('isbuildareportTourDone') == 'false'){
              localStorage.setItem('isreporttableTourDone','false')
              setTimeout(() => {
                this.tourGuideService.startTour('reporttable');
              }, 1000);
              }
            if (data.body.records == null || data.body.records.length == 0) {

              data.body.headers?.forEach((head, i) => {
                // Normalize the dataType to lowercase once to avoid redundant calls
                const dataType = head.dataType.toLowerCase();
                let type;

                switch (dataType) {
                  //"varchar","varchar2", "text", "longtext", "mediumtext", "bpchar", "char","character","character varying"
                  //"integer","int4","int8", "number", "serial", "numeric","int"
                  case 'string':
                    type = 'text';
                    break;
                  case 'varchar':
                    type = 'text';
                    break;
                  case 'varchar2':
                    type = 'text';
                    break;
                  case 'longtext':
                    type = 'text';
                    break;
                  case 'mediumtext':
                    type = 'text';
                    break;
                  case 'bpchar':
                    type = 'text';
                    break;
                  case 'character':
                    type = 'text';
                    break;
                  case 'bpchar':
                    type = 'text';
                    break;
                  case 'character varying':
                    type = 'text';
                    break;
                  //"timestamp", "date", "datetime", "timestamp without time zone","timestamptz","timestamp"
                  case 'datetime':
                    type = 'date';
                    break;
                  case 'timestamp':
                    type = 'date';
                    break;
                  case 'timestamp without time zone':
                    type = 'date';
                    break;
                  case 'timestamptz':
                    type = 'date';
                    break;
                  case 'timestamp':
                    type = 'date';
                    break;
                  //"integer","int4","int8", "number", "serial", "numeric","int"
                  case 'int':
                    type = 'number';
                    break;
                  case 'int4':
                    type = 'number';
                    break;
                  case 'int8':
                    type = 'number';
                    break;
                  case 'serial':
                    type = 'number';
                    break;
                  case 'numeric':
                    type = 'number';
                    break;
                  default:
                    type = dataType; // Fallback to the original dataType if not one of the specific cases
                    break;
                }

                let mappedColumn = {
                  headerName: head.header?.replace(/_/g, ' ').replace(/\b\w/g, (s) => s.toUpperCase()),
                  field: head.header,
                  checked: true,
                  type: type,
                  id: i,
                  selectedDataStart: '',
                  selectedDataLike: '',
                  selectedDataEnd: ''
                };

                this.coldata.push(mappedColumn);
                this.coldataTemp.push(mappedColumn.field);
                this.coldataSelectAll.push(mappedColumn);
              });
              this.rowData = [];
              data.body?.records?.length > 0
                ? (this.tableEmptyMessage = '')
                : (this.tableEmptyMessage = 'No Records Found');
              this.isLoading2 = false;
              this.showGrid = true;
              this.startIndex = 0;
              this.endIndex = 0;
            }
            else {
              this.originalApiData = data?.body?.totalRecords;
              //this.rows = data.body.records;
              this.totalRecords = data?.body?.totalRecords;
              data.body.headers?.forEach((head, i) => {
                const dataType = head.dataType.toLowerCase();
                let type;

                switch (dataType) {
                  //"varchar","varchar2", "text", "longtext", "mediumtext", "bpchar", "char","character","character varying"
                  //"integer","int4","int8", "number", "serial", "numeric","int"
                  case 'string':
                    type = 'text';
                    break;
                  case 'varchar':
                    type = 'text';
                    break;
                  case 'varchar2':
                    type = 'text';
                    break;
                  case 'longtext':
                    type = 'text';
                    break;
                  case 'mediumtext':
                    type = 'text';
                    break;
                  case 'bpchar':
                    type = 'text';
                    break;
                  case 'character':
                    type = 'text';
                    break;
                  case 'bpchar':
                    type = 'text';
                    break;
                  case 'character varying':
                    type = 'text';
                    break;
                  //"timestamp", "date", "datetime", "timestamp without time zone","timestamptz","timestamp"
                  case 'datetime':
                    type = 'date';
                    break;
                  case 'timestamp':
                    type = 'date';
                    break;
                  case 'timestamp without time zone':
                    type = 'date';
                    break;
                  case 'timestamptz':
                    type = 'date';
                    break;
                  case 'timestamp':
                    type = 'date';
                    break;
                  //"integer","int4","int8", "number", "serial", "numeric","int"
                  case 'int':
                    type = 'number';
                    break;
                  case 'int4':
                    type = 'number';
                    break;
                  case 'int8':
                    type = 'number';
                    break;
                  case 'serial':
                    type = 'number';
                    break;
                  case 'numeric':
                    type = 'number';
                    break;
                  default:
                    type = dataType; // Fallback to the original dataType if not one of the specific cases
                    break;
                }
                let mappedColumn = {
                  headerName: head.header?.replace(/_/g, ' ')
                    .replace(/\b\w/g, (s) => s.toUpperCase()),
                  field: head.header,
                  checked: true,
                  type: type,
                  id: i,
                  selectedDataStart: '',
                  selectedDataLike: '',
                  selectedDataEnd: '',
                }
                this.coldata.push(mappedColumn);
                this.coldataTemp.push(mappedColumn.field);
                this.coldataSelectAll.push(mappedColumn);
              });
              this.rowData = data.body.records;
              this.isLoading2 = false;
              this.startIndex = 1;
              this.endIndex = this.rowData.length;
              this.showGrid = true;
            }
            this.isLoading2 = false;

          },
            (error) => {
              this.isLoading2 = false;
              this.startIndex = 0;
              this.endIndex = 0;
              this.alertSeverity = 'error';
              // this.tableEmptyMessage = 'No Records Found';
              if (Number(error.status) == 401) {
                this.alertMsg = 'Please Try After Some Time!';
              } else if (Number(error.status) == 500) {
                this.alertMsg = 'Internal Error';
              } else {
                this.alertMsg = 'Please Try After Some Time.';
              }
              this.addSingle();
            }
          );
      }

    }
  }
  public getDate(date: Date): string {
    return date?.toLocaleString('en-US', { timeZone: 'America/Los_Angeles' });
  }
  run1(event, fields, condition) {
  //  console.log("hit")
    this.isLoading2 = true;
    this.pageNum = 1;
    this.currentPageIndex = 0;
    this.paginator.first = 0;
  //  console.log(event)

    // setTimeout(() => {
    //   if (event == null || event == '') {
    //     this.requestPayload.filters=this.requestPayload.filters.filter(item => item.operator !== condition);
    //   }
    //   else {
    //     let val: any;
    //     if (condition == 'dtlt' || condition == 'dtgt') {
    //       fields.selectedDataEnd = '';
    //       this.requestPayload.filters = this.requestPayload.filters.filter(item => item.operator !== 'dton');
    //       const z = new Date(this.getDate(event)).getTime();
    //       val = z + 1000 * 60 * 60 * 24;
    //     }
    //     else if (condition == 'dton') {
    //       fields.selectedDataStart = '';
    //       fields.selectedDataLike = '';
    //       this.requestPayload.filters = this.requestPayload.filters.filter(item => item.operator !== 'dtlt');
    //       this.requestPayload.filters = this.requestPayload.filters.filter(item => item.operator !== 'dtgt')
    //       const z = new Date(this.getDate(event)).getTime();
    //       val = z + 1000 * 60 * 60 * 24;
    //     }
    //     else {
    //       val = event;
    //     }
    //     let obj = {};
    //     obj = {
    //       property: fields.field,
    //       operator: condition,
    //       value: val,
    //     };
    //     if (this.requestPayload.filters.length > 0) {
    //       let found = false;
    //       this.requestPayload.filters.forEach((e, i) => {
    //         if (e.property === fields.field && e.operator === condition) {
    //           e.value = val;
    //           found = true;
    //         }
    //       });
    //       if (!found) {
    //         this.requestPayload.filters.push(obj);
    //       }
    //     } else {
    //       this.requestPayload.filters.push(obj);
    //     }
    //   }
    //   this.requestPayload.filters = this.requestPayload.filters
    //     .map((item, index, self) => {
    //       const existingItem = self.find(
    //         (t) => t.property === item.property && t.operator === item.operator);
    //       if (existingItem) {
    //         return {
    //           ...item,
    //           value: item.value !== "" ? item.value : existingItem.value,
    //         };
    //       }
    //       return item;
    //     });
    //   this.requestPayload.filters = this.requestPayload.filters.filter((item) => item.value !== "");
    //   this.FilteredData = this.requestPayload.filters;


    //   this.rundataSubscription = this.customerService.getRunData(this.requestPayload, this.pageNum, this.pageSize).subscribe((res: any) => {
    //     if (res.body?.records?.length > 0) {
    //       this.rowData = res.body.records;
    //       this.totalRecords = res?.body?.totalRecords;
    //       this.isLoading2 = false
    //       this.startIndex = 1;
    //       this.endIndex = this.rowData.length;
    //     }
    //     else {
    //       this.rowData = [];
    //       this.totalRecords = 0;
    //       this.tableEmptyMessage = 'No Records Found';
    //       this.isLoading2 = false;
    //       this.startIndex = 0;
    //       this.endIndex = 0;
    //     }
    //   });
    // }, 300);
   // console.log(fields);
    this.requestPayload.filters = [];
    fields.forEach((column: any) => {
      if (column.selectedDataStart || column.selectedDataLike || column.selectedDataEnd) {
        if (column.type == 'text') {
          if (column.selectedDataStart) {
            let obj = {
              property: column.field,
              operator: 'startw',
              value: column.selectedDataStart.trim(),
            };
            this.requestPayload.filters.push(obj);
          }
          if (column.selectedDataLike) {
            let obj = {
              property: column.field,
              operator: 'like',
              value: column.selectedDataLike.trim(),
            };
            this.requestPayload.filters.push(obj);
          }
          if (column.selectedDataEnd) {
            let obj = {
              property: column.field,
              operator: 'endw',
              value: column.selectedDataEnd.trim(),
            };
            this.requestPayload.filters.push(obj);
          }
        }
        if (column.type == 'date') {
          let val: any;
          if (column.selectedDataStart) {
            const z = new Date(this.getDate(column.selectedDataStart)).getTime();
            val = z + 1000 * 60 * 60 * 24;
           // console.log(val)
            column.selectedDataEnd = ""
            let obj = {
              property: column.field,
              operator: 'dtlt',
              value:this.datePipe.transform(column.selectedDataStart.setHours(0, 0, 0, 0), 'MM/dd/yyyy h:mm a') //val,
            };
            this.requestPayload.filters.push(obj);
          }
          if (column.selectedDataLike) {
            const z = new Date(this.getDate(column.selectedDataLike)).getTime();
            val = z + 1000 * 60 * 60 * 24;
           // console.log(val)
            column.selectedDataEnd = "";
            let obj = {
              property: column.field,
              operator: 'dtgt',
              value: this.datePipe.transform(column.selectedDataLike.setHours(0, 0, 0, 0), 'MM/dd/yyyy h:mm a')//val,
            };
            this.requestPayload.filters.push(obj);
          }
          if (column.selectedDataEnd) {
            const z = new Date(this.getDate(column.selectedDataEnd)).getTime();
            val = z + 1000 * 60 * 60 * 24;
          //  console.log(val)
            column.selectedDataStart = "";
            column.selectedDataLike = "";
            let obj = {
              property: column.field,
              operator: 'dton',
              value: this.datePipe.transform(column.selectedDataEnd.setHours(0, 0, 0, 0), 'MM/dd/yyyy h:mm a')//val,
            };
            this.requestPayload.filters.push(obj);
          }
        }
        if (column.type == 'number') {
          if (column.selectedDataStart) {
            let obj = {
              property: column.field,
              operator: 'lt',
              value: column.selectedDataStart.trim(),
            };
            this.requestPayload.filters.push(obj);
          }
          if (column.selectedDataLike) {
            let obj = {
              property: column.field,
              operator: 'gt',
              value: column.selectedDataLike.trim(),
            };
            this.requestPayload.filters.push(obj);
          }
          if (column.selectedDataEnd) {
            let obj = {
              property: column.field,
              operator: 'eq',
              value: column.selectedDataEnd.trim(),
            };
            this.requestPayload.filters.push(obj);
          }
        }
      }
    })
    this.FilteredData = this.requestPayload.filters;
    let name=this.listDomains.filter(item=>item.authorizationId==this.selectedDropdown)
    this.rundataSubscription = this.customerService
      .getRunData(name[0].authorizationName, this.pageNum, this.pageSize).subscribe((res: any) => {
      if (res.body?.records?.length > 0) {
        this.rowData = res.body.records;
        this.totalRecords = res?.body?.totalRecords;
        this.isLoading2 = false
        this.startIndex = 1;
        this.endIndex = this.rowData.length;
      }
      else {
        this.rowData = [];
        this.totalRecords = 0;
        this.tableEmptyMessage = 'No Records Found';
        this.isLoading2 = false;
        this.startIndex = 0;
        this.endIndex = 0;
      }
    }
  ,(error)=>{
    this.alertSeverity = 'error';
              // this.tableEmptyMessage = 'No Records Found';
              if (Number(error.status) == 401) {
                this.alertMsg = 'Please Try After Some Time!';
              } else if (Number(error.status) == 500) {
                this.alertMsg = 'Internal Error';
              } else {
                this.alertMsg = 'Please Try After Some Time.';
              }
              this.addSingle();
  });

  }
  customSort(event: SortEvent) {
    this.isLoading2 = true
    this.requestPayload.sort = [];
    if (event.order == 1) {
      this.requestPayload.sort.push({
        property: event.field,
        dir: 'ASC',
      });
    } else if (event.order == -1) {
      this.requestPayload.sort.push({
        property: event.field,
        dir: 'DESC',
      });
    }
    this.sqlTableSortColumn = event.field;
    this.sqlTableSortDir = event.order;
    let name=this.listDomains.filter(item=>item.authorizationId==this.selectedDropdown)
    this.rundataSubscription = this.customerService
      .getRunData(name[0].authorizationName, this.pageNum, this.pageSize)
      .subscribe(
        (response: any) => {
          this.isLoading2 = false
          let sortedData = [];
          response.body?.records
            ? (sortedData = response.body.records)
            : (sortedData = []);
          event.data.splice(0, event.data.length);
          sortedData.forEach((d) => {
            event.data.push(d);
          });
        },
        (error) => {
          this.isLoading2 = false;
          this.alertSeverity = 'error';
          if (Number(error.status) == 401) {
            this.alertMsg = 'Please Try After Some Time!';
          } else if (Number(error.status) == 500) {
            this.alertMsg = 'Internal Error';
          } else {
            this.alertMsg = 'Please Try After Some Time.';
          }
          this.addSingle();
        },
        () => { }
      );
  }
  paginate(event) {
    this.isLoading2 = true;
    // this.showGrid=false;
    //this.rowData=[];
    this.sideMenuCollapse = true;
    this.pageNum = event.page + 1;
    this.rowsPerPage = event.rows;
    let name=this.listDomains.filter(item=>item.authorizationId==this.selectedDropdown)
    this.rundataSubscription = this.customerService
      .getRunData(name[0].authorizationName, this.pageNum, this.pageSize)
      .subscribe((data: any) => {
        this.isLoading2 = false;
        if (data.body.records != null || data.body.records.length != 0) {
          this.originalApiData = data?.body?.totalRecords;
          // this.rows = data.body.records;
          this.rowData = data.body.records;
          this.totalRecords = data?.body?.totalRecords;
          this.startIndex = this.rowData.length > 0 ? (150 * (this.pageNum - 1)) + 1 : 0;
          this.endIndex = this.rowData.length > 0 ? (this.startIndex - 1) + this.rowData.length : 0;
          // this.showGrid=true;
        }

      },
        (error) => {
          this.isLoading2 = false;
          this.startIndex = 0;
          this.endIndex = 0;
        });
  }
  ChangeVal(event) {
    this.showGrid = false;
    this.HeaderData = [];
    this.requestPayload.columns = [];
    this.requestPayload.filters = [];
    this.selectedRow = event.value;
    this.requestPayload.qa = this.qaValue;
    this.view.forEach(e => {
      if (e.label == this.selectedRow) {
        this.requestPayload.viewId = e.id;
        this.customerService.headerData(e.dataSourceId, { query: "", table_name: e.viewName }).subscribe(
          (response: any) => {
            if (response.body.headers == null || response.body.headers.length == 0) {
              this.HeaderData = []
            }
            else {
              this.HeaderData = response.body.headers;
              let arr = [];
              this.HeaderData.forEach(h => {
                arr.push(h.header)
              })
              this.requestPayload.columns = arr;
            }
          },
          (error) => {
            this.alertSeverity = 'error';
          if (Number(error.status) == 401) {
            this.alertMsg = 'Please Try After Some Time!';
          } else if (Number(error.status) == 500) {
            this.alertMsg = 'Internal Error';
          } else {
            this.alertMsg = 'Please Try After Some Time.';
          }
          this.addSingle();
          },
          () => { }
        );
      }
    });
    this.run();
  }
  showFilterFields() {
    this.showFilterFieldsBoo = !this.showFilterFieldsBoo;
    if (!this.coldata) {
      if (this.showFilterFieldsBoo) {
        this.tableReduceHeight = 449;
        this.filterToolTip = 'Hide';
      } else {
        this.tableReduceHeight = 322;
        this.filterToolTip = 'Show';
      }
    } else {
      if (this.showFilterFieldsBoo) {
        this.tableReduceHeight = 380;
        this.filterToolTip = 'Hide';
      } else {
        this.tableReduceHeight = 260;
        this.filterToolTip = 'Show';
      }
    }
  }
  resetForm() {
    this.form.reset();
    this.qaValue = [];
    this.showGrid = false;
    this.rowData = [];
    this.runBool = true;
    this.dateError=false;
    this.mainData.forEach(e => {
      if (e.conditionalAnswer === "" || e.conditionalAnswer === null) {
        e.showElement = true; // Set the showElement property to true if the condition matches
      } else {
        e.showElement = false; // Set the showElement property to false if the condition doesn't match
      }
    });
  }
  saveDialog() {
    this.minDate = new Date;
    this.maxDate = new Date;
    if (this.runBool == true || this.qaValue.some(obj => Object.keys(obj).length === 0)) {
      alert("Please Select Mandatory Field");
      this.showSaveDialog = false;
      this.fileName = '';
      this.fileDesc='';
      this.showFileNameError=false;
    }
    else{
      this.qaValue1 = this.qaValue.filter(item => item !== null && Object.keys(item).length !== 0);
      this.mainData.forEach(e => {
        this.qaValue1.forEach(val => {
          if (e.label.toLowerCase().replace(/[_ ]/g, '') == 'startdate' || e.label.toLowerCase().replace(/[_ ]/g, '') == 'fromdate') {
            if (e.id == val.aid) {
              this.minDate = val.value
            }
          }
          else if (e.label.toLowerCase().replace(/[_ ]/g, '') == "enddate" || e.label.toLowerCase().replace(/[_ ]/g, '') == "thrudate") {
            if (e.id == val.aid) {
              this.maxDate = val.value
            }
          }
        })
      })
      let min = new Date(this.maxDate);
      let max = new Date(this.minDate);
      if (min.getTime() < max.getTime()) {
        // alert("end date should be greater than or equal to start date");
        this.dateError=true;
        this.isLoading2 = false;
      }
      else
        {   this.dateError=false;
            this.showSaveDialog = true;
            this.fileName = '';
            this.fileDesc='';
            this.showFileNameError=false;
          }
    }
  }
  binSelect(event,label) {
   // console.log(event)
    if(label=='table'){
      this.coldataSelectAll.forEach((d) => {
        if (d.field === event.field) {
          d.checked = false;
        }
      });
      this.coldata = this.coldataSelectAll.filter(column => column.checked === true);
    }
    if(label=='option'){
    if (event?.value?.length === this.coldataSelectAll.length) {
      this.coldataSelectAll.forEach((d) => (d.checked = true));
      this.coldata = this.coldataSelectAll;
    }
    if (event?.value?.length == 0) {
      this.coldataSelectAll.forEach((d) => (d.checked = false));
      this.coldata = this.coldataSelectAll;
    }
    else {
      if (event?.value?.includes(event?.itemValue)) {
        this.coldataSelectAll.forEach((d) => {
          if (d.field === event?.itemValue) {
            d.checked = true;
          }
        });
        this.coldata = this.coldataSelectAll.filter(column => column.checked === true);
      } else {
        this.coldataSelectAll.forEach((d) => {
          if (d.field === event?.itemValue) {
            d.checked = false;
          }
        });
        this.coldata = this.coldataSelectAll.filter(column => column.checked === true);
      }
    }
    }
    this.coldataTemp = this.coldataSelectAll.filter(col => col.checked).map(col => col.field);
  }
  binSelect1(event){
   // console.log(event)
   this.coldataSelectAll.forEach((d) => {
          if (d.field === event.field) {
            d.checked = false;
          }
        });
        this.coldata = this.coldataSelectAll.filter(column => column.checked === true);
  }
  trackByFn(index, item) {
    return item.field; // or any unique identifier
  }
  handleColReorder(event) {
    this.coldata = event.columns.filter(column => column.checked === true);
  }
  Refresh(data) {
    this.coldataTemp = [];
    this.globalFilter = '';
    data.forEach((d) => {
      this.coldataTemp.push(d.field);
      d.checked = true;
    });
    this.requestPayload.columns = this.columnData
    this.coldataSelectAll?.forEach(e => {
      e.checked = true
      // e.selectedDataStart='';
      // e.selectedDataLike='';
      // e.selectedDataEnd=''
    })
    this.buildAreportTable.clear();
    // let search= document.getElementById('searchInput') as HTMLInputElement;
    // search.value = '';
    // this.paginator.first = 0;
    this.triggerGlobalFilter('');
    this.clearTableFilter();
   
  }
  openModal(id: string) {
    this.modalService.open(id);
  }
  closeModal(id: string) {
    this.modalService.close(id);
  }
  saveFilter() {
    
    this.qaValue1 = [];
    let queryParams = new HttpParams();
    var fileNameExists = false;
    let file = this.fileName.trim().replace(/\s+/g, '+');
    if (this.savedFilterData.length > 0) {
      this.savedFilterData.forEach((d) => {
        if (d.adhocSaveFilterName == file) {
          fileNameExists = true;
        }
      });
    }
    if (this.fileName == '') {
      this.showFileNameError=true;
      // alert('please enter filename');
      // this.alertSeverity = 'error';
      // this.alertMsg = 'Please Enter Filename';
      // this.addSingle();
    } else if (fileNameExists) {
      this.alertSeverity = 'error';
      this.alertMsg = 'File Name Already Exists,Please Enter New Name';
      this.addSingle();
      // alert('file name already exists,please enter new name');
    } else {
      this.showSaveDialog = false;
      this.showFileNameError=false;
      queryParams = queryParams.append('name', file); //aid: 59, value: 'Account'
      let obj = [];
      this.qaValue1 = this.qaValue.filter(item => item !== null && Object.keys(item).length !== 0);
      this.qaValue1.forEach((e) => {
        obj.push({
          aid: 'q_' + e.aid,
          value: e.value,
        });
      });
      let obj1 = {};
      obj1['qa'] = obj;
      obj1['filterDesc']=this.fileDesc?this.fileDesc:'';
      this.customerService.setfilter(this.Id, queryParams, obj1).subscribe(
        (response: any) => {
          this.alertSeverity = 'success';
          this.alertMsg = 'Parameter Saved';
          this.addSingle();
          this.getList();
        },
        (error) => {
          this.alertSeverity = 'error';
          if (Number(error.status) == 401) {
            this.alertMsg = 'Please Try After Some Time!';
          } else if (Number(error.status) == 500) {
            this.alertMsg = 'Internal Error';
          } 
          else if (Number(error.status) == 409) {
            this.alertMsg = 'File Name Already Exists,Please Enter New Name';
            this.showSaveDialog = true;
          }
          else {
            this.alertMsg = 'Please Try After Some Time.';
          }
          this.addSingle();
        },
        () => { }
      );
    }
  
  }
  getList() {
    this.customerService.getSavedFilter(this.Id).subscribe(
      (response: any) => {
        if (response?.body?.records != null && response?.body?.records.length > 0 ) {
          this.savedFilterData = response.body.records;
          this.showFilter = true;
        }
        else{
          this.savedFilterData =[]
          this.showFilter = true;
        }
      },
      (error) => {
        this.alertSeverity = 'error';
          if (Number(error.status) == 401) {
            this.alertMsg = 'Please Try After Some Time!';
          } else if (Number(error.status) == 500) {
            this.alertMsg = 'Internal Error';
          } else {
            this.alertMsg = 'Please Try After Some Time.';
          }
          this.addSingle();
      },
      () => { }
    );
  }
  deleteFilter(value) {
    this.dataToBeDeleted = {};
    this.showDeleteDialog = true;
    this.dataToBeDeleted = this.savedFilterData[value];
  }
  ondeleteClick() {
    this.showDeleteDialog = false;
    this.customerService
      .deleteSavedFilter(this.dataToBeDeleted.adhocSaveFilterId)
      .subscribe(
        (response: any) => {
          if(response){
          this.alertSeverity = 'info';
          this.alertMsg = 'Deleted Successfully!';
          this.addSingle();
          this.getList();
          }
        },
        (error) => {
          this.alertSeverity = 'error';
          if (Number(error.status) == 401) {
            this.alertMsg = 'Please Try After Some Time!';
          } else if (Number(error.status) == 500) {
            this.alertMsg = 'Internal Error';
          } else {
            this.alertMsg = 'Please Try After Some Time.';
          }
          this.addSingle();
        },
        () => { }
      );
  }
  FillFilterToForm(value: number) {
    let data = JSON.parse(this.savedFilterData[value].adhocSaveFilterData);
    data.qa.forEach(e => e.aid = e.aid.replace('q_', ''))
    // data.qa.forEach(q=>{
    this.mainData.forEach((d, i) => {
      if (d.inputtype == 'radio') {
        let j = '';
        data.qa.filter(e => {
          d.options.filter((e1) => {
            if (e1.id == e.aid) {
              j = e1.id
              d.selectedData = e1.value;
              //d.showElement=true;
              this.onSelected(j, d.selectedData, i, d.inputtype)
            }
          });
        })

      }
      else if (d.inputtype == 'date') {
        data.qa.filter(e => {
          if (d.id == e.aid) {
            d.selectedData = new Date(e.value)
            this.onSelected(d.id, d.selectedData, i, d.inputtype)
            //d.showElement=true;
          }
        })

      }
      else {
        data.qa.filter(e => {
          if (d.id == e.aid) {
            d.selectedData = e.value;
            this.onSelected(d.id, d.selectedData, i, d.inputtype)
            //d.showElement=true;
            //
          }
        })

      }
    })

  }
  download1(val) {
    this.laodings = true;
    this.requestPayload.columns = []
    let cols = [];
    let arr = [];
    // if (val == 1) {
    //   this.HeaderData.forEach(h => { arr.push(h.header) })
    //   this.requestPayload.filters = [];
    //   // delete( this.requestPayload.filterType)//=arr;
    //   // delete( this.requestPayload.sort);
    //   this.requestPayload.columns = arr;
    // }
    // if (val == 2) {
    //   this.coldata.forEach(e => {
    //     if (e.checked == true) {
    //       cols.push(e.field)
    //     }
    //   });
     // console.log(this.FilteredData)
    //   this.requestPayload.sort = [];
    //   this.requestPayload.columns = cols;
    //   this.requestPayload.filters = this.FilteredData;
    // }
     const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.rowData);
      const workbook: XLSX.WorkBook = { Sheets: { 'Report': worksheet }, SheetNames: ['Report'] };
        const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const file = new Blob([excelBuffer], { type: 'application/octet-stream' });
        saveAs(file, 'Report.xlsx');
        this.laodings = false
    // this.customerService.downloadData(this.requestPayload).subscribe((res: any) => {
    //   this.getRecordsDownloadFileName = res.body.uniqueName;
      // this.customerService.downloadTableRecordsService2(this.getRecordsDownloadFileName).subscribe({
      //   next: (res: any) => {
      //     const file = new Blob([res], {
      //       type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'//'application/vnd.ms-excel'//'text/csv;charset=utf-8'//,
      //     });
      //     const fileName = 'customReport';
      //     // if(val==1){
      //     FileSaver.saveAs(file, fileName);
      //     this.laodings = false
      //     // }
      //     // if(val==2){
      //     // this.download2(file);
      //     // this.laodings=false;
      //     // }
      //   },
      //   error: (error) => {
      //     this.alertSeverity = 'error';
      //     if (Number(error.status) == 401) {
      //       this.alertMsg = 'Please Try After Some Time!';
      //     } else if (Number(error.status) == 500) {
      //       this.alertMsg = 'Internal Error';
      //     } else {
      //       this.alertMsg = 'Please Try After Some Time.';
      //     }
      //     this.addSingle();
      //     this.laodings = false
      //   },
      // });
    // },
    //   (error) => {
    //     this.alertSeverity = 'error';
    //       if (Number(error.status) == 401) {
    //         this.alertMsg = 'Please Try After Some Time!';
    //       } else if (Number(error.status) == 500) {
    //         this.alertMsg = 'Internal Error';
    //       } else {
    //         this.alertMsg = 'Please Try After Some Time.';
    //       }
    //       this.addSingle();
    //     this.laodings = false;
    //   }, () => {
    //   });
  }
  async download2(val) {

    const arrayBuffer = await this.blobToArrayBuffer(val);
    const data = new Uint8Array(arrayBuffer);
    const workbook = XLSX.read(data, { type: 'array' });
    const sheetName = workbook.SheetNames[0];
    const sheet = workbook.Sheets[sheetName];
    let jsonData: any = XLSX.utils.sheet_to_json(sheet, { header: 1 });
    let items: string[] = this.coldata.filter(d => d.checked != true).map(d => d.field);
    let indices = [];
    items.forEach(item => {
      indices.push(jsonData[0].findIndex(element => element === item));
    });
    let filteredArrays = [];
    filteredArrays = jsonData.map(array =>
      array.filter((_, index) => !indices.includes(index))
    );
    const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(filteredArrays);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, 'Customreport.xlsx');

  }
  private blobToArrayBuffer(blob: Blob): Promise<ArrayBuffer> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.result instanceof ArrayBuffer) {
          resolve(reader.result);
        } else {
          reject(new Error('Failed to convert Blob to ArrayBuffer'));
        }
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsArrayBuffer(blob);
    });
  }
  // download(dat, hdata, rdata) {
  //   // Excel Title, Header, Data
  //   let arr = [];
  //   let arr1 = [];
  //   let header = [];
  //   for (let i = 0; i < hdata.length; i++) {
  //     arr[i] = hdata[i].headerName;
  //   }
  //   header = arr;
  //   for (let i = 0; i < rdata.length; i++) {
  //     arr1[i] = Object.values(rdata[i]);
  //   }
  //   const data = arr1;
  //   // Create workbook and worksheet
  //   const workbook = new Workbook();
  //   const worksheet = workbook.addWorksheet('Sharing Data');
  //   // worksheet.mergeCells('A1:D2');
  //   const headerRow = worksheet.addRow(header);
  //   headerRow.eachCell((cell, number) => {
  //     cell.fill = {
  //       type: 'pattern',
  //       pattern: 'solid',
  //       fgColor: { argb: 'E9E9E9' },
  //       bgColor: { argb: 'FF0000FF' },
  //     };
  //     cell.border = {
  //       top: { style: 'thin' },
  //       left: { style: 'thin' },
  //       bottom: { style: 'thin' },
  //       right: { style: 'thin' },
  //     };
  //   });
  //   // Add Data and Conditional Formatting
  //   data.forEach((d) => {
  //     const row = worksheet.addRow(d);
  //     let color = 'FF99FF99';
  //   });
  //   worksheet.getColumn(3).width = 30;
  //   worksheet.getColumn(4).width = 30;
  //   // Generate Excel File with given name
  //   workbook.xlsx.writeBuffer().then((data: any) => {
  //     const blob = new Blob([data], {
  //       type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  //     });
  //     fs.saveAs(blob, 'CustomReport.xlsx');
  //   });
  // }
  confirm() {
    this.scheduleShow = true;
    this.showParameter = false;
    this.showScheduleBox = true;
    this.showDemand = false;
    this.selectedDemandValue = '';
    this.showFilter = false;
    this.resetForm();
  }
  GotoScheduler() {
    let id = '';
    this.customerService.getDomain(this.selectedDomain).subscribe((res: any) => {
      if (res.body.records == null || res.body.records.length == 0) {
        id = '';
      }
      else {
        id = res.body?.records[0]?.authorizationId;

        this.router.navigate(['./scheduler'], {
          queryParams: { reportId: id, name: res.body?.records[0]?.authorizationName, authDesc: res.body?.records[0]?.authorizationDesc, component: "build" },
        });
      }
    })

  }
  close() {
    this.selectedDemandValue = "onDemand";
    this.showDemand = true;
    this.showScheduleBox = false;
    this.DemandChange(this.selectedDemandValue);
    this.showFilter=true;
  }
  getGlobalFilterFields(): string[] {
    // Assuming coldata is an array representing your columns
    return this.coldata.map(col => col.field);
  }
  triggerGlobalFilter(enteredValue: string) {
    if(enteredValue==''){
      return;
    }
    // this.buildAreportTable.filterGlobal(enteredValue, 'startsWith');
    this.buildAreportTable.filterGlobal(enteredValue, 'contains');
    //console.log(this.rowData)
    setTimeout(() => {
    //  console.log(this.buildAreportTable.filteredValue);
    }, 100);
  }
  ngAfterViewInit() {
    if (this.buildAreportTable) {
      this.triggerGlobalFilter('yourSearchTerm');
    }
  }
  pascalCaseWithSpace(str) {
    // Convert to PascalCase
    return str.replace(/\w\S*/g, function (match) {
      return match.charAt(0).toUpperCase() + match.slice(1);
    });
  }
  clearTableFilter() {
    this.isLoading2 = true
    this.pageNum = 1;
    this.currentPageIndex = 0;
    this.paginator.first = 0;
    this.startIndex = 0;
    this.endIndex = 0;
    this.rowData=[];
    this.totalRecords =0
    this.requestPayload.filters = [];
    this.requestPayload.columns = [];
    // this.buildAreportTable.clear();
    this.coldataSelectAll?.forEach(e => {
      //e.checked= true,
      e.selectedDataStart = '';
      e.selectedDataLike = '';
      e.selectedDataEnd = ''
    })
    let name=this.listDomains.filter(item=>item.authorizationId==this.selectedDropdown)
    this.rundataSubscription = this.customerService
      .getRunData(name[0].authorizationName, this.pageNum, this.pageSize)
      .subscribe((data: any) => {
        if (data.body.records) {
          this.originalApiData = data.body.totalRecords;
          // this.rows = data.body.records;
          this.rowData = data.body.records;
          this.coldata = this.coldataSelectAll;
          this.totalRecords = data.body.totalRecords;
          this.startIndex = 1;
          this.endIndex = this.rowData.length;
          this.paginator.changePage(0);
          //this.coldataSelectAll=data.body.records;
        }
        this.isLoading2 = false;
      },
        (error) => {
          this.isLoading2 = false;
          this.startIndex = 0;
          this.endIndex = 0;
        });
  }
  back() {
    this.router.navigate(['/discovery'], { queryParams: { search: this.searchMetri } });
    this.showreport = false;
    // window.history.back();
  }
  dynamicWidth(field: string, headerName: string): string {
    const minWidth = 180; 
    const contentWidth = Math.max(
      ...this.rowData.map(item => String(item[field]).length)
    );
    const headerWidth = headerName.length;
    const maxWidth = Math.max(contentWidth, headerWidth);
    const calculatedWidth = headerWidth * 20;
    return `${Math.max(calculatedWidth, minWidth)}px`;
  }
}
