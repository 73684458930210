<div class="container-fluid catalogue-report-parent-container main-content">
	<p-card class="table-card">
	<div class="parent-user">
		<img src="././assets/images/dualBallSpinner.svg" alt="" class="modal-spinner-img" *ngIf="isLoadingModal || customSortBool">
		<p-table #mycatalogtable [value]="this.reportRecords" [scrollable]="true" [customSort]="true" (sortFunction)="customSort($event)" scrollHeight="calc(100vh - {{tableReduceHeight+'px'}})"
		selectionMode="single" [sortOrder]="dir" scrollHeight="calc(100vh - {{tableReduceHeight+'px'}})"
		[sortField]="tableSortColumn" [tableStyle]="{'min-width':'50rem'}" >
		<ng-template pTemplate="caption">
			<div class="p-d-flex p-jc-between p-ai-center searchI">
				<div class="filter-icons-section-table-header">
					<i class="pi pi-filter table-filter-icons pi-filter-icon"
					pTooltip="Show Filter" tooltipPosition="top" (click)="showFilter()"></i>
				<i class="pi pi-filter-slash table-filter-icons"
					pTooltip="Reset/Clear Filter" tooltipPosition="top" (click)="HideFilter()"></i>
				</div>
				<span class="p-input-icon-left p-ml-auto">
					<!-- <i class="pi pi-search"></i>
					<input pInputText type="text" [(ngModel)]="searchText"
						(ngModelChange)="this.tableSearchUpdate.next($event)" placeholder="Search keyword"
						[disabled]="searchFieldDisable" /> -->
				</span>
				<!-- <div class="p-input-icon-left searchFilter">
					<i class="pi pi-search"></i>
					<input type="text" pInputText placeholder="Search" [type]="'search'" class="w-full" [(ngModel)]="searchText"/>
				</div> -->
			</div>
		</ng-template>
		<ng-template pTemplate="header">
			<tr>
				<th pSortableColumn="clientName" (click)="theadClick($event)" class="job-name-table-th">
					Client Name<p-sortIcon field="clientName"></p-sortIcon></th>
				<th pSortableColumn="authorizationName" (click)="theadClick($event)" class="job-name-table-th ">Report Name
					<p-sortIcon field="authorizationName"></p-sortIcon></th>
				<th pSortableColumn="authorizationDesc" (click)="theadClick($event)" class="job-name-table-th">Report Description
					<p-sortIcon field="authorizationDesc"></p-sortIcon></th>
				<th pSortableColumn="reportType" (click)="theadClick($event)" class="job-name-table-th">Report Type
					<p-sortIcon field="reportType"></p-sortIcon></th>
				<th (click)="theadClick($event)" class="job-name-table-th">Location
					</th>
				<th style="text-align:center">Action</th>
			</tr>
				<tr *ngIf="hideFilter">
					<th class="filter-input-th" *ngIf="hasAdminRole">
						<input class="filter-input-table" pInputText type="text" [(ngModel)]="clientName_Start"
						(ngModelChange)="this.tableSearchUpdate.next($event)" placeholder="Starts With"/>
						<input class="filter-input-table" pInputText type="text" [(ngModel)]="clientName_Contains"
						(ngModelChange)="this.tableSearchUpdate.next($event)" placeholder="Contains" />
						<input class="filter-input-table" pInputText type="text" [(ngModel)]="clientName_ends"
						(ngModelChange)="this.tableSearchUpdate.next($event)" placeholder="Ends With" />
					</th>
					<th class="filter-input-th">
						<input class="filter-input-table" pInputText type="text" [(ngModel)]="ReportName_start"
						(ngModelChange)="this.tableSearchUpdate.next($event)" placeholder="Starts With"/>
						<input class="filter-input-table" pInputText type="text" [(ngModel)]="ReportName_Contains"
						(ngModelChange)="this.tableSearchUpdate.next($event)" placeholder="Contains" />
						<input class="filter-input-table" pInputText type="text" [(ngModel)]="ReportName_ends"
						(ngModelChange)="this.tableSearchUpdate.next($event)" placeholder="Ends With" />
					</th>
					<th class="filter-input-th">
						<input class="filter-input-table" pInputText type="text" [(ngModel)]="reportDesc_Start"
						(ngModelChange)="this.tableSearchUpdate.next($event)" placeholder="Starts With"/>
						<input class="filter-input-table" pInputText type="text" [(ngModel)]="reportDesc_Contains"
						(ngModelChange)="this.tableSearchUpdate.next($event)" placeholder="Contains" />
						<input class="filter-input-table" pInputText type="text" [(ngModel)]="reportDesc_ends"
						(ngModelChange)="this.tableSearchUpdate.next($event)" placeholder="Ends With" />
					</th>
					<th class="filter-input-th">
						<input class="filter-input-table" pInputText type="text" [(ngModel)]="ReportType_Start"
						(ngModelChange)="this.tableSearchUpdate.next($event)" placeholder="Starts With"/>
						<input class="filter-input-table" pInputText type="text" [(ngModel)]="ReportType_Contains"
						(ngModelChange)="this.tableSearchUpdate.next($event)" placeholder="Contains" />
						<input class="filter-input-table" pInputText type="text" [(ngModel)]="ReportType_ends"
						(ngModelChange)="this.tableSearchUpdate.next($event)" placeholder="Ends With" />
					</th>
					<!-- <th class="filter-input-th">
						<input class="filter-input-table" pInputText type="text" [(ngModel)]="location_Start"
						(ngModelChange)="this.tableSearchUpdate.next($event)" placeholder="Starts With"/>
						<input class="filter-input-table" pInputText type="text" [(ngModel)]="location_Contains"
						(ngModelChange)="this.tableSearchUpdate.next($event)" placeholder="Contains" />
						<input class="filter-input-table" pInputText type="text" [(ngModel)]="location_ends"
						(ngModelChange)="this.tableSearchUpdate.next($event)" placeholder="Ends With" />
					</th> -->
					<th></th>
					</tr>
			</ng-template>
			<ng-template pTemplate="body" let-data let-i="rowIndex">
				<tr>
					<td title="{{data.clientName}}" *ngIf="hasAdminRole">{{data.clientName}}</td>
					<td title="{{data.authorizationName}}">{{data.authorizationName}}</td>
					<td title="{{data.authorizationDesc}}">{{data.authorizationDesc?data.authorizationDesc:'-'}}</td>
					<td title="{{data.reportType}}">{{data.reportType?data.reportType:'-'}}</td>
					<td title="{{data.location}}">{{data.location}}</td>
					<td>
						<div class="tibo-myrepo-listview-btns-cont" style="text-align:center">			
							<button id="" class="p-mr-2"
								type="button" pButton icon="pi pi-info-circle" pTooltip="Report Information"
								tooltipPosition="top" (click)="onReportInfoButton(data)"></button>
								<button id="" class="p-mr-2" *ngIf="hasAdminRole"><i class="fa fa-pencil-square-o" 
								pTooltip="Edit Report" tooltipPosition="top" (click)="EditReport(data,i)"></i></button>
								</div>
					</td>
			</ng-template>
			<ng-template pTemplate="emptymessage">
				<tr>
					<td>No Records Found</td>
				</tr>
			</ng-template>
		</p-table>
		<div class="p-d-flex Adminpagination-container">
			<div class="Adminpagination-first-part">
				<p class="p-mx-2">Showing <b>{{startIndex}}-{{endIndex}}</b> of <b>{{totalRecords}}</b>
					Records
				</p>
			</div>
			<div class="Adminpagination-second-part">
				<p-paginator #paginator styleClass="tibo-schedrepo-listView-p-paginator" [rows]="150"
					[totalRecords]="totalRecords" (onPageChange)="paginate($event, 'userData')"
					[showJumpToPageDropdown]="true" [showPageLinks]="false"></p-paginator>
			</div>
		</div>
	</div>
	</p-card>
	<!-- information dialog -->
	<p-dialog header="Report Catalog {{reportInfo?.authorizationName}}" [draggable]="false" class="confirm-dialog info-dialog" [position]="'top'"
			[modal]="true" [(visible)]="reportDialogVisible" [style]="{ width: '700px' }">
			<img src="././assets/images/dualBallSpinner.svg" alt="" class="modal-spinner-img" *ngIf="isLoadingModalInfo">
			<p-card class="card" *ngIf="!isLoadingModal">
				<div class="p-grid header-back-info-model" *ngIf="hasAdminRole">
					<div class="p-col-4 p-d-flex">
						<b class="header-name-info-modal">Client Name:</b>
						<b class="hyphen">-</b>
					</div>
					<div class="p-col-8 p-d-flex">
						<b>{{reportInfo?.clientName}}</b>
					</div>
				</div>
				<div class="p-grid" style="overflow-wrap: anywhere">
					<div class="p-col-4 border-col" *ngIf="hasAdminRole">
						<b class="header-name-info-modal">Client Code:</b>
						<b class="hyphen">-</b>
					</div>
					<div class="p-col-8 border-col" *ngIf="hasAdminRole">
						{{reportInfo?.clientCode}}
					</div>
					<div class="p-col-4 border-col">
						<b class="header-name-info-modal">Report Name:</b>
						<b class="hyphen">-</b>
					</div>
					<div class="p-col-8 border-col">
						{{reportInfo?.authorizationName}}
					</div>
					<div class="p-col-4 border-col">
						<b class="header-name-info-modal">Report Description:</b>
						<b class="hyphen">-</b>
					</div>
					<div class="p-col-8 border-col">
						{{reportInfo?.authorizationDesc}}
					</div>
					<div class="p-col-4 border-col">
						<b class="header-name-info-modal">Report Parameters:</b>
						<b class="hyphen">-</b>
					</div>
					<div class="p-col-8 border-col">
						{{reportInfo?.parameters | stripHtml}}
					</div>
					<div class="p-col-4 border-col">
						<b class="header-name-info-modal">Report Criteria:</b>
						<b class="hyphen">-</b>
					</div>
					<div class="p-col-8 border-col">
						{{reportInfo?.reportCriteria  | stripHtml}}
					</div>
					<div class="p-col-4 border-col">
						<b class="header-name-info-modal">Report Type:</b>
						<b class="hyphen">-</b>
					</div>
					<div class="p-col-8 border-col">
						{{reportInfo?.reportType}}
					</div>
					<div class="p-col-4 border-col">
						<b class="header-name-info-modal">Field Mapping:</b>
						<b class="hyphen">-</b>
					</div>
					<div class="p-col-8 border-col">
						{{reportInfo?.fieldMapping  | stripHtml}}
					</div>
					<div class="p-col-4 border-col">
						<b class="header-name-info-modal">Location:</b>
						<b class="hyphen">-</b>
					</div>
					<div class="p-col-8 border-col">
						{{reportInfo?.location}}
					</div>
				</div>
			</p-card>
		</p-dialog>
		<!-- edit dialog -->
		<p-dialog [modal]="true" [draggable]="false" [style]="{ width: '500px' }" class="confirm-dialog"
			[(visible)]="showEditReport" header="Edit Catalog" [position]="'top'">
			<img src="././assets/images/dualBallSpinner.svg" alt="" class="modal-spinner-img" *ngIf="isLoadingModal">
			<form [formGroup]="editReportForm">
				<div class="p-field p-col-12 form-group">
					<label for="name" class="form-label">Report Name</label><br>
					<input type="text" class="form-input1" pInputText  id="name" formControlName="reportName" readonly>
				</div>
				<div class="p-field p-col-12 form-group">
					<label for="Id" class="form-label">Description</label><br>
					<input type="text" class="form-input" pInputText id="Id" formControlName="reportDesc">
				</div>
				<div class="p-field p-col-12 form-group">
					<label for="Id" class="form-label">Report Criteria</label><br>
					<!-- <input type="text" class="form-input" pInputText id="Criteria" formControlName="reportCriteria"> -->
					<p-editor [style]="{'height':'200px'}" formControlName="reportCriteria">
					</p-editor>
				</div>
				<div class="p-field p-col-12 form-group">
					<label for="Id" class="form-label">Report Parameters</label><br>
					<!-- <input type="text" class="form-input" pInputText id="Parameters" formControlName="reportParameter"> -->
					<p-editor formControlName="reportParameter"
						[style]="{'height':'200px'}"></p-editor>
				</div>
				<div class="p-field p-col-12 form-group">
					<label for="Id" class="form-label">Field Mapping</label><br>
					<!-- <input type="text" class="form-input" pInputText id="Mapping" formControlName="fieldMap"> -->
					<p-editor formControlName="fieldMap"
						[style]="{'height':'200px'}"></p-editor>
				</div>
			</form>
			<ng-template pTemplate="footer">
				<p-button class="save-btn" type="submit" label="Update" (click)="updateReportCatelogue()"></p-button>
			</ng-template>
		</p-dialog>
	</div>