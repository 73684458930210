import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { MyReportsOutputService } from './my-reports-output.service';
import { Subject, Subscription } from 'rxjs';
import { Message, MessageService } from 'primeng/api';
import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe, Location } from '@angular/common';
import { Table } from 'primeng/table';
import { SortEvent } from 'primeng/api';
import { ViewChild } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { bufferTime, debounceTime } from 'rxjs/operators';
import { Paginator } from 'primeng/paginator';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

declare var d3: any;

@Component({
  selector: 'app-my-reports-output',
  templateUrl: './my-reports-output.component.html',
  styleUrls: ['./my-reports-output.component.css'],
  providers: [MyReportsOutputService],
})
export class MyReportsOutputComponent implements OnInit, OnDestroy {
  //   @Input() isRunnable: boolean;
  //  @Input() reportId: any;
  //   @Input() reportType: any;
  //   @Input() reportName: any;
  @Input() chartData1: any;
  @ViewChild('myreportssqloutputtable', { static: true }) table: Table;
  @ViewChild('paginator', { static: false }) paginator: Paginator;
  downloadBtnSpinner = 'pi-download';
  tableSearchUpdate1 = new Subject<string>();
  reportId?: any;
  reportName?: any;
  reportType?: any;
  reportOutput?: any;
  reportOutputStr = '';
  birtDocumentName = '';
  sqlTotalRecords?: any;
  sqlTableColumns?: any;
  parameterElementsArray = [];
  requestPayloadParameterObj = {
    filters: [],
    filterType: 'and',
    parameters: {},
    sort: [],
  };
  repoCritPanelToggleBool: boolean = false;
  downloadTypeList: MenuItem[];
  downloadTypeListArray = [];
  // tableWidth: string = '95%';
  dwnlRepoSerRequestPayload = {
    sort: [],
    filters: [],
    filterType: 'and',
    parameters: {},
    columns: []
  };
  paginatorPageNo = 1;
  sqlTableSortColumn = undefined;
  sqlTableSortDir?: any;
  globalFilterFieldsArr = [];
  reportIdSubscrip: Subscription;
  reportParamSerSubscrip: Subscription;
  getReportSerSubscrip: Subscription;
  alertMessage: Message[];
  alertSeverity: string = 'error';
  alertMsg: string = '';
  sideMenuCollapse: boolean = false;
  showDownloadBtn: boolean = false;
  payload: any = {};
  payData: any = '';
  cascadeBool: boolean;
  values: any;
  showSideBar: boolean = false;
  showreport: any = false;
  searchMetri: any = '';
  SelectedRequestedPage: any = '';
  clientId: any;
  SubGroupId: any;
  isLoad: boolean = false;
  showTab: boolean = false;
  tabs: any = 0;
  tabOptions: any = [];
  selectedTab: any;
  showBirt: boolean = false;
  docName: any = '';
  clientFlag: string;
  parentFlag: string;
  lobData: any;
  policiesData: any;
  policyId: any;
  classBON: string;
  classId: string;
  paramDates = [];
  dateAlert: boolean = false;
  showBirtRepo: boolean = false;
  coldata: any;
  rowData: any;
  totalRecords: number;
  coldataTemp: any;
  coldataSelectAll: any;
  showFilterFieldsBoo: boolean = false;
  globalFilter: string;
  downloadButtonList: { label: string; icon: string; id: string; command: () => void; }[];
  laodings: boolean = false;
  tableSearchUpdate = new Subject<string>();
  showmenu: boolean = false;
  isLoading2: boolean = false;
  tableEmptyMessage: string;
  filteredPayload: any = [];
  showDownloadBtn1: boolean = false;
  startIndex: number = 0;
  endIndex: number = 0;
  originalApi: any;
  tableReduceHeight = 300;
  tableWidth: string = '99%';
  dateError: boolean = false;
  filterToolTip = 'Show';
  restricted: boolean = false;
  runReport: any;
  runReport1:any;
  runReport2: any;
  isMatrixData: any=false;
  matrixClientData: any='';
  matrixClientAccId: any='';
  //datePipe: any;

  constructor(
    private messageService: MessageService,
    public activatedRoute: ActivatedRoute,
    private myRepoOutputService: MyReportsOutputService,
    private location: Location,
    private router: Router,
    private datePipe: DatePipe,
  ) {
    this.tableSearchUpdate.pipe(debounceTime(3000)).subscribe((value) => {
      this.triggerGlobalFilter(value);
    });
    this.tableSearchUpdate1.pipe(debounceTime(3000)).subscribe((value) => {
      this.run1(value[0], value[1], value[2]);
    });
  }

  ngOnInit(): void {
    this.filteredPayload = [];
    document.getElementById('report-output-sql').style.display =
      'none';
    this.showmenu = false;
    this.coldata = [];
    this.coldataTemp = [];
    this.coldataSelectAll = [];
    this.downloadButtonList = [
      {
        label: 'All Records',
        icon: 'pi pi-file-excel',
        id: '',
        command: () => {
          this.download1(1);
        },
      }
      // ,
      // {
      //   label: 'Filtered Records',
      //   icon: 'pi pi-file-excel',
      //   id: '',
      //   command: () => {
      //     this.download1(2);
      //   },
      // },
    ];
    this.rowData = []
    this.showBirtRepo = false;
    this.showTab = false;
    this.SelectedRequestedPage = '';
    this.clientId = '';
    this.SubGroupId = '';
    let obj = {},
      hasDrillKey = false;
    if (this.chartData1 != undefined) {
      this.reportId = this.chartData1['id'];
      this.reportType = this.chartData1['type'];
      this.reportName = this.chartData1['name'];
      //this.showreport = this.chartData1['showReport'];
      this.searchMetri = this.chartData1['searchMetric'];
      // let obj = {},
      //     hasDrillKey = false;
      // for (let key in this.chartData1) {
      //   if (key.substr(0, 6) == 'drill_') {
      //     hasDrillKey = true;
      //     let sub = key.substr(6, key.length);
      //     obj[sub] = this.chartData1[key];
      //   }
      // }
    }
    else {
      this.reportIdSubscrip = this.activatedRoute.queryParams.subscribe(
        (params) => {
          this.reportId = params.id;
          this.reportType = params.type;
          this.reportName = params.name;
          this.showreport = params.showReport;
          this.searchMetri = params.searchMetric;
          this.docName = params.uniqueName;
          this.isMatrixData = params.isMatrix ? params.isMatrix : false;
          this.matrixClientData=params.matrixCliCode ? params.matrixCliCode : '';
          this.matrixClientAccId=params.matrixAccId ? params.matrixAccId : '';
          // let obj = {},
          //   hasDrillKey = false;
          // for (let key in params) {
          //   if (key.substr(0, 6) == 'drill_') {
          //     hasDrillKey = true;
          //     let sub = key.substr(6, key.length);
          //     obj[sub] = params[key];
          //   }
          // }
        }
      );
    }
    // if(this.isMatrixData){
    //  this.callMatric();
    // }
    // else{
        if (hasDrillKey) {
          d3.select('#myrepo-output-parameters-panel').style('display', 'none'); //.style("pointer-events","none")
          d3.select('#myrepo-output-container')
            .style('left', '0px')
            .style('width', '100%');
          this.isLoad = true;
          this.paramDates = [];

          this.reportParamSerSubscrip = this.myRepoOutputService
            .reportParameterService(this.reportId)
            .subscribe(
              (response: any) => {
                // report parameters
                this.isLoad = false;

                response.body?.reportInputControl?.forEach(e => {
                  if ((e.type == 'singleValueDate') && (e.id.toLowerCase().replace(/[_ ]/g, '') != 'Valuationdate')) {
                    e.mandatory = true;
                    e.state.value = [];
                  }
                  // if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'startdate' || e.id.toLowerCase().replace(/[_ ]/g, '') == 'enddate' || e.id.toLowerCase().replace(/[_ ]/g, '') == 'fromdate' || e.id.toLowerCase().replace(/[_ ]/g, '') == 'thrudate') {
                  //   e.mandatory=true;
                  //   e.state.value =[];
                  // }
                  let index = 0;
                  e.state?.options.filter((item, i) => {
                    if (item['value'] == '' || item['value'] == 'empty' || item['value'] == "" || item['value'] == null) {
                      e.state?.options.splice(i, 1);
                    }
                  })
                  e.selectedData = e.state?.options[0].value;
                  if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'startdate' || e.id.toLowerCase().replace(/[_ ]/g, '') == 'enddate' || e.id.toLowerCase().replace(/[_ ]/g, '') == 'fromdate' || e.id.toLowerCase().replace(/[_ ]/g, '') == 'thrudate') {
                    this.paramDates[index] = e;
                    if (e.selectedData == '' || e.selectedData.length == null || e.selectedData.length == 0) { 
                      if(e.type=='singleValueDate'){this.paramDates[index].selectedData[0]= ""}
                      if(e.type=='singleValueText'){this.paramDates[index].selectedData= "" }
                    }
                    index++;
                  }
                  this.parameterElementsArray.push(e)
                })
                // this.parameterElementsArray = response.body?.reportInputControl;
                // response.body.exportType.forEach((expType) => {
                //   this.downloadTypeListArray.push({
                //     label: expType.toLowerCase(),
                //     command: (event) => {
                //       this.downloadReportFunc(event);
                //     },
                //   });

                //   if (expType.toLowerCase() == 'xlsx') {
                //     this.downloadTypeListArray.push({
                //       label: 'Save xlsx to Inbox',
                //       command: (event) => {
                //         this.downloadReportFunc(event);
                //       },
                //     });
                //   } else if (expType.toLowerCase() == 'pdf') {
                //     this.downloadTypeListArray.push({
                //       label: 'Save pdf to Inbox',
                //       command: (event) => {
                //         this.downloadReportFunc(event);
                //       },
                //     });
                //   } else if (expType.toLowerCase() == 'csv') {
                //     this.downloadTypeListArray.push({
                //       label: 'Save csv to Inbox',
                //       command: (event) => {
                //         this.downloadReportFunc(event);
                //       },
                //     });
                //   }
                // });

                this.downloadTypeList = this.downloadTypeListArray;
              },
              (error) => {
                this.alertSeverity = 'error';
                if (Number(error.status) == 401) {
                  this.alertMsg = 'Please Try After Some Time!';
                } else if (Number(error.status) == 500) {
                  this.alertMsg = 'Internal Error';
                } else {
                  this.alertMsg = 'Please Try After Some Time.';
                }
                this.addSingle();
              },
              () => { }
            );

          this.myRepoOutputService
            .reportDrillDownService(this.reportId, { parameters: obj })
            .subscribe(
              (drillDownResp: any) => {
                // report output drill down

                drillDownResp.body.output = drillDownResp.body.output.replace(
                  /target=\"_self\"/gi,
                  'target="_top"'
                );
                drillDownResp.body.output = drillDownResp.body.output.replace(
                  /target=\'_self\'/gi,
                  'target="_top"'
                );

                const iframe = document.getElementById(
                  'report-output-birt'
                ) as HTMLIFrameElement;
                iframe.setAttribute('srcdoc', drillDownResp.body.output);
                iframe.style.background = 'white';
                iframe.contentWindow.document.body.style.backgroundColor =
                  'white';
                this.showBirt = true;
              },
              (error) => {
                this.alertSeverity = 'error';
                if (Number(error.status) == 401) {
                  this.alertMsg = 'Please Try After Some Time!';
                } else if (Number(error.status) == 500) {
                  this.alertMsg = 'Internal Error';
                } else {
                  this.alertMsg = 'Please Try After Some Time.';
                }
                this.addSingle();
              },
              () => { }
            );
        }

        else {
          let hasParameter = false;
          this.isLoad = true;
          this.reportParamSerSubscrip = this.myRepoOutputService
            .reportParameterService(this.reportId)
            .subscribe(
              (response: any) => {
                // report parameters
                this.isLoad = false;
                if (response.reportInputControl == null) {
                  this.parameterElementsArray = [];
                  this.showSideBar = false
                }
                else {
                  this.showSideBar = true;
                  response.reportInputControl.forEach((e, i) => {
                    if (e.visible) {
                      if ((e.type == "singleValueDate") && (e.id.toLowerCase().replace(/[_ ]/g, '') != "valuationdate")) {
                        e.mandatory = true;
                        e.state.value = [];
                      }
                    }
                  })

                  response.reportInputControl.forEach((e, i) => {
                    if (e.state.options.length > 0) {
                      if (e.state.options[0].value === '' || e.state.options[0].value === 'empty' || e.state.options[0].value === "") {
                        e.state.options.splice(0, 1);
                      }

                      e.state.options = e.state.options.filter(option => {
                        if (typeof option === 'object' && option !== null) {
                          // Filter out if value is empty string ('' or ""), "empty", or null
                          return option.value !== null && option.value !== '' && option.value !== 'empty';
                        }
                        // Ensure non-object values are also filtered accordingly
                        return false;
                      });
                      //e.state.options = e.state.options.lenght>0?e.state.options.options:[];
                    }

                    if (e.visible && (e.id.toLowerCase().replace(/[_ ]/g, '') !== "daterange")) {
                      let selectedOption=[];
                      if(e.state.options.length > 0){
                          selectedOption = e.state.options.filter(option => option.selected);
                      }
                      if (selectedOption.length>0) {
                        this.requestPayloadParameterObj.parameters[e.id]=  selectedOption.length > 1|| e.type=='multiSelect'?selectedOption.map(option => option.value):selectedOption[0].value;
                        e.paramElementFilledBool=true;
                        e.selectedData =selectedOption.length > 1|| e.type=='multiSelect'? selectedOption.map(option => option.value):selectedOption[0].value;
                      } 
                      else {
                          this.requestPayloadParameterObj.parameters[e.id] =e.state.options.length>0 ? []:e.state.value? e.state.value: '';
                          e.paramElementFilledBool=e.state.options.length>0 ? false:e.state.value? true:false;
                          e.selectedData =e.state.options.length>0 ? []:e.state.value? e.state.value:e.type=='singleValueDate'?[]: ''
                      }
                      this.parameterElementsArray.push(e);
                      console.log(this.parameterElementsArray)
                    }
                    // if (e.state.value == '' || e.state.value == null) {
                    //   e.paramElementFilledBool = false
                    // }

                    if (e.id == "CustomParameterPage") {
                      this.SelectedRequestedPage = e.state.value;
                      this.requestPayloadParameterObj.parameters[e.id] = e.state.value ? e.state.value : '';
                    }
                    // if (!e.visible && e.state && e.state.options && e.state.options.length > 0) {
                    //   this.requestPayloadParameterObj.parameters[e.id] = e.state.options[0]['value'] ? e.state.options[0]['value'] : e.state.value ? e.state.value : '';
                    // }
                    if (!e.visible && e.state && e.state.options) {
                        let selectedOption=[];
                         if(e.state.options.length > 0){
                              selectedOption = e.state.options.filter(option => option.selected);
                          }
                      // let selectedOption = e.state.options.find(option => option.selected);
                      if (selectedOption.length>0) {
                        this.requestPayloadParameterObj.parameters[e.id]=  selectedOption.length > 1?selectedOption.map(option => option.value):selectedOption[0].value;
                      } else {
                        this.requestPayloadParameterObj.parameters[e.id] =
                         e.state.options.length>0 
                          ? []
                          : 
                          e.state.value 
                            ? e.state.value 
                            : '';
                      }
                    }
                  })
                  let index = 0
                  this.parameterElementsArray?.forEach(e => {
                    e.state?.options.filter((item, i) => {
                      if (item['value'] === '' || item['value'] === 'empty' || item['value'] === "") {
                        e.state?.options.splice(i, 1);
                      }
                    })
                    // e.selectedData = [];
                    // if (e.type === 'singleValueDate' && e.id.toLowerCase().replace(/[_ ]/g, '') != "valuationdate") {
                    //   this.paramDates[index] = e;
                    //   index++;
                    // }
                    if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'startdate' || e.id.toLowerCase().replace(/[_ ]/g, '') == 'enddate' || e.id.toLowerCase().replace(/[_ ]/g, '') == 'fromdate' || e.id.toLowerCase().replace(/[_ ]/g, '') == 'thrudate') {
                      this.paramDates[index] = e;
                      if (e.selectedData == '' || e.selectedData.length == null || e.selectedData.length == 0) {
                        if(e.type=='singleValueDate'){this.paramDates[index].selectedData[0]= ""}
                        if(e.type=='singleValueText'){this.paramDates[index].selectedData= "" }
                        e.mandatory = true;
                        e.state.value = [];
                        }
                      index++;
                    }
                  })
                  this.parameterElementsArray.forEach(e => {
                    this.payload[e.id] = [];
                  })
                  this.parameterElementsArray.forEach(e => {
                    if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'clicode' && e.type != 'singleValueText') {
                      e.loading = true;
                      this.myRepoOutputService.getClientNameCode(this.reportId).subscribe((responses: any) => {
                        e.loading = false;
                        e.loading = false;
                        responses?.body?.forEach(c => {
                          c.value = c.clientCode;
                          delete c.clientCode;
                          c.label = c.clientName;
                          delete c.clientName;
                          c.selected = false;
                        })
                        e.state.options = responses?.body;
                        e.selectedData = e.state.options[0].value;
                        e.paramElementFilledBool = true;
                        this.requestPayloadParameterObj.parameters[e.id] = e.state.options[0].value;
                        // this.parameterElementsArray.filter(e => {
                        //   if (e.id == obj.id) {
                        if (e.slaveDependencies.length > 0) {
                          for (let i = 0; i < e.slaveDependencies.length; i++) {
                            this.payload[e.slaveDependencies[i]] = '';
                          }

                          this.cascadeBool = true;
                        }
                        else {
                          this.cascadeBool = false;
                        }
                        //   }
                        // });
                        if (this.cascadeBool) {
                          for (let k in this.payload) {
                            if (k == e.id) {
                              this.payload[k] = e.selectedData;
                            }

                          }
                          // this.parameterElementsArray.forEach(e => {
                          //   if (e.id == e.id) {
                          this.payData = e.slaveDependencies.join(';')
                          //   }
                          // })
                          let idNames = this.payData.split(';')
                          this.parameterElementsArray.forEach(item => {
                            if (idNames.includes(item.id)) {
                              item.loading = true;
                              item.selectedData = '';
                              // this.requestPayloadParameterObj.parameters[e.id] ="";
                              item.paramElementFilledBool=false;
                            }
                          });
                          this.myRepoOutputService.cascadeParameterService(this.reportId, this.payData, this.payload).subscribe((res: any) => {
                            this.parameterElementsArray.forEach(e => {
                              if (idNames.includes(e.id)) {
                                // if(e.id == e.slaveDependencies[0]){
                                res?.body?.records.filter(element => {
                                  if (element.id == e.id) {
                                    element.options.forEach((el, i) => {
                                      if (el['value'] === '' || el['value'] === 'empty' || el['value'] === "" || el['value'] === null) {
                                        element.options.splice(i, 1);
                                      }
                                    })
                                    e.state.options = element.options;
                                    e.loading = false;

                                  }

                                });
                              }
                            })
                          })
                        }
                      })

                    }
                    if (this.SelectedRequestedPage == 'LOA') {
                      if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'clients') {
                        e.visible = true;
                        e.loading = true;
                        this.myRepoOutputService.getClientNamesList(true, this.reportId).subscribe((response: any) => {
                          e.loading = false;
                          response?.body?.forEach(c => {
                            c.value = c.clientCode;
                            delete c.clientCode;
                            c.label = c.clientName;
                            delete c.clientName;
                            c.selected = false;
                          })
                          e.state.options = response?.body;
                          e.selectedData = e.state.options[0].value;
                          e.paramElementFilledBool = true;
                          this.requestPayloadParameterObj.parameters[e.id] = e.state.options[0].value;
                          this.clientId = e.state.options[0].value;
                          //console.log(this.requestPayloadParameterObj);
                          let updatedArray = []
                          this.myRepoOutputService.getSubgroupList(this.reportId, this.clientId).subscribe((response: any) => {
                            response?.body?.forEach(c => {
                              if (c.displayName !== null && c.value !== null) {
                                updatedArray.push({
                                  label: c.displayName ? c.displayName : '',
                                  value: c.value,
                                  selected: false
                                });
                              }
                            });
                            updatedArray.forEach(d => { if (d.value.toLowerCase() == 'restricted') { this.restricted = true } })
                            this.parameterElementsArray.filter(e => {
                              if (e.id.toLowerCase() == 'subgroup') { e.state.options = updatedArray }
                            })
                          })
                        })
                      }
                      if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'runreportby') {
                        e.visible = true;
                        e.loading = true;
                        this.myRepoOutputService.getRunBy(this.reportId).subscribe((response: any) => {
                          e.loading = false;
                          let outputArray = response?.body.map(item => {
                            return { value: item, label: item, selected: false };
                          });
                          e.state.options = outputArray;
                        })
                      }
                      if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroup' || e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroupvalues') { e.visible = false } else { e.visible = true }
                    }
                    if (this.SelectedRequestedPage == 'ADA_REQ') {
                      if (e.id.toLowerCase() == 'clients') {
                        e.visible = true;
                        e.loading = true;
                        this.myRepoOutputService.getAdaClientReport(this.reportId).subscribe((response: any) => {
                          e.loading = false;
                          response?.body?.forEach(c => {
                            c.value = c.clientCode;
                            delete c.clientCode;
                            c.label = c.clientName;
                            delete c.clientName;
                            c.selected = false;
                          })
                          e.state.options = response?.body;
                          e.selectedData = e.state.options[0].value;
                          e.paramElementFilledBool = true;
                          this.requestPayloadParameterObj.parameters[e.id] = e.state.options[0].value;
                          this.clientId = e.state.options[0].value;
                          if (this.clientId) {
                            this.parameterElementsArray.filter(e => {
                              if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroup') {
                                e.selectedData = [];
                                this.requestPayloadParameterObj.parameters[e.id] = '';
                                e.state.options = [];
                                e.visible = true;
                                e.loading = true
                                this.myRepoOutputService.getSubgroupList(this.reportId, this.clientId).subscribe((response: any) => {
                                  e.loading = false;
                                  response?.body?.forEach(c => {
                                    if (c.displayName !== null && c.value !== null) {
                                      c.label = c.displayName ? c.displayName : '',
                                        delete c.displayName;
                                      c.selected = false;
                                    }
                                  })
                                  e.state.options = response?.body;
                                  response?.body.forEach(d => { if (d.value.toLowerCase() == 'restricted') { this.restricted = true } })
                                  if (this.restricted) {
                                    e.mandatory = true;
                                    e.disabled = true;
                                    e.paramElementFilledBool = true;
                                    e.selectedData = e.state.options[0].value;
                                    this.requestPayloadParameterObj.parameters[e.id] = e.state.options[0].value;
                                    this.SubGroupId=e.state.options[0].value;
                                    this.parameterElementsArray.filter(e => {
                                      if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroupvalues') {
                                        // if (this.restricted) {
                                          e.visible = true;
                                          e.loading = true
                                          let updatedArray = [];
                                          const selectedValues = []
                                          e.selectedData = [];
                                          this.requestPayloadParameterObj.parameters[e.id] = [];
                                          this.myRepoOutputService.getAdareqSubGroupValue(this.reportId, this.clientId, this.SubGroupId, this.SelectedRequestedPage).subscribe((response: any) => {
                                            e.loading = false;
                                            response?.body?.forEach(c => {
                                              if (c.displayName !== null && c.value !== null) {
                                                updatedArray.push({
                                                  label: c.displayName ? c.displayName : '',
                                                  value: c.value,
                                                  selected: true
                                                });
                                              }
                                              selectedValues.push(c.value)
                                            });
                                            e.state.options = updatedArray;
                                            e.mandatory = true;
                                            e.disabled = true;
                                            e.paramElementFilledBool = true;
                                            e.selectedData = selectedValues;
                                            this.requestPayloadParameterObj.parameters[e.id] = selectedValues;
                                          })
                                      }
                                    })
                                  }
                                })
                              }
                            })
                          }
                        })

                      }
                      if (e.id.toLowerCase() == 'subgroup' || e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroupvalues') {
                        e.visible = this.restricted ? true : false
                      } else {
                        e.visible = true
                      }
                    }
                    if (this.SelectedRequestedPage == 'ADA') {
                      if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'clientnames') {
                        e.visible = true;
                        e.loading = true;
                        this.myRepoOutputService.getAdaClient(this.reportId).subscribe((response: any) => {
                          e.loading = false;
                          response?.body?.forEach(c => {
                            c.value = c.clientCode;
                            delete c.clientCode;
                            c.label = c.clientName;
                            delete c.clientName;
                            c.selected = false;
                          })
                          e.state.options = response?.body;
                          e.selectedData = e.state.options[0].value;
                          e.paramElementFilledBool = true;
                          this.requestPayloadParameterObj.parameters[e.id] = e.state.options[0].value;
                          this.clientId = e.state.options[0].value;
                          if (this.clientId) {
                            this.parameterElementsArray.filter(e => {
                              if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroup') {
                                e.selectedData = [];
                                let updatedArray = [];
                                e.visible = true;
                                e.loading = true;
                                this.myRepoOutputService.getAdaSubgroupList(this.reportId, this.clientId).subscribe((response: any) => {
                                  e.loading = false;
                                  response?.body?.forEach((c: any) => {
                                    if (c.displayName !== null && c.value !== null) {
                                      updatedArray.push({
                                        label: c.displayName ? c.displayName : '',
                                        value: c.value,
                                        selected: false
                                      });
                                    }
                                  });
                                  e.state.options = updatedArray;
                                  e.state.options.forEach(d => { if (d.value.toLowerCase() == 'restricted') { this.restricted = true } })
                                    if (this.restricted) {
                                      e.mandatory = true;
                                      e.disabled = true;
                                      e.paramElementFilledBool = true;
                                      e.selectedData = e.state.options[0].value;
                                      this.SubGroupId=e.state.options[0].value;
                                      this.requestPayloadParameterObj.parameters[e.id] = e.state.options[0].value;
                                      this.parameterElementsArray.filter(e => {
                                        if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroupvalues') {
                                          // if (this.restricted) {
                                            e.visible = true;
                                            e.loading = true
                                            let updatedArray = [];
                                            const selectedValues = []
                                            e.selectedData = [];
                                            this.requestPayloadParameterObj.parameters[e.id] = [];
                                            this.myRepoOutputService.getAdaSubGroupValue(this.reportId, this.clientId, this.SubGroupId, this.SelectedRequestedPage).subscribe((response: any) => {
                                              e.loading = false;
                                              response?.body?.forEach(c => {
                                                if (c.displayName !== null && c.value !== null) {
                                                  updatedArray.push({
                                                    label: c.displayName ? c.displayName : '',
                                                    value: c.value,
                                                    selected: true
                                                  });
                                                }
                                                selectedValues.push(c.value)
                                              });
                                              e.state.options = updatedArray;
      
                                              e.mandatory = true;
                                              e.disabled = true;
                                              e.paramElementFilledBool = true;
                                              e.selectedData = selectedValues;
                                              this.requestPayloadParameterObj.parameters[e.id] = selectedValues;
                                            })
                                        }
                                      })
                                    }
                                })
                              }
                              if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroupvalues') { e.selectedData = []; e.visible = false; delete this.requestPayloadParameterObj.parameters[e.id] } else { e.visible = true }
                            })
                          }
                        })
                      }
                      if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroup' || e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroupvalues') { e.visible = false } else { e.visible = true }
                    }
                    if (this.SelectedRequestedPage == 'CD2') {
                      if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'clientnames') {
                        e.visible = true;
                        e.loading = true;
                        this.myRepoOutputService.getCD2Clients(this.reportId).subscribe((response: any) => {
                          e.loading = false;
                          response?.body?.forEach(c => {
                            c.value = c.clientCode;
                            delete c.clientCode;
                            c.label = c.clientName;
                            delete c.clientName;
                            c.selected = false;
                          })
                          e.state.options = response?.body;
                          e.selectedData = e.state.options[0].value;
                          e.paramElementFilledBool = true;
                          this.requestPayloadParameterObj.parameters[e.id] = e.state.options[0].value;
                          this.clientId = e.state.options[0].value;
                          if (this.clientId) {
                            this.parameterElementsArray.filter(e => {
                              if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroup') {
                                let updatedArray = [];
                                e.visible = true;
                                e.loading = true;
                                this.myRepoOutputService.getCD2Subgroup(this.reportId, this.clientId).subscribe((response: any) => {
                                  e.loading = false;
                                  response?.body?.forEach(c => {
                                    if (c.displayName !== null && c.value !== null) {
                                      c.label = c.displayName ? c.displayName : '',
                                        delete c.displayName;
                                      c.selected = false;
                                    }
                                  })
                                  e.state.options = response?.body;
                                  e.state.options.forEach(d => { if (d.value.toLowerCase() == 'restricted') { this.restricted = true } })
                                    if (this.restricted) {
                                      e.mandatory = true;
                                      e.disabled = true;
                                      e.paramElementFilledBool = true;
                                      e.selectedData = e.state.options[0].value;
                                      this.SubGroupId=e.state.options[0].value;
                                      this.requestPayloadParameterObj.parameters[e.id] = e.state.options[0].value;
                                      this.parameterElementsArray.filter(e => {
                                        if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroupvalues') {
                                          // if (this.restricted) {
                                            e.visible = true;
                                            e.loading = true
                                            let updatedArray = [];
                                            const selectedValues = []
                                            e.selectedData = [];
                                            this.requestPayloadParameterObj.parameters[e.id] = [];
                                            this.myRepoOutputService.getCD2SubgroupVlaue(this.reportId, this.clientId, this.SubGroupId, this.SelectedRequestedPage).subscribe((response: any) => {
                                              e.loading = false;
                                              response?.body?.forEach(c => {
                                                if (c.displayName !== null && c.value !== null) {
                                                  updatedArray.push({
                                                    label: c.displayName ? c.displayName : '',
                                                    value: c.value,
                                                    selected: true
                                                  });
                                                }
                                                selectedValues.push(c.value)
                                              });
                                              e.state.options = updatedArray;
      
                                              e.mandatory = true;
                                              e.disabled = true;
                                              e.paramElementFilledBool = true;
                                              e.selectedData = selectedValues;
                                              this.requestPayloadParameterObj.parameters[e.id] = selectedValues;
                                            })
                                        }
                                      })
                                    }
                                })
                              }
                              if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'loapolicy') {
                                let updatedArray = [];
                                e.visible = true;
                                e.loading = true;
                                this.myRepoOutputService.getCD2LoaPolicy(this.reportId, this.clientId).subscribe((response: any) => {
                                  e.loading = false;
                                  response?.body?.forEach((c: any) => {
                                    updatedArray.push({
                                      label: c,
                                      value: c,
                                      selected: false
                                    });
                                  });
                                  e.state.options = updatedArray;
                                })
                              }
                              if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'ltdpolicy') {
                                let updatedArray = [];
                                e.visible = true;
                                e.loading = true;
                                this.myRepoOutputService.getCD2LtdPolicy(this.reportId, this.clientId).subscribe((response: any) => {
                                  e.loading = false;
                                  response?.body?.forEach((c: any) => {
                                    updatedArray.push({
                                      label: c,
                                      value: c,
                                      selected: false
                                    });
                                  });
                                  e.state.options = updatedArray;
                                })
                              }
                              if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'stdpolicy') {
                                let updatedArray = [];
                                e.visible = true;
                                e.loading = true;
                                this.myRepoOutputService.getCD2StdPolicy(this.reportId, this.clientId).subscribe((response: any) => {
                                  e.loading = false;
                                  response?.body?.forEach((c: any) => {
                                    updatedArray.push({
                                      label: c,
                                      value: c,
                                      selected: false
                                    });
                                  });
                                  e.state.options = updatedArray;
                                })
                              }
                              if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'wcpolicy') {
                                let updatedArray = [];
                                e.visible = true;
                                e.loading = true;
                                this.myRepoOutputService.getCD2WcPolicy(this.reportId, this.clientId).subscribe((response: any) => {
                                  e.loading = false;
                                  response?.body?.forEach((c: any) => {
                                    updatedArray.push({
                                      label: c,
                                      value: c,
                                      selected: false
                                    });
                                  });
                                  e.state.options = updatedArray;
                                })
                              }
                            })
                          }
                        })
                      }
                      if (e.id.toLowerCase() == 'subgroup' || e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroupvalues' || e.id.toLowerCase().replace(/[_ ]/g, '') == 'stdpolicy' || e.id.toLowerCase().replace(/[_ ]/g, '') == 'loapolicy' || e.id.toLowerCase().replace(/[_ ]/g, '') == 'ltdpolicy' || e.id.toLowerCase().replace(/[_ ]/g, '') == 'wcpolicy') { e.visible = false } else { e.visible = true }
                    }
                    if (this.SelectedRequestedPage == 'CD3') {
                      if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'clientnames') {
                        e.visible = true;
                        e.loading = false;
                        this.myRepoOutputService.getCD3Clients(this.reportId).subscribe((response: any) => {
                          e.loading = false;
                          response?.body?.forEach(c => {
                            c.value = c.clientCode;
                            delete c.clientCode;
                            c.label = c.clientName;
                            delete c.clientName;
                            c.selected = false;
                          })
                          e.state.options = response?.body;
                          e.selectedData = e.state.options[0].value;
                          e.paramElementFilledBool = true;
                          this.requestPayloadParameterObj.parameters[e.id] = e.state.options[0].value;
                          this.clientId = e.state.options[0].value;
                          if (this.clientId) {
                            this.parameterElementsArray.filter(e => {
                              if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroup') {
                                let updatedArray = [];
                                e.visible = true;
                                e.loading = true;
                                this.myRepoOutputService.getCD3Subgroup(this.reportId, this.clientId).subscribe((response: any) => {
                                  e.loading = false;
                                  response?.body?.forEach(c => {
                                    if (c.displayName !== null && c.value !== null) {
                                      c.label = c.displayName ? c.displayName : '',
                                        delete c.displayName;
                                      c.selected = false;
                                    }
                                  })
                                  e.state.options = response?.body;
                                  e.state.options.forEach(d => { if (d.value.toLowerCase() == 'restricted') { this.restricted = true } })
                                    if (this.restricted) {
                                      e.mandatory = true;
                                      e.disabled = true;
                                      e.paramElementFilledBool = true;
                                      e.selectedData = e.state.options[0].value;
                                      this.SubGroupId=e.state.options[0].value;
                                      this.requestPayloadParameterObj.parameters[e.id] = e.state.options[0].value;
                                      this.parameterElementsArray.filter(e => {
                                        if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroupvalues') {
                                          // if (this.restricted) {
                                            e.visible = true;
                                            e.loading = true
                                            let updatedArray = [];
                                            const selectedValues = []
                                            e.selectedData = [];
                                            this.requestPayloadParameterObj.parameters[e.id] = [];
                                            this.myRepoOutputService.getCD3SubgroupVlaue(this.reportId, this.clientId, this.SubGroupId, this.SelectedRequestedPage).subscribe((response: any) => {
                                              e.loading = false;
                                              response?.body?.forEach(c => {
                                                if (c.displayName !== null && c.value !== null) {
                                                  updatedArray.push({
                                                    label: c.displayName ? c.displayName : '',
                                                    value: c.value,
                                                    selected: true
                                                  });
                                                }
                                                selectedValues.push(c.value)
                                              });
                                              e.state.options = updatedArray;
      
                                              e.mandatory = true;
                                              e.disabled = true;
                                              e.paramElementFilledBool = true;
                                              e.selectedData = selectedValues;
                                              this.requestPayloadParameterObj.parameters[e.id] = selectedValues;
                                            })
                                        }
                                      })
                                    }
                                })
                              }
                              if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'loapolicy') {
                                let updatedArray = [];
                                e.visible = true;
                                e.loading = true;
                                this.myRepoOutputService.getCD3LoaPolicy(this.reportId, this.clientId).subscribe((response: any) => {
                                  e.loading = false;
                                  response?.body?.forEach((c: any) => {
                                    updatedArray.push({
                                      label: c,
                                      value: c,
                                      selected: false
                                    });
                                  });
                                  e.state.options = updatedArray;
                                })
                              }
                              if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'ltdpolicy') {
                                let updatedArray = [];
                                e.visible = true;
                                e.loading = true;
                                this.myRepoOutputService.getCD3LtdPolicy(this.reportId, this.clientId).subscribe((response: any) => {
                                  e.loading = false;
                                  response?.body?.forEach((c: any) => {
                                    updatedArray.push({
                                      label: c,
                                      value: c,
                                      selected: false
                                    });
                                  });
                                  e.state.options = updatedArray;
                                })
                              }
                              if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'stdpolicy') {
                                let updatedArray = [];
                                e.visible = true;
                                e.loading = true;
                                this.myRepoOutputService.getCD3StdPolicy(this.reportId, this.clientId).subscribe((response: any) => {
                                  e.loading = false;
                                  response?.body?.forEach((c: any) => {
                                    updatedArray.push({
                                      label: c,
                                      value: c,
                                      selected: false
                                    });
                                  });
                                  e.state.options = updatedArray;
                                })
                              }
                              if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'wcpolicy') {
                                let updatedArray = [];
                                e.visible = true;
                                e.loading = true;
                                this.myRepoOutputService.getCD3WcPolicy(this.reportId, this.clientId).subscribe((response: any) => {
                                  e.loading = false;
                                  response?.body?.forEach((c: any) => {
                                    updatedArray.push({
                                      label: c,
                                      value: c,
                                      selected: false
                                    });
                                  });
                                  e.state.options = updatedArray;
                                })
                              }
                            })
                          }
                        })
                      }
                      if (e.id.toLowerCase() == 'subgroup' || e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroupvalues' || e.id.toLowerCase().replace(/[_ ]/g, '') == 'stdpolicy' || e.id.toLowerCase().replace(/[_ ]/g, '') == 'loapolicy' || e.id.toLowerCase().replace(/[_ ]/g, '') == 'ltdpolicy' || e.id.toLowerCase().replace(/[_ ]/g, '') == 'wcpolicy') { e.visible = false } else { e.visible = true }
                    }
                    if (this.SelectedRequestedPage == 'PACS') {
                      if (e.id.toLowerCase().replace(' ', '').replace(/[_ ]/g, '') == 'clients') {
                        e.visible = true;
                        e.loading = true;
                        this.myRepoOutputService.getClientNamesList(true, this.reportId).subscribe((response: any) => {
                          e.loading = false;
                          response?.body?.forEach(c => {
                            c.value = c.clientCode;
                            delete c.clientCode;
                            c.label = c.clientName;
                            delete c.clientName;
                            c.selected = false;
                          })
                          e.state.options = response?.body;
                          e.selectedData = e.state.options[0].value;
                          e.paramElementFilledBool = true;
                          this.requestPayloadParameterObj.parameters[e.id] = e.state.options[0].value;
                          this.clientId = e.state.options[0].value;
                          if (this.clientId) {
                            // this.myRepoOutputService.getSubgroupList(this.reportId, this.clientId).subscribe((response: any) => {
                            //   response?.body.forEach(d => { if (d.value.toLowerCase() == 'restricted') { this.restricted = true } })
                            // })
                            this.parameterElementsArray.forEach(e => {
                              if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'lineofbusiness') {
                                e.selectedData = [];
                                this.requestPayloadParameterObj.parameters[e.id] = '';
                                e.state.options = [];
                                e.visible = true;
                                e.loading = true
                                this.clientFlag = "C";
                                this.parentFlag = "Y";
                                let updatedArray = [];
                                this.myRepoOutputService.getLOBList(this.reportId, this.clientId, this.clientFlag, this.parentFlag).subscribe((response: any) => {
                                  e.loading = false;
                                  e.visible
                                  response?.body?.forEach((c: any) => {
                                    updatedArray.push({
                                      label: c,
                                      value: c,
                                      selected: false
                                    });
                                  });
                                  e.state.options = updatedArray;
                                })//e.id.toLowerCase() == 'policy'||e.id.toLowerCase() == 'class'||e.id.toLowerCase() == 'plan type'||e.id.toLowerCase()=='policy type'
                              }
                              if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroup') {
                                e.selectedData = [];
                                this.requestPayloadParameterObj.parameters[e.id] = '';
                                e.state.options = [];
                                e.visible = true;
                                e.loading = true
                                this.myRepoOutputService.getSubgroupList(this.reportId, this.clientId).subscribe((response: any) => {
                                  e.loading = false;
                                  response?.body?.forEach(c => {
                                    if (c.displayName !== null && c.value !== null) {
                                      c.label = c.displayName ? c.displayName : '',
                                        delete c.displayName;
                                      c.value = c.value;
                                      c.selected = false;
                                    }

                                  })
                                  e.state.options = response?.body;
                                  e.state.options.forEach(d => { if (d.value.toLowerCase() == 'restricted') { this.restricted = true } })
                                  if (this.restricted) {
                                    e.mandatory = true;
                                    e.disabled = true;
                                    e.paramElementFilledBool = true;
                                    e.selectedData = e.state.options[0].value;
                                    this.SubGroupId=e.state.options[0].value;
                                    this.requestPayloadParameterObj.parameters[e.id] = e.state.options[0].value;
                                    this.parameterElementsArray.filter(e => {
                                      if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroupvalues') {
                                        // if (this.restricted) {
                                          e.visible = true;
                                          e.loading = true
                                          let updatedArray = [];
                                          const selectedValues = []
                                          e.selectedData = [];
                                          this.requestPayloadParameterObj.parameters[e.id] = [];
                                          this.myRepoOutputService.getSubGroupValue(this.reportId, this.clientId, this.SubGroupId, this.SelectedRequestedPage).subscribe((response: any) => {
                                            e.loading = false;
                                            response?.body?.forEach(c => {
                                              if (c.displayName !== null && c.value !== null) {
                                                updatedArray.push({
                                                  label: c.displayName ? c.displayName : '',
                                                  value: c.value,
                                                  selected: true
                                                });
                                              }
                                              selectedValues.push(c.value)
                                            });
                                            e.state.options = updatedArray;

                                            e.mandatory = true;
                                            e.disabled = true;
                                            e.paramElementFilledBool = true;
                                            e.selectedData = selectedValues;
                                            this.requestPayloadParameterObj.parameters[e.id] = selectedValues;
                                          })
                                      }
                                    })
                                  }
                                })
                              }
                            })
                          }
                        })
                      }
                      if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'policies') {
                        // if (e.state.options[0].value == "Policy") { e.state.options[0].value = ["P", "o", "l", "i", "c", "y"] };
                        // if (e.state.options[1].value == "Policy Type") { e.state.options[1].value = ["P", "o", "l", "i", "c", "y", " ", "T", "p", "e"] }
                      }
                      if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'policy' || e.id.toLowerCase().replace(/[_ ]/g, '') == 'class' || e.id.toLowerCase().replace(/[_ ]/g, '') == 'plantype' || e.id.toLowerCase().replace(/[_ ]/g, '') == 'policytype') { e.visible = false } else { e.visible = true }
                      if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroupvalues') {
                        if (this.restricted) {
                          e.visible = true;
                        }
                        else {
                          e.visible = false
                        }
                      }
                    }
                    if (this.SelectedRequestedPage == 'WC') {
                      if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'clients') {
                        e.visible = true;
                        e.loading = true;
                        this.myRepoOutputService.getWcClient(this.reportId).subscribe((response: any) => {
                          e.loading = false;
                          response?.body?.forEach(c => {
                            c.value = c.clientCode;
                            delete c.clientCode;
                            c.label = c.clientName;
                            delete c.clientName;
                            c.selected = false;
                          })
                          e.state.options = response?.body;
                          e.selectedData = e.state.options[0].value;
                          e.paramElementFilledBool = true;
                          this.requestPayloadParameterObj.parameters[e.id] = e.state.options[0].value;
                          this.clientId = e.state.options[0].value;
                          // if(this.clientId){
                          this.parameterElementsArray.filter(e => {
                            if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'runreportby') {
                              e.selectedData = [];
                              e.visible = true;
                              e.loading = true;
                              let updatedArray = []
                              this.myRepoOutputService.getRunByOptions(this.reportId).subscribe((response: any) => {
                                e.loading = false;
                                response?.body?.forEach((c: any) => {
                                  updatedArray.push({
                                    label: c,
                                    value: c,
                                    selected: false
                                  });
                                });
                                e.state.options = updatedArray;
                              })

                            }
                          })
                          // }
                        })

                      }
                      if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroup' || e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroupvalues' || e.id.toLowerCase().replace(/[_ ]/g, '') == 'policies' || e.id.toLowerCase().replace(/[_ ]/g, '') == 'sitesbenefitstates') { e.visible = false } else { e.visible = true }
                    }
                    if (this.SelectedRequestedPage == 'WC_All') {
                      if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'clients') {
                        e.visible = true;
                        e.loading = true;
                        this.myRepoOutputService.getWcClient(this.reportId).subscribe((response: any) => {
                          e.loading = false;
                          response?.body?.forEach(c => {
                            c.value = c.clientCode;
                            delete c.clientCode;
                            c.label = c.clientName;
                            delete c.clientName;
                            c.selected = false;
                          })
                          e.state.options = response?.body;
                          e.selectedData = e.state.options[0].value;
                          e.paramElementFilledBool = true;
                          this.requestPayloadParameterObj.parameters[e.id] = e.state.options[0].value;
                          this.clientId = e.state.options[0].value;
                          // if(this.clientId){}
                        })

                      }
                      if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroup' || e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroupvalues' || e.id.toLowerCase().replace(/[_ ]/g, '') == 'policies') { e.visible = false } else { e.visible = true }
                    }
                    if (this.SelectedRequestedPage == 'BCD') {
                      if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'clientnames') {
                        e.visible = true;
                        e.loading = true;
                        this.myRepoOutputService.getBCDclient(this.reportId).subscribe((response: any) => {
                          e.loading = false;
                          response?.body?.forEach(c => {
                            c.value = c.clientCode;
                            delete c.clientCode;
                            c.label = c.clientName;
                            delete c.clientName;
                            c.selected = false;
                          })
                          e.state.options = response?.body;
                          e.selectedData = e.state.options[0].value;
                          e.paramElementFilledBool = true;
                          this.requestPayloadParameterObj.parameters[e.id] = e.state.options[0].value;
                          this.clientId = e.state.options[0].value;
                          if (this.clientId) {
                            this.parameterElementsArray.filter(e => {
                              if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroup') {
                                let updatedArray = [];
                                e.visible = true;
                                e.loading = true;
                                this.myRepoOutputService.getBCDsubgroup(this.clientId, this.reportId).subscribe((response: any) => {
                                  e.loading = false;
                                  response?.body?.forEach(c => {
                                    if (c.displayName !== null && c.value !== null) {
                                      c.label = c.displayName ? c.displayName : '',
                                        delete c.displayName;
                                      c.selected = false;
                                    }
                                  })
                                  e.state.options = response?.body;
                                  e.state.options.forEach(d => { if (d.value.toLowerCase() == 'restricted') { this.restricted = true } })
                                    if (this.restricted) {
                                      e.mandatory = true;
                                      e.disabled = true;
                                      e.paramElementFilledBool = true;
                                      e.selectedData = e.state.options[0].value;
                                      this.SubGroupId=e.state.options[0].value;
                                      this.requestPayloadParameterObj.parameters[e.id] = e.state.options[0].value;
                                      this.parameterElementsArray.filter(e => {
                                        if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroupvalues') {
                                          // if (this.restricted) {
                                            e.visible = true;
                                            e.loading = true
                                            let updatedArray = [];
                                            const selectedValues = []
                                            e.selectedData = [];
                                            this.requestPayloadParameterObj.parameters[e.id] = [];
                                            this.myRepoOutputService.getBCDsubgroupValues(this.reportId, this.clientId, this.SubGroupId).subscribe((response: any) => {
                                              e.loading = false;
                                              response?.body?.forEach(c => {
                                                if (c.displayName !== null && c.value !== null) {
                                                  updatedArray.push({
                                                    label: c.displayName ? c.displayName : '',
                                                    value: c.value,
                                                    selected: true
                                                  });
                                                }
                                                selectedValues.push(c.value)
                                              });
                                              e.state.options = updatedArray;
      
                                              e.mandatory = true;
                                              e.disabled = true;
                                              e.paramElementFilledBool = true;
                                              e.selectedData = selectedValues;
                                              this.requestPayloadParameterObj.parameters[e.id] = selectedValues;
                                            })
                                        }
                                      })
                                    }
                                })
                              }
                              if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'loapolicy') {
                                let updatedArray = [];
                                e.visible = true;
                                e.loading = true;
                                this.myRepoOutputService.getBcdLOApolicy(this.clientId, this.reportId).subscribe((response: any) => {
                                  e.loading = false;
                                  response?.body?.forEach((c: any) => {
                                    updatedArray.push({
                                      label: c,
                                      value: c,
                                      selected: false
                                    });
                                  });
                                  e.state.options = updatedArray;
                                })
                              }
                              if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'ltdpolicy') {
                                let updatedArray = [];
                                e.visible = true;
                                e.loading = true;
                                this.myRepoOutputService.getBcdLTDpolicy(this.clientId, this.reportId).subscribe((response: any) => {
                                  e.loading = false;
                                  response?.body?.forEach((c: any) => {
                                    updatedArray.push({
                                      label: c,
                                      value: c,
                                      selected: false
                                    });
                                  });
                                  e.state.options = updatedArray;
                                })
                              }
                              if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'stdpolicy') {
                                let updatedArray = [];
                                e.visible = true;
                                e.loading = true;
                                this.myRepoOutputService.getBcdSTDpolicy(this.clientId, this.reportId).subscribe((response: any) => {
                                  e.loading = false;
                                  response?.body?.forEach((c: any) => {
                                    updatedArray.push({
                                      label: c,
                                      value: c,
                                      selected: false
                                    });
                                  });
                                  e.state.options = updatedArray;
                                })
                              }
                              if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'wcpolicy') {
                                let updatedArray = [];
                                e.visible = true;
                                e.loading = true;
                                this.myRepoOutputService.getBcdWCpolicy(this.clientId, this.reportId).subscribe((response: any) => {
                                  e.loading = false;
                                  response?.body?.forEach((c: any) => {
                                    updatedArray.push({
                                      label: c,
                                      value: c,
                                      selected: false
                                    });
                                  });
                                  e.state.options = updatedArray;
                                })
                              }
                            })
                          }
                        })
                      }
                      if (e.id.toLowerCase() == 'subgroup' || e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroupvalues' || e.id.toLowerCase().replace(/[_ ]/g, '') == 'stdpolicy' || e.id.toLowerCase().replace(/[_ ]/g, '') == 'loapolicy' || e.id.toLowerCase().replace(/[_ ]/g, '') == 'ltdpolicy' || e.id.toLowerCase().replace(/[_ ]/g, '') == 'wcpolicy') { e.visible = false } else { e.visible = true }
                    }
                    if (this.SelectedRequestedPage == 'PCR') {
                      if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'clientcode') {
                        e.visible = true;
                        e.loading = true;
                        this.myRepoOutputService.getPCRclient(this.reportId).subscribe((response: any) => {
                          e.loading = false;
                          response?.body?.forEach(c => {
                            c.value = c.clientCode;
                            delete c.clientCode;
                            c.label = c.clientName;
                            delete c.clientName;
                            c.selected = false;
                          })
                          e.state.options = response?.body;
                          e.selectedData = e.state.options[0].value;
                          e.paramElementFilledBool = true;
                          this.requestPayloadParameterObj.parameters[e.id] = e.state.options[0].value;
                          this.clientId = e.state.options[0].value;
                          if (this.clientId) {
                            this.parameterElementsArray.filter(e => {
                              if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'lineofbusiness') {
                                e.selectedData = [];
                                this.requestPayloadParameterObj.parameters[e.id] = '';
                                e.state.options = [];
                                e.visible = true;
                                e.loading = true
                                this.clientFlag = "C";
                                this.parentFlag = "Y";
                                let updatedArray = [];
                                this.myRepoOutputService.getPcrLOBList(this.reportId, this.clientId, this.clientFlag, this.parentFlag).subscribe((response: any) => {
                                  e.loading = false;
                                  e.visible
                                  response?.body?.forEach((c: any) => {
                                    updatedArray.push({
                                      label: c,
                                      value: c,
                                      selected: false
                                    });
                                  });
                                  e.state.options = updatedArray;
                                })
                              }
                              if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'bankaccountnumber') {
                                e.selectedData = []; e.visible = false; delete this.requestPayloadParameterObj.parameters[e.id]
                              }
                            })
                          }
                        })
                      }
                      if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'lineofbusiness' || e.id.toLowerCase().replace(/[_ ]/g, '') == 'bankaccountnumber') { e.visible = false } else { e.visible = true }
                    }
                    if (this.SelectedRequestedPage == 'SC') {
                      if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'clientnames') {
                        e.visible = true;
                        e.loading = true;
                        this.myRepoOutputService.getSCclient(this.reportId).subscribe((response: any) => {
                          e.loading = false;
                          response?.body?.forEach(c => {
                            c.value = c.clientCode;
                            delete c.clientCode;
                            c.label = c.clientName;
                            delete c.clientName;
                            c.selected = false;
                          })
                          e.state.options = response?.body;
                          e.selectedData = e.state.options[0].value;
                          e.paramElementFilledBool = true;
                          this.requestPayloadParameterObj.parameters[e.id] = e.state.options[0].value;
                          this.clientId = e.state.options[0].value;
                          if (this.clientId) {
                            this.parameterElementsArray.filter(e => {
                              if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroup') {
                                let updatedArray = [];
                                e.visible = true;
                                e.loading = true;
                                this.myRepoOutputService.getSCsubgroup(this.clientId, this.reportId).subscribe((response: any) => {
                                  e.loading = false;
                                  response?.body?.forEach(c => {
                                    if (c.displayName !== null && c.value !== null) {
                                      c.label = c.displayName ? c.displayName : '',
                                        delete c.displayName;
                                      c.selected = false;
                                    }
                                  })

                                  e.state.options = response?.body;
                                  e.state.options.forEach(d => { if (d.value.toLowerCase() == 'restricted') { this.restricted = true } })
                                    if (this.restricted) {
                                      e.mandatory = true;
                                      e.disabled = true;
                                      e.paramElementFilledBool = true;
                                      e.selectedData = e.state.options[0].value;
                                      this.SubGroupId=e.state.options[0].value;
                                      this.requestPayloadParameterObj.parameters[e.id] = e.state.options[0].value;
                                      this.parameterElementsArray.filter(e => {
                                        if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroupvalues') {
                                          // if (this.restricted) {
                                            e.visible = true;
                                            e.loading = true
                                            let updatedArray = [];
                                            const selectedValues = []
                                            e.selectedData = [];
                                            this.requestPayloadParameterObj.parameters[e.id] = [];
                                            this.myRepoOutputService.getSCsubgroupValues(this.reportId, this.clientId, this.SubGroupId).subscribe((response: any) => {
                                              e.loading = false;
                                              response?.body?.forEach(c => {
                                                if (c.displayName !== null && c.value !== null) {
                                                  updatedArray.push({
                                                    label: c.displayName ? c.displayName : '',
                                                    value: c.value,
                                                    selected: true
                                                  });
                                                }
                                                selectedValues.push(c.value)
                                              });
                                              e.state.options = updatedArray;
      
                                              e.mandatory = true;
                                              e.disabled = true;
                                              e.paramElementFilledBool = true;
                                              e.selectedData = selectedValues;
                                              this.requestPayloadParameterObj.parameters[e.id] = selectedValues;
                                            })
                                        }
                                      })
                                    }
                                })
                              }
                              if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'loapolicy') {
                                let updatedArray = [];
                                e.visible = true;
                                e.loading = true;
                                this.myRepoOutputService.getScLOApolicy(this.clientId, this.reportId).subscribe((response: any) => {
                                  e.loading = false;
                                  response?.body?.forEach((c: any) => {
                                    updatedArray.push({
                                      label: c,
                                      value: c,
                                      selected: false
                                    });
                                  });
                                  e.state.options = updatedArray;
                                })
                              }
                              if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'ltdpolicy') {
                                let updatedArray = [];
                                e.visible = true;
                                e.loading = true;
                                this.myRepoOutputService.getScLTDpolicy(this.clientId, this.reportId).subscribe((response: any) => {
                                  e.loading = false;
                                  response?.body?.forEach((c: any) => {
                                    if (c.displayName !== null && c.value !== null) {
                                      updatedArray.push({
                                        label: c.displayName ? c.displayName : '',
                                        value: c.value,
                                        selected: false
                                      });
                                    }
                                  });
                                  e.state.options = updatedArray;
                                })
                              }
                              if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'stdpolicy') {
                                let updatedArray = [];
                                e.visible = true;
                                e.loading = true;
                                this.myRepoOutputService.getScSTDpolicy(this.clientId, this.reportId).subscribe((response: any) => {
                                  e.loading = false;
                                  response?.body?.forEach((c: any) => {
                                    if (c.displayName !== null && c.value !== null) {
                                      updatedArray.push({
                                        label: c.displayName ? c.displayName : '',
                                        value: c.value,
                                        selected: false
                                      });
                                    }
                                  });
                                  e.state.options = updatedArray;
                                })
                              }
                            })
                          }
                        })
                      }
                      if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'loapolicy' || e.id.toLowerCase().replace(/[_ ]/g, '') == 'ltdpolicy' || e.id.toLowerCase().replace(/[_ ]/g, '') == 'stdpolicy' || e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroup' || e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroupvalues') { e.visible = false } else { e.visible = true }
                    }
                    if (this.SelectedRequestedPage == 'PACS_All_Vendors2') {
                      if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'clients') {
                        e.visible = true;
                        e.loading = true;
                        this.myRepoOutputService.getPACSV2client(this.reportId).subscribe((response: any) => {
                          e.loading = false;
                          response?.body?.forEach(c => {
                            c.value = c.clientCode;
                            delete c.clientCode;
                            c.label = c.clientName;
                            delete c.clientName;
                            c.selected = false;
                          })
                          e.state.options = response?.body;
                          e.selectedData = e.state.options[0].value;
                          e.paramElementFilledBool = true;
                          this.requestPayloadParameterObj.parameters[e.id] = e.state.options[0].value;
                          this.clientId = e.state.options[0].value;
                          if (this.clientId) {
                            this.parameterElementsArray.filter(e => {
                              if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'policy') {
                                let updatedArray = [];
                                e.visible = true;
                                e.loading = true;
                                this.myRepoOutputService.getPACSV2policyt(this.clientId, this.reportId).subscribe((response: any) => {
                                  e.loading = false;
                                  response?.body?.forEach((c: any) => {
                                    updatedArray.push({
                                      label: c.policyNumber + " - " + c.policyType + " - " + c.effectiveDate + " - " + c.expiryDate,
                                      value: c.policyNumber + "|" + c.policyBON,
                                      selected: false
                                    });
                                  });
                                  e.state.options = updatedArray;
                                })
                              }
                            })
                          }
                        })
                      }
                      if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'policy') { e.visible = false } else { e.visible = true }
                    }
                  });
                  response.exportType.forEach((expType) => {
                    this.downloadTypeListArray.push({
                      label: expType.toLowerCase(),
                      command: (event) => {
                        this.downloadReportFunc(event);
                      },
                    });

                    if (expType.toLowerCase() == 'xlsx') {
                      this.downloadTypeListArray.push({
                        label: 'Save xlsx to Inbox',
                        command: (event) => {
                          this.downloadReportFunc(event);
                        },
                      });
                    } else if (expType.toLowerCase() == 'pdf') {
                      this.downloadTypeListArray.push({
                        label: 'Save pdf to Inbox',
                        command: (event) => {
                          this.downloadReportFunc(event);
                        },
                      });
                    } else if (expType.toLowerCase() == 'csv') {
                      this.downloadTypeListArray.push({
                        label: 'Save csv to Inbox',
                        command: (event) => {
                          this.downloadReportFunc(event);
                        },
                      });
                    }
                  });
                  this.downloadTypeList = this.downloadTypeListArray;
                  for (
                    let i = 0;
                    i < response.reportInputControl.length;
                    i++
                  ) {
                    if (response.reportInputControl[i].visible) {
                      hasParameter = true;
                      break;
                    }
                  }

                  if (!hasParameter) {
                    d3.select('#myrepo-output-parameters-panel').style(
                      'display',
                      'none'
                    );
                    d3.select('#myrepo-output-container')
                      .style('left', '0px')
                      .style('width', '100%');
                    this.getReportDataFunc();
                  }

                }
              },
              (error) => {
                this.alertSeverity = 'error';
                if (Number(error.status) == 401) {
                  this.alertMsg = 'Please Try After Some Time!';
                } else if (Number(error.status) == 500) {
                  this.alertMsg = 'Internal Error';
                } else {
                  this.alertMsg = 'Please Try After Some Time.';
                }
                this.addSingle();
              },
              () => { }
            );
        }
      // }
    // );
  }
  callMatric(){
    console.log(this.reportName)
      this.reportParamSerSubscrip = this.myRepoOutputService
    .reportParameterService(this.reportId)
    .subscribe(
      (response: any) => {
        this.showSideBar = false;
        this.docName='';
        this.paginatorPageNo=1;
        d3.select('#myrepo-output-parameters-panel').style('display', 'none');
        d3.select('#myrepo-output-container')
          .style('left', '0px')
          .style('width', '100%');
        this.isLoad = true;
        response.body?.reportInputControl.filter(e=>
          {
            if(e.id.toLowerCase().replace(/[_ ]/g, '') == 'clicode'){
              // e.selectedData = thi;
              // e.paramElementFilledBool = true;
              this.requestPayloadParameterObj.parameters[e.id] = this.matrixClientData;
            }
            if(e.id.toLowerCase().replace(/[_ ]/g, '') == 'accommodationid'){
              // e.selectedData = e.state.options[0].value;
              // e.paramElementFilledBool = true;
              this.requestPayloadParameterObj.parameters[e.id] = this.matrixClientAccId;
            }
        })
        response.body.exportType.forEach((expType) => {
          this.downloadTypeListArray.push({
            label: expType.toLowerCase(),
            command: (event) => {
              this.downloadReportFunc(event);
            },
          });

          if (expType.toLowerCase() == 'xlsx') {
            this.downloadTypeListArray.push({
              label: 'Save xlsx to Inbox',
              command: (event) => {
                this.downloadReportFunc(event);
              },
            });
          } else if (expType.toLowerCase() == 'pdf') {
            this.downloadTypeListArray.push({
              label: 'Save pdf to Inbox',
              command: (event) => {
                this.downloadReportFunc(event);
              },
            });
          } else if (expType.toLowerCase() == 'csv') {
            this.downloadTypeListArray.push({
              label: 'Save csv to Inbox',
              command: (event) => {
                this.downloadReportFunc(event);
              },
            });
          }
        });
        this.downloadTypeList = this.downloadTypeListArray;
        setTimeout(() => {
          this.onFilterSubmitClick();
        }, 1000);
      });
  }
  showFilterFields() {
    this.showFilterFieldsBoo = !this.showFilterFieldsBoo;
    if (!this.coldata) {
      if (this.showFilterFieldsBoo) {
        this.tableReduceHeight = 449;
        this.filterToolTip = 'Hide';
      } else {
        this.tableReduceHeight = 322;
        this.filterToolTip = 'Show';
      }
    } else {
      if (this.showFilterFieldsBoo) {
        this.tableReduceHeight = 420;
        this.filterToolTip = 'Hide';
      } else {
        this.tableReduceHeight = 305;
        this.filterToolTip = 'Show';
      }
    }
  }
  ngOnDestroy() {
    if (this.reportIdSubscrip) {
      this.reportIdSubscrip.unsubscribe();
    }
    if (this.reportParamSerSubscrip) {
      this.reportParamSerSubscrip.unsubscribe();
    }
    if (this.getReportSerSubscrip) {
      this.getReportSerSubscrip.unsubscribe();
    }
  }
  triggerGlobalFilter(enteredValue: string) {
    // const globalFilterFields = this.getGlobalFilterFields();
    this.table.filterGlobal(enteredValue, 'startsWith');
  }
  ManualChange(event, refEl, index, id) {
    //console.log(event, refEl)
    var obj =
      this.parameterElementsArray[
      Number(refEl.el.nativeElement.attributes.selectInfoIndex.value)
      ];

    obj.paramElementFilledBool = false;
    this.requestPayloadParameterObj.parameters[obj.id] = '';
    if(this.paramDates.length>0){
    if (this.paramDates[0].id == id) {
      this.paramDates[0].selectedData[0] = "";
    }
    if (this.paramDates[1].id == id) {
      this.paramDates[1].selectedData[0] = "";
    }
  }
    if (event != null && event != '') {
      this.onChange(event, refEl, index)
    }
  }
  onChange(event, refEl, index) {
    //console.log(event, refEl)
    var value1: any = '';
    if (refEl != undefined) {
      if (refEl?.el?.nativeElement.nodeName == 'P-CALENDAR') {
        value1 =
          ('0' + (event.getMonth() + 1)).slice(-2) +
          '/' +
          ('0' + event.getDate()).slice(-2) +
          '/' +
          event.getFullYear();
        var obj =
          this.parameterElementsArray[
          Number(refEl.el.nativeElement.attributes.selectInfoIndex.value)
          ];

        obj.paramElementFilledBool = true;
        obj.selectedData.push(event);

        this.requestPayloadParameterObj.parameters[obj.id] = value1;
      } else if (refEl?.el?.nativeElement.nodeName == 'P-MULTISELECT') {
        var obj =
          this.parameterElementsArray[
          Number(refEl.el.nativeElement.attributes.selectInfoIndex.value)
          ];

        if (event.value.length == 0) {
          obj.paramElementFilledBool = false;
        } else {
          obj.paramElementFilledBool = true;
        }

        this.requestPayloadParameterObj.parameters[obj.id] = event.value;
      } else if (refEl?.el?.nativeElement.nodeName == 'P-DROPDOWN') {
        // let value1="";
        value1 = event.value;
        var obj =
          this.parameterElementsArray[
          Number(refEl?.el?.nativeElement.attributes.selectInfoIndex.value)
          ];

        obj.paramElementFilledBool = true;

        this.requestPayloadParameterObj.parameters[obj.id] = value1;
      }
      else if (refEl.inputViewChild.nativeElement.nodeName == 'INPUT') {
        value1 = refEl.value;
        var obj = this.parameterElementsArray[index];
        obj.paramElementFilledBool = true;
        this.requestPayloadParameterObj.parameters[obj.id] = value1;
      }
      else if (refEl.inputViewChild.nativeElement.nodeName == 'COMBOBOX') {
        value1 = event.value;
        var obj =
          this.parameterElementsArray[
          Number(refEl.el.nativeElement.attributes.selectInfoIndex.value)
          ];

        obj.paramElementFilledBool = true;

        this.requestPayloadParameterObj.parameters[obj.id] = value1;
      }
      console.log(this.requestPayloadParameterObj.parameters, this.parameterElementsArray)
    } else if (refEl == undefined) {
      value1 = event.target?event.target.value:event;
      var obj =event.target?
        this.parameterElementsArray[
        Number(event.target.attributes.selectInfoIndex.value)
        ]: this.parameterElementsArray[index];
        obj.selectedData=value1;
      // if (obj.type == 'singleValueText') {
        if (value1!='') {
          obj.paramElementFilledBool = true;
        } else {
          obj.paramElementFilledBool = false;
        }
      // }

      this.requestPayloadParameterObj.parameters[obj.id] = value1;
      //console.log(this.requestPayloadParameterObj.parameters)
    }
    this.parameterElementsArray.filter(e => {
      if (e.id == obj.id) {
        if (e.slaveDependencies.length > 0) {
          for (let i = 0; i < e.slaveDependencies.length; i++) {
            this.payload[e.slaveDependencies[i]] = '';
          }

          this.cascadeBool = true;
        }
        else {
          this.cascadeBool = false;
        }
      }
    });
    if (this.cascadeBool) {
     
      for (let k in this.payload) {
        if (k == obj.id) {
          this.payload[k] = value1;
        }

      }
      this.parameterElementsArray.forEach(e => {
        if (e.id == obj.id) {
          this.payData = e.slaveDependencies.join(';')
        }
      })
      let idNames = this.payData.split(';')
      this.parameterElementsArray.forEach(item => {
        if (idNames.includes(item.id)) {
          item.loading = true;
          item.selectedData = (item.type=='multiSelect' ||item.type=='singleValueDate')?[]:'';
          item.paramElementFilledBool=false;
        }
      });
      this.myRepoOutputService.cascadeParameterService(this.reportId, this.payData, this.payload).subscribe((res: any) => {
        this.parameterElementsArray.forEach(e => {
          if (idNames.includes(e.id)) {
            this.requestPayloadParameterObj.parameters[e.id]=e.selectedData;
            // if(e.id == e.slaveDependencies[0]){
            res?.body?.records.filter(element => {
              if (element.id == e.id) {
                element.options?.forEach((el, i) => {
                  if (el['value'] == '' || el['value'] == 'empty' || el['value'] == ""|| el['value'] == null) {
                    element.options.splice(i, 1);
                  }
                })
                e.state.options = element.options;
                e.loading = false;

              }

            });
          }
        })
      })
    }
    if (this.SelectedRequestedPage == 'LOA') {
      if (this.parameterElementsArray[index].id.toLowerCase().replace(/[_ ]/g, '') == 'clients') {
        let dat = this.parameterElementsArray[index].state.options.filter((t) => t.value === event.value)
        this.clientId = dat[0].value;
        this.parameterElementsArray.filter(e => {
             if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroup' || e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroupvalues') {
            e.selectedData = []; e.visible = false;  e.paramElementFilledBool=false;delete this.requestPayloadParameterObj.parameters[e.id]; } 

        })
        if (this.runReport != '') {
          if (this.runReport.toLowerCase().replace(/[_ ]/g, '') == 'employeename') {
            this.parameterElementsArray.filter(e => {
              if (e.id.toLowerCase() == 'subgroup') { e.selectedData = []; e.visible = false;  e.paramElementFilledBool=false;delete this.requestPayloadParameterObj.parameters[e.id]; }
              if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroupvalues') { e.selectedData = []; e.visible = false;  e.paramElementFilledBool=false;delete this.requestPayloadParameterObj.parameters[e.id];; }
            })
          }
          if (this.runReport.toLowerCase() == 'subgroup') {
            this.isLoading2 = true;
            this.parameterElementsArray.filter(e => {
              if (e.id.toLowerCase() == 'subgroup') {
                let updatedArray = []
                e.visible = true;
                e.loading = true;
                this.myRepoOutputService.getSubgroupList(this.reportId, this.clientId).subscribe((response: any) => {
                  e.loading = false;
                  response?.body?.forEach((c: any) => {
                    if (c.displayName !== null && c.value !== null) {
                      updatedArray.push({
                        label: c.displayName ? c.displayName : '',
                        value: c.value,
                        selected: false
                      });
                    }
                  });
                  e.state.options = updatedArray;
                })
              }
            })
          }
        }
      }
      let dat = this.parameterElementsArray[index].state.options.filter((t) => t.value === event.value)
      if (dat[0]?.value.toLowerCase().replace(/[_ ]/g, '') == 'employeename') {
        this.runReport = dat[0]?.value;
        this.parameterElementsArray.filter(e => {
          if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroup') {
            if (this.restricted) {
              let updatedArray = []
              e.visible = true;
              e.loading = true;
              this.myRepoOutputService.getSubgroupList(this.reportId, this.clientId).subscribe((response: any) => {
                e.loading = false;
                response?.body?.forEach((c: any) => {
                  if (c.displayName !== null && c.value !== null) {
                    updatedArray.push({
                      label: c.displayName ? c.displayName : '',
                      value: c.value,
                      selected: false
                    });
                  }
                });
                e.state.options = updatedArray;
                e.mandatory = true;
                e.disabled = true;
                e.paramElementFilledBool = true;
                e.selectedData = e.state.options[0].value;
                this.requestPayloadParameterObj.parameters[e.id] = e.state.options[0].value;
              })
            }
            else {
              e.selectedData = []; e.visible = false; delete this.requestPayloadParameterObj.parameters[e.id]
            }
          }
          if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroupvalues') {
            if (this.restricted) {
              e.visible = true;
              e.loading = true;
              let updatedArray = [];
              const selectedValues = [];
              this.myRepoOutputService.getSubGroupValue(this.reportId, this.clientId, this.SubGroupId, this.SelectedRequestedPage).subscribe((response: any) => {
                e.loading = false;
                response?.body?.forEach((c: any) => {
                  if (c.displayName !== null && c.value !== null) {
                    updatedArray.push({
                      label: c.displayName ? c.displayName : '',
                      value: c.value,
                      selected: true
                    });
                  }
                  selectedValues.push(c.value);
                });
                e.state.options = updatedArray;
                e.mandatory = true;
                e.paramElementFilledBool = true;
                e.selectedData = selectedValues;
                this.requestPayloadParameterObj.parameters[e.id] = selectedValues;
              })
            }
            else {
              e.selectedData = []; e.visible = false;  e.paramElementFilledBool=false;delete this.requestPayloadParameterObj.parameters[e.id];
            }
          }
        })
      }
      if (dat[0]?.value.toLowerCase().replace(/[_ ]/g, '') == 'subgroup') {
        this.runReport = dat[0]?.value;
        this.parameterElementsArray.filter(e => {
          if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroup') {
            let updatedArray = []
            e.visible = true;
            e.loading = true;
            this.myRepoOutputService.getSubgroupList(this.reportId, this.clientId).subscribe((response: any) => {
              e.loading = false;
              response?.body?.forEach((c: any) => {
                if (c.displayName !== null && c.value !== null) {
                  updatedArray.push({
                    label: c.displayName ? c.displayName : '',
                    value: c.value,
                    selected: false
                  });
                }
              });
              e.state.options = updatedArray;
            })
            if (this.restricted) {
              e.mandatory = true;
              e.disabled = true;
              e.paramElementFilledBool = true;
              e.selectedData = e.state.options[0].value;
              this.requestPayloadParameterObj.parameters[e.id] = e.state.options[0].value;
            }
          }
          if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroupvalues') {
            if (this.restricted) {
              e.visible = true;
              e.loading = true;
              let updatedArray = [];
              const selectedValues = [];
              this.myRepoOutputService.getSubGroupValue(this.reportId, this.clientId, this.SubGroupId, this.SelectedRequestedPage).subscribe((response: any) => {
                e.loading = false;
                response?.body?.forEach((c: any) => {
                  if (c.displayName !== null && c.value !== null) {
                    updatedArray.push({
                      label: c.displayName ? c.displayName : '',
                      value: c.value,
                      selected: true
                    });
                  }
                  selectedValues.push(c.value);
                });
                e.state.options = updatedArray;
                e.mandatory = true;
                e.paramElementFilledBool = true;
                e.selectedData = selectedValues;
                this.requestPayloadParameterObj.parameters[e.id] = selectedValues;
              })
            }
            else {
              e.selectedData = []; e.visible = false;  e.paramElementFilledBool=false;delete this.requestPayloadParameterObj.parameters[e.id];
            }
          }
        })
      }
      if (this.parameterElementsArray[index].id.toLowerCase().replace(/[_ ]/g, '') == 'subgroup') {
        let dat = this.parameterElementsArray[index].state.options.filter((t) => t.value === event.value)
        this.SubGroupId = dat[0].value;
        this.parameterElementsArray.filter(e => {
          if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroupvalues') {
            e.visible = true;
            e.loading = true;
            let updatedArray = [];
            this.myRepoOutputService.getSubGroupValue(this.reportId, this.clientId, this.SubGroupId, this.SelectedRequestedPage).subscribe((response: any) => {
              e.loading = false;
              response?.body?.forEach((c: any) => {
                if (c.displayName !== null && c.value !== null) {
                  updatedArray.push({
                    label: c.displayName ? c.displayName : '',
                    value: c.value,
                    selected: false
                  });
                }
              });
              e.state.options = updatedArray;

            })
          }
        })
      }
    }
    if (this.SelectedRequestedPage == 'ADA_REQ') {
      if (this.parameterElementsArray[index].id.toLowerCase().replace(/[_ ]/g, '') == 'clients') {
        let dat = this.parameterElementsArray[index].state.options.filter((t) => t.value === event.value)
        this.clientId = dat[0].value;
        this.parameterElementsArray.filter(e => {
          if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroup') {
            e.selectedData = [];
            this.requestPayloadParameterObj.parameters[e.id] = '';
            e.state.options = [];
            let updatedArray = [];
            e.visible = true;
            e.loading = true
            this.myRepoOutputService.getSubgroupList(this.reportId, this.clientId).subscribe((response: any) => {
              e.loading = false;
              response?.body?.forEach((c: any) => {
                if (c.displayName !== null && c.value !== null) {
                  updatedArray.push({
                    label: c.displayName ? c.displayName : '',
                    value: c.value,
                    selected: false
                  });
                }
              });
              e.state.options = updatedArray;
            })
            if (this.restricted) {
              e.mandatory = true;
              e.disabled = true;
              e.paramElementFilledBool = true;
              e.selectedData = e.state.options[0].value;
              this.requestPayloadParameterObj.parameters[e.id] = e.state.options[0].value;
            }
          }
          if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroupvalues') {
            if (this.restricted) {
              e.visible = true;
              let updatedArray = [];
              const selectedValues = []
              e.selectedData = [];
              this.requestPayloadParameterObj.parameters[e.id] = [];
              // e.loading=true
              this.myRepoOutputService.getAdareqSubGroupValue(this.reportId, this.clientId, this.SubGroupId, this.SelectedRequestedPage).subscribe((response: any) => {
                //e.loading=false;
                response?.body?.forEach((c: any) => {
                  if (c.displayName !== null && c.value !== null) {
                    updatedArray.push({
                      label: c.displayName ? c.displayName : '',
                      value: c.value,
                      selected: true
                    });
                  }
                  selectedValues.push(c.value);
                });
                e.state.options = updatedArray;
                e.mandatory = true;
                e.paramElementFilledBool = true;
                e.selectedData = selectedValues;
                this.requestPayloadParameterObj.parameters[e.id] = selectedValues;
              })
            }
            else {
              e.selectedData = []; e.visible = false;  e.paramElementFilledBool=false;delete this.requestPayloadParameterObj.parameters[e.id];
            }
          }
        })
      }

      if (this.parameterElementsArray[index].id.toLowerCase().replace(/[_ ]/g, '') == 'subgroup') {
        let dat = this.parameterElementsArray[index].state.options.filter((t) => t.value === event.value)
        this.SubGroupId = dat[0].value;
        //  this.isLoading2=true;
        this.parameterElementsArray.filter(e => {
          if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroupvalues') {
            e.visible = true;
            let updatedArray = []
            e.selectedData = [];
            this.requestPayloadParameterObj.parameters[e.id] = [];
            // e.loading=true
            this.myRepoOutputService.getAdareqSubGroupValue(this.reportId, this.clientId, this.SubGroupId, this.SelectedRequestedPage).subscribe((response: any) => {
              //e.loading=false;
              response?.body?.forEach((c: any) => {
                if (c.displayName !== null && c.value !== null) {
                  updatedArray.push({
                    label: c.displayName ? c.displayName : '',
                    value: c.value,
                    selected: false
                  });
                }
              });
              e.state.options = updatedArray;
            })
          }
        })
      }
    }
    if (this.SelectedRequestedPage == 'ADA') {
      if (this.parameterElementsArray[index].id.toLowerCase().replace(/[_ ]/g, '') == 'clientnames') {
        //this.clientId=[];
        let dat = this.parameterElementsArray[index].state.options.filter((t) => t.value === event.value)
        this.clientId = dat[0].value;
        this.parameterElementsArray.filter(e => {
          if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroup') {
            e.selectedData = [];
            this.requestPayloadParameterObj.parameters[e.id] = '';
            e.selectedData = [];
            let updatedArray = [];
            e.visible = true;
            e.loading = true;
            this.myRepoOutputService.getAdaSubgroupList(this.reportId, this.clientId).subscribe((response: any) => {
              e.loading = false;
              response?.body?.forEach((c: any) => {
                if (c.displayName !== null && c.value !== null) {
                  updatedArray.push({
                    label: c.displayName ? c.displayName : '',
                    value: c.value,
                    selected: false
                  });
                }
              });
              e.state.options = updatedArray;

            })
            if (this.restricted) {
              e.mandatory = true;
              e.disabled = true;
              e.paramElementFilledBool = true;
              e.selectedData = e.state.options[0].value;
              this.requestPayloadParameterObj.parameters[e.id] = e.state.options[0].value;
            }
          }
          if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroupvalues') { 
            if (this.restricted) {
              e.visible = true;
              let updatedArray = [];
              const selectedValues = []
              e.selectedData = [];
              this.requestPayloadParameterObj.parameters[e.id] = [];
              // e.loading=true
              this.myRepoOutputService.getAdaSubGroupValue(this.reportId, this.clientId, this.SubGroupId, this.SelectedRequestedPage).subscribe((response: any) => {
                //e.loading=false;
                response?.body?.forEach((c: any) => {
                  if (c.displayName !== null && c.value !== null) {
                    updatedArray.push({
                      label: c.displayName ? c.displayName : '',
                      value: c.value,
                      selected: true
                    });
                  }
                  selectedValues.push(c.value);
                });
                e.state.options = updatedArray;
                e.mandatory = true;
                e.paramElementFilledBool = true;
                e.selectedData = selectedValues;
                this.requestPayloadParameterObj.parameters[e.id] = selectedValues;
              })
            }
            else{
            e.selectedData = []; e.visible = false; delete this.requestPayloadParameterObj.parameters[e.id] } 
          }
        })
      }
      if (this.parameterElementsArray[index].id.toLowerCase().replace(/[_ ]/g, '') == 'subgroup') {
        this.SubGroupId = '';
        let dat = this.parameterElementsArray[index].state.options.filter((t) => t.value === event.value)
        this.SubGroupId = dat[0].value;
        this.parameterElementsArray.filter(e => {
          if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroupvalues') {
            e.selectedData = [];
            let updatedArray = [];
            e.visible = true;
            e.loading = true;
            e.selectedData = [];
            this.requestPayloadParameterObj.parameters[e.id] = [];
            this.myRepoOutputService.getAdaSubGroupValue(this.reportId, this.clientId, this.SubGroupId, this.SelectedRequestedPage).subscribe((response: any) => {
              e.loading = false;
              response?.body?.forEach((c: any) => {
                if (c.displayName !== null && c.value !== null) {
                  updatedArray.push({
                    label: c.displayName ? c.displayName : '',
                    value: c.value,
                    selected: false
                  });
                }
              });
              e.state.options = updatedArray;
            })
          }
        })
      }
    }
    if (this.SelectedRequestedPage == 'CD2') {
      if (this.parameterElementsArray[index].id.toLowerCase().replace(/[_ ]/g, '') == 'clientnames') {
        //this.clientId=[];
        let dat = this.parameterElementsArray[index].state.options.filter((t) => t.value === event.value)
        this.clientId = dat[0].value;
        this.parameterElementsArray.filter(e => {
          if(e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroup' ||e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroupvalues'||e.id.toLowerCase().replace(/[_ ]/g, '') == 'loapolicy'||e.id.toLowerCase().replace(/[_ ]/g, '') == 'ltdpolicy'||e.id.toLowerCase().replace(/[_ ]/g, '') == 'stdpolicy'||e.id.toLowerCase().replace(/[_ ]/g, '') == 'wcpolicy')  
            {e.selectedData = []; e.visible = false; e.paramElementFilledBool=false;delete this.requestPayloadParameterObj.parameters[e.id];}
        })
        this.parameterElementsArray.filter(e => {
          if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroup') {
            let updatedArray = [];
            e.visible = true;
            e.loading = true;
            this.myRepoOutputService.getCD2Subgroup(this.reportId, this.clientId).subscribe((response: any) => {
              e.loading = false;
              response?.body?.forEach((c: any) => {
                if (c.displayName !== null && c.value !== null) {
                  updatedArray.push({
                    label: c.displayName ? c.displayName : '',
                    value: c.value,
                    selected: false
                  });
                }
              });
              e.state.options = updatedArray;

            })
            if (this.restricted) {
              e.mandatory = true;
              e.disabled = true;
              e.paramElementFilledBool = true;
              e.selectedData = e.state.options[0].value;
              this.requestPayloadParameterObj.parameters[e.id] = e.state.options[0].value;
            }
          }
          if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'loapolicy') {
            let updatedArray = [];
            e.visible = true;
            e.loading = true;
            this.myRepoOutputService.getCD2LoaPolicy(this.reportId, this.clientId).subscribe((response: any) => {
              e.loading = false;
              response?.body?.forEach((c: any) => {
                if (c !== null) {
                  updatedArray.push({
                    label: c,
                    value: c,
                    selected: false
                  });
                }
              });
              e.state.options = updatedArray;
            })
          }
          if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'ltdpolicy') {
            let updatedArray = [];
            e.visible = true;
            e.loading = true;
            this.myRepoOutputService.getCD2LtdPolicy(this.reportId, this.clientId).subscribe((response: any) => {
              e.loading = false;
              response?.body?.forEach((c: any) => {
                if (c !== null) {
                  updatedArray.push({
                    label: c,
                    value: c,
                    selected: false
                  });
                }
              });
              e.state.options = updatedArray;
            })
          }
          if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'stdpolicy') {
            let updatedArray = [];
            e.visible = true;
            e.loading = true;
            this.myRepoOutputService.getCD2StdPolicy(this.reportId, this.clientId).subscribe((response: any) => {
              e.loading = false;
              response?.body?.forEach((c: any) => {
                if (c !== null) {
                  updatedArray.push({
                    label: c,
                    value: c,
                    selected: false
                  });
                }
              });
              e.state.options = updatedArray;
            })
          }
          if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'wcpolicy') {
            let updatedArray = [];
            e.visible = true;
            e.loading = true;
            this.myRepoOutputService.getCD2WcPolicy(this.reportId, this.clientId).subscribe((response: any) => {
              e.loading = false;
              response?.body?.forEach((c: any) => {
                if (c !== null) {
                  updatedArray.push({
                    label: c,
                    value: c,
                    selected: false
                  });
                }
              });
              e.state.options = updatedArray;
            })
          }
          if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroupvalues') {
            if (this.restricted) {
              e.visible = true;
              let updatedArray = [];
              const selectedValues = []
              e.selectedData = [];
              this.requestPayloadParameterObj.parameters[e.id] = [];
              // e.loading=true
              this.myRepoOutputService.getCD2SubgroupVlaue(this.reportId, this.clientId, this.SubGroupId, this.SelectedRequestedPage).subscribe((response: any) => {
                //e.loading=false;
                response?.body?.forEach((c: any) => {
                  if (c.displayName !== null && c.value !== null) {
                    updatedArray.push({
                      label: c.displayName ? c.displayName : '',
                      value: c.value,
                      selected: true
                    });
                  }
                  selectedValues.push(c.value);
                });
                e.state.options = updatedArray;
                e.mandatory = true;
                e.paramElementFilledBool = true;
                e.selectedData = selectedValues;
                this.requestPayloadParameterObj.parameters[e.id] = selectedValues;
              })
            }
            else{
            e.selectedData = []; e.visible = false; delete this.requestPayloadParameterObj.parameters[e.id] 
          } 
          }
        })
      }
      if (this.parameterElementsArray[index].id.toLowerCase().replace(/[_ ]/g, '') == 'subgroup') {
        this.SubGroupId = '';
        let updatedArray = [];
        let dat = this.parameterElementsArray[index].state.options.filter((t) => t.value === event.value)
        this.SubGroupId = dat[0].value;
        this.parameterElementsArray.filter(e => {
          if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroupvalues') {
            e.visible = true;
            e.loading = true;
            e.selectedData = [];
            this.requestPayloadParameterObj.parameters[e.id] = [];
            this.myRepoOutputService.getCD2SubgroupVlaue(this.reportId, this.clientId, this.SubGroupId, this.SelectedRequestedPage).subscribe((response: any) => {
              e.loading = false;
              response?.body?.forEach(c => {
                if (c !== null) {
                  updatedArray.push({
                    label: c,
                    value: c,
                    selected: false
                  });
                }
              })
              e.state.options = response?.body;
            })
          }
        })
      }
    }
    if (this.SelectedRequestedPage == 'CD3') {
      if (this.parameterElementsArray[index].id.toLowerCase().replace(/[_ ]/g, '') == 'clientnames') {
        //this.clientId=[];
        let dat = this.parameterElementsArray[index].state.options.filter((t) => t.value === event.value)
        this.clientId = dat[0].value;
        this.parameterElementsArray.filter(e => {
          if(e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroup' ||e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroupvalues'||e.id.toLowerCase().replace(/[_ ]/g, '') == 'loapolicy'||e.id.toLowerCase().replace(/[_ ]/g, '') == 'ltdpolicy'||e.id.toLowerCase().replace(/[_ ]/g, '') == 'stdpolicy'||e.id.toLowerCase().replace(/[_ ]/g, '') == 'wcpolicy')  
            {e.selectedData = []; e.visible = false; e.paramElementFilledBool=false;delete this.requestPayloadParameterObj.parameters[e.id];}
        })
        this.parameterElementsArray.filter(e => {
          if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroup') {
            let updatedArray = [];
            e.visible = true;
            e.loading = true;
            this.myRepoOutputService.getCD3Subgroup(this.reportId, this.clientId).subscribe((response: any) => {
              e.loading = false;
              response?.body?.forEach(c => {
                if (c.displayName !== null && c.value !== null) {
                  updatedArray.push({
                    label: c.displayName ? c.displayName : '',
                    value: c.value,
                    selected: false
                  });
                }
              });
              e.state.options = updatedArray;

            })
            if (this.restricted) {
              e.mandatory = true;
              e.disabled = true;
              e.paramElementFilledBool = true;
              e.selectedData = e.state.options[0].value;
              this.requestPayloadParameterObj.parameters[e.id] = e.state.options[0].value;
            }
          }
          if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'loapolicy') {
            let updatedArray = [];
            e.visible = true;
            e.loading = true;
            this.myRepoOutputService.getCD3LoaPolicy(this.reportId, this.clientId).subscribe((response: any) => {
              e.loading = false;
              response?.body?.forEach((c: any) => {
                if (c !== null) {
                  updatedArray.push({
                    label: c,
                    value: c,
                    selected: false
                  });
                }
              });
              e.state.options = updatedArray;
            })
          }
          if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'ltdpolicy') {
            let updatedArray = [];
            e.visible = true;
            e.loading = true;
            this.myRepoOutputService.getCD3LtdPolicy(this.reportId, this.clientId).subscribe((response: any) => {
              e.loading = false;
              response?.body?.forEach((c: any) => {
                if (c !== null) {
                  updatedArray.push({
                    label: c,
                    value: c,
                    selected: false
                  });
                }
              });
              e.state.options = updatedArray;
            })
          }
          if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'stdpolicy') {
            let updatedArray = [];
            e.visible = true;
            e.loading = true;
            this.myRepoOutputService.getCD3StdPolicy(this.reportId, this.clientId).subscribe((response: any) => {
              e.loading = false;
              response?.body?.forEach((c: any) => {
                if (c !== null) {
                  updatedArray.push({
                    label: c,
                    value: c,
                    selected: false
                  });
                }
              });
              e.state.options = updatedArray;
            })
          }
          if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'wcpolicy') {
            let updatedArray = [];
            e.visible = true;
            e.loading = true;
            this.myRepoOutputService.getCD3WcPolicy(this.reportId, this.clientId).subscribe((response: any) => {
              e.loading = false;
              response?.body?.forEach((c: any) => {
                if (c !== null) {
                  updatedArray.push({
                    label: c,
                    value: c,
                    selected: false
                  });
                }
              });
              e.state.options = updatedArray;
            })
          }
          if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroupvalues') {
            if (this.restricted) {
              e.visible = true;
              let updatedArray = [];
              const selectedValues = []
              e.selectedData = [];
              this.requestPayloadParameterObj.parameters[e.id] = [];
              // e.loading=true
              this.myRepoOutputService.getCD3SubgroupVlaue(this.reportId, this.clientId, this.SubGroupId, this.SelectedRequestedPage).subscribe((response: any) => {
                //e.loading=false;
                response?.body?.forEach((c: any) => {
                  if (c.displayName !== null && c.value !== null) {
                    updatedArray.push({
                      label: c.displayName ? c.displayName : '',
                      value: c.value,
                      selected: true
                    });
                  }
                  selectedValues.push(c.value);
                });
                e.state.options = updatedArray;
                e.mandatory = true;
                e.paramElementFilledBool = true;
                e.selectedData = selectedValues;
                this.requestPayloadParameterObj.parameters[e.id] = selectedValues;
              })
            }
            else{
            e.selectedData = []; e.visible = false; delete this.requestPayloadParameterObj.parameters[e.id] 
          } 
          }
        })
      }
      if (this.parameterElementsArray[index].id.toLowerCase().replace(/[_ ]/g, '') == 'subgroup') {
        this.SubGroupId = '';
        let dat = this.parameterElementsArray[index].state.options.filter((t) => t.value === event.value)
        this.SubGroupId = dat[0].value;
        this.parameterElementsArray.filter(e => {
          if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroupvalues') {
            e.visible = true;
            let updatedArray = [];
            e.loading = true;
            e.selectedData = [];
            this.requestPayloadParameterObj.parameters[e.id] = [];
            this.myRepoOutputService.getCD3SubgroupVlaue(this.reportId, this.clientId, this.SubGroupId, this.SelectedRequestedPage).subscribe((response: any) => {
              e.loading = false;
              response?.body?.forEach(c => {
                if (c !== null) {
                  updatedArray.push({
                    label: c,
                    value: c,
                    selected: false
                  });
                }
              });
              e.state.options = updatedArray;
            })
          }
        })
      }
    }
    if (this.SelectedRequestedPage == 'PACS') {
      if (this.parameterElementsArray[index].id.toLowerCase().replace(/[_ ]/g, '') == 'clients') {
        let dat = this.parameterElementsArray[index].state.options?.filter((t) => t.value === event.value)
        this.clientId = dat[0].value;
        this.lobData='';
        this.parameterElementsArray.filter(e => {
          if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'lineofbusiness') {
            e.selectedData = [];
            e.paramElementFilledBool=false;
            this.requestPayloadParameterObj.parameters[e.id] = '';
            e.state.options = [];
            e.visible = true;
            e.loading = true
            this.clientFlag = "C";
            this.parentFlag = "Y";
            let updatedArray = [];
            this.myRepoOutputService.getLOBList(this.reportId, this.clientId, this.clientFlag, this.parentFlag).subscribe((response: any) => {
              e.loading = false;
              e.visible
              response?.body?.forEach((c: any) => {
                if (c !== null) {
                  updatedArray.push({
                    label: c,
                    value: c,
                    selected: false
                  });
                }
              });
              e.state.options = updatedArray;
            })//e.id.toLowerCase() == 'policy'||e.id.toLowerCase() == 'class'||e.id.toLowerCase() == 'plan type'||e.id.toLowerCase()=='policy type'
          }
          // if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'policies') {
          //   e.selectedData = []; e.state.options.filter(o => { if (o.selected == true) { o.selected = false } });  e.paramElementFilledBool=false;delete this.requestPayloadParameterObj.parameters[e.id];
          // }
          if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'policy') { e.selectedData = []; e.visible = false;  e.paramElementFilledBool=false;delete this.requestPayloadParameterObj.parameters[e.id]; }
          if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'plantype') { e.selectedData = []; e.visible = false;  e.paramElementFilledBool=false;delete this.requestPayloadParameterObj.parameters[e.id]; }
          if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'policytype') { e.selectedData = []; e.visible = false;  e.paramElementFilledBool=false;delete this.requestPayloadParameterObj.parameters[e.id]; }
          if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'class') { e.selectedData = []; e.visible = false;  e.paramElementFilledBool=false;delete this.requestPayloadParameterObj.parameters[e.id]; }
          if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroup') {
            e.selectedData = [];
            this.requestPayloadParameterObj.parameters[e.id] = '';
            e.state.options = [];
            let updatedArray = [];
            e.visible = true;
            e.loading = true
            this.myRepoOutputService.getSubgroupList(this.reportId, this.clientId).subscribe((response: any) => {
              e.loading = false;
              response?.body?.forEach(c => {
                if (c.displayName !== null && c.value !== null) {
                  updatedArray.push({
                    label: c.displayName ? c.displayName : '',
                    value: c.value,
                    selected: false
                  });
                }
              });
              e.state.options = updatedArray;
            })
             if (this.restricted) {
              e.mandatory = true;
              e.disabled = true;
              e.paramElementFilledBool = true;
              e.selectedData = e.state.options[0].value;
              this.requestPayloadParameterObj.parameters[e.id] = e.state.options[0].value;
            }
          }
          if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroupvalues') {
            if (this.restricted) {
              e.visible = true;
              let updatedArray = [];
              const selectedValues = []
              e.selectedData = [];
              this.requestPayloadParameterObj.parameters[e.id] = [];
              // e.loading=true
              this.myRepoOutputService.getSubGroupValue(this.reportId, this.clientId, this.SubGroupId, this.SelectedRequestedPage).subscribe((response: any) => {
                //e.loading=false;
                response?.body?.forEach((c: any) => {
                  if (c.displayName !== null && c.value !== null) {
                    updatedArray.push({
                      label: c.displayName ? c.displayName : '',
                      value: c.value,
                      selected: true
                    });
                  }
                  selectedValues.push(c.value);
                });
                e.state.options = updatedArray;
                e.mandatory = true;
                e.paramElementFilledBool = true;
                e.selectedData = selectedValues;
                this.requestPayloadParameterObj.parameters[e.id] = selectedValues;
              })
            }
            else{
            e.selectedData = []; e.visible = false; delete this.requestPayloadParameterObj.parameters[e.id] 
          } 
          }
        })
      }
      if (this.parameterElementsArray[index].id.toLowerCase().replace(/[_ ]/g, '') == 'lineofbusiness') {
        let dat = this.parameterElementsArray[index].state.options?.filter((t) => t.value === event.value)
        this.lobData = dat[0]?.value;
        this.parameterElementsArray.filter(e => {
          if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'policies') {
            // e.selectedData = []; e.state.options.filter(o => { if (o.selected == true) { o.selected = false } });  e.paramElementFilledBool=false;delete this.requestPayloadParameterObj.parameters[e.id];
            if (e.selectedData.toLowerCase().replace(/[_ ]/g, '') == 'policy') {
              this.parameterElementsArray.filter(e => {
                if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'policy') {
                  e.visible = true
                  e.loading = true
                  this.myRepoOutputService.getPolicyList(this.reportId, this.clientId, this.lobData).subscribe((response: any) => {
                    e.loading = false;
                    let convertedData = [];
                    response?.body?.map((obj, i) => {
                      //obj.policyNumber; // Assuming you want the 'age' property's value as the 'value' field
                      const formattedLabel = obj.policyNumber + " - " + obj.effectiveDate + " - " + obj.expiryDate + " - " + obj.policyType + " - " + obj.policyDesc;
                      convertedData[i] = { label: formattedLabel, value: obj.policyNumber + "|" + obj.policyBON, value1: obj.policyBON, selected: false };
                    });
                    e.state.options = convertedData;
                  })
                }
                if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'plantype') { e.selectedData = []; e.visible = false;  e.paramElementFilledBool=false;delete this.requestPayloadParameterObj.parameters[e.id]; }
                if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'policytype') { e.selectedData = []; e.visible = false;  e.paramElementFilledBool=false;delete this.requestPayloadParameterObj.parameters[e.id]; }
                if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'class') { e.selectedData = []; e.visible = false;  e.paramElementFilledBool=false;delete this.requestPayloadParameterObj.parameters[e.id]; }
              })
            }
            else if (e.selectedData.toLowerCase().replace(/[_ ]/g, '') == 'policytype') {
              this.parameterElementsArray.filter(e => {
                if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'policytype') {
                  e.visible = true;
                  e.loading = true;
                  let updatedArray = [];
                  this.myRepoOutputService.getPolicyTypeList(this.reportId, this.clientId, this.lobData).subscribe((response: any) => {
                    e.loading = false;
                    response?.body?.forEach((c: any) => {
                      if (c !== null) {
                        updatedArray.push({
                          label: c,
                          value: c,
                          selected: false
                        });
                      }
                    });
                    e.state.options = updatedArray;
                  })
                }
                if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'plantype') { e.selectedData = []; e.visible = false;  e.paramElementFilledBool=false;delete this.requestPayloadParameterObj.parameters[e.id]; }
                if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'policy') { e.selectedData = []; e.visible = false;  e.paramElementFilledBool=false;delete this.requestPayloadParameterObj.parameters[e.id]; }
                if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'class') { e.selectedData = []; e.visible = false;  e.paramElementFilledBool=false;delete this.requestPayloadParameterObj.parameters[e.id]; }
              })
            }
          }
          // if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'policy') { e.selectedData = []; e.visible = false;  e.paramElementFilledBool=false;delete this.requestPayloadParameterObj.parameters[e.id]; }
          // if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'plantype') { e.selectedData = []; e.visible = false;  e.paramElementFilledBool=false;delete this.requestPayloadParameterObj.parameters[e.id]; }
          // if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'policytype') { e.selectedData = []; e.visible = false;  e.paramElementFilledBool=false;delete this.requestPayloadParameterObj.parameters[e.id]; }
          // if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'class') { e.selectedData = []; e.visible = false;  e.paramElementFilledBool=false;delete this.requestPayloadParameterObj.parameters[e.id]; }
        })
      }
      if (this.parameterElementsArray[index].id.toLowerCase().replace(/[_ ]/g, '') == 'policies') {
        let dat = this.parameterElementsArray[index].state.options?.filter((t) => t.value === refEl.value)
        this.policiesData = dat[0]?.label;
        if(this.lobData!='' && this.lobData!=null && this.lobData!=undefined){
          if (dat[0]?.label.toLowerCase().replace(/[_ ]/g, '') == 'policy') {
            this.parameterElementsArray.filter(e => {
              if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'policy') {
                e.visible = true
                e.loading = true
                this.myRepoOutputService.getPolicyList(this.reportId, this.clientId, this.lobData).subscribe((response: any) => {
                  e.loading = false;
                  let convertedData = [];
                  response?.body?.map((obj, i) => {
                    //obj.policyNumber; // Assuming you want the 'age' property's value as the 'value' field
                    const formattedLabel = obj.policyNumber + " - " + obj.effectiveDate + " - " + obj.expiryDate + " - " + obj.policyType + " - " + obj.policyDesc;
                    convertedData[i] = { label: formattedLabel, value: obj.policyNumber + "|" + obj.policyBON, value1: obj.policyBON, selected: false };
                  });
                  e.state.options = convertedData;
                })
              }
              if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'plantype') { e.selectedData = []; e.visible = false;  e.paramElementFilledBool=false;delete this.requestPayloadParameterObj.parameters[e.id]; }
              if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'policytype') { e.selectedData = []; e.visible = false;  e.paramElementFilledBool=false;delete this.requestPayloadParameterObj.parameters[e.id]; }
              if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'class') { e.selectedData = []; e.visible = false;  e.paramElementFilledBool=false;delete this.requestPayloadParameterObj.parameters[e.id]; }
            })
          }
          if (dat[0]?.label.toLowerCase().replace(/[_ ]/g, '') == 'policytype') {
            this.parameterElementsArray.filter(e => {
              if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'policytype') {
                e.visible = true;
                e.loading = true;
                let updatedArray = [];
                this.myRepoOutputService.getPolicyTypeList(this.reportId, this.clientId, this.lobData).subscribe((response: any) => {
                  e.loading = false;
                  response?.body?.forEach((c: any) => {
                    if (c !== null) {
                      updatedArray.push({
                        label: c,
                        value: c,
                        selected: false
                      });
                    }
                  });
                  e.state.options = updatedArray;
                })
              }
              if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'plantype') { e.selectedData = []; e.visible = false;  e.paramElementFilledBool=false;delete this.requestPayloadParameterObj.parameters[e.id]; }
              if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'policy') { e.selectedData = []; e.visible = false;  e.paramElementFilledBool=false;delete this.requestPayloadParameterObj.parameters[e.id]; }
              if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'class') { e.selectedData = []; e.visible = false;  e.paramElementFilledBool=false;delete this.requestPayloadParameterObj.parameters[e.id]; }
            })
          }
      }
      }
      if (this.parameterElementsArray[index].id.toLowerCase().replace(/[_ ]/g, '') == 'subgroup') {
        let dat = this.parameterElementsArray[index].state.options?.filter((t) => t.value === event.value)
        this.SubGroupId = dat[0]?.value;
        this.parameterElementsArray.filter(e => {
          if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroupvalues') {
            e.visible = true;
            e.loading = true
            let updatedArray = [];
            e.selectedData = [];
            this.requestPayloadParameterObj.parameters[e.id] = [];
            this.myRepoOutputService.getSubGroupValue(this.reportId, this.clientId, this.SubGroupId, this.SelectedRequestedPage).subscribe((response: any) => {
              e.loading = false;
              response?.body?.forEach(c => {
                if (c.displayName !== null && c.value !== null) {
                  updatedArray.push({
                    label: c.displayName ? c.displayName : '',
                    value: c.value,
                    selected: false
                  });
                }
              });
              e.state.options = updatedArray;
            })
          }
        })
      }
      if (this.parameterElementsArray[index].id.toLowerCase().replace(/[_ ]/g, '') == 'policy') {
        this.policyId = '';
        let dat = this.parameterElementsArray[index].selectedData;
        //this.policyId = dat.value1;
        let data1 = [];
        if (dat.length > 1) {
          this.policyId = '';
        }
        else {
          data1 = this.parameterElementsArray[index].state.options?.filter((t) => t.value === event.value[0])
          this.policyId = data1[0]['value1'];
        }

        let checkbool = false;

        // if (!checkbool) {
        this.parameterElementsArray.filter(e => {
          if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'class') {
            // const containsEmptyString = this.policyId.includes(' ');
            // this.policyId = this.policyId.filter(item => item !== '');
            if (this.policyId == '') {
              checkbool = true;
              e.selectedData = [];
              e.visible = false;
               e.paramElementFilledBool=false;delete this.requestPayloadParameterObj.parameters[e.id];
            }
            else {
              e.visible = true;
              e.loading = true
              this.myRepoOutputService.getClassList(this.reportId, this.policyId).subscribe((response: any) => {
                e.loading = false;
                response?.body?.forEach(c => {
                  c.label = c.contractClassID;
                  c.value1 = c.contractClassBON;
                  c.value = '(' + c.contractClassBON + ',' + c.contractClassID + ')';
                  delete c.contractClassID;
                  delete c.contractClassBON;
                  c.selected = false;
                })
                e.state.options = response?.body;
              })
            }
          }
        })
        // }
      }
      if (this.parameterElementsArray[index].id.toLowerCase().replace(/[_ ]/g, '') == 'class') {
        //var obj=this.parameterElementsArray[index];
        this.classBON = '';
        this.classId = '';
        let dat = this.parameterElementsArray[index].selectedData;
        let data1;
        if (dat.length > 1) {
          this.classBON = '';
          this.classId = '';
        }
        else {
          data1 = this.parameterElementsArray[index].state.options?.filter((t) => t.value === event.value[0])
          this.classBON = data1[0].value1
          this.classId = data1[0].label;
          this.requestPayloadParameterObj.parameters[obj.id] = data1[0].value;
        }
        this.parameterElementsArray.filter(e => {
          if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'plantype') {
            if (this.classBON == '' && this.classId == '') {
              e.selectedData = [];
              e.visible = false;
               e.paramElementFilledBool=false;delete this.requestPayloadParameterObj.parameters[e.id];
            }
            else {
              // if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'plantype') {
              e.visible = true;
              e.loading = true
              this.myRepoOutputService.getPlanTypeList(this.reportId, this.classBON, this.classId).subscribe((response: any) => {
                e.loading = false;
                response?.body?.forEach(c => {
                  c.label = c.planDescription;
                  delete c.planDescription;
                  c.value = '(' + c.planDesignBON + ',' + c.planType + ')';
                  delete c.planType;
                  delete c.planDesignBON
                  c.selected = false;
                })
                e.state.options = response?.body;
              })
              // }
            }
          }
        })
      }
    }
    if (this.SelectedRequestedPage == 'WC') {
      if (this.parameterElementsArray[index].id.toLowerCase().replace(/[_ ]/g, '') == 'clients') {
        this.clientId = '';
        let dat = this.parameterElementsArray[index].state.options?.filter((t) => t.value === event.value)
        this.clientId = dat[0]?.value;
        this.parameterElementsArray.filter(e => {
          // if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'runreportby') {
          //   e.selectedData = [];
          //   this.requestPayloadParameterObj.parameters[e.id]='';
          //   e.paramElementFilledBool=false;
          //   e.visible = true;
          //   e.loading = true;
          //   let updatedArray = []
          //   this.myRepoOutputService.getRunByOptions(this.reportId).subscribe((response: any) => {
          //     e.loading = false;
          //     response?.body?.forEach((c: any) => {
          //       if (c !== null) {
          //         updatedArray.push({
          //           label: c,
          //           value: c,
          //           selected: false
          //         });
          //       }
          //     });
          //     e.state.options = updatedArray;
          //   })

          // }
          if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'policies' ||e.id.toLowerCase().replace(/[_ ]/g, '') == 'sitesbenefitstates'||
          e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroup'||e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroupvalues')
          {e.selectedData = []; e.visible = false;  e.paramElementFilledBool=false;delete this.requestPayloadParameterObj.parameters[e.id];}
        })
        if(this.runReport1 != ''){
          if (this.runReport1.toLowerCase().replace(/[_ ]/g, '') === 'policyonly') {
            this.parameterElementsArray.filter(e => {
              if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'policies') {
                // this.requestPayloadParameterObj.parameters[e.label] = '';
                e.selectedData = [];
                e.visible = true;
                e.loading = true;
                this.myRepoOutputService.getWCPolicyList(this.reportId, this.clientId).subscribe((response: any) => {
                  e.loading = false;
                  response?.body?.forEach(c => {
                    c.value = c.policyNumber;
                    c.label = c.policyNumber + '-' + c.effectiveDate + '-' + c.expiryDate;
                    c.selected = false;
                  })
                  e.state.options = response?.body;
                })
  
              }
              if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'sitesbenefitstates') { e.selectedData = []; e.visible = false;  e.paramElementFilledBool=false;delete this.requestPayloadParameterObj.parameters[e.id]; }
              if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroup') { e.selectedData = []; e.visible = false;  e.paramElementFilledBool=false;delete this.requestPayloadParameterObj.parameters[e.id]; }
              if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroupvalues') { e.selectedData = []; e.visible = false;  e.paramElementFilledBool=false;delete this.requestPayloadParameterObj.parameters[e.id]; }
            })
          }
          if (this.runReport1.toLowerCase().replace(/[_ ]/g, '') === 'siteonly') {
            this.parameterElementsArray.filter(e => {
              if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'sitesbenefitstates') {
                e.selectedData = [];
                // this.requestPayloadParameterObj.parameters[e.label] = '';
                e.visible = true;
                e.loading = true;
                this.myRepoOutputService.getWCSiteList(this.reportId, this.clientId).subscribe((response: any) => {
                  e.loading = false;
                  response?.body?.forEach(c => {
                    c.label = c.siteCode + '-' + c.siteDesc;
                    c.value = c.siteCode;
                    c.selected = false;
                  })
                  e.state.options = response?.body;
                })
  
              }
              if (e.id.toLowerCase() == 'policies') { e.selectedData = []; e.visible = false;  e.paramElementFilledBool=false;delete this.requestPayloadParameterObj.parameters[e.id]; }
              if (e.id.toLowerCase() == 'subgroup') { e.selectedData = []; e.visible = false;  e.paramElementFilledBool=false;delete this.requestPayloadParameterObj.parameters[e.id]; }
              if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroupvalues') { e.selectedData = []; e.visible = false;  e.paramElementFilledBool=false;delete this.requestPayloadParameterObj.parameters[e.id]; }
            })
          }
          if (this.runReport1.toLowerCase().replace(/[_ ]/g, '') === 'benefitstateonly') {
            this.parameterElementsArray.filter(e => {
              if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'sitesbenefitstates') {
                // this.requestPayloadParameterObj.parameters[e.label] = '';
                e.selectedData = [];
                e.visible = true;
                e.loading = true;
                this.myRepoOutputService.getWCBenefitList(this.reportId, this.clientId).subscribe((response: any) => {
                  e.loading = false;
                  response?.body?.forEach(c => {
                    c.label = c.benefitState;
                    c.value = c.ncciStateJurisdictionCode;
                    c.selected = false;
                  })
                  e.state.options = response?.body;
                })
              }
              if (e.id.toLowerCase() == 'policies') { e.selectedData = []; e.visible = false;  e.paramElementFilledBool=false;delete this.requestPayloadParameterObj.parameters[e.id]; }
              if (e.id.toLowerCase() == 'subgroup') { e.selectedData = []; e.visible = false;  e.paramElementFilledBool=false;delete this.requestPayloadParameterObj.parameters[e.id]; }
              if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroupvalues') { e.selectedData = []; e.visible = false;  e.paramElementFilledBool=false;delete this.requestPayloadParameterObj.parameters[e.id]; }
            })
          }
          if (this.runReport1.toLowerCase().replace(/[_ ]/g, '') === 'policy,benefitstate') {
            this.parameterElementsArray.filter(e => {
              if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'policies') {
                // this.requestPayloadParameterObj.parameters[e.label] = '';
                e.selectedData = [];
                e.visible = true;
                e.loading = true;
                this.myRepoOutputService.getWCPolicyList(this.reportId, this.clientId).subscribe((response: any) => {
                  e.loading = false;
                  response?.body?.forEach(c => {
                    c.value = c.policyNumber;
                    c.label = c.policyNumber + '-' + c.effectiveDate + '-' + c.expiryDate;
                    c.selected = false;
                  })
                  e.state.options = response?.body;
                })
  
              }
              if (e.id.toLowerCase().replace(/[_ ]/g, '') === 'sitesbenefitstates') {
                e.selectedData = [];
                // this.requestPayloadParameterObj.parameters[e.label] = '';
                e.visible = true;
                e.loading = true;
                this.myRepoOutputService.getWCBenefitList(this.reportId, this.clientId).subscribe((response: any) => {
                  e.loading = false;
                  response?.body?.forEach(c => {
                    c.label = c.benefitState;
                    c.value = c.ncciStateJurisdictionCode;
                    c.selected = false;
                  })
                  e.state.options = response?.body;
                })
  
              }
              if (e.id.toLowerCase() === 'subgroup') { e.selectedData = []; e.visible = false;  e.paramElementFilledBool=false;delete this.requestPayloadParameterObj.parameters[e.id]; }
              if (e.id.toLowerCase().replace(/[_ ]/g, '') === 'subgroupvalues') { e.selectedData = []; e.visible = false;  e.paramElementFilledBool=false;delete this.requestPayloadParameterObj.parameters[e.id]; }
            })
          }
          if (this.runReport1.toLowerCase().replace(/[_ ]/g, '') === 'policy,site') {
            this.parameterElementsArray.filter(e => {
              if (e.id.toLowerCase() == 'policies') {
                e.selectedData = [];
                // this.requestPayloadParameterObj.parameters[e.label] = '';
                e.visible = true;
                e.loading = true;
                this.myRepoOutputService.getWCPolicyList(this.reportId, this.clientId).subscribe((response: any) => {
                  e.loading = false;
                  response?.body?.forEach(c => {
                    c.value = c.policyNumber;
                    c.label = c.policyNumber + '-' + c.effectiveDate + '-' + c.expiryDate;
                    c.selected = false;
                  })
                  e.state.options = response?.body;
                })
  
              }
              if (e.id.toLowerCase().replace(/[_ ]/g, '') === 'sitesbenefitstates') {
                e.selectedData = [];
                // this.requestPayloadParameterObj.parameters[e.label] = '';
                e.visible = true;
                e.loading = true;
                this.myRepoOutputService.getWCSiteList(this.reportId, this.clientId).subscribe((response: any) => {
                  e.loading = false;
                  response?.body?.forEach(c => {
                    c.label = c.siteCode + '-' + c.siteDesc;
                    c.value = c.siteCode;
                    c.selected = false;
                  })
                  e.state.options = response?.body;
                })
  
              }
              if (e.id.toLowerCase() == 'subgroup') { e.selectedData = []; e.visible = false;  e.paramElementFilledBool=false;delete this.requestPayloadParameterObj.parameters[e.id]; }
              if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroupvalues') { e.selectedData = []; e.visible = false;  e.paramElementFilledBool=false;delete this.requestPayloadParameterObj.parameters[e.id]; }
            })
          }
          if (this.runReport1.toLowerCase().replace(/[_ ]/g, '') === 'policy,benefitstate,subgroup') {
            this.parameterElementsArray.filter(e => {
              if (e.id.toLowerCase() == 'policies') {
                // this.requestPayloadParameterObj.parameters[e.label] = '';
                e.selectedData = [];
                e.visible = true;
                e.loading = true;
                this.myRepoOutputService.getWCPolicyList(this.reportId, this.clientId).subscribe((response: any) => {
                  e.loading = false;
                  response?.body?.forEach(c => {
                    c.value = c.policyNumber;
                    c.label = c.policyNumber + '-' + c.effectiveDate + '-' + c.expiryDate;
                    c.selected = false;
                  })
                  e.state.options = response?.body;
                })
  
              }
              if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'sitesbenefitstates') {
                e.selectedData = [];
                e.visible = true;
                // this.requestPayloadParameterObj.parameters[e.label] = '';
                e.loading = true;
                this.myRepoOutputService.getWCBenefitList(this.reportId, this.clientId).subscribe((response: any) => {
                  e.loading = false;
                  response?.body?.forEach(c => {
                    c.label = c.benefitState;
                    c.value = c.ncciStateJurisdictionCode;
                    c.selected = false;
                  })
                  e.state.options = response?.body;
                })
  
              }
              if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroup') {
                e.selectedData = [];
                // this.requestPayloadParameterObj.parameters[e.label] = '';
                e.state.options = [];
                e.visible = true;
                e.loading = true;
                let updatedArray = []
                this.myRepoOutputService.getWCSubgroup(this.reportId, this.clientId).subscribe((response: any) => {
                  e.loading = false;
                  response?.body?.forEach(c => {
                    if (c.displayName !== null && c.value !== null) {
                      updatedArray.push({
                        label: c.displayName ? c.displayName : '',
                        value: c.value,
                        selected: false
                      });
                    }
                  });
                  e.state.options = updatedArray;
                  e.state.options.forEach(d => { if (d.value.toLowerCase() == 'restricted') { this.restricted = true } })
                    if (this.restricted) {
                      e.mandatory = true;
                      e.disabled = true;
                      e.paramElementFilledBool = true;
                      e.selectedData = e.state.options[0].value;
                      this.SubGroupId=e.state.options[0].value;
                      this.requestPayloadParameterObj.parameters[e.id] = e.state.options[0].value;
                      this.parameterElementsArray.filter(e => {
                        if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroupvalues') {
                          // if (this.restricted) {
                            e.visible = true;
                            e.loading = true
                            let updatedArray = [];
                            const selectedValues = []
                            e.selectedData = [];
                            this.requestPayloadParameterObj.parameters[e.id] = [];
                            this.myRepoOutputService.getSubGroupValue(this.reportId, this.clientId, this.SubGroupId, this.SelectedRequestedPage).subscribe((response: any) => {
                              e.loading = false;
                              response?.body?.forEach(c => {
                                if (c.displayName !== null && c.value !== null) {
                                  updatedArray.push({
                                    label: c.displayName ? c.displayName : '',
                                    value: c.value,
                                    selected: true
                                  });
                                }
                                selectedValues.push(c.value)
                              });
                              e.state.options = updatedArray;
  
                              e.mandatory = true;
                              e.disabled = true;
                              e.paramElementFilledBool = true;
                              e.selectedData = selectedValues;
                              this.requestPayloadParameterObj.parameters[e.id] = selectedValues;
                            })
                        }
                      })
                    }
                })
              }
              if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroupvalues') { e.selectedData = []; e.visible = false;  e.paramElementFilledBool=false;delete this.requestPayloadParameterObj.parameters[e.id]; }
            })
          }
          if (this.runReport1.toLowerCase().replace(/[_ ]/g, '') === 'site,subgroup') {
            this.parameterElementsArray.filter(e => {
              if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'sitesbenefitstates') {
                // this.requestPayloadParameterObj.parameters[e.label] = '';
                e.selectedData = [];
                e.visible = true;
                e.loading = true;
                this.myRepoOutputService.getWCSiteList(this.reportId, this.clientId).subscribe((response: any) => {
                  e.loading = false;
                  response?.body?.forEach(c => {
                    c.label = + c.siteCode + '-' + c.siteDesc;
                    c.value = c.siteCode;
                    c.selected = false;
                  })
                  e.state.options = response?.body;
                })
  
              }
              if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroup') {
                e.selectedData = [];
                // this.requestPayloadParameterObj.parameters[e.label] = '';
                e.state.options = [];
                e.visible = true;
                e.loading = true;
                let updatedArray = []
                this.myRepoOutputService.getWCSubgroup(this.reportId, this.clientId).subscribe((response: any) => {
                  e.loading = false;
                  response?.body?.forEach(c => {
                    if (c.displayName !== null && c.value !== null) {
                      updatedArray.push({
                        label: c.displayName ? c.displayName : '',
                        value: c.value,
                        selected: false
                      });
                    }
                  });
                  e.state.options = updatedArray;
                  e.state.options.forEach(d => { if (d.value.toLowerCase() == 'restricted') { this.restricted = true } })
                    if (this.restricted) {
                      e.mandatory = true;
                      e.disabled = true;
                      e.paramElementFilledBool = true;
                      e.selectedData = e.state.options[0].value;
                      this.SubGroupId=e.state.options[0].value;
                      this.requestPayloadParameterObj.parameters[e.id] = e.state.options[0].value;
                      this.parameterElementsArray.filter(e => {
                        if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroupvalues') {
                          // if (this.restricted) {
                            e.visible = true;
                            e.loading = true
                            let updatedArray = [];
                            const selectedValues = []
                            e.selectedData = [];
                            this.requestPayloadParameterObj.parameters[e.id] = [];
                            this.myRepoOutputService.getSubGroupValue(this.reportId, this.clientId, this.SubGroupId, this.SelectedRequestedPage).subscribe((response: any) => {
                              e.loading = false;
                              response?.body?.forEach(c => {
                                if (c.displayName !== null && c.value !== null) {
                                  updatedArray.push({
                                    label: c.displayName ? c.displayName : '',
                                    value: c.value,
                                    selected: true
                                  });
                                }
                                selectedValues.push(c.value)
                              });
                              e.state.options = updatedArray;
  
                              e.mandatory = true;
                              e.disabled = true;
                              e.paramElementFilledBool = true;
                              e.selectedData = selectedValues;
                              this.requestPayloadParameterObj.parameters[e.id] = selectedValues;
                            })
                        }
                      })
                    }
                })
              }
              if (e.id.toLowerCase() == 'policies') { e.selectedData = []; e.visible = false;  e.paramElementFilledBool=false;delete this.requestPayloadParameterObj.parameters[e.id]; }
              if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroupvalues') { e.selectedData = []; e.visible = false;  e.paramElementFilledBool=false;delete this.requestPayloadParameterObj.parameters[e.id]; }
            })
          }
          if (this.runReport1.toLowerCase().replace(/[_ ]/g, '') === 'benefitstate,subgroup') {
            this.parameterElementsArray.filter(e => {
  
              if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'sitesbenefitstates') {
                e.selectedData = [];
                // this.requestPayloadParameterObj.parameters[e.label] = '';
                e.visible = true;
                e.loading = true;
                this.myRepoOutputService.getWCBenefitList(this.reportId, this.clientId).subscribe((response: any) => {
                  e.loading = false;
                  response?.body?.forEach(c => {
                    c.label = c.benefitState;
                    c.value = c.ncciStateJurisdictionCode;
                    c.selected = false;
                  })
                  e.state.options = response?.body;
                })
  
              }
              if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroup') {
                e.selectedData = [];
                // this.requestPayloadParameterObj.parameters[e.label] = '';
                e.state.options = [];
                e.visible = true;
                e.loading = true;
                let updatedArray = []
                this.myRepoOutputService.getWCSubgroup(this.reportId, this.clientId).subscribe((response: any) => {
                  e.loading = false;
                  response?.body?.forEach(c => {
                    if (c.displayName !== null && c.value !== null) {
                      updatedArray.push({
                        label: c.displayName ? c.displayName : '',
                        value: c.value,
                        selected: false
                      });
                    }
                  });
                  e.state.options = updatedArray;
                  e.state.options.forEach(d => { if (d.value.toLowerCase() == 'restricted') { this.restricted = true } })
                    if (this.restricted) {
                      e.mandatory = true;
                      e.disabled = true;
                      e.paramElementFilledBool = true;
                      e.selectedData = e.state.options[0].value;
                      this.SubGroupId=e.state.options[0].value;
                      this.requestPayloadParameterObj.parameters[e.id] = e.state.options[0].value;
                      this.parameterElementsArray.filter(e => {
                        if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroupvalues') {
                          // if (this.restricted) {
                            e.visible = true;
                            e.loading = true
                            let updatedArray = [];
                            const selectedValues = []
                            e.selectedData = [];
                            this.requestPayloadParameterObj.parameters[e.id] = [];
                            this.myRepoOutputService.getSubGroupValue(this.reportId, this.clientId, this.SubGroupId, this.SelectedRequestedPage).subscribe((response: any) => {
                              e.loading = false;
                              response?.body?.forEach(c => {
                                if (c.displayName !== null && c.value !== null) {
                                  updatedArray.push({
                                    label: c.displayName ? c.displayName : '',
                                    value: c.value,
                                    selected: true
                                  });
                                }
                                selectedValues.push(c.value)
                              });
                              e.state.options = updatedArray;
  
                              e.mandatory = true;
                              e.disabled = true;
                              e.paramElementFilledBool = true;
                              e.selectedData = selectedValues;
                              this.requestPayloadParameterObj.parameters[e.id] = selectedValues;
                            })
                        }
                      })
                    }
                })
              }
              if (e.id.toLowerCase() == 'policies') { e.selectedData = []; e.visible = false;  e.paramElementFilledBool=false;delete this.requestPayloadParameterObj.parameters[e.id]; }
              if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroupvalues') { e.selectedData = []; e.visible = false;  e.paramElementFilledBool=false;delete this.requestPayloadParameterObj.parameters[e.id]; }
            })
          }
          if (this.runReport1.toLowerCase().replace(/[_ ]/g, '') === 'policy,site,subgroup') {
            this.parameterElementsArray.filter(e => {
              if (e.id.toLowerCase() == 'policies') {
                // this.requestPayloadParameterObj.parameters[e.label] = '';
                e.selectedData = [];
                e.visible = true;
                e.loading = true;
                this.myRepoOutputService.getWCPolicyList(this.reportId, this.clientId).subscribe((response: any) => {
                  e.loading = false;
                  response?.body?.forEach(c => {
                    c.value = c.policyNumber;
                    c.label = c.policyNumber + '-' + c.effectiveDate + '-' + c.expiryDate;
                    c.selected = false;
                  })
                  e.state.options = response?.body;
                })
  
              }
              if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'sitesbenefitstates') {
                // this.requestPayloadParameterObj.parameters[e.label] = '';
                e.selectedData = [];
                e.visible = true;
                e.loading = true;
                this.myRepoOutputService.getWCSiteList(this.reportId, this.clientId).subscribe((response: any) => {
                  e.loading = false;
                  response?.body?.forEach(c => {
                    c.label = + c.siteCode + '-' + c.siteDesc;
                    c.value = c.siteCode;
                    c.selected = false;
                  })
                  e.state.options = response?.body;
                })
  
              }
              if (e.id.toLowerCase() == 'subgroup') {
                e.selectedData = [];
                // this.requestPayloadParameterObj.parameters[e.label] = '';
                e.state.options = [];
                e.visible = true;
                e.loading = true;
                let updatedArray = []
                this.myRepoOutputService.getWCSubgroup(this.reportId, this.clientId).subscribe((response: any) => {
                  e.loading = false;
                  response?.body?.forEach(c => {
                    if (c.displayName !== null && c.value !== null) {
                      updatedArray.push({
                        label: c.displayName ? c.displayName : '',
                        value: c.value,
                        selected: false
                      });
                    }
                  });
                  e.state.options = updatedArray;
                  e.state.options.forEach(d => { if (d.value.toLowerCase() == 'restricted') { this.restricted = true } })
                    if (this.restricted) {
                      e.mandatory = true;
                      e.disabled = true;
                      e.paramElementFilledBool = true;
                      e.selectedData = e.state.options[0].value;
                      this.SubGroupId=e.state.options[0].value;
                      this.requestPayloadParameterObj.parameters[e.id] = e.state.options[0].value;
                      this.parameterElementsArray.filter(e => {
                        if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroupvalues') {
                          // if (this.restricted) {
                            e.visible = true;
                            e.loading = true
                            let updatedArray = [];
                            const selectedValues = []
                            e.selectedData = [];
                            this.requestPayloadParameterObj.parameters[e.id] = [];
                            this.myRepoOutputService.getSubGroupValue(this.reportId, this.clientId, this.SubGroupId, this.SelectedRequestedPage).subscribe((response: any) => {
                              e.loading = false;
                              response?.body?.forEach(c => {
                                if (c.displayName !== null && c.value !== null) {
                                  updatedArray.push({
                                    label: c.displayName ? c.displayName : '',
                                    value: c.value,
                                    selected: true
                                  });
                                }
                                selectedValues.push(c.value)
                              });
                              e.state.options = updatedArray;
  
                              e.mandatory = true;
                              e.disabled = true;
                              e.paramElementFilledBool = true;
                              e.selectedData = selectedValues;
                              this.requestPayloadParameterObj.parameters[e.id] = selectedValues;
                            })
                        }
                      })
                    }
                })
              }
  
              if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroupvalues') { e.selectedData = []; e.visible = false;  e.paramElementFilledBool=false;delete this.requestPayloadParameterObj.parameters[e.id]; }
            })
          }
        }
       
      }
      if (this.parameterElementsArray[index].id.toLowerCase().replace(/[_ ]/g, '') == 'runreportby') {
        this.runReport1 = event.value;
        if (event.value.toLowerCase().replace(/[_ ]/g, '') === 'policyonly') {
          this.parameterElementsArray.filter(e => {
            if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'policies') {
              // this.requestPayloadParameterObj.parameters[e.label] = '';
              e.selectedData = [];
              e.visible = true;
              e.loading = true;
              this.myRepoOutputService.getWCPolicyList(this.reportId, this.clientId).subscribe((response: any) => {
                e.loading = false;
                response?.body?.forEach(c => {
                  c.value = c.policyNumber;
                  c.label = c.policyNumber + '-' + c.effectiveDate + '-' + c.expiryDate;
                  c.selected = false;
                })
                e.state.options = response?.body;
              })

            }
            if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'sitesbenefitstates') { e.selectedData = []; e.visible = false;  e.paramElementFilledBool=false;delete this.requestPayloadParameterObj.parameters[e.id]; }
            if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroup') { e.selectedData = []; e.visible = false;  e.paramElementFilledBool=false;delete this.requestPayloadParameterObj.parameters[e.id]; }
            if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroupvalues') { e.selectedData = []; e.visible = false;  e.paramElementFilledBool=false;delete this.requestPayloadParameterObj.parameters[e.id]; }
          })
        }
        if (event.value.toLowerCase().replace(/[_ ]/g, '') === 'siteonly') {
          this.parameterElementsArray.filter(e => {
            if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'sitesbenefitstates') {
              e.selectedData = [];
              // this.requestPayloadParameterObj.parameters[e.label] = '';
              e.visible = true;
              e.loading = true;
              this.myRepoOutputService.getWCSiteList(this.reportId, this.clientId).subscribe((response: any) => {
                e.loading = false;
                response?.body?.forEach(c => {
                  c.label = c.siteCode + '-' + c.siteDesc;
                  c.value = c.siteCode;
                  c.selected = false;
                })
                e.state.options = response?.body;
              })

            }
            if (e.id.toLowerCase() == 'policies') { e.selectedData = []; e.visible = false;  e.paramElementFilledBool=false;delete this.requestPayloadParameterObj.parameters[e.id]; }
            if (e.id.toLowerCase() == 'subgroup') { e.selectedData = []; e.visible = false;  e.paramElementFilledBool=false;delete this.requestPayloadParameterObj.parameters[e.id]; }
            if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroupvalues') { e.selectedData = []; e.visible = false;  e.paramElementFilledBool=false;delete this.requestPayloadParameterObj.parameters[e.id]; }
          })
        }
        if (event.value.toLowerCase().replace(/[_ ]/g, '') === 'benefitstateonly') {
          this.parameterElementsArray.filter(e => {
            if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'sitesbenefitstates') {
              // this.requestPayloadParameterObj.parameters[e.label] = '';
              e.selectedData = [];
              e.visible = true;
              e.loading = true;
              this.myRepoOutputService.getWCBenefitList(this.reportId, this.clientId).subscribe((response: any) => {
                e.loading = false;
                response?.body?.forEach(c => {
                  c.label = c.benefitState;
                  c.value = c.ncciStateJurisdictionCode;
                  c.selected = false;
                })
                e.state.options = response?.body;
              })
            }
            if (e.id.toLowerCase() == 'policies') { e.selectedData = []; e.visible = false;  e.paramElementFilledBool=false;delete this.requestPayloadParameterObj.parameters[e.id]; }
            if (e.id.toLowerCase() == 'subgroup') { e.selectedData = []; e.visible = false;  e.paramElementFilledBool=false;delete this.requestPayloadParameterObj.parameters[e.id]; }
            if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroupvalues') { e.selectedData = []; e.visible = false;  e.paramElementFilledBool=false;delete this.requestPayloadParameterObj.parameters[e.id]; }
          })
        }
        if (event.value.toLowerCase().replace(/[_ ]/g, '') === 'policy,benefitstate') {
          this.parameterElementsArray.filter(e => {
            if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'policies') {
              // this.requestPayloadParameterObj.parameters[e.label] = '';
              e.selectedData = [];
              e.visible = true;
              e.loading = true;
              this.myRepoOutputService.getWCPolicyList(this.reportId, this.clientId).subscribe((response: any) => {
                e.loading = false;
                response?.body?.forEach(c => {
                  c.value = c.policyNumber;
                  c.label = c.policyNumber + '-' + c.effectiveDate + '-' + c.expiryDate;
                  c.selected = false;
                })
                e.state.options = response?.body;
              })

            }
            if (e.id.toLowerCase().replace(/[_ ]/g, '') === 'sitesbenefitstates') {
              e.selectedData = [];
              // this.requestPayloadParameterObj.parameters[e.label] = '';
              e.visible = true;
              e.loading = true;
              this.myRepoOutputService.getWCBenefitList(this.reportId, this.clientId).subscribe((response: any) => {
                e.loading = false;
                response?.body?.forEach(c => {
                  c.label = c.benefitState;
                  c.value = c.ncciStateJurisdictionCode;
                  c.selected = false;
                })
                e.state.options = response?.body;
              })

            }
            if (e.id.toLowerCase() === 'subgroup') { e.selectedData = []; e.visible = false;  e.paramElementFilledBool=false;delete this.requestPayloadParameterObj.parameters[e.id]; }
            if (e.id.toLowerCase().replace(/[_ ]/g, '') === 'subgroupvalues') { e.selectedData = []; e.visible = false;  e.paramElementFilledBool=false;delete this.requestPayloadParameterObj.parameters[e.id]; }
          })
        }
        if (event.value.toLowerCase().replace(/[_ ]/g, '') === 'policy,site') {
          this.parameterElementsArray.filter(e => {
            if (e.id.toLowerCase() == 'policies') {
              e.selectedData = [];
              // this.requestPayloadParameterObj.parameters[e.label] = '';
              e.visible = true;
              e.loading = true;
              this.myRepoOutputService.getWCPolicyList(this.reportId, this.clientId).subscribe((response: any) => {
                e.loading = false;
                response?.body?.forEach(c => {
                  c.value = c.policyNumber;
                  c.label = c.policyNumber + '-' + c.effectiveDate + '-' + c.expiryDate;
                  c.selected = false;
                })
                e.state.options = response?.body;
              })

            }
            if (e.id.toLowerCase().replace(/[_ ]/g, '') === 'sitesbenefitstates') {
              e.selectedData = [];
              // this.requestPayloadParameterObj.parameters[e.label] = '';
              e.visible = true;
              e.loading = true;
              this.myRepoOutputService.getWCSiteList(this.reportId, this.clientId).subscribe((response: any) => {
                e.loading = false;
                response?.body?.forEach(c => {
                  c.label = c.siteCode + '-' + c.siteDesc;
                  c.value = c.siteCode;
                  c.selected = false;
                })
                e.state.options = response?.body;
              })

            }
            if (e.id.toLowerCase() == 'subgroup') { e.selectedData = []; e.visible = false;  e.paramElementFilledBool=false;delete this.requestPayloadParameterObj.parameters[e.id]; }
            if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroupvalues') { e.selectedData = []; e.visible = false;  e.paramElementFilledBool=false;delete this.requestPayloadParameterObj.parameters[e.id]; }
          })
        }
        if (event.value.toLowerCase().replace(/[_ ]/g, '') === 'policy,benefitstate,subgroup') {
          this.parameterElementsArray.filter(e => {
            if (e.id.toLowerCase() == 'policies') {
              // this.requestPayloadParameterObj.parameters[e.label] = '';
              e.selectedData = [];
              e.visible = true;
              e.loading = true;
              this.myRepoOutputService.getWCPolicyList(this.reportId, this.clientId).subscribe((response: any) => {
                e.loading = false;
                response?.body?.forEach(c => {
                  c.value = c.policyNumber;
                  c.label = c.policyNumber + '-' + c.effectiveDate + '-' + c.expiryDate;
                  c.selected = false;
                })
                e.state.options = response?.body;
              })

            }
            if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'sitesbenefitstates') {
              e.selectedData = [];
              e.visible = true;
              // this.requestPayloadParameterObj.parameters[e.label] = '';
              e.loading = true;
              this.myRepoOutputService.getWCBenefitList(this.reportId, this.clientId).subscribe((response: any) => {
                e.loading = false;
                response?.body?.forEach(c => {
                  c.label = c.benefitState;
                  c.value = c.ncciStateJurisdictionCode;
                  c.selected = false;
                })
                e.state.options = response?.body;
              })

            }
            if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroup') {
              e.selectedData = [];
              // this.requestPayloadParameterObj.parameters[e.label] = '';
              e.state.options = [];
              e.visible = true;
              e.loading = true;
              let updatedArray = []
              this.myRepoOutputService.getWCSubgroup(this.reportId, this.clientId).subscribe((response: any) => {
                e.loading = false;
                response?.body?.forEach(c => {
                  if (c.displayName !== null && c.value !== null) {
                    updatedArray.push({
                      label: c.displayName ? c.displayName : '',
                      value: c.value,
                      selected: false
                    });
                  }
                });
                e.state.options = updatedArray;
                e.state.options.forEach(d => { if (d.value.toLowerCase() == 'restricted') { this.restricted = true } })
                  if (this.restricted) {
                    e.mandatory = true;
                    e.disabled = true;
                    e.paramElementFilledBool = true;
                    e.selectedData = e.state.options[0].value;
                    this.SubGroupId=e.state.options[0].value;
                    this.requestPayloadParameterObj.parameters[e.id] = e.state.options[0].value;
                    this.parameterElementsArray.filter(e => {
                      if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroupvalues') {
                        // if (this.restricted) {
                          e.visible = true;
                          e.loading = true
                          let updatedArray = [];
                          const selectedValues = []
                          e.selectedData = [];
                          this.requestPayloadParameterObj.parameters[e.id] = [];
                          this.myRepoOutputService.getSubGroupValue(this.reportId, this.clientId, this.SubGroupId, this.SelectedRequestedPage).subscribe((response: any) => {
                            e.loading = false;
                            response?.body?.forEach(c => {
                              if (c.displayName !== null && c.value !== null) {
                                updatedArray.push({
                                  label: c.displayName ? c.displayName : '',
                                  value: c.value,
                                  selected: true
                                });
                              }
                              selectedValues.push(c.value)
                            });
                            e.state.options = updatedArray;

                            e.mandatory = true;
                            e.disabled = true;
                            e.paramElementFilledBool = true;
                            e.selectedData = selectedValues;
                            this.requestPayloadParameterObj.parameters[e.id] = selectedValues;
                          })
                      }
                    })
                  }
              })
            }
            if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroupvalues') { e.selectedData = []; e.visible = false;  e.paramElementFilledBool=false;delete this.requestPayloadParameterObj.parameters[e.id]; }
          })
        }
        if (event.value.toLowerCase().replace(/[_ ]/g, '') === 'site,subgroup') {
          this.parameterElementsArray.filter(e => {
            if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'sitesbenefitstates') {
              // this.requestPayloadParameterObj.parameters[e.label] = '';
              e.selectedData = [];
              e.visible = true;
              e.loading = true;
              this.myRepoOutputService.getWCSiteList(this.reportId, this.clientId).subscribe((response: any) => {
                e.loading = false;
                response?.body?.forEach(c => {
                  c.label = + c.siteCode + '-' + c.siteDesc;
                  c.value = c.siteCode;
                  c.selected = false;
                })
                e.state.options = response?.body;
              })

            }
            if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroup') {
              e.selectedData = [];
              // this.requestPayloadParameterObj.parameters[e.label] = '';
              e.state.options = [];
              e.visible = true;
              e.loading = true;
              let updatedArray = []
              this.myRepoOutputService.getWCSubgroup(this.reportId, this.clientId).subscribe((response: any) => {
                e.loading = false;
                response?.body?.forEach(c => {
                  if (c.displayName !== null && c.value !== null) {
                    updatedArray.push({
                      label: c.displayName ? c.displayName : '',
                      value: c.value,
                      selected: false
                    });
                  }
                });
                e.state.options = updatedArray;
                e.state.options.forEach(d => { if (d.value.toLowerCase() == 'restricted') { this.restricted = true } })
                  if (this.restricted) {
                    e.mandatory = true;
                    e.disabled = true;
                    e.paramElementFilledBool = true;
                    e.selectedData = e.state.options[0].value;
                    this.SubGroupId=e.state.options[0].value;
                    this.requestPayloadParameterObj.parameters[e.id] = e.state.options[0].value;
                    this.parameterElementsArray.filter(e => {
                      if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroupvalues') {
                        // if (this.restricted) {
                          e.visible = true;
                          e.loading = true
                          let updatedArray = [];
                          const selectedValues = []
                          e.selectedData = [];
                          this.requestPayloadParameterObj.parameters[e.id] = [];
                          this.myRepoOutputService.getSubGroupValue(this.reportId, this.clientId, this.SubGroupId, this.SelectedRequestedPage).subscribe((response: any) => {
                            e.loading = false;
                            response?.body?.forEach(c => {
                              if (c.displayName !== null && c.value !== null) {
                                updatedArray.push({
                                  label: c.displayName ? c.displayName : '',
                                  value: c.value,
                                  selected: true
                                });
                              }
                              selectedValues.push(c.value)
                            });
                            e.state.options = updatedArray;

                            e.mandatory = true;
                            e.disabled = true;
                            e.paramElementFilledBool = true;
                            e.selectedData = selectedValues;
                            this.requestPayloadParameterObj.parameters[e.id] = selectedValues;
                          })
                      }
                    })
                  }
              })
            }
            if (e.id.toLowerCase() == 'policies') { e.selectedData = []; e.visible = false;  e.paramElementFilledBool=false;delete this.requestPayloadParameterObj.parameters[e.id]; }
            if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroupvalues') { e.selectedData = []; e.visible = false;  e.paramElementFilledBool=false;delete this.requestPayloadParameterObj.parameters[e.id]; }
          })
        }
        if (event.value.toLowerCase().replace(/[_ ]/g, '') === 'benefitstate,subgroup') {
          this.parameterElementsArray.filter(e => {

            if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'sitesbenefitstates') {
              e.selectedData = [];
              // this.requestPayloadParameterObj.parameters[e.label] = '';
              e.visible = true;
              e.loading = true;
              this.myRepoOutputService.getWCBenefitList(this.reportId, this.clientId).subscribe((response: any) => {
                e.loading = false;
                response?.body?.forEach(c => {
                  c.label = c.benefitState;
                  c.value = c.ncciStateJurisdictionCode;
                  c.selected = false;
                })
                e.state.options = response?.body;
              })

            }
            if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroup') {
              e.selectedData = [];
              // this.requestPayloadParameterObj.parameters[e.label] = '';
              e.state.options = [];
              e.visible = true;
              e.loading = true;
              let updatedArray = []
              this.myRepoOutputService.getWCSubgroup(this.reportId, this.clientId).subscribe((response: any) => {
                e.loading = false;
                response?.body?.forEach(c => {
                  if (c.displayName !== null && c.value !== null) {
                    updatedArray.push({
                      label: c.displayName ? c.displayName : '',
                      value: c.value,
                      selected: false
                    });
                  }
                });
                e.state.options = updatedArray;
                e.state.options.forEach(d => { if (d.value.toLowerCase() == 'restricted') { this.restricted = true } })
                  if (this.restricted) {
                    e.mandatory = true;
                    e.disabled = true;
                    e.paramElementFilledBool = true;
                    e.selectedData = e.state.options[0].value;
                    this.SubGroupId=e.state.options[0].value;
                    this.requestPayloadParameterObj.parameters[e.id] = e.state.options[0].value;
                    this.parameterElementsArray.filter(e => {
                      if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroupvalues') {
                        // if (this.restricted) {
                          e.visible = true;
                          e.loading = true
                          let updatedArray = [];
                          const selectedValues = []
                          e.selectedData = [];
                          this.requestPayloadParameterObj.parameters[e.id] = [];
                          this.myRepoOutputService.getSubGroupValue(this.reportId, this.clientId, this.SubGroupId, this.SelectedRequestedPage).subscribe((response: any) => {
                            e.loading = false;
                            response?.body?.forEach(c => {
                              if (c.displayName !== null && c.value !== null) {
                                updatedArray.push({
                                  label: c.displayName ? c.displayName : '',
                                  value: c.value,
                                  selected: true
                                });
                              }
                              selectedValues.push(c.value)
                            });
                            e.state.options = updatedArray;

                            e.mandatory = true;
                            e.disabled = true;
                            e.paramElementFilledBool = true;
                            e.selectedData = selectedValues;
                            this.requestPayloadParameterObj.parameters[e.id] = selectedValues;
                          })
                      }
                    })
                  }
              })
            }
            if (e.id.toLowerCase() == 'policies') { e.selectedData = []; e.visible = false;  e.paramElementFilledBool=false;delete this.requestPayloadParameterObj.parameters[e.id]; }
            if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroupvalues') { e.selectedData = []; e.visible = false;  e.paramElementFilledBool=false;delete this.requestPayloadParameterObj.parameters[e.id]; }
          })
        }
        if (event.value.toLowerCase().replace(/[_ ]/g, '') === 'policy,site,subgroup') {
          this.parameterElementsArray.filter(e => {
            if (e.id.toLowerCase() == 'policies') {
              // this.requestPayloadParameterObj.parameters[e.label] = '';
              e.selectedData = [];
              e.visible = true;
              e.loading = true;
              this.myRepoOutputService.getWCPolicyList(this.reportId, this.clientId).subscribe((response: any) => {
                e.loading = false;
                response?.body?.forEach(c => {
                  c.value = c.policyNumber;
                  c.label = c.policyNumber + '-' + c.effectiveDate + '-' + c.expiryDate;
                  c.selected = false;
                })
                e.state.options = response?.body;
              })

            }
            if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'sitesbenefitstates') {
              // this.requestPayloadParameterObj.parameters[e.label] = '';
              e.selectedData = [];
              e.visible = true;
              e.loading = true;
              this.myRepoOutputService.getWCSiteList(this.reportId, this.clientId).subscribe((response: any) => {
                e.loading = false;
                response?.body?.forEach(c => {
                  c.label = + c.siteCode + '-' + c.siteDesc;
                  c.value = c.siteCode;
                  c.selected = false;
                })
                e.state.options = response?.body;
              })

            }
            if (e.id.toLowerCase() == 'subgroup') {
              e.selectedData = [];
              // this.requestPayloadParameterObj.parameters[e.label] = '';
              e.state.options = [];
              e.visible = true;
              e.loading = true;
              let updatedArray = []
              this.myRepoOutputService.getWCSubgroup(this.reportId, this.clientId).subscribe((response: any) => {
                e.loading = false;
                response?.body?.forEach(c => {
                  if (c.displayName !== null && c.value !== null) {
                    updatedArray.push({
                      label: c.displayName ? c.displayName : '',
                      value: c.value,
                      selected: false
                    });
                  }
                });
                e.state.options = updatedArray;
                e.state.options.forEach(d => { if (d.value.toLowerCase() == 'restricted') { this.restricted = true } })
                  if (this.restricted) {
                    e.mandatory = true;
                    e.disabled = true;
                    e.paramElementFilledBool = true;
                    e.selectedData = e.state.options[0].value;
                    this.SubGroupId=e.state.options[0].value;
                    this.requestPayloadParameterObj.parameters[e.id] = e.state.options[0].value;
                    this.parameterElementsArray.filter(e => {
                      if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroupvalues') {
                        // if (this.restricted) {
                          e.visible = true;
                          e.loading = true
                          let updatedArray = [];
                          const selectedValues = []
                          e.selectedData = [];
                          this.requestPayloadParameterObj.parameters[e.id] = [];
                          this.myRepoOutputService.getSubGroupValue(this.reportId, this.clientId, this.SubGroupId, this.SelectedRequestedPage).subscribe((response: any) => {
                            e.loading = false;
                            response?.body?.forEach(c => {
                              if (c.displayName !== null && c.value !== null) {
                                updatedArray.push({
                                  label: c.displayName ? c.displayName : '',
                                  value: c.value,
                                  selected: true
                                });
                              }
                              selectedValues.push(c.value)
                            });
                            e.state.options = updatedArray;

                            e.mandatory = true;
                            e.disabled = true;
                            e.paramElementFilledBool = true;
                            e.selectedData = selectedValues;
                            this.requestPayloadParameterObj.parameters[e.id] = selectedValues;
                          })
                      }
                    })
                  }
              })
            }

            if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroupvalues') { e.selectedData = []; e.visible = false;  e.paramElementFilledBool=false;delete this.requestPayloadParameterObj.parameters[e.id]; }
          })
        }
      }
      if (this.parameterElementsArray[index].id.toLowerCase().replace(/[_ ]/g, '') == 'subgroup') {
        let dat = this.parameterElementsArray[index].state.options?.filter((t) => t.value === event.value)
        this.SubGroupId = dat[0]?.value;
        this.parameterElementsArray.filter(e => {
          if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroupvalues') {
            e.visible = true;
            e.loading = true;
            let updatedArray = [];
            e.selectedData = [];
            this.requestPayloadParameterObj.parameters[e.id] = [];
            this.myRepoOutputService.getSubGroupValue(this.reportId, this.clientId, this.SubGroupId, this.SelectedRequestedPage).subscribe((response: any) => {
              e.loading = false;
              response?.body?.forEach(c => {
                if (c.displayName !== null && c.value !== null) {
                  updatedArray.push({
                    label: c.displayName ? c.displayName : '',
                    value: c.value,
                    selected: false
                  });
                }
              });
              e.state.options = updatedArray;
            })
          }
        })
      }
    }
    if (this.SelectedRequestedPage == 'WC_All') {
      if (this.parameterElementsArray[index].id.toLowerCase().replace(/[_ ]/g, '') == 'clients') {
        this.clientId = '';
        let dat = this.parameterElementsArray[index].state.options?.filter((t) => t.value === event.value)
        this.clientId = dat[0]?.value;
        this.parameterElementsArray.filter(e => {
        if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'policies' ||e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroup' ||e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroupvalues')
          { e.selectedData = []; e.visible = false;  e.paramElementFilledBool=false;delete this.requestPayloadParameterObj.parameters[e.id]; }
      })
        if(this.runReport2!=''){
          if (this.runReport2.toLowerCase().replace(/[_ ]/g, '') === 'allpolicy') {
            this.parameterElementsArray.filter(e => {
              if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroup') {
                e.selectedData = [];
                // this.requestPayloadParameterObj.parameters[e.label] = '';
                e.state.options = [];
                e.visible = true;
                e.loading = true;
                let updatedArray = []
                this.myRepoOutputService.getWCSubgroup(this.reportId, this.clientId).subscribe((response: any) => {
                  e.loading = false;
                  response?.body?.forEach(c => {
                    if (c.displayName !== null && c.value !== null) {
                      updatedArray.push({
                        label: c.displayName ? c.displayName : '',
                        value: c.value,
                        selected: false
                      });
                    }
                  });
                  e.state.options = updatedArray;
                  e.state.options.forEach(d => { if (d.value.toLowerCase() == 'restricted') { this.restricted = true } })
                    if (this.restricted) {
                      e.mandatory = true;
                      e.disabled = true;
                      e.paramElementFilledBool = true;
                      e.selectedData = e.state.options[0].value;
                      this.SubGroupId=e.state.options[0].value;
                      this.requestPayloadParameterObj.parameters[e.id] = e.state.options[0].value;
                      this.parameterElementsArray.filter(e => {
                        if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroupvalues') {
                          // if (this.restricted) {
                            e.visible = true;
                            e.loading = true
                            let updatedArray = [];
                            const selectedValues = []
                            e.selectedData = [];
                            this.requestPayloadParameterObj.parameters[e.id] = [];
                            this.myRepoOutputService.getSubGroupValue(this.reportId, this.clientId, this.SubGroupId, this.SelectedRequestedPage).subscribe((response: any) => {
                              e.loading = false;
                              response?.body?.forEach(c => {
                                if (c.displayName !== null && c.value !== null) {
                                  updatedArray.push({
                                    label: c.displayName ? c.displayName : '',
                                    value: c.value,
                                    selected: true
                                  });
                                }
                                selectedValues.push(c.value)
                              });
                              e.state.options = updatedArray;
  
                              e.mandatory = true;
                              e.disabled = true;
                              e.paramElementFilledBool = true;
                              e.selectedData = selectedValues;
                              this.requestPayloadParameterObj.parameters[e.id] = selectedValues;
                            })
                        }
                      })
                    }
                })
              }
              if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'policies' ||e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroupvalues')
                 { e.selectedData = []; e.visible = false;  e.paramElementFilledBool=false;delete this.requestPayloadParameterObj.parameters[e.id]; }
            })
          }
          if (this.runReport2.toLowerCase().replace(/[_ ]/g, '') === 'policyonly') {
            this.parameterElementsArray.filter(e => {
              if (e.id.toLowerCase() == 'policies') {
                e.selectedData = [];
                e.visible = true;
                e.loading = true;
                this.myRepoOutputService.getWCPolicyList(this.reportId, this.clientId).subscribe((response: any) => {
                  e.loading = false;
                  response?.body?.forEach(c => {
                    c.value = c.policyNumber;
                    c.label = c.policyNumber + '-' + c.effectiveDate + '-' + c.expiryDate;
                    c.selected = false;
                  })
                  e.state.options = response?.body;
                })
  
              }
              if (e.id.toLowerCase() == 'subgroup') {
                e.selectedData = [];
                // this.requestPayloadParameterObj.parameters[e.label] = '';
                e.state.options = [];
                e.visible = true;
                e.loading = true;
                let updatedArray = []
                this.myRepoOutputService.getWCSubgroup(this.reportId, this.clientId).subscribe((response: any) => {
                  e.loading = false;
                  response?.body?.forEach(c => {
                    if (c.displayName !== null && c.value !== null) {
                      updatedArray.push({
                        label: c.displayName ? c.displayName : '',
                        value: c.value,
                        selected: false
                      });
                    }
                  });
                  e.state.options = updatedArray;
                })
              }
              if(e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroupvalues'){
                { e.selectedData = []; e.visible = false;  e.paramElementFilledBool=false;delete this.requestPayloadParameterObj.parameters[e.id]; }
              }
            })
          }
        }
      }
      if (this.parameterElementsArray[index].id.toLowerCase().replace(/[_ ]/g, '') == 'runreportby') {
        this.parameterElementsArray.filter(e => {
        if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'policies' ||e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroup' ||e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroupvalues')
          { e.selectedData = []; e.visible = false;  e.paramElementFilledBool=false;delete this.requestPayloadParameterObj.parameters[e.id]; }
      })
        this.runReport2=event.value
        if (this.runReport2.toLowerCase().replace(/[_ ]/g, '') === 'allpolicy') {
          this.parameterElementsArray.filter(e => {
            if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroup') {
              e.selectedData = [];
              // this.requestPayloadParameterObj.parameters[e.label] = '';
              e.state.options = [];
              e.visible = true;
              e.loading = true;
              let updatedArray = []
              this.myRepoOutputService.getWCSubgroup(this.reportId, this.clientId).subscribe((response: any) => {
                e.loading = false;
                response?.body?.forEach(c => {
                  if (c.displayName !== null && c.value !== null) {
                    updatedArray.push({
                      label: c.displayName ? c.displayName : '',
                      value: c.value,
                      selected: false
                    });
                  }
                });
                e.state.options = updatedArray;
                e.state.options.forEach(d => { if (d.value.toLowerCase() == 'restricted') { this.restricted = true } })
                  if (this.restricted) {
                    e.mandatory = true;
                    e.disabled = true;
                    e.paramElementFilledBool = true;
                    e.selectedData = e.state.options[0].value;
                    this.SubGroupId=e.state.options[0].value;
                    this.requestPayloadParameterObj.parameters[e.id] = e.state.options[0].value;
                    this.parameterElementsArray.filter(e => {
                      if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroupvalues') {
                        // if (this.restricted) {
                          e.visible = true;
                          e.loading = true
                          let updatedArray = [];
                          const selectedValues = []
                          e.selectedData = [];
                          this.requestPayloadParameterObj.parameters[e.id] = [];
                          this.myRepoOutputService.getSubGroupValue(this.reportId, this.clientId, this.SubGroupId, this.SelectedRequestedPage).subscribe((response: any) => {
                            e.loading = false;
                            response?.body?.forEach(c => {
                              if (c.displayName !== null && c.value !== null) {
                                updatedArray.push({
                                  label: c.displayName ? c.displayName : '',
                                  value: c.value,
                                  selected: true
                                });
                              }
                              selectedValues.push(c.value)
                            });
                            e.state.options = updatedArray;

                            e.mandatory = true;
                            e.disabled = true;
                            e.paramElementFilledBool = true;
                            e.selectedData = selectedValues;
                            this.requestPayloadParameterObj.parameters[e.id] = selectedValues;
                          })
                      }
                    })
                  }
              })
            }
            if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'policies') { e.selectedData = []; e.visible = false;  e.paramElementFilledBool=false;delete this.requestPayloadParameterObj.parameters[e.id]; }
          })
        }
        if (this.runReport2.toLowerCase().replace(/[_ ]/g, '') === 'policyonly') {
          this.parameterElementsArray.filter(e => {
            if (e.id.toLowerCase() == 'policies') {
              e.selectedData = [];
              e.visible = true;
              e.loading = true;
              this.myRepoOutputService.getWCPolicyList(this.reportId, this.clientId).subscribe((response: any) => {
                e.loading = false;
                response?.body?.forEach(c => {
                  c.value = c.policyNumber;
                  c.label = c.policyNumber + '-' + c.effectiveDate + '-' + c.expiryDate;
                  c.selected = false;
                })
                e.state.options = response?.body;
              })

            }
            if (e.id.toLowerCase() == 'subgroup') {
              e.selectedData = [];
              // this.requestPayloadParameterObj.parameters[e.label] = '';
              e.state.options = [];
              e.visible = true;
              e.loading = true;
              let updatedArray = []
              this.myRepoOutputService.getWCSubgroup(this.reportId, this.clientId).subscribe((response: any) => {
                e.loading = false;
                response?.body?.forEach(c => {
                  if (c.displayName !== null && c.value !== null) {
                    updatedArray.push({
                      label: c.displayName ? c.displayName : '',
                      value: c.value,
                      selected: false
                    });
                  }
                });
                e.state.options = updatedArray;
              })
            }
          })
        }

      }
      if (this.parameterElementsArray[index].id.toLowerCase() == 'subgroup') {
        let dat = this.parameterElementsArray[index].state.options?.filter((t) => t.value === event.value)
        this.SubGroupId = dat[0]?.value;
        this.parameterElementsArray.filter(e => {
          if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroupvalues') {
            e.visible = true;
            e.loading = true;
            let updatedArray = [];
            e.selectedData = [];
            this.requestPayloadParameterObj.parameters[e.id] = [];
            this.myRepoOutputService.getSubGroupValue(this.reportId, this.clientId, this.SubGroupId, this.SelectedRequestedPage).subscribe((response: any) => {
              e.loading = false;
              response?.body?.forEach(c => {
                if (c.displayName !== null && c.value !== null) {
                  updatedArray.push({
                    label: c.displayName ? c.displayName : '',
                    value: c.value,
                    selected: false
                  });
                }
              });
              e.state.options = updatedArray;
            })
          }
        })
      }
    }
    if (this.SelectedRequestedPage == 'BCD') {
      if (this.parameterElementsArray[index].id.toLowerCase().replace(/[_ ]/g, '') == 'clientnames') {
        //this.clientId=[];
        let dat = this.parameterElementsArray[index].state.options.filter((t) => t.value === event.value)
        this.clientId = dat[0].value;

        this.parameterElementsArray.filter(e => {
          if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroup') {
            let updatedArray = [];
            e.visible = true;
            e.loading = true;
            this.myRepoOutputService.getBCDsubgroup(this.clientId, this.reportId).subscribe((response: any) => {
              e.loading = false;
              response?.body?.forEach(c => {
                if (c.displayName !== null && c.value !== null) {
                  updatedArray.push({
                    label: c.displayName ? c.displayName : '',
                    value: c.value,
                    selected: false
                  });
                }
              });
              e.state.options = updatedArray;
            })
            if (this.restricted) {
              e.mandatory = true;
              e.disabled = true;
              e.paramElementFilledBool = true;
              e.selectedData = e.state.options[0].value;
              this.requestPayloadParameterObj.parameters[e.id] = e.state.options[0].value;
            }
          }
          if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'loapolicy') {
            let updatedArray = [];
            e.visible = true;
            e.loading = true;
            this.myRepoOutputService.getBcdLOApolicy(this.clientId, this.reportId).subscribe((response: any) => {
              e.loading = false;
              response?.body?.forEach((c: any) => {
                if (c !== null) {
                  updatedArray.push({
                    label: c,
                    value: c,
                    selected: false
                  });
                }
              });
              e.state.options = updatedArray;
            })
          }
          if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'ltdpolicy') {
            let updatedArray = [];
            e.visible = true;
            e.loading = true;
            this.myRepoOutputService.getBcdLTDpolicy(this.clientId, this.reportId).subscribe((response: any) => {
              e.loading = false;
              response?.body?.forEach((c: any) => {
                if (c !== null) {
                  updatedArray.push({
                    label: c,
                    value: c,
                    selected: false
                  });
                }
              });
              e.state.options = updatedArray;
            })
          }
          if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'stdpolicy') {
            let updatedArray = [];
            e.visible = true;
            e.loading = true;
            this.myRepoOutputService.getBcdSTDpolicy(this.clientId, this.reportId).subscribe((response: any) => {
              e.loading = false;
              response?.body?.forEach((c: any) => {
                if (c !== null) {
                  updatedArray.push({
                    label: c,
                    value: c,
                    selected: false
                  });
                }
              });
              e.state.options = updatedArray;
            })
          }
          if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'wcpolicy') {
            let updatedArray = [];
            e.visible = true;
            e.loading = true;
            this.myRepoOutputService.getBcdWCpolicy(this.clientId, this.reportId).subscribe((response: any) => {
              e.loading = false;
              response?.body?.forEach((c: any) => {
                if (c !== null) {
                  updatedArray.push({
                    label: c,
                    value: c,
                    selected: false
                  });
                }
              });
              e.state.options = updatedArray;
            })
          }
          if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroupvalues') {
            if (this.restricted) {
              e.visible = true;
              let updatedArray = [];
              const selectedValues = []
              e.selectedData = [];
              this.requestPayloadParameterObj.parameters[e.id] = [];
              // e.loading=true
              this.myRepoOutputService.getBCDsubgroupValues(this.reportId, this.clientId, this.SubGroupId).subscribe((response: any) => {
                //e.loading=false;
                response?.body?.forEach((c: any) => {
                  if (c.displayName !== null && c.value !== null) {
                    updatedArray.push({
                      label: c.displayName ? c.displayName : '',
                      value: c.value,
                      selected: true
                    });
                  }
                  selectedValues.push(c.value);
                });
                e.state.options = updatedArray;
                e.mandatory = true;
                e.paramElementFilledBool = true;
                e.selectedData = selectedValues;
                this.requestPayloadParameterObj.parameters[e.id] = selectedValues;
              })
            }
            else{
            e.selectedData = []; e.visible = false; delete this.requestPayloadParameterObj.parameters[e.id] 
          } 
          }
          
        })
      }
      if (this.parameterElementsArray[index].id.toLowerCase().replace(/[_ ]/g, '') == 'subgroup') {
        this.SubGroupId = '';
        let dat = this.parameterElementsArray[index].state.options.filter((t) => t.value === event.value)
        this.SubGroupId = dat[0].value;
        this.parameterElementsArray.filter(e => {
          if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroupvalues') {
            e.visible = true;
            e.loading = true;
            let updatedArray = [];
            e.selectedData = [];
            this.requestPayloadParameterObj.parameters[e.id] = [];
            this.myRepoOutputService.getBCDsubgroupValues(this.clientId, this.reportId, this.SubGroupId).subscribe((response: any) => {
              e.loading = false;
              response?.body?.forEach(c => {
                if (c !== null) {
                  updatedArray.push({
                    label: c,
                    value: c,
                    selected: false
                  });
                }
              });
              e.state.options = updatedArray;
            })
          }
        })
      }
    }
    if (this.SelectedRequestedPage == 'PCR') {
      if (this.parameterElementsArray[index].id.toLowerCase().replace(/[_ ]/g, '') == 'clientcode') {
        //this.clientId=[];
        let dat = this.parameterElementsArray[index].state.options.filter((t) => t.value === event.value)
        this.clientId = dat[0].value;
        this.parameterElementsArray.filter(e => {
          if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'lineofbusiness') {
            e.selectedData = [];
            this.requestPayloadParameterObj.parameters[e.id] = '';
            e.state.options = [];
            e.visible = true;
            e.loading = true
            this.clientFlag = "C";
            this.parentFlag = "Y";
            let updatedArray = [];
            this.myRepoOutputService.getPcrLOBList(this.reportId, this.clientId, this.clientFlag, this.parentFlag).subscribe((response: any) => {
              e.loading = false;
              e.visible
              response?.body?.forEach((c: any) => {
                if (c !== null) {
                  updatedArray.push({
                    label: c,
                    value: c,
                    selected: false
                  });
                }
              });
              e.state.options = updatedArray;
            })
          }
          if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'bankaccountnumber') {
            e.selectedData = []; e.visible = false; delete this.requestPayloadParameterObj.parameters[e.id]
          }
        })
      }
      if (this.parameterElementsArray[index].id.toLowerCase().replace(/[_ ]/g, '') == 'lineofbusiness') {
        let dat = event.value;
        this.parameterElementsArray.filter(e => {
          if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'bankaccountnumber') {
            e.selectedData = [];
            this.requestPayloadParameterObj.parameters[e.id] = '';
            e.state.options = [];
            e.visible = true;
            e.loading = true
            let updatedArray = [];
            this.myRepoOutputService.getPcrBANList(this.reportId, this.clientId, dat).subscribe((response: any) => {
              e.loading = false;
              e.visible
              response?.body?.forEach((c: any) => {
                if (c.displayName !== null && c.value !== null) {
                  updatedArray.push({
                    label: c.displayName ? c.displayName : '',
                    value: c.value,
                    selected: false
                  });
                }
              });
              e.state.options = updatedArray;
            })
          }

        })
      }
    }
    if (this.SelectedRequestedPage == 'SC') {
      if (this.parameterElementsArray[index].id.toLowerCase().replace(/[_ ]/g, '') == 'clientnames') {
        //this.clientId=[];
        let dat = this.parameterElementsArray[index].state.options.filter((t) => t.value === event.value)
        this.clientId = dat[0].value;
        this.parameterElementsArray.filter(e => {
          if(e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroup' ||e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroupvalues'||e.id.toLowerCase().replace(/[_ ]/g, '') == 'loapolicy'||e.id.toLowerCase().replace(/[_ ]/g, '') == 'ltdpolicy'||e.id.toLowerCase().replace(/[_ ]/g, '') == 'stdpolicy'||e.id.toLowerCase().replace(/[_ ]/g, '') == 'wcpolicy')  
            {e.selectedData = []; e.visible = false;  e.paramElementFilledBool=false;delete this.requestPayloadParameterObj.parameters[e.id];}
        })
        this.parameterElementsArray.filter(e => {
          if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroup') {
            let updatedArray = [];
            e.visible = true;
            e.loading = true;
            this.myRepoOutputService.getSCsubgroup(this.clientId, this.reportId).subscribe((response: any) => {
              e.loading = false;
              response?.body?.forEach(c => {
                if (c.displayName !== null && c.value !== null) {
                  updatedArray.push({
                    label: c.displayName ? c.displayName : '',
                    value: c.value,
                    selected: false
                  });
                }
              });
              e.state.options = updatedArray;
            })
            if (this.restricted) {
              e.mandatory = true;
              e.disabled = true;
              e.paramElementFilledBool = true;
              e.selectedData = e.state.options[0].value;
              this.requestPayloadParameterObj.parameters[e.id] = e.state.options[0].value;
            }
          }
          if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'loapolicy') {
            let updatedArray = [];
            e.visible = true;
            e.loading = true;
            this.myRepoOutputService.getScLOApolicy(this.clientId, this.reportId).subscribe((response: any) => {
              e.loading = false;
              response?.body?.forEach((c: any) => {
                if (c !== null) {
                  updatedArray.push({
                    label: c,
                    value: c,
                    selected: false
                  });
                }
              });
              e.state.options = updatedArray;
            })
          }
          if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'ltdpolicy') {
            let updatedArray = [];
            e.visible = true;
            e.loading = true;
            this.myRepoOutputService.getScLTDpolicy(this.clientId, this.reportId).subscribe((response: any) => {
              e.loading = false;
              response?.body?.forEach((c: any) => {
                if (c.displayName !== null && c.value !== null) {
                  updatedArray.push({
                    label: c.displayName ? c.displayName : '',
                    value: c.value,
                    selected: false
                  });
                }
              });
              e.state.options = updatedArray;
            })
          }
          if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'stdpolicy') {
            let updatedArray = [];
            e.visible = true;
            e.loading = true;
            this.myRepoOutputService.getScSTDpolicy(this.clientId, this.reportId).subscribe((response: any) => {
              e.loading = false;
              response?.body?.forEach((c: any) => {
                if (c.displayName !== null && c.value !== null) {
                  updatedArray.push({
                    label: c.displayName ? c.displayName : '',
                    value: c.value,
                    selected: false
                  });
                }
              });
              e.state.options = updatedArray;
            })
          }
          if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroupvalues') {
            if (this.restricted) {
              e.visible = true;
              let updatedArray = [];
              const selectedValues = []
              e.selectedData = [];
              this.requestPayloadParameterObj.parameters[e.id] = [];
              // e.loading=true
              this.myRepoOutputService.getSCsubgroupValues(this.reportId, this.clientId, this.SubGroupId).subscribe((response: any) => {
                //e.loading=false;
                response?.body?.forEach((c: any) => {
                  if (c.displayName !== null && c.value !== null) {
                    updatedArray.push({
                      label: c.displayName ? c.displayName : '',
                      value: c.value,
                      selected: true
                    });
                  }
                  selectedValues.push(c.value);
                });
                e.state.options = updatedArray;
                e.mandatory = true;
                e.paramElementFilledBool = true;
                e.selectedData = selectedValues;
                this.requestPayloadParameterObj.parameters[e.id] = selectedValues;
              })
            }
            else{
            e.selectedData = []; e.visible = false; delete this.requestPayloadParameterObj.parameters[e.id] 
          } 
          }
        })
      }
      if (this.parameterElementsArray[index].id.toLowerCase().replace(/[_ ]/g, '') == 'subgroup') {
        this.SubGroupId = '';
        let dat = this.parameterElementsArray[index].state.options.filter((t) => t.value === event.value)
        this.SubGroupId = dat[0].value;
        this.parameterElementsArray.filter(e => {
          if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'subgroupvalues') {
            e.visible = true;
            e.loading = true;
            let updatedArray = [];
            e.selectedData = [];
            this.requestPayloadParameterObj.parameters[e.id] = [];
            this.myRepoOutputService.getSCsubgroupValues(this.clientId, this.reportId, this.SubGroupId).subscribe((response: any) => {
              e.loading = false;
              response?.body?.forEach(c => {
                if (c !== null) {
                  updatedArray.push({
                    label: c,
                    value: c,
                    selected: false
                  });
                }
              });
              e.state.options = updatedArray;
            })
          }
        })
      }
    }
    if (this.SelectedRequestedPage == 'PACS_All_Vendors2') {
      if (this.parameterElementsArray[index]?.id.toLowerCase().replace(/[_ ]/g, '') == 'clients') {
        //this.clientId=[];
        let dat = this.parameterElementsArray[index]?.state.options.filter((t) => t.value === event.value)
        this.clientId = dat[0].value;

        this.parameterElementsArray.filter(e => {
          if (e.id.toLowerCase().replace(/[_ ]/g, '') == 'policy') {
            let updatedArray = [];
            e.visible = true;
            e.loading = true;
            this.myRepoOutputService.getPACSV2policyt(this.clientId, this.reportId).subscribe((response: any) => {
              e.loading = false;
              response?.body?.forEach((c: any) => {
                updatedArray.push({
                  label: c.policyNumber + " - " + c.policyType + " - " + c.effectiveDate + " - " + c.expiryDate,
                  value: c.policyNumber + "|" + c.policyBON,
                  selected: false
                });
              });
              e.state.options = updatedArray;
            })
          }
        })
      }
    }
  }
  onFilterSubmitClick() {
    let value = 0
    let minDate, maxDate;
    let minDateName,maxDateName='';
    let dateError: Boolean = false;
    let showAlertBool: Boolean = false;
    let dateAlertBool: Boolean = false
    let arr = [];
    let arr1 = [];
    this.parameterElementsArray.forEach(d => {
      if (d.visible == true && d.mandatory == true && d.paramElementFilledBool != true) {
        arr.push(true);
        arr1.push(d.id)
      }
      else {
        arr.push(false)
      }
    })
    let checker = (arr) => arr.every((v) => v === false && v != undefined);
    if (checker(arr) == false) {
      showAlertBool = true;
    }
    this.parameterElementsArray.forEach((ele) => {
      // if (this.paramDates.length > 1) {
        if (((ele.type == "singleValueDate") &&(ele.id.toLowerCase().replace(/[_ ]/g, '') != "valuationdate"))) {
          if (ele.id.toLowerCase().replace(/[_ ]/g, '') == 'startdate'|| ele.id.toLowerCase().replace(/[_ ]/g, '') == 'fromdate') 
            { minDate = ele.selectedData[0] ;minDateName=ele.id}
          if (ele.id.toLowerCase().replace(/[_ ]/g, '') == 'enddate'|| ele.id.toLowerCase().replace(/[_ ]/g, '') == 'thrudate')
             { maxDate = ele.selectedData[0];maxDateName=ele.id }
        }
        if((ele.type != "singleValueDate") &&(ele.id.toLowerCase().replace(/[_ ]/g, '') == 'startdate' || ele.id.toLowerCase().replace(/[_ ]/g, '') == 'enddate' || ele.id.toLowerCase().replace(/[_ ]/g, '') == 'fromdate' || ele.id.toLowerCase().replace(/[_ ]/g, '') == 'thrudate')){
            if (ele.id.toLowerCase().replace(/[_ ]/g, '') == 'startdate'|| ele.id.toLowerCase().replace(/[_ ]/g, '') == 'fromdate') 
              { 
                minDate = ele.state.value;
                minDateName=ele.id
                ele.selectedData= ele.state.value;
              }
            if (ele.id.toLowerCase().replace(/[_ ]/g, '') == 'enddate'|| ele.id.toLowerCase().replace(/[_ ]/g, '') == 'thrudate')
              { 
                maxDate = ele.state.value;
                maxDateName=ele.id
                ele.selectedData= ele.state.value;
              }
        }
      // }
      // else {
      //   dateAlertBool = false;
      // }
    });
    if (new Date(minDate)> new Date(maxDate)) {
      dateAlertBool = true;
    }
    // if (this.paramDates.length > 0 && this.paramDates.length==2) {
      if (this.paramDates.length > 0) {
      if (this.paramDates[0].selectedData.length == 0 && this.paramDates[1].selectedData.length == 0) {
        dateError = true;
      }
      if (this.paramDates[0].selectedData[0] == "" && this.paramDates[1].selectedData[0] == "") {
        dateError = true;
      }
      if (this.paramDates[0].selectedData[0] == null && this.paramDates[1].selectedData[0] == null) {
        dateError = true;
      }
      if (this.paramDates[0].selectedData[0] == null && this.paramDates[1].selectedData[0]) {
        dateError = true;
      }
      if (this.paramDates[0].selectedData[0] && this.paramDates[1].selectedData[0] == null) {
        dateError = true;
      }
      if (this.paramDates[0].selectedData[0] && !this.paramDates[1].selectedData[0]) {
        dateError = true;
      } else if (this.paramDates[1].selectedData[0] && !this.paramDates[0].selectedData[0]) {
        dateError = true;
      }
    }
    if (showAlertBool == true) {
      this.addMessages(0);
    }
    if (dateError == true) {
      this.addMessages(0);
    }
    else if (dateAlertBool == true) {
      this.alertSeverity= 'error';
      this.alertMsg= minDateName +' Cannot Be Less Than '+maxDateName;
      this.addSingle();
    }
    else if (!dateAlertBool && !dateError && !showAlertBool) {
      this.clearMessages();
      this.sideMenuCollapse = !this.sideMenuCollapse;
      d3.select('.tibo-myrepo-output-spinner').style('display', 'block');
      d3.select('#myrepo-output-container')
        .transition()
        .delay(200)
        .duration(500)
        .style('left', '50px');
      d3.select('#report-criteria-id')
        .transition()
        .delay(100)
        .duration(100)
        .style({ left: '-25px', top: '85px' })
        .style('transform', 'rotate(-90deg)');
      this.repoCritPanelToggleBool = true;

      if (this.reportType.toLowerCase() == 'sql') {
        this.paginator.first = 0;
        this.paginatorPageNo = 1;
        this.showBirt = false;
        this.rowData = [];
        this.requestPayloadParameterObj['filterType'] = 'and';
        this.requestPayloadParameterObj['filters'] = [];
        this.requestPayloadParameterObj['sort'] = [];
        this.reportOutput = [];
        // this.parameterElementsArray.forEach((e) => {
        //   if (!e.visible) {
        //     this.requestPayloadParameterObj.parameters[e.id] = e.state.options[0].value;
        //   }
        // })
        this.getReportSerSubscrip = this.myRepoOutputService
          .getReportService(
            this.reportId,
            this.requestPayloadParameterObj,
            this.reportType,
            this.paginatorPageNo, this.docName
          )
          .subscribe(
            (response: any) => {
              // report parameters
              this.rowData = response?.body?.records ? response?.body?.records : [];
              this.startIndex = this.rowData.length > 0 ? 1 : 0;
              this.endIndex = this.rowData.length > 0 ? this.rowData.length : 0;
              this.totalRecords = response.body.totalRecords;
              // this.sqlTableColumns = response.body.headers;
              response.body.headers?.forEach((head, i) => {
                const dataType = head.dataType.toLowerCase();
                let type;
                switch (dataType) {
                  //"varchar","varchar2", "text", "longtext", "mediumtext", "bpchar", "char","character","character varying"
                  //"integer","int4","int8", "number", "serial", "numeric","int"
                  case 'string':
                    type = 'text';
                    break;
                  case 'varchar':
                    type = 'text';
                    break;
                  case 'varchar2':
                    type = 'text';
                    break;
                  case 'longtext':
                    type = 'text';
                    break;
                  case 'mediumtext':
                    type = 'text';
                    break;
                  case 'bpchar':
                    type = 'text';
                    break;
                  case 'character':
                    type = 'text';
                    break;
                  case 'bpchar':
                    type = 'text';
                    break;
                  case 'character varying':
                    type = 'text';
                    break;
                  //"timestamp", "date", "datetime", "timestamp without time zone","timestamptz","timestamp"
                  case 'datetime':
                    type = 'date';
                    break;
                  case 'timestamp':
                    type = 'date';
                    break;
                  case 'timestamp without time zone':
                    type = 'date';
                    break;
                  case 'timestamptz':
                    type = 'date';
                    break;
                  case 'timestamp':
                    type = 'date';
                    break;
                  //"integer","int4","int8", "number", "serial", "numeric","int"
                  case 'int':
                    type = 'number';
                    break;
                  case 'int4':
                    type = 'number';
                    break;
                  case 'int8':
                    type = 'number';
                    break;
                  case 'serial':
                    type = 'number';
                    break;
                  case 'numeric':
                    type = 'number';
                    break;
                  default:
                    type = dataType; // Fallback to the original dataType if not one of the specific cases
                    break;
                }
                let mappedColumn = {
                  headerName: head.header?.replace(/_/g, ' ')
                    .replace(/\b\w/g, (s) => s.toUpperCase()),
                  field: head.header,
                  checked: true,
                  type: type,
                  id: i,
                  selectedData: '',
                  selectedDataStart: '',
                  selectedDataLike: '',
                  selectedDataEnd: '',
                }
                this.coldata.push(mappedColumn);
                this.coldataTemp.push(mappedColumn.field);
                this.coldataSelectAll.push(mappedColumn);
              });
              d3.select('.tibo-myrepo-output-spinner').style('display', 'none'); // Hidding report output spinner
              document.getElementById('report-output-sql').style.display =
                'block';
              // document.getElementById('report-output-birt').style.display =
              //   'none';
              //d3.select('.tibo-myrepo-output-view-iframe').style('display', 'none');
              this.showDownloadBtn = true;
              //this.showDownloadBtn1 = false;
            },
            (error) => {
              d3.select('.tibo-myrepo-output-spinner').style('display', 'none'); // Hidding report output spinner
              this.alertSeverity = 'error';
              if (Number(error.status) == 401) {
                this.alertMsg = 'Please Try After Some Time!';
              } else if (Number(error.status) == 500) {
                this.alertMsg = 'Internal Error';
              } else {
                this.alertMsg = 'Please Try After Some Time.';
              }
              this.startIndex = 0;
              this.endIndex = 0;
              this.addSingle();
            },
            () => { }
          );
      } else {
        document.getElementById('report-output-sql').style.display =
          'none';
        this.showBirt = true;
        this.getReportSerSubscrip = this.myRepoOutputService
          .getReportService(
            this.reportId,
            this.requestPayloadParameterObj,
            this.reportType,
            1, this.docName
          )
          .subscribe(
            (response: any) => {
              // report parameters
              d3.select('.tibo-myrepo-output-spinner').style('display', 'none'); // Hidding report output spinner
              this.showDownloadBtn = true;
              //this.showDownloadBtn = false;
              this.birtDocumentName = response.body.document;
              this.reportOutputStr = response.body.output;
              this.tabs = response.body.totalPage;
              if (this.tabs > 1) {
                this.tabOptions = [];
                this.showTab = true;
                for (let i = 1; i <= this.tabs; i++) {
                  this.tabOptions.push({ label: 'Tab' + i, value: i })
                }
                this.selectedTab = this.tabOptions[0];
              }
              //iframe code change


              const iframe = document.getElementById(
                'report-output-birt'
              ) as HTMLIFrameElement;
              this.reportOutputStr = this.reportOutputStr.replace(
                /target=\"_self\"/gi,
                'target="_top"'
              );
              this.reportOutputStr = this.reportOutputStr.replace(
                /target=\'_self\'/gi,
                'target="_top"'
              );
              iframe.setAttribute('srcdoc', this.reportOutputStr);
              iframe.style.background = 'white';
              iframe.contentWindow.document.body.style.backgroundColor =
                'white';

              //iframe code change


              // const objectElement = document.getElementById('report-output-birt') as HTMLObjectElement;
              // this.reportOutputStr = this.reportOutputStr.replace(/target=\"_self\"/gi, 'target="_top"');
              // this.reportOutputStr = this.reportOutputStr.replace(/target=\'_self\'/gi, 'target="_top"');
              // const blob = new Blob([this.reportOutputStr], { type: 'text/html' });
              // const blobUrl = URL.createObjectURL(blob);
              // objectElement.setAttribute('data', blobUrl);    
              // objectElement.style.background = 'white';
              // objectElement.addEventListener('load', () => {
              //   const objectDoc = objectElement.contentDocument || objectElement.contentWindow?.document;
              //   if (objectDoc) {
              //     objectDoc.body.style.backgroundColor = 'white';
              //   }
              // });
            },
            (error) => {
              d3.select('.tibo-myrepo-output-spinner').style('display', 'none'); // Hidding report output spinner
              this.alertSeverity = 'error';
              if (Number(error.status) == 401) {
                this.alertMsg = 'Please Try After Some Time!';
              } else if (Number(error.status) == 500) {
                this.alertMsg = 'Internal Error';
              } else {
                this.alertMsg = 'Please Try After Some Time.';
              }

              this.addSingle();
            },
            () => { }
          );
      }
    }
  }
  getTab(val) {
    d3.select('.tibo-myrepo-output-view-iframe').style('display', 'none');
    d3.select('.tibo-myrepo-output-spinner').style('display', 'block');
    this.getReportSerSubscrip = this.myRepoOutputService
      .getReportService(
        this.reportId,
        this.requestPayloadParameterObj,
        this.reportType,
        val.value,
        this.docName
      )
      .subscribe(
        (response: any) => {
          // report parameters
          d3.select('.tibo-myrepo-output-spinner').style('display', 'none'); // Hidding report output spinner
          d3.select('.tibo-myrepo-output-view-iframe').style('display', 'block');
          this.showDownloadBtn = true;
          this.birtDocumentName = response.body.document;
          this.reportOutputStr = response.body.output;
          this.tabs = response.body.totalPage;
          //this.selectedTab=val.value;
          const iframe = document.getElementById(
            'report-output-birt'
          ) as HTMLIFrameElement;
          this.reportOutputStr = this.reportOutputStr.replace(
            /target=\"_self\"/gi,
            'target="_top"'
          );
          this.reportOutputStr = this.reportOutputStr.replace(
            /target=\'_self\'/gi,
            'target="_top"'
          );
          iframe.setAttribute('srcdoc', this.reportOutputStr);
          iframe.style.background = 'white';
          iframe.contentWindow.document.body.style.backgroundColor =
            'white';
        },
        (error) => {
          d3.select('.tibo-myrepo-output-spinner').style('display', 'none'); // Hidding report output spinner
          this.alertSeverity = 'error';
          if (Number(error.status) == 401) {
            this.alertMsg = 'Please Try After Some Time!';
          } else if (Number(error.status) == 500) {
            this.alertMsg = 'Internal Error';
          } else {
            this.alertMsg = 'Please Try After Some Time.';
          }

          this.addSingle();
        },
        () => { }
      );
  }
  getReportDataFunc() {
    d3.select('.tibo-myrepo-output-spinner').style('display', 'block'); // Showing report output spinner
    if (this.reportType.toLowerCase() == 'sql') {
      this.requestPayloadParameterObj['filterType'] = 'and';
      this.requestPayloadParameterObj['filters'] = [];
      this.requestPayloadParameterObj['sort'] = [];
      this.reportOutput = [];
      this.paginator.first = 0;
      this.paginatorPageNo = 1;
      this.getReportSerSubscrip = this.myRepoOutputService
        .getReportService(
          this.reportId,
          this.requestPayloadParameterObj,
          this.reportType,
          this.paginatorPageNo,
          this.docName
        )
        .subscribe(
          (response: any) => {
            // report parameters
            this.rowData = response?.body?.records ? response?.body?.records : [];
            this.startIndex = this.rowData.length > 0 ? 1 : 0;
            this.endIndex = this.rowData.length > 0 ? this.rowData.length : 0;
            this.totalRecords = response?.body?.totalRecords;
            // this.sqlTableColumns = response.body.headers;
            response.body.headers?.forEach((head, i) => {
              const dataType = head.dataType.toLowerCase();
              let type;
              switch (dataType) {
                //"varchar","varchar2", "text", "longtext", "mediumtext", "bpchar", "char","character","character varying"
                //"integer","int4","int8", "number", "serial", "numeric","int"
                case 'string':
                  type = 'text';
                  break;
                case 'varchar':
                  type = 'text';
                  break;
                case 'varchar2':
                  type = 'text';
                  break;
                case 'longtext':
                  type = 'text';
                  break;
                case 'mediumtext':
                  type = 'text';
                  break;
                case 'bpchar':
                  type = 'text';
                  break;
                case 'character':
                  type = 'text';
                  break;
                case 'bpchar':
                  type = 'text';
                  break;
                case 'character varying':
                  type = 'text';
                  break;
                //"timestamp", "date", "datetime", "timestamp without time zone","timestamptz","timestamp"
                case 'datetime':
                  type = 'date';
                  break;
                case 'timestamp':
                  type = 'date';
                  break;
                case 'timestamp without time zone':
                  type = 'date';
                  break;
                case 'timestamptz':
                  type = 'date';
                  break;
                case 'timestamp':
                  type = 'date';
                  break;
                //"integer","int4","int8", "number", "serial", "numeric","int"
                case 'int':
                  type = 'number';
                  break;
                case 'int4':
                  type = 'number';
                  break;
                case 'int8':
                  type = 'number';
                  break;
                case 'serial':
                  type = 'number';
                  break;
                case 'numeric':
                  type = 'number';
                  break;
                default:
                  type = dataType; // Fallback to the original dataType if not one of the specific cases
                  break;
              }
              let mappedColumn = {
                headerName: head.header?.replace(/_/g, ' ').replace(/\b\w/g, (s) => s.toUpperCase()),//head.header?.replace(/_/g, ' ').replace(/\b\w/g, (s) => s.toUpperCase()),//head.header.toUpperCase().replaceAll(' ','_'),
                field: head.header,
                checked: true,
                type: type,
                id: i,
                selectedData: '',
                selectedDataStart: '',
                selectedDataLike: '',
                selectedDataEnd: '',
              }
              this.coldata.push(mappedColumn);
              this.coldataTemp.push(mappedColumn.field);
              this.coldataSelectAll.push(mappedColumn);
            });
            //console.log(this.coldata)
            // this.reportOutput = response.body.records;
            // this.sqlTotalRecords = response.body.totalRecords;
            // this.sqlTableColumns = response.body.headers;
            this.showDownloadBtn = true;
            // this.globalFilterFieldsArr = this.sqlTableColumns.map(
            //   (obj) => obj.header
            // );
            d3.select('.tibo-myrepo-output-spinner').style('display', 'none'); // Hidding report output spinner
            document.getElementById('report-output-sql').style.display =
              'block';
          },
          (error) => {
            d3.select('.tibo-myrepo-output-spinner').style('display', 'none'); // Hidding report output spinner
            this.alertSeverity = 'error';
            if (Number(error.status) == 401) {
              this.alertMsg = 'Please Try After Some Time!';
            } else if (Number(error.status) == 500) {
              this.alertMsg = 'Internal Error';
            } else {
              this.alertMsg = 'Please Try After Some Time.';
            }
            this.startIndex = 0;
            this.endIndex = 0;
            this.addSingle();
          },
          () => { }
        );
    } else {
      // d3.select('.tibo-myrepo-output-spinner').style('display', 'none'); // Hidding report output spinner
      document.getElementById('report-output-sql').style.display =
        'none';
      this.showBirt = true;
      this.getReportSerSubscrip = this.myRepoOutputService
        .getReportService(
          this.reportId,
          this.requestPayloadParameterObj,
          this.reportType,
          1,
          this.docName
        )
        .subscribe(
          (response: any) => {
            d3.select('.tibo-myrepo-output-spinner').style('display', 'none'); // Hidding report output spinner
            this.showDownloadBtn = true;
            this.birtDocumentName = response.body.document;
            this.reportOutputStr = response.body.output;
            this.tabs = response.body.totalPage;
            if (this.tabs > 1) {
              this.tabOptions = [];
              this.showTab = true;
              for (let i = 1; i <= this.tabs; i++) {
                this.tabOptions.push({ label: 'tab' + i, value: i })
              }
              this.selectedTab = this.tabOptions[0];
            }
            const iframe = document.getElementById(
              'report-output-birt'
            ) as HTMLIFrameElement;
            this.reportOutputStr = this.reportOutputStr.replace(
              /target=\"_self\"/gi,
              'target="_top"'
            );
            this.reportOutputStr = this.reportOutputStr.replace(
              /target=\'_self\'/gi,
              'target="_top"'
            );
            iframe.setAttribute('srcdoc', this.reportOutputStr);
            iframe.style.background = 'white';
            iframe.contentWindow.document.body.style.backgroundColor =
              'white';
            //   this.coldata=[];
            //   this.rowData=[]
            //   this.myRepoOutputService
            //   .XLSXBirtReportDownloadService(
            //     this.reportId,
            //     'rptDocumentName=' + this.birtDocumentName
            //   ).subscribe((res:any)=>{
            //     this.showBirtRepo=true;
            //   const fileReader = new FileReader();
            // fileReader.onload = (e: any) => {
            //   const arrayBuffer = e.target.result;
            //   const workbook = xlsx.read(arrayBuffer, { type: 'array' });
            //   const firstSheetName = workbook.SheetNames[0];
            //   const worksheet = workbook.Sheets[firstSheetName];
            //   const jsonData = xlsx.utils.sheet_to_json(worksheet);
            //   this.totalRecords=jsonData.length;
            //   const keys = Object.keys(jsonData[0]);
            //   keys.forEach((key, index) => {
            //     let mappedColumn = {
            //     headerName: key.toUpperCase(),
            //     field: key,
            //     checked:true
            //     }
            //     this.coldata.push(mappedColumn);
            //     });
            //     this.rowData=jsonData;
            //   }
            //   fileReader.readAsArrayBuffer(res.body);
            // }
            // )
          },
          (error) => {
            d3.select('.tibo-myrepo-output-spinner').style('display', 'none'); // Hidding report output spinner
            this.alertSeverity = 'error';
            if (Number(error.status) == 401) {
              this.alertMsg = 'Please Try After Some Time!';
            } else if (Number(error.status) == 500) {
              this.alertMsg = 'Internal Error';
            } else {
              this.alertMsg = 'Please Try After Some Time.';
            }

            this.addSingle();
          },
          () => { }
        );
    } // birt report else block
  }
  handleColReorder(event) {
    //console.log(event)
    this.coldata = event.columns.filter(column => column.checked === true);
  }
  // showFilterFields(){
  //   this.showFilterFieldsBoo=!this.showFilterFieldsBoo;
  // }
  clearTableFilter() {
    this.sqlTableSortColumn = undefined;
    this.sqlTableSortDir = 0;
    this.isLoading2 = true;
    this.paginatorPageNo = 1;
    this.totalRecords = 0;
    this.startIndex = 0;
    this.endIndex = 0;
    this.paginator.first = 0;
    // d3.select('.tibo-myrepo-output-spinner').style('display', 'block');
    this.requestPayloadParameterObj.filters = [];
    this.requestPayloadParameterObj.sort = [];
    //  = {
    //   filters: [],
    //   filterType: 'and',
    //   parameters: {},
    //   sort: [],
    // };
    // this.buildAreportTable.clear();
    this.coldataSelectAll?.forEach(e => {
      //e.checked= true,
      e.selectedDataStart = '';
      e.selectedDataLike = '';
      e.selectedDataEnd = ''
    })
    this.getReportSerSubscrip = this.myRepoOutputService
      .getReportService(this.reportId,
        this.requestPayloadParameterObj,
        this.reportType,
        this.paginatorPageNo, this.docName)
      .subscribe((data: any) => {
        if (data.body.records != null || data.body.records.length != 0) {
          this.isLoading2 = false;
          this.rowData = data?.body?.records;
          this.startIndex = this.rowData.length > 0 ? 1 : 0;
          this.endIndex = this.rowData.length > 0 ? this.rowData.length : 0;
          this.totalRecords = data?.body?.totalRecords;
          this.coldata = this.coldataSelectAll;
          this.totalRecords = data?.body?.totalRecords;
          //this.coldataSelectAll=data.body.records;
        }
        d3.select('.tibo-myrepo-output-spinner').style('display', 'none');
      },
        (error) => {
          this.isLoading2 = true;
          this.startIndex = 0;
          this.endIndex = 0;
          d3.select('.tibo-myrepo-output-spinner').style('display', 'none');
        });
  }
  binSelect(event) {
    if (event?.value?.length === this.coldataSelectAll.length) {
      this.coldataSelectAll.forEach((d) => (d.checked = true));
      this.coldata = this.coldataSelectAll;
    }
    if (event?.value?.length == 0) {
      this.coldataSelectAll.forEach((d) => (d.checked = false));
      this.coldata = this.coldataSelectAll;
      //this.coldata.forEach((d) => (d.checked = false));
    }
    else {
      if (event?.value?.includes(event?.itemValue)) {
        this.coldataSelectAll.forEach((d) => {
          if (d.field === event?.itemValue) {
            d.checked = true;
          }
        });
        this.coldata = this.coldataSelectAll.filter(column => column.checked === true);
      } else {
        this.coldataSelectAll.forEach((d) => {
          if (d.field === event?.itemValue) {
            d.checked = false;
          }
        });
        this.coldata = this.coldataSelectAll.filter(column => column.checked === true);
      }
    }

  }
  Refresh(data) {
    this.coldataTemp = [];
    this.globalFilter = '';
    data.forEach((d) => {
      this.coldataTemp.push(d.field);
      d.checked = true;
    });
    this.coldataSelectAll?.forEach(e => {
      e.checked = true
    })
    this.table.clear();
    this.clearTableFilter()
  }
  download1(val) {
    this.laodings = true;
    this.dwnlRepoSerRequestPayload.parameters = this.requestPayloadParameterObj.parameters;
    this.dwnlRepoSerRequestPayload.columns = []
    let cols = [];
    let arr = [];
    if (val == 1) {
      this.coldataSelectAll.forEach(h => { arr.push(h.field) })
      this.dwnlRepoSerRequestPayload.filters = [];
      this.dwnlRepoSerRequestPayload.columns = arr;
      this.Downloader(this.dwnlRepoSerRequestPayload)
    }
    if (val == 2) {
      let coldataSelectAll = this.reorderArray(this.coldataSelectAll, this.coldata)
      //console.log(coldataSelectAll)
      coldataSelectAll.forEach(e => {
        if (e.checked == true) {
          cols.push(e.field)
        }
      });
      //console.log(cols)
      this.dwnlRepoSerRequestPayload.filters = this.filteredPayload;
      this.dwnlRepoSerRequestPayload.columns = cols;
      this.Downloader(this.dwnlRepoSerRequestPayload)
    }
  }
  reorderArray(firstArray, secondArray) {
    let headerOrder = secondArray.map(item => item.headerName);

    return headerOrder.map(headerName => {
      return firstArray.find(item => item.headerName === headerName);
    });
  }
  Downloader(payload) {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.rowData);
    const workbook: XLSX.WorkBook = { Sheets: { 'Report': worksheet }, SheetNames: ['Report'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const file = new Blob([excelBuffer], { type: 'application/octet-stream' });
    saveAs(file, 'Report.xlsx');
    this.myRepoOutputService
      .XLSXReportDownloadService(
        this.reportId,
        payload,
        undefined
      )
      .subscribe(
        (response: any) => {
          // XLSX report download unique name service

          this.myRepoOutputService
            .XLSXReportDownloadService(
              this.reportId,
              payload,
              'uniqueName=' + response.body.uniqueName
            )
            .subscribe(
              (repoResp: any) => {
                // XLSX report download service

                let blob = new Blob([repoResp.body], {
                  type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                });

                var a = document.createElement('a');
                a.href = URL.createObjectURL(blob);
                a.download = this.reportName.replaceAll("_", " ").split('.')[0] + '.xlsx';
                this.laodings = false
                //this.downloadBtnSpinner = 'pi-download';
                a.click();
              },
              (error) => {
                this.laodings = false
                //this.downloadBtnSpinner = 'pi-download';
                this.alertSeverity = 'error';
                if (Number(error.status) == 401) {
                  this.alertMsg = 'Please Try After Some Time!';
                } else if (Number(error.status) == 500) {
                  this.alertMsg = 'Internal Error';
                } else {
                  this.alertMsg = 'Please Try After Some Time.';
                }

                this.addSingle();
              },
              () => { }
            );
        },
        (error) => {
          this.laodings = false
          //this.downloadBtnSpinner = 'pi-download';
          this.alertSeverity = 'error';
          if (Number(error.status) == 401) {
            this.alertMsg = 'Please Try After Some Time!';
          } else if (Number(error.status) == 500) {
            this.alertMsg = 'Internal Error';
          } else {
            this.alertMsg = 'Please Try After Some Time.';
          }

          this.addSingle();
        },
        () => { }
      );
    // this.myRepoOutputService.downloadData(this.dwnlRepoSerRequestPayload).subscribe((res:any) => {
    //   let name = res.body.uniqueName;
    //   this.myRepoOutputService.downloadTableRecordsService2( name).subscribe({
    //     next: (res: any) => {
    //       const file = new Blob([res], {
    //         type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    //       });
    //       const fileName = 'SQL Report';
    //      // if(val==1){
    //       FileSaver.saveAs(file, fileName);
    //       this.laodings=false
    //       // }
    //       // if(val==2){
    //       // this.download2(file);
    //       // this.laodings=false;
    //       // }
    //     },
    //     error: (err) => {
    //       this.laodings=false
    //     },
    //   });
    //   }, () => {
    //   });
  }
  paginate(event) {
    var showAlertBool: Boolean = false;
    this.isLoading2 = true;
    this.parameterElementsArray.forEach((ele) => {
      if (
        ele.visible == true &&
        ele.mandatory == true &&
        ele.paramElementFilledBool != true
      ) {
        showAlertBool = true;
      }
    });

    if (showAlertBool == true) {
      this.addMessages(0);
    } else {
      this.clearMessages();

      // d3.select('.tibo-myrepo-output-spinner').style('display', 'block'); // Showing report output spinner

      this.paginatorPageNo = event.page + 1;

      // this.requestPayloadParameterObj['filterType'] = 'and';
      // this.requestPayloadParameterObj['filters'] = [];
      // this.requestPayloadParameterObj['sort'] = [];

      //this.table.clear();
      this.reportOutput = [];

      this.getReportSerSubscrip = this.myRepoOutputService
        .getReportService(
          this.reportId,
          this.requestPayloadParameterObj,
          this.reportType,
          event.page + 1, this.docName
        )
        .subscribe(
          (response: any) => {
            // report parameters
            //this.reportOutput = response.body.records;
            this.showDownloadBtn = true;
            // d3.select('.tibo-myrepo-output-spinner').style('display', 'none'); // Hidding report output spinner
            this.isLoading2 = false;
            response.body?.records
              ? (this.rowData = response?.body?.records)
              : (this.rowData = []);
            this.startIndex = this.rowData.length > 0 ? (150 * (this.paginatorPageNo - 1)) + 1 : 0;
            this.endIndex = this.rowData.length > 0 ? (this.startIndex - 1) + this.rowData.length : 0;
            this.totalRecords = response?.body?.totalRecords;
          },
          (error) => {
            this.isLoading2 = false;
            // d3.select('.tibo-myrepo-output-spinner').style('display', 'none'); // Hidding report output spinner
            this.alertSeverity = 'error';
            if (Number(error.status) == 401) {
              this.alertMsg = 'Please Try After Some Time!';
            } else if (Number(error.status) == 500) {
              this.alertMsg = 'Internal Error';
            } else {
              this.alertMsg = 'Please Try After Some Time.';
            }
            this.startIndex = 0;
            this.endIndex = 0;
            this.addSingle();
          },
          () => { }
        );
    }
  }
  // public getDate(date: Date): string {
  //   return date?.toLocaleString('en-US', { timeZone: 'America/Los_Angeles' });
  // }
  run1(event, fields, condition) {
    let val: any;
    //console.log(fields)
    this.isLoading2 = true;
    this.paginatorPageNo = 1;
    this.paginator.first = 0;
    this.requestPayloadParameterObj.filters = [];
    fields.forEach((column: any) => {
      if (column.selectedDataStart || column.selectedDataLike || column.selectedDataEnd) {
        if (column.type == 'text') {
          if (column.selectedDataStart) {
            let obj = {
              property: column.field,
              operator: 'startw',
              value: column.selectedDataStart,
            };
            this.requestPayloadParameterObj.filters.push(obj);
          }
          if (column.selectedDataLike) {
            let obj = {
              property: column.field,
              operator: 'like',
              value: column.selectedDataLike,
            };
            this.requestPayloadParameterObj.filters.push(obj);
          }
          if (column.selectedDataEnd) {
            let obj = {
              property: column.field,
              operator: 'endw',
              value: column.selectedDataEnd,
            };
            this.requestPayloadParameterObj.filters.push(obj);
          }
        }
        if (column.type == 'date') {
          let val: any;
          if (column.selectedDataStart) {
            const z = new Date(column.selectedDataStart).getTime();
            val = z// + 1000 * 60 * 60 * 24;
            //console.log(val)
            // column.selectedDataEnd = ""
            let obj = {
              property: column.field,
              operator: 'dtlt',
              value: this.datePipe.transform(column.selectedDataStart.setHours(23, 59, 59, 999), 'MM/dd/yyyy h:mm a')//val,
            };
            this.requestPayloadParameterObj.filters.push(obj);
          }
          if (column.selectedDataLike) {
            const z = new Date(column.selectedDataLike).getTime();
            val = z// + 1000 * 60 * 60 * 24;
            //console.log(val)
            // column.selectedDataEnd = "";
            let obj = {
              property: column.field,
              operator: 'dtgt',
              value: this.datePipe.transform(column.selectedDataLike.setHours(0, 0, 0, 0), 'MM/dd/yyyy h:mm a')//val,
            };
            this.requestPayloadParameterObj.filters.push(obj);
          }
          if (column.selectedDataEnd) {
            const z = new Date(column.selectedDataEnd).getTime();
            val = z //+ 1000 * 60 * 60 * 24;
            //console.log(val)
            // column.selectedDataStart = "";
            // column.selectedDataLike = "";
            let obj = {
              property: column.field,
              operator: 'dton',
              value: this.datePipe.transform(column.selectedDataEnd.setHours(0, 0, 0, 0), 'MM/dd/yyyy h:mm a')//val,
            };
            this.requestPayloadParameterObj.filters.push(obj);
          }
        }
        if ((column.type == 'number') || (column.type == 'INT')) {
          if (column.selectedDataStart) {
            let obj = {
              property: column.field,
              operator: 'lt',
              value: column.selectedDataStart,
            };
            this.requestPayloadParameterObj.filters.push(obj);
          }
          if (column.selectedDataLike) {
            let obj = {
              property: column.field,
              operator: 'gt',
              value: column.selectedDataLike,
            };
            this.requestPayloadParameterObj.filters.push(obj);
          }
          if (column.selectedDataEnd) {
            let obj = {
              property: column.field,
              operator: 'eq',
              value: column.selectedDataEnd,
            };
            this.requestPayloadParameterObj.filters.push(obj);
          }
        }
      }
    })
    // if(event == ''){
    //   // this.requestPayloadParameterObj = {
    //   //   sort: [],
    //   //   filters: [],
    //   //   filterType: 'and',
    //   //   parameters: {},
    //   // };
    //   // if(this.requestPayloadParameterObj .filters.length>0){
    //   //   this.requestPayloadParameterObj.filters= this.requestPayloadParameterObj.filters.filter(item=>(item.property !== field && item.operator !== condition))
    //   // }
    //   setTimeout(() => {
    //     this.myRepoOutputService
    //     .getReportService(
    //       this.reportId,
    //       this.requestPayloadParameterObj,
    //       this.reportType,
    //      this.paginatorPageNo, this.docName
    //     ).subscribe((res:any)=>{
    //         if(res.body?.records?.length > 0){
    //           this.rowData = res.body.records;
    //           this.totalRecords=res.body.totalRecords;
    //           this.isLoading2=false;
    //           this.startIndex= this.rowData.length>0?1:0;
    //           this.endIndex=this.rowData.length>0?this.rowData.length:0;
    //         }
    //        else{
    //             this.tableEmptyMessage = 'No Records Found';
    //             this.isLoading2 = false;
    //             this.startIndex= 0;
    //             this.endIndex=0;
    //             this.totalRecords = 0;
    //             this.rowData = [];
    //        }
    //    });
    //   }, 200);
    // }
    // else{
    //this.isLoading2=true;
    //     if(condition == 'dtlt' ||condition == 'dtgt'||condition == 'dton'){
    //       const z = new Date(this.getDate(event)).getTime();
    //       val = z + 1000 * 60 * 60 * 24;
    //     }
    //     else{
    //       val=event;
    //     }
    //     let obj = {};
    //     obj={
    //       property: field,
    //       operator: condition,
    //       value:val,
    //     };
    //   if(this.requestPayloadParameterObj .filters.length>0){
    //     this.requestPayloadParameterObj .filters?.forEach((e,i)=>{
    //       if(e.property == field && e.operator == condition){
    //         e.value=val;
    //       }
    //       else{
    //         this.requestPayloadParameterObj .filters.push(obj);
    //       }
    //     })
    //   }
    //   else{
    //     this.requestPayloadParameterObj .filters.push(obj);
    //   }
    //   this.requestPayloadParameterObj.filters =this.requestPayloadParameterObj .filters.filter(
    //     (value, index, self) =>
    //       index === self.findIndex((t) => t.property === value.property && t.operator === value.operator && t.value === value.value)
    //   );
    //   // this.isLoading2 = true;
    //  this.filteredPayload=this.requestPayloadParameterObj.filters;
    // setTimeout(() => {
    this.filteredPayload = this.requestPayloadParameterObj.filters;
    this.myRepoOutputService
      .getReportService(
        this.reportId,
        this.requestPayloadParameterObj,
        this.reportType,
        this.paginatorPageNo, ''
      ).subscribe((res: any) => {
        if (res.body?.records?.length > 0) {
          // this.showGrid=true;
          this.rowData = res.body.records;
          this.totalRecords = res.body.totalRecords;
          this.startIndex = this.rowData.length > 0 ? 1 : 0;
          this.endIndex = this.rowData.length > 0 ? this.rowData.length : 0;
          this.isLoading2 = false
        }
        else {
          this.rowData = [];
          this.tableEmptyMessage = 'No Records Found';
          this.startIndex = 0;
          this.endIndex = 0;
          this.totalRecords = 0;
          this.isLoading2 = false;
        }
      });
    // }, 500);

    // }

  }
  repoCriteriaPanelToggle() {
    this.sideMenuCollapse = !this.sideMenuCollapse;
    if (this.repoCritPanelToggleBool == false) {
      d3.select('#myrepo-output-container')
        .transition()
        .delay(200)
        .duration(500)
        .style('left', '50px');
      d3.select('#report-criteria-id')
        .transition()
        .delay(100)
        .duration(100)
        .style({ left: '-25px', top: '85px' })
        .style('transform', 'rotate(-90deg)');
      this.repoCritPanelToggleBool = true;
    } else {
      d3.select('#submitbutton-id')
        .transition()
        .delay(500)
        .duration(400)
        .style('display', 'block');
      d3.select('#myrepo-output-container')
        .transition()
        .delay(200)
        .duration(500)
        .style('left', '0px');
      d3.select('#report-criteria-id')
        .transition()
        .delay(250)
        .duration(100)
        .style({ left: '10px', top: '10px' })
        .style('transform', 'rotate(0deg)');
      this.repoCritPanelToggleBool = false;
    }
  }

  customSort(event: SortEvent) {
    this.requestPayloadParameterObj.sort = [];
    //console.log(event)
    d3.select('.tibo-myrepo-output-spinner').style('display', 'block'); // Showing report output spinner
    this.requestPayloadParameterObj.sort = [];
    if (event.order == 1) {
      this.requestPayloadParameterObj.sort.push({
        property: event.field,
        dir: 'ASC',
      });
    } else if (event.order == -1) {
      this.requestPayloadParameterObj.sort.push({
        property: event.field,
        dir: 'DESC',
      });
    }
    this.sqlTableSortColumn = event.field;
    this.sqlTableSortDir = event.order;
    this.getReportSerSubscrip = this.myRepoOutputService
      .getReportService(
        this.reportId,
        this.requestPayloadParameterObj,
        this.reportType,
        this.paginatorPageNo, this.docName
      )
      .subscribe(
        (response: any) => {
          this.showDownloadBtn = true;
          d3.select('.tibo-myrepo-output-spinner').style('display', 'none'); // Hidding report output spinner

          let sortedData = [];
          response.body?.records
            ? (sortedData = response.body.records)
            : (sortedData = []);
          event.data.splice(0, event.data.length);
          sortedData.forEach((d) => {
            event.data.push(d);
          });
        },
        (error) => {
          d3.select('.tibo-myrepo-output-spinner').style('display', 'none'); // Hidding report output spinner
          this.alertSeverity = 'error';
          if (Number(error.status) == 401) {
            this.alertMsg = 'Please Try After Some Time!';
          } else if (Number(error.status) == 500) {
            this.alertMsg = 'Internal Error';
          } else {
            this.alertMsg = 'Please Try After Some Time.';
          }
          this.startIndex = 0;
          this.endIndex = 0;
          this.totalRecords = 0;
          this.addSingle();
        },
        () => { }
      );
  }

  downloadReportFunc(event) {
    this.dwnlRepoSerRequestPayload.columns = [];
    this.dwnlRepoSerRequestPayload.filters = [];
    this.dwnlRepoSerRequestPayload.parameters = this.requestPayloadParameterObj.parameters;
    this.downloadBtnSpinner = 'pi-spin pi-spinner';
    //console.log(this.reportType, this.reportId)
    if (this.reportType.toLowerCase() == 'sql') {
      if (event.item.label == 'xls') {
        this.myRepoOutputService
          .XLSReportDownloadService(
            this.reportId,
            this.dwnlRepoSerRequestPayload,
            undefined
          )
          .subscribe(
            (response: any) => {
              // XLS report download unique name service

              this.myRepoOutputService
                .XLSReportDownloadService(
                  this.reportId,
                  this.dwnlRepoSerRequestPayload,
                  'uniqueName=' + response.body.uniqueName
                )
                .subscribe(
                  (repoResp: any) => {
                    // XLSX report download service

                    let blob = new Blob([repoResp.body], {
                      type: 'application/vnd.ms-excel',
                    });

                    var a = document.createElement('a');
                    a.href = URL.createObjectURL(blob);
                    a.download = this.reportName.replace(".sql", '') + '.xls';
                    a.click();
                    this.downloadBtnSpinner = 'pi-download';
                  },
                  (error) => {
                    this.alertSeverity = 'error';
                    if (Number(error.status) == 401) {
                      this.alertMsg = 'Please Try After Some Time!';
                    } else if (Number(error.status) == 500) {
                      this.alertMsg = 'Internal Error';
                    } else {
                      this.alertMsg = 'Please Try After Some Time.';
                    }
                    this.downloadBtnSpinner = 'pi-download';
                    this.addSingle();
                  },
                  () => { }
                );
            },
            (error) => {
              this.alertSeverity = 'error';
              if (Number(error.status) == 401) {
                this.alertMsg = 'Please Try After Some Time!';
              } else if (Number(error.status) == 500) {
                this.alertMsg = 'Internal Error';
              } else {
                this.alertMsg = 'Please Try After Some Time.';
              }
              this.downloadBtnSpinner = 'pi-download';
              this.addSingle();
            },
            () => { }
          );
      } else if (event.item.label == 'xlsx') {
        this.myRepoOutputService
          .XLSXReportDownloadService(
            this.reportId,
            this.dwnlRepoSerRequestPayload,
            undefined
          )
          .subscribe(
            (response: any) => {
              // XLSX report download unique name service

              this.myRepoOutputService
                .XLSXReportDownloadService(
                  this.reportId,
                  this.dwnlRepoSerRequestPayload,
                  'uniqueName=' + response.body.uniqueName
                )
                .subscribe(
                  (repoResp: any) => {
                    // XLSX report download service

                    let blob = new Blob([repoResp.body], {
                      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                    });

                    var a = document.createElement('a');
                    a.href = URL.createObjectURL(blob);
                    a.download = this.reportName.replaceAll("_", " ").split('.')[0] + '.xlsx';
                    this.downloadBtnSpinner = 'pi-download';
                    a.click();
                  },
                  (error) => {
                    this.downloadBtnSpinner = 'pi-download';
                    this.alertSeverity = 'error';
                    if (Number(error.status) == 401) {
                      this.alertMsg = 'Please Try After Some Time!';
                    } else if (Number(error.status) == 500) {
                      this.alertMsg = 'Internal Error';
                    } else {
                      this.alertMsg = 'Please Try After Some Time.';
                    }

                    this.addSingle();
                  },
                  () => { }
                );
            },
            (error) => {
              this.downloadBtnSpinner = 'pi-download';
              this.alertSeverity = 'error';
              if (Number(error.status) == 401) {
                this.alertMsg = 'Please Try After Some Time!';
              } else if (Number(error.status) == 500) {
                this.alertMsg = 'Internal Error';
              } else {
                this.alertMsg = 'Please Try After Some Time.';
              }

              this.addSingle();
            },
            () => { }
          );
      } else if (event.item.label == 'Save xlsx to Inbox') {
        this.myRepoOutputService
          .XLSXReportSaveToInboxService(
            this.reportId,
            this.dwnlRepoSerRequestPayload,
            undefined
          )
          .subscribe(
            (response: any) => {
              // XLSX report save to inbox unique name service

              this.myRepoOutputService
                .XLSXReportSaveToInboxService(
                  this.reportId,
                  this.dwnlRepoSerRequestPayload,
                  'uniqueName=' + response.body.uniqueName
                )
                .subscribe(
                  (repoResp: any) => {
                    this.downloadBtnSpinner = 'pi-download';
                    // XLSX report save to inbox service
                    if (repoResp.status == 200) {
                      this.alertSeverity = 'success';
                      this.alertMsg = 'Saved to Inbox!';
                      this.addSingle();
                    }
                  },
                  (error) => {
                    this.downloadBtnSpinner = 'pi-download';
                    this.alertSeverity = 'error';
                    if (Number(error.status) == 401) {
                      this.alertMsg = 'Please Try After Some Time!';
                    } else if (Number(error.status) == 500) {
                      this.alertMsg = 'Internal Error';
                    } else {
                      this.alertMsg = 'Please Try After Some Time.';
                    }
                    this.addSingle();
                  },
                  () => { }
                );
            },
            (error) => {
              this.downloadBtnSpinner = 'pi-download';
              this.alertSeverity = 'error';
              if (Number(error.status) == 401) {
                this.alertMsg = 'Please Try After Some Time!';
              } else if (Number(error.status) == 500) {
                this.alertMsg = 'Internal Error';
              } else {
                this.alertMsg = 'Please Try After Some Time.';
              }

              this.addSingle();
            },
            () => { }
          );
      } else if (event.item.label == 'csv') {
        this.myRepoOutputService
          .CSVReportDownloadService(
            this.reportId,
            this.dwnlRepoSerRequestPayload,
            undefined
          )
          .subscribe(
            (response: any) => {
              // CSV report download unique name service

              this.myRepoOutputService
                .CSVReportDownloadService(
                  this.reportId,
                  this.dwnlRepoSerRequestPayload,
                  'uniqueName=' + response.body.uniqueName
                )
                .subscribe(
                  (repoResp: any) => {
                    // CSV report download service
                    let blob = new Blob([repoResp.body], {
                      type: 'text/csv;charset=utf-8',
                    });

                    var a = document.createElement('a');
                    a.href = URL.createObjectURL(blob);
                    a.download = this.reportName.replaceAll("_", " ").split('.')[0] + '.csv';
                    a.click();
                    this.downloadBtnSpinner = 'pi-download';
                  },
                  (error) => {
                    this.downloadBtnSpinner = 'pi-download';
                    this.alertSeverity = 'error';
                    if (Number(error.status) == 401) {
                      this.alertMsg = 'Please Try After Some Time!';
                    } else if (Number(error.status) == 500) {
                      this.alertMsg = 'Internal Error';
                    } else {
                      this.alertMsg = 'Please Try After Some Time.';
                    }

                    this.addSingle();
                  },
                  () => { }
                );
            },
            (error) => {
              this.alertSeverity = 'error';
              if (Number(error.status) == 401) {
                this.alertMsg = 'Please Try After Some Time!';
              } else if (Number(error.status) == 500) {
                this.alertMsg = 'Internal Error';
              } else {
                this.alertMsg = 'Please Try After Some Time.';
              }
              this.downloadBtnSpinner = 'pi-download';
              this.addSingle();
            },
            () => { }
          );
      } else if (event.item.label == 'pdf') {
        this.myRepoOutputService
          .PDFReportDownloadService(
            this.reportId,
            this.dwnlRepoSerRequestPayload,
            undefined
          )
          .subscribe(
            (response: any) => {
              // XLSX report download unique name service

              this.myRepoOutputService
                .PDFReportDownloadService(
                  this.reportId,
                  this.dwnlRepoSerRequestPayload,
                  'uniqueName=' + response.body.uniqueName
                )
                .subscribe(
                  (repoResp: any) => {
                    // XLSX report download service

                    let blob = new Blob([repoResp.body], { type: 'application/pdf' });

                    var a = document.createElement('a');
                    a.href = URL.createObjectURL(blob);
                    a.download = this.reportName.replaceAll("_", " ").split('.')[0] + '.pdf';
                    this.downloadBtnSpinner = 'pi-download';
                    a.click();
                  },
                  (error) => {
                    this.downloadBtnSpinner = 'pi-download';
                    this.alertSeverity = 'error';
                    if (Number(error.status) == 401) {
                      this.alertMsg = 'Please Try After Some Time!';
                    } else if (Number(error.status) == 500) {
                      this.alertMsg = 'Internal Error';
                    } else {
                      this.alertMsg = 'Please Try After Some Time.';
                    }

                    this.addSingle();
                  },
                  () => { }
                );
            },
            (error) => {
              this.downloadBtnSpinner = 'pi-download';
              this.alertSeverity = 'error';
              if (Number(error.status) == 401) {
                this.alertMsg = 'Please Try After Some Time!';
              } else if (Number(error.status) == 500) {
                this.alertMsg = 'Internal Error';
              } else {
                this.alertMsg = 'Please Try After Some Time.';
              }

              this.addSingle();
            },
            () => { }
          );
      } else if (event.item.label == 'Save csv to Inbox') {
        this.myRepoOutputService
          .CSVReportSaveToInboxService(
            this.reportId,
            this.dwnlRepoSerRequestPayload,
            undefined
          )
          .subscribe(
            (response: any) => {
              // CSV report save to inbox unique name service

              this.myRepoOutputService
                .CSVReportSaveToInboxService(
                  this.reportId,
                  this.dwnlRepoSerRequestPayload,
                  'uniqueName=' + response.body.uniqueName
                )
                .subscribe(
                  (repoResp: any) => {
                    // CSV report save to inbox service
                    this.downloadBtnSpinner = 'pi-download';
                    if (repoResp.status == 200) {
                      this.alertSeverity = 'success';
                      this.alertMsg = 'Saved to Inbox!';
                      this.addSingle();
                    }
                  },
                  (error) => {
                    this.downloadBtnSpinner = 'pi-download';
                    this.alertSeverity = 'error';
                    if (Number(error.status) == 401) {
                      this.alertMsg = 'Please Try After Some Time!';
                    } else if (Number(error.status) == 500) {
                      this.alertMsg = 'Internal Error';
                    } else {
                      this.alertMsg = 'Please Try After Some Time.';
                    }

                    this.addSingle();
                  },
                  () => { }
                );
            },
            (error) => {
              this.downloadBtnSpinner = 'pi-download';
              this.alertSeverity = 'error';
              if (Number(error.status) == 401) {
                this.alertMsg = 'Please Try After Some Time!';
              } else if (Number(error.status) == 500) {
                this.alertMsg = 'Internal Error';
              } else {
                this.alertMsg = 'Please Try After Some Time.';
              }

              this.addSingle();
            },
            () => { }
          );
      } else if (event.item.label == 'txt') {
        this.myRepoOutputService
          .TXTReportDownloadService(
            this.reportId,
            this.dwnlRepoSerRequestPayload,
            undefined
          )
          .subscribe(
            (response: any) => {
              // TXT report download unique name service

              this.myRepoOutputService
                .CSVReportDownloadService(
                  this.reportId,
                  this.dwnlRepoSerRequestPayload,
                  'uniqueName=' + response.body.uniqueName
                )
                .subscribe(
                  (repoResp: any) => {
                    // TXT report download service

                    let blob = new Blob([repoResp.body], { type: 'text/text' });

                    var a = document.createElement('a');
                    a.href = URL.createObjectURL(blob);
                    a.download = this.reportName.replaceAll("_", " ").split('.')[0] + '.txt';
                    a.click();
                    this.downloadBtnSpinner = 'pi-download';
                  },
                  (error) => {
                    this.downloadBtnSpinner = 'pi-download';
                    this.alertSeverity = 'error';
                    if (Number(error.status) == 401) {
                      this.alertMsg = 'Please Try After Some Time!';
                    } else if (Number(error.status) == 500) {
                      this.alertMsg = 'Internal Error';
                    } else {
                      this.alertMsg = 'Please Try After Some Time.';
                    }

                    this.addSingle();
                  },
                  () => { }
                );
            },
            (error) => {
              this.downloadBtnSpinner = 'pi-download';
              this.alertSeverity = 'error';
              if (Number(error.status) == 401) {
                this.alertMsg = 'Please Try After Some Time!';
              } else if (Number(error.status) == 500) {
                this.alertMsg = 'Internal Error';
              } else {
                this.alertMsg = 'Please Try After Some Time.';
              }

              this.addSingle();
            },
            () => { }
          );
      } else if (event.item.label == 'pptx') {
        this.myRepoOutputService
          .PPTXReportDownloadService(
            this.reportId,
            this.dwnlRepoSerRequestPayload,
            undefined
          )
          .subscribe(
            (response: any) => {
              // TXT report download unique name service

              this.myRepoOutputService
                .PPTXReportDownloadService(
                  this.reportId,
                  this.dwnlRepoSerRequestPayload,
                  'uniqueName=' + response.body.uniqueName
                )
                .subscribe(
                  (repoResp: any) => {
                    // TXT report download service

                    let blob = new Blob([repoResp.body], {
                      type: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
                    });

                    var a = document.createElement('a');
                    a.href = URL.createObjectURL(blob);
                    a.download = this.reportName.replaceAll("_", " ").split('.')[0] + '.pptx';
                    a.click();
                    this.downloadBtnSpinner = 'pi-download';
                  },
                  (error) => {
                    this.downloadBtnSpinner = 'pi-download';
                    this.alertSeverity = 'error';
                    if (Number(error.status) == 401) {
                      this.alertMsg = 'Please Try After Some Time!';
                    } else if (Number(error.status) == 500) {
                      this.alertMsg = 'Internal Error';
                    } else {
                      this.alertMsg = 'Please Try After Some Time.';
                    }

                    this.addSingle();
                  },
                  () => { }
                );
            },
            (error) => {
              this.downloadBtnSpinner = 'pi-download';
              this.alertSeverity = 'error';
              if (Number(error.status) == 401) {
                this.alertMsg = 'Please Try After Some Time!';
              } else if (Number(error.status) == 500) {
                this.alertMsg = 'Internal Error';
              } else {
                this.alertMsg = 'Please Try After Some Time.';
              }

              this.addSingle();
            },
            () => { }
          );
      }
      else if (event.item.label == 'Save pdf to Inbox') {
        this.myRepoOutputService
          .PDFtoInboxReportDownloadService(
            this.reportId,
            this.dwnlRepoSerRequestPayload,
            undefined
          )
          .subscribe(
            (response: any) => {
              // TXT report download unique name service

              this.myRepoOutputService
                .PDFtoInboxReportDownloadService(
                  this.reportId,
                  this.dwnlRepoSerRequestPayload,
                  'uniqueName=' + response.body.uniqueName
                )
                .subscribe(
                  (repoResp: any) => {
                    // CSV report save to inbox service
                    this.downloadBtnSpinner = 'pi-download';
                    if (repoResp.status == 200) {
                      this.alertSeverity = 'success';
                      this.alertMsg = 'Saved to Inbox!';
                      this.addSingle();
                    }
                  },
                  (error) => {
                    this.downloadBtnSpinner = 'pi-download';
                    this.alertSeverity = 'error';
                    if (Number(error.status) == 401) {
                      this.alertMsg = 'Please Try After Some Time!';
                    } else if (Number(error.status) == 500) {
                      this.alertMsg = 'Internal Error';
                    } else {
                      this.alertMsg = 'Please Try After Some Time.';
                    }

                    this.addSingle();
                  },
                  () => { }
                );
            },
            (error) => {
              this.downloadBtnSpinner = 'pi-download';
              this.alertSeverity = 'error';
              if (Number(error.status) == 401) {
                this.alertMsg = 'Please Try After Some Time!';
              } else if (Number(error.status) == 500) {
                this.alertMsg = 'Internal Error';
              } else {
                this.alertMsg = 'Please Try After Some Time.';
              }

              this.addSingle();
            },
            () => { }
          );
      }
      else if (event.item.label == 'txt-pipe') {
        this.myRepoOutputService
          .TEXTPIPEReportDownloadService(
            this.reportId,
            this.dwnlRepoSerRequestPayload,
            undefined
          )
          .subscribe(
            (response: any) => {
              // TXT report download unique name service

              this.myRepoOutputService
                .TEXTPIPEReportDownloadService(
                  this.reportId,
                  this.dwnlRepoSerRequestPayload,
                  'uniqueName=' + response.body.uniqueName
                )
                .subscribe(
                  (repoResp: any) => {
                    // CSV report save to inbox service
                    let blob = new Blob([repoResp.body], { type: 'text/text' });

                    var a = document.createElement('a');
                    a.href = URL.createObjectURL(blob);
                    a.download = this.reportName.replaceAll("_", " ").split('.')[0] + '.txt';
                    a.click();
                    this.downloadBtnSpinner = 'pi-download';

                  },
                  (error) => {
                    this.downloadBtnSpinner = 'pi-download';
                    this.alertSeverity = 'error';
                    if (Number(error.status) == 401) {
                      this.alertMsg = 'Please Try After Some Time!';
                    } else if (Number(error.status) == 500) {
                      this.alertMsg = 'Internal Error';
                    } else {
                      this.alertMsg = 'Please Try After Some Time.';
                    }

                    this.addSingle();
                  },
                  () => { }
                );
            },
            (error) => {
              this.downloadBtnSpinner = 'pi-download';
              this.alertSeverity = 'error';
              if (Number(error.status) == 401) {
                this.alertMsg = 'Please Try After Some Time!';
              } else if (Number(error.status) == 500) {
                this.alertMsg = 'Internal Error';
              } else {
                this.alertMsg = 'Please Try After Some Time.';
              }

              this.addSingle();
            },
            () => { }
          );
      }
      else if (event.item.label == 'doc') {
        this.myRepoOutputService
          .DOCReportDownloadService(
            this.reportId,
            this.dwnlRepoSerRequestPayload,
            undefined
          )
          .subscribe(
            (response: any) => {
              // TXT report download unique name service

              this.myRepoOutputService
                .DOCReportDownloadService(
                  this.reportId,
                  this.dwnlRepoSerRequestPayload,
                  'uniqueName=' + response.body.uniqueName
                )
                .subscribe(
                  (repoResp: any) => {
                    // CSV report save to inbox service
                    let blob = new Blob([repoResp.body], {
                      type: 'application/msword',
                    });

                    var a = document.createElement('a');
                    a.href = URL.createObjectURL(blob);
                    a.download = this.reportName.replaceAll("_", " ").split('.')[0] + '.doc';
                    a.click();
                    this.downloadBtnSpinner = 'pi-download';

                  },
                  (error) => {
                    this.downloadBtnSpinner = 'pi-download';
                    this.alertSeverity = 'error';
                    if (Number(error.status) == 401) {
                      this.alertMsg = 'Please Try After Some Time!';
                    } else if (Number(error.status) == 500) {
                      this.alertMsg = 'Internal Error';
                    } else {
                      this.alertMsg = 'Please Try After Some Time.';
                    }

                    this.addSingle();
                  },
                  () => { }
                );
            },
            (error) => {
              this.downloadBtnSpinner = 'pi-download';
              this.alertSeverity = 'error';
              if (Number(error.status) == 401) {
                this.alertMsg = 'Please Try After Some Time!';
              } else if (Number(error.status) == 500) {
                this.alertMsg = 'Internal Error';
              } else {
                this.alertMsg = 'Please Try After Some Time.';
              }

              this.addSingle();
            },
            () => { }
          );
      }
    } else {
      if (event.item.label == 'xls') {
        this.myRepoOutputService
          .XLSBirtReportDownloadService(
            this.reportId,
            'rptDocumentName=' + this.birtDocumentName
          )
          .subscribe(
            (repoResp: any) => {
              // XLS report download service

              let blob = new Blob([repoResp.body], {
                type: 'application/vnd.ms-excel',
              });

              var a = document.createElement('a');
              a.href = URL.createObjectURL(blob);
              a.download = this.reportName.replaceAll("_", " ").split('.')[0] + '.xls';
              a.click();
              this.downloadBtnSpinner = 'pi-download';
            },
            (error) => {
              this.alertSeverity = 'error';
              if (Number(error.status) == 401) {
                this.alertMsg = 'Please Try After Some Time!';
              } else if (Number(error.status) == 500) {
                this.alertMsg = 'Internal Error';
              } else {
                this.alertMsg = 'Please Try After Some Time.';
              }
              this.downloadBtnSpinner = 'pi-download';
              this.addSingle();
            },
            () => { }
          );
      } else if (event.item.label == 'xlsx') {
        this.myRepoOutputService
          .XLSXBirtReportDownloadService(
            this.reportId,
            'rptDocumentName=' + this.birtDocumentName
          )
          .subscribe(
            (repoResp: any) => {
              // XLSX report download service


              // const result = this.transformData( fileReader.readAsArrayBuffer(repoResp.body));
              let blob = new Blob([repoResp.body], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
              });

              var a = document.createElement('a');
              a.href = URL.createObjectURL(blob);
              a.download = this.reportName.replaceAll("_", " ").split('.')[0] + '.xlsx';
              a.click();
              this.downloadBtnSpinner = 'pi-download';
            },
            (error) => {
              this.downloadBtnSpinner = 'pi-download';
              this.alertSeverity = 'error';
              if (Number(error.status) == 401) {
                this.alertMsg = 'Please Try After Some Time!';
              } else if (Number(error.status) == 500) {
                this.alertMsg = 'Internal Error';
              } else {
                this.alertMsg = 'Please Try After Some Time.';
              }

              this.addSingle();
            },
            () => { }
          );
      } else if (event.item.label == 'Save xlsx to Inbox') {
        this.myRepoOutputService
          .XLSXBirtReportSaveToInboxService(
            this.reportId,
            'rptDocumentName=' + this.birtDocumentName
          )
          .subscribe(
            (repoResp: any) => {
              // XLSX report save to inbox service
              this.downloadBtnSpinner = 'pi-download';
              if (repoResp.status == 200) {
                this.alertSeverity = 'success';
                this.alertMsg = 'Saved to Inbox!';
                this.addSingle();
              }
            },
            (error) => {
              this.downloadBtnSpinner = 'pi-download';
              this.alertSeverity = 'error';
              if (Number(error.status) == 401) {
                this.alertMsg = 'Please Try After Some Time!';
              } else if (Number(error.status) == 500) {
                this.alertMsg = 'Internal Error';
              } else {
                this.alertMsg = 'Please Try After Some Time.';
              }

              this.addSingle();
            },
            () => { }
          );
      } else if (event.item.label == 'pdf') {
        this.myRepoOutputService
          .PDFBirtReportDownloadService(
            this.reportId,
            'rptDocumentName=' + this.birtDocumentName
          )
          .subscribe(
            (repoResp: any) => {
              // PDF report download service

              let blob = new Blob([repoResp.body], { type: 'application/pdf' });

              var a = document.createElement('a');
              a.href = URL.createObjectURL(blob);
              a.download = this.reportName.replaceAll("_", " ").split('.')[0] + '.pdf';
              a.click();
              this.downloadBtnSpinner = 'pi-download';
            },
            (error) => {
              this.downloadBtnSpinner = 'pi-download';
            },
            () => { }
          );
      } else if (event.item.label == 'Save pdf to Inbox') {
        this.myRepoOutputService
          .PDFBirtReportSaveToInboxService(
            this.reportId,
            'rptDocumentName=' + this.birtDocumentName
          )
          .subscribe(
            (repoResp: any) => {
              // PDF report save to inbox service
              this.downloadBtnSpinner = 'pi-download';
              if (repoResp.status == 200) {
                this.alertSeverity = 'success';
                this.alertMsg = 'Saved to Inbox!';
                this.addSingle();
              }
            },
            (error) => {
              this.downloadBtnSpinner = 'pi-download';
              this.alertSeverity = 'error';
              if (Number(error.status) == 401) {
                this.alertMsg = 'Please Try After Some Time!';
              } else if (Number(error.status) == 500) {
                this.alertMsg = 'Internal Error';
              } else {
                this.alertMsg = 'Please Try After Some Time.';
              }

              this.addSingle();
            },
            () => { }
          );
      } else if (event.item.label == 'doc') {
        this.myRepoOutputService
          .DOCBirtReportDownloadService(
            this.reportId,
            'rptDocumentName=' + this.birtDocumentName
          )
          .subscribe(
            (repoResp: any) => {
              // DOC report download service

              let blob = new Blob([repoResp.body], {
                type: 'application/msword',
              });

              var a = document.createElement('a');
              a.href = URL.createObjectURL(blob);
              a.download = this.reportName.replaceAll("_", " ").split('.')[0] + '.doc';
              a.click();
              this.downloadBtnSpinner = 'pi-download';
            },
            (error) => {
              this.alertSeverity = 'error';
              if (Number(error.status) == 401) {
                this.alertMsg = 'Please Try After Some Time!';
              } else if (Number(error.status) == 500) {
                this.alertMsg = 'Internal Error';
              } else {
                this.alertMsg = 'Please Try After Some Time.';
              }
              this.downloadBtnSpinner = 'pi-download';
              this.addSingle();
            },
            () => { }
          );
      } else if (event.item.label == 'pptx') {
        this.myRepoOutputService
          .PPTXBirtReportDownloadService(
            this.reportId,
            'rptDocumentName=' + this.birtDocumentName
          )
          .subscribe(
            (repoResp: any) => {
              // PPTX report download service

              let blob = new Blob([repoResp.body], {
                type: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
              });

              var a = document.createElement('a');
              a.href = URL.createObjectURL(blob);
              a.download = this.reportName.replaceAll("_", " ").split('.')[0] + '.pptx';
              a.click();
              this.downloadBtnSpinner = 'pi-download';
            },
            (error) => {
              this.alertSeverity = 'error';
              if (Number(error.status) == 401) {
                this.alertMsg = 'Please Try After Some Time!';
              } else if (Number(error.status) == 500) {
                this.alertMsg = 'Internal Error';
              } else {
                this.alertMsg = 'Please Try After Some Time.';
              }
              this.downloadBtnSpinner = 'pi-download';
              this.addSingle();
            },
            () => { }
          );
      }
      else if (event.item.label == 'txt-pipe') {
        this.myRepoOutputService
          .TXTPIPEBirtReportDownloadService(
            this.reportId,
            'rptDocumentName=' + this.birtDocumentName
          )
          .subscribe(
            (repoResp: any) => {
              // PPTX report download service

              let blob = new Blob([repoResp.body], { type: 'text/text' });

              var a = document.createElement('a');
              a.href = URL.createObjectURL(blob);
              a.download = this.reportName.replaceAll("_", " ").split('.')[0] + '.txt';
              a.click();
              this.downloadBtnSpinner = 'pi-download';
            },
            (error) => {
              this.alertSeverity = 'error';
              if (Number(error.status) == 401) {
                this.alertMsg = 'Please Try After Some Time!';
              } else if (Number(error.status) == 500) {
                this.alertMsg = 'Internal Error';
              } else {
                this.alertMsg = 'Please Try After Some Time.';
              }
              this.downloadBtnSpinner = 'pi-download';
              this.addSingle();
            },
            () => { }
          );
      }
      else if (event.item.label == 'txt') {
        this.myRepoOutputService
          .TXTBirtReportDownloadService(
            this.reportId,
            'rptDocumentName=' + this.birtDocumentName
          )
          .subscribe(
            (repoResp: any) => {
              // PPTX report download service

              let blob = new Blob([repoResp.body], { type: 'text/text' });

              var a = document.createElement('a');
              a.href = URL.createObjectURL(blob);
              a.download = this.reportName.replaceAll("_", " ").split('.')[0] + '.txt';
              a.click();
              this.downloadBtnSpinner = 'pi-download';
            },
            (error) => {
              this.alertSeverity = 'error';
              if (Number(error.status) == 401) {
                this.alertMsg = 'Please Try After Some Time!';
              } else if (Number(error.status) == 500) {
                this.alertMsg = 'Internal Error';
              } else {
                this.alertMsg = 'Please Try After Some Time.';
              }
              this.downloadBtnSpinner = 'pi-download';
              this.addSingle();
            },
            () => { }
          );
      }
      else if (event.item.label == 'csv') {
        this.myRepoOutputService
          .CSVBirtReportDownloadService(
            this.reportId,
            'rptDocumentName=' + this.birtDocumentName
          )
          .subscribe(
            (repoResp: any) => {
              // PPTX report download service

              let blob = new Blob([repoResp.body], {
                type: 'text/csv;charset=utf-8',
              });

              var a = document.createElement('a');
              a.href = URL.createObjectURL(blob);
              a.download = this.reportName.replaceAll("_", " ").split('.')[0] + '.csv';
              a.click();
              this.downloadBtnSpinner = 'pi-download';
            },
            (error) => {
              this.alertSeverity = 'error';
              if (Number(error.status) == 401) {
                this.alertMsg = 'Please Try After Some Time!';
              } else if (Number(error.status) == 500) {
                this.alertMsg = 'Internal Error';
              } else {
                this.alertMsg = 'Please Try After Some Time.';
              }
              this.downloadBtnSpinner = 'pi-download';
              this.addSingle();
            },
            () => { }
          );
      }
      else if (event.item.label == 'Save csv to Inbox') {
        this.myRepoOutputService
          .CSVinboxBirtReportDownloadService(
            this.reportId,
            'rptDocumentName=' + this.birtDocumentName
          )
          .subscribe(
            (repoResp: any) => {
              this.downloadBtnSpinner = 'pi-download';
              if (repoResp.status == 200) {
                this.alertSeverity = 'success';
                this.alertMsg = 'Saved to Inbox!';
                this.addSingle();
              }
            },
            (error) => {
              this.alertSeverity = 'error';
              if (Number(error.status) == 401) {
                this.alertMsg = 'Please Try After Some Time!';
              } else if (Number(error.status) == 500) {
                this.alertMsg = 'Internal Error';
              } else {
                this.alertMsg = 'Please Try After Some Time.';
              }
              this.downloadBtnSpinner = 'pi-download';
              this.addSingle();
            },
            () => { }
          );
      }
    }
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.rowData);
    const workbook: XLSX.WorkBook = { Sheets: { 'Report': worksheet }, SheetNames: ['Report'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const file = new Blob([excelBuffer], { type: 'application/octet-stream' });
    saveAs(file, 'Report.xlsx');
  }
  addMessages(val) {
    if (val == 0) {
      this.alertMessage = [
        {
          severity: 'error',
          summary: 'Error',
          detail: 'Please fill all required fields.',
        },
      ];
    }
    if (val == 1) {
      this.alertMessage = [
        {
          severity: 'error',
          summary: 'Error',
          detail: 'End date cannot be less than start date.',
        },
      ];
    }
  }

  transformData(jsonData) {
    const headers = jsonData[1];

    // Map each data row to an object with named properties
    const transformedData = jsonData.slice(2).map(dataRow => {
      const transformedRow = {};
      Object.keys(headers).forEach(key => {
        if (key.startsWith('__EMPTY')) {
          transformedRow[headers[key]] = dataRow[key];
        }
      });
      return transformedRow;
    });

    return transformedData;
  }
  clearMessages() {
    this.alertMessage = [];
  }

  addSingle() {
    this.messageService.add({
      severity: this.alertSeverity,
      summary: '',
      detail: this.alertMsg,
    });
  }

  clear() {
    this.messageService.clear();
  }
  back() {
    this.router.navigate(['/discovery'], { queryParams: { search: this.searchMetri } });
    this.showreport = false;
    // window.history.back();
  }
  onRowReorder(event) {
    //console.log(event)
  }
  dynamicWidth(field: string, headerName: string): string {
    const minWidth = 180;
    const contentWidth = Math.max(
      ...this.rowData.map(item => String(item[field]).length)
    );
    const headerWidth = headerName.length;
    const maxWidth = Math.max(contentWidth, headerWidth);
    const calculatedWidth = maxWidth * 8;
    return `${Math.max(calculatedWidth, minWidth)}px`;
  }
}
