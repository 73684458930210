import { Component, OnInit, OnDestroy } from '@angular/core';
import { Message, MessageService } from 'primeng/api';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { HttpClient } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { Table } from 'primeng/table';
import { SortEvent } from 'primeng/api';
import { Paginator } from 'primeng/paginator';
import { ViewChild } from '@angular/core';

declare var d3: any;

@Component({
  template: `
    <img
      src="././assets/images/dualBallSpinner.svg"
      alt=""
      class="modal-spinner-img"
      *ngIf="isLoadingModal || customSortBool"
    />
    <p-button
      class="p-my-2 save-btn output-ts-btn"
      type="button"
      [label]="hisOrWeklyBoolVal"
      style=""
      (click)="historyOrWeeklyBtnClick()"
    ></p-button>
    <p-card class="table-card">
      <p-table
        #reportoutputfilestable
        class="tibo-myrepo-output-files-table"
        [value]="myReportRecords"
        [sortField]="tableSortColumn"
        [sortOrder]="tableSortDir"
        [scrollable]="true"
        scrollHeight="calc(100vh - 400px)"
        (sortFunction)="customSort($event)"
        [customSort]="true"
      >
        <ng-template pTemplate="header">
          <tr>
            <th
              pSortableColumn="authorizationName"
              (click)="theadClick()"
              class="firsh-table-header"
            >
              <div class="p-d-flex p-jc-between p-ai-center">
                File Name
                <p-sortIcon field="authorizationName"></p-sortIcon>
                <!--<p-columnFilter type="text" field="authorizationName" display="menu" class="p-ml-auto"></p-columnFilter>-->
              </div>
            </th>
            <th pSortableColumn="authorizationDesc" (click)="theadClick()">
              <div class="p-d-flex p-jc-between p-ai-center">
                Report Type
                <p-sortIcon field="authorizationDesc"></p-sortIcon>
                <!--<p-columnFilter type="text" field="authorizationDesc" display="menu" class="p-ml-auto"></p-columnFilter>-->
              </div>
            </th>
            <th pSortableColumn="createdDate" (click)="theadClick()">
              <div class="p-d-flex p-jc-between p-ai-center">
                Run Date/Time
                <p-sortIcon field="createdDate"></p-sortIcon>
                <!--<p-columnFilter type="date" field="createdDate" display="menu" class="p-ml-auto"></p-columnFilter>-->
              </div>
            </th>
            <th>Download</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-reportObj let-i="rowIndex">
          <tr>
            <td title="{{ reportObj?.authorizationName}}">{{ reportObj?.authorizationName}}</td>
            <td title="{{ reportObj?.authorizationDesc }}">{{ reportObj?.authorizationDesc }}</td>
            <td title="{{ reportObj?.strCreatedDate }}">{{ reportObj?.strCreatedDate}}</td>
            <td>
              <i
                class="bx bx-download bx-download-modal"
                (click)="
                  downloadOutputFileBtnClick(
                    reportObj?.authorizationId,
                    reportObj?.authorizationName,
                    i
                  )
                "
                *ngIf="getOutputFileIndex !== i"
              ></i>
              <i
                class="pi pi-spin pi-spinner spinner-circle-icon-modal"
                *ngIf="getOutputFileIndex === i && spinner"
              ></i>
              <!-- <div
                class=""
                style="width:max-content; min-width:50px; padding:3px 18px; background-color:#e7f0f9; border-radius:20px; box-sizing:border-box;"
              >
                <button
                  id=""
                  class=""
                  type="button"
                  pButton
                  icon="pi pi-download"
                  pTooltip="Download"
                  tooltipPosition="top"
                  style="background-color:#5ea7e0; width:26px; height:24px; border:0; border-radius:3px; border-color:#398dce;"
                  (click)="
                    downloadOutputFileBtnClick(
                      reportObj?.authorizationId,
                      reportObj?.authorizationName
                    )
                  "
                ></button>
              </div> -->
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td>{{ tableEmptyMessage }}</td>
          </tr>
        </ng-template>
      </p-table>
      <div class="p-d-flex pagination-container">
        <div class="pagination-first-part">
          <p class="p-mx-2">
            Showing <b>{{startIndex}}-{{endIndex}}</b> of
            <b>{{ totalRecords }}</b>
            Records
          </p>
        </div>
        <div class="pagination-second-part">
          <p-paginator #paginator
            styleClass="tibo-schedrepo-listView-p-paginator"
            [rows]="150"
            [totalRecords]="totalRecords"
            (onPageChange)="paginate($event)"
            [showJumpToPageDropdown]="true"
            [showPageLinks]="false"
          ></p-paginator>
        </div>
      </div>
    </p-card>
  `,
  styleUrls: ['./my-reports.component.css'],
  providers: [MessageService],
})
export class AllOutputFiles implements OnInit, OnDestroy {
  @ViewChild('paginator', { static: true }) paginator: Paginator;

  @ViewChild('reportoutputfilestable', { static: true }) table: Table;

  errorMsg = '';
  paginatorPageNo = 1;
  totalRecords = 0;
  myReportRecords = [];
  hisOrWeklyBoolVal = 'History';
  customSortBool: boolean = false;
  tableEmptyMessage: string = '';
  isLoadingModal: boolean = false;
  getOutputFileIndex: number = -1;
  spinner: boolean = false;
  startIndex: number=0;
  endIndex: number=0;

  constructor(
    private messageService: MessageService,
    public config: DynamicDialogConfig,
    private http: HttpClient
  ) {}

  requestPayload = {
    sort: [{ property: 'createdDate', dir: 'DESC' }],
    filters: [],
    filterType: 'and',
  };
  tableSortColumn = 'createdDate';
  tableSortDir = -1;

  outputFilesHistorySubscrip: Subscription;
  outputFilesDownLoadSubscrip: Subscription;

  ngOnInit() {
    this.isLoadingModal = true;
    this.outputFilesHistorySubscrip = this.http
      .post(
        'api/v1/report/' +
          this.config.data?.reportId +
          '/output/search?depth=0&limit=150&page=' +
          this.paginatorPageNo,
        this.requestPayload,
        {
          headers: { 'X-Auth-Token': localStorage.getItem('token') },
          observe: 'response',
        }
      )
      .subscribe(
        (recordsResp: any) => {
          d3.selectAll('.tibo-myrepo-output-files-spinner').style(
            'display',
            'none'
          ); // hidding reports container spinner

          this.totalRecords = recordsResp.body.totalRecords;
          recordsResp.body?.values
            ? (this.myReportRecords = recordsResp.body.values)
            : (this.myReportRecords = []);

          this.myReportRecords.forEach((d) => {
            if (d?.createdDate != null) {
              d.createdDate = new Date(d?.createdDate);
            }
          });

          this.myReportRecords.length > 0
            ? (this.tableEmptyMessage = '')
            : (this.tableEmptyMessage = 'No Records Found');
          this.isLoadingModal = false;
          this.startIndex=this.myReportRecords.length>0?1:0;
          this.endIndex=this.myReportRecords.length>0?this.myReportRecords.length:0;
        },
        (error) => {
          this.isLoadingModal = false;
          d3.selectAll('.tibo-myrepo-output-files-spinner').style(
            'display',
            'none'
          ); // hidding reports container spinner
          if (Number(error.status) == 401) {
            this.errorMsg = 'Please Try After Some Time!';
          } else if (Number(error.status) == 500) {
            this.errorMsg = 'Internal Error';
          } else {
            this.errorMsg = 'Please Try After Some Time.';
          }
          this.startIndex=0;
          this.endIndex=0;
          this.addSingle();
        }
      );
  }

  ngOnDestroy() {
    if (this.outputFilesHistorySubscrip) {
      this.outputFilesHistorySubscrip.unsubscribe();
    }
    if (this.outputFilesDownLoadSubscrip) {
      this.outputFilesDownLoadSubscrip.unsubscribe();
    }
  }

  theadClick() {
    this.customSortBool = true;
  }

  customSort(event: SortEvent) {
    this.tableSortColumn = event.field;
    this.tableSortDir = event.order;

    this.table.reset();
    this.table.sortField = this.tableSortColumn;
    this.table._sortOrder = this.tableSortDir;

    if (this.customSortBool) {
      this.requestPayload.sort = [];

      if (event.order == 1) {
        this.requestPayload.sort.push({ property: event.field, dir: 'ASC' });
      } else if (event.order == -1) {
        this.requestPayload.sort.push({ property: event.field, dir: 'DESC' });
      }

      d3.selectAll('.tibo-myrepo-output-files-spinner').style(
        'display',
        'block'
      ); // showing reports container spinner

      if (this.hisOrWeklyBoolVal == 'History') {
        this.outputFilesHistorySubscrip = this.http
          .post(
            'api/v1/report/' +
              this.config.data?.reportId +
              '/output/search?depth=0&limit=150&page=' +
              this.paginatorPageNo,
            this.requestPayload,
            {
              headers: { 'X-Auth-Token': localStorage.getItem('token') },
              observe: 'response',
            }
          )
          .subscribe(
            (recordsResp: any) => {
              d3.selectAll('.tibo-myrepo-output-files-spinner').style(
                'display',
                'none'
              ); // hidding reports container spinner

              this.totalRecords = recordsResp.body.totalRecords;
              let sortedData = [];
              recordsResp.body?.values
                ? (sortedData = recordsResp.body.values)
                : (sortedData = []);

              sortedData.forEach((d) => {
                if (d?.createdDate != null) {
                  d.createdDate = new Date(d?.createdDate);
                }
              });

              event.data.splice(0, event.data.length);

              sortedData.forEach((d) => {
                event.data.push(d);
              });

              sortedData.length > 0
                ? (this.tableEmptyMessage = '')
                : (this.tableEmptyMessage = 'No Records Found');
              this.customSortBool = false;
            },
            (error) => {
              this.customSortBool = false;
              d3.selectAll('.tibo-myrepo-output-files-spinner').style(
                'display',
                'none'
              ); // hidding reports container spinner
              if (Number(error.status) == 401) {
                this.errorMsg = 'Please Try After Some Time!';
              } else if (Number(error.status) == 500) {
                this.errorMsg = 'Internal Error';
              } else {
                this.errorMsg = 'Please Try After Some Time.';
              }

              this.addSingle();
            }
          );
      } else {
        this.outputFilesHistorySubscrip = this.http
          .post(
            'api/v1/report/' +
              this.config.data?.reportId +
              '/output/history/search?depth=0&limit=150&page=' +
              this.paginatorPageNo,
            this.requestPayload,
            {
              headers: { 'X-Auth-Token': localStorage.getItem('token') },
              observe: 'response',
            }
          )
          .subscribe(
            (recordsResp: any) => {
              d3.selectAll('.tibo-myrepo-output-files-spinner').style(
                'display',
                'none'
              ); // hidding reports container spinner

              this.totalRecords = recordsResp.body.totalRecords;
              let sortedData = [];
              recordsResp.body?.values
                ? (sortedData = recordsResp.body.values)
                : (sortedData = []);

              sortedData.forEach((d) => {
                if (d?.createdDate != null) {
                  d.createdDate = new Date(d?.createdDate);
                }
              });

              event.data.splice(0, event.data.length);

              sortedData.forEach((d) => {
                event.data.push(d);
              });

              sortedData.length > 0
                ? (this.tableEmptyMessage = '')
                : (this.tableEmptyMessage = 'No Records Found');
              this.customSortBool = false;
            },
            (error) => {
              this.customSortBool = false;
              d3.selectAll('.tibo-myrepo-output-files-spinner').style(
                'display',
                'none'
              ); // hidding reports container spinner
              if (Number(error.status) == 401) {
                this.errorMsg = 'Please Try After Some Time!';
              } else if (Number(error.status) == 500) {
                this.errorMsg = 'Internal Error';
              } else {
                this.errorMsg = 'Please Try After Some Time.';
              }

              this.addSingle();
            }
          );
      }
    }
  }

  paginate(event) {
    this.paginatorPageNo = event.page + 1;
    this.myReportRecords = [];
    this.isLoadingModal = true;
    d3.selectAll('.tibo-myrepo-output-files-spinner').style('display', 'block'); // showing reports container spinner

    if (this.hisOrWeklyBoolVal == 'History') {
      this.outputFilesHistorySubscrip = this.http
        .post(
          'api/v1/report/' +
            this.config.data?.reportId +
            '/output/search?depth=0&limit=150&page=' +
            this.paginatorPageNo,
          this.requestPayload,
          {
            headers: { 'X-Auth-Token': localStorage.getItem('token') },
            observe: 'response',
          }
        )
        .subscribe(
          (recordsResp: any) => {
            d3.selectAll('.tibo-myrepo-output-files-spinner').style(
              'display',
              'none'
            ); // hidding reports container spinner

            //this.totalRecords = recordsResp.body.totalRecords;
            recordsResp.body?.values
              ? (this.myReportRecords = recordsResp.body.values)
              : (this.myReportRecords = []);

            this.myReportRecords.forEach((d) => {
              if (d?.createdDate != null) {
                d.createdDate = new Date(d?.createdDate);
              }
            });
            this.isLoadingModal = false;
            this.startIndex=this.myReportRecords.length>0?(150*(this.paginatorPageNo-1))+1:0;
            this.endIndex=this.myReportRecords.length>0?(this.startIndex-1)+this.myReportRecords.length:0;
          },
          (error) => {
            this.isLoadingModal = false;
            d3.selectAll('.tibo-myrepo-output-files-spinner').style(
              'display',
              'none'
            ); // hidding reports container spinner
            if (Number(error.status) == 401) {
              this.errorMsg = 'Please Try After Some Time!';
            } else if (Number(error.status) == 500) {
              this.errorMsg = 'Internal Error';
            } else {
              this.errorMsg = 'Please Try After Some Time.';
            }
            this.startIndex=0;
            this.endIndex=0;
            this.addSingle();
          }
        );
    } else {
      this.outputFilesHistorySubscrip = this.http
        .post(
          'api/v1/report/' +
            this.config.data?.reportId +
            '/output/history/search?depth=0&limit=150&page=' +
            this.paginatorPageNo,
          this.requestPayload,
          {
            headers: { 'X-Auth-Token': localStorage.getItem('token') },
            observe: 'response',
          }
        )
        .subscribe(
          (recordsResp: any) => {
            d3.selectAll('.tibo-myrepo-output-files-spinner').style(
              'display',
              'none'
            ); // hidding reports container spinner

            //this.totalRecords = recordsResp.body.totalRecords;
            recordsResp.body?.values
              ? (this.myReportRecords = recordsResp.body.values)
              : (this.myReportRecords = []);

            this.myReportRecords.forEach((d) => {
              if (d?.createdDate != null) {
                d.createdDate = new Date(d?.createdDate);
              }
            });
            this.startIndex=this.myReportRecords.length>0?(150*(this.paginatorPageNo-1))+1:0;
            this.endIndex=this.myReportRecords.length>0?(this.startIndex-1)+this.myReportRecords.length:0;
            this.isLoadingModal = false;
          },
          (error) => {
            this.isLoadingModal = false;
            d3.selectAll('.tibo-myrepo-output-files-spinner').style(
              'display',
              'none'
            ); // hidding reports container spinner
            if (Number(error.status) == 401) {
              this.errorMsg = 'Please Try After Some Time!';
            } else if (Number(error.status) == 500) {
              this.errorMsg = 'Internal Error';
            } else {
              this.errorMsg = 'Please Try After Some Time.';
            }
            this.startIndex=0;
            this.endIndex=0;
            this.addSingle();
          }
        );
    }
  }

  historyOrWeeklyBtnClick() {
    this.tableSortColumn = 'createdDate';
    this.tableSortDir = -1;

    /*this.table.reset();
		this.table.sortField = this.tableSortColumn;
		this.table._sortOrder = this.tableSortDir;*/

    this.tableEmptyMessage = '';

    this.requestPayload = {
      sort: [{ property: 'createdDate', dir: 'DESC' }],
      filters: [],
      filterType: 'and',
    };
    this.paginator.first=0;
    this.paginatorPageNo = 1;
    this.myReportRecords = [];
    this.totalRecords = 0;
    this.startIndex=0;
    this.endIndex=0;
    this.isLoadingModal = true;
    d3.selectAll('.tibo-myrepo-output-files-spinner').style('display', 'block'); // showing reports container spinner

    if (this.hisOrWeklyBoolVal == 'History') {
      this.hisOrWeklyBoolVal = 'Weekly';

      this.outputFilesHistorySubscrip = this.http
        .post(
          'api/v1/report/' +
            this.config.data?.reportId +
            '/output/history/search?depth=0&limit=150&page=' +
            this.paginatorPageNo,
          this.requestPayload,
          {
            headers: { 'X-Auth-Token': localStorage.getItem('token') },
            observe: 'response',
          }
        )
        .subscribe(
          (recordsResp: any) => {
            d3.selectAll('.tibo-myrepo-output-files-spinner').style(
              'display',
              'none'
            ); // hidding reports container spinner

            this.totalRecords = recordsResp.body.totalRecords;
            recordsResp.body?.values
              ? (this.myReportRecords = recordsResp.body.values)
              : (this.myReportRecords = []);

            this.myReportRecords.forEach((d) => {
              if (d?.createdDate != null) {
                d.createdDate = new Date(d?.createdDate);
              }
            });

            this.myReportRecords.length > 0
              ? (this.tableEmptyMessage = '')
              : (this.tableEmptyMessage = 'No Records Found');
              // this.paginator.first=0;
              this.startIndex=this.myReportRecords.length>0?1:0;
              this.endIndex=this.myReportRecords.length>0?this.myReportRecords.length:0;
            this.isLoadingModal = false;
          },
          (error) => {
            this.isLoadingModal = false;
            d3.selectAll('.tibo-myrepo-output-files-spinner').style(
              'display',
              'none'
            ); // hidding reports container spinner
            if (Number(error.status) == 401) {
              this.errorMsg = 'Please Try After Some Time!';
            } else if (Number(error.status) == 500) {
              this.errorMsg = 'Internal Error';
            } else {
              this.errorMsg = 'Please Try After Some Time.';
            }
            this.startIndex=0;
            this.endIndex=0;
            this.addSingle();
          }
        );
    } else {
      this.hisOrWeklyBoolVal = 'History';

      this.outputFilesHistorySubscrip = this.http
        .post(
          'api/v1/report/' +
            this.config.data?.reportId +
            '/output/search?depth=0&limit=150&page=' +
            this.paginatorPageNo,
          this.requestPayload,
          {
            headers: { 'X-Auth-Token': localStorage.getItem('token') },
            observe: 'response',
          }
        )
        .subscribe(
          (recordsResp: any) => {
            d3.selectAll('.tibo-myrepo-output-files-spinner').style(
              'display',
              'none'
            ); // hidding reports container spinner

            this.totalRecords = recordsResp.body.totalRecords;
            recordsResp.body?.values
              ? (this.myReportRecords = recordsResp.body.values)
              : (this.myReportRecords = []);

            this.myReportRecords.forEach((d) => {
              if (d?.createdDate != null) {
                d.createdDate = new Date(d?.createdDate);
              }
            });
            this.startIndex=this.myReportRecords.length>0?1:0;
            this.endIndex=this.myReportRecords.length>0?this.myReportRecords.length:0;
            this.myReportRecords.length > 0
              ? (this.tableEmptyMessage = '')
              : (this.tableEmptyMessage = 'No Records Found');

            this.paginator.changePage(0);
            this.isLoadingModal = false;
          },
          (error) => {
            d3.selectAll('.tibo-myrepo-output-files-spinner').style(
              'display',
              'none'
            ); // hidding reports container spinner
            this.isLoadingModal = false;
            if (Number(error.status) == 401) {
              this.errorMsg = 'Please Try After Some Time!';
            } else if (Number(error.status) == 500) {
              this.errorMsg = 'Internal Error';
            } else {
              this.errorMsg = 'Please Try After Some Time.';
            }
            this.totalRecords =0;
            this.startIndex=0;
            this.endIndex=0;
            this.addSingle();
          }
        );
    }
  }

  downloadOutputFileBtnClick(fileId, fileName, index) {
    this.getOutputFileIndex = index;
    this.spinner = true;
    if (fileName.split('.').pop().toLowerCase() == 'xls') {
      this.outputFilesDownLoadSubscrip = this.http
        .get('api/v1/file/' + fileId + '/export', {
          headers: { 'X-Auth-Token': localStorage.getItem('token') },
          responseType: 'blob' as 'json',
          observe: 'response',
        })
        .subscribe(
          (response: any) => {
            let blob = new Blob([response.body], {
              type: 'application/vnd.ms-excel',
            });
            const contentDisposition = response.headers.get('content-disposition');
            const filename = contentDisposition
                      .split(';')[1]
                      .split('filename')[1]
                      .split('=')[1]
                      .trim()
                      .match(/"([^"]+)"/)[1];
            var a = document.createElement('a');
            a.href = URL.createObjectURL(blob);
            a.download = filename;
            a.click();
            this.getOutputFileIndex = -1;
            this.spinner = false;
          },
          (error) => {
            if (Number(error.status) == 401) {
              this.errorMsg = 'Please Try After Some Time!';
            } else if (Number(error.status) == 500) {
              this.errorMsg = 'Internal Error';
            } else {
              this.errorMsg = 'Please Try After Some Time.';
            }
            this.getOutputFileIndex = -1;
            this.spinner = false;
            this.addSingle();
          }
        );
    } else if (fileName.split('.').pop().toLowerCase() == 'xlsx') {
      this.outputFilesDownLoadSubscrip = this.http
        .get('api/v1/file/' + fileId + '/export', {
          headers: { 'X-Auth-Token': localStorage.getItem('token') },
          responseType: 'blob' as 'json',
          observe: 'response',
        })
        .subscribe(
          (response: any) => {
            let blob = new Blob([response.body], {
              type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            });
            const contentDisposition = response.headers.get('content-disposition');
            const filename = contentDisposition
                      .split(';')[1]
                      .split('filename')[1]
                      .split('=')[1]
                      .trim()
                      .match(/"([^"]+)"/)[1];
            var a = document.createElement('a');
            a.href = URL.createObjectURL(blob);
            a.download = filename;
            // a.download = fileName;
            a.click();
            this.getOutputFileIndex = -1;
            this.spinner = false;
          },
          (error) => {
            if (Number(error.status) == 401) {
              this.errorMsg = 'Please Try After Some Time!';
            } else if (Number(error.status) == 500) {
              this.errorMsg = 'Internal Error';
            } else {
              this.errorMsg = 'Please Try After Some Time.';
            }
            this.getOutputFileIndex = -1;
            this.spinner = false;
            this.addSingle();
          }
        );
    } else if (fileName.split('.').pop().toLowerCase() == 'csv') {
      this.outputFilesDownLoadSubscrip = this.http
        .get('api/v1/file/' + fileId + '/export', {
          headers: { 'X-Auth-Token': localStorage.getItem('token') },
          responseType: 'text',
          observe: 'response',
        })
        .subscribe(
          (response: any) => {
            let blob = new Blob([response.body], {
              type: 'text/csv;charset=utf-8',
            });
            const contentDisposition = response.headers.get('content-disposition');
            const filename = contentDisposition
                      .split(';')[1]
                      .split('filename')[1]
                      .split('=')[1]
                      .trim()
                      .match(/"([^"]+)"/)[1];
            var a = document.createElement('a');
            a.href = URL.createObjectURL(blob);
            a.download = filename;
            a.click();
            this.getOutputFileIndex = -1;
            this.spinner = false;
          },
          (error) => {
            if (Number(error.status) == 401) {
              this.errorMsg = 'Please Try After Some Time!';
            } else if (Number(error.status) == 500) {
              this.errorMsg = 'Internal Error';
            } else {
              this.errorMsg = 'Please Try After Some Time.';
            }
            this.getOutputFileIndex = -1;
            this.spinner = false;
            this.addSingle();
          }
        );
    } else if (fileName.split('.').pop().toLowerCase() == 'txt') {
      this.outputFilesDownLoadSubscrip = this.http
        .get('api/v1/file/' + fileId + '/export', {
          headers: { 'X-Auth-Token': localStorage.getItem('token') },
          responseType: 'text',
          observe: 'response',
        })
        .subscribe(
          (response: any) => {
            let blob = new Blob([response.body], { type: 'text/text' });
            const contentDisposition = response.headers.get('content-disposition');
            const filename = contentDisposition
                      .split(';')[1]
                      .split('filename')[1]
                      .split('=')[1]
                      .trim()
                      .match(/"([^"]+)"/)[1];
            var a = document.createElement('a');
            a.href = URL.createObjectURL(blob);
            a.download = filename;
            a.click();
            this.getOutputFileIndex = -1;
            this.spinner = false;
          },
          (error) => {
            if (Number(error.status) == 401) {
              this.errorMsg = 'Please Try After Some Time!';
            } else if (Number(error.status) == 500) {
              this.errorMsg = 'Internal Error';
            } else {
              this.errorMsg = 'Please Try After Some Time.';
            }
            this.getOutputFileIndex = -1;
            this.spinner = false;
            this.addSingle();
          }
        );
    } else if (fileName.split('.').pop().toLowerCase() == 'pdf') {
      this.outputFilesDownLoadSubscrip = this.http
        .get('api/v1/file/' + fileId + '/export', {
          headers: { 'X-Auth-Token': localStorage.getItem('token') },
          responseType: 'blob' as 'json',
          observe: 'response',
        })
        .subscribe(
          (response: any) => {
            let blob = new Blob([response.body], { type: 'application/pdf' });
            const contentDisposition = response.headers.get('content-disposition');
            const filename = contentDisposition
                      .split(';')[1]
                      .split('filename')[1]
                      .split('=')[1]
                      .trim()
                      .match(/"([^"]+)"/)[1];
            var a = document.createElement('a');
            a.href = URL.createObjectURL(blob);
            a.download = filename;
            a.click();
            this.getOutputFileIndex = -1;
            this.spinner = false;
          },
          (error) => {
            if (Number(error.status) == 401) {
              this.errorMsg = 'Please Try After Some Time!';
            } else if (Number(error.status) == 500) {
              this.errorMsg = 'Internal Error';
            } else {
              this.errorMsg = 'Please Try After Some Time.';
            }
            this.getOutputFileIndex = -1;
            this.spinner = false;
            this.addSingle();
          }
        );
    } else if (fileName.split('.').pop().toLowerCase() == 'doc') {
      this.outputFilesDownLoadSubscrip = this.http
        .get('api/v1/file/' + fileId + '/export', {
          headers: { 'X-Auth-Token': localStorage.getItem('token') },
          responseType: 'blob' as 'json',
          observe: 'response',
        })
        .subscribe(
          (response: any) => {
            let blob = new Blob([response.body], {
              type: 'application/msword',
            });
            const contentDisposition = response.headers.get('content-disposition');
            const filename = contentDisposition
                      .split(';')[1]
                      .split('filename')[1]
                      .split('=')[1]
                      .trim()
                      .match(/"([^"]+)"/)[1];
            var a = document.createElement('a');
            a.href = URL.createObjectURL(blob);
            a.download = filename;
            a.click();
            this.getOutputFileIndex = -1;
            this.spinner = false;
          },
          (error) => {
            if (Number(error.status) == 401) {
              this.errorMsg = 'Please Try After Some Time!';
            } else if (Number(error.status) == 500) {
              this.errorMsg = 'Internal Error';
            } else {
              this.errorMsg = 'Please Try After Some Time.';
            }
            this.getOutputFileIndex = -1;
            this.spinner = false;
            this.addSingle();
          }
        );
    } else if (fileName.split('.').pop().toLowerCase() == 'pptx') {
      this.outputFilesDownLoadSubscrip = this.http
        .get('api/v1/file/' + fileId + '/export', {
          headers: { 'X-Auth-Token': localStorage.getItem('token') },
          responseType: 'blob' as 'json',
          observe: 'response',
        })
        .subscribe(
          (response: any) => {
            let blob = new Blob([response.body], {
              type: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
            });
            const contentDisposition = response.headers.get('content-disposition');
            const filename = contentDisposition
                      .split(';')[1]
                      .split('filename')[1]
                      .split('=')[1]
                      .trim()
                      .match(/"([^"]+)"/)[1];
            var a = document.createElement('a');
            a.href = URL.createObjectURL(blob);
            a.download = filename;
            a.click();
            this.getOutputFileIndex = -1;
            this.spinner = false;
          },
          (error) => {
            if (Number(error.status) == 401) {
              this.errorMsg = 'Please Try After Some Time!';
            } else if (Number(error.status) == 500) {
              this.errorMsg = 'Internal Error';
            } else {
              this.errorMsg = 'Please Try After Some Time.';
            }
            this.getOutputFileIndex = -1;
            this.spinner = false;
            this.addSingle();
          }
        );
    }
  }

  addSingle() {
    this.messageService.add({
      severity: 'error',
      summary: '',
      detail: this.errorMsg,
    });
  }

  clear() {
    this.messageService.clear();
  }
}
