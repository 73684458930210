import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Message, MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { LoginService } from './login.service';
import * as CryptoJS from 'crypto-js';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  providers: [LoginService],
})
export class LoginComponent implements OnInit {
  loginUsername = '';
  loginPassword = '';
  isLoading = false;
  loginServiceSubcribition: Subscription;
  userServiceSubcribtion: Subscription;
  configServiceSubcribtion: Subscription;
  errorMsg = '';
  userid: any = 0;
  @Output() boolVal = new EventEmitter<any>();

  constructor( ) {}

  ngOnInit(): void {}

  onLoginButtonClick() {
    if (this.loginUsername == '' || this.loginPassword == '') {
      this.boolVal.emit(true);
      this.isLoading=false;
    } 
  }
}
