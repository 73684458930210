<div class="home-page-parent-container2">
	<div class="home-page-parent-container" *ngIf="showPanel">
		<div style="display: flex;">
			<div class="p-col-6">
				<div class="p-col-12">
					<div class="search-container" id="shepherd-searchReports">
						<div class="quickSearch">Search</div>
						<div class="search-div" >
							<input type="text" [(ngModel)]="businessMetricText" #data_disco_business_metric_text_id
								id="data-disco-business-metric-text-id" class="search-input" pInputText
								placeholder="Search" (keyup.enter)="onSearchBtnClick()" autocomplete="off"/>
							<div class="search--clear-bar-icon">
								<i class="fa fa-close" (click)="businessMetricText =''" (click)="onSearchBtnClick()"
									pTooltip="Clear"></i>
							</div>
							<div class="search-bar-icon">
								<i class="fa fa-search" (click)="onSearchBtnClick()" pTooltip="Click to Search"
									[disabled]="businessMetricText == ''"></i>
							</div>
						</div>
						<div class="check-parent">
							<div *ngFor="let category of categories" class="checkboxes">
								<div class="single-check">
									<p-checkbox name="group2" value="category" [(ngModel)]="selectedCategories"
										[value]="category" [inputId]="category.key" (onChange)="checkboxClick()">
									</p-checkbox>&nbsp;
									<label [for]="category.key" class="category-name">{{ category.name
										}}</label>
								</div>
							</div>
						</div>
						<div class="abbc" *ngIf="report">
							<img src="././assets/images/dualBallSpinner.svg" alt="" class="modal-spinner-img"
								*ngIf="isLoading">
							<div *ngIf="resultsBool" class="tibo-data-disco-results-container p-grid p-jc-start">

								<div id="buildreports-folderview-panel" class="tibo-buildrepo-folderview-panel">
									<div class="list-result-header">
										<span id="all-reports-id" class="tibo-buildrepo-folderview-panel-title">Search
											Result</span>
									</div>
									<div id="buildreport-folder-panel-scroll-div"
										styleClass="tibo-buildrepo-folderview-panel-scrollpanel">
										<div class="list-result">
											<!-- <div class="p-col-12">
										<div class="p-col-12 p-grid">
											 -->
											<p-accordion styleClass="accordion-head" [activeIndex]="activeIndex">
												<p-accordionTab header="Reports ({{reports.length}})"
													style="font-family:Roboto; font-size:16px; font-weight:bold;"
													*ngIf="showR">
													<div class=" p-col-12 p-p-0 data-disc-reports-plistbox"
														style="display: flex;">
														<!-- <i class="fa-regular fa-clipboard" style="padding-top: 10px;
											font-size: 35px;padding-left: 10px;"></i> -->
														<!-- <span style="width:170px;font-family:Roboto; font-size:16px; font-weight:bold; color:#0f528c;margin: 20px 0px 10px 10px;">
												Reports ({{reports.length}})
											</span> -->
														<div class="reports">
															<p-listbox [options]="reports" class="list-box"
																[(ngModel)]="selectedCity" optionLabel="name" >
																<ng-template let-widget pTemplate="item">
																	<div class="reportMain">
																		<!-- <div>{{widget.name}}</div> -->
																		<div>
																			{{widget.searchResult.replace('.rptdesign','').replace('.sql','')}}
																		</div>
																		<div class="reportMainFirst">
																			<div class="reportMainTitle">
																				<!-- {{widget.desc?widget.desc:'No Description'}} -->
																				{{widget.searchComment?widget.searchComment:'No
																				Description'}}
																			</div>
																			<div class="reportMainButton">
																				<button style="border-radius:20px;margin-bottom: 10px;" *ngIf="widget.searchIsRunnable.toLowerCase() == 'y'" id="" class="p-mr-2" type="button" pButton icon="pi pi-play" pTooltip="Run Report" tooltipPosition="top"
																					(click)="itemSelectedFunc1(widget,'report','run')"></button>
																				<button style="border-radius:20px;margin-bottom: 10px;" *ngIf="widget.searchIsSchedulable.toLowerCase() == 'y'" id="" class="p-mr-2" type="button" pButton icon="pi pi-calendar-plus" pTooltip="Schedule Report" tooltipPosition="top"
																					(click)="itemSelectedFunc1(widget,'report','schedule')"></button>
																			</div>
																	</div>
																	</div>
																</ng-template>
															</p-listbox>
														</div>
													</div>
												</p-accordionTab>
												<p-accordionTab header="Dashboards ({{dashboards.length}})"
													style="font-family:Roboto; font-size:16px; font-weight:bold;"
													*ngIf="showD">
													<div class=" p-col-12 p-p-0 data-disc-dashboards-plistbox"
														style="display: flex;">
														<!-- <i class="fa-solid fa-border-all" style="padding-top: 10px;
											font-size: 35px;padding-left: 10px;"></i> -->
														<!-- <img src="././assets/images/chart-icon/chart0.svg"
											style='width: 50px;padding-top: 10px;padding-left: 10px;
											height: 40px;'> -->
														<!-- <span
												style="width:155px;font-family:Roboto; font-size:16px; font-weight:bold; color:#0f528c;margin: 20px 0px 10px 10px;">Dashboards
												({{dashboards.length}})</span> -->
														<div class="dash">
															<p-listbox class="list-box" [options]="dashboards"
																[(ngModel)]="selectedCity" optionLabel="name"
																(onClick)="itemSelectedFunc($event,'dashboard')">
																<ng-template let-widget pTemplate="item">
																	<div style="display:block;font-weight: bolder;">
																		<!-- <div>{{widget.name}}</div> -->
																		<div>{{widget.searchResult}}</div>
																		<div style="font-weight: 400;
														padding-left: 20px;
														margin: 5px;">
																			<!-- {{widget.desc?widget.desc:'No Description'}} -->
																			{{widget.searchComment?widget.searchComment:'No
																			Description'}}
																		</div>


																	</div>
																</ng-template>
															</p-listbox>
														</div>
													</div>
												</p-accordionTab>
												<!-- </div> -->
												<!-- <div class="p-col-12 p-grid"> -->
												<p-accordionTab header="Widgets ({{widgets.length}})"
													style="font-family:Roboto; font-size:16px; font-weight:bold;"
													*ngIf="showW">
													<div class=" p-col-12 p-p-0 data-disc-widgets-plistbox"
														style="display: flex;">
														<!-- <i class="fa-regular fa-chart-bar" style="padding-top: 10px;
											font-size: 35px;padding-left: 10px;"></i> -->
														<!-- <span
												style="width:170px;font-family:Roboto; font-size:16px; font-weight:bold; color:#0f528c;margin: 20px 0px 10px 10px;">Widgets ({{widgets.length}})</span> -->
														<div class="widgets">
															<p-listbox [options]="widgets" class="list-box"
																[(ngModel)]="selectedCity" optionLabel="name"
																(onClick)="itemSelectedFunc($event,'widget')">
																<ng-template let-widget pTemplate="item">
																	<div style="display:block;font-weight: bolder;">
																		<!-- <div>{{widget.name}}</div> -->
																		<div>{{widget.searchResult}}</div>
																		<div style="font-weight: 400;
														padding-left: 20px;
														margin: 5px;">
																			<!-- {{widget.desc?widget.desc:'No Description'}} -->
																			{{widget.searchComment?widget.searchComment:'No
																			Description'}}
																		</div>
																	</div>
																</ng-template>
															</p-listbox>
														</div>
													</div>
												</p-accordionTab>
												<p-accordionTab header="Build A Report ({{buildReport.length}})"
													style="font-family:Roboto; font-size:16px; font-weight:bold;"
													*ngIf="showB">
													<div class=" p-col-12 p-p-0 data-disc-widgets-plistbox"
														style="display: flex;">
														<!-- <img src="././assets/images/chart-icon/reporting.svg"
											style='width: 50px;padding-top: 10px;padding-left: 10px;
											height: 40px;'> -->
														<!-- <span
												style="width:170px;font-family:Roboto; font-size:16px; font-weight:bold; color:#0f528c;margin: 20px 0px 10px 10px;">Build A Report
												({{buildReport.length}})</span> -->
														<div class="widgets">
															<p-listbox [options]="buildReport" class="list-box"
																[(ngModel)]="selectedCity" optionLabel="name"
																(onClick)="itemSelectedFunc($event,'buildareport')">
																<ng-template let-widget pTemplate="item">
																	<div style="display:block;font-weight: bolder;">
																		<!-- <div>{{widget.name}}</div> -->
																		<div>{{widget.searchResult}}</div>
																		<div style="font-weight: 400;
														padding-left: 20px;
														margin: 5px;">
																			<!-- {{widget.desc?widget.desc:'No Description'}} -->
																			{{widget.searchComment?widget.searchComment:'No
																			Description'}}
																		</div>
																	</div>
																</ng-template>
															</p-listbox>
														</div>
													</div>
												</p-accordionTab>
											</p-accordion>
											<!-- </div> -->
											<!-- </div> -->
										</div>
									</div>
								</div>
							</div>


						</div>
					</div>
				</div>
			</div>
			<div class="p-col-6">
				<div class="p-col-12">
					<div id="shepherd-quickNav">
						<div style="display: flex;padding-bottom: 10px;">
							<div class="widgetClass left-right-transition-order-one" routerLink="/my-report-inbox"
								*ngIf="showReportInbox">
								<a class="home-comp-icons"><i class="pi pi-inbox"
										style="color: #622e82"></i></a>
								<h1 class="widgettext">My Inbox</h1>
							</div>
							<div class="widgetClass left-right-transition-order-one" routerLink="/my-reports" (click)="makeListEmpty()"
								*ngIf="showRouteMyReport">
								<a class="home-comp-icons"><i class="bx bx-folder" style="color: #e3da5a"></i></a>
								<h1 class="widgettext">My Reports</h1>
							</div>
							<div class="widgetClass left-right-transition-order-one" routerLink="/create-dashboard"
								*ngIf="showRouteSettings">
								<a class="home-comp-icons"><i class="fa-regular fa-plus" style="color: #FF5722"></i></a>
								<h1 class="widgettext">Create Dashboard</h1>
							</div>
						</div>
						<div style="display: flex;padding-bottom: 10px;">
							<div class="widgetClass left-right-transition-order-one" routerLink="/build-report"
								*ngIf="showRouteBuild">
								<a class="home-comp-icons"><i class='fa-regular fa-clipboard'
										style="color: #0e4e6d"></i></a>
								<h1 class="widgettext">Build A Report</h1>
							</div>
							<div class="widgetClass left-right-transition-order-one" routerLink="/dashboard"
								*ngIf="showRouteCreate">
								<a class="home-comp-icons"><i class="fa-solid fa-book" style="color: #2988b6"></i></a>
								<h1 class="widgettext">My Dashboard</h1>
							</div>
							<div class="widgetClass left-right-transition-order-one" routerLink="/adhoc"
								*ngIf="showRouteDiscovery">
								<a class="home-comp-icons"><i class="fa fa-table scale-class"
										style="color: #fbc02d"></i></a>
								<h1 class="widgettext">Adhoc Report</h1>
							</div>
						</div>
					</div>
					<div class="p-grid" id="shepherd-recentlyViewed">
						<div class="p-col-12">
							<div class="p-grid" 
								style="margin: -11px -1px;padding-top: 10px;display: flex;padding-bottom: 10px;">
								<div class="p-col-12">
									<div class="most-used-dashboard-container"><span class="loader"
											*ngIf="loader1"></span>
										<span *ngIf="!loader1">
											<p class="mostUsedText1">Previously Viewed Dashboards & Reports</p>
											<div class="dataClass">
												<a *ngFor="let d of Dashboard" class="dashboard">
													<p style="cursor: pointer;" class="dashboard-anchor-tag"
														 *ngIf="d.type=='report'">
														<span class="Name"><i
																class="fa-regular fa-clipboard menu-panel-icons"
																style="padding-right: 10px;"></i>{{d.authorizationName.replace('.rptdesign',
															'').replace('.sql', '')}}</span><span
															class="date">{{d.date}}</span>
															<span class="Name1">
																<button style="border-radius:20px;" *ngIf="d.isRunnable == 'Y'"  id="" class="p-mr-2" type="button" pButton icon="pi pi-play" pTooltip="Run Report" tooltipPosition="top"
																(click)="goToDashboard(d,'run')"></button>
																<button style="border-radius:20px;" *ngIf="d.isSchedulable == 'Y'" id="" class="p-mr-2" type="button" pButton icon="pi pi-calendar-plus" pTooltip="Schedule Report" tooltipPosition="top"
																(click)="goToDashboard(d,'schedule')"></button>
																</span>
													</p>
													<p style="cursor: pointer;" class="dashboard-anchor-tag"
														*ngIf="d.type=='dashboard'">
														<span class="Name"><i
																class="fa-solid fa-chart-column menu-panel-icons"
																style="padding-right: 10px;"></i>{{d.authorizationName}}</span><span
															class="date">{{d.date}}</span>
															<span class="Name1">
																<button style="border-radius:20px;"  id="" class="p-mr-2" type="button" pButton icon="pi pi-play" pTooltip="Run a Dashboard" tooltipPosition="top"
																(click)="goToDashboard(d,'')"></button>
																<!-- <button style="border-radius:20px;" *ngIf="d.isSchedulable == 'Y'" id="" class="p-mr-2" type="button" pButton icon="pi pi-calendar-plus" pTooltip="Schedule Report" tooltipPosition="top"
																(click)="goToDashboard(d)"></button> -->
															</span>
													</p>
													
													<!-- <p style="cursor: pointer;" class="dashboard-anchor-tag"
													(click)="goToDashboard(d)" >
													<span class="Name">
															<button style="border-radius:20px;margin-bottom: 10px;" *ngIf="d.isRunnable == 'Y'" id="" class="p-mr-2" type="button" pButton icon="pi pi-play" pTooltip="Run Report" tooltipPosition="top"
																(click)="itemSelectedFunc1(widget,'report','run')"></button>
															<button style="border-radius:20px;margin-bottom: 10px;" *ngIf="d.isSchedulable == 'Y'" id="" class="p-mr-2" type="button" pButton icon="pi pi-calendar-plus" pTooltip="Schedule Report" tooltipPosition="top"
																(click)="itemSelectedFunc1(widget,'report','schedule')"></button></span>
													</p> -->
												</a>
											</div>
										</span>
									</div>
								</div>
								<!-- <div class="p-col-6" id="shepherd-recentReports">
								<div class="most-used-dashboard-container"><span class="loader" *ngIf="loader2"></span>
									<span *ngIf="!loader2">
										<p class="mostUsedText1">Previously Viewed Reports</p>
										<div class="dataClass">
											<a *ngFor="let d of recentReports" class="dashboard">
												<p style="cursor: pointer;" class="dashboard-anchor-tag"
													(click)="goToReport(d.authorizationId,d.authorizationName)">
													<span class="Name">{{d.authorizationName.replace('.rptdesign',
														'').replace('.sql', '')}}</span><span
														class="date">{{d.date}}</span>
												</p>
											</a>
										</div>
									</span>
								</div>
							</div> -->
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- <div style="padding-top: 10px;display: flex;padding-bottom: 10px;" id="shepherd-quickNav"
			*ngIf="!showAdminfunctionality">
			<div class="widgetClass left-right-transition-order-one" routerLink="/discovery" *ngIf="showRouteDiscovery">
				<a class="home-comp-icons"><i class="fa-solid fa-magnifying-glass-chart" style="color: #fbc02d"></i></a>
				<h1 class="widgettext">Data Discovery</h1>
			</div>
			<div class="widgetClass left-right-transition-order-one" routerLink="/build-report" *ngIf="showRouteBuild">
				<a class="home-comp-icons"><i class='fa-regular fa-clipboard' style="color: #0e4e6d"></i></a>
				<h1 class="widgettext">Build A Report</h1>
			</div>
			<div class="widgetClass left-right-transition-order-one" routerLink="/my-reports" *ngIf="showRouteMyReport">
				<a class="home-comp-icons"><i class="bx bx-folder" style="color: #e3da5a"></i></a>
				<h1 class="widgettext">My Reports</h1>
			</div>
			<div class="widgetClass left-right-transition-order-one" routerLink="/dashboard" *ngIf="showRouteCreate">
				<a class="home-comp-icons"><i class="fa-solid fa-book" style="color: #2988b6"></i></a>
				<h1 class="widgettext">My Dashboard</h1>
			</div>
			<div class="widgetClass left-right-transition-order-one" routerLink="/my-favorites"
				*ngIf="showRouteSettings">
				<a class="home-comp-icons"><i class="fa-regular fa-star" style="color: #FF5722"></i></a>
				<h1 class="widgettext">Favorites</h1>
			</div>
		</div>
		<div class="p-grid" *ngIf="!showAdminfunctionality">
			<div class="p-col-12">
				<div class="p-grid" id="shepherd-recentlyViewed">
					<div class="p-col-6">
						<div class="most-used-dashboard-container">
							<p class="mostUsedText1">Previously Viewed Dashboards</p>
							<div class="dataClass">
								<a *ngFor="let d of Dashboard" class="dashboard">
									<p style="cursor: pointer;" class="dashboard-anchor-tag" (click)="goToDashboard(d)">
										<span class="Name">{{d.authorizationName}}</span><span
											class="date">{{d.date}}</span>
									</p>
								</a>
							</div>
						</div>
					</div>
					<div class="p-col-6" id="shepherd-recentReports">
						<div class="most-used-dashboard-container">
							<p class="mostUsedText1">Previously Viewed Reports</p>
							<div class="dataClass">
								<a *ngFor="let d of recentReports" class="dashboard">
									<p style="cursor: pointer;" class="dashboard-anchor-tag"
										(click)="goToReport(d.authorizationId,d.authorizationName)">
										<span class="Name">{{d.authorizationName.replace('.rptdesign',
											'').replace('.sql', '')}}</span><span class="date">{{d.date}}</span>
									</p>
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div> -->
	</div>


	<!-- dialog section -->
	<p-dialog header="Profile" [draggable]="false" class="confirm-dialog" [position]="'top'" [modal]="true"
		[(visible)]="showProfileDialog" [style]="{ width: '780px' }"
		[ngClass]="{'label-class-width': tabHeaderName === 'Change Password'}">
		<div class="card">
			<div class="details1">
				<div class="adminUser">
					<i class="fa fa-user fa-userss1" aria-hidden="true"></i>
				</div>
				<div class="data3">{{this.userName}}</div>
			</div>
			<p-tabView (onChange)="switchHeaders($event);">
				<p-tabPanel header="Account Information">
					<form [formGroup]="accountForm" class="formClass" novalidate>
						<div class="p-field">
							<label for="first-name" class="labelClass">First Name <span
									style="color:red">*</span></label>
							<input id="first-name" pInputText type="text" required formControlName="firstName">
						</div>
						<div class="p-field">
							<label for="last-name" class="labelClass">Last Name</label>
							<input id="last-name" pInputText type="text" formControlName="lastName">
						</div>
						<div class="p-field">
							<label for="email" class="labelClass">Email Address <span style="color:red">*</span></label>
							<input id="email" pInputText type="email" required formControlName="email"
								pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$">
						</div>
						<div class="p-field">
							<label for="phone" class="labelClass">Phone Number </label>
							<input id="phone" pInputText type="text" formControlName="phone"
								placeholder="Ex +XXXXXXXXXX">
						</div>
					</form>
				</p-tabPanel>
				<p-tabPanel header="Change Password" class="tab1">
					<form [formGroup]="passwordForm" class="formClass" novalidate>
						<div class="p-field" style="display: flex;">
							<label for="oldPassword" class="labelClass">Enter Old Password <span
									style="color:red">*</span></label>
							<input id="oldPassword" pInputText required [type]="isPasswordVisible ? 'text' : 'password'"
								formControlName="oldPassword">
							<i style="position: relative;padding-top: 8px;right: 24px"
								[class]="!isPasswordVisible ?hideIcon : showIcon"
								(click)="togglePasswordVisibility()"></i>
						</div>
						<div class="p-field" style="display: flex;">
							<label for="newPassword" class="labelClass">Enter New Password <span
									style="color:red">*</span></label>
							<input id="newPassword" pInputText required
								[type]="isPasswordVisible1 ? 'text' : 'password'" formControlName="newPassword">
							<i style="position: relative;padding-top: 8px;right: 24px"
								[class]="!isPasswordVisible1 ? hideIcon : showIcon"
								(click)="togglePasswordVisibility1()"></i>
						</div>
						<div class="p-field" style="display: flex;">
							<label for="reEnter" class="labelClass">Re-Enter New Password <span
									style="color:red">*</span></label>
							<input id="reEnter" pInputText required [type]="isPasswordVisible2 ? 'text' : 'password'"
								formControlName="reEnter">
							<i style="position: relative;padding-top: 8px;right: 24px"
								[class]="!isPasswordVisible2 ? hideIcon : showIcon"
								(click)="togglePasswordVisibility2()"></i>
						</div>
					</form>
				</p-tabPanel>
			</p-tabView>
		</div>
		<ng-template pTemplate="footer">
			<p-button class="updateButton" label="Update" [disabled]="accountForm.invalid" (click)="updateAccount()"
				*ngIf="tabHeaderName === 'Account Information'"></p-button>
			<p-button label="Update" class="updateButton" [disabled]="passwordForm.invalid" (click)="updatePassword()"
				*ngIf="tabHeaderName === 'Change Password'"></p-button>
		</ng-template>
	</p-dialog>
	<div *ngIf="chartShow1" class="chart">
		<button class="tibo-mydash-chartcoloroptions-btn" type="button" pButton icon="pi pi-angle-left"
			(click)=" backToSearch1()" pTooltip="Back to Search" style="margin-bottom: 22px;"></button>
		<app-old-dashboard [chartData]="sampleChartData1" *ngIf="dashboardSelected"></app-old-dashboard>
		<app-my-reports-output [chartData1]="sampleChartData2" *ngIf="reportSelected"></app-my-reports-output>
		<app-build-report [chartData4]="sampleChartData4" *ngIf="buildReportSelected"></app-build-report>
		<app-scheduler [chartData5]="sampleChartData5" *ngIf="scheduleSelected"></app-scheduler>
	</div>
</div>
<!-- <button class="chartResiz" id = "resizeBtn" type="button" onclick="btnClickedFunc(this.id)" ></button> -->
<p-toast></p-toast>