<div class="tibo-settings-container-wrapper tibo-right-container settings-parent-container">
	<div class="tibo-settings-accord-container p-px-5 p-py-4">
		<p-accordion styleClass="accordion-head">
			<p-accordionTab header="Application Settings">
				<div class="p-px-5">
					<p-scrollPanel styleClass="tibo-stgs-accord-scroll-panel">
						<div class="container">
							<p-card>
								<ng-template pTemplate="header">Dashboard</ng-template>
								<div class="p-grid">
									<div class="p-col-12 p-md-6 margin-class">
										<div class="p-mb-2"><label class="label-class">Set Default Dashboard</label></div>
										<p-dropdown [options]="defaultDashboardData" [(ngModel)]="savedDefaultDashboard"
											optionLabel="name" optionValue="code" (onChange)="defaultDashboard($event)"
											placeholder="Select a dashboard"></p-dropdown>
									</div>
									<div class="p-col-12 p-md-6 margin-class">
										<div class="p-mb-2"><label class="label-class">Auto Refresh</label></div>
										<p-inputSwitch [(ngModel)]="autoRefreshCheckBool"
											(onChange)="handleChange($event)"></p-inputSwitch>
									</div>
									<div class="p-col-12 p-md-6 margin-class">
										<div class="p-mb-2"><label class="label-class">Refresh Frequency</label></div>
										<p-inputNumber class="number-input" styleClass="tibo-settings-number-input"
											[(ngModel)]="requestPayload.dashboardRefreshTime" [showButtons]="true"
											[min]="3" (onInput)="onChangeFrequency($event)">
										</p-inputNumber> Minutes
									</div>
									<div class="p-col-12 p-md-6 margin-class">
										<div class="p-mb-2"><label class="label-class">Create Dashboard Widgets Limit</label></div>
										<p-inputNumber class="number-input" styleClass="tibo-settings-number-input"
											[(ngModel)]="requestPayload.userDashboardWidgetLimit" [showButtons]="true"
											[min]="3" [step]="3" (onInput)="onChangeWidgetLimit($event)">
										</p-inputNumber>
									</div>

									<!-- <div class="p-col-12 p-md-3 margin-class">
										<div class="p-mb-2"><label class="label-class"> Font Family</label></div>
										<p-dropdown [options]="fonts" [(ngModel)]="requestPayload.fontFamily" appendTo="body"
											optionLabel="name" optionValue="value" (onChange)="onFontFamilyChange($event)"
											placeholder="Select a font"></p-dropdown>
									</div>
									<div class="p-col-12 p-md-3 margin-class">
										<div class="p-mb-2"><label class="label-class">Font size</label></div>
										<p-inputNumber class="number-input" styleClass="tibo-settings-number-input"
											[(ngModel)]="requestPayload.fontSize" [showButtons]="true"
											[min]="3" (onInput)="onFontSizeChange($event)">
										</p-inputNumber> PX
									</div>
									<div class="p-col-12 p-md-3 margin-class">
										<div class="p-mb-2"><label class="label-class">Font Style</label></div>
										<p-dropdown [options]="fontStyle" [(ngModel)]="requestPayload.fontStyle" appendTo="body"
											optionLabel="style" optionValue="value"
											(onChange)="onFontStyleChange($event)"
											placeholder="Select a font"></p-dropdown>
									</div> -->
									<div class="widget-color-button" class="p-col-12 p-md-12 p-lg-6 margin-class">
										<label class="label-class">Default Widget Colour</label><br><br>
										<div class="widget-color-border" (click)="widgetBackcolorsetsoverlaypanel.toggle($event)">
											<div class="single-row-color-set">
												<div class="p-p-2"
													[ngStyle]="{'background-color': selectedColor[i], cursor:'pointer'}"
													*ngFor='let row of selectedColor let i = index'><span
														pTooltip="{{row}}" class="inside-color">{{row}}</span>
												</div>
											</div>
											<i class='pi pi-angle-down widget-color-down-arrow'></i>
										</div>
										<p-overlayPanel #widgetBackcolorsetsoverlaypanel styleClass=""
											[showTransitionOptions]="'100ms'" appendTo="body" [showCloseIcon]=false>
											<p class="theme-color">Theme Color</p>
											<div class="color-main">
												<div class="color-set">
													<div class="single-row-color-set">
														<div class="p-p-2"
															[ngStyle]="{'background-color': colorSetOne[i], cursor:'pointer', heigth:'30px',width:'50px'}"
															*ngFor='let row of colorSetOne let i = index'
															(click)="changeChartColorsFunc('One',colorSetOne)"><span
																pTooltip="{{row}}" class="inside-color">{{row}}</span>
														</div>
													</div>
													<div class="single-row-color-set">
														<div class="p-p-2"
															[ngStyle]="{'background-color': colorSetTwo[i], cursor:'pointer'}"
															*ngFor='let row of colorSetTwo let i = index'
															(click)="changeChartColorsFunc('Two',colorSetTwo)"><span
																pTooltip="{{row}}" class="inside-color">{{row}}</span>
														</div>
													</div>
													<div class="single-row-color-set">
														<div class="p-p-2"
															[ngStyle]="{'background-color': colorSetThree[i], cursor:'pointer'}"
															*ngFor='let row of colorSetThree let i = index'
															(click)="changeChartColorsFunc('Three',colorSetThree)"><span
																pTooltip="{{row}}" class="inside-color">{{row}}</span>
														</div>
													</div>
													<!-- <div class="single-row-color-set">
														<div class="p-p-2"
															[ngStyle]="{'background-color': colorSetFour[i], cursor:'pointer'}"
															*ngFor='let row of colorSetFour let i = index'
															(click)="changeChartColorsFunc('Four',colorSetFour)"><span
																pTooltip="{{row}}" class="inside-color">{{row}}</span>
														</div>
													</div>
													<div class="single-row-color-set">
														<div class="p-p-2"
															[ngStyle]="{'background-color': colorSetFive[i], cursor:'pointer'}"
															*ngFor='let row of colorSetFive let i = index'
															(click)="changeChartColorsFunc('Five',colorSetFive)"><span
																pTooltip="{{row}}" class="inside-color">{{row}}</span>
														</div>
													</div>
													<div class="single-row-color-set">
														<div class="p-p-2"
															[ngStyle]="{'background-color': colorSetSix[i], cursor:'pointer'}"
															*ngFor='let row of colorSetSix let i = index'
															(click)="changeChartColorsFunc('Six',colorSetSix)"><span
																pTooltip="{{row}}" class="inside-color">{{row}}</span>
														</div>
													</div>
													<div class="single-row-color-set">
														<div class="p-p-2"
															[ngStyle]="{'background-color': colorSetSeven[i], cursor:'pointer'}"
															*ngFor='let row of colorSetSeven let i = index'
															(click)="changeChartColorsFunc('Seven',colorSetSeven)"><span
																pTooltip="{{row}}" class="inside-color">{{row}}</span>
														</div>
													</div>
													<div class="single-row-color-set">
														<div class="p-p-2"
															[ngStyle]="{'background-color': colorSetEight[i], cursor:'pointer'}"
															*ngFor='let row of colorSetEight let i = index'
															(click)="changeChartColorsFunc('Eight',colorSetEight)">
															<span pTooltip="{{row}}" class="inside-color">{{row}} </span>
														</div>
													</div> -->
												</div>
											</div>
										</p-overlayPanel>
									</div>
									<div class="widget-color-button" class="p-col-12 p-md-6 p-lg-6 margin-class">
										<label class="label-class">Default Background Colour</label><br><br>
										<div class="widget-back-color" pButton pTooltip="Color Sets" tooltipPosition="left" (click)="chartscolorsetsoverlaypanel.toggle($event)">
											<div class="tibo-mydash-chartcoloroptions-btn tibo-mydash-chartcoloroptions-btn-icon"
												type="button" class="widget-red-icon"
												[ngStyle]="{'background': getBackgroundStyle()}">
											</div>
											<i class='pi pi-angle-down down-arrow'></i>
										</div>
										<p-overlayPanel #chartscolorsetsoverlaypanel styleClass=""
											[showTransitionOptions]="'100ms'" appendTo="body" [showCloseIcon]=false>
											<p class="theme-color">Theme Color</p>
											<div class="color-setone">
												<button pButton class="rowOne hover-fx"
													*ngFor="let color of colorOne ; let i = index"
													[ngStyle]="{'background-color': color}"
													(click)="getcolor(color, i)"><span class="focus"
														*ngIf="i === buttonIndex"
														[ngValue]="color">&#10003;</span></button>
												<button pButton class="rowOne hover-fx"
													[ngStyle]="{'background-image': 'linear-gradient(red,lightgreen,lightblue)'}"
													(click)="getcolor(colorOne, 12)"><span class="focus"
														*ngIf="allColorButton">&#10003;</span></button>
											</div>
										</p-overlayPanel>
									</div>
									<div class="p-col-12 p-md-6 margin-class">
										<div class="p-mb-2"><label class="label-class">Show Series Number</label></div>
										<p-inputSwitch [(ngModel)]="seriesNumber"
											(onChange)="handleChangeSeries($event)"></p-inputSwitch>
									</div>
									<div class="p-col-12 p-md-6 margin-class">
										<div class="p-mb-2"><label class="label-class">Show Legend Number</label></div>
										<p-inputSwitch [(ngModel)]="legendNumber"
											(onChange)="handleChangeLegend($event)"></p-inputSwitch>
									</div>
								</div>
							</p-card>
						</div>
						<div class="container">
							<p-card>
								<ng-template pTemplate="header">Reports</ng-template>
								<div class="p-grid">
									<div class="p-col-12 p-mb-sm-2 p-md-6 p-xl-3">
										<div class="p-mb-2"><label class="label-class">Default Display Style</label></div>
										<p-selectButton [options]="displayOptions"
											[(ngModel)]="requestPayload.viewStyle" optionLabel="label"
											optionValue="value"
											(onChange)="displayStyleChange($event)"></p-selectButton>
									</div>
									<div class="p-col-12 p-md-6 p-xl-3">
										<div class="p-mb-2"><label class="label-class">Default Reports Lists</label></div>
										<p-selectButton [options]="reportLists"
											[(ngModel)]="requestPayload.directoryView" optionLabel="label"
											optionValue="value"
											(onChange)="displayStyleViewChange($event)"></p-selectButton>
									</div>
								</div>
							</p-card>
						</div>
						<div class="container">
							<p-card>
								<ng-template pTemplate="header">Report Inbox</ng-template>
								<div class="p-grid">
									<div class="p-col-6">
										<div class="p-mb-2"><label class="label-class">Keep Inbox Files For</label></div>
										<p-inputNumber class="number-input" styleClass="tibo-settings-number-input"
											[(ngModel)]="requestPayload.retentionDays" [showButtons]="true"
											(onInput)="onChangeRepInboxLimit($event)" [min]=90 [max]=180>
										</p-inputNumber> Days
									</div>
								</div>
							</p-card>
						</div>
					</p-scrollPanel>
				</div>
			</p-accordionTab>
			<p-accordionTab header="General Settings">
				<div class="p-px-5">
					<p-scrollPanel styleClass="tibo-stgs-accord-scroll-panel">
						<div class="container">
							<p-card>
								<ng-template pTemplate="header">Set Default Landing Page</ng-template>
								<div class="p-grid">
									<p-dropdown [options]="landingPages" [(ngModel)]="savedLandingPage"
										optionLabel="name" optionValue="code"
										(onChange)="landingPage($event)"></p-dropdown>
								</div>
							</p-card>
						</div>
						<div class="container">
							<p-card styleClass="tibo-settings-theme">
								<ng-template pTemplate="header">Set Your Favorite Theme</ng-template>
								<div class="flex-container tibo-settings-themes-container" *ngFor="let theme of defaultTheme">
									<div class="tibo-stgs-color-theme" [ngStyle]="{'background-color': theme, cursor:'pointer'}" (click)="onThemeChange(theme)"></div>
									<!-- <div class="tibo-stgs-color-theme"></div>
									<div class="tibo-stgs-color-theme"></div>
									<div class="tibo-stgs-color-theme"></div>
									<div class="tibo-stgs-color-theme"></div>
									<div class="tibo-stgs-color-theme"></div>
									<div class="tibo-stgs-color-theme"></div>
									<div class="tibo-stgs-color-theme"></div> -->
								</div>
							</p-card>
						</div>
					</p-scrollPanel>
				</div>
			</p-accordionTab>
		</p-accordion>
		<div class="tibo-settings-apply-changes-btn p-grid p-jc-center">
			<button pButton type="button" label="Apply Changes" class="p-button-Primary"
				(click)="applyChanges($event)"></button>
		</div>
	</div>
</div>