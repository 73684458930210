import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  constructor() { }

  clearLocalStorageOnFirstLoad() {
    const localStorageCleared = localStorage.getItem('localStorageCleared');
    if (!localStorageCleared) {
      localStorage.clear();
      localStorage.setItem('localStorageCleared', 'true');
    }
  }
}