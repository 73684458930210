import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class MenuPanelService {
    
    constructor(private http: HttpClient) { }
	
	//requestPayload = {"sort":[], "filters":[], "filterType":"or"}
	
	/*directoryService(param) {
        return this.http.get('api/v1/directory/'+param+'/directory', { headers: {'X-Auth-Token': localStorage.getItem("token")}, observe: 'response' })
    }*/
	
	/*recordsService(page,requestPayload) {
		return this.http.post('api/v1/report/catalogue/search?limit=250&page='+page, requestPayload,
		{ headers: {'X-Auth-Token': localStorage.getItem("token")}, observe: 'response' })
	}*/
		
	config() {
		return this.http.get('api/v1/user/config', { headers: { 'X-Auth-Token': localStorage.getItem("token") }, observe: 'response' })
	  }
}

