<!-- <div id="showNew"> -->
<div class="my-reports-parent-container p-d-flex">

	<div *ngIf="showFolder" class="side-menu" [ngClass]="{'side-menu-collapsed':sideMenuCollapse}">
		<div class="side-menu-header" [ngClass]="{'p-d-flex':!sideMenuCollapse}">
			<div class="side-menu-header-title">
				<h1 class="side-menu-title" [ngClass]="{'side-menu-title-collapsed':sideMenuCollapse}">All
					Reports</h1>
			</div>
			<div class="side-menu-header-icon">
				<i class="pi pi-angle-double-left side-menu-left-icon" *ngIf="!sideMenuCollapse"
					(click)="folderPanelToggle()"></i>
				<i class='pi pi-angle-double-right side-menu-right-icon' *ngIf="sideMenuCollapse"
					(click)="folderPanelToggle()"></i>
			</div>
		</div>
		<div class="head-button head-button-side-menu" *ngIf="!sideMenuCollapse && showAdminfunctionality">
			<button class="btn" type="button" pButton icon="pi pi-plus" pTooltip="Add a Folder" tooltipPosition="top"
				(click)="addfolder()"></button>
			<button class="btn" type="button" pButton icon="fa fa-pencil-square-o" pTooltip="Edit a Folder"
				tooltipPosition="top" [disabled]="disabledData1" (click)="editFolder()"></button>
			<button class="btn" type="button" pButton icon="pi pi-trash" pTooltip="Delete a Folder"
				tooltipPosition="top" [disabled]="disabledData1" (click)="deleteFolder()"></button>
			<button class="btn" type="button" pButton icon="fa fa-key" pTooltip="Manage Permissions"
				tooltipPosition="top" [disabled]="disabledData1" (click)="PermissionFolder()"></button>
		</div>
		<div class="side-menu-content" [ngClass]="{'side-menu-content-scroll':userId != 1}">
			<!-- <p-scrollPanel id="myreport-folder-panel-scroll-div" styleClass="tibo-myrepo-folderview-panel-scrollpanel"
				*ngIf="!sideMenuCollapse">
				<p-tree [filter]="true" styleClass="tibo-myrepo-repofolders-tree-cont" [value]="files" selectionMode="single"
					[(selection)]="selectedFile" scrollable="true" emptyMessage="   Please wait..." filterPlaceholder	="Search Folder"
					(onNodeSelect)="nodeSelect($event)" (onNodeUnselect)="nodeUnselect($event)"
					(onNodeExpand)="nodeExpand($event)" >
				</p-tree> -->

			<!-- <i class="pi pi-spin pi-spinner spinClass" *ngIf="isLoading1"></i> -->
			<!-- </p-scrollPanel> -->
			<p-scrollPanel id="myreport-folder-panel-scroll-div" styleClass="tibo-myrepo-folderview-panel-scrollpanel"
				*ngIf="!sideMenuCollapse">
				<p-tree styleClass="tibo-myrepo-repofolders-tree-cont" [value]="files" selectionMode="single"
					[(selection)]="selectedFile" scrollable="true" emptyMessage="Please wait..."
					filterPlaceholder="Search Folder" (onNodeSelect)="nodeSelect($event)"
					(onNodeUnselect)="nodeUnselect($event)" (onNodeExpand)="nodeExpand($event)">
					<!--[filter]="true"  (onFilter)="handleFilter($event)" -->
					<ng-template let-node pTemplate="default">
						<div class="tree-node-icon" style="width: 100%;display: flex;">
							<p class="nodeLabel">{{ node.label }}</p>
							<i *ngIf="node.loading" class="pi pi-spin pi-spinner"></i>
							<!-- <i *ngIf="!node.loading && node.expanded" class="pi pi-chevron-down"></i>
						  <i *ngIf="!node.loading && !node.expanded" class="pi pi-chevron-right"></i> -->
						</div>

					</ng-template>
					<ng-template pTemplate="filter">
						<input type="text" (input)="handleFilter($event.target.value)" placeholder="Search Folder">
					</ng-template>
				</p-tree>
			</p-scrollPanel>
		</div>
	</div>
	<div id="myreports-tile-list-scroll-view" class="second-section">
		<img src="././assets/images/dualBallSpinner.svg" alt="" class="spinner-img" *ngIf="isLoading">
		<div class="second-section-header-section">
			<div class="sec-section-header-one p-d-flex">
				<span id="" class="pi pi-folder-open"></span> <span class="topic-name">
					{{selectedFile?.label}}</span>
			</div>
			<div class="sec-section-header-two p-d-flex">
				<div class="searchI" id="shepherd-reportSearch">
					<span class="p-input-span p-ml-auto">
						<i class="pi pi-search"></i>
						<input pInputText type="text" [(ngModel)]="searchText" (keyup.enter)="this.initialCallForSearch(searchText)"
							(ngModelChange)="this.tableSearchUpdate.next($event)" [disabled]="searchFieldDisable"
							placeholder="Search Keyword" [type]="'search'" />
					</span>
					<!-- <p-multiSelect class="search-multiselect" [filter]="false" [showToggleAll]="false"
						defaultLabel="Select a Country" [options]="searchDropDownList"
						[(ngModel)]="searchDropDownSelected" optionValue="code" optionLabel="name"
						(onClick)="dropDownFun(searchDropDownSelected)">
						<ng-template pTemplate="header">
						<div class="input-switch-div">
							<span class="input-switch-text">Included Subfolder</span>
							<p-inputSwitch [(ngModel)]="searchChecked"></p-inputSwitch>
						</div>
						</ng-template>
						<ng-template pTemplate="footer">
							<p class="choose-text" *ngIf="searchFieldDisable">Choose any options</p>
						</ng-template>
					</p-multiSelect> -->
					<p-multiSelect #multiselect [filter]="false" [showToggleAll]="false" [options]="searchDropDownList"
						[(ngModel)]="searchDropDownSelected" defaultLabel="Options" optionValue="code"
						optionLabel="name" class="search-multiselect search-multiselect-my-report-table"
						(onClick)="dropDownFun(searchDropDownSelected)">
						<ng-template pTemplate="header">
							<div class="input-switch-div box-shadow-none">
								<span class="input-switch-text">Included Subfolder</span>
								<p-inputSwitch [(ngModel)]="searchChecked"></p-inputSwitch>
							</div>
						</ng-template>
						<ng-template pTemplate="selectedItems">
							<span [ngStyle]="{'color': searchFieldDisable ? 'red' : ''}">Options</span>
						</ng-template>
						<ng-template let-data pTemplate="item">
							<div class="country-item">
								<div>{{data.name}}</div>
							</div>
						</ng-template>
						<ng-template pTemplate="footer">
							<p class="choose-text" *ngIf="searchFieldDisable">Choose Any Options</p>
						</ng-template>
					</p-multiSelect>
				</div>
				<!-- <p-selectButton [options]="stateOptions" [(ngModel)]="listOrTileView" optionLabel="label"
					optionValue="value" (onChange)="changeViewFunc($event)" class="tile-list-view-btn"></p-selectButton> -->
				<p-selectButton [options]="stateOptions" [(ngModel)]="listOrTileView" optionLabel="label"
					optionValue="value" class="tile-list-view-btn" (onChange)="changeViewFunc($event)"
					id="shepherd-reportChangeView">
					<ng-template let-option pTemplate="item">
						<span [pTooltip]="option.tooltip">
							<i [class]="option.icon"></i>
						</span>
					</ng-template>
				</p-selectButton>
			</div>
		</div>
		<div class="head-button" *ngIf="showAdminfunctionality">
			<button class="btn" type="button" pButton icon="pi pi-plus" pTooltip="Add a Report" tooltipPosition="top"
				(click)="addReport()"></button>
			<button class="btn" type="button" [disabled]="disabledData" pButton icon="fa fa-pencil-square-o"
				pTooltip="Edit a Report" tooltipPosition="top" (click)="EditReport()"></button>
			<button class="btn" tooltipPosition="top" type="button" [disabled]="disabledData" pButton icon="pi pi-trash"
				pTooltip="Delete a Report" (click)="deleteReport()"></button>
			<button class="btn" tooltipPosition="top" type="button" [disabled]="disabledData" pButton icon="fa fa-key"
				pTooltip="Manage Permissions" (click)="PermissionReport()"></button>
		</div>
		<div class="tibo-myrepo-tileview-cont my-reports-tile-view"
			[ngClass]="{'spinner-pointer-event': isLoading || customSortBool}">
			<p *ngIf="myReportRecords.length == 0" class="p-ml-5">{{ tableEmptyMessage }}</p>
			<p-scrollPanel *ngIf="myReportRecords.length > 0" styleClass="tibo-myrepo-titleview-scrollpanel">
				<div class="p-grid p-nogutter tileClass">
					<div class="tibo-myrepo-tile-box p-col-12 p-sm-12 p-md-6 p-lg-6 p-xl-4"
						*ngFor="let reportObj of myReportRecords; let j = index;" (click)="onTileSelect(j)">
						<!--Children styling available in .css file-->
						<div class=""
							[ngClass]="{'tile-active-class' : isTileSelected && j == getTableRowIndex,'tile-hover-class' : j != getTableRowIndex}">
							<div class="p-grid p-col-12 p-ai-center vertical-container">
								<label class="tile-lable" style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;" pTooltip="{{reportObj?.authorizationName.split('.')[0]}}" 
								tooltipPosition="top">{{reportObj?.authorizationName.split('.')[0]}}</label>
							</div>
							<div [ngClass]="{'icon-tile-bar' : isTileSelected && j == value}">
								<button *ngIf="reportObj?.isRunnable.toLowerCase() == 'y'" id="" class="p-mr-2"
									type="button" pButton icon="pi pi-play" pTooltip="Run Report" tooltipPosition="top"
									(click)="onRunButtonClick(reportObj?.authorizationId,reportObj?.authorizationName,reportObj?.extension,reportObj?.uniqueName,j)"></button>
								<button *ngIf="reportObj?.isSchedulable.toLowerCase() == 'y'" id="" class="p-mr-2"
									type="button" pButton icon="pi pi-calendar-plus" pTooltip="Schedule Report"
									tooltipPosition="top"
									(click)="onScheduleBtnClick(reportObj?.authorizationId,reportObj?.authorizationName,reportObj?.authorizationDesc,j)"></button>
								<button id="" class="p-mr-2" type="button" pButton icon="pi pi-star"
									pTooltip="Add To Favorites" tooltipPosition="top"
									[ngStyle]="{'color': (reportObj?.favourite?.toLowerCase() === 'y') ? '#ffca2b' : 'grey'}"
									(click)="onAddToMyFavoritesBtnClick(reportObj?.authorizationId,reportObj?.authorizationName,reportObj)"></button>
								<button *ngIf="reportObj?.isSchedulable.toLowerCase() == 'y' && showAdminfunctionality" id="" class="p-mr-2"
									type="button" pButton icon="fa fa-calendar" pTooltip="Secure Schedule"
									tooltipPosition="top"
									(click)="onSecureScheduleBtnClick(reportObj?.authorizationId,reportObj?.authorizationName,reportObj?.authorizationDesc,j)"></button>
								<button id="" class="p-mr-2" type="button" pButton icon="pi pi-file"
									pTooltip="Show All Output Files" tooltipPosition="top"
									(click)="showAllOutputFilesBtnClick(reportObj?.authorizationId)"></button>
								<button id="" class="p-mr-2" type="button" pButton icon="fa-solid fa-circle-info"
									pTooltip="Information" tooltipPosition="top"
									(click)="showReportInfoBtnClick(reportObj?.authorizationId)"></button>
								<!-- <button id="" class="p-mr-2" type="button" pButton icon="pi pi-info-circle"
									pTooltip="Report Information" tooltipPosition="top"
									(click)="showReportInfoBtnClick(reportObj?.authorizationId)"></button> -->
								<!-- <i class='bx bxs-book-alt action-icons audioIcons iconAction action-icon-float-class'
								pTooltip="Report Catalogue Information" tooltipPosition="top"<i class="fa-sharp fa-light fa-circle-info"></i>
								(click)="showReportCatalogueInfoBtnClick(reportObj?.authorizationId)"></i>
								<i class='bx bx-info-circle action-icons audioIcons iconAction action-icon-float-class'
								pTooltip="Report Information" tooltipPosition="top"
								(click)="showReportInfoBtnClick(reportObj?.authorizationId)"></i> -->
							</div>
						</div>
					</div>
				</div>
			</p-scrollPanel>
			<div class="pagination-second-part1">
				<p-paginator #paginator styleClass="tibo-schedrepo-listView-p-paginator" [rows]="150"
					[totalRecords]="totalRecords" (onPageChange)="paginate($event)" [(first)]="first"
					[showJumpToPageDropdown]="true" [showPageLinks]="false"></p-paginator>
			</div>
		</div>
		<div class="tibo-myrepo-listview-cont my-reports-list-view"
			[ngClass]="{'spinner-pointer-event': isLoading || customSortBool}">
			<img src="././assets/images/dualBallSpinner.svg" alt="" class="spinner-img"
				*ngIf="isLoading || customSortBool">
			<p-card class="table-card">
				<p-table #myreportstable [value]="this.myReportRecords" [sortField]="tableSortColumn"
					[sortOrder]="tableSortDir" [scrollable]="true"
					scrollHeight="calc(100vh - {{tableReduceHeight+'px'}})"
					[globalFilterFields]="['authorizationName','reportType']" (sortFunction)="customSort($event)"
					[customSort]="true" selectionMode="single">
					<ng-template pTemplate="header">
						<tr>
							<th pSortableColumn="authorizationName" (click)="theadClick($event)"
								class="firsh-table-header" style="width:340px">
								<div class="p-d-flex p-jc-between p-ai-center">
									Report Name
									<p-sortIcon field="authorizationName"></p-sortIcon>
								</div>
							</th>
							<!-- [style.width]="dynamicWidth('reportType','Report Type')" -->
							<th pSortableColumn="reportType" (click)="theadClick($event)" >
								<div class="p-d-flex p-jc-between p-ai-center">
									Report Type
									<p-sortIcon field="reportType"></p-sortIcon>
								</div>
							</th>

							<th pSortableColumn="createdDate" (click)="theadClick($event)"
								*ngIf="showAdminfunctionality">
								<div class="p-d-flex p-jc-between p-ai-center">
									Created Date
									<p-sortIcon field="createdDate"></p-sortIcon>
								</div>
							</th>
							<th pSortableColumn="modifiedDate" (click)="theadClick($event)"
								*ngIf="showAdminfunctionality">
								<div class="p-d-flex p-jc-between p-ai-center">
									Modified Date
									<p-sortIcon field="modifiedDate"></p-sortIcon>
								</div>
							</th>
							<th>Actions</th>
						</tr>
					</ng-template>
					<ng-template pTemplate="body" let-reportObj let-i="rowIndex">
						<tr (click)="onTileSelect(i)" [ngClass]="{'p-highlight':getTableRowIndex === i}">
							<td title="{{reportObj?.authorizationName.substring(0,
							reportObj?.authorizationName.lastIndexOf('.'))}}" class="reportNameTd">{{reportObj?.authorizationName.split('.')[0]}}</td>
							<td title="{{reportObj?.reportType ? reportObj?.reportType : '-'}}" >{{reportObj?.reportType ? reportObj?.reportType : '-'}}</td>
							<!-- <td>
								<div class="tibo-myrepo-listview-btns-cont">
								
									<button *ngIf="reportObj?.isRunnable.toLowerCase() == 'y'" id="" class="p-mr-2"
										type="button" pButton icon="pi pi-play" pTooltip="Run Report"
										tooltipPosition="top"
										(click)="onRunButtonClick(reportObj?.authorizationId,reportObj?.authorizationName,reportObj?.reportType,reportObj?.uniqueName)"></button>
									<button *ngIf="reportObj?.isSchedulable.toLowerCase() == 'y'" id="" class="p-mr-2"
										type="button" pButton icon="pi pi-calendar-plus" pTooltip="Schedule Report"
										tooltipPosition="top"
										(click)="onScheduleBtnClick(reportObj?.authorizationId,reportObj?.authorizationName,reportObj?.authorizationDesc)"></button>
									<button *ngIf="reportObj?.isSchedulable.toLowerCase() == 'y'" id="" class="p-mr-2"
										type="button" pButton icon="fa fa-calendar" pTooltip="Secure Schedule"
										tooltipPosition="top"
										(click)="onSecureScheduleBtnClick(reportObj?.authorizationId,reportObj?.authorizationName,reportObj?.authorizationDesc)"></button>
									<button id="" class="p-mr-2" type="button" pButton icon="pi pi-star"
										pTooltip="Add to Favorites" tooltipPosition="top"
										(click)="onAddToMyFavoritesBtnClick(reportObj?.authorizationId,reportObj?.authorizationName,reportObj?.authorizationDesc)"></button>
									<button id="" class="p-mr-2" type="button" pButton icon="pi pi-file"
										pTooltip="Show all output files" tooltipPosition="top"
										(click)="showAllOutputFilesBtnClick(reportObj?.authorizationId)"></button>
										<button id="" class="p-mr-2" type="button" pButton icon="fa fa-book"
									pTooltip="Report Catalogue Information" tooltipPosition="top"
									(click)="showReportCatalogueInfoBtnClick(reportObj?.authorizationId)"></button>
								<button id="" class="p-mr-2" type="button" pButton icon="pi pi-info-circle"
									pTooltip="Report Information" tooltipPosition="top"
									(click)="showReportInfoBtnClick(reportObj?.authorizationId)"></button>
									
								</div>
							</td> -->
							<td *ngIf="showAdminfunctionality">{{reportObj?.strCreatedDate ? reportObj?.strCreatedDate:
								'-'}}
							</td>
							<td *ngIf="showAdminfunctionality">{{reportObj?.strModifiedDate ? reportObj?.strModifiedDate
								: '-'}}
							</td>
							<td class="action-td1 actionTd" style="max-width: 295px !important;padding-left: 10px;">
								<i class='bx bx-play action-icons audioIcons iconAction'
									*ngIf="reportObj?.isRunnable.toLowerCase() == 'y'" type="button"
									pTooltip="Run Report" tooltipPosition="top"
									(click)="onRunButtonClick(reportObj?.authorizationId,reportObj?.authorizationName,reportObj?.extension,reportObj?.uniqueName,i)"
									id="shepherd-reportRun"></i>
								<i class='bx bxs-calendar-plus action-icons audioIcons iconAction'
									*ngIf="reportObj?.isSchedulable.toLowerCase() == 'y'"
									(click)="onScheduleBtnClick(reportObj?.authorizationId,reportObj?.authorizationName,reportObj?.authorizationDesc,i)"
									pTooltip="Schedule Report" tooltipPosition="top" id="shepherd-reportSchedule"></i>
								<!-- <nav class="action-nav" (click)="$event.stopPropagation()" [ngClass]="{'translate-class':i===actionIconsIndex, 'translate-class2':i!==actionIconsIndex}"> -->
								<!-- <i class="bx bx-chevrons-right action-trigger-icons" pTooltip="Expand"
									tooltipPosition="top" *ngIf=" i!==actionIconsIndex"
									(click)="showActionIconsFun('show',i)"   id="shepherd-reportExpand"></i>
								<i class="bx bx-chevrons-left action-trigger-icons" pTooltip="Hide"
									tooltipPosition="top" *ngIf="showActionIcons && i===actionIconsIndex"
									(click)="showActionIconsFun('hide',-1)"></i>
									<i class="background-action-icon"
									[ngClass]="{'background-action-icon-2': col?.isActive === 'Y'}"></i> -->

								<i class='bx bxs-calendar action-icons audioIcons iconAction '
									*ngIf="reportObj?.isSchedulable.toLowerCase() == 'y' && showAdminfunctionality"
									(click)="onSecureScheduleBtnClick(reportObj?.authorizationId,reportObj?.authorizationName,reportObj?.authorizationDesc,i)"
									pTooltip="Secure Schedule" tooltipPosition="top"></i>
								<i class='bx bxs-star action-icons audioIcons iconAction'
									[ngStyle]="{'color': (reportObj?.favourite?.toLowerCase() === 'y') ? '#ffca2b' : 'grey'}"
									pTooltip="Add To Favorites" tooltipPosition="top"
									(click)="onAddToMyFavoritesBtnClick(reportObj?.authorizationId,reportObj?.authorizationName,reportObj)"></i>
								<i class='bx bx-file action-icons audioIcons iconAction '
									pTooltip="Show All Output Files" tooltipPosition="top"
									(click)="showAllOutputFilesBtnClick(reportObj?.authorizationId)"></i>
								<i class='bx bx-info-circle action-icons audioIcons iconAction ' pTooltip="Information"
									tooltipPosition="top"
									(click)="showReportInfoBtnClick(reportObj?.authorizationId)"></i>
								<!-- <i class='bx bx-info-circle action-icons audioIcons iconAction action-icon-float-class'
								pTooltip="Report Information" tooltipPosition="top"<i class='bx bxs-info-circle' ></i>
								(click)="showReportInfoBtnClick(reportObj?.authorizationId)"></i> -->
								<!-- </nav> -->
							</td>

						</tr>
					</ng-template>
					<ng-template pTemplate="emptymessage">
						<tr>
							<td>{{ tableEmptyMessage }}</td>
						</tr>
					</ng-template>
				</p-table>
				<div class="p-d-flex pagination-container">
					<div class="pagination-first-part">
						<p class="p-mx-2">Showing <b>{{startIndex}}-{{endIndex}}</b> of <b>{{totalRecords}}</b>
							Records
						</p>
					</div>
					<div class="pagination-second-part">
						<p-paginator #paginator styleClass="tibo-schedrepo-listView-p-paginator" [rows]="150"
							[totalRecords]="totalRecords" (onPageChange)="paginate($event)" [(first)]="first"
							[showJumpToPageDropdown]="true" [showPageLinks]="false"></p-paginator>
					</div>
				</div>
			</p-card>
		</div>
	</div>

	<!-- modal section -->
	<!-- add modal -->
	<p-dialog [modal]="true" [draggable]="false" class="confirm-dialog" [(visible)]="showAddDialog" [header]="title"
		[style]="{ width: '575px' }" [position]="'top'">
		<!-- <h3 class="madatory-text"><span class="mandatory-simple">*</span> indicates mandatory field</h3> -->
		<form [formGroup]="addForm">
			<div class="p-field p-col-12 form-group">
				<label for="folder-name" class="form-label">Folder Name <span class="mandatory-simple">*</span></label>
				<input class="form-input" type="text" pInputText formControlName="folderName" id="folder"
					pattern="[^\\/:\x22*?<>|]+" placeholder="Enter Folder Name" [(ngModel)]="FolderName"
					(ngModelChange)="change(FolderName)" value="{{FolderName}}" onkeyup="textChange(FolderName)"
					[disabled]="isEditMode1">
			</div>
			<div class="p-field p-col-12 form-group">
				<label for="resource" class="form-label">Resource Id <span class="mandatory-simple">*</span></label>
				<input class="form-input" type="text" pInputText formControlName="resourceidName" id="resource"
					placeholder="Enter Resource Id" [(ngModel)]="ResourceId" value="{{ResourceId}}"
					[disabled]="isEditMode1">
			</div>
			<div class="p-field p-col-12 form-group">
				<label for="description" class="form-label">Description</label>
				<input class="form-input" type="text" pInputText formControlName="descriptionName"
					placeholder="Enter Folder Description">
			</div>
		</form>
		<ng-template pTemplate="footer">
			<p-button class="cancel-btn" type="close" (click)="showAddDialog = false" label="Close"
				(click)="cancel('addFolder')"></p-button>
			<p-button class="save-btn" type="submit" [label]="subName" [disabled]="addForm.invalid"
				(click)="onAddSubmit(addForm,subName)"></p-button>
		</ng-template>
	</p-dialog>

	<!-- delete modal -->
	<p-dialog [modal]="true" [draggable]="false" [style]="{ width: '800px' }" class="delete-dialog"
		[(visible)]="showDeleteDialog" [position]="'top'" [header]="' '">
		<i class='pi pi-exclamation-triangle stop-modal-bx-error'></i> <span class="header-text">{{title}}</span>
		<ng-template pTemplate="footer">
			<p-button class="cancel-btn" type="close" (click)="showDeleteDialog = false" label="Close"></p-button>
			<p-button class="save-btn" type="submit" (click)="ondeleteClick()" (click)="showDeleteDialog = false"
				[label]="subName"></p-button>
		</ng-template>
	</p-dialog>

	<!-- add report modal -->
	<p-dialog [modal]="true" [draggable]="false" [style]="{ width: '500px' }" class="confirm-dialog"
		[(visible)]="showAddReport" [header]="title" [position]="'top'" (onHide)="cancel('addReport')">
		<img src="././assets/images/dualBallSpinner.svg" alt="" class="modal-spinner-img" *ngIf="isLoadingModal">
		<form [formGroup]="addFileForm">
			<div class="p-field p-col-12 form-group">
				<label for="name" class="form-label">Report Name <span class="mandatory-simple">*</span></label><br>
				<input type="text" class="form-input" pInputText formControlName="reportName" id="name" maxlength="100"
					minlength="1" required [(ngModel)]="reportName" (ngModelChange)="change1(reportName)"
					[disabled]="isEditMode" value="{{reportName}}">
			</div>
			<div class="p-field p-col-12 form-group">
				<label for="Id" class="form-label">Resource Id <span class="mandatory-simple">*</span></label><br>
				<input type="text" class="form-input" pInputText formControlName="resourcesId" id="Id" required
					[disabled]="isEditMode" [(ngModel)]="resourcesId" value="{{resourcesId}}">
			</div>
			<div class="p-field p-col-12 form-group">
				<label for="Id" class="form-label">Description</label><br>
				<input type="text" class="form-input" pInputText formControlName="description" id="Id"
					[(ngModel)]="description" value="{{description}}">
			</div>
			<!-- <div class="p-field p-col-12 form-group">
		<label for="file" class="form-label">Locate the File <span class="mandatory-simple">*</span></label>
		<div class="form-group-file-upload" *ngIf="!fileName">
			<input type="file" pInputText formControlName="file" id="file" class="file-input" accept=".rptdesign"
				(change)="onFileChange($event)" required #fileUpload>
		</div>
		<div *ngIf="sowFileName" class="selected-file-class animateH5"> {{fileName }} <span
				class="cancel-icon-drag"><i class="pi pi-times" (click)="cancel()"></i></span>
		</div>
	</div> -->
			<div class="p-col-12 p-field form-group">
				<label for="file" class="form-label">Locate The File <span class="mandatory-simple">*</span></label>
				<div class="form-group-file-upload" *ngIf="!fileName">
					<div class="img-background">
						<!-- <input type="file" formControlName="file" pInputText class="file-input" accept=".rptdesign"
							(change)="onFileChange($event)" required #fileUpload> -->

						<input type="file" formControlName="file" pInputText class="file-input"
							(change)="onFileChange($event)" required #fileUpload>
					</div>
				</div>
				<div >
					<div  class="selected-file-class" *ngIf="fileloader"><i class="pi pi-spin pi-spinner" ></i></div>
					<div  class="selected-file-class" *ngIf="sowFileName" style="overflow: hidden;text-overflow: ellipsis;">
						<span class="animateH5 selected-file-name" title="{{fileName}}">{{fileName}} </span><i
							class="pi pi-times cancel-icon-drag" (click)="RemoveFile()" pTooltip="Remove"
							tooltipPosition="top"></i>
					</div>
				</div>
			</div>
			<div *ngIf="showDataSource" class="p-field p-col-12 form-group">
				<label for="Id" class="form-label">Data Source<span class="mandatory-simple">*</span></label><br>
				<p-dropdown formControlName="dataSource" [options]="dataSourceList" placeholder="Select Data Source"
					appendTo="body" optionLabel="dataSourceName" optionValue="dataSourceId" class="filter-drop-down"
					[(ngModel)]="dataSource" (onChange)="changeSource($event)"></p-dropdown>
			</div>
			<div class="p-field p-col-12 form-group">
				<label for="Id" class="form-label">Report Criteria</label><br>
				<p-editor [(ngModel)]="reportCriteria" formControlName="reportCriteria" [style]="{'height':'200px'}">
				</p-editor>
			</div>
			<div class="p-field p-col-12 form-group">
				<label for="Id" class="form-label">Report Parameters</label><br>
				<p-editor [(ngModel)]="reportParameter" formControlName="reportParameter"
					[style]="{'height':'200px'}"></p-editor>
			</div>
			<div class="p-field p-col-12 form-group">
				<label for="Id" class="form-label">Report Type<span class="mandatory-simple">*</span></label><br>
				<p-dropdown formControlName="reportType" [options]="reportsList" placeholder="Select Report Type"
					appendTo="body" class="filter-drop-down" [(ngModel)]="reportType"
					(onChange)="changereport($event)"></p-dropdown>
			</div>
			<div class="p-field p-col-12 form-group">
				<label for="Id" class="form-label">Export Type <span class="mandatory-simple">*</span></label><br>
				<p-multiSelect class="search-multiselect-modal" [options]="fileTypes" appendTo="body"
					[showHeader]="false" [(ngModel)]="exportType" optionLabel="name" optionValue="code"
					formControlName="exportType" (onChange)="changeExport($event)" placeholder="Select Export Type">
				</p-multiSelect>
			</div>
			<div class="p-field p-col-12 form-group">
				<label for="Id" class="form-label">Client</label><br>
				<p-dropdown formControlName="client" binary="true" [options]="clientList" class="filter-drop-down"
					appendTo="body" optionLabel="clientName" optionValue="clientCode" placeholder="Select A Client"
					[(ngModel)]="client" (onChange)="changeClient($event)"
					[dropdownIcon]="load? 'pi pi-spinner pi-spin' : 'pi pi-chevron-down'"></p-dropdown>
			</div>
			<div class="p-field p-col-12 p-md-4 form-group display-class">
				<p-checkbox formControlName="schedule" class="form-check-box form-check-box-filter-form" inputId="ny"
					label="Schedule" binary="true" [(ngModel)]="schedule"
					(onChange)="sceduleChange($event)"></p-checkbox>
			</div>
			<div class="p-field p-col-12 p-md-4 form-group display-class">
				<p-checkbox formControlName="run" class="form-check-box form-check-box-filter-form" inputId="run"
					label="Run" binary="true" [(ngModel)]="run" (onChange)="runChange($event)"></p-checkbox>
			</div>
			<div class="p-field p-col-12 p-md-4 form-group display-class">
				<p-checkbox formControlName="eventBased" class="form-check-box form-check-box-filter-form"
					label="Event Based" inputId="eventBased" binary="true" [(ngModel)]="eventBased"
					(onChange)="eventChange($event)"></p-checkbox>
			</div>
			<div *ngIf="showHeader" class="p-field p-col-12 p-md-4 form-group display-class">
				<p-checkbox formControlName="header" class="form-check-box form-check-box-filter-form"
					label="Include header in xls/xlsx output file(s)" inputId="header" binary="true"
					[(ngModel)]="header" (onChange)="headerChange($event)"></p-checkbox>
			</div>
		</form>
		<!-- second style -->
		<!-- <form [formGroup]="addFileForm">
	<div class="p-grid">
		<div class="p-col-12 p-md-6 p-lg-6 p-field form-group">
			<label for="name" class="form-label">Report Name <span class="mandatory-simple">*</span></label><br>
			<input type="text" class="form-input" pInputText formControlName="reportName" id="name" maxlength="100"
				minlength="1" required [(ngModel)]="reportName" value="{{reportName}}">
		</div>
		<div class="p-col-12 p-md-6 p-lg-6 p-field form-group">
			<label for="Id" class="form-label">Resource Id <span class="mandatory-simple">*</span></label><br>
			<input type="text" class="form-input" pInputText formControlName="resourcesId" id="Id" required
				[(ngModel)]="resourcesId" required value="{{resourcesId}}">
		</div>
		<div class="p-col-12 p-md-6 p-lg-6 p-field form-group">
			<label for="Id" class="form-label">Description</label><br>
			<input type="text" class="form-input" pInputText formControlName="description" id="Id"
				[(ngModel)]="description" value="{{description}}">
		</div>
		<div class="p-col-12 p-md-6 p-lg-6 p-field form-group">
			<label for="Id" class="form-label">Report Type</label><br>
			<p-dropdown formControlName="reportType" [options]="reportsList" placeholder="Select Report type"
				class="filter-drop-down" [(ngModel)]="reportType" (onChange)="changereport($event)"></p-dropdown>
		</div>
		<div class="p-col-12 p-md-12 p-lg-12 p-field form-group">
			<label for="file" class="form-label">Locate the File <span class="mandatory-simple">*</span></label>
			<div class="form-group-file-upload" *ngIf="!fileName">
				<div class="img-background">
				<input type="file" pInputText formControlName="file" id="file" class="file-input"
					accept=".rptdesign" (change)="onFileChange($event)" required #fileUpload>
				</div>
			</div>
			<div *ngIf="sowFileName" class="selected-file-class"><span class="cancel-icon-drag animateH5">{{fileName
					}} <i class="pi pi-times" (click)="cancel()"></i></span>
			</div>
		</div>
		<div class="p-col-12 p-md-6 p-lg-6 p-field form-group">
			<label for="Id" class="form-label">Export Type <span class="mandatory-simple">*</span></label><br>
			<p-dropdown formControlName="exportType" binary="true" [options]="fileTypes" class="filter-drop-down"
				placeholder="Select export type" [(ngModel)]="exportType"
				(onChange)="changeExport($event)"></p-dropdown>
		</div>
		<div class="p-col-12 p-md-6 p-lg-6 p-field form-group">
			<label for="Id" class="form-label">Client</label><br>
			<p-dropdown formControlName="client" binary="true" [options]="clientList" class="filter-drop-down"
				placeholder="Select a Client" [(ngModel)]="client" (onChange)="changeClient($event)"></p-dropdown>
		</div>
		<div class="p-col-12 p-md-4 p-lg-4 p-field form-group display-class">
			<p-checkbox formControlName="schedule" class="form-check-box form-check-box-filter-form" inputId="ny"
				label="Schedule" binary="true" [(ngModel)]="schedule"
				(onChange)="sceduleChange($event)"></p-checkbox>
		</div>
		<div class="p-col-12 p-md-4 p-lg-4 p-field form-group display-class">
			<p-checkbox formControlName="run" class="form-check-box form-check-box-filter-form" inputId="run"
				label="Run" binary="true" [(ngModel)]="run" (onChange)="runChange($event)"></p-checkbox>
		</div>
		<div class="p-col-12 p-md-4 p-lg-4 p-field form-group display-class">
			<p-checkbox formControlName="eventBased" class="form-check-box form-check-box-filter-form"
				label="Event Based" inputId="eventBased" binary="true" [(ngModel)]="eventBased"
				(onChange)="eventChange($event)"></p-checkbox>
		</div>
		<div class="p-col-12 p-md-6 p-lg-6 p-field form-group">
			<label for="Id" class="form-label">Report Criteria</label><br>
			<p-editor [(ngModel)]="reportCriteria" formControlName="reportCriteria" [style]="{'height':'200px'}">
			</p-editor>
		</div>
		<div class="p-col-12 p-md-6 p-lg-6 p-field form-group">
			<label for="Id" class="form-label">Report Parameters</label><br>
			<p-editor [(ngModel)]="reportParameter" formControlName="reportParameter"
				[style]="{'height':'200px'}"></p-editor>
		</div>
	</div>
</form> -->
		<ng-template pTemplate="footer">
			<p-button class="cancel-btn" type="close"
				(click)="showAddReport = false && maincontent=true; cancel('addReport')" label="Close"></p-button>
			<p-button class="save-btn" icon="{{btnSpinner}}" type="submit" (click)="showAddReport" [label]="ButtonName"
				[disabled]="addFileForm.invalid ||  fileloader"
				(click)="!addFileForm.invalid &&  !fileloader && addReportData()"></p-button>
		</ng-template>
	</p-dialog>

	<!-- delete modal report dialog -->
	<p-dialog [modal]="true" [draggable]="false" [style]="{ width: '800px' }" class="delete-dialog"
		[(visible)]="showDeleteReportDialog" [position]="'top'" [header]="' '">
		<i class='pi pi-exclamation-triangle stop-modal-bx-error'></i> <span class="header-text">{{title}}</span>
		<ng-template pTemplate="footer">
			<p-button class="cancel-btn" type="close" (click)="showDeleteReportDialog = false" label="Close"></p-button>
			<p-button class="save-btn" type="submit" (click)="ondelete()" (click)="showDeleteReportDialog = false"
				[label]="subName"></p-button>
		</ng-template>
	</p-dialog>
	<!-- permission dialog folder-->
	<p-dialog [modal]="true" [draggable]="false" class="confirm-dialog" [(visible)]="showPermissionDialog"
		[header]="title" [style]="{ width: '700px' }" [position]="'top'">
		<img src="././assets/images/dualBallSpinner.svg" alt="" class="modal-spinner-img" *ngIf="isLoadingModal">
		<div class="button-class">
			<p-selectButton [options]="permissionOptions" [(ngModel)]="selectedRole" optionLabel="label" appendTo="body"
				optionValue="value" (click)="permissionFunc()"></p-selectButton>
		</div>
		<div *ngIf="roleShow" class="role-class">
			<div class="TagInline"><label class="label-class">Grant Read Access To Role : </label>
				<p-autoComplete class="tagField" placeholder="Add a Tag" [(ngModel)]="selectedList1" appendTo="body"
					[suggestions]="itemList1" (completeMethod)="filterItems1($event)" field="roleName"
					[multiple]="true">
				</p-autoComplete>
			</div>
			<div class="CheckBox-class"><p-checkbox [(ngModel)]="checked1" [binary]="true"
					inputId="binary"></p-checkbox>
				<label for="ny" class="grant-checked">Grant Permission To All Subfolders And Its Reports</label>
			</div>
			<!-- <hr style="margin-top: 20px;"> -->
			<!-- <div style="float:right">
				<p-button label="Close" (click)="showPermissionDialog=false"></p-button>
				<p-button label="Assign" (click)=" onAssignClick1()"></p-button>
			</div> -->
		</div>
		<div *ngIf="userShow" class="role-class">
			<div class="TagInline"><label class="label-class">Grant Read Access To User : </label>
				<p-autoComplete class="tagField" placeholder="Add a Tag" [(ngModel)]="selectedList2"
					[suggestions]="itemList2" (completeMethod)="filterItems2($event)" field="userName" appendTo="body"
					[multiple]="true">
				</p-autoComplete>
			</div>
			<div class="CheckBox-class"><p-checkbox [(ngModel)]="checked2" [binary]="true"
					inputId="binary"></p-checkbox>
				<label for="ny" class="grant-checked">Grant Permission To All Subfolders And Its Reports</label>
			</div>
			<!-- <hr style="margin-top: 20px;">
			<div style="float:right">
				<p-button label="Close" (click)="showPermissionDialog=false"></p-button>
				<p-button label="Assign" (click)="onAssignClick2()"></p-button>
			</div> -->
		</div>
		<ng-template pTemplate="footer">
			<div *ngIf="roleShow">
				<p-button label="Close" (click)="showPermissionDialog=false"></p-button>
				<p-button label="Assign" (click)=" onAssignClick1()" [disabled]="isRoleDisable"></p-button>
			</div>
			<div *ngIf="userShow">
				<p-button label="Close" (click)="showPermissionDialog=false"></p-button>
				<p-button label="Assign" (click)="onAssignClick2()" [disabled]="isUserDisable"></p-button>
			</div>
		</ng-template>
	</p-dialog>
	<!-- permission dialog report-->
	<p-dialog [modal]="true" [draggable]="false" class="confirm-dialog" [(visible)]="showPermissionReportDialog"
		[header]="title" [style]="{ width: '700px' }" [position]="'top'">
		<img src="././assets/images/dualBallSpinner.svg" alt="" class="modal-spinner-img" *ngIf="isLoadingModal">
		<div class="button-class">
			<p-selectButton [options]="permissionReportOptions" [(ngModel)]="selectedReportRole" optionLabel="label"
				appendTo="body" optionValue="value" (click)="permissionReportFunc()"></p-selectButton>
		</div>
		<div *ngIf="roleReportShow" class="role-class">
			<div class="TagInline"><label class="label-class">Grant Read Access To Role </label>
				<p-autoComplete class="tagField" placeholder="Add a Tag" [(ngModel)]="selectedReportList1"
					appendTo="body" [suggestions]="itemReportList1" (completeMethod)="filterReportItems1($event)"
					field="roleName" [multiple]="true">
				</p-autoComplete>
			</div>
			<div class="CheckBox-class"><p-checkbox [(ngModel)]="checkedReport1" [binary]="true"
					inputId="binary"></p-checkbox>
				<label for="ny" class="grant-checked">Grant Permission To All Subfolders And Its Reports</label>
			</div>
			<!-- <hr style="margin-top: 20px;">
			<div style="float:right">
				<p-button label="Close" (click)="showPermissionReportDialog=false"></p-button>
				<p-button label="Assign" (click)="onReportAssignClick1()"></p-button>
			</div> -->
		</div>
		<div *ngIf="userReportShow" class="role-class">
			<div class="TagInline"><label class="label-class">Grant Read Access To User</label>
				<p-autoComplete class="tagField" placeholder="Add a Tag" [(ngModel)]="selectedReportList2"
					appendTo="body" [suggestions]="itemReportList2" (completeMethod)="filterReportItems2($event)"
					field="userName" [multiple]="true">
				</p-autoComplete>
			</div>
			<div class="CheckBox-class"><p-checkbox [(ngModel)]="checkedReport2" [binary]="true"
					inputId="binary"></p-checkbox>
				<label for="ny" class="grant-checked">Grant Permission To All Subfolders And Its Reports</label>
			</div>
			<!-- <hr style="margin-top: 20px;">
			<div style="float:right">
				<p-button label="Close" (click)="showPermissionReportDialog=false"></p-button>
				<p-button label="Assign" (click)="onReportAssignClick2()"></p-button>
			</div> -->
		</div>
		<ng-template pTemplate="footer">
			<div *ngIf="roleReportShow">
				<p-button label="Close" (click)="showPermissionReportDialog=false"></p-button>
				<p-button label="Assign" (click)="onReportAssignClick1()"></p-button>
			</div>
			<div *ngIf="userReportShow">
				<p-button label="Close" (click)="showPermissionReportDialog=false"></p-button>
				<p-button label="Assign" (click)="onReportAssignClick2()"></p-button>
			</div>
		</ng-template>
	</p-dialog>
	<p-dialog header="Report Catalog - {{reportCatalogueInfo?.authorizationName}}" [draggable]="false"
		class="confirm-dialog info-dialog" [position]="'top'" [modal]="true"
		[(visible)]="reporCatalogueInfotDialogVisible" [style]="{ width: '700px' }">
		<img src="././assets/images/dualBallSpinner.svg" alt="" class="modal-spinner-img" *ngIf="isLoadingModalInfo">
		<p-card class="Report-catalogue-card">
			<div class="p-grid header-back-info-model" *ngIf="showAdminfunctionality">
				<div class="p-col-4 p-d-flex">
					<b class="header-name-info-modal">Client Name</b>
					<b class="hyphen">-</b>
				</div>
				<div class="p-col-8 p-d-flex">
					<b>{{reportCatalogueInfo?.clientName}}</b>
				</div>
			</div>
			<div class="p-grid">
				<div class="p-col-4 border-cols" *ngIf="showAdminfunctionality">
					<b class="header-name-info-modal">Client Code</b>
					<b class="hyphen">-</b>
				</div>
				<div class="p-col-8 border-cols" *ngIf="showAdminfunctionality">
					<b>{{reportCatalogueInfo?.clientCode?reportCatalogueInfo?.clientCode : '' }}</b>
				</div>
				<div class="p-col-4 border-cols">
					<b class="header-name-info-modal">Report Name</b>
					<b class="hyphen">-</b>
				</div>
				<div class="p-col-8 border-cols">
					{{reportCatalogueInfo?.authorizationName ?
					reportCatalogueInfo?.authorizationName.charAt(0).toUpperCase() +
					reportCatalogueInfo?.authorizationName.slice(1) : ''}}
				</div>
				<div class="p-col-4 border-cols">
					<b class="header-name-info-modal">Report Description</b>
					<b class="hyphen">-</b>
				</div>
				<div class="p-col-8 border-cols">
					{{reportCatalogueInfo?.authorizationDesc?reportCatalogueInfo?.authorizationDesc.charAt(0).toUpperCase()
					+reportCatalogueInfo?.authorizationDesc.slice(1) :''}}
				</div>
				<div class="p-col-4 border-cols">
					<b class="header-name-info-modal">Report Type</b>
					<b class="hyphen">-</b>
				</div>
				<div class="p-col-8 border-cols">
					{{reportCatalogueInfo?.reportType?reportCatalogueInfo?.reportType.charAt(0).toUpperCase()
					+reportCatalogueInfo?.reportType.slice(1):''}}
				</div>
				<div class="p-col-4 border-cols">
					<b class="header-name-info-modal">Report Parameters</b>
					<b class="hyphen">-</b>
				</div>
				<div class="p-col-8 border-cols">
					{{ reportCatalogueInfo?.parameters ? (reportCatalogueInfo?.parameters |
					stripHtml).charAt(0).toUpperCase() + (reportCatalogueInfo?.parameters | stripHtml).slice(1) : '' }}
				</div>
				<div class="p-col-4 border-cols">
					<b class="header-name-info-modal">Report Criteria</b>
					<b class="hyphen">-</b>
				</div>
				<div class="p-col-8 border-cols">
					{{ reportCatalogueInfo?.reportCriteria ? (reportCatalogueInfo?.reportCriteria |
					stripHtml).charAt(0).toUpperCase() + (reportCatalogueInfo?.reportCriteria | stripHtml).slice(1) : ''
					}}
				</div>
				<div class="p-col-4 border-cols">
					<b class="header-name-info-modal">Field Mapping</b>
					<b class="hyphen">-</b>
				</div>
				<div class="p-col-8 border-cols">
					{{reportCatalogueInfo?.fieldMapping?(reportCatalogueInfo?.fieldMapping |
					stripHtml).charAt(0).toUpperCase()+(reportCatalogueInfo?.fieldMapping | stripHtml).slice(1):''}}
				</div>
				<div class="p-col-4 border-cols">
					<b class="header-name-info-modal">Location</b>
					<b class="hyphen">-</b>
				</div>
				<div class="p-col-8 border-cols">
					{{reportCatalogueInfo?.location?reportCatalogueInfo?.location.charAt(0).toUpperCase()+reportCatalogueInfo?.location.slice(1):''}}
				</div>
			</div>
		</p-card>
	</p-dialog>
	<!-- Only Report information dialog -->
	<p-dialog header="Report Information" [draggable]="false" class="confirm-dialog Reporttabs" [position]="'top'" [modal]="true"
		[(visible)]="showReportInformationDialog" [style]="{ width: '845px' }">
		<img src="././assets/images/dualBallSpinner.svg" alt="" class="modal-spinner-img" *ngIf="isLoadingModalInfo">
		<div  class="Report-catalogue-card">
			<p-tabView class="mainReport-tabs">
				<p-tabPanel header="Information" class="tab1">
					<p-card class="Report-catalogue-card">
						<div class="p-grid header-back-info-model" *ngIf="showAdminfunctionality">
							<div class="p-col-4 p-d-flex">
								<b class="header-name-info-modal">Client Name</b>
								<b class="hyphen">-</b>
							</div>
							<div class="p-col-8 p-d-flex">
								<b>{{reportCatalogueInfo?.clientName}}</b>
							</div>
						</div>
						<div class="p-grid">
							<div class="p-col-4 border-cols" *ngIf="showAdminfunctionality">
								<b class="header-name-info-modal">Client Code</b>
								<b class="hyphen">-</b>
							</div>
							<div class="p-col-8 border-cols" *ngIf="showAdminfunctionality">
								<b>{{reportCatalogueInfo?.clientCode?reportCatalogueInfo?.clientCode : '' }}</b>
							</div>
							<div class="p-col-4 border-cols">
								<b class="header-name-info-modal">Report Name</b>
								<b class="hyphen">-</b>
							</div>
							<div class="p-col-8 border-cols">
								{{reportCatalogueInfo?.authorizationName ?
								reportCatalogueInfo?.authorizationName.charAt(0).toUpperCase() +
								reportCatalogueInfo?.authorizationName.slice(1) : ''}}
							</div>
							<div class="p-col-4 border-cols">
								<b class="header-name-info-modal">Report Description</b>
								<b class="hyphen">-</b>
							</div>
							<div class="p-col-8 border-cols">
								{{reportCatalogueInfo?.authorizationDesc?reportCatalogueInfo?.authorizationDesc.charAt(0).toUpperCase()
								+reportCatalogueInfo?.authorizationDesc.slice(1) :''}}
							</div>
							<div class="p-col-4 border-cols">
								<b class="header-name-info-modal">Report Type</b>
								<b class="hyphen">-</b>
							</div>
							<div class="p-col-8 border-cols">
								{{reportCatalogueInfo?.reportType?reportCatalogueInfo?.reportType.charAt(0).toUpperCase()
								+reportCatalogueInfo?.reportType.slice(1):''}}
							</div>
							<div class="p-col-4 border-cols">
								<b class="header-name-info-modal">Report Parameters</b>
								<b class="hyphen">-</b>
							</div>
							<div class="p-col-8 border-cols">
								{{ reportCatalogueInfo?.parameters ? (reportCatalogueInfo?.parameters |
								stripHtml).charAt(0).toUpperCase() + (reportCatalogueInfo?.parameters | stripHtml).slice(1) : '' }}
							</div>
							<div class="p-col-4 border-cols">
								<b class="header-name-info-modal">Report Criteria</b>
								<b class="hyphen">-</b>
							</div>
							<div class="p-col-8 border-cols">
								{{ reportCatalogueInfo?.reportCriteria ? (reportCatalogueInfo?.reportCriteria |
								stripHtml).charAt(0).toUpperCase() + (reportCatalogueInfo?.reportCriteria | stripHtml).slice(1) : ''
								}}
							</div>
							<div class="p-col-4 border-cols">
								<b class="header-name-info-modal">Field Mapping</b>
								<b class="hyphen">-</b>
							</div>
							<div class="p-col-8 border-cols">
								{{reportCatalogueInfo?.fieldMapping?(reportCatalogueInfo?.fieldMapping |
								stripHtml).charAt(0).toUpperCase()+(reportCatalogueInfo?.fieldMapping | stripHtml).slice(1):''}}
							</div>
							<div class="p-col-4 border-cols">
								<b class="header-name-info-modal">Location</b>
								<b class="hyphen">-</b>
							</div>
							<div class="p-col-8 border-cols">
								{{reportCatalogueInfo?.location?reportCatalogueInfo?.location.charAt(0).toUpperCase()+reportCatalogueInfo?.location.slice(1):''}}
							</div>
						</div>
					</p-card>
				</p-tabPanel>
				<p-tabPanel header="Report Specification" class="tab1">
					<div class="button-group">
						<p-button label="Upload" (onClick)="setOption('upload')" class="btun" *ngIf="this.showAdminfunctionality"></p-button>
						<p-button label="Specification" (onClick)="setOption('specification')" class="btun"></p-button>
						<p-button label="History" (onClick)="setOption('history')" class="btun"></p-button>
					  </div>

						<div class="tab-panels" style="margin-top: 10px;">
							<section id="marzen" *ngIf="selectedOption === 'upload' && this.showAdminfunctionality" class="tab-panel">
								<form [formGroup]="uploadForm" (ngSubmit)="onSubmit()">
									<div class="p-field p-col-12 form-group">
										<label for="filename" class="form-label">File Name <span class="mandatory-simple">*</span></label><br>
										<input type="text" formControlName="filename" class="form-input" pInputText id="filename" required>
										<div *ngIf="uploadForm.get('filename').invalid && uploadForm.get('filename').touched" class="error-message">
											File Name is required.
										</div>
									</div>
									<div class="p-field p-col-12 form-group">
										<label for="description" class="form-label">Description</label><br>
										<textarea rows="5" cols="60" formControlName="description" pInputTextarea placeholder="Enter Description"
											class="roleTextarea"></textarea><br><br>
									</div>
									<div class="p-field p-col-12 form-group">
										<label for="file" class="form-label">Locate the File <span class="mandatory-simple">*</span></label>
										<div class="form-group-file-upload" *ngIf="!selectedFileName">
											<div class="img-background-report">
												<input type="file" (change)="onFileSelect($event)" class="file-input-report" required #fileUpload>
											</div>
										</div>
										<div *ngIf="selectedFileName" class="selected-file-class">
											<span class="animateH5 selected-file-name" title="{{selectedFileName}}">{{selectedFileName}}</span>
											<i class="pi pi-times cancel-icon-drag" (click)="removeFile()" pTooltip="Remove" tooltipPosition="top"></i>
										</div>
										<div *ngIf="uploadForm.get('file').invalid && uploadForm.get('file').touched" class="error-message">
											File is required.
										</div>
									</div>
									<div *ngIf="conflictError" class="error-message">Duplicate File Name</div>
									<!-- Upload Button -->
									<div class="upload-Button-in-report-info">
										<p-button class="save-btn" type="submit" label="Upload" [disabled]="uploadForm.invalid"></p-button>
									</div>
							</form>
							</section>
							<section id="rauchbier" *ngIf="selectedOption === 'specification'" class="tab-panel">
								<!-- <img src="././assets/images/dualBallSpinner.svg" alt="" class="spinner-img"> -->
								<p-card class="table-card">
									<p-table [scrollable]="true" [value]="specificationReport" 
										scrollHeight="calc(65vh - 200px)"
										[globalFilterFields]="['roleName','roleDesc']" selectionMode="single"
										[tableStyle]="{'min-width':'38rem'}">
										<ng-template pTemplate="header">
											<tr>
												<th pSortableColumn="specificationName">File Name<p-sortIcon
														field="specificationName"></p-sortIcon></th>
												<th>Description</th>
												<th>Created By</th>
												<th>Options</th>
											</tr>
										</ng-template>
										<ng-template pTemplate="body" let-data let-i="rowIndex">
											<tr>
												<td>{{data.specificationName}}</td>
												<td>{{data.specificationDesc}}</td>
												<td>{{data.createdByUser}}</td>
												<td>
													<button *ngIf="index!=i" class="btn" style="min-width: 20px;" type="button" pButton icon="pi pi-download" pTooltip="Download" tooltipPosition="top"
													(click)="download(data,i)"></button>
													<button *ngIf="index==i" class="btn" style="min-width: 20px;" type="button" pButton icon="pi pi-spin pi-spinner" pTooltip="Download" tooltipPosition="top"
													></button>
												<button class="btn" style="min-width: 20px;" type="button" pButton icon="pi pi-trash" pTooltip="Delete"
													tooltipPosition="top"
													(click)="delete(data,i)"></button>
												</td>
											</tr>
										</ng-template>
										<ng-template pTemplate="emptymessage">
											<tr>
												<td>No Records Found</td>
											</tr>
										</ng-template>
									</p-table>
								</p-card>
							</section>
							<section id="dunkles"  *ngIf="selectedOption === 'history'" class="tab-panel">
								<p-card class="table-card">
									<p-table [scrollable]="true" [value]="this.specificationHistoryReport"
										scrollHeight="calc(65vh - 200px)"
										[globalFilterFields]="['roleName','roleDesc']" selectionMode="single"
										[tableStyle]="{'min-width':'45rem'}">
										<ng-template pTemplate="header">
											<tr>
												<th pSortableColumn="specificationName">File Name<p-sortIcon
														field="specificationName"></p-sortIcon></th>
												<th pSortableColumn="specificationDesc">Description<p-sortIcon
														field="specificationDesc"></p-sortIcon></th>
												<th pSortableColumn="createdByUser">Created By<p-sortIcon
														field="createdByUser"></p-sortIcon></th>
												<th pSortableColumn="strCrDate">Created Date<p-sortIcon
														field="strCrDate"></p-sortIcon></th>
												<th>Options</th>
											</tr>
										</ng-template>
										<ng-template pTemplate="body" let-data let-i="rowIndex">
											<tr>
												<td>{{data.specificationName}}</td>
												<td>{{data.specificationDesc}}</td>
												<td>{{data.createdByUser}}</td>
												<td>{{data.strCrDate}}</td>
												<td>
													<button *ngIf="index!=i" class="btn" style="min-width: 20px;" type="button" pButton icon="pi pi-download" pTooltip="Download" tooltipPosition="top"
													(click)="download(data,i)"></button>
													<button *ngIf="index==i" class="btn" style="min-width: 20px;" type="button" pButton icon="pi pi-spin pi-spinner" pTooltip="Download" tooltipPosition="top"
													></button>
												</td>
											</tr>
										</ng-template>
										<ng-template pTemplate="emptymessage">
											<tr>
												<td>No Records Found</td>
											</tr>
										</ng-template>
									</p-table>
								</p-card>
							</section>
						</div>
				</p-tabPanel>
			</p-tabView>
		</div>
		<ng-template pTemplate="footer">
			<p-button class="updateButton" label="Update" [disabled]="accountForm.invalid" (click)="updateAccount()"
				*ngIf="tabHeaderName === 'Account Information'"></p-button>
			<p-button label="Update" class="updateButton" [disabled]="passwordForm.invalid" (click)="updatePassword()"
				*ngIf="tabHeaderName === 'Change Password'"></p-button>
		</ng-template>
	</p-dialog>
</div>
<p-toast></p-toast>
<!-- </div> -->
<!-- <div id="showNew1" *ngIf="showView">
	<app-my-reports-output [reportId]="Rid"  [reportType]="Rtype" [reportName]="Rname" [uniqueName]="uniqueName"></app-my-reports-output>
</div> -->