
<!-- seocond design -->
<div class="login-parent-container">

	<div class="p-d-flex image-p-grid">
		<div class="img-container">
			<img src="././assets/images/NewTBlogo2.png" alt="" class="time-bound-logo">
				<!-- <img src="././assets/images/NewTBlogo2.png" alt="" class="time-bound-logo"><span class="reliance">reliance</span><span class="matrix">matrix</span> -->
				<!-- matrix_logo.png -->
		</div>
	
			<div class="container form-container">
				<div class="p-grid">
					<div class="p-col-10 welcome-heading-class">
						<h1 class="heading2-login-form">Timebound</h1>
					</div>
					<div class="p-col-10 form-box">
						<div class="p-col-12">
							<label class="form-input-label">Username</label>
							<span class="p-input-icon-left p-col-12">
								<i class="pi pi-user"></i>
								<input class="p-col-12 p-py-3 form-input" type="text"
									placeholder="Enter the Username" [(ngModel)]="loginUsername" pInputText />
							</span>
						</div>
						<div class="p-col-12 p-mb-3">
							<label class="form-input-label">Password</label>
							<span class="p-input-icon-left p-col-12">
								<i class="pi pi-key"></i>
								<input class="p-col-12 p-py-3 form-input"
									(keyup.enter)="onLoginButtonClick()" type="password"
									placeholder="Enter the Password" [(ngModel)]="loginPassword" pInputText />
							</span>
						</div>
						<div class="p-col-12 p-px-3">
							<button class="p-col-12 tibo-login-loginbutton" pButton label="Log In" iconPos="right"
								loadingIcon="pi pi-spinner pi-spin" [loading]="isLoading"
								(click)="onLoginButtonClick()"></button>
						</div>
					</div>
				</div>
				<h2 class="headerText">AI-Driven Business Intelligence Reporting and Data Analytics
					Platform
				</h2>
			</div>
		
	
	</div>
</div>
<p-toast></p-toast>
