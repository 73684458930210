import { Component, OnInit } from '@angular/core';
import { SettingsService } from './settings.service';
import { Subscription } from 'rxjs';
import { MessageService } from 'primeng/api';
// import { SharedService } from '../shared.service';


declare var d3: any;

interface DashboardModel {
	name: string,
	code: string
}

interface LandingPage {
	name: string,
	code: string
}

@Component({
	selector: 'app-settings',
	templateUrl: './settings.component.html',
	styleUrls: ['./settings.component.css'],
	providers: [SettingsService]
})

export class SettingsComponent implements OnInit {

	displayOptions: any[];
	reportLists: any[];
	landingPages: LandingPage[];
	defaultDashboardData: DashboardModel[];
	defaultDashDataCopy = [];
	fonts: any[];
	fontSize: any[];
	fontStyle: any[];

	savedDefaultDashboard: any;
	autoRefreshCheckBool: boolean = false;
	savedLandingPage: string = "";
	autoSuggestCheckBool: boolean = false;

	alertSeverity: string = "error";
	alertMsg: string = "";

	requestPayload = {
		appLandingPage: "",
		defaultDashboard: 0,
		repositoryLandingPage: "",
		retentionDays: 0,
		dashboardRefreshTime: 0,
		userDashboardWidgetLimit: 0,
		autoSuggest: "",
		dashboardRefreshOnOROff: "",
		viewStyle: "",
		directoryView: "",
		fontFamily: "",
		fontSize: "",
		fontStyle: "",
		defaultWidgetColor: "",
		defaultBackgroundColor: "",
		defaultTheme: "",
		seriesValue:"N",
		legendValue:"N"
	}

	backColor: any;
	value: number;
	wigetcolor: string;
	showClass: boolean = false;
	Disabled: boolean = false;
	buttonIndex: number;
	allColorButton: boolean = false;
	wigetBackgroundColor: any;
	event: any;
	selectedChartsColorSet: string;
	widgetBackColor: any;
	fontFam: any;
	fontSty: any;
	selectedColor: any;
	themeColor: any;
	seriesNumber:boolean=false;
	legendNumber:boolean=false;

	constructor(private settings: SettingsService, private messageService: MessageService) {

		this.displayOptions = [
			{ label: "List", value: "List" },
			{ label: "Tile", value: "Tile" }
		];

		this.reportLists = [
			{ label: "List View", value: "ClientView" },
			{ label: "Folder View", value: "TreeView" }
		]

		this.landingPages = [
			{ name: 'Home', code: 'LandingPage' },
			{ name: 'My Dashboard', code: 'dashboard' },
			{ name: 'Favorites', code: 'myFavorites' },
			{ name: 'My Reports', code: 'myReports' },
			{ name: 'Reports Inbox', code: 'myInbox' },
			{ name: 'Scheduled Reports', code: 'scheduleReport' }
		];

		this.fonts = [
			{ name: 'Verdana', value: 'verdana' },
			{ name: 'San-Serif', value: 'san-serif' },
			{ name: 'Inter', value: 'inter' },
			{ name: 'Roboto', value: 'roboto' },

		]
		this.fontSize = [
			{ size: '10px', value: '10px' },
			{ size: '12px', value: '12px' },
			{ size: '14px', value: '14px' },
			{ size: '16px', value: '16px' },

		]
		this.fontStyle = [
			{ style: 'Normal', value: 'normal' },
			{ style: 'Bold', value: 'bold' },
			{ style: 'Italic', value: 'italic' },
		]
	}

	colorOne = ["#FFFF", "#CFE2F3", "#E1CCB6", "#ffd670", "#d9d9d9", "#ebb9b9", "#e1d0e8", "#E3F2FD", "#B2DFDB", "#C8E6C9", "#FFF9C4"];

	colorSetOne = ["#00A9E9", "#BC851C", "#004990", "#87189D", "#C5003E", "#28724F", "#92C1E9", "#CEB888", "#678787", "#B884CB", "#EF6079", "#85B09A"]
	colorSetTwo = ["#E95044", "#F28A4C", "#F0D444", "#A2DF9C", "#93DBE4", "#B091D8", "#DD1D24", "#E35724", "#EFE60C", "#39A654", "#0C9FDC", "#812888"] //third set
	colorSetThree = ["#01204F", "#006A8D", "#03848C", "#64A79D", "#E9CFA1", "#F2A465", "#EF6727", "#F03113", "#E6DCAB", "#E6DCAB", "#DDBD36", "#926A00"]; //yellow
	// colorSetFour = ["#f7e37a", "#fbdd38", "#ebc403", "#f8c32a", "#e2a83d", "#f3b064", "#f68933", "#f5636d", "#ff4e5b", "#e42f24", "#bc2025", "#c02024"]; //yellow to red	
	// colorSetFive = ["#012c66", "#86bedf", "#cfe8f7", "#cb2624", "#e34746", "#f47473", "#046365", "#13a1a4", "#68dddb", "#e7c414", "#ffe242", "#ffe87b"]; //Neon
	// colorSetSix = ["#6E2C00", "#873600", "#A04000", "#BA4A00", "#D35400", "#DC7633", "#E59866", "#EDBB99", "#F6DDCC", "#FBEEE6", "#fff2eb", "#fff7f2"];// 6 cube set brown
	// colorSetSeven = ["#002E55", "#014C7C", "#005B7C", "#008098", "#0E89B5", "#0695AA", "#35B5C1", "#397C82", "#87C9CF", "#ACCBC6", "#C0E0DB", "#E4EBEA"]; //Brown color set
	// colorSetEight = ["#3d818c", "#63adb3", "#95cdcf", "#b9d0d0", "#cfe6e6", "#e5eeee", "#eeebe3", "#c3bca1", "#c3bca1", "#c4ad91", "#b89f87", "#a08d76"]; //4 cube set
	defaultTheme=["#1d1e22","#df8070","#6882bb","#3e9a77","#7b71b6","#394269","#79c4d0","#2d3643"];
	

	dashboardListSubscrip: Subscription;
	savedSettingsSubscrip: Subscription;
	saveNewSettingsSubscrip: Subscription;

	ngOnInit(): void {
		this.selectedColor = this.colorSetOne;
		this.themeColor=this.defaultTheme[0];
		this.widgetBackColor = "#d9d9d9";
		d3.select(".tibo-topbar-title").text("Home/Settings"); // setting title
		this.dashboardListSubscrip = this.settings.getDashboardsList().subscribe((data: any) => { // getting the dashboards list
			this.defaultDashboardData = [];
			if(data.body.length==0){
				this.alertMsg = 'No Dashboards Assigned';
				this.addSingle();
			  }
			else{
			data.body.forEach((d, i) => {
				this.defaultDashDataCopy.push({ name: d.widgetName, code: Number(d.widgetMasterId) })
			})
			this.defaultDashboardData = [...this.defaultDashDataCopy];
		}

		}, error => {

			this.alertSeverity = "error";

			if (Number(error.status) == 401) { this.alertMsg = "Please Try After Some Time!" }
			else if (Number(error.status) == 500) { this.alertMsg = "Internal Error" }
			else { this.alertMsg = "Please Try After Some Time." }

			this.addSingle();
		}, () => { })

		this.savedSettingsSubscrip = this.settings.getSavedSettings().subscribe((data: any) => { // getting the saved settings

			this.requestPayload.appLandingPage = data.body["appLandingPage"]
			this.requestPayload.defaultDashboard = data.body["defaultDashboard"]
			this.requestPayload.repositoryLandingPage = data.body["repositoryLandingPage"]
			this.requestPayload.retentionDays = data.body["retentionDays"]
			this.requestPayload.dashboardRefreshTime = data.body["dashboardRefreshTime"]
			this.requestPayload.userDashboardWidgetLimit = data.body?.userDashboardWidgetLimit == undefined ? 6 : data.body["userDashboardWidgetLimit"]
			this.requestPayload.autoSuggest = data.body["autoSuggest"]
			this.requestPayload.dashboardRefreshOnOROff = data.body["dashboardRefreshOnOROff"]
			this.requestPayload.seriesValue = data.body["seriesValue"]
			this.requestPayload.legendValue = data.body["legendValue"]
			this.requestPayload.viewStyle = data.body["viewStyle"]
			this.requestPayload.directoryView = data.body["directoryView"];
			this.requestPayload.fontFamily = data.body["fontFamily"];
			this.requestPayload.fontSize = data.body["fontSize"];
			this.requestPayload.fontStyle = data.body["fontStyle"]
			this.requestPayload.defaultBackgroundColor=data?.body["defaultBackgroundColor"]?data?.body["defaultBackgroundColor"]:this.colorSetOne;
			this.requestPayload.defaultTheme=data?.body["defaultTheme"];
			this.requestPayload.defaultWidgetColor=data?.body["defaultWidgetColor"]?data?.body["defaultWidgetColor"]:this.widgetBackColor;
			this.savedDefaultDashboard = Number(data.body["defaultDashboard"])
			if(data?.body["defaultWidgetColor"] == "One"){
			this.selectedColor=this.colorSetOne;
			}
			else if(data?.body["defaultWidgetColor"] == "Two"){
				this.selectedColor=this.colorSetTwo;
			}
			else if(data?.body["defaultWidgetColor"] == "Three"){
				this.selectedColor=this.colorSetThree;
			}
			// else if(data?.body["defaultWidgetColor"] == "Four"){
			// 	this.selectedColor=this.colorSetFour;
			// }
			// else if(data?.body["defaultWidgetColor"] == "Five"){
			// 	this.selectedColor=this.colorSetFive;
			// }
			// else if(data?.body["defaultWidgetColor"] == "Six"){
			// 	this.selectedColor=this.colorSetSix;
			// }
			// else if(data?.body["defaultWidgetColor"] == "Seven"){
			// 	this.selectedColor=this.colorSetSeven;
			// }
			// else if(data?.body["defaultWidgetColor"] == "Eight"){
			// 	this.selectedColor=this.colorSetEight;
			// }
			this.widgetBackColor=data?.body["defaultBackgroundColor"]?data?.body["defaultBackgroundColor"]:"#d9d9d9";
			this.colorOne.filter((e,i)=>{
				if(e==this.widgetBackColor){
					this.buttonIndex=i;
				}
			})
			if (this.requestPayload.dashboardRefreshOnOROff == "Y") {
				this.autoRefreshCheckBool = true;
			}
			if (this.requestPayload.seriesValue == "Y") {
				this.seriesNumber = true;
			}
			if (this.requestPayload.legendValue == "Y") {
				this.legendNumber = true;
			}
			this.themeColor=data?.body["defaultTheme"]? data?.body["defaultTheme"] : this.defaultTheme[0];
			this.savedLandingPage = data.body["appLandingPage"]

			if (this.requestPayload.autoSuggest == "Y") {
				this.autoSuggestCheckBool = true;
			}
		}, error => {

			this.alertSeverity = "error";

			if (Number(error.status) == 401) { this.alertMsg = "Please Try After Some Time!" }
			else if (Number(error.status) == 500) { this.alertMsg = "Internal Error" }
			else { this.alertMsg = "Please Try After Some Time." }

			this.addSingle();
		}, () => { });


		d3.select(".tibo-settings-themes-container").selectAll("div.tibo-stgs-color-theme").on("click", function () {

			d3.select(".tibo-settings-themes-container").selectAll("div.tibo-stgs-color-theme").style("border", "none")
			d3.select(this).style("border", "5px solid #e0dbdb")
			d3.select("#tibo-min-leftpanel-container").style("background-color", d3.select(this).style("background-color"))
			d3.select("#tibo-large-leftpanel-container").style("background-color", d3.select(this).style("background-color"))
			d3.selectAll(".tibo-menupanel-largepanel-accrdn-wrapper .p-accordion .p-accordion-header .p-accordion-header-link")
				.style("background-color", "transparent")

			d3.select("#lrg-header8 a").style("background-color", "#3b586e");

		});

	}

	ngOnDestroy() {
		if (this.dashboardListSubscrip) {
			this.dashboardListSubscrip.unsubscribe();
		}
		if (this.savedSettingsSubscrip) {
			this.savedSettingsSubscrip.unsubscribe();
		}
		if (this.saveNewSettingsSubscrip) {
			this.saveNewSettingsSubscrip.unsubscribe();
		}
	}

	defaultDashboard(event: any) {
		this.requestPayload.defaultDashboard = event.value
	}

	handleChange(event: any) {
		if (event.checked) {
			this.requestPayload.dashboardRefreshOnOROff = "Y"
		}
		else {
			this.requestPayload.dashboardRefreshOnOROff = "N"
		}
	}

	displayStyleChange(event: any) {
		this.requestPayload.viewStyle = event.value
	}

	displayStyleViewChange(event: any) {
		this.requestPayload.directoryView = event.value
	}

	autoSuggestSearch(event: any) {
		if (event.checked) {
			this.requestPayload.autoSuggest = "Y"
		}
		else {
			this.requestPayload.autoSuggest = "N"
		}
	}

	landingPage(event: any) {
		this.requestPayload.appLandingPage = event.value
	}

	onChangeFrequency(event: any) {
		if(event.value<3){
			this.addSingle();
			this.alertSeverity = "error";
			this.alertMsg = "Refresh time cannot be less then 3";
		}
		else{
		this.requestPayload.dashboardRefreshTime = event.value;
		}
	}

	onChangeWidgetLimit(event: any) {
		if(event.value<3){
			this.addSingle();
			this.alertSeverity = "error";
			this.alertMsg = "Widget limit cannot be less then 3";
		}
		else{
		this.requestPayload.userDashboardWidgetLimit = event.value;
		}
	}

	onChangeRepInboxLimit(event: any) {
		if(event.value<90){
			this.addSingle();
			this.alertSeverity = "error";
			this.alertMsg = "Limit cannot be less then 90";
		}
		else{
			this.requestPayload.retentionDays = event.value
		}
		
	}
	onFontFamilyChange(event: any) {
		this.requestPayload.fontFamily = event.value;
	}
	onFontSizeChange(event: any) {
		this.requestPayload.fontSize = event.value;
	}
	onFontStyleChange(event: any) {
		this.requestPayload.fontStyle = event.value;
	}
	onWidColorChange(event: any) {
		this.requestPayload.fontStyle = event.value;
	}
	onThemeChange(event: any) {
		//console.log(event)
		this.requestPayload.defaultTheme = event;
		this.themeColor=event;
		// d3.select(".tibo-settings-themes-container").selectAll("div.tibo-stgs-color-theme").style("border", "none")
		// 	d3.select(this).style("border", "5px solid #e0dbdb")
			d3.select("#tibo-min-leftpanel-container").style("background-color", event)
			d3.select("#tibo-large-leftpanel-container").style("background-color", event)
			// d3.selectAll(".tibo-menupanel-largepanel-accrdn-wrapper .p-accordion .p-accordion-header .p-accordion-header-link")
			// 	.style("background-color", "transparent")

			// d3.select("#lrg-header8 a").style("background-color", "#3b586e");

	}
	changeChartColorsFunc(colorSet, colorBackground) {
		this.requestPayload.defaultWidgetColor = colorSet;
		this.selectedColor = colorBackground;
		// this.shared.DefaultWidgetColor(colorSet)
	}
	applyChanges(event: any) {
		if (this.requestPayload.fontFamily == "" || this.requestPayload.fontFamily == null) {
			delete this.requestPayload.fontFamily
		}
		if (this.requestPayload.fontSize == "" || this.requestPayload.fontSize == null) {
			delete this.requestPayload.fontSize
		}
		if (this.requestPayload.fontStyle == "" || this.requestPayload.fontStyle == null) {
			delete this.requestPayload.fontStyle
		}
		if (this.requestPayload.defaultWidgetColor == "" || this.requestPayload.defaultWidgetColor == null) {
			delete this.requestPayload.defaultWidgetColor
		}
		if (this.requestPayload.defaultBackgroundColor == "" || this.requestPayload.defaultBackgroundColor == null) {
			delete this.requestPayload.defaultBackgroundColor
		}
		if (this.requestPayload.defaultTheme == "" || this.requestPayload.defaultTheme == null) {
			delete this.requestPayload.defaultTheme
		}
		this.saveNewSettingsSubscrip = this.settings.saveSettings(this.requestPayload).subscribe((data:any) => {

			if(data.status){
				this.alertSeverity = "success";
				this.alertMsg = "Changes applied!";
				this.addSingle();
			}

		}, error => { 

			this.alertSeverity = "error";

			if(Number(error.status) == 401){ this.alertMsg = "Please Try After Some Time!" }
			else if(Number(error.status) == 500){ this.alertMsg = "Internal Error" }
			else { this.alertMsg = "Please Try After Some Time." }

			this.addSingle();
		}, () => {});


	}

	addSingle() { this.messageService.add({ severity: this.alertSeverity, summary: "", detail: this.alertMsg }); }

	clear() { this.messageService.clear(); }


	getcolor(event, index) {
		this.allColorButton=false;
		if(index==12){
			this.allColorButton=true;
		}
		else{
			this.widgetBackColor = event;
		}
		this.requestPayload.defaultBackgroundColor = event;
		this.buttonIndex = index;
	}
	getBackgroundStyle(): string {
		if (this.allColorButton) {
		  return 'linear-gradient(red, lightgreen, lightblue)';
		} else {
		  return this.widgetBackColor;
		}
	  }
	  getThemeColor(): string {
		if (this.allColorButton) {
		  return 'linear-gradient(red, lightgreen, lightblue)';
		} else {
		  return this.widgetBackColor;
		}
	  }
	  handleChangeLegend(event){
		if (event.checked) {
			this.requestPayload.legendValue = "Y"
		}
		else {
			this.requestPayload.legendValue = "N"
		}
	  }
	  handleChangeSeries(event){
		if (event.checked) {
			this.requestPayload.seriesValue = "Y"
		}
		else {
			this.requestPayload.seriesValue = "N"
		}
	  }

}
