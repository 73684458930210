import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MenuPanelService } from './menu-panel.service';
import { MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { AppComponent } from '../app.component';
declare var d3: any;
@Component({
  selector: 'app-menu-panel',
  templateUrl: './menu-panel.component.html',
  styleUrls: ['./menu-panel.component.css'],
  providers: [MenuPanelService],
})

export class MenuPanelComponent implements OnInit {
  @Input() isExpanded: boolean = true;
  @Output() toggleSidebar: EventEmitter<boolean> = new EventEmitter<boolean>();
  activeString = '';
  pBadge_string!: string;
  @Input() getTodayFailedCountSideBar!: number;
  menus: any;
  resultMenuList: any = [];
  naviEventSubscrip: Subscription;

  constructor(private appComp: AppComponent,private menuService:MenuPanelService) {}

  ngOnChanges() {}

  ngOnInit(): void {
    // this.menuService.config().subscribe((res:any)=>{
    //   if(res){
    //     let color=res?.body?.defaultTheme?res?.body?.defaultTheme:'#292f3e';
    //     d3.select("#tibo-min-leftpanel-container").style("background-color", color)
		// 	d3.select("#tibo-large-leftpanel-container").style("background-color", color)
    //   }
    // });
    this.resultMenuList = this.appComp.resultMenuList;
  }

  ngOnDestroy() {}

  menuFunc() {
    this.toggleSidebar.emit((this.isExpanded = !this.isExpanded));
  }

  onLinkClick() {
    this.toggleSidebar.emit((this.isExpanded = false));
  }
  activefun(value: string) {
    this.activeString = value;
    // localStorage.setItem('active', value);
  }

}
