import { APP_INITIALIZER,NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ButtonModule } from 'primeng/button';
import { AccordionModule } from 'primeng/accordion';
import { ToastModule } from 'primeng/toast';
import { InputTextModule } from 'primeng/inputtext';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { DropdownModule } from 'primeng/dropdown';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { CalendarModule } from 'primeng/calendar';
import { MultiSelectModule } from 'primeng/multiselect';
import { RadioButtonModule } from 'primeng/radiobutton';
import { TooltipModule } from 'primeng/tooltip';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { DialogService, DynamicDialogModule } from 'primeng/dynamicdialog';
import { PanelMenuModule } from 'primeng/panelmenu';
import { TreeModule } from 'primeng/tree';
import { PaginatorModule } from 'primeng/paginator';
import { TableModule } from 'primeng/table';
import { SelectButtonModule } from 'primeng/selectbutton';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ChipModule } from 'primeng/chip';
import { InputNumberModule } from 'primeng/inputnumber';
import { CheckboxModule } from 'primeng/checkbox';
import { MenuModule } from 'primeng/menu';
import { DialogModule } from 'primeng/dialog';
import { TabViewModule } from 'primeng/tabview';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ChipsModule } from 'primeng/chips';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ListboxModule } from 'primeng/listbox';
import { CardModule } from 'primeng/card';
import { PanelModule } from 'primeng/panel';
import { DividerModule } from 'primeng/divider';
import { InputSwitchModule } from 'primeng/inputswitch';
import { BadgeModule } from 'primeng/badge';
import { LoginComponent } from './login/login.component';
import { MenuPanelComponent } from './menu-panel/menu-panel.component';
import { TopBarComponent } from './top-bar/top-bar.component';
import { HomeComponent } from './home/home.component';
// import { MyDashboardComponent } from './my-dashboard/my-dashboard.component';
import { MyReportsComponent } from './my-reports/my-reports.component';
import { MyReportsOutputComponent } from './my-reports-output/my-reports-output.component';
import { DashboardInfo } from './home/dashboard-info';
import { ReportsInfo } from './home/reports-info';
// import { DrillThruOutput } from './my-dashboard/drill-thru-output';
import { AllOutputFiles } from './my-reports/all-output-files';
import { LoginService } from './login/login.service';
// import { MyDashboardService } from './my-dashboard/my-dashboard.service';
import { MyReportsService } from './my-reports/my-reports.service';
import { MyReportsOutputService } from './my-reports-output/my-reports-output.service';
// import { DashboardOutputService } from './dashboard-output/dashboard-output.service';
import { RouteReuseStrategy } from '@angular/router';
import { CustomReuseStrategy } from './reuse-strategy';
import { SchedulerComponent } from './scheduler/scheduler.component';
import { SettingsComponent } from './settings/settings.component';
import { ReportCatalogueComponent } from './report-catalogue/report-catalogue.component';
import { CreateDashboardComponent } from './create-dashboard/create-dashboard.component';
import { BuildReportComponent } from './build-report/build-report.component';
import { ReactiveFormsModule } from '@angular/forms';
import { ModalComponent } from './modal/modal.component';
import { DatePipe, HashLocationStrategy, LocationStrategy } from '@angular/common';
import { SplitButtonModule } from 'primeng/splitbutton';
import { BuildReportService } from './build-report/build-report.service';
// import { DashboardComponent } from './dashboard/dashboard.component';
// import { DashboardService } from './dashboard/dashboard.service';
// import { DashboardOutput } from './dashboard/dashboard.output';
import { OldDashboardComponent } from './old-dashboard/old-dashboard.component';
// import { DashboardV3Component } from './dashboard-v3/dashboard-v3.component';
import { FilterPipe } from '././build-report/filter.pipe';
import { StripHtmlPipe } from './my-reports/strip-html.pipe';
import { CommonModule } from '@angular/common';
import { MenubarModule } from 'primeng/menubar';
import { OrderListModule } from 'primeng/orderlist';
import { EditorModule } from 'primeng/editor';
import {FileUploadModule} from 'primeng/fileupload';
import { HomeService } from './home/home.service';
import { MessageService } from 'primeng/api';
import { ChartModule } from 'primeng/chart';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { SearchPipe } from './pipe/search.pipe';
import { DragDropModule } from 'primeng/dragdrop';
import {DataViewModule} from 'primeng/dataview';
import { LocalStorageService } from './localStorageClearService';
import { DashboardChartComponent } from './dashboard-chart/dashboard-chart.component';
import { ProgressBarComponent } from './progress-bar/progress-bar.component';
import { ProgressBarService } from './progress-bar/progress-bar.service';
import { ProgressBarModule } from 'primeng/progressbar';
import { FooterComponent } from './footer/footer.component';
import { Keys } from './pipe/keys.pipe';
import { DiscoveryCloneComponent } from './discovery-clone/discovery-clone.component';
// import {CookieService} from 'ngx-cookie';

export function initializeApp(localStorageService: LocalStorageService) {
  return () => localStorageService.clearLocalStorageOnFirstLoad();
}
@NgModule({
  declarations: [
    AppComponent,
    MenuPanelComponent,
    LoginComponent,
    TopBarComponent,
    HomeComponent,
    // MyDashboardComponent,
    MyReportsComponent,
    MyReportsOutputComponent,
    SchedulerComponent,
    // DataDiscoveryComponent,
    // DashboardOutputComponent,
    // WidgetOutputComponent,
    SettingsComponent,
    ReportCatalogueComponent,
    BuildReportComponent,
    FilterPipe,
    StripHtmlPipe,
    DashboardInfo,
    ReportsInfo,
    // DrillThruOutput,
    // DashboardOutput,
    AllOutputFiles,
    DashboardChartComponent,
    CreateDashboardComponent,
    BuildReportComponent,
    ModalComponent,
    // DashboardComponent,
    OldDashboardComponent,
    // DashboardV3Component,
    SearchPipe,
    Keys,
    ProgressBarComponent,
    FooterComponent,
    DiscoveryCloneComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    AccordionModule,
    BadgeModule,
    ButtonModule,
    ToastModule,
    HttpClientModule,
    InputTextModule,
    ScrollPanelModule,
    TieredMenuModule,
    DropdownModule,
    FormsModule,
    SplitButtonModule,
    ProgressSpinnerModule,
    CalendarModule,
    MultiSelectModule,
    RadioButtonModule,
    TooltipModule,
    MessagesModule,
    MessageModule,
    DynamicDialogModule,
    PanelMenuModule,
    TreeModule,
    PaginatorModule,
    TableModule,
    SelectButtonModule,
    OverlayPanelModule,
    ChipModule,
    InputNumberModule,
    CheckboxModule,
    MenuModule,
    DialogModule,
    TabViewModule,
    AutoCompleteModule,
    ChipsModule,
    ConfirmDialogModule,
    ListboxModule,
    CardModule,
    PanelModule,
    DividerModule,
    InputSwitchModule,
    ReactiveFormsModule,
    MenubarModule,
    OrderListModule,
    EditorModule,
    FileUploadModule,
    ChartModule,
    InputTextareaModule,
    DragDropModule,
    DataViewModule,
    ProgressBarModule

  ],
  entryComponents: [],
  providers: [
    // CookieService,
    DialogService,
    LoginService,
    BuildReportService,
    DatePipe,
    // MyDashboardService,
    // DashboardService,
    MyReportsService,
    MyReportsOutputService,
    HomeService,
    // DashboardOutputService,
    MessageService,
    ProgressBarService,
    { provide: RouteReuseStrategy, useClass: CustomReuseStrategy },
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    LocalStorageService,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      multi: true,
      deps: [LocalStorageService]
    }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {

}