import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';

@Injectable()
export class ReportCatalogueService {
    
    constructor(private http: HttpClient) { }
	
	//requestPayload = {"sort":[], "filters":[], "filterType":"or"}
	
	/*directoryService(param) {
        return this.http.get('api/v1/directory/'+param+'/directory', { headers: {'X-Auth-Token': localStorage.getItem("token")}, observe: 'response' })
    }*/
	
	ReportrecordsService(page,requestPayload) {
		return this.http.post('api/v1/report/catalogue/search?limit=150&page='+page, requestPayload,
		{ headers: {'X-Auth-Token': localStorage.getItem("token")}, observe: 'response' })
	}
	reportInfoService(reportId){
		return this.http.get('api/v1/report/'+reportId+'/catalogue/information', { headers: {'X-Auth-Token': localStorage.getItem("token")}, observe: 'response' })
	}
	updateReportCatelogue(id,ReqPayLoad){
		return this.http.put('api/v1/report/catalogue/'+id, ReqPayLoad, { headers: {'X-Auth-Token': localStorage.getItem("token")}, 
		observe: 'response' })
	}
	
	/*settingsGetService(){
		return this.http.get('api/v1/user/config', { headers: {'X-Auth-Token': localStorage.getItem("token")}, observe: 'response' })
	}
	
	settingsPostService(settingsReqPayLoad){
		return this.http.put('api/v1/user/config', settingsReqPayLoad, { headers: {'X-Auth-Token': localStorage.getItem("token")}, 
		observe: 'response' })
	}*/

}
