import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export class DashboardService {
    
    constructor(private http: HttpClient) { }
     
    requestPayload = {"sort":[], "filters":[], "filterType":"and", 
                    "parameters":[{"name":"User","value":localStorage.userEmail,"questionId":1234567890,"id":1234567890,"isVisible":"Y"},
                    {"name":"User","value":localStorage.userEmail,"questionId":0,"id":80,"isVisible":"Y"}]}

    requestPayload1 = {"parameters":[{"name":"User","value":localStorage.userEmail}]}
   
    dashboardsListService() {
        return this.http.get('assets/json/domainWidget.json')
    }

    dashboardsList(){
        return this.http.get('assets/json/dashboardWidget.json')
    }

    getADashboard(metric,dashboardID: any,type: any ) {
		if(type.toLowerCase() == "dashboard"){
			return this.http.get('assets/json/'+dashboardID+'.json')
		}
		else if(type.toLowerCase() == "widget"){
			return this.http.get('assets/json/'+metric+'/'+dashboardID+'Search.json')
		}
    }

    getWidgetData(widgetID: any, limit) {
        return this.http.get('assets/json/'+widgetID+'.json')
    }

    getDefaultDashboard() {
        return this.http.get('assets/json/config.json')
    }
    settingsPostService(settingsReqPayLoad){
		return this.http.put('api/v1/user/config', settingsReqPayLoad, { headers: {'X-Auth-Token': localStorage.getItem("token")}, 
		observe: 'response' })
	}
    logData(param,payload){
        return this.http.post('api/v1/dashboard/'+param+'/logs', payload,
                { headers: {'X-Auth-Token': localStorage.getItem("token")}, observe: 'response' })//api/v1/dashboard/35519/logs
    }
    getParameterizedDashboardItemRecords(dashboardID: any){

        return this.http.post('api/v1/dashboard/cascade/dashboard/'+ dashboardID +'/0', this.requestPayload1,
        { headers: {'X-Auth-Token': localStorage.getItem("token")}, observe: 'response' })
    }
	
	getParameterizedDashboardCascadingRecords(dashboardID: any, paramId: any, reqPay){

        return this.http.post('api/v1/dashboard/cascade/dashboard/'+ dashboardID +'/' + paramId, reqPay,
        { headers: {'X-Auth-Token': localStorage.getItem("token")}, observe: 'response' })
    }

    getParameterizedDashboardWidgetData(widgetID: any, limit, resPalLoad: any) {
        
        return this.http.post('api/v1/dashboard/controls/'+widgetID+'/run?page=1&'+limit, resPalLoad,
                { headers: {'X-Auth-Token': localStorage.getItem("token")}, observe: 'response' })
    }
	
	PPTXDashboardDownloadService(requestPayload){
		return this.http.post('api/v1/dashboard/images/ppt', requestPayload,
			{ headers: {'X-Auth-Token': localStorage.getItem("token")}, observe: 'response' })
			
		/*return this.http.get('api/v1/dashboard/ppt/export?uniqueName=', 
				{ headers: {'X-Auth-Token': localStorage.getItem("token")}, 'responseType': 'blob' as 'json', observe: 'response' })*/
		
	}
	
	usersNameService(letter){
		return this.http.post('api/v1/user/search?page=1&limit=20', 
			{"sort":[{"property":"userName","dir":"ASC"}],"filters":[{"property":"userName","operator":"startw","value":letter}]},
			{ headers: {'X-Auth-Token': localStorage.getItem("token")}, observe: 'response' })
	}
	
	shareDashboardService(details){
		return this.http.post('api/v1/dashboard/share', details,
			{ headers: {'X-Auth-Token': localStorage.getItem("token")}, observe: 'response' })
	}
	
    getHeaders() {
        let headers = new HttpHeaders();
        headers.append('Accept', 'application/json');
        headers.append('Access-Control-Allow-Origin', '*');
        headers.append('X-Auth-Token', "")
        return headers;
    }

    downloadPPTXWidgets(data: any){
        return this.http.post('api/v1/dashboard/images/ppt', data,
        {responseType: 'arraybuffer', headers: {'X-Auth-Token': localStorage.getItem("token")}, observe: 'response' })    
    }
    addToFavourite(id){
        return this.http.post('api/v1/dashboard/'+id+'/favourite',{},
            { headers: {'X-Auth-Token': localStorage.getItem("token")}, observe: 'response' })  
    }
    removeFromFavourite(id){
        return this.http.delete('api/v1/dashboard/'+id+'/favourite',
            { headers: {'X-Auth-Token': localStorage.getItem("token")}, observe: 'response' })  
    }

}