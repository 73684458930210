import { AfterViewInit, Component, ElementRef, HostListener, OnDestroy } from '@angular/core';
import { ActivatedRoute, NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { AppComponentService } from './app-component.service';
import { Subscription } from 'rxjs';
import { MessageService } from 'primeng/api';
import { LocalStorageService } from './localStorageClearService';
import * as CryptoJS from 'crypto-js';
import { ProgressBarService } from './progress-bar/progress-bar.service';
import { TourGuideService } from './tour-guide.service';
declare var d3: any;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnDestroy, AfterViewInit {
  show = false;
  scheduleHistoryRecords: any = [];
  tableSortColumn = 'startTime';
  dir = 'DESC';
  requestPayload = {
    sort: [{ property: this.tableSortColumn, dir: this.dir }],
    filters: [],
    filterType: 'and',
  };
  getTodayFailedCountSideBar = 0;
  destroyEvent: Subscription;
  sidebarExpanded = true;
  menus: any;
  resultMenuList: any = [];
  resultMenuList2: any = [];
  isLoading: boolean = false;
  getTodayCompletedCount = 0;
  getTodayFailedCount = 0;
  getTodayRunningCount = 0;
  getTodayPendingCount = 0;
  getTotalTodayCount = 0;
  data: any;
  storeScheduledReportCount: any = [];
  storeUserDetails: any = [];
  resultUserDetails: any = [];
  storeConfigDetails: any = [];
  DomainListDetails: any = [];
  DomainDetails: any = [];
  userid: any = '';
  storeUserRoleDetails: any = [];
  dashboardsListSubscrip: Subscription;
  isLoggedIn: boolean = false;
  isLoggedIn1: boolean = false;
  loginPassword: string;
  loginUsername: string;
  errorMsg: string;
  private messageEventListener: (event: MessageEvent) => void;
  isLoggedout: boolean=false;
  tokenGenerated: boolean=false;
  isMatrixData: any=false;
  url: string='';
  reportIdSubscrip: Subscription;
  params: any='';
  title: string;
  isAdaLoginBool: boolean=false;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private appComponentService: AppComponentService,
    private messageService: MessageService,
    private localStorageService: LocalStorageService,
    public activatedRoute: ActivatedRoute,
    private progressBarService: ProgressBarService,
    private tourGuideService: TourGuideService
  ) { }

  ngAfterViewInit() { }
  ngOnInit() {
    this.url=window.location.href;
    this.isAdaLoginBool=false;
    this.isLoggedIn=true
    // if (this.url.includes('isMatrix=true')){localStorage.clear();}
    this.localStorageService.clearLocalStorageOnFirstLoad();
    if (this.isLoggedIn) {
      this.tokenGenerated=true;
      this.getUserData();
      this.menuListFun();
      this.getConfigData();
      // localStorage.clear();
      // this.url=window.location.href;
      // if (this.url.includes('isMatrix=true')){this.isMatrixData=true;this.isAdaLoginBool=true;localStorage.setItem('adaLogin','true')}
      // this.loginPassword = localStorage.getItem('password');
      // this.loginUsername = localStorage.getItem('userNames');
      // var salt = CryptoJS.lib.WordArray.random(128 / 8);
      // var iv = CryptoJS.lib.WordArray.random(128 / 8);
      // var key = CryptoJS.lib.WordArray.random(128 / 8);
      // var key128Bits100Iterations = CryptoJS.PBKDF2(key.toString(), salt, {
      //   keySize: 128 / 32,
      //   iterations: 100,
      // });
      // var encrypted = CryptoJS.AES.encrypt(
      //   this.loginPassword,
      //   key128Bits100Iterations,
      //   { iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 }
      // );

      // var data = {
      //   username: this.loginUsername,
      //   encryptedData: encrypted.toString(),
      //   salt: salt.toString(),
      //   iv: iv.toString(),
      //   key: key.toString(),
      // };

      // this.appComponentService
      //   .tokenService(data)
      //   .subscribe(
      //     (response: any) => {
      //       if(response){
      //       this.tokenGenerated=false;
      //       if (typeof Storage !== 'undefined') {
      //         if(response?.token!=null){
      //           localStorage.setItem('token', response?.token);
      //           const expirationTime = new Date().getTime() + 4 * 3600 * 1000; //4 hour in milliseconds
      //           localStorage.setItem('tokenExpiration', expirationTime.toString());
      //           this.tokenGenerated=true;
      //           if (this.url.includes('isMatrix=true')){this.isMatrixData=true;}
      //           this.callUserMenuAndConfig();
      //         }
      //       }
      //     }
      //     },
      //     (error) => {
      //       this.errorMsg = 'Please Try After Some Time.';
      //       this.addSingle();
      //     }
      //   );
    }
    // else {
    //   this.localStorageService.clearLocalStorageOnFirstLoad();
    //   if (localStorage.getItem('token') != null) {
    //     this.isLoggedIn = true;
    //     this.tokenGenerated=true;
    //     this.getUserData();
    //     this.menuListFun();
    //     this.getConfigData();
    //   } else {
    //     if (!this.isLoggedIn) {
    //     }
    //   }
    // }
  }
  // callUserMenuAndConfig(){
  //   // setTimeout(() => {
  //     if (localStorage.getItem('token') != null) {
  //       this.tokenGenerated=true;
  //       this.getUserData();
  //       this.menuListFun();
  //       this.getConfigData();
  //   }
  //   // }, 100);
  // }
  addSingle() {
    this.messageService.add({
      severity: 'error',
      summary: '',
      detail: this.errorMsg,
    });
    this.isLoading = false;
  }
  
  enterAppFunc(receivedBoolVal: any) {
    this.isLoggedIn = receivedBoolVal;
    if (this.isLoggedIn) {
      this.progressBarService.show()
      this.tokenGenerated=true;
      this.getUserData();
      this.menuListFun();
      this.getConfigData();
    }
  }

  ngOnDestroy() {
    // this.destroyEvent.unsubscribe();
    // window.removeEventListener('message', this.messageEventListener, false);
  }

  toggleSidebarAppCom(event) {
    this.sidebarExpanded = event;
    if (this.dashboardsListSubscrip) {
      this.dashboardsListSubscrip.unsubscribe();
    }
  }

  menuListFun() {
    this.appComponentService.menuListGetService().subscribe({
      next: (res) => {
        this.menus = res;
        const menuList = [
          'DataDiscovery',
          'Dashboard',
          'Custom Reports',
          'Adhoc',
          'My Documents',
          'My Reports',
          'Scheduled Reports',
          'Scheduler Logs',
          'My Favorites',
          'My Inbox',
          'Payroll',
          'Payroll Validation',
          'Configuration',
          'Manage Security',
          'Permission',
          'Widget Designer',
          'Report Catalogue',
          'Data Analytic Dashboard',
          'Self BI',
          'Settings',
          'GuidedTour',
          'Help',
        ];

        for (let i = 0; menuList.length > i; i++) {
          for (let j = 0; this.menus.length > j; j++) {
            if (menuList[i] === this.menus[j].menuName) {
              this.resultMenuList.push(this.menus[j].menuName);
            }
          }
        }
        this.tourGuideService.setMenuList(this.resultMenuList)
      },
      error: (err) => {
        this.messageService.add({
          severity: 'error',
          summary: '',
          detail: 'Please try again after sometime',
        });
      },
    });
  }
  getUserData() {
    this.isLoading = true;
    this.appComponentService.getUserDetails().subscribe({
      next: (res) => {
        this.storeUserDetails = res;
        this.userid = this.storeUserDetails?.userId
        this.getUserRoleData(this.userid);
        this.appComponentService.currentUser.subscribe((res) => {
          this.resultUserDetails = res;
          localStorage.setItem('userEmail', res?.emailId);
          localStorage.setItem('userName', res?.userName);
        });
        this.appComponentService.userMessage(this.storeUserDetails);
      },
      error: (err) => {
        localStorage.clear();
        window.location.reload();
      },
    });
  }
  getTitleFromUrl(url: string): string {
    const hashIndex = url.indexOf('#'); 
    if (hashIndex !== -1) {
        const hashPart = url.substr(hashIndex + 1);
        if (!hashPart.includes('?')) { 
            const parts = hashPart.split('/');
            return parts[1];
        }
    }
    return undefined;
  }
  getConfigData() {
    if(this.isMatrixData){
      window.location.href=this.url;
    }
    else{
    let url1 ='';
    url1 = window.location.href; // Fetch the current URL
    let active =''
    active = this.getTitleFromUrl(url1);
    if (active) {
      if(active == "dashboard"){
        this.router.navigate(['./dashboard']);
      }
      else if(active == "favorites"){
        this.router.navigate(['./my-favorites']);
      }
      else if(active== "myReports"){
        this.router.navigate(['./my-reports']);
      }
      else if(active== "report"){
        this.router.navigate(['./my-reports']);
      }
      else if(active== "reportInbox"){
        this.router.navigate(['./my-report-inbox']);
      }
      else if(active== "scheduleReport"){
        this.router.navigate(['./scheduled-reports']);
      }
      else if(active == "buildReport"){
        this.router.navigate(['./build-report']);
      }
      else if(active == "adhoc"){
        this.router.navigate(['./adhoc']);
      }
      else if(active == "document"){
        this.router.navigate(['./document']);
      }
      else if(active == "dataDiscovery"){
        this.router.navigate(['./discovery']);
      }
      else if(active== "scheduleHistory"){
        this.router.navigate(['./schedule-history']);
      }
      else if(active== "payrollValidation"){
        this.router.navigate(['./payrollValidation']);
      }
      else if(active== "payroll"){
        this.router.navigate(['./payroll']);
      }
      if(active == "Config"){
        this.router.navigate(['./Config']);
      }
      else if(active == "Permission"){
        this.router.navigate(['./Permission']);
      }
      else if(active== "Data Analytic Dashboard"){
        this.router.navigate(['./data-analytics']);
      }
      else if(active== "widget-designer"){
        this.router.navigate(['./dashboard-designer']);
      }
      else if(active== "report-catalog"){
        this.router.navigate(['./report-catalog']);
      }
      else if(active== "self-service"){
        this.router.navigate(['./self-service']);
      }
      else if(active== "Manage Security"){
        this.router.navigate(['./admin-security']);
      }
      else if(active== "settings"){
        this.router.navigate(['./settings']);
      }
      else if(active== "help"){
        this.router.navigate(['./help']);
      }
      else if(active== "home"){
        this.router.navigate(['./home']);
      }
      // }
      else if (active =="login"){
        this.appComponentService.config().subscribe({
          next: (res: any) => {
            if ((res.appLandingPage == "LandingPage") || !(res.hasOwnProperty('appLandingPage'))) {
              this.router.navigate(['./home']);
            }
            else if (res.appLandingPage == "dashboard") {
              this.router.navigate(['./dashboard']);
            }
            else if (res.appLandingPage == "myFavorites") {
              this.router.navigate(['./my-favorites']);
            }
            else if (res.appLandingPage == "myReports") {
              this.router.navigate(['./my-reports']);
            }
            else if (res.appLandingPage == "myInbox") {
              this.router.navigate(['./my-report-inbox']);
            }
            else if (res.appLandingPage == "scheduleReport") {
              this.router.navigate(['./scheduled-reports']);
            }
            this.storeConfigDetails = res;
            this.appComponentService.configMessage(this.storeConfigDetails);
            localStorage.setItem('isTourEnabled', res.guidedTour)
            // localStorage.setItem('active', res.body?.appLandingPage)
          },
          error: (err) => {
          }
        })
      }
    }
    else {
      this.appComponentService.config().subscribe({
        next: (res: any) => {
            if ((res.appLandingPage == "LandingPage") || !(res.hasOwnProperty('appLandingPage'))) {
              this.router.navigate(['./home']);
            }
            else if (res.appLandingPage == "dashboard") {
              this.router.navigate(['./dashboard']);
            }
            else if (res.appLandingPage == "myFavorites") {
              this.router.navigate(['./my-favorites']);
            }
            else if (res.appLandingPage == "myReports") {
              this.router.navigate(['./my-reports']);
            }
            else if (res.appLandingPage == "myInbox") {
              this.router.navigate(['./my-report-inbox']);
            }
            else if (res.appLandingPage == "scheduleReport") {
              this.router.navigate(['./scheduled-reports']);
            }
          
          this.storeConfigDetails = res;
          this.appComponentService.configMessage(this.storeConfigDetails);
          let color=res.defaultTheme?res.defaultTheme:'#292f3e';
          d3.select("#tibo-min-leftpanel-container").style("background-color", color)
          d3.select("#tibo-large-leftpanel-container").style("background-color", color)
          localStorage.setItem('isTourEnabled', res.guidedTour)
        },
        error: (err) => {
        }
      })
    }
  }
  }
  getUserRoleData(id) {
    this.appComponentService.getRoleDetails(id).subscribe({
      next: (res) => {
        this.storeUserRoleDetails = res;
        this.isLoading = false;
        this.appComponentService.userRoleMessage(this.storeUserRoleDetails);
      },
      error: (err) => {
        this.isLoading = false;
      }
    })
  }
  // if (this.isMatrixData) {
        //   window.location.href=this.url;
        //    this.activatedRoute.fragment.subscribe((fragment: string | null) => {
        //    if (fragment) {
        //      const params = new URLSearchParams(fragment);
        //      const paramObj: any = {}; 
        //      params.forEach((value, key) => {
        //        paramObj[key] = value;
        //      });
        //      this.isMatrixData = paramObj.isMatrix === 'true';
        //      this.params = paramObj;
        //      this.router.navigate(['/view'], { queryParams: this.params });
        //        }
        //    });
        //  } else {
        //    this.getConfigData();
        //  }

         // this.activatedRoute.fragment.subscribe((fragment: string | null) => {
          // if (fragment) {
          //   const params = new URLSearchParams(fragment);
          //   const paramObj: any = {}; 
          //   params.forEach((value, key) => {
          //     paramObj[key] = value;
          //   });
          //   this.isMatrixData = paramObj.isMatrix === 'true';
          //   this.params = paramObj;
          //   this.router.navigate(['/view'], { queryParams: this.params });
          //     }
          // });
          // const hash = window.location.hash; // "#/view?id=176441&type=birt&..."
          // const queryString = hash.split('?')[1]; // "id=176441&type=birt&..."
        
          // if (!queryString) return {};
        
          // const queryParams = new URLSearchParams(queryString);
          // const paramsObject: { [key: string]: string } = {};
        
          // queryParams.forEach((value, key) => {
          //   paramsObject[key] = value;
          // });
        
          // console.log(paramsObject);
}
