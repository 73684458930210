import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { map } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable()
export class LoginService {
    
  constructor(private http: HttpClient,private router: Router) { }

    tokenService(body: any) {
		return this.http.post('api/user/authenticate', body, 
        { headers: this.getHeaders() }).pipe(map(
            (response) => {
                return response;
            }
        ))
    }
	
	loggedIn() {
		// return !!localStorage.getItem("token");
        const token = localStorage.getItem('token');
        const tokenExpiry = localStorage.getItem('tokenExpiration'); // Store expiry when setting the token
        if (token && tokenExpiry) {
          const now = new Date().getTime();
          if (now < +tokenExpiry) {
            return true;
          } else {
            // Token expired, remove it
            // this.logout();
            const matrixBool=localStorage.getItem("isMatrix")
            if(matrixBool){
              window.location.href = window.location.origin+'/logout/';
              localStorage.removeItem('token');
              localStorage.removeItem('tokenExpiration');
             
            }
            else{
              this.router.navigate(['./login']);
              localStorage.removeItem('token');
              localStorage.removeItem('tokenExpiration');
              // Redirect to login page after logging out
            }
              
            return false;
          }
        }
        return false;
	}
	logout() {
    const matrixBool=localStorage.getItem("isMatrix")
			if(matrixBool){
        window.location.href = window.location.origin+'/logout/';
        localStorage.removeItem('token');
        localStorage.removeItem('tokenExpiration');
       
      }
      else{
        this.router.navigate(['./login']);
        localStorage.removeItem('token');
        localStorage.removeItem('tokenExpiration');
        // Redirect to login page after logging out
      }
        
      }
    config() {
        return this.http.get('api/v1/user/config', { headers: {'X-Auth-Token': localStorage.getItem("token")}, observe: 'response' })
    }

    getHeaders() {
        let headers = new HttpHeaders();
        headers.append('Accept', 'application/json');
        headers.append('Access-Control-Allow-Origin', '*');
        return headers;
    }

}