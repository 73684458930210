import { ChangeDetectorRef, Component, ElementRef, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DiscoveryService } from './../discovery/discovery.service';
import { Subscription } from 'rxjs';
import { MenuItem, Message, MessageService } from 'primeng/api';
import { ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { AppComponentService } from '../app-component.service';
import { TourGuideService } from '../tour-guide.service';
declare var d3: any;
declare var acinfotech: any;
interface City {
  name: string;
  code: string;
}
interface DashboardInfo {
  name: string;
  code: string;
}
@Component({
  selector: 'app-discovery-clone',
  templateUrl: './discovery-clone.component.html',
  styleUrls: ['./discovery-clone.component.css'],
  providers: [DiscoveryService],

})
export class DiscoveryCloneComponent implements OnInit {
  @ViewChild('chart1', { static: false }) chart1: ElementRef;
  reports: any = [];
  dashboards: any = [];
  widgets: any = [];
  ref: DynamicDialogRef;
  domainsArray = [];
  selectedDomain: any;
  businessMetricText = '';
  selectedCity: City;
  tempObj = {};
  repomatricAndWidgetsArray = [];
  shareDashDialBoxDisplayBool: boolean = false;
  alertMessage: Message[];
  second: boolean = false;
  sec: boolean = false;
  first: boolean = true;
  topbar: boolean = true;
  widgetdash: boolean = false;
  maindash: boolean = true;
  headerbtn: boolean = false;
  shareDashUserNamesArr: any;
  shareDashSuccMsg: string;
  sideMenuCollapse: boolean = false;
  showLoading: boolean = true;
  isLoading!: boolean;
  widgetsList: any = [];
  srchResDashNames: any = [];
  folderResp: any;
  responseData1: any;
  responseData2: any;
  responseData3: any;
  selectedWidget: { name: string; code: any; };
  dashCode: any;
  DashboardContainer: boolean = false;
  search: any = false;
  showR!: boolean;
  showW!: boolean;
  showD!: boolean;
  reportCount: number = 0;
  widgetCount: number = 0;
  dashCount: number = 0;
  questions: any;
  ValueAvailable: boolean = false;
  lists: any;
  sampleChartData: any = {};
  isListening: boolean = false;
  spokenText: string;
  recognition: any;
  questions1: any;
  call: boolean = false;
  showQuestionsArea: boolean = false;
  showPanel: boolean = true;
  // value: { report: any; widget: any; dashboard: any; text: string; };
  reportCall: boolean = false;
  dashCall: boolean = false;
  dashboardSelected: boolean = false;
  reportSelected: boolean = false;
  showError: boolean = false;
  load: boolean = false;
  isDash: boolean = false;
  isReport: boolean = false;
  isBuild: boolean = false;
  isSchedule: boolean = false;
  trackerPayload: any = {
    autoCompleteId: "0",
    autoCompleteResult: "0",
    searchCategory: "",
    searchId: "0",
    searchResult: "",
    searchControlMasterId: "0",
    clicode: "0"
  };
  showB!: boolean;
  buildReport: any = [];
  buildReportSelected: boolean = false;
  scheduleSelected: boolean = false;
  showResult: boolean = false;
  loader: boolean = false;
  title: any = '';
  totalResult: any;
  totalResult1: any;
  originalApi: any;
  oneValue: boolean = false;
  multiValue: boolean = false;
  colData = [];
  showReleventResult: boolean = false;
  showFaqResult: boolean = false;
  showBoxes: boolean = false;
  questions2: any;
  loader1: boolean = false;
  loader2: boolean = false;
  legendvalue: any;
  seriesVal: any;
  wigetBackgroundColor: any;
  backColor: string;
  value = 1;
  showResult1: boolean = false;
  loader4: boolean = false;
  errorMessage: boolean = false;
  showCommentsDialog: boolean;
  commentName: string;
  loaderShow: boolean = false;
  showParameters: boolean = false;
  id: any;
  widgetDetail: any;
  payloadParameter: any[];
  chartShow1: boolean = false;
  sampleChartData2: { id: any; type: string; name: any; showReport: boolean; searchMetric: any; };
  sampleChartData4: { reportId: any; filter: any; name: any; searchMetric: any; component: string; };
  sampleChartData1: { dashboardname: any; dashboardcode: any; containerbool: boolean; searchMetric: any; component: string; type: any; };
  sampleChartData5: { reportId: any; type: any; name: any; id: number; showReport: boolean; searchMetric: string; };
  code: any;
  clients: any[];
  clientListIsLoading: boolean = false;
  selectedClient: any = '';
  showerror: boolean = false;
  showEmpty: boolean = false;
  showSearch: boolean=false;
  disableMike: boolean=false;


  constructor(
    public activatedRoute: ActivatedRoute,
    private messageService: MessageService,
    private http: HttpClient,
    public dialogService: DialogService,
    private discoveryService: DiscoveryService,
    private appCompService: AppComponentService,
    private router: Router,
    private cdRef: ChangeDetectorRef,
    private tourGuideService: TourGuideService
    // private dashService: DashboardService
  ) {
    const SpeechRecognition = (window as any).SpeechRecognition || (window as any).webkitSpeechRecognition;
    if (!SpeechRecognition) {
      console.warn("Speech recognition is not supported in this browser.");
      this.messageService.add({
        severity: 'warn',
        summary: 'Browser Not Supported',
        detail: 'Speech recognition is only supported in certain browsers, such as Chrome.',
      });
      this.disableMike=true
      return;
    }
    this.recognition = new SpeechRecognition();
    this.recognition.continuous = true;
    this.recognition.interimResults = true;
    this.disableMike=false;
  }

  selected = {
    color: '#3580db',
  };
  resultsBool: boolean = false;
  dataDiscoverySubscrip: Subscription;
  clientDiscoverySubscrip: Subscription;
  reportsAndMatricsSubscrip: Subscription;
  dashboardWidgetsSubscrip: Subscription;
  widgetContainerBool: boolean = false;
  dashboardInfo: DashboardInfo[];
  selectedDashboard: DashboardInfo;
  errorMsg = '';
  dashboardName: string = '';
  totalWidgets = [];
  eachWidgetRecords = [];
  randomIdArray = [];
  parameterizedDashboardRandomArray = [];
  tempArray = [];
  dashboardRecords = [];
  totalRows = [];
  totalColumns = [];
  rowIndex = [];
  finalCallCounter = 0;
  parameterElementsArray = [];
  parameterElementsDataArray = [];
  parameterizedFilterArray = [];
  filterPanelToggleBool: boolean = false;
  requestPayloadParametersArray = [];
  userParameterObject = {};
  requestPayloadParameterObj = {
    filterType: 'and',
    filters: [],
    sort: [],
    parameters: [],
  };
  defaultValuesOfParameterArray = [];
  cascadeParametersObject = {};
  dashboardsListSubscrip: Subscription;
  defaultDashboardSubscrip: Subscription;
  getADashboardSubscrip: Subscription;
  getWidgetDataSubscrip: Subscription;
  getPrmDashItmRecsSubscrip: Subscription;
  getPrmDashCasRecsCalSubscrip: Subscription;
  getPrmDashCasRecsMultSubscrip: Subscription;
  getPrmDashCasRecsDropSubscrip: Subscription;
  getPrmDashCasRecsRadioSubscrip: Subscription;
  getPrmDashWidgetDataSubscrip: Subscription;
  usersNameSubscrip: Subscription;
  shareDashSubscrip: Subscription;
  searchSubscription: Subscription;
  dashSubscrip: Subscription;
  viewDashboardsList = [];
  dashboardRefreshTime: any;
  refreshCountDownTimerStr: string = '';
  refreshInterval;
  countDownLabel: any;
  folderPanelToggleBool: boolean = false;
  // dashboardInfoObject: any;
  selectedChartsColorSet = 'one';
  moreItems: MenuItem[];
  dashboardInfoObject: any;
  secondpanel = false;
  widget = true;
  report = false;
  display: boolean = false;
  texts: string[];
  results: string[];
  usersArray = [];
  values2: string[];
  shareDashTabIndex = 0; // setting default value as 0 (1st tab) for share dashboard modal window's tab
  selectedItemType = '';
  categories: any[];
  selectedCategories: string[] = [];
  dashboardModeBool: boolean = false;
  dashMaxAndMinBtn: string = 'Full Screen Mode';
  dashMaxAndMinBtnTTip: string = 'Maximize';
  parametersSubscrip: Subscription;
  showOrHideSeriesBool = true;
  showOrHideLegendBool = true;
  shareDashSrchUserNamesArr: string[];
  shareDashEmailIdsArr = [];
  requestPayload: any;
  paginatorPageNo: number = 1;
  showQuestions: boolean = false;
  myText: any;
  chartShow: boolean = false;
  searchBar: boolean = false;
  SearchLabel: boolean = false
  isWriting: boolean = false;
  isWritingText: any;
  selectedTabIndex: any = 0;

  ngOnInit() {
    this.requestPayload = { "sort": [], "filters": [], "filterType": "and", "parameters": [{ "name": "User", "value": localStorage.userEmail, "questionId": 1234567890, "id": 1234567890, "isVisible": "Y" }] }
    this.legendvalue = 'block';
    this.seriesVal = 'block';
    this.backColor = '#d9d9d9';
    this.sampleChartData = {}
    this.questions1 = [];
    this.isWriting = false;
    this.SearchLabel = true;
    this.searchBar = true;
    this.chartShow = false;
    this.chartShow1 = false;
    this.myText = '';
    this.spokenText = '';
    this.showQuestions = false;
    this.clients = [];
    this.clientListIsLoading = true;
    this.clientDiscoverySubscrip = this.discoveryService.getClient().subscribe((res: any) => {
      this.clientListIsLoading = false;
      this.clients = res.body;
      if (this.clients.length == 1) {
        this.selectedClient = this.clients[0].clientCode;
        this.showSearch=true;
      }
      setTimeout(() => {
        this.tourGuideService.startTour('discovery');
      }, 500)
      // this.clients?.unshift({
      //   userId: '0',
      //   clientCode: '-1',
      //   clientName: 'ALL',
      // });
    },
      (error) => {
        this.clientListIsLoading = false;
        this.clients = [];
      },);
    
  }

  ngOnDestroy() {
    if (this.dataDiscoverySubscrip) {
      this.dataDiscoverySubscrip.unsubscribe();
    }
    if (this.reportsAndMatricsSubscrip) {
      this.reportsAndMatricsSubscrip.unsubscribe();
    }
    if (this.dashboardWidgetsSubscrip) {
      this.dashboardWidgetsSubscrip.unsubscribe();
    }
    if (this.getADashboardSubscrip) {
      this.getADashboardSubscrip.unsubscribe();
    }
    if (this.getWidgetDataSubscrip) {
      this.getWidgetDataSubscrip.unsubscribe();
    }
    if (this.clientDiscoverySubscrip) {
      this.clientDiscoverySubscrip.unsubscribe();
    }
  }
  toggleListening(): void {
    this.isListening = !this.isListening;

    if (this.isListening) {
      this.myText = '';
      this.startListening();
    } else {
      this.stopListening();
      this.spokenText = "";
      this.isWriting = true;
    }
  }
  startListening(): void {
    this.showBoxes = false;
    this.questions = [];
    this.spokenText = '';
    this.isWriting = false;
    // this.placeHolderValue = '';
    this.recognition.start();
    this.recognition.onresult = (event: any) => {
      const transcript = event.results[event.results.length - 1][0].transcript;
      this.spokenText = transcript.trim();
      this.myText = this.spokenText;

      // // Filter suggestions based on spoken text
      // this.filteredItems = this.Suggestions.filter(item =>
      // item.question.toLowerCase().includes(this.spokenText.toLowerCase())
      // );
      // Trigger change detection
      this.detectChanges();
      this.myText = this.spokenText;
      this.onSearchBtnClick1();
    };

  }
  onSearchBtnClick1() {
    this.showQuestionsArea = false;
    this.showBoxes = false;
    this.chartShow = false;
    this.questions = [];
    if (this.myText == '') {
      this.questions = []
    }
    else {
      this.discoveryService.dashboardsSearchService(this.myText).subscribe((res: any) => {
        //console.log(res)
        if (res.length > 0) {
          this.isWriting = true
          this.SearchLabel = false;
          this.showQuestions = true;
          this.questions = res;
        }
        else {
          this.questions = [];
          // this.trackerPayload.autoCompleteResult= this.myText;
        }
      })
    }
  }
  onSearchBtnClick2(item) {
    this.isWriting = false;
    this.showQuestionsArea = true;
    this.showQuestions = true;
    this.chartShow = false;
    this.trackerPayload = {}
    this.questions1 = [];
    if (this.myText == '') {
      this.questions1 = [];
    }
    else {
      this.trackerPayload.autoCompleteId = item.autoCompleteId;
      this.trackerPayload.autoCompleteResult = item.autoCompleteResult;
      this.myText = item.autoCompleteResult;
      this.oldApi("auto",item.autoCompleteId);
    }
    // else {
    //   this.myText = item;
    //   let payload = {
    //     'mytext': item
    //   }
    //   this.load = true;
    //   let result = this.questions.filter(e => e.autoCompleteResult === item);
    //   if(result.length>0){
    //   this.trackerPayload['autoCompleteId'] = result[0]['autoCompleteId'];
    //   this.trackerPayload['autoCompleteResult']  = result[0]['autoCompleteResult'];
    //   }
    //   else {
    //     this.trackerPayload.autoCompleteResult = this.myText;
    //   }
    //   // this.discoveryService.dashboardsSearchEngine(item.autoCompleteResult).subscribe((res:any) => {
    //   this.discoveryService.dashboardsSearchEngine(payload).subscribe((res: any) => {
    //     if (res?.body?.length > 0) {
    //       this.showError = false;
    //       this.load = false;
    //       this.showError = false;
    //       this.isWriting = false
    //       //this.SearchLabel = false;
    //       this.showQuestions = true;
    //       this.questions1 = res?.body?.filter(item=>item.searchCategory!= 'grid');
    //     }
    //     // if (res.length > 0) {
    //     //   this.load=false;
    //     //   this.showError=false;
    //     //   this.isWriting=false
    //     //   //this.SearchLabel = false;
    //     //   this.showQuestions = true;
    //     //   this.questions1 = res;
    //     // }
    //     else {
    //       this.showError = true;
    //       this.load = false;
    //         this.discoveryService.tracker(this.trackerPayload).subscribe((res:any)=>{
    //         })
    //     }
    //   }
    //     ,
    //     (error) => {
    //       if (Number(error.status) == 401) alert('Please Try After Some Time!');
    //       else if (Number(error.status) == 500) {
    //         // alert('Internal Error ');
    //         this.questions1 = []
    //         this.showError = true;
    //         this.discoveryService.tracker(this.trackerPayload).subscribe((res:any)=>{
    //         })
    //       }
    //       this.load = false;
    //     },
    //   )
    // }
  }
  stopListening(): void {
    this.recognition.stop();
    this.isListening = false;
    this.isWriting = true;
  }
  detectChanges(): void {
    this.cdRef.detectChanges();
  }
  gotoCharts(quest) {
    this.loader = true;
    this.showResult = false;
    let data = { dashboardname: quest.searchResult, dashboardcode: quest.searchId, containerbool: true, searchMetric: this.myText, component: 'discovery', type: quest.searchCategory }
    this.sampleChartData = { ...data }
    // this.showResult=true;
    this.loader = false;
  }
  backToSearch1() {
    this.showPanel = true;
    this.isLoading = false;
    this.chartShow1 = false;
    this.getDashboard(this.id);
    // this.getParameterDashboard();
  }
  oldApi(value,ids) {
    if (this.selectedClient == '' || this.selectedClient == '' || this.selectedClient == undefined) {
      this.errorMsg = "Please Select Client"
      this.addSingle();
    }
    else {
      if (value == 'enter') {
        setTimeout(() => {
          this.questions = []
          this.isWriting = false;
          this.isListening = false;
          this.trackerPayload = {};
          this.trackerPayload.autoCompleteId = "0";
          this.trackerPayload.autoCompleteResult = this.myText;
        }, 300);
      }
      this.isWriting = false;
      this.isListening = false;
      this.showBoxes = true;
      this.showResult1 = false;
      this.showResult = false;
      this.questions = [];
      this.totalResult = '';
      this.totalResult1 = '';
      this.chartShow = false;
      this.loader = true;
      this.id = undefined;
      if (this.myText == '') {
        this.showBoxes = false;
        this.showResult = false;
        this.loader = false;
        this.loader4 = false;
        this.showReleventResult = false;
        this.showFaqResult = false;
        this.showResult1 = false;
        return;
      }
      else {
        this.showResult = false;
        let payload = {
          'mytext': this.myText
        }
        let payload1 = {
          'queryString': this.myText
        }
        this.originalApi = [];
        this.oneValue = false;
        this.multiValue = false;
        this.colData = [];
        this.questions2 = [];
        this.showReleventResult = false;
        this.showFaqResult = false;
        this.loader4 = true;
        this.showerror = false;
        // this.loader = true;
        this.loader1 = true;
        this.loader2 = true;
        this.errorMessage = false;
        this.loaderShow = false;
        this.showParameters = false;
        // d3.select('#showResult').style('display', 'none');
        // d3.select('#showResult1').style('display', 'block');
        this.dataDiscoverySubscrip = this.discoveryService.relevantResult(payload).subscribe((res: any) => {
          this.isWriting = false;
          this.isListening = false;
          if (res?.body?.length > 0) {
            this.showReleventResult = true;
            this.showResult1 = true;
            this.questions1 = res?.body?.filter(item => item.searchResult != "0");
            // this.questions= res?.body?.filter(item=>item.searchCategory== 'Widget');
            var array = [];
            if (this.questions1.length > 0) {
              this.questions1.forEach(item => {
                if (item.searchScore > 0.95) {
                  array.push(item);
                }
              })
              if (array.length > 0) {
                this.loader = false;
                this.loader1 = false;
                this.showEmpty = false;
                this.id = array[0].searchId;
                this.trackerPayload.searchCategory = array[0].searchCategory;
                this.trackerPayload.searchId = array[0].searchId;
                this.trackerPayload.searchResult = array[0].searchResult;
                this.trackerPayload.searchControlMasterId = array[0].searchControlMasterId;
                if (this.clients.length > 0) {
                  this.trackerPayload.clicode = this.selectedClient;
                }
                this.getDashboard(this.id);
              }
              else {
                this.loader = false;
                this.loader1 = false;
                this.showEmpty = true;
                this.trackerPayload.autoCompleteId = "0";
                this.trackerPayload.autoCompleteResult = this.myText;
                this.trackerPayload.searchCategory = "";
                this.trackerPayload.searchId = "0";
                this.trackerPayload.searchResult = "";
                this.trackerPayload.searchControlMasterId = "0";
                if (this.clients.length > 0) {
                  this.trackerPayload.clicode = this.selectedClient;
                }
                this.discoveryService.trackerSearch(this.trackerPayload, "DataDiscovery").subscribe((res: any) => {
                })
              }
            }
            else {
              this.loader = false;
              this.loader1 = false;
              this.showEmpty = true;
              this.trackerPayload.autoCompleteId = "0";
              this.trackerPayload.autoCompleteResult = this.myText;
              this.trackerPayload.searchCategory = "";
              this.trackerPayload.searchId = "0";
              this.trackerPayload.searchResult = "";
              this.trackerPayload.searchControlMasterId = "0";
              if (this.clients.length > 0) {
                this.trackerPayload.clicode = this.selectedClient;
              }
              this.discoveryService.trackerSearch(this.trackerPayload, "DataDiscovery").subscribe((res: any) => {
              })
            }
          }
          else {
            this.loader = false;
            this.loader1 = false;
            this.questions1 = [];
            this.questions = [];
            this.showEmpty = true;
            this.trackerPayload.searchCategory = "";
            this.trackerPayload.searchId = "0";
            this.trackerPayload.searchResult = "";
            this.trackerPayload.searchControlMasterId = "0";
          }
        },
          (error) => {

            this.loader1 = false;
            this.loader = false;
          })
        this.reportsAndMatricsSubscrip = this.discoveryService.relevantResult1(payload,ids).subscribe((res: any) => {
          if (res?.body?.length > 0) {
            this.questions2 = res?.body.filter(item =>item.searchResult != "0");
            if (this.questions2.length > 0) {
              this.questions2.sort((a, b) => b.searchScore - a.searchScore);
              this.showerror = false;
              this.showReleventResult = true;
            }
            else {
              this.questions2 = [];
              this.showReleventResult = false;
              this.showerror = true;
            }
          }
          else {
            this.showReleventResult = false;
            this.showerror = true;
            this.questions2 = [];
            this.trackerPayload.autoCompleteId = "0";
            this.trackerPayload.autoCompleteResult = this.myText;
            this.trackerPayload.searchCategory = "";
            this.trackerPayload.searchId = "0";
            this.trackerPayload.searchResult = "";
            this.trackerPayload.searchControlMasterId = "0";
            if (this.clients.length > 0) {
              this.trackerPayload.clicode = this.selectedClient;
            }
            this.discoveryService.trackerSearch(this.trackerPayload, "DataDiscovery").subscribe((res: any) => {
            })
          }
        },
          error => {
            this.showReleventResult = false;
            this.questions2 = [];
            this.showerror = true;
          })
      }
    }
  }
  // gotoText(quest) {
  //   this.myText = quest;
  //   this.oldApi();
  // }
  getDashboard1(quest) {
    this.showPanel = false;
    //console.log(quest)
    this.chartShow1 = true;
    let type1 = 'birt';
    if (quest.searchCategory == 'Report') {
      if (quest.searchIsRunnable == "Y") {
        if (quest.searchResult.toLowerCase().includes('.sql')) {
          type1 = 'sql';
        }
        if (quest.searchResult.toLowerCase().includes('.rptdesign')) {
          type1 = 'birt';
        }
        this.sampleChartData2 = { id: quest.searchId, type: type1, name: quest.searchResult, showReport: true, searchMetric: this.myText }
        this.scheduleSelected = false;
        this.dashboardSelected = false;
        this.reportSelected = true;
        // this.reportCall = true;
        // this.dashCall = false;
        this.buildReportSelected = false;
      }
      else if (quest.searchIsRunnable == "N" && quest.searchIsSchedulable == "Y") {
        this.sampleChartData5 = { reportId: quest.searchId, type: undefined, name: quest.searchResult, id: 1, showReport: true, searchMetric: this.myText }
        this.scheduleSelected = true;
        this.dashboardSelected = false;
        this.reportSelected = false;
        this.buildReportSelected = false;
      }
      else if (quest.searchIsRunnable == "N" && quest.searchIsSchedulable == "N") {
        this.messageService.add({
          severity: "error",
          summary: '',
          detail: "Report Is Not Runnable Or Schedulable",
        });
      }
    }
    if (quest.searchCategory == 'Dashboard' || quest.searchCategory == 'Widget' || quest.searchCategory == 'grid') {
      let category=quest.searchCategory=='grid'?'Widget':quest.searchCategory;
      this.dashboardSelected = true;
      this.scheduleSelected = false;
      this.buildReportSelected = false;
      this.reportSelected = false;
      this.DashboardContainer = true;
      this.widgetContainerBool = false;
      this.headerbtn = true;
      this.sampleChartData1 = { dashboardname: quest.searchResult, dashboardcode: quest.searchId, containerbool: true, searchMetric: this.myText, component: 'discovery', type: category.toLowerCase() }
    }
    if (quest.searchCategory == 'Build A Report') {
      this.sampleChartData4 = { reportId: quest.searchDomainId, filter: quest.searchId, name: quest.searchResult, searchMetric: this.myText, component: 'discovery' }
      this.scheduleSelected = false;
      this.dashboardSelected = false;
      this.reportSelected = false;
      this.buildReportSelected = true;
    }
    this.trackerPayload.searchCategory = quest.searchCategory;
    this.trackerPayload.searchId = quest.searchId;
    this.trackerPayload.searchResult = quest.searchResult;
    this.trackerPayload.searchControlMasterId = quest.searchControlMasterId;
    this.trackerPayload.clicode = "";
    this.discoveryService.trackerSearch(this.trackerPayload, "DataDiscovery").subscribe((res: any) => {
    })
  }

  getDashboard(id) {
    // this.loader = true;
    this.showResult1 = false;
    this.loaderShow = false;
    this.showParameters = false;
    acinfotech.requestType(true);
    this.showResult = true;
    var chartElement = d3.select('#chart1');
    chartElement.html('');
    chartElement.style('height', '390px')
      .style('min-width', '350px')
      .style('min-height', '350px')
      .style('position', 'relative');
    this.parameterElementsDataArray = [];
    this.parameterElementsDataArray = [];
    this.parameterElementsArray = [];
    this.requestPayloadParametersArray = [];
    this.widgetDetail = [];
    if (id != undefined) {
      this.getADashboardSubscrip = this.discoveryService.getWidget(id).subscribe((res: any) => {
        //console.log(res.body);
        if (res.body.widgetControls && res.body.widgetControls.length > 0) {
          this.payloadParameter = [];
          res.body.dashboardRequestorParameters?.forEach((param) => {
            this.userParameterObject = {
              id: param.dashboardParameterId,
              isVisible: 'Y',
              name: 'User',
              questionId: 0,
              value: localStorage.userEmail,
            };
            this.payloadParameter.push(this.userParameterObject);
            this.payloadParameter.push(
              {
                "name": "User",
                "value": localStorage.userEmail,
                "questionId": 1234567890,
                "id": 1234567890,
                "isVisible": "Y"
              })
            let obj = {
              id: param.dashboardParameterId,
              isVisible: param.isVisible,
              name: param.parameterName,
              questionId: param.questionOrderBy,
              value: this.selectedClient,
            }
            this.payloadParameter.push(obj)
            this.showParameters = false;
            this.requestPayloadParametersArray = this.payloadParameter;
            this.widgetDetail = res.body?.widgetControls[0];
            this.onFilterSubmitClick();
          })
          // res.body.dashboardRequestorParameters?.forEach((parameter) => {
          //   if (parameter.parameterName !== 'User') {
          //     this.requestPayloadParametersArray.push({
          //       id: parameter.dashboardParameterId,
          //       isVisible: parameter.isVisible,
          //       name: parameter.parameterName,
          //       questionId: parameter.questionOrderBy,
          //       value: '',
          //     });
          //   }
          //   this.parameterElementsArray.push(parameter);
          // })

          // this.widgetDetail = res.body?.widgetControls[0];
          // if (res.body?.dashboardRequestorParameters?.length == 0) {
          //   // var chartElement = d3.select('#chart1');
          //   // chartElement.html('');
          //   this.isLoading = true;
          //   let widget = res.body?.widgetControls[0];
          //   if (widget.controlMasterId == 3) {
          //     this.getWidgetDataSubscrip = this.discoveryService
          //       .getWidgetData(
          //         widget.widgetControlId,
          //         'limit=&isWebservice=N', this.requestPayload
          //       ).subscribe((widgetdetails: any) => {
          //         var chartElement = d3.select('#chart1')
          //           .attr('empty', false);
          //         this.loader = false;
          //         this.table(chartElement, widget, widgetdetails);
          //         this.isLoading = false;
          //         d3.select('#loader' + widget.objectCordinates.replace(',', '')).style('display', 'none');
          //       },
          //         error => {
          //           this.loader = false;
          //           this.isLoading = false;
          //         })
          //   }
          //   else {
          //     this.getWidgetDataSubscrip = this.discoveryService
          //       .getWidgetData(
          //         widget.widgetControlId,
          //         'limit=10', this.requestPayload
          //       ).subscribe((widgetdetails: any) => {
          //         this.loader = false;

          //         if (widget.controlMasterId == 1) {
          //           var chartElement = d3.select('#chart1')
          //             .attr('empty', false);
          //           this.pieChart(chartElement, widget, widgetdetails);
          //           d3.select('#loader' + widget.objectCordinates.replace(',', '')).style('display', 'none');
          //           this.isLoading = false;
          //         } else if (widget.controlMasterId == 2) {
          //           var chartElement = d3.select('#chart1')
          //             .attr('empty', false);
          //           this.columnChart(chartElement, widget, widgetdetails);
          //           d3.select('#loader' + widget.objectCordinates.replace(',', '')).style('display', 'none');
          //           this.isLoading = false;
          //         } else if (widget.controlMasterId == 5) {
          //           /*else if(widget.controlMasterId == 3){
          //       var chartElement = d3.select(".widget-class[objectCordinates='" + widget.objectCordinates + "']")
          //       .attr("empty", false)

          //       this.table(chartElement,widget,widgetdetails)
          //     }*/
          //           var chartElement = d3.select('#chart1')
          //             .attr('empty', false);
          //           this.donutChart(chartElement, widget, widgetdetails);
          //           d3.select('#loader' + widget.objectCordinates.replace(',', '')).style('display', 'none');
          //           this.isLoading = false;
          //         } else if (widget.controlMasterId == 6) {
          //           var chartElement = d3.select('#chart1')
          //             .attr('empty', false);
          //           this.horizontalChart(chartElement, widget, widgetdetails);
          //           d3.select('#loader' + widget.objectCordinates.replace(',', '')).style('display', 'none');
          //           this.isLoading = false;
          //         } else if (widget.controlMasterId == 7) {
          //         } // Waffle chart
          //         else if (widget.controlMasterId == 8) {
          //           var chartElement = d3.select('#chart1')
          //             .attr('empty', false);
          //           this.perGauge(chartElement, widget, widgetdetails);
          //           d3.select('#loader' + widget.objectCordinates.replace(',', '')).style('display', 'none');
          //           this.isLoading = false;
          //         } else if (widget.controlMasterId == 9) {
          //           var chartElement = d3.select('#chart1')
          //             .attr('empty', false);
          //           this.numberGauge(chartElement, widget, widgetdetails);
          //           d3.select('#loader' + widget.objectCordinates.replace(',', '')).style('display', 'none');
          //           this.isLoading = false;
          //         } else if (widget.controlMasterId == 10) {
          //           var chartElement = d3.select('#chart1')
          //             .attr('empty', false);
          //           this.map(chartElement, widget, widgetdetails);
          //           d3.select('#loader' + widget.objectCordinates.replace(',', '')).style('display', 'none');
          //           this.isLoading = false;
          //         } else if (widget.controlMasterId == 11) {
          //           var chartElement = d3.select('#chart1')
          //             .attr('empty', false);
          //           this.columnAndLineChart(chartElement, widget, widgetdetails);
          //           d3.select('#loader' + widget.objectCordinates.replace(',', '')).style('display', 'none');
          //           this.isLoading = false;
          //         } else if (widget.controlMasterId == 12) {
          //           var chartElement = d3.select('#chart1')
          //             .attr('empty', false);
          //           this.lineChart(chartElement, widget, widgetdetails);
          //           d3.select('#loader' + widget.objectCordinates.replace(',', '')).style('display', 'none');
          //           this.isLoading = false;
          //         } else if (widget.controlMasterId == 13) {
          //           var chartElement = d3.select('#chart1')
          //             .attr('empty', false);
          //           this.bubbleChart(chartElement, widget, widgetdetails);
          //           d3.select('#loader' + widget.objectCordinates.replace(',', '')).style('display', 'none');
          //           this.isLoading = false;
          //         } else if (widget.controlMasterId == 14) {
          //           var chartElement = d3.select('#chart1')
          //             .attr('empty', false);
          //           this.stackedHorizontalBarChart(
          //             chartElement,
          //             widget,
          //             widgetdetails
          //           );
          //           d3.select('#loader' + widget.objectCordinates.replace(',', '')).style('display', 'none');
          //           this.isLoading = false;
          //         } else if (widget.controlMasterId == 15) {
          //           var chartElement = d3.select('#chart1')
          //             .attr('empty', false);
          //           this.stackedBarChart(chartElement, widget, widgetdetails);
          //           d3.select('#loader' + widget.objectCordinates.replace(',', '')).style('display', 'none');
          //           this.isLoading = false;
          //         } else if (widget.controlMasterId == 16) {
          //           var chartElement = d3.select('#chart1')
          //             .attr('empty', false);
          //           this.perGaugeWithFlip(chartElement, widget, widgetdetails);
          //           d3.select('#loader' + widget.objectCordinates.replace(',', '')).style('display', 'none');
          //           this.isLoading = false;
          //         } else if (widget.controlMasterId == 17) {
          //           var chartElement = d3.select('#chart1')
          //             .attr('empty', false);
          //           this.squareGauge(chartElement, widget, widgetdetails);
          //           d3.select('#loader' + widget.objectCordinates.replace(',', '')).style('display', 'none');
          //           this.isLoading = false;
          //         } else if (widget.controlMasterId == 18) {
          //           var chartElement = d3.select('#chart1')
          //             .attr('empty', false);
          //           this.bellCurveChart(chartElement, widget, widgetdetails);
          //           d3.select('#loader' + widget.objectCordinates.replace(',', '')).style('display', 'none');
          //           this.isLoading = false;
          //         } else if (widget.controlMasterId == 19) {
          //           var chartElement = d3.select('#chart1')
          //             .attr('empty', false);
          //           this.areaChart(chartElement, widget, widgetdetails);
          //           d3.select('#loader' + widget.objectCordinates.replace(',', '')).style('display', 'none');
          //           this.isLoading = false;
          //         } else if (widget.controlMasterId == 20) {
          //           var chartElement = d3.select('#chart1')
          //             .attr('empty', false);
          //           this.groupedBarChart(chartElement, widget, widgetdetails);
          //           d3.select('#loader' + widget.objectCordinates.replace(',', '')).style('display', 'none');
          //           this.isLoading = false;
          //         } else if (widget.controlMasterId == 21) {
          //           var chartElement = d3.select('#chart1')
          //             .attr('empty', false);
          //           this.horizontalGroupedBarChart(
          //             chartElement,
          //             widget,
          //             widgetdetails
          //           );
          //           d3.select('#loader' + widget.objectCordinates.replace(',', '')).style('display', 'none');
          //           this.isLoading = false;
          //         } else if (widget.controlMasterId == 22) {
          //         } //Horizontal opposite bar Chart
          //         else if (widget.controlMasterId == 23) {
          //         } //Vertical opposite bar Chart
          //         else if (widget.controlMasterId == 24) {
          //           var chartElement = d3.select('#chart1')
          //             .attr('empty', false);
          //           this.dotGauge(chartElement, widget, widgetdetails);
          //           d3.select('#loader' + widget.objectCordinates.replace(',', '')).style('display', 'none');
          //           this.isLoading = false;
          //         } else if (widget.controlMasterId == 25) {
          //         } //Square bubble chart
          //         else if (widget.controlMasterId == 26) {
          //         } //Magic quadrant
          //         else if (widget.controlMasterId == 27) {
          //         } //Funnel chart
          //         else if (widget.controlMasterId == 28) {
          //           var chartElement = d3.select('#chart1')
          //             .attr('empty', false);
          //           this.columnAndLinesChart(chartElement, widget, widgetdetails);
          //           d3.select('#loader' + widget.objectCordinates.replace(',', '')).style('display', 'none');
          //           this.isLoading = false;
          //         } else if (widget.controlMasterId == 29) {
          //           var chartElement = d3.select('#chart1')
          //             .attr('empty', false);
          //           this.threeXAxisesGroupedBarChart(
          //             chartElement,
          //             widget,
          //             widgetdetails
          //           );
          //           d3.select('#loader' + widget.objectCordinates.replace(',', '')).style('display', 'none');
          //           this.isLoading = false;
          //         } else if (widget.controlMasterId == 30) {
          //           var chartElement = d3.select('#chart1')
          //             .attr('empty', false);
          //           this.twoXAxisesGroupedBarChart(
          //             chartElement,
          //             widget,
          //             widgetdetails
          //           );
          //           d3.select('#loader' + widget.objectCordinates.replace(',', '')).style('display', 'none');
          //           this.isLoading = false;
          //         } else if (widget.controlMasterId == 31) {
          //           var chartElement = d3.select('#chart1')
          //             .attr('empty', false);
          //           this.bellCurveChartWithMinAndMaxRange(
          //             chartElement,
          //             widget,
          //             widgetdetails
          //           );
          //           d3.select('#loader' + widget.objectCordinates.replace(',', '')).style('display', 'none');
          //           this.isLoading = false;
          //         } else if (widget.controlMasterId == 32) {
          //           var chartElement = d3.select('#chart1')
          //             .attr('empty', false);
          //           this.stackedGroupedBarChart(
          //             chartElement,
          //             widget,
          //             widgetdetails
          //           );
          //           d3.select('#loader' + widget.objectCordinates.replace(',', '')).style('display', 'none');
          //           this.isLoading = false;
          //         } else if (widget.controlMasterId == 33) {
          //           var chartElement = d3.select('#chart1')
          //             .attr('empty', false);
          //           this.stackedGroupedBarsWithLineChart(
          //             chartElement,
          //             widget,
          //             widgetdetails
          //           );
          //           d3.select('#loader' + widget.objectCordinates.replace(',', '')).style('display', 'none');
          //           this.isLoading = false;
          //         } else if (widget.controlMasterId == 34) {
          //           var chartElement = d3.select('#chart1')
          //             .attr('empty', false);
          //           this.stackedBarWithLinesChart(
          //             chartElement,
          //             widget,
          //             widgetdetails
          //           );
          //           d3.select('#loader' + widget.objectCordinates.replace(',', '')).style('display', 'none');
          //           this.isLoading = false;
          //         } else if (widget.controlMasterId == 35) {
          //           var chartElement = d3.select('#chart1')
          //             .attr('empty', false);
          //           this.trafficLightsChart(chartElement, widget, widgetdetails);
          //           d3.select('#loader' + widget.objectCordinates.replace(',', '')).style('display', 'none');
          //           this.isLoading = false;
          //         } else if (widget.controlMasterId == 36) {
          //           var chartElement = d3.select('#chart1')
          //             .attr('empty', false);
          //           this.columnChartWithNegativeValues(
          //             chartElement,
          //             widget,
          //             widgetdetails
          //           );
          //           d3.select('#loader' + widget.objectCordinates.replace(',', '')).style('display', 'none');
          //           this.isLoading = false;
          //         } else if (widget.controlMasterId == 37) {
          //           var chartElement = d3.select('#chart1')
          //             .attr('empty', false);
          //           this.groupedBarsWithLineChart(
          //             chartElement,
          //             widget,
          //             widgetdetails
          //           );
          //           d3.select('#loader' + widget.objectCordinates.replace(',', '')).style('display', 'none');
          //           this.isLoading = false;
          //         } else if (widget.controlMasterId == 38) {
          //           var chartElement = d3.select('#chart1')
          //             .attr('empty', false);
          //           this.USMapWithPins(chartElement, widget, widgetdetails);
          //           d3.select('#loader' + widget.objectCordinates.replace(',', '')).style('display', 'none');
          //           this.isLoading = false;
          //         }
          //       }, error => {
          //         this.loader = false;
          //         this.isLoading = false;
          //       })
          //   }
          // }
          // else {
          //   var chartElement = d3.select('#chart1');
          //   chartElement.html('');
          //   chartElement.style('height', '390px')
          //     .style('min-width', '350px')
          //     .style('min-height', '350px')
          //     .style('position', 'relative');
          //   this.loaderShow = true;
          //   this.loader = false;
          //   this.isLoading = false;
          //   this.payloadParameter = [];
          //   res.body.dashboardRequestorParameters?.forEach((parameter) => {
          //     if (parameter.parameterName !== 'User') {
          //       this.requestPayloadParametersArray.push({
          //         id: parameter.dashboardParameterId,
          //         isVisible: parameter.isVisible,
          //         name: parameter.parameterName,
          //         questionId: parameter.questionOrderBy,
          //         value: '',
          //       });
          //     }
          //     this.parameterElementsArray.push(parameter);
          //     this.parameterElementsArray.forEach((ele) => {
          //       if (ele.defaultValue != "") {
          //         ele.selectedData = ele.defaultValue;
          //       }
          //       else {
          //         ele['selectedData'] = [];
          //       }
          //     });
          //     this.getPrmDashItmRecsSubscrip = this.discoveryService
          //       .getParameterizedDashboardItemRecords(res?.body?.dashboardRequestorParameters[0]?.authorizationId)
          //       .subscribe(
          //         (data: any) => {
          //           this.loaderShow = false;
          //           this.showParameters = true;
          //           this.parameterElementsDataArray = data.body?.parameterResponse; // 0 service response
          //         },
          //         (error) => //console.log(error),
          //         () => {

          //           this.parameterElementsArray.forEach((ele) => {
          //             if (ele.displayType.toLowerCase() == 'select') {
          //               ele['fetchedRecords'] = {
          //                 paramElement: ele,
          //                 records: null,
          //                 questionOrderBy: ele.questionOrderBy,
          //                 listEle: null,
          //               };
          //             }
          //             ele['fetchedRecordsBool'] = false;

          //             ele['paramElementFilledBool'] = false;

          //             ele['cascadeChildParameters'] = [];

          //             this.parameterElementsDataArray.forEach((rec) => {
          //               if (
          //                 ele.dashboardParameterId == rec.dashboardParameterId
          //               ) {
          //                 if (ele.displayType.toLowerCase() == 'select') {
          //                   if (ele.isVisible == "N") { ele['fetchedRecordsBool'] = false; ele['paramElementFilledBool'] = true; }
          //                   else { ele['fetchedRecordsBool'] = true; ele['paramElementFilledBool'] = false; }
          //                   if (rec.records == null || rec.records.length == 0) {
          //                     if (ele.defaultValue != '') {
          //                       ele.defaultValue.split(',').forEach((defaulVal) => {
          //                         this.defaultValuesOfParameterArray.push({
          //                           [ele.parameterLabel]: defaulVal,
          //                           [ele.parameterValue]: defaulVal,
          //                         });
          //                       });
          //                       ele["fetchedRecords"] = { paramElement: ele, records: { dashboardParameterId: ele.dashboardParameterId, parameterLabel: ele.parameterLabel, parameterValue: ele.parameterValue, records: this.defaultValuesOfParameterArray }, questionOrderBy: ele.questionOrderBy, listEle: this.defaultValuesOfParameterArray }
          //                       ele.selectedData = ele.defaultValue;
          //                       ele['paramElementFilledBool'] = true;
          //                       // ele.selectedData = '';
          //                     }
          //                     else {
          //                       ele.fetchedRecords.records = rec;
          //                       ele.fetchedRecords.listEle = rec.records;
          //                       ele.selectedData = '';
          //                     }

          //                   }
          //                   else {
          //                     if (ele.isVisible == "N") { ele['fetchedRecordsBool'] = false; ele['paramElementFilledBool'] = true; }
          //                     else { ele['fetchedRecordsBool'] = true; ele['paramElementFilledBool'] = false; }
          //                     this.parameterizedFilterArray.push({
          //                       paramElement: ele,
          //                       records: rec,
          //                       questionOrderBy: ele.questionOrderBy,
          //                       listEle: rec.records,
          //                     });

          //                     ele['fetchedRecords'] = {
          //                       paramElement: ele,
          //                       records: rec,
          //                       questionOrderBy: ele.questionOrderBy,
          //                       listEle: rec.records,
          //                     };
          //                   }
          //                 }
          //               }
          //               // }
          //             }); //rec
          //           });
          //           // this.parameterElementsArray.forEach((param, index) => {
          //           //   if (param.displayType === 'Select' && param.clientUserParameter === 'Y' && param.cascadeParameters === '' && param.fetchedRecords?.records?.records?.length > 0 && param.fetchedRecords?.records?.records?.length == 1) {
          //           //     this.requestPayloadParametersArray = []
          //           //     this.payloadParameter = [];
          //           //     param.selectedData = param.fetchedRecords.records.records[0][param.fetchedRecords.records.parameterValue];
          //           //     param.paramElementFilledBool = true;
          //           //     this.trackerPayload.clicode = param.fetchedRecords.records.records[0][param.fetchedRecords.records.parameterValue];
          //           //     this.userParameterObject = {
          //           //       id: param.dashboardParameterId,
          //           //       isVisible: 'Y',
          //           //       name: 'User',
          //           //       questionId: 0,
          //           //       value: localStorage.userEmail,
          //           //     };
          //           //     this.payloadParameter.push(this.userParameterObject);
          //           //     this.payloadParameter.push(
          //           //       {
          //           //         "name": "User",
          //           //         "value": localStorage.userEmail,
          //           //         "questionId": 1234567890,
          //           //         "id": 1234567890,
          //           //         "isVisible": "Y"
          //           //       })
          //           //     let obj = {
          //           //       id: param.dashboardParameterId,
          //           //       isVisible: param.isVisible,
          //           //       name: param.parameterName,
          //           //       questionId: param.questionOrderBy,
          //           //       value: param.fetchedRecords.records.records[0][param.fetchedRecords.records.parameterValue],
          //           //     }
          //           //     this.payloadParameter.push(obj)
          //           //     this.showParameters = false;
          //           //     this.requestPayloadParametersArray = this.payloadParameter;
          //           //     // this.onFilterSubmitClick();
          //           //   }
          //           // });
          //         })
          //   })
          // }
        }
        else {
          this.loader = false;
          this.isLoading = false;
          this.errorMessage = true;
          this.showResult = false;
        }
      },
        error => {
          this.loader = false;
          this.isLoading = false;
        }
      )
    }
  }
  getParameterDashboard() {
    this.isLoading = true;
    this.showResult = true;
    acinfotech.requestType(true);
    this.errorMessage = false;
    var chartElement = d3.select('#chart1');
    chartElement.html('');
    chartElement.style('height', '370px')
      .style('min-width', '350px')
      .style('min-height', '350px')
      .style('position', 'relative');
    let widget = this.widgetDetail;
    if (this.widgetDetail) {
      this.requestPayload.parameters = this.requestPayloadParametersArray
      if (widget.controlMasterId == 3) {
        this.getWidgetDataSubscrip = this.discoveryService
          .getParameterizedDashboardWidgetData(
            widget.widgetControlId,
            'limit=&isWebservice=N', this.requestPayload
          ).subscribe((widgetdetails: any) => {
            var chartElement = d3.select('#chart1')
              .attr('empty', false);
            this.loader = false;
            this.table(chartElement, widget, widgetdetails);
            d3.select('#loader' + widget.objectCordinates.replace(',', '')).style('display', 'none');
            this.isLoading = false;
          },
            error => {
              this.loader = false;
              this.isLoading = false;
            })
      }
      else {
        this.getWidgetDataSubscrip = this.discoveryService
          .getParameterizedDashboardWidgetData(
            widget.widgetControlId,
            'limit=10', this.requestPayload
          ).subscribe((widgetdetails: any) => {
            this.loader = false;

            if (widget.controlMasterId == 1) {

              var chartElement = d3.select('#chart1')
                .attr('empty', false);
              this.pieChart(chartElement, widget, widgetdetails);
              d3.select('#loader' + widget.objectCordinates.replace(',', '')).style('display', 'none');
              this.isLoading = false;
            } else if (widget.controlMasterId == 2) {
              var chartElement = d3.select('#chart1')
                .attr('empty', false);
              this.columnChart(chartElement, widget, widgetdetails);
              d3.select('#loader' + widget.objectCordinates.replace(',', '')).style('display', 'none');
              this.isLoading = false;
            } else if (widget.controlMasterId == 5) {
              /*else if(widget.controlMasterId == 3){
          var chartElement = d3.select(".widget-class[objectCordinates='" + widget.objectCordinates + "']")
          .attr("empty", false)
          
          this.table(chartElement,widget,widgetdetails)
        }*/
              var chartElement = d3.select('#chart1')
                .attr('empty', false);
              this.donutChart(chartElement, widget, widgetdetails);
              d3.select('#loader' + widget.objectCordinates.replace(',', '')).style('display', 'none');
              this.isLoading = false;
            } else if (widget.controlMasterId == 6) {
              var chartElement = d3.select('#chart1')
                .attr('empty', false);
              this.horizontalChart(chartElement, widget, widgetdetails);
              d3.select('#loader' + widget.objectCordinates.replace(',', '')).style('display', 'none');
              this.isLoading = false;
            } else if (widget.controlMasterId == 7) {
            } // Waffle chart
            else if (widget.controlMasterId == 8) {
              var chartElement = d3.select('#chart1')
                .attr('empty', false);
              this.perGauge(chartElement, widget, widgetdetails);
              d3.select('#loader' + widget.objectCordinates.replace(',', '')).style('display', 'none');
              this.isLoading = false;
            } else if (widget.controlMasterId == 9) {

              var chartElement = d3.select('#chart1').attr('empty', false);
              this.cdRef.detectChanges();
              this.numberGauge(chartElement, widget, widgetdetails);
              d3.select('#loader' + widget.objectCordinates.replace(',', '')).style('display', 'none');
              this.isLoading = false;
            } else if (widget.controlMasterId == 10) {
              var chartElement = d3.select('#chart1')
                .attr('empty', false);
              this.map(chartElement, widget, widgetdetails);
              d3.select('#loader' + widget.objectCordinates.replace(',', '')).style('display', 'none');
              this.isLoading = false;
            } else if (widget.controlMasterId == 11) {
              var chartElement = d3.select('#chart1')
                .attr('empty', false);
              this.columnAndLineChart(chartElement, widget, widgetdetails);
              d3.select('#loader' + widget.objectCordinates.replace(',', '')).style('display', 'none');
              this.isLoading = false;
            } else if (widget.controlMasterId == 12) {
              var chartElement = d3.select('#chart1')
                .attr('empty', false);
              this.lineChart(chartElement, widget, widgetdetails);
              d3.select('#loader' + widget.objectCordinates.replace(',', '')).style('display', 'none');
              this.isLoading = false;
            } else if (widget.controlMasterId == 13) {
              var chartElement = d3.select('#chart1')
                .attr('empty', false);
              this.bubbleChart(chartElement, widget, widgetdetails);
              d3.select('#loader' + widget.objectCordinates.replace(',', '')).style('display', 'none');
              this.isLoading = false;
            } else if (widget.controlMasterId == 14) {
              var chartElement = d3.select('#chart1')
                .attr('empty', false);
              this.stackedHorizontalBarChart(
                chartElement,
                widget,
                widgetdetails
              );
              d3.select('#loader' + widget.objectCordinates.replace(',', '')).style('display', 'none');
              this.isLoading = false;
            } else if (widget.controlMasterId == 15) {
              var chartElement = d3.select('#chart1')
                .attr('empty', false);
              this.stackedBarChart(chartElement, widget, widgetdetails);
              d3.select('#loader' + widget.objectCordinates.replace(',', '')).style('display', 'none');
              this.isLoading = false;
            } else if (widget.controlMasterId == 16) {
              var chartElement = d3.select('#chart1')
                .attr('empty', false);
              this.perGaugeWithFlip(chartElement, widget, widgetdetails);
              d3.select('#loader' + widget.objectCordinates.replace(',', '')).style('display', 'none');
              this.isLoading = false;
            } else if (widget.controlMasterId == 17) {
              var chartElement = d3.select('#chart1')
                .attr('empty', false);
              this.squareGauge(chartElement, widget, widgetdetails);
              d3.select('#loader' + widget.objectCordinates.replace(',', '')).style('display', 'none');
              this.isLoading = false;
            } else if (widget.controlMasterId == 18) {
              var chartElement = d3.select('#chart1')
                .attr('empty', false);
              this.bellCurveChart(chartElement, widget, widgetdetails);
              d3.select('#loader' + widget.objectCordinates.replace(',', '')).style('display', 'none');
              this.isLoading = false;
            } else if (widget.controlMasterId == 19) {
              var chartElement = d3.select('#chart1')
                .attr('empty', false);
              this.areaChart(chartElement, widget, widgetdetails);
              d3.select('#loader' + widget.objectCordinates.replace(',', '')).style('display', 'none');
              this.isLoading = false;
            } else if (widget.controlMasterId == 20) {
              var chartElement = d3.select('#chart1')
                .attr('empty', false);
              this.groupedBarChart(chartElement, widget, widgetdetails);
              d3.select('#loader' + widget.objectCordinates.replace(',', '')).style('display', 'none');
              this.isLoading = false;
            } else if (widget.controlMasterId == 21) {
              var chartElement = d3.select('#chart1')
                .attr('empty', false);
              this.horizontalGroupedBarChart(
                chartElement,
                widget,
                widgetdetails
              );
              d3.select('#loader' + widget.objectCordinates.replace(',', '')).style('display', 'none');
              this.isLoading = false;
            } else if (widget.controlMasterId == 22) {
            } //Horizontal opposite bar Chart
            else if (widget.controlMasterId == 23) {
            } //Vertical opposite bar Chart
            else if (widget.controlMasterId == 24) {
              var chartElement = d3.select('#chart1')
                .attr('empty', false);
              this.dotGauge(chartElement, widget, widgetdetails);
              d3.select('#loader' + widget.objectCordinates.replace(',', '')).style('display', 'none');
              this.isLoading = false;
            } else if (widget.controlMasterId == 25) {
            } //Square bubble chart
            else if (widget.controlMasterId == 26) {
            } //Magic quadrant
            else if (widget.controlMasterId == 27) {
            } //Funnel chart
            else if (widget.controlMasterId == 28) {
              var chartElement = d3.select('#chart1')
                .attr('empty', false);
              this.columnAndLinesChart(chartElement, widget, widgetdetails);
              d3.select('#loader' + widget.objectCordinates.replace(',', '')).style('display', 'none');
              this.isLoading = false;
            } else if (widget.controlMasterId == 29) {
              var chartElement = d3.select('#chart1')
                .attr('empty', false);
              this.threeXAxisesGroupedBarChart(
                chartElement,
                widget,
                widgetdetails
              );
              d3.select('#loader' + widget.objectCordinates.replace(',', '')).style('display', 'none');
              this.isLoading = false;
            } else if (widget.controlMasterId == 30) {
              var chartElement = d3.select('#chart1')
                .attr('empty', false);
              this.twoXAxisesGroupedBarChart(
                chartElement,
                widget,
                widgetdetails
              );
              d3.select('#loader' + widget.objectCordinates.replace(',', '')).style('display', 'none');
              this.isLoading = false;
            } else if (widget.controlMasterId == 31) {
              var chartElement = d3.select('#chart1')
                .attr('empty', false);
              this.bellCurveChartWithMinAndMaxRange(
                chartElement,
                widget,
                widgetdetails
              );
              d3.select('#loader' + widget.objectCordinates.replace(',', '')).style('display', 'none');
              this.isLoading = false;
            } else if (widget.controlMasterId == 32) {
              var chartElement = d3.select('#chart1')
                .attr('empty', false);
              this.stackedGroupedBarChart(
                chartElement,
                widget,
                widgetdetails
              );
              d3.select('#loader' + widget.objectCordinates.replace(',', '')).style('display', 'none');
              this.isLoading = false;
            } else if (widget.controlMasterId == 33) {
              var chartElement = d3.select('#chart1')
                .attr('empty', false);
              this.stackedGroupedBarsWithLineChart(
                chartElement,
                widget,
                widgetdetails
              );
              d3.select('#loader' + widget.objectCordinates.replace(',', '')).style('display', 'none');
              this.isLoading = false;
            } else if (widget.controlMasterId == 34) {
              var chartElement = d3.select('#chart1')
                .attr('empty', false);
              this.stackedBarWithLinesChart(
                chartElement,
                widget,
                widgetdetails
              );
              d3.select('#loader' + widget.objectCordinates.replace(',', '')).style('display', 'none');
              this.isLoading = false;
            } else if (widget.controlMasterId == 35) {
              var chartElement = d3.select('#chart1')
                .attr('empty', false);
              this.trafficLightsChart(chartElement, widget, widgetdetails);
              d3.select('#loader' + widget.objectCordinates.replace(',', '')).style('display', 'none');
              this.isLoading = false;
            } else if (widget.controlMasterId == 36) {
              var chartElement = d3.select('#chart1')
                .attr('empty', false);
              this.columnChartWithNegativeValues(
                chartElement,
                widget,
                widgetdetails
              );
              d3.select('#loader' + widget.objectCordinates.replace(',', '')).style('display', 'none');
              this.isLoading = false;
            } else if (widget.controlMasterId == 37) {
              var chartElement = d3.select('#chart1')
                .attr('empty', false);
              this.groupedBarsWithLineChart(
                chartElement,
                widget,
                widgetdetails
              );
              d3.select('#loader' + widget.objectCordinates.replace(',', '')).style('display', 'none');
              this.isLoading = false;
            } else if (widget.controlMasterId == 38) {
              var chartElement = d3.select('#chart1')
                .attr('empty', false);
              this.USMapWithPins(chartElement, widget, widgetdetails);
              d3.select('#loader' + widget.objectCordinates.replace(',', '')).style('display', 'none');
              this.isLoading = false;
            }
          }, error => {
            this.loader = false;
            this.isLoading = false;
          })
      }
    }
    else {
      this.loader = false;
      this.isLoading = false;
      this.errorMessage = true;
      this.showResult = false;
    }

  }
  pieChart(chartElement, widget, widgetdetails) {
    let fetchedData =
      widgetdetails.body.records == null
        ? 'No Records!'
        : widgetdetails.body.records.length > 0
          ? widgetdetails.body.records
          : 'No Records!';

    if (fetchedData == 'No Records!') {
      chartElement
        .append('div')
        .style({
          position: 'absolute',
          left: '0%',
          top: '0%',
          width: '100%',
          height: '8%',
          'background-color': this.wigetBackgroundColor,
          'text-indent': '6px',
        })
        .html(
          '<div  style="width: 100%; height:0%; background-color:{{this.wigetBackgroundColor}}; position: absolute; top:10.0%; font-size:16px; font-family:inter; color:#00162E;">' +
          widget.chartTitle +
          '</div>'
        );

      chartElement
        .append('label')
        .style({
          position: 'absolute',
          left: '0%',
          top: '45%',
          width: '100%',
          'font-family': 'inter',
          'font-size': '30px',
          color: '#f16464',
          'text-align': 'center',
        })
        .text('No Records!');
    } else {
      if (this.value == 1) {
        this.wigetBackgroundColor = this.backColor;
        // if(this.selectedChartsColorSet == "one"){
        // 	this.wigetBackgroundColor = '#ffffff';
        // }
        // if(this.selectedChartsColorSet == "two"){
        // 	this.wigetBackgroundColor = '#fff2cc';
        // }
        // if(this.selectedChartsColorSet == "three"){
        // 	this.wigetBackgroundColor = '#cfe2f3';
        // }
      }
      if (this.value == 2) {
        this.wigetBackgroundColor = '#CFE2F3';
      }
      let xAxisFormatted = '';
      if (
        widget.yAxis.charAt(0) == '[' &&
        widget.yAxis.charAt(widget.yAxis.length - 1) == ']'
      ) {
        xAxisFormatted = JSON.parse(widget.yAxis)[0];
      } else {
        xAxisFormatted = widget.yAxis;
      }

      let yAxisFormatted = '';
      if (
        widget.xAxis.charAt(0) == '[' &&
        widget.xAxis.charAt(widget.xAxis.length - 1) == ']'
      ) {
        yAxisFormatted = JSON.parse(widget.xAxis)[0];
      } else {
        yAxisFormatted = widget.xAxis;
      }
      var ShowLegend = false;
      if (widget.legendYesNo == 'Y') { ShowLegend = true }
      else if (widget.legendYesNo == 'N') { ShowLegend = false }
      let options = {
        // data: widgetdetails.body.records,//widgetdetails.body?.groupedQuery?.records ?  widgetdetails.body?.groupedQuery?.records : widgetdetails.body.records, // for chart
        // dataForTable: [],//widgetdetails.body?.groupedQuery?.records, //[], // for table if there is no data send empty array ([])
        data: widgetdetails.body?.groupedQuery?.records ? widgetdetails.body?.groupedQuery?.records : widgetdetails.body.records,
        dataForTable: widgetdetails.body?.records,
        isDataDetailedForTable: false,
        keys: {
          label: xAxisFormatted,
          value: yAxisFormatted,
        },
        chartTitle: widget.chartTitle,
        showTitle: true,
        showLegend: ShowLegend,
        legendSide: 'right', //"right" or "left"
        colorSet: this.selectedChartsColorSet,
        series: this.seriesVal,
        legend: this.legendvalue,
        BGcolor: this.wigetBackgroundColor, //"one" or "two" or "three"
        gradient: 'none', //"none" or "smooth" or "elliptical" or "circular" or "radial"
        drillDownColumnName: widget.drillThru[0]?.drillDownColumn
          ? widget.drillThru[0].drillDownColumn
          : '',
        showTableIcon: true, //false - hide, true - show
        showTable: false, //false - hide, true - show
        showComments: false,
        comments: [],
        resizeButtonID: document.getElementById('resizeBtn').id,
      };

      setTimeout(() => {
        acinfotech.pieChart(chartElement, options);
      }, 1000);
    }
  }
  columnChart(chartElement, widget, widgetdetails) {

    let fetchedData =
      widgetdetails.body.records == null
        ? 'No Records!'
        : widgetdetails.body.records.length > 0
          ? widgetdetails.body.records
          : 'No Records!';

    if (fetchedData == 'No Records!') {
      chartElement
        .append('div')
        .style({
          position: 'absolute',
          left: '0%',
          top: '0%',
          width: '100%',
          height: '8%',
          'background-color': this.wigetBackgroundColor,
          'text-indent': '6px',
        })
        .html(
          '<div  style="width: 100%; height:0%; background-color:{{ this.wigetBackgroundColor}}; position: absolute; top:10.0%; font-size:16px; font-family:inter; color:#00162E;">' +
          widget.chartTitle +
          '</div>'
        );

      chartElement
        .append('label')
        .style({
          position: 'absolute',
          left: '0%',
          top: '45%',
          width: '100%',
          'font-family': 'inter',
          'font-size': '30px',
          color: '#f16464',
          'text-align': 'center',
        })
        .text('No Records!');
    } else {
      if (this.value == 1) {
        this.wigetBackgroundColor = this.backColor;
        // if(this.selectedChartsColorSet == "one"){
        // 	this.wigetBackgroundColor = '#ffffff';
        // }
        // if(this.selectedChartsColorSet == "two"){
        // 	this.wigetBackgroundColor = '#fff2cc';
        // }
        // if(this.selectedChartsColorSet == "three"){
        // 	this.wigetBackgroundColor = '#cfe2f3';
        // }
      }
      if (this.value == 2) {
        this.wigetBackgroundColor = '#CFE2F3';
      }
      let xAxisFormatted = '';
      if (
        widget.yAxis.charAt(0) == '[' &&
        widget.yAxis.charAt(widget.yAxis.length - 1) == ']'
      ) {
        xAxisFormatted = JSON.parse(widget.yAxis)[0];
      } else {
        xAxisFormatted = widget.yAxis;
      }

      let yAxisFormatted = '';
      if (
        widget.xAxis.charAt(0) == '[' &&
        widget.xAxis.charAt(widget.xAxis.length - 1) == ']'
      ) {
        yAxisFormatted = JSON.parse(widget.xAxis)[0];
      } else {
        yAxisFormatted = widget.xAxis;
      }

      let options = {
        data: widgetdetails.body?.groupedQuery?.records ? widgetdetails.body?.groupedQuery?.records : widgetdetails.body.records,
        dataForTable: widgetdetails.body?.records, // for table
        isDataDetailedForTable: false,
        keys: {
          label: xAxisFormatted,
          value: yAxisFormatted,
        },
        chartTitle: widget.chartTitle,
        xAxisLable: xAxisFormatted,
        yAxisLable: yAxisFormatted,
        showTitle: true,
        colorSet: this.selectedChartsColorSet,
        series: this.seriesVal,
        legend: this.legendvalue,
        BGcolor: this.wigetBackgroundColor, //one or two or three
        drillDownColumnName: widget.drillThru[0]?.drillDownColumn
          ? widget.drillThru[0].drillDownColumn
          : '',
        showTable: false, //false - hide, true - show
        showComments: false,
        comments: [],
        resizeButtonID: document.getElementById('resizeBtn').id,
      };

      setTimeout(() => {
        acinfotech.columnChart(chartElement, options);
      }, 1000);
    }
  }
  table(chartElement, widget, widgetdetails) {
    let fetchedData =
      widgetdetails.body.records == null
        ? 'No Records!'
        : widgetdetails.body.records.length > 0
          ? widgetdetails.body.records
          : 'No Records!';

    if (fetchedData == 'No Records!') {
      chartElement
        .append('div')
        .style({
          position: 'absolute',
          left: '0%',
          top: '0%',
          width: '100%',
          height: '8%',
          'background-color': this.wigetBackgroundColor,
          'text-indent': '6px',
        })
        .html(
          '<div  style="width: 100%; height:0%; background-color:{{ this.wigetBackgroundColor}}; position: absolute; top:10.0%; font-size:16px; font-family:inter; color:#00162E;">' +
          widget.chartTitle +
          '</div>'
        );

      chartElement
        .append('label')
        .style({
          position: 'absolute',
          left: '0%',
          top: '45%',
          width: '100%',
          'font-family': 'inter',
          'font-size': '30px',
          color: '#f16464',
          'text-align': 'center',
        })
        .text('No Records!');
    } else {
      let options = {
        data: widgetdetails.body?.groupedQuery?.records ? widgetdetails.body?.groupedQuery?.records : widgetdetails.body.records,
        chartTitle: widget.chartTitle,
        showTitle: true,
        drillDownColumnName: widget.drillThru[0]?.drillDownColumn
          ? widget.drillThru[0].drillDownColumn
          : '',
        fontStyle: 'Normal', // Normal, Italic
        fontWeight: 'Bold', // Normal, Bold
        fontFamily: 'inter', // inter, Century Gothic, Candara, Verdana, Trebuchet MS, Segoe UI
        resizeButtonID: document.getElementById('resizeBtn').id,
      };
      setTimeout(() => {
        acinfotech.table(chartElement, options);
      }, 1000);
    }
  }
  donutChart(chartElement, widget, widgetdetails) {
    let fetchedData =
      widgetdetails.body.records == null
        ? 'No Records!'
        : widgetdetails.body.records.length > 0
          ? widgetdetails.body.records
          : 'No Records!';

    if (fetchedData == 'No Records!') {
      chartElement
        .append('div')
        .style({
          position: 'absolute',
          left: '0%',
          top: '0%',
          width: '100%',
          height: '8%',
          'background-color': this.wigetBackgroundColor,
          'text-indent': '6px',
        })
        .html(
          '<div  style="width: 100%; height:0%; background-color:{{ this.wigetBackgroundColor}}; position: absolute; top:10.0%; font-size:16px; font-family:inter; color:#00162E;">' +
          widget.chartTitle +
          '</div>'
        );

      chartElement
        .append('label')
        .style({
          position: 'absolute',
          left: '0%',
          top: '45%',
          width: '100%',
          'font-family': 'inter',
          'font-size': '30px',
          color: '#f16464',
          'text-align': 'center',
        })
        .text('No Records!');
    } else {
      if (this.value == 1) {
        this.wigetBackgroundColor = this.backColor;
        // if(this.selectedChartsColorSet == "one"){
        // 	this.wigetBackgroundColor = '#ffffff';
        // }
        // if(this.selectedChartsColorSet == "two"){
        // 	this.wigetBackgroundColor = '#fff2cc';
        // }
        // if(this.selectedChartsColorSet == "three"){
        // 	this.wigetBackgroundColor = '#cfe2f3';
        // }
      }
      if (this.value == 2) {
        this.wigetBackgroundColor = '#ebb9b9';
      }
      let xAxisFormatted = '';
      if (
        widget.yAxis.charAt(0) == '[' &&
        widget.yAxis.charAt(widget.yAxis.length - 1) == ']'
      ) {
        xAxisFormatted = JSON.parse(widget.yAxis)[0];
      } else {
        xAxisFormatted = widget.yAxis;
      }

      let yAxisFormatted = '';
      if (
        widget.xAxis.charAt(0) == '[' &&
        widget.xAxis.charAt(widget.xAxis.length - 1) == ']'
      ) {
        yAxisFormatted = JSON.parse(widget.xAxis)[0];
      } else {
        yAxisFormatted = widget.xAxis;
      }
      var ShowLegend = false;
      if (widget.legendYesNo == 'Y') { ShowLegend = true }
      else if (widget.legendYesNo == 'N') { ShowLegend = false }
      let options = {
        data: widgetdetails.body?.groupedQuery?.records ? widgetdetails.body?.groupedQuery?.records : widgetdetails.body.records, // for chart
        dataForTable: widgetdetails.body?.records, // for table if there is no data send empty array ([])
        isDataDetailedForTable: false,
        keys: {
          label: xAxisFormatted,
          value: yAxisFormatted,
        },
        chartTitle: widget.chartTitle,
        showTitle: true,
        showLegend: ShowLegend,
        legendSide: 'right', //"right" or "left"
        series: this.seriesVal,
        legend: this.legendvalue,
        colorSet: this.selectedChartsColorSet, //"one" or "two" or "three"
        BGcolor: this.wigetBackgroundColor,
        gradient: 'none', //"none" or "smooth" or "elliptical" or "circular" or "radial"
        drillDownColumnName: widget.drillThru[0]?.drillDownColumn
          ? widget.drillThru[0].drillDownColumn
          : '',
        showTableIcon: true, //false - hide, true - show
        showTable: false, //false - hide, true - show
        showComments: false,
        comments: [],
        resizeButtonID: document.getElementById('resizeBtn').id,
      };
      setTimeout(() => {
        acinfotech.donutChart(chartElement, options);
      }, 1000);
    }
  }
  horizontalChart(chartElement, widget, widgetdetails) {
    let fetchedData =
      widgetdetails.body.records == null
        ? 'No Records!'
        : widgetdetails.body.records.length > 0
          ? widgetdetails.body.records
          : 'No Records!';

    if (fetchedData == 'No Records!') {
      chartElement
        .append('div')
        .style({
          position: 'absolute',
          left: '0%',
          top: '0%',
          width: '100%',
          height: '8%',
          'background-color': this.wigetBackgroundColor,
          'text-indent': '6px',
        })
        .html(
          '<div  style="width: 100%; height:0%; background-color:{{ this.wigetBackgroundColor}}; position: absolute; top:10.0%; font-size:16px; font-family:inter; color:#00162E;">' +
          widget.chartTitle +
          '</div>'
        );

      chartElement
        .append('label')
        .style({
          position: 'absolute',
          left: '0%',
          top: '45%',
          width: '100%',
          'font-family': 'inter',
          'font-size': '30px',
          color: '#f16464',
          'text-align': 'center',
        })
        .text('No Records!');
    } else {
      if (this.value == 1) {
        this.wigetBackgroundColor = this.backColor;
        // if(this.selectedChartsColorSet == "one"){
        // 	this.wigetBackgroundColor = '#ffffff';
        // }
        // if(this.selectedChartsColorSet == "two"){
        // 	this.wigetBackgroundColor = '#fff2cc';
        // }
        // if(this.selectedChartsColorSet == "three"){
        // 	this.wigetBackgroundColor = '#cfe2f3';
        // }
      }
      if (this.value == 2) {
        this.wigetBackgroundColor = '#ffd670';
      }

      let xAxisFormatted = '';
      if (
        widget.yAxis.charAt(0) == '[' &&
        widget.yAxis.charAt(widget.yAxis.length - 1) == ']'
      ) {
        xAxisFormatted = JSON.parse(widget.yAxis)[0];
      } else {
        xAxisFormatted = widget.yAxis;
      }

      let yAxisFormatted = '';
      if (
        widget.xAxis.charAt(0) == '[' &&
        widget.xAxis.charAt(widget.xAxis.length - 1) == ']'
      ) {
        yAxisFormatted = JSON.parse(widget.xAxis)[0];
      } else {
        yAxisFormatted = widget.xAxis;
      }

      let options = {
        data: widgetdetails.body?.groupedQuery?.records ? widgetdetails.body?.groupedQuery?.records : widgetdetails.body.records,
        dataForTable: widgetdetails.body?.records,
        isDataDetailedForTable: false,
        keys: {
          label: xAxisFormatted,
          value: yAxisFormatted,
        },
        chartTitle: widget.chartTitle,
        xAxisLable: yAxisFormatted,
        yAxisLable: xAxisFormatted,
        showTitle: true,
        series: this.seriesVal,
        legend: this.legendvalue,
        colorSet: this.selectedChartsColorSet, //"one" or "two" or "three"
        BGcolor: this.wigetBackgroundColor,
        drillDownColumnName: widget.drillThru[0]?.drillDownColumn
          ? widget.drillThru[0].drillDownColumn
          : '',
        showTable: false,
        showComments: false,
        comments: [],
        resizeButtonID: document.getElementById('resizeBtn').id,
      };
      setTimeout(() => {
        acinfotech.horizontalChart(chartElement, options);
      }, 1000);
    }
  }
  //waffleChart(chartElement,widget,widgetdetails){}
  perGauge(chartElement, widget, widgetdetails) {
    let fetchedData =
      widgetdetails.body.records == null
        ? widget.chartPlot
        : widgetdetails.body.records.length > 0
          ? widgetdetails.body.records[0][widget.yAxis]
          : 'No Records!';

    if (fetchedData == 'No Records!') {
      chartElement
        .append('div')
        .style({
          position: 'absolute',
          left: '0%',
          top: '0%',
          width: '100%',
          height: '8%',
          'background-color': this.wigetBackgroundColor,
          'text-indent': '6px',
        })
        .html(
          '<div  style="width: 100%; height:0%; background-color:{{ this.wigetBackgroundColor}}; position: absolute; top:10.0%; font-size:16px; font-family:inter; color:#00162E;">' +
          widget.chartTitle +
          '</div>'
        );

      chartElement
        .append('label')
        .style({
          position: 'absolute',
          left: '0%',
          top: '45%',
          width: '100%',
          'font-family': 'inter',
          'font-size': '30px',
          color: '#f16464',
          'text-align': 'center',
        })
        .text('No Records!');
    } else {
      if (this.value == 1) {
        this.wigetBackgroundColor = this.backColor;
        // if(this.selectedChartsColorSet == "one"){
        // 	this.wigetBackgroundColor = '#ffffff';
        // }
        // if(this.selectedChartsColorSet == "two"){
        // 	this.wigetBackgroundColor = '#fff2cc';
        // }
        // if(this.selectedChartsColorSet == "three"){
        // 	this.wigetBackgroundColor = '#cfe2f3';
        // }
      }
      if (this.value == 2) {
        this.wigetBackgroundColor = '#d9d9d9';
      }

      let options = {
        data: fetchedData,
        chartTitle: widget.chartTitle,
        showTitle: true,
        colorSet: this.selectedChartsColorSet,
        BGcolor: this.wigetBackgroundColor,
        resizeButtonID: document.getElementById('resizeBtn').id,
      };
      setTimeout(() => {
        acinfotech.perGauge(chartElement, options);
      }, 1000);
    }
  }
  numberGauge(chartElement, widget, widgetdetails) {
    let fetchedData =
      widgetdetails.body.records == null
        ? widget.chartPlot
        : widgetdetails.body.records.length > 0
          ? widgetdetails.body.records[0][widget.yAxis]
          : 'No Records!';

    if (fetchedData == 'No Records!') {
      chartElement
        .append('div')
        .style({
          position: 'absolute',
          left: '0%',
          top: '0%',
          width: '100%',
          height: '8%',
          'background-color': this.wigetBackgroundColor,
          'text-indent': '6px',
        })
        .html(
          '<div  style="width: 100%; height:0%; background-color:{{ this.wigetBackgroundColor}}; position: absolute; top:10.0%; font-size:16px; font-family:inter; color:#00162E;">' +
          widget.chartTitle +
          '</div>'
        );

      chartElement
        .append('label')
        .style({
          position: 'absolute',
          left: '0%',
          top: '45%',
          width: '100%',
          'font-family': 'inter',
          'font-size': '30px',
          color: '#f16464',
          'text-align': 'center',
        })
        .text('No Records!');
    } else {
      if (this.value == 1) {
        this.wigetBackgroundColor = this.backColor;
        // if(this.selectedChartsColorSet == "one"){
        // 	this.wigetBackgroundColor = '#ffffff';
        // }
        // if(this.selectedChartsColorSet == "two"){
        // 	this.wigetBackgroundColor = '#fff2cc';
        // }
        // if(this.selectedChartsColorSet == "three"){
        // 	this.wigetBackgroundColor = '#cfe2f3';
        // }
      }
      if (this.value == 2) {
        this.wigetBackgroundColor = '#ebb9b9';
      }

      let options = {
        data: fetchedData,
        chartTitle: widget.chartTitle,
        showTitle: true,
        colorSet: this.selectedChartsColorSet,
        BGcolor: this.wigetBackgroundColor,
        resizeButtonID: document.getElementById('resizeBtn').id,
      };
      setTimeout(() => {
        acinfotech.numberGauge(chartElement, options);
      }, 1000);
    }
  }
  map(chartElement, widget, widgetdetails) {
    let fetchedData =
      widgetdetails.body.records == null
        ? 'No Records!'
        : widgetdetails.body.records.length > 0
          ? widgetdetails.body.records
          : 'No Records!';

    if (fetchedData == 'No Records!') {
      chartElement
        .append('div')
        .style({
          position: 'absolute',
          left: '0%',
          top: '0%',
          width: '100%',
          height: '8%',
          'background-color': this.wigetBackgroundColor,
          'text-indent': '6px',
        })
        .html(
          '<div  style="width: 100%; height:0%; background-color:{{ this.wigetBackgroundColor}}; position: absolute; top:10.0%; font-size:16px; font-family:inter; color:#00162E;">' +
          widget.chartTitle +
          '</div>'
        );

      chartElement
        .append('label')
        .style({
          position: 'absolute',
          left: '0%',
          top: '45%',
          width: '100%',
          'font-family': 'inter',
          'font-size': '30px',
          color: '#f16464',
          'text-align': 'center',
        })
        .text('No Records!');
    } else {
      if (this.value == 1) {
        this.wigetBackgroundColor = this.backColor;
        // if(this.selectedChartsColorSet == "one"){
        // 	this.wigetBackgroundColor = '#ffffff';
        // }
        // if(this.selectedChartsColorSet == "two"){
        // 	this.wigetBackgroundColor = '#fff2cc';
        // }
        // if(this.selectedChartsColorSet == "three"){
        // 	this.wigetBackgroundColor = '#cfe2f3';
        // }
      }
      if (this.value == 2) {
        this.wigetBackgroundColor = '#e1d0e8';
      }
      let xAxisFormatted = '';
      if (
        widget.yAxis.charAt(0) == '[' &&
        widget.yAxis.charAt(widget.yAxis.length - 1) == ']'
      ) {
        xAxisFormatted = JSON.parse(widget.yAxis)[0];
      } else {
        xAxisFormatted = widget.yAxis;
      }

      let yAxisFormatted = '';
      if (
        widget.xAxis.charAt(0) == '[' &&
        widget.xAxis.charAt(widget.xAxis.length - 1) == ']'
      ) {
        yAxisFormatted = JSON.parse(widget.xAxis)[0];
      } else {
        yAxisFormatted = widget.xAxis;
      }

      let options = {
        data: widgetdetails.body?.groupedQuery?.records ? widgetdetails.body?.groupedQuery?.records : widgetdetails.body.records,
        keys: {
          label: xAxisFormatted,
          value: yAxisFormatted,
        },
        chartTitle: widget.chartTitle,
        showTitle: true,
        maximize: true,
        series: this.seriesVal,
        legend: this.legendvalue,
        BGcolor: this.wigetBackgroundColor,
        drillDownColumnName: widget.drillThru[0]?.drillDownColumn
          ? widget.drillThru[0].drillDownColumn
          : '',
        resizeButtonID: document.getElementById('resizeBtn').id,
      };
      setTimeout(() => {
        acinfotech.map(chartElement, options);
      }, 1000);
    }
  }
  columnAndLineChart(chartElement, widget, widgetdetails) {
    let fetchedData =
      widgetdetails.body.records == null
        ? 'No Records!'
        : widgetdetails.body.records.length > 0
          ? widgetdetails.body.records
          : 'No Records!';

    if (fetchedData == 'No Records!') {
      chartElement
        .append('div')
        .style({
          position: 'absolute',
          left: '0%',
          top: '0%',
          width: '100%',
          height: '8%',
          'background-color': this.wigetBackgroundColor,
          'text-indent': '6px',
        })
        .html(
          '<div  style="width: 100%; height:0%; background-color:{{ this.wigetBackgroundColor}}; position: absolute; top:10.0%; font-size:16px; font-family:inter; color:#00162E;">' +
          widget.chartTitle +
          '</div>'
        );

      chartElement
        .append('label')
        .style({
          position: 'absolute',
          left: '0%',
          top: '45%',
          width: '100%',
          'font-family': 'inter',
          'font-size': '30px',
          color: '#f16464',
          'text-align': 'center',
        })
        .text('No Records!');
    } else {
      if (this.value == 1) {
        this.wigetBackgroundColor = this.backColor;
        // if(this.selectedChartsColorSet == "one"){
        // 	this.wigetBackgroundColor = '#ffffff';
        // }
        // if(this.selectedChartsColorSet == "two"){
        // 	this.wigetBackgroundColor = '#fff2cc';
        // }
        // if(this.selectedChartsColorSet == "three"){
        // 	this.wigetBackgroundColor = '#cfe2f3';
        // }
      }
      if (this.value == 2) {
        this.wigetBackgroundColor = '#E3F2FD';
      }
      let xAxis = '';
      if (
        widget.yAxis.charAt(0) == '[' &&
        widget.yAxis.charAt(widget.yAxis.length - 1) == ']'
      ) {
        xAxis = JSON.parse(widget.yAxis)[0];
      } else {
        xAxis = widget.yAxis;
      }

      let options = {
        data: widgetdetails.body?.groupedQuery?.records ? widgetdetails.body?.groupedQuery?.records : widgetdetails.body.records,
        dataForTable: widgetdetails.body?.records,
        isDataDetailedForTable: false,
        keys: {
          label: xAxis,
          valueForColumn: JSON.parse(widget.xAxis)[0], // left axis
          valueForLine: JSON.parse(widget.xAxis)[1], // right axis
        },
        chartTitle: widget.chartTitle,
        xAxisLable: xAxis,
        yLeftAxisLable: JSON.parse(widget.xAxis)[0],
        yRightAxisLable: JSON.parse(widget.xAxis)[1],
        showTitle: true,
        series: this.seriesVal,
        legend: this.legendvalue,
        colorSet: this.selectedChartsColorSet, //"one" or "two" or "three";
        BGcolor: this.wigetBackgroundColor,
        showTable: false,
        showComments: false,
        comments: [],
        drillDownColumnName: widget.drillThru[0]?.drillDownColumn
          ? widget.drillThru[0].drillDownColumn
          : '',
        resizeButtonID: document.getElementById('resizeBtn').id,
      };
      setTimeout(() => {
        acinfotech.columnAndLineChart(chartElement, options);
      }, 1000);
    }
  }
  lineChart(chartElement, widget, widgetdetails) {
    let fetchedData =
      widgetdetails?.body?.records == null
        ? 'No Records!'
        : widgetdetails?.body?.records.length > 0
          ? widgetdetails?.body?.records
          : 'No Records!';

    if (fetchedData == 'No Records!') {
      chartElement
        .append('div')
        .style({
          position: 'absolute',
          left: '0%',
          top: '0%',
          width: '100%',
          height: '8%',
          'background-color': this.wigetBackgroundColor,
          'text-indent': '6px',
        })
        .html(
          '<div  style="width: 100%; height:0%; background-color:{{ this.wigetBackgroundColor}}; position: absolute; top:10.0%; font-size:16px; font-family:inter; color:#00162E;">' +
          widget.chartTitle +
          '</div>'
        );

      chartElement
        .append('label')
        .style({
          position: 'absolute',
          left: '0%',
          top: '45%',
          width: '100%',
          'font-family': 'inter',
          'font-size': '30px',
          color: '#f16464',
          'text-align': 'center',
        })
        .text('No Records!');
    } else {
      if (this.value == 1) {
        this.wigetBackgroundColor = this.backColor;
        // if(this.selectedChartsColorSet == "one"){
        // 	this.wigetBackgroundColor = '#ffffff';
        // }
        // if(this.selectedChartsColorSet == "two"){
        // 	this.wigetBackgroundColor = '#fff2cc';
        // }
        // if(this.selectedChartsColorSet == "three"){
        // 	this.wigetBackgroundColor = '#cfe2f3';
        // }
      }
      if (this.value == 2) {
        this.wigetBackgroundColor = '#B2DFDB';
      }
      let xAxis = '',
        xAxisArr = [];
      if (
        widget.yAxis.charAt(0) == '[' &&
        widget.yAxis.charAt(widget.yAxis.length - 1) == ']'
      ) {
        xAxis = JSON.parse(widget.yAxis);
        if (xAxis.length > 1) {
          xAxisArr = xAxis as any;
        } else {
          xAxis = JSON.parse(widget.yAxis)[0];
        }
      } else {
        xAxis = widget.yAxis;
      }

      if (xAxisArr.length > 0) {
        let yAxis = '';
        if (
          widget.xAxis.charAt(0) == '[' &&
          widget.xAxis.charAt(widget.xAxis.length - 1) == ']'
        ) {
          yAxis = JSON.parse(widget.xAxis)[0];
        } else {
          yAxis = widget.xAxis;
        }

        let options = {
          data: widgetdetails.body?.groupedQuery?.records ? widgetdetails.body?.groupedQuery?.records : widgetdetails.body.records,
          dataForTable: widgetdetails.body?.records,
          isDataDetailedForTable: false,
          keys: {
            label: xAxisArr, // X-Axis labels
            lines: yAxis,
          },
          chartTitle: widget.chartTitle,
          xAxisLable: yAxis,
          yAxisLable: '',
          showTitle: true,
          series: this.seriesVal,
          legend: this.legendvalue,
          colorSet: this.selectedChartsColorSet, //"one" or "two" or "three";
          BGcolor: this.wigetBackgroundColor,
          lineStyle: 'cardinal', // "cardinal" or "linear" or "step"
          drillDownColumnName: widget.drillThru[0]?.drillDownColumn
            ? widget.drillThru[0].drillDownColumn
            : '',
          showTable: false,
          showComments: false,
          comments: [],
          resizeButtonID: document.getElementById('resizeBtn').id,
        };

        setTimeout(() => {
          acinfotech.lineChart2(chartElement, options);
        }, 1000);
      } else {
        let options = {
          data: widgetdetails.body?.groupedQuery?.records ? widgetdetails.body?.groupedQuery?.records : widgetdetails.body.records,
          dataForTable: widgetdetails.body?.records,
          isDataDetailedForTable: false,
          keys: {
            label: xAxis, // X-Axis labels
            lines: JSON.parse(widget.xAxis),
          },
          chartTitle: widget.chartTitle,
          xAxisLable: xAxis,
          yAxisLable: '',
          showTitle: true,
          series: this.seriesVal,
          legend: this.legendvalue,
          colorSet: this.selectedChartsColorSet, //"one" or "two" or "three";
          BGcolor: this.wigetBackgroundColor,
          lineStyle: 'linear', // "cardinal" or "linear" or "step"
          drillDownColumnName: widget.drillThru[0]?.drillDownColumn
            ? widget.drillThru[0].drillDownColumn
            : '',
          showTable: false,
          showComments: false,
          comments: [],
          resizeButtonID: document.getElementById('resizeBtn').id,
        };

        setTimeout(() => {
          acinfotech.lineChart(chartElement, options);
        }, 1000);
      }
    }
  }
  bubbleChart(chartElement, widget, widgetdetails) {
    let fetchedData =
      widgetdetails.body.records == null
        ? 'No Records!'
        : widgetdetails.body.records.length > 0
          ? widgetdetails.body.records
          : 'No Records!';

    if (fetchedData == 'No Records!') {
      chartElement
        .append('div')
        .style({
          position: 'absolute',
          left: '0%',
          top: '0%',
          width: '100%',
          height: '8%',
          'background-color': this.wigetBackgroundColor,
          'text-indent': '6px',
        })
        .html(
          '<div  style="width: 100%; height:0%; background-color:{{ this.wigetBackgroundColor}}; position: absolute; top:10.0%; font-size:16px; font-family:inter; color:#00162E;">' +
          widget.chartTitle +
          '</div>'
        );

      chartElement
        .append('label')
        .style({
          position: 'absolute',
          left: '0%',
          top: '45%',
          width: '100%',
          'font-family': 'inter',
          'font-size': '30px',
          color: '#f16464',
          'text-align': 'center',
        })
        .text('No Records!');
    } else {
      if (this.value == 1) {
        // if(this.selectedChartsColorSet == "one"){
        // 	this.wigetBackgroundColor = '#ffffff';
        // }
        // if(this.selectedChartsColorSet == "two"){
        // 	this.wigetBackgroundColor = '#fff2cc';
        // }
        // if(this.selectedChartsColorSet == "three"){
        // 	this.wigetBackgroundColor = '#cfe2f3';
        // }
        this.wigetBackgroundColor = this.backColor;
      }
      if (this.value == 2) {
        this.wigetBackgroundColor = '#C8E6C9';
      }

      let xAxisFormatted = '';
      if (
        widget.yAxis.charAt(0) == '[' &&
        widget.yAxis.charAt(widget.yAxis.length - 1) == ']'
      ) {
        xAxisFormatted = JSON.parse(widget.yAxis)[0];
      } else {
        xAxisFormatted = widget.yAxis;
      }

      let yAxisFormatted = '';
      if (
        widget.xAxis.charAt(0) == '[' &&
        widget.xAxis.charAt(widget.xAxis.length - 1) == ']'
      ) {
        yAxisFormatted = JSON.parse(widget.xAxis)[0];
      } else {
        yAxisFormatted = widget.xAxis;
      }

      let options = {
        data: widgetdetails.body?.groupedQuery?.records ? widgetdetails.body?.groupedQuery?.records : widgetdetails.body.records,
        dataForTable: widgetdetails.body?.records,
        isDataDetailedForTable: false,
        keys: {
          label: xAxisFormatted,
          value: yAxisFormatted,
        },
        chartTitle: widget.chartTitle,
        showTitle: true,
        series: this.seriesVal,
        legend: this.legendvalue,
        colorSet: this.selectedChartsColorSet, //"one" or "two" or "three";
        BGcolor: this.wigetBackgroundColor,
        drillDownColumnName: widget.drillThru[0]?.drillDownColumn
          ? widget.drillThru[0].drillDownColumn
          : '',
        showTable: false,
        showComments: false,
        comments: [],
        resizeButtonID: document.getElementById('resizeBtn').id,
      };

      setTimeout(() => {
        acinfotech.bubbleChart(chartElement, options);
      }, 1000);
    }
  }
  stackedHorizontalBarChart(chartElement, widget, widgetdetails) {
    let fetchedData =
      widgetdetails.body.records == null
        ? 'No Records!'
        : widgetdetails.body.records.length > 0
          ? widgetdetails.body.records
          : 'No Records!';

    if (fetchedData == 'No Records!') {
      chartElement
        .append('div')
        .style({
          position: 'absolute',
          left: '0%',
          top: '0%',
          width: '100%',
          height: '8%',
          'background-color': this.wigetBackgroundColor,
          'text-indent': '6px',
        })
        .html(
          '<div  style="width: 100%; height:0%; background-color:{{ this.wigetBackgroundColor}}; position: absolute; top:10.0%; font-size:16px; font-family:inter; color:#00162E;">' +
          widget.chartTitle +
          '</div>'
        );

      chartElement
        .append('label')
        .style({
          position: 'absolute',
          left: '0%',
          top: '45%',
          width: '100%',
          'font-family': 'inter',
          'font-size': '30px',
          color: '#f16464',
          'text-align': 'center',
        })
        .text('No Records!');
    } else {
      if (this.value == 1) {
        this.wigetBackgroundColor = this.backColor;
        // if(this.selectedChartsColorSet == "one"){
        // 	this.wigetBackgroundColor = '#ffffff';
        // }
        // if(this.selectedChartsColorSet == "two"){
        // 	this.wigetBackgroundColor = '#fff2cc';
        // }
        // if(this.selectedChartsColorSet == "three"){
        // 	this.wigetBackgroundColor = '#cfe2f3';
        // }
      }
      if (this.value == 2) {
        this.wigetBackgroundColor = '#ebb9b9';
      }
      let xAxis = '',
        xAxisArr = [];
      if (
        widget.yAxis.charAt(0) == '[' &&
        widget.yAxis.charAt(widget.yAxis.length - 1) == ']'
      ) {
        xAxis = JSON.parse(widget.yAxis);
        if (xAxis.length > 1) {
          xAxisArr = xAxis as any;
        } else {
          xAxis = JSON.parse(widget.yAxis)[0];
        }
      } else {
        xAxis = widget.yAxis;
      }

      if (xAxisArr.length > 0) {
        let yAxis = '';
        if (
          widget.xAxis.charAt(0) == '[' &&
          widget.xAxis.charAt(widget.xAxis.length - 1) == ']'
        ) {
          yAxis = JSON.parse(widget.xAxis)[0];
        } else {
          yAxis = widget.xAxis;
        }
        let options = {
          data: widgetdetails.body?.groupedQuery?.records ? widgetdetails.body?.groupedQuery?.records : widgetdetails.body.records,
          dataForDrillDown: widgetdetails.body?.groupedQuery?.records ? widgetdetails.body?.records : [],
          dataForTable: widgetdetails.body?.records,
          isDataDetailedForTable: false,
          keys: {
            label: xAxisArr, // X-Axis labels
            stackedBars: yAxis,
          },
          chartTitle: widget.chartTitle,
          xAxisLable: '',
          yAxisLable: yAxis,
          showTitle: true,
          series: this.seriesVal,
          legend: this.legendvalue,
          colorSet: this.selectedChartsColorSet, //"one" or "two" or "three";
          BGcolor: this.wigetBackgroundColor,
          lineStyle: 'cardinal', // "cardinal" or "linear" or "step"
          drillDownColumnName: widget.drillThru[0]?.drillDownColumn
            ? widget.drillThru[0].drillDownColumn
            : '',
          showTable: false,
          showComments: false,
          comments: [],
          resizeButtonID: document.getElementById('resizeBtn').id,
        };

        setTimeout(() => {
          acinfotech.stackedHorizontalBarChart2(chartElement, options);
        }, 1000);
      } else {
        let options = {
          data: widgetdetails.body?.groupedQuery?.records ? widgetdetails.body?.groupedQuery?.records : widgetdetails.body.records,
          dataForDrillDown: widgetdetails.body?.groupedQuery?.records ? widgetdetails.body?.records : [],
          dataForTable: widgetdetails.body?.records,
          isDataDetailedForTable: false,
          keys: {
            label: xAxis, // X-Axis labels
            stackedBars: JSON.parse(widget.xAxis),
          },
          chartTitle: widget.chartTitle,
          xAxisLable: '',
          yAxisLable: xAxis,
          showTitle: true,
          series: this.seriesVal,
          legend: this.legendvalue,
          colorSet: this.selectedChartsColorSet, //"one" or "two" or "three";
          BGcolor: this.wigetBackgroundColor,
          lineStyle: 'cardinal', // "cardinal" or "linear" or "step"
          drillDownColumnName: widget.drillThru[0]?.drillDownColumn
            ? widget.drillThru[0].drillDownColumn
            : '',
          showTable: false,
          showComments: false,
          comments: [],
          resizeButtonID: document.getElementById('resizeBtn').id,
        };

        setTimeout(() => {
          acinfotech.stackedHorizontalBarChart(chartElement, options);
        }, 1000);
      }
    }
  }
  stackedBarChart(chartElement, widget, widgetdetails) {
    let fetchedData =
      widgetdetails.body.records == null
        ? 'No Records!'
        : widgetdetails.body.records.length > 0
          ? widgetdetails.body.records
          : 'No Records!';

    if (fetchedData == 'No Records!') {
      chartElement
        .append('div')
        .style({
          position: 'absolute',
          left: '0%',
          top: '0%',
          width: '100%',
          height: '8%',
          'background-color': this.wigetBackgroundColor,
          'text-indent': '6px',
        })
        .html(
          '<div  style="width: 100%; height:0%; background-color:{{ this.wigetBackgroundColor}}; position: absolute; top:10.0%; font-size:16px; font-family:inter; color:#00162E;">' +
          widget.chartTitle +
          '</div>'
        );

      chartElement
        .append('label')
        .style({
          position: 'absolute',
          left: '0%',
          top: '45%',
          width: '100%',
          'font-family': 'inter',
          'font-size': '30px',
          color: '#f16464',
          'text-align': 'center',
        })
        .text('No Records!');
    } else {
      if (this.value == 1) {
        this.wigetBackgroundColor = this.backColor;
        // if(this.selectedChartsColorSet == "one"){
        // 	this.wigetBackgroundColor = '#ffffff';
        // }
        // if(this.selectedChartsColorSet == "two"){
        // 	this.wigetBackgroundColor = '#fff2cc';
        // }
        // if(this.selectedChartsColorSet == "three"){
        // 	this.wigetBackgroundColor = '#cfe2f3';
        // }
      }
      if (this.value == 2) {
        this.wigetBackgroundColor = '#FFF9C4';
      }
      let xAxis = '',
        xAxisArr = [];
      if (
        widget.yAxis.charAt(0) == '[' &&
        widget.yAxis.charAt(widget.yAxis.length - 1) == ']'
      ) {
        xAxis = JSON.parse(widget.yAxis);
        if (xAxis.length > 1) {
          xAxisArr = xAxis as any;
        } else {
          xAxis = JSON.parse(widget.yAxis)[0];
        }
      } else {
        xAxis = widget.yAxis;
      }

      if (xAxisArr.length > 0) {
        let yAxis = '';
        if (
          widget.xAxis.charAt(0) == '[' &&
          widget.xAxis.charAt(widget.xAxis.length - 1) == ']'
        ) {
          yAxis = JSON.parse(widget.xAxis)[0];
        } else {
          yAxis = widget.xAxis;
        }
        let options = {
          data: widgetdetails.body?.groupedQuery?.records ? widgetdetails.body?.groupedQuery?.records : widgetdetails.body.records,
          dataForDrillDown: widgetdetails.body?.groupedQuery?.records ? widgetdetails.body?.records : [],
          dataForTable: widgetdetails.body?.records,
          isDataDetailedForTable: false,
          keys: {
            label: xAxisArr, // X-Axis labels
            stackedBars: yAxis,
          },
          chartTitle: widget.chartTitle,
          xAxisLable: yAxis,
          yAxisLable: '',
          showTitle: true,
          series: this.seriesVal,
          legend: this.legendvalue,
          colorSet: this.selectedChartsColorSet, //"one" or "two" or "three";
          BGcolor: this.wigetBackgroundColor,
          lineStyle: 'cardinal', // "cardinal" or "linear" or "step"
          drillDownColumnName: widget.drillThru[0]?.drillDownColumn
            ? widget.drillThru[0].drillDownColumn
            : '',
          showTable: false,
          showComments: false,
          comments: [],
          resizeButtonID: document.getElementById('resizeBtn').id,
        };

        setTimeout(() => {
          acinfotech.stackedBarChart2(chartElement, options);
        }, 1000);
      } else {
        let options = {
          data: widgetdetails.body?.groupedQuery?.records ? widgetdetails.body?.groupedQuery?.records : widgetdetails.body.records,
          dataForDrillDown: widgetdetails.body?.groupedQuery?.records ? widgetdetails.body?.records : [],
          dataForTable: widgetdetails.body?.records,
          isDataDetailedForTable: false,
          keys: {
            label: xAxis, // X-Axis labels
            stackedBars: JSON.parse(widget.xAxis),
          },
          chartTitle: widget.chartTitle,
          xAxisLable: xAxis,
          yAxisLable: '',
          showTitle: true,
          series: this.seriesVal,
          legend: this.legendvalue,
          colorSet: this.selectedChartsColorSet, //"one" or "two" or "three";
          BGcolor: this.wigetBackgroundColor,
          lineStyle: 'cardinal', // "cardinal" or "linear" or "step"
          drillDownColumnName: widget.drillThru[0]?.drillDownColumn
            ? widget.drillThru[0].drillDownColumn
            : '',
          showTable: false,
          showComments: false,
          comments: [],
          resizeButtonID: document.getElementById('resizeBtn').id,
        };
        setTimeout(() => {
          acinfotech.stackedBarChart(chartElement, options);
        }, 1000);
      }
    }
  }
  perGaugeWithFlip(chartElement, widget, widgetdetails) {
    let fetchedData =
      widgetdetails.body.records == null
        ? widget.chartPlot
        : widgetdetails.body.records.length > 0
          ? widgetdetails.body.records[0][widget.yAxis]
          : 'No Records!';

    if (fetchedData == 'No Records!') {
      chartElement
        .append('div')
        .style({
          position: 'absolute',
          left: '0%',
          top: '0%',
          width: '100%',
          height: '8%',
          'background-color': this.wigetBackgroundColor,
          'text-indent': '6px',
        })
        .html(
          '<div  style="width: 100%; height:0%; background-color:{{ this.wigetBackgroundColor}}; position: absolute; top:10.0%; font-size:16px; font-family:inter; color:#00162E;">' +
          widget.chartTitle +
          '</div>'
        );

      chartElement
        .append('label')
        .style({
          position: 'absolute',
          left: '0%',
          top: '45%',
          width: '100%',
          'font-family': 'inter',
          'font-size': '30px',
          color: '#f16464',
          'text-align': 'center',
        })
        .text('No Records!');
    } else {
      if (this.value == 1) {
        this.wigetBackgroundColor = this.backColor;
        // if(this.selectedChartsColorSet == "one"){
        // 	this.wigetBackgroundColor = '#ffffff';
        // }
        // if(this.selectedChartsColorSet == "two"){
        // 	this.wigetBackgroundColor = '#fff2cc';
        // }
        // if(this.selectedChartsColorSet == "three"){
        // 	this.wigetBackgroundColor = '#cfe2f3';
        // }
      }
      if (this.value == 2) {
        this.wigetBackgroundColor = '#CFD8DC';
      }
      let options = {
        data:
          widgetdetails.body.records == null
            ? widget.chartPlot
            : widgetdetails.body.records[0][widget.yAxis],
        chartTitle: widget.chartTitle,
        coveredCircularAreaText: '',
        unCoveredCircularAreaText: '',
        showTitle: true,
        series: this.seriesVal,
        legend: this.legendvalue,
        colorSet: this.selectedChartsColorSet, //"one" or "two" or "three";
        BGcolor: this.wigetBackgroundColor,

        resizeButtonID: document.getElementById('resizeBtn').id,
      };

      setTimeout(() => {
        acinfotech.perGaugeWithFlip(chartElement, options);
      }, 1000);
    }
  }
  squareGauge(chartElement, widget, widgetdetails) {
    let fetchedData =
      widgetdetails.body.records == null
        ? widget.chartPlot
        : widgetdetails.body.records.length > 0
          ? widgetdetails.body.records[0][widget.yAxis]
          : 'No Records!';

    if (fetchedData == 'No Records!') {
      chartElement
        .append('div')
        .style({
          position: 'absolute',
          left: '0%',
          top: '0%',
          width: '100%',
          height: '8%',
          'background-color': this.wigetBackgroundColor,
          'text-indent': '6px',
        })
        .html(
          '<div  style="width: 100%; height:0%; background-color:{{ this.wigetBackgroundColor}}; position: absolute; top:10.0%; font-size:16px; font-family:inter; color:#00162E;">' +
          widget.chartTitle +
          '</div>'
        );

      chartElement
        .append('label')
        .style({
          position: 'absolute',
          left: '0%',
          top: '45%',
          width: '100%',
          'font-family': 'inter',
          'font-size': '30px',
          color: '#f16464',
          'text-align': 'center',
        })
        .text('No Records!');
    } else {
      if (this.value == 1) {
        this.wigetBackgroundColor = this.backColor;
        // if(this.selectedChartsColorSet == "one"){
        // 	this.wigetBackgroundColor = '#ffffff';
        // }
        // if(this.selectedChartsColorSet == "two"){
        // 	this.wigetBackgroundColor = '#fff2cc';
        // }
        // if(this.selectedChartsColorSet == "three"){
        // 	this.wigetBackgroundColor = '#cfe2f3';
        // }
      }
      if (this.value == 2) {
        this.wigetBackgroundColor = '#CFE2F3';
      }
      let options = {
        text:
          widgetdetails.body.records == null
            ? widget.chartPlot
            : widgetdetails.body.records[0][widget.yAxis],
        widgetTitle: widget.chartTitle,
        showTitle: true,
        series: this.seriesVal,
        legend: this.legendvalue,
        colorSet: this.selectedChartsColorSet, //"one" or "two" or "three";
        BGcolor: this.wigetBackgroundColor,
        resizeButtonID: document.getElementById('resizeBtn').id,
      };

      setTimeout(() => {
        acinfotech.squareGauge(chartElement, options);
      }, 1000);
    }
  }
  bellCurveChart(chartElement, widget, widgetdetails) {
    let fetchedData =
      widgetdetails.body.records == null
        ? 'No Records!'
        : widgetdetails.body.records.length > 0
          ? widgetdetails.body.records
          : 'No Records!';

    if (fetchedData == 'No Records!') {
      chartElement
        .append('div')
        .style({
          position: 'absolute',
          left: '0%',
          top: '0%',
          width: '100%',
          height: '8%',
          'background-color': this.wigetBackgroundColor,
          'text-indent': '6px',
        })
        .html(
          '<div  style="width: 100%; height:0%; background-color:{{ this.wigetBackgroundColor}}; position: absolute; top:10.0%; font-size:16px; font-family:inter; color:#00162E;">' +
          widget.chartTitle +
          '</div>'
        );

      chartElement
        .append('label')
        .style({
          position: 'absolute',
          left: '0%',
          top: '45%',
          width: '100%',
          'font-family': 'inter',
          'font-size': '30px',
          color: '#f16464',
          'text-align': 'center',
        })
        .text('No Records!');
    } else {
      if (this.value == 1) {
        this.wigetBackgroundColor = this.backColor;
        // if(this.selectedChartsColorSet == "one"){
        // 	this.wigetBackgroundColor = '#ffffff';
        // }
        // if(this.selectedChartsColorSet == "two"){
        // 	this.wigetBackgroundColor = '#fff2cc';
        // }
        // if(this.selectedChartsColorSet == "three"){
        // 	this.wigetBackgroundColor = '#cfe2f3';
        // }
      }
      if (this.value == 2) {
        this.wigetBackgroundColor = '#ebb9b9';
      }
      let xAxisFormatted = '';
      if (
        widget.yAxis.charAt(0) == '[' &&
        widget.yAxis.charAt(widget.yAxis.length - 1) == ']'
      ) {
        xAxisFormatted = JSON.parse(widget.yAxis)[0];
      } else {
        xAxisFormatted = widget.yAxis;
      }

      let yAxisFormatted = '';
      if (
        widget.xAxis.charAt(0) == '[' &&
        widget.xAxis.charAt(widget.xAxis.length - 1) == ']'
      ) {
        yAxisFormatted = JSON.parse(widget.xAxis)[0];
      } else {
        yAxisFormatted = widget.xAxis;
      }

      let options = {
        data: widgetdetails.body?.groupedQuery?.records ? widgetdetails.body?.groupedQuery?.records : widgetdetails.body.records,
        keys: {
          label: xAxisFormatted,
          value: yAxisFormatted,
          toolTipLabel:
            widget.chartPlot.split(',') > 0 ? widget.chartPlot.split(',') : [],
        },
        chartTitle: widget.chartTitle,
        showTitle: true,
        series: this.seriesVal,
        legend: this.legendvalue,
        colorSet: this.selectedChartsColorSet, //"one" or "two" or "three";
        BGcolor: this.wigetBackgroundColor,
        resizeButtonID: document.getElementById('resizeBtn').id,
      };
      setTimeout(() => {
        acinfotech.bellCurveChart(chartElement, options);
      }, 1000);
    }
  }
  areaChart(chartElement, widget, widgetdetails) {
    let fetchedData =
      widgetdetails.body.records == null
        ? 'No Records!'
        : widgetdetails.body.records.length > 0
          ? widgetdetails.body.records
          : 'No Records!';

    if (fetchedData == 'No Records!') {
      chartElement
        .append('div')
        .style({
          position: 'absolute',
          left: '0%',
          top: '0%',
          width: '100%',
          height: '8%',
          'background-color': this.wigetBackgroundColor,
          'text-indent': '6px',
        })
        .html(
          '<div  style="width: 100%; height:0%; background-color:{{ this.wigetBackgroundColor}}; position: absolute; top:10.0%; font-size:16px; font-family:inter; color:#00162E;">' +
          widget.chartTitle +
          '</div>'
        );

      chartElement
        .append('label')
        .style({
          position: 'absolute',
          left: '0%',
          top: '45%',
          width: '100%',
          'font-family': 'inter',
          'font-size': '30px',
          color: '#f16464',
          'text-align': 'center',
        })
        .text('No Records!');
    } else {
      if (this.value == 1) {
        this.wigetBackgroundColor = this.backColor;
        // if(this.selectedChartsColorSet == "one"){
        // 	this.wigetBackgroundColor = '#ffffff';
        // }
        // if(this.selectedChartsColorSet == "two"){
        // 	this.wigetBackgroundColor = '#fff2cc';
        // }
        // if(this.selectedChartsColorSet == "three"){
        // 	this.wigetBackgroundColor = '#cfe2f3';
        // }
      }
      if (this.value == 2) {
        this.wigetBackgroundColor = '#ebb9b9';
      }
      let xAxis = '',
        xAxisArr = [];
      if (
        widget.yAxis.charAt(0) == '[' &&
        widget.yAxis.charAt(widget.yAxis.length - 1) == ']'
      ) {
        xAxis = JSON.parse(widget.yAxis);
        if (xAxis.length > 1) {
          xAxisArr = xAxis as any;
        } else {
          xAxis = JSON.parse(widget.yAxis)[0];
        }
      } else {
        xAxis = widget.yAxis;
      }

      if (xAxisArr.length > 0) {
        let yAxis = '';
        if (
          widget.xAxis.charAt(0) == '[' &&
          widget.xAxis.charAt(widget.xAxis.length - 1) == ']'
        ) {
          yAxis = JSON.parse(widget.xAxis)[0];
        } else {
          yAxis = widget.xAxis;
        }

        let options = {
          data: widgetdetails.body?.groupedQuery?.records ? widgetdetails.body?.groupedQuery?.records : widgetdetails.body.records,
          dataForTable: widgetdetails.body?.records,
          isDataDetailedForTable: false,
          keys: {
            label: xAxisArr, // X-Axis labels
            areas: yAxis,
          },
          chartTitle: widget.chartTitle,
          xAxisLable: yAxis,
          yAxisLable: '',
          showTitle: true,
          series: this.seriesVal,
          legend: this.legendvalue,
          colorSet: this.selectedChartsColorSet, //"one" or "two" or "three";
          BGcolor: this.wigetBackgroundColor,
          lineStyle: 'cardinal', // "cardinal" or "linear" or "step"
          drillDownColumnName: widget.drillThru[0]?.drillDownColumn
            ? widget.drillThru[0].drillDownColumn
            : '',
          showTable: false,
          showComments: false,
          comments: [],
          resizeButtonID: document.getElementById('resizeBtn').id,
        };

        setTimeout(() => {
          acinfotech.areaChart2(chartElement, options);
        }, 1000);
      } else {
        let options = {
          data: widgetdetails.body?.groupedQuery?.records ? widgetdetails.body?.groupedQuery?.records : widgetdetails.body.records,
          dataForTable: widgetdetails.body?.records,
          isDataDetailedForTable: false,
          keys: {
            label: xAxis, // X-Axis labels
            areas: JSON.parse(widget.xAxis),
          },
          chartTitle: widget.chartTitle,
          xAxisLable: xAxis,
          yAxisLable: '',
          showTitle: true,
          series: this.seriesVal,
          legend: this.legendvalue,
          colorSet: this.selectedChartsColorSet, //"one" or "two" or "three";
          BGcolor: this.wigetBackgroundColor,
          // colorSet: this.selectedChartsColorSet, //"one" or "two" or "three";
          lineStyle: 'cardinal', // "cardinal" or "linear" or "step"
          drillDownColumnName: widget.drillThru[0]?.drillDownColumn
            ? widget.drillThru[0].drillDownColumn
            : '',
          showTable: false,
          showComments: false,
          comments: [],
          resizeButtonID: document.getElementById('resizeBtn').id,
        };

        setTimeout(() => {
          acinfotech.areaChart(chartElement, options);
        }, 1000);
      }
    }
  }
  groupedBarChart(chartElement, widget, widgetdetails) {
    let fetchedData =
      widgetdetails?.body?.records == null
        ? 'No Records!'
        : widgetdetails.body.records.length > 0
          ? widgetdetails.body.records
          : 'No Records!';

    if (fetchedData == 'No Records!') {
      chartElement
        .append('div')
        .style({
          position: 'absolute',
          left: '0%',
          top: '0%',
          width: '100%',
          height: '8%',
          'background-color': this.wigetBackgroundColor,
          'text-indent': '6px',
        })
        .html(
          '<div  style="width: 100%; height:0%; background-color:{{ this.wigetBackgroundColor}}; position: absolute; top:10.0%; font-size:16px; font-family:inter; color:#00162E;">' +
          widget.chartTitle +
          '</div>'
        );

      chartElement
        .append('label')
        .style({
          position: 'absolute',
          left: '0%',
          top: '45%',
          width: '100%',
          'font-family': 'inter',
          'font-size': '30px',
          color: '#f16464',
          'text-align': 'center',
        })
        .text('No Records!');
    } else {
      if (this.value == 1) {
        this.wigetBackgroundColor = this.backColor;
        // if(this.selectedChartsColorSet == "one"){
        // 	this.wigetBackgroundColor = '#ffffff';
        // }
        // if(this.selectedChartsColorSet == "two"){
        // 	this.wigetBackgroundColor = '#fff2cc';
        // }
        // if(this.selectedChartsColorSet == "three"){
        // 	this.wigetBackgroundColor = '#cfe2f3';
        // }
      }
      if (this.value == 2) {
        this.wigetBackgroundColor = '#E1CCB6';
      }

      let xAxisFormatted = '';
      if (
        widget.yAxis.charAt(0) == '[' &&
        widget.yAxis.charAt(widget.yAxis.length - 1) == ']'
      ) {
        xAxisFormatted = JSON.parse(widget.yAxis)[0];
      } else {
        xAxisFormatted = widget.yAxis;
      }

      let options = {
        data: widgetdetails.body?.groupedQuery?.records ? widgetdetails.body?.groupedQuery?.records : widgetdetails.body.records,
        dataForDrillDown: widgetdetails.body?.groupedQuery?.records ? widgetdetails.body?.records : [],
        dataForTable: widgetdetails.body?.records,
        isDataDetailedForTable: false,
        keys: {
          label: xAxisFormatted,
          groupBars: JSON.parse(widget.xAxis),
        },
        chartTitle: widget.chartTitle,
        xAxisLable: xAxisFormatted,
        yAxisLable: '',
        showTitle: true,
        series: this.seriesVal,
        legend: this.legendvalue,
        colorSet: this.selectedChartsColorSet, //"one" or "two" or "three";
        BGcolor: this.wigetBackgroundColor,
        drillDownColumnName: widget.drillThru[0]?.drillDownColumn
          ? widget.drillThru[0].drillDownColumn
          : '',
        showTable: false,
        showComments: false,
        comments: [],
        resizeButtonID: document.getElementById('resizeBtn').id,
      };

      setTimeout(() => {
        acinfotech.groupedBarChart(chartElement, options);
      }, 1000);
    }
  }
  horizontalGroupedBarChart(chartElement, widget, widgetdetails) {
    let fetchedData =
      widgetdetails.body.records == null
        ? 'No Records!'
        : widgetdetails.body.records.length > 0
          ? widgetdetails.body.records
          : 'No Records!';

    if (fetchedData == 'No Records!') {
      chartElement
        .append('div')
        .style({
          position: 'absolute',
          left: '0%',
          top: '0%',
          width: '100%',
          height: '8%',
          'background-color': this.wigetBackgroundColor,
          'text-indent': '6px',
        })
        .html(
          '<div  style="width: 100%; height:0%; background-color:{{ this.wigetBackgroundColor}}; position: absolute; top:10.0%; font-size:16px; font-family:inter; color:#00162E;">' +
          widget.chartTitle +
          '</div>'
        );

      chartElement
        .append('label')
        .style({
          position: 'absolute',
          left: '0%',
          top: '45%',
          width: '100%',
          'font-family': 'inter',
          'font-size': '30px',
          color: '#f16464',
          'text-align': 'center',
        })
        .text('No Records!');
    } else {
      if (this.value == 1) {
        this.wigetBackgroundColor = this.backColor;
        // if(this.selectedChartsColorSet == "one"){
        // 	this.wigetBackgroundColor = '#ffffff';
        // }
        // if(this.selectedChartsColorSet == "two"){
        // 	this.wigetBackgroundColor = '#fff2cc';
        // }
        // if(this.selectedChartsColorSet == "three"){
        // 	this.wigetBackgroundColor = '#cfe2f3';
        // }
      }
      if (this.value == 2) {
        this.wigetBackgroundColor = '#ffd670';
      }
      let xAxisFormatted = '';
      if (
        widget.yAxis.charAt(0) == '[' &&
        widget.yAxis.charAt(widget.yAxis.length - 1) == ']'
      ) {
        xAxisFormatted = JSON.parse(widget.yAxis)[0];
      } else {
        xAxisFormatted = widget.yAxis;
      }

      let options = {
        data: widgetdetails.body?.groupedQuery?.records ? widgetdetails.body?.groupedQuery?.records : widgetdetails.body.records,
        dataForDrillDown: widgetdetails.body?.groupedQuery?.records ? widgetdetails.body?.records : [],
        dataForTable: widgetdetails.body?.records,
        isDataDetailedForTable: false,
        keys: {
          label: xAxisFormatted,
          groupBars: JSON.parse(widget.xAxis),
        },
        chartTitle: widget.chartTitle,
        xAxisLable: '',
        yAxisLable: xAxisFormatted,
        showTitle: true,
        series: this.seriesVal,
        legend: this.legendvalue,
        colorSet: this.selectedChartsColorSet,
        BGcolor: this.wigetBackgroundColor, //"one" or "two" or "three";
        drillDownColumnName: widget.drillThru[0]?.drillDownColumn
          ? widget.drillThru[0].drillDownColumn
          : '',
        showTable: false,
        showComments: false,
        comments: [],
        resizeButtonID: document.getElementById('resizeBtn').id,
      };

      setTimeout(() => {
        acinfotech.horizontalGroupedBarChart(chartElement, options);
      }, 1000);
    }
  }
  //horizontalOppositeBarChart(chartElement,widget,widgetdetails){}
  //verticalOppositeBarChart(chartElement,widget,widgetdetails){}
  dotGauge(chartElement, widget, widgetdetails) {
    if (this.value == 1) {
      this.wigetBackgroundColor = this.backColor;
      // if(this.selectedChartsColorSet == "one"){
      // 	this.wigetBackgroundColor = '#ffffff';
      // }
      // if(this.selectedChartsColorSet == "two"){
      // 	this.wigetBackgroundColor = '#fff2cc';
      // }
      // if(this.selectedChartsColorSet == "three"){
      // 	this.wigetBackgroundColor = '#cfe2f3';
      // }
    }
    if (this.value == 2) {
      this.wigetBackgroundColor = '#ebb9b9';
    }
    let options = {
      data:
        widgetdetails.body.records == null
          ? widget.chartPlot
          : widgetdetails.body.records[0][widget.yAxis],
      chartTitle: widget.chartTitle,
      BGcolor: this.wigetBackgroundColor,
      showTitle: true,
      resizeButtonID: document.getElementById('resizeBtn').id,
    };
    setTimeout(() => {
      acinfotech.dotGauge(chartElement, options);
    }, 1000);
  }
  //squareBubbleChart(chartElement,widget,widgetdetails){}
  //magicQuadrant(chartElement,widget,widgetdetails){}
  //funnelChart(chartElement,widget,widgetdetails){}
  columnAndLinesChart(chartElement, widget, widgetdetails) {
    let fetchedData =
      widgetdetails.body.records == null
        ? 'No Records!'
        : widgetdetails.body.records.length > 0
          ? widgetdetails.body.records
          : 'No Records!';

    if (fetchedData == 'No Records!') {
      chartElement
        .append('div')
        .style({
          position: 'absolute',
          left: '0%',
          top: '0%',
          width: '100%',
          height: '8%',
          'background-color': this.wigetBackgroundColor,
          'text-indent': '6px',
        })
        .html(
          '<div  style="width: 100%; height:0%; background-color:{{ this.wigetBackgroundColor}}; position: absolute; top:10.0%; font-size:16px; font-family:inter; color:#00162E;">' +
          widget.chartTitle +
          '</div>'
        );

      chartElement
        .append('label')
        .style({
          position: 'absolute',
          left: '0%',
          top: '45%',
          width: '100%',
          'font-family': 'inter',
          'font-size': '30px',
          color: '#f16464',
          'text-align': 'center',
        })
        .text('No Records!');
    } else {
      if (this.value == 1) {
        this.wigetBackgroundColor = this.backColor;
        // if(this.selectedChartsColorSet == "one"){
        // 	this.wigetBackgroundColor = '#ffffff';
        // }
        // if(this.selectedChartsColorSet == "two"){
        // 	this.wigetBackgroundColor = '#fff2cc';
        // }
        // if(this.selectedChartsColorSet == "three"){
        // 	this.wigetBackgroundColor = '#cfe2f3';
        // }
      }
      if (this.value == 2) {
        this.wigetBackgroundColor = '#ffd670';
      }

      let xAxisFormatted = '';
      if (
        widget.yAxis.charAt(0) == '[' &&
        widget.yAxis.charAt(widget.yAxis.length - 1) == ']'
      ) {
        xAxisFormatted = JSON.parse(widget.yAxis)[0];
      } else {
        xAxisFormatted = widget.yAxis;
      }

      let options = {
        data: widgetdetails.body?.groupedQuery?.records ? widgetdetails.body?.groupedQuery?.records : widgetdetails.body.records,
        dataForTable: widgetdetails.body?.records,
        isDataDetailedForTable: false,
        keys: {
          label: xAxisFormatted, // X-Axis labels
          valueForColumn: JSON.parse(widget.xAxis)[0], // Single Value - left y axis
          valueForLines: JSON.parse(widget.xAxis).slice(1), // multiple value (array) - right y axis
          toolTipLabel:
            widget.chartPlot.split(',') > 0 ? widget.chartPlot.split(',') : [],
        },
        chartTitle: widget.chartTitle,
        xAxisLable: xAxisFormatted,
        //yAxisLable: "",
        showTitle: true,
        series: this.seriesVal,
        legend: this.legendvalue,
        BGcolor: this.wigetBackgroundColor,
        colorSet: this.selectedChartsColorSet, //"one" or "two" or "three";
        lineStyle: 'cardinal', // "cardinal" or "linear" or "step"
        drillDownColumnName: widget.drillThru[0]?.drillDownColumn
          ? widget.drillThru[0].drillDownColumn
          : '',
        showTable: false,
        showComments: false,
        comments: [],
        resizeButtonID: document.getElementById('resizeBtn').id,
      };

      setTimeout(() => {
        acinfotech.columnAndLinesChart(chartElement, options);
      }, 1000);
    }
  }
  threeXAxisesGroupedBarChart(chartElement, widget, widgetdetails) {
    let fetchedData =
      widgetdetails.body.records == null
        ? 'No Records!'
        : widgetdetails.body.records.length > 0
          ? widgetdetails.body.records
          : 'No Records!';

    if (fetchedData == 'No Records!') {
      chartElement
        .append('div')
        .style({
          position: 'absolute',
          left: '0%',
          top: '0%',
          width: '100%',
          height: '8%',
          'background-color': this.wigetBackgroundColor,
          'text-indent': '6px',
        })
        .html(
          '<div  style="width: 100%; height:0%; background-color:{{ this.wigetBackgroundColor}}; position: absolute; top:10.0%; font-size:16px; font-family:inter; color:#00162E;">' +
          widget.chartTitle +
          '</div>'
        );

      chartElement
        .append('label')
        .style({
          position: 'absolute',
          left: '0%',
          top: '45%',
          width: '100%',
          'font-family': 'inter',
          'font-size': '30px',
          color: '#f16464',
          'text-align': 'center',
        })
        .text('No Records!');
    } else {
      if (this.value == 1) {
        this.wigetBackgroundColor = this.backColor;
        // if(this.selectedChartsColorSet == "one"){
        // 	this.wigetBackgroundColor = '#ffffff';
        // }
        // if(this.selectedChartsColorSet == "two"){
        // 	this.wigetBackgroundColor = '#fff2cc';
        // }
        // if(this.selectedChartsColorSet == "three"){
        // 	this.wigetBackgroundColor = '#cfe2f3';
        // }
      }
      if (this.value == 2) {
        this.wigetBackgroundColor = '#ebb9b9';
      }
      let options = {
        data: widgetdetails.body?.groupedQuery?.records ? widgetdetails.body?.groupedQuery?.records : widgetdetails.body.records,
        keys: {
          labels: JSON.parse(widget.yAxis),
          groupBars: JSON.parse(widget.xAxis),
          toolTipLabel:
            widget.chartPlot.split(',') > 0 ? widget.chartPlot.split(',') : [],
        },
        chartTitle: widget.chartTitle,
        xAxisLables: JSON.parse(widget.yAxis),
        yAxisLable: '',
        showTitle: true,
        series: this.seriesVal,
        legend: this.legendvalue,
        colorSet: this.selectedChartsColorSet, //"one" or "two" or "three";
        BGcolor: this.wigetBackgroundColor,
        showComments: false,
        comments: [],
        resizeButtonID: document.getElementById('resizeBtn').id,
      };
      setTimeout(() => {
        acinfotech.threeXAxisesGroupedBarChart(chartElement, options);
      }, 1000);
    }
  }
  twoXAxisesGroupedBarChart(chartElement, widget, widgetdetails) {
    let fetchedData =
      widgetdetails.body.records == null
        ? 'No Records!'
        : widgetdetails.body.records.length > 0
          ? widgetdetails.body.records
          : 'No Records!';

    if (fetchedData == 'No Records!') {
      chartElement
        .append('div')
        .style({
          position: 'absolute',
          left: '0%',
          top: '0%',
          width: '100%',
          height: '8%',
          'background-color': this.wigetBackgroundColor,
          'text-indent': '6px',
        })
        .html(
          '<div  style="width: 100%; height:0%; background-color:{{ this.wigetBackgroundColor}}; position: absolute; top:10.0%; font-size:16px; font-family:inter; color:#00162E;">' +
          widget.chartTitle +
          '</div>'
        );

      chartElement
        .append('label')
        .style({
          position: 'absolute',
          left: '0%',
          top: '45%',
          width: '100%',
          'font-family': 'inter',
          'font-size': '30px',
          color: '#f16464',
          'text-align': 'center',
        })
        .text('No Records!');
    } else {
      if (this.value == 1) {
        this.wigetBackgroundColor = this.backColor;
        // if(this.selectedChartsColorSet == "one"){
        // 	this.wigetBackgroundColor = '#ffffff';
        // }
        // if(this.selectedChartsColorSet == "two"){
        // 	this.wigetBackgroundColor = '#fff2cc';
        // }
        // if(this.selectedChartsColorSet == "three"){
        // 	this.wigetBackgroundColor = '#cfe2f3';
        // }
      }
      if (this.value == 2) {
        this.wigetBackgroundColor = '#ebb9b9';
      }
      let options = {
        data: widgetdetails.body?.groupedQuery?.records ? widgetdetails.body?.groupedQuery?.records : widgetdetails.body.records,
        dataForDrillDown: widgetdetails.body?.groupedQuery?.records ? widgetdetails.body?.records : [],
        keys: {
          labels: JSON.parse(widget.yAxis),
          groupBars: JSON.parse(widget.xAxis),
          toolTipLabel:
            widget.chartPlot.split(',') > 0 ? widget.chartPlot.split(',') : [],
        },
        chartTitle: widget.chartTitle,
        xAxisLables: JSON.parse(widget.yAxis),
        //yAxisLable: "",
        showTitle: true,
        series: this.seriesVal,
        legend: this.legendvalue,
        colorSet: this.selectedChartsColorSet, //"one" or "two" or "three";
        BGcolor: this.wigetBackgroundColor,
        drillDownColumnName: widget.drillThru[0]?.drillDownColumn
          ? widget.drillThru[0].drillDownColumn
          : '',
        showComments: false,
        comments: [],
        resizeButtonID: document.getElementById('resizeBtn').id,
      };
      setTimeout(() => {
        acinfotech.twoXAxisesGroupedBarChart(chartElement, options);
      }, 1000);
    }
  }
  bellCurveChartWithMinAndMaxRange(chartElement, widget, widgetdetails) {
    let fetchedData =
      widgetdetails.body.records == null
        ? 'No Records!'
        : widgetdetails.body.records.length > 0
          ? widgetdetails.body.records
          : 'No Records!';

    if (fetchedData == 'No Records!') {
      chartElement
        .append('div')
        .style({
          position: 'absolute',
          left: '0%',
          top: '0%',
          width: '100%',
          height: '8%',
          'background-color': this.wigetBackgroundColor,
          'text-indent': '6px',
        })
        .html(
          '<div  style="width: 100%; height:0%; background-color:{{ this.wigetBackgroundColor}}; position: absolute; top:10.0%; font-size:16px; font-family:inter; color:#00162E;">' +
          widget.chartTitle +
          '</div>'
        );

      chartElement
        .append('label')
        .style({
          position: 'absolute',
          left: '0%',
          top: '45%',
          width: '100%',
          'font-family': 'inter',
          'font-size': '30px',
          color: '#f16464',
          'text-align': 'center',
        })
        .text('No Records!');
    } else {
      if (this.value == 1) {
        this.wigetBackgroundColor = this.backColor;
        // if(this.selectedChartsColorSet == "one"){
        // 	this.wigetBackgroundColor = '#ffffff';
        // }
        // if(this.selectedChartsColorSet == "two"){
        // 	this.wigetBackgroundColor = '#fff2cc';
        // }
        // if(this.selectedChartsColorSet == "three"){
        // 	this.wigetBackgroundColor = '#cfe2f3';
        // }
      }
      if (this.value == 2) {
        this.wigetBackgroundColor = '#ebb9b9';
      }
      let options = {
        data: widgetdetails.body?.groupedQuery?.records ? widgetdetails.body?.groupedQuery?.records : widgetdetails.body.records,
        keys: {
          label: JSON.parse(widget.yAxis[0]),
          value: JSON.parse(widget.xAxis[0]),
          minValue: JSON.parse(widget.xAxis[1]),
          maxValue: JSON.parse(widget.xAxis[2]),
          minAndMaxLines: JSON.parse(widget.yAxis[0]),
          toolTipLabel:
            widget.chartPlot.split(',') > 0 ? widget.chartPlot.split(',') : [],
        },
        chartTitle: widget.chartTitle,
        showTitle: true,
        series: this.seriesVal,
        legend: this.legendvalue,
        colorSet: this.selectedChartsColorSet, //"one" or "two" or "three";
        BGcolor: this.wigetBackgroundColor,
        resizeButtonID: document.getElementById('resizeBtn').id,
      };

      setTimeout(() => {
        acinfotech.bellCurveChartWithMinAndMaxRange(chartElement, options);
      }, 1000);
    }
  }
  stackedGroupedBarChart(chartElement, widget, widgetdetails) {
    let fetchedData =
      widgetdetails.body.records == null
        ? 'No Records!'
        : widgetdetails.body.records.length > 0
          ? widgetdetails.body.records
          : 'No Records!';

    if (fetchedData == 'No Records!') {
      chartElement
        .append('div')
        .style({
          position: 'absolute',
          left: '0%',
          top: '0%',
          width: '100%',
          height: '8%',
          'background-color': this.wigetBackgroundColor,
          'text-indent': '6px',
        })
        .html(
          '<div  style="width: 100%; height:0%; background-color:{{ this.wigetBackgroundColor}}; position: absolute; top:10.0%; font-size:16px; font-family:inter; color:#00162E;">' +
          widget.chartTitle +
          '</div>'
        );

      chartElement
        .append('label')
        .style({
          position: 'absolute',
          left: '0%',
          top: '45%',
          width: '100%',
          'font-family': 'inter',
          'font-size': '30px',
          color: '#f16464',
          'text-align': 'center',
        })
        .text('No Records!');
    } else {
      if (this.value == 1) {
        this.wigetBackgroundColor = this.backColor;
        // if(this.selectedChartsColorSet == "one"){
        // 	this.wigetBackgroundColor = '#ffffff';
        // }
        // if(this.selectedChartsColorSet == "two"){
        // 	this.wigetBackgroundColor = '#fff2cc';
        // }
        // if(this.selectedChartsColorSet == "three"){
        // 	this.wigetBackgroundColor = '#cfe2f3';
        // }
      }
      if (this.value == 2) {
        this.wigetBackgroundColor = '#ebb9b9';
      }
      let options = {
        data: widgetdetails.body?.groupedQuery?.records ? widgetdetails.body?.groupedQuery?.records : widgetdetails.body.records,
        keys: {
          labels: JSON.parse(widget.yAxis),
          groupBars: JSON.parse(widget.xAxis),
          toolTipLabel:
            widget.chartPlot.split(',') > 0 ? widget.chartPlot.split(',') : [],
        },
        chartTitle: widget.chartTitle,
        xAxisLables: JSON.parse(widget.yAxis),
        //yAxisLable: "",
        showTitle: true,
        series: this.seriesVal,
        legend: this.legendvalue,
        colorSet: this.selectedChartsColorSet, //"one" or "two" or "three";
        BGcolor: this.wigetBackgroundColor,
        drillDownColumnName: widget.drillThru[0]?.drillDownColumn
          ? widget.drillThru[0].drillDownColumn
          : '',
        showTable: false,
        showComments: false,
        comments: [],
        resizeButtonID: document.getElementById('resizeBtn').id,
      };

      setTimeout(() => {
        acinfotech.stackedGroupedBarChart(chartElement, options);
      }, 1000);
    }
  }
  stackedGroupedBarsWithLineChart(chartElement, widget, widgetdetails) {
    let fetchedData =
      widgetdetails.body.records == null
        ? 'No Records!'
        : widgetdetails.body.records.length > 0
          ? widgetdetails.body.records
          : 'No Records!';

    if (fetchedData == 'No Records!') {
      chartElement
        .append('div')
        .style({
          position: 'absolute',
          left: '0%',
          top: '0%',
          width: '100%',
          height: '8%',
          'background-color': this.wigetBackgroundColor,
          'text-indent': '6px',
        })
        .html(
          '<div  style="width: 100%; height:0%; background-color:{{ this.wigetBackgroundColor}}; position: absolute; top:10.0%; font-size:16px; font-family:inter; color:#00162E;">' +
          widget.chartTitle +
          '</div>'
        );

      chartElement
        .append('label')
        .style({
          position: 'absolute',
          left: '0%',
          top: '45%',
          width: '100%',
          'font-family': 'inter',
          'font-size': '30px',
          color: '#f16464',
          'text-align': 'center',
        })
        .text('No Records!');
    } else {
      if (this.value == 1) {
        this.wigetBackgroundColor = this.backColor;
        // if(this.selectedChartsColorSet == "one"){
        // 	this.wigetBackgroundColor = '#ffffff';
        // }
        // if(this.selectedChartsColorSet == "two"){
        // 	this.wigetBackgroundColor = '#fff2cc';
        // }
        // if(this.selectedChartsColorSet == "three"){
        // 	this.wigetBackgroundColor = '#cfe2f3';
        // }
      }
      if (this.value == 2) {
        this.wigetBackgroundColor = '#ebb9b9';
      }
      let options = {
        data: widgetdetails.body?.groupedQuery?.records ? widgetdetails.body?.groupedQuery?.records : widgetdetails.body.records,
        keys: {
          labels: JSON.parse(widget.yAxis),
          groupBars: JSON.parse(widget.xAxis),
          lines: JSON.parse(widget.secondaryYaxis),
          toolTipLabel:
            widget.chartPlot.split(',') > 0 ? widget.chartPlot.split(',') : [],
        },
        chartTitle: widget.chartTitle,
        xAxisLables: JSON.parse(widget.yAxis),
        //yAxisLable: "",
        showTitle: true,
        series: this.seriesVal,
        legend: this.legendvalue,
        colorSet: this.selectedChartsColorSet, //"one" or "two" or "three";
        BGcolor: this.wigetBackgroundColor,
        drillDownColumnName: widget.drillThru[0]?.drillDownColumn
          ? widget.drillThru[0].drillDownColumn
          : '',
        showTable: false,
        showComments: false,
        comments: [],
        resizeButtonID: document.getElementById('resizeBtn').id,
      };

      setTimeout(() => {
        acinfotech.stackedGroupedBarsWithLineChart(chartElement, options);
      }, 1000);
    }
  }
  stackedBarWithLinesChart(chartElement, widget, widgetdetails) {
    let fetchedData =
      widgetdetails.body.records == null
        ? 'No Records!'
        : widgetdetails.body.records.length > 0
          ? widgetdetails.body.records
          : 'No Records!';

    if (fetchedData == 'No Records!') {
      chartElement
        .append('div')
        .style({
          position: 'absolute',
          left: '0%',
          top: '0%',
          width: '100%',
          height: '8%',
          'background-color': this.wigetBackgroundColor,
          'text-indent': '6px',
        })
        .html(
          '<div  style="width: 100%; height:0%; background-color:{{ this.wigetBackgroundColor}}; position: absolute; top:10.0%; font-size:16px; font-family:inter; color:#00162E;">' +
          widget.chartTitle +
          '</div>'
        );

      chartElement
        .append('label')
        .style({
          position: 'absolute',
          left: '0%',
          top: '45%',
          width: '100%',
          'font-family': 'inter',
          'font-size': '30px',
          color: '#f16464',
          'text-align': 'center',
        })
        .text('No Records!');
    } else {
      if (this.value == 1) {
        this.wigetBackgroundColor = this.backColor;
        // if(this.selectedChartsColorSet == "one"){
        // 	this.wigetBackgroundColor = '#ffffff';
        // }
        // if(this.selectedChartsColorSet == "two"){
        // 	this.wigetBackgroundColor = '#fff2cc';
        // }
        // if(this.selectedChartsColorSet == "three"){
        // 	this.wigetBackgroundColor = '#cfe2f3';
        // }
      }
      if (this.value == 2) {
        this.wigetBackgroundColor = '#ebb9b9';
      }
      let xAxisFormatted = '';
      if (
        widget.yAxis.charAt(0) == '[' &&
        widget.yAxis.charAt(widget.yAxis.length - 1) == ']'
      ) {
        xAxisFormatted = JSON.parse(widget.yAxis)[0];
      } else {
        xAxisFormatted = widget.yAxis;
      }

      let options = {
        data: widgetdetails.body?.groupedQuery?.records ? widgetdetails.body?.groupedQuery?.records : widgetdetails.body.records,
        dataForTable: widgetdetails.body?.records,
        isDataDetailedForTable: false,
        keys: {
          label: xAxisFormatted,
          stackedBars: JSON.parse(widget.xAxis),
          lines: JSON.parse(widget.secondaryYaxis),
          toolTipLabel:
            widget.chartPlot.split(',') > 0 ? widget.chartPlot.split(',') : [],
        },
        chartTitle: widget.chartTitle,
        xAxisLable: xAxisFormatted,
        yAxisLable: '',
        showTitle: true,
        series: this.seriesVal,
        legend: this.legendvalue,
        colorSet: this.selectedChartsColorSet, //"one" or "two" or "three";
        BGcolor: this.wigetBackgroundColor,
        drillDownColumnName: widget.drillThru[0]?.drillDownColumn
          ? widget.drillThru[0].drillDownColumn
          : '',
        showTable: false,
        showComments: false,
        comments: [],
        resizeButtonID: document.getElementById('resizeBtn').id,
      };

      setTimeout(() => {
        acinfotech.stackedBarWithLinesChart(chartElement, options);
      }, 1000);
    }
  }
  trafficLightsChart(chartElement, widget, widgetdetails) {
    let fetchedData =
      widgetdetails.body.records == null
        ? 'No Records!'
        : widgetdetails.body.records.length > 0
          ? widgetdetails.body.records
          : 'No Records!';

    if (fetchedData == 'No Records!') {
      chartElement
        .append('div')
        .style({
          position: 'absolute',
          left: '0%',
          top: '0%',
          width: '100%',
          height: '8%',
          'background-color': this.wigetBackgroundColor,
          'text-indent': '6px',
        })
        .html(
          '<div  style="width: 100%; height:0%; background-color:{{ this.wigetBackgroundColor}}; position: absolute; top:10.0%; font-size:16px; font-family:inter; color:#00162E;">' +
          widget.chartTitle +
          '</div>'
        );

      chartElement
        .append('label')
        .style({
          position: 'absolute',
          left: '0%',
          top: '45%',
          width: '100%',
          'font-family': 'inter',
          'font-size': '30px',
          color: '#f16464',
          'text-align': 'center',
        })
        .text('No Records!');
    } else {
      if (this.value == 1) {
        this.wigetBackgroundColor = this.backColor;
        // if(this.selectedChartsColorSet == "one"){
        // 	this.wigetBackgroundColor = '#ffffff';
        // }
        // if(this.selectedChartsColorSet == "two"){
        // 	this.wigetBackgroundColor = '#fff2cc';
        // }
        // if(this.selectedChartsColorSet == "three"){
        // 	this.wigetBackgroundColor = '#cfe2f3';
        // }
      }
      if (this.value == 2) {
        this.wigetBackgroundColor = '#ebb9b9';
      }
      let xAxisFormatted = '';
      if (
        widget.yAxis.charAt(0) == '[' &&
        widget.yAxis.charAt(widget.yAxis.length - 1) == ']'
      ) {
        xAxisFormatted = JSON.parse(widget.yAxis)[0];
      } else {
        xAxisFormatted = widget.yAxis;
      }

      let yAxisFormatted = '';
      if (
        widget.xAxis.charAt(0) == '[' &&
        widget.xAxis.charAt(widget.xAxis.length - 1) == ']'
      ) {
        yAxisFormatted = JSON.parse(widget.xAxis)[0];
      } else {
        yAxisFormatted = widget.xAxis;
      }

      let options = {
        data: widgetdetails.body?.groupedQuery?.records ? widgetdetails.body?.groupedQuery?.records : widgetdetails.body.records,
        dataForTable: widgetdetails.body?.records,
        isDataDetailedForTable: false,
        keys: {
          label: xAxisFormatted,
          value: yAxisFormatted,
          toolTipLabel:
            widget.chartPlot.split(',') > 0 ? widget.chartPlot.split(',') : [],
        },
        chartTitle: widget.chartTitle,
        showTitle: true,
        series: this.seriesVal,
        legend: this.legendvalue,
        BGcolor: this.wigetBackgroundColor,
        drillDownColumnName: widget.drillThru[0]?.drillDownColumn
          ? widget.drillThru[0].drillDownColumn
          : '',
        showTable: false,
        showComments: false,
        comments: [],
        resizeButtonID: document.getElementById('resizeBtn').id,
      };

      setTimeout(() => {
        acinfotech.trafficLightsChart(chartElement, options);
      }, 1000);
    }
  }
  columnChartWithNegativeValues(chartElement, widget, widgetdetails) {
    let fetchedData =
      widgetdetails.body.records == null
        ? 'No Records!'
        : widgetdetails.body.records.length > 0
          ? widgetdetails.body.records
          : 'No Records!';

    if (fetchedData == 'No Records!') {
      chartElement
        .append('div')
        .style({
          position: 'absolute',
          left: '0%',
          top: '0%',
          width: '100%',
          height: '8%',
          'background-color': this.wigetBackgroundColor,
          'text-indent': '6px',
        })
        .html(
          '<div  style="width: 100%; height:0%; background-color:{{ this.wigetBackgroundColor}}; position: absolute; top:10.0%; font-size:16px; font-family:inter; color:#00162E;">' +
          widget.chartTitle +
          '</div>'
        );

      chartElement
        .append('label')
        .style({
          position: 'absolute',
          left: '0%',
          top: '45%',
          width: '100%',
          'font-family': 'inter',
          'font-size': '30px',
          color: '#f16464',
          'text-align': 'center',
        })
        .text('No Records!');
    } else {
      if (this.value == 1) {
        this.wigetBackgroundColor = this.backColor;
        // if(this.selectedChartsColorSet == "one"){
        // 	this.wigetBackgroundColor = '#ffffff';
        // }
        // if(this.selectedChartsColorSet == "two"){
        // 	this.wigetBackgroundColor = '#fff2cc';
        // }
        // if(this.selectedChartsColorSet == "three"){
        // 	this.wigetBackgroundColor = '#cfe2f3';
        // }
      }
      if (this.value == 2) {
        this.wigetBackgroundColor = '#ebb9b9';
      }
      let xAxisFormatted = '';
      if (
        widget.yAxis.charAt(0) == '[' &&
        widget.yAxis.charAt(widget.yAxis.length - 1) == ']'
      ) {
        xAxisFormatted = JSON.parse(widget.yAxis)[0];
      } else {
        xAxisFormatted = widget.yAxis;
      }

      let yAxisFormatted = '';
      if (
        widget.xAxis.charAt(0) == '[' &&
        widget.xAxis.charAt(widget.xAxis.length - 1) == ']'
      ) {
        yAxisFormatted = JSON.parse(widget.xAxis)[0];
      } else {
        yAxisFormatted = widget.xAxis;
      }

      let options = {
        data: widgetdetails.body?.groupedQuery?.records ? widgetdetails.body?.groupedQuery?.records : widgetdetails.body.records,
        dataForTable: widgetdetails.body?.records, // for table
        isDataDetailedForTable: false,
        keys: {
          label: xAxisFormatted,
          value: yAxisFormatted,
        },
        chartTitle: widget.chartTitle,
        xAxisLable: xAxisFormatted,
        yAxisLable: yAxisFormatted,
        showTitle: true,
        series: this.seriesVal,
        legend: this.legendvalue,
        colorSet: this.selectedChartsColorSet, //one or two or three
        BGcolor: this.wigetBackgroundColor,
        drillDownColumnName: widget.drillThru[0]?.drillDownColumn
          ? widget.drillThru[0].drillDownColumn
          : '',
        showTable: false, //false - hide, true - show
        showComments: false,
        comments: [],
        resizeButtonID: document.getElementById('resizeBtn').id,
      };

      setTimeout(() => {
        acinfotech.columnChartWithNegativeValues(chartElement, options);
      }, 1000);
    }
  }
  groupedBarsWithLineChart(chartElement, widget, widgetdetails) {
    let fetchedData =
      widgetdetails.body.records == null
        ? 'No Records!'
        : widgetdetails.body.records.length > 0
          ? widgetdetails.body.records
          : 'No Records!';

    if (fetchedData == 'No Records!') {
      chartElement
        .append('div')
        .style({
          position: 'absolute',
          left: '0%',
          top: '0%',
          width: '100%',
          height: '8%',
          'background-color': this.wigetBackgroundColor,
          'text-indent': '6px',
        })
        .html(
          '<div  style="width: 100%; height:0%; background-color:{{ this.wigetBackgroundColor}}; position: absolute; top:10.0%; font-size:16px; font-family:inter; color:#00162E;">' +
          widget.chartTitle +
          '</div>'
        );

      chartElement
        .append('label')
        .style({
          position: 'absolute',
          left: '0%',
          top: '45%',
          width: '100%',
          'font-family': 'inter',
          'font-size': '30px',
          color: '#f16464',
          'text-align': 'center',
        })
        .text('No Records!');
    } else {
      if (this.value == 1) {
        this.wigetBackgroundColor = this.backColor;
        // if(this.selectedChartsColorSet == "one"){
        // 	this.wigetBackgroundColor = '#ffffff';
        // }
        // if(this.selectedChartsColorSet == "two"){
        // 	this.wigetBackgroundColor = '#fff2cc';
        // }
        // if(this.selectedChartsColorSet == "three"){
        // 	this.wigetBackgroundColor = '#cfe2f3';
        // }
      }
      if (this.value == 2) {
        this.wigetBackgroundColor = '#ebb9b9';
      }
      let xAxisFormatted = '';
      if (
        widget.yAxis.charAt(0) == '[' &&
        widget.yAxis.charAt(widget.yAxis.length - 1) == ']'
      ) {
        xAxisFormatted = JSON.parse(widget.yAxis)[0];
      } else {
        xAxisFormatted = widget.yAxis;
      }

      let options = {
        data: widgetdetails.body?.groupedQuery?.records ? widgetdetails.body?.groupedQuery?.records : widgetdetails.body.records,
        dataForTable: widgetdetails.body?.records,
        isDataDetailedForTable: false,
        keys: {
          label: xAxisFormatted,
          groupBars: JSON.parse(widget.xAxis),
          lines: JSON.parse(widget.secondaryYaxis),
        },
        chartTitle: widget.chartTitle,
        xAxisLable: xAxisFormatted,
        yAxisLable: '',
        showTitle: true,
        series: this.seriesVal,
        legend: this.legendvalue,
        colorSet: this.selectedChartsColorSet, //"one" or "two" or "three";
        BGcolor: this.wigetBackgroundColor,
        drillDownColumnName: widget.drillThru[0]?.drillDownColumn
          ? widget.drillThru[0].drillDownColumn
          : '',
        showTable: false,
        showComments: true,
        comments: [],
        resizeButtonID: document.getElementById('resizeBtn').id,
      };

      setTimeout(() => {
        acinfotech.groupedBarsWithLineChart(chartElement, options);
      }, 1000);
    }
  }
  USMapWithPins(chartElement, widget, widgetdetails) {
    let fetchedData =
      widgetdetails.body.records == null
        ? 'No Records!'
        : widgetdetails.body.records.length > 0
          ? widgetdetails.body.records
          : 'No Records!';

    if (fetchedData == 'No Records!') {
      chartElement
        .append('div')
        .style({
          position: 'absolute',
          left: '0%',
          top: '0%',
          width: '100%',
          height: '8%',
          'background-color': this.wigetBackgroundColor,
          'text-indent': '6px',
        })
        .html(
          '<div  style="width: 100%; height:0%; background-color:{{ this.wigetBackgroundColor}}; position: absolute; top:10.0%; font-size:16px; font-family:inter; color:#00162E;">' +
          widget.chartTitle +
          '</div>'
        );

      chartElement
        .append('label')
        .style({
          position: 'absolute',
          left: '0%',
          top: '45%',
          width: '100%',
          'font-family': 'inter',
          'font-size': '30px',
          color: '#f16464',
          'text-align': 'center',
        })
        .text('No Records!');
    } else {
      if (this.value == 1) {
        this.wigetBackgroundColor = this.backColor;
        // if(this.selectedChartsColorSet == "one"){
        // 	this.wigetBackgroundColor = '#ffffff';
        // }
        // if(this.selectedChartsColorSet == "two"){
        // 	this.wigetBackgroundColor = '#fff2cc';
        // }
        // if(this.selectedChartsColorSet == "three"){
        // 	this.wigetBackgroundColor = '#cfe2f3';
        // }
      }
      if (this.value == 2) {
        this.wigetBackgroundColor = '#ebb9b9';
      }
      let xAxisFormatted = '';
      if (
        widget.yAxis.charAt(0) == '[' &&
        widget.yAxis.charAt(widget.yAxis.length - 1) == ']'
      ) {
        xAxisFormatted = JSON.parse(widget.yAxis)[0];
      } else {
        xAxisFormatted = widget.yAxis;
      }

      let yAxisFormatted = '';
      if (
        widget.xAxis.charAt(0) == '[' &&
        widget.xAxis.charAt(widget.xAxis.length - 1) == ']'
      ) {
        yAxisFormatted = JSON.parse(widget.xAxis)[0];
      } else {
        yAxisFormatted = widget.xAxis;
      }

      let options = {
        data: widgetdetails.body?.groupedQuery?.records ? widgetdetails.body?.groupedQuery?.records : widgetdetails.body.records,
        dataForTable: widgetdetails.body?.records,
        keys: {
          stateCode: xAxisFormatted,
          latitude: 'LATITUDE',
          longitude: 'LONGITUDE',
          state: widget.chartPlot,
          place: yAxisFormatted,
          value: JSON.parse(widget.secondaryYaxis)[0],
        },
        chartTitle: widget.chartTitle,
        showTable: false,
        series: this.seriesVal,
        legend: this.legendvalue,
        BGcolor: this.wigetBackgroundColor,
        drillDownColumnName: widget.drillThru[0]?.drillDownColumn
          ? widget.drillThru[0].drillDownColumn
          : '',
        resizeButtonID: document.getElementById('resizeBtn').id,
      };

      setTimeout(() => {
        acinfotech.USMapWithPins(chartElement, options);
      }, 1500);
    }
  }

  //---------------------------------------------------------------------------------------------

  addSingle() {
    this.messageService.add({
      severity: 'error',
      summary: '',
      detail: this.errorMsg,
    });
  }

  clear() {
    this.messageService.clear();
  }
  showComment() {
    this.showCommentsDialog = true;
  }
  onChange(event, ref, i) {
    this.requestPayloadParametersArray = []
    //console.log(event);
    this.trackerPayload.clicode = event;
    var value = event;
    var obj =
      this.parameterElementsArray[
      Number(ref.el.nativeElement.attributes.selectInfoIndex.value)
      ];
    if (event === "") {
      obj.paramElementFilledBool = false;
    } else {
      obj.paramElementFilledBool = true;
    }

    this.requestPayloadParametersArray =
      this.requestPayloadParametersArray.filter(
        (obje) => obje.id !== obj.dashboardParameterId
      );
    var parameterArrayObj1 = {
      id: obj.dashboardParameterId,
      isVisible: obj.isVisible,
      name: obj.parameterName,
      questionId: obj.questionOrderBy,
      value: value,
    };
    let obj1 = {
      "name": "User", "value": localStorage.userEmail, "questionId": 1234567890, "id": 1234567890, "isVisible": "Y"
    }
    this.requestPayloadParametersArray.push(obj1);
    this.requestPayloadParametersArray.push(parameterArrayObj1);
    //console.log(this.requestPayloadParametersArray)
  }
  onFilterSubmitClick() {
    var showAlertBool: Boolean;
    this.parameterElementsArray.forEach((ele) => {
      if (ele.isMandatory == 'Y' && ele.paramElementFilledBool != true) {
        showAlertBool = true;
      }
    })
    if (showAlertBool == true) {
      this.errorMsg = "please fill mandatory field"
      this.addSingle();
    } else {
      this.clearMessages();
      this.showResult = true;
      this.discoveryService.trackerSearch(this.trackerPayload, "DataDiscovery").subscribe((res: any) => {
      })
      this.getParameterDashboard();
    }
  }
  clearMessages() {
    this.alertMessage = [];
  }
  dropdownChange(event) {
    //console.log(event);
    //console.log(this.selectedClient)
        // }
    this.showSearch=true;
    if(this.myText){
      this.oldApi("auto",'')
    }
    this.showTour();
  }
  showTour(){
    let tour = this.tourGuideService.getTourDetails();
    // if(tour.isActive()){
    let tourInfo = localStorage.getItem('isdiscoveryTourDone');
    if(tourInfo == 'false'){
      tour.steps.splice(1,1)
      tour.addStep({
        id: 'shepherd-askTab',
        attachTo: {
          element: "#shepherd-askTab",
          on: 'bottom',
        },
        text: "Get answers by asking questions related to your reports",
        title: "Ask Question",
        buttons: [
          {
            classes: 'shepherd-button-secondary',
            text: 'Skip',
            action: tour.cancel,
          },
          {
            classes: 'shepherd-button-secondary',
            text: 'Back',
            action: tour.back,
          },
          {
            classes: 'shepherd-button-primary',
            text: 'Next',
            action: tour.next,
          },
        ],
        beforeShowPromise: () =>
          new Promise((resolve) => {
            const interval = setInterval(() => {
              if (document.querySelector('#shepherd-askTab')) {
                clearInterval(interval);
                resolve(true);
              }
            }, 100);
          }),
      });
      this.tourGuideService.getTourEnd();
      this.tourGuideService.disableClick();
      tour.next();
      tour.on('complete',() => {
        localStorage.setItem(`isdiscoveryTourDone`, 'true');
      });
      tour.on('cancel', () => {
        localStorage.setItem(`isdiscoveryTourDone`, 'true');
      });
    }
  }
}
