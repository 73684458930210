import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AppComponentService {

  private schduledReports_BS: any = new BehaviorSubject([]);
  currentSchduledReports = this.schduledReports_BS.asObservable();
  private user_BS: any = new BehaviorSubject([]);
  currentUser = this.user_BS.asObservable();
  private role_BS: any = new BehaviorSubject([]);
  currentRole = this.role_BS.asObservable();
  private config_BS: any = new BehaviorSubject([]);
  currentConfig = this.config_BS.asObservable();
  private domain_BS: any = new BehaviorSubject([]);
  currentDomain = this.domain_BS.asObservable();
  private widget_BS: any = new BehaviorSubject([]);
  currentWidget = this.widget_BS.asObservable();

  constructor(private http: HttpClient) { }
  private myGlobalVariable: any;
  private myGlobalVariable1: any;
  private myLists:any;

	setGlobalVariable(value: any): void {
		this.myGlobalVariable = value;
	}
  setGlobalVariable1(value: any): void {
		this.myGlobalVariable1 = value;
	}
	getGlobalVariable(): any {
		return this.myGlobalVariable;
	}
  getGlobalVariable1(): any {
		return this.myGlobalVariable1;
	}
  getLists(){
    return this.myLists;
  }
  setLists(value){
    this.myLists=value;
  }
  recordsService(page, requestPayload) {
    return this.http.post(
      'api/v1/job-logs/search?limit=150&page=' + page,
      requestPayload,
      {
        headers: { 'X-Auth-Token': localStorage.getItem('token') },
        observe: 'response',
      }
    );
  }

  menuListGetService() {
    return this.http.get('assets/json/menu.json');
  }

  changesMessage(value: any) {
    this.schduledReports_BS.next(value);
  }

  getUserDetails() {
    return this.http.get('assets/json/user.json')
  }

  userMessage(id: any) {
    this.user_BS.next(id);
  }

  getRoleDetails(id:any) {
    return this.http.get('assets/json/role.json')
  }

  userRoleMessage(id:any){
    this.role_BS.next(id);
  }

  config() {
    return this.http.get('assets/json/config.json')
  }

  configMessage(id: any) {
    this.config_BS.next(id);
  }
  dashboardsListService() {
    return this.http.get('api/v1/dashboard/domain/widgets', { headers: { 'X-Auth-Token': localStorage.getItem("token") }, observe: 'response' })
  }

  domainList(data: any) {
    this.domain_BS.next(data)
  }

  dashboardsList() {
    return this.http.get('api/v1/dashboard/widgets', { headers: { 'X-Auth-Token': localStorage.getItem("token") }, observe: 'response' })
  }

  widgetList(data: any) {
    this.widget_BS.next(data)
  }
  getHeaders() {
    let headers = new HttpHeaders();
    headers.append('Accept', 'application/json');
    headers.append('Access-Control-Allow-Origin', '*');
    return headers;
}
tokenService(body: any) {
  return this.http.post('api/user/authenticate', body, 
      { headers: this.getHeaders() }).pipe(map(
          (response) => {
              return response;
          }
      ))
  }
  setChangeProfile(payload) {
    return this.http.put('api/v1/profile/user', payload, { headers: { 'X-Auth-Token': localStorage.getItem("token") }, observe: 'response' });
  }
  setChangePassword(payload) {
    return this.http.put('api/v1/profile/user/password', payload, { headers: { 'X-Auth-Token': localStorage.getItem("token") }, observe: 'response' });
  }
  
  setTourGuideStatus(payload) {
    return this.http.put('api/v1/user/config', payload, {
      headers: { 'X-Auth-Token': localStorage.getItem('token') },
      observe: 'response',
    });
  }
}
