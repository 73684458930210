import { HttpClient ,HttpHeaders} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Customer, Leave } from './customer';
import { Observable } from 'rxjs';

@Injectable({providedIn:'root'})
export class BuildReportService {
    constructor(private http: HttpClient) {}

    getApiData(param){
        return this.http.get('assets/json/buildAreport/'+param+'qa.json')
    }
    getSavedFilter(param){
        return this.http.get('assets/json/buildAreport/saveFilter.json')
    }
    headerData(param,payload){
        return this.http.post('api/v1/datasource/'+param+'/tables/headers', payload,
        { headers: {'X-Auth-Token': localStorage.getItem("token")}, observe: 'response' })
    }
    setfilter(param,query,payload){//api/v1/custom-reports/233/saved-filters?name=testtt
        return this.http.post('api/v1/custom-reports/'+ param +'/saved-filters',payload, 
        {params:query, headers: {'X-Auth-Token': localStorage.getItem("token")}, observe: 'response' })
    }
    deleteSavedFilter(param){
        return this.http.delete('api/v1/custom-reports/saved-filters/'+ param , 
        { headers: {'X-Auth-Token': localStorage.getItem("token")}, observe: 'response' })
    }
    getReportData(){
        return this.http.get('assets/json/buildAreport/customReport.json')
    }
    getFilterData(){
        return this.http.get('api/v1/custom-reports/233/saved-filters', 
        { headers: {'X-Auth-Token': localStorage.getItem("token")}, observe: 'response' }) 
    }
    getRunData(payload,page,size){
        return this.http.get('assets/json/buildAreport/'+payload+'run.json')
    }
    //api/reports/requestor/subgroup/config/label/648
    getClientData(id){
        return this.http.get('api/reports/requestor/subgroup/config/label/'+id,
		{ headers: {'X-Auth-Token': localStorage.getItem("token")}, observe: 'response' })
    }
    getDomain(id:any){
        return this.http.get('assets/json/buildAreport/customReport.json')
      
    }
    downloadData(data){
        return this.http.post('api/v1/custom-reports/export?format=xlsx', data,
        { headers: {'X-Auth-Token': localStorage.getItem("token")}, observe: 'response' })
    }
    downloadTableRecordsService2(name) {
		return this.http.get('api/v1/custom-reports/export?uniqueName='+name, {responseType: 'arraybuffer', headers: {'X-Auth-Token': localStorage.getItem("token")}})
	}
}
