import {
  Component,
  OnInit,
  OnDestroy,
  ElementRef,
  ChangeDetectorRef,
} from '@angular/core';
import { MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { DashboardInfo } from './dashboard-info';
import { ReportsInfo } from './reports-info';
import { ConfirmationService } from 'primeng/api';
import { Router } from '@angular/router';
import { FormControl, FormGroup } from '@angular/forms';
import { HomeService } from './home.service';
import { AppComponent } from '../app.component';
import { AppComponentService } from '../app-component.service';
import { TourGuideService } from '../tour-guide.service';
declare var d3: any;
declare var acinfotech: any;
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  providers: [
    MessageService,
    DialogService,
    DashboardInfo,
    ConfirmationService,
  ],
})
export class HomeComponent implements OnInit, OnDestroy {
  accountForm = new FormGroup({
    firstName: new FormControl(''),
    lastName: new FormControl(''),
    email: new FormControl(''),
    phone: new FormControl(''),
  });
  passwordForm = new FormGroup({
    oldPassword: new FormControl(''),
    newPassword: new FormControl(''),
    reEnter: new FormControl(''),
  });
  savedData: any = [];
  alertSeverity: string = 'error';
  alertMsg: string = '';
  secDashboard = [
    {
      DashboardName: 'Leave of Absence',
    },
    {
      DashboardName: 'Call Center Analysis',
    },
    {
      DashboardName: 'Disability Dashboard - V1',
    },
  ];
  recentReports: any;
  data: any;
  options: any;
  showProfileDialog: boolean = false;
  tabHeaderName = 'Account Information';
  userName: any = '';
  animationBoolean: boolean = false;
  tableSortColumn = 'startTime';
  dir = 'DESC';
  requestPayload = {
    sort: [{ property: this.tableSortColumn, dir: this.dir }],
    filters: [],
    filterType: 'and',
  };
  getTodayCompletedCount = 0;
  getTodayFailedCount = 0;
  getTodayRunningCount = 0;
  getTodayPendingCount = 0;
  getTotalTodayCount = 0;
  scheduleHistoryRecords: any = [];
  productInChild = [];
  newMessage: any;
  Temp: any;
  userDetail: any;
  chartStatus = '';
  resultUserRoleDetails: any = [];
  getTodayFailedCountSideBar = 0;
  storeScheduledReportCount: any = [];
  data1: any;
  chartOptions: any;
  Dashboard: any;
  isListening: boolean;
  spokenText: string;
  questions: any[];
  isWriting: boolean;
  recognition: any;
  myText: string;
  SearchLabel: boolean = false;
  showQuestions: boolean = false;
  chartShow: boolean = false;
  DashboardContainer: boolean;
  widgetContainerBool: boolean;
  headerbtn: boolean;
  sampleChartData: {
    dashboardname: string;
    dashboardcode: number;
    containerbool: boolean;
    searchMetric: string;
    component: string;
    type: string;
  };
  DailyReports: any;
  disable: boolean = false;
  widgets = [
    { text: 'Data Discovery', color: '#e2e2e2', icon: 'pi pi-search' },
    { text: 'Build a Report', color: '#d4d4d4', icon: 'bx bx-notepad' },
    { text: 'Report', color: '#c6c6c6', icon: 'bx bx-folder' },
    { text: 'Dashboard', color: '#b8b8b8', icon: 'fa-solid fa-border-all' },
  ];
  responsiveOptions: any[] | undefined;
  showAdminfunctionality: boolean = false;
  isPasswordVisible: boolean = false;
  isPasswordVisible1: boolean = false;
  isPasswordVisible2: boolean = false;
  hideIcon = 'pi pi-eye';
  showIcon = 'pi pi-eye-slash';
  resultMenuList: any = [];
  showRouteBuild: boolean = false;
  showRouteCreate: boolean = false;
  showRouteCatalog: boolean = false;
  showReportInbox:boolean=false;
  showRouteDiscovery: boolean = false;
  disableSchedule: boolean = true;
  disableReport: boolean = true;
  disableDashboard: boolean = true;
  showChart: boolean = false;
  showStatus: boolean = false;
  errorMsg: string;
  showRouteSettings: boolean = false;
  completedCount: any;
  reportsin3days: any;
  disableCatalogue: boolean = false;
  completed: any;
  running: any;
  failed: any;
  pending:any;
  completedCount1: any;
  showRouteMyReport: boolean=false;
  dateToday: string;
  time: string;
  intervalId: any;
  loader: boolean=false;
  chartElement13: any;
  detail13:any;
  loader1: boolean=false;
  loader2: boolean=false;
  businessMetricText:any='';
  categories: { name: string; key: string; }[];
  selectedCategories: { name: string; key: string; }[];
  showR: boolean=false;
  showD: boolean=false;
  showW: boolean=false;
  showB: boolean=false;
  activeIndex=0;
  resultsBool: boolean=false;
  reports: any[];
  dashboards: any[];
  isLoading: boolean=false;
  buildReport: any;
  first: boolean=false;
  widget: boolean=false;
  report: boolean=false;
  reportCount: number=0;
  dashCount: number=0;
  widgetCount: number=0;
  topbar: boolean;
  widgetsList: any[];
  selectedCity: { name: string; code: string; };
  dashboardName: string;
  scheduleSelected: boolean;
  dashboardSelected: boolean;
  reportSelected: boolean;
  buildReportSelected: boolean;
  sampleChartData5: { reportId: any; type: any; name: any; id: number; showReport: boolean; searchMetric: any; };
  selectedItemType: any;
  chartShow1: boolean=false;
  sampleChartData2: { id: any; type: string; name: any; showReport: boolean; searchMetric: any; };
  sampleChartData4: { reportId: any; filter: any; name: any; searchMetric: any; component: string; };
  sampleChartData1: { dashboardname: any; dashboardcode: any; containerbool: boolean; searchMetric: any; component: string; type: any; };
  showPanel: boolean=true;
  trackerPayload: any={
    autoCompleteId:"0",
    autoCompleteResult:"0",
    searchCategory:"",
    searchId:"0",
    searchResult:"",
    searchControlMasterId:"0",
    clicode:"0"
  };
  menus: any;
  constructor(
    private router: Router,
    private messageService: MessageService,
    public dialogService: DialogService,
    private confirmationService: ConfirmationService,
    private homeService: HomeService,
    private appComp: AppComponent,
    private cdRef: ChangeDetectorRef,
    private appCompService: AppComponentService,
    private elementRef: ElementRef,
    private tourGuideService: TourGuideService
  ) {
    //  const SpeechRecognition =
    //   (window as any).SpeechRecognition || (window as any).webkitSpeechRecognition;
    // this.recognition = new SpeechRecognition();
    // this.recognition.continuous = true;
    // this.recognition.interimResults = true;
    this.productInChild = ['hi hello'];
  }

  ref: DynamicDialogRef;

  ngOnInit(): void {
    this.categories = [
      { name: 'Reports', key: 'R' },
      { name: 'Dashboards', key: 'D' },
      { name: 'Widgets', key: 'W' },
      { name: 'Build A Report', key: 'B' },
    ];
    this.selectedCategories = this.categories;
    this.startClock();
    var date = new Date();
    this.dateToday = date.toDateString();
    // this.time = date.toLocaleTimeString();
    setTimeout(()=>{
      this.tourGuideService.startTour('home');
    },1000)
    this.completed = 0;
    this.running = 0;
    this.failed = 0;
    this.pending=0;
    this.loader=true;
    this.loader1=true;
    this.loader2=true;
    this.completedCount1 = [];
    // this.homeService.dashboardsReportsCounts().subscribe((res: any) => {
    //   //console.log(res);
    //   this.completedCount = res?.body?.totalRecords;
    // });
  //   this.homeService.getTotalReportsCounts().subscribe((res: any) => {
  //     //console.log(res);
  //     this.loader=false;
  //     this.completedCount1 = res?.body;
  //     if (this.completedCount1) {
  //       this.completedCount1.forEach((item: any) => {
  //         if (item.status == 'Completed') {
  //           this.completed = item.jobCount;
  //         }
  //         if (item.status == 'Running') {
  //           this.running = item.jobCount;
  //         }
  //         if (item.status == 'Failed') {
  //           this.failed = item.jobCount;
  //         }
  //         if(item.status=="Pending"){
  //           this.pending=item.jobCount;
  //         }
  //       })
  //     }
  //   },
  //   error=>{
  //     this.loader=false;
  //   }
  // );
    // this.homeService.getreportsin3days().subscribe((res: any) => {
    //   //console.log(res);
    //   this.reportsin3days = res?.body;
    // });
    this.appCompService.menuListGetService().subscribe({
      next: (res) => {
        this.menus = res;
        const menuList = [
          'DataDiscovery',
          'Dashboard',
          'Custom Reports',
          'Adhoc',
          'My Documents',
          'My Reports',
          'Scheduled Reports',
          'Scheduler Logs',
          'My Favorites',
          'My Inbox',
          'Payroll',
          'Payroll Validation',
          'Configuration',
          'Manage Security',
          'Permission',
          'Widget Designer',
          'Report Catalogue',
          'Data Analytic Dashboard',
          'Self BI',
          'Settings',
          'GuidedTour',
          'Help',
        ];

        for (let i = 0; menuList.length > i; i++) {
          for (let j = 0; this.menus.length > j; j++) {
            if (menuList[i] === this.menus[j].menuName) {
              this.resultMenuList.push(this.menus[j].menuName);
            }
          }
        }
        this.tourGuideService.setMenuList(this.resultMenuList);
        this.resultMenuList.filter((item) => {
          if (item == 'My Reports') {
            this.disableSchedule = false;
            this.disableReport = false;
            this.showRouteMyReport=true
          }
          if (item == 'Report Catalogue') {
            // this.disableCatalogue=false;
            this.showRouteCatalog = true;
          }
          else if(item=='My Reports'){
            this.disableReport=false;
          }
          else if (item == 'Dashboard') {
            this.disableDashboard = false;
            this.showRouteCreate = true;
          } else if (item == 'Adhoc') {
            this.showRouteDiscovery = true;
          } else if (item == 'Custom Reports') {
            this.showRouteBuild = true;
          } else if (item == 'Settings') {
            this.showRouteSettings = true;
          }
          else if (item == 'My Inbox'){
            this.showReportInbox=true;
          }
        });
      },
      error: (err) => {
        this.messageService.add({
          severity: 'error',
          summary: '',
          detail: 'Please try again after sometime',
        });
      },
    });
    //console.log(this.resultMenuList);
   
    this.responsiveOptions = [
      {
        breakpoint: '1024px',
        numVisible: 4,
        numScroll: 4,
      },
      {
        breakpoint: '768px',
        numVisible: 3,
        numScroll: 3,
      },
      {
        breakpoint: '560px',
        numVisible: 2,
        numScroll: 2,
      },
    ];

    this.disable = false;
    // this.setScheduledReportData();
    // this.scheduledReportFun();
    d3.select('.tibo-topbar-title').text('Home');
    this.appCompService.currentUser.subscribe((res) => {
      this.userName = res.userName;
      this.userDetail = res;
    });

    this.appCompService.currentRole.subscribe(
      (res: any) => {
        this.showAdminfunctionality = false;
        if (res) {
          let roledetails = res;
          this.resultUserRoleDetails = res;
          if(roledetails['assignedRoles']){
          roledetails['assignedRoles'].forEach((r) => {
            if (r.roleId == 1) {
              this.showAdminfunctionality = true;
            }
          });
        }
        }
      },
      (error) => {
        this.alertSeverity = 'error';
        if (Number(error.status) == 401) {
          this.alertMsg = 'Please try after some time!';
        } else if (Number(error.status) == 500) {
          this.alertMsg = 'Internal error';
        } else {
          this.alertMsg = 'Please try after some time.';
        }
        this.addSingle();
      },
      () => {}
    );
    this.homeService.getRecentDashboard().subscribe((res: any) => {
      if (res?.records.length > 0) {
        this.loader1=false;
        let displayDashboard=[]
        displayDashboard = res?.records;
        // displayDashboard=displayDashboard.filter(item=>item.dashboardVisibility==="Y" ||item.dashboardVisibility===null  )
        this.Dashboard= displayDashboard;
        this.Dashboard.forEach(item=>{
          if(item.isRunnable==null || item.isSchedulable==null){
            item['type']='dashboard';
          }
          else{
            item['type']='report';
          }
          });
        // this.homeService.getRecentReports().subscribe((res: any) => {
        //   if (res?.body?.records.length > 0) {
        //     this.loader2=false;
        //     let displayReport=[]
        //     displayReport = res?.body?.records;
        //     this.recentReports= displayReport.length > 5 ? displayReport.slice(0, 5) : displayReport;
        //     this.recentReports.forEach(item=>item['type']='report')
        //     this.Dashboard.push(...this.recentReports);
            const parseDate = (dateStr: string) => new Date(dateStr);
            this.Dashboard.sort((a, b) => parseDate(b.date).getTime() - parseDate(a.date).getTime());
      //     }
      //     this.loader2=false;
      //   },
      // error=>{
      //   this.loader2=false;
      // });
      }
      this.loader1=false;
    },
  error=>{
    this.loader1=false;
  });
    
    // this.homeService.getRecentScheduledReports().subscribe((res:any)=>{
    //   if(res?.body?.records?.length>0 ){
    //     this.DailyReports=res?.body?.records?res?.body?.records:[];
    //     this.disable=false;
    //   }
    //   else{
    //     this.disable=true;
    //   }
    // })
    this.animationBoolean = true;
    setTimeout(() => {
      this.animationBoolean = false;
    }, 2000);
  }
 
  startClock() {
    this.intervalId = setInterval(() => {
      const currentTime = new Date();
      this.time = currentTime.toLocaleTimeString();
    }, 1000);
  }
  ngOnDestroy() {
    if (this.ref) {
      this.ref.close();
    }
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }
  makeListEmpty(){
    let value=undefined;
    this.appCompService.setGlobalVariable(value);
  }
  download() {
    const exportData = this.DailyReports?.map((item) => ({
      jobName: item.jobName,
      reportType: item.reportType,
      nextRunDate: item.nextRunDate,
    }));
    this.homeService.exportToExcel(exportData, 'exported_data');
  }
  goToDashboard(data,action) {
    if(data.type=='report'){
      let type='';
      if(data.authorizationName.includes('.rptdesign')){type='birt'}
      if(data.authorizationName.includes('.sql')){type='sql'}
      if(action=="schedule"){
        this.router.navigate(['/scheduler'], {
          queryParams: { reportId: data.authorizationId, id:1,type: undefined, name: data.authorizationName, component: 'home' },
        });
      }
      if(action=="run"){
        this.router.navigate(['/view'], {
          queryParams: { id: data.authorizationId, type: type, name: data.authorizationName, uniqueName: '' },
        });
      }
    }
    else if(data.type=='dashboard'){
    this.router.navigate(['/dashboard'], {
      queryParams: {
        dashboardname: data.authorizationName,
        dashboardcode: data.authorizationId,
        containerbool: false,
        searchMetric: '',
        component: 'home',
        type: 'dashboard',
      },
    });
  }
    //(['/dashboard'], { queryParams: { dashboardname: data.authorizationName ,containerbool:false } });
  }

  showDashboardInfoBtnClick() {
    this.ref = this.dialogService.open(DashboardInfo, {
      data: {},
      header: 'Dashboard',
      width: '75%',
      height: '80%',
      contentStyle: {
        'min-height': '350px',
        height: '100%',
        'background-color': '#f1f1f4',
        'overflow-y': 'auto',
      },
      baseZIndex: 10000,
    });
  }
  goToReport(id, name) {
    let type='';
    if(name.includes('.rptdesign')){type='birt'}
    if(name.includes('.sql')){type='sql'}
    this.router.navigate(['/view'], {
      queryParams: {id: id, type: type, name: name, uniqueName: '' },
    });
  }
  goToReport1(report) {
    this.router.navigate(['/scheduled-reports']);
  }
  showReportsInfoBtnClick() {
    this.ref = this.dialogService.open(ReportsInfo, {
      data: {},
      header: 'Reports',
      width: '75%',
      height: '80%',
      contentStyle: {
        'min-height': '350px',
        height: '100%',
        'background-color': '#f1f1f4',
        'overflow-y': 'auto',
      },
      baseZIndex: 10000,
    });
  }
  adminProfile() {
    this.showProfileDialog = true;
    this.accountForm.controls['firstName'].setValue(
      this.userDetail['firstName']
    );
    this.accountForm.controls['lastName'].setValue(this.userDetail['lastName']);
    this.accountForm.controls['email'].setValue(this.userDetail['emailId']);
    this.accountForm.controls['phone'].setValue(this.userDetail['handPhoneNo']);
  }
  updateAccount() {
    let payload = {
      firstName: this.accountForm.controls.firstName.value
        ? this.accountForm.controls.firstName.value
        : '',
      lastName: this.accountForm.controls.lastName.value
        ? this.accountForm.controls.lastName.value
        : '',
      emailId: this.accountForm.controls.email.value
        ? this.accountForm.controls.email.value
        : '',
      handPhoneNo: this.accountForm.controls.phone.value
        ? this.accountForm.controls.phone.value
        : '',
    };
    this.homeService.setChangeProfile(payload).subscribe(
      (res) => {
        if (res.status == 200) {
          this.alertSeverity = 'success';
          this.alertMsg = 'Account updated successfully!';
          this.addSingle();
        }
        this.showProfileDialog = false;
        alert('Account updated successfully');
        this.ngOnInit();
      },
      (error) => {
        this.alertSeverity = 'error';
        if (Number(error.status) == 401) {
          this.alertMsg = 'Please try after some time!';
        } else if (Number(error.status) == 500) {
          this.alertMsg = 'Internal error';
        } else {
          this.alertMsg = 'Please try after some time.';
        }
        this.addSingle();
      },
      () => {}
    );
  }
  updatePassword() {
    let payload = {
      oldPassword: this.passwordForm.controls.oldPassword.value,
      newPassword: this.passwordForm.controls.newPassword.value,
    };
    this.homeService.setChangePassword(payload).subscribe(
      (res) => {
        if (res.status == 200) {
          this.alertSeverity = 'success';
          this.alertMsg = 'Account updated successfully!';
          this.addSingle();
        }
        this.ngOnInit();
      },
      (error) => {
        this.alertSeverity = 'error';
        if (Number(error.status) == 401) {
          this.alertMsg = 'Please check Password!';
        } else if (Number(error.status) == 412) {
          this.alertMsg = 'Please check Password!';
        } else if (Number(error.status) == 500) {
          this.alertMsg = 'Internal error';
        } else {
          this.alertMsg = 'Please try after some time.';
        }
        this.addSingle();
      }
    );
    //
  }
  addSingle() {
    this.messageService.add({
      severity: this.alertSeverity,
      summary: '',
      detail: this.alertMsg,
    });
  }

  switchHeaders(event: any) {
    this.tabHeaderName = event.originalEvent.target.innerText;
  }
  toggleListening(): void {
    this.isListening = !this.isListening;

    if (this.isListening) {
      this.startListening();
    } else {
      this.stopListening();
      this.spokenText = '';
      this.isWriting = true;
    }
  }

  startListening(): void {
    this.questions = [];
    this.spokenText = '';
    this.isWriting = false;
    // this.placeHolderValue = '';
    this.recognition.start();
    // this.recognition.onresult = (event: any) => {
    //   const transcript = event.results[event.results.length - 1][0].transcript;
    //   this.spokenText = transcript.trim();
    //   this.myText = this.spokenText;
    //   // // Filter suggestions based on spoken text
    //   // this.filteredItems = this.Suggestions.filter(item =>
    //   // item.question.toLowerCase().includes(this.spokenText.toLowerCase())
    //   // );
    //   // Trigger change detection
    //   this.detectChanges();
    //   this.myText = this.spokenText;
    //   this.onSearchBtnClick1();
    // };
  }
  stopListening(): void {
    // this.recognition.stop();
    this.isListening = false;
    this.isWriting = true;
  }
  detectChanges(): void {
    this.cdRef.detectChanges();
  }
  onSearchBtnClick1() {
    this.questions = [];
    if (this.myText == '') {
      this.questions = [];
    } else {
      this.homeService
        .dashboardsSearchService(this.myText)
        .subscribe((res: any) => {
          if (res.length > 0) {
            //this.isWriting=true
            this.SearchLabel = false;
            this.showQuestions = false;
            this.questions = res;
          }
        });
    }
  }
  onSearchBtnClick4() {
    this.questions = [];
    if (this.myText == '') {
      this.questions = [];
    } else {
      this.homeService
        .dashboardsSearchService(this.myText)
        .subscribe((res: any) => {
          if (res.length > 0) {
            this.isWriting = true;
            this.SearchLabel = false;
            this.showQuestions = false;
            this.questions = res;
          }
        });
    }
  }
  onSearchBtnClick3() {
    this.questions = [];
    if (this.myText == '') {
      this.questions = [];
    } else {
      this.homeService
        .dashboardsSearchService(this.myText)
        .subscribe((res: any) => {
          if (res.length > 0) {
            this.isWriting = true;
            this.SearchLabel = false;
            this.showQuestions = false;
            this.questions = res;
          }
        });
    }
  }
  onSearchBtnClick2(item) {
    this.questions = [];
    if (this.myText == '') {
      this.questions = [];
    } else {
      this.homeService
        .dashboardsSearchEngine(item.autoCompleteResult)
        .subscribe((res: any) => {
          if (res.length > 0) {
            this.isWriting = false;
            this.SearchLabel = false;
            this.showQuestions = true;
            this.questions = res;
          }
        });
    }
  }
  gotoCharts(quest) {
    this.chartShow = true;
    this.DashboardContainer = true;
    this.widgetContainerBool = false;
    this.headerbtn = true;
    // this.sampleChartData = { dashboardname: quest.searchResult, dashboardcode: quest.searchId, containerbool: true, searchMetric: this.myText, component: 'discovery', type: 'widget' }
    this.router.navigate(['/dashboard'], {
      queryParams: {
        dashboardname: quest.searchResult,
        dashboardcode: quest.searchId,
        containerbool: true,
        searchMetric: this.myText,
        component: 'discovery',
        type: quest.searchCategory,
      },
    });
  }
  imageClick(url: string) {}
  togglePasswordVisibility() {
    this.isPasswordVisible = !this.isPasswordVisible;
  }
  togglePasswordVisibility1() {
    this.isPasswordVisible1 = !this.isPasswordVisible1;
  }
  togglePasswordVisibility2() {
    this.isPasswordVisible2 = !this.isPasswordVisible2;
  }
  goToHelp(){
    this.router.navigate(['./help']);
  }
  onSearchBtnClick() {
    if (this.businessMetricText == '') {
      this.showW = false;
      this.showR = false;
      this.showD = false;
      this.showB=false;
      this.report=false;
      this.widgetsList = []
    }
    else {
      this.reportCount = 0;
      this.widgetCount = 0;
      this.dashCount = 0;
      this.showW = false;
      this.showR = false;
      this.showD = false;
      this.showB=false;
      this.isLoading = true;
      this.widgetsList = []
      this.resultsBool = false; // hiding the reports, dashboards, and widgets boxes container
      this.widgetContainerBool = false;
      this.selectedCity = { name: '', code: '' };
      this.dashboardName = '';
      this.widget = false;
      this.report = true;
      this.widgets = [];
      this.reports = [];
      this.dashboards = [];
      this.buildReport=[];
      this.first = true;
      const obj = {
        property: '',
        operator: '',
        value: '',
      };
      let payload = {
        'mytext': this.businessMetricText
      }
      this.homeService.dashboardsSearchEngine(this.businessMetricText.toLowerCase()).subscribe((res: any) => {
        this.isLoading = false;
        this.resultsBool = true;
        if (res?.length > 0) {
          let data= res?.filter(item => item.searchResult != "0");
          if(data.length>0){
          res?.forEach(item => {
            if (item.searchCategory == "Report") {
              this.reports.push(item)
            }
            else if (item.searchCategory == "Dashboard") {
              this.dashboards.push(item)
            }
            else if (item.searchCategory == "Widget") {
              this.widgets.push(item)
            }
            else if (item.searchCategory == "Build A Report") {
              this.buildReport.push(item)
            }
          })
        }
        else{
          this.trackerPayload.autoCompleteId="0";
          this.trackerPayload.autoCompleteResult=this.businessMetricText;
          this.trackerPayload.searchCategory="";
          this.trackerPayload.searchId="0";
          this.trackerPayload.searchResult="";
          this.trackerPayload.searchControlMasterId="0";
          this.homeService.trackerSearch(this.trackerPayload,"Home").subscribe((response:any)=>{
          })
        }
      }
      }, (error) => {
        if (Number(error.status) == 401) alert('Please Try After Some Time!');
        else if (Number(error.status) == 500) {
          // alert('Internal Error ');
          this.resultsBool = true;
          this.reports = [];
          this.dashboards = [];
          this.widgets = [];
          this.buildReport=[];
        }
        this.isLoading = false;
      },
      )
      this.selectedCategories.forEach((d: any) => {
        if (d.name == 'Reports') {
          this.showR = true;
        } else if (d.name == 'Dashboards') {
          this.showD = true;
        } else if (d.name == 'Widgets') {
          this.showW = true;
        }
         else if (d.name == 'Build A Report') {
        this.showB = true;
      }
      });

      // },
      //   (error) => {
      //     this.isLoading = false;
      //   },
      //   () => { }
      // );
    }
  }
  checkboxClick() {
    this.activeIndex=-1;
    this.showR = false;
    this.showD = false;
    this.showW = false;
    this.showB=false;
    this.selectedCategories.forEach((d: any) => {
      if (d.name == 'Reports') {
        this.showR = true;
      } else if (d.name == 'Dashboards') {
        this.showD = true;
      } else if (d.name == 'Widgets') {
        this.showW = true;
      }
      else if (d.name == 'Build A Report') {
        this.showB = true;
      }
    });
  }
  itemSelectedFunc(event, type) {
    this.trackerPayload = {};
    this.trackerPayload.autoCompleteId="0";
    this.trackerPayload.autoCompleteResult=this.businessMetricText;
    this.trackerPayload.searchCategory=event.value.searchCategory;
    this.trackerPayload.searchId=event.value.searchId;
    this.trackerPayload.searchResult=event.value.searchResult;
    this.trackerPayload.searchControlMasterId=event.value.searchControlMasterId;
    this.trackerPayload.clicode="";
    this.homeService.trackerSearch(this.trackerPayload,"Home").subscribe((res:any)=>{
    })
    this.dashboardSelected = false;
    this.reportSelected = false;
    this.buildReportSelected=false;
    this.headerbtn = false;
    this.widgetContainerBool = true;
    this.resultsBool = false;
    this.first = false;
    this.showPanel = false;
    this.dashboardName = '';
    this.isLoading = true;
    this.selectedItemType = type;
    this.report = false;
    this.chartShow1 = true;
    let type1 = 'birt';
    if (type == 'report') {
      if(event.value.searchIsRunnable == "Y"){
      if (event.value.searchResult.toLowerCase().includes('.sql')) {
        type1 = 'sql';
      }
      if (event.value.searchResult.toLowerCase().includes('.rptdesign')) {
        type1 = 'birt';
      }
      this.scheduleSelected=false;
      this.dashboardSelected = false;
      this.reportSelected = true;
      // this.reportCall = true;
      // this.dashCall = false;
      this.buildReportSelected=false;
      this.sampleChartData2 = { id: event.value.searchId, type: type1, name: event.value.searchResult, showReport: true, searchMetric: this.businessMetricText }
    }
    else if(event.value.searchIsRunnable == "N" && event.value.searchIsSchedulable == "Y"){
      this.scheduleSelected=true;
      this.dashboardSelected = false;
      this.reportSelected = false;
      this.buildReportSelected=false;
      this.sampleChartData5 = { reportId: event.value.searchId, type: undefined, name: event.value.searchResult,id:1, showReport: true, searchMetric: this.businessMetricText }
    }
      //this.onRunButtonClick(event.value.code, event.value.name, event.value.type)
      // this.sampleChartData={ reportId: event.value.code, type: event.value.type, name: event.value.name, showReport: true, searchMetric: this.businessMetricText }
    }
    else if(event.value.searchIsRunnable == "N" && event.value.searchIsSchedulable == "N"){
      this.messageService.add({
        severity: "error" ,
        summary: '',
        detail:  "Report Is Not Runnable Or Schedulable" ,
      });
    }
    else if(type=='buildareport'){
      this.scheduleSelected=false;
      this.dashboardSelected = false;
      this.reportSelected = false;
      this.buildReportSelected=true;
      // this.reportCall = false;
      // this.dashCall = false;
      this.sampleChartData4 = { reportId: event.value.searchDomainId, filter: event.value.searchId, name: event.value.searchResult,searchMetric: this.businessMetricText,component: 'discovery' }

    }
    else if (type == 'dashboard' || type == 'widget') {
      this.dashboardSelected = true;
      this.scheduleSelected=false;
      this.buildReportSelected=false;
      this.reportSelected = false;
      this.DashboardContainer = true;
      this.widgetContainerBool = false;
      this.headerbtn = true;
      // this.dashCall = true;
      // this.reportCall = false;
      this.sampleChartData1 = { dashboardname: event.value.searchResult, dashboardcode: event.value.searchId, containerbool: true, searchMetric: this.businessMetricText, component: 'discovery', type: event.value.searchCategory }

      // this.sampleChartData1 = { dashboardname: event.value.name, dashboardcode: event.value.code, containerbool: true, searchMetric: this.businessMetricText, component: 'discovery', type: type  }
    }

    this.headerbtn = false;
  }
  itemSelectedFunc1(event, type,action) {
    this.trackerPayload = {};
    this.trackerPayload.autoCompleteId="0";
    this.trackerPayload.autoCompleteResult=this.businessMetricText;
    this.trackerPayload.searchCategory=event.searchCategory;
    this.trackerPayload.searchId=event.searchId;
    this.trackerPayload.searchResult=event.searchResult;
    this.trackerPayload.searchControlMasterId=event.searchControlMasterId;
    this.trackerPayload.clicode="";
    this.homeService.trackerSearch(this.trackerPayload,"Home").subscribe((res:any)=>{
    })
    this.dashboardSelected = false;
    this.reportSelected = false;
    this.buildReportSelected=false;
    this.headerbtn = false;
    this.widgetContainerBool = true;
    this.resultsBool = false;
    this.first = false;
    this.showPanel = false;
    this.dashboardName = '';
    this.isLoading = true;
    this.selectedItemType = type;
    this.report = false;
    this.chartShow1 = true;
    let type1 = 'birt';
    if (type == 'report') {
      if(action=='run'){
      if (event.searchResult.toLowerCase().includes('.sql')) {
        type1 = 'sql';
      }
      if (event.searchResult.toLowerCase().includes('.rptdesign')) {
        type1 = 'birt';
      }
      this.scheduleSelected=false;
      this.dashboardSelected = false;
      this.reportSelected = true;
      // this.reportCall = true;
      // this.dashCall = false;
      this.buildReportSelected=false;
      this.sampleChartData2 = { id: event.searchId, type: type1, name: event.searchResult, showReport: true, searchMetric: this.businessMetricText }
    }
    else if(action=='schedule'){
      this.scheduleSelected=true;
      this.dashboardSelected = false;
      this.reportSelected = false;
      this.buildReportSelected=false;
      this.sampleChartData5 = { reportId: event.searchId, type: undefined, name: event.searchResult,id:1, showReport: true, searchMetric: this.businessMetricText }
    }
  }
      //this.onRunButtonClick(event.value.code, event.value.name, event.value.type)
      // this.sampleChartData={ reportId: event.value.code, type: event.value.type, name: event.value.name, showReport: true, searchMetric: this.businessMetricText }
    // }
    // else if(event.value.searchIsRunnable == "N" && event.value.searchIsSchedulable == "N"){
    //   this.messageService.add({
    //     severity: "error" ,
    //     summary: '',
    //     detail:  "Report Is Not Runnable Or Schedulable" ,
    //   });
    // }
    // else if(type=='buildareport'){
    //   this.scheduleSelected=false;
    //   this.dashboardSelected = false;
    //   this.reportSelected = false;
    //   this.buildReportSelected=true;
    //   // this.reportCall = false;
    //   // this.dashCall = false;
    //   this.sampleChartData4 = { reportId: event.value.searchDomainId, filter: event.value.searchId, name: event.value.searchResult,searchMetric: this.businessMetricText,component: 'discovery' }

    // }
    // else if (type == 'dashboard' || type == 'widget') {
    //   this.dashboardSelected = true;
    //   this.scheduleSelected=false;
    //   this.buildReportSelected=false;
    //   this.reportSelected = false;
    //   this.DashboardContainer = true;
    //   this.widgetContainerBool = false;
    //   this.headerbtn = true;
    //   // this.dashCall = true;
    //   // this.reportCall = false;
    //   this.sampleChartData1 = { dashboardname: event.value.searchResult, dashboardcode: event.value.searchId, containerbool: true, searchMetric: this.businessMetricText, component: 'discovery', type: event.value.searchCategory }

    //   // this.sampleChartData1 = { dashboardname: event.value.name, dashboardcode: event.value.code, containerbool: true, searchMetric: this.businessMetricText, component: 'discovery', type: type  }
    // }

    this.headerbtn = false;
  }
  backToSearch1() {
    this.showPanel = true;
    this.isLoading = false;
    this.report = true;
    this.resultsBool = true;
    this.chartShow1 = false;
    this.first = true;
  }
}

