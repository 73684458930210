<div class="scheduler-parent-container">
	<div *ngIf="showreport">
		<div style="height: 35px;"></div>
			<!-- <button class="tibo-mydash-chartcoloroptions-btn" type="button" pButton icon="pi pi-angle-left"
				(click)="back()" pTooltip="Back to Search"></button> -->
	</div>
	<img src="././assets/images/dualBallSpinner.svg" alt="" class="spinner-img" *ngIf="isLoading">
	<div class="modal-header cus-bg">
		<label class="form-label">{{this.reportNameLabel}}</label>
	</div>
	<div style="margin-top: 1rem" [ngClass]="{'ScrollStyle':!showreport, 'ScrollStyle1': showreport}" *ngIf="!isLoading">
		<div class="column Report">
			<p-panel header="Report Parameter" toggleable="true">
				<i class="pi pi-spin pi-spinner piSearch" *ngIf="loadings"></i>
				<div *ngIf="showParamenter">
					<div *ngFor="let d of mainData;index as i">
						
						<div *ngIf="d.showBool">
							
						<div [ngSwitch]=d.type>
							<div *ngSwitchCase="'singleSelect'">
								<div *ngIf="d.id == key1">
									<div class="or">(OR)</div>
								</div>
								<div class="p-field p-col-12" style="position: relative;">
									<label class="form-label">{{d.label }}<span *ngIf="d.mandatory"
											class="mandatory-simple"> *</span></label>
											
									<p-dropdown *ngIf="d.id != key1" [attr.id]="d.id" [options]="d.options" [appendTo]="body"
										optionLabel="label" [required]="!d.selected" optionValue="value"
										[(ngModel)]="d.selectedData" [attr.selectInfoIndex]=i
										(onChange)="selectchange($event.value,d.id,i,d.type)"
										[attr.selected]="selected" editable="true" [filter]="true" 
										[dropdownIcon]="isLoading1 || d.loading ? 'pi pi-spinner pi-spin' : 'pi pi-chevron-down'"
										[ngClass]="{'danger-class': !d.selected}" [disabled]="d.disabled" 
										#refElm>
									</p-dropdown>
									<p-dropdown *ngIf="d.id == key1" [attr.id]="d.id" [options]="d.options" appendTo="body"
										optionLabel="label" optionValue="value" [required]="!d.selected"
										[(ngModel)]="d.selectedData" [attr.selectInfoIndex]=i
										(onChange)="selectchange($event.value,d.id,i,d.type)"
										[attr.selected]="selected" editable="true" [filter]="true"
										[dropdownIcon]="isLoading1 || d.loading? 'pi pi-spinner pi-spin' : 'pi pi-chevron-down'"
										[ngClass]="{'danger-class': !d.selected}"
										#refElm>
									</p-dropdown>
									<!-- <i class="pi pi-spin pi-spinner spinClass" *ngIf="isLoading1"></i> -->
								</div>
							</div>
							<div *ngSwitchCase="'multiSelect'">
								<div class="p-field p-col-12" style="position: relative;">
									<label class="form-label">{{d.label }}<span *ngIf="d.mandatory"
											class="mandatory-simple"> *</span></label>
											
									<p-multiSelect class="search-multiselect" [attr.id]="d.id" [options]=d.options
										[required]="!d.selected" optionLabel="label" [(ngModel)]="d.selectedData"
										[attr.selectInfoIndex]=i optionValue="value" appendTo="body"
										(onChange)="selectchange($event,d.id,i,d.type)" [attr.selected]="selected"
										[dropdownIcon]="isLoading1 || d.loading  ? 'pi pi-spinner pi-spin' : 'pi pi-chevron-down'"
										[ngClass]="{'danger-class': !d.selected}"
										#refElm>
									</p-multiSelect>
									<!-- <i class="pi pi-spin pi-spinner spinClass" *ngIf="isLoading1"></i> -->
								</div>
							</div>
							<div *ngSwitchCase="'singleValueText'">
								<div class="p-field p-col-12">
									<label class="form-label">{{d.label }}<span *ngIf="d.mandatory"
											class="mandatory-simple"> *</span></label>
									<input type="text" class="form-input" style="width:100%" pInputText
										[(ngModel)]="d.selectedData" [required]="!d.selected"
										(change)="selectchange($event,d.id,i,d.type)" [editable]="true" 
										[ngClass]="{'danger-class': !d.selected}"
										#refElm>
									<!-- <span class="ml-2">{{d.selectedData}}</span> -->
								</div>
							</div>
							<div *ngSwitchCase="'singleValueNumber'">
								<div class="p-field p-col-12">
									<label class="form-label">{{d.label }}<span *ngIf="d.mandatory"
											class="mandatory-simple"> *</span></label>
									<input type="text" class="form-input" style="width:100%" pInputText
										[(ngModel)]="d.selectedData" [required]="!d.selected"
										(change)="selectchange($event,d.id,i,d.type)" [editable]="true" 
										[ngClass]="{'danger-class': !d.selected}"
										#refElm>
									<!-- <span class="ml-2">{{d.selectedData}}</span> -->
								</div>
							</div>
							<div *ngSwitchCase="'singleSelectRadio'">
								<div class="p-field p-col-12">
									<label class="form-label">{{d.label }}<span *ngIf="d.mandatory"
											class="mandatory-simple"> *</span></label>
									<div *ngFor="let options of d.options" class="field-checkbox" style="padding:5px">
										<p-radioButton [inputId]="options.label" name="options" [value]="options.value"
											[(ngModel)]="d.selectedData" [attr.selectInfoIndex]=i
											[required]="!d.selected"
											(onClick)="selectchange(options.value,d.id,i,d.type)"
											[attr.selected]="d.selected" #refElm [label]="options.label" [ngClass]="{'danger-class': !d.selected}"></p-radioButton>
										<!-- <label [for]="options.label">{{options.value}}</label> -->
									</div>

								</div>
							</div>
							<div *ngSwitchCase="'combobox'">
								<div class="p-field p-col-12 scheduler-addon-dropdown-col">
									<label class="form-label">{{d.label }}<span *ngIf="d.mandatory"
											class="mandatory-simple"> *</span></label>
									<div class="p-inputgroup">
										<span class="p-inputgroup-addon"><p-radioButton name="category" value="1"
												[(ngModel)]="values"></p-radioButton></span>
										<p-dropdown class="scheduler-addon-dropdown" [attr.id]="d.id"
											[options]="d.options" optionLabel="label" [required]="!d.selected"
											optionValue="value" [(ngModel)]="d.selectedData" [attr.selectInfoIndex]=i
											[editable]="true" (onChange)="selectchange($event.value,d.id,i,d.type)"
											[attr.selected]="selected" [filter]="true" 
											[dropdownIcon]="isLoading1 || d.loading  ? 'pi pi-spinner pi-spin' : 'pi pi-chevron-down'"
											[ngClass]="{'danger-class': !d.selected}"
											#refElm [disabled]="!values">
										</p-dropdown>
									</div>
									<div class="p-inputgroup">
										<span class="p-inputgroup-addon"><p-radioButton name="category" value="2"
												[(ngModel)]="values"></p-radioButton></span>
										<input type="text" class="form-input" pInputText [required]="!d.selected"
											[(ngModel)]="d.selectedData"
											(change)="selectchange($event,d.id,i,d.type)" [editable]="true" [ngClass]="{'danger-class': !d.selected}" 
											#refElm [disabled]="!values"><i class="pi pi-spin pi-spinner spinClass-calender"
											*ngIf="isLoading1"></i>
									</div>
								</div>
							</div>
							<div *ngSwitchCase="'date'">
								<div class="p-field p-col-12" style="position: relative;">
									<label class="form-label">{{d.label }}<span *ngIf="d.mandatory"
											class="mandatory-simple"> *</span></label>
									<p-calendar [attr.id]="d.id" dateFormat="mm/dd/yy" appendTo="body"
										[required]="!d.selected" inputId="icon" showButtonBar="true"
										[monthNavigator]="true" [(ngModel)]="d.option" [yearNavigator]="true"
										yearRange="1000:3000" [showIcon]="true" [(ngModel)]="d.selectedData[0]"
										 placeholder="MM/DD/YYYY"
										(onSelect)="selectchange($event,d.id,i,d.type,refElm)"
										(onClearClick)="selectchange($event.value,d.id,i,d.type)" 
										[ngClass]="{'danger-class': !d.selected}"
										(ngModelChange)="onManualChange($event, d.id, i, d.type)"
										#refElm>
									</p-calendar><i class="pi pi-spin pi-spinner spinClass-calender"
										*ngIf="isLoading1"></i>
								</div>
							</div>
						</div>
					</div>
					</div>
					<!-- <p class="text-danger" *ngIf="!DateRangeMandatoryBoo">From Date/Thru Date or DateRange is mandatory! -->
					<!-- </p> -->
				</div>
			</p-panel>
		</div>
		<div class="column Schedule">
			<p-panel header="Schedule" toggleable="true">
				<div class="p-field p-col-12">
					<label class="form-label">Job Name</label>
					<input type="text" class="form-input" pInputText [(ngModel)]="Jobvalue"
						(change)="jobChange(Jobvalue)">
				</div>
				<div class="Occurrence-container">
					<span class="form-label">Occurrence</span>
					<div class="p-grid tibo-myrepo-schelr-secttwo-occur-pselectbtn-cont">
						<p-selectButton [options]="stateOptions" [(ngModel)]="scheduleOccurrenceValue"
							[multiple]="false" optionLabel="label" optionValue="label"
							(onChange)="changeSchedulerView($event.originalEvent.target.innerText,scheduleOccurrenceValue)">
						</p-selectButton>
					</div>
					<div id="daily-scheduler" class="Occurrence-container2" *ngIf="dailyScheduler">
						<label class="form-label">Produce This Report On - Select Day(s)<span
								class="mandatory-simple">*</span></label>
						<div class="p-grid p-ai-center vertical-container p-mx-1" style="margin-top: 7px;">
							<input type="radio" class="form-input" name="daily_occurrence" value="every days(s)"
								[(ngModel)]="everyDays" (change)="dailyOccurSchedFunc($event.target.value,undefined)">
							Every
							<p-inputNumber id="refElmval1" class="number-input"
								styleClass="tibo-myrepo-schelr-elements-inputnumber"
								[(ngModel)]="dailyOccurrenceDayVal1" [showButtons]="true" mode="decimal" [min]="1"
								[max]="31" [maxlength]="2" (onInput)="dailyOccurSchedFunc($event,refElmval1)"
								#refElmval1
								[ngClass]="{'danger-class': everyDays === 'every days(s)' && dailyOccurrenceDayVal1 === 0}"></p-inputNumber>
							Day(s)

						</div>
						<div class="p-grid p-ai-center vertical-container p-mx-1 p-py-2">
							<input type="radio" class="form-input" name="daily_occurrence" value="every week day"
								[(ngModel)]="everyDays" (change)="dailyOccurSchedFunc($event.target.value,undefined)">
							Every week day
						</div>

						<div class="p-grid p-ai-center vertical-container p-mx-1 p-py-2">
							<input type="radio" class="form-input" name="daily_occurrence" value="every day"
								[(ngModel)]="everyDays" (change)="dailyOccurSchedFunc($event.target.value,undefined)">
							Every day
						</div>
						<div class="p-filed p-col-12">
							<label class="form-label">Produce This Report At (PST):</label><span
								class="mandatory-simple">*</span>
							<p-calendar styleClass="p-mx-1 daily-time" id="daily-occurrence-report-time"
								[timeOnly]="true" hourFormat="12" appendTo="body" [(ngModel)]="timeZoneDaily"
								showIcon="true" icon="pi pi-clock" placeholder="HH:MM AM/PM" showButtonBar="true"
								(onSelect)="dailyOccurSchedFunc($event,refElw1)"
								(onClearClick)="dailyOccurSchedFunc($event,refElw1)" (ngModelChange)="manualChangeDaily($event,refElw1)"
								[ngClass]="{'danger-class': timeZoneDaily === ''||timeZoneDaily ===null}" #refElw1>
							</p-calendar>
						</div>
						<div class="p-filed p-col-12">
							<label class="form-label">Schedule Start Date: </label><span
								class="mandatory-simple">*</span>
							<p-calendar styleClass="p-mx-1 daily-time" id="daily-occurrence-report-start-date"
								dateFormat="mm/dd/yy" appendTo="body" [monthNavigator]="true"
								[(ngModel)]="startDateDaily" [yearNavigator]="true" yearRange="1000:3000"
								showIcon="true" [minDate]="todayDate" showButtonBar="true" placeholder="MM/DD/YYYY"
								(onSelect)="dailyOccurSchedFunc($event,refElw2)"
								(onClearClick)="dailyOccurSchedFunc($event,refElw2)"
								(ngModelChange)="manualChangeDaily($event,refElw2)"
								[ngClass]="{'danger-class': startDateDaily === ''  ||  startDateDaily ===null}"
								#refElw2>
							</p-calendar>
						</div>
						<div class="p-filed p-col-12">
							<label class="form-label">Schedule End Date: </label><span class="mandatory-simple">*</span>
							<p-calendar styleClass="p-mx-1 daily-time" id="daily-occurrence-report-end-date"
								dateFormat="mm/dd/yy" appendTo="body" [monthNavigator]="true" [(ngModel)]="endDateDaily"
								showButtonBar="true" [yearNavigator]="true" yearRange="1000:3000" showIcon="true"
								[minDate]="startDateDaily" placeholder="MM/DD/YYYY" [maxDate]="maxDateDaily"
								(onSelect)="dailyOccurSchedFunc($event,refElw3)"
								(onClearClick)="dailyOccurSchedFunc($event,refElw3)"
								(ngModelChange)="manualChangeDaily($event,refElw3)"
								[ngClass]="{'danger-class': endDateDaily === '' ||endDateDaily ===null}" #refElw3>
							</p-calendar>
						</div>
					</div>
					<div id="weekly-scheduler" *ngIf="weeklyScheduler" class="Occurrence-container2">
						<label class="form-label">Produce This Report On - Select Day(s) <span
								class="mandatory-simple">*</span></label>
						<div style="margin-top: 1rem;" class="field-checkbox-div">
							<span *ngFor="let c of weeklyOccurList2" class="field-checkbox">
								<p-checkbox class="p-mr-2" [(ngModel)]="weekList" [label]=c.label [value]=c.value
									(onChange)="weeklyOccurSchedFunc($event,refElw0)" #refElw0></p-checkbox>
							</span>
						</div>
						<div class="p-filed p-col-12">
							<label class="form-label">Produce This Report At (PST):</label><span
								class="mandatory-simple">*</span>
							<p-calendar styleClass="p-mx-1 daily-time" id="weekly-occurrence-report-time"
								showButtonBar="true" [timeOnly]="true" hourFormat="12" appendTo="body" showIcon="true"
								icon="pi pi-clock" [(ngModel)]="timeZoneWeekly" placeholder="HH:MM AM/PM"
								(onSelect)="weeklyOccurSchedFunc($event,refElw1)" (ngModelChange)="manualChangeWeekly($event,refElw1)"
								(onClearClick)="weeklyOccurSchedFunc($event,refElw1)"
								[ngClass]="{'danger-class': timeZoneWeekly == '' ||timeZoneWeekly == null}" #refElw1>
							</p-calendar>
						</div>
						<div class="p-filed p-col-12">
							<label class="form-label">Schedule Start Date:</label><span
								class="mandatory-simple">*</span>
							<p-calendar styleClass="p-mx-1 daily-time" id="weekly-occurrence-report-start-date"
								dateFormat="mm/dd/yy" appendTo="body" [monthNavigator]="true"
								[(ngModel)]="startDateWeekly" showButtonBar="true" [yearNavigator]="true" 
								yearRange="1000:3000" showIcon="true" [minDate]="todayDate" placeholder="MM/DD/YYYY"
								(onSelect)="weeklyOccurSchedFunc($event,refElw2)"
								(onClearClick)="weeklyOccurSchedFunc($event,refElw2)"
								(ngModelChange)="manualChangeWeekly($event,refElw2)"
								[ngClass]="{'danger-class': startDateWeekly == ''||startDateWeekly == null }" #refElw2>
							</p-calendar>
						</div>
						<div class="p-filed p-col-12">
							<label class="form-label">Schedule End Date:</label><span class="mandatory-simple">*</span>
							<p-calendar styleClass="p-mx-1 daily-time" id="weekly-occurrence-report-end-date"
								dateFormat="mm/dd/yy" appendTo="body" [monthNavigator]="true"
								[(ngModel)]="endDateWeekly" showButtonBar="true" [yearNavigator]="true"
								yearRange="1000:3000" showIcon="true" [minDate]="startDateWeekly" [maxDate]="maxDateWeekly"
								placeholder="MM/DD/YYYY" (onSelect)="weeklyOccurSchedFunc($event,refElw3)"
								(onClearClick)="weeklyOccurSchedFunc($event,refElw3)"
								(ngModelChange)="manualChangeWeekly($event,refElw3)"
								[ngClass]="{'danger-class': endDateWeekly == ''||endDateWeekly == null }" #refElw3>
							</p-calendar>
						</div>
					</div>
					<div id="monthly-scheduler" *ngIf="monthlyScheduler" class="Occurrence-container2">
						<label class="form-label">Produce This Report On - Select Day(s) <span
								class="mandatory-simple">*</span></label>
						<div class="p-grid p-ai-center vertical-container p-mx-1" style="margin-top: 7px;">
							<input type="radio" class="form-input" name="monthly_occurrence" value="first"
								[(ngModel)]="monthlyDays" (change)="monthlyOccurSchedFunc($event,undefined)">
							Day
							<p-inputNumber id="refElmval1" class="number-input"
								styleClass="tibo-myrepo-schelr-elements-inputnumber"
								[(ngModel)]="monthlyOccurrenceDayVal1" [showButtons]="true" mode="decimal" [min]="1"
								[max]="31" [maxlength]="2" (onInput)="monthlyOccurSchedFunc($event,refElmval1)"
								#refElmval1
								[ngClass]="{'danger-class': monthlyDays === 'first' && monthlyOccurrenceDayVal1 === 0}"></p-inputNumber>
							of every
							<p-inputNumber id="refElmval2" class="number-input"
								styleClass="tibo-myrepo-schelr-elements-inputnumber"
								[(ngModel)]="monthlyOccurrenceMonthVal" [showButtons]="true" mode="decimal" [min]="1"
								[max]="12" [maxlength]="2" (onInput)="monthlyOccurSchedFunc($event,refElmval2)"
								#refElmval2
								[ngClass]="{'danger-class': monthlyDays === 'first' && monthlyOccurrenceMonthVal === 0}"></p-inputNumber>
							month(s)
						</div>
						<div class="p-grid p-ai-center vertical-container p-mx-1 p-py-2" style="margin-top: 0.5rem;">
							<input type="radio" class="form-input" name="monthly_occurrence" value="second"
								[(ngModel)]="monthlyDays" (change)="monthlyOccurSchedFunc($event,undefined)">
							The <p-dropdown styleClass="dropdown p-mx-1  value" id="monthly-occurrence-drop-one"
								appendTo="body" [options]="monthlyOccurList1" optionLabel="label" optionValue="value"
								[(ngModel)]="monthlyOccurDrop1" placeholder="Eg: First"
								(onChange)="monthlyOccurSchedFunc($event,refElFirst)" #refElFirst
								[ngClass]="{'danger-class': monthlyDays === 'second' && monthlyOccurrence.dayUnit ==''}">
							</p-dropdown>
							<p-dropdown styleClass="dropdown p-mx-1" id="monthly-occurrence-drop-two" appendTo="body"
								[options]="monthlyOccurList2" optionLabel="label" optionValue="value"
								[(ngModel)]="monthlyOccurDrop2" placeholder="Eg: Day"
								(onChange)="monthlyOccurSchedFunc($event,refElSecond)" #refElSecond
								[ngClass]="{'danger-class': monthlyDays === 'second' && monthlyOccurrence.dayUnitValue ==''}">
							</p-dropdown>
							of every
							<p-inputNumber id="refElmval3" class="number-input"
								styleClass="tibo-myrepo-schelr-elements-inputnumber"
								[(ngModel)]="monthlyOccurrenceMonthVal2" [showButtons]="true" mode="decimal" [min]="1"
								[max]="12" [maxlength]="2" (onInput)="monthlyOccurSchedFunc($event,refElmval3)"
								#refElmval3
								[ngClass]="{'danger-class': monthlyDays === 'second' && monthlyOccurrenceMonthVal2 === 0}"></p-inputNumber>
							month(s)
						</div>
						<div class="p-filed p-col-12">
							<label class="form-label">Produce This Report At (PST):</label><span
								class="mandatory-simple">*</span>
							<p-calendar styleClass="p-mx-1 daily-time" id="monthly-occurrence-report-time"
								showButtonBar="true" [timeOnly]="true" hourFormat="12" appendTo="body" showIcon="true"
								[(ngModel)]="timeZoneMonthly" icon="pi pi-clock" placeholder="HH:MM AM/PM"
								(onSelect)="monthlyOccurSchedFunc($event,refElm1)" (ngModelChange)="manualChangeMonthly($event,refElm1)"
								(onClearClick)="monthlyOccurSchedFunc($event,refElm1)"
								[ngClass]="{'danger-class': timeZoneMonthly === ''|| timeZoneMonthly === null}"
								#refElm1>
							</p-calendar>
						</div>
						<div class="p-filed p-col-12">
							<label class="form-label">Schedule Start Date:</label><span
								class="mandatory-simple">*</span>
							<p-calendar styleClass="p-mx-1 daily-time" id="monthly-occurrence-report-start-date"
								dateFormat="mm/dd/yy" appendTo="body" [monthNavigator]="true"
								[(ngModel)]="startDateMonthly" [yearNavigator]="true" yearRange="1000:3000"
								showIcon="true" [minDate]="todayDate" showButtonBar="true" placeholder="MM/DD/YYYY"
								(onSelect)="monthlyOccurSchedFunc($event,refElm2)"  
								(onClearClick)="monthlyOccurSchedFunc($event,refElm2)"
								(ngModelChange)="manualChangeMonthly($event,refElm2)"
								[ngClass]="{'danger-class': startDateMonthly === ''||startDateMonthly === null}"
								#refElm2>
							</p-calendar>
						</div>
						<div class="p-filed p-col-12">
							<label class="form-label">Schedule End Date:</label><span class="mandatory-simple">*</span>
							<p-calendar styleClass="p-mx-1 daily-time" id="monthly-occurrence-report-end-date"
								showButtonBar="true" dateFormat="mm/dd/yy" appendTo="body" [monthNavigator]="true"
								[(ngModel)]="endDateMonthly" [yearNavigator]="true" yearRange="1000:3000"
								showIcon="true" [minDate]="startDateMonthly" [maxDate]="maxDateMonthly"
								(onClearClick)="monthlyOccurSchedFunc($event,refElm3)" placeholder="MM/DD/YYYY"
								(onSelect)="monthlyOccurSchedFunc($event,refElm3)"
								(ngModelChange)="manualChangeMonthly($event,refElm3)"
								[ngClass]="{'danger-class': endDateMonthly === ''||endDateMonthly === null}" #refElm3>
							</p-calendar>
						</div>
					</div>
				</div>
			</p-panel>
		</div>
		<div class="column Output">
			<p-panel header="Report Format Type" toggleable="true">
				<div class="p-field p-col-10">
					<label class="form-label">File Formats</label>
					<div class="p-grid tibo-myrepo-schelr-secttwo-occur-pselectbtn-cont">
						<p-selectButton [options]="fileFormatOptions" [(ngModel)]="fileValue" [multiple]="false"
							optionLabel="filelabel" optionValue="filevalue" (ngModelChange)="fileFormatChange($event)">
						</p-selectButton>
					</div>
					
				</div>
				<div class="p-field p-col-12" *ngIf="ShowSftpSetting">
					<!-- <p-checkbox *ngIf="showSftp" label="SFTP" class="form-check-box form-check-box-filter-form"
					[binary]="true" [(ngModel)]="isSftp"></p-checkbox> -->
					<p-checkbox *ngIf="showPassword" label="Password Protected" class="form-check-box form-check-box-filter-form"
					[binary]="true" [(ngModel)]="isPassword"></p-checkbox>
				</div>
				<div class="p-field p-col-12">
					<label class="form-label">File Name</label>
					<input type="text" class="form-input" [(ngModel)]="filename" 
						(change)="fileChange(filename)" pInputText>
				</div>
			</p-panel>
		</div>
		<div class="column mail pull-right">
			<p-panel header=" Email Report" toggleable="true">
				<div class="p-field p-col-12">
					<label class="form-label">To<span class="mandatory-simple">*</span></label>
					<input type="email" class="form-input" multiple="multiple" id="mail" [(ngModel)]="ToValue" (ngModelChange)="mailChange(ToValue,'to')"
						pInputText [ngClass]="{'danger-class-text-area': ToValue == '' && alert1}">
				</div>
				<div class="p-field p-col-12">
					<label class="form-label">CC</label>
					<input type="email" class="form-input" multiple="multiple" id="ccmail" [(ngModel)]="CCValue" (ngModelChange)="mailChange(CCValue,'cc')"
						pInputText [ngClass]="{'danger-class-text-area':showError == ''}">
						
					<!-- <p-chips class="form-input" allowDuplicate="false" id="ccmail" [(ngModel)]="CCValue" [separator]="separatorExp" (onChange)="mailChange(CCValue,'cc')" ></p-chips> -->
				</div>
				<div class="p-field p-col-12">
					<label class="form-label">Bcc</label>
					<input type="email" class="form-input" multiple="multiple" id="bccmail" [(ngModel)]="BCCValue" (ngModelChange)="mailChange(BCCValue,'Bcc')"
						pInputText [ngClass]="{'danger-class-text-area': showError1 == ''}">
						
					<!-- <p-chips class="form-input" allowDuplicate="false" id="ccmail" [(ngModel)]="CCValue" [separator]="separatorExp" (onChange)="mailChange(CCValue,'cc')" ></p-chips> -->
				</div>
				<div class="p-field p-col-12">
					<label class="form-label">Subject</label>
					<input type="text" class="form-input" id="subject" [(ngModel)]="SubjectValue"
						(change)="mailChange(SubjectValue,'subject')" pInputText>
					<!-- <p-checkbox label="Add Date to Subject" class="form-check-box form-check-box-filter-form"
						[binary]="true" [(ngModel)]="addSubjectDate"
						(ngModelChange)="addSubjectDateFun($event)"></p-checkbox> -->
				</div>
				<div class="p-field p-col-12">
					<label class="form-label">Message<span class="mandatory-simple">*</span></label>
					<textarea type="text" [rows]="5" [cols]="10" autoResize="autoResize" class="form-input text-area"
						pInputTextarea id="messag" [(ngModel)]="message" (change)="mailChange(message,'msg')"
						[ngClass]="{'danger-class-text-area': message == ''}"></textarea>
				</div>
			</p-panel>
		</div>
		<div class="column Output" *ngIf="ShowSftpSetting1">
			<p-panel header="File Transfer" toggleable="true">
				<div class="p-field p-col-12">
					<label class="form-label">Target Folder Path</label>
					<input type="text" class="form-input" [(ngModel)]="folderPath" [attr.disabled]="true" pInputText>
				</div>
				<div class="p-field p-col-12">
					<label class="form-label">Server Name</label>
					<input type="text" class="form-input" [(ngModel)]="serverName" [attr.disabled]="true" pInputText>
				</div>
			</p-panel>
		</div>
	</div>
	<div class="schedule-btn-div" *ngIf="!isLoading">
		<!-- <p-button class="btn cancel-btn" (click)="cancel($event)" type="button"
			label="Cancel"></p-button> -->
		<button type="cancel" pButton class="btn cancel-btn" (click)="cancel($event)">Cancel</button>
		<!-- <button type="submit" pButton class="btn submit-btn" [disabled]="disabledData"
			(click)="onScheduleBtnClick()">{{buttonName}}</button> -->
		<p-button class="btn save-btn btn-width"  (click)="!disableBtn && onScheduleBtnClick()" type="button"
			icon="{{btnSpinnerIcon}}" [disabled]="disableBtn" label="{{buttonName}}"></p-button>
	</div>
</div>