 <!-- <p-toast styleClass="tibo-myrepo-toast" position="center"></p-toast> -->
	<!-- 
        <p-progressSpinner styleClass="tibo-mydash-drillthru-spinner custom-spinner" animationDuration="2s"></p-progressSpinner> -->
        <img src="././assets/images/dualBallSpinner.svg" alt="" class="spinner-img"
        *ngIf="isLoading">
	<div class="tibo-mydash-drill-thru-widget" style="position:relative; left:0px; top:5%; width:100%; height:95%; background-color:white;" (click)="chartsDivContainerClick($event)">
        <div class="container"  *ngIf="showGrid">
            <div class="drpFilterChkbox" style="float:right">
                <!-- <p-multiSelect class="search-multiselect" [options]="coldata"
                [(ngModel)]="coldataTemp" optionValue="field" optionLabel="headerName" 
                (onClick)="binSelect($event,coldataTemp)" dropdownIcon="bx bx-low-vision" pTooltip="Show/Hide Column"
                tooltipPosition="top">
            </p-multiSelect> -->
                <!-- <button type="button" class="drpButton" (click)="showHide()"><i class="pi pi-eye" pTooltip="Show/Hide Column" tooltipPosition="top"></i></button> -->
                <!-- <button class="drpButton" (click)="Refresh(coldata )"><i class="pi pi-refresh" pTooltip="Refresh" tooltipPosition="top"></i></button> -->
                <button class="drpButton" ><i class="pi {{downloadBtnSpinner}}" pTooltip="Download" tooltipPosition="top" (click)="download1()"></i></button>
                <span class="p-input-icon-right">
                    <i class="pi pi-search" style="padding-top: 0px;"></i>
                    <input type="text" pInputText [(ngModel)]="globalFilter"
                        (ngModelChange)="this.tableSearchUpdate.next($event,'')" placeholder="Search" />
                </span>
                <!-- <div *ngIf="this.showdropdown" class="DropMenus" >
                    <input class="form-control" type="text" name="search" [(ngModel)]="searchText" autocomplete="off" placeholder="Search...">
                    <div class="dropContainer" *ngFor="let d of coldata  |filter: searchText ">
                    <input type="checkbox"  name="{{d.field}}" (change)="binSelect($event,d)" [checked]="d.checked" >{{d.headerName}}<br>
                </div></div> -->
                    <div *ngIf="showmenu" class="downloadmenu">
                        <button type="button" class="downloadall" (click)="download1()" >Download All</button><br>
                        <button type="button" class="downloadall" (click)="download2()">Download filtered grid</button>
            </div>
        </div>
        <!-- <div class="drpFilterChkbox" style="float:right">
            <p-multiSelect class="search-multiselect" [options]="coldata"
                [(ngModel)]="coldataTemp" optionValue="field" optionLabel="headerName" 
                (onClick)="binSelect($event,coldataTemp)" dropdownIcon="bx bx-low-vision" pTooltip="Show/Hide Column"
                tooltipPosition="top">
            </p-multiSelect>
            <button class="drpButton" (click)="Refresh(coldata)" pTooltip="Refresh" tooltipPosition="top"><i
                    class="pi pi-refresh"></i></button>
            <button class="drpButton" (click)="showmenu=!showmenu" (click)="downloadMenu.toggle($event)"
                pTooltip="Download" tooltipPosition="top"><i [class]="laodings ? 'pi pi-spinner pi-spin' : 'pi pi-download'"></i></button>
            <span class="p-input-icon-right">
                <i class="pi pi-search"></i>
                <input type="text" pInputText [(ngModel)]="globalFilter"
                    (ngModelChange)="this.tableSearchUpdate.next($event,'')" placeholder="Search" />
            </span>
            <p-tieredMenu #downloadMenu [model]="downloadButtonList" [popup]="true" my="left top"
                at="left bottom" ></p-tieredMenu>
        </div>    -->
        <div style="clear: both;"></div>
        <div class="main-content">
                <p-table #drillTable [columns]="coldata" [value]="rowData " [scrollable]="true"
                    [reorderableColumns]="true" [autoLayout]="true" selectionMode="single" 
                    [tableStyle]="{'min-width':'50rem','width': tableWidth}" scrollHeight="calc(100vh - 400px)" >
                    <ng-template pTemplate="header" let-columns>
                        <tr>
                            <ng-container *ngFor="let col of columns">
                                <th title=" {{rowData[col.headerName]}}" [pSortableColumn]="col.field" pReorderableColumn *ngIf="col.checked" style="width: 150px;">
                                    {{col.headerName}}
                                    <p-sortIcon [field]="col.field"></p-sortIcon>

                                </th>
                            </ng-container>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-rowData let-columns="columns">
                        <tr>
                            <ng-container *ngFor="let col of columns">
                                <td *ngIf="col.checked" style="width: 150px;" title="{{rowData[col.field]}}">
                                    {{rowData[col.field]}}
                                </td>
                            </ng-container>
                        </tr>
                    </ng-template>
                    <!-- <ng-template pTemplate="body" let-rowData let-columns="columns">
                        <tr>
                            <ng-container *ngFor="let col of columns">
                                <td title=" {{rowData[col.field]}}"  *ngIf="col.checked" style="width: 150px;">
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <input pInputText [(ngModel)]="rowData[col.field]">
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            {{rowData[col.field]}}
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                                <td pEditableColumn style="width: 150px;">
                                    {{rowData[col.field]}}
                                </td>
                            </ng-container>
                        </tr>
                    </ng-template> -->
                    <ng-template pTemplate="emptymessage">
                        <tr>
                            <td >{{ tableEmptyMessage }}</td>
                        </tr>
                    </ng-template>
                </p-table>
                <div class="p-d-flex pagination-container">
                    <div class="pagination-first-part">
                        <p class="p-mx-2">Showing <b>{{startIndex}} - {{endIndex}}</b> of <b>{{totalRecords}}</b>
                            Records
                        </p>
                    </div>
                    <div class="pagination-second-part">
                        <p-paginator #paginator styleClass="tibo-schedrepo-listView-p-paginator" 
                        [rows]="250" 
                        appendTo="body"
                        [totalRecords]="totalRecords" 
                        (onPageChange)="paginate($event)" 
                        [showJumpToPageDropdown]="true" 
                        [showPageLinks]="false"></p-paginator>
                    </div>
                </div>
            </div>
            <!-- <div class="drpFilterChkbox" style="float:right">
                    <button type="button" class="drpButton" (click)="showdropdown=!showdropdown"><i class="pi pi-eye" pTooltip="Show/Hide Column" tooltipPosition="top"></i></button>
                    <button class="drpButton" (click)="Refresh(coldata )"><i class="pi pi-refresh" pTooltip="Refresh" tooltipPosition="top"></i></button>
                    <button class="drpButton" (click)="showmenu=!showmenu"><i class="pi pi-download" pTooltip="Download" tooltipPosition="top"></i></button>
                    <span class="p-input-icon-right">
                        <i class="pi pi-search"></i>
                        <input type="text" pInputText [(ngModel)]="value"  placeholder="Search"/>
                    </span>
                    <div *ngIf="this.showdropdown" class="DropMenus" >
                        <input class="form-control" type="text" name="search" [(ngModel)]="searchText" autocomplete="off" placeholder="Search...">
                        <div class="dropContainer" *ngFor="let d of coldata  |filter: searchText ">
                        <input type="checkbox"  name="{{d.field}}" (change)="binSelect($event,d)" [checked]="d.checked" >{{d.headerName}}<br></div></div>
                        <div *ngIf="showmenu" class="downloadmenu">
                            <button type="button" class="downloadall" (click)="download1()" >Download All</button><br>
                            <button type="button" class="downloadall" (click)="download2()">Download filtered grid</button>
                </div>
            </div>
            <div class="aggriid" >
                <ag-grid-angular 
                style="width: 100%; height:545px;clear: both;overflow:scroll"
                class="ag-theme-alpine"
                [rowData]="rowData |filter: value" 
                [columnDefs]="columnDefs "
                [defaultColDef]="defaultColDef"
                [enableFilter]="true"
                [pagination]="true"
                [gridOptions]="gridOptions"
                [setQuickFilter]="quickFilterText" 
                (gridReady)="onGridReady($event)"
                >
            </ag-grid-angular>
            </div> -->
            </div>
        </div>
        <!-- <p-paginator #pagenoindicator styleClass="tibo-drillthru-model-table-pagin" [rows]="250" [totalRecords]="totalRecords" (onPageChange)="paginate($event)"></p-paginator> -->