<div class="tibo-myrepo-output-wrapper tibo-right-container my-report-output-parent-container">
	<div *ngIf="showreport">
		<!-- <img src="././assets/images/dualBallSpinner.svg" alt="" class="spinner-img" *ngIf="isLoading"> -->
		<!-- <div *ngIf="headerbtn" class="tibo-mydash-dashboard-title" id="full-screen-mode-btn">{{ dashboardName }} -->
			<button class="tibo-mydash-chartcoloroptions-btn" type="button" pButton icon="pi pi-angle-left"
				(click)="back()" pTooltip="Back to Search"></button>
		<!-- </div> -->
	</div>
	<div id="" class="tibo-myrepo-output-container p-d-flex">
		
		<div id="myrepo-output-parameters-panel"  [ngClass]="{'side-menu-collapsed-h1':sideMenuCollapse}" >
			
			<div class="side-menu" [ngClass]="{'side-menu-collapsed':sideMenuCollapse}" *ngIf="showSideBar">
			<div class="side-menu-header"
				[ngClass]="{'p-d-flex':!sideMenuCollapse, 'side-menu-header-collapsed': sideMenuCollapse}">
				<div class="side-menu-header-title">
					<h1 class="side-menu-title" [ngClass]="{'side-menu-title-collapsed':sideMenuCollapse}">Report
						Criteria</h1>
				</div>
				<div class="side-menu-header-icon">
					<i class="pi pi-angle-double-left side-menu-left-icon" *ngIf="!sideMenuCollapse"
						(click)="repoCriteriaPanelToggle()"></i>
					<i class='pi pi-angle-double-right side-menu-right-icon' *ngIf="sideMenuCollapse"
						(click)="repoCriteriaPanelToggle()"></i>
				</div>
			</div>
			
			<div class="side-menu-content">
				<img src="././assets/images/dualBallSpinner.svg" alt="" class="spinner-img1" *ngIf="isLoad"> 
				<div id="myrepo-output-paramspanl-scrollpanel" *ngIf="!sideMenuCollapse" class="above-tree-cont">
					<ng-container *ngFor="let row of parameterElementsArray let i = index">
						<div *ngIf="parameterElementsArray[i]?.visible">
						<div *ngIf="parameterElementsArray[i]?.label.replaceAll(' ','').toLowerCase()!='daterange'"
							class="p-col-12 myreport-output-tree-cont">
							
								<div class="form-label" style="text-wrap: balance;">
									{{parameterElementsArray[i]?.label.replaceAll("_", " ")}}
									<span *ngIf="parameterElementsArray[i]?.mandatory"> <span
											class="mandatory-simple">*</span></span>
								</div>
								<input type="text" class="form-input" pInputText
									*ngIf="(parameterElementsArray[i]?.type == 'singleValueText') || (parameterElementsArray[i]?.type == 'singleValueNumber')"
									[attr.selectInfoIndex]=i
									placeholder="{{'Enter ' + parameterElementsArray[i]?.label.replace('_', ' ') }}"
									(keyup)="onChange($event)" [(ngModel)]="parameterElementsArray[i]?.state.value" (ngModelChange)="onChange($event,undefined,i)">
								<p-dropdown *ngIf="parameterElementsArray[i].type == 'singleSelect'" appendTo="body"
									[filter]="true" 
									[disabled]="parameterElementsArray[i].disabled"
									[attr.selectInfoIndex]=i [options]="parameterElementsArray[i].state?.options"
									[optionLabel]="parameterElementsArray[i].state?.options?.label"
									[optionValue]="parameterElementsArray[i].state?.options?.value"
									placeholder="{{'Select ' + parameterElementsArray[i]?.label.replace('_', ' ') }}"
									[dropdownIcon]="parameterElementsArray[i].loading ? 'pi pi-spinner pi-spin' : 'pi pi-chevron-down'"
									(onChange)="onChange($event,refEl,i)" [(ngModel)]="parameterElementsArray[i].selectedData" #refEl>
								</p-dropdown>
								<p-multiSelect class="search-multiselect"
									*ngIf="parameterElementsArray[i].type == 'multiSelect'" appendTo="body"
									[attr.selectInfoIndex]=i [options]="parameterElementsArray[i].state?.options"
									[optionLabel]="parameterElementsArray[i].state?.options?.label"
									[optionValue]="parameterElementsArray[i].state?.options?.value"
									[dropdownIcon]="parameterElementsArray[i].loading ? 'pi pi-spinner pi-spin' : 'pi pi-chevron-down'"
									[defaultLabel]="'Please Select'" (onChange)="onChange($event,refEl,i)" [(ngModel)]="parameterElementsArray[i].selectedData" #refEl>
								</p-multiSelect>
								<p-calendar *ngIf="parameterElementsArray[i].type == 'singleValueDate'"
										[showIcon]="true" inputId="icon" yearRange="1000:3000"
									dateFormat="mm/dd/yy" appendTo="body" [monthNavigator]="true" [yearNavigator]="true"
									 [attr.selectInfoIndex]=i placeholder="MM/DD/YYYY" (ngModelChange)="ManualChange($event,refEl,i,parameterElementsArray[i].id)"
									(onSelect)="onChange($event,refEl,i)" [(ngModel)]="parameterElementsArray[i].selectedData[0]" #refEl>
								</p-calendar>
								<div *ngIf="parameterElementsArray[i].type == 'singleSelectRadio'">
									<div *ngFor="let option of parameterElementsArray[i].state?.options" >
											<p-radioButton class="radio-button-margin" [inputId]="option.value" name="option" [value]="option.value" [label] ="option.label"
											(onClick)="onChange($event,refEl,i)"
											 [(ngModel)]="parameterElementsArray[i].selectedData" #refEl></p-radioButton>
										<!-- </span> -->
											<!-- <label [for]="option.value">{{option.label}}</label> -->
									</div>
								</div>
								<div *ngIf="parameterElementsArray[i].type == 'combobox'">
									<div class="p-field p-col-12 scheduler-addon-dropdown-col">
										<div class="p-inputgroup">
											<span class="p-inputgroup-addon"><p-radioButton name="category" value="1"
													[(ngModel)]="values"></p-radioButton></span>
											<p-dropdown class="scheduler-addon-dropdown" appendTo="body"
												[attr.selectInfoIndex]=i
												[options]="parameterElementsArray[i].state?.options"
												[optionLabel]="parameterElementsArray[i].state?.options?.label"
												[optionValue]="parameterElementsArray[i].state?.options?.value"
												placeholder="{{'Select ' + parameterElementsArray[i]?.label.replace('_', ' ') }}"
												(onChange)="onChange($event,refEl)" [(ngModel)]="parameterElementsArray[i].selectedData" #refEl>
											</p-dropdown>
										</div>
										<div class="p-inputgroup">
											<span class="p-inputgroup-addon"><p-radioButton name="category" value="2"
													[(ngModel)]="values"></p-radioButton></span>
											<input type="text" pInputText class="form-input" [attr.selectInfoIndex]=i
												placeholder="{{'Enter ' + parameterElementsArray[i]?.label.replace('_', ' ') }}"
												(keyup)="onChange($event)" [(ngModel)]="parameterElementsArray[i].selectedData" (ngModelChange)="onChange($event,undefined,i)" #refEl>
										</div>
									</div>
								</div>
							</div>
						</div>
					</ng-container>
				</div>
			</div>
			<div class="btn-div">
				<button type="button" pButton id="submitbutton-id" class="btn submit-btn" label="Submit"
					(click)="onFilterSubmitClick()" *ngIf="!sideMenuCollapse"></button>
			</div>
		</div>
	</div>
		<div id="myrepo-output-container" class="tibo-myrepo-output-view second-section">
			<div class="table-header">
				<div class="table-header-title-section">
					<label class="table-header-title">{{reportName?.substring(0, reportName?.lastIndexOf('.'))}}</label>
					<label *ngIf="isMatrixData">{{reportName}}</label>
				</div>
				
				<!-- <button *ngIf="showDownloadBtn" class="tibo-myrepo-output-download-btn" type="button" pButton icon="pi pi-download"
					(click)="downloadtypemenu.toggle($event)"></button>-->
				<!-- <p-tieredMenu #downloadtypemenu styleClass="tibo-myrepo-output-download-overlay" *ngIf="showDownloadBtn"
					[model]="downloadTypeListArray" [popup]="true" my="left top" at="left top"></p-tieredMenu>  -->
				<button *ngIf="showDownloadBtn" class="download-button" type="button" pButton
					icon="pi {{downloadBtnSpinner}}" (click)="downloadtypemenu.toggle($event)" pTooltip="Download"
					tooltipPosition="left"></button>
				<p-tieredMenu #downloadtypemenu [model]="downloadTypeList" [popup]="true" my="left top"
					at="left bottom"></p-tieredMenu>
					<!-- <button class="tibo-topbar-button table-filter-icons" type="button" pButton
					icon="pi {{downloadBtnSpinner}}" (click)="downloadMenu.toggle($event)" pTooltip="Export"
					tooltipPosition="left"></button>
				<p-tieredMenu #downloadMenu [model]="downloadButtonList" [popup]="true" my="right top"
					at="right bottom"></p-tieredMenu> -->
			</div>
			<div *ngIf="showTab" style="width: 100px;margin-bottom: 10px;">
				<p-dropdown [options]="tabOptions" [(ngModel)]="selectedTab" placeholder="Select a City" optionLabel="label" 
				(onChange)="getTab(selectedTab)"></p-dropdown>
			</div>
			<p-progressSpinner styleClass="tibo-myrepo-output-spinner custom-spinner"
				animationDuration="2s"></p-progressSpinner>
			<div *ngIf="showBirt">	
				
			<iframe id="report-output-birt" class="tibo-myrepo-output-view-iframe"
				[ngClass]="{'visibility-class': !showDownloadBtn}"></iframe>

				<!-- object code -->
				<!-- <object id="report-output-birt" class="tibo-myrepo-output-view-object"
							[ngClass]="{'visibility-class': !showDownloadBtn}" 
							type="text/html"
							width="100%" height="800px">
					<p>Your browser does not support displaying this content. 
						Please <a [href]="birtReportUrl" target="_blank">open the report</a>.
					</p>
			</object> -->
			<!-- object code -->
			</div>
			<!-- <div id="report-output-sql" class="tibo-myrepo-output-view-sqltable-cont">
				<p-table #myreportssqloutputtable [columns]="sqlTableColumns" [value]="reportOutput"
					[sortField]="sqlTableSortColumn" [sortOrder]="sqlTableSortDir" [scrollable]="true"
					scrollHeight="flex" (sortFunction)="customSort($event)" [customSort]="true"
					[globalFilterFields]="globalFilterFieldsArr">
					<ng-template pTemplate="caption">
						<div class="p-d-flex p-jc-between p-ai-center">
							<span class="p-input-icon-left p-ml-auto">
								<i class="pi pi-search"></i>
								<input pInputText type="text"
									(input)="myreportssqloutputtable.filterGlobal($event.target.value, 'contains')"
									placeholder="Search keyword" />
							</span>
							<label class="p-mx-2">Showing {{reportOutput?.length}} of {{sqlTotalRecords}}</label>
						</div>
					</ng-template>
					<ng-template pTemplate="header" let-columns>
						<tr>
							<th *ngFor="let col of columns" [pSortableColumn]="col.header">
								<div class="p-d-flex p-jc-between p-ai-center">
									{{col.header}}
									<p-sortIcon [field]="col.header"></p-sortIcon>
								</div>
							</th>
						</tr>
					</ng-template>
					<ng-template pTemplate="body" let-rowData let-columns="columns">
						<tr>
							<td *ngFor="let col of columns">
								{{rowData[col.header]}}
							</td>
						</tr>
					</ng-template>
					<ng-template pTemplate="emptymessage">
						<tr>
							<td colspan="8">No Records Found</td>
						</tr>
					</ng-template>
				</p-table>
				<p-paginator *ngIf="reportType.toLowerCase() == 'sql'" styleClass="tibo-myrepo-output-view-p-paginator"
				[rows]="250" [totalRecords]="sqlTotalRecords" (onPageChange)="paginate($event)"></p-paginator>
			</div> -->
			<!-- <div style="width: 100%; overflow-x: auto;" id="report-output-sql" class="tibo-myrepo-output-view-sqltable-cont">
				<p-table #myreportssqloutputtable [columns]="this.coldata" [value]="this.rowData " [scrollable]="true"
				[reorderableColumns]="true" [autoLayout]="true" selectionMode="single" 
				[tableStyle]="{'min-width':'50rem'}" scrollHeight="calc(100vh - 330px)" editable="true">
				<ng-template pTemplate="header" let-columns>
					<tr>
						<ng-container *ngFor="let col of columns">
							<th title=" {{rowData[col.headerName]}}" [pSortableColumn]="col.field" pReorderableColumn *ngIf="col.checked" style="width: 150px;">
								{{col.headerName}}
								<p-sortIcon [field]="col.field"></p-sortIcon>

							</th>
						</ng-container>
					</tr>
				</ng-template>
				<ng-template pTemplate="body" let-rowData let-columns="columns">
					<tr>
						<ng-container *ngFor="let col of columns">
							<td title=" {{rowData[col.field]}}" pEditableColumn *ngIf="col.checked" style="width: 150px;">
								<p-cellEditor>
									<ng-template pTemplate="input">
										<input pInputText [(ngModel)]="rowData[col.field]">
									</ng-template>
									<ng-template pTemplate="output">
										{{rowData[col.field]}}
									</ng-template>
								</p-cellEditor>
							</td>
							
						</ng-container>
					</tr>
				</ng-template>
				<ng-template pTemplate="emptymessage">
					<tr>
						<td >{{ tableEmptyMessage }}</td>
					</tr>
				</ng-template>
			</p-table>
			<div class="p-d-flex pagination-container">
				<div class="pagination-first-part">
					<p class="p-mx-2">Showing <b>{{this.rowData?.length}}</b> of <b>{{totalRecords}}</b>
						Records
					</p>
				</div>
				<div class="pagination-second-part">
					<p-paginator styleClass="tibo-schedrepo-listView-p-paginator" [rows]="250"
						[totalRecords]="totalRecords" (onPageChange)="paginate($event)"
						[showJumpToPageDropdown]="true" [showPageLinks]="false"></p-paginator>
				</div>
			</div>
            </div> -->
			<div id="report-output-sql" style="background-color:#ffffff">
			<div class="drpFilterChkbox" style="float:right">
				<!-- <i class="pi pi-filter pi-filter-icon table-filter-icons" (click)="showFilterFields()"
					pTooltip="{{filterToolTip}} Filter" tooltipPosition="top"></i>
				<i class="pi pi-filter-slash table-filter-icons" (click)="clearTableFilter()"
					pTooltip="Reset/Clear Filter" tooltipPosition="top"></i> -->
				<p-multiSelect class="search-multiselect" defaultLabel="Search" [options]="coldataSelectAll"
					[(ngModel)]="coldataTemp" optionValue="field" optionLabel="headerName" appendTo="body"
					(onChange)="binSelect($event)" dropdownIcon="bx bx-low-vision" pTooltip="Show/Hide Column"
					tooltipPosition="top">
				</p-multiSelect>
				<button class="drpButton" (click)="Refresh(coldataSelectAll)" pTooltip="Refresh" tooltipPosition="top"><i
						class="pi pi-refresh"></i></button>
				<button class="drpButton" (click)="showmenu=!showmenu" (click)="downloadMenu.toggle($event)"
					pTooltip="Download" tooltipPosition="top"><i [class]="laodings ? 'pi pi-spinner pi-spin' : 'pi pi-download'"></i></button>
				<!-- <span class="p-input-icon-right">
					<i class="pi pi-search"></i>
					<input type="text" pInputText [(ngModel)]="globalFilter"
						(ngModelChange)="this.tableSearchUpdate.next($event)" placeholder="Search" />
				</span> -->
				<p-tieredMenu #downloadMenu [model]="downloadButtonList" [popup]="true" my="left top"
					at="left bottom" ></p-tieredMenu>
			</div>
			<div style="clear: both;"></div>
			<div class="main-content">	
				<p-card class="table-card" [ngClass]="{'empty-table-card':rowData.length === 0}">
				<div *ngIf="isLoading2" class="spinner-overlay">
                    <img src="././assets/images/dualBallSpinner.svg" alt="" class="modal-spinner-img">
                </div>
				<p-table #myreportssqloutputtable [columns]="this.coldata" [value]="this.rowData" [scrollable]="true"  
					[reorderableColumns]="true" [autoLayout]="true" selectionMode="single" [sortField]="sqlTableSortColumn" [sortOrder]="sqlTableSortDir"
					scrollHeight="calc(100vh - {{tableReduceHeight+'px'}})"
                        [tableStyle]="{'min-width':'50rem','width': tableWidth}" 
					(onColReorder)="handleColReorder($event)">
					<ng-template pTemplate="header" let-columns>
						<tr>
							<ng-container *ngFor="let col of columns; trackBy: trackByFn">
								<th *ngIf="col.checked" [pSortableColumn]="col.field" pReorderableColumn 
									 style="width: 150px;" title="{{col.headerName}}" [style.width]="dynamicWidth(col.field,col.headerName)">
									{{col.headerName}}
									<p-sortIcon [field]="col.field"></p-sortIcon>

								</th>
							</ng-container>
						</tr>
						<!-- <tr *ngIf="showFilterFieldsBoo " class="input-tr animateH5">
							<ng-container *ngFor="let col of columns">
								<th class="filter-input-th" *ngIf="col.type === 'text' && col.checked"> 
									<input class="filter-input-table" pInputText type="text"  id="{{ '1'+col.id }}" [(ngModel)]="col.selectedDataStart"
									(ngModelChange)="this.tableSearchUpdate1.next([$event,this.coldata,'startw'])" placeholder="Starts with" />
									<input class="filter-input-table" pInputText type="text"  id="{{ '2'+col.id }}" [(ngModel)]="col.selectedDataLike"
									(ngModelChange)="this.tableSearchUpdate1.next([$event,this.coldata,'like'])" placeholder="Contains" />
									<input class="filter-input-table" pInputText type="text"  id="{{ '3'+col.id }}" [(ngModel)]="col.selectedDataEnd"
									(ngModelChange)="this.tableSearchUpdate1.next([$event,this.coldata,'endw'])" placeholder="Ends with" />
								</th>
								<th class="filter-input-th" *ngIf="col.type === 'date'&& col.checked ">
									<p-calendar class="filter-input-table" appendTo="body" (ngModelChange)="this.tableSearchUpdate1.next([$event,this.coldata,'dtlt'])" [showIcon]="true" [(ngModel)]="col.selectedDataStart"
										placeholder="Before" hourFormat="12" [monthNavigator]="true" id="{{ '1'+col.id }}"
										[yearNavigator]="true" yearRange="1000:3000"></p-calendar>
									<p-calendar class="filter-input-table" appendTo="body"
									(ngModelChange)="this.tableSearchUpdate1.next([$event,this.coldata,'dtgt'])" [showIcon]="true" [(ngModel)]="col.selectedDataLike"
										placeholder="After" hourFormat="12" [monthNavigator]="true" id="{{ '1'+col.id }}"
										[yearNavigator]="true" yearRange="1000:3000"></p-calendar>
									<p-calendar class="filter-input-table" appendTo="body"  (ngModelChange)="this.tableSearchUpdate1.next([$event,this.coldata,'dton'])" [(ngModel)]="col.selectedDataEnd"
										[showIcon]="true" placeholder="On" hourFormat="12" id="{{ '1'+col.id }}"
										[monthNavigator]="true" [yearNavigator]="true" yearRange="1000:3000"></p-calendar>
								</th>
								<th class="filter-input-th" *ngIf="(col.type === 'number'||col.type === 'INT')&& col.checked">
									<input class="filter-input-table" pInputText type="text" id="{{ '1'+col.id }}" [(ngModel)]="col.selectedDataStart"
									(ngModelChange)="this.tableSearchUpdate1.next([$event,this.coldata,'lt'])" placeholder="<" />
									<input class="filter-input-table" pInputText type="text" id="{{ '2'+col.id }}" [(ngModel)]="col.selectedDataLike"
										(ngModelChange)="this.tableSearchUpdate1.next([$event,this.coldata,''])" placeholder=">" />
									<input class="filter-input-table" pInputText type="text" id="{{ '3'+col.id }}" [(ngModel)]="col.selectedDataEnd"
									(ngModelChange)="this.tableSearchUpdate1.next([$event,this.coldata,'eq'])" placeholder="=" />
								</th>
							</ng-container> 
						</tr> -->
					</ng-template>
					<ng-template pTemplate="body" let-rowData let-columns="columns">
						<tr>
							<ng-container *ngFor="let col of columns">
								<td *ngIf="col.checked" style="width: 150px;" title="{{rowData[col.field]}}" [style.width]="dynamicWidth(col.field,col.headerName)">
									{{rowData[col.field]}}
								</td>
							</ng-container>
						</tr>
					</ng-template>
					<ng-template pTemplate="emptymessage">
						<tr>
							<td>{{ tableEmptyMessage }}</td>
						</tr>
					</ng-template>
				</p-table>
				</p-card>
				<div class="p-d-flex pagination-container" *ngIf="reportType.toLowerCase() == 'sql'">
					<div class="pagination-first-part">
						<p class="p-mx-2">Showing <b>{{startIndex}}-{{endIndex}}</b> of <b>{{totalRecords}}</b>
							Records
						</p>
					</div>
					<div class="pagination-second-part">
						<p-paginator #paginator styleClass="tibo-schedrepo-listView-p-paginator" [rows]="150"
							[totalRecords]="totalRecords" (onPageChange)="paginate($event)" appendTo="body"
							[showJumpToPageDropdown]="true" [showPageLinks]="false"></p-paginator>
					</div>
				</div>
			</div>
		</div>
			<div style="width: 100%; " *ngIf="showBirtRepo">
                <p-table #birtTable [columns]="this.coldata" [value]="this.rowData " [scrollable]="true"
                    [reorderableColumns]="true" [autoLayout]="true" selectionMode="single" 
                    [tableStyle]="{'min-width':'50rem','width': tableWidth}" scrollHeight="calc(100vh - 330px)" editable="true">
                    <ng-template pTemplate="header" let-columns>
                        <tr>
                            <ng-container *ngFor="let col of columns">
                                <th title="{{rowData[col.headerName]}}" [pSortableColumn]="col.field" pReorderableColumn *ngIf="col.checked" style="width: 150px;">
                                    {{col.headerName}}
                                    <p-sortIcon [field]="col.field"></p-sortIcon>

                                </th>
                            </ng-container>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-rowData let-columns="columns">
                        <tr>
                            <ng-container *ngFor="let col of columns">
                                <td title=" {{rowData[col.field]}}" pEditableColumn *ngIf="col.checked" style="width: 150px;">
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <input pInputText [(ngModel)]="rowData[col.field]">
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            {{rowData[col.field]}}
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                                <!-- <td pEditableColumn style="width: 150px;">
                                    {{rowData[col.field]}}
                                </td> -->
                            </ng-container>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="emptymessage">
                        <tr>
                            <td >{{ tableEmptyMessage }}</td>
                        </tr>
                    </ng-template>
                </p-table>
                <div class="p-d-flex pagination-container">
                    <div class="pagination-first-part">
                        <p class="p-mx-2">Showing <b>{{this.rowData?.length}}</b> of <b>{{totalRecords}}</b>
                            Records
                        </p>
                    </div>
                    <div class="pagination-second-part">
                        <p-paginator styleClass="tibo-schedrepo-listView-p-paginator" [rows]="150"
                            [totalRecords]="totalRecords" (onPageChange)="paginate($event)"
                            [showJumpToPageDropdown]="true" [showPageLinks]="false"></p-paginator>
                    </div>
                </div>
            </div>
			
		</div>
	</div>
	<p-messages [(value)]="alertMessage" [enableService]="false"
				styleClass="tibo-myrepo-output-params-filtrpanl-alert"></p-messages>
</div>
