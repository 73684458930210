import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as XLSX from 'xlsx';
@Injectable({
  providedIn: 'root'
})
export class HomeService {

  constructor(private http: HttpClient) { }
  setChangeProfile(payload) {
    return this.http.put('api/v1/profile/user', payload, { headers: { 'X-Auth-Token': localStorage.getItem("token") }, observe: 'response' });
  }
  setChangePassword(payload) {
    return this.http.put('api/v1/profile/user/password', payload, { headers: { 'X-Auth-Token': localStorage.getItem("token") }, observe: 'response' });
  }

  recordsService(page, requestPayload) {
    return this.http.post('api/v1/job-logs/search?limit=250&page=' + page, requestPayload,
      { headers: { 'X-Auth-Token': localStorage.getItem("token") }, observe: 'response' });
  }
  getRecentDashboard(){
    return this.http.get('assets/json/PreviousDashAndReport.json')

  }
  getRecentReports(){
    return this.http.get('api/v1/recent/reports', { headers: {'X-Auth-Token': localStorage.getItem("token")}, observe: 'response' })
  }
  getRecentScheduledReports(){
    return this.http.get('api/v1/scheduled/reports/ending', { headers: {'X-Auth-Token': localStorage.getItem("token")}, observe: 'response' })
  }
  dashboardsSearchService(keyword){
    return this.http.post('http://72.167.143.20:8082/autocomplete', {"mytext":keyword}
            )
}
dashboardsSearchEngine(keyword){
 
  return this.http.get('assets/json/'+keyword+'/homeSearchResult.json')
}
exportToExcel(data: any[], fileName: string): void {
  const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);
  const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
  XLSX.writeFile(workbook, `Daily report.xlsx`);
}
dashboardsReportsCounts(){
  // timebound/api/v1/status/dailyScheduledReports
  return this.http.get('api/v1/count/dailyScheduledReports', { headers: {'X-Auth-Token': localStorage.getItem("token")}, observe: 'response' })
}
getTotalReportsCounts(){
  return this.http.get('api/v1/status/dailyScheduledReports', { headers: {'X-Auth-Token': localStorage.getItem("token")}, observe: 'response' })
  }
  getreportsin3days(){
    return this.http.get('api/v1/scheduled/reports/ending', { headers: {'X-Auth-Token': localStorage.getItem("token")}, observe: 'response' })
    }
    trackerSearch(payload,type) {
      return this.http.post('api/v1/datadiscovery/question/answer/tracker?searchType='+ type, payload,
          { headers: { 'X-Auth-Token': localStorage.getItem("token") }, observe: 'response' })
  }
}
