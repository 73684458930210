import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class MyReportsService {
    
    constructor(private http: HttpClient) { }
	getDefaultDashboard() {
        return this.http.get('api/v1/user/config', 
                { headers: {'X-Auth-Token': localStorage.getItem("token")}, observe: 'response' })
    }
	directoryService(param) {
        return this.http.get('assets/json/report/'+ param+'.json')
    }
	
	recordsService(param,page,requestPayload, depth) {
		return this.http.get('assets/json/report/'+ param+'Search.json')
	}
	
	settingsGetService(){
		return this.http.get('assets/json/config.json')
	}
	
	settingsPostService(settingsReqPayLoad){
		return this.http.put('api/v1/user/config', settingsReqPayLoad, { headers: {'X-Auth-Token': localStorage.getItem("token")}, 
		observe: 'response' })
	}
	
	reportAddToFavoritesService(param){
		return this.http.post('api/v1/report/'+param+'/favourite', {},
		{ headers: {'X-Auth-Token': localStorage.getItem("token")}, observe: 'response' })
	}

	getFolder(){
		return this.http.get('timebound/views/directoryModel.html')
	}

	AddFolder(param,payload){
		return this.http.post('api/v1/directory/'+param+'/directory',payload,{ headers: {'X-Auth-Token': localStorage.getItem("token")}, observe: 'response' })
	}

	updateFolderData(param,payload){
		return this.http.put('api/v1/directory/'+param,payload,{ headers: {'X-Auth-Token': localStorage.getItem("token")}, observe: 'response' })
	}

	deleteFolderData(param){
		return this.http.delete('api/v1/directory/'+param,{ headers: {'X-Auth-Token': localStorage.getItem("token")}, observe: 'response' })
	}
	getSearch(){
		return this.http.get('api/reports/requestor/clientcode/search',{ headers: {'X-Auth-Token': localStorage.getItem("token")}, observe: 'response' })
	}

	getreportType(){
		return this.http.get('api/v1/report-type',{ headers: {'X-Auth-Token': localStorage.getItem("token")}, observe: 'response' })
	}
	setfileType(payload){
		return this.http.post('api/v1/report/upload?fileType=uploadFile',payload,{ headers: {'X-Auth-Token': localStorage.getItem("token")}, observe: 'response' })
	}

	setAddReport(param,payload){
		return this.http.post('api/v1/directory/'+param+'/report',payload,{ headers: {'X-Auth-Token': localStorage.getItem("token")}, observe: 'response' })
	}
	deleteReportData(param){
		return this.http.delete('api/v1/report/'+param,{ headers: {'X-Auth-Token': localStorage.getItem("token")}, observe: 'response' })
	}

	editReportData(param){
		return this.http.get('api/v1/report/'+param+'/dynamic-values',{ headers: {'X-Auth-Token': localStorage.getItem("token")}, observe: 'response' })
	}

	UpdateAddReport(param,payload){
		return this.http.put('api/v1/report/'+param,payload,{ headers: {'X-Auth-Token': localStorage.getItem("token")}, observe: 'response' })
	}
	updatedReport(param,payload){
		return this.http.post('api/v1/directory/'+param+'/report',payload,{ headers: {'X-Auth-Token': localStorage.getItem("token")}, observe: 'response' })
	}

	getPermission(param,payload){
		return this.http.get('api/v1/object/'+param+'/permission/'+payload,{ headers: {'X-Auth-Token': localStorage.getItem("token")}, observe: 'response' })
	}

	searchUser(payload){
		return this.http.post('api/v1/user/search?page=1&limit=20', payload,
		{ headers: {'X-Auth-Token': localStorage.getItem("token")}, observe: 'response' })
	}

	searchRole(payload){
		return this.http.post('api/v1/role/search?page=1&limit=20', payload,
		{ headers: {'X-Auth-Token': localStorage.getItem("token")}, observe: 'response' })
	}
	
	assignReport(payload){
		return this.http.post('api/v1/object/permission',payload,{ headers: {'X-Auth-Token': localStorage.getItem("token")}, observe: 'response' })
	}
	reportInfoService(reportId){
		return this.http.get('assets/json/report/'+ reportId+'information.json')
	}
	specificationReport(reportId,payload){
		return this.http.post('api/v1/report/'+reportId+'/specification/search?limit=1&page=1',payload,{ headers: {'X-Auth-Token': localStorage.getItem("token")}, observe: 'response' })
	}
	specificationHistoryReport(reportId,payload){
		return this.http.post('api/v1/report/'+reportId+'/specification/search?limit=150&page=1',payload,{ headers: {'X-Auth-Token': localStorage.getItem("token")}, observe: 'response' })
	}
	specificationWhoElseUsesReport(reportId,payload){
		return this.http.post('api/v1/report/'+reportId+'/who-else-uses/search?limit=5&page=1',payload,{ headers: {'X-Auth-Token': localStorage.getItem("token")}, observe: 'response' })
	}
	getDataSource(payload){
		return this.http.post('api/v1/datasource/list/?limit=10000&page=1',payload,{ headers: {'X-Auth-Token': localStorage.getItem("token")}, observe: 'response' })

	}
}

