import { Component, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { ConfirmationService, MessageService } from 'primeng/api';
import {
  ActivatedRoute,
  NavigationEnd,
  NavigationStart,
  Router,
} from '@angular/router';
import { AppComponentService } from '../app-component.service';
//import { AuthService } from '../auth.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';

declare var d3: any;

@Component({
  selector: 'app-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.css'],
  providers: [ConfirmationService],
})
export class TopBarComponent implements OnInit {
  userName = '';
  userButtonList: MenuItem[];
  showLogoutModal!: boolean;
  storePath: any = ['Home '];
  routingCompList = {
    '/home': '',
    '/my-dashboard': 'My Dashboard',
    '/new-dashboard': 'New Dashboard',
    '/dashboard-v3': 'Dashboard V3',
    '/dashboard': 'Dashboard',
    '/create-dashboard': 'Create Dashboard',
    '/data-discovery': 'Data Discovery',
    '/data-analytics': 'Data Analytics',
    '/my-reports': 'My Reports',
    '/view': 'View',
    '/scheduler': 'scheduler',
    '/scheduled-reports': 'Schedule Reports',
    '/schedule-history': 'Schedule History',
    '/report-catalog': 'Report Catalog',
    '/my-report-inbox': 'My Report Inbox',
    '/my-favorites': 'My Favorites',
    '/build-report': 'Build A Report',
    // '/widget': 'widget',
    '/discovery': 'Discovery',
    '/payroll': 'Payroll',
    '/payrollValidation': 'payroll Validation',
    '/settings': 'settings',
    '/dashboard-designer': 'Dashboard Designer',
    '/self-service': 'Self Service',
    '/sservice/:id': 'Self Service',
    // '/dashboard-preview': 'Dashboard Preview',
    // '/admin-security': 'Admin Security',
    // '/Configuration': 'Configuration',
    '/admin-security': 'Manage Security',
    '/Config': 'Configuration',
    '/Permission':'Permission',
    '/help':'Help',
    '/adhoc':'Adhoc'
  };

  getUrl = 'Home';
  showLogout: boolean=false;
  alertSeverity: string;
  alertMsg: string;
  passwordForm = new FormGroup({
    oldPassword: new FormControl('',[Validators.required]),
    newPassword: new FormControl('',[Validators.required]),
    reEnter: new FormControl('',[Validators.required]),
  });
  accountForm = new FormGroup({
    firstName: new FormControl('',[Validators.required]),
    lastName: new FormControl(''),
    email: new FormControl('',[Validators.required]),
    phone: new FormControl(''),
  });
  userDetail: any;
  showProfileDialog: boolean;
  isPasswordVisible: boolean=false;
  isPasswordVisible1: boolean=false;
  isPasswordVisible2: boolean=false;
  hideIcon = 'pi pi-eye';
  showIcon = 'pi pi-eye-slash';
  tabHeaderName = 'Account Information';
  showError: boolean=false;

  constructor(
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private router: Router,
    private route: ActivatedRoute,
    private appComponentService:AppComponentService,
    //private authService:AuthService
  ) {}

  ngOnInit(): void {
    this.showLogout=false;
    //var loggedIn=this.authService.autoLogin();
    // if(loggedIn){
    //   this.showLogout=false;
    //   setTimeout(() => {
      this.initialCall();
    // }, 1000);
    // }
    // else{
      this.showLogout=true;
      this.initialCall();
    // }
  }
  initialCall(){
    let url1 ='';
    url1 = window.location.href;
    //console.log(url1)
    let title1 =''
    title1 = this.getTitleFromUrl(url1); 
   
       if(title1 === "dashboard"){
            this.storePath[0]="Home /"
            this.storePath[1]='My Dashboard';
          }
          else if(title1 == "my-favorites"){
            this.storePath[0]="Home /"
            this.storePath[1]='Favorites';
          }
          else if(title1== "my-reports"){
            this.storePath[0]="Home /"
            this.storePath[1]='My Reports';
          }
          else if(title1== "my-report-inbox"){
            this.storePath[0]="Home /"
            this.storePath[1]='Report Inbox';
          }
          else if(title1== "scheduled-reports"){
            this.storePath[0]="Home /"
            this.storePath[1]='Schedule Reports';
          }
          else if(title1 == "build-report"){
            this.storePath[0]="Home /"
            this.storePath[1]='Build A Report';
          }
          else if(title1 == "adhoc"){
            this.storePath[0]="Home /"
            this.storePath[1]='Adhoc';
          }
          else if(title1 == "discovery"){
            this.storePath[0]="Home /"
            this.storePath[1]='Data Discovery';
          }
          else if(title1== "schedule-history"){
            this.storePath[0]="Home /"
            this.storePath[1]='Schedule History';
          }
          else if(title1== "payrollValidation"){
            this.storePath[0]="Home /"
            this.storePath[1]='Payroll Validation';
          }
          else if(title1== "payroll"){
            this.storePath[0]="Home /"
            this.storePath[1]='Payroll';
          }
          if(title1 == "Config"){
            this.storePath[0]="Home /"
            this.storePath[1]='Configuration';
          }
          else if(title1 == "Permission"){
            this.storePath[0]="Home /"
            this.storePath[1]='Permission';
          }
          else if(title1== "data-analytics"){
            this.storePath[0]="Home /"
            this.storePath[1]='Data Analytics';
          }
          else if(title1== "dashboard-designer"){
            this.storePath[0]="Home /"
            this.storePath[1]='Dashboard Designer';
          }
          else if(title1== "report-catalog"){
            this.storePath[0]="Home /"
            this.storePath[1]='Report Catalog';
          }
          else if(title1== "document"){
            this.storePath[0]="Home /"
            this.storePath[1]='My Documents';
          }
          else if(title1== "self-service"){
            this.storePath[0]="Home /"
            this.storePath[1]='Self Service';
          }
          else if(title1== "admin-security"){
            this.storePath[0]="Home /"
            this.storePath[1]='Manage Security';
          }
          else if(title1== "settings"){
            this.storePath[0]="Home /"
            this.storePath[1]='Settings';
          }
          else if(title1== "help"){
            this.storePath[0]="Home /"
            this.storePath[1]='Help';
          }
          else if(title1== "GuidedTour"){
            this.storePath[0]="Home /"
            this.storePath[1]='Guided Tours';
          }
          else if(title1== "home"){
            this.storePath[0]="Home"
            this.storePath[1]="";
          }
          else if(title1 == undefined){
            this.storePath[0]="Home"
            this.storePath[1]="";
  }
    this.userName = localStorage.getItem('userName');
    this.userButtonList = [
      
      {
        label: 'Profile Setting',
        icon: 'pi pi-user',
        id: '',
        command: () => {
          this.adminProfile();
        },
      },
      {
        label: 'Log Out',
        icon: 'pi pi-fw pi-sign-out',
        id: '',
        command: () => {
          this.logoutConfirmFunc();
        },
      }
    ];
    this.appComponentService.getUserDetails().subscribe((res:any) => {
      this.userName = res.userName;
      this.userDetail = res;
    });
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        let title ;
        this.route.firstChild?.data.subscribe(data => {
          title = data['title'];
          console.log('top bar',title) // Assuming you pass the title in route data
          // if (url1.includes('isMatrix=true')){
          //   window.location.href=url1;
          // }
      });
        const url = event.url;
        if (title === '/home') {
          this.storePath[0]="Home";
          this.storePath[1] = '';

        } 
        else {
          // const title = this.routingCompList[url];
          if (title !== undefined) {
            this.storePath[0]="Home /";
            this.storePath[1] = title;

          }
        }
        // this.url=window.location.href;
     
      }
    });
  }
  getTitleFromUrl(url: string): string {
    const hashIndex = url.indexOf('#'); 
    if (hashIndex !== -1) {
        const hashPart = url.substr(hashIndex + 1);
        if (!hashPart.includes('?')) { 
            const parts = hashPart.split('/');
            return parts[1];
        }
    }
    return undefined;
  }
  logoutConfirmFunc() {
    this.showLogoutModal = true;
  }
  switchFunc() {
    // const user = localStorage.getItem("userName") || "";
    // const domain = user.substring(user.indexOf('@') + 1);
    // this.setCookie('mgmtwebUserName', '"' + user+ '"',undefined, '/', undefined, true, 'None');
    // this.setCookie('mgmtwebPassword', `""`);
    // this.setCookie('mgmtwebDomain','"' + domain+ '"',undefined, '/', undefined, true, 'None');
    const newUrl = 'https://www.matrixabsenceuat.com/timeboundlegacy/ ';
    window.location.href = newUrl;
    // // const newWindow = window.open(newUrl, '_blank');
    // // setTimeout(() => {
    //   this.deleteCookie('mgmtwebUserName',  '/');
    //   this.deleteCookie('mgmtwebPassword',  '/')
    //   this.deleteCookie('mgmtwebDomain',  '/')
    // // },100);
  }
  deleteCookie(name: string, path: string = '/') {
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=${path}`;
    //console.log(`Cookie deleted: ${name}`);
  }
  logoutFunc() {
    localStorage.clear();
    // this.authService.clearCookies();
    this.router.navigate(['/login']);
    setTimeout(function () {
      window.location.reload();
    }, 10);
  }
  routeComp(index: number) {
    if (index === 0) {
      this.router.navigate(['/home']);
    }
  }
  adminProfile() {
    this.showProfileDialog = true;
    this.passwordForm.reset();
    this.accountForm.reset();
    this.accountForm.controls['firstName'].setValue(
      this.userDetail['firstName']
    );
    this.accountForm.controls['lastName'].setValue(this.userDetail['lastName']);
    this.accountForm.controls['email'].setValue(this.userDetail['emailId']);
    this.accountForm.controls['phone'].setValue(this.userDetail['handPhoneNo']);
  }
  updateAccount() {
    if (this.accountForm.valid){
    let payload = {
      firstName: this.accountForm.controls.firstName.value?this.accountForm.controls.firstName.value:'',
      lastName: this.accountForm.controls.lastName.value?this.accountForm.controls.lastName.value:'',
      emailId: this.accountForm.controls.email.value?this.accountForm.controls.email.value:'',
      handPhoneNo: this.accountForm.controls.phone.value?this.accountForm.controls.phone.value:'',
    };
    this.appComponentService.setChangeProfile(payload).subscribe(
      (res) => {
        if (res.status == 200) {
          this.alertSeverity = 'success';
          this.alertMsg = 'Account updated successfully!';
          this.addSingle();
        }
        this.showProfileDialog = false;
        // alert('Account updated successfully');
        this.accountForm.reset();
        // this.ngOnInit();
        this.logoutFunc();
      },
      (error) => {
        this.alertSeverity = 'error';
        if (Number(error.status) == 401) {
          this.alertMsg = 'Please try after some time!';
        } else if (Number(error.status) == 500) {
          this.alertMsg = 'Internal error';
        } else {
          this.alertMsg = 'Please try after some time.';
        }
        this.addSingle();
      },
      () => {}
    );
  }
  }
  updatePassword() {
    if(this.passwordForm.valid){
    this.showError=false;
    if(this.passwordForm.controls.newPassword.value != this.passwordForm.controls.reEnter.value){
      // this.showError=true;
      this.showProfileDialog = true;
      this.passwordForm.controls.reEnter.setValue("");
      this.passwordForm.controls.newPassword.setValue("");
      this.alertSeverity = 'error';
      this.alertMsg = 'Please Enter Correct Password!';
      this.addSingle();
      this.ngOnInit();
    }
    else{
    this.showProfileDialog = false;
    let payload = {
      oldPassword: this.passwordForm.controls.oldPassword.value,
      newPassword: this.passwordForm.controls.newPassword.value,
    };
    this.appComponentService.setChangePassword(payload).subscribe((res) => {
      if (res.status == 200) {
      this.alertSeverity = 'success';
      this.alertMsg = 'Account updated successfully!';
      this.addSingle();
      }
      //this.ngOnInit();
      this.logoutFunc();
    },(error) => {
            this.alertSeverity = 'error';
            if (Number(error.status) == 401) {
              this.alertMsg = 'Please check Password!';
            } 
            else if (Number(error.status) == 412) {
              this.alertMsg = 'Please check Password!';
            }else if (Number(error.status) == 500) {
              this.alertMsg = 'Internal error';
            } else {
              this.alertMsg = 'Please try after some time.';
            }
            this.addSingle();
          });
        }
      }
    // 
  }
  addSingle() {
    this.messageService.add({
      severity: this.alertSeverity,
      summary: '',
      detail: this.alertMsg,
    });
  }
  togglePasswordVisibility(){
    this.isPasswordVisible = !this.isPasswordVisible;
  }
  togglePasswordVisibility1(){
    this.isPasswordVisible1 = !this.isPasswordVisible1;
  }
  togglePasswordVisibility2(){
    this.isPasswordVisible2 = !this.isPasswordVisible2;
  }
  switchHeaders(event: any) {
    this.tabHeaderName = event.originalEvent.target.innerText;
  }
}
