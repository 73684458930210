import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  ElementRef,
  HostListener,
  Input,
  SimpleChanges
} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject, Subscription } from 'rxjs';
import { Message, MessageService, TreeNode } from 'primeng/api';
import { MenuItem } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { DashboardOutput } from './old-dashboard.output';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import { DashboardService } from './old-dashboard.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DashboardChartComponent } from '../dashboard-chart/dashboard-chart.component';
import pptxgen from 'pptxgenjs';
import { Location } from '@angular/common';
import { AppComponentService } from '../app-component.service';
import { takeUntil } from 'rxjs/operators';
import { TourGuideService } from '../tour-guide.service';

declare var d3: any;
declare var acinfotech: any;
let dashListPanelToggleBool: boolean = false;
let isShow = true;
interface DashboardInfo {
  name: string;
  code: string;
}

@Component({
  selector: 'app-old-dashboard',
  templateUrl: './old-dashboard.component.html',
  styleUrls: ['./old-dashboard.component.css'],
  providers: [DashboardService, MessageService, DialogService, DashboardOutput],
})
export class OldDashboardComponent implements OnInit, OnDestroy {
  selectionMode: string = 'single';
  @Input() chartData: any;
  dashSubscrip: Subscription;
  private destroy$ = new Subject<void>();
  dashSelected: any;
  widgetList: any;
  selectedColor: any;
  seriesVal: any;
  legendvalue: any;
  scrollHeight: string = '300px';
  dashboardInfo: DashboardInfo[];
  selectedDashboard: DashboardInfo;
  errorMsg = '';
  shareDashSuccMsg = '';
  searchText = '';
  alertMessage: Message[];
  //items: MenuItem[];
  items: TreeNode[];
  moreItems: MenuItem[];
  wigetcolor: string;
  showClass: boolean = false;
  buttonIndex: any;
  allColorButton: boolean = false;
  listOfDashboards: any = [];
  parametersList: any[];
  lists = [];
  DownloadItems: MenuItem[];
  @ViewChild('chartContainerDiv', { static: true })
  el!: ElementRef<HTMLImageElement>;
  downloadSpinner = 'pi-download';
  hiddenSpinner: boolean;
  pdfDownloadPressed = '';
  parabool: boolean = false;
  paraData: any = [];
  isLoading: boolean = false;
  DashboardContainer: any = false;
  metric: any;
  showBack: boolean = false;
  showEdit: boolean = false;
  showData: boolean = false;
  count: number = 0;
  indexValue: any;
  widSpin: any = '';
  pptxData: any = [];
  coordinate: any = [];
  showDashboardList: boolean = true;
  randomDownloadArray: any = [];
  parameters: any[] = [];
  loaderShow: boolean = false;
  showParameters: boolean = false;
  showTitle: boolean = false;
  payloadParameter: any = [];
  paraValuesOfParameterArray: any = [];
  selectedType: any = 'dashboard';
  hideDashList: boolean = false;
  curentWidgetSubscription: Subscription;
  curentDomainSubscription: Subscription;
  disable: boolean;
  paramDashName: any = '';
  isDownloading: boolean = false;
  isHovered: boolean = false;
  loaderShow1: boolean = false;
  dateRangeAvailable: any;
  showOtherOption: boolean = false;
  startDate: Date;
  endDate: Date;
  selectedFromDate: Date;
  selectedEndDate: Date;
  filteredRecords: TreeNode[];
  showDashboardList1: boolean = true;
  FullScreen: string = "Full Screen";
  SettingsResponse!: any;
  settingsPostSubscrip: Subscription;
  isLoading11: boolean=false;
  searchMetric: any;

  constructor(
    private location: Location,
    private appCompService: AppComponentService,
    public activatedRoute: ActivatedRoute,
    private messageService: MessageService,
    public dialogService: DialogService,
    private http: HttpClient,
    private dashService: DashboardService,
    private router: Router,
    private tourGuideService: TourGuideService
  ) { }

  colorOne = [
    '#FFFF',
    '#CFE2F3',
    '#E1CCB6',
    '#ffd670',
    '#d9d9d9',
    '#ebb9b9',
    '#e1d0e8',
    '#E3F2FD',
    '#B2DFDB',
    '#C8E6C9',
    '#FFF9C4',
  ];
  colorSetOne = [
    '#00A9E9',
    '#BC851C',
    '#004990',
    '#87189D',
    '#C5003E',
    '#28724F',
    '#92C1E9',
    '#CEB888',
    '#678787',
    '#B884CB',
    '#EF6079',
    '#85B09A',
  ];
  colorSetTwo = [
    '#E95044',
    '#F28A4C',
    '#F0D444',
    '#A2DF9C',
    '#93DBE4',
    '#B091D8',
    '#DD1D24',
    '#E35724',
    '#EFE60C',
    '#39A654',
    '#0C9FDC',
    '#812888',
  ]; //third set
  colorSetThree = [
    '#01204F',
    '#006A8D',
    '#03848C',
    '#64A79D',
    '#E9CFA1',
    '#F2A465',
    '#EF6727',
    '#F03113',
    '#E6DCAB',
    '#dbcb7c',
    '#DDBD36',
    '#926A00',
  ]; //yellow
  colorSetFour = [
    '#f7e37a',
    '#fbdd38',
    '#ebc403',
    '#f8c32a',
    '#e2a83d',
    '#f3b064',
    '#f68933',
    '#f5636d',
    '#ff4e5b',
    '#e42f24',
    '#bc2025',
    '#c02024',
  ]; //yellow to red
  colorSetFive = [
    '#012c66',
    '#86bedf',
    '#cfe8f7',
    '#cb2624',
    '#e34746',
    '#f47473',
    '#046365',
    '#13a1a4',
    '#68dddb',
    '#e7c414',
    '#ffe242',
    '#ffe87b',
  ]; //Neon
  colorSetSix = [
    '#6E2C00',
    '#873600',
    '#A04000',
    '#BA4A00',
    '#D35400',
    '#DC7633',
    '#E59866',
    '#EDBB99',
    '#F6DDCC',
    '#FBEEE6',
    '#fff2eb',
    '#fff7f2',
  ]; // 6 cube set brown
  colorSetSeven = [
    '#002E55',
    '#014C7C',
    '#005B7C',
    '#008098',
    '#0E89B5',
    '#0695AA',
    '#35B5C1',
    '#397C82',
    '#87C9CF',
    '#ACCBC6',
    '#C0E0DB',
    '#E4EBEA',
  ]; //Brown color set
  colorSetEight = [
    '#3d818c',
    '#63adb3',
    '#95cdcf',
    '#b9d0d0',
    '#cfe6e6',
    '#e5eeee',
    '#eeebe3',
    '#c3bca1',
    '#c3bca1',
    '#c4ad91',
    '#b89f87',
    '#a08d76',
  ]; //4 cube set

  selected = {
    color: '#3580db',
  };

  dashboardName: string = '';
  totalWidgets = [];
  eachWidgetRecords = [];
  randomIdArray = [];
  parameterizedDashboardRandomArray = [];
  tempArray = [];
  dashboardRecords = [];
  totalRows = [];
  totalColumns = [];
  rowIndex = [];
  finalCallCounter = 0;
  parameterElementsArray = [];
  parameterElementsDataArray = [];
  parameterizedFilterArray = [];
  filterPanelToggleBool: boolean = false;
  requestPayloadParametersArray = [];
  userParameterObject = {};
  requestPayloadParameterObj = {
    filterType: 'and',
    filters: [],
    sort: [],
    parameters: [],
  };
  defaultValuesOfParameterArray = [];
  cascadeParametersObject = {};
  dashboardsListSubscrip: Subscription;
  defaultDashboardSubscrip: Subscription;
  listOfDashboardsSubscrip: Subscription;
  getADashboardSubscrip: Subscription;
  getWidgetDataSubscrip: Subscription;
  getPrmDashItmRecsSubscrip: Subscription;
  getPrmDashCasRecsCalSubscrip: Subscription;
  getPrmDashCasRecsMultSubscrip: Subscription;
  getPrmDashCasRecsDropSubscrip: Subscription;
  getPrmDashCasRecsRadioSubscrip: Subscription;
  getPrmDashWidgetDataSubscrip: Subscription;
  usersNameSubscrip: Subscription;
  shareDashSubscrip: Subscription;
  viewDashboardsList = [];
  dashboardRefreshTime: any;
  dashboardInfoObject: any;
  refreshCountDownTimerStr: string = '';
  refreshInterval;
  countDownLabel: any;
  selectDate: Date;
  shareDashDialBoxDisplayBool: boolean = false;
  displayBasic: boolean = false;
  discontainer: boolean = false;
  menu = false;
  shareDashUserNamesArr = [];
  shareDashSrchUserNamesArr: string[];
  usersArray = [];
  shareDashEmailIdsArr = [];
  shareDashTabIndex = 0; // setting default value as 0 (1st tab) for share dashboard modal window's tab
  selectedChartsColorSet = 'three';
  wigetBackgroundColor: any = '#d9d9d9';
  showOrHideLegendBool = true;
  showOrHideSeriesBool = true;

  value: any;
  ref: DynamicDialogRef;
  backColor: any;
  routingComp!: string;
  storeActivatedRouteDetails: any;
  downloadArray: any = [];
  ngAfterViewInit(): void {
    d3.select('div#tibo-mydash-dashlist-container-id').attr(
      'non-hide',
      'non-hide'
    );
    d3.select('button#mydash-dashlist-toggle-btn')
      .attr('non-hide', 'non-hide')
      .selectAll('button#mydash-dashlist-toggle-btn > span')
      .attr('non-hide', 'non-hide');
    d3.select('div#tibo-mydash-dashlist-container').attr(
      'non-hide',
      'non-hide'
    );
    d3.select('button#mydash-dashlist-toggle-btn2')
      .attr('non-hide', 'non-hide')
      .selectAll('button#mydash-dashlist-toggle-btn2 > span')
      .attr('non-hide', 'non-hide');
  }
  ngOnInit(): void {
    this.hideDashList = false;
    this.disable = false;
    this.selectedType = 'dashboard';
    this.dashboardName = '';
    this.parameterElementsArray = [];
    this.showDashboardList = true;
    this.showDashboardList1 = true;
    this.showBack = false;
    this.showTitle = false;
    this.showEdit = false;
    this.DashboardContainer = false;
    this.legendvalue = 'none';
    this.seriesVal = 'none';
    this.backColor = '#d9d9d9';
    this.buttonIndex = 0;
    this.widgetList = {};
    this.dashSelected = '';
    this.paramDashName = '';
    acinfotech.requestType(true); // Letting chart library know it's a dashboard module call
    // this.countDownLabel = document.getElementById(
    //   'mydash-countdown-timer-label'
    // ) as HTMLElement;
    if (this.chartData != undefined) {
      this.dashSelected = { name: this.chartData?.dashboardname, code: this.chartData?.dashboardcode };
      this.selectedType = this.chartData?.type
      this.showDashboardList = false;
      this.routingComp = this.chartData?.component;
      this.searchMetric=this.chartData?.searchMetric
      if (this.routingComp == 'discovery') { this.showDashboardList1 = false ;this.isLoading11 = true;};
      this.showBack = true;
    }
    this.dashSubscrip = this.activatedRoute.queryParams.subscribe((params) => {
      if (Object.keys(params).length > 0) {
        this.dashSelected = { name: params?.dashboardname, code: params?.dashboardcode };
        this.DashboardContainer = params?.containerbool;
        this.metric = params?.searchMetric
        this.routingComp = params?.component;
        this.storeActivatedRouteDetails = params;
        this.showDashboardList1 = true;
        //if (this.routingComp == 'discovery') { this.showBack = true; this.selectedType = params.type; this.showDashboardList = false;this.paramDashName=params.dashboardname}
        if (this.routingComp == 'dashboard-designer') { this.showEdit = true; this.showDashboardList = false; }
        // if(this.routingComp=='create-dashboard'){this.initialServiceCalls()}
      }
    });

    this.moreItems = [
      {
        label: 'Turn On Page Refresh',
        id: '2',
        command: (e) => this.optionsFunc(e),
        icon: 'pi pi-refresh',
      },
      {
        label: 'Share',
        id: '3',
        command: (e) => this.optionsFunc(e),
        icon: 'pi pi-share-alt',
      },

      {
        label: 'Hide/Show',
        icon: 'pi pi-eye',
        items: [
          {
            label: 'Show Series Number',
            id: '7',
            command: (e) => this.showLegend(e),
            icon: 'pi pi-eye',
          },
          {
            label: 'Show Legend Number',
            id: '8',
            command: (e) => this.showLegend(e),
            icon: 'pi pi-eye',
          },
        ],
      },
    ];

    this.DownloadItems = [
      {
        label: 'Widget View pdf',
        icon: 'pi pi-file-pdf',
        id: '',
        command: () => {
          this.downloadWidgetViewPDF();
        },
      },
      {
        label: 'pptx',
        icon: 'pi pi-file',
        id: '',
        command: () => {
          this.downloadPPTX();
        },
      },
      // {
      //   label: 'Full Screen View PDF',
      //   icon: 'pi pi-file-pdf',
      //   id: '',
      //   command: () => {
      //     this.downloadFullScreenWidget();
      //   },
      // },
      {
        label: 'png Image',
        icon: 'pi pi-image',
        id: '',
        command: () => {
          this.downloadPNG();
        },
      },
      {
        label: 'jpg Image',
        icon: 'pi pi-image',
        id: '',
        command: () => {
          this.downloadJPEG();
        },
      },
    ];
    d3.select('html').on('click', function () {
      if (!d3.event.target.hasAttribute('non-hide')) {
        d3.select('#tibo-mydash-dashlist-container-id').style(
          'display',
          'none'
        );
      }
    });
    d3.select('html').on('click', function () {
      if (!d3.event.target.hasAttribute('non-hide')) {
        d3.select('#tibo-mydash-dashlist-container').style('display', 'none');
      }
    });
    d3.selectAll(".series_value").style("display", "none");
    d3.selectAll(".legend_value").style("display", "none");
    d3.select('.tibo-topbar-title').text('Home / My Dashboard'); // setting title
    // clearInterval(this.refreshInterval);
    // document.getElementById(
    //   'mydash-countdown-timer-label'
    // ).innerHTML = '';

    this.initialServiceCalls();
  } // ngOnInit
  // showPdfOptionsSubMenu(){
  //   const pdfOptionsSubMenu = [
  //     {
  //       label: 'High Quality',
  //       icon: 'pi pi-arrow-up',
  //       id: '',
  //       command: () => {
  //         this.downloadWidgetViewPDF('highQuality');
  //       },
  //     },
  //     {
  //       label: 'Low Quality',
  //       icon: 'pi pi-arrow-down',
  //       id: '',
  //       command: () => {
  //         this.downloadWidgetViewPDF('lowQuality');
  //       },
  //     },
  //   ];
  //   // Show the sub-menu
  //  this.showSubMenu(pdfOptionsSubMenu);
  // }
  // showSubMenu(subMenuItems: any[]) {
  //   this.downloadOptions.overlayVisible = true;
  // }
  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    if (this.dashboardsListSubscrip) {
      this.dashboardsListSubscrip.unsubscribe();
    }
    if (this.defaultDashboardSubscrip) {
      this.defaultDashboardSubscrip.unsubscribe();
    }
    if (this.curentWidgetSubscription) {
      this.curentWidgetSubscription.unsubscribe();
    }
    if (this.curentDomainSubscription) {
      this.curentDomainSubscription.unsubscribe();
    }
    if (this.listOfDashboardsSubscrip) {
      this.listOfDashboardsSubscrip.unsubscribe();
    }
    if (this.getADashboardSubscrip) {
      this.getADashboardSubscrip.unsubscribe();
    }
    if (this.getWidgetDataSubscrip) {
      this.getWidgetDataSubscrip.unsubscribe();
    }
    if (this.getPrmDashItmRecsSubscrip) {
      this.getPrmDashItmRecsSubscrip.unsubscribe();
    }
    if (this.getPrmDashCasRecsCalSubscrip) {
      this.getPrmDashCasRecsCalSubscrip.unsubscribe();
    }
    if (this.getPrmDashCasRecsMultSubscrip) {
      this.getPrmDashCasRecsMultSubscrip.unsubscribe();
    }
    if (this.getPrmDashCasRecsDropSubscrip) {
      this.getPrmDashCasRecsDropSubscrip.unsubscribe();
    }
    if (this.getPrmDashCasRecsRadioSubscrip) {
      this.getPrmDashCasRecsRadioSubscrip.unsubscribe();
    }
    if (this.getPrmDashWidgetDataSubscrip) {
      this.getPrmDashWidgetDataSubscrip.unsubscribe();
    }
    if (this.usersNameSubscrip) {
      this.usersNameSubscrip.unsubscribe();
    }
    if (this.shareDashSubscrip) {
      this.shareDashSubscrip.unsubscribe();
    }
    if (this.ref) {
      this.ref.close();
    }
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes.chartData) {
      //console.log('Chart data updated:', this.chartData);
    }
    this.isLoading = true;
    this.dashSelected = { name: this.chartData?.dashboardname, code: this.chartData?.dashboardcode };
    this.selectedType = this.chartData?.type
    // this.showDashboardList = false;
    this.initialServiceCalls()
  }
  initialServiceCalls() {
    // clearInterval(this.refreshInterval);
    //   window.addEventListener('load', function() {
    //     document.getElementById('mydash-countdown-timer-label').innerHTML = '';
    // });
    this.isLoading = true;
    // this.isLoading11=false;
    this.showDashboardList = true;
    this.dashService.dashboardsList().subscribe(
      (dashboardsListResp: any) => {
        console.log(dashboardsListResp)
        if(dashboardsListResp.length==0){
          this.isLoading = false;
          this.errorMsg = 'No Dashboards Assigned';
          this.isLoading11=false;
          this.addSingle();
        }
        else{
        //console.log(dashboardsListResp)
        this.listOfDashboards = dashboardsListResp;
        this.listOfDashboards= dashboardsListResp.filter(item => item.dashboardVisibility == "Y" || item.dashboardVisibility == null)
        console.log(this.listOfDashboards)
        this.dashService.dashboardsListService().subscribe(
          (resp: any) => {
            console.log(resp)
            if(resp.length==0){
              this.errorMsg = 'No Widgets Assigned';
              this.addSingle();
              this.isLoading11=false;
            }
            else{
            this.widgetList = resp;

            let dashTemp = {},
              dashItemsTemp = {};
            resp.forEach((element: any, index: any) => {
              if (element.domainDashboardVisibility == "Y" || element.domainDashboardVisibility == null) {
                let dashTemp: TreeNode = {
                  label: element.domainName,
                  children: []
                };
                this.tempArray.push({
                  name: element.widgetName,
                  code: element.widgetMasterId,
                });
                // dashTemp['label'] = element.domainName;
                dashTemp['items'] = [];
                dashTemp['children'] = [];
                let dashboards = element.dashboards.filter(item => item.dashboardVisibility == "Y" || item.dashboardVisibility == null);
                dashboards.sort((a: any, b: any) => (b.favourite === 'Y' ? 1 : 0) - (a.favourite === 'Y' ? 1 : 0));
                dashboards.forEach((dashboard: any) => {
                  // dashItemsTemp['label'] = dashboard.widgetName;
                  dashItemsTemp['children'] = { label: dashboard.widgetName, children: [] }
                  dashItemsTemp['id'] = dashboard.widgetMasterId;
                  dashItemsTemp['favourite'] = dashboard.favourite
                  //dashItemsTemp['command'] = (e) => this.changeDashboardFunc(e);
                  dashTemp['items'].push(dashItemsTemp);
                  dashTemp['children'].push(dashItemsTemp);
                  dashItemsTemp = {};
                });

                this.dashboardRecords.push(dashTemp);
                dashTemp = {};
              }
            });
            this.items = this.dashboardRecords;
            this.dashboardInfo = this.tempArray;

            // Additional dashboard domains filtering ---------------------
            let filteredDomains = [];
            this.items.forEach((domain: any) => {
              if (domain.items.length > 0) {
                filteredDomains.push(domain);
              }
            });

            this.items = filteredDomains;
            this.filteredRecords = this.items;
            console.log(this.items);
            if (this.dashSelected.code == '' || this.dashSelected.code == undefined) {
              this.dashService
                .getDefaultDashboard()
                .subscribe(
                  (res: any) => {
                    // get defaul dashboard
                    this.SettingsResponse = res;
                    console.log(this.SettingsResponse)
                    this.dashboardRefreshTime = res.dashboardRefreshTime;
                    this.selectedChartsColorSet = res.defaultWidgetColor?.toLowerCase() ? res.defaultWidgetColor?.toLowerCase() : 'three';
                    this.wigetBackgroundColor = res.defaultBackgroundColor?res.defaultBackgroundColor:'#d9d9d9';
                    const seriesValue = res.seriesValue === "Y";
                    const legendValue = res.legendValue === "Y";

                    const hideShowMenu = this.moreItems.find(item => item.label === 'Hide/Show');
                    if (hideShowMenu && hideShowMenu.items) {
                      const seriesItem = hideShowMenu.items.find(subItem => subItem.id === '7');
                      const legendItem = hideShowMenu.items.find(subItem => subItem.id === '8');

                      if (seriesItem) {
                        seriesItem.label = seriesValue ? 'Hide Series Values' : 'Show Series Values';
                        seriesItem.icon = seriesValue ? 'pi pi-eye-slash' : 'pi pi-eye';
                      }

                      if (legendItem) {
                        legendItem.label = legendValue ? 'Hide Legend Values' : 'Show Legend Values';
                        legendItem.icon = legendValue ? 'pi pi-eye-slash' : 'pi pi-eye';
                      }
                    }
                    this.seriesVal = res.seriesValue == "Y" ? 'block' : 'none';
                    this.legendvalue = res.legendValue == "Y" ? 'block' : 'none';
                    this.showOrHideSeriesBool = res.seriesValue == "Y" ? false : true;
                    this.showOrHideLegendBool = res.legendValue == "Y" ? false : true;
                    this.selectedColor = this.wigetBackgroundColor;
                    if (
                      res.hasOwnProperty('defaultDashboard') &&
                      res.defaultDashboard.toString() != 'null'
                    ) {
                      this.selectedDashboard = {
                        name: '',
                        code: res.defaultDashboard,
                      };
                      if (res.hasOwnProperty('dashboardRefreshOnOROff')) {
                        if (
                          res.dashboardRefreshOnOROff.toString().toLowerCase() ==
                          'y'
                        ) {
                          // checking whether dashboard auto refresh is turned on in settings page
                          this.moreItems[0].label = 'Turn off page refresh';
                          clearInterval(this.refreshInterval);
                          document.getElementById(
                            'mydash-countdown-timer-label'
                          ).innerHTML = '';
                          this.refreshTimerFunc();
                        }
                        else {
                          this.moreItems[0].label = 'Turn On Page Refresh';
                        }
                      }
                      this.getDashboard('');
                    }

                    else if (!res.defaultDashboard || res.defaultDashboard == 'null' || res.defaultDashboard == null) {
                      // if there is no default dashboard set

                      this.selectedDashboard = {
                        name: '',
                        code: this.listOfDashboards[0]?.widgetMasterId,
                      };
                      if (res.hasOwnProperty('dashboardRefreshOnOROff')) {
                        if (
                          res.dashboardRefreshOnOROff.toString().toLowerCase() ==
                          'y'
                        ) {
                          // checking whether dashboard auto refresh is turned on in settings page
                          this.moreItems[0].label = 'Turn off page refresh';
                          clearInterval(this.refreshInterval);
                          document.getElementById(
                            'mydash-countdown-timer-label'
                          ).innerHTML = '';
                          this.refreshTimerFunc();
                        }
                        else {
                          this.moreItems[0].label = 'Turn On Page Refresh';
                        }
                      }
                     this.getDashboard('');
                    }

                  },
                  (error) => {
                    d3.select('.tibo-mydash-dash-spinner').style('display', 'none'); // hidding dashboard spinner
                    this.isLoading = false
                    if (Number(error.status) == 401) {
                      this.errorMsg = 'Please Try After Some Time!';
                    } else if (Number(error.status) == 500) {
                      this.errorMsg = 'Internal Error';
                    } else {
                      this.errorMsg = 'Please Try After Some Time.';
                    }

                    this.addSingle();
                  },
                  () => { }
                ); // get default dashboard service
            }
            else {
              this.selectedDashboard = {
                name: this.dashSelected.name,
                code: this.dashSelected.code,
              };
              this.defaultDashboardSubscrip = this.dashService
                .getDefaultDashboard()
                .subscribe(
                  (response: any) => {
                    // get defaul dashboard
                    this.SettingsResponse = response;
                    this.dashboardRefreshTime = response.dashboardRefreshTime;
                    this.selectedChartsColorSet = response.defaultWidgetColor?.toLowerCase() ? response.defaultWidgetColor?.toLowerCase() : 'three';
                    this.wigetBackgroundColor = response.defaultBackgroundColor?response.defaultBackgroundColor:'#d9d9d9';
                    this.selectedColor = this.wigetBackgroundColor;
                    const seriesValue = response.seriesValue === "Y";
                    const legendValue = response.legendValue === "Y";

                    const hideShowMenu = this.moreItems.find(item => item.label === 'Hide/Show');
                    if (hideShowMenu && hideShowMenu.items) {
                      const seriesItem = hideShowMenu.items.find(subItem => subItem.id === '7');
                      const legendItem = hideShowMenu.items.find(subItem => subItem.id === '8');

                      if (seriesItem) {
                        seriesItem.label = seriesValue ? 'Hide Series Values' : 'Show Series Values';
                        seriesItem.icon = seriesValue ? 'pi pi-eye-slash' : 'pi pi-eye';
                      }

                      if (legendItem) {
                        legendItem.label = legendValue ? 'Hide Legend Values' : 'Show Legend Values';
                        legendItem.icon = legendValue ? 'pi pi-eye-slash' : 'pi pi-eye';
                      }
                    }
                    this.seriesVal = response.seriesValue == "Y" ? 'block' : 'none';
                    this.legendvalue = response.legendValue == "Y" ? 'block' : 'none';
                    this.showOrHideSeriesBool = response.seriesValue == "Y" ? false : true;
                    this.showOrHideLegendBool = response.legendValue == "Y" ? false : true;
                    if (response.hasOwnProperty('dashboardRefreshOnOROff')) {
                      if (
                        response.dashboardRefreshOnOROff.toString().toLowerCase() ==
                        'y'
                      ) {
                        // checking whether dashboard auto refresh is turned on in settings page
                        this.moreItems[0].label = 'Turn off page refresh';
                        clearInterval(this.refreshInterval);
                        document.getElementById(
                          'mydash-countdown-timer-label'
                        ).innerHTML = '';
                        this.refreshTimerFunc();
                      }
                      else {
                        this.moreItems[0].label = 'Turn On Page Refresh';
                      }
                    }
                  })
              this.getDashboard('');
            }
          }
            this.isLoading = false;
          
          },
          (error) => {
            d3.select('.tibo-mydash-dash-spinner').style('display', 'none'); // hidding dashboard spinner
            this.isLoading = false
            if (Number(error.status) == 401) {
              this.errorMsg = 'Please Try After Some Time!';
            } else if (Number(error.status) == 500) {
              this.errorMsg = 'Internal Error';
            } else {
              this.errorMsg = 'Please Try After Some Time.';
            }
            this.isLoading11=false;
            this.addSingle();
          },
          () => { }
        );
        // this.isLoading = true;
      }
      }, // dashboardService
      (error) => {
        d3.select('.tibo-mydash-dash-spinner').style(
          'display',
          'none'
        ); // hidding dashboard spinner
        this.isLoading = false
        if (Number(error.status) == 401) {
          this.errorMsg = 'Please Try After Some Time!';
        } else if (Number(error.status) == 500) {
          this.errorMsg = 'Internal Error';
        } else {
          this.errorMsg = 'Please Try After Some Time.';
        }
        this.isLoading11=false;
        this.addSingle();
      },
      () => { }
    );

  }

  datasData: any;

  getDashboard(event: any) {
    this.isLoading11=false;
    this.loaderShow = false;
    this.showParameters = false;
    this.dashboardName = '';
    //d3.select("#chartContent").style("display", "none")  
    //this.parameterElementsArray['fetchedRecordsBool']=false;
    // d3.selectAll('.widget-class').style('background-color',this.backColor)
    this.showData = false;
    this.hiddenSpinner = false;
    acinfotech.requestType(true); // Letting chart library know it's a dashboard module call and empty all stored divs id and chart div elements
    d3.select(document).selectAll('.d3-tip').remove(); // hiding d3-tip tool tip on dashboard change
    d3.select(".tibo-mydash-widget-spinner").style("display", "block") //  showing dashboard spinner
    d3.selectAll(".series_value").style("display", "none");
    d3.selectAll(".legend_value").style("display", "none");
    if (this.moreItems[0].label == 'Turn off page refresh') {
      clearInterval(this.refreshInterval);
      document.getElementById(
        'mydash-countdown-timer-label'
      ).innerHTML = '';
      this.refreshTimerFunc();
    }
    // if( this.moreItems[0].label = 'Turn On Page Refresh'){
    //   this.dashboardRefreshTime=3;
    //   this.refreshTimerFunc();
    // }
    this.isLoading = true;
    this.dateRangeAvailable = {};
    let metric='';
    if(this.selectedType.toLowerCase()=='widget'){
      metric=this.searchMetric.includes('leave')?'leave':this.searchMetric.includes('claims')?'claims':this.searchMetric.includes('disability')?'disability':'';
    }
    this.getADashboardSubscrip = this.dashService
      .getADashboard(metric,this.selectedDashboard.code, this.selectedType.toLowerCase())
      .subscribe(
        (data: any) => {
          // this.disable=true;
          //this.isLoading=false;
        
          this.selectedDashboard.name = data.widgetName;
          this.dateRangeAvailable = data.dashboardDateRange;
          // a dashboard info
          this.viewDashboardsList = this.viewDashboardsList.filter(
            (obje) => Number(obje.code) !== Number(this.selectedDashboard.code)
          );


          this.viewDashboardsList.push(this.selectedDashboard); // storing opened dashboards
          this.totalRows = Array(Number(data.row)).fill(0);
          this.totalColumns = Array(Number(data.column)).fill(0);
          if (this.paramDashName == '') {
            this.dashboardName = data.widgetName;
          }
          else {
            this.dashboardName = this.paramDashName;
          }
          setTimeout(() => {
            this.tourGuideService.startTour('dashboard');
          }, 1000)
          // if(this.selectedType.toLowerCase()=='widget'){
          //   this.totalWidgets = data.widgetControls.filter(item=>item.chartTitle ==this.selectedDashboard.name );
          // }
          // else{
            this.totalWidgets = data.widgetControls;
          // }
          
          (this.randomIdArray = []),
            this.randomDownloadArray = [],
            (this.parameterizedDashboardRandomArray = []),
            (this.rowIndex = []),
            (this.finalCallCounter = 0);
          (this.parameterizedFilterArray = []),
            (this.parameterElementsDataArray = []);
          // if (!this.parabool) {
          this.parameterElementsArray = [];
          this.requestPayloadParametersArray = [];
          this.downloadArray = [];
          this.coordinate = [];
          // }

          this.requestPayloadParameterObj = {
            filterType: 'and',
            filters: [],
            sort: [],
            parameters: [],
          };
          this.defaultValuesOfParameterArray = [];
          //d3.select("#chartContent").style("display", "block")  
          // d3.select(".tibo-mydash-widget-spinner").style("display", "block")
          if (data.dashboardRequestorParameters == null) {
            this.isLoading = false;

          }
          else if (data.dashboardRequestorParameters?.length == 0) {
            this.disable = true;
            this.isLoading = false;
            d3.select('#dashboard-scroll-wrapper').style('display', 'block');
            d3.select('#dashboard-scroll-wrapper1').style('display', 'block');
            d3.select('#dashboard-parameterized-scroll-wrapper').style(
              'display',
              'none'
            );
            var id;
            this.totalRows.forEach((rowEle, rowIndex) => {
              this.totalColumns.forEach((colEle, columnIndex) => {
                var id = '';
                id = 'chartid-' + Math.floor(Math.random() * 10000) + 1;
                this.rowIndex.push(id);
                this.coordinate.push({
                  'coord': String(rowIndex + 1) + ',' + String(columnIndex + 1),
                  'chartId': id
                })
              });
              this.randomIdArray.push(this.rowIndex);
              this.rowIndex = [];
            });


            setTimeout(() => {
              // removing unused chart divs and adjusting their width

              var totalRows = this.totalRows;
              var totalColumns = this.totalColumns;
              var totalObjCoords = [];
              var usedObjCoords = [];

              totalRows.map(function (row, rowInd) {
                totalColumns.map(function (column, columnInd) {
                  totalObjCoords.push(rowInd + 1 + ',' + (columnInd + 1));
                });
              });

              totalObjCoords.map(function (coord) {
                data.widgetControls.map(function (d) {
                  if (
                    Number(d.controlMasterId) == 7 ||
                    Number(d.controlMasterId) == 22 ||
                    Number(d.controlMasterId) == 23 ||
                    Number(d.controlMasterId) == 25 ||
                    Number(d.controlMasterId) == 26 ||
                    Number(d.controlMasterId) == 27
                  ) {
                  } else {
                    if (String(coord) == String(d.objectCordinates)) {
                      usedObjCoords.push(coord);
                    }
                  }
                });
              });
              //d3.attr("class","widget-class").style({"position": "relative", "float": "left", "margin": "0.5%","min-width":"350px","height": "350px", "background-color":this.wigetBackgroundColor, "border":" 1px solid #f0f0f0"," box-sizing": "border-box","visibility": "hidden"})
              var eachWidget = d3.selectAll('.widget-class')[0];
              for (var widi = 0; widi < totalObjCoords.length; widi++) {
                if (
                  usedObjCoords.indexOf(
                    d3.select(eachWidget[widi]).attr('objectCordinates')
                  ) == -1
                ) {
                  d3.select(eachWidget[widi]).remove();
                }
              }
              this.coordinate.forEach((w) => {
                usedObjCoords.forEach((u) => {
                  if (String(w['coord']) == String(u)) {
                    this.downloadArray.push(w)
                  }
                })
              })
              d3.selectAll('.tibo-mydash-nonparamdash-widget')[0].forEach(
                function (row) {
                  if (
                    d3.select(row).selectAll('div.widget-class')[0].length == 1
                  ) {
                    d3.select(row)
                      .selectAll('div.widget-class')
                      .attr('class', 'widget-class expand-widget-class-1');
                  } else if (
                    d3.select(row).selectAll('div.widget-class')[0].length == 2
                  ) {
                    d3.select(row)
                      .selectAll('div.widget-class')
                      .attr('class', 'widget-class expand-widget-class-2');
                  } else if (
                    d3.select(row).selectAll('div.widget-class')[0].length == 3
                  ) {
                    d3.select(row)
                      .selectAll('div.widget-class')
                      .attr('class', 'widget-class expand-widget-class-3');
                  }
                }
              );
            }, 1000); // removing unused chart divs and adjusting their width

            data.widgetControls.filter((widget, index) => {
              // this.disable=true;
              this.widSpin = widget.objectCordinates.replace(',', '')
              d3.select('#loader' + this.widSpin).style('display', 'block');
              //widget.objectCordinates
              this.indexValue = index;

              if (widget.controlMasterId == 3) {
                this.getWidgetDataSubscrip = this.dashService
                  .getWidgetData(
                    widget.widgetControlId,
                    'limit=&isWebservice=N'
                  )
                  .pipe(takeUntil(this.destroy$))
                  .subscribe(
                    (widgetdetails: any) => {
                      data.widgetControls[index]['eachWidgetResponse'] =
                        widgetdetails; // Storing each widget information for changing chart colors usage

                      var chartElement = d3
                        .select(
                          ".widget-class[objectCordinates='" +
                          widget.objectCordinates +
                          "']"
                        )
                        .attr('empty', false);
                      this.table(chartElement, widget, widgetdetails);
                      d3.select('#loader' + widget.objectCordinates.replace(',', '')).style('display', 'none');
                    },
                    (error) => console.log(error),
                    () => this.finalCall()
                  ); // getWidgetData
              } else {
                this.getWidgetDataSubscrip = this.dashService
                  .getWidgetData(widget.widgetControlId, 'limit=10')
                  .subscribe(
                    (widgetdetails: any) => {
                      data.widgetControls[index]['eachWidgetResponse'] =
                        widgetdetails; // Storing each widget information for changing chart colors usage

                      //-------------------------------------------------------------------------------------------------

                      if (widget.controlMasterId == 1) {
                        var chartElement = d3
                          .select(
                            ".widget-class[objectCordinates='" +
                            widget.objectCordinates +
                            "']"
                          )
                          .attr('empty', false);
                        this.pieChart(chartElement, widget, widgetdetails);
                        d3.select('#loader' + widget.objectCordinates.replace(',', '')).style('display', 'none');

                      } else if (widget.controlMasterId == 2) {
                        var chartElement = d3
                          .select(
                            ".widget-class[objectCordinates='" +
                            widget.objectCordinates +
                            "']"
                          )
                          .attr('empty', false);
                        this.columnChart(chartElement, widget, widgetdetails);
                        d3.select('#loader' + widget.objectCordinates.replace(',', '')).style('display', 'none');

                      } else if (widget.controlMasterId == 5) {
                        /*else if(widget.controlMasterId == 3){
                    var chartElement = d3.select(".widget-class[objectCordinates='" + widget.objectCordinates + "']")
                    .attr("empty", false)
                    
                    this.table(chartElement,widget,widgetdetails)
                  }*/
                        var chartElement = d3
                          .select(
                            ".widget-class[objectCordinates='" +
                            widget.objectCordinates +
                            "']"
                          )
                          .attr('empty', false);
                        this.donutChart(chartElement, widget, widgetdetails);
                        d3.select('#loader' + widget.objectCordinates.replace(',', '')).style('display', 'none');

                      } else if (widget.controlMasterId == 6) {
                        var chartElement = d3
                          .select(
                            ".widget-class[objectCordinates='" +
                            widget.objectCordinates +
                            "']"
                          )
                          .attr('empty', false);
                        this.horizontalChart(chartElement, widget, widgetdetails);
                        d3.select('#loader' + widget.objectCordinates.replace(',', '')).style('display', 'none');

                      } else if (widget.controlMasterId == 7) {
                      } // Waffle chart
                      else if (widget.controlMasterId == 8) {
                        var chartElement = d3
                          .select(
                            ".widget-class[objectCordinates='" +
                            widget.objectCordinates +
                            "']"
                          )
                          .attr('empty', false);
                        this.perGauge(chartElement, widget, widgetdetails);
                        d3.select('#loader' + widget.objectCordinates.replace(',', '')).style('display', 'none');

                      } else if (widget.controlMasterId == 9) {
                        var chartElement = d3
                          .select(
                            ".widget-class[objectCordinates='" +
                            widget.objectCordinates +
                            "']"
                          )
                          .attr('empty', false);
                        this.numberGauge(chartElement, widget, widgetdetails);
                        d3.select('#loader' + widget.objectCordinates.replace(',', '')).style('display', 'none');

                      } else if (widget.controlMasterId == 10) {
                        var chartElement = d3
                          .select(
                            ".widget-class[objectCordinates='" +
                            widget.objectCordinates +
                            "']"
                          )
                          .attr('empty', false);
                        this.map(chartElement, widget, widgetdetails);
                        d3.select('#loader' + widget.objectCordinates.replace(',', '')).style('display', 'none');

                      } else if (widget.controlMasterId == 11) {
                        var chartElement = d3
                          .select(
                            ".widget-class[objectCordinates='" +
                            widget.objectCordinates +
                            "']"
                          )
                          .attr('empty', false);
                        this.columnAndLineChart(chartElement, widget, widgetdetails);
                        d3.select('#loader' + widget.objectCordinates.replace(',', '')).style('display', 'none');

                      } else if (widget.controlMasterId == 12) {
                        var chartElement = d3
                          .select(
                            ".widget-class[objectCordinates='" +
                            widget.objectCordinates +
                            "']"
                          )
                          .attr('empty', false);
                        this.lineChart(chartElement, widget, widgetdetails);
                        d3.select('#loader' + widget.objectCordinates.replace(',', '')).style('display', 'none');

                      } else if (widget.controlMasterId == 13) {
                        var chartElement = d3
                          .select(
                            ".widget-class[objectCordinates='" +
                            widget.objectCordinates +
                            "']"
                          )
                          .attr('empty', false);
                        this.bubbleChart(chartElement, widget, widgetdetails);
                        d3.select('#loader' + widget.objectCordinates.replace(',', '')).style('display', 'none');

                      } else if (widget.controlMasterId == 14) {
                        var chartElement = d3
                          .select(
                            ".widget-class[objectCordinates='" +
                            widget.objectCordinates +
                            "']"
                          )
                          .attr('empty', false);
                        this.stackedHorizontalBarChart(
                          chartElement,
                          widget,
                          widgetdetails
                        );
                        d3.select('#loader' + widget.objectCordinates.replace(',', '')).style('display', 'none');

                      } else if (widget.controlMasterId == 15) {
                        var chartElement = d3
                          .select(
                            ".widget-class[objectCordinates='" +
                            widget.objectCordinates +
                            "']"
                          )
                          .attr('empty', false);
                        this.stackedBarChart(chartElement, widget, widgetdetails);
                        d3.select('#loader' + widget.objectCordinates.replace(',', '')).style('display', 'none');

                      } else if (widget.controlMasterId == 16) {
                        var chartElement = d3
                          .select(
                            ".widget-class[objectCordinates='" +
                            widget.objectCordinates +
                            "']"
                          )
                          .attr('empty', false);
                        this.perGaugeWithFlip(chartElement, widget, widgetdetails);
                        d3.select('#loader' + widget.objectCordinates.replace(',', '')).style('display', 'none');

                      } else if (widget.controlMasterId == 17) {
                        var chartElement = d3
                          .select(
                            ".widget-class[objectCordinates='" +
                            widget.objectCordinates +
                            "']"
                          )
                          .attr('empty', false);
                        this.squareGauge(chartElement, widget, widgetdetails);
                        d3.select('#loader' + widget.objectCordinates.replace(',', '')).style('display', 'none');

                      } else if (widget.controlMasterId == 18) {
                        var chartElement = d3
                          .select(
                            ".widget-class[objectCordinates='" +
                            widget.objectCordinates +
                            "']"
                          )
                          .attr('empty', false);
                        this.bellCurveChart(chartElement, widget, widgetdetails);
                        d3.select('#loader' + widget.objectCordinates.replace(',', '')).style('display', 'none');

                      } else if (widget.controlMasterId == 19) {
                        var chartElement = d3
                          .select(
                            ".widget-class[objectCordinates='" +
                            widget.objectCordinates +
                            "']"
                          )
                          .attr('empty', false);
                        this.areaChart(chartElement, widget, widgetdetails);
                        d3.select('#loader' + widget.objectCordinates.replace(',', '')).style('display', 'none');

                      } else if (widget.controlMasterId == 20) {
                        var chartElement = d3
                          .select(
                            ".widget-class[objectCordinates='" +
                            widget.objectCordinates +
                            "']"
                          )
                          .attr('empty', false);
                        this.groupedBarChart(chartElement, widget, widgetdetails);
                        d3.select('#loader' + widget.objectCordinates.replace(',', '')).style('display', 'none');

                      } else if (widget.controlMasterId == 21) {
                        var chartElement = d3
                          .select(
                            ".widget-class[objectCordinates='" +
                            widget.objectCordinates +
                            "']"
                          )
                          .attr('empty', false);
                        this.horizontalGroupedBarChart(
                          chartElement,
                          widget,
                          widgetdetails
                        );
                        d3.select('#loader' + widget.objectCordinates.replace(',', '')).style('display', 'none');

                      } else if (widget.controlMasterId == 22) {
                      } //Horizontal opposite bar Chart
                      else if (widget.controlMasterId == 23) {
                      } //Vertical opposite bar Chart
                      else if (widget.controlMasterId == 24) {
                        var chartElement = d3
                          .select(
                            ".widget-class[objectCordinates='" +
                            widget.objectCordinates +
                            "']"
                          )
                          .attr('empty', false);
                        this.dotGauge(chartElement, widget, widgetdetails);
                        d3.select('#loader' + widget.objectCordinates.replace(',', '')).style('display', 'none');

                      } else if (widget.controlMasterId == 25) {
                      } //Square bubble chart
                      else if (widget.controlMasterId == 26) {
                      } //Magic quadrant
                      else if (widget.controlMasterId == 27) {
                      } //Funnel chart
                      else if (widget.controlMasterId == 28) {
                        var chartElement = d3
                          .select(
                            ".widget-class[objectCordinates='" +
                            widget.objectCordinates +
                            "']"
                          )
                          .attr('empty', false);
                        this.columnAndLinesChart(chartElement, widget, widgetdetails);
                        d3.select('#loader' + widget.objectCordinates.replace(',', '')).style('display', 'none');

                      } else if (widget.controlMasterId == 29) {
                        var chartElement = d3
                          .select(
                            ".widget-class[objectCordinates='" +
                            widget.objectCordinates +
                            "']"
                          )
                          .attr('empty', false);
                        this.threeXAxisesGroupedBarChart(
                          chartElement,
                          widget,
                          widgetdetails
                        );
                        d3.select('#loader' + widget.objectCordinates.replace(',', '')).style('display', 'none');

                      } else if (widget.controlMasterId == 30) {
                        var chartElement = d3
                          .select(
                            ".widget-class[objectCordinates='" +
                            widget.objectCordinates +
                            "']"
                          )
                          .attr('empty', false);
                        this.twoXAxisesGroupedBarChart(
                          chartElement,
                          widget,
                          widgetdetails
                        );
                        d3.select('#loader' + widget.objectCordinates.replace(',', '')).style('display', 'none');

                      } else if (widget.controlMasterId == 31) {
                        var chartElement = d3
                          .select(
                            ".widget-class[objectCordinates='" +
                            widget.objectCordinates +
                            "']"
                          )
                          .attr('empty', false);
                        this.bellCurveChartWithMinAndMaxRange(
                          chartElement,
                          widget,
                          widgetdetails
                        );
                        d3.select('#loader' + widget.objectCordinates.replace(',', '')).style('display', 'none');

                      } else if (widget.controlMasterId == 32) {
                        var chartElement = d3
                          .select(
                            ".widget-class[objectCordinates='" +
                            widget.objectCordinates +
                            "']"
                          )
                          .attr('empty', false);
                        this.stackedGroupedBarChart(
                          chartElement,
                          widget,
                          widgetdetails
                        );
                        d3.select('#loader' + widget.objectCordinates.replace(',', '')).style('display', 'none');

                      } else if (widget.controlMasterId == 33) {
                        var chartElement = d3
                          .select(
                            ".widget-class[objectCordinates='" +
                            widget.objectCordinates +
                            "']"
                          )
                          .attr('empty', false);
                        this.stackedGroupedBarsWithLineChart(
                          chartElement,
                          widget,
                          widgetdetails
                        );
                        d3.select('#loader' + widget.objectCordinates.replace(',', '')).style('display', 'none');

                      } else if (widget.controlMasterId == 34) {
                        var chartElement = d3
                          .select(
                            ".widget-class[objectCordinates='" +
                            widget.objectCordinates +
                            "']"
                          )
                          .attr('empty', false);
                        this.stackedBarWithLinesChart(
                          chartElement,
                          widget,
                          widgetdetails
                        );
                        d3.select('#loader' + widget.objectCordinates.replace(',', '')).style('display', 'none');

                      } else if (widget.controlMasterId == 35) {
                        var chartElement = d3
                          .select(
                            ".widget-class[objectCordinates='" +
                            widget.objectCordinates +
                            "']"
                          )
                          .attr('empty', false);
                        this.trafficLightsChart(chartElement, widget, widgetdetails);
                        d3.select('#loader' + widget.objectCordinates.replace(',', '')).style('display', 'none');

                      } else if (widget.controlMasterId == 36) {
                        var chartElement = d3
                          .select(
                            ".widget-class[objectCordinates='" +
                            widget.objectCordinates +
                            "']"
                          )
                          .attr('empty', false);
                        this.columnChartWithNegativeValues(
                          chartElement,
                          widget,
                          widgetdetails
                        );
                        d3.select('#loader' + widget.objectCordinates.replace(',', '')).style('display', 'none');

                      } else if (widget.controlMasterId == 37) {
                        var chartElement = d3
                          .select(
                            ".widget-class[objectCordinates='" +
                            widget.objectCordinates +
                            "']"
                          )
                          .attr('empty', false);
                        this.groupedBarsWithLineChart(
                          chartElement,
                          widget,
                          widgetdetails
                        );
                        d3.select('#loader' + widget.objectCordinates.replace(',', '')).style('display', 'none');

                      } else if (widget.controlMasterId == 38) {
                        var chartElement = d3
                          .select(
                            ".widget-class[objectCordinates='" +
                            widget.objectCordinates +
                            "']"
                          )
                          .attr('empty', false);
                        this.USMapWithPins(chartElement, widget, widgetdetails);
                        d3.select('#loader' + widget.objectCordinates.replace(',', '')).style('display', 'none');

                      }
                      this.hiddenSpinner = false;
                      // this.finalCall()
                      //-------------------------------------------------------------------------------------------------
                    },
                    (error) => console.log(error),
                    () => this.finalCall()
                  ); // getWidgetData
              } // else
            }); // forEach

            this.dashboardInfoObject = data;
          } // data.body.dashboardRequestorParameters.length if block
          else {

            this.loaderShow = true;
            this.payloadParameter = []
            d3.select('.tibo-mydash-widget-spinner').style('display', 'none'); // hidding dashboard spinner
            d3.select('#dashboard-parameterized-scroll-wrapper').style(
              'display',
              'block'
            );
            d3.select('#dashboard-scroll-wrapper').style('display', 'none');
            this.isLoading = false
            d3.select('#parameterized-dashboard-filter-panel-scroll-div')
              .transition()
              .delay(300)
              .duration(400)
              .style('display', 'block');
            d3.select("#submitbutton-id").transition().delay(500).duration(400).style("display", "block")
            d3.select('#dashboard-parameterized-filter')
              .transition()
              .delay(200)
              .duration(500)
              .style('width', '350px');
            // d3.select("#dashboard-parameterized-scroll-view").transition().delay(200).duration(500).style("left","360px")//.style("width","calc(100% - 360px)")
            d3.select('#dashboard-parameterized-filter')
              .select('button')
              .style('transform', 'rotate(0deg)');
            d3.select('#filter-id')
              .transition()
              .delay(250)
              .duration(100)
              .style({ left: '10px', top: '10px' })
              .style('transform', 'rotate(0deg)');
            this.filterPanelToggleBool = false;
            data.dashboardRequestorParameters?.forEach((parameter) => {
              if (parameter.parameterName !== 'User') {
                if (parameter.cascadeParameters == '') {
                  this.requestPayloadParametersArray.push({
                    id: parameter.dashboardParameterId,
                    isVisible: parameter.isVisible,
                    name: parameter.parameterName,
                    questionId: parameter.questionOrderBy,
                    value: parameter.defaultValue ? parameter.defaultValue : '',
                  });
                }
                else {
                  this.requestPayloadParametersArray.push({
                    id: parameter.dashboardParameterId,
                    isVisible: parameter.isVisible,
                    name: parameter.parameterName,
                    questionId: parameter.questionOrderBy,
                    value: '',
                  });
                }


                this.parameterElementsArray.push(parameter);
                this.parameterElementsArray.forEach((ele) => {
                  if (ele.displayType == 'Date') {
                    ele['selectedData'] = null;
                  } else {
                    ele['selectedData'] = [];
                  }
                  // ele.selectedData = ele.defaultValue.toString();
                });
                this.parameterElementsArray.forEach((ele) => {
                  if (ele.defaultValue != "") {
                    ele.selectedData = ele.defaultValue;
                  }
                  if (ele.parameterLabel == '') { ele.parameterLabel = 'label' };
                  if (ele.parameterValue == '') { ele.parameterValue = 'value' }
                });
              }
              else if (parameter.parameterName == 'User') {
                this.requestPayloadParametersArray.push({
                  id: parameter.dashboardParameterId,
                  isVisible: 'Y',
                  name: 'User',
                  questionId: 0,
                  value: localStorage.userEmail,
                });
                this.userParameterObject = {
                  id: parameter.dashboardParameterId,
                  isVisible: 'Y',
                  name: 'User',
                  questionId: 0,
                  value: localStorage.userEmail,
                };

              }
            });

            let defaultTempReqPayload = [];
            defaultTempReqPayload.push(this.userParameterObject);
            let paramTempObj = {};

            data.dashboardRequestorParameters?.forEach(function (param) {
              if (param.parameterName !== 'User') {
                paramTempObj = {
                  id: param.dashboardParameterId,
                  isVisible: param.isVisible,
                  name: param.parameterName,
                  questionId: param.questionOrderBy,
                  value: param.defaultValue,
                };
                defaultTempReqPayload.push(paramTempObj);
              }
            });

            this.requestPayloadParameterObj.parameters = defaultTempReqPayload;
            this.payloadParameter = defaultTempReqPayload;
            this.datasData = defaultTempReqPayload;

            this.getPrmDashItmRecsSubscrip = this.dashService
              .getParameterizedDashboardItemRecords(data?.dashboardRequestorParameters[0]?.authorizationId)
              .subscribe(
                (data1: any) => {
                  this.loaderShow = false;
                  this.showParameters = true;
                  this.parameterElementsDataArray = data1.body?.parameterResponse; // 0 service response
                },
                (error) => console.log(error),
                () => {

                  this.parameterElementsArray.forEach((ele) => {

                    this.defaultValuesOfParameterArray = [];
                    this.paraValuesOfParameterArray = [];
                    if (ele.displayType == 'Multi Select') {
                      // if (ele.defaultValue != '') {
                      //   ele.defaultValue.split(',').forEach((defaulVal) => {
                      //     this.defaultValuesOfParameterArray.push({
                      //       [ele.parameterLabel]: defaulVal,
                      //       [ele.parameterValue]: defaulVal,
                      //     });
                      //   });
                      //   ele["fetchedRecords"] = { paramElement: ele, records: { dashboardParameterId: ele.dashboardParameterId, parameterLabel: ele.parameterLabel, parameterValue: ele.parameterValue, records: this.defaultValuesOfParameterArray }, questionOrderBy: ele.questionOrderBy, listEle: this.defaultValuesOfParameterArray }
                      //   //ele.selectedData = '';
                      // } else {
                      ele['fetchedRecords'] = {
                        paramElement: ele,
                        records: null,
                        questionOrderBy: ele.questionOrderBy,
                        listEle: null,
                      };
                      // }
                    }
                    else {
                      // if (ele.defaultValue != '') {
                      //   ele.defaultValue.split(',').forEach((defaulVal) => {
                      //     this.defaultValuesOfParameterArray.push({
                      //       [ele.parameterLabel]: defaulVal,
                      //       [ele.parameterValue]: defaulVal,
                      //     });
                      //   });
                      //   ele["fetchedRecords"] = { paramElement: ele, records: { dashboardParameterId: ele.dashboardParameterId, parameterLabel: ele.parameterLabel, parameterValue: ele.parameterValue, records: this.defaultValuesOfParameterArray }, questionOrderBy: ele.questionOrderBy, listEle: this.defaultValuesOfParameterArray }
                      //   ele.selectedData = '';
                      // }
                      // else {
                      ele['fetchedRecords'] = {
                        paramElement: ele,
                        records: null,
                        questionOrderBy: ele.questionOrderBy,
                        listEle: null,
                      };
                      // }
                    }
                    ele['fetchedRecordsBool'] = false;

                    ele['paramElementFilledBool'] = false;

                    ele['cascadeChildParameters'] = [];

                    this.parameterElementsDataArray.forEach((rec) => {
                      // assigning records from 0'th service

                      if (
                        ele.dashboardParameterId == rec.dashboardParameterId
                      ) {
                        if (ele.displayType == 'Multi Select') {
                          if (ele.isVisible == "N") { ele['fetchedRecordsBool'] = false; ele['paramElementFilledBool'] = true; }
                          else { ele['fetchedRecordsBool'] = true; ele['paramElementFilledBool'] = false; }
                          if (rec.records == null || rec.records.length == 0) {
                            if (ele.defaultValue != '') {
                              ele.defaultValue.split(',').forEach((defaulVal) => {
                                this.defaultValuesOfParameterArray.push({
                                  [ele.parameterLabel]: defaulVal,
                                  [ele.parameterValue]: defaulVal,
                                });
                              });
                              ele["fetchedRecords"] = { paramElement: ele, records: { dashboardParameterId: ele.dashboardParameterId, parameterLabel: ele.parameterLabel, parameterValue: ele.parameterValue, records: this.defaultValuesOfParameterArray }, questionOrderBy: ele.questionOrderBy, listEle: this.defaultValuesOfParameterArray }
                              ele.selectedData = ele.defaultValue;
                              ele['paramElementFilledBool'] = true;
                              // ele.selectedData = '';
                            }
                            else {
                              ele.fetchedRecords.records = rec;
                              ele.fetchedRecords.listEle = rec.records;
                              ele.selectedData = '';
                            }

                          }
                          else {
                            if (ele.isVisible == "N") { ele['fetchedRecordsBool'] = false; ele['paramElementFilledBool'] = true; }
                            else { ele['fetchedRecordsBool'] = true; ele['paramElementFilledBool'] = false; }
                            if (ele.parameterValue.split(',').length > 1) {
                              rec.records.forEach(obj => {
                                let params = ele.parameterValue.split(',');

                                let convertedObject: any = {
                                  "display_label": obj.parameterLabel,
                                  "display_Value": {}
                                };

                                for (let param of params) {
                                  convertedObject.display_Value[param] = obj[param];
                                }
                                this.paraValuesOfParameterArray.push(convertedObject)
                              })

                              this.parameterizedFilterArray.push({
                                paramElement: ele,
                                records: {
                                  parameterLabel: ele.parameterLabel,
                                  parameterValue: 'display_Value',
                                  records: this.paraValuesOfParameterArray,
                                },
                                questionOrderBy: ele.questionOrderBy,
                                listEle: this.paraValuesOfParameterArray,
                              });

                              ele['fetchedRecords'] = {
                                paramElement: ele,
                                records: {
                                  parameterLabel: ele.parameterLabel,
                                  parameterValue: 'display_Value',
                                  records: this.paraValuesOfParameterArray,
                                },
                                questionOrderBy: ele.questionOrderBy,
                                listEle: this.paraValuesOfParameterArray,
                              };

                            }
                            else if (ele.parameterLabel.split(',').length > 1) {
                              rec.records.forEach(obj => {
                                let params = ele.parameterLabel.split(',');
                                let convertedObject: any = {
                                  "display_label": '',
                                  [ele.parameterValue]: obj[ele.parameterValue]
                                };
                                let str = {};
                                for (let param of params) {
                                  str[param] = obj[param];
                                }
                                convertedObject.display_label = Object.values(str).join('|');
                                this.paraValuesOfParameterArray.push(convertedObject);
                              })
                              this.parameterizedFilterArray.push({
                                paramElement: ele,
                                records: {
                                  parameterLabel: 'display_label',
                                  parameterValue: ele.parameterValue,
                                  records: this.paraValuesOfParameterArray,
                                },
                                questionOrderBy: ele.questionOrderBy,
                                listEle: this.paraValuesOfParameterArray,
                              });

                              ele['fetchedRecords'] = {
                                paramElement: ele,
                                records: {
                                  parameterLabel: 'display_label',
                                  parameterValue: ele.parameterValue,
                                  records: this.paraValuesOfParameterArray,
                                },
                                questionOrderBy: ele.questionOrderBy,
                                listEle: this.paraValuesOfParameterArray,
                              };
                              // if (ele.isVisible == "N") { ele['fetchedRecordsBool'] = false; ele['paramElementFilledBool'] = true; }
                              // else { ele['fetchedRecordsBool'] = true; ele['paramElementFilledBool'] = false; }
                            }
                            else {
                              this.parameterizedFilterArray.push({
                                paramElement: ele,
                                records: rec,
                                questionOrderBy: ele.questionOrderBy,
                                listEle: rec.records,
                              });

                              ele['fetchedRecords'] = {
                                paramElement: ele,
                                records: rec,
                                questionOrderBy: ele.questionOrderBy,
                                listEle: rec.records,
                              };
                              // if (ele.isVisible == "N") { ele['fetchedRecordsBool'] = false; ele['paramElementFilledBool'] = true; }
                              // else { ele['fetchedRecordsBool'] = true; ele['paramElementFilledBool'] = false; }
                            }
                          }
                        }
                        else {
                          if (rec.records == null || rec.records?.length == 0) {
                            if (ele.isVisible == "N") { ele['fetchedRecordsBool'] = false; ele['paramElementFilledBool'] = true; }
                            else { ele['fetchedRecordsBool'] = true; ele['paramElementFilledBool'] = false; }
                            if (ele.defaultValue != '') {
                              ele.defaultValue.split(',').forEach((defaulVal) => {
                                this.defaultValuesOfParameterArray.push({
                                  [ele.parameterLabel]: defaulVal,
                                  [ele.parameterValue]: defaulVal,
                                });
                              });
                              ele["fetchedRecords"] = { paramElement: ele, records: { dashboardParameterId: ele.dashboardParameterId, parameterLabel: ele.parameterLabel, parameterValue: ele.parameterValue, records: this.defaultValuesOfParameterArray }, questionOrderBy: ele.questionOrderBy, listEle: this.defaultValuesOfParameterArray }
                              ele.selectedData = ele.defaultValue;
                              ele['paramElementFilledBool'] = true;
                              // var parameterArrayObj0 = {
                              //   id: ele.dashboardParameterId,
                              //   isVisible: ele.isVisible,
                              //   name: ele.parameterName,
                              //   questionId: ele.questionOrderBy,
                              //   value: ele.selectedData,
                              // };
                              // this.requestPayloadParametersArray.push(parameterArrayObj0);
                            }
                            else {
                              ele.fetchedRecords.records = rec;
                              ele.fetchedRecords.listEle = rec.records;
                              ele.selectedData = '';
                            }

                          }
                          else {
                            if (ele.isVisible == "N") { ele['fetchedRecordsBool'] = false; ele['paramElementFilledBool'] = true; }
                            else { ele['fetchedRecordsBool'] = true; ele['paramElementFilledBool'] = false; }
                            if (ele.parameterValue.split(',').length > 1) {
                              rec.records.forEach(obj => {
                                let params = ele.parameterValue.split(',');

                                let convertedObject: any = {
                                  "display_label": obj.parameterLabel,
                                  "display_Value": {}
                                };

                                for (let param of params) {
                                  convertedObject.display_Value[param] = obj[param];
                                }
                                this.paraValuesOfParameterArray.push(convertedObject)
                              })

                              this.parameterizedFilterArray.push({
                                paramElement: ele,
                                records: {
                                  parameterLabel: ele.parameterLabel,
                                  parameterValue: 'display_Value',
                                  records: this.paraValuesOfParameterArray,
                                },
                                questionOrderBy: ele.questionOrderBy,
                                listEle: this.paraValuesOfParameterArray,
                              });

                              ele['fetchedRecords'] = {
                                paramElement: ele,
                                records: {
                                  parameterLabel: ele.parameterLabel,
                                  parameterValue: 'display_Value',
                                  records: this.paraValuesOfParameterArray,
                                },
                                questionOrderBy: ele.questionOrderBy,
                                listEle: this.paraValuesOfParameterArray,
                              };

                            }
                            else if (ele.parameterLabel.split(',').length > 1) {
                              rec.records.forEach(obj => {
                                let params = ele.parameterLabel.split(',');
                                let convertedObject: any = {
                                  "display_label": '',
                                  [ele.parameterValue]: obj[ele.parameterValue]
                                };
                                let str = {};
                                for (let param of params) {
                                  str[param] = obj[param];
                                }
                                convertedObject.display_label = Object.values(str).join('|');
                                this.paraValuesOfParameterArray.push(convertedObject);
                              })
                              this.parameterizedFilterArray.push({
                                paramElement: ele,
                                records: {
                                  parameterLabel: 'display_label',
                                  parameterValue: ele.parameterValue,
                                  records: this.paraValuesOfParameterArray,
                                },
                                questionOrderBy: ele.questionOrderBy,
                                listEle: this.paraValuesOfParameterArray,
                              });

                              ele['fetchedRecords'] = {
                                paramElement: ele,
                                records: {
                                  parameterLabel: 'display_label',
                                  parameterValue: ele.parameterValue,
                                  records: this.paraValuesOfParameterArray,
                                },
                                questionOrderBy: ele.questionOrderBy,
                                listEle: this.paraValuesOfParameterArray,
                              };
                              if (ele.isVisible == "N") { ele['fetchedRecordsBool'] = false; ele['paramElementFilledBool'] = true; }
                              else { ele['fetchedRecordsBool'] = true; ele['paramElementFilledBool'] = false; }
                            }
                            else {
                              this.parameterizedFilterArray.push({
                                paramElement: ele,
                                records: rec,
                                questionOrderBy: ele.questionOrderBy,
                                listEle: rec.records
                              });

                              ele['fetchedRecords'] = {
                                paramElement: ele,
                                records: rec,
                                questionOrderBy: ele.questionOrderBy,
                                listEle: rec.records
                              };
                              if (ele.isVisible == "N") { ele['fetchedRecordsBool'] = false; ele['paramElementFilledBool'] = true; }
                              else { ele['fetchedRecordsBool'] = true; ele['paramElementFilledBool'] = false; }
                            }
                          }
                        }
                      }
                      // }
                    }); //rec
                  }); // ele
                  this.parameterElementsArray =
                    this.parameterElementsArray.sort((n1, n2) => {
                      if (
                        Number(n1.questionOrderBy) > Number(n2.questionOrderBy)
                      ) {
                        return 1;
                      }
                      if (
                        Number(n1.questionOrderBy) < Number(n2.questionOrderBy)
                      ) {
                        return -1;
                      }
                      return 0;
                    });
                  this.parameterElementsArray.forEach((param, index) => {
                    if (param.displayType === 'Select' && param.clientUserParameter=== 'Y' && param.cascadeParameters === '' && param.fetchedRecords?.records?.records?.length > 0 && param.fetchedRecords?.records?.records?.length == 1) {
                      this.payloadParameter=[];
                      this.showParameters=false;
                      param.selectedData = param.fetchedRecords.records.records[0][param.fetchedRecords.records.parameterValue];
                      param.paramElementFilledBool=true;
                      this.userParameterObject = {
                        id: param.dashboardParameterId,
                        isVisible: 'Y',
                        name: 'User',
                        questionId: 0,
                        value: localStorage.userEmail,
                      };
                      this.payloadParameter.push(this.userParameterObject);
                      this.payloadParameter.push(
                        {
                          "name": "User",
                          "value": localStorage.userEmail,
                          "questionId": 1234567890,
                          "id": 1234567890,
                          "isVisible": "Y"
                        })
                      let obj={
                        id: param.dashboardParameterId,
                        isVisible: param.isVisible,
                        name: param.parameterName,
                        questionId: param.questionOrderBy,
                        value: param.fetchedRecords.records.records[0][param.fetchedRecords.records.parameterValue],
                      }
                      this.payloadParameter.push(obj);
                      this.onFilterSubmitClick();
                    }
                  });
                }
              ); // getParameterizedDashboardItemRecords final block
            //this.showData=true;
            // this.dashboard();
            this.dashboardInfoObject = data?.body;
            // this.finalCall() // Storing entire dashboard information for changing chart colors usage
          } // data.body.dashboardRequestorParameters.length else block
          if (this.parameterElementsArray.length > 0) {
            this.parametersList = this.parameterElementsArray;
            data = [];
            this.parametersList.forEach((e) => {
              // e.parameterLabel.replace(" "," ");
              data.push(
                e.parameterName.replace(e.parameterLabel.replace(' ', '_'), '')
              );
            });
            let unique = data.reduce(function (acc, curr) {
              if (!acc.includes(curr)) acc.push(curr);
              return acc;
            }, []);
            for (let i = 0; i < unique.length; i++) {
              this.listOfDashboards?.filter((element) => {
                if (
                  unique[i].includes(
                    '___' + element.widgetName.replace(/ /g, '')
                  )
                ) {
                  let obj = { name: '', parameters: [] };
                  obj.name = element.widgetName;

                  this.parametersList.forEach((e) => {
                    if (e.parameterName.includes(unique[i])) {
                      obj.parameters.push(e);
                    }
                  });
                  this.lists.push(obj);
                }
              });
            }
          }
          if (this.dateRangeAvailable?.isActive == "Y") {
            // let dateRangeObj={};
            this.parameterElementsArray.forEach((item) => {
              if (item.parameterName == this.dateRangeAvailable.endDateColumn) {
                item.isVisible = "N";
                item.isMandatory = "N";
              }
              if (item.parameterName == this.dateRangeAvailable.startDateColumn) {
                // dateRangeObj=item;
                item.isVisible = "N";
                item.isMandatory = "N";
              }
            })
            const dateRange = ["Current Business Day", "Previous 3 Business Days", "Previous 2 Business Days", "Previous 3 Days", "Previous 2 Days", "Previous 3 Days Including Today", "Previous Business Day", "Previous 7 Days", "Previous 7 Days Including Today", "Previous 2 Weeks", "Previous 3 Weeks", "Previous 4 Weeks", "Month to Date", "Previous 30 Days", "First Half Month(1st - 15th)", "Second Half Month(16th - EOM)", "Previous Calendar Month", "Previous 3 Months", "Year To Date", "Year To Previous Month End", "Last 12 Months", "Last 12 Months Including This Month", "Previous 1 Year", "Last 365 Days Including Today", "Last 365 Days", "Other"];

            let dateObj = dateRange.map(dateRangeName => ({ Date_Range: dateRangeName, value: dateRangeName }));

            //console.log(dateObj);
            let obj = {
              parameterLabel: "Date_Range",
              parameterValue: "value",
              records: dateObj
            };
            setTimeout(() => {
              let dateRangeObj = {
                authorizationId: this.parameterElementsArray[0].authorizationId,
                cascadeChildParameters: [],
                cascadeParameters: "",
                createdBy: this.parameterElementsArray[0].createdBy,
                createdDate: this.parameterElementsArray[0].createdDate,
                dashboardParameterId: 0,
                dataSourceId: this.parameterElementsArray[0].dataSourceId,
                dateRange: null,
                defaultValue: "",
                displayType: "Select",
                fetchedRecords: {
                  paramElement: '', // Assuming you want to assign the first element of parameterElementsArray
                  records: obj, // Assign null or some other value as required
                  questionOrderBy: 1, // Assign as required
                  listEle: dateObj, // Assign null or some other value as required
                },
                fetchedRecordsBool: true,
                isActive: "Y",
                isMandatory: "Y",
                isVisible: "Y",
                modifiedBy: this.parameterElementsArray[0].modifiedBy,
                modifiedDate: this.parameterElementsArray[0].modifiedDate,
                paramElementFilledBool: false,
                parameterLabel: "Date_Range",
                parameterName: "Date_Range",
                parameterValue: "value",
                procedureParameters: "",
                procedureText: "",
                queryText: "",
                questionOrderBy: 1,
                selectedData: [],
                tableView: ""
              };

              // dateRangeObj['displayType']="Select";
              // dateRangeObj['isMandatory']="Y";
              // dateRangeObj['isVisible']="Y";
              // dateRangeObj['fetchedRecords']=obj;
              // dateRangeObj['fetchedRecordsBool']=true;
              // dateRangeObj['parameterLabel']="Date Range";
              // dateRangeObj['parameterName']="DateRange";
              // dateRangeObj['parameterValue']="value";
              // dateRangeObj['queryText']='';
              this.parameterElementsArray.push(dateRangeObj)
              //console.log(this.parameterElementsArray)
            }, 2000);
          }

          //console.log(this.parameterElementsArray)
          setTimeout(() => {
            d3.selectAll('.widget-class').style('visibility', 'visible')
          }, 1000);
        },
        (error) => {
          d3.select('.tibo-mydash-dash-spinner').style('display', 'none');

          this.isLoading = false
          if (Number(error.status) == 401) {
            this.errorMsg = 'Please Try After Some Time!';
          } else if (Number(error.status) == 500) {
            this.errorMsg = 'Internal Error';
          } else {
            this.errorMsg = 'Please Try After Some Time.';
          }

          this.addSingle();
        },
        //() => this.finalCall() 
      ); // getADashboard service
    let payload = {
      parameters: []
    };
    // this.dashService.logData(this.selectedDashboard.code, payload).subscribe((res: any) => {
    // });
  } // get a dashboard func
  findRefElement(index: number): any {
    const refEl = document.querySelector(
      `[attr.selectInfoIndex="${index}"]`
    ) as HTMLElement;
    return refEl;
  }
  finalCall() {
    this.finalCallCounter++;

    if (this.finalCallCounter == this.totalWidgets.length) {
      // when all widgets service calling completes
      this.disable = false
      this.isLoading = false;
      d3.select('.tibo-mydash-dash-spinner').style('display', 'none');
      this.showData = true
      d3.selectAll('.widget-class')[0].map(function (d, ind) {
        if (d3.select(d).attr('empty') == 'true') {
          //d3.select(d).remove()
        } else {
          d3.select(d).style('visibility', 'visible');
          d3.select(d).select('p-progressspinner').remove();
        }
      });
    }
  }
  l;

  navigateToCreate() {
    this.router.navigate(['/create-dashboard']);
  }

  selectcolor: boolean = false;

  onManualInputChange(value: string, refEl: any, index: number) {
    //console.log(value, refEl, index)
    //console.log(this.requestPayloadParametersArray)
    if (value == null) {
      var obj =
        this.parameterElementsArray[
        Number(refEl.el.nativeElement.attributes.selectInfoIndex.value)
        ];
      obj.selectedData = value;
      obj.paramElementFilledBool = false;
      this.requestPayloadParametersArray.forEach(
        (obje) =>{ 
          if(obje.id == obj.dashboardParameterId){
            obje.value = '';
        }}
      );
     
    }
    else {
      // //console.log(obj)
      var obj =
        this.parameterElementsArray[
        Number(refEl.el.nativeElement.attributes.selectInfoIndex.value)
        ];
      obj.selectedData = value;
      this.onChange(value, refEl, index);
    }
  }
  onChange(event, refEl, j: any) {
    //console.log(event, refEl, j)
    //console.log(this.parameterElementsArray[j].selectedData)
    this.payloadParameter = []
    if (refEl != undefined) {
      if (refEl?.el?.nativeElement?.nodeName == 'P-CALENDAR') {
        // this.selectcolor = false;
        var value11 = '';
        value11 =
          ('0' + (event.getMonth() + 1)).slice(-2) +
          '/' +
          ('0' + event.getDate()).slice(-2) +
          '/' +
          event.getFullYear();
        var obj =
          this.parameterElementsArray[
          Number(refEl.el.nativeElement.attributes.selectInfoIndex.value)
          ];
        //console.log(obj)
        if (!obj.selectedData) {
          obj.paramElementFilledBool = false;
        }
        else {
          obj.paramElementFilledBool = true;
        }
        this.requestPayloadParametersArray =
          this.requestPayloadParametersArray.filter(
            (obje) => obje.id !== obj.dashboardParameterId
          );

        var parameterArrayObj0 = {
          id: obj.dashboardParameterId,
          isVisible: obj.isVisible,
          name: obj.parameterName,
          questionId: obj.questionOrderBy,
          value: value11,
        };
        this.requestPayloadParametersArray.push(parameterArrayObj0);

        //this.requestPayloadParametersArray[j].value=value
        this.requestPayloadParameterObj.parameters = this.datasData.map(
          (obj) =>
            this.requestPayloadParametersArray.find((o) => o.id === obj.id) ||
            obj
        );
        let ids = obj.cascadeChildParameters;
        this.requestPayloadParametersArray = this.requestPayloadParametersArray.filter(item => !ids.includes(item.id));
        if (ids.length > 0) {
          for (let i = j + 1; i < this.parameterElementsArray.length; i++) {
            if (this.parameterElementsArray[i].cascadeParameters.length > 0) {
              this.loaderShow1 = true;
              this.parameterElementsArray[i].selectedData = [];
              // this.parameterElementsArray[i].fetchedRecords = [];
              this.requestPayloadParametersArray = this.requestPayloadParametersArray.filter(item => item.id !== this.parameterElementsArray[i].dashboardParameterId);
            }
          }
        }
      }
      else if (refEl?.el?.nativeElement?.nodeName == 'P-MULTISELECT') {
        // this.selectcolor = false;
        if (event.value.length == 1) {
          if (typeof event.value === 'object') {
            var valueArray = event.value[0]
          }
          else {
            var valueArray = event.value[0].toString();
          }
        }
        else if (event.value.length > 1) {
          var valueArray = event.value.map((x) => "'" + x + "'").toString();
        }
        var obj =
          this.parameterElementsArray[
          Number(refEl.el.nativeElement.attributes.selectInfoIndex.value)
          ];
        if (event.value.length == 0) {
          obj.paramElementFilledBool = false;
        } else {
          obj.paramElementFilledBool = true;
        }

        this.requestPayloadParametersArray =
          this.requestPayloadParametersArray.filter(
            (obje) => obje.id !== obj.dashboardParameterId
          );
        var parameterArrayObj1 = {
          id: obj.dashboardParameterId,
          isVisible: obj.isVisible,
          name: obj.parameterName,
          questionId: obj.questionOrderBy,
          value: valueArray,
        };

        this.requestPayloadParametersArray.push(parameterArrayObj1);
        this.requestPayloadParameterObj.parameters = this.datasData.map(
          (obj) =>
            this.requestPayloadParametersArray.find((o) => o.id === obj.id) ||
            obj
        );
        let ids = obj.cascadeChildParameters;
        this.requestPayloadParametersArray = this.requestPayloadParametersArray.filter(item => !ids.includes(item.id));
        if (ids.length > 0) {
          for (let i = j + 1; i < this.parameterElementsArray.length; i++) {
            if (this.parameterElementsArray[i].cascadeParameters.length > 0) {
              this.loaderShow1 = true;
              this.parameterElementsArray[i].selectedData = [];
              //this.parameterElementsArray[i].fetchedRecords = [];
              this.requestPayloadParametersArray = this.requestPayloadParametersArray.filter(item => item.id !== this.parameterElementsArray[i].dashboardParameterId);
            }

          }
        }
      }
      else if (refEl?.el?.nativeElement?.nodeName == 'P-DROPDOWN') {
        if (event !== "" && event !== null && event !== undefined) {
          this.selectcolor = true;
          var value1 = event;
          var obj =
            this.parameterElementsArray[
            Number(refEl.el.nativeElement.attributes.selectInfoIndex.value)
            ];
          if (obj.parameterName == "Date_Range") {
            if (event == 'Other') {
              this.parameterElementsArray.filter((item) => {
                if (item.parameterName == this.dateRangeAvailable.endDateColumn) {
                  item.isVisible = "Y";
                  item.isMandatory = "Y";
                  item.fetchedRecordsBool = true;
                  item.selectedData = "";
                }
                if (item.parameterName == this.dateRangeAvailable.startDateColumn) {
                  item.isVisible = "Y";
                  item.isMandatory = "Y";
                  item.fetchedRecordsBool = true;
                  item.selectedData = "";
                }
              })
              //console.log(this.parameterElementsArray)
            }
            if (event != 'Other') {
              this.parameterElementsArray.filter((item) => {
                if (item.parameterName == this.dateRangeAvailable.endDateColumn) {
                  item.isVisible = "N";
                  item.isMandatory = "N";
                  item.fetchedRecordsBool = false;
                  item.selectedData = "";
                }
                if (item.parameterName == this.dateRangeAvailable.startDateColumn) {
                  item.isVisible = "N";
                  item.isMandatory = "N";
                  item.fetchedRecordsBool = false;
                  item.selectedData = "";
                }
              })
            }
          }

          this.requestPayloadParametersArray =
            this.requestPayloadParametersArray.filter(
              (obje) => obje.id !== obj.dashboardParameterId
            );
          var value2 = '';
          //obj.fetchedRecords?.listEle?.filter((o) => { if (o[obj.parameterLabel] == event.target.innerText) { value2 = o[obj.parameterValue] } })
          var parameterArrayObj2 = {
            id: obj.dashboardParameterId,
            isVisible: obj.isVisible,
            name: obj.parameterName,
            questionId: obj.questionOrderBy,
            value: value1,
          };

          this.requestPayloadParametersArray.push(parameterArrayObj2);
          this.requestPayloadParameterObj.parameters = this.datasData.map(
            (obj) =>
              this.requestPayloadParametersArray.find((o) => o.id === obj.id) ||
              obj
          );
          let ids = obj.cascadeChildParameters;
          this.requestPayloadParametersArray = this.requestPayloadParametersArray.filter(item => !ids.includes(item.id));
          if (ids.length > 0) {
            for (let i = j + 1; i < this.parameterElementsArray.length; i++) {
              if (this.parameterElementsArray[i].cascadeParameters != '') {
                this.loaderShow1 = true;
                this.parameterElementsArray[i].selectedData = [];
                //this.parameterElementsArray[i].fetchedRecords = [];
                this.requestPayloadParametersArray = this.requestPayloadParametersArray.filter(item => item.id !== this.parameterElementsArray[i].dashboardParameterId);
              }

            }
          }
          if (event === "") {
            obj.paramElementFilledBool = false;
          } else {
            obj.paramElementFilledBool = true;
          }
        }
      }
      else if (refEl?.name === 'radiobutton') {
        var obj =
          this.parameterElementsArray[j];
        var value1 = refEl?.value;

        this.requestPayloadParametersArray =
          this.requestPayloadParametersArray.filter(
            (obje) => obje.id !== obj.dashboardParameterId
          );

        var parameterArrayObj3 = {
          id: obj.dashboardParameterId,
          isVisible: obj.isVisible,
          name: obj.parameterName,
          questionId: obj.questionOrderBy,
          value: value1,
        };
        this.requestPayloadParametersArray.push(parameterArrayObj3);
        this.requestPayloadParameterObj.parameters = this.datasData.map(
          (obj) =>
            this.requestPayloadParametersArray.find((o) => o.id === obj.id) ||
            obj
        );
        if (value1 === "") {
          obj.paramElementFilledBool = false;
        } else {
          obj.paramElementFilledBool = true;
        }
        let ids = obj.cascadeChildParameters;
        this.requestPayloadParametersArray = this.requestPayloadParametersArray.filter(item => !ids.includes(item.id));
        // ids.forEach(i=>{
        if (ids.length > 0) {
          for (let i = j + 1; i < this.parameterElementsArray.length; i++) {
            if (this.parameterElementsArray[i].cascadeParameters != '') {
              this.loaderShow1 = true;
              this.parameterElementsArray[i].selectedData = [];
              //this.parameterElementsArray[i].fetchedRecords = [];
              this.requestPayloadParametersArray = this.requestPayloadParametersArray.filter(item => item.id !== this.parameterElementsArray[i].dashboardParameterId);
            }
          }

          //obj.paramElementFilledBool = true;
        }
      }
    }

    else if (refEl == undefined) {
      var value1 = event.target.value;
      var obj =
        this.parameterElementsArray[
        Number(event.target.attributes.selectInfoIndex.value)
        ];
      this.requestPayloadParametersArray =
        this.requestPayloadParametersArray.filter(
          (obje) => obje.id !== obj.dashboardParameterId
        );

      var parameterArrayObj3 = {
        id: obj.dashboardParameterId,
        isVisible: obj.isVisible,
        name: obj.parameterName,
        questionId: obj.questionOrderBy,
        value: value1,
      };
      this.requestPayloadParametersArray.push(parameterArrayObj3);
      let ids = obj.cascadeChildParameters;
      this.requestPayloadParametersArray = this.requestPayloadParametersArray.filter(item => !ids.includes(item.id));
      // ids.forEach(i=>{
      //   this.parameterElementsArray.forEach(e=>{
      //     if(i==e.dashboardParameterId){
      //       e.selectedData=[];
      //       e.fetchedRecords=[];
      //     }
      //   })
      // })
      if (ids.length > 0) {
        for (let i = j + 1; i < this.parameterElementsArray.length; i++) {
          if (this.parameterElementsArray[i].cascadeParameters.length > 0) {
            this.loaderShow1 = true;
            this.parameterElementsArray[i].selectedData = [];
            // this.parameterElementsArray[i].fetchedRecords = [];
            this.requestPayloadParametersArray = this.requestPayloadParametersArray.filter(item => item.id !== this.parameterElementsArray[i].dashboardParameterId);
          }

        }
      }
      // 

      // this.requestPayloadParameterObj.parameters =
      //   this.requestPayloadParametersArray;

      //if(event.target.nodeName != "INPUT"){
      if (event.target.type != 'text') {
        // radio button

        obj.paramElementFilledBool = true;
      } else if (event.target.type == 'text') {
        if (event.target.value.length == 0) {
          obj.paramElementFilledBool = false;
        } else {
          obj.paramElementFilledBool = true;
        }
      }
      //}
    }

    this.parameterElementsArray.forEach(ele => {
      this.requestPayloadParametersArray.forEach(req => {
        if (ele?.dashboardParameterId == req.id) {
          if ((req.value === "") || (req.value === undefined)) {
            return;
          }
          else {
            if (ele.parameterValue.split(',').length > 1) {
              let params = ele.parameterValue.split(',');
              for (let param of params) {
                let obj = {
                  id: ele.dashboardParameterId,
                  isVisible: ele.isVisible,
                  name: ele.parameterName + '|' + param,
                  questionId: ele.questionOrderBy,
                  value: req.value[param],
                }
                this.payloadParameter.push(obj)
              }
            }
            else {
              this.payloadParameter.push(req)
            }
          }
        }
      })
    })
    if (Object.keys(this.userParameterObject).length === 0) {
      this.payloadParameter.push(
        {
          "name": "User",
          "value": localStorage.userEmail,
          "questionId": 1234567890,
          "id": 1234567890,
          "isVisible": "Y"
        })
    } else {
      this.payloadParameter.push(this.userParameterObject);
      this.payloadParameter.push(
        {
          "name": "User",
          "value": localStorage.userEmail,
          "questionId": 1234567890,
          "id": 1234567890,
          "isVisible": "Y"
        })
    }
    if (obj?.dashboardParameterId == undefined || obj?.dashboardParameterId == null || obj?.dashboardParameterId == '') {
      return
    }
    else {
      this.loaderShow1 = true;
      this.getPrmDashCasRecsRadioSubscrip = this.dashService
        .getParameterizedDashboardCascadingRecords(
          this.selectedDashboard.code,
          obj?.dashboardParameterId, { parameters: this.payloadParameter }
          // { parameters: [this.userParameterObject, parameterArrayObj2] }
        )
        .subscribe(
          (data: any) => {
            this.loaderShow1 = false;
            //--------------------------------------------
            data?.body?.parameterResponse?.forEach((rec) => {
              this.parameterElementsArray.forEach((ele) => {
                if (
                  Number(ele.dashboardParameterId) ==
                  Number(rec.dashboardParameterId)
                ) {
                  this.parameterElementsArray.forEach((ele1) => {
                    if (ele1.parameterName == ele.cascadeParameters) {
                      ele1.cascadeChildParameters.push(
                        ele.dashboardParameterId
                      );
                    }
                  });
                }
              });
            });

            var array = obj.cascadeChildParameters,
              newArray = [],
              arrayCopy = [];

            if (array.length > 0) {
              array = array.filter(function (item, pos) {
                return array.indexOf(item) == pos;
              });
            }

            arrayCopy = array;

            do {
              newArray = [];

              array.forEach((item, i) => {
                this.parameterElementsArray.forEach((ele) => {
                  if (Number(ele.dashboardParameterId) == Number(item)) {
                    if (ele.cascadeChildParameters.length > 0) {
                      for (
                        let ii = 0;
                        ii < ele.cascadeChildParameters.length;
                        ii++
                      ) {
                        newArray.push(ele.cascadeChildParameters[ii]);
                      }
                    } else if (ele.cascadeChildParameters.length == 0) {
                    }
                  }
                });
              });

              array = [];
              array = newArray;
              arrayCopy = [...arrayCopy, ...newArray];
            } while (newArray.length > 0);

            var uniqueArray = arrayCopy.filter(function (item, pos) {
              return arrayCopy.indexOf(item) == pos;
            });
            uniqueArray.forEach((item) => {
              this.parameterElementsArray.forEach((ele) => {
                if (Number(item) == Number(ele.dashboardParameterId)) {
                  ele.fetchedRecordsBool = false;
                  ele.fetchedRecords.records = null;
                  ele.paramElementFilledBool = false;
                  ele.selectedData = '';
                }
              });
            });
            //--------------------------------------------

            this.parameterElementsArray.forEach((ele) => {
              data.body.parameterResponse.forEach((rec) => {

                if (
                  Number(ele.dashboardParameterId) ==
                  Number(rec.dashboardParameterId)
                ) {
                  this.defaultValuesOfParameterArray = []
                  this.paraValuesOfParameterArray = []
                  ele['fetchedRecords'] = []
                  if (ele.parameterValue.split(',').length > 1) {
                    rec?.records?.forEach(obj => {
                      let params = ele.parameterValue.split(',');

                      let convertedObject: any = {
                        [ele.parameterLabel]: obj[ele.parameterLabel],
                        [ele.parameterValue]: ''
                      };
                      let str = {};
                      for (let param of params) {
                        str[param] = obj[param];
                      }
                      // for (let param of params) {
                      convertedObject[ele.parameterValue] = str;
                      // }
                      this.paraValuesOfParameterArray.push(convertedObject)
                    })
                    ele['fetchedRecords'] = {
                      paramElement: ele,
                      records: {
                        parameterLabel: ele.parameterLabel,
                        parameterValue: ele.parameterValue,
                        records: this.paraValuesOfParameterArray,
                      },
                      questionOrderBy: ele.questionOrderBy,
                      listEle: this.paraValuesOfParameterArray,
                    };
                    setTimeout(function () {
                      // if(refEl?.name === 'radiobutton'){
                      //         if(ele.parameterName == event){
                      //           ele.fetchedRecordsBool=true;
                      //         }
                      //         else{
                      //           ele.fetchedRecordsBool=false;
                      //         }
                      //       }
                      // else{
                      if (ele.isVisible == "N") {
                        ele['fetchedRecordsBool'] = false; ele['paramElementFilledBool'] = true;
                      }
                      else {
                        if (refEl?.name === 'radiobutton') {
                          if (ele['parameterName'] == refEl?.value.replace(' ', '')) {
                            ele['fetchedRecordsBool'] = true;
                            ele['paramElementFilledBool'] = false;
                          }
                          else {
                            ele['fetchedRecordsBool'] = false;
                            ele['paramElementFilledBool'] = false;
                          }
                        }
                        else {
                          ele['fetchedRecordsBool'] = true; ele['paramElementFilledBool'] = false;
                        }
                      }
                    });
                  }
                  else if (ele.parameterLabel.split(',').length > 1) {
                    rec?.records?.forEach(obj => {
                      let params = ele.parameterLabel.split(',');
                      let convertedObject: any = {
                        "display_label": '',
                        [ele.parameterValue]: obj[ele.parameterValue]
                      };
                      let str = {};
                      for (let param of params) {
                        str[param] = obj[param];
                      }
                      convertedObject.display_label = Object.values(str).join('|');
                      this.paraValuesOfParameterArray.push(convertedObject);
                    })
                    ele['fetchedRecords'] = {
                      paramElement: ele,
                      records: {
                        parameterLabel: 'display_label',
                        parameterValue: ele.parameterValue,
                        records: this.paraValuesOfParameterArray,
                      },
                      questionOrderBy: ele.questionOrderBy,
                      listEle: this.paraValuesOfParameterArray,
                    };
                    setTimeout(function () {
                      // if(refEl?.name === 'radiobutton'){

                      //         if(ele.parameterName == event){
                      //           ele.fetchedRecordsBool=true;
                      //         }
                      //         else{
                      //           ele.fetchedRecordsBool=false;
                      //         }
                      //       }

                      // else{
                      if (ele.isVisible == "N") { ele['fetchedRecordsBool'] = false; ele['paramElementFilledBool'] = true; }
                      else {
                        if (refEl?.name === 'radiobutton') {
                          if (ele['parameterName'] == refEl?.value.replace(' ', '')) {
                            ele['fetchedRecordsBool'] = true;
                            ele['paramElementFilledBool'] = false;
                          }
                          else {
                            ele['fetchedRecordsBool'] = false;
                            ele['paramElementFilledBool'] = false;
                          }
                        }
                        else {

                          ele['fetchedRecordsBool'] = true; ele['paramElementFilledBool'] = false;
                        }
                      }
                    });
                  }
                  else {

                    if (rec.records == null || rec.records?.length == 0) {
                      if (ele.defaultValue != '') {
                        ele.defaultValue.split(',').forEach((defaulVal) => {
                          this.defaultValuesOfParameterArray.push({
                            [ele.parameterLabel]: defaulVal,
                            [ele.parameterValue]: defaulVal,
                          });
                        });
                        ele["fetchedRecords"] = {
                          paramElement: ele,
                          records: { dashboardParameterId: ele.dashboardParameterId, parameterLabel: ele.parameterLabel, parameterValue: ele.parameterValue, records: this.defaultValuesOfParameterArray },
                          questionOrderBy: ele.questionOrderBy,
                          listEle: this.defaultValuesOfParameterArray
                        }
                        // ele.selectedData = ele.defaultValue;
                        setTimeout(function () {
                          // if(refEl?.name === 'radiobutton'){

                          //         if(ele.parameterName == event){
                          //           ele.fetchedRecordsBool=true;
                          //         }
                          //         else{
                          //           ele.fetchedRecordsBool=false;
                          //         }
                          //       }

                          // else{
                          if (ele.isVisible == "N") { ele['fetchedRecordsBool'] = false; ele['paramElementFilledBool'] = true; }
                          else {
                            if (refEl?.name === 'radiobutton') {
                              if (ele['parameterName'] == refEl?.value.replace(' ', '')) {
                                ele['fetchedRecordsBool'] = true;
                                ele['paramElementFilledBool'] = false;
                              }
                              else {
                                ele['fetchedRecordsBool'] = false;
                                ele['paramElementFilledBool'] = false;
                              }
                            }
                            else { ele['fetchedRecordsBool'] = true; ele['paramElementFilledBool'] = false; }
                          }
                        });
                      }
                      else {
                        ele.fetchedRecords.records = rec;
                        ele.fetchedRecords.listEle = rec.records;
                        setTimeout(function () {
                          // if(refEl?.name === 'radiobutton'){

                          //         if(ele.parameterName == event){
                          //           ele.fetchedRecordsBool=true;
                          //         }
                          //         else{
                          //           ele.fetchedRecordsBool=false;
                          //         }
                          //       }

                          // else{
                          if (ele.isVisible == "N") { ele['fetchedRecordsBool'] = false; ele['paramElementFilledBool'] = true; }
                          else {
                            if (refEl?.name === 'radiobutton') {
                              if (ele['parameterName'] == refEl?.value.replace(' ', '')) {
                                ele['fetchedRecordsBool'] = true;
                                ele['paramElementFilledBool'] = false;
                              }
                              else {
                                ele['fetchedRecordsBool'] = false;
                                ele['paramElementFilledBool'] = false;
                              }
                            }
                            else {
                              ele['fetchedRecordsBool'] = true; ele['paramElementFilledBool'] = false;
                            }
                          }
                        });
                      }
                    }
                    else {
                      ele.fetchedRecords.records = rec;
                      ele.fetchedRecords.listEle = rec.records;
                      setTimeout(function () {
                        // if(refEl?.name === 'radiobutton'){

                        //         if(ele.parameterName == event){
                        //           ele.fetchedRecordsBool=true;
                        //         }
                        //         else{
                        //           ele.fetchedRecordsBool=false;
                        //         }
                        //       }

                        // else{
                        if (ele.isVisible == "N") { ele['fetchedRecordsBool'] = false; ele['paramElementFilledBool'] = true; }
                        else {
                          if (refEl?.name === 'radiobutton') {
                            if (ele['parameterName'] == refEl?.value.replace(' ', '')) {
                              ele['fetchedRecordsBool'] = true;
                              ele['paramElementFilledBool'] = false;
                            }
                            else {
                              ele['fetchedRecordsBool'] = false;
                              ele['paramElementFilledBool'] = false;
                            }
                          }
                          else {
                            ele['fetchedRecordsBool'] = true; ele['paramElementFilledBool'] = false;
                          }
                        }
                      });
                    }

                  }
                }
              }
              );
            });
            // if(refEl?.name === 'radiobutton'){
            //   uniqueArray.forEach((item) => {
            //     this.parameterElementsArray.forEach((ele) => {
            //       if (Number(item) == Number(ele.dashboardParameterId)){
            //         if(ele.parameterName == event){
            //           ele.fetchedRecordsBool=true;
            //         }
            //         else{
            //           ele.fetchedRecordsBool=false;
            //         }
            //       }
            //     })
            //   })
            // }
          },
          (error) => console.log(error),
          () => console.log()
        );

    }
    this.parameters[j] = this.requestPayloadParametersArray;
    //this.onFilterSubmitClick();
    //}
  }

  dashboard() {

    acinfotech.requestType(true); // Letting chart library know it's a dashboard module call and empty all stored divs id and chart div elements
    //this.isLoading = true;
    //d3.select('.tibo-mydash-dash-spinner').style('display', 'block'); // showing dashboard spinner
    this.filterPanelToggleBool = true;
    this.downloadArray = [];
    this.coordinate = []
    this.parameterizedDashboardRandomArray = [];
    this.finalCallCounter = 0;
    this.totalRows.forEach((rowEle, rowIndex) => {
      this.totalColumns.forEach((colEle, columnIndex) => {
        var id = '';
        id = 'chartid-' + Math.floor(Math.random() * 10000) + 1;
        this.rowIndex.push(id);
        this.coordinate.push({
          'coord': String(rowIndex + 1) + ',' + String(columnIndex + 1),
          'chartId': id
        })
      });

      this.parameterizedDashboardRandomArray.push(this.rowIndex);
      this.rowIndex = [];
    });
    //console.log(this.requestPayloadParameterObj)
    setTimeout(() => {
      // removing unused chart divs and adjusting their width
      this.disable = true
      var totalRows = this.totalRows;
      var totalColumns = this.totalColumns;
      var totalObjCoords = [];
      var usedObjCoords = [];
      var totalWidgets = this.totalWidgets;

      totalRows.map(function (row, rowInd) {
        totalColumns.map(function (column, columnInd) {
          totalObjCoords.push(rowInd + 1 + ',' + (columnInd + 1));
        });
      });

      totalObjCoords.map(function (coord) {
        totalWidgets?.map(function (d) {
          if (
            Number(d.controlMasterId) == 7 ||
            Number(d.controlMasterId) == 22 ||
            Number(d.controlMasterId) == 23 ||
            Number(d.controlMasterId) == 25 ||
            Number(d.controlMasterId) == 26 ||
            Number(d.controlMasterId) == 27
          ) {
          } else {
            if (String(coord) == String(d.objectCordinates)) {
              usedObjCoords.push(coord);
            }
          }
        });
      });

      var eachWidget = d3.selectAll('.widget-class')[0];
      for (var widi = 0; widi < totalObjCoords.length; widi++) {
        if (
          usedObjCoords.indexOf(
            d3.select(eachWidget[widi]).attr('objectCordinates')
          ) == -1
        ) {
          d3.select(eachWidget[widi]).remove();
        }
      }
      this.coordinate.forEach((w) => {
        usedObjCoords.forEach((u) => {
          if (String(w['coord']) == String(u)) {
            this.downloadArray.push(w)
          }
        })
      })
      d3.selectAll('.tibo-mydash-paramdash-widget')[0].forEach(function (row) {
        if (d3.select(row).selectAll('div.widget-class')[0].length == 1) {
          d3.select(row)
            .selectAll('div.widget-class')
            .attr('class', 'widget-class expand-widget-class-1');
        } else if (
          d3.select(row).selectAll('div.widget-class')[0].length == 2
        ) {
          d3.select(row)
            .selectAll('div.widget-class')
            .attr('class', 'widget-class expand-widget-class-2');
        } else if (
          d3.select(row).selectAll('div.widget-class')[0].length == 3
        ) {
          d3.select(row)
            .selectAll('div.widget-class')
            .attr('class', 'widget-class expand-widget-class-3');
        }
      });
    }, 500); // removing unused chart divs and adjusting their width
    this.requestPayloadParameterObj.parameters.filter((item, i) => {
      if (item.name == "Date_Range") {
        if (item.value != "Other") {
          this.getFromThruDate(item.value);
          this.requestPayloadParameterObj.parameters.filter((e, i) => {
            if (e.name == this.dateRangeAvailable.startDateColumn) { delete this.requestPayloadParameterObj.parameters[i] }
            if (e.name == this.dateRangeAvailable.endDateColumn) { delete this.requestPayloadParameterObj.parameters[i] }
          })
          this.parameterElementsArray.filter((e) => {
            if (e.parameterName == this.dateRangeAvailable.startDateColumn) {
              let obj1 = {
                id: e.dashboardParameterId,
                isVisible: "Y",
                name: e.parameterName,
                questionId: e.questionOrderBy,
                value: this.selectedFromDate.toLocaleDateString('en-US', {
                  month: '2-digit',
                  day: '2-digit',
                  year: 'numeric'
                })
              }
              this.requestPayloadParameterObj.parameters.push(obj1)
            }
            if (e.parameterName == this.dateRangeAvailable.endDateColumn) {
              let obj2 = {
                id: e.dashboardParameterId,
                isVisible: "Y",
                name: e.parameterName,
                questionId: e.questionOrderBy,
                value: this.selectedEndDate.toLocaleDateString('en-US', {
                  month: '2-digit',
                  day: '2-digit',
                  year: 'numeric'
                })
              }
              this.requestPayloadParameterObj.parameters.push(obj2)
            }
          })
        }
      }
    })
    this.requestPayloadParameterObj.parameters = this.requestPayloadParameterObj.parameters.filter(item => item.name !== "Date_Range");
    this.totalWidgets?.forEach((widget, index) => {
      this.widSpin = widget.objectCordinates.replace(',', '')
      d3.select('#loader' + this.widSpin).style('display', 'block');
      if (widget.controlMasterId == 3) {
        this.getPrmDashWidgetDataSubscrip = this.dashService
          .getParameterizedDashboardWidgetData(
            widget.widgetControlId,
            'limit=&isWebservice=N',
            this.requestPayloadParameterObj
          )
          .subscribe(
            (widgetdetails: any) => {
              this.dashboardInfoObject.widgetControls[index][
                'eachWidgetResponse'
              ] = widgetdetails; // Storing each widget information for changing chart colors usage

              var chartElement = d3
                .select(
                  ".widget-class[objectCordinates='" +
                  widget.objectCordinates +
                  "']"
                )
                .attr('empty', false);
              this.table(chartElement, widget, widgetdetails);
              d3.select('#loader' + widget.objectCordinates.replace(',', '')).style('display', 'none');

            },
            (error) => console.log(error),
            () => this.finalCall()
          ); // getParameterizedDashboardWidgetData
      } else {
        this.getPrmDashWidgetDataSubscrip = this.dashService
          .getParameterizedDashboardWidgetData(
            widget.widgetControlId,
            'limit=10',
            this.requestPayloadParameterObj
          )
          .subscribe(
            (widgetdetails: any) => {
              this.dashboardInfoObject.widgetControls[index][
                'eachWidgetResponse'
              ] = widgetdetails; // Storing each widget information for changing chart colors usage

              //-------------------------------------------------------------------------------------------------

              if (widget.controlMasterId == 1) {
                var chartElement = d3
                  .select(
                    ".widget-class[objectCordinates='" +
                    widget.objectCordinates +
                    "']"
                  )
                  .attr('empty', false);
                this.pieChart(chartElement, widget, widgetdetails);
                d3.select('#loader' + widget.objectCordinates.replace(',', '')).style('display', 'none');

              } else if (widget.controlMasterId == 2) {
                var chartElement = d3
                  .select(
                    ".widget-class[objectCordinates='" +
                    widget.objectCordinates +
                    "']"
                  )
                  .attr('empty', false);
                this.columnChart(chartElement, widget, widgetdetails);
                d3.select('#loader' + widget.objectCordinates.replace(',', '')).style('display', 'none');

              } else if (widget.controlMasterId == 5) {
                /*else if(widget.controlMasterId == 3){
            var chartElement = d3.select(".widget-class[objectCordinates='" + widget.objectCordinates + "']")
            .attr("empty", false)
          	
            this.table(chartElement,widget,widgetdetails)
          }*/
                var chartElement = d3
                  .select(
                    ".widget-class[objectCordinates='" +
                    widget.objectCordinates +
                    "']"
                  )
                  .attr('empty', false);
                this.donutChart(chartElement, widget, widgetdetails);
                d3.select('#loader' + widget.objectCordinates.replace(',', '')).style('display', 'none');

              } else if (widget.controlMasterId == 6) {
                var chartElement = d3
                  .select(
                    ".widget-class[objectCordinates='" +
                    widget.objectCordinates +
                    "']"
                  )
                  .attr('empty', false);
                this.horizontalChart(chartElement, widget, widgetdetails);
                d3.select('#loader' + widget.objectCordinates.replace(',', '')).style('display', 'none');

              } else if (widget.controlMasterId == 7) {
              } // Waffle chart
              else if (widget.controlMasterId == 8) {
                var chartElement = d3
                  .select(
                    ".widget-class[objectCordinates='" +
                    widget.objectCordinates +
                    "']"
                  )
                  .attr('empty', false);
                this.perGauge(chartElement, widget, widgetdetails);
                d3.select('#loader' + widget.objectCordinates.replace(',', '')).style('display', 'none');

              } else if (widget.controlMasterId == 9) {
                var chartElement = d3
                  .select(
                    ".widget-class[objectCordinates='" +
                    widget.objectCordinates +
                    "']"
                  )
                  .attr('empty', false);
                this.numberGauge(chartElement, widget, widgetdetails);
                d3.select('#loader' + widget.objectCordinates.replace(',', '')).style('display', 'none');

              } else if (widget.controlMasterId == 10) {
                var chartElement = d3
                  .select(
                    ".widget-class[objectCordinates='" +
                    widget.objectCordinates +
                    "']"
                  )
                  .attr('empty', false);
                this.map(chartElement, widget, widgetdetails);
                d3.select('#loader' + widget.objectCordinates.replace(',', '')).style('display', 'none');

              } else if (widget.controlMasterId == 11) {
                var chartElement = d3
                  .select(
                    ".widget-class[objectCordinates='" +
                    widget.objectCordinates +
                    "']"
                  )
                  .attr('empty', false);
                this.columnAndLineChart(chartElement, widget, widgetdetails);
                d3.select('#loader' + widget.objectCordinates.replace(',', '')).style('display', 'none');

              } else if (widget.controlMasterId == 12) {
                var chartElement = d3
                  .select(
                    ".widget-class[objectCordinates='" +
                    widget.objectCordinates +
                    "']"
                  )
                  .attr('empty', false);
                this.lineChart(chartElement, widget, widgetdetails);
                d3.select('#loader' + widget.objectCordinates.replace(',', '')).style('display', 'none');

              } else if (widget.controlMasterId == 13) {
                var chartElement = d3
                  .select(
                    ".widget-class[objectCordinates='" +
                    widget.objectCordinates +
                    "']"
                  )
                  .attr('empty', false);
                this.bubbleChart(chartElement, widget, widgetdetails);
                d3.select('#loader' + widget.objectCordinates.replace(',', '')).style('display', 'none');

              } else if (widget.controlMasterId == 14) {
                var chartElement = d3
                  .select(
                    ".widget-class[objectCordinates='" +
                    widget.objectCordinates +
                    "']"
                  )
                  .attr('empty', false);
                this.stackedHorizontalBarChart(
                  chartElement,
                  widget,
                  widgetdetails
                );
                d3.select('#loader' + widget.objectCordinates.replace(',', '')).style('display', 'none');

              } else if (widget.controlMasterId == 15) {
                var chartElement = d3
                  .select(
                    ".widget-class[objectCordinates='" +
                    widget.objectCordinates +
                    "']"
                  )
                  .attr('empty', false);
                this.stackedBarChart(chartElement, widget, widgetdetails);
                d3.select('#loader' + widget.objectCordinates.replace(',', '')).style('display', 'none');

              } else if (widget.controlMasterId == 16) {
                var chartElement = d3
                  .select(
                    ".widget-class[objectCordinates='" +
                    widget.objectCordinates +
                    "']"
                  )
                  .attr('empty', false);
                this.perGaugeWithFlip(chartElement, widget, widgetdetails);
                d3.select('#loader' + widget.objectCordinates.replace(',', '')).style('display', 'none');

              } else if (widget.controlMasterId == 17) {
                var chartElement = d3
                  .select(
                    ".widget-class[objectCordinates='" +
                    widget.objectCordinates +
                    "']"
                  )
                  .attr('empty', false);
                this.squareGauge(chartElement, widget, widgetdetails);
                d3.select('#loader' + widget.objectCordinates.replace(',', '')).style('display', 'none');

              } else if (widget.controlMasterId == 18) {
                var chartElement = d3
                  .select(
                    ".widget-class[objectCordinates='" +
                    widget.objectCordinates +
                    "']"
                  )
                  .attr('empty', false);
                this.bellCurveChart(chartElement, widget, widgetdetails);
                d3.select('#loader' + widget.objectCordinates.replace(',', '')).style('display', 'none');

              } else if (widget.controlMasterId == 19) {
                var chartElement = d3
                  .select(
                    ".widget-class[objectCordinates='" +
                    widget.objectCordinates +
                    "']"
                  )
                  .attr('empty', false);
                this.areaChart(chartElement, widget, widgetdetails);
                d3.select('#loader' + widget.objectCordinates.replace(',', '')).style('display', 'none');

              } else if (widget.controlMasterId == 20) {
                var chartElement = d3
                  .select(
                    ".widget-class[objectCordinates='" +
                    widget.objectCordinates +
                    "']"
                  )
                  .attr('empty', false);
                this.groupedBarChart(chartElement, widget, widgetdetails);
                d3.select('#loader' + widget.objectCordinates.replace(',', '')).style('display', 'none');

              } else if (widget.controlMasterId == 21) {
                var chartElement = d3
                  .select(
                    ".widget-class[objectCordinates='" +
                    widget.objectCordinates +
                    "']"
                  )
                  .attr('empty', false);
                this.horizontalGroupedBarChart(
                  chartElement,
                  widget,
                  widgetdetails
                );
                d3.select('#loader' + widget.objectCordinates.replace(',', '')).style('display', 'none');

              } else if (widget.controlMasterId == 22) {
              } //Horizontal opposite bar Chart
              else if (widget.controlMasterId == 23) {
              } //Vertical opposite bar Chart
              else if (widget.controlMasterId == 24) {
                var chartElement = d3
                  .select(
                    ".widget-class[objectCordinates='" +
                    widget.objectCordinates +
                    "']"
                  )
                  .attr('empty', false);
                this.dotGauge(chartElement, widget, widgetdetails);
                d3.select('#loader' + widget.objectCordinates.replace(',', '')).style('display', 'none');

              } else if (widget.controlMasterId == 25) {
              } //Square bubble chart
              else if (widget.controlMasterId == 26) {
              } //Magic quadrant
              else if (widget.controlMasterId == 27) {
              } //Funnel chart
              else if (widget.controlMasterId == 28) {
                var chartElement = d3
                  .select(
                    ".widget-class[objectCordinates='" +
                    widget.objectCordinates +
                    "']"
                  )
                  .attr('empty', false);
                this.columnAndLinesChart(chartElement, widget, widgetdetails);
                d3.select('#loader' + widget.objectCordinates.replace(',', '')).style('display', 'none');

              } else if (widget.controlMasterId == 29) {
                var chartElement = d3
                  .select(
                    ".widget-class[objectCordinates='" +
                    widget.objectCordinates +
                    "']"
                  )
                  .attr('empty', false);
                this.threeXAxisesGroupedBarChart(
                  chartElement,
                  widget,
                  widgetdetails
                );
                d3.select('#loader' + widget.objectCordinates.replace(',', '')).style('display', 'none');

              } else if (widget.controlMasterId == 30) {
                var chartElement = d3
                  .select(
                    ".widget-class[objectCordinates='" +
                    widget.objectCordinates +
                    "']"
                  )
                  .attr('empty', false);
                this.twoXAxisesGroupedBarChart(
                  chartElement,
                  widget,
                  widgetdetails
                );
                d3.select('#loader' + widget.objectCordinates.replace(',', '')).style('display', 'none');

              } else if (widget.controlMasterId == 31) {
                var chartElement = d3
                  .select(
                    ".widget-class[objectCordinates='" +
                    widget.objectCordinates +
                    "']"
                  )
                  .attr('empty', false);
                this.bellCurveChartWithMinAndMaxRange(
                  chartElement,
                  widget,
                  widgetdetails
                );
                d3.select('#loader' + widget.objectCordinates.replace(',', '')).style('display', 'none');

              } else if (widget.controlMasterId == 32) {
                var chartElement = d3
                  .select(
                    ".widget-class[objectCordinates='" +
                    widget.objectCordinates +
                    "']"
                  )
                  .attr('empty', false);
                this.stackedGroupedBarChart(
                  chartElement,
                  widget,
                  widgetdetails
                );
                d3.select('#loader' + widget.objectCordinates.replace(',', '')).style('display', 'none');

              } else if (widget.controlMasterId == 33) {
                var chartElement = d3
                  .select(
                    ".widget-class[objectCordinates='" +
                    widget.objectCordinates +
                    "']"
                  )
                  .attr('empty', false);
                this.stackedGroupedBarsWithLineChart(
                  chartElement,
                  widget,
                  widgetdetails
                );
                d3.select('#loader' + widget.objectCordinates.replace(',', '')).style('display', 'none');

              } else if (widget.controlMasterId == 34) {
                var chartElement = d3
                  .select(
                    ".widget-class[objectCordinates='" +
                    widget.objectCordinates +
                    "']"
                  )
                  .attr('empty', false);
                this.stackedBarWithLinesChart(
                  chartElement,
                  widget,
                  widgetdetails
                );
                d3.select('#loader' + widget.objectCordinates.replace(',', '')).style('display', 'none');

              } else if (widget.controlMasterId == 35) {
                var chartElement = d3
                  .select(
                    ".widget-class[objectCordinates='" +
                    widget.objectCordinates +
                    "']"
                  )
                  .attr('empty', false);
                this.trafficLightsChart(chartElement, widget, widgetdetails);
                d3.select('#loader' + widget.objectCordinates.replace(',', '')).style('display', 'none');

              } else if (widget.controlMasterId == 36) {
                var chartElement = d3
                  .select(
                    ".widget-class[objectCordinates='" +
                    widget.objectCordinates +
                    "']"
                  )
                  .attr('empty', false);
                this.columnChartWithNegativeValues(
                  chartElement,
                  widget,
                  widgetdetails
                );
                d3.select('#loader' + widget.objectCordinates.replace(',', '')).style('display', 'none');

              } else if (widget.controlMasterId == 37) {
                var chartElement = d3
                  .select(
                    ".widget-class[objectCordinates='" +
                    widget.objectCordinates +
                    "']"
                  )
                  .attr('empty', false);
                this.groupedBarsWithLineChart(
                  chartElement,
                  widget,
                  widgetdetails
                );
                d3.select('#loader' + widget.objectCordinates.replace(',', '')).style('display', 'none');

              } else if (widget.controlMasterId == 38) {
                var chartElement = d3
                  .select(
                    ".widget-class[objectCordinates='" +
                    widget.objectCordinates +
                    "']"
                  )
                  .attr('empty', false);
                this.USMapWithPins(chartElement, widget, widgetdetails);
                d3.select('#loader' + widget.objectCordinates.replace(',', '')).style('display', 'none');

              }

              //-------------------------------------------------------------------------------------------------
            },
            (error) => console.log(error),
            () => this.finalCall()
          ); // getParameterizedDashboardWidgetData
      }

    }); // forEach
    setTimeout(() => {
      d3.selectAll('.widget-class').style('visibility', 'visible')
    }, 1000);
  }
  getFromThruDate(selectedValue) {
    const currentDate = new Date();
    switch (selectedValue) {
      // case "Other":
      //   this.parameterElementsArray.filter((item)=>{
      //     if(item.parameterName ==this.dateRangeAvailable.endDateColumn){
      //       item.isVisible="Y";
      //       item.isMandatory="Y";
      //       item.fetchedRecordsBool=true;
      //     }
      //       if( item.parameterName ==this.dateRangeAvailable.startDateColumn){
      //         item.isVisible="Y";
      //         item.isMandatory="Y";
      //         item.fetchedRecordsBool=true;
      //       }
      //   })
      //   //console.log(this.parameterElementsArray)
      //   break;
      case "Current Business Day":
        this.showOtherOption = false;
        this.startDate = currentDate;
        this.endDate = currentDate;
        break;
      case "Previous 3 Business Days":
        this.showOtherOption = false;
        const previous3BusinessDays = this.getPrevious3BusinessDays(currentDate);
        this.startDate = previous3BusinessDays[2];
        this.endDate = previous3BusinessDays[0];
        break;
      case "Previous 2 Business Days":
        this.showOtherOption = false;
        const previous2BusinessDays = this.getPrevious2BusinessDays(currentDate);
        this.startDate = previous2BusinessDays[1];
        this.endDate = previous2BusinessDays[0];
        break;
      case "Previous 3 Days":
        this.showOtherOption = false;
        const previous3Days = this.getPrevious3Days(currentDate);
        this.startDate = previous3Days[2];
        this.endDate = previous3Days[0];
        break;
      case "Previous 2 Days":
        this.showOtherOption = false;
        const previous2Days = this.getPrevious2Days(currentDate);
        this.startDate = previous2Days[1];
        this.endDate = previous2Days[0];
        break;
      case "Previous 3 Days Including Today":
        this.showOtherOption = false;
        const previous3DaysI = this.getPrevious3DaysIncludingToday(currentDate);
        this.startDate = previous3DaysI[2];
        this.endDate = previous3DaysI[0];
        break;
      case "Previous Business Day":
        this.showOtherOption = false;
        const previousBusinessDay = this.getPreviousBusinessDay(currentDate);
        this.startDate = previousBusinessDay;
        this.endDate = previousBusinessDay;
        break;
      case "Previous 7 Days":
        this.showOtherOption = false;
        const previous7Days = this.getPrevious7Days(currentDate);
        this.startDate = previous7Days[0];
        this.endDate = previous7Days[6];
        break;
      case "Previous 7 Days Including Today":
        this.showOtherOption = false;
        const previous7DaysI = this.getPrevious7DaysIncludingToday(currentDate);
        this.startDate = previous7DaysI[0];
        this.endDate = previous7DaysI[6];
        break;
      case "Previous 2 Weeks":
        this.showOtherOption = false;
        const previous2weeks = this.getPrevious2Weeks(currentDate);
        this.startDate = previous2weeks[0];
        this.endDate = previous2weeks[1];
        break;
      case "Previous 3 Weeks":
        this.showOtherOption = false;
        const previous3Weeks = this.getPrevious3Weeks(currentDate);
        this.startDate = previous3Weeks[0];
        this.endDate = previous3Weeks[1];
        break;
      case "Previous 4 Weeks":
        this.showOtherOption = false;
        const previous4Weeks = this.getPrevious4Weeks(currentDate);
        this.startDate = previous4Weeks[0];
        this.endDate = previous4Weeks[1];
        break;
      case "Month to Date":
        this.showOtherOption = false;
        const monthToDate = this.getMonthToDateRange(currentDate);
        this.startDate = monthToDate[0];
        this.endDate = monthToDate[1];
        break;
      case "Previous 30 Days":
        this.showOtherOption = false;
        const previous30days = this.getPrevious30DaysRange(currentDate);
        this.startDate = previous30days[0];
        this.endDate = previous30days[1];
        break;
      case "First Half Month(1st - 15th)":
        this.showOtherOption = false;
        const firstHalf = this.getCurrentFirstHalfMonthRange(currentDate);
        this.startDate = firstHalf[0];
        this.endDate = firstHalf[1];
        break;
      case "Second Half Month(16th - EOM)":
        this.showOtherOption = false;
        const secondHalfMonth = this.getCurrentSecondHalfMonthRange(currentDate);
        this.startDate = secondHalfMonth[0];
        this.endDate = secondHalfMonth[1];
        break;
      case "Previous Calendar Month":
        this.showOtherOption = false;
        const previousCalendarMonth = this.getPreviousCalendarMonthRange(currentDate);
        this.startDate = previousCalendarMonth[0];
        this.endDate = previousCalendarMonth[1];
        break;
      case "Previous 3 Months":
        this.showOtherOption = false;
        const previous3Months = this.getPrevious3Months(currentDate);
        this.startDate = previous3Months[2];
        this.endDate = previous3Months[0];
        break;
      case "Year To Date":
        const yearStartDate = new Date(currentDate.getFullYear(), 0, 1); // Start of the current year
        this.startDate = yearStartDate;
        this.endDate = currentDate;
        break;
      case "Year To Previous Month End":
        this.startDate = new Date(currentDate.getFullYear(), 0, 1); // Start of the current year
        this.endDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 0); // End of the previous month
        break;
      case "Last 12 Months Including This Month":
        this.endDate = currentDate; // End date is current date
        this.startDate = new Date(currentDate.getFullYear(), currentDate.getMonth() - 11, 1); // Start date is 12 months ago
        break;
      case "Last 12 Months":
        this.endDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 0); // Last day of the previous month
        this.startDate = new Date(this.endDate); // Start date is the end date initially
        this.startDate.setMonth(this.endDate.getMonth() - 11, 1); // Subtract 11 months to get the start date
        break;
      case "Previous 1 Year":
        this.showOtherOption = false;
        this.startDate = new Date(currentDate.getFullYear() - 1, 0, 1);
        this.endDate = new Date(currentDate.getFullYear() - 1, 11, 31);
        break;
      case "Last 365 Days Including Today":
        this.endDate = currentDate; // End date is current date
        this.startDate = new Date(currentDate.getTime() - (365 * 24 * 60 * 60 * 1000)); // Start date is 365 days ago
        break;

      case "Last 365 Days":
        const yesterday = new Date(currentDate);
        yesterday.setDate(currentDate.getDate() - 1);
        this.endDate = yesterday; // End date is yesterday
        this.startDate = new Date(this.endDate); // Start date is the end date initially
        this.startDate.setDate(this.endDate.getDate() - 365); // Subtract 365 days to get the start date
        break;
      default:
        break;
    }
    this.selectedFromDate = this.startDate;
    this.selectedEndDate = this.endDate;

    // }

  }
  getPrevious3BusinessDays(currentDate: Date): Date[] {
    const previousBusinessDays: Date[] = [];
    let count = 0;
    let previousDate = new Date(currentDate);
    while (count < 3) {
      previousDate.setDate(previousDate.getDate() - 1);
      if (previousDate.getDay() !== 0 && previousDate.getDay() !== 6) {
        previousBusinessDays.push(new Date(previousDate));
        count++;
      }
    }
    return previousBusinessDays;
  }
  getPrevious2BusinessDays(currentDate: Date): Date[] {
    const previousBusinessDays: Date[] = [];
    let count = 0;
    let previousDate = new Date(currentDate);
    while (count < 2) {
      previousDate.setDate(previousDate.getDate() - 1);
      if (previousDate.getDay() !== 0 && previousDate.getDay() !== 6) {
        previousBusinessDays.push(new Date(previousDate));
        count++;
      }
    }
    return previousBusinessDays;
  }
  getPrevious2Days(currentDate: Date): Date[] {
    const previousDays: Date[] = [];
    for (let i = 1; i <= 2; i++) {
      const previousDate = new Date(currentDate);
      previousDate.setDate(previousDate.getDate() - i); // Go back i days
      previousDays.push(previousDate);
    }
    return previousDays;
  }
  getPrevious3Days(currentDate) {
    const previousDays = [];
    for (let i = 0; i < 3; i++) {
      const previousDate = new Date(currentDate);
      previousDate.setDate(previousDate.getDate() - i - 1); // Go back i+1 days
      previousDays.push(previousDate);
    }
    return previousDays;
  }
  getPrevious3DaysIncludingToday(currentDate: Date): Date[] {
    const previous3DaysIncludingToday = [];
    for (let i = 0; i < 3; i++) {
      const previousDate = new Date(currentDate);
      previousDate.setDate(previousDate.getDate() - i); // Go back i days
      previous3DaysIncludingToday.push(previousDate);
    }
    return previous3DaysIncludingToday;
  }
  getPrevious3Months(currentDate) {
    const previousMonths = [];
    for (let i = 1; i < 4; i++) {
      const previousMonth = new Date(currentDate);
      previousMonth.setMonth(previousMonth.getMonth() - i); // Go back i months
      previousMonths.push(previousMonth);
    }
    return previousMonths;
  }
  getPreviousBusinessDay(currentDate: Date): Date {
    const previousDay = new Date(currentDate);
    do {
      previousDay.setDate(previousDay.getDate() - 1); // Move one day back
    } while (previousDay.getDay() === 0 || previousDay.getDay() === 6); // Skip Sunday (0) and Saturday (6)
    return previousDay;
  }
  getPrevious7Days(currentDate) {
    const previousDays = [];
    for (let i = 0; i < 7; i++) {
      const previousDate = new Date(currentDate);
      previousDate.setDate(previousDate.getDate() - i - 1);
      previousDays.push(previousDate);
    }
    return previousDays.reverse();
  }
  getPrevious7DaysIncludingToday(currentDate) {
    const previousDays = [];
    for (let i = 0; i < 7; i++) {
      const previousDate = new Date(currentDate);
      previousDate.setDate(previousDate.getDate() - i);
      previousDays.push(previousDate);
    }
    return previousDays.reverse();
  }
  getPrevious2Weeks(currentDate: Date): [Date, Date] {
    const endDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - (currentDate.getDay() + 1));
    const startDate = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate() - 13); // 3 weeks = 21 days
    return [startDate, endDate];
  } // Reverse the array to get the dates in ascending order

  getPrevious3Weeks(currentDate: Date): [Date, Date] {
    const endDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - (currentDate.getDay() + 1));
    const startDate = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate() - 20); // 3 weeks = 21 days
    return [startDate, endDate];
  }
  getPrevious4Weeks(currentDate: Date): [Date, Date] {
    const endDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - (currentDate.getDay() + 1));
    const startDate = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate() - 27); // 4 weeks = 28 days
    return [startDate, endDate];
  }
  getMonthToDateRange(currentDate: Date): [Date, Date] {
    const startDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    const endDate = new Date(currentDate);
    endDate.setHours(23, 59, 59, 999);
    return [startDate, endDate];
  }
  getPrevious30DaysRange(currentDate: Date): [Date, Date] {
    const endDate = new Date(currentDate);
    const startDate = new Date(currentDate);
    startDate.setDate(startDate.getDate() - 30);
    return [startDate, endDate];
  }
  getCurrentFirstHalfMonthRange(currentDate: Date): [Date, Date] {
    const startDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1); // First day of the current month
    const endDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 15); // 15th day of the current month
    return [startDate, endDate];
  }
  getCurrentSecondHalfMonthRange(currentDate: Date): [Date, Date] {
    const startDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    const lastDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
    const middleOfMonth = Math.ceil(lastDayOfMonth.getDate() / 2);
    const startDateSecondHalf = new Date(currentDate.getFullYear(), currentDate.getMonth(), middleOfMonth);
    const endDate = lastDayOfMonth;
    return [startDateSecondHalf, endDate];
  }
  getPreviousCalendarMonthRange(currentDate: Date): [Date, Date] {
    const firstDayOfCurrentMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    const endDate = new Date(firstDayOfCurrentMonth);
    endDate.setDate(endDate.getDate() - 1);
    const startDate = new Date(endDate.getFullYear(), endDate.getMonth(), 1);

    return [startDate, endDate];
  }
  getPrevious3MonthsRange(currentDate: Date): [Date, Date] {
    const firstDayOfCurrentMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    const startMonth = firstDayOfCurrentMonth.getMonth() - 3;
    const startYear = firstDayOfCurrentMonth.getFullYear();
    const startDate = new Date(startYear, startMonth, 1);
    const endDate = new Date(firstDayOfCurrentMonth);
    endDate.setDate(endDate.getDate() - 1);

    return [startDate, endDate];
  }
  getPreviousQuarter(currentDate: Date): [Date, Date] {
    const currentMonth = currentDate.getMonth();
    const quarterStartMonth = Math.floor(currentMonth / 3) * 3; // Start month of the current quarter
    const previousQuarterStartMonth = quarterStartMonth - 3 >= 0 ? quarterStartMonth - 3 : 9; // Start month of the previous quarter
    const previousQuarterStartDate = new Date(currentDate.getFullYear(), previousQuarterStartMonth, 1);
    const previousQuarterEndDate = new Date(currentDate.getFullYear(), quarterStartMonth, 0);
    return [previousQuarterStartDate, previousQuarterEndDate];
  }
  onFilterSubmitClick() {

    var showAlertBool: Boolean;

    this.parameterElementsArray.forEach((ele) => {
      //if()
      if (ele.isMandatory == 'Y' && ele.paramElementFilledBool != true) {
        showAlertBool = true;
        // this.errorMsg="please fill mandatory field"
        // this.addSingle();
        // alert("please fill mandatory field");
      }
    })

    if (showAlertBool == true) {
      // this.addMessages();
      this.errorMsg = "please fill mandatory field"
      this.addSingle();
    } else {
      this.clearMessages();
      console.log( this.payloadParameter,this.requestPayloadParametersArray, this.requestPayloadParameterObj.parameters)
      this.requestPayloadParameterObj.parameters = this.payloadParameter;
      //this.disable=true
      this.dashboard();
      //if (this.routingComp == 'dashboard-designer'){this.fullscreen()}
    }
    //   acinfotech.requestType(true); // Letting chart library know it's a dashboard module call and empty all stored divs id and chart div elements
    //   this.isLoading = true;
    //   //d3.select('.tibo-mydash-dash-spinner').style('display', 'block'); // showing dashboard spinner

    //   //  d3.selectAll("#parameterized-dashboard-filter-panel-scroll-div, #submitbutton-id").transition().delay(200).duration(400).style("display","none")
    //   // d3.select("#dashboard-parameterized-filter").transition().delay(200).duration(500).style("width","40px")
    //   // d3.select("#dashboard-parameterized-scroll-view").transition().delay(200).duration(500).style("left","50px")//.style("width","calc(100% - 50px)")
    //   // d3.select("#dashboard-parameterized-filter").select("button").style("transform","rotate(180deg)")
    //   // d3.select("#filter-id").transition().delay(100).duration(100)
    //   // .style({'left':'3px','top':'50px'}).style("transform","rotate(-90deg)")
    //   this.filterPanelToggleBool = true;

    //   this.parameterizedDashboardRandomArray = [];
    //   this.finalCallCounter = 0;
    //   this.totalRows.forEach((rowEle, rowIndex) => {
    //     this.totalColumns.forEach((colEle, columnIndex) => {
    //       this.rowIndex.push(
    //         'chartid-' + Math.floor(Math.random() * 10000) + 1
    //       );
    //     });
    //     this.parameterizedDashboardRandomArray.push(this.rowIndex);
    //     this.rowIndex = [];
    //   });

    //   setTimeout(() => {
    //     // removing unused chart divs and adjusting their width

    //     var totalRows = this.totalRows;
    //     var totalColumns = this.totalColumns;
    //     var totalObjCoords = [];
    //     var usedObjCoords = [];
    //     var totalWidgets = this.totalWidgets;

    //     totalRows.map(function (row, rowInd) {
    //       totalColumns.map(function (column, columnInd) {
    //         totalObjCoords.push(rowInd + 1 + ',' + (columnInd + 1));
    //       });
    //     });

    //     totalObjCoords.map(function (coord) {
    //       totalWidgets.map(function (d) {
    //         if (
    //           Number(d.controlMasterId) == 7 ||
    //           Number(d.controlMasterId) == 22 ||
    //           Number(d.controlMasterId) == 23 ||
    //           Number(d.controlMasterId) == 25 ||
    //           Number(d.controlMasterId) == 26 ||
    //           Number(d.controlMasterId) == 27
    //         ) {
    //         } else {
    //           if (String(coord) == String(d.objectCordinates)) {
    //             usedObjCoords.push(coord);
    //           }
    //         }
    //       });
    //     });

    //     var eachWidget = d3.selectAll('.widget-class')[0];
    //     for (var widi = 0; widi < totalObjCoords.length; widi++) {
    //       if (
    //         usedObjCoords.indexOf(
    //           d3.select(eachWidget[widi]).attr('objectCordinates')
    //         ) == -1
    //       ) {
    //         d3.select(eachWidget[widi]).remove();
    //       }
    //     }

    //     d3.selectAll('.tibo-mydash-paramdash-widget')[0].forEach(function (
    //       row
    //     ) {
    //       if (d3.select(row).selectAll('div.widget-class')[0].length == 1) {
    //         d3.select(row)
    //           .selectAll('div.widget-class')
    //           .attr('class', 'widget-class expand-widget-class-1');
    //       } else if (
    //         d3.select(row).selectAll('div.widget-class')[0].length == 2
    //       ) {
    //         d3.select(row)
    //           .selectAll('div.widget-class')
    //           .attr('class', 'widget-class expand-widget-class-2');
    //       } else if (
    //         d3.select(row).selectAll('div.widget-class')[0].length == 3
    //       ) {
    //         d3.select(row)
    //           .selectAll('div.widget-class')
    //           .attr('class', 'widget-class expand-widget-class-3');
    //       }
    //     });
    //   }, 1000); // removing unused chart divs and adjusting their width

    //   this.totalWidgets.forEach((widget, index) => {
    //     if (widget.controlMasterId == 3) {
    //     this.getPrmDashWidgetDataSubscrip = this.dashService
    //     .getParameterizedDashboardWidgetData(
    //       widget.widgetControlId,
    //       'limit=&isWebservice=N',
    //       this.requestPayloadParameterObj
    //     )
    //     .subscribe(
    //       (widgetdetails: any) => {
    //         this.dashboardInfoObject.widgetControls[index][
    //           'eachWidgetResponse'
    //         ] = widgetdetails; // Storing each widget information for changing chart colors usage

    //         var chartElement = d3
    //           .select(
    //             ".widget-class[objectCordinates='" +
    //             widget.objectCordinates +
    //             "']"
    //           )
    //           .attr('empty', false);
    //         d3.select('#loader' + widget.objectCordinates.replace(',', '')).style('display', 'none');
    //         this.table(chartElement, widget, widgetdetails);
    //       },
    //       (error) => //console.log(error),
    //       // () => this.finalCall()
    //     ); // getParameterizedDashboardWidgetData
    // } else {
    //   this.getPrmDashWidgetDataSubscrip = this.dashService
    //     .getParameterizedDashboardWidgetData(
    //       widget.widgetControlId,
    //       'limit=10',
    //       this.requestPayloadParameterObj
    //     )
    //     .subscribe(
    //       (widgetdetails: any) => {
    //         this.dashboardInfoObject.widgetControls[index][
    //           'eachWidgetResponse'
    //         ] = widgetdetails; // Storing each widget information for changing chart colors usage

    //         //-------------------------------------------------------------------------------------------------

    //         if (widget.controlMasterId == 1) {
    //           var chartElement = d3
    //             .select(
    //               ".widget-class[objectCordinates='" +
    //               widget.objectCordinates +
    //               "']"
    //             )
    //             .attr('empty', false);
    //           d3.select('#loader' + widget.objectCordinates.replace(',', '')).style('display', 'none');
    //           this.pieChart(chartElement, widget, widgetdetails);
    //         } else if (widget.controlMasterId == 2) {
    //           var chartElement = d3
    //             .select(
    //               ".widget-class[objectCordinates='" +
    //               widget.objectCordinates +
    //               "']"
    //             )
    //             .attr('empty', false);
    //           d3.select('#loader' + widget.objectCordinates.replace(',', '')).style('display', 'none');
    //           this.columnChart(chartElement, widget, widgetdetails);
    //         } else if (widget.controlMasterId == 5) {
    //           /*else if(widget.controlMasterId == 3){
    //       var chartElement = d3.select(".widget-class[objectCordinates='" + widget.objectCordinates + "']")
    //       .attr("empty", false)

    //       this.table(chartElement,widget,widgetdetails)
    //     }*/
    //           var chartElement = d3
    //             .select(
    //               ".widget-class[objectCordinates='" +
    //               widget.objectCordinates +
    //               "']"
    //             )
    //             .attr('empty', false);
    //           d3.select('#loader' + widget.objectCordinates.replace(',', '')).style('display', 'none');
    //           this.donutChart(chartElement, widget, widgetdetails);
    //         } else if (widget.controlMasterId == 6) {
    //           var chartElement = d3
    //             .select(
    //               ".widget-class[objectCordinates='" +
    //               widget.objectCordinates +
    //               "']"
    //             )
    //             .attr('empty', false);
    //           d3.select('#loader' + widget.objectCordinates.replace(',', '')).style('display', 'none');
    //           this.horizontalChart(chartElement, widget, widgetdetails);
    //         } else if (widget.controlMasterId == 7) {
    //         } // Waffle chart
    //         else if (widget.controlMasterId == 8) {
    //           var chartElement = d3
    //             .select(
    //               ".widget-class[objectCordinates='" +
    //               widget.objectCordinates +
    //               "']"
    //             )
    //             .attr('empty', false);
    //           d3.select('#loader' + widget.objectCordinates.replace(',', '')).style('display', 'none');
    //           this.perGauge(chartElement, widget, widgetdetails);
    //         } else if (widget.controlMasterId == 9) {
    //           var chartElement = d3
    //             .select(
    //               ".widget-class[objectCordinates='" +
    //               widget.objectCordinates +
    //               "']"
    //             )
    //             .attr('empty', false);
    //           d3.select('#loader' + widget.objectCordinates.replace(',', '')).style('display', 'none');
    //           this.numberGauge(chartElement, widget, widgetdetails);
    //         } else if (widget.controlMasterId == 10) {
    //           var chartElement = d3
    //             .select(
    //               ".widget-class[objectCordinates='" +
    //               widget.objectCordinates +
    //               "']"
    //             )
    //             .attr('empty', false);
    //           d3.select('#loader' + widget.objectCordinates.replace(',', '')).style('display', 'none');
    //           this.map(chartElement, widget, widgetdetails);
    //         } else if (widget.controlMasterId == 11) {
    //           var chartElement = d3
    //             .select(
    //               ".widget-class[objectCordinates='" +
    //               widget.objectCordinates +
    //               "']"
    //             )
    //             .attr('empty', false);
    //           d3.select('#loader' + widget.objectCordinates.replace(',', '')).style('display', 'none');
    //           this.columnAndLineChart(chartElement, widget, widgetdetails);
    //         } else if (widget.controlMasterId == 12) {
    //           var chartElement = d3
    //             .select(
    //               ".widget-class[objectCordinates='" +
    //               widget.objectCordinates +
    //               "']"
    //             )
    //             .attr('empty', false);
    //           d3.select('#loader' + widget.objectCordinates.replace(',', '')).style('display', 'none');
    //           this.lineChart(chartElement, widget, widgetdetails);
    //         } else if (widget.controlMasterId == 13) {
    //           var chartElement = d3
    //             .select(
    //               ".widget-class[objectCordinates='" +
    //               widget.objectCordinates +
    //               "']"
    //             )
    //             .attr('empty', false);
    //           d3.select('#loader' + widget.objectCordinates.replace(',', '')).style('display', 'none');
    //           this.bubbleChart(chartElement, widget, widgetdetails);
    //         } else if (widget.controlMasterId == 14) {
    //           var chartElement = d3
    //             .select(
    //               ".widget-class[objectCordinates='" +
    //               widget.objectCordinates +
    //               "']"
    //             )
    //             .attr('empty', false);
    //           d3.select('#loader' + widget.objectCordinates.replace(',', '')).style('display', 'none');
    //           this.stackedHorizontalBarChart(
    //             chartElement,
    //             widget,
    //             widgetdetails
    //           );
    //         } else if (widget.controlMasterId == 15) {
    //           var chartElement = d3
    //             .select(
    //               ".widget-class[objectCordinates='" +
    //               widget.objectCordinates +
    //               "']"
    //             )
    //             .attr('empty', false);
    //           d3.select('#loader' + widget.objectCordinates.replace(',', '')).style('display', 'none');
    //           this.stackedBarChart(chartElement, widget, widgetdetails);
    //         } else if (widget.controlMasterId == 16) {
    //           var chartElement = d3
    //             .select(
    //               ".widget-class[objectCordinates='" +
    //               widget.objectCordinates +
    //               "']"
    //             )
    //             .attr('empty', false);
    //           d3.select('#loader' + widget.objectCordinates.replace(',', '')).style('display', 'none');
    //           this.perGaugeWithFlip(chartElement, widget, widgetdetails);
    //         } else if (widget.controlMasterId == 17) {
    //           var chartElement = d3
    //             .select(
    //               ".widget-class[objectCordinates='" +
    //               widget.objectCordinates +
    //               "']"
    //             )
    //             .attr('empty', false);
    //           d3.select('#loader' + widget.objectCordinates.replace(',', '')).style('display', 'none');
    //           this.squareGauge(chartElement, widget, widgetdetails);
    //         } else if (widget.controlMasterId == 18) {
    //           var chartElement = d3
    //             .select(
    //               ".widget-class[objectCordinates='" +
    //               widget.objectCordinates +
    //               "']"
    //             )
    //             .attr('empty', false);
    //           d3.select('#loader' + widget.objectCordinates.replace(',', '')).style('display', 'none');
    //           this.bellCurveChart(chartElement, widget, widgetdetails);
    //         } else if (widget.controlMasterId == 19) {
    //           var chartElement = d3
    //             .select(
    //               ".widget-class[objectCordinates='" +
    //               widget.objectCordinates +
    //               "']"
    //             )
    //             .attr('empty', false);
    //           d3.select('#loader' + widget.objectCordinates.replace(',', '')).style('display', 'none');
    //           this.areaChart(chartElement, widget, widgetdetails);
    //         } else if (widget.controlMasterId == 20) {
    //           var chartElement = d3
    //             .select(
    //               ".widget-class[objectCordinates='" +
    //               widget.objectCordinates +
    //               "']"
    //             )
    //             .attr('empty', false);
    //           d3.select('#loader' + widget.objectCordinates.replace(',', '')).style('display', 'none');
    //           this.groupedBarChart(chartElement, widget, widgetdetails);
    //         } else if (widget.controlMasterId == 21) {
    //           var chartElement = d3
    //             .select(
    //               ".widget-class[objectCordinates='" +
    //               widget.objectCordinates +
    //               "']"
    //             )
    //             .attr('empty', false);
    //           d3.select('#loader' + widget.objectCordinates.replace(',', '')).style('display', 'none');
    //           this.horizontalGroupedBarChart(
    //             chartElement,
    //             widget,
    //             widgetdetails
    //           );
    //         } else if (widget.controlMasterId == 22) {
    //         } //Horizontal opposite bar Chart
    //         else if (widget.controlMasterId == 23) {
    //         } //Vertical opposite bar Chart
    //         else if (widget.controlMasterId == 24) {
    //           var chartElement = d3
    //             .select(
    //               ".widget-class[objectCordinates='" +
    //               widget.objectCordinates +
    //               "']"
    //             )
    //             .attr('empty', false);
    //           d3.select('#loader' + widget.objectCordinates.replace(',', '')).style('display', 'none');
    //           this.dotGauge(chartElement, widget, widgetdetails);
    //         } else if (widget.controlMasterId == 25) {
    //         } //Square bubble chart
    //         else if (widget.controlMasterId == 26) {
    //         } //Magic quadrant
    //         else if (widget.controlMasterId == 27) {
    //         } //Funnel chart
    //         else if (widget.controlMasterId == 28) {
    //           var chartElement = d3
    //             .select(
    //               ".widget-class[objectCordinates='" +
    //               widget.objectCordinates +
    //               "']"
    //             )
    //             .attr('empty', false);
    //           d3.select('#loader' + widget.objectCordinates.replace(',', '')).style('display', 'none');
    //           this.columnAndLinesChart(chartElement, widget, widgetdetails);
    //         } else if (widget.controlMasterId == 29) {
    //           var chartElement = d3
    //             .select(
    //               ".widget-class[objectCordinates='" +
    //               widget.objectCordinates +
    //               "']"
    //             )
    //             .attr('empty', false);
    //           d3.select('#loader' + widget.objectCordinates.replace(',', '')).style('display', 'none');
    //           this.threeXAxisesGroupedBarChart(
    //             chartElement,
    //             widget,
    //             widgetdetails
    //           );
    //         } else if (widget.controlMasterId == 30) {
    //           var chartElement = d3
    //             .select(
    //               ".widget-class[objectCordinates='" +
    //               widget.objectCordinates +
    //               "']"
    //             )
    //             .attr('empty', false);
    //           d3.select('#loader' + widget.objectCordinates.replace(',', '')).style('display', 'none');
    //           this.twoXAxisesGroupedBarChart(
    //             chartElement,
    //             widget,
    //             widgetdetails
    //           );
    //         } else if (widget.controlMasterId == 31) {
    //           var chartElement = d3
    //             .select(
    //               ".widget-class[objectCordinates='" +
    //               widget.objectCordinates +
    //               "']"
    //             )
    //             .attr('empty', false);
    //           d3.select('#loader' + widget.objectCordinates.replace(',', '')).style('display', 'none');
    //           this.bellCurveChartWithMinAndMaxRange(
    //             chartElement,
    //             widget,
    //             widgetdetails
    //           );
    //         } else if (widget.controlMasterId == 32) {
    //           var chartElement = d3
    //             .select(
    //               ".widget-class[objectCordinates='" +
    //               widget.objectCordinates +
    //               "']"
    //             )
    //             .attr('empty', false);
    //           d3.select('#loader' + widget.objectCordinates.replace(',', '')).style('display', 'none');
    //           this.stackedGroupedBarChart(
    //             chartElement,
    //             widget,
    //             widgetdetails
    //           );
    //         } else if (widget.controlMasterId == 33) {
    //           var chartElement = d3
    //             .select(
    //               ".widget-class[objectCordinates='" +
    //               widget.objectCordinates +
    //               "']"
    //             )
    //             .attr('empty', false);
    //           d3.select('#loader' + widget.objectCordinates.replace(',', '')).style('display', 'none');
    //           this.stackedGroupedBarsWithLineChart(
    //             chartElement,
    //             widget,
    //             widgetdetails
    //           );
    //         } else if (widget.controlMasterId == 34) {
    //           var chartElement = d3
    //             .select(
    //               ".widget-class[objectCordinates='" +
    //               widget.objectCordinates +
    //               "']"
    //             )
    //             .attr('empty', false);
    //           d3.select('#loader' + widget.objectCordinates.replace(',', '')).style('display', 'none');
    //           this.stackedBarWithLinesChart(
    //             chartElement,
    //             widget,
    //             widgetdetails
    //           );
    //         } else if (widget.controlMasterId == 35) {
    //           var chartElement = d3
    //             .select(
    //               ".widget-class[objectCordinates='" +
    //               widget.objectCordinates +
    //               "']"
    //             )
    //             .attr('empty', false);
    //           d3.select('#loader' + widget.objectCordinates.replace(',', '')).style('display', 'none');
    //           this.trafficLightsChart(chartElement, widget, widgetdetails);
    //         } else if (widget.controlMasterId == 36) {
    //           var chartElement = d3
    //             .select(
    //               ".widget-class[objectCordinates='" +
    //               widget.objectCordinates +
    //               "']"
    //             )
    //             .attr('empty', false);
    //           d3.select('#loader' + widget.objectCordinates.replace(',', '')).style('display', 'none');
    //           this.columnChartWithNegativeValues(
    //             chartElement,
    //             widget,
    //             widgetdetails
    //           );
    //         } else if (widget.controlMasterId == 37) {
    //           var chartElement = d3
    //             .select(
    //               ".widget-class[objectCordinates='" +
    //               widget.objectCordinates +
    //               "']"
    //             )
    //             .attr('empty', false);
    //           d3.select('#loader' + widget.objectCordinates.replace(',', '')).style('display', 'none');
    //           this.groupedBarsWithLineChart(
    //             chartElement,
    //             widget,
    //             widgetdetails
    //           );
    //         } else if (widget.controlMasterId == 38) {
    //           var chartElement = d3
    //             .select(
    //               ".widget-class[objectCordinates='" +
    //               widget.objectCordinates +
    //               "']"
    //             )
    //             .attr('empty', false);
    //           d3.select('#loader' + widget.objectCordinates.replace(',', '')).style('display', 'none');
    //           this.USMapWithPins(chartElement, widget, widgetdetails);
    //         }
    //             //-------------------------------------------------------------------------------------------------
    //           },
    //           (error) => //console.log(error),
    //           () => this.finalCall()
    //         ); // getParameterizedDashboardWidgetData
    //     }
    //   }); // forEach
    // }

    // this.selectDate = null;
  } //onFilterSubmitClick func

  filterPanelToggle() {
    if (this.filterPanelToggleBool == false) {
      d3.selectAll(
        '#parameterized-dashboard-filter-panel-scroll-div, #submitbutton-id'
      )
        .transition()
        .delay(200)
        .duration(400)
        .style('display', 'none');
      d3.select('#dashboard-parameterized-filter')
        .transition()
        .delay(200)
        .duration(500)
        .style('width', '40px');
      // d3.select("#dashboard-parameterized-scroll-view").transition().delay(200).duration(500).style("left","50px")//.style("width","calc(100% - 50px)")
      d3.select('#dashboard-parameterized-filter')
        .select('button')
        .style('transform', 'rotate(180deg)');
      d3.select('#filter-id')
        .transition()
        .delay(100)
        .duration(100)
        .style({ left: '3px', top: '50px' })
        .style('transform', 'rotate(-90deg)');
      this.filterPanelToggleBool = true;
    } else {
      d3.select('#parameterized-dashboard-filter-panel-scroll-div')
        .transition()
        .delay(300)
        .duration(400)
        .style('display', 'block');
      d3.select('#submitbutton-id')
        .transition()
        .delay(500)
        .duration(400)
        .style('display', 'block');
      d3.select('#dashboard-parameterized-filter')
        .transition()
        .delay(200)
        .duration(500)
        .style('width', '350px');
      // d3.select("#dashboard-parameterized-scroll-view").transition().delay(200).duration(500).style("left","360px")//.style("width","calc(100% - 360px)")
      d3.select('#dashboard-parameterized-filter')
        .select('button')
        .style('transform', 'rotate(0deg)');
      d3.select('#filter-id')
        .transition()
        .delay(250)
        .duration(100)
        .style({ left: '10px', top: '10px' })
        .style('transform', 'rotate(0deg)');
      this.filterPanelToggleBool = false;
    }
  }

  addMessages() {
    this.alertMessage = [
      {
        severity: 'error',
        summary: 'Error',
        detail: 'Please fill all required fields.',
      },
    ];
    this.addSingle()
  }

  clearMessages() {
    this.alertMessage = [];
  }

  //---------------------------------------------------------------------------------------------
  // getcolor(event){
  // 	this.showClass=true;
  // 	if(event == "#CFD8DC"){
  // 		this.value=2;
  // 	}
  // 	else{
  // 		this.value=1;
  // 		this.backColor=event;
  // 		this.wigetBackgroundColor = this.backColor;
  // 	}

  // 	this.reloadDashboardWithNewColorSetFunc();
  // }
  getcolor(event, index) {
    this.selectedColor = event;
    this.buttonIndex = index;
    this.showClass = true;
    if (event == this.colorOne) {
      this.value = 2;
      this.allColorButton = true;
    } else {
      this.value = 1;
      this.backColor = event;
      // d3.selectAll('.widget-class').style('background-color',this.backColor)
      this.wigetBackgroundColor = this.backColor;
      this.allColorButton = false;
    }
    this.reloadDashboardWithNewColorSetFunc();
  }

  pieChart(chartElement, widget, widgetdetails) {
    let fetchedData =
      widgetdetails.body.records == null
        ? 'No Records!'
        : widgetdetails.body.records.length > 0
          ? widgetdetails.body.records
          : 'No Records!';

    if (fetchedData == 'No Records!') {
      chartElement
        .append('div')
        .style({
          position: 'absolute',
          left: '0%',
          top: '0%',
          width: '100%',
          height: '8%',
          'background-color': this.wigetBackgroundColor,
          'text-indent': '6px',
        })
        .html(
          '<div  style="width: 100%; height:0%; background-color:{{this.wigetBackgroundColor}}; position: absolute; top:10.0%; font-size:16px; font-family:inter; color:#00162E;">' +
          widget.chartTitle +
          '</div>'
        );

      chartElement
        .append('label')
        .style({
          position: 'absolute',
          left: '0%',
          top: '45%',
          width: '100%',
          'font-family': 'inter',
          'font-size': '30px',
          color: '#f16464',
          'text-align': 'center',
        })
        .text('No Records!');
    } else {
      if (this.value == 1) {
        this.wigetBackgroundColor = this.backColor;
        // if(this.selectedChartsColorSet == "one"){
        // 	this.wigetBackgroundColor = '#ffffff';
        // }
        // if(this.selectedChartsColorSet == "two"){
        // 	this.wigetBackgroundColor = '#fff2cc';
        // }
        // if(this.selectedChartsColorSet == "three"){
        // 	this.wigetBackgroundColor = '#cfe2f3';
        // }
      }
      if (this.value == 2) {
        this.wigetBackgroundColor = '#CFE2F3';
      }
      let xAxisFormatted = '';
      if (
        widget.yAxis.charAt(0) == '[' &&
        widget.yAxis.charAt(widget.yAxis.length - 1) == ']'
      ) {
        xAxisFormatted = JSON.parse(widget.yAxis)[0];
      } else {
        xAxisFormatted = widget.yAxis;
      }

      let yAxisFormatted = '';
      if (
        widget.xAxis.charAt(0) == '[' &&
        widget.xAxis.charAt(widget.xAxis.length - 1) == ']'
      ) {
        yAxisFormatted = JSON.parse(widget.xAxis)[0];
      } else {
        yAxisFormatted = widget.xAxis;
      }
      var ShowLegend = false;
      if (widget.legendYesNo == 'Y') { ShowLegend = true }
      else if (widget.legendYesNo == 'N') { ShowLegend = false }
      let options = {
        // data: widgetdetails.body.records,//widgetdetails.body?.groupedQuery?.records ?  widgetdetails.body?.groupedQuery?.records : widgetdetails.body.records, // for chart
        // dataForTable: [],//widgetdetails.body?.groupedQuery?.records, //[], // for table if there is no data send empty array ([])
        data: widgetdetails.body?.groupedQuery?.records ? widgetdetails.body?.groupedQuery?.records : widgetdetails.body.records,
        dataForTable: widgetdetails.body?.records,
        isDataDetailedForTable: false,
        keys: {
          label: xAxisFormatted,
          value: yAxisFormatted,
        },
        chartTitle: widget.chartTitle,
        showTitle: true,
        showLegend: ShowLegend,
        legendSide: 'right', //"right" or "left"
        colorSet: this.selectedChartsColorSet,
        series: this.seriesVal,
        legend: this.legendvalue,
        BGcolor: this.wigetBackgroundColor, //"one" or "two" or "three"
        gradient: 'none', //"none" or "smooth" or "elliptical" or "circular" or "radial"
        drillDownColumnName: widget.drillThru[0]?.drillDownColumn
          ? widget.drillThru[0].drillDownColumn
          : '',
        showTableIcon: true, //false - hide, true - show
        showTable: false, //false - hide, true - show
        showComments: false,
        comments: widget.comments,
        resizeButtonID: document.getElementById('resizeBtn').id,
      };

      setTimeout(() => {
        acinfotech.pieChart(chartElement, options);
      }, 1000);
    }
  }
  columnChart(chartElement, widget, widgetdetails) {

    let fetchedData =
      widgetdetails.body.records == null
        ? 'No Records!'
        : widgetdetails.body.records.length > 0
          ? widgetdetails.body.records
          : 'No Records!';

    if (fetchedData == 'No Records!') {
      chartElement
        .append('div')
        .style({
          position: 'absolute',
          left: '0%',
          top: '0%',
          width: '100%',
          height: '8%',
          'background-color': this.wigetBackgroundColor,
          'text-indent': '6px',
        })
        .html(
          '<div  style="width: 100%; height:0%; background-color:{{ this.wigetBackgroundColor}}; position: absolute; top:10.0%; font-size:16px; font-family:inter; color:#00162E;">' +
          widget.chartTitle +
          '</div>'
        );

      chartElement
        .append('label')
        .style({
          position: 'absolute',
          left: '0%',
          top: '45%',
          width: '100%',
          'font-family': 'inter',
          'font-size': '30px',
          color: '#f16464',
          'text-align': 'center',
        })
        .text('No Records!');
    } else {
      if (this.value == 1) {
        this.wigetBackgroundColor = this.backColor;
        // if(this.selectedChartsColorSet == "one"){
        // 	this.wigetBackgroundColor = '#ffffff';
        // }
        // if(this.selectedChartsColorSet == "two"){
        // 	this.wigetBackgroundColor = '#fff2cc';
        // }
        // if(this.selectedChartsColorSet == "three"){
        // 	this.wigetBackgroundColor = '#cfe2f3';
        // }
      }
      if (this.value == 2) {
        this.wigetBackgroundColor = '#CFE2F3';
      }
      let xAxisFormatted = '';
      if (
        widget.yAxis.charAt(0) == '[' &&
        widget.yAxis.charAt(widget.yAxis.length - 1) == ']'
      ) {
        xAxisFormatted = JSON.parse(widget.yAxis)[0];
      } else {
        xAxisFormatted = widget.yAxis;
      }

      let yAxisFormatted = '';
      if (
        widget.xAxis.charAt(0) == '[' &&
        widget.xAxis.charAt(widget.xAxis.length - 1) == ']'
      ) {
        yAxisFormatted = JSON.parse(widget.xAxis)[0];
      } else {
        yAxisFormatted = widget.xAxis;
      }

      let options = {
        data: widgetdetails.body?.groupedQuery?.records ? widgetdetails.body?.groupedQuery?.records : widgetdetails.body.records,
        dataForTable: widgetdetails.body?.records, // for table
        isDataDetailedForTable: false,
        keys: {
          label: xAxisFormatted,
          value: yAxisFormatted,
        },
        chartTitle: widget.chartTitle,
        xAxisLable: xAxisFormatted,
        yAxisLable: yAxisFormatted,
        showTitle: true,
        colorSet: this.selectedChartsColorSet,
        series: this.seriesVal,
        legend: this.legendvalue,
        BGcolor: this.wigetBackgroundColor, //one or two or three
        drillDownColumnName: widget.drillThru[0]?.drillDownColumn
          ? widget.drillThru[0].drillDownColumn
          : '',
        showTable: false, //false - hide, true - show
        showComments: false,
        comments: widget.comments,
        resizeButtonID: document.getElementById('resizeBtn').id,
      };

      setTimeout(() => {
        acinfotech.columnChart(chartElement, options);
      }, 1000);
    }
  }
  table(chartElement, widget, widgetdetails) {
    let fetchedData =
      widgetdetails.body.records == null
        ? 'No Records!'
        : widgetdetails.body.records.length > 0
          ? widgetdetails.body.records
          : 'No Records!';

    if (fetchedData == 'No Records!') {
      chartElement
        .append('div')
        .style({
          position: 'absolute',
          left: '0%',
          top: '0%',
          width: '100%',
          height: '8%',
          'background-color': this.wigetBackgroundColor,
          'text-indent': '6px',
        })
        .html(
          '<div  style="width: 100%; height:0%; background-color:{{ this.wigetBackgroundColor}}; position: absolute; top:10.0%; font-size:16px; font-family:inter; color:#00162E;">' +
          widget.chartTitle +
          '</div>'
        );

      chartElement
        .append('label')
        .style({
          position: 'absolute',
          left: '0%',
          top: '45%',
          width: '100%',
          'font-family': 'inter',
          'font-size': '30px',
          color: '#f16464',
          'text-align': 'center',
        })
        .text('No Records!');
    } else {
      let options = {
        data: widgetdetails.body?.groupedQuery?.records ? widgetdetails.body?.groupedQuery?.records : widgetdetails.body.records,
        chartTitle: widget.chartTitle,
        showTitle: true,
        drillDownColumnName: widget.drillThru[0]?.drillDownColumn
          ? widget.drillThru[0].drillDownColumn
          : '',
        fontStyle: 'Normal', // Normal, Italic
        fontWeight: 'Bold', // Normal, Bold
        fontFamily: 'inter', // inter, Century Gothic, Candara, Verdana, Trebuchet MS, Segoe UI
        resizeButtonID: document.getElementById('resizeBtn').id,
      };
      setTimeout(() => {
        acinfotech.table(chartElement, options);
      }, 1000);
    }
  }
  donutChart(chartElement, widget, widgetdetails) {
    let fetchedData =
      widgetdetails.body.records == null
        ? 'No Records!'
        : widgetdetails.body.records.length > 0
          ? widgetdetails.body.records
          : 'No Records!';

    if (fetchedData == 'No Records!') {
      chartElement
        .append('div')
        .style({
          position: 'absolute',
          left: '0%',
          top: '0%',
          width: '100%',
          height: '8%',
          'background-color': this.wigetBackgroundColor,
          'text-indent': '6px',
        })
        .html(
          '<div  style="width: 100%; height:0%; background-color:{{ this.wigetBackgroundColor}}; position: absolute; top:10.0%; font-size:16px; font-family:inter; color:#00162E;">' +
          widget.chartTitle +
          '</div>'
        );

      chartElement
        .append('label')
        .style({
          position: 'absolute',
          left: '0%',
          top: '45%',
          width: '100%',
          'font-family': 'inter',
          'font-size': '30px',
          color: '#f16464',
          'text-align': 'center',
        })
        .text('No Records!');
    } else {
      if (this.value == 1) {
        this.wigetBackgroundColor = this.backColor;
        // if(this.selectedChartsColorSet == "one"){
        // 	this.wigetBackgroundColor = '#ffffff';
        // }
        // if(this.selectedChartsColorSet == "two"){
        // 	this.wigetBackgroundColor = '#fff2cc';
        // }
        // if(this.selectedChartsColorSet == "three"){
        // 	this.wigetBackgroundColor = '#cfe2f3';
        // }
      }
      if (this.value == 2) {
        this.wigetBackgroundColor = '#ebb9b9';
      }
      let xAxisFormatted = '';
      if (
        widget.yAxis.charAt(0) == '[' &&
        widget.yAxis.charAt(widget.yAxis.length - 1) == ']'
      ) {
        xAxisFormatted = JSON.parse(widget.yAxis)[0];
      } else {
        xAxisFormatted = widget.yAxis;
      }

      let yAxisFormatted = '';
      if (
        widget.xAxis.charAt(0) == '[' &&
        widget.xAxis.charAt(widget.xAxis.length - 1) == ']'
      ) {
        yAxisFormatted = JSON.parse(widget.xAxis)[0];
      } else {
        yAxisFormatted = widget.xAxis;
      }
      var ShowLegend = false;
      if (widget.legendYesNo == 'Y') { ShowLegend = true }
      else if (widget.legendYesNo == 'N') { ShowLegend = false }
      let options = {
        data: widgetdetails.body?.groupedQuery?.records ? widgetdetails.body?.groupedQuery?.records : widgetdetails.body.records, // for chart
        dataForTable: widgetdetails.body?.records, // for table if there is no data send empty array ([])
        isDataDetailedForTable: false,
        keys: {
          label: xAxisFormatted,
          value: yAxisFormatted,
        },
        chartTitle: widget.chartTitle,
        showTitle: true,
        showLegend: ShowLegend,
        legendSide: 'right', //"right" or "left"
        series: this.seriesVal,
        legend: this.legendvalue,
        colorSet: this.selectedChartsColorSet, //"one" or "two" or "three"
        BGcolor: this.wigetBackgroundColor,
        gradient: 'none', //"none" or "smooth" or "elliptical" or "circular" or "radial"
        drillDownColumnName: widget.drillThru[0]?.drillDownColumn
          ? widget.drillThru[0].drillDownColumn
          : '',
        showTableIcon: true, //false - hide, true - show
        showTable: false, //false - hide, true - show
        showComments: false,
        comments: widget.comments,
        resizeButtonID: document.getElementById('resizeBtn').id,
      };
      setTimeout(() => {
        acinfotech.donutChart(chartElement, options);
      }, 1000);
    }
  }
  horizontalChart(chartElement, widget, widgetdetails) {
    let fetchedData =
      widgetdetails.body.records == null
        ? 'No Records!'
        : widgetdetails.body.records.length > 0
          ? widgetdetails.body.records
          : 'No Records!';

    if (fetchedData == 'No Records!') {
      chartElement
        .append('div')
        .style({
          position: 'absolute',
          left: '0%',
          top: '0%',
          width: '100%',
          height: '8%',
          'background-color': this.wigetBackgroundColor,
          'text-indent': '6px',
        })
        .html(
          '<div  style="width: 100%; height:0%; background-color:{{ this.wigetBackgroundColor}}; position: absolute; top:10.0%; font-size:16px; font-family:inter; color:#00162E;">' +
          widget.chartTitle +
          '</div>'
        );

      chartElement
        .append('label')
        .style({
          position: 'absolute',
          left: '0%',
          top: '45%',
          width: '100%',
          'font-family': 'inter',
          'font-size': '30px',
          color: '#f16464',
          'text-align': 'center',
        })
        .text('No Records!');
    } else {
      if (this.value == 1) {
        this.wigetBackgroundColor = this.backColor;
        // if(this.selectedChartsColorSet == "one"){
        // 	this.wigetBackgroundColor = '#ffffff';
        // }
        // if(this.selectedChartsColorSet == "two"){
        // 	this.wigetBackgroundColor = '#fff2cc';
        // }
        // if(this.selectedChartsColorSet == "three"){
        // 	this.wigetBackgroundColor = '#cfe2f3';
        // }
      }
      if (this.value == 2) {
        this.wigetBackgroundColor = '#ffd670';
      }

      let xAxisFormatted = '';
      if (
        widget.yAxis.charAt(0) == '[' &&
        widget.yAxis.charAt(widget.yAxis.length - 1) == ']'
      ) {
        xAxisFormatted = JSON.parse(widget.yAxis)[0];
      } else {
        xAxisFormatted = widget.yAxis;
      }

      let yAxisFormatted = '';
      if (
        widget.xAxis.charAt(0) == '[' &&
        widget.xAxis.charAt(widget.xAxis.length - 1) == ']'
      ) {
        yAxisFormatted = JSON.parse(widget.xAxis)[0];
      } else {
        yAxisFormatted = widget.xAxis;
      }

      let options = {
        data: widgetdetails.body?.groupedQuery?.records ? widgetdetails.body?.groupedQuery?.records : widgetdetails.body.records,
        dataForTable: widgetdetails.body?.records,
        isDataDetailedForTable: false,
        keys: {
          label: xAxisFormatted,
          value: yAxisFormatted,
        },
        chartTitle: widget.chartTitle,
        xAxisLable: yAxisFormatted,
        yAxisLable: xAxisFormatted,
        showTitle: true,
        series: this.seriesVal,
        legend: this.legendvalue,
        colorSet: this.selectedChartsColorSet, //"one" or "two" or "three"
        BGcolor: this.wigetBackgroundColor,
        drillDownColumnName: widget.drillThru[0]?.drillDownColumn
          ? widget.drillThru[0].drillDownColumn
          : '',
        showTable: false,
        showComments: false,
        comments: widget.comments,
        resizeButtonID: document.getElementById('resizeBtn').id,
      };
      setTimeout(() => {
        acinfotech.horizontalChart(chartElement, options);
      }, 1000);
    }
  }
  //waffleChart(chartElement,widget,widgetdetails){}
  perGauge(chartElement, widget, widgetdetails) {
    let fetchedData =
      widgetdetails.body.records == null
        ? widget.chartPlot
        : widgetdetails.body.records.length > 0
          ? widgetdetails.body.records[0][widget.yAxis]
          : 'No Records!';

    if (fetchedData == 'No Records!') {
      chartElement
        .append('div')
        .style({
          position: 'absolute',
          left: '0%',
          top: '0%',
          width: '100%',
          height: '8%',
          'background-color': this.wigetBackgroundColor,
          'text-indent': '6px',
        })
        .html(
          '<div  style="width: 100%; height:0%; background-color:{{ this.wigetBackgroundColor}}; position: absolute; top:10.0%; font-size:16px; font-family:inter; color:#00162E;">' +
          widget.chartTitle +
          '</div>'
        );

      chartElement
        .append('label')
        .style({
          position: 'absolute',
          left: '0%',
          top: '45%',
          width: '100%',
          'font-family': 'inter',
          'font-size': '30px',
          color: '#f16464',
          'text-align': 'center',
        })
        .text('No Records!');
    } else {
      if (this.value == 1) {
        this.wigetBackgroundColor = this.backColor;
        // if(this.selectedChartsColorSet == "one"){
        // 	this.wigetBackgroundColor = '#ffffff';
        // }
        // if(this.selectedChartsColorSet == "two"){
        // 	this.wigetBackgroundColor = '#fff2cc';
        // }
        // if(this.selectedChartsColorSet == "three"){
        // 	this.wigetBackgroundColor = '#cfe2f3';
        // }
      }
      if (this.value == 2) {
        this.wigetBackgroundColor = '#d9d9d9';
      }

      let options = {
        data: fetchedData,
        chartTitle: widget.chartTitle,
        showTitle: true,
        colorSet: this.selectedChartsColorSet,
        BGcolor: this.wigetBackgroundColor,
        resizeButtonID: document.getElementById('resizeBtn').id,
      };
      setTimeout(() => {
        acinfotech.perGauge(chartElement, options);
      }, 1000);
    }
  }
  numberGauge(chartElement, widget, widgetdetails) {
    let fetchedData =
      widgetdetails.body.records == null
        ? widget.chartPlot
        : widgetdetails.body.records.length > 0
          ? widgetdetails.body.records[0][widget.yAxis]
          : 'No Records!';

    if (fetchedData == 'No Records!') {
      chartElement
        .append('div')
        .style({
          position: 'absolute',
          left: '0%',
          top: '0%',
          width: '100%',
          height: '8%',
          'background-color': this.wigetBackgroundColor,
          'text-indent': '6px',
        })
        .html(
          '<div  style="width: 100%; height:0%; background-color:{{ this.wigetBackgroundColor}}; position: absolute; top:10.0%; font-size:16px; font-family:inter; color:#00162E;">' +
          widget.chartTitle +
          '</div>'
        );

      chartElement
        .append('label')
        .style({
          position: 'absolute',
          left: '0%',
          top: '45%',
          width: '100%',
          'font-family': 'inter',
          'font-size': '30px',
          color: '#f16464',
          'text-align': 'center',
        })
        .text('No Records!');
    } else {
      if (this.value == 1) {
        this.wigetBackgroundColor = this.backColor;
        // if(this.selectedChartsColorSet == "one"){
        // 	this.wigetBackgroundColor = '#ffffff';
        // }
        // if(this.selectedChartsColorSet == "two"){
        // 	this.wigetBackgroundColor = '#fff2cc';
        // }
        // if(this.selectedChartsColorSet == "three"){
        // 	this.wigetBackgroundColor = '#cfe2f3';
        // }
      }
      if (this.value == 2) {
        this.wigetBackgroundColor = '#ebb9b9';
      }

      let options = {
        data: fetchedData,
        chartTitle: widget.chartTitle,
        showTitle: true,
        colorSet: this.selectedChartsColorSet,
        BGcolor: this.wigetBackgroundColor,
        resizeButtonID: document.getElementById('resizeBtn').id,
      };
      setTimeout(() => {
        acinfotech.numberGauge(chartElement, options);
      }, 1000);
    }
  }
  map(chartElement, widget, widgetdetails) {
    let fetchedData =
      widgetdetails.body.records == null
        ? 'No Records!'
        : widgetdetails.body.records.length > 0
          ? widgetdetails.body.records
          : 'No Records!';

    if (fetchedData == 'No Records!') {
      chartElement
        .append('div')
        .style({
          position: 'absolute',
          left: '0%',
          top: '0%',
          width: '100%',
          height: '8%',
          'background-color': this.wigetBackgroundColor,
          'text-indent': '6px',
        })
        .html(
          '<div  style="width: 100%; height:0%; background-color:{{ this.wigetBackgroundColor}}; position: absolute; top:10.0%; font-size:16px; font-family:inter; color:#00162E;">' +
          widget.chartTitle +
          '</div>'
        );

      chartElement
        .append('label')
        .style({
          position: 'absolute',
          left: '0%',
          top: '45%',
          width: '100%',
          'font-family': 'inter',
          'font-size': '30px',
          color: '#f16464',
          'text-align': 'center',
        })
        .text('No Records!');
    } else {
      if (this.value == 1) {
        this.wigetBackgroundColor = this.backColor;
        // if(this.selectedChartsColorSet == "one"){
        // 	this.wigetBackgroundColor = '#ffffff';
        // }
        // if(this.selectedChartsColorSet == "two"){
        // 	this.wigetBackgroundColor = '#fff2cc';
        // }
        // if(this.selectedChartsColorSet == "three"){
        // 	this.wigetBackgroundColor = '#cfe2f3';
        // }
      }
      if (this.value == 2) {
        this.wigetBackgroundColor = '#e1d0e8';
      }
      let xAxisFormatted = '';
      if (
        widget.yAxis.charAt(0) == '[' &&
        widget.yAxis.charAt(widget.yAxis.length - 1) == ']'
      ) {
        xAxisFormatted = JSON.parse(widget.yAxis)[0];
      } else {
        xAxisFormatted = widget.yAxis;
      }

      let yAxisFormatted = '';
      if (
        widget.xAxis.charAt(0) == '[' &&
        widget.xAxis.charAt(widget.xAxis.length - 1) == ']'
      ) {
        yAxisFormatted = JSON.parse(widget.xAxis)[0];
      } else {
        yAxisFormatted = widget.xAxis;
      }

      let options = {
        data: widgetdetails.body?.groupedQuery?.records ? widgetdetails.body?.groupedQuery?.records : widgetdetails.body.records,
        keys: {
          label: xAxisFormatted,
          value: yAxisFormatted,
        },
        chartTitle: widget.chartTitle,
        showTitle: true,
        maximize: true,
        series: this.seriesVal,
        legend: this.legendvalue,
        BGcolor: this.wigetBackgroundColor,
        drillDownColumnName: widget.drillThru[0]?.drillDownColumn
          ? widget.drillThru[0].drillDownColumn
          : '',
        resizeButtonID: document.getElementById('resizeBtn').id,
      };
      setTimeout(() => {
        acinfotech.map(chartElement, options);
      }, 1000);
    }
  }
  columnAndLineChart(chartElement, widget, widgetdetails) {
    let fetchedData =
      widgetdetails.body.records == null
        ? 'No Records!'
        : widgetdetails.body.records.length > 0
          ? widgetdetails.body.records
          : 'No Records!';

    if (fetchedData == 'No Records!') {
      chartElement
        .append('div')
        .style({
          position: 'absolute',
          left: '0%',
          top: '0%',
          width: '100%',
          height: '8%',
          'background-color': this.wigetBackgroundColor,
          'text-indent': '6px',
        })
        .html(
          '<div  style="width: 100%; height:0%; background-color:{{ this.wigetBackgroundColor}}; position: absolute; top:10.0%; font-size:16px; font-family:inter; color:#00162E;">' +
          widget.chartTitle +
          '</div>'
        );

      chartElement
        .append('label')
        .style({
          position: 'absolute',
          left: '0%',
          top: '45%',
          width: '100%',
          'font-family': 'inter',
          'font-size': '30px',
          color: '#f16464',
          'text-align': 'center',
        })
        .text('No Records!');
    } else {
      if (this.value == 1) {
        this.wigetBackgroundColor = this.backColor;
        // if(this.selectedChartsColorSet == "one"){
        // 	this.wigetBackgroundColor = '#ffffff';
        // }
        // if(this.selectedChartsColorSet == "two"){
        // 	this.wigetBackgroundColor = '#fff2cc';
        // }
        // if(this.selectedChartsColorSet == "three"){
        // 	this.wigetBackgroundColor = '#cfe2f3';
        // }
      }
      if (this.value == 2) {
        this.wigetBackgroundColor = '#E3F2FD';
      }
      let xAxis = '';
      if (
        widget.yAxis.charAt(0) == '[' &&
        widget.yAxis.charAt(widget.yAxis.length - 1) == ']'
      ) {
        xAxis = JSON.parse(widget.yAxis)[0];
      } else {
        xAxis = widget.yAxis;
      }

      let options = {
        data: widgetdetails.body?.groupedQuery?.records ? widgetdetails.body?.groupedQuery?.records : widgetdetails.body.records,
        dataForTable: widgetdetails.body?.records,
        isDataDetailedForTable: false,
        keys: {
          label: xAxis,
          valueForColumn: JSON.parse(widget.xAxis)[0], // left axis
          valueForLine: JSON.parse(widget.xAxis)[1], // right axis
        },
        chartTitle: widget.chartTitle,
        xAxisLable: xAxis,
        yLeftAxisLable: JSON.parse(widget.xAxis)[0],
        yRightAxisLable: JSON.parse(widget.xAxis)[1],
        showTitle: true,
        series: this.seriesVal,
        legend: this.legendvalue,
        colorSet: this.selectedChartsColorSet, //"one" or "two" or "three";
        BGcolor: this.wigetBackgroundColor,
        showTable: false,
        showComments: false,
        comments: widget.comments,
        drillDownColumnName: widget.drillThru[0]?.drillDownColumn
          ? widget.drillThru[0].drillDownColumn
          : '',
        resizeButtonID: document.getElementById('resizeBtn').id,
      };
      setTimeout(() => {
        acinfotech.columnAndLineChart(chartElement, options);
      }, 1000);
    }
  }
  lineChart(chartElement, widget, widgetdetails) {
    let fetchedData =
      widgetdetails?.body?.records == null
        ? 'No Records!'
        : widgetdetails?.body?.records.length > 0
          ? widgetdetails?.body?.records
          : 'No Records!';

    if (fetchedData == 'No Records!') {
      chartElement
        .append('div')
        .style({
          position: 'absolute',
          left: '0%',
          top: '0%',
          width: '100%',
          height: '8%',
          'background-color': this.wigetBackgroundColor,
          'text-indent': '6px',
        })
        .html(
          '<div  style="width: 100%; height:0%; background-color:{{ this.wigetBackgroundColor}}; position: absolute; top:10.0%; font-size:16px; font-family:inter; color:#00162E;">' +
          widget.chartTitle +
          '</div>'
        );

      chartElement
        .append('label')
        .style({
          position: 'absolute',
          left: '0%',
          top: '45%',
          width: '100%',
          'font-family': 'inter',
          'font-size': '30px',
          color: '#f16464',
          'text-align': 'center',
        })
        .text('No Records!');
    } else {
      if (this.value == 1) {
        this.wigetBackgroundColor = this.backColor;
        // if(this.selectedChartsColorSet == "one"){
        // 	this.wigetBackgroundColor = '#ffffff';
        // }
        // if(this.selectedChartsColorSet == "two"){
        // 	this.wigetBackgroundColor = '#fff2cc';
        // }
        // if(this.selectedChartsColorSet == "three"){
        // 	this.wigetBackgroundColor = '#cfe2f3';
        // }
      }
      if (this.value == 2) {
        this.wigetBackgroundColor = '#B2DFDB';
      }
      let xAxis = '',
        xAxisArr = [];
      if (
        widget.yAxis.charAt(0) == '[' &&
        widget.yAxis.charAt(widget.yAxis.length - 1) == ']'
      ) {
        xAxis = JSON.parse(widget.yAxis);
        if (xAxis.length > 1) {
          xAxisArr = xAxis as any;
        } else {
          xAxis = JSON.parse(widget.yAxis)[0];
        }
      } else {
        xAxis = widget.yAxis;
      }

      if (xAxisArr.length > 0) {
        let yAxis = '';
        if (
          widget.xAxis.charAt(0) == '[' &&
          widget.xAxis.charAt(widget.xAxis.length - 1) == ']'
        ) {
          yAxis = JSON.parse(widget.xAxis)[0];
        } else {
          yAxis = widget.xAxis;
        }

        let options = {
          data: widgetdetails.body?.groupedQuery?.records ? widgetdetails.body?.groupedQuery?.records : widgetdetails.body.records,
          dataForTable: widgetdetails.body?.records,
          isDataDetailedForTable: false,
          keys: {
            label: xAxisArr, // X-Axis labels
            lines: yAxis,
          },
          chartTitle: widget.chartTitle,
          xAxisLable: yAxis,
          yAxisLable: '',
          showTitle: true,
          series: this.seriesVal,
          legend: this.legendvalue,
          colorSet: this.selectedChartsColorSet, //"one" or "two" or "three";
          BGcolor: this.wigetBackgroundColor,
          lineStyle: 'cardinal', // "cardinal" or "linear" or "step"
          drillDownColumnName: widget.drillThru[0]?.drillDownColumn
            ? widget.drillThru[0].drillDownColumn
            : '',
          showTable: false,
          showComments: false,
          comments: widget.comments,
          resizeButtonID: document.getElementById('resizeBtn').id,
        };

        setTimeout(() => {
          acinfotech.lineChart2(chartElement, options);
        }, 1000);
      } else {
        let options = {
          data: widgetdetails.body?.groupedQuery?.records ? widgetdetails.body?.groupedQuery?.records : widgetdetails.body.records,
          dataForTable: widgetdetails.body?.records,
          isDataDetailedForTable: false,
          keys: {
            label: xAxis, // X-Axis labels
            lines: JSON.parse(widget.xAxis),
          },
          chartTitle: widget.chartTitle,
          xAxisLable: xAxis,
          yAxisLable: '',
          showTitle: true,
          series: this.seriesVal,
          legend: this.legendvalue,
          colorSet: this.selectedChartsColorSet, //"one" or "two" or "three";
          BGcolor: this.wigetBackgroundColor,
          lineStyle: 'linear', // "cardinal" or "linear" or "step"
          drillDownColumnName: widget.drillThru[0]?.drillDownColumn
            ? widget.drillThru[0].drillDownColumn
            : '',
          showTable: false,
          showComments: false,
          comments: widget.comments,
          resizeButtonID: document.getElementById('resizeBtn').id,
        };

        setTimeout(() => {
          acinfotech.lineChart(chartElement, options);
        }, 1000);
      }
    }
  }
  bubbleChart(chartElement, widget, widgetdetails) {
    let fetchedData =
      widgetdetails.body.records == null
        ? 'No Records!'
        : widgetdetails.body.records.length > 0
          ? widgetdetails.body.records
          : 'No Records!';

    if (fetchedData == 'No Records!') {
      chartElement
        .append('div')
        .style({
          position: 'absolute',
          left: '0%',
          top: '0%',
          width: '100%',
          height: '8%',
          'background-color': this.wigetBackgroundColor,
          'text-indent': '6px',
        })
        .html(
          '<div  style="width: 100%; height:0%; background-color:{{ this.wigetBackgroundColor}}; position: absolute; top:10.0%; font-size:16px; font-family:inter; color:#00162E;">' +
          widget.chartTitle +
          '</div>'
        );

      chartElement
        .append('label')
        .style({
          position: 'absolute',
          left: '0%',
          top: '45%',
          width: '100%',
          'font-family': 'inter',
          'font-size': '30px',
          color: '#f16464',
          'text-align': 'center',
        })
        .text('No Records!');
    } else {
      if (this.value == 1) {
        // if(this.selectedChartsColorSet == "one"){
        // 	this.wigetBackgroundColor = '#ffffff';
        // }
        // if(this.selectedChartsColorSet == "two"){
        // 	this.wigetBackgroundColor = '#fff2cc';
        // }
        // if(this.selectedChartsColorSet == "three"){
        // 	this.wigetBackgroundColor = '#cfe2f3';
        // }
        this.wigetBackgroundColor = this.backColor;
      }
      if (this.value == 2) {
        this.wigetBackgroundColor = '#C8E6C9';
      }

      let xAxisFormatted = '';
      if (
        widget.yAxis.charAt(0) == '[' &&
        widget.yAxis.charAt(widget.yAxis.length - 1) == ']'
      ) {
        xAxisFormatted = JSON.parse(widget.yAxis)[0];
      } else {
        xAxisFormatted = widget.yAxis;
      }

      let yAxisFormatted = '';
      if (
        widget.xAxis.charAt(0) == '[' &&
        widget.xAxis.charAt(widget.xAxis.length - 1) == ']'
      ) {
        yAxisFormatted = JSON.parse(widget.xAxis)[0];
      } else {
        yAxisFormatted = widget.xAxis;
      }

      let options = {
        data: widgetdetails.body?.groupedQuery?.records ? widgetdetails.body?.groupedQuery?.records : widgetdetails.body.records,
        dataForTable: widgetdetails.body?.records,
        isDataDetailedForTable: false,
        keys: {
          label: xAxisFormatted,
          value: yAxisFormatted,
        },
        chartTitle: widget.chartTitle,
        showTitle: true,
        series: this.seriesVal,
        legend: this.legendvalue,
        colorSet: this.selectedChartsColorSet, //"one" or "two" or "three";
        BGcolor: this.wigetBackgroundColor,
        drillDownColumnName: widget.drillThru[0]?.drillDownColumn
          ? widget.drillThru[0].drillDownColumn
          : '',
        showTable: false,
        showComments: false,
        comments: widget.comments,
        resizeButtonID: document.getElementById('resizeBtn').id,
      };

      setTimeout(() => {
        acinfotech.bubbleChart(chartElement, options);
      }, 1000);
    }
  }
  stackedHorizontalBarChart(chartElement, widget, widgetdetails) {
    let fetchedData =
      widgetdetails.body.records == null
        ? 'No Records!'
        : widgetdetails.body.records.length > 0
          ? widgetdetails.body.records
          : 'No Records!';

    if (fetchedData == 'No Records!') {
      chartElement
        .append('div')
        .style({
          position: 'absolute',
          left: '0%',
          top: '0%',
          width: '100%',
          height: '8%',
          'background-color': this.wigetBackgroundColor,
          'text-indent': '6px',
        })
        .html(
          '<div  style="width: 100%; height:0%; background-color:{{ this.wigetBackgroundColor}}; position: absolute; top:10.0%; font-size:16px; font-family:inter; color:#00162E;">' +
          widget.chartTitle +
          '</div>'
        );

      chartElement
        .append('label')
        .style({
          position: 'absolute',
          left: '0%',
          top: '45%',
          width: '100%',
          'font-family': 'inter',
          'font-size': '30px',
          color: '#f16464',
          'text-align': 'center',
        })
        .text('No Records!');
    } else {
      if (this.value == 1) {
        this.wigetBackgroundColor = this.backColor;
        // if(this.selectedChartsColorSet == "one"){
        // 	this.wigetBackgroundColor = '#ffffff';
        // }
        // if(this.selectedChartsColorSet == "two"){
        // 	this.wigetBackgroundColor = '#fff2cc';
        // }
        // if(this.selectedChartsColorSet == "three"){
        // 	this.wigetBackgroundColor = '#cfe2f3';
        // }
      }
      if (this.value == 2) {
        this.wigetBackgroundColor = '#ebb9b9';
      }
      let xAxis = '',
        xAxisArr = [];
      if (
        widget.yAxis.charAt(0) == '[' &&
        widget.yAxis.charAt(widget.yAxis.length - 1) == ']'
      ) {
        xAxis = JSON.parse(widget.yAxis);
        if (xAxis.length > 1) {
          xAxisArr = xAxis as any;
        } else {
          xAxis = JSON.parse(widget.yAxis)[0];
        }
      } else {
        xAxis = widget.yAxis;
      }

      if (xAxisArr.length > 0) {
        let yAxis = '';
        if (
          widget.xAxis.charAt(0) == '[' &&
          widget.xAxis.charAt(widget.xAxis.length - 1) == ']'
        ) {
          yAxis = JSON.parse(widget.xAxis)[0];
        } else {
          yAxis = widget.xAxis;
        }
        let options = {
          data: widgetdetails.body?.groupedQuery?.records ? widgetdetails.body?.groupedQuery?.records : widgetdetails.body.records,
          dataForDrillDown: widgetdetails.body?.groupedQuery?.records ? widgetdetails.body?.records : [],
          dataForTable: widgetdetails.body?.records,
          isDataDetailedForTable: false,
          keys: {
            label: xAxisArr, // X-Axis labels
            stackedBars: yAxis,
          },
          chartTitle: widget.chartTitle,
          xAxisLable: '',
          yAxisLable: yAxis,
          showTitle: true,
          series: this.seriesVal,
          legend: this.legendvalue,
          colorSet: this.selectedChartsColorSet, //"one" or "two" or "three";
          BGcolor: this.wigetBackgroundColor,
          lineStyle: 'cardinal', // "cardinal" or "linear" or "step"
          drillDownColumnName: widget.drillThru[0]?.drillDownColumn
            ? widget.drillThru[0].drillDownColumn
            : '',
          showTable: false,
          showComments: false,
          comments: widget.comments,
          resizeButtonID: document.getElementById('resizeBtn').id,
        };

        setTimeout(() => {
          acinfotech.stackedHorizontalBarChart2(chartElement, options);
        }, 1000);
      } else {
        let options = {
          data: widgetdetails.body?.groupedQuery?.records ? widgetdetails.body?.groupedQuery?.records : widgetdetails.body.records,
          dataForDrillDown: widgetdetails.body?.groupedQuery?.records ? widgetdetails.body?.records : [],
          dataForTable: widgetdetails.body?.records,
          isDataDetailedForTable: false,
          keys: {
            label: xAxis, // X-Axis labels
            stackedBars: JSON.parse(widget.xAxis),
          },
          chartTitle: widget.chartTitle,
          xAxisLable: '',
          yAxisLable: xAxis,
          showTitle: true,
          series: this.seriesVal,
          legend: this.legendvalue,
          colorSet: this.selectedChartsColorSet, //"one" or "two" or "three";
          BGcolor: this.wigetBackgroundColor,
          lineStyle: 'cardinal', // "cardinal" or "linear" or "step"
          drillDownColumnName: widget.drillThru[0]?.drillDownColumn
            ? widget.drillThru[0].drillDownColumn
            : '',
          showTable: false,
          showComments: false,
          comments: widget.comments,
          resizeButtonID: document.getElementById('resizeBtn').id,
        };

        setTimeout(() => {
          acinfotech.stackedHorizontalBarChart(chartElement, options);
        }, 1000);
      }
    }
  }
  stackedBarChart(chartElement, widget, widgetdetails) {
    let fetchedData =
      widgetdetails.body.records == null
        ? 'No Records!'
        : widgetdetails.body.records.length > 0
          ? widgetdetails.body.records
          : 'No Records!';

    if (fetchedData == 'No Records!') {
      chartElement
        .append('div')
        .style({
          position: 'absolute',
          left: '0%',
          top: '0%',
          width: '100%',
          height: '8%',
          'background-color': this.wigetBackgroundColor,
          'text-indent': '6px',
        })
        .html(
          '<div  style="width: 100%; height:0%; background-color:{{ this.wigetBackgroundColor}}; position: absolute; top:10.0%; font-size:16px; font-family:inter; color:#00162E;">' +
          widget.chartTitle +
          '</div>'
        );

      chartElement
        .append('label')
        .style({
          position: 'absolute',
          left: '0%',
          top: '45%',
          width: '100%',
          'font-family': 'inter',
          'font-size': '30px',
          color: '#f16464',
          'text-align': 'center',
        })
        .text('No Records!');
    } else {
      if (this.value == 1) {
        this.wigetBackgroundColor = this.backColor;
        // if(this.selectedChartsColorSet == "one"){
        // 	this.wigetBackgroundColor = '#ffffff';
        // }
        // if(this.selectedChartsColorSet == "two"){
        // 	this.wigetBackgroundColor = '#fff2cc';
        // }
        // if(this.selectedChartsColorSet == "three"){
        // 	this.wigetBackgroundColor = '#cfe2f3';
        // }
      }
      if (this.value == 2) {
        this.wigetBackgroundColor = '#FFF9C4';
      }
      let xAxis = '',
        xAxisArr = [];
      if (
        widget.yAxis.charAt(0) == '[' &&
        widget.yAxis.charAt(widget.yAxis.length - 1) == ']'
      ) {
        xAxis = JSON.parse(widget.yAxis);
        if (xAxis.length > 1) {
          xAxisArr = xAxis as any;
        } else {
          xAxis = JSON.parse(widget.yAxis)[0];
        }
      } else {
        xAxis = widget.yAxis;
      }

      if (xAxisArr.length > 0) {
        let yAxis = '';
        if (
          widget.xAxis.charAt(0) == '[' &&
          widget.xAxis.charAt(widget.xAxis.length - 1) == ']'
        ) {
          yAxis = JSON.parse(widget.xAxis)[0];
        } else {
          yAxis = widget.xAxis;
        }
        let options = {
          data: widgetdetails.body?.groupedQuery?.records ? widgetdetails.body?.groupedQuery?.records : widgetdetails.body.records,
          dataForDrillDown: widgetdetails.body?.groupedQuery?.records ? widgetdetails.body?.records : [],
          dataForTable: widgetdetails.body?.records,
          isDataDetailedForTable: false,
          keys: {
            label: xAxisArr, // X-Axis labels
            stackedBars: yAxis,
          },
          chartTitle: widget.chartTitle,
          xAxisLable: yAxis,
          yAxisLable: '',
          showTitle: true,
          series: this.seriesVal,
          legend: this.legendvalue,
          colorSet: this.selectedChartsColorSet, //"one" or "two" or "three";
          BGcolor: this.wigetBackgroundColor,
          lineStyle: 'cardinal', // "cardinal" or "linear" or "step"
          drillDownColumnName: widget.drillThru[0]?.drillDownColumn
            ? widget.drillThru[0].drillDownColumn
            : '',
          showTable: false,
          showComments: false,
          comments: widget.comments,
          resizeButtonID: document.getElementById('resizeBtn').id,
        };

        setTimeout(() => {
          acinfotech.stackedBarChart2(chartElement, options);
        }, 1000);
      } else {
        let options = {
          data: widgetdetails.body?.groupedQuery?.records ? widgetdetails.body?.groupedQuery?.records : widgetdetails.body.records,
          dataForDrillDown: widgetdetails.body?.groupedQuery?.records ? widgetdetails.body?.records : [],
          dataForTable: widgetdetails.body?.records,
          isDataDetailedForTable: false,
          keys: {
            label: xAxis, // X-Axis labels
            stackedBars: JSON.parse(widget.xAxis),
          },
          chartTitle: widget.chartTitle,
          xAxisLable: xAxis,
          yAxisLable: '',
          showTitle: true,
          series: this.seriesVal,
          legend: this.legendvalue,
          colorSet: this.selectedChartsColorSet, //"one" or "two" or "three";
          BGcolor: this.wigetBackgroundColor,
          lineStyle: 'cardinal', // "cardinal" or "linear" or "step"
          drillDownColumnName: widget.drillThru[0]?.drillDownColumn
            ? widget.drillThru[0].drillDownColumn
            : '',
          showTable: false,
          showComments: false,
          comments: widget.comments,
          resizeButtonID: document.getElementById('resizeBtn').id,
        };
        setTimeout(() => {
          acinfotech.stackedBarChart(chartElement, options);
        }, 1000);
      }
    }
  }
  perGaugeWithFlip(chartElement, widget, widgetdetails) {
    let fetchedData =
      widgetdetails.body.records == null
        ? widget.chartPlot
        : widgetdetails.body.records.length > 0
          ? widgetdetails.body.records[0][widget.yAxis]
          : 'No Records!';

    if (fetchedData == 'No Records!') {
      chartElement
        .append('div')
        .style({
          position: 'absolute',
          left: '0%',
          top: '0%',
          width: '100%',
          height: '8%',
          'background-color': this.wigetBackgroundColor,
          'text-indent': '6px',
        })
        .html(
          '<div  style="width: 100%; height:0%; background-color:{{ this.wigetBackgroundColor}}; position: absolute; top:10.0%; font-size:16px; font-family:inter; color:#00162E;">' +
          widget.chartTitle +
          '</div>'
        );

      chartElement
        .append('label')
        .style({
          position: 'absolute',
          left: '0%',
          top: '45%',
          width: '100%',
          'font-family': 'inter',
          'font-size': '30px',
          color: '#f16464',
          'text-align': 'center',
        })
        .text('No Records!');
    } else {
      if (this.value == 1) {
        this.wigetBackgroundColor = this.backColor;
        // if(this.selectedChartsColorSet == "one"){
        // 	this.wigetBackgroundColor = '#ffffff';
        // }
        // if(this.selectedChartsColorSet == "two"){
        // 	this.wigetBackgroundColor = '#fff2cc';
        // }
        // if(this.selectedChartsColorSet == "three"){
        // 	this.wigetBackgroundColor = '#cfe2f3';
        // }
      }
      if (this.value == 2) {
        this.wigetBackgroundColor = '#CFD8DC';
      }
      let options = {
        data:
          widgetdetails.body.records == null
            ? widget.chartPlot
            : widgetdetails.body.records[0][widget.yAxis],
        chartTitle: widget.chartTitle,
        coveredCircularAreaText: '',
        unCoveredCircularAreaText: '',
        showTitle: true,
        series: this.seriesVal,
        legend: this.legendvalue,
        colorSet: this.selectedChartsColorSet, //"one" or "two" or "three";
        BGcolor: this.wigetBackgroundColor,

        resizeButtonID: document.getElementById('resizeBtn').id,
      };

      setTimeout(() => {
        acinfotech.perGaugeWithFlip(chartElement, options);
      }, 1000);
    }
  }
  squareGauge(chartElement, widget, widgetdetails) {
    let fetchedData =
      widgetdetails.body.records == null
        ? widget.chartPlot
        : widgetdetails.body.records.length > 0
          ? widgetdetails.body.records[0][widget.yAxis]
          : 'No Records!';

    if (fetchedData == 'No Records!') {
      chartElement
        .append('div')
        .style({
          position: 'absolute',
          left: '0%',
          top: '0%',
          width: '100%',
          height: '8%',
          'background-color': this.wigetBackgroundColor,
          'text-indent': '6px',
        })
        .html(
          '<div  style="width: 100%; height:0%; background-color:{{ this.wigetBackgroundColor}}; position: absolute; top:10.0%; font-size:16px; font-family:inter; color:#00162E;">' +
          widget.chartTitle +
          '</div>'
        );

      chartElement
        .append('label')
        .style({
          position: 'absolute',
          left: '0%',
          top: '45%',
          width: '100%',
          'font-family': 'inter',
          'font-size': '30px',
          color: '#f16464',
          'text-align': 'center',
        })
        .text('No Records!');
    } else {
      if (this.value == 1) {
        this.wigetBackgroundColor = this.backColor;
        // if(this.selectedChartsColorSet == "one"){
        // 	this.wigetBackgroundColor = '#ffffff';
        // }
        // if(this.selectedChartsColorSet == "two"){
        // 	this.wigetBackgroundColor = '#fff2cc';
        // }
        // if(this.selectedChartsColorSet == "three"){
        // 	this.wigetBackgroundColor = '#cfe2f3';
        // }
      }
      if (this.value == 2) {
        this.wigetBackgroundColor = '#CFE2F3';
      }
      let options = {
        text:
          widgetdetails.body.records == null
            ? widget.chartPlot
            : widgetdetails.body.records[0][widget.yAxis],
        widgetTitle: widget.chartTitle,
        showTitle: true,
        series: this.seriesVal,
        legend: this.legendvalue,
        colorSet: this.selectedChartsColorSet, //"one" or "two" or "three";
        BGcolor: this.wigetBackgroundColor,
        resizeButtonID: document.getElementById('resizeBtn').id,
      };

      setTimeout(() => {
        acinfotech.squareGauge(chartElement, options);
      }, 1000);
    }
  }
  bellCurveChart(chartElement, widget, widgetdetails) {
    let fetchedData =
      widgetdetails.body.records == null
        ? 'No Records!'
        : widgetdetails.body.records.length > 0
          ? widgetdetails.body.records
          : 'No Records!';

    if (fetchedData == 'No Records!') {
      chartElement
        .append('div')
        .style({
          position: 'absolute',
          left: '0%',
          top: '0%',
          width: '100%',
          height: '8%',
          'background-color': this.wigetBackgroundColor,
          'text-indent': '6px',
        })
        .html(
          '<div  style="width: 100%; height:0%; background-color:{{ this.wigetBackgroundColor}}; position: absolute; top:10.0%; font-size:16px; font-family:inter; color:#00162E;">' +
          widget.chartTitle +
          '</div>'
        );

      chartElement
        .append('label')
        .style({
          position: 'absolute',
          left: '0%',
          top: '45%',
          width: '100%',
          'font-family': 'inter',
          'font-size': '30px',
          color: '#f16464',
          'text-align': 'center',
        })
        .text('No Records!');
    } else {
      if (this.value == 1) {
        this.wigetBackgroundColor = this.backColor;
        // if(this.selectedChartsColorSet == "one"){
        // 	this.wigetBackgroundColor = '#ffffff';
        // }
        // if(this.selectedChartsColorSet == "two"){
        // 	this.wigetBackgroundColor = '#fff2cc';
        // }
        // if(this.selectedChartsColorSet == "three"){
        // 	this.wigetBackgroundColor = '#cfe2f3';
        // }
      }
      if (this.value == 2) {
        this.wigetBackgroundColor = '#ebb9b9';
      }
      let xAxisFormatted = '';
      if (
        widget.yAxis.charAt(0) == '[' &&
        widget.yAxis.charAt(widget.yAxis.length - 1) == ']'
      ) {
        xAxisFormatted = JSON.parse(widget.yAxis)[0];
      } else {
        xAxisFormatted = widget.yAxis;
      }

      let yAxisFormatted = '';
      if (
        widget.xAxis.charAt(0) == '[' &&
        widget.xAxis.charAt(widget.xAxis.length - 1) == ']'
      ) {
        yAxisFormatted = JSON.parse(widget.xAxis)[0];
      } else {
        yAxisFormatted = widget.xAxis;
      }

      let options = {
        data: widgetdetails.body?.groupedQuery?.records ? widgetdetails.body?.groupedQuery?.records : widgetdetails.body.records,
        keys: {
          label: xAxisFormatted,
          value: yAxisFormatted,
          toolTipLabel:
            widget.chartPlot.split(',') > 0 ? widget.chartPlot.split(',') : [],
        },
        chartTitle: widget.chartTitle,
        showTitle: true,
        series: this.seriesVal,
        legend: this.legendvalue,
        colorSet: this.selectedChartsColorSet, //"one" or "two" or "three";
        BGcolor: this.wigetBackgroundColor,
        resizeButtonID: document.getElementById('resizeBtn').id,
      };
      setTimeout(() => {
        acinfotech.bellCurveChart(chartElement, options);
      }, 1000);
    }
  }
  areaChart(chartElement, widget, widgetdetails) {
    let fetchedData =
      widgetdetails.body.records == null
        ? 'No Records!'
        : widgetdetails.body.records.length > 0
          ? widgetdetails.body.records
          : 'No Records!';

    if (fetchedData == 'No Records!') {
      chartElement
        .append('div')
        .style({
          position: 'absolute',
          left: '0%',
          top: '0%',
          width: '100%',
          height: '8%',
          'background-color': this.wigetBackgroundColor,
          'text-indent': '6px',
        })
        .html(
          '<div  style="width: 100%; height:0%; background-color:{{ this.wigetBackgroundColor}}; position: absolute; top:10.0%; font-size:16px; font-family:inter; color:#00162E;">' +
          widget.chartTitle +
          '</div>'
        );

      chartElement
        .append('label')
        .style({
          position: 'absolute',
          left: '0%',
          top: '45%',
          width: '100%',
          'font-family': 'inter',
          'font-size': '30px',
          color: '#f16464',
          'text-align': 'center',
        })
        .text('No Records!');
    } else {
      if (this.value == 1) {
        this.wigetBackgroundColor = this.backColor;
        // if(this.selectedChartsColorSet == "one"){
        // 	this.wigetBackgroundColor = '#ffffff';
        // }
        // if(this.selectedChartsColorSet == "two"){
        // 	this.wigetBackgroundColor = '#fff2cc';
        // }
        // if(this.selectedChartsColorSet == "three"){
        // 	this.wigetBackgroundColor = '#cfe2f3';
        // }
      }
      if (this.value == 2) {
        this.wigetBackgroundColor = '#ebb9b9';
      }
      let xAxis = '',
        xAxisArr = [];
      if (
        widget.yAxis.charAt(0) == '[' &&
        widget.yAxis.charAt(widget.yAxis.length - 1) == ']'
      ) {
        xAxis = JSON.parse(widget.yAxis);
        if (xAxis.length > 1) {
          xAxisArr = xAxis as any;
        } else {
          xAxis = JSON.parse(widget.yAxis)[0];
        }
      } else {
        xAxis = widget.yAxis;
      }

      if (xAxisArr.length > 0) {
        let yAxis = '';
        if (
          widget.xAxis.charAt(0) == '[' &&
          widget.xAxis.charAt(widget.xAxis.length - 1) == ']'
        ) {
          yAxis = JSON.parse(widget.xAxis)[0];
        } else {
          yAxis = widget.xAxis;
        }

        let options = {
          data: widgetdetails.body?.groupedQuery?.records ? widgetdetails.body?.groupedQuery?.records : widgetdetails.body.records,
          dataForTable: widgetdetails.body?.records,
          isDataDetailedForTable: false,
          keys: {
            label: xAxisArr, // X-Axis labels
            areas: yAxis,
          },
          chartTitle: widget.chartTitle,
          xAxisLable: yAxis,
          yAxisLable: '',
          showTitle: true,
          series: this.seriesVal,
          legend: this.legendvalue,
          colorSet: this.selectedChartsColorSet, //"one" or "two" or "three";
          BGcolor: this.wigetBackgroundColor,
          lineStyle: 'cardinal', // "cardinal" or "linear" or "step"
          drillDownColumnName: widget.drillThru[0]?.drillDownColumn
            ? widget.drillThru[0].drillDownColumn
            : '',
          showTable: false,
          showComments: false,
          comments: widget.comments,
          resizeButtonID: document.getElementById('resizeBtn').id,
        };

        setTimeout(() => {
          acinfotech.areaChart2(chartElement, options);
        }, 1000);
      } else {
        let options = {
          data: widgetdetails.body?.groupedQuery?.records ? widgetdetails.body?.groupedQuery?.records : widgetdetails.body.records,
          dataForTable: widgetdetails.body?.records,
          isDataDetailedForTable: false,
          keys: {
            label: xAxis, // X-Axis labels
            areas: JSON.parse(widget.xAxis),
          },
          chartTitle: widget.chartTitle,
          xAxisLable: xAxis,
          yAxisLable: '',
          showTitle: true,
          series: this.seriesVal,
          legend: this.legendvalue,
          colorSet: this.selectedChartsColorSet, //"one" or "two" or "three";
          BGcolor: this.wigetBackgroundColor,
          // colorSet: this.selectedChartsColorSet, //"one" or "two" or "three";
          lineStyle: 'cardinal', // "cardinal" or "linear" or "step"
          drillDownColumnName: widget.drillThru[0]?.drillDownColumn
            ? widget.drillThru[0].drillDownColumn
            : '',
          showTable: false,
          showComments: false,
          comments: widget.comments,
          resizeButtonID: document.getElementById('resizeBtn').id,
        };

        setTimeout(() => {
          acinfotech.areaChart(chartElement, options);
        }, 1000);
      }
    }
  }
  groupedBarChart(chartElement, widget, widgetdetails) {
    let fetchedData =
      widgetdetails?.body?.records == null
        ? 'No Records!'
        : widgetdetails.body.records.length > 0
          ? widgetdetails.body.records
          : 'No Records!';

    if (fetchedData == 'No Records!') {
      chartElement
        .append('div')
        .style({
          position: 'absolute',
          left: '0%',
          top: '0%',
          width: '100%',
          height: '8%',
          'background-color': this.wigetBackgroundColor,
          'text-indent': '6px',
        })
        .html(
          '<div  style="width: 100%; height:0%; background-color:{{ this.wigetBackgroundColor}}; position: absolute; top:10.0%; font-size:16px; font-family:inter; color:#00162E;">' +
          widget.chartTitle +
          '</div>'
        );

      chartElement
        .append('label')
        .style({
          position: 'absolute',
          left: '0%',
          top: '45%',
          width: '100%',
          'font-family': 'inter',
          'font-size': '30px',
          color: '#f16464',
          'text-align': 'center',
        })
        .text('No Records!');
    } else {
      if (this.value == 1) {
        this.wigetBackgroundColor = this.backColor;
        // if(this.selectedChartsColorSet == "one"){
        // 	this.wigetBackgroundColor = '#ffffff';
        // }
        // if(this.selectedChartsColorSet == "two"){
        // 	this.wigetBackgroundColor = '#fff2cc';
        // }
        // if(this.selectedChartsColorSet == "three"){
        // 	this.wigetBackgroundColor = '#cfe2f3';
        // }
      }
      if (this.value == 2) {
        this.wigetBackgroundColor = '#E1CCB6';
      }

      let xAxisFormatted = '';
      if (
        widget.yAxis.charAt(0) == '[' &&
        widget.yAxis.charAt(widget.yAxis.length - 1) == ']'
      ) {
        xAxisFormatted = JSON.parse(widget.yAxis)[0];
      } else {
        xAxisFormatted = widget.yAxis;
      }

      let options = {
        data: widgetdetails.body?.groupedQuery?.records ? widgetdetails.body?.groupedQuery?.records : widgetdetails.body.records,
        dataForDrillDown: widgetdetails.body?.groupedQuery?.records ? widgetdetails.body?.records : [],
        dataForTable: widgetdetails.body?.records,
        isDataDetailedForTable: false,
        keys: {
          label: xAxisFormatted,
          groupBars: JSON.parse(widget.xAxis),
        },
        chartTitle: widget.chartTitle,
        xAxisLable: xAxisFormatted,
        yAxisLable: '',
        showTitle: true,
        series: this.seriesVal,
        legend: this.legendvalue,
        colorSet: this.selectedChartsColorSet, //"one" or "two" or "three";
        BGcolor: this.wigetBackgroundColor,
        drillDownColumnName: widget.drillThru[0]?.drillDownColumn
          ? widget.drillThru[0].drillDownColumn
          : '',
        showTable: false,
        showComments: false,
        comments: widget.comments,
        resizeButtonID: document.getElementById('resizeBtn').id,
      };

      setTimeout(() => {
        acinfotech.groupedBarChart(chartElement, options);
      }, 1000);
    }
  }
  horizontalGroupedBarChart(chartElement, widget, widgetdetails) {
    let fetchedData =
      widgetdetails.body.records == null
        ? 'No Records!'
        : widgetdetails.body.records.length > 0
          ? widgetdetails.body.records
          : 'No Records!';

    if (fetchedData == 'No Records!') {
      chartElement
        .append('div')
        .style({
          position: 'absolute',
          left: '0%',
          top: '0%',
          width: '100%',
          height: '8%',
          'background-color': this.wigetBackgroundColor,
          'text-indent': '6px',
        })
        .html(
          '<div  style="width: 100%; height:0%; background-color:{{ this.wigetBackgroundColor}}; position: absolute; top:10.0%; font-size:16px; font-family:inter; color:#00162E;">' +
          widget.chartTitle +
          '</div>'
        );

      chartElement
        .append('label')
        .style({
          position: 'absolute',
          left: '0%',
          top: '45%',
          width: '100%',
          'font-family': 'inter',
          'font-size': '30px',
          color: '#f16464',
          'text-align': 'center',
        })
        .text('No Records!');
    } else {
      if (this.value == 1) {
        this.wigetBackgroundColor = this.backColor;
        // if(this.selectedChartsColorSet == "one"){
        // 	this.wigetBackgroundColor = '#ffffff';
        // }
        // if(this.selectedChartsColorSet == "two"){
        // 	this.wigetBackgroundColor = '#fff2cc';
        // }
        // if(this.selectedChartsColorSet == "three"){
        // 	this.wigetBackgroundColor = '#cfe2f3';
        // }
      }
      if (this.value == 2) {
        this.wigetBackgroundColor = '#ffd670';
      }
      let xAxisFormatted = '';
      if (
        widget.yAxis.charAt(0) == '[' &&
        widget.yAxis.charAt(widget.yAxis.length - 1) == ']'
      ) {
        xAxisFormatted = JSON.parse(widget.yAxis)[0];
      } else {
        xAxisFormatted = widget.yAxis;
      }

      let options = {
        data: widgetdetails.body?.groupedQuery?.records ? widgetdetails.body?.groupedQuery?.records : widgetdetails.body.records,
        dataForDrillDown: widgetdetails.body?.groupedQuery?.records ? widgetdetails.body?.records : [],
        dataForTable: widgetdetails.body?.records,
        isDataDetailedForTable: false,
        keys: {
          label: xAxisFormatted,
          groupBars: JSON.parse(widget.xAxis),
        },
        chartTitle: widget.chartTitle,
        xAxisLable: '',
        yAxisLable: xAxisFormatted,
        showTitle: true,
        series: this.seriesVal,
        legend: this.legendvalue,
        colorSet: this.selectedChartsColorSet,
        BGcolor: this.wigetBackgroundColor, //"one" or "two" or "three";
        drillDownColumnName: widget.drillThru[0]?.drillDownColumn
          ? widget.drillThru[0].drillDownColumn
          : '',
        showTable: false,
        showComments: false,
        comments: widget.comments,
        resizeButtonID: document.getElementById('resizeBtn').id,
      };

      setTimeout(() => {
        acinfotech.horizontalGroupedBarChart(chartElement, options);
      }, 1000);
    }
  }
  //horizontalOppositeBarChart(chartElement,widget,widgetdetails){}
  //verticalOppositeBarChart(chartElement,widget,widgetdetails){}
  dotGauge(chartElement, widget, widgetdetails) {
    if (this.value == 1) {
      this.wigetBackgroundColor = this.backColor;
      // if(this.selectedChartsColorSet == "one"){
      // 	this.wigetBackgroundColor = '#ffffff';
      // }
      // if(this.selectedChartsColorSet == "two"){
      // 	this.wigetBackgroundColor = '#fff2cc';
      // }
      // if(this.selectedChartsColorSet == "three"){
      // 	this.wigetBackgroundColor = '#cfe2f3';
      // }
    }
    if (this.value == 2) {
      this.wigetBackgroundColor = '#ebb9b9';
    }
    let options = {
      data:
        widgetdetails.body.records == null
          ? widget.chartPlot
          : widgetdetails.body.records[0][widget.yAxis],
      chartTitle: widget.chartTitle,
      BGcolor: this.wigetBackgroundColor,
      showTitle: true,
      resizeButtonID: document.getElementById('resizeBtn').id,
    };
    setTimeout(() => {
      acinfotech.dotGauge(chartElement, options);
    }, 1000);
  }
  //squareBubbleChart(chartElement,widget,widgetdetails){}
  //magicQuadrant(chartElement,widget,widgetdetails){}
  //funnelChart(chartElement,widget,widgetdetails){}
  columnAndLinesChart(chartElement, widget, widgetdetails) {
    let fetchedData =
      widgetdetails.body.records == null
        ? 'No Records!'
        : widgetdetails.body.records.length > 0
          ? widgetdetails.body.records
          : 'No Records!';

    if (fetchedData == 'No Records!') {
      chartElement
        .append('div')
        .style({
          position: 'absolute',
          left: '0%',
          top: '0%',
          width: '100%',
          height: '8%',
          'background-color': this.wigetBackgroundColor,
          'text-indent': '6px',
        })
        .html(
          '<div  style="width: 100%; height:0%; background-color:{{ this.wigetBackgroundColor}}; position: absolute; top:10.0%; font-size:16px; font-family:inter; color:#00162E;">' +
          widget.chartTitle +
          '</div>'
        );

      chartElement
        .append('label')
        .style({
          position: 'absolute',
          left: '0%',
          top: '45%',
          width: '100%',
          'font-family': 'inter',
          'font-size': '30px',
          color: '#f16464',
          'text-align': 'center',
        })
        .text('No Records!');
    } else {
      if (this.value == 1) {
        this.wigetBackgroundColor = this.backColor;
        // if(this.selectedChartsColorSet == "one"){
        // 	this.wigetBackgroundColor = '#ffffff';
        // }
        // if(this.selectedChartsColorSet == "two"){
        // 	this.wigetBackgroundColor = '#fff2cc';
        // }
        // if(this.selectedChartsColorSet == "three"){
        // 	this.wigetBackgroundColor = '#cfe2f3';
        // }
      }
      if (this.value == 2) {
        this.wigetBackgroundColor = '#ffd670';
      }

      let xAxisFormatted = '';
      if (
        widget.yAxis.charAt(0) == '[' &&
        widget.yAxis.charAt(widget.yAxis.length - 1) == ']'
      ) {
        xAxisFormatted = JSON.parse(widget.yAxis)[0];
      } else {
        xAxisFormatted = widget.yAxis;
      }

      let options = {
        data: widgetdetails.body?.groupedQuery?.records ? widgetdetails.body?.groupedQuery?.records : widgetdetails.body.records,
        dataForTable: widgetdetails.body?.records,
        isDataDetailedForTable: false,
        keys: {
          label: xAxisFormatted, // X-Axis labels
          valueForColumn: JSON.parse(widget.xAxis)[0], // Single Value - left y axis
          valueForLines: JSON.parse(widget.xAxis).slice(1), // multiple value (array) - right y axis
          toolTipLabel:
            widget.chartPlot.split(',') > 0 ? widget.chartPlot.split(',') : [],
        },
        chartTitle: widget.chartTitle,
        xAxisLable: xAxisFormatted,
        //yAxisLable: "",
        showTitle: true,
        series: this.seriesVal,
        legend: this.legendvalue,
        BGcolor: this.wigetBackgroundColor,
        colorSet: this.selectedChartsColorSet, //"one" or "two" or "three";
        lineStyle: 'cardinal', // "cardinal" or "linear" or "step"
        drillDownColumnName: widget.drillThru[0]?.drillDownColumn
          ? widget.drillThru[0].drillDownColumn
          : '',
        showTable: false,
        showComments: false,
        comments: widget.comments,
        resizeButtonID: document.getElementById('resizeBtn').id,
      };

      setTimeout(() => {
        acinfotech.columnAndLinesChart(chartElement, options);
      }, 1000);
    }
  }
  threeXAxisesGroupedBarChart(chartElement, widget, widgetdetails) {
    let fetchedData =
      widgetdetails.body.records == null
        ? 'No Records!'
        : widgetdetails.body.records.length > 0
          ? widgetdetails.body.records
          : 'No Records!';

    if (fetchedData == 'No Records!') {
      chartElement
        .append('div')
        .style({
          position: 'absolute',
          left: '0%',
          top: '0%',
          width: '100%',
          height: '8%',
          'background-color': this.wigetBackgroundColor,
          'text-indent': '6px',
        })
        .html(
          '<div  style="width: 100%; height:0%; background-color:{{ this.wigetBackgroundColor}}; position: absolute; top:10.0%; font-size:16px; font-family:inter; color:#00162E;">' +
          widget.chartTitle +
          '</div>'
        );

      chartElement
        .append('label')
        .style({
          position: 'absolute',
          left: '0%',
          top: '45%',
          width: '100%',
          'font-family': 'inter',
          'font-size': '30px',
          color: '#f16464',
          'text-align': 'center',
        })
        .text('No Records!');
    } else {
      if (this.value == 1) {
        this.wigetBackgroundColor = this.backColor;
        // if(this.selectedChartsColorSet == "one"){
        // 	this.wigetBackgroundColor = '#ffffff';
        // }
        // if(this.selectedChartsColorSet == "two"){
        // 	this.wigetBackgroundColor = '#fff2cc';
        // }
        // if(this.selectedChartsColorSet == "three"){
        // 	this.wigetBackgroundColor = '#cfe2f3';
        // }
      }
      if (this.value == 2) {
        this.wigetBackgroundColor = '#ebb9b9';
      }
      let options = {
        data: widgetdetails.body?.groupedQuery?.records ? widgetdetails.body?.groupedQuery?.records : widgetdetails.body.records,
        keys: {
          labels: JSON.parse(widget.yAxis),
          groupBars: JSON.parse(widget.xAxis),
          toolTipLabel:
            widget.chartPlot.split(',') > 0 ? widget.chartPlot.split(',') : [],
        },
        chartTitle: widget.chartTitle,
        xAxisLables: JSON.parse(widget.yAxis),
        yAxisLable: '',
        showTitle: true,
        series: this.seriesVal,
        legend: this.legendvalue,
        colorSet: this.selectedChartsColorSet, //"one" or "two" or "three";
        BGcolor: this.wigetBackgroundColor,
        showComments: false,
        comments: widget.comments,
        resizeButtonID: document.getElementById('resizeBtn').id,
      };
      setTimeout(() => {
        acinfotech.threeXAxisesGroupedBarChart(chartElement, options);
      }, 1000);
    }
  }
  twoXAxisesGroupedBarChart(chartElement, widget, widgetdetails) {
    let fetchedData =
      widgetdetails.body.records == null
        ? 'No Records!'
        : widgetdetails.body.records.length > 0
          ? widgetdetails.body.records
          : 'No Records!';

    if (fetchedData == 'No Records!') {
      chartElement
        .append('div')
        .style({
          position: 'absolute',
          left: '0%',
          top: '0%',
          width: '100%',
          height: '8%',
          'background-color': this.wigetBackgroundColor,
          'text-indent': '6px',
        })
        .html(
          '<div  style="width: 100%; height:0%; background-color:{{ this.wigetBackgroundColor}}; position: absolute; top:10.0%; font-size:16px; font-family:inter; color:#00162E;">' +
          widget.chartTitle +
          '</div>'
        );

      chartElement
        .append('label')
        .style({
          position: 'absolute',
          left: '0%',
          top: '45%',
          width: '100%',
          'font-family': 'inter',
          'font-size': '30px',
          color: '#f16464',
          'text-align': 'center',
        })
        .text('No Records!');
    } else {
      if (this.value == 1) {
        this.wigetBackgroundColor = this.backColor;
        // if(this.selectedChartsColorSet == "one"){
        // 	this.wigetBackgroundColor = '#ffffff';
        // }
        // if(this.selectedChartsColorSet == "two"){
        // 	this.wigetBackgroundColor = '#fff2cc';
        // }
        // if(this.selectedChartsColorSet == "three"){
        // 	this.wigetBackgroundColor = '#cfe2f3';
        // }
      }
      if (this.value == 2) {
        this.wigetBackgroundColor = '#ebb9b9';
      }
      let options = {
        data: widgetdetails.body?.groupedQuery?.records ? widgetdetails.body?.groupedQuery?.records : widgetdetails.body.records,
        dataForDrillDown: widgetdetails.body?.groupedQuery?.records ? widgetdetails.body?.records : [],
        keys: {
          labels: JSON.parse(widget.yAxis),
          groupBars: JSON.parse(widget.xAxis),
          toolTipLabel:
            widget.chartPlot.split(',') > 0 ? widget.chartPlot.split(',') : [],
        },
        chartTitle: widget.chartTitle,
        xAxisLables: JSON.parse(widget.yAxis),
        //yAxisLable: "",
        showTitle: true,
        series: this.seriesVal,
        legend: this.legendvalue,
        colorSet: this.selectedChartsColorSet, //"one" or "two" or "three";
        BGcolor: this.wigetBackgroundColor,
        drillDownColumnName: widget.drillThru[0]?.drillDownColumn
          ? widget.drillThru[0].drillDownColumn
          : '',
        showComments: false,
        comments: widget.comments,
        resizeButtonID: document.getElementById('resizeBtn').id,
      };
      setTimeout(() => {
        acinfotech.twoXAxisesGroupedBarChart(chartElement, options);
      }, 1000);
    }
  }
  bellCurveChartWithMinAndMaxRange(chartElement, widget, widgetdetails) {
    let fetchedData =
      widgetdetails.body.records == null
        ? 'No Records!'
        : widgetdetails.body.records.length > 0
          ? widgetdetails.body.records
          : 'No Records!';

    if (fetchedData == 'No Records!') {
      chartElement
        .append('div')
        .style({
          position: 'absolute',
          left: '0%',
          top: '0%',
          width: '100%',
          height: '8%',
          'background-color': this.wigetBackgroundColor,
          'text-indent': '6px',
        })
        .html(
          '<div  style="width: 100%; height:0%; background-color:{{ this.wigetBackgroundColor}}; position: absolute; top:10.0%; font-size:16px; font-family:inter; color:#00162E;">' +
          widget.chartTitle +
          '</div>'
        );

      chartElement
        .append('label')
        .style({
          position: 'absolute',
          left: '0%',
          top: '45%',
          width: '100%',
          'font-family': 'inter',
          'font-size': '30px',
          color: '#f16464',
          'text-align': 'center',
        })
        .text('No Records!');
    } else {
      if (this.value == 1) {
        this.wigetBackgroundColor = this.backColor;
        // if(this.selectedChartsColorSet == "one"){
        // 	this.wigetBackgroundColor = '#ffffff';
        // }
        // if(this.selectedChartsColorSet == "two"){
        // 	this.wigetBackgroundColor = '#fff2cc';
        // }
        // if(this.selectedChartsColorSet == "three"){
        // 	this.wigetBackgroundColor = '#cfe2f3';
        // }
      }
      if (this.value == 2) {
        this.wigetBackgroundColor = '#ebb9b9';
      }
      let options = {
        data: widgetdetails.body?.groupedQuery?.records ? widgetdetails.body?.groupedQuery?.records : widgetdetails.body.records,
        keys: {
          label: JSON.parse(widget.yAxis[0]),
          value: JSON.parse(widget.xAxis[0]),
          minValue: JSON.parse(widget.xAxis[1]),
          maxValue: JSON.parse(widget.xAxis[2]),
          minAndMaxLines: JSON.parse(widget.yAxis[0]),
          toolTipLabel:
            widget.chartPlot.split(',') > 0 ? widget.chartPlot.split(',') : [],
        },
        chartTitle: widget.chartTitle,
        showTitle: true,
        series: this.seriesVal,
        legend: this.legendvalue,
        colorSet: this.selectedChartsColorSet, //"one" or "two" or "three";
        BGcolor: this.wigetBackgroundColor,
        resizeButtonID: document.getElementById('resizeBtn').id,
      };

      setTimeout(() => {
        acinfotech.bellCurveChartWithMinAndMaxRange(chartElement, options);
      }, 1000);
    }
  }
  stackedGroupedBarChart(chartElement, widget, widgetdetails) {
    let fetchedData =
      widgetdetails.body.records == null
        ? 'No Records!'
        : widgetdetails.body.records.length > 0
          ? widgetdetails.body.records
          : 'No Records!';

    if (fetchedData == 'No Records!') {
      chartElement
        .append('div')
        .style({
          position: 'absolute',
          left: '0%',
          top: '0%',
          width: '100%',
          height: '8%',
          'background-color': this.wigetBackgroundColor,
          'text-indent': '6px',
        })
        .html(
          '<div  style="width: 100%; height:0%; background-color:{{ this.wigetBackgroundColor}}; position: absolute; top:10.0%; font-size:16px; font-family:inter; color:#00162E;">' +
          widget.chartTitle +
          '</div>'
        );

      chartElement
        .append('label')
        .style({
          position: 'absolute',
          left: '0%',
          top: '45%',
          width: '100%',
          'font-family': 'inter',
          'font-size': '30px',
          color: '#f16464',
          'text-align': 'center',
        })
        .text('No Records!');
    } else {
      if (this.value == 1) {
        this.wigetBackgroundColor = this.backColor;
        // if(this.selectedChartsColorSet == "one"){
        // 	this.wigetBackgroundColor = '#ffffff';
        // }
        // if(this.selectedChartsColorSet == "two"){
        // 	this.wigetBackgroundColor = '#fff2cc';
        // }
        // if(this.selectedChartsColorSet == "three"){
        // 	this.wigetBackgroundColor = '#cfe2f3';
        // }
      }
      if (this.value == 2) {
        this.wigetBackgroundColor = '#ebb9b9';
      }
      let options = {
        data: widgetdetails.body?.groupedQuery?.records ? widgetdetails.body?.groupedQuery?.records : widgetdetails.body.records,
        keys: {
          labels: JSON.parse(widget.yAxis),
          groupBars: JSON.parse(widget.xAxis),
          toolTipLabel:
            widget.chartPlot.split(',') > 0 ? widget.chartPlot.split(',') : [],
        },
        chartTitle: widget.chartTitle,
        xAxisLables: JSON.parse(widget.yAxis),
        //yAxisLable: "",
        showTitle: true,
        series: this.seriesVal,
        legend: this.legendvalue,
        colorSet: this.selectedChartsColorSet, //"one" or "two" or "three";
        BGcolor: this.wigetBackgroundColor,
        drillDownColumnName: widget.drillThru[0]?.drillDownColumn
          ? widget.drillThru[0].drillDownColumn
          : '',
        showTable: false,
        showComments: false,
        comments: widget.comments,
        resizeButtonID: document.getElementById('resizeBtn').id,
      };

      setTimeout(() => {
        acinfotech.stackedGroupedBarChart(chartElement, options);
      }, 1000);
    }
  }
  stackedGroupedBarsWithLineChart(chartElement, widget, widgetdetails) {
    let fetchedData =
      widgetdetails.body.records == null
        ? 'No Records!'
        : widgetdetails.body.records.length > 0
          ? widgetdetails.body.records
          : 'No Records!';

    if (fetchedData == 'No Records!') {
      chartElement
        .append('div')
        .style({
          position: 'absolute',
          left: '0%',
          top: '0%',
          width: '100%',
          height: '8%',
          'background-color': this.wigetBackgroundColor,
          'text-indent': '6px',
        })
        .html(
          '<div  style="width: 100%; height:0%; background-color:{{ this.wigetBackgroundColor}}; position: absolute; top:10.0%; font-size:16px; font-family:inter; color:#00162E;">' +
          widget.chartTitle +
          '</div>'
        );

      chartElement
        .append('label')
        .style({
          position: 'absolute',
          left: '0%',
          top: '45%',
          width: '100%',
          'font-family': 'inter',
          'font-size': '30px',
          color: '#f16464',
          'text-align': 'center',
        })
        .text('No Records!');
    } else {
      if (this.value == 1) {
        this.wigetBackgroundColor = this.backColor;
        // if(this.selectedChartsColorSet == "one"){
        // 	this.wigetBackgroundColor = '#ffffff';
        // }
        // if(this.selectedChartsColorSet == "two"){
        // 	this.wigetBackgroundColor = '#fff2cc';
        // }
        // if(this.selectedChartsColorSet == "three"){
        // 	this.wigetBackgroundColor = '#cfe2f3';
        // }
      }
      if (this.value == 2) {
        this.wigetBackgroundColor = '#ebb9b9';
      }
      let options = {
        data: widgetdetails.body?.groupedQuery?.records ? widgetdetails.body?.groupedQuery?.records : widgetdetails.body.records,
        keys: {
          labels: JSON.parse(widget.yAxis),
          groupBars: JSON.parse(widget.xAxis),
          lines: JSON.parse(widget.secondaryYaxis),
          toolTipLabel:
            widget.chartPlot.split(',') > 0 ? widget.chartPlot.split(',') : [],
        },
        chartTitle: widget.chartTitle,
        xAxisLables: JSON.parse(widget.yAxis),
        //yAxisLable: "",
        showTitle: true,
        series: this.seriesVal,
        legend: this.legendvalue,
        colorSet: this.selectedChartsColorSet, //"one" or "two" or "three";
        BGcolor: this.wigetBackgroundColor,
        drillDownColumnName: widget.drillThru[0]?.drillDownColumn
          ? widget.drillThru[0].drillDownColumn
          : '',
        showTable: false,
        showComments: false,
        comments: widget.comments,
        resizeButtonID: document.getElementById('resizeBtn').id,
      };

      setTimeout(() => {
        acinfotech.stackedGroupedBarsWithLineChart(chartElement, options);
      }, 1000);
    }
  }
  stackedBarWithLinesChart(chartElement, widget, widgetdetails) {
    let fetchedData =
      widgetdetails.body.records == null
        ? 'No Records!'
        : widgetdetails.body.records.length > 0
          ? widgetdetails.body.records
          : 'No Records!';

    if (fetchedData == 'No Records!') {
      chartElement
        .append('div')
        .style({
          position: 'absolute',
          left: '0%',
          top: '0%',
          width: '100%',
          height: '8%',
          'background-color': this.wigetBackgroundColor,
          'text-indent': '6px',
        })
        .html(
          '<div  style="width: 100%; height:0%; background-color:{{ this.wigetBackgroundColor}}; position: absolute; top:10.0%; font-size:16px; font-family:inter; color:#00162E;">' +
          widget.chartTitle +
          '</div>'
        );

      chartElement
        .append('label')
        .style({
          position: 'absolute',
          left: '0%',
          top: '45%',
          width: '100%',
          'font-family': 'inter',
          'font-size': '30px',
          color: '#f16464',
          'text-align': 'center',
        })
        .text('No Records!');
    } else {
      if (this.value == 1) {
        this.wigetBackgroundColor = this.backColor;
        // if(this.selectedChartsColorSet == "one"){
        // 	this.wigetBackgroundColor = '#ffffff';
        // }
        // if(this.selectedChartsColorSet == "two"){
        // 	this.wigetBackgroundColor = '#fff2cc';
        // }
        // if(this.selectedChartsColorSet == "three"){
        // 	this.wigetBackgroundColor = '#cfe2f3';
        // }
      }
      if (this.value == 2) {
        this.wigetBackgroundColor = '#ebb9b9';
      }
      let xAxisFormatted = '';
      if (
        widget.yAxis.charAt(0) == '[' &&
        widget.yAxis.charAt(widget.yAxis.length - 1) == ']'
      ) {
        xAxisFormatted = JSON.parse(widget.yAxis)[0];
      } else {
        xAxisFormatted = widget.yAxis;
      }

      let options = {
        data: widgetdetails.body?.groupedQuery?.records ? widgetdetails.body?.groupedQuery?.records : widgetdetails.body.records,
        dataForTable: widgetdetails.body?.records,
        isDataDetailedForTable: false,
        keys: {
          label: xAxisFormatted,
          stackedBars: JSON.parse(widget.xAxis),
          lines: JSON.parse(widget.secondaryYaxis),
          toolTipLabel:
            widget.chartPlot.split(',') > 0 ? widget.chartPlot.split(',') : [],
        },
        chartTitle: widget.chartTitle,
        xAxisLable: xAxisFormatted,
        yAxisLable: '',
        showTitle: true,
        series: this.seriesVal,
        legend: this.legendvalue,
        colorSet: this.selectedChartsColorSet, //"one" or "two" or "three";
        BGcolor: this.wigetBackgroundColor,
        drillDownColumnName: widget.drillThru[0]?.drillDownColumn
          ? widget.drillThru[0].drillDownColumn
          : '',
        showTable: false,
        showComments: false,
        comments: widget.comments,
        resizeButtonID: document.getElementById('resizeBtn').id,
      };

      setTimeout(() => {
        acinfotech.stackedBarWithLinesChart(chartElement, options);
      }, 1000);
    }
  }
  trafficLightsChart(chartElement, widget, widgetdetails) {
    let fetchedData =
      widgetdetails.body.records == null
        ? 'No Records!'
        : widgetdetails.body.records.length > 0
          ? widgetdetails.body.records
          : 'No Records!';

    if (fetchedData == 'No Records!') {
      chartElement
        .append('div')
        .style({
          position: 'absolute',
          left: '0%',
          top: '0%',
          width: '100%',
          height: '8%',
          'background-color': this.wigetBackgroundColor,
          'text-indent': '6px',
        })
        .html(
          '<div  style="width: 100%; height:0%; background-color:{{ this.wigetBackgroundColor}}; position: absolute; top:10.0%; font-size:16px; font-family:inter; color:#00162E;">' +
          widget.chartTitle +
          '</div>'
        );

      chartElement
        .append('label')
        .style({
          position: 'absolute',
          left: '0%',
          top: '45%',
          width: '100%',
          'font-family': 'inter',
          'font-size': '30px',
          color: '#f16464',
          'text-align': 'center',
        })
        .text('No Records!');
    } else {
      if (this.value == 1) {
        this.wigetBackgroundColor = this.backColor;
        // if(this.selectedChartsColorSet == "one"){
        // 	this.wigetBackgroundColor = '#ffffff';
        // }
        // if(this.selectedChartsColorSet == "two"){
        // 	this.wigetBackgroundColor = '#fff2cc';
        // }
        // if(this.selectedChartsColorSet == "three"){
        // 	this.wigetBackgroundColor = '#cfe2f3';
        // }
      }
      if (this.value == 2) {
        this.wigetBackgroundColor = '#ebb9b9';
      }
      let xAxisFormatted = '';
      if (
        widget.yAxis.charAt(0) == '[' &&
        widget.yAxis.charAt(widget.yAxis.length - 1) == ']'
      ) {
        xAxisFormatted = JSON.parse(widget.yAxis)[0];
      } else {
        xAxisFormatted = widget.yAxis;
      }

      let yAxisFormatted = '';
      if (
        widget.xAxis.charAt(0) == '[' &&
        widget.xAxis.charAt(widget.xAxis.length - 1) == ']'
      ) {
        yAxisFormatted = JSON.parse(widget.xAxis)[0];
      } else {
        yAxisFormatted = widget.xAxis;
      }

      let options = {
        data: widgetdetails.body?.groupedQuery?.records ? widgetdetails.body?.groupedQuery?.records : widgetdetails.body.records,
        dataForTable: widgetdetails.body?.records,
        isDataDetailedForTable: false,
        keys: {
          label: xAxisFormatted,
          value: yAxisFormatted,
          toolTipLabel:
            widget.chartPlot.split(',') > 0 ? widget.chartPlot.split(',') : [],
        },
        chartTitle: widget.chartTitle,
        showTitle: true,
        series: this.seriesVal,
        legend: this.legendvalue,
        BGcolor: this.wigetBackgroundColor,
        drillDownColumnName: widget.drillThru[0]?.drillDownColumn
          ? widget.drillThru[0].drillDownColumn
          : '',
        showTable: false,
        showComments: false,
        comments: widget.comments,
        resizeButtonID: document.getElementById('resizeBtn').id,
      };

      setTimeout(() => {
        acinfotech.trafficLightsChart(chartElement, options);
      }, 1000);
    }
  }
  columnChartWithNegativeValues(chartElement, widget, widgetdetails) {
    let fetchedData =
      widgetdetails.body.records == null
        ? 'No Records!'
        : widgetdetails.body.records.length > 0
          ? widgetdetails.body.records
          : 'No Records!';

    if (fetchedData == 'No Records!') {
      chartElement
        .append('div')
        .style({
          position: 'absolute',
          left: '0%',
          top: '0%',
          width: '100%',
          height: '8%',
          'background-color': this.wigetBackgroundColor,
          'text-indent': '6px',
        })
        .html(
          '<div  style="width: 100%; height:0%; background-color:{{ this.wigetBackgroundColor}}; position: absolute; top:10.0%; font-size:16px; font-family:inter; color:#00162E;">' +
          widget.chartTitle +
          '</div>'
        );

      chartElement
        .append('label')
        .style({
          position: 'absolute',
          left: '0%',
          top: '45%',
          width: '100%',
          'font-family': 'inter',
          'font-size': '30px',
          color: '#f16464',
          'text-align': 'center',
        })
        .text('No Records!');
    } else {
      if (this.value == 1) {
        this.wigetBackgroundColor = this.backColor;
        // if(this.selectedChartsColorSet == "one"){
        // 	this.wigetBackgroundColor = '#ffffff';
        // }
        // if(this.selectedChartsColorSet == "two"){
        // 	this.wigetBackgroundColor = '#fff2cc';
        // }
        // if(this.selectedChartsColorSet == "three"){
        // 	this.wigetBackgroundColor = '#cfe2f3';
        // }
      }
      if (this.value == 2) {
        this.wigetBackgroundColor = '#ebb9b9';
      }
      let xAxisFormatted = '';
      if (
        widget.yAxis.charAt(0) == '[' &&
        widget.yAxis.charAt(widget.yAxis.length - 1) == ']'
      ) {
        xAxisFormatted = JSON.parse(widget.yAxis)[0];
      } else {
        xAxisFormatted = widget.yAxis;
      }

      let yAxisFormatted = '';
      if (
        widget.xAxis.charAt(0) == '[' &&
        widget.xAxis.charAt(widget.xAxis.length - 1) == ']'
      ) {
        yAxisFormatted = JSON.parse(widget.xAxis)[0];
      } else {
        yAxisFormatted = widget.xAxis;
      }

      let options = {
        data: widgetdetails.body?.groupedQuery?.records ? widgetdetails.body?.groupedQuery?.records : widgetdetails.body.records,
        dataForTable: widgetdetails.body?.records, // for table
        isDataDetailedForTable: false,
        keys: {
          label: xAxisFormatted,
          value: yAxisFormatted,
        },
        chartTitle: widget.chartTitle,
        xAxisLable: xAxisFormatted,
        yAxisLable: yAxisFormatted,
        showTitle: true,
        series: this.seriesVal,
        legend: this.legendvalue,
        colorSet: this.selectedChartsColorSet, //one or two or three
        BGcolor: this.wigetBackgroundColor,
        drillDownColumnName: widget.drillThru[0]?.drillDownColumn
          ? widget.drillThru[0].drillDownColumn
          : '',
        showTable: false, //false - hide, true - show
        showComments: false,
        comments: widget.comments,
        resizeButtonID: document.getElementById('resizeBtn').id,
      };

      setTimeout(() => {
        acinfotech.columnChartWithNegativeValues(chartElement, options);
      }, 1000);
    }
  }
  groupedBarsWithLineChart(chartElement, widget, widgetdetails) {
    let fetchedData =
      widgetdetails.body.records == null
        ? 'No Records!'
        : widgetdetails.body.records.length > 0
          ? widgetdetails.body.records
          : 'No Records!';

    if (fetchedData == 'No Records!') {
      chartElement
        .append('div')
        .style({
          position: 'absolute',
          left: '0%',
          top: '0%',
          width: '100%',
          height: '8%',
          'background-color': this.wigetBackgroundColor,
          'text-indent': '6px',
        })
        .html(
          '<div  style="width: 100%; height:0%; background-color:{{ this.wigetBackgroundColor}}; position: absolute; top:10.0%; font-size:16px; font-family:inter; color:#00162E;">' +
          widget.chartTitle +
          '</div>'
        );

      chartElement
        .append('label')
        .style({
          position: 'absolute',
          left: '0%',
          top: '45%',
          width: '100%',
          'font-family': 'inter',
          'font-size': '30px',
          color: '#f16464',
          'text-align': 'center',
        })
        .text('No Records!');
    } else {
      if (this.value == 1) {
        this.wigetBackgroundColor = this.backColor;
        // if(this.selectedChartsColorSet == "one"){
        // 	this.wigetBackgroundColor = '#ffffff';
        // }
        // if(this.selectedChartsColorSet == "two"){
        // 	this.wigetBackgroundColor = '#fff2cc';
        // }
        // if(this.selectedChartsColorSet == "three"){
        // 	this.wigetBackgroundColor = '#cfe2f3';
        // }
      }
      if (this.value == 2) {
        this.wigetBackgroundColor = '#ebb9b9';
      }
      let xAxisFormatted = '';
      if (
        widget.yAxis.charAt(0) == '[' &&
        widget.yAxis.charAt(widget.yAxis.length - 1) == ']'
      ) {
        xAxisFormatted = JSON.parse(widget.yAxis)[0];
      } else {
        xAxisFormatted = widget.yAxis;
      }

      let options = {
        data: widgetdetails.body?.groupedQuery?.records ? widgetdetails.body?.groupedQuery?.records : widgetdetails.body.records,
        dataForTable: widgetdetails.body?.records,
        isDataDetailedForTable: false,
        keys: {
          label: xAxisFormatted,
          groupBars: JSON.parse(widget.xAxis),
          lines: JSON.parse(widget.secondaryYaxis),
        },
        chartTitle: widget.chartTitle,
        xAxisLable: xAxisFormatted,
        yAxisLable: '',
        showTitle: true,
        series: this.seriesVal,
        legend: this.legendvalue,
        colorSet: this.selectedChartsColorSet, //"one" or "two" or "three";
        BGcolor: this.wigetBackgroundColor,
        drillDownColumnName: widget.drillThru[0]?.drillDownColumn
          ? widget.drillThru[0].drillDownColumn
          : '',
        showTable: false,
        showComments: true,
        comments: widget.comments,
        resizeButtonID: document.getElementById('resizeBtn').id,
      };

      setTimeout(() => {
        acinfotech.groupedBarsWithLineChart(chartElement, options);
      }, 1000);
    }
  }
  USMapWithPins(chartElement, widget, widgetdetails) {
    let fetchedData =
      widgetdetails.body.records == null
        ? 'No Records!'
        : widgetdetails.body.records.length > 0
          ? widgetdetails.body.records
          : 'No Records!';

    if (fetchedData == 'No Records!') {
      chartElement
        .append('div')
        .style({
          position: 'absolute',
          left: '0%',
          top: '0%',
          width: '100%',
          height: '8%',
          'background-color': this.wigetBackgroundColor,
          'text-indent': '6px',
        })
        .html(
          '<div  style="width: 100%; height:0%; background-color:{{ this.wigetBackgroundColor}}; position: absolute; top:10.0%; font-size:16px; font-family:inter; color:#00162E;">' +
          widget.chartTitle +
          '</div>'
        );

      chartElement
        .append('label')
        .style({
          position: 'absolute',
          left: '0%',
          top: '45%',
          width: '100%',
          'font-family': 'inter',
          'font-size': '30px',
          color: '#f16464',
          'text-align': 'center',
        })
        .text('No Records!');
    } else {
      if (this.value == 1) {
        this.wigetBackgroundColor = this.backColor;
        // if(this.selectedChartsColorSet == "one"){
        // 	this.wigetBackgroundColor = '#ffffff';
        // }
        // if(this.selectedChartsColorSet == "two"){
        // 	this.wigetBackgroundColor = '#fff2cc';
        // }
        // if(this.selectedChartsColorSet == "three"){
        // 	this.wigetBackgroundColor = '#cfe2f3';
        // }
      }
      if (this.value == 2) {
        this.wigetBackgroundColor = '#ebb9b9';
      }
      let xAxisFormatted = '';
      if (
        widget.yAxis.charAt(0) == '[' &&
        widget.yAxis.charAt(widget.yAxis.length - 1) == ']'
      ) {
        xAxisFormatted = JSON.parse(widget.yAxis)[0];
      } else {
        xAxisFormatted = widget.yAxis;
      }

      let yAxisFormatted = '';
      if (
        widget.xAxis.charAt(0) == '[' &&
        widget.xAxis.charAt(widget.xAxis.length - 1) == ']'
      ) {
        yAxisFormatted = JSON.parse(widget.xAxis)[0];
      } else {
        yAxisFormatted = widget.xAxis;
      }

      let options = {
        data: widgetdetails.body?.groupedQuery?.records ? widgetdetails.body?.groupedQuery?.records : widgetdetails.body.records,
        dataForTable: widgetdetails.body?.records,
        keys: {
          stateCode: xAxisFormatted,
          latitude: 'LATITUDE',
          longitude: 'LONGITUDE',
          state: widget.chartPlot,
          place: yAxisFormatted,
          value: JSON.parse(widget.secondaryYaxis)[0],
        },
        chartTitle: widget.chartTitle,
        showTable: false,
        series: this.seriesVal,
        legend: this.legendvalue,
        BGcolor: this.wigetBackgroundColor,
        drillDownColumnName: widget.drillThru[0]?.drillDownColumn
          ? widget.drillThru[0].drillDownColumn
          : '',
        resizeButtonID: document.getElementById('resizeBtn').id,
      };

      setTimeout(() => {
        acinfotech.USMapWithPins(chartElement, options);
      }, 1500);
    }
  }

  //---------------------------------------------------------------------------------------------

  addSingle() {
    this.messageService.add({
      severity: 'error',
      summary: '',
      detail: this.errorMsg,
    });
  }

  clear() {
    this.messageService.clear();
  }

  changeDashboardFunc(event) {
    // this.selectedDashboard = { name: event.item.label, code: event.item.id };
    this.selectedDashboard = { name: event.label, code: event.id };
    this.getDashboard('');
    d3.select('#dashboard-scroll-wrapper').style('display', 'none');
    d3.select('#dashboard-parameterized-scroll-wrapper').style(
      'display',
      'none'
    );
    d3.select('#tibo-mydash-dashlist-container-id').style('display', 'none');
    d3.select('#tibo-mydash-dashlist-container').style('display', 'none');
    //dashListPanelToggleBool = false;
  }

  optionsFunc(event) {
    // if(event.item.id == 1){

    // 	let canvas = document.getElementsByClassName('widget-class') as HTMLCollectionOf<HTMLElement>;

    // 	if(this.moreItems[0].label == "Show widgets in full view"){
    // 		this.moreItems[0].label = "Show widgets in widget view"
    // 		this.moreItems[0].icon = "pi pi-th-large"

    // 		for(var i = 0; i < canvas.length; i++)
    // 		{
    // 			canvas[i].classList.add('full-view-widget-class');
    // 		}
    // 		window.dispatchEvent(new Event('resize'));
    // 	}
    // 	else {
    // 		this.moreItems[0].label = "Show widgets in full view"
    // 		this.moreItems[0].icon = "pi pi-external-link"

    // 		for(var i = 0; i < canvas.length; i++)
    // 		{
    // 			canvas[i].classList.remove('full-view-widget-class');
    // 		}
    // 		window.dispatchEvent(new Event('resize'));
    // 	}

    // 	//d3.selectAll(".widget-class").attr("class","widget-class-2 full-view-widget-class")
    // 	//window.dispatchEvent(new Event('resize'));

    // }
    if (event.item.id == 2) {
      if (this.moreItems[0].label == 'Turn On Page Refresh') {
        this.moreItems[0].label = 'Turn off page refresh';
        this.moreItems[0].icon = 'pi pi-times';
        this.refreshTimerFunc();
      } else {
        this.moreItems[0].label = 'Turn On Page Refresh';
        this.moreItems[0].icon = 'pi pi-refresh';
        clearInterval(this.refreshInterval);
        document.getElementById(
          'mydash-countdown-timer-label'
        ).innerHTML = '';
      }
    } else if (event.item.id == 3) {
      this.shareDashDialBoxDisplayBool = true;
    } else if (event.item.id == 4) {
      this.selectedChartsColorSet = 'one';
      //this.getDashboard("");
      this.reloadDashboardWithNewColorSetFunc();
    } else if (event.item.id == 5) {
      this.selectedChartsColorSet = 'two';
      //this.getDashboard("");
      this.reloadDashboardWithNewColorSetFunc();
    } else if (event.item.id == 6) {
      this.selectedChartsColorSet = 'three';
      //this.getDashboard("");
      this.reloadDashboardWithNewColorSetFunc();
    }
  }

  showLegend(event) {
    this.parabool = false;
    this.paraData = this.parameterElementsArray;
    if (event.item.id == 7) {
      this.SettingsResponse['seriesValue'] = this.showOrHideSeriesBool ? "Y" : "N";
      // this.isLoading = true;
      this.settingsPostSubscrip = this.dashService
        .settingsPostService(this.SettingsResponse)
        .subscribe(
          (response: any) => {
            this.isLoading = false;
          },
          (error) => {
            // this.isLoading = false;
            // if (Number(error.status) == 401) {
            //   this.errorMsg = 'Please Try After Some Time!!';
            // } else if (Number(error.status) == 500) {
            //   this.errorMsg = 'Internal Error';
            // } else {
            //   this.errorMsg = 'Please Try After Some Time!.';
            // }
            // this.addSingle();
          },
          () => { }
        );
      if (this.showOrHideSeriesBool) {
        this.seriesVal = 'block';
        event.item.label = 'Hide Series Values';
        event.item.icon = 'pi pi-eye-slash';
        this.showOrHideSeriesBool = false;
        if (this.paraData.length > 0) {
          d3.selectAll('.widget-class').style('visibility', 'hidden')
          this.dashboard();
        }
        else {
          d3.selectAll(".series_value").style("display", "block");
          this.reloadDashboardWithNewColorSetFunc();
        }
      }
      else {
        this.seriesVal = 'none';
        this.showOrHideSeriesBool = true;
        event.item.label = 'Show series Values';
        event.item.icon = 'pi pi-eye';
        if (this.paraData.length > 0) {
          d3.selectAll('.widget-class').style('visibility', 'hidden')
          this.dashboard();
        }
        else {
          d3.selectAll(".series_value").style("display", "none");
          this.reloadDashboardWithNewColorSetFunc();
        }
      }
    } else if (event.item.id == 8) {
      this.SettingsResponse['legendValue'] = this.showOrHideLegendBool ? "Y" : "N";
      // this.isLoading = true;
      this.settingsPostSubscrip = this.dashService
        .settingsPostService(this.SettingsResponse)
        .subscribe(
          (response: any) => {
            this.isLoading = false;
          },
          (error) => {
            this.isLoading = false;
            // if (Number(error.status) == 401) {
            //   this.errorMsg = 'Please Try After Some Time!!';
            // } else if (Number(error.status) == 500) {
            //   this.errorMsg = 'Internal Error';
            // } else {
            //   this.errorMsg = 'Please Try After Some Time!.';
            // }
            // this.addSingle();
          },
          () => { }
        );
      if (this.showOrHideLegendBool) {
        this.showOrHideLegendBool = false;
        this.legendvalue = 'block';
        event.item.label = 'Hide Legend Values';
        event.item.icon = 'pi pi-eye-slash';
        if (this.paraData.length > 0) {
          d3.selectAll('.widget-class').style('visibility', 'hidden')
          this.dashboard();
        }
        else {
          d3.selectAll(".legend_value").style("display", "block");
          this.reloadDashboardWithNewColorSetFunc();
        }
      } else {
        this.showOrHideLegendBool = true;
        this.legendvalue = 'none';
        event.item.label = 'Show Legend Values';
        event.item.icon = 'pi pi-eye';
        if (this.paraData.length > 0) {
          d3.selectAll('.widget-class').style('visibility', 'hidden')
          this.dashboard();
        }
        else {
          d3.selectAll(".legend_value").style("display", "none");
          this.reloadDashboardWithNewColorSetFunc();
        }
      }
    }
  }

  changeChartColorsFunc(colorSet) {
    if (colorSet == 'one') {
      this.selectedChartsColorSet = 'one';
      this.reloadDashboardWithNewColorSetFunc();
    } else if (colorSet == 'two') {
      this.selectedChartsColorSet = 'two';
      this.reloadDashboardWithNewColorSetFunc();
    } else if (colorSet == 'three') {
      this.selectedChartsColorSet = 'three';
      this.reloadDashboardWithNewColorSetFunc();
    }
  }

  reloadDashboardWithNewColorSetFunc() {
    // this.wigetBackgroundColor = this.backColor;
    // this.moreItems[1].label = 'share'; // Setting the widgets full view mode option
    // this.moreItems[0].icon = 'pi pi-refresh';

    var dashboardInfoObject = this.dashboardInfoObject; // Creating local variable for use in .map, .forEach method

    if (
      this.randomIdArray.length > 0 ||
      this.parameterizedDashboardRandomArray.length > 0
    ) {
      // checking whether a dashboard is loaded or not

      acinfotech.requestType(true); // Letting chart library know it's a dashboard module call and empty all stored divs id and chart div elements
      this.isLoading = true;
      //d3.select('.tibo-mydash-dash-spinner').style('display', 'block'); //  showing dashboard spinner

      this.totalRows = Array(Number(dashboardInfoObject.row)).fill(0);
      this.totalColumns = Array(Number(dashboardInfoObject.column)).fill(0);

      (this.randomIdArray = []),
        (this.parameterizedDashboardRandomArray = []),
        (this.rowIndex = []),
        (this.finalCallCounter = 0);

      if (dashboardInfoObject.dashboardRequestorParameters.length == 0) {
        this.totalRows.forEach((rowEle, rowIndex) => {
          this.totalColumns.forEach((colEle, columnIndex) => {
            this.rowIndex.push(
              'chartid-' + Math.floor(Math.random() * 10000) + 1
            );
          });
          this.randomIdArray.push(this.rowIndex);

          this.rowIndex = [];
        });

        //d3.select("#dashboard-scroll-wrapper").style("display","block")
        //d3.select("#dashboard-parameterized-scroll-wrapper").style("display","none")

        setTimeout(() => {
          // removing unused chart divs and adjusting their width

          var totalRows = this.totalRows;
          var totalColumns = this.totalColumns;
          var totalObjCoords = [];
          var usedObjCoords = [];

          totalRows.map(function (row, rowInd) {
            totalColumns.map(function (column, columnInd) {
              totalObjCoords.push(rowInd + 1 + ',' + (columnInd + 1));
            });
          });

          totalObjCoords.map(function (coord) {
            dashboardInfoObject.widgetControls.map(function (d) {
              if (
                Number(d.controlMasterId) == 7 ||
                Number(d.controlMasterId) == 22 ||
                Number(d.controlMasterId) == 23 ||
                Number(d.controlMasterId) == 25 ||
                Number(d.controlMasterId) == 26 ||
                Number(d.controlMasterId) == 27
              ) {
              } else {
                if (String(coord) == String(d.objectCordinates)) {
                  usedObjCoords.push(coord);
                }
              }
            });
          });

          var eachWidget = d3.selectAll('.widget-class')[0];
          for (var widi = 0; widi < totalObjCoords.length; widi++) {
            if (
              usedObjCoords.indexOf(
                d3.select(eachWidget[widi]).attr('objectCordinates')
              ) == -1
            ) {
              d3.select(eachWidget[widi]).remove();
            }
          }

          d3.selectAll('.tibo-mydash-nonparamdash-widget')[0].forEach(function (
            row
          ) {
            if (d3.select(row).selectAll('div.widget-class')[0].length == 1) {
              d3.select(row)
                .selectAll('div.widget-class')
                .attr('class', 'widget-class expand-widget-class-1');
            } else if (
              d3.select(row).selectAll('div.widget-class')[0].length == 2
            ) {
              d3.select(row)
                .selectAll('div.widget-class')
                .attr('class', 'widget-class expand-widget-class-2');
            } else if (
              d3.select(row).selectAll('div.widget-class')[0].length == 3
            ) {
              d3.select(row)
                .selectAll('div.widget-class')
                .attr('class', 'widget-class expand-widget-class-3');
            }
          });
        }, 1000); // removing unused chart divs and adjusting their width
      } // dashboardInfoObject.dashboardRequestorParameters.length == 0
      else {
        this.totalRows.forEach((rowEle, rowIndex) => {
          this.totalColumns.forEach((colEle, columnIndex) => {
            this.rowIndex.push(
              'chartid-' + Math.floor(Math.random() * 10000) + 1
            );
          });
          this.parameterizedDashboardRandomArray.push(this.rowIndex);
          this.rowIndex = [];
        });

        //d3.select("#dashboard-scroll-wrapper").style("display","block")
        //d3.select("#dashboard-parameterized-scroll-wrapper").style("display","none")

        setTimeout(() => {
          // removing unused chart divs and adjusting their width

          var totalRows = this.totalRows;
          var totalColumns = this.totalColumns;
          var totalObjCoords = [];
          var usedObjCoords = [];

          totalRows.map(function (row, rowInd) {
            totalColumns.map(function (column, columnInd) {
              totalObjCoords.push(rowInd + 1 + ',' + (columnInd + 1));
            });
          });

          totalObjCoords.map(function (coord) {
            dashboardInfoObject.widgetControls.map(function (d) {
              if (
                Number(d.controlMasterId) == 7 ||
                Number(d.controlMasterId) == 22 ||
                Number(d.controlMasterId) == 23 ||
                Number(d.controlMasterId) == 25 ||
                Number(d.controlMasterId) == 26 ||
                Number(d.controlMasterId) == 27
              ) {
              } else {
                if (String(coord) == String(d.objectCordinates)) {
                  usedObjCoords.push(coord);
                }
              }
            });
          });

          var eachWidget = d3.selectAll('.widget-class')[0];
          for (var widi = 0; widi < totalObjCoords.length; widi++) {
            if (
              usedObjCoords.indexOf(
                d3.select(eachWidget[widi]).attr('objectCordinates')
              ) == -1
            ) {
              d3.select(eachWidget[widi]).remove();
            }
          }

          d3.selectAll('.tibo-mydash-paramdash-widget')[0].forEach(function (
            row
          ) {
            if (d3.select(row).selectAll('div.widget-class')[0].length == 1) {
              d3.select(row)
                .selectAll('div.widget-class')
                .attr('class', 'widget-class expand-widget-class-1');
            } else if (
              d3.select(row).selectAll('div.widget-class')[0].length == 2
            ) {
              d3.select(row)
                .selectAll('div.widget-class')
                .attr('class', 'widget-class expand-widget-class-2');
            } else if (
              d3.select(row).selectAll('div.widget-class')[0].length == 3
            ) {
              d3.select(row)
                .selectAll('div.widget-class')
                .attr('class', 'widget-class expand-widget-class-3');
            }
          });
        }, 1000); // removing unused chart divs and adjusting their width
      }

      setTimeout(() => {
        dashboardInfoObject.widgetControls.forEach((widget, index) => {
          // if(widget.objectCordinates !=null || widget.objectCordinates !="null"){
          if (widget.controlMasterId == 3) {
            var chartElement = d3
              .select(
                ".widget-class[objectCordinates='" +
                widget.objectCordinates +
                "']"
              )
              .attr('empty', false);
            this.table(
              chartElement,
              widget,
              dashboardInfoObject.widgetControls[index].eachWidgetResponse
            );
            d3.select('#loader' + widget.objectCordinates.replace(',', '')).style('display', 'none');


            this.finalCall();
          } else {
            //-------------------------------------------------------------------------------------------------

            if (widget.controlMasterId == 1) {
              var chartElement = d3
                .select(
                  ".widget-class[objectCordinates='" +
                  widget.objectCordinates +
                  "']"
                )
                .attr('empty', false);
              this.pieChart(
                chartElement,
                widget,
                dashboardInfoObject.widgetControls[index].eachWidgetResponse
              );
              d3.select('#loader' + widget.objectCordinates.replace(',', '')).style('display', 'none');

            } else if (widget.controlMasterId == 2) {
              var chartElement = d3
                .select(
                  ".widget-class[objectCordinates='" +
                  widget.objectCordinates +
                  "']"
                )
                .attr('empty', false);
              this.columnChart(
                chartElement,
                widget,
                dashboardInfoObject.widgetControls[index].eachWidgetResponse
              );
              d3.select('#loader' + widget.objectCordinates.replace(',', '')).style('display', 'none');

            } else if (widget.controlMasterId == 5) {
              /*else if(widget.controlMasterId == 3){
              var chartElement = d3.select(".widget-class[objectCordinates='" + widget.objectCordinates + "']")
              .attr("empty", false)
            	
              this.table(chartElement,widget,dashboardInfoObject.widgetControls[index].eachWidgetResponse)
            }*/
              var chartElement = d3
                .select(
                  ".widget-class[objectCordinates='" +
                  widget.objectCordinates +
                  "']"
                )
                .attr('empty', false);
              this.donutChart(
                chartElement,
                widget,
                dashboardInfoObject.widgetControls[index].eachWidgetResponse
              );
              d3.select('#loader' + widget.objectCordinates.replace(',', '')).style('display', 'none');

            } else if (widget.controlMasterId == 6) {
              var chartElement = d3
                .select(
                  ".widget-class[objectCordinates='" +
                  widget.objectCordinates +
                  "']"
                )
                .attr('empty', false);
              this.horizontalChart(
                chartElement,
                widget,
                dashboardInfoObject.widgetControls[index].eachWidgetResponse
              );
              d3.select('#loader' + widget.objectCordinates.replace(',', '')).style('display', 'none');

            } else if (widget.controlMasterId == 7) {
            } // Waffle chart
            else if (widget.controlMasterId == 8) {
              var chartElement = d3
                .select(
                  ".widget-class[objectCordinates='" +
                  widget.objectCordinates +
                  "']"
                )
                .attr('empty', false);
              this.perGauge(
                chartElement,
                widget,
                dashboardInfoObject.widgetControls[index].eachWidgetResponse
              );
              d3.select('#loader' + widget.objectCordinates.replace(',', '')).style('display', 'none');

            } else if (widget.controlMasterId == 9) {
              var chartElement = d3
                .select(
                  ".widget-class[objectCordinates='" +
                  widget.objectCordinates +
                  "']"
                )
                .attr('empty', false);
              this.numberGauge(
                chartElement,
                widget,
                dashboardInfoObject.widgetControls[index].eachWidgetResponse
              );
              d3.select('#loader' + widget.objectCordinates.replace(',', '')).style('display', 'none');

            } else if (widget.controlMasterId == 10) {
              var chartElement = d3
                .select(
                  ".widget-class[objectCordinates='" +
                  widget.objectCordinates +
                  "']"
                )
                .attr('empty', false);
              this.map(
                chartElement,
                widget,
                dashboardInfoObject.widgetControls[index].eachWidgetResponse
              );
              d3.select('#loader' + widget.objectCordinates.replace(',', '')).style('display', 'none');

            } else if (widget.controlMasterId == 11) {
              var chartElement = d3
                .select(
                  ".widget-class[objectCordinates='" +
                  widget.objectCordinates +
                  "']"
                )
                .attr('empty', false);
              this.columnAndLineChart(
                chartElement,
                widget,
                dashboardInfoObject.widgetControls[index].eachWidgetResponse
              );
              d3.select('#loader' + widget.objectCordinates.replace(',', '')).style('display', 'none');

            } else if (widget.controlMasterId == 12) {
              var chartElement = d3
                .select(
                  ".widget-class[objectCordinates='" +
                  widget.objectCordinates +
                  "']"
                )
                .attr('empty', false);
              this.lineChart(
                chartElement,
                widget,
                dashboardInfoObject.widgetControls[index].eachWidgetResponse
              );
              d3.select('#loader' + widget.objectCordinates.replace(',', '')).style('display', 'none');


            } else if (widget.controlMasterId == 13) {
              var chartElement = d3
                .select(
                  ".widget-class[objectCordinates='" +
                  widget.objectCordinates +
                  "']"
                )
                .attr('empty', false);

              this.bubbleChart(
                chartElement,
                widget,
                dashboardInfoObject.widgetControls[index].eachWidgetResponse
              );
              d3.select('#loader' + widget.objectCordinates.replace(',', '')).style('display', 'none');
            } else if (widget.controlMasterId == 14) {
              var chartElement = d3
                .select(
                  ".widget-class[objectCordinates='" +
                  widget.objectCordinates +
                  "']"
                )
                .attr('empty', false);

              this.stackedHorizontalBarChart(
                chartElement,
                widget,
                dashboardInfoObject.widgetControls[index].eachWidgetResponse
              );
              d3.select('#loader' + widget.objectCordinates.replace(',', '')).style('display', 'none');

            } else if (widget.controlMasterId == 15) {
              var chartElement = d3
                .select(
                  ".widget-class[objectCordinates='" +
                  widget.objectCordinates +
                  "']"
                )
                .attr('empty', false);
              this.stackedBarChart(
                chartElement,
                widget,
                dashboardInfoObject.widgetControls[index].eachWidgetResponse
              );
              d3.select('#loader' + widget.objectCordinates.replace(',', '')).style('display', 'none');

            } else if (widget.controlMasterId == 16) {
              var chartElement = d3
                .select(
                  ".widget-class[objectCordinates='" +
                  widget.objectCordinates +
                  "']"
                )
                .attr('empty', false);
              this.perGaugeWithFlip(
                chartElement,
                widget,
                dashboardInfoObject.widgetControls[index].eachWidgetResponse
              );
              d3.select('#loader' + widget.objectCordinates.replace(',', '')).style('display', 'none');

            } else if (widget.controlMasterId == 17) {
              var chartElement = d3
                .select(
                  ".widget-class[objectCordinates='" +
                  widget.objectCordinates +
                  "']"
                )
                .attr('empty', false);
              this.squareGauge(
                chartElement,
                widget,
                dashboardInfoObject.widgetControls[index].eachWidgetResponse
              );
              d3.select('#loader' + widget.objectCordinates.replace(',', '')).style('display', 'none');

            } else if (widget.controlMasterId == 18) {
              var chartElement = d3
                .select(
                  ".widget-class[objectCordinates='" +
                  widget.objectCordinates +
                  "']"
                )
                .attr('empty', false);
              this.bellCurveChart(
                chartElement,
                widget,
                dashboardInfoObject.widgetControls[index].eachWidgetResponse
              );
              d3.select('#loader' + widget.objectCordinates.replace(',', '')).style('display', 'none');

            } else if (widget.controlMasterId == 19) {
              var chartElement = d3
                .select(
                  ".widget-class[objectCordinates='" +
                  widget.objectCordinates +
                  "']"
                )
                .attr('empty', false);
              this.areaChart(
                chartElement,
                widget,
                dashboardInfoObject.widgetControls[index].eachWidgetResponse
              );
              d3.select('#loader' + widget.objectCordinates.replace(',', '')).style('display', 'none');

            } else if (widget.controlMasterId == 20) {
              var chartElement = d3
                .select(
                  ".widget-class[objectCordinates='" +
                  widget.objectCordinates +
                  "']"
                )
                .attr('empty', false);
              this.groupedBarChart(
                chartElement,
                widget,
                dashboardInfoObject.widgetControls[index].eachWidgetResponse
              );
              d3.select('#loader' + widget.objectCordinates.replace(',', '')).style('display', 'none');

            } else if (widget.controlMasterId == 21) {
              var chartElement = d3
                .select(
                  ".widget-class[objectCordinates='" +
                  widget.objectCordinates +
                  "']"
                )
                .attr('empty', false);
              this.horizontalGroupedBarChart(
                chartElement,
                widget,
                dashboardInfoObject.widgetControls[index].eachWidgetResponse
              );
              d3.select('#loader' + widget.objectCordinates.replace(',', '')).style('display', 'none');

            } else if (widget.controlMasterId == 22) {
            } //Horizontal opposite bar Chart
            else if (widget.controlMasterId == 23) {
            } //Vertical opposite bar Chart
            else if (widget.controlMasterId == 24) {
              var chartElement = d3
                .select(
                  ".widget-class[objectCordinates='" +
                  widget.objectCordinates +
                  "']"
                )
                .attr('empty', false);
              this.dotGauge(
                chartElement,
                widget,
                dashboardInfoObject.widgetControls[index].eachWidgetResponse
              );
              d3.select('#loader' + widget.objectCordinates.replace(',', '')).style('display', 'none');

            } else if (widget.controlMasterId == 25) {
            } //Square bubble chart
            else if (widget.controlMasterId == 26) {
            } //Magic quadrant
            else if (widget.controlMasterId == 27) {
            } //Funnel chart
            else if (widget.controlMasterId == 28) {
              var chartElement = d3
                .select(
                  ".widget-class[objectCordinates='" +
                  widget.objectCordinates +
                  "']"
                )
                .attr('empty', false);
              this.columnAndLinesChart(
                chartElement,
                widget,
                dashboardInfoObject.widgetControls[index].eachWidgetResponse
              );
              d3.select('#loader' + widget.objectCordinates.replace(',', '')).style('display', 'none');

            } else if (widget.controlMasterId == 29) {
              var chartElement = d3
                .select(
                  ".widget-class[objectCordinates='" +
                  widget.objectCordinates +
                  "']"
                )
                .attr('empty', false);
              this.threeXAxisesGroupedBarChart(
                chartElement,
                widget,
                dashboardInfoObject.widgetControls[index].eachWidgetResponse
              );
              d3.select('#loader' + widget.objectCordinates.replace(',', '')).style('display', 'none');

            } else if (widget.controlMasterId == 30) {
              var chartElement = d3
                .select(
                  ".widget-class[objectCordinates='" +
                  widget.objectCordinates +
                  "']"
                )
                .attr('empty', false);
              this.twoXAxisesGroupedBarChart(
                chartElement,
                widget,
                dashboardInfoObject.widgetControls[index].eachWidgetResponse
              );
              d3.select('#loader' + widget.objectCordinates.replace(',', '')).style('display', 'none');

            } else if (widget.controlMasterId == 31) {
              var chartElement = d3
                .select(
                  ".widget-class[objectCordinates='" +
                  widget.objectCordinates +
                  "']"
                )
                .attr('empty', false);
              this.bellCurveChartWithMinAndMaxRange(
                chartElement,
                widget,
                dashboardInfoObject.widgetControls[index].eachWidgetResponse
              );
              d3.select('#loader' + widget.objectCordinates.replace(',', '')).style('display', 'none');

            } else if (widget.controlMasterId == 32) {
              var chartElement = d3
                .select(
                  ".widget-class[objectCordinates='" +
                  widget.objectCordinates +
                  "']"
                )
                .attr('empty', false);
              this.stackedGroupedBarChart(
                chartElement,
                widget,
                dashboardInfoObject.widgetControls[index].eachWidgetResponse
              );
              d3.select('#loader' + widget.objectCordinates.replace(',', '')).style('display', 'none');

            } else if (widget.controlMasterId == 33) {
              var chartElement = d3
                .select(
                  ".widget-class[objectCordinates='" +
                  widget.objectCordinates +
                  "']"
                )
                .attr('empty', false);
              this.stackedGroupedBarsWithLineChart(
                chartElement,
                widget,
                dashboardInfoObject.widgetControls[index].eachWidgetResponse
              );
              d3.select('#loader' + widget.objectCordinates.replace(',', '')).style('display', 'none');

            } else if (widget.controlMasterId == 34) {
              var chartElement = d3
                .select(
                  ".widget-class[objectCordinates='" +
                  widget.objectCordinates +
                  "']"
                )
                .attr('empty', false);
              this.stackedBarWithLinesChart(
                chartElement,
                widget,
                dashboardInfoObject.widgetControls[index].eachWidgetResponse
              );
              d3.select('#loader' + widget.objectCordinates.replace(',', '')).style('display', 'none');

            } else if (widget.controlMasterId == 35) {
              var chartElement = d3
                .select(
                  ".widget-class[objectCordinates='" +
                  widget.objectCordinates +
                  "']"
                )
                .attr('empty', false);
              this.trafficLightsChart(
                chartElement,
                widget,
                dashboardInfoObject.widgetControls[index].eachWidgetResponse
              );
              d3.select('#loader' + widget.objectCordinates.replace(',', '')).style('display', 'none');

            } else if (widget.controlMasterId == 36) {
              var chartElement = d3
                .select(
                  ".widget-class[objectCordinates='" +
                  widget.objectCordinates +
                  "']"
                )
                .attr('empty', false);
              this.columnChartWithNegativeValues(
                chartElement,
                widget,
                dashboardInfoObject.widgetControls[index].eachWidgetResponse
              );
              d3.select('#loader' + widget.objectCordinates.replace(',', '')).style('display', 'none');

            } else if (widget.controlMasterId == 37) {
              var chartElement = d3
                .select(
                  ".widget-class[objectCordinates='" +
                  widget.objectCordinates +
                  "']"
                )
                .attr('empty', false);
              this.groupedBarsWithLineChart(
                chartElement,
                widget,
                dashboardInfoObject.widgetControls[index].eachWidgetResponse
              );
              d3.select('#loader' + widget.objectCordinates.replace(',', '')).style('display', 'none');

            } else if (widget.controlMasterId == 38) {
              var chartElement = d3
                .select(
                  ".widget-class[objectCordinates='" +
                  widget.objectCordinates +
                  "']"
                )
                .attr('empty', false);
              this.USMapWithPins(
                chartElement,
                widget,
                dashboardInfoObject.widgetControls[index].eachWidgetResponse
              );
              d3.select('#loader' + widget.objectCordinates.replace(',', '')).style('display', 'none');

            }

            //-------------------------------------------------------------------------------------------------

            this.finalCall();
            // } 
          }// else
        }); // forEach
        // this.isLoading=false;
        d3.select('.tibo-mydash-dash-spinner').style('display', 'none'); //  hidding dashboard spinner
      }, 1100);
    } // if((this.randomIdArray.length > 0) || (this.parameterizedDashboardRandomArray.length > 0))
  }

  shareDashTabChanFunc(e) {
    //this.shareDashUserNamesArr = [], this.shareDashSrchUserNamesArr = [], this.usersArray = [], this.shareDashEmailIdsArr = [];
    e.index == 0 ? (this.shareDashTabIndex = 0) : (this.shareDashTabIndex = 1);
  }

  search(event) {
    this.usersArray = [];

    this.usersNameSubscrip = this.dashService
      .usersNameService(event.query)
      .subscribe(
        (resp: any) => {
          resp.body?.records.map((x) =>
            this.usersArray.push({ userName: x.userName, userId: x.userId })
          );
          this.showSuggestions(this.usersArray);
        },
        (error) => {
          if (Number(error.status) == 401) {
            this.errorMsg = 'Please Try After Some Time!';
          } else if (Number(error.status) == 500) {
            this.errorMsg = 'Internal Error';
          } else {
            this.errorMsg = 'Please Try After Some Time.';
          }

          this.addSingle();
        },
        () => { }
      ); // get users name service
  }

  showSuggestions(usersArray) {
    this.shareDashSrchUserNamesArr = [];
    usersArray.map((x) => this.shareDashSrchUserNamesArr.push(x.userName));
  }

  shareDashFunc(event) {
    //this.shareDashUserNamesArr = [];
    let ids = [];

    if (this.shareDashTabIndex == 0) {
      if (this.shareDashUserNamesArr.length != 0) {
        this.shareDashDialBoxDisplayBool = false;

        this.shareDashUserNamesArr.forEach((user) => {
          this.usersArray.forEach((d) => {
            if (user == d.userName) {
              ids.push(Number(d.userId));
            }
          });
        });
        const uniqueIdsArray = Array.from(ids);
        this.shareDashSubscrip = this.dashService
          .shareDashboardService({
            userId: uniqueIdsArray,
            emailId: [],
            dashboardId: [Number(this.selectedDashboard.code)],
          })
          .subscribe(
            (resp: any) => {
              //resp.body?.records.map(x => this.usersArray.push({ userName: x.userName, userId: x.userId }));

              if (resp.status == 200) {
                this.shareDashSuccMsg = 'Dashboard shared successfully!';
                this.successAlertMsgFunc();
              }
            },
            (error) => {
              if (Number(error.status) == 401) {
                this.errorMsg = 'Please Try After Some Time!';
              } else if (Number(error.status) == 500) {
                this.errorMsg = 'Internal Error';
              } else {
                this.errorMsg = 'Please Try After Some Time.';
              }

              this.addSingle();
            },
            () => { }
          ); // share the dashboard service
      } else {
        this.errorMsg = 'Please enter User Name(s)!';
        this.addSingle();
      }
      (this.shareDashUserNamesArr = []),
        (this.shareDashSrchUserNamesArr = []),
        (this.usersArray = []),
        (this.shareDashEmailIdsArr = []); // clear all users name and email ids arrays

    } else if (this.shareDashTabIndex == 1) {
      if (this.shareDashEmailIdsArr.length != 0) {
        this.shareDashDialBoxDisplayBool = false;

        this.shareDashSubscrip = this.dashService
          .shareDashboardService({
            userId: [],
            emailId: this.shareDashEmailIdsArr,
            dashboardId: [Number(this.selectedDashboard.code)],
          })
          .subscribe(
            (resp: any) => {
              //resp.body?.records.map(x => this.usersArray.push({ userName: x.userName, userId: x.userId }));

              if (resp.status == 200) {
                this.shareDashSuccMsg = 'Dashboard shared successfully!';
                this.successAlertMsgFunc();
              }
            },
            (error) => {
              if (Number(error.status) == 401) {
                this.errorMsg = 'Please Try After Some Time!';
              } else if (Number(error.status) == 500) {
                this.errorMsg = 'Internal Error';
              } else {
                this.errorMsg = 'Please Try After Some Time.';
              }

              this.addSingle();
            },
            () => { }
          ); // share the dashboard service
      } else {
        this.errorMsg = 'Please enter Email Id(s)!';
        this.addSingle();
      }

      (this.shareDashUserNamesArr = []),
        (this.shareDashSrchUserNamesArr = []),
        (this.usersArray = []),
        (this.shareDashEmailIdsArr = []); // clear all users name and email ids arrays
    }
  }

  dialogBoxOnCloseFunc(event) {
    this.shareDashTabIndex = 0;
    (this.shareDashUserNamesArr = []),
      (this.shareDashSrchUserNamesArr = []),
      (this.usersArray = []),
      (this.shareDashEmailIdsArr = []); // clear all users name and email ids arrays
  }

  successAlertMsgFunc() {
    this.messageService.add({
      severity: 'success',
      summary: '',
      detail: this.shareDashSuccMsg,
    });
  }

  dashboardListFunc() {
    d3.select('#tibo-mydash-dashlist-container-id').style('display') == 'none'
      ? d3
        .select('#tibo-mydash-dashlist-container-id')
        .style('display', 'block')
      : d3
        .select('#tibo-mydash-dashlist-container-id')
        .style('display', 'none');
    d3.select('#tibo-mydash-dashlist-container-id')
      .select('p-panelmenu')
      .selectAll('div > div.p-panelmenu-header > a.p-panelmenu-header-link')
      .attr('non-hide', 'non-hide');
    d3.select('#tibo-mydash-dashlist-container-id')
      .select('p-panelmenu')
      .selectAll(
        'div > div.p-panelmenu-header > a.p-panelmenu-header-link > span'
      )
      .attr('non-hide', 'non-hide');
  }
  dashboardListFunc1() {
    d3.select('#tibo-mydash-dashlist-container-id1').style('display') == 'none'
      ? d3
        .select('#tibo-mydash-dashlist-container-id1')
        .style('display', 'block')
      : d3
        .select('#tibo-mydash-dashlist-container-id')
        .style('display', 'none');
    d3.select('#tibo-mydash-dashlist-container-id1')
      .select('p-panelmenu')
      .selectAll('div > div.p-panelmenu-header > a.p-panelmenu-header-link')
      .attr('non-hide', 'non-hide');
    d3.select('#tibo-mydash-dashlist-container-id1')
      .select('p-panelmenu')
      .selectAll(
        'div > div.p-panelmenu-header > a.p-panelmenu-header-link > span'
      )
      .attr('non-hide', 'non-hide');
  }
  dashboardListFuncs() {
    d3.select('#tibo-mydash-dashlist-container').style('display') == 'none'
      ? d3.select('#tibo-mydash-dashlist-container').style('display', 'block')
      : d3.select('#tibo-mydash-dashlist-container').style('display', 'none');

    d3.select('#tibo-mydash-dashlist-container')
      .select('p-panelmenu')
      .selectAll('div > div.p-panelmenu-header > a.p-panelmenu-header-link')
      .attr('non-hide', 'non-hide');
    d3.select('#tibo-mydash-dashlist-container')
      .select('p-panelmenu')
      .selectAll(
        'div > div.p-panelmenu-header > a.p-panelmenu-header-link > span'
      )
      .attr('non-hide', 'non-hide');
  }
  visitedDashFunc(dashboardName, dashboardId) {
    this.selectedDashboard = { name: dashboardName, code: dashboardId };
    this.getDashboard('');
  }

  refreshTimerFunc() {
    let thisClass = this; // assigning component class object to a variable
    let minutes = this.dashboardRefreshTime == undefined ? 3 : this.dashboardRefreshTime;
    let counter = 60;
    let strMin, strSec;
    document.getElementById(
      'mydash-countdown-timer-label'
    ).innerHTML = '';
    let timerLabel = document.getElementById('mydash-countdown-timer-label');
    if (!timerLabel) {
      //console.error("Element with ID 'mydash-countdown-timer-label' not found.");
      return;
    }

    this.refreshCountDownTimerStr = minutes + ":" + "00";
    timerLabel.innerHTML = this.refreshCountDownTimerStr;

    minutes--;

    this.refreshInterval = setInterval(() => {

      counter = counter - 1;

      if (String(minutes).length == 1) { strMin = "0" + minutes; }
      else { strMin = minutes; }

      if (String(counter).length == 1) { strSec = "0" + counter; }
      else {
        if (counter == 60) { strSec = "00"; }
        else { strSec = counter; }
      }

      this.refreshCountDownTimerStr = minutes + ":" + strSec;
      timerLabel.innerHTML = this.refreshCountDownTimerStr;

      if (counter == 0) {
        minutes--;
        counter = 60;
      }

      if (minutes < 0) {
        minutes = this.dashboardRefreshTime;
        this.refreshCountDownTimerStr = minutes + ":" + "00";
        timerLabel.innerHTML = this.refreshCountDownTimerStr;
        minutes--;
        this.getDashboard("");
      }

    }, 1000);
  }

  chartsDivContainerClick(event) {
    //console.log(event)
    // drill thru
    var chartDivObjCoor = '',
      clickedWidget = {};

    if (
      event.target.nodeName.toLowerCase() == 'rect' ||
      event.target.nodeName.toLowerCase() == 'path' ||
      event.target.nodeName.toLowerCase() == 'circle'
    ) {
      if (event.target.attributes['data-drilldownkey'] != undefined) {
        var drillThruKeyValuesArray = [],
          dataDrillDownKeyArray = d3
            .select(event.target)
            .attr('data-drilldownkey')
            .split(',');
        for (var i = 0; i < dataDrillDownKeyArray.length; i++) {
          drillThruKeyValuesArray.push({
            drillThruColumn: dataDrillDownKeyArray[i].substr(
              0,
              dataDrillDownKeyArray[i].indexOf('|')
            ),
            value: dataDrillDownKeyArray[i].substr(
              dataDrillDownKeyArray[i].indexOf('|') + 1,
              dataDrillDownKeyArray[i].length
            ),
          });
        }
        var nodes = [];
        var element = event.target;
        nodes.push(element);
        while (element.parentNode) {
          nodes.push(element.parentNode);
          element = element.parentNode;
        }

        for (var i = 0; i < nodes.length; i++) {
          if (nodes[i].nodeName != undefined) {
            if (nodes[i].nodeName.toLowerCase() == 'div') {
              if (nodes[i].attributes['class']) {
                if (nodes[i].attributes['class'].value.includes('widget-class')) {
                  chartDivObjCoor = nodes[i].attributes['objectCordinates'].value;
                }
              }
            }
          }
        }
        this.dashboardInfoObject?.widgetControls.forEach((widget) => {
          if (chartDivObjCoor == widget.objectCordinates) {
            clickedWidget = widget;
            this.showDrillThruOutputBtnClick(
              drillThruKeyValuesArray,
              clickedWidget
            );
          }
        });
      }
    } else if (event.target.nodeName.toLowerCase() == 'td') {
      if (event.target.style['textDecoration'].toLowerCase() == 'underline') {
        var drillThruKeyValuesArray = [];
        for (
          var i = 0;
          i < event.target.offsetParent.firstChild.children.length;
          i++
        ) {
          drillThruKeyValuesArray.push({
            drillThruColumn:
              event.target.offsetParent.firstChild.children[i].textContent,
            value: event.target.parentNode.children[i].textContent,
          });
        }
        var nodes = [];
        var element = event.target;
        nodes.push(element);
        while (element.parentNode) {
          nodes.push(element.parentNode);
          element = element.parentNode;
        }
        for (var i = 0; i < nodes.length; i++) {
          if (nodes[i].nodeName != undefined) {
            if (nodes[i].nodeName.toLowerCase() == 'div') {
              if (nodes[i].attributes['class']) {
                if (nodes[i].attributes['class'].value.includes('widget-class')) {
                  chartDivObjCoor = nodes[i].attributes['objectCordinates'].value;
                }
              }
            }
          }
        }

        this.dashboardInfoObject?.widgetControls.forEach((widget) => {
          if (chartDivObjCoor == widget.objectCordinates) {
            clickedWidget = widget;
            this.showDrillThruOutputBtnClick(
              drillThruKeyValuesArray,
              clickedWidget
            );
          }
        });
      }
    } // td
  }

  showDrillThruOutputBtnClick(drillThruKeyValuesArray, clickedWidget) {
    if (clickedWidget.drillThru.length != 0) {
      this.ref = this.dialogService.open(DashboardChartComponent, {
        data: {
          drillThruKeyValuesArray: drillThruKeyValuesArray,
          clickedWidget: clickedWidget,
          selectedChartsColorSet: this.selectedChartsColorSet,
          BGcolor: this.wigetBackgroundColor,
        },
        header: clickedWidget?.chartTitle + ' - Detail View',
        width: '100%',
        height: '100%',
        contentStyle: {
          'min-width': '380px',
          'min-height': '350px',
          height: '100%',
          'background-color': '#f1f1f4',
          overflow: 'hidden',
        },
        baseZIndex: 10000,
      });

      this.ref.onClose.subscribe(() => {
        d3.select('.tibo-mydash-drill-thru-widget').select('*').remove();
      });
    }
  }

  showBasicDialog() {
    this.displayBasic = true;
  }

  toggle() { }
  fullscreen() {
    // debugger;
    // this.FullScreen=="Widget View"?"Full Screen":"Widget View";
    let canvas = document.getElementsByClassName(
      'widget-class'
    ) as HTMLCollectionOf<HTMLElement>;
    if (canvas[0].classList.contains('full-view-widget-class')) {
      //this.moreItems[0].label = "Turn On Page Refresh"
      this.FullScreen = 'Full Screen'
      for (var i = 0; i < canvas.length; i++) {
        canvas[i].classList.remove('full-view-widget-class');
        d3.selectAll('.ui-resizable-handle').style('display', 'block');
        // document.getElementById('resizeBtn').innerHTML=
        this.moreItems[0].icon = 'pi pi-th-large';
      }
      window.dispatchEvent(new Event('resize'));
    } else {
      //this.moreItems[0].label = "Turn off page refresh"
      this.FullScreen = 'Widget View'
      for (var i = 0; i < canvas.length; i++) {
        canvas[i].classList.add('full-view-widget-class');
        d3.selectAll('.ui-resizable-handle').style('display', 'none');
      }
      window.dispatchEvent(new Event('resize'));
    }
  }


  downloadJPEG() {
    this.downloadSpinner = 'pi-spin pi-spinner';
    setTimeout(() => {
      let data = document.getElementById('chartContent');
      let scrollPanel = document.getElementById('dashboard-scroll-wrapper');
      let originalHeight = scrollPanel!.style.height;  // Save original scroll panel height
      scrollPanel!.style.height = 'auto';  // Expand to fit all content
      scrollPanel!.style.overflow = 'visible';  // Ensure no overflow content is hidden

      // Capture original styles for widgets
      let widgets = document.querySelectorAll('.widget-class');
      let originalStyles: { element: HTMLElement; minHeight: string; minWidth: string; }[] = [];

      widgets.forEach(widget => {
        const htmlWidget = widget as HTMLElement;  // Explicitly cast to HTMLElement
        originalStyles.push({
          element: htmlWidget,
          minHeight: htmlWidget.style.minHeight,
          minWidth: htmlWidget.style.minWidth
        });

        // Set temporary styles to ensure full rendering
        htmlWidget.style.visibility = 'visible';
        htmlWidget.style.overflow = 'visible';
        htmlWidget.style.minHeight = '400px';  // Set the minimum height (or adjust based on your widget's actual size)
        htmlWidget.style.minWidth = '350px';   // Set the minimum width
      });

      // Capture the content
      html2canvas(data, {
        scale: 2,
        useCORS: true,
        onclone: (cloneDoc) => {
          // Adjust title style in the cloned content
          const title = cloneDoc.getElementById('title-dashboard')!.style;
          title.color = '#1976D2';
          title.fontSize = '21px';
          title.marginTop = '10px';

          // Ensure the widgets in the cloned document have the proper dimensions
          let clonedWidgets = cloneDoc.querySelectorAll('.widget-class');
          clonedWidgets.forEach(widget => {
            const clonedHtmlWidget = widget as HTMLElement;  // Explicitly cast to HTMLElement
            clonedHtmlWidget.style.minHeight = '400px';  // Maintain minimum height in the clone
            clonedHtmlWidget.style.minWidth = '350px';   // Maintain minimum width
            clonedHtmlWidget.style.overflow = 'visible';  // Prevent content cut-off
            clonedHtmlWidget.style.visibility = 'visible';  // Ensure full visibility
          });
        },
      }).then((canvas) => {
        // Convert the canvas to a JPEG and trigger the download
        var a = document.createElement('a');
        a.href = canvas.toDataURL('image/jpeg');
        a.download = this.dashboardName + '.jpeg';
        a.click();

        // Restore the original styles for the widgets
        originalStyles.forEach((style) => {
          style.element.style.minHeight = style.minHeight;
          style.element.style.minWidth = style.minWidth;
        });

        // Restore the original scroll panel height and overflow
        scrollPanel!.style.height = originalHeight;
        scrollPanel!.style.overflow = 'auto';

        this.downloadSpinner = 'pi-download';
      }).catch((error) => {
        // Handle any errors during the capture
        //console.error('Error generating image:', error);

        // Restore original styles and settings in case of an error
        originalStyles.forEach((style) => {
          style.element.style.minHeight = style.minHeight;
          style.element.style.minWidth = style.minWidth;
        });

        scrollPanel!.style.height = originalHeight;
        scrollPanel!.style.overflow = 'auto';
        this.downloadSpinner = 'pi-download';
      });
    }, 400);
  }
  downloadPNG() {
    this.downloadSpinner = 'pi-spin pi-spinner';
    setTimeout(() => {
      let data = document.getElementById('chartContent');
      let scrollPanel = document.getElementById('dashboard-scroll-wrapper');
      let originalHeight = scrollPanel!.style.height;  // Save original scroll panel height
      scrollPanel!.style.height = 'auto';  // Expand to fit all content
      scrollPanel!.style.overflow = 'visible';  // Ensure no overflow content is hidden

      // Capture original styles for widgets
      let widgets = document.querySelectorAll('.widget-class');
      let originalStyles: { element: HTMLElement; minHeight: string; minWidth: string; }[] = [];

      widgets.forEach(widget => {
        const htmlWidget = widget as HTMLElement;  // Explicitly cast to HTMLElement
        originalStyles.push({
          element: htmlWidget,
          minHeight: htmlWidget.style.minHeight,
          minWidth: htmlWidget.style.minWidth
        });

        // Set temporary styles to ensure full rendering
        htmlWidget.style.visibility = 'visible';
        htmlWidget.style.overflow = 'visible';
        htmlWidget.style.minHeight = '400px';  // Set the minimum height (adjust as needed)
        htmlWidget.style.minWidth = '350px';   // Set the minimum width
      });

      // Capture the content
      html2canvas(data, {
        scale: 2,
        useCORS: true,
        onclone: (cloneDoc) => {
          // Adjust title style in the cloned content
          const title = cloneDoc.getElementById('title-dashboard')!.style;
          title.color = '#1976D2';
          title.fontSize = '21px';
          title.marginTop = '10px';

          // Ensure the widgets in the cloned document have the proper dimensions
          let clonedWidgets = cloneDoc.querySelectorAll('.widget-class');
          clonedWidgets.forEach(widget => {
            const clonedHtmlWidget = widget as HTMLElement;  // Explicitly cast to HTMLElement
            clonedHtmlWidget.style.minHeight = '400px';  // Maintain minimum height in the clone
            clonedHtmlWidget.style.minWidth = '350px';   // Maintain minimum width
            clonedHtmlWidget.style.overflow = 'visible';  // Prevent content cut-off
            clonedHtmlWidget.style.visibility = 'visible';  // Ensure full visibility
          });
        },
      }).then((canvas) => {
        // Convert the canvas to a PNG and trigger the download
        var a = document.createElement('a');
        a.href = canvas.toDataURL('image/png');
        a.download = this.dashboardName + '.png';
        a.click();

        // Restore the original styles for the widgets
        originalStyles.forEach((style) => {
          style.element.style.minHeight = style.minHeight;
          style.element.style.minWidth = style.minWidth;
        });

        // Restore the original scroll panel height and overflow
        scrollPanel!.style.height = originalHeight;
        scrollPanel!.style.overflow = 'auto';

        this.downloadSpinner = 'pi-download';
      }).catch((error) => {
        // Handle any errors during the capture
        //console.error('Error generating image:', error);

        // Restore original styles and settings in case of an error
        originalStyles.forEach((style) => {
          style.element.style.minHeight = style.minHeight;
          style.element.style.minWidth = style.minWidth;
        });

        scrollPanel!.style.height = originalHeight;
        scrollPanel!.style.overflow = 'auto';
        this.downloadSpinner = 'pi-download';
      });
    }, 400);
  }
  async downloadPPTX() {
    this.downloadSpinner = 'pi-spin pi-spinner';

    // Start after a slight delay
    setTimeout(async () => {
      let pres = new pptxgen();
      let imageDataBlob: string[] = [];
      let randomArrayStore: string[] = [];

      // Collect chart IDs
      this.downloadArray.forEach(e => {
        randomArrayStore.push(e['chartId']);
      });

      // Save original styles for the charts
      let originalStyles: { element: HTMLElement; minHeight: string; minWidth: string; visibility: string; overflow: string; }[] = [];

      // Loop through each chart ID and capture them
      for (let i = 0; i < randomArrayStore.length; i++) {
        const chartElement = document.getElementById(randomArrayStore[i]) as HTMLElement;

        if (chartElement) {
          // Save the original styles (ensure values are copied as strings)
          originalStyles.push({
            element: chartElement,
            minHeight: chartElement.style.minHeight,
            minWidth: chartElement.style.minWidth,
            visibility: chartElement.style.visibility,
            overflow: chartElement.style.overflow
          });

          // Apply temporary styles for better rendering
          chartElement.style.visibility = 'visible';
          chartElement.style.overflow = 'visible';
          chartElement.style.minHeight = '400px'; // Set the minimum height
          chartElement.style.minWidth = '350px';  // Set the minimum width

          // Wait for html2canvas to finish rendering and capture the chart
          await html2canvas(chartElement, {
            allowTaint: false,
            useCORS: true,
            scale: 2, // Increase scale for better quality
          }).then((canvas) => {
            var imgData = canvas.toDataURL('image/png');
            imageDataBlob.push(imgData);

            // Add the image to a new slide in the presentation
            let slide = pres.addSlide();
            slide.addImage({
              data: imgData,
              x: '7%',
              y: '7%',
              h: '85%',
              w: '85%',
            });

            // Restore the original styles immediately after capturing
            chartElement.style.minHeight = originalStyles[i].minHeight;
            chartElement.style.minWidth = originalStyles[i].minWidth;
            chartElement.style.visibility = originalStyles[i].visibility;
            chartElement.style.overflow = originalStyles[i].overflow;
          }).catch((error) => {
            //console.error("Error capturing chart:", error);
          });
        }
      }

      // Write the presentation file
      await pres.writeFile({ fileName: this.dashboardName });
      this.downloadSpinner = 'pi-download';

    }, 400);
  }
  async downloadWidgetViewPDF() {
    const canvas = document.getElementsByClassName('widget-class') as HTMLCollectionOf<HTMLElement>;

    if (canvas[0].classList.contains('full-view-widget-class')) {
      this.fullscreen();
    }

    this.pdfDownloadPressed = 'widget pdf';
    const doc = new jsPDF('p', 'mm');
    this.downloadSpinner = 'pi-spin pi-spinner';
    const data = document.getElementById('chartContent');

    setTimeout(() => {
      // Save original styles for restoration
      const originalStyles: { element: HTMLElement; minHeight: string; minWidth: string; }[] = [];
      const widgets = document.querySelectorAll('.widget-class');

      widgets.forEach(widget => {
        const htmlWidget = widget as HTMLElement;
        originalStyles.push({
          element: htmlWidget,
          minHeight: htmlWidget.style.minHeight,
          minWidth: htmlWidget.style.minWidth
        });

        // Temporarily adjust styles
        htmlWidget.style.visibility = 'visible';
        htmlWidget.style.overflow = 'visible';
        htmlWidget.style.minHeight = '400px';
        htmlWidget.style.minWidth = '350px';
      });

      // Capture the content
      html2canvas(data as HTMLElement, {
        scale: 3,  // Increase scale factor for higher resolution
        useCORS: true,
        onclone: (cloneDoc) => {
          const title = cloneDoc.getElementById('title-dashboard')!.style;
          title.color = '#1976D2';
          title.fontSize = '40px';
        },
      }).then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        document.getElementById('chartContent').style.overflow = 'scroll';
        const imgWidth = 180;
        const pageHeight = 295;
        const imgHeight = (canvas.height * imgWidth) / canvas.width + 25;
        let heightLeft = imgHeight;
        let position = 16; // give some top padding to first page

        // Add the first image
        doc.addImage(imgData,  'JPEG', 16, position, imgWidth, imgHeight, '0', 'FAST');
        heightLeft -= pageHeight;

        // Add subsequent pages if needed
        while (heightLeft >= 0) {
          position += heightLeft - imgHeight; // top padding for other pages
          doc.addPage();
          doc.addImage(
            imgData,
            'PNG',
            16,
            position,
            imgWidth,
            imgHeight,
            '0',
            'SLOW'
          );
          heightLeft -= pageHeight;
        }

        // Save the PDF
        doc.save(`${this.dashboardName}.pdf`);
        this.downloadSpinner = 'pi-download';
        this.pdfDownloadPressed = '';

        // Restore original styles
        originalStyles.forEach((style) => {
          style.element.style.minHeight = style.minHeight;
          style.element.style.minWidth = style.minWidth;
        });
      }).catch((error) => {
        //console.error('Error generating PDF:', error);
        // Restore original styles in case of an error
        originalStyles.forEach((style) => {
          style.element.style.minHeight = style.minHeight;
          style.element.style.minWidth = style.minWidth;
        });
        this.downloadSpinner = 'pi-download';
      });
    }, 400);
  }
  downloadFullScreenWidget() {
    let canvas = document.getElementsByClassName(
      'widget-class'
    ) as HTMLCollectionOf<HTMLElement>;

    if (canvas[0].classList.contains('full-view-widget-class')) {
    } else {
      this.fullscreen();
    }

    this.pdfDownloadPressed = 'widget pdf';
    const doc = new jsPDF('p', 'mm');
    this.downloadSpinner = 'pi-spin pi-spinner';
    let data = document.getElementById('chartContent');
    setTimeout(() => {
      html2canvas(data as any, {
        onclone: (cloneDoc) => {
          const title = cloneDoc.getElementById('title-dashboard')!.style;
          title.color = '#1976D2';
          title.fontSize = '40px';
        },
      }).then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        document.getElementById('chartContent').style.overflow = 'scroll';
        const imgWidth = 179;
        const pageHeight = 295;
        const imgHeight = (canvas.height * imgWidth) / canvas.width + 50;
        let heightLeft = imgHeight;
        let position = 16; // give some top padding to first page
        doc.addImage(imgData, 'PNG', 16, 10, imgWidth, imgHeight, '0', 'SLOW');
        heightLeft -= pageHeight;

        while (heightLeft >= 0) {
          position += heightLeft - imgHeight; // top padding for other pages
          doc.addPage();
          doc.addImage(
            imgData,
            'PNG',
            16,
            position,
            imgWidth,
            imgHeight,
            '0',
            'SLOW'
          );
          heightLeft -= pageHeight;
        }
        doc.save(this.dashboardName + '.pdf');
        this.downloadSpinner = 'pi-download';
        this.pdfDownloadPressed = '';
      });
    }, 400);
  }

  @HostListener('click') clicked() {
    if (d3.select('#tibo-mydash-dashlist-container-id').style('display') == 'block') {
      this.dashboardListFunc();
    }
  }
  back() {
    this.DashboardContainer = false;
    // this.location.back();
    if (this.routingComp === 'dashboard-designer') {
      // this.location.back();
      this.router.navigate(['/dashboard-designer'], { queryParams: { search: this.metric, domainId: this.storeActivatedRouteDetails.domainId } });

    } else if (this.routingComp === 'discovery') {
      this.router.navigate(['/discovery'], { queryParams: { search: this.metric } });
    }
  }
  callStarAPI(node) {
    //console.log(node)
    if (node.favourite == "N") {
      this.dashService.addToFavourite(node.id).subscribe((res: any) => {
        node.favourite = "Y";
        this.messageService.add({
          severity: 'success',
          summary: '',
          detail: 'Successfully Added To Your Favorites!',
        });
        //this.items=this.items.filter((item)=>)
        this.items.filter((item) => {
          if (node.parent.label === item.label) {
            item.children.sort((a: any, b: any) => (b.favourite === 'Y' ? 1 : 0) - (a.favourite === 'Y' ? 1 : 0));
          }
        })
      },
        (error) => {
          if (Number(error.status) == 401) {
            this.errorMsg = 'Please Try After Some Time!!';
          } else if (Number(error.status) == 500) {
            this.errorMsg = 'Internal Error';
          } else {
            this.errorMsg = 'Please Try After Some Time!.';
          }
          // this.addSingle();
        },
        () => { }
      );
    }
    else if (node.favourite == "Y") {
      this.dashService.removeFromFavourite(node.id).subscribe((res: any) => {
        node.favourite = "N";
        this.messageService.add({
          severity: 'info',
          summary: '',
          detail: 'Successfully Deleted From Your Favorites!',
        });
        this.items.filter((item) => {
          if (node.parent.label === item.label) {
            item.children.sort((a: any, b: any) => (b.favourite === 'Y' ? 1 : 0) - (a.favourite === 'Y' ? 1 : 0));
          }
        })
      },
        (error) => {
          if (Number(error.status) == 401) {
            this.errorMsg = 'Please Try After Some Time!!';
          } else if (Number(error.status) == 500) {
            this.errorMsg = 'Internal Error';
          } else {
            this.errorMsg = 'Please Try After Some Time!.';
          }
          // this.addSingle();
        },
        () => { }
      );
    }
  }

  filterItems() {
    // Perform filtering based on searchText
    if (this.searchText.trim().length === 0) {
      this.items = [...this.filteredRecords]; // No filter, show all items
    } else {
      this.items = this.filteredRecords.filter(node => this.doesNodeMatch(node, this.searchText.toLowerCase()));
    }
  }
  doesNodeMatch(node: TreeNode, searchText: string): boolean {
    //console.log(node)
    // Function to check if a node or its children match the search text
    if (node.label.toLowerCase().includes(searchText)) {
      return true;
    }

    if (node.children) {
      for (let child of node.children) {
        if (child.children['label'].toLowerCase().includes(searchText)) {
          return true;
        }
      }
    }

    return false;
  }
}
