import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { Observable, forkJoin, throwError } from 'rxjs';

@Injectable()
export class DiscoveryService {

    constructor(private http: HttpClient) { }

    requestPayload = { "sort": [], "filters": [], "filterType": "and","parameters":[{"name":"User","value":localStorage.userEmail,"questionId":1234567890,"id":1234567890,"isVisible":"Y"}] }
    requestDataFromMultipleSources(keyword: any, param) {
        let response1 = this.http.get('api/v1/user-dashboard/widget/search/' + keyword, { headers: { 'X-Auth-Token': localStorage.getItem("token") }, observe: 'response' });
        let response2 = this.http.get('api/v1/dashboard/widgets', { headers: { 'X-Auth-Token': localStorage.getItem("token") }, observe: 'response' });
        let response3 = this.http.get('api/v1/directory/' + param + '/directory', { headers: { 'X-Auth-Token': localStorage.getItem("token") }, observe: 'response' });
        return forkJoin([response1, response2, response3]);
    }
    domainsService() {
        return this.http.get('api/v1/custom-reports/domains/dataDiscovery', { headers: { 'X-Auth-Token': localStorage.getItem("token") }, observe: 'response' })
    }
    getWidgets(keyword: any) {
        return this.http.get('api/v1/user-dashboard/widget/search/' + keyword,
            { headers: { 'X-Auth-Token': localStorage.getItem("token") }, observe: 'response' })
    }
    dashboardsListService() {
        // return this.http.get('api/v1/dashboard/domain/widgets', { headers: {'X-Auth-Token': localStorage.getItem("token")}, observe: 'response' })
        return this.http.get('api/v1/dashboard/widgets', { headers: { 'X-Auth-Token': localStorage.getItem("token") }, observe: 'response' })

    }
    directoryService(param) {
        return this.http.get('api/v1/directory/' + param + '/directory', { headers: { 'X-Auth-Token': localStorage.getItem("token") }, observe: 'response' })
    }

    recordsService(param, page, requestPayload, depth) {
        return this.http.post('api/v1/directory/' + param + '/report/search/list-view?depth=' + depth + '&limit=250&page=' + page, requestPayload,
            { headers: { 'X-Auth-Token': localStorage.getItem("token") }, observe: 'response' })
    }

    // reportAndMatricsGetService(domainId,keyword,pageNo){
    // 	return this.http.post('api/v1/datadiscovery/view/'+domainId+'/matrics?searchString='+keyword+'&limit=250&page='+pageNo, this.requestPayload,
    // 	{ headers: {'X-Auth-Token': localStorage.getItem("token")}, observe: 'response' })
    // }

    // dashWidgetsGetService(keyword){
    // 	return this.http.get('api/v1/user-dashboard/widget/search/'+keyword, { headers: {'X-Auth-Token': localStorage.getItem("token")}, observe: 'response' })
    // }



    reqPayOfDashboard = {
        "sort": [], "filters": [], "filterType": "and",
        "parameters": [{ "name": "User", "value": localStorage.userEmail, "questionId": 1234567890, "id": 1234567890, "isVisible": "Y" },
        { "name": "User", "value": localStorage.userEmail, "questionId": 0, "id": 80, "isVisible": "Y" }]
    }

    requestPayload1 = { "parameters": [{ "name": "User", "value": localStorage.userEmail }] }

    /*getADashboard(dashboardID: any) { // for a single widget
        return this.http.get('api/v1/widget/details/'+dashboardID, 
                { headers: {'X-Auth-Token': localStorage.getItem("token")}, observe: 'response' })
    }*/

    getADashboard(dashboardID: any, type: any) { // for a whole dashboard

        if (type == "dashboard") {
            return this.http.get('api/v1/dashboard/widget-details/' + dashboardID,
                { headers: { 'X-Auth-Token': localStorage.getItem("token") }, observe: 'response' })
        }
        else if (type == "widget") {
            return this.http.get('api/v1/widget/details/' + dashboardID,
                { headers: { 'X-Auth-Token': localStorage.getItem("token") }, observe: 'response' })
        }

    }
    getDash() {
        return this.http.get('api/v1/dashboard/widget-details/1',
            { headers: { 'X-Auth-Token': localStorage.getItem("token") }, observe: 'response' })
    }
   

    // getWidgetData(widgetID: any, limit) {
    //     return this.http.post('api/v1/dashboard/controls/' + widgetID + '/run?page=1&' + limit, this.reqPayOfDashboard,
    //         { headers: { 'X-Auth-Token': localStorage.getItem("token") }, observe: 'response' })
    // }



    getParameterizedDashboardItemRecords(dashboardID: any) {
        // nk
        return this.http.post('api/v1/dashboard/cascade/dashboard/' + dashboardID + '/0', this.requestPayload1,
            { headers: { 'X-Auth-Token': localStorage.getItem("token") }, observe: 'response' })
    }

    getParameterizedDashboardCascadingRecords(dashboardID: any, paramId: any, reqPay) {

        return this.http.post('api/v1/dashboard/cascade/dashboard/' + dashboardID + '/' + paramId, reqPay,
            { headers: { 'X-Auth-Token': localStorage.getItem("token") }, observe: 'response' })
    }

    getParameterizedDashboardWidgetData(widgetID: any, limit, resPalLoad: any) {

        return this.http.post('api/v1/dashboard/controls/' + widgetID + '/run?page=1&' + limit, resPalLoad,
            { headers: { 'X-Auth-Token': localStorage.getItem("token") }, observe: 'response' })
    }

    dashboardsSearchService(keyword) {
        return this.http.get('assets/json/discovery/autofill.json')
        // return this.http.post('http://mtrx-svr828.matrix.local:8082/autocomplete', { "mytext": keyword }
        // )
    }
    dashboardsSearchEngine(keyword) {
        // return this.http.post('http://72.167.143.20:8082/productionSearchEngine', {"mytext":keyword})
        // return this.http.post(' http://mtrx-svr828.matrix.local:8082/productionSearchEngine', {"mytext":keyword})
        return this.http.post('api/v1/datadiscovery/askquestions/results', keyword,
            { headers: { 'X-Auth-Token': localStorage.getItem("token") }, observe: 'response' })
    }
    tracker(payload) {
        return this.http.post('api/v1/datadiscovery/question/answer/tracker', payload,
            { headers: { 'X-Auth-Token': localStorage.getItem("token") }, observe: 'response' })
    }
    trackerSearch(payload,type) {
        return this.http.post('api/v1/datadiscovery/question/answer/tracker?searchType='+ type, payload,
            { headers: { 'X-Auth-Token': localStorage.getItem("token") }, observe: 'response' })
    }
    text2Sql(payload){
        return this.http.post('api/v1/dataDiscovery/tables/results?page=1&limit=5', payload,
            { headers: { 'X-Auth-Token': localStorage.getItem("token") }, observe: 'response' })
    }
    tablesResult(payload){
        return this.http.post('api/v1/dataDiscovery/tables/results?limit=150&page=1', payload,
            { headers: { 'X-Auth-Token': localStorage.getItem("token") }, observe: 'response' })
    }
    relevantResult(payload){
        return this.http.post('api/v1/datadiscovery/relevant/results', payload,
            { headers: { 'X-Auth-Token': localStorage.getItem("token") }, observe: 'response' })
    }
    relevantResult1(payload,id){
        return this.http.get('assets/json/discovery/'+id+'.json')
    }
    relevantResultGet(payload){
        return this.http.get('api/v1/datadiscovery/relevant/results',
            { headers: { 'X-Auth-Token': localStorage.getItem("token") }, observe: 'response' })
    }
    faqResult(payload){
        return this.http.get('api/v1/dataDiscovery/frequent/questions',
            { headers: { 'X-Auth-Token': localStorage.getItem("token") }, observe: 'response' })
    }
    faqResultPost(payload){
        return this.http.post('api/v1/dataDiscovery/frequent/questions',payload,
            { headers: { 'X-Auth-Token': localStorage.getItem("token") }, observe: 'response' })
    }
    getWidget(dashboardID){
        return this.http.get('api/v1/widget/details/'+dashboardID, 
            { headers: {'X-Auth-Token': localStorage.getItem("token")}, observe: 'response' })
    }
    getWidgetData(widgetID: any, limit,payload) {
        return this.http.post('api/v1/dashboard/controls/'+widgetID+'/run?page=1&'+limit, payload,
                { headers: {'X-Auth-Token': localStorage.getItem("token")}, observe: 'response' })
    }
    getClient(){
		return this.http.get('assets/json/runReport/clientList.json')
	}
}

// API : http://localhost:8080/timebound/api/v1/datadiscovery/question/answer/tracker
// reuqest : POST
// payload: {
//     "autoCompleteId":"45",
//     "autoCompleteResult":"Claims payment",
//     "searchCategory":"Widget",
//     "searchId":"2222",
//     "searchResult":"New Claims - [ Disability Claims ]",
//     "searchControl_id":"15"
// }