import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home/home.component'
// import { MyDashboardComponent } from './my-dashboard/my-dashboard.component';
import { CreateDashboardComponent } from './create-dashboard/create-dashboard.component';
// import { DataDiscoveryComponent } from './data-discovery/data-discovery.component';
import { MyReportsComponent } from './my-reports/my-reports.component'
import { MyReportsOutputComponent } from './my-reports-output/my-reports-output.component'
import { SchedulerComponent } from './scheduler/scheduler.component'
import { ReportCatalogueComponent } from './report-catalogue/report-catalogue.component'
import { BuildReportComponent } from './build-report/build-report.component'
import { SettingsComponent } from './settings/settings.component'
import { OldDashboardComponent } from './old-dashboard/old-dashboard.component';
// import { DashboardV3Component } from './dashboard-v3/dashboard-v3.component';
import { DashboardChartComponent } from './dashboard-chart/dashboard-chart.component';
import { LoginComponent } from './login/login.component';
import { DiscoveryCloneComponent } from './discovery-clone/discovery-clone.component';

const routes: Routes = [
    { path: 'login', component: LoginComponent },
    { path: 'home', component: HomeComponent,  data: { title: 'Home' } }, 
    // { path: 'my-dashboard', component: MyDashboardComponent,  data: { title: 'Scheduler' } },
    { path: 'dashboard-out', component: DashboardChartComponent,  data: { title: 'Dashboard' }},
    { path: 'dashboard', component: OldDashboardComponent,  data: { title: 'My Dashboard' } },
    { path: 'create-dashboard', component: CreateDashboardComponent, data: { title: 'Create Dashboard' }},
    { path: 'discovery', component: DiscoveryCloneComponent,data: { title: 'Create Dashboard' }},
    { path: 'my-reports', component: MyReportsComponent,  data: { title: 'My Reports' }},
    { path: 'view', component: MyReportsOutputComponent,  data: { title: 'View' } },
    { path: 'scheduler', component: SchedulerComponent, data: { title: 'Scheduler' } },
    { path: 'report-catalog', component: ReportCatalogueComponent,  data: { title: 'Report Catalog' } },
    { path: 'build-report', component: BuildReportComponent, data: { title: 'Build A Report' }},
    { path: 'settings', component: SettingsComponent,  data: { title: 'Settings' } },

    // { path: '', redirectTo: 'home', pathMatch: 'full' },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { useHash:true })], //{ enableTracing: true, useHash:true } { useHash:true }
    
    exports: [RouterModule]
})

export class AppRoutingModule { 

}