<div class="discovery-parent-container tibo-right-container"  >

	<div id="schedrepo-scroll-wrapper" class="tibo-schedrepo-scroll-wrapper" *ngIf="showPanel">
		<div class="main-content">
			<div class="search-bar" style="padding-top:20px;"
				[ngStyle]="{'height': isListening || isWriting? '100%':'160px'}" >
				<div class="p-grid">
					<div class="p-col-12">
						<div class="clientDrp">
							<p-dropdown [options]="clients" appendTo="body"
							optionLabel="clientName" optionValue="clientCode"
							placeholder="Select Client" [(ngModel)]="selectedClient" [filter]="true" 
							[dropdownIcon]="clientListIsLoading ? 'pi pi-spinner pi-spin' : 'pi pi-chevron-down'"
							(onChange)="dropdownChange($event)" id="shepherd-selectClient">
							</p-dropdown>
						</div>
					</div>
					<div class="p-col-12" *ngIf="showSearch">
					<!-- <div class="p-md-6 p-sm-9 p-px-0"> -->
						<div class="search-div">
							<input type="text" [(ngModel)]="myText" (ngModelChange)="onSearchBtnClick1()" pInputText
								id="data-disco-business-metric-text-id" class="search-input"
								placeholder="How May I Assist You?" (keyup.enter)="oldApi('enter')" (keyup.enter)="isListening=false" (keyup.enter)="isWriting=false" [pTooltip]="myText"
								tooltipPosition="bottom" autocomplete="off" id="shepherd-askTab"/>
							<!-- (ngModelChange)="onSearchBtnClick1()"  -->
							<div class="search--clear-bar-icon2">
								<i class="fa fa-close" (click)="myText =''" (click)="oldApi('enter')" pTooltip="Clear"></i>
							</div>
							<div class="search-bar-icon2">

								<i class="fa fa-search" (click)="oldApi('enter')" pTooltip="Click to Search"></i>
							</div>
							<div class="microphone-icon" tooltip="Search by Voice" (click)="toggleListening()"
								*ngIf="!isListening"  [class.disabled]="disableMike" >
								<i class="fa fa-microphone-slash" pTooltip="Click to Speak"  [ngClass]="{'disabled': disableMike}"></i>
							</div>
							<div class="microphone-icon" tooltip="Search by Voice" (click)="toggleListening()"
								*ngIf="isListening"  [class.disabled]="disableMike" >
								<i class="fa fa-microphone" [ngClass]="{'listening': isListening, 'disabled': disableMike}"
									pTooltip="Click to Stop"></i>
							</div>
						</div>
						<div *ngIf="isListening || isWriting" class="heightFix">
							<div *ngIf="isListening">
								<p class="speak-now-text" *ngIf="!spokenText">Speak now...</p>
							</div>
							<div *ngIf="isListening" class="lists">
								<ul class="suggestionUl">
									<li *ngFor="let item of questions" (click)="onSearchBtnClick2(item)" style="cursor: pointer;">
										{{
										item.autoCompleteResult }}</li>
								</ul>
							</div>

							<div *ngIf="isWriting">
								<div *ngIf="isWriting" class="lists">
									<ul class="suggestionUl">
										<li *ngFor="let item of questions" 
											(click)="onSearchBtnClick2(item)" style="cursor: pointer;">{{
											item.autoCompleteResult }}</li>
									</ul>
								</div>

							</div>
						</div>
					<!-- </div> -->
				</div>
				</div>
			</div>
			<!-- <div *ngIf="showQuestions" class="ques">
				<div *ngFor="let quest of this.questions" class="questionsList">
					<label class="question" (click)="gotoCharts(quest)">{{quest.searchResult}}</label>
				</div>
			</div> -->
			<div *ngIf="showBoxes" style="height: 65vh;overflow: scroll;">
				<div *ngIf="loader" class="spinner-overlay">
					<img src="././assets/images/dualBallSpinner.svg" alt="" class="modal-spinner-img">
				</div>

				<div style="width:99%">
					<div class="p-grid">
						<div class="p-col-12">
							<div *ngIf="showResult" id="showResult">
								<!-- <div class="chartDivContainer"> -->
									<img src="././assets/images/dualBallSpinner.svg" alt=""
											class="modal-spinner-img" *ngIf="isLoading">
								<div #chart1 id="chart1">
								</div>
								<button style="display: none;" class="chartResiz" id="resizeBtn" type="button"></button>
							<!-- </div> -->
							</div>
							<div *ngIf="showEmpty" class="chartEmptyClass">
								<p>We're working on an answer. Please check back in 7 days.</p>
							<p></p>
								<p>Thank you for your question and for helping us improve our "Data Discovery" feature.</p>
							</div>
							<!-- <div class="frequentReport"> -->
								<div style="font-size: 14px;font-weight: 600;padding-left: 15px;padding-top: 40px;" *ngIf="this.questions2.length>0">
									Relevant Result</div>
								<!-- <div *ngIf="showReleventResult"> -->
									<div *ngFor="let quest of this.questions2" class="questionsList">
										<p class="question"
											(click)="getDashboard1(quest)">
											<i class="fa-regular fa-clipboard menu-panel-icons" style="padding-right: 10px;" *ngIf="quest.searchCategory == 'Report'"></i>
											<img src="././assets/images/chart-icon/chart0.svg" style='width: 24px;padding-top: 10px;height: 27px;padding-right:4px' *ngIf="quest.searchCategory == 'Dashboard'">
											<i class="fa-solid fa-chart-column menu-panel-icons" style="padding-right: 10px;" *ngIf="quest.searchCategory == 'Widget'"></i>
											<i class="fa-solid fa-table-cells" style="padding-right: 10px;" *ngIf="quest.searchCategory == 'grid'"></i>
											<img src="././assets/images/chart-icon/reporting.svg" style='width: 16px;padding-top: 10px;height: 27px;' *ngIf="quest.searchCategory == 'Build A Report'">
											<label style="font-size:14px;font-weight: 700;">{{quest.searchResult.replace('.rptdesign','').replace('.sql','')}}</label>
										</p>
										<p style="font-size:14px;font-weight:500;padding-left:40px;">{{quest.searchDescription?quest.searchDescription:'No Description'}}</p>
									</div>
									
								<!-- </div> -->
								<!-- <div style="font-size: 18px;font-weight: 700;padding-left: 36px;padding-top: 26px;" *ngIf="showerror">No Records Found</div> -->

							<!-- </div> -->
							<!-- <div class="result">
								<div style="font-size: 18px;font-weight: 600;padding-left: 15px;padding-top: 10px;">
									Search Result</div>
								<div class="chart-container">
									<div *ngIf="loaderShow"> <p-progressBar mode="indeterminate"
											[style]="{'height': '3px'}"></p-progressBar>
									</div> 
									<div *ngIf="showParameters" style="display:flex">
										<div class="tibo-mydash-dashboard-title">
											<div class="tibo-mydash-dashlistbtn-dashoptsbtn-container-2">
												<div *ngFor="let row of parameterElementsArray let i = index"
													class="row" style="float:left; padding: 10px 10px 0px;">
													<div *ngIf="parameterElementsArray[i].fetchedRecordsBool">
														<div style="text-align:left ">
														</div>
														<p-multiSelect class="search-multiselect" appendTo="body"
															*ngIf="parameterElementsArray[i].displayType == 'Multi Select'"
															[attr.selectInfoIndex]=i
															[options]="parameterElementsArray[i].fetchedRecords?.listEle"
															[optionLabel]="parameterElementsArray[i].fetchedRecords?.records?.parameterLabel"
															[optionValue]="parameterElementsArray[i].fetchedRecords?.records?.parameterValue"
															[defaultLabel]="'Please select '"
															[(ngModel)]="parameterElementsArray[i].selectedData"
															placeholder="{{'Select ' + parameterElementsArray[i].parameterName.replaceAll('_', ' ') }}"
															pTooltip="{{'Select ' + parameterElementsArray[i].parameterName.replaceAll('_', ' ') }}"
															tooltipPosition="top" (onChange)="onChange($event,refEl,i)"
															#refEl>
														</p-multiSelect>
														<p-dropdown class="search-multiselect" [filter]="true"
															appendTo="body" *ngIf="parameterElementsArray[i].displayType == 'Select'"
															[attr.selectInfoIndex]=i
															[options]="parameterElementsArray[i].fetchedRecords?.records?.records"
															[optionLabel]="parameterElementsArray[i].fetchedRecords?.records?.parameterLabel "
															[optionValue]="parameterElementsArray[i].fetchedRecords?.records?.parameterValue"
															placeholder="{{'Select ' + parameterElementsArray[i].parameterName.replaceAll('_', ' ') }}"
															pTooltip="{{'Select ' + parameterElementsArray[i].parameterName.replaceAll('_', ' ') }}"
															tooltipPosition="top"
															[(ngModel)]="parameterElementsArray[i].selectedData"
															(ngModelChange)="onChange($event,refEl,i)" #refEl>
														</p-dropdown>
													</div>
												</div>
											</div>
										</div>
										<div class="subBtn">
											<button pButton type="button" id="submitbutton-id" label="Submit"
												style="border-radius: 20px;" (click)="onFilterSubmitClick()"></button>
										</div>
									</div>
									<div *ngIf="loaderShow1"> <p-progressBar mode="indeterminate"
											[style]="{'height': '3px'}"></p-progressBar>
									</div>
									<img src="././assets/images/dualBallSpinner.svg" alt=""
											class="modal-spinner-img" *ngIf="isLoading">
									<div *ngIf="showResult" id="showResult">
										<div class="chartDivContainer">
										<div #chart1 id="chart1" style="height:43vh">
										</div>
										<button style="display: none;" class="chartResiz" id="resizeBtn" type="button"></button>
									</div>
									</div>
									<div *ngIf="errorMessage"
									style="font-size: 18px;font-weight: 600;padding-left: 70px;padding-top: 80px;">
									No Result Found
									</div>
								</div>
							</div> -->
						</div>
							<!-- <div class="p-col-12">
								<div class="frequentReport">
									<div style="font-size: 14px;font-weight: 600;padding-left: 15px;padding-top: 10px;">
										Relevant Result</div>
									<div *ngIf="showReleventResult" style="height: 14vh;overflow: scroll;">
										<div *ngFor="let quest of this.questions2" class="questionsList">
											<p class="question"
												(click)="getDashboard1(quest)">
												<i class="fa-regular fa-clipboard menu-panel-icons" style="padding-right: 10px;" *ngIf="quest.searchCategory == 'Report'"></i>
												<img src="././assets/images/chart-icon/chart0.svg" style='width: 24px;padding-top: 10px;height: 27px;' *ngIf="quest.searchCategory == 'Dashboard'">
												<i class="fa-solid fa-chart-column menu-panel-icons" style="padding-right: 10px;" *ngIf="quest.searchCategory == 'Widget'"></i>
												<img src="././assets/images/chart-icon/reporting.svg" style='width: 16px;padding-top: 10px;height: 27px;' *ngIf="quest.searchCategory == 'Build A Report'">
												{{quest.searchResult.replace('.rptdesign','').replace('.sql','')}}
											</p>
										</div>
										
									</div>
									<div style="font-size: 12px;font-weight: 500;padding-left: 15px;padding-top: 10px;" *ngIf="showerror">No Records Found</div>

								</div>
							</div> -->
					</div>
				</div>
			</div>
		</div>
		
	</div>

<div *ngIf="chartShow1" class="chart">
	<button class="tibo-mydash-chartcoloroptions-btn" type="button" pButton icon="pi pi-angle-left"
		(click)=" backToSearch1()" pTooltip="Back to Search"></button>
	<app-old-dashboard [chartData]="sampleChartData1" *ngIf="dashboardSelected"></app-old-dashboard>
	<app-my-reports-output [chartData1]="sampleChartData2" *ngIf="reportSelected"></app-my-reports-output>
	<app-build-report [chartData4]="sampleChartData4" *ngIf="buildReportSelected"></app-build-report>
	<app-scheduler [chartData5]="sampleChartData5" *ngIf="scheduleSelected"></app-scheduler>
</div>
<p-dialog [style]="{width:'30vw'}" [baseZIndex]="10000" header="Comments" [draggable]="false"
	[(visible)]="showCommentsDialog" [modal]="true" [position]="'top'" class="confirm-dialog">
	<input type="text" pInputText [(ngModel)]="commentName" placeholder="Enter Comments" required
		class="form-input input-lg">
	<ng-template pTemplate="footer">
		<p-button type="close" class="cancel-btn" label="Close" value="Update"
			(click)="showCommentsDialog = false "></p-button>
		<p-button type="button" class="save-btn" label="Save" (click)="saveComment()"></p-button>
	</ng-template>
</p-dialog>
</div>
<p-toast></p-toast>