
<div class="tibo-myreport-container-wrapper tibo-right-container">
    <div id="myrepo-scroll-wrapper" class="tibo-myrepo-scroll-wrapper">
        <p-toast styleClass="tibo-buildrepo-toast" position="center"></p-toast>
        <div id="myreports-folderview-panel" class="tibo-myrepo-folderview-panel">
            <span id="all-reports-id" class="tibo-myrepo-folderview-panel-title">Report Parameter</span>

            <button type="button" class="tibo-myrepo-folderview-panel-arrow-icon" (click)="folderPanelToggle()"></button>

            <p-scrollPanel id="myreport-folder-panel-scroll-div" styleClass="tibo-myrepo-folderview-panel-scrollpanel">
                <div class="scrollable pagecontent" resize="" *ngIf="!folderCollapse">
                    <ng-container>
                        <div class="tibo-buildrepo-repofolders-tree-cont">
                            <div class="rows">
                                <div class="list">
                                    <div> <label class="list-group-item title">Build A Report For<span class="required">
                                                *</span></label>
                                    </div>
                                    <div class="list-group-item"> <select (ngModelChange)="dropdownChange(event)"
                                            class="form-control ng-valid ng-dirty ng-valid-parse ng-touched"
                                            style=" width: 18rem;height: 2rem;" [(ngModel)]="selectedDomain" 
                                            ng-disabled="IsScheduledPermitedDomainListLoaded"
                                            ng-options="record as record.authorizationName for record in records"
                                           >
                                            <option value="" disabled="" selected="" style="display:none" class="">
                                                Select </option>
                                            <option value="0" label="Disability">Disability</option>
                                            <option value="1" label="Leave">Leave</option>
                                        </select> </div>
                                    <div class="list-group-item" *ngIf="selectedDomain=='0'"> <label> <input
                                                type="radio" [(ngModel)]="selectedValue"
                                                class="ng-valid ng-dirty ng-touched" name="button" [checked]="demand"
                                                value="onDemand"  (change)="selectedValue=$event.target.value"
                                                #demandRadio>
                                            On
                                            Demand
                                        </label>&nbsp;&nbsp;&nbsp; <label> <input type="radio" ng-model="selectedValue"
                                                name="button" value="schedule" (click)="confirm()" #scheduleRadio>
                                            Schedule </label> </div>
                                    <div class="list-group-item" *ngIf="selectedDomain=='1'"> <label> <input
                                                type="radio" [(ngModel)]="selectedValue"
                                                class="ng-valid ng-dirty ng-touched" name="button" [checked]="Leave"
                                                value="leavee" (change)="selectedValue=$event.target.value"
                                                #demandRadio>
                                            On
                                            Demand
                                        </label>&nbsp;&nbsp;&nbsp; <label> <input type="radio" ng-model="selectedValue"
                                                name="button" value="schedule" (click)="confirm()" #scheduleRadio>
                                            Schedule </label> </div>

                                    <div *ngIf="Is">
                                        <p-confirmDialog [style]="{width:'600px'}" [baseZIndex]="10000">
                                            <p-footer>
                                                <div style="margin-top:2rem">
                                                    <button type="button" pButton label="Cancel" value="Update"
                                                        class="button-tertiary" (click)="cancel()"></button>
                                                    <button type="button" label="Ok" class="ok"
                                                        (click)="ok()">ok</button>
                                                </div>
                                            </p-footer>
                                        </p-confirmDialog>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container>
                        <div class="rows" *ngIf="selectedDomain=='1'">

                            <div *ngIf="selectedValue=='leavee'">

                                <div >


                                    <form [formGroup]="leaveForm" novalidate>
                                        <div class="company">
                                        <div class="rows ng-scope" style="font-size: 16px;">
                                            <div class="list">
                                                    <div id="46" class="questionlabel"> <label id="s46"
                                                            class="list-group-item ng-binding title">Select A Client
                                                    <span class="required">*</span></label> </div>
                                                    <div class="list-group-item ng-binding ng-scope"
                                                        ng-repeat="a in questions.a"
                                                        ng-show="checkVisibility(a,questions)">


                                                        <select formControlName="clientname" class="form-control value " ng-model="selectedcompany"
                                                            style=" width: 18rem;height: 2rem;">
                                                            <option value="ABC COMPANY" class="value"
                                                                selected="selected" label="ABC COMPANY">
                                                            </option>
                                                        
                                                        </select>

                                                    </div>
                                                </div>
                                        </div>
                                        </div>
                                        <div class="disability">
                                        <div class="rows ng-scope" style="font-size: 16px;">
                                            <div class="list">
                                                <div id="47" class="questionlabel"> <label id="s47"
                                                        class="list-group-item ng-binding title">Select A Leave Type
                                                        <span class="required">*</span></label> </div>
                                                <div class="list-group-item ng-binding ng-scope value">
                                                    <input type="radio" formControlName="leavetype" name="leavetype" (click)="leaveType('Inter.')" value="Inter.">
                                                    Intermittent

                                                </div>
                                                <div class="list-group-item ng-binding ng-scope value">
                                                    <input type="radio" formControlName="leavetype" name="leavetype" (click)="leaveType('Cont.')" value="Cont.">
                                                    Continuous

                                                </div>
                                                <div class="list-group-item ng-binding ng-scope value">
                                                    <input type="radio" formControlName="leavetype" name="leavetype" (click)="leaveType('In')" value="In">
                                                    Both

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="data">
                                        <div class="rows ng-scope" style="font-size: 16px;">
                                            <div class="list">
                                                <div id="47" class="questionlabel"> <label id="s47"
                                                        class="list-group-item ng-binding title">Select A Data Set
                                                        <span class="required">*</span></label> </div>
                                                <div class="list-group-item ng-binding ng-scope value">
                                                    <input type="radio" formControlName="datatype" (click)="currentLeave('N')" name="datatype" value="N">
                                                    Current Open Leaves

                                                </div>
                                                <div class="list-group-item ng-binding ng-scope value">
                                                    <input type="radio" formControlName="datatype" (click)="leaveDate('Y')"name="datatype" value="Y">
                                                    Leaves Closed In The Period

                                                </div>
                                                <div class="list-group-item ng-binding ng-scope value">
                                                    <input type="radio" formControlName="datatype"(click)="leaveDate('ACTUAL_RTW_DATE')" name="datatype" value="ACTUAL_RTW_DATE">
                                                    Actual Return To Work In The Period
                                                </div>
                                                <div class="list-group-item ng-binding ng-scope value">
                                                    <input type="radio" formControlName="datatype"(click)="leaveDate('ESTIMATED_RTW_DATE')" name="datatype" value="ESTIMATED_RTW_DATE">
                                                    Expected Return To Work In The Period

                                                </div>
                                                <div class="list-group-item ng-binding ng-scope value">
                                                    <input type="radio" formControlName="datatype" (click)="leaveDate('REQUESTED_START_DATE')" name="datatype" value="REQUESTED_START_DATE">
                                                    Leaves Filed In The Period

                                                </div>
                                             
                                            </div>
                                        </div>
                                    </div>
                                    <div class="date">
                                        <div class="rows ng-scope" style="font-size: 16px;">
                                            <div class="list">
                                                <div id="48" class="questionlabel"> <label id="s48"
                                                        class="list-group-item ng-binding title">Select A Time Period<span
                                                            class="required">*</span></label> </div>
                                                            <div *ngIf="date">
                                                            <div class="list" style="background-color:white">
                                                                <div>
                                                                    <label class="list-group-item title">Start Date</label>
                                                                    <div class="p-field list-group-item" style="margin-top:3rem">
    
                                                                        <p-calendar formControlName="startdate" [(ngModel)]="startDate" styleClass="tibo-buildrepo-output-param-calendar top"
                                                                            [showIcon]="true" inputId="icon" 
                                                                            class="calendar" 
                                                                            (click)="leaveStartDate()">
                                                                        </p-calendar>
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                    <label class="list-group-item title" for="basic">End
                                                                        Date</label>
                                                                    <div class="p-field list-group-item" style="margin-top:4rem">
    
                                                                        <p-calendar inputId="basic" styleClass="tibo-buildrepo-output-param-calendar-endate top"
                                                                            formControlName="enddate" [(ngModel)]="endDate" class="calendar"
                                                                            [showIcon]="true" 
                                                                            (click)="leaveStartDate()">
                                                                        </p-calendar>
                                                                    </div>
    
                                                                </div>
                                                            </div>
                                                        </div>
                                            </div>
                                        </div>
                                        </div>


                                        <div class="rows" style="font-size: 16px;">
                                            <div class="submit-btn"style="text-align: center; margin-left: 0px;">

                                                <div class="submit-btn"style="text-align: center; margin-left: 15px;">
                                                <button type="submit" class="btn btn-default" (click)="report()" style="margin-right:5px;">Save
                                                    Report Parameters</button>
                                                    <button class="btn btn-default" (click)="resetForm()" style="margin-right:5px;"> Clear</button>
                                                <button type="submit" class="tibo-data-disco-submitbtn"
                                                (click)="leave()">Run</button>
                                                  
                                               
                                              

                                            </div>

                                    

                                            </div>
                                            <hr>
                                            <div class="scrollable ng-scope" ng-if="listOfFilter"
                                                style="height:auto; max-height:250px; overflow-y:auto">
                                                <div class="rows">
                                                    <ul data-is-saved="isSaved" class="list-group"> <label
                                                            class="list-group-item title">Your
                                                            Saved
                                                            Report Parameters</label>

                                                        <li class="list-group-item ng-scope"
                                                            ng-repeat="filter in listSaveFilter"> <a
                                                                class="filterWidth ng-binding value"
                                                                style="cursor:pointer"
                                                                ng-click="nameOfFilter(filter.adhocSaveFilterData.qa);">Leave Details
                                                                </a>
                                                            <div class="pull-right"> <button class="btn-sm btn-default"
                                                                    pTooltip="Remove" placement=""
                                                                    data-ng-click="removeItem($index)"><i
                                                                        class="pi pi-times"></i></button> </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div>
                                                    <ul class="Unorderedli">
                                                        <li *ngFor="let list of savedData">
                                                            <button class="savedPara" (click)="onSelect(list)"> {{list.Name}}</button>
                                                        
                                                        </li>
                                                      </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container>
                        <div class="rows" *ngIf="selectedDomain=='0'">

                            <div *ngIf="selectedValue=='onDemand'">
                                <div>

                                    <form [formGroup]="disabilityForm" novalidate>
                                        <div class="company">
                                            <div class="rows ng-scope" style="font-size: 16px;"
                                                *ngIf="selectedValue=='onDemand'">
                                                <div class="list">
                                                    <div id="46" class="questionlabel"> <label id="s46"
                                                            class="list-group-item ng-binding title">Company
                                                            Name<span class="required">*</span></label> </div>
                                                    <div class="list-group-item ng-binding ng-scope"
                                                        ng-repeat="a in questions.a"
                                                        ng-show="checkVisibility(a,questions)">


                                                        <select formControlName="name" class="form-control value " ng-model="selectedcompany" 
                                                            style=" width: 18rem;height: 2rem;" >
                                                            <option value="ABC COMPANY" class="value"
                                                                selected="selected" label="ABC COMPANY">
                                                            </option>
                                                        
                                                        </select>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="disability">
                                            <div class="rows ng-scope" style="font-size: 16px;"
                                                *ngIf="selectedValue=='onDemand'">
                                                <div class="list">
                                                    <div id="47" class="questionlabel"> <label id="s47"
                                                            class="list-group-item ng-binding title">Select A Disability
                                                            Type
                                                            <span class="required">*</span></label> </div>
                                                    <div class="list-group-item ng-binding ng-scope value">
                                                        <input type="radio" formControlName="value" name="value"
                                                            value="STD" (click)="disabiltyType('STD')">
                                                        Short Term Disability

                                                    </div>
                                                    <div class="list-group-item ng-binding ng-scope value">
                                                        <input type="radio" formControlName="value" name="value"
                                                            value="LTD" (click)="disabiltyType('LTD')">
                                                        Long Term Disability

                                                    </div>
                                                    <div class="list-group-item ng-binding ng-scope value">
                                                        <input type="radio" formControlName="value" name="value"
                                                            value="IN" (click)="disabiltyType('both')">
                                                        Both

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="data">
                                            <div class="rows ng-scope" style="font-size: 16px;"
                                                *ngIf="selectedValue=='onDemand'">
                                                <div class="list">
                                                    <div id="47" class="questionlabel"> <label id="s47"
                                                            class="list-group-item ng-binding title">Select A Data Set
                                                            <span class="required">*</span></label> </div>
                                                    <div class="list-group-item ng-binding ng-scope value">
                                                        <input type="radio" (click)="datavalue('OPEN')"
                                                            formControlName="data" name="data" value="OPEN">
                                                        Current Open Claims

                                                    </div>
                                                    <div class="list-group-item ng-binding ng-scope value">
                                                        <input type="radio" (click)="dateByValue('ACTUAL_RTW_DATE')"
                                                            formControlName="data" name="data" value="ACTUAL_RTW_DATE">
                                                        Actual Return To Work In The Period

                                                    </div>
                                                    <div class="list-group-item ng-binding ng-scope value">
                                                        <input type="radio" (click)="dateByValue('EXPECTED_RTW_DATE')"
                                                            formControlName="data" name="data" value="EXPECTED_RTW_DATE">
                                                        Expected Return To Work In The Period

                                                    </div>
                                                    <div class="list-group-item ng-binding ng-scope value">
                                                        <input type="radio" (click)="dateByValue('CLAIM')"
                                                            formControlName="data" name="data" value="CLAIM">
                                                        Claim Filed In The Period

                                                    </div>
                                                    <div class="list-group-item ng-binding ng-scope value">
                                                        <input type="radio" formControlName="data" name="data"
                                                            value="CLOSED" (change)="dateField()"
                                                            (click)="datavalue('CLOSED')">
                                                        Claim Closed In The Period

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="date">
                                            <div class="rows ng-scope" style="font-size: 16px;"
                                                *ngIf="selectedValue=='onDemand'">
                                                <div class="list">
                                                    <div id="48" class="questionlabel"> <label id="s48"
                                                            class="list-group-item ng-binding title">Activity In A
                                                            Period<span class="required">*</span></label> </div>
                                                    <div *ngIf="datePicker">
                                                        <div class="list" style="background-color:white">
                                                            <div>
                                                                <label class="list-group-item title">Start Date</label>
                                                                <div class="p-field list-group-item" style="margin-top:3rem">

                                                                    <p-calendar formControlName="calendar" 
                                                                    [(ngModel)]="startDate"
                                                                        [showIcon]="true" inputId="icon"
                                                                        class="calendar" styleClass="tibo-buildrepo-output-param-calendar top"
                                                                        (click)="selecteDate()">
                                                                    </p-calendar>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <label class="list-group-item title" for="basic">End
                                                                    Date</label>
                                                                <div class="p-field list-group-item" style="margin-top:4rem">

                                                                    <p-calendar inputId="basic" styleClass="tibo-buildrepo-output-param-calendar-endate top"
                                                                        formControlName="calendar1" class="calendar"  [(ngModel)]="endDate"
                                                                        [showIcon]="true"
                                                                        (click)="selecteDate()">
                                                                    </p-calendar>
                                                                </div>

                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="rows" style="font-size: 16px;" *ngIf="selectedValue=='onDemand'">
                                            <div class="submit-btn"style="text-align: center; margin-left: 15px;">
                                                <button type="submit" class="btn btn-default" (click)="report()" style="margin-right:0px;">Save
                                                    Report Parameters</button>
                                                    <button class="btn btn-default" (click)="resetForm()" style="margin-right:0px;"> Clear</button>
                                                <button type="submit" class="tibo-data-disco-submitbtn" value="Run1"
                                                    (click)="disability()"  style="margin-right:17px;">Run</button>


                                            </div>
                                            <hr>
                                            <div class="scrollable ng-scope" ng-if="listOfFilter"
                                                style="height:auto; max-height:250px; overflow-y:auto">
                                                <div class="rows">
                                                    <div data-is-saved="isSaved" class="list-group"> <label
                                                            class="list-group-item title">Your
                                                            Saved
                                                            Report Parameters</label>
                                                        

                                                        <div class="list-group-item ng-scope"
                                                            ng-repeat="filter in listSaveFilter"> <a
                                                                class="filterWidth ng-binding title"
                                                                style="cursor:pointer"
                                                                ng-click="nameOfFilter(filter.adhocSaveFilterData.qa);">Disability
                                                                Details
                                                            </a>
                                                            <div class="pull-right"> <button class="btn-sm btn-default"
                                                                    pTooltip="Remove" placement=""
                                                                    data-ng-click="removeItem($index)"><i
                                                                        class="pi pi-times"></i></button> </div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <ul class="Unorderedli">
                                                            <li *ngFor="let list of savedData1">
                                                                <button class="savedPara" (click)="onSelect(list)"> {{list.Name}}</button>
                                                            
                                                            </li>
                                                          </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </p-scrollPanel>

        </div>
            <div id="myreports-tile-list-scroll-view" class="tibo-myrepo-tile-list-scrolview-wrapper" *ngIf="selectedValue=='onDemand' || value1">
                
                <div style="width:100%;height:100%" *ngIf="value1">
                    <select class="summaryDrpdwn" name="Disability" [(ngModel)]="selectedDisability" (change)="SelectedDis(selectedDisability)">
                        <option value="detail" selected>Disability Detail</option>
                        <option value="summary"> Disability Summary</option>
                      </select>
                <div  *ngIf="selectedDisability == 'detail'">  
                    <div class="drpFilterChkbox" style="float:right">
                        <button type="button" class="drpButton hideSearch" (click)="showdropdown=!showdropdown"><i class="pi pi-eye-slash" pTooltip="show/hide column" tooltipPosition="top"></i></button>
                        <button class="drpButton" (click)="Refresh(coldata )"><i class="pi pi-refresh" pTooltip="Refresh" tooltipPosition="top"></i></button>
                        <button class="drpButton hideDownload" (click)="showmenu=!showmenu"><i class="pi pi-download" pTooltip="Download" tooltipPosition="top"></i></button>
                        <span class="p-input-icon-right">
                            <i class="pi pi-search"></i>
                            <input type="text" pInputText [(ngModel)]="value"  placeholder="Search"/>
                        </span>
                        <div appClickOutside (appClickOutside)="hideDiv()" *ngIf="this.showdropdown" class="DropMenus" >
                            <input class="form-control" type="text" name="search" [(ngModel)]="searchText" autocomplete="off" placeholder="Search...">
                            <div class="dropContainer" *ngFor="let d of coldata  |filter: searchText ">
                            <input type="checkbox"  name="{{d.field}}" (change)="binSelect($event,d)" [checked]="d.checked" >{{d.headerName}}<br></div></div>
                            <div appClickOutside (appClickOutsideDownload)="hideDownloadDiv()" *ngIf="showmenu" class="downloadmenu">
                                <button type="button" class="downloadall" (click)="download1(1)" >Download All</button><br>
                                <button type="button" class="downloadall" (click)="download2(1)">Download filtered grid</button>
                            </div>
                    </div>
                
                <ag-grid-angular 
                    style="width: 100%; height:593px;clear: both;" 
                    class="ag-theme-alpine"
                    [rowData]="this.rowData |filter: value" 
                    [columnDefs]="this.columnDefs "
                    [defaultColDef]="defaultColDef"
                    [enableFilter]="true"
                    [pagination]="true"
                    [gridOptions]="gridOptions"
                    [setQuickFilter]="quickFilterText" 
                    (gridReady)="onGridReady($event)"
                    >
                </ag-grid-angular>
                </div>
                <div  *ngIf="selectedDisability == 'summary'">
                    <div class="drpFilterChkbox" style="float:right">
                            
                            <button type="button" class="drpButton hideSearch" (click)="showdropdown = !showdropdown;" ><i class='pi pi-eye-slash' tooltipPosition="top"></i></button>
                            <button class="drpButton" (click)="Refresh(coldata)"><i class="pi pi-refresh" pTooltip="Refresh" tooltipPosition="top"></i></button>
                            <button type="button" class="drpButton hideDownload" (click)="showmenu=!showmenu" ><i class="pi pi-download" pTooltip="Download" tooltipPosition="top"></i></button>
                            <span class="p-input-icon-right">
                                <i class="pi pi-search"></i>
                                <input type="text" pInputText [(ngModel)]="value" placeholder="Search"/>
                            </span>
                            <div appClickOutside (appClickOutside)="hideDiv()" *ngIf="showdropdown" class="DropMenus">
                                <input class="form-control" type="text" name="search" [(ngModel)]="searchText" autocomplete="off" placeholder="Search...">
                                <div class="dropContainer" *ngFor="let d of coldata  |filter: searchText ">
                                <input type="checkbox"  name="{{d.field}}" (change)="binSelect($event,d)" [checked]="d.checked" >{{d.headerName}}<br></div></div>
                            <div appClickOutside (appClickOutsideDownload)="hideDownloadDiv()" *ngIf="showmenu" class="downloadmenu">
                                <button type="button" class="downloadall" (click)="download1(1)" >Download All</button><br>
                                <button type="button" class="downloadall" (click)="download2(1)">Download filtered grid</button>
                            </div>
                  </div>
                <ag-grid-angular 
                    style="width: 100%; height: 593px;clear: both;" 
                    class="ag-theme-alpine"
                    [rowData]="this.rowData  |filter: value" 
                    [columnDefs]="this.columnDefs"
                    [defaultColDef]="defaultColDef"
                    [enableFilter]="true"
                    [gridOptions]="gridOptions"
                    [setQuickFilter]="quickFilterText" 
                    (gridReady)="onGridReady($event)">
                </ag-grid-angular>
            </div>
            </div>
            </div>
            <div id="myreports-tile-list-scroll-view" class="tibo-myrepo-tile-list-scrolview-wrapper"  *ngIf="selectedValue=='leavee' || value2">
                <div class="gridBox" *ngIf="value2">
                    <select class="summaryDrpdwn" name="leave"  [(ngModel)]="selectedLeave" (change)="Selected(selectedLeave)">
                        <!-- <option value="" slected></option> -->
                        <option value="Detail" selected>Leave Detail</option>
                        <option value="Lsummary" > Leave Summary</option>
                        
                      </select>
                    <div  *ngIf="selectedLeave == 'Detail'">
                    <div class="drpFilterChkbox" style="float:right">
                            
                            <button type="button" class="drpButton hideSearch" (click)="showdropdown=!showdropdown" ><i class='pi pi-eye-slash' tooltipPosition="top"></i></button>
                            <button class="drpButton" (click)="Refresh(coldata1)"><i class="pi pi-refresh" pTooltip="Refresh" tooltipPosition="top"></i></button>
                            <button type="button" class="drpButton hideDownload" (click)="showmenu=!showmenu" ><i class="pi pi-download" pTooltip="Download" tooltipPosition="top"></i></button>
                            <span class="p-input-icon-right">
                                <i class="pi pi-search"></i>
                                <input type="text" pInputText [(ngModel)]="value" placeholder="Search"/>
                            </span>
                            <div appClickOutside (appClickOutside)="hideDiv()" *ngIf="showdropdown" class="DropMenus">
                                <input class="form-control" type="text" name="search" [(ngModel)]="searchText" autocomplete="off" placeholder="Search...">
                                <div class="dropContainer" *ngFor="let d of coldata1  |filter: searchText ">
                                <input type="checkbox"  name="{{d.field}}" (change)="binSelect($event,d)" [checked]="d.checked" >{{d.headerName}}<br></div></div>
                            <div appClickOutside (appClickOutsideDownload)="hideDownloadDiv()" *ngIf="showmenu" class="downloadmenu">
                                <button type="button" class="downloadall" (click)="download1(2)" >Download All</button><br>
                                <button type="button" class="downloadall" (click)="download2(2)">Download filtered grid</button>
                            </div>
                  </div>
                <ag-grid-angular 
                    style="width: 100%; height: 593px;clear: both;" 
                    class="ag-theme-alpine"
                    [rowData]="this.rowData  |filter: value" 
                    [columnDefs]="this.columnDefs"
                    [defaultColDef]="defaultColDef"
                    [enableFilter]="true"
                    [gridOptions]="gridOptions"
                    [setQuickFilter]="quickFilterText" 
                    (gridReady)="onGridReady($event)">
                </ag-grid-angular>
            </div>
            <div  *ngIf="selectedLeave == 'Lsummary'">
                <div class="drpFilterChkbox" style="float:right">
                        
                        <button type="button" class="drpButton hideSearch" (click)="showdropdown=!showdropdown" ><i class='pi pi-eye-slash' tooltipPosition="top"></i></button>
                        <button class="drpButton" (click)="Refresh(coldata11)"><i class="pi pi-refresh" pTooltip="Refresh" tooltipPosition="top"></i></button>
                        <button type="button" class="drpButton hideDownload" (click)="showmenu=!showmenu" ><i class="pi pi-download" pTooltip="Download" tooltipPosition="top"></i></button>
                        <span class="p-input-icon-right">
                            <i class="pi pi-search"></i>
                            <input type="text" pInputText [(ngModel)]="value" placeholder="Search"/>
                        </span>
                        <div appClickOutside (appClickOutside)="hideDiv()" *ngIf="showdropdown" class="DropMenus">
                            <input class="form-control" type="text" name="search" [(ngModel)]="searchText" autocomplete="off" placeholder="Search...">
                            <div class="dropContainer" *ngFor="let d of coldata11  |filter: searchText ">
                            <input type="checkbox"  name="{{d.field}}" (change)="binSelect($event,d)" [checked]="d.checked" >{{d.headerName}}<br></div></div>
                        <div appClickOutside (appClickOutsideDownload)="hideDownloadDiv()" *ngIf="showmenu" class="downloadmenu">
                            <button type="button" class="downloadall" (click)="download1(2)" >Download All</button><br>
                            <button type="button" class="downloadall" (click)="download2(2)">Download filtered grid</button>
                        </div>
              </div>
            <ag-grid-angular 
                style="width: 100%; height: 593px;clear: both;" 
                class="ag-theme-alpine"
                [rowData]="this.rowData  |filter: value" 
                [columnDefs]="this.columnDefs"
                [defaultColDef]="defaultColDef"
                [enableFilter]="true"
                [gridOptions]="gridOptions"
                [setQuickFilter]="quickFilterText" 
                (gridReady)="onGridReady($event)">
            </ag-grid-angular>
        </div>
                </div>
            </div>
    </div>
</div>

<div *ngIf="save">

    <p-confirmDialog [style]="{width:'600px'}" [baseZIndex]="10000">
        <p-footer>
            <input type="text" [(ngModel)]="selectedName"
                class="form-control input-lg ng-pristine ng-untouched ng-invalid ng-invalid-required"
                style="width:35rem; height:2rem">
            <div style="margin-top:2rem">
                <button type="button" pButton label="Close" value="Update" class="button-tertiary"
                    (click)="close()"></button>
                <button type="button" pButton label="run" class="ok" (click)="Save(selectedName)"></button>
            </div>
        </p-footer>
    </p-confirmDialog>

</div>