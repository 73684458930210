import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class SchedulerService {
	
	constructor(private http: HttpClient) { }
	
	scheduleFormService(reportId){
		return this.http.get('assets/json/exportDetailFile.json')
    }
	reportParameterService(reportId){
		return this.http.get('assets/json/'+ reportId+'Schedule.json')
    }
	cascadeParameterService(parameter,param,payload){
		return this.http.post('api/v1/report/'+parameter+'/cascade/'+param ,payload, 
		{ headers: {'X-Auth-Token': localStorage.getItem("token")}, observe: 'response' })
		
	}
	scheduleReportService(reportId,requestPayload){
		return this.http.post('api/v1/report/'+reportId+'/schedule', requestPayload,
			{ headers: {'X-Auth-Token': localStorage.getItem("token")}, observe: 'response' })
		
	}
	EditScheduledReport(reportId,param){
		return this.http.get('api/v1/schedule/'+reportId,{params:param, headers: {'X-Auth-Token': localStorage.getItem("token")}, observe: 'response' })
	}
	getClientNameCode(id){
		//api/reports/requestor/getClientCode?reportId=5162
		return this.http.get('api/reports/requestor/getClientCode?reportId=' + id, { headers: { 'X-Auth-Token': localStorage.getItem("token") }, observe: 'response' })

	}
	UpdateScheduledReport(jobId,payload){
		return this.http.put('api/v1/schedule/'+jobId,payload,{headers: {'X-Auth-Token': localStorage.getItem("token")}, observe: 'response' })
	}
	copyScheduledReport(reportId,payload){
		return this.http.post('api/v1/report/'+reportId+'/schedule/clone',payload,{headers: {'X-Auth-Token': localStorage.getItem("token")}, observe: 'response' })
	}
	getClientNamesList(pacs,id){
		return this.http.get('api/reports/requestor/getClientNamesList?isPacs='+pacs+'&reportId='+id,{ headers: {'X-Auth-Token': localStorage.getItem("token")}, observe: 'response' })
	}
	getRunBy(id){
		return this.http.get('api/reports/requestor/getRunBy?reportId='+id,{ headers: {'X-Auth-Token': localStorage.getItem("token")}, observe: 'response' })
	}
	getSubgroupList(id,client){
		return this.http.get('api/reports/requestor/getSubGroupList?clientId='+client+'&reportId='+id,{ headers: {'X-Auth-Token': localStorage.getItem("token")}, observe: 'response' })
	}
	getSubGroupValue(reportId,clientId,SubGroupId,SelectedRequestedPage){
		return this.http.get('api/reports/requestor/getSubGroupValuesList?clientCode='+clientId+'&reportId='+reportId+'&subGroup='+SubGroupId+'&reportType='+SelectedRequestedPage,{ headers: {'X-Auth-Token': localStorage.getItem("token")}, observe: 'response' })
	}
	getAdaClientReport(id){
		return this.http.get('api/reports/requestor/ada-req/clientcode/search?reportId='+id,{ headers: {'X-Auth-Token': localStorage.getItem("token")}, observe: 'response' })
	}
	getLOBList(id,client,cFlag,pFlag){
		return this.http.get('api/reports/requestor/getLobList?clientId='+client+'&clientFlag='+cFlag+'&parentFlag='+pFlag+'&reportId='+id,{ headers: {'X-Auth-Token': localStorage.getItem("token")}, observe: 'response' })
	}
	getPolicyList(id,client,lob){
		return this.http.get('api/reports/requestor/getPolicyList?clientCode='+client+'&sublineOfBusiness='+lob+'&reportId='+id,{ headers: {'X-Auth-Token': localStorage.getItem("token")}, observe: 'response' })
	}
	getPolicyTypeList(id,client,lob){
		return this.http.get('api/reports/requestor/getPolicyTypeList?clientCode='+client+'&sublineOfBusiness='+lob+'&reportId='+id,{ headers: {'X-Auth-Token': localStorage.getItem("token")}, observe: 'response' })
	}
	getClassList(id,policy){
		return this.http.get('api/reports/requestor/getClassIdList?policyBON='+policy+'&reportId='+id,{ headers: {'X-Auth-Token': localStorage.getItem("token")}, observe: 'response' })
	}
	getPlanTypeList(id,policy,plan){
		return this.http.get('api/reports/requestor/getPlanTypeList?contractClassBON='+policy+'&contractClassId='+plan+'&reportId='+id,{ headers: {'X-Auth-Token': localStorage.getItem("token")}, observe: 'response' })
	}
	getAdareqSubGroupValue(reportId,clientId,SubGroupId,SelectedRequestedPage){
		return this.http.get('api/reports/requestor/getSubGroupValuesList?clientCode='+clientId+'&reportId='+reportId+'&subGroup='+SubGroupId+'&reportType=LOA',{ headers: {'X-Auth-Token': localStorage.getItem("token")}, observe: 'response' })
	}
	//ADA
	getAdaClient(id){
		///api/reports/requestor/ada/clientcode/list?reportId
		return this.http.get('api/reports/requestor/ada/clientcode/list?reportId='+id,{ headers: {'X-Auth-Token': localStorage.getItem("token")}, observe: 'response' })
	}
	getAdaSubgroupList(id,client){
		//api/reports/requestor/ada/subgroup/list?clientId=3142&reportId=83560
		return this.http.get('api/reports/requestor/ada/subgroup/list?clientId=' + client +'&reportId=' + id,{ headers: {'X-Auth-Token': localStorage.getItem("token")}, observe: 'response' })
	}
	getAdaSubGroupValue(reportId,clientId,SubGroupId,SelectedRequestedPage){
		//api/reports/requestor/ada/subgroupvalues/list?clientId=3142&subGroup=Subgroup_A&reportId=83560
		return this.http.get('api/reports/requestor/ada/subgroupvalues/list?clientId='+clientId+'&subGroup='+SubGroupId+'&reportId='+reportId,{ headers: {'X-Auth-Token': localStorage.getItem("token")}, observe: 'response' })
	}
	//CD2
	getCD2Clients(id){
		return this.http.get('api/reports/requestor/getClients?reportId='+ id,{ headers: {'X-Auth-Token': localStorage.getItem("token")}, observe: 'response' })
	}
	getCD2Subgroup(id,client){
		return this.http.get('api/reports/requestor/getSubGroupList?clientId=' + client + '&reportId=' + id,{ headers: {'X-Auth-Token': localStorage.getItem("token")}, observe: 'response' })
	}
	getCD2SubgroupVlaue(id,client,subgroup,SelectedRequestedPage){
		return this.http.get('api/reports/requestor/getCDSubgroupValues?clientCode=' + client + '&subGroup=' + subgroup + '&reportId=' + id,{ headers: {'X-Auth-Token': localStorage.getItem("token")}, observe: 'response' })
	}
	getCD2LoaPolicy(id,client){
		return this.http.get('api/reports/requestor/getLOAPolicy?clientCode=' + client + '&reportId=' + id,{ headers: {'X-Auth-Token': localStorage.getItem("token")}, observe: 'response' })
	}
	getCD2StdPolicy(id,client){
		return this.http.get('api/reports/requestor/getSTDPolicy?clientCode=' + client + '&reportId=' + id,{ headers: {'X-Auth-Token': localStorage.getItem("token")}, observe: 'response' })
	}
	getCD2LtdPolicy(id,client){
		return this.http.get('api/reports/requestor/getLTDPolicy?clientCode=' + client + '&reportId=' + id,{ headers: {'X-Auth-Token': localStorage.getItem("token")}, observe: 'response' })
	}
	getCD2WcPolicy(id,client){
		return this.http.get('api/reports/requestor/getWCPolicy?clientCode=' + client + '&reportId=' + id,{ headers: {'X-Auth-Token': localStorage.getItem("token")}, observe: 'response' })
	}
	//WC
	getWcClient(id){
		return this.http.get('api/reports/requestor/getWCClients?reportId='+ id,{ headers: {'X-Auth-Token': localStorage.getItem("token")}, observe: 'response' })
	}
	getRunByOptions(id){
		return this.http.get('api/reports/requestor/getWCRunByOptions?reportId=' + id,{ headers: {'X-Auth-Token': localStorage.getItem("token")}, observe: 'response' })
	}
	getWCPolicyList(id,client){
		return this.http.get('api/reports/requestor/getWCPoliciesList?clientCode=' + client + '&reportId=' + id,{ headers: {'X-Auth-Token': localStorage.getItem("token")}, observe: 'response' })
	}
	getWCBenefitList(id,client){
		return this.http.get('api/reports/requestor/getWCBenefitStateList?clientCode=' + client + '&reportId=' + id,{ headers: {'X-Auth-Token': localStorage.getItem("token")}, observe: 'response' })
	}
	getWCSiteList(id,client){
		return this.http.get('api/reports/requestor/getWCSitesList?clientCode=' + client + '&reportId=' + id,{ headers: {'X-Auth-Token': localStorage.getItem("token")}, observe: 'response' })
	}
	getWCSubgroup(id,client){
		return this.http.get('api/reports/requestor/getSubGroupList?clientId=' + client + '&reportId=' + id,{ headers: {'X-Auth-Token': localStorage.getItem("token")}, observe: 'response' })
	}
	getWCSubgroupVlaue(id,client,subgroup,SelectedRequestedPage){
		return this.http.get('api/reports/requestor/getSubGroupValuesList?clientCode=' + client +'&reportId=' + id+'&subGroup='+ subgroup+'&reportType='+SelectedRequestedPage,{ headers: {'X-Auth-Token': localStorage.getItem("token")}, observe: 'response' })
	}
	//BCD
	getBCDclient(id){
		return this.http.get('api/reports/requestor/getBCDClientCode?reportId='+id,{ headers: {'X-Auth-Token': localStorage.getItem("token")}, observe: 'response' })
	}
	getBCDsubgroup(client,id){
			return this.http.get('api/reports/requestor/getSubGroupList?clientId='+client+'&reportId='+id,{ headers: {'X-Auth-Token': localStorage.getItem("token")}, observe: 'response' })
	}
	getBcdLOApolicy(client,id){
			return this.http.get('api/reports/requestor/getLOAPolicy?clientCode='+client+'&reportId='+id,{ headers: {'X-Auth-Token': localStorage.getItem("token")}, observe: 'response' })
	}
	getBcdSTDpolicy(client,id){
			return this.http.get('api/reports/requestor/getSTDPolicy?clientCode='+client+'&reportId='+id,{ headers: {'X-Auth-Token': localStorage.getItem("token")}, observe: 'response' })
	}
	getBcdLTDpolicy(client,id){
			return this.http.get('api/reports/requestor/getLTDPolicy?clientCode='+client+'&reportId='+id,{ headers: {'X-Auth-Token': localStorage.getItem("token")}, observe: 'response' })
	}
	getBcdWCpolicy(client,id){
			return this.http.get('api/reports/requestor/getWCPolicy?clientCode='+client+'&reportId='+id,{ headers: {'X-Auth-Token': localStorage.getItem("token")}, observe: 'response' })
	}
	getBCDsubgroupValues(client,id,subgroup){
			return this.http.get('api/reports/requestor/getCDSubgroupValues?clientCode='+client+'&subGroup='+subgroup+'&reportId='+id,{ headers: {'X-Auth-Token': localStorage.getItem("token")}, observe: 'response' })
	}
	//CD3
	getCD3Clients(id){
		return this.http.get('api/reports/requestor/getClientsCD3?reportId='+ id,{ headers: {'X-Auth-Token': localStorage.getItem("token")}, observe: 'response' })
	}
	getCD3Subgroup(id,client){
		return this.http.get('api/reports/requestor/getSubGroupList?clientId=' + client + '&reportId=' + id,{ headers: {'X-Auth-Token': localStorage.getItem("token")}, observe: 'response' })
	}
	getCD3SubgroupVlaue(id,client,subgroup,SelectedRequestedPage){
		return this.http.get('api/reports/requestor/getCDSubgroupValues?clientCode=' + client + '&subGroup=' + subgroup + '&reportId=' + id,{ headers: {'X-Auth-Token': localStorage.getItem("token")}, observe: 'response' })
	}
	getCD3LoaPolicy(id,client){
		return this.http.get('api/reports/requestor/getLOAPolicy?clientCode=' + client + '&reportId=' + id,{ headers: {'X-Auth-Token': localStorage.getItem("token")}, observe: 'response' })
	}
	getCD3StdPolicy(id,client){
		return this.http.get('api/reports/requestor/getSTDPolicy?clientCode=' + client + '&reportId=' + id,{ headers: {'X-Auth-Token': localStorage.getItem("token")}, observe: 'response' })
	}
	getCD3LtdPolicy(id,client){
		return this.http.get('api/reports/requestor/getLTDPolicy?clientCode=' + client + '&reportId=' + id,{ headers: {'X-Auth-Token': localStorage.getItem("token")}, observe: 'response' })
	}
	getCD3WcPolicy(id,client){
		return this.http.get('api/reports/requestor/getWCPolicy?clientCode=' + client + '&reportId=' + id,{ headers: {'X-Auth-Token': localStorage.getItem("token")}, observe: 'response' })
	}
	//PCR
	getPCRclient(id) {
		return this.http.get('api/reports/requestor/getClientNamesList?isPacs=false'+ '&reportId=' + id, { headers: { 'X-Auth-Token': localStorage.getItem("token") }, observe: 'response' })
	}
	getPcrLOBList(id, client, cFlag, pFlag) {
		return this.http.get('api/reports/requestor/getLobList?clientId=' + client + '&clientFlag=' + cFlag + '&parentFlag=' + pFlag + '&reportId=' + id, { headers: { 'X-Auth-Token': localStorage.getItem("token") }, observe: 'response' })
	}
	getPcrBANList(id, client, list) {
		return this.http.get('api/reports/requestor/getBANList?clientCode='+ client + '&lob='+list+'&reportId=' + id, { headers: { 'X-Auth-Token': localStorage.getItem("token") }, observe: 'response' })
	}
	//SC
	getSCclient(id) {
		return this.http.get('api/reports/requestor/getClientNamesList?isPacs=true'+ '&reportId=' + id, { headers: { 'X-Auth-Token': localStorage.getItem("token") }, observe: 'response' })
	}
	getSCsubgroup(id, client){
		return this.http.get('api/reports/requestor/getSubGroupList?clientId='+client+'&reportId='+id,{ headers: {'X-Auth-Token': localStorage.getItem("token")}, observe: 'response' })
	}
	getScLOApolicy(id, client){
		return this.http.get('api/reports/requestor/getLOAPolicy?clientCode='+client+'&reportId='+id,{ headers: {'X-Auth-Token': localStorage.getItem("token")}, observe: 'response' })
	}
	getScSTDpolicy(id, client){
			return this.http.get('api/reports/requestor/getSCSTDPolicy?clientCode='+client+'&reportId='+id,{ headers: {'X-Auth-Token': localStorage.getItem("token")}, observe: 'response' })
	}
	getScLTDpolicy(id, client){
			return this.http.get('api/reports/requestor/getSCLTDPolicy?clientCode='+client+'&reportId='+id,{ headers: {'X-Auth-Token': localStorage.getItem("token")}, observe: 'response' })
	}
	getSCsubgroupValues(id, client,subgroup){
		return this.http.get('api/reports/requestor/getCDSubgroupValues?clientCode='+client+'&subGroup='+subgroup+'&reportId='+id,{ headers: {'X-Auth-Token': localStorage.getItem("token")}, observe: 'response' })
	}
	// PACS VENDORS 2
	getPACSV2client(id) {
		return this.http.get('api/reports/requestor/getClientNamesList?isPacs=true'+ '&reportId=' + id, { headers: { 'X-Auth-Token': localStorage.getItem("token") }, observe: 'response' })
	}
	getPACSV2policyt(id,client) {
		return this.http.get('api/reports/requestor/getPolicyList?clientCode='+client+'&sublineOfBusiness=STD&reportId=' + id, { headers: { 'X-Auth-Token': localStorage.getItem("token") }, observe: 'response' })
	}

	getSftpConfig(id){
		return this.http.get('api/v1/sftp/config/'+ id, { headers: { 'X-Auth-Token': localStorage.getItem("token") }, observe: 'response' })

	}

	// scheduleReportService(reportId,requestPayload){
	// 	return this.http.post('api/v1/report/'+reportId+'/schedule', requestPayload,
	// 		{ headers: {'X-Auth-Token': localStorage.getItem("token")}, observe: 'response' })
		
	// }
	
	/*getReportService(reportId,requestPayload,reportType,pageNum) {
		if(reportType.toLowerCase() == "sql"){
			return this.http.post('/api/v1/report/'+reportId+'/sql/run?limit=250&page='+pageNum, requestPayload,
			{ headers: {'X-Auth-Token': localStorage.getItem("token")}, observe: 'response' })
		}
		else {
			return this.http.post('/api/v1/report/'+reportId+'/birt/run?page=1&rptDocumentName=', requestPayload,
			{ headers: {'X-Auth-Token': localStorage.getItem("token")}, observe: 'response' })
		}
		
	}
	//api/reports/requestor/getClientNamesList?isPacs=true&reportId=" + reportId
	
	reportDrillDownService(reportId,requestPayload){
		return this.http.post('/api/v1/report/'+reportId+'/birt/run?page=1&rptDocumentName=', requestPayload,
			{ headers: {'X-Auth-Token': localStorage.getItem("token")}, observe: 'response' })
		
	}
	
	XLSReportDownloadService(reportId,requestPayload,uniqueName){
		
		if(uniqueName == undefined){ 
			return this.http.post('/api/v1/report/sql/'+reportId+'/export-filename?format=xls', requestPayload,   // to get uniqueName from service
				{ headers: {'X-Auth-Token': localStorage.getItem("token")}, observe: 'response' })
		}
		else{
			
			return this.http.get('/api/v1/report/sql/'+reportId+'/export?'+uniqueName, 
				{ headers: {'X-Auth-Token': localStorage.getItem("token")}, 'responseType': 'blob' as 'json', observe: 'response' })
		}
	}
	
	XLSXReportDownloadService(reportId,requestPayload,uniqueName){
		
		if(uniqueName == undefined){ 
			return this.http.post('/api/v1/report/sql/'+reportId+'/export-filename?format=xlsx', requestPayload,   // to get uniqueName from service
				{ headers: {'X-Auth-Token': localStorage.getItem("token")}, observe: 'response' })
		}
		else{
			
			return this.http.get('/api/v1/report/sql/'+reportId+'/export?'+uniqueName, 
				{ headers: {'X-Auth-Token': localStorage.getItem("token")}, 'responseType': 'blob' as 'json', observe: 'response' })
		}
	}
	
	XLSXReportSaveToInboxService(reportId,requestPayload,uniqueName){
		
		if(uniqueName == undefined){ 
			return this.http.post('/api/v1/report/sql/'+reportId+'/export-filename?format=xlsx', requestPayload,   // to get uniqueName from service
				{ headers: {'X-Auth-Token': localStorage.getItem("token")}, observe: 'response' })
		}
		else{
			
			return this.http.get('api/v1/report/sql/'+reportId+'/export?'+uniqueName+'&formatType=savePdfToInbox', 
				{ headers: {'X-Auth-Token': localStorage.getItem("token")}, observe: 'response' })
		}
	}
	
	CSVReportDownloadService(reportId,requestPayload,uniqueName){
		
		if(uniqueName == undefined){ 
			return this.http.post('/api/v1/report/sql/'+reportId+'/export-filename?format=csv', requestPayload,   // to get uniqueName from service
				{ headers: {'X-Auth-Token': localStorage.getItem("token")}, observe: 'response' })
		}
		else{
			
			return this.http.get('/api/v1/report/sql/'+reportId+'/export?'+uniqueName, 
				{ headers: {'X-Auth-Token': localStorage.getItem("token")}, 'responseType': 'text', observe: 'response' })
		}
	}
	
	CSVReportSaveToInboxService(reportId,requestPayload,uniqueName){
		
		if(uniqueName == undefined){ 
			return this.http.post('/api/v1/report/sql/'+reportId+'/export-filename?format=csv', requestPayload,   // to get uniqueName from service
				{ headers: {'X-Auth-Token': localStorage.getItem("token")}, observe: 'response' })
		}
		else{
			
			return this.http.get('api/v1/report/sql/'+reportId+'/export?'+uniqueName+'&formatType=savePdfToInbox', 
				{ headers: {'X-Auth-Token': localStorage.getItem("token")}, observe: 'response' })
		}
	}
	
	TXTReportDownloadService(reportId,requestPayload,uniqueName){
		
		if(uniqueName == undefined){ 
			return this.http.post('/api/v1/report/sql/'+reportId+'/export-filename?format=txt', requestPayload,   // to get uniqueName from service
				{ headers: {'X-Auth-Token': localStorage.getItem("token")}, observe: 'response' })
		}
		else{
			
			return this.http.get('/api/v1/report/sql/'+reportId+'/export?'+uniqueName, 
				{ headers: {'X-Auth-Token': localStorage.getItem("token")}, 'responseType': 'text', observe: 'response' })
		}
	}
	
	
	XLSBirtReportDownloadService(reportId,documentName){
		
		return this.http.get('/api/v1/report/'+reportId+'/export?'+documentName+'&format=xls', 
			{ headers: {'X-Auth-Token': localStorage.getItem("token")}, 'responseType': 'blob' as 'json', observe: 'response' })
			
	}
	
	XLSXBirtReportDownloadService(reportId,documentName){
		
		return this.http.get('/api/v1/report/'+reportId+'/export?'+documentName+'&format=xlsx', 
			{ headers: {'X-Auth-Token': localStorage.getItem("token")}, 'responseType': 'blob' as 'json', observe: 'response' })
			
	}
	
	XLSXBirtReportSaveToInboxService(reportId,documentName){
		
		return this.http.get('/api/v1/report/'+reportId+'/export?'+documentName+'&format=xlsx&formatType=savePdfToInbox', 
			{ headers: {'X-Auth-Token': localStorage.getItem("token")}, observe: 'response' })
		
	}
	
	PDFBirtReportDownloadService(reportId,documentName){
		
		return this.http.get('/api/v1/report/'+reportId+'/export?'+documentName+'&format=pdf', 
			{ headers: {'X-Auth-Token': localStorage.getItem("token")}, 'responseType': 'blob' as 'json', observe: 'response' })
		
	}
	
	PDFBirtReportSaveToInboxService(reportId,documentName){
		
		return this.http.get('/api/v1/report/'+reportId+'/export?'+documentName+'&format=pdf&formatType=savePdfToInbox', 
			{ headers: {'X-Auth-Token': localStorage.getItem("token")}, observe: 'response' })
		
	}
	
	DOCBirtReportDownloadService(reportId,documentName){
		
		return this.http.get('/api/v1/report/'+reportId+'/export?'+documentName+'&format=doc', 
			{ headers: {'X-Auth-Token': localStorage.getItem("token")}, 'responseType': 'blob' as 'json', observe: 'response' })
		
	}*/
	
}