import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { CreateDashboardService } from './create-dashboard.service'
import { Subject, Subscription } from 'rxjs';
import { MessageService, SortEvent } from 'primeng/api';
import { Router } from '@angular/router';
import { AppComponentService } from '../app-component.service';
import { debounceTime } from 'rxjs/operators';
import { Table } from 'primeng/table';
import { Paginator } from 'primeng/paginator';

declare var d3: any;
declare var acinfotech: any;

interface DashboardInfo {
	name: string,
	code: string
}

@Component({
	selector: 'app-create-dashboard',
	templateUrl: './create-dashboard.component.html',
	styleUrls: ['./create-dashboard.component.css'],
	providers: [CreateDashboardService]
})
export class CreateDashboardComponent implements OnInit, OnDestroy {
	@ViewChild('scheduledreportstable11 ', { static: true }) table1: Table;
	@ViewChild('paginator', { static: false }) paginator: Paginator;
	tempArray: any = [];
	dashboardRecords: any = [];
	items: any;
	dashboardInfo: any;
	showSearch = false;
	showPanelDomain = false;
	chartImage: string;
	showList = false;
	isLoading1: boolean = false;
	listOfDash: any = [];
	widgetLoading: Boolean = true;
	showMessage: boolean = false;
	showSearch1: boolean = false;
	listDomains: any = [];
	ListItems: any = [];
	listDomainsIsLoading: boolean = false;
	widgetTableDataIsLoading: boolean = false;
	selectedDomain: any;
	getWidgetId: any;
	dashboardDesc: any;
	getTableRowIndex: number;
	tableSelectArray: any = [];
	disableBtn: boolean = true;
	tableSortColumn = 'widgetName';
	tableSortDir = 1;
	customSortBool: boolean = false;
	requestPayload = {
		sortDirection: '',
		filters: [],
		filterType: 'and',
	};
	getDomainId: number;
	pageNo = 1;
	widgetTableData: any = [];
	tableEmptyMessage = 'No Records Found';
	showCreateDash: boolean = false;
	buttonLable: string = "Save Dashboard";
	storeRemovedIndex: any = [];
	compareWidgetList: any[] = [];
	editDroppedParamWidgetParameters: any=[];
	showError: boolean=false;
	constructor(private creatDashService: CreateDashboardService, private appCompService: AppComponentService, private router: Router, private messageService: MessageService) {
		this.tableSearchUpdate.pipe(debounceTime(3000)).subscribe((value) => {
			this.isLoading1 = true;
			this.search();
		});
	}
	store_index_j: any = [];
	store_index_j2: any = [];
	store_index_i: any = { 0: [], 1: [], 2: [] };
	store_index_i2: any = [];
	alertSeverity: string = "error";
	alertMsg: string = "";

	text: string = "";
	dashboardsList = [];
	metricSearchBool: boolean = false;
	metricFrmDashBool: boolean = false;
	widgetsList = [];
	srchResDashNames = [];
	searchType: string = "";
	chartId: string = "";
	saveDashDialBoxDisplayBool: boolean = false;
	domainsList = [];
	dashboardName: string = "";
	searchOrDashResult: string = "";
	searchOrDashResultArray = [];
	saveDashboardDomainId: number;
	saveDashboardReqPayload = {
		column: 3, dashboardRequestorParameters: [], domainId: 0, isUserCreateDashboard: "Y",
		mainTitle: "", parameterdetails: "", row: 15, widgetControls: [], widgetName: "", widgetDescription: ""
	};
	isParameterizedWidget: boolean = false;
	droppedParamWidgetDomainId: number;
	droppedParamWidgetParameters = [];
	json: string = "";

	selectedDashboard: DashboardInfo;

	savedSettingsSubscrip: Subscription;
	dashboardsListSubscrip: Subscription;
	getWidgetsSubscrip: Subscription;
	getADashboardSubscrip: Subscription;
	getWidgetDataSubscrip: Subscription;
	domainsListSubscrip: Subscription;
	createDashSubscrip: Subscription;

	rows = [];
	columns = [1, 2, 3];
	widgets = [];
	dragIndex: number;
	hidePlus = true;
	index_i!: number;
	index_j!: number;
	tableSearchUpdate = new Subject<string>();
	startIndex = 0;
	endIndex = 0;
	totalRecords = 0;
	deleteDialogVisible: boolean = false;

	ngOnInit(): void {

		acinfotech.requestType(false); // Letting chart library know it's a create dashboard module

		d3.select(".tibo-topbar-title").text("Home / Create Dashboard"); // setting title

		// if(Number(d3.select("#tibo-large-leftpanel-container").node().getBoundingClientRect().width) == 64){
		// 		setTimeout(function(){
		// 			d3.select(".tibo-right-container").transition().delay(0).duration(0).style("left","64px").ease("linear");
		// 		},10)
		// 	}

		this.json = JSON.stringify(this.saveDashboardReqPayload);
		this.getDomainNameData()
		// this.initialServiceCalls();

	}

	ngOnDestroy() {
		if (this.savedSettingsSubscrip) {
			this.savedSettingsSubscrip.unsubscribe();
		}
		if (this.dashboardsListSubscrip) {
			this.dashboardsListSubscrip.unsubscribe();
		}
		if (this.getADashboardSubscrip) {
			this.getADashboardSubscrip.unsubscribe();
		}
		if (this.getWidgetsSubscrip) {
			this.getWidgetsSubscrip.unsubscribe();
		}
		if (this.getWidgetDataSubscrip) {
			this.getWidgetDataSubscrip.unsubscribe();
		}
		if (this.domainsListSubscrip) {
			this.domainsListSubscrip.unsubscribe();
		}
		if (this.createDashSubscrip) {
			this.createDashSubscrip.unsubscribe();
		}
		if (this.dashboardsListSubscrip) {
			this.dashboardsListSubscrip.unsubscribe();
		}
	}

	initialServiceCalls() {

		this.savedSettingsSubscrip = this.creatDashService.getSavedSettings().subscribe((response: any) => { // dashboards list
			if (response.body?.userDashboardWidgetLimit) {
				this.rows = Array(Number(Math.round(response.body?.userDashboardWidgetLimit / 3))).fill(0);
			}
			else {
				this.rows = Array(Number(Math.round(9 / 3))).fill(0);
			}
			for (let i = 0; i < this.rows.length; i++) {
				for (let j = 0; j < this.columns.length; j++) {
					d3.select('#loader' + (i + 1) + (j + 1)).style('display', 'block')
				}
			}

		}, error => {
			// if(Number(error.status) == 401){ this.alertMsg = "Please Try After Some Time!" }
			// else if(Number(error.status) == 500){ this.alertMsg = "Internal Error" }
			// else { this.alertMsg = "Please Try After Some Time." }

			// this.addSingle();
		}, () => { }) // getSavedSettings

		// this.dashboardsListSubscrip = this.creatDashService.dashboardsListService().subscribe((response: any) => { // dashboards list

		// 	let dashTemp = {};
		// 	response.body.forEach((dashboard: any) => {
		// 		dashTemp["label"] = dashboard.widgetName;
		// 		dashTemp["value"] = dashboard.widgetMasterId;
		// 		this.dashboardsList.push(dashTemp);
		// 		dashTemp = {};
		// 	});



		// }, error => {
		// 	if (Number(error.status) == 401) { this.alertMsg = "Please Try After Some Time!" }
		// 	else if (Number(error.status) == 500) { this.alertMsg = "Internal Error" }
		// 	else { this.alertMsg = "Please Try After Some Time." }

		// 	this.addSingle();
		// }, () => { }) // dashboardService
		// this.dashboardsListSubscrip = this.creatDashService.dashboardsListService().subscribe((response: any) => { // dashboards list

		//this.widgetList = response;
		this.items = [];
		this.dashboardRecords = [];
		let dashTemp = {}, dashItemsTemp = {};
		this.ListItems.forEach((element: any, index: any) => {
			if (element.domainDashboardVisibility == "Y" || element.domainDashboardVisibility == null) {
				this.tempArray.push({ name: element.widgetName, code: element.widgetMasterId })
				dashTemp["label"] = element.domainName;
				dashTemp["items"] = [];
				let dashboards = element.dashboards.filter(item => item.dashboardVisibility == "Y" || item.dashboardVisibility == null);
				dashboards.forEach((dashboard: any) => {
					dashItemsTemp["label"] = dashboard.widgetName;
					dashItemsTemp["id"] = dashboard.widgetMasterId;
					dashItemsTemp["command"] = e => this.onDashboardSelect(e);

					dashTemp["items"].push(dashItemsTemp)
					dashItemsTemp = {};
				})

				this.dashboardRecords.push(dashTemp);
				dashTemp = {};
			}
		});
		this.items = this.dashboardRecords;
		this.dashboardInfo = this.tempArray;

		// Additional dashboard domains filtering ---------------------
		let filteredDomains = [];
		this.items.forEach((domain: any) => {
			if (domain.items.length > 0) {
				filteredDomains.push(domain)
			}

		})

		this.items = filteredDomains;
		// });
		this.domainsListSubscrip = this.creatDashService.domainListService().subscribe((response: any) => { // dashboards list

			let dashTemp = {};
			this.domainsList = [];
			if(response.length>0){
				response.forEach((domain: any) => {
					dashTemp["label"] = domain.domainName;
					dashTemp["value"] = domain.domainId;
					this.domainsList.push(dashTemp);
					dashTemp = {};
				});
			}
			// else{
			// 	this.listDomains.forEach((domain: any) => {
			// 		dashTemp["label"] = domain.domainName;
			// 		dashTemp["value"] = domain.domainId;
			// 		this.domainsList.push(dashTemp);
			// 		dashTemp = {};
			// 	});
			// }
		}, error => {
			if (Number(error.status) == 401) { this.alertMsg = "Please Try After Some Time!" }
			else if (Number(error.status) == 500) { this.alertMsg = "Internal Error" }
			else { this.alertMsg = "Please Try After Some Time." }

			this.addSingle();
		}, () => { }) // dashboardService

		// })
	}

	search() {
		this.showSearch = false;
		this.showSearch1 = false;
		this.showError=false;
		// d3.select(".tibo-mydash-widget-spinner").style("display", "block");
		this.isLoading1 = true;
		this.searchOrDashResultArray = [];
		this.widgetsList = [];
		this.srchResDashNames = [];
		this.searchOrDashResult = "search result";
		if (!this.text) {
			this.alertMsg = "Missing Search Text."
			this.isLoading1 = false;
			this.addSingle();
		}
		else {
			this.getWidgetsSubscrip = this.creatDashService.getWidgets(this.text).subscribe((data: any) => { // widgets list
				if (!data) {
					this.showSearch = false;
					this.showSearch1 = false;
					this.isLoading1 = false;
				}
				else {
					if(data.length==0){
						this.showError=true;
					}
					this.showSearch = true;
					this.showSearch1 = true;
					this.isLoading1 = false;
					this.searchOrDashResultArray = data.filter(item => item.dashboardVisibility == "Y" || item.dashboardVisibility == null); //Storing the entire response in order to check parameterized dashboard
					data.forEach((response: any) => {
						if (response.dashboardVisibility == "Y" || response.dashboardVisibility == null) {
							// this.widgetsList.push(response.widgetControls[0])
							this.srchResDashNames.push(response.widgetName.includes(this.text));

						}
					})

				}
				d3.select(".tibo-mydash-widget-spinner").style("display", "none");
			}, error => {
				d3.select(".tibo-mydash-widget-spinner").style("display", "none");
				this.alertSeverity = "error";
				if (Number(error.status) == 401) { this.alertMsg = "Please Try After Some Time!" }
				else if (Number(error.status) == 500) { this.alertMsg = "Internal Error" }
				else { this.alertMsg = "Please Try After Some Time." }
				this.isLoading1 = false;
				this.addSingle();
			}, () => { })
		}

	}
	radioSwitch(searchType) {
		this.showSearch = false;
		this.showList=false;
		this.selectedDashboard = { name: '', code: '' };
		searchType == "search metric" ? (this.metricSearchBool = true, this.metricFrmDashBool = false) : (this.metricSearchBool = false, this.metricFrmDashBool = true, this.text = '')
	}

	onDashboardSelect(event) {
		this.showList = false;
		this.showSearch = true;
		this.showError=false;
		d3.select(".tibo-mydash-widget-spinner").style("display", "block");

		this.selectedDashboard = { name: event.item.label, code: event.item.id }
		// this.selectedDashboard = { name: "", code: event.value }
		this.searchOrDashResultArray = [];
		this.widgetsList = [];
		this.isLoading1 = true;
		this.searchOrDashResult = "dashboard result";

		this.getADashboardSubscrip = this.creatDashService.getADashboard(this.selectedDashboard.code).subscribe((data: any) => { // a dashboard info

			this.searchOrDashResultArray.push(data); //Storing the entire response in order to check parameterized dashboard
			if (data.widgetControls && data.widgetControls.length > 0) {
				this.isLoading1 = false;
				this.widgetsList = [...data.widgetControls];
				this.showMessage = false;
				this.showError=false;
				// this.showList=false;
				this.showSearch1 = true;
				d3.select(".tibo-mydash-widget-spinner").style("display", "none");
			}
			else {
				this.showMessage = true;
				this.showSearch1 = true;
				this.showError=true;
				this.isLoading1 = false;
			}
		}, error => {
			this.isLoading1 = false;
			d3.select(".tibo-mydash-widget-spinner").style("display", "none");

			this.alertSeverity = "error";

			if (Number(error.status) == 401) { this.alertMsg = "Please Try After Some Time!" }
			else if (Number(error.status) == 500) { this.alertMsg = "Internal Error" }
			else { this.alertMsg = "Please Try After Some Time." }

			this.addSingle();
		}, () => { })

	}

	delete(event) {
		let datasss = this.searchOrDashResultArray[0].widgetControls[this.dragIndex];
		d3.select(event.target).attr("widgetInfo",
			JSON.stringify({ parameters: this.searchOrDashResultArray[this.dragIndex].dashboardRequestorParameters }));
		this.chartId = "";
		//var testContainer = document.querySelector('#tibo-mydash-nonparamdash-scrollpanel');
		this.widgetsList[this.dragIndex].chartTitle = "";
		this.searchOrDashResultArray[this.dragIndex].widgetName = "";

		// event.target.innerHTML = "";
		// 						event.target.id = "";
		// 						event.target.removeAttribute("widgetInfo");
		// var fourChildNode = testContainer.querySelector('.tibo-mydash-nonparamdash-scrollpanel .p-scrollpanel-wrapper .p-scrollpanel-content');

		// d3.select(event.target).attr("widgetInfo",
		// JSON.stringify({parameters:this.searchOrDashResultArray[this.dragIndex].dashboardRequestorParameters,
		// 		controls:this.variio}));
		// 	this.variio = "";
		// 		event.target.innerHTML="";
		// 					event.target.id = "";
		// 					event.target.removeAttribute("widgetInfo");



	}
	variio: any;
	drop(event, index_j: number, index_i: number) {
		
		////console.log(event.target.querySelector('p-progressspinner'))
		//if((event.target.children.length == 0) && (event.target.hasAttribute("draggable") == false)){
		// if ((event.target.querySelector('.p-progress-spinner') != null) && (event.target.hasAttribute("draggable") == false)) {

		event.preventDefault();
		if(this.dragIndex!=-1){
		// this.widgetLoading=false
		if (this.searchOrDashResult == "search result") {

			var droppedWidget = this.searchOrDashResultArray[this.dragIndex].widgetControls[0];
			this.variio = this.searchOrDashResultArray[this.dragIndex].widgetControls[0];
			let idData = d3.select(event.target).attr("objectCordinates");
			var dData = idData.replace(',', '')
			if (this.searchOrDashResultArray[this.dragIndex].dashboardRequestorParameters.length > 0) {

				d3.select('#loader' + dData).style('display', 'none')
				if (this.isParameterizedWidget == false) {
					this.isParameterizedWidget = true;
					// this.widgetLoading=false

					this.droppedParamWidgetDomainId = Number(this.searchOrDashResultArray[this.dragIndex].dashboardRequestorParameters[0].authorizationId);

					this.droppedParamWidgetParameters = this.searchOrDashResultArray[this.dragIndex].dashboardRequestorParameters; // Storing parameterized widget parameters

					this.saveDashboardReqPayload.dashboardRequestorParameters = this.searchOrDashResultArray[this.dragIndex].dashboardRequestorParameters;
					droppedWidget["objectCordinates"] = d3.select(event.target).attr("objectCordinates");
					this.saveDashboardReqPayload.widgetControls.push(droppedWidget);
					//console.log(droppedWidget)

					d3.select(event.target).attr("widgetInfo",
						JSON.stringify({
							parameters: this.searchOrDashResultArray[this.dragIndex].dashboardRequestorParameters,
							controls: droppedWidget
						}));


					// event.target.innerHTML = "<div style='width: 100%; height:0%; background-color:#c7c7c7; position: absolute; top:5px; font-size:16px; font-family:Roboto; color:#0f528c; text-indent:5px;'>" + this.widgetsList[this.dragIndex].chartTitle + "</div>" +
					// 	"<p class='p-mx-3' style='position: relative; top:20%; font-family:Roboto; font-size:16px; color:#0f528c; text-align:left;'>Dashboard: " + this.searchOrDashResultArray[this.dragIndex].widgetName + "</p>" +
					// 	"<p class='p-mx-3' style='position: relative; top:40%; font-family:Roboto; font-size:16px; color:#25c475; text-align:center;'>Widget added to dashboard. It's a parameterized widget!</p>";
					this.chartImage = "././assets/images/createChart/chart" + droppedWidget['controlMasterId'] + ".svg";
					event.target.innerHTML = "<div style='width: 100%; height:0%; background-color:#c7c7c7; position: absolute; top:5px; font-size:16px; font-family:Roboto; color:#0f528c; text-indent:5px;'>" + this.widgetsList[this.dragIndex].chartTitle + "</div>" + "<div><img src=" + this.chartImage + " style='height: 230px;width: 100%;border: 1px solid #f0f0f0;'></div>"
					var z = document.createElement('button'); // is a node
					z.setAttribute("style", "position:absolute; right:-5px; top:-10px; width:23px; height:23px; border-radius:100%; background-image:url('././assets/images/cancel.png'); background-repeat: no-repeat; background-size: 100% 100%; background-position: center; border:0; z-index:1000;");
					z.addEventListener("click", () => {

						//
						event.target.innerHTML = "  <i class='pi pi-plus' id=" + "loader" + dData + "></i>";
						event.target.id = "";
						event.target.removeAttribute("widgetInfo");
						d3.select('#loader' + dData).style('display', 'block')

						var hasParameterizedWidget = false;
						d3.selectAll(".create-dashboard-widget-container")[0].forEach(wid => {
							if (d3.select(wid).attr("widgetInfo") != null) {
								var widgetInfo = JSON.parse(d3.select(wid).attr("widgetInfo"));
								if (widgetInfo.parameters.length > 0) {
									hasParameterizedWidget = true;
								}
							}
						});

						if (!hasParameterizedWidget) {
							this.droppedParamWidgetDomainId = null;
							this.isParameterizedWidget = false;
							this.droppedParamWidgetParameters = []; // Empty the stored parameterized widget parameters
						}

					}, false);
					event.target.appendChild(z);
					/*var data = event.dataTransfer.getData("text");
					var nodeCopy = document.getElementById(data).cloneNode(true);
					var testContainer = document.querySelector('#tibo-mydash-nonparamdash-scrollpanel');
					var fourChildNode = testContainer.querySelector('.tibo-mydash-nonparamdash-scrollpanel .p-scrollpanel-wrapper .p-scrollpanel-content');
					fourChildNode.insertBefore(document.getElementById(data), fourChildNode.children[this.dragIndex]);
							
					d3.select(nodeCopy).attr("class","chart-div")
					this.chartId = "chartid-" + Math.floor(Math.random() * 10000) + 1;
					d3.select(event.target).attr("id", this.chartId)
						
					var z = document.createElement('button'); // is a node
					z.setAttribute("style","position:absolute; right:-5px; top:-10px; width:23px; height:23px; border-radius:100%; background-image:url('././assets/images/cancel.png'); background-repeat: no-repeat; background-size: 100% 100%; background-position: center; border:0; z-index:1000;")
					z.addEventListener("click", () => {
						event.target.innerHTML = "";
					}, false);
					event.target.appendChild(z);
						
					this.getWidgetData(this.widgetsList[this.dragIndex]);*/

				}
				else {

					if (this.droppedParamWidgetDomainId == Number(this.searchOrDashResultArray[this.dragIndex].dashboardRequestorParameters[0].authorizationId)) {

						this.droppedParamWidgetParameters = this.searchOrDashResultArray[this.dragIndex].dashboardRequestorParameters; // Storing parameterized widget parameters

						d3.select('#loader' + dData).style('display', 'none')

						//this.saveDashboardReqPayload.dashboardRequestorParameters = this.searchOrDashResultArray[this.dragIndex].dashboardRequestorParameters;
						droppedWidget["objectCordinates"] = d3.select(event.target).attr("objectCordinates");
						this.saveDashboardReqPayload.widgetControls.push(droppedWidget);

						d3.select(event.target).attr("widgetInfo",
							JSON.stringify({
								parameters: this.searchOrDashResultArray[this.dragIndex].dashboardRequestorParameters,
								controls: droppedWidget
							}));

						// event.target.innerHTML = "<div style='width: 100%; height:0%; background-color:#c7c7c7; position: absolute; top:5px; font-size:16px; font-family:Roboto; color:#0f528c; text-indent:5px;'>" + this.widgetsList[this.dragIndex].chartTitle + "</div>" +
						// 	"<p class='p-mx-3' style='position: relative; top:20%; font-family:Roboto; font-size:16px; color:#0f528c; text-align:left;'>Dashboard: " + this.searchOrDashResultArray[this.dragIndex].widgetName + "</p>" +
						// 	"<p class='p-mx-3' style='position: relative; top:40%; font-family:Roboto; font-size:16px; color:#25c475; text-align:center;'>Widget added to dashboard. It's a parameterized widget!</p>";
						this.chartImage = "././assets/images/createChart/chart" + droppedWidget['controlMasterId'] + ".svg";
						event.target.innerHTML = "<div style='width: 100%; height:0%; background-color:#c7c7c7; position: absolute; top:5px; font-size:16px; font-family:Roboto; color:#0f528c; text-indent:5px;'>" + this.widgetsList[this.dragIndex].chartTitle + "</div>" + "<div><img src=" + this.chartImage + " style='height: 230px;width: 100%;border: 1px solid #f0f0f0;'></div>"
						var z = document.createElement('button'); // is a node
						z.setAttribute("style", "position:absolute; right:-5px; top:-10px; width:23px; height:23px; border-radius:100%; background-image:url('././assets/images/cancel.png'); background-repeat: no-repeat; background-size: 100% 100%; background-position: center; border:0; z-index:1000;")
						z.addEventListener("click", () => {

							event.target.innerHTML = "  <i class='pi pi-plus' id=" + "loader" + dData + "></i>";
							event.target.id = "";
							event.target.removeAttribute("widgetInfo");
							d3.select('#loader' + dData).style('display', 'block')
							this.widgetLoading = true
							var hasParameterizedWidget = false;
							d3.selectAll(".create-dashboard-widget-container")[0].forEach(wid => {
								if (d3.select(wid).attr("widgetInfo") != null) {
									var widgetInfo = JSON.parse(d3.select(wid).attr("widgetInfo"));
									if (widgetInfo.parameters.length > 0) {
										hasParameterizedWidget = true;
									}
								}
							});

							if (!hasParameterizedWidget) {
								this.droppedParamWidgetDomainId = null;
								this.isParameterizedWidget = false;
								this.droppedParamWidgetParameters = []; // Empty the stored parameterized widget parameters
							}

						}, false);
						event.target.appendChild(z);
						/*var data = event.dataTransfer.getData("text");
						var nodeCopy = document.getElementById(data).cloneNode(true);
						var testContainer = document.querySelector('#tibo-mydash-nonparamdash-scrollpanel');
						var fourChildNode = testContainer.querySelector('.tibo-mydash-nonparamdash-scrollpanel .p-scrollpanel-wrapper .p-scrollpanel-content');
						fourChildNode.insertBefore(document.getElementById(data), fourChildNode.children[this.dragIndex]);
								
						d3.select(nodeCopy).attr("class","chart-div")
						this.chartId = "chartid-" + Math.floor(Math.random() * 10000) + 1;
						d3.select(event.target).attr("id", this.chartId)
							
						var z = document.createElement('button'); // is a node
						z.setAttribute("style","position:absolute; right:-5px; top:-10px; width:23px; height:23px; border-radius:100%; background-image:url('././assets/images/cancel.png'); background-repeat: no-repeat; background-size: 100% 100%; background-position: center; border:0; z-index:1000;")
						z.addEventListener("click", () => {
							event.target.innerHTML = "";
						}, false);
						event.target.appendChild(z);
							
						this.getWidgetData(this.widgetsList[this.dragIndex]);*/

					}
					else {
						alert("Widgets from different parameterized dashboard cannot be dropped");
					}

				}

			}
			else {
				this.hidePlus = false;
				this.store_index_j.push(index_j);

				if (index_i === 0) {
					this.store_index_i[0].push(index_j);
					this.removeDuplicates2(this.store_index_i[0]);
					this.store_index_i[0] = this.store_index_i2;

				} else if (index_i === 1) {
					this.store_index_i[1].push(index_j);
					this.removeDuplicates2(this.store_index_i[1]);
					this.store_index_i[1] = this.store_index_i2;
				}
				else if (index_i === 2) {
					this.store_index_i[2].push(index_j);
					this.removeDuplicates2(this.store_index_i[2]);
					this.store_index_i[2] = this.store_index_i2;
				}

				this.index_i = index_i;
				this.removeDuplicates(this.store_index_j);
				this.store_index_j = this.store_index_j2;
				d3.select('#loader' + dData).style('display', 'none')
				this.index_i = index_i;
				this.index_j = index_j;

				droppedWidget["objectCordinates"] = d3.select(event.target).attr("objectCordinates");
				this.saveDashboardReqPayload.widgetControls.push(droppedWidget);

				d3.select(event.target).attr("widgetInfo",
					JSON.stringify({
						parameters: this.searchOrDashResultArray[this.dragIndex].dashboardRequestorParameters,
						controls: droppedWidget
					}));


				// var data = event.dataTransfer.getData("text");
				// var nodeCopy = document.getElementById(data).cloneNode(true);
				// var testContainer = document.querySelector('#tibo-mydash-nonparamdash-scrollpanel');
				// var fourChildNode = testContainer.querySelector('.tibo-mydash-nonparamdash-scrollpanel .p-scrollpanel-wrapper .p-scrollpanel-content');
				// fourChildNode.insertBefore(document.getElementById(data), fourChildNode.children[this.dragIndex]);

				// d3.select(nodeCopy).attr("class", "chart-div")
				// this.chartId = "chartid-" + Math.floor(Math.random() * 10000) + 1;
				// d3.select(event.target).attr("id", this.chartId)


				// var spinner = event.target.querySelector('.tibo-mydash-widget-spinner1');
				// var clonedSpinner = spinner.cloneNode(true);
				// spinner.setAttribute("style", "position:absolute; left:45%; top:45%; width:40px; height:40px; display:none;")
				this.chartImage = "././assets/images/createChart/chart" + droppedWidget['controlMasterId'] + ".svg";
				event.target.innerHTML = "<div style='width: 100%; height:0%; background-color:#c7c7c7; position: absolute; top:5px; font-size:16px; font-family:Roboto; color:#0f528c; text-indent:5px;'>" + this.widgetsList[this.dragIndex].chartTitle + "</div>" + "<div><img src=" + this.chartImage + " style='height: 230px;width: 100%;border: 1px solid #f0f0f0;'></div>"
				var z = document.createElement('button'); // is a node
				z.setAttribute("style", "position:absolute; right:-5px; top:-10px; width:23px; height:23px; border-radius:100%; background-image:url('././assets/images/cancel.png'); background-repeat: no-repeat; background-size: 100% 100%; background-position: center; border:0; z-index:1000;")
				z.addEventListener("click", () => {
					this.widgetLoading = true
					event.target.innerHTML = "  <i class='pi pi-plus' id=" + "loader" + dData + "></i>";
					event.target.id = "";
					event.target.removeAttribute("widgetInfo");
					d3.select('#loader' + dData).style('display', 'block')
					// event.target.appendChild(clonedSpinner);
				}, false);
				event.target.appendChild(z);

				// this.getWidgetData(this.widgetsList[this.dragIndex]);
			}

		}
		else if (this.searchOrDashResult == "dashboard result") {
			//console.log(this.dragIndex)
			var droppedWidget = this.searchOrDashResultArray[0].widgetControls[this.dragIndex];
			let idData = d3.select(event.target).attr("objectCordinates");
			var dData = idData.replace(',', '')
			if (this.searchOrDashResultArray[0].dashboardRequestorParameters.length > 0) {

				if (this.isParameterizedWidget == false) {
					this.isParameterizedWidget = true;
					d3.select('#loader' + dData).style('display', 'none')
					this.droppedParamWidgetDomainId = Number(this.searchOrDashResultArray[0].dashboardRequestorParameters[0].authorizationId);

					this.droppedParamWidgetParameters = this.searchOrDashResultArray[0].dashboardRequestorParameters; // Storing parameterized widget parameters

					this.saveDashboardReqPayload.dashboardRequestorParameters = this.searchOrDashResultArray[0].dashboardRequestorParameters;
					droppedWidget["objectCordinates"] = d3.select(event.target).attr("objectCordinates");
					this.saveDashboardReqPayload.widgetControls.push(droppedWidget);

					d3.select(event.target).attr("widgetInfo",
						JSON.stringify({
							parameters: this.searchOrDashResultArray[0].dashboardRequestorParameters,
							controls: droppedWidget
						}));

					// event.target.innerHTML = "<div style='width: 100%; height:0%; background-color:#c7c7c7; position: absolute; top:5px; font-size:16px; font-family:Roboto; color:#0f528c; text-indent:5px;'>" + this.widgetsList[this.dragIndex].chartTitle + "</div>" +
					// 	"<p class='p-mx-3' style='position: relative; top:20%; font-family:Roboto; font-size:16px; color:#0f528c; text-align:left;'>Dashboard: " + this.searchOrDashResultArray[0].widgetName + "</p>" +
					// 	"<p class='p-mx-3' style='position: relative; top:40%; font-family:Roboto; font-size:16px; color:#25c475; text-align:center;'>Widget added to dashboard. It's a parameterized widget!</p>";
					this.chartImage = "././assets/images/createChart/chart" + droppedWidget['controlMasterId'] + ".svg";
					event.target.innerHTML = "<div style='width: 100%; height:0%; background-color:#c7c7c7; position: absolute; top:5px; font-size:16px; font-family:Roboto; color:#0f528c; text-indent:5px;'>" + this.widgetsList[this.dragIndex].chartTitle + "</div>" + "<div><img src=" + this.chartImage + " style='height: 230px;width: 100%;border: 1px solid #f0f0f0;'></div>"
					var z = document.createElement('button'); // is a node
					z.setAttribute("style", "position:absolute; right:-5px; top:-10px; width:23px; height:23px; border-radius:100%; background-image:url('././assets/images/cancel.png'); background-repeat: no-repeat; background-size: 100% 100%; background-position: center; border:0; z-index:1000;")
					z.addEventListener("click", () => {
						this.widgetLoading = true
						event.target.innerHTML = "  <i class='pi pi-plus' id=" + "loader" + dData + "></i>";
						event.target.id = "";
						event.target.removeAttribute("widgetInfo");
						d3.select('#loader' + dData).style('display', 'block')

						var hasParameterizedWidget = false;
						d3.selectAll(".create-dashboard-widget-container")[0].forEach(wid => {
							if (d3.select(wid).attr("widgetInfo") != null) {
								var widgetInfo = JSON.parse(d3.select(wid).attr("widgetInfo"));
								if (widgetInfo.parameters.length > 0) {
									hasParameterizedWidget = true;
								}
							}
						});

						if (!hasParameterizedWidget) {
							this.droppedParamWidgetDomainId = null;
							this.isParameterizedWidget = false;
							this.droppedParamWidgetParameters = []; // Empty the stored parameterized widget parameters
						}

					}, false);
					event.target.appendChild(z);
					/*var data = event.dataTransfer.getData("text");
					var nodeCopy = document.getElementById(data).cloneNode(true);
					var testContainer = document.querySelector('#tibo-mydash-nonparamdash-scrollpanel');
					var fourChildNode = testContainer.querySelector('.tibo-mydash-nonparamdash-scrollpanel .p-scrollpanel-wrapper .p-scrollpanel-content');
					fourChildNode.insertBefore(document.getElementById(data), fourChildNode.children[this.dragIndex]);
							
					d3.select(nodeCopy).attr("class","chart-div")
					this.chartId = "chartid-" + Math.floor(Math.random() * 10000) + 1;
					d3.select(event.target).attr("id", this.chartId)
						
					var z = document.createElement('button'); // is a node
					z.setAttribute("style","position:absolute; right:-5px; top:-10px; width:23px; height:23px; border-radius:100%; background-image:url('././assets/images/cancel.png'); background-repeat: no-repeat; background-size: 100% 100%; background-position: center; border:0; z-index:1000;")
					z.addEventListener("click", () => {
						event.target.innerHTML = "";
					}, false);
					event.target.appendChild(z);
						
					this.getWidgetData(this.widgetsList[this.dragIndex]);*/

				}
				else {
					if (this.droppedParamWidgetDomainId == Number(this.searchOrDashResultArray[0].dashboardRequestorParameters[0].authorizationId)) {

						this.droppedParamWidgetParameters = this.searchOrDashResultArray[0].dashboardRequestorParameters; // Storing parameterized widget parameters

						//this.saveDashboardReqPayload.dashboardRequestorParameters = this.searchOrDashResultArray[0].dashboardRequestorParameters;
						droppedWidget["objectCordinates"] = d3.select(event.target).attr("objectCordinates");
						this.saveDashboardReqPayload.widgetControls.push(droppedWidget);
						d3.select('#loader' + dData).style('display', 'none')
						d3.select(event.target).attr("widgetInfo",
							JSON.stringify({
								parameters: this.searchOrDashResultArray[0].dashboardRequestorParameters,
								controls: droppedWidget
							}));

						// event.target.innerHTML = "<div style='width: 100%; height:0%; background-color:#c7c7c7; position: absolute; top:5px; font-size:16px; font-family:Roboto; color:#0f528c; text-indent:5px;'>" + this.widgetsList[this.dragIndex].chartTitle + "</div>" +
						// 	"<p class='p-mx-3' style='position: relative; top:20%; font-family:Roboto; font-size:16px; color:#0f528c; text-align:left;'>Dashboard: " + this.searchOrDashResultArray[0].widgetName + "</p>" +
						// 	"<p class='p-mx-3' style='position: relative; top:40%; font-family:Roboto; font-size:16px; color:#25c475; text-align:center;'>Widget added to dashboard. It's a parameterized widget!</p>";
						this.chartImage = "././assets/images/createChart/chart" + droppedWidget['controlMasterId'] + ".svg";
						event.target.innerHTML = "<div style='width: 100%; height:0%; background-color:#c7c7c7; position: absolute; top:5px; font-size:16px; font-family:Roboto; color:#0f528c; text-indent:5px;'>" + this.widgetsList[this.dragIndex].chartTitle + "</div>" + "<div><img src=" + this.chartImage + " style='height: 230px;width: 100%;border: 1px solid #f0f0f0;'></div>"
						var z = document.createElement('button'); // is a node
						z.setAttribute("style", "position:absolute; right:-5px; top:-10px; width:23px; height:23px; border-radius:100%; background-image:url('././assets/images/cancel.png'); background-repeat: no-repeat; background-size: 100% 100%; background-position: center; border:0; z-index:1000;")
						z.addEventListener("click", () => {

							event.target.innerHTML = "  <i class='pi pi-plus' id=" + "loader" + dData + "></i>";
							event.target.id = "";
							event.target.removeAttribute("widgetInfo");
							d3.select('#loader' + dData).style('display', 'block')

							var hasParameterizedWidget = false;
							d3.selectAll(".create-dashboard-widget-container")[0].forEach(wid => {
								if (d3.select(wid).attr("widgetInfo") != null) {
									var widgetInfo = JSON.parse(d3.select(wid).attr("widgetInfo"));
									if (widgetInfo.parameters.length > 0) {
										hasParameterizedWidget = true;
									}
								}
							});

							if (!hasParameterizedWidget) {
								this.droppedParamWidgetDomainId = null;
								this.isParameterizedWidget = false;
								this.droppedParamWidgetParameters = []; // Empty the stored parameterized widget parameters
							}


						}, false);
						event.target.appendChild(z);
						/*var data = event.dataTransfer.getData("text");
						var nodeCopy = document.getElementById(data).cloneNode(true);
						var testContainer = document.querySelector('#tibo-mydash-nonparamdash-scrollpanel');
						var fourChildNode = testContainer.querySelector('.tibo-mydash-nonparamdash-scrollpanel .p-scrollpanel-wrapper .p-scrollpanel-content');
						fourChildNode.insertBefore(document.getElementById(data), fourChildNode.children[this.dragIndex]);
								
						d3.select(nodeCopy).attr("class","chart-div")
						this.chartId = "chartid-" + Math.floor(Math.random() * 10000) + 1;
						d3.select(event.target).attr("id", this.chartId)
							
						var z = document.createElement('button'); // is a node
						z.setAttribute("style","position:absolute; right:-5px; top:-10px; width:23px; height:23px; border-radius:100%; background-image:url('././assets/images/cancel.png'); background-repeat: no-repeat; background-size: 100% 100%; background-position: center; border:0; z-index:1000;")
						z.addEventListener("click", () => {
							event.target.innerHTML = "";
						}, false);
						event.target.appendChild(z);
							
						this.getWidgetData(this.widgetsList[this.dragIndex]);*/

					}
					else {
						alert("Widgets from different parameterized dashboard cannot be dropped");
					}

				}

			}
			else {
				this.hidePlus = false;
				this.store_index_j.push(index_j);

				if (index_i === 0) {
					this.store_index_i[0].push(index_j);
					this.removeDuplicates2(this.store_index_i[0]);
					this.store_index_i[0] = this.store_index_i2;

				} else if (index_i === 1) {
					this.store_index_i[1].push(index_j);
					this.removeDuplicates2(this.store_index_i[1]);
					this.store_index_i[1] = this.store_index_i2;
				}
				else if (index_i === 2) {
					this.store_index_i[2].push(index_j);
					this.removeDuplicates2(this.store_index_i[2]);
					this.store_index_i[2] = this.store_index_i2;
				}

				this.index_i = index_i;
				this.removeDuplicates(this.store_index_j);
				this.store_index_j = this.store_index_j2;
				d3.select('#loader' + dData).style('display', 'none')
				this.index_i = index_i;
				this.index_j = index_j;
				droppedWidget["objectCordinates"] = d3.select(event.target).attr("objectCordinates");
				this.saveDashboardReqPayload.widgetControls.push(droppedWidget);

				d3.select(event.target).attr("widgetInfo",
					JSON.stringify({
						parameters: this.searchOrDashResultArray[0].dashboardRequestorParameters,
						controls: droppedWidget
					}));

				// var data = event.dataTransfer.getData("text");
				// var nodeCopy = document.getElementById(data).cloneNode(true);
				// var testContainer = document.querySelector('#tibo-mydash-nonparamdash-scrollpanel');
				// var fourChildNode = testContainer.querySelector('.tibo-mydash-nonparamdash-scrollpanel .p-scrollpanel-wrapper .p-scrollpanel-content');
				// fourChildNode.insertBefore(document.getElementById(data), fourChildNode.children[this.dragIndex]);

				// d3.select(nodeCopy).attr("class", "chart-div")
				// this.chartId = "chartid-" + Math.floor(Math.random() * 10000) + 1;
				// d3.select(event.target).attr("id", this.chartId)


				// var spinner = event.target.querySelector('.tibo-mydash-widget-spinner1');
				// var clonedSpinner = spinner.cloneNode(true);
				// spinner.setAttribute("style", "position:absolute; left:45%; top:45%; width:40px; height:40px; display:none;")
				this.chartImage = "././assets/images/createChart/chart" + droppedWidget['controlMasterId'] + ".svg";
				event.target.innerHTML = "<div style='width: 100%; height:0%; background-color:#c7c7c7; position: absolute; top:5px; font-size:16px; font-family:Roboto; color:#0f528c; text-indent:5px;'>" + this.widgetsList[this.dragIndex].chartTitle + "</div>" + "<div><img src=" + this.chartImage + " style='height: 230px;width: 100%;border: 1px solid #f0f0f0;'></div>"

				var z = document.createElement('button'); // is a node
				z.setAttribute("style", "position:absolute; right:-5px; top:-10px; width:23px; height:23px; border-radius:100%; background-image:url('././assets/images/cancel.png'); background-repeat: no-repeat; background-size: 100% 100%; background-position: center; border:0; z-index:1000;")
				z.addEventListener("click", () => {

					event.target.innerHTML = "  <i class='pi pi-plus' id=" + "loader" + dData + "></i>";
					event.target.id = "";
					event.target.removeAttribute("widgetInfo");
					d3.select('#loader' + dData).style('display', 'block')
					// event.target.appendChild(clonedSpinner);
				}, false);
				event.target.appendChild(z);


				// this.getWidgetData(this.widgetsList[this.dragIndex]);


			}

		}
		this.dragIndex=-1
	}
	}

	saveDashboard() {
		var checkDroppedWidgetsBool = false;
		d3.selectAll(".create-dashboard-widget-container")[0].forEach(wid => {
			if (d3.select(wid).attr("widgetInfo") != null) {
				checkDroppedWidgetsBool = true;
			}
		})
		if (checkDroppedWidgetsBool) {
			if (this.buttonLable == "Update Dashboard") {
				this.createDashboard();
			}
			if (this.buttonLable == "Save Dashboard") {
				this.saveDashDialBoxDisplayBool = true;
				this.dashboardName = '';
			}
		}
		else {
			this.alertSeverity = "error";
			this.alertMsg = "Please drag and drop widgets on drop boxes.";
			this.addSingle();
		}
	}
	onDomainSelect(event) {
		this.saveDashboardDomainId = Number(event.value)
	}
	createDashboard() {
		var totalDroppedWidgetsArray = [];
		d3.selectAll(".create-dashboard-widget-container")[0].forEach(wid => {
			if (d3.select(wid).attr("widgetInfo") != null) {
				var widgetInfo = JSON.parse(d3.select(wid).attr("widgetInfo"));
				totalDroppedWidgetsArray.push(widgetInfo.controls);
			}
		})
		//console.log(totalDroppedWidgetsArray)
		// else {
			this.alertSeverity = "success";
			this.alertMsg = '"' + this.saveDashboardReqPayload.widgetName + '" Dashboard Created Successfully!';
			this.addSingle();
			this.showCreateDash = false;

		if (this.buttonLable == "Update Dashboard") {
			this.saveDashboardReqPayload.domainId = this.saveDashboardDomainId;
			this.saveDashboardReqPayload.widgetName = this.dashboardName;
			this.saveDashboardReqPayload.dashboardRequestorParameters = this.editDroppedParamWidgetParameters?this.editDroppedParamWidgetParameters:this.droppedParamWidgetParameters;
			
			let array = this.compareArrays(this.compareWidgetList, totalDroppedWidgetsArray)
			//console.log(array);
			this.saveDashboardReqPayload['deleteControlIds'] = array.deletedIds;
			const firstArrayIds = this.compareWidgetList.map(item => item.widgetControlId);
			totalDroppedWidgetsArray.forEach(item => {
				if (!firstArrayIds.includes(item.widgetControlId)) {
					item.widgetControlId = 0;
				}
			});
			if(this.editDroppedParamWidgetParameters.length==0){
				this.saveDashboardReqPayload.dashboardRequestorParameters.forEach((d) => {
					d.dashboardParameterId = 0;
					d.authorizationId = 0;
				})
			}
			this.saveDashboardReqPayload.widgetControls = totalDroppedWidgetsArray;
			// this.saveDashboardReqPayload.widgetControls.forEach((d) => {
			// 	d.widgetControlId = 0;
			// })
			this.saveDashboardReqPayload["dashboardVisibility"] = "Y"
			this.saveDashDialBoxDisplayBool = false;
			//console.log(this.saveDashboardReqPayload);
			this.createDashSubscrip = this.creatDashService.updateDashboard(this.getWidgetId, this.saveDashboardReqPayload).subscribe((response: any) => { // widgets list

				if (response.status == 200) {
					this.alertSeverity = "success";
					this.alertMsg = '"' + this.saveDashboardReqPayload.widgetName + '" Dashboard Updated Successfully!';
					this.addSingle();
				}
				this.creatDashService.dashboardlist().subscribe(
					(dashboardsListResp: any) => {
						this.listOfDash = dashboardsListResp.body;
						this.listOfDash.filter(e => {
							if (e.widgetName == this.saveDashboardReqPayload.widgetName) {
								this.router.navigate(['/dashboard'], {
									queryParams: {
										dashboardname: e.widgetName,
										dashboardcode: e.widgetMasterId,
										containerbool: false
									},
								});
							}
						})
					});
			}, error => {
				// this.alertSeverity = "error";
				// if (Number(error.status) == 401) { this.alertMsg = "Please Try After Some Time!" }
				// else if (Number(error.status) == 409) { this.alertMsg = 'Sorry, duplicate name not allowed!' }
				// else if (Number(error.status) == 500) { this.alertMsg = "Internal Error" }
				// else { this.alertMsg = "Please Try After Some Time." }

				// this.addSingle();
			}, () => { })
		}
		else if (this.buttonLable == "Save Dashboard") {
			if( d3.select("#dashboard-name-id").node().value.trim()!=''){
			this.saveDashboardReqPayload.domainId = this.saveDashboardDomainId;
			this.saveDashboardReqPayload.widgetName = d3.select("#dashboard-name-id").node().value.trim();
			this.saveDashboardReqPayload.dashboardRequestorParameters = this.droppedParamWidgetParameters;
			this.saveDashboardReqPayload.widgetControls = totalDroppedWidgetsArray;
			this.saveDashboardReqPayload.dashboardRequestorParameters.forEach((d) => {
				d.dashboardParameterId = 0;
				d.authorizationId = 0;
			})
			this.saveDashboardReqPayload.widgetControls.forEach((d) => {
				d.widgetControlId = 0;
			})

			if ((this.saveDashboardDomainId == undefined) || (d3.select("#dashboard-name-id").node().value.length == 0)) {
				this.alertSeverity = "error";
				this.alertMsg = "Please Select A Domain And Enter Dashboard Name."
				this.addSingle();
			}
			else {
				this.saveDashboardReqPayload["dashboardVisibility"] = "Y"
				this.saveDashDialBoxDisplayBool = false;
				this.createDashSubscrip = this.creatDashService.createDashboard(this.saveDashboardReqPayload).subscribe((response: any) => { // widgets list

					if (response.status == 200) {
						this.alertSeverity = "success";
						this.alertMsg = '"' + this.saveDashboardReqPayload.widgetName + '" Dashboard Created Successfully!';
						this.addSingle();
					}
					this.creatDashService.dashboardlist().subscribe(
						(dashboardsListResp: any) => {
							this.listOfDash = dashboardsListResp.body;
							this.listOfDash.filter(e => {
								if (e.widgetName == this.saveDashboardReqPayload.widgetName) {
									this.router.navigate(['/dashboard'], {
										queryParams: {
											dashboardname: e.widgetName,
											dashboardcode: e.widgetMasterId,
											containerbool: false
										},
									});
								}
							})
						});
				}, error => {
					// this.alertSeverity = "error";
					// if (Number(error.status) == 401) { this.alertMsg = "Please Try After Some Time!" }
					// else if (Number(error.status) == 409) { this.alertMsg = 'Sorry, duplicate name not allowed!' }
					// else if (Number(error.status) == 500) { this.alertMsg = "Internal Error" }
					// else { this.alertMsg = "Please Try After Some Time." }

					// this.addSingle();
				}, () => { })
			}
		}
		else{
			this.alertSeverity = "error";
			this.alertMsg = "Please Select A Domain And Enter Dashboard Name."
			this.addSingle();
		}
		}
		
	}

	dialogBoxOnCloseFunc(event) {
		
	}

	addSingle() { this.messageService.add({ severity: this.alertSeverity, summary: "", detail: this.alertMsg }); }

	clear() { this.messageService.clear(); }

	getWidgetData(widget) {


		// widget = '';

		if (widget.controlMasterId == 3) {

			this.getWidgetDataSubscrip = this.creatDashService.getWidgetData(widget.widgetControlId, "limit=&isWebservice=N").subscribe((widgetdetails: any) => {


				var chartElement = d3.select("#" + this.chartId)

				this.table(chartElement, widget, widgetdetails)

			}, error => {
				if (Number(error.status) == 401) { this.alertMsg = "Please Try After Some Time!" }
				else if (Number(error.status) == 500) { this.alertMsg = "Internal Error" }
				else { this.alertMsg = "Please Try After Some Time." }

				this.addSingle();
			}, () => this.finalCall()) // getWidgetData

		}
		else {

			this.getWidgetDataSubscrip = this.creatDashService.getWidgetData(widget.widgetControlId, "limit=10").subscribe((widgetdetails: any) => {

				//-------------------------------------------------------------------------------------------------

				if (widget.controlMasterId == 1) {
					var chartElement = d3.select("#" + this.chartId)
						.attr("empty", false)

					this.pieChart(chartElement, widget, widgetdetails)
				}
				else if (widget.controlMasterId == 2) {
					var chartElement = d3.select("#" + this.chartId)
						.attr("empty", false)

					this.columnChart(chartElement, widget, widgetdetails)
				}
				/*else if(widget.controlMasterId == 3){
				  var chartElement = d3.select("#"+this.chartId)
				  .attr("empty", false)
				  
				  this.table(chartElement,widget,widgetdetails)
				}*/
				else if (widget.controlMasterId == 5) {
					var chartElement = d3.select("#" + this.chartId)
						.attr("empty", false)

					this.donutChart(chartElement, widget, widgetdetails)
				}
				else if (widget.controlMasterId == 6) {
					var chartElement = d3.select("#" + this.chartId)
						.attr("empty", false)

					this.horizontalChart(chartElement, widget, widgetdetails)
				}
				else if (widget.controlMasterId == 7) { } // Waffle chart
				else if (widget.controlMasterId == 8) {
					var chartElement = d3.select("#" + this.chartId)
						.attr("empty", false)

					this.perGauge(chartElement, widget, widgetdetails)
				}
				else if (widget.controlMasterId == 9) {
					var chartElement = d3.select("#" + this.chartId)
						.attr("empty", false)

					this.numberGauge(chartElement, widget, widgetdetails)
				}
				else if (widget.controlMasterId == 10) {
					var chartElement = d3.select("#" + this.chartId)
						.attr("empty", false)

					this.map(chartElement, widget, widgetdetails)
				}
				else if (widget.controlMasterId == 11) {
					var chartElement = d3.select("#" + this.chartId)
						.attr("empty", false)

					this.columnAndLineChart(chartElement, widget, widgetdetails)
				}
				else if (widget.controlMasterId == 12) {
					var chartElement = d3.select("#" + this.chartId)
						.attr("empty", false)

					this.lineChart(chartElement, widget, widgetdetails)
				}
				else if (widget.controlMasterId == 13) {
					var chartElement = d3.select("#" + this.chartId)
						.attr("empty", false)

					this.bubbleChart(chartElement, widget, widgetdetails)
				}
				else if (widget.controlMasterId == 14) {
					var chartElement = d3.select("#" + this.chartId)
						.attr("empty", false)

					this.stackedHorizontalBarChart(chartElement, widget, widgetdetails)
				}
				else if (widget.controlMasterId == 15) {
					var chartElement = d3.select("#" + this.chartId)
						.attr("empty", false)

					this.stackedBarChart(chartElement, widget, widgetdetails)
				}
				else if (widget.controlMasterId == 16) {
					var chartElement = d3.select("#" + this.chartId)
						.attr("empty", false)

					this.perGaugeWithFlip(chartElement, widget, widgetdetails)
				}
				else if (widget.controlMasterId == 17) {
					var chartElement = d3.select("#" + this.chartId)
						.attr("empty", false)

					this.squareGauge(chartElement, widget, widgetdetails)
				}
				else if (widget.controlMasterId == 18) {
					var chartElement = d3.select("#" + this.chartId)
						.attr("empty", false)

					this.bellCurveChart(chartElement, widget, widgetdetails)
				}
				else if (widget.controlMasterId == 19) {
					var chartElement = d3.select("#" + this.chartId)
						.attr("empty", false)

					this.areaChart(chartElement, widget, widgetdetails)
				}
				else if (widget.controlMasterId == 20) {
					var chartElement = d3.select("#" + this.chartId)
						.attr("empty", false)

					this.groupedBarChart(chartElement, widget, widgetdetails)
				}
				else if (widget.controlMasterId == 21) {
					var chartElement = d3.select("#" + this.chartId)
						.attr("empty", false)

					this.horizontalGroupedBarChart(chartElement, widget, widgetdetails)
				}
				else if (widget.controlMasterId == 22) { } //Horizontal opposite bar Chart 
				else if (widget.controlMasterId == 23) { } //Vertical opposite bar Chart 
				else if (widget.controlMasterId == 24) {
					var chartElement = d3.select("#" + this.chartId)
						.attr("empty", false)

					this.dotGauge(chartElement, widget, widgetdetails)
				}
				else if (widget.controlMasterId == 25) { }//Square bubble chart
				else if (widget.controlMasterId == 26) { }//Magic quadrant
				else if (widget.controlMasterId == 27) { }//Funnel chart
				else if (widget.controlMasterId == 28) {
					var chartElement = d3.select("#" + this.chartId)
						.attr("empty", false)

					this.columnAndLinesChart(chartElement, widget, widgetdetails)
				}
				else if (widget.controlMasterId == 29) {
					var chartElement = d3.select("#" + this.chartId)
						.attr("empty", false)

					this.threeXAxisesGroupedBarChart(chartElement, widget, widgetdetails)
				}
				else if (widget.controlMasterId == 30) {
					var chartElement = d3.select("#" + this.chartId)
						.attr("empty", false)

					this.twoXAxisesGroupedBarChart(chartElement, widget, widgetdetails)
				}
				else if (widget.controlMasterId == 31) {
					var chartElement = d3.select("#" + this.chartId)
						.attr("empty", false)

					this.bellCurveChartWithMinAndMaxRange(chartElement, widget, widgetdetails)
				}
				else if (widget.controlMasterId == 32) {
					var chartElement = d3.select("#" + this.chartId)
						.attr("empty", false)

					this.stackedGroupedBarChart(chartElement, widget, widgetdetails)
				}
				else if (widget.controlMasterId == 33) {
					var chartElement = d3.select("#" + this.chartId)
						.attr("empty", false)

					this.stackedGroupedBarsWithLineChart(chartElement, widget, widgetdetails)
				}
				else if (widget.controlMasterId == 34) {
					var chartElement = d3.select("#" + this.chartId)
						.attr("empty", false)

					this.stackedBarWithLinesChart(chartElement, widget, widgetdetails)
				}
				else if (widget.controlMasterId == 35) {
					var chartElement = d3.select("#" + this.chartId)
						.attr("empty", false)

					this.trafficLightsChart(chartElement, widget, widgetdetails)
				}
				else if (widget.controlMasterId == 36) {
					var chartElement = d3.select("#" + this.chartId)
						.attr("empty", false)

					this.columnChartWithNegativeValues(chartElement, widget, widgetdetails)
				}
				else if (widget.controlMasterId == 37) {
					var chartElement = d3.select("#" + this.chartId)
						.attr("empty", false)

					this.groupedBarsWithLineChart(chartElement, widget, widgetdetails)
				}
				else if (widget.controlMasterId == 38) {
					var chartElement = d3.select("#" + this.chartId)
						.attr("empty", false)

					this.USMapWithPins(chartElement, widget, widgetdetails)
				}

				//-------------------------------------------------------------------------------------------------

			}, error => {
				if (Number(error.status) == 401) { this.alertMsg = "Please Try After Some Time!" }
				else if (Number(error.status) == 500) { this.alertMsg = "Internal Error" }
				else { this.alertMsg = "Please Try After Some Time." }

				this.addSingle();
			}, () => this.finalCall()) // getWidgetData

		} // else

	} // getWidgetData()

	finalCall() {
		setTimeout(() => {
			d3.selectAll(".widget-class")[0].map(function (d, ind) { // removing menu button from each chart after loading the chart!
				if (d.children.length > 0) {
					//d3.select(d).select(".p-progress-spinner").style("display","none"); //hidding widget box spinner after loading the chart
					var div = d3.select(d).select("div > img[src='images/menuIcon.png']")[0][0]
					if (d3.select(div).node()?.parentNode != null) {
						d3.select(d3.select(div).node().parentNode).style("display", "none");
					}

				}
			})
		}, 1000);
	}

	pieChart(chartElement, widget, widgetdetails) {
		let fetchedData = widgetdetails.body.records == null ? "No records!" : (widgetdetails.body.records.length > 0 ? widgetdetails.body.records : "No records!")

		if (fetchedData == "No records!") {
			chartElement.append("div").style({ "position": "absolute", "left": "0%", "top": "0%", "width": "100%", "height": "8%", "background-color": "white", "text-indent": "6px" })
				.html('<div  style="width: 100%; height:0%; background-color:#ffffff; position: absolute; top:10.0%; font-size:16px; font-family:Roboto; color:#0f528c;">' + widget.chartTitle + '</div>')

			chartElement.append("label").style({ "position": "absolute", "left": "0%", "top": "45%", "width": "100%", "font-family": "Roboto", "font-size": "30px", "color": "#f16464", "text-align": "center" })
				.text("No records!")
		}
		else {
			let xAxisFormatted = "";
			if (widget.yAxis.charAt(0) == "[" && widget.yAxis.charAt(widget.yAxis.length - 1) == "]") {
				xAxisFormatted = JSON.parse(widget.yAxis)[0]
			}
			else { xAxisFormatted = widget.yAxis }

			let yAxisFormatted = "";
			if (widget.xAxis.charAt(0) == "[" && widget.xAxis.charAt(widget.xAxis.length - 1) == "]") {
				yAxisFormatted = JSON.parse(widget.xAxis)[0]
			}
			else { yAxisFormatted = widget.xAxis }

			let options = {
				data: widgetdetails.body.records, // for chart
				dataForTable: [], // for table if there is no data send empty array ([])
				isDataDetailedForTable: false,
				keys: {
					label: xAxisFormatted,
					value: yAxisFormatted
				},
				chartTitle: widget.chartTitle,
				showTitle: true,
				showLegend: true,
				legendSide: "right", //"right" or "left"
				colorSet: "one", //"one" or "two" or "three"
				gradient: "none", //"none" or "smooth" or "elliptical" or "circular" or "radial"
				drillDownColumnName: widget.drillThru[0]?.drillDownColumn ? widget.drillThru[0].drillDownColumn : "",
				showTableIcon: true, //false - hide, true - show
				showTable: false, //false - hide, true - show
				showComments: false,
				comments: widget.comments,
				resizeButtonID: document.getElementById("resizeBtn").id
			}

			setTimeout(() => { acinfotech.pieChart(chartElement, options); }, 1000)
		}
	}
	columnChart(chartElement, widget, widgetdetails) {
		let fetchedData = widgetdetails.body.records == null ? "No records!" : (widgetdetails.body.records.length > 0 ? widgetdetails.body.records : "No records!")

		if (fetchedData == "No records!") {
			chartElement.append("div").style({ "position": "absolute", "left": "0%", "top": "0%", "width": "100%", "height": "8%", "background-color": "white", "text-indent": "6px" })
				.html('<div  style="width: 100%; height:0%; background-color:#ffffff; position: absolute; top:10.0%; font-size:16px; font-family:Roboto; color:#0f528c;">' + widget.chartTitle + '</div>')

			chartElement.append("label").style({ "position": "absolute", "left": "0%", "top": "45%", "width": "100%", "font-family": "Roboto", "font-size": "30px", "color": "#f16464", "text-align": "center" })
				.text("No records!")
		}
		else {
			let xAxisFormatted = "";
			if (widget.yAxis.charAt(0) == "[" && widget.yAxis.charAt(widget.yAxis.length - 1) == "]") {
				xAxisFormatted = JSON.parse(widget.yAxis)[0]
			}
			else { xAxisFormatted = widget.yAxis }

			let yAxisFormatted = "";
			if (widget.xAxis.charAt(0) == "[" && widget.xAxis.charAt(widget.xAxis.length - 1) == "]") {
				yAxisFormatted = JSON.parse(widget.xAxis)[0]
			}
			else { yAxisFormatted = widget.xAxis }

			let options = {
				data: widgetdetails.body.records,
				dataForTable: [], // for table
				isDataDetailedForTable: false,
				keys: {
					label: xAxisFormatted,
					value: yAxisFormatted
				},
				chartTitle: widget.chartTitle,
				xAxisLable: xAxisFormatted,
				yAxisLable: yAxisFormatted,
				showTitle: true,
				colorSet: "one", //one or two or three
				drillDownColumnName: widget.drillThru[0]?.drillDownColumn ? widget.drillThru[0].drillDownColumn : "",
				showTable: false, //false - hide, true - show
				showComments: false,
				comments: widget.comments,
				resizeButtonID: document.getElementById("resizeBtn").id
			}
			setTimeout(() => { acinfotech.columnChart(chartElement, options) }, 1000)
		}
	}
	table(chartElement, widget, widgetdetails) {
		let fetchedData = widgetdetails.body.records == null ? "No records!" : (widgetdetails.body.records.length > 0 ? widgetdetails.body.records : "No records!")

		if (fetchedData == "No records!") {
			chartElement.append("div").style({ "position": "absolute", "left": "0%", "top": "0%", "width": "100%", "height": "8%", "background-color": "white", "text-indent": "6px" })
				.html('<div  style="width: 100%; height:0%; background-color:#ffffff; position: absolute; top:10.0%; font-size:16px; font-family:Roboto; color:#0f528c;">' + widget.chartTitle + '</div>')

			chartElement.append("label").style({ "position": "absolute", "left": "0%", "top": "45%", "width": "100%", "font-family": "Roboto", "font-size": "30px", "color": "#f16464", "text-align": "center" })
				.text("No records!")
		}
		else {
			let options = {
				data: widgetdetails.body.records,
				chartTitle: widget.chartTitle,
				showTitle: true,
				drillDownColumnName: widget.drillThru[0]?.drillDownColumn ? widget.drillThru[0].drillDownColumn : "",
				fontStyle: "Normal", // Normal, Italic
				fontWeight: "Bold", // Normal, Bold
				fontFamily: "Roboto", // Roboto, Century Gothic, Candara, Verdana, Trebuchet MS, Segoe UI
				resizeButtonID: document.getElementById("resizeBtn").id
			}
			setTimeout(() => { acinfotech.table(chartElement, options) }, 1000)
		}
	}
	donutChart(chartElement, widget, widgetdetails) {
		let fetchedData = widgetdetails.body.records == null ? "No records!" : (widgetdetails.body.records.length > 0 ? widgetdetails.body.records : "No records!")

		if (fetchedData == "No records!") {
			chartElement.append("div").style({ "position": "absolute", "left": "0%", "top": "0%", "width": "100%", "height": "8%", "background-color": "white", "text-indent": "6px" })
				.html('<div  style="width: 100%; height:0%; background-color:#ffffff; position: absolute; top:10.0%; font-size:16px; font-family:Roboto; color:#0f528c;">' + widget.chartTitle + '</div>')

			chartElement.append("label").style({ "position": "absolute", "left": "0%", "top": "45%", "width": "100%", "font-family": "Roboto", "font-size": "30px", "color": "#f16464", "text-align": "center" })
				.text("No records!")
		}
		else {
			let xAxisFormatted = "";
			if (widget.yAxis.charAt(0) == "[" && widget.yAxis.charAt(widget.yAxis.length - 1) == "]") {
				xAxisFormatted = JSON.parse(widget.yAxis)[0]
			}
			else { xAxisFormatted = widget.yAxis }

			let yAxisFormatted = "";
			if (widget.xAxis.charAt(0) == "[" && widget.xAxis.charAt(widget.xAxis.length - 1) == "]") {
				yAxisFormatted = JSON.parse(widget.xAxis)[0]
			}
			else { yAxisFormatted = widget.xAxis }

			let options = {
				data: widgetdetails.body.records, // for chart
				dataForTable: [], // for table if there is no data send empty array ([])
				isDataDetailedForTable: false,
				keys: {
					label: xAxisFormatted,
					value: yAxisFormatted
				},
				chartTitle: widget.chartTitle,
				showTitle: true,
				showLegend: true,
				legendSide: "right", //"right" or "left"
				colorSet: "one", //"one" or "two" or "three"
				gradient: "none", //"none" or "smooth" or "elliptical" or "circular" or "radial"
				drillDownColumnName: widget.drillThru[0]?.drillDownColumn ? widget.drillThru[0].drillDownColumn : "",
				showTableIcon: true, //false - hide, true - show
				showTable: false, //false - hide, true - show
				showComments: false,
				comments: widget.comments,
				resizeButtonID: document.getElementById("resizeBtn").id
			}
			setTimeout(() => { acinfotech.donutChart(chartElement, options) }, 1000)
		}
	}
	horizontalChart(chartElement, widget, widgetdetails) {
		let fetchedData = widgetdetails.body.records == null ? "No records!" : (widgetdetails.body.records.length > 0 ? widgetdetails.body.records : "No records!")

		if (fetchedData == "No records!") {
			chartElement.append("div").style({ "position": "absolute", "left": "0%", "top": "0%", "width": "100%", "height": "8%", "background-color": "white", "text-indent": "6px" })
				.html('<div  style="width: 100%; height:0%; background-color:#ffffff; position: absolute; top:10.0%; font-size:16px; font-family:Roboto; color:#0f528c;">' + widget.chartTitle + '</div>')

			chartElement.append("label").style({ "position": "absolute", "left": "0%", "top": "45%", "width": "100%", "font-family": "Roboto", "font-size": "30px", "color": "#f16464", "text-align": "center" })
				.text("No records!")
		}
		else {
			let xAxisFormatted = "";
			if (widget.yAxis.charAt(0) == "[" && widget.yAxis.charAt(widget.yAxis.length - 1) == "]") {
				xAxisFormatted = JSON.parse(widget.yAxis)[0]
			}
			else { xAxisFormatted = widget.yAxis }

			let yAxisFormatted = "";
			if (widget.xAxis.charAt(0) == "[" && widget.xAxis.charAt(widget.xAxis.length - 1) == "]") {
				yAxisFormatted = JSON.parse(widget.xAxis)[0]
			}
			else { yAxisFormatted = widget.xAxis }

			let options = {
				data: widgetdetails.body.records,
				dataForTable: [],
				isDataDetailedForTable: false,
				keys: {
					label: xAxisFormatted,
					value: yAxisFormatted
				},
				chartTitle: widget.chartTitle,
				xAxisLable: yAxisFormatted,
				yAxisLable: xAxisFormatted,
				showTitle: true,
				colorSet: "one", //"one" or "two" or "three"
				drillDownColumnName: widget.drillThru[0]?.drillDownColumn ? widget.drillThru[0].drillDownColumn : "",
				showTable: false,
				showComments: false,
				comments: widget.comments,
				resizeButtonID: document.getElementById("resizeBtn").id
			}
			setTimeout(() => { acinfotech.horizontalChart(chartElement, options) }, 1000)
		}
	}
	//waffleChart(chartElement,widget,widgetdetails){}
	perGauge(chartElement, widget, widgetdetails) {
		let fetchedData = widgetdetails.body.records == null ? widget.chartPlot : (widgetdetails.body.records.length > 0 ? widgetdetails.body.records[0][widget.yAxis] : "No records!")

		if (fetchedData == "No records!") {
			chartElement.append("div").style({ "position": "absolute", "left": "0%", "top": "0%", "width": "100%", "height": "8%", "background-color": "white", "text-indent": "6px" })
				.html('<div  style="width: 100%; height:0%; background-color:#ffffff; position: absolute; top:10.0%; font-size:16px; font-family:Roboto; color:#0f528c;">' + widget.chartTitle + '</div>')

			chartElement.append("label").style({ "position": "absolute", "left": "0%", "top": "45%", "width": "100%", "font-family": "Roboto", "font-size": "30px", "color": "#f16464", "text-align": "center" })
				.text("No records!")
		}
		else {
			let options = {
				data: fetchedData,
				chartTitle: widget.chartTitle,
				showTitle: true,
				resizeButtonID: document.getElementById("resizeBtn").id
			}
			setTimeout(() => { acinfotech.perGauge(chartElement, options) }, 1000)
		}
	}
	numberGauge(chartElement, widget, widgetdetails) {
		let fetchedData = widgetdetails.body.records == null ? widget.chartPlot : (widgetdetails.body.records.length > 0 ? widgetdetails.body.records[0][widget.yAxis] : "No records!")

		if (fetchedData == "No records!") {
			chartElement.append("div").style({ "position": "absolute", "left": "0%", "top": "0%", "width": "100%", "height": "8%", "background-color": "white", "text-indent": "6px" })
				.html('<div  style="width: 100%; height:0%; background-color:#ffffff; position: absolute; top:10.0%; font-size:16px; font-family:Roboto; color:#0f528c;">' + widget.chartTitle + '</div>')

			chartElement.append("label").style({ "position": "absolute", "left": "0%", "top": "45%", "width": "100%", "font-family": "Roboto", "font-size": "30px", "color": "#f16464", "text-align": "center" })
				.text("No records!")
		}
		else {
			let options = {
				data: fetchedData,
				chartTitle: widget.chartTitle,
				showTitle: true,
				resizeButtonID: document.getElementById("resizeBtn").id
			}
			setTimeout(() => { acinfotech.numberGauge(chartElement, options) }, 1000)
		}
	}
	map(chartElement, widget, widgetdetails) {
		let fetchedData = widgetdetails.body.records == null ? "No records!" : (widgetdetails.body.records.length > 0 ? widgetdetails.body.records : "No records!")

		if (fetchedData == "No records!") {
			chartElement.append("div").style({ "position": "absolute", "left": "0%", "top": "0%", "width": "100%", "height": "8%", "background-color": "white", "text-indent": "6px" })
				.html('<div  style="width: 100%; height:0%; background-color:#ffffff; position: absolute; top:10.0%; font-size:16px; font-family:Roboto; color:#0f528c;">' + widget.chartTitle + '</div>')

			chartElement.append("label").style({ "position": "absolute", "left": "0%", "top": "45%", "width": "100%", "font-family": "Roboto", "font-size": "30px", "color": "#f16464", "text-align": "center" })
				.text("No records!")
		}
		else {
			let xAxisFormatted = "";
			if (widget.yAxis.charAt(0) == "[" && widget.yAxis.charAt(widget.yAxis.length - 1) == "]") {
				xAxisFormatted = JSON.parse(widget.yAxis)[0]
			}
			else { xAxisFormatted = widget.yAxis }

			let yAxisFormatted = "";
			if (widget.xAxis.charAt(0) == "[" && widget.xAxis.charAt(widget.xAxis.length - 1) == "]") {
				yAxisFormatted = JSON.parse(widget.xAxis)[0]
			}
			else { yAxisFormatted = widget.xAxis }

			let options = {
				data: widgetdetails.body.records,
				keys: {
					label: xAxisFormatted,
					value: yAxisFormatted
				},
				chartTitle: widget.chartTitle,
				showTitle: true,
				drillDownColumnName: widget.drillThru[0]?.drillDownColumn ? widget.drillThru[0].drillDownColumn : "",
				resizeButtonID: document.getElementById("resizeBtn").id
			}
			setTimeout(() => { acinfotech.map(chartElement, options) }, 1000)
		}
	}
	columnAndLineChart(chartElement, widget, widgetdetails) {
		let fetchedData = widgetdetails.body.records == null ? "No records!" : (widgetdetails.body.records.length > 0 ? widgetdetails.body.records : "No records!")

		if (fetchedData == "No records!") {
			chartElement.append("div").style({ "position": "absolute", "left": "0%", "top": "0%", "width": "100%", "height": "8%", "background-color": "white", "text-indent": "6px" })
				.html('<div  style="width: 100%; height:0%; background-color:#ffffff; position: absolute; top:10.0%; font-size:16px; font-family:Roboto; color:#0f528c;">' + widget.chartTitle + '</div>')

			chartElement.append("label").style({ "position": "absolute", "left": "0%", "top": "45%", "width": "100%", "font-family": "Roboto", "font-size": "30px", "color": "#f16464", "text-align": "center" })
				.text("No records!")
		}
		else {
			let xAxis = "";
			if (widget.yAxis.charAt(0) == "[" && widget.yAxis.charAt(widget.yAxis.length - 1) == "]") {
				xAxis = JSON.parse(widget.yAxis)[0]
			}
			else { xAxis = widget.yAxis }

			let options = {
				data: widgetdetails.body.records,
				dataForTable: [],
				isDataDetailedForTable: false,
				keys: {
					label: xAxis,
					valueForColumn: JSON.parse(widget.xAxis)[0], // left axis
					valueForLine: JSON.parse(widget.xAxis)[1] // right axis
				},
				chartTitle: widget.chartTitle,
				xAxisLable: xAxis,
				yLeftAxisLable: JSON.parse(widget.xAxis)[0],
				yRightAxisLable: JSON.parse(widget.xAxis)[1],
				showTitle: true,
				colorSet: "one", //"one" or "two" or "three";
				showTable: false,
				showComments: false,
				comments: widget.comments,
				drillDownColumnName: widget.drillThru[0]?.drillDownColumn ? widget.drillThru[0].drillDownColumn : "",
				resizeButtonID: document.getElementById("resizeBtn").id
			}
			setTimeout(() => { acinfotech.columnAndLineChart(chartElement, options) }, 1000)
		}
	}
	lineChart(chartElement, widget, widgetdetails) {
		let fetchedData = widgetdetails.body.records == null ? "No records!" : (widgetdetails.body.records.length > 0 ? widgetdetails.body.records : "No records!")

		if (fetchedData == "No records!") {
			chartElement.append("div").style({ "position": "absolute", "left": "0%", "top": "0%", "width": "100%", "height": "8%", "background-color": "white", "text-indent": "6px" })
				.html('<div  style="width: 100%; height:0%; background-color:#ffffff; position: absolute; top:10.0%; font-size:16px; font-family:Roboto; color:#0f528c;">' + widget.chartTitle + '</div>')

			chartElement.append("label").style({ "position": "absolute", "left": "0%", "top": "45%", "width": "100%", "font-family": "Roboto", "font-size": "30px", "color": "#f16464", "text-align": "center" })
				.text("No records!")
		}
		else {
			let xAxis = "", xAxisArr = [];
			if (widget.yAxis.charAt(0) == "[" && widget.yAxis.charAt(widget.yAxis.length - 1) == "]") {
				xAxis = JSON.parse(widget.yAxis)
				if (xAxis.length > 1) { xAxisArr = xAxis as any }
				else { xAxis = JSON.parse(widget.yAxis)[0] }
			}
			else { xAxis = widget.yAxis }

			if (xAxisArr.length > 0) {
				let yAxis = "";
				if (widget.xAxis.charAt(0) == "[" && widget.xAxis.charAt(widget.xAxis.length - 1) == "]") {
					yAxis = JSON.parse(widget.xAxis)[0];
				}
				else {
					yAxis = widget.xAxis
				}
				let options = {
					data: widgetdetails.body.records,
					dataForTable: [],
					isDataDetailedForTable: false,
					keys: {
						label: xAxisArr, // X-Axis labels
						lines: yAxis
					},
					chartTitle: widget.chartTitle,
					xAxisLable: yAxis,
					yAxisLable: "",
					showTitle: true,
					colorSet: "one", //"one" or "two" or "three";
					lineStyle: "cardinal", // "cardinal" or "linear" or "step"
					drillDownColumnName: widget.drillThru[0]?.drillDownColumn ? widget.drillThru[0].drillDownColumn : "",
					showTable: false,
					showComments: false,
					comments: widget.comments,
					resizeButtonID: document.getElementById("resizeBtn").id
				}

				setTimeout(() => { acinfotech.lineChart2(chartElement, options) }, 1000)
			}
			else {
				let options = {
					data: widgetdetails.body.records,
					dataForTable: [],
					isDataDetailedForTable: false,
					keys: {
						label: xAxis, // X-Axis labels
						lines: JSON.parse(widget.xAxis)
					},
					chartTitle: widget.chartTitle,
					xAxisLable: xAxis,
					yAxisLable: "",
					showTitle: true,
					colorSet: "one", //"one" or "two" or "three";
					lineStyle: "cardinal", // "cardinal" or "linear" or "step"
					drillDownColumnName: widget.drillThru[0]?.drillDownColumn ? widget.drillThru[0].drillDownColumn : "",
					showTable: false,
					showComments: false,
					comments: widget.comments,
					resizeButtonID: document.getElementById("resizeBtn").id
				}

				setTimeout(() => { acinfotech.lineChart(chartElement, options) }, 1000)
			}
		}
	}
	bubbleChart(chartElement, widget, widgetdetails) {
		let fetchedData = widgetdetails.body.records == null ? "No records!" : (widgetdetails.body.records.length > 0 ? widgetdetails.body.records : "No records!")

		if (fetchedData == "No records!") {
			chartElement.append("div").style({ "position": "absolute", "left": "0%", "top": "0%", "width": "100%", "height": "8%", "background-color": "white", "text-indent": "6px" })
				.html('<div  style="width: 100%; height:0%; background-color:#ffffff; position: absolute; top:10.0%; font-size:16px; font-family:Roboto; color:#0f528c;">' + widget.chartTitle + '</div>')

			chartElement.append("label").style({ "position": "absolute", "left": "0%", "top": "45%", "width": "100%", "font-family": "Roboto", "font-size": "30px", "color": "#f16464", "text-align": "center" })
				.text("No records!")
		}
		else {
			let xAxisFormatted = "";
			if (widget.yAxis.charAt(0) == "[" && widget.yAxis.charAt(widget.yAxis.length - 1) == "]") {
				xAxisFormatted = JSON.parse(widget.yAxis)[0]
			}
			else { xAxisFormatted = widget.yAxis }

			let yAxisFormatted = "";
			if (widget.xAxis.charAt(0) == "[" && widget.xAxis.charAt(widget.xAxis.length - 1) == "]") {
				yAxisFormatted = JSON.parse(widget.xAxis)[0]
			}
			else { yAxisFormatted = widget.xAxis }

			let options = {
				data: widgetdetails.body.records,
				dataForTable: [],
				isDataDetailedForTable: false,
				keys: {
					label: xAxisFormatted,
					value: yAxisFormatted
				},
				chartTitle: widget.chartTitle,
				showTitle: true,
				colorSet: "one", //"one" or "two" or "three";
				drillDownColumnName: widget.drillThru[0]?.drillDownColumn ? widget.drillThru[0].drillDownColumn : "",
				showTable: false,
				showComments: false,
				comments: widget.comments,
				resizeButtonID: document.getElementById("resizeBtn").id
			}

			setTimeout(() => { acinfotech.bubbleChart(chartElement, options) }, 1000)
		}
	}
	stackedHorizontalBarChart(chartElement, widget, widgetdetails) {
		let fetchedData = widgetdetails.body.records == null ? "No records!" : (widgetdetails.body.records.length > 0 ? widgetdetails.body.records : "No records!")

		if (fetchedData == "No records!") {
			chartElement.append("div").style({ "position": "absolute", "left": "0%", "top": "0%", "width": "100%", "height": "8%", "background-color": "white", "text-indent": "6px" })
				.html('<div  style="width: 100%; height:0%; background-color:#ffffff; position: absolute; top:10.0%; font-size:16px; font-family:Roboto; color:#0f528c;">' + widget.chartTitle + '</div>')

			chartElement.append("label").style({ "position": "absolute", "left": "0%", "top": "45%", "width": "100%", "font-family": "Roboto", "font-size": "30px", "color": "#f16464", "text-align": "center" })
				.text("No records!")
		}
		else {
			let xAxis = "", xAxisArr = [];
			if (widget.yAxis.charAt(0) == "[" && widget.yAxis.charAt(widget.yAxis.length - 1) == "]") {
				xAxis = JSON.parse(widget.yAxis)
				if (xAxis.length > 1) { xAxisArr = xAxis as any }
				else { xAxis = JSON.parse(widget.yAxis)[0] }
			}
			else { xAxis = widget.yAxis }

			if (xAxisArr.length > 0) {
				let yAxis = "";
				if (widget.xAxis.charAt(0) == "[" && widget.xAxis.charAt(widget.xAxis.length - 1) == "]") {
					yAxis = JSON.parse(widget.xAxis)[0];
				}
				else {
					yAxis = widget.xAxis
				}
				let options = {
					data: widgetdetails.body.records,
					dataForDrillDown: [],
					dataForTable: [],
					isDataDetailedForTable: false,
					keys: {
						label: xAxisArr, // X-Axis labels
						stackedBars: yAxis
					},
					chartTitle: widget.chartTitle,
					xAxisLable: "",
					yAxisLable: yAxis,
					showTitle: true,
					colorSet: "one", //"one" or "two" or "three";
					lineStyle: "cardinal", // "cardinal" or "linear" or "step"
					drillDownColumnName: widget.drillThru[0]?.drillDownColumn ? widget.drillThru[0].drillDownColumn : "",
					showTable: false,
					showComments: false,
					comments: widget.comments,
					resizeButtonID: document.getElementById("resizeBtn").id
				}

				setTimeout(() => { acinfotech.stackedHorizontalBarChart2(chartElement, options) }, 1000)
			}
			else {
				let options = {
					data: widgetdetails.body.records,
					dataForDrillDown: [],
					dataForTable: [],
					isDataDetailedForTable: false,
					keys: {
						label: xAxis, // X-Axis labels
						stackedBars: JSON.parse(widget.xAxis)
					},
					chartTitle: widget.chartTitle,
					xAxisLable: "",
					yAxisLable: xAxis,
					showTitle: true,
					colorSet: "one", //"one" or "two" or "three";
					lineStyle: "cardinal", // "cardinal" or "linear" or "step"
					drillDownColumnName: widget.drillThru[0]?.drillDownColumn ? widget.drillThru[0].drillDownColumn : "",
					showTable: false,
					showComments: false,
					comments: widget.comments,
					resizeButtonID: document.getElementById("resizeBtn").id
				}

				setTimeout(() => { acinfotech.stackedHorizontalBarChart(chartElement, options) }, 1000)
			}
		}
	}
	stackedBarChart(chartElement, widget, widgetdetails) {
		let fetchedData = widgetdetails.body.records == null ? "No records!" : (widgetdetails.body.records.length > 0 ? widgetdetails.body.records : "No records!")

		if (fetchedData == "No records!") {
			chartElement.append("div").style({ "position": "absolute", "left": "0%", "top": "0%", "width": "100%", "height": "8%", "background-color": "white", "text-indent": "6px" })
				.html('<div  style="width: 100%; height:0%; background-color:#ffffff; position: absolute; top:10.0%; font-size:16px; font-family:Roboto; color:#0f528c;">' + widget.chartTitle + '</div>')

			chartElement.append("label").style({ "position": "absolute", "left": "0%", "top": "45%", "width": "100%", "font-family": "Roboto", "font-size": "30px", "color": "#f16464", "text-align": "center" })
				.text("No records!")
		}
		else {
			let xAxis = "", xAxisArr = [];
			if (widget.yAxis.charAt(0) == "[" && widget.yAxis.charAt(widget.yAxis.length - 1) == "]") {
				xAxis = JSON.parse(widget.yAxis)
				if (xAxis.length > 1) { xAxisArr = xAxis as any }
				else { xAxis = JSON.parse(widget.yAxis)[0] }
			}
			else { xAxis = widget.yAxis }

			if (xAxisArr.length > 0) {
				let yAxis = "";
				if (widget.xAxis.charAt(0) == "[" && widget.xAxis.charAt(widget.xAxis.length - 1) == "]") {
					yAxis = JSON.parse(widget.xAxis)[0];
				}
				else {
					yAxis = widget.xAxis
				}
				let options = {
					data: widgetdetails.body.records,
					dataForDrillDown: [],
					dataForTable: [],
					isDataDetailedForTable: false,
					keys: {
						label: xAxisArr, // X-Axis labels
						stackedBars: yAxis
					},
					chartTitle: widget.chartTitle,
					xAxisLable: yAxis,
					yAxisLable: "",
					showTitle: true,
					colorSet: "one", //"one" or "two" or "three";
					lineStyle: "cardinal", // "cardinal" or "linear" or "step"
					drillDownColumnName: widget.drillThru[0]?.drillDownColumn ? widget.drillThru[0].drillDownColumn : "",
					showTable: false,
					showComments: false,
					comments: widget.comments,
					resizeButtonID: document.getElementById("resizeBtn").id
				}

				setTimeout(() => { acinfotech.stackedBarChart2(chartElement, options) }, 1000)
			}
			else {
				let options = {
					data: widgetdetails.body.records,
					dataForDrillDown: [],
					dataForTable: [],
					isDataDetailedForTable: false,
					keys: {
						label: xAxis, // X-Axis labels
						stackedBars: JSON.parse(widget.xAxis)
					},
					chartTitle: widget.chartTitle,
					xAxisLable: xAxis,
					yAxisLable: "",
					showTitle: true,
					colorSet: "one", //"one" or "two" or "three";
					lineStyle: "cardinal", // "cardinal" or "linear" or "step"
					drillDownColumnName: widget.drillThru[0]?.drillDownColumn ? widget.drillThru[0].drillDownColumn : "",
					showTable: false,
					showComments: false,
					comments: widget.comments,
					resizeButtonID: document.getElementById("resizeBtn").id
				}

				setTimeout(() => { acinfotech.stackedBarChart(chartElement, options) }, 1000)
			}
		}
	}
	perGaugeWithFlip(chartElement, widget, widgetdetails) {
		let fetchedData = widgetdetails.body.records == null ? widget.chartPlot : (widgetdetails.body.records.length > 0 ? widgetdetails.body.records[0][widget.yAxis] : "No records!")

		if (fetchedData == "No records!") {
			chartElement.append("div").style({ "position": "absolute", "left": "0%", "top": "0%", "width": "100%", "height": "8%", "background-color": "white", "text-indent": "6px" })
				.html('<div  style="width: 100%; height:0%; background-color:#ffffff; position: absolute; top:10.0%; font-size:16px; font-family:Roboto; color:#0f528c;">' + widget.chartTitle + '</div>')

			chartElement.append("label").style({ "position": "absolute", "left": "0%", "top": "45%", "width": "100%", "font-family": "Roboto", "font-size": "30px", "color": "#f16464", "text-align": "center" })
				.text("No records!")
		}
		else {
			let options = {
				data: widgetdetails.body.records == null ? widget.chartPlot : widgetdetails.body.records[0][widget.yAxis],
				chartTitle: widget.chartTitle,
				coveredCircularAreaText: "",
				unCoveredCircularAreaText: "",
				showTitle: true,
				resizeButtonID: document.getElementById("resizeBtn").id
			}

			setTimeout(() => { acinfotech.perGaugeWithFlip(chartElement, options) }, 1000)
		}
	}
	squareGauge(chartElement, widget, widgetdetails) {
		let fetchedData = widgetdetails.body.records == null ? widget.chartPlot : (widgetdetails.body.records.length > 0 ? widgetdetails.body.records[0][widget.yAxis] : "No records!")

		if (fetchedData == "No records!") {
			chartElement.append("div").style({ "position": "absolute", "left": "0%", "top": "0%", "width": "100%", "height": "8%", "background-color": "white", "text-indent": "6px" })
				.html('<div  style="width: 100%; height:0%; background-color:#ffffff; position: absolute; top:10.0%; font-size:16px; font-family:Roboto; color:#0f528c;">' + widget.chartTitle + '</div>')

			chartElement.append("label").style({ "position": "absolute", "left": "0%", "top": "45%", "width": "100%", "font-family": "Roboto", "font-size": "30px", "color": "#f16464", "text-align": "center" })
				.text("No records!")
		}
		else {
			let options = {
				text: widgetdetails.body.records == null ? widget.chartPlot : widgetdetails.body.records[0][widget.yAxis],
				widgetTitle: widget.chartTitle,
				showTitle: true,
				resizeButtonID: document.getElementById("resizeBtn").id
			}

			setTimeout(() => { acinfotech.squareGauge(chartElement, options) }, 1000)
		}
	}
	bellCurveChart(chartElement, widget, widgetdetails) {
		let fetchedData = widgetdetails.body.records == null ? "No records!" : (widgetdetails.body.records.length > 0 ? widgetdetails.body.records : "No records!")

		if (fetchedData == "No records!") {
			chartElement.append("div").style({ "position": "absolute", "left": "0%", "top": "0%", "width": "100%", "height": "8%", "background-color": "white", "text-indent": "6px" })
				.html('<div  style="width: 100%; height:0%; background-color:#ffffff; position: absolute; top:10.0%; font-size:16px; font-family:Roboto; color:#0f528c;">' + widget.chartTitle + '</div>')

			chartElement.append("label").style({ "position": "absolute", "left": "0%", "top": "45%", "width": "100%", "font-family": "Roboto", "font-size": "30px", "color": "#f16464", "text-align": "center" })
				.text("No records!")
		}
		else {
			let xAxisFormatted = "";
			if (widget.yAxis.charAt(0) == "[" && widget.yAxis.charAt(widget.yAxis.length - 1) == "]") {
				xAxisFormatted = JSON.parse(widget.yAxis)[0]
			}
			else { xAxisFormatted = widget.yAxis }

			let yAxisFormatted = "";
			if (widget.xAxis.charAt(0) == "[" && widget.xAxis.charAt(widget.xAxis.length - 1) == "]") {
				yAxisFormatted = JSON.parse(widget.xAxis)[0]
			}
			else { yAxisFormatted = widget.xAxis }

			let options = {
				data: widgetdetails.body.records,
				keys: {
					label: xAxisFormatted,
					value: yAxisFormatted,
					toolTipLabel: (widget.chartPlot.split(",") > 0) ? widget.chartPlot.split(",") : []
				},
				chartTitle: widget.chartTitle,
				showTitle: true,
				colorSet: "one", //"one" or "two" or "three";
				resizeButtonID: document.getElementById("resizeBtn").id
			}
			setTimeout(() => { acinfotech.bellCurveChart(chartElement, options) }, 1000)
		}
	}
	areaChart(chartElement, widget, widgetdetails) {
		let fetchedData = widgetdetails.body.records == null ? "No records!" : (widgetdetails.body.records.length > 0 ? widgetdetails.body.records : "No records!")

		if (fetchedData == "No records!") {
			chartElement.append("div").style({ "position": "absolute", "left": "0%", "top": "0%", "width": "100%", "height": "8%", "background-color": "white", "text-indent": "6px" })
				.html('<div  style="width: 100%; height:0%; background-color:#ffffff; position: absolute; top:10.0%; font-size:16px; font-family:Roboto; color:#0f528c;">' + widget.chartTitle + '</div>')

			chartElement.append("label").style({ "position": "absolute", "left": "0%", "top": "45%", "width": "100%", "font-family": "Roboto", "font-size": "30px", "color": "#f16464", "text-align": "center" })
				.text("No records!")
		}
		else {
			let xAxis = "", xAxisArr = [];
			if (widget.yAxis.charAt(0) == "[" && widget.yAxis.charAt(widget.yAxis.length - 1) == "]") {
				xAxis = JSON.parse(widget.yAxis)
				if (xAxis.length > 1) { xAxisArr = xAxis as any }
				else { xAxis = JSON.parse(widget.yAxis)[0] }
			}
			else { xAxis = widget.yAxis }

			if (xAxisArr.length > 0) {
				let yAxis = "";
				if (widget.xAxis.charAt(0) == "[" && widget.xAxis.charAt(widget.xAxis.length - 1) == "]") {
					yAxis = JSON.parse(widget.xAxis)[0];
				}
				else {
					yAxis = widget.xAxis
				}

				let options = {
					data: widgetdetails.body.records,
					dataForTable: [],
					isDataDetailedForTable: false,
					keys: {
						label: xAxisArr, // X-Axis labels
						areas: yAxis
					},
					chartTitle: widget.chartTitle,
					xAxisLable: yAxis,
					yAxisLable: "",
					showTitle: true,
					colorSet: "one", //"one" or "two" or "three";
					lineStyle: "cardinal", // "cardinal" or "linear" or "step"
					drillDownColumnName: widget.drillThru[0]?.drillDownColumn ? widget.drillThru[0].drillDownColumn : "",
					showTable: false,
					showComments: false,
					comments: widget.comments,
					resizeButtonID: document.getElementById("resizeBtn").id
				}

				setTimeout(() => { acinfotech.areaChart2(chartElement, options) }, 1000)
			}
			else {
				let options = {
					data: widgetdetails.body.records,
					dataForTable: [],
					isDataDetailedForTable: false,
					keys: {
						label: xAxis, // X-Axis labels
						areas: JSON.parse(widget.xAxis)
					},
					chartTitle: widget.chartTitle,
					xAxisLable: xAxis,
					yAxisLable: "",
					showTitle: true,
					colorSet: "one", //"one" or "two" or "three";
					lineStyle: "cardinal", // "cardinal" or "linear" or "step"
					drillDownColumnName: widget.drillThru[0]?.drillDownColumn ? widget.drillThru[0].drillDownColumn : "",
					showTable: false,
					showComments: false,
					comments: widget.comments,
					resizeButtonID: document.getElementById("resizeBtn").id
				}

				setTimeout(() => { acinfotech.areaChart(chartElement, options) }, 1000)
			}
		}
	}
	groupedBarChart(chartElement, widget, widgetdetails) {
		let fetchedData = widgetdetails.body.records == null ? "No records!" : (widgetdetails.body.records.length > 0 ? widgetdetails.body.records : "No records!")

		if (fetchedData == "No records!") {
			chartElement.append("div").style({ "position": "absolute", "left": "0%", "top": "0%", "width": "100%", "height": "8%", "background-color": "white", "text-indent": "6px" })
				.html('<div  style="width: 100%; height:0%; background-color:#ffffff; position: absolute; top:10.0%; font-size:16px; font-family:Roboto; color:#0f528c;">' + widget.chartTitle + '</div>')

			chartElement.append("label").style({ "position": "absolute", "left": "0%", "top": "45%", "width": "100%", "font-family": "Roboto", "font-size": "30px", "color": "#f16464", "text-align": "center" })
				.text("No records!")
		}
		else {
			let xAxisFormatted = "";
			if (widget.yAxis.charAt(0) == "[" && widget.yAxis.charAt(widget.yAxis.length - 1) == "]") {
				xAxisFormatted = JSON.parse(widget.yAxis)[0]
			}
			else { xAxisFormatted = widget.yAxis }

			let options = {
				data: widgetdetails.body.records,
				dataForDrillDown: [],
				dataForTable: [],
				isDataDetailedForTable: false,
				keys: {
					label: xAxisFormatted,
					groupBars: JSON.parse(widget.xAxis),
				},
				chartTitle: widget.chartTitle,
				xAxisLable: xAxisFormatted,
				yAxisLable: "",
				showTitle: true,
				colorSet: "one", //"one" or "two" or "three";
				drillDownColumnName: widget.drillThru[0]?.drillDownColumn ? widget.drillThru[0].drillDownColumn : "",
				showTable: false,
				showComments: false,
				comments: widget.comments,
				resizeButtonID: document.getElementById("resizeBtn").id
			}

			setTimeout(() => { acinfotech.groupedBarChart(chartElement, options) }, 1000)
		}
	}
	horizontalGroupedBarChart(chartElement, widget, widgetdetails) {
		let fetchedData = widgetdetails.body.records == null ? "No records!" : (widgetdetails.body.records.length > 0 ? widgetdetails.body.records : "No records!")

		if (fetchedData == "No records!") {
			chartElement.append("div").style({ "position": "absolute", "left": "0%", "top": "0%", "width": "100%", "height": "8%", "background-color": "white", "text-indent": "6px" })
				.html('<div  style="width: 100%; height:0%; background-color:#ffffff; position: absolute; top:10.0%; font-size:16px; font-family:Roboto; color:#0f528c;">' + widget.chartTitle + '</div>')

			chartElement.append("label").style({ "position": "absolute", "left": "0%", "top": "45%", "width": "100%", "font-family": "Roboto", "font-size": "30px", "color": "#f16464", "text-align": "center" })
				.text("No records!")
		}
		else {
			let xAxisFormatted = "";
			if (widget.yAxis.charAt(0) == "[" && widget.yAxis.charAt(widget.yAxis.length - 1) == "]") {
				xAxisFormatted = JSON.parse(widget.yAxis)[0]
			}
			else { xAxisFormatted = widget.yAxis }

			let options = {
				data: widgetdetails.body.records,
				dataForDrillDown: [],
				dataForTable: [],
				isDataDetailedForTable: false,
				keys: {
					label: xAxisFormatted,
					groupBars: JSON.parse(widget.xAxis),
				},
				chartTitle: widget.chartTitle,
				xAxisLable: "",
				yAxisLable: xAxisFormatted,
				showTitle: true,
				colorSet: "one", //"one" or "two" or "three";
				drillDownColumnName: widget.drillThru[0]?.drillDownColumn ? widget.drillThru[0].drillDownColumn : "",
				showTable: false,
				showComments: false,
				comments: widget.comments,
				resizeButtonID: document.getElementById("resizeBtn").id
			}

			setTimeout(() => { acinfotech.horizontalGroupedBarChart(chartElement, options) }, 1000)
		}
	}
	//horizontalOppositeBarChart(chartElement,widget,widgetdetails){}
	//verticalOppositeBarChart(chartElement,widget,widgetdetails){}
	dotGauge(chartElement, widget, widgetdetails) {
		let options = {
			data: widgetdetails.body.records == null ? widget.chartPlot : widgetdetails.body.records[0][widget.yAxis],
			chartTitle: widget.chartTitle,
			showTitle: true,
			resizeButtonID: document.getElementById("resizeBtn").id
		}
		setTimeout(() => { acinfotech.dotGauge(chartElement, options) }, 1000)
	}
	//squareBubbleChart(chartElement,widget,widgetdetails){}
	//magicQuadrant(chartElement,widget,widgetdetails){}
	//funnelChart(chartElement,widget,widgetdetails){}
	columnAndLinesChart(chartElement, widget, widgetdetails) {
		let fetchedData = widgetdetails.body.records == null ? "No records!" : (widgetdetails.body.records.length > 0 ? widgetdetails.body.records : "No records!")

		if (fetchedData == "No records!") {
			chartElement.append("div").style({ "position": "absolute", "left": "0%", "top": "0%", "width": "100%", "height": "8%", "background-color": "white", "text-indent": "6px" })
				.html('<div  style="width: 100%; height:0%; background-color:#ffffff; position: absolute; top:10.0%; font-size:16px; font-family:Roboto; color:#0f528c;">' + widget.chartTitle + '</div>')

			chartElement.append("label").style({ "position": "absolute", "left": "0%", "top": "45%", "width": "100%", "font-family": "Roboto", "font-size": "30px", "color": "#f16464", "text-align": "center" })
				.text("No records!")
		}
		else {
			let xAxisFormatted = "";
			if (widget.yAxis.charAt(0) == "[" && widget.yAxis.charAt(widget.yAxis.length - 1) == "]") {
				xAxisFormatted = JSON.parse(widget.yAxis)[0]
			}
			else { xAxisFormatted = widget.yAxis }

			let options = {
				data: widgetdetails.body.records,
				dataForTable: [],
				isDataDetailedForTable: false,
				keys: {
					label: xAxisFormatted, // X-Axis labels
					valueForColumn: JSON.parse(widget.xAxis)[0], // Single Value - left y axis
					valueForLines: JSON.parse(widget.xAxis).slice(1),  // multiple value (array) - right y axis
					toolTipLabel: (widget.chartPlot.split(",") > 0) ? widget.chartPlot.split(",") : []
				},
				chartTitle: widget.chartTitle,
				xAxisLable: xAxisFormatted,
				//yAxisLable: "",
				showTitle: true,
				colorSet: "one", //"one" or "two" or "three";
				lineStyle: "cardinal", // "cardinal" or "linear" or "step"
				drillDownColumnName: widget.drillThru[0]?.drillDownColumn ? widget.drillThru[0].drillDownColumn : "",
				showTable: false,
				showComments: false,
				comments: widget.comments,
				resizeButtonID: document.getElementById("resizeBtn").id
			}

			setTimeout(() => { acinfotech.columnAndLinesChart(chartElement, options) }, 1000)
		}
	}
	threeXAxisesGroupedBarChart(chartElement, widget, widgetdetails) {
		let fetchedData = widgetdetails.body.records == null ? "No records!" : (widgetdetails.body.records.length > 0 ? widgetdetails.body.records : "No records!")

		if (fetchedData == "No records!") {
			chartElement.append("div").style({ "position": "absolute", "left": "0%", "top": "0%", "width": "100%", "height": "8%", "background-color": "white", "text-indent": "6px" })
				.html('<div  style="width: 100%; height:0%; background-color:#ffffff; position: absolute; top:10.0%; font-size:16px; font-family:Roboto; color:#0f528c;">' + widget.chartTitle + '</div>')

			chartElement.append("label").style({ "position": "absolute", "left": "0%", "top": "45%", "width": "100%", "font-family": "Roboto", "font-size": "30px", "color": "#f16464", "text-align": "center" })
				.text("No records!")
		}
		else {
			let options = {
				data: widgetdetails.body.records,
				keys: {
					labels: JSON.parse(widget.yAxis),
					groupBars: JSON.parse(widget.xAxis),
					toolTipLabel: (widget.chartPlot.split(",") > 0) ? widget.chartPlot.split(",") : []
				},
				chartTitle: widget.chartTitle,
				xAxisLables: JSON.parse(widget.yAxis),
				yAxisLable: "",
				showTitle: true,
				colorSet: "one", //"one" or "two" or "three";
				showComments: false,
				comments: widget.comments,
				resizeButtonID: document.getElementById("resizeBtn").id
			}
			setTimeout(() => { acinfotech.threeXAxisesGroupedBarChart(chartElement, options) }, 1000)
		}
	}
	twoXAxisesGroupedBarChart(chartElement, widget, widgetdetails) {
		let fetchedData = widgetdetails.body.records == null ? "No records!" : (widgetdetails.body.records.length > 0 ? widgetdetails.body.records : "No records!")

		if (fetchedData == "No records!") {
			chartElement.append("div").style({ "position": "absolute", "left": "0%", "top": "0%", "width": "100%", "height": "8%", "background-color": "white", "text-indent": "6px" })
				.html('<div  style="width: 100%; height:0%; background-color:#ffffff; position: absolute; top:10.0%; font-size:16px; font-family:Roboto; color:#0f528c;">' + widget.chartTitle + '</div>')

			chartElement.append("label").style({ "position": "absolute", "left": "0%", "top": "45%", "width": "100%", "font-family": "Roboto", "font-size": "30px", "color": "#f16464", "text-align": "center" })
				.text("No records!")
		}
		else {
			let options = {
				data: widgetdetails.body.records,
				dataForDrillDown: [],
				keys: {
					labels: JSON.parse(widget.yAxis),
					groupBars: JSON.parse(widget.xAxis),
					toolTipLabel: (widget.chartPlot.split(",") > 0) ? widget.chartPlot.split(",") : []

				},
				chartTitle: widget.chartTitle,
				xAxisLables: JSON.parse(widget.yAxis),
				//yAxisLable: "",
				showTitle: true,
				colorSet: "one", //"one" or "two" or "three";
				drillDownColumnName: widget.drillThru[0]?.drillDownColumn ? widget.drillThru[0].drillDownColumn : "",
				showComments: false,
				comments: widget.comments,
				resizeButtonID: document.getElementById("resizeBtn").id

			}
			setTimeout(() => { acinfotech.twoXAxisesGroupedBarChart(chartElement, options) }, 1000)
		}
	}
	bellCurveChartWithMinAndMaxRange(chartElement, widget, widgetdetails) {
		let fetchedData = widgetdetails.body.records == null ? "No records!" : (widgetdetails.body.records.length > 0 ? widgetdetails.body.records : "No records!")

		if (fetchedData == "No records!") {
			chartElement.append("div").style({ "position": "absolute", "left": "0%", "top": "0%", "width": "100%", "height": "8%", "background-color": "white", "text-indent": "6px" })
				.html('<div  style="width: 100%; height:0%; background-color:#ffffff; position: absolute; top:10.0%; font-size:16px; font-family:Roboto; color:#0f528c;">' + widget.chartTitle + '</div>')

			chartElement.append("label").style({ "position": "absolute", "left": "0%", "top": "45%", "width": "100%", "font-family": "Roboto", "font-size": "30px", "color": "#f16464", "text-align": "center" })
				.text("No records!")
		}
		else {
			let options = {
				data: widgetdetails.body.records,
				keys: {
					label: JSON.parse(widget.yAxis[0]),
					value: JSON.parse(widget.xAxis[0]),
					minValue: JSON.parse(widget.xAxis[1]),
					maxValue: JSON.parse(widget.xAxis[2]),
					minAndMaxLines: JSON.parse(widget.yAxis[0]),
					toolTipLabel: (widget.chartPlot.split(",") > 0) ? widget.chartPlot.split(",") : []
				},
				chartTitle: widget.chartTitle,
				showTitle: true,
				colorSet: "one", //"one" or "two" or "three";
				resizeButtonID: document.getElementById("resizeBtn").id
			}

			setTimeout(() => { acinfotech.bellCurveChartWithMinAndMaxRange(chartElement, options) }, 1000)
		}
	}
	stackedGroupedBarChart(chartElement, widget, widgetdetails) {
		let fetchedData = widgetdetails.body.records == null ? "No records!" : (widgetdetails.body.records.length > 0 ? widgetdetails.body.records : "No records!")

		if (fetchedData == "No records!") {
			chartElement.append("div").style({ "position": "absolute", "left": "0%", "top": "0%", "width": "100%", "height": "8%", "background-color": "white", "text-indent": "6px" })
				.html('<div  style="width: 100%; height:0%; background-color:#ffffff; position: absolute; top:10.0%; font-size:16px; font-family:Roboto; color:#0f528c;">' + widget.chartTitle + '</div>')

			chartElement.append("label").style({ "position": "absolute", "left": "0%", "top": "45%", "width": "100%", "font-family": "Roboto", "font-size": "30px", "color": "#f16464", "text-align": "center" })
				.text("No records!")
		}
		else {
			let options = {
				data: widgetdetails.body.records,
				keys: {
					labels: JSON.parse(widget.yAxis),
					groupBars: JSON.parse(widget.xAxis),
					toolTipLabel: (widget.chartPlot.split(",") > 0) ? widget.chartPlot.split(",") : []

				},
				chartTitle: widget.chartTitle,
				xAxisLables: JSON.parse(widget.yAxis),
				//yAxisLable: "",
				showTitle: true,
				colorSet: "one", //"one" or "two" or "three";
				drillDownColumnName: widget.drillThru[0]?.drillDownColumn ? widget.drillThru[0].drillDownColumn : "",
				showTable: false,
				showComments: false,
				comments: widget.comments,
				resizeButtonID: document.getElementById("resizeBtn").id
			}

			setTimeout(() => { acinfotech.stackedGroupedBarChart(chartElement, options) }, 1000)
		}
	}
	stackedGroupedBarsWithLineChart(chartElement, widget, widgetdetails) {
		let fetchedData = widgetdetails.body.records == null ? "No records!" : (widgetdetails.body.records.length > 0 ? widgetdetails.body.records : "No records!")

		if (fetchedData == "No records!") {
			chartElement.append("div").style({ "position": "absolute", "left": "0%", "top": "0%", "width": "100%", "height": "8%", "background-color": "white", "text-indent": "6px" })
				.html('<div  style="width: 100%; height:0%; background-color:#ffffff; position: absolute; top:10.0%; font-size:16px; font-family:Roboto; color:#0f528c;">' + widget.chartTitle + '</div>')

			chartElement.append("label").style({ "position": "absolute", "left": "0%", "top": "45%", "width": "100%", "font-family": "Roboto", "font-size": "30px", "color": "#f16464", "text-align": "center" })
				.text("No records!")
		}
		else {
			let options = {
				data: widgetdetails.body.records,
				keys: {
					labels: JSON.parse(widget.yAxis),
					groupBars: JSON.parse(widget.xAxis),
					lines: JSON.parse(widget.secondaryYaxis),
					toolTipLabel: (widget.chartPlot.split(",") > 0) ? widget.chartPlot.split(",") : []

				},
				chartTitle: widget.chartTitle,
				xAxisLables: JSON.parse(widget.yAxis),
				//yAxisLable: "",
				showTitle: true,
				colorSet: "one", //"one" or "two" or "three";
				drillDownColumnName: widget.drillThru[0]?.drillDownColumn ? widget.drillThru[0].drillDownColumn : "",
				showTable: false,
				showComments: false,
				comments: widget.comments,
				resizeButtonID: document.getElementById("resizeBtn").id
			}

			setTimeout(() => { acinfotech.stackedGroupedBarsWithLineChart(chartElement, options) }, 1000)
		}
	}
	stackedBarWithLinesChart(chartElement, widget, widgetdetails) {
		let fetchedData = widgetdetails.body.records == null ? "No records!" : (widgetdetails.body.records.length > 0 ? widgetdetails.body.records : "No records!")

		if (fetchedData == "No records!") {
			chartElement.append("div").style({ "position": "absolute", "left": "0%", "top": "0%", "width": "100%", "height": "8%", "background-color": "white", "text-indent": "6px" })
				.html('<div  style="width: 100%; height:0%; background-color:#ffffff; position: absolute; top:10.0%; font-size:16px; font-family:Roboto; color:#0f528c;">' + widget.chartTitle + '</div>')

			chartElement.append("label").style({ "position": "absolute", "left": "0%", "top": "45%", "width": "100%", "font-family": "Roboto", "font-size": "30px", "color": "#f16464", "text-align": "center" })
				.text("No records!")
		}
		else {
			let xAxisFormatted = "";
			if (widget.yAxis.charAt(0) == "[" && widget.yAxis.charAt(widget.yAxis.length - 1) == "]") {
				xAxisFormatted = JSON.parse(widget.yAxis)[0]
			}
			else { xAxisFormatted = widget.yAxis }

			let options = {
				data: widgetdetails.body.records,
				dataForTable: [],
				isDataDetailedForTable: false,
				keys: {
					label: xAxisFormatted,
					stackedBars: JSON.parse(widget.xAxis),
					lines: JSON.parse(widget.secondaryYaxis),
					toolTipLabel: (widget.chartPlot.split(",") > 0) ? widget.chartPlot.split(",") : []
				},
				chartTitle: widget.chartTitle,
				xAxisLable: xAxisFormatted,
				yAxisLable: "",
				showTitle: true,
				colorSet: "one", //"one" or "two" or "three";
				drillDownColumnName: widget.drillThru[0]?.drillDownColumn ? widget.drillThru[0].drillDownColumn : "",
				showTable: false,
				showComments: false,
				comments: widget.comments,
				resizeButtonID: document.getElementById("resizeBtn").id
			}

			setTimeout(() => { acinfotech.stackedBarWithLinesChart(chartElement, options) }, 1000)
		}
	}
	trafficLightsChart(chartElement, widget, widgetdetails) {
		let fetchedData = widgetdetails.body.records == null ? "No records!" : (widgetdetails.body.records.length > 0 ? widgetdetails.body.records : "No records!")

		if (fetchedData == "No records!") {
			chartElement.append("div").style({ "position": "absolute", "left": "0%", "top": "0%", "width": "100%", "height": "8%", "background-color": "white", "text-indent": "6px" })
				.html('<div  style="width: 100%; height:0%; background-color:#ffffff; position: absolute; top:10.0%; font-size:16px; font-family:Roboto; color:#0f528c;">' + widget.chartTitle + '</div>')

			chartElement.append("label").style({ "position": "absolute", "left": "0%", "top": "45%", "width": "100%", "font-family": "Roboto", "font-size": "30px", "color": "#f16464", "text-align": "center" })
				.text("No records!")
		}
		else {
			let xAxisFormatted = "";
			if (widget.yAxis.charAt(0) == "[" && widget.yAxis.charAt(widget.yAxis.length - 1) == "]") {
				xAxisFormatted = JSON.parse(widget.yAxis)[0]
			}
			else { xAxisFormatted = widget.yAxis }

			let yAxisFormatted = "";
			if (widget.xAxis.charAt(0) == "[" && widget.xAxis.charAt(widget.xAxis.length - 1) == "]") {
				yAxisFormatted = JSON.parse(widget.xAxis)[0]
			}
			else { yAxisFormatted = widget.xAxis }

			let options = {
				data: widgetdetails.body.records,
				dataForTable: [],
				isDataDetailedForTable: false,
				keys: {
					label: xAxisFormatted,
					value: yAxisFormatted,
					toolTipLabel: (widget.chartPlot.split(",") > 0) ? widget.chartPlot.split(",") : []
				},
				chartTitle: widget.chartTitle,
				showTitle: true,
				drillDownColumnName: widget.drillThru[0]?.drillDownColumn ? widget.drillThru[0].drillDownColumn : "",
				showTable: false,
				showComments: false,
				comments: widget.comments,
				resizeButtonID: document.getElementById("resizeBtn").id
			}

			setTimeout(() => { acinfotech.trafficLightsChart(chartElement, options) }, 1000)
		}
	}
	columnChartWithNegativeValues(chartElement, widget, widgetdetails) {
		let fetchedData = widgetdetails.body.records == null ? "No records!" : (widgetdetails.body.records.length > 0 ? widgetdetails.body.records : "No records!")

		if (fetchedData == "No records!") {
			chartElement.append("div").style({ "position": "absolute", "left": "0%", "top": "0%", "width": "100%", "height": "8%", "background-color": "white", "text-indent": "6px" })
				.html('<div  style="width: 100%; height:0%; background-color:#ffffff; position: absolute; top:10.0%; font-size:16px; font-family:Roboto; color:#0f528c;">' + widget.chartTitle + '</div>')

			chartElement.append("label").style({ "position": "absolute", "left": "0%", "top": "45%", "width": "100%", "font-family": "Roboto", "font-size": "30px", "color": "#f16464", "text-align": "center" })
				.text("No records!")
		}
		else {
			let xAxisFormatted = "";
			if (widget.yAxis.charAt(0) == "[" && widget.yAxis.charAt(widget.yAxis.length - 1) == "]") {
				xAxisFormatted = JSON.parse(widget.yAxis)[0]
			}
			else { xAxisFormatted = widget.yAxis }

			let yAxisFormatted = "";
			if (widget.xAxis.charAt(0) == "[" && widget.xAxis.charAt(widget.xAxis.length - 1) == "]") {
				yAxisFormatted = JSON.parse(widget.xAxis)[0]
			}
			else { yAxisFormatted = widget.xAxis }

			let options = {
				data: widgetdetails.body.records,
				dataForTable: [], // for table
				isDataDetailedForTable: false,
				keys: {
					label: xAxisFormatted,
					value: yAxisFormatted
				},
				chartTitle: widget.chartTitle,
				xAxisLable: xAxisFormatted,
				yAxisLable: yAxisFormatted,
				showTitle: true,
				colorSet: "one", //one or two or three
				drillDownColumnName: widget.drillThru[0]?.drillDownColumn ? widget.drillThru[0].drillDownColumn : "",
				showTable: false, //false - hide, true - show
				showComments: false,
				comments: widget.comments,
				resizeButtonID: document.getElementById("resizeBtn").id
			}

			setTimeout(() => { acinfotech.columnChartWithNegativeValues(chartElement, options) }, 1000)
		}
	}
	groupedBarsWithLineChart(chartElement, widget, widgetdetails) {
		let fetchedData = widgetdetails.body.records == null ? "No records!" : (widgetdetails.body.records.length > 0 ? widgetdetails.body.records : "No records!")

		if (fetchedData == "No records!") {
			chartElement.append("div").style({ "position": "absolute", "left": "0%", "top": "0%", "width": "100%", "height": "8%", "background-color": "white", "text-indent": "6px" })
				.html('<div  style="width: 100%; height:0%; background-color:#ffffff; position: absolute; top:10.0%; font-size:16px; font-family:Roboto; color:#0f528c;">' + widget.chartTitle + '</div>')

			chartElement.append("label").style({ "position": "absolute", "left": "0%", "top": "45%", "width": "100%", "font-family": "Roboto", "font-size": "30px", "color": "#f16464", "text-align": "center" })
				.text("No records!")
		}
		else {
			let xAxisFormatted = "";
			if (widget.yAxis.charAt(0) == "[" && widget.yAxis.charAt(widget.yAxis.length - 1) == "]") {
				xAxisFormatted = JSON.parse(widget.yAxis)[0]
			}
			else { xAxisFormatted = widget.yAxis }

			let options = {
				data: widgetdetails.body.records,
				dataForTable: [],
				isDataDetailedForTable: false,
				keys: {
					label: xAxisFormatted,
					groupBars: JSON.parse(widget.xAxis),
					lines: JSON.parse(widget.secondaryYaxis)
				},
				chartTitle: widget.chartTitle,
				xAxisLable: xAxisFormatted,
				yAxisLable: "",
				showTitle: true,
				colorSet: "one", //"one" or "two" or "three";
				drillDownColumnName: widget.drillThru[0]?.drillDownColumn ? widget.drillThru[0].drillDownColumn : "",
				showTable: false,
				showComments: true,
				comments: widget.comments,
				resizeButtonID: document.getElementById("resizeBtn").id
			}

			setTimeout(() => { acinfotech.groupedBarsWithLineChart(chartElement, options) }, 1000)
		}
	}
	USMapWithPins(chartElement, widget, widgetdetails) {
		let fetchedData = widgetdetails.body.records == null ? "No records!" : (widgetdetails.body.records.length > 0 ? widgetdetails.body.records : "No records!")

		if (fetchedData == "No records!") {
			chartElement.append("div").style({ "position": "absolute", "left": "0%", "top": "0%", "width": "100%", "height": "8%", "background-color": "white", "text-indent": "6px" })
				.html('<div  style="width: 100%; height:0%; background-color:#ffffff; position: absolute; top:10.0%; font-size:16px; font-family:Roboto; color:#0f528c;">' + widget.chartTitle + '</div>')

			chartElement.append("label").style({ "position": "absolute", "left": "0%", "top": "45%", "width": "100%", "font-family": "Roboto", "font-size": "30px", "color": "#f16464", "text-align": "center" })
				.text("No records!")
		}
		else {
			let xAxisFormatted = "";
			if (widget.yAxis.charAt(0) == "[" && widget.yAxis.charAt(widget.yAxis.length - 1) == "]") {
				xAxisFormatted = JSON.parse(widget.yAxis)[0]
			}
			else { xAxisFormatted = widget.yAxis }

			let yAxisFormatted = "";
			if (widget.xAxis.charAt(0) == "[" && widget.xAxis.charAt(widget.xAxis.length - 1) == "]") {
				yAxisFormatted = JSON.parse(widget.xAxis)[0]
			}
			else { yAxisFormatted = widget.xAxis }

			let options = {
				data: widgetdetails.body.records,
				dataForTable: [],
				keys: {
					stateCode: xAxisFormatted,
					latitude: "LATITUDE",
					longitude: "LONGITUDE",
					state: widget.chartPlot,
					place: yAxisFormatted,
					value: JSON.parse(widget.secondaryYaxis)[0]
				},
				chartTitle: widget.chartTitle,
				showTable: false,
				drillDownColumnName: widget.drillThru[0]?.drillDownColumn ? widget.drillThru[0].drillDownColumn : "",
				resizeButtonID: document.getElementById("resizeBtn").id
			}

			setTimeout(() => { acinfotech.USMapWithPins(chartElement, options) }, 1000)
		}
	}

	allowDrop(event, index_j: number, index_i: number) {
		//event.stopPropagation();
		event.preventDefault();
		this.hidePlus = false;
		this.store_index_j.push(index_j);

		if (index_i === 0) {
			this.store_index_i[0].push(index_j);
			this.removeDuplicates2(this.store_index_i[0]);
			this.store_index_i[0] = this.store_index_i2;

		} else if (index_i === 1) {
			this.store_index_i[1].push(index_j);
			this.removeDuplicates2(this.store_index_i[1]);
			this.store_index_i[1] = this.store_index_i2;
		}
		else if (index_i === 2) {
			this.store_index_i[2].push(index_j);
			this.removeDuplicates2(this.store_index_i[2]);
			this.store_index_i[2] = this.store_index_i2;
		}

		this.index_i = index_i;

		this.removeDuplicates(this.store_index_j);

		this.store_index_j = this.store_index_j2;
	}
	theadClick(event) {
		// event.target.attributes.class.value.includes('pi-filter-icon')
		//   ? (this.customSortBool = false)
		//   : (
			this.customSortBool = true
		// );
	  }
	removeDuplicates(data) {
		this.store_index_j2 = data.filter((value: any, index: any) => {
			return data.indexOf(value) === index
		});
	}
	removeDuplicates2(data) {
		this.store_index_i2 = data.filter((value: any, index: any) => {
			return data.indexOf(value) === index
		});
	}


	dragStart(event, ind) {
		event.dataTransfer.setData("text/plain", event.target.id);
		//event.dataTransfer.effectAllowed = "copy";
		this.dragIndex = ind;
	}

	dragEnd(event) {
		//document.getElementById("demo").innerHTML = "Dropped";
	}

	removeWidget(id) {

	}
	editDashboard(data) {
		this.widgetsList = [];
		this.showMessage = false;
		this.showSearch1 = false;
		this.showError=false;
		this.showSearch = false;
		this.selectedDashboard={ name: '', code: '' };
		if (data == 'create') {
			this.showCreateDash = true;
			this.buttonLable = "Save Dashboard";
			this.initialServiceCalls();
		}
		if (data == 'edit') {
			this.showCreateDash = true;
			this.buttonLable = "Update Dashboard";
			this.initialServiceCalls();
		}
	}
	customSort(event: SortEvent) {
		this.tableSortColumn = event.field;
		this.tableSortDir = event.order;
		this.getTableRowIndex =-1;
		this.disableBtn=true;
		if (this.customSortBool) {
			this.customSortBool = false;
			this.requestPayload.sortDirection ='';
			if (event.order == 1) {
				this.requestPayload.sortDirection='ASC';
			} else if (event.order == -1) {
				this.requestPayload.sortDirection='DESC';
			}
			this.creatDashService.getWidgetName(
				this.selectedDomain,
				this.pageNo,
				this.requestPayload
			).subscribe({
				next: (res: any) => {
					this.widgetTableData = res.body?.records;
					//  this.widgetTableData=this.widgetTableData.filter(item=>item.userCreatedDashboard=='Y');
					this.startIndex = this.widgetTableData.length > 0 ? (150 * (this.pageNo - 1)) + 1 : 0;
					this.endIndex = this.widgetTableData.length > 0 ? (this.startIndex - 1) + this.widgetTableData.length : 0;
					this.totalRecords =res.body?.totalRecords;
				},
				error: (err) => {
					this.widgetTableData = [];
					this.startIndex = 0;
					this.endIndex = 0;
					this.totalRecords =0;
				},
			});
		}
	}
	getDomainNameData() {
		this.listDomains = [];
		this.ListItems = []
		this.listDomainsIsLoading = true;
		this.creatDashService.getDomainName().subscribe({
			next: (res: any) => {
				// this.listDomains = res.body;
				this.ListItems = res.body;
				res.body.forEach(element=>{
					if(element.domainDashboardVisibility == "Y" || element.domainDashboardVisibility == null){
						this.listDomains.push(element);
					}
				})
				this.listDomains= this.listDomains.filter(item => item.dashboards.length > 0)
				//console.log(this.listDomains)
				this.listDomainsIsLoading = false;
			},
			error: (err) => {
				this.listDomainsIsLoading = false;
				this.listDomains = [];
			},
		});
	}
	domainChange(event: any) {
		this.pageNo = 1;
		this.paginator.first = 1;
		this.getTableRowIndex = -1;
		this.disableBtn = true;
		this.getWidgetNameData('');
	}

	getWidgetNameData(value: string) {
		this.pageNo = 1;
		// const id = this.selectedDomain;
		this.widgetTableDataIsLoading = true;
		this.creatDashService.getWidgetName(
			this.selectedDomain,
			this.pageNo,
			this.requestPayload
		).subscribe({
			next: (res: any) => {
				this.widgetTableData = res.body?.records;
				// this.widgetTableData=this.widgetTableData.filter(item=>item.userCreatedDashboard=='Y')
				this.startIndex = this.widgetTableData.length > 0 ? 1 : 0;
				this.endIndex = this.widgetTableData.length > 0 ? this.widgetTableData.length : 0;
				this.widgetTableDataIsLoading = false;
				this.totalRecords =res.body?.totalRecords;
				this.widgetTableData.length > 0
					? (this.tableEmptyMessage = '')
					: (this.tableEmptyMessage = 'No Records Found');

			},
			error: (err) => {
				this.widgetTableDataIsLoading = false;
				this.startIndex = 0;
				this.endIndex = 0;
				this.totalRecords =0;
				this.widgetTableData=[]
			},
		});
	}
	paginate(event) {
		this.customSortBool = false;
		this.pageNo = event.page + 1;
		this.widgetTableDataIsLoading = true;
		this.creatDashService.getWidgetName(
			this.selectedDomain,
			this.pageNo,
			this.requestPayload
		).subscribe({
			next: (res: any) => {
				this.widgetTableData = res.body?.records;
				// this.widgetTableData=this.widgetTableData.filter(item=>item.userCreatedDashboard=='Y');
				this.startIndex = this.widgetTableData.length > 0 ? (150 * (this.pageNo - 1)) + 1 : 0;
				this.endIndex = this.widgetTableData.length > 0 ? (this.startIndex - 1) + this.widgetTableData.length : 0;
				this.widgetTableDataIsLoading = false;
				this.totalRecords =res.body?.totalRecords;
			},
			error: (err) => {
				this.widgetTableData = [];
				this.widgetTableDataIsLoading = false;
				this.startIndex = 0;
				this.endIndex = 0;
				this.totalRecords =0;
			},
		});
	}
	onTileSelect(index: number) {
		this.dashboardName = this.widgetTableData[index]?.widgetName;
		this.dashboardDesc = this.widgetTableData[index]?.widgetDescription;
		this.getWidgetId = this.widgetTableData[index]?.widgetMasterId;
		this.getTableRowIndex = index;
		this.tableSelectArray.push(index);
		if (this.tableSelectArray[0] === this.tableSelectArray[1]) {
			this.disableBtn = true;
			// this.isTileSelected = false;
			this.tableSelectArray = [];
			this.getTableRowIndex = -1;
		} else {
			// this.isSelected = true;
			// this.isTileSelected = true;
			// this.datatoedit = this.myReportRecords[index];
			this.disableBtn = false;
			this.tableSelectArray.length === 2
				? this.tableSelectArray.splice(0, 1)
				: '';
		}
	}

	deleteDashboardList(index: number) {
		const requestPayload = {};
		this.creatDashService.deleteDashboard(this.getWidgetId, requestPayload).subscribe({
			next: (res) => {
				this.messageService.add({
					severity: 'success',
					summary: 'Deleted',
					detail: 'Dashboard Deleted Successfully',
				});
				this.widgetTableData.splice(index, 1);
				this.dashboardName = '';
				//this.dashboardDesc='';
				this.getWidgetNameData('');
			},
			error: (err) => {
			},
		});
	}
	getParameterListData() {
		// this.showCreateDash = true
		// this.storeDraggedProduct = [];
		// this.storeChartConfiguration = [];
		// this.getWidgetId = 36070 tata capital
		// 36285 N_piechart
		// if (this.storeWidgetDetails.length === 0) {
		//   this.isLoading = true;
		// }
		this.searchOrDashResultArray = []
		this.widgetsList = [];
		this.compareWidgetList = [];
		this.editDroppedParamWidgetParameters=[];
		this.creatDashService.getParameterList(this.getWidgetId).subscribe((res: any) => {
			this.widgetsList = []
			this.searchOrDashResultArray.push(res?.body);
			this.searchOrDashResult = "edit result";
			this.saveDashboardDomainId = res.body?.domainId;
			this.dashboardName = res.body?.widgetName
			if (res.body?.widgetControls.length > 0 && res.body?.widgetControls) {
				this.widgetsList = [...res.body?.widgetControls];
				this.compareWidgetList = [...res.body?.widgetControls]
				this.widgetsList.forEach((item, i) => {
					this.dragIndex = i;
					this.isParameterizedWidget = false
					const [a, b] = item['objectCordinates'].split(",").map(Number);
					this.index_i = a - 1;
					this.index_j = b - 1;
					if (a === 1) {
						this.store_index_i[0].push(b); // row 1
						this.removeDuplicates2(this.store_index_i[0]);
						this.store_index_i[0] = this.store_index_i2;
					} else if (a === 2) {
						this.store_index_i[1].push(b); // row 2
						this.removeDuplicates2(this.store_index_i[1]);
						this.store_index_i[1] = this.store_index_i2;
					} else if (a === 3) {
						this.store_index_i[2].push(b); // row 3
						this.removeDuplicates2(this.store_index_i[2]);
						this.store_index_i[2] = this.store_index_i2;
					}
					// this.renderWidget(item, a, b);

					var droppedWidget = this.searchOrDashResultArray[0].widgetControls[this.dragIndex];
					let idData = item['objectCordinates'];
					var dData = idData.replace(',', '')
					if (this.searchOrDashResultArray[0].dashboardRequestorParameters.length > 0) {

						if (this.isParameterizedWidget == false) {
							this.isParameterizedWidget = true;
							d3.select('#loader' + dData).style('display', 'none')
							this.droppedParamWidgetDomainId = Number(this.searchOrDashResultArray[0].dashboardRequestorParameters[0].authorizationId);
							this.editDroppedParamWidgetParameters= this.searchOrDashResultArray[0].dashboardRequestorParameters;
							this.droppedParamWidgetParameters = this.searchOrDashResultArray[0].dashboardRequestorParameters; // Storing parameterized widget parameters
							this.saveDashboardReqPayload.dashboardRequestorParameters = this.searchOrDashResultArray[0].dashboardRequestorParameters;
							droppedWidget["objectCordinates"] = d3.select(`#widgetClass-${a}-${b}`).attr("objectCordinates");
							// droppedWidget["objectCordinates"] = d3.select("#widgetClass").attr("objectCordinates");
							this.saveDashboardReqPayload.widgetControls.push(droppedWidget);
							d3.select(`#widgetClass-${a}-${b}`).attr("widgetInfo",
								JSON.stringify({
									parameters: this.searchOrDashResultArray[0].dashboardRequestorParameters,
									controls: droppedWidget
								}));
							const widgetElement = d3.select(`#widgetClass-${a}-${b}`);
							this.chartImage = "././assets/images/createChart/chart" + droppedWidget['controlMasterId'] + ".svg";
							widgetElement.html(
								"<div style='width: 100%; height:0%; background-color:#c7c7c7; position: absolute; top:5px; font-size:16px; font-family:Roboto; color:#0f528c; text-indent:5px;'>" +
								this.widgetsList[this.dragIndex].chartTitle +
								"</div>" +
								"<div><img src='" + this.chartImage + "' style='height: 230px;width: 100%;border: 1px solid #f0f0f0;'></div>"
							);
							var z = document.createElement('button');
							z.setAttribute("style", "position:absolute; right:-5px; top:-10px; width:23px; height:23px; border-radius:100%; background-image:url('././assets/images/cancel.png'); background-repeat: no-repeat; background-size: 100% 100%; background-position: center; border:0; z-index:1000;");
							z.addEventListener("click", () => {
								this.widgetLoading = true;
								widgetElement.html("<i class='pi pi-plus' id='loader" + dData + "'></i>");
								// widgetElement.attr("id", ""); // Clear the ID
								d3.select(`#widgetClass-${a}-${b}`).attr("widgetInfo", null); // Remove widgetInfo attribute
								d3.select('#loader' + dData).style('display', 'block');
								var hasParameterizedWidget = false;
								d3.selectAll(".create-dashboard-widget-container").each(function () {
									var wid = d3.select(this);
									if (wid.attr("widgetInfo") != null) {
										var widgetInfo = JSON.parse(wid.attr("widgetInfo"));
										if (widgetInfo.parameters.length > 0) {
											hasParameterizedWidget = true;
										}
									}
								});

								if (!hasParameterizedWidget) {
									this.droppedParamWidgetDomainId = null;
									this.isParameterizedWidget = false;
									this.droppedParamWidgetParameters = []; // Empty the stored parameterized widget parameters
								}
							}, false);
							widgetElement.node().appendChild(z);
						}
					}
					else {
						this.hidePlus = false;
						this.store_index_j.push(b);

						if (a === 0) {
							this.store_index_i[0].push(b);
							this.removeDuplicates2(this.store_index_i[0]);
							this.store_index_i[0] = this.store_index_i2;

						} else if (a === 1) {
							this.store_index_i[1].push(b);
							this.removeDuplicates2(this.store_index_i[1]);
							this.store_index_i[1] = this.store_index_i2;
						}
						else if (a === 2) {
							this.store_index_i[2].push(b);
							this.removeDuplicates2(this.store_index_i[2]);
							this.store_index_i[2] = this.store_index_i2;
						}

						this.index_i = a;
						this.removeDuplicates(this.store_index_j);
						this.store_index_j = this.store_index_j2;
						d3.select('#loader' + dData).style('display', 'none')
						this.index_i = a;
						this.index_j = b;
						droppedWidget["objectCordinates"] = d3.select(`#widgetClass-${a}-${b}`).attr("objectCordinates");
						this.saveDashboardReqPayload.widgetControls.push(droppedWidget);

						d3.select(`#widgetClass-${a}-${b}`).attr("widgetInfo",
							JSON.stringify({
								parameters: this.searchOrDashResultArray[0].dashboardRequestorParameters,
								controls: droppedWidget
							}));
						const widgetElement = d3.select(`#widgetClass-${a}-${b}`);
						this.chartImage = "././assets/images/createChart/chart" + droppedWidget['controlMasterId'] + ".svg";
						widgetElement.html(
							"<div style='width: 100%; height:0%; background-color:#c7c7c7; position: absolute; top:5px; font-size:16px; font-family:Roboto; color:#0f528c; text-indent:5px;'>" +
							this.widgetsList[this.dragIndex].chartTitle +
							"</div>" +
							"<div><img src='" + this.chartImage + "' style='height: 230px;width: 100%;border: 1px solid #f0f0f0;'></div>"
						);
						var z = document.createElement('button');
						z.setAttribute("style", "position:absolute; right:-5px; top:-10px; width:23px; height:23px; border-radius:100%; background-image:url('././assets/images/cancel.png'); background-repeat: no-repeat; background-size: 100% 100%; background-position: center; border:0; z-index:1000;");
						z.addEventListener("click", () => {
							this.widgetLoading = true;
							widgetElement.html("<i class='pi pi-plus' id='loader" + dData + "'></i>");
							// widgetElement.attr("id", ""); // Clear the ID
							d3.select(`#widgetClass-${a}-${b}`).attr("widgetInfo", null); // Remove widgetInfo attribute
							d3.select('#loader' + dData).style('display', 'block');
						}, false);
						widgetElement.node().appendChild(z);
					}
				})
			}
		});
	}
	compareArrays(firstArray, secondArray) {
		const deletedIds: number[] = [];
		const newIds: number[] = [];

		const firstArrayIds = firstArray.map(item => item.widgetControlId);
		const secondArrayIds = secondArray.map(item => item.widgetControlId);

		// Find deleted IDs (present in firstArray but not in secondArray)
		deletedIds.push(...firstArrayIds.filter(id => !secondArrayIds.includes(id)));

		// Find new IDs (present in secondArray but not in firstArray)
		newIds.push(...secondArrayIds.filter(id => !firstArrayIds.includes(id)));

		return { deletedIds, newIds };
	}
	cancel(){
		this.metricFrmDashBool=false;
		this.metricSearchBool=false;
		this.text='';
		this.showCreateDash=false
		d3.selectAll(".create-dashboard-widget-container")[0].forEach(wid => {
			d3.select(wid).attr("widgetInfo", null) 
		})
		this.saveDashboardDomainId=undefined;
	}
}
