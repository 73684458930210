// tour-guide.service.ts
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import Shepherd from 'shepherd.js';
import { AppComponentService } from './app-component.service';
import * as tourData from '../assets/json/tourguide.json';
@Injectable({
  providedIn: 'root',
})
export class TourGuideService {
  tourGuide: any = tourData.default;
  private tour = new Shepherd.Tour;
  eventAdded = false;
  menuList = [];
  pageList = [
    {
      tourName: 'home',
      displayName: 'Home',
      tourPath: '/home',
      class: 'fa-solid fa-house menu-panel-icons'
    },
    {
      tourName: 'discovery',
      displayName: 'Data Discovery',
      tourPath: '/discovery',
      class: 'fa-solid fa-magnifying-glass-chart menu-panel-icons',
      menuName: 'DataDiscovery'
    },
    {
      tourName: 'dashboard',
      displayName: 'My Dashboard',
      tourPath: '/dashboard',
      class: 'fa-solid fa-chart-column menu-panel-icons',
      menuName: 'Dashboard'
    },
    {
      tourName: 'report',
      displayName: 'My Reports',
      tourPath: '/my-reports',
      class: 'fa-solid fa-folder menu-panel-icons',
      menuName: 'My Reports'
    },
    {
      tourName: 'buildareport',
      displayName: 'Build a Report',
      tourPath: '/build-report',
      class: 'fa-solid fa-file-text menu-panel-icons',
      menuName: 'Custom Reports'
    }];
  constructor(
    private router: Router,
    private appComponentService: AppComponentService
  ) {}

  startWelcomeTour() {
    this.tour = new Shepherd.Tour({
      useModalOverlay: true,
      defaultStepOptions: {
        classes: 'shadow-md bg-purple-dark shepherd-theme-arrows',
        scrollTo: { behavior: 'smooth', block: 'center' },
      },
    });
    this.eventAdded = false;
    document.removeEventListener('click', this.handleButtonClick);
    this.tour.addStep({
      id: 'onboarding',
      text: () => this.getNavButtons(this.tourGuide,'Welcome'),
      classes: 'tour content pButton',
      buttons: [
        {
          classes: 'shepherd-button-secondary',
          text: 'Skip',
          action: this.tour.cancel,
        },
      ],
    });
    if (this.eventAdded == false) {
      document.addEventListener('click', this.handleButtonClick);
      this.eventAdded = true;
    }
    this.tour.start();
    const handleTourEnd = () => {
      this.enableClick();
      localStorage.setItem('isTourDone', 'true');
    };
    this.tour.on('complete', handleTourEnd);
    this.tour.on('cancel', () => {
      handleTourEnd();
      this.hideTourGuide();
      this.setTourStatus('true');
    });
  }

  private handleButtonClick = (event: Event) => {
    const currentUrl = this.router.url;
    const target = event.target as HTMLElement;
    if (target.classList.contains('shepherd-button')) {
      const buttonName = target.getAttribute('data-button-name');
      const buttonRoute = target.getAttribute('data-button-route');
      if (buttonName) {
        this.tour.complete();
        localStorage.setItem(`is${buttonName}TourDone`, 'false');
        if (currentUrl == buttonRoute) {
          this.startTour(buttonName);
        } else {
          this.router.navigate([`.${buttonRoute}`]);
        }
      }
    }
  };

  private getNavButtons(
    buttonsData: { tourName: string; displayName: string; tourPath: string }[],page:string
  ): string {
    let arr = ["buildareporttype","runreport","reporttable"]
    let buttonsHtml = this.getPageList()
      .map(
        (button) =>{
          if(!arr.includes(button.tourName)){
            return `<button class="shepherd-button" data-button-route="${button.tourPath}" data-button-name="${button.tourName}">${button.displayName}</button>`
          }
        }
      )
      .join('');
      if(page != 'Welcome'){
        return `
      <div style="text-align: center;">
        <p><strong>Thank you for taking the time to complete our tour. We hope you found it informative and helpful!</strong></p>
        <p>If you want to explore further, Go to Help Menu or Click the button below.<br><br></p>
        ${buttonsHtml}
      </div>
    `;
      }
    return `
      <div style="text-align: center;">
        <p><strong>Welcome to our TimeBound Guided Tours!</strong></p>
        <p> We're excited to show you around. Click the button below to get started.<br><br></p>
        ${buttonsHtml}
      </div>
    `;
  }

  startTourForPage(page: string) {
    this.tour = new Shepherd.Tour({
      useModalOverlay: true,
      defaultStepOptions: {
        classes: 'shadow-md bg-purple-dark shepherd-theme-arrows',
        scrollTo: { behavior: 'smooth', block: 'center' },
      },
    });
    this.eventAdded = false;
    document.removeEventListener('click', this.handleButtonClick);
    const tourGuidePage = this.tourGuide.filter((e) => e.tourName === page);
    const tourGuide = tourGuidePage[0]?.pageStep;
    const steps = [];
    tourGuide.map((guide, index) => {
      if (document.querySelector(`#${guide.id}`)) {
        steps.push({
          id: guide.id,
          attachTo: {
            element: guide?.attachTo?.element,
            on: guide?.attachTo?.on,
          },
          title: `${guide.title}`,
          text: `<p>${guide.text}</p>`,
          buttons: [
            {
              classes: 'shepherd-button-secondary',
              text: 'Skip',
              action: this.tour.cancel,
            },
            {
              classes: 'shepherd-button-secondary',
              text: 'Back',
              action: this.tour.back,
            },
            {
              classes: 'shepherd-button-primary',
              text: 'Next',
              action: this.tour.next,
            },
          ],
        });
        if (index == 0) {
          steps[index].buttons.splice(1, 1);
        }
      }
    });
    this.tour.addSteps(steps);
    if(tourGuide[tourGuide.length - 1]?.skipNext != true || document.querySelector('#shepherd-askTab'))
    this.tour.addStep({
      id: 'onboardingEnd',
      // text: `<div style="text-align: center;"><p><strong>Thank you for taking the time to complete our tour. We hope you found it informative and helpful.</strong></p><p> If you want to explore further, Go to Help Menu<br><br> Enjoy using TimeBound!</p></div>`,
      text: () => this.getNavButtons(this.tourGuide,page),
      classes: 'tour',
      buttons: [
        {
          classes: 'shepherd-button-secondary',
          text: 'Back',
          action: this.tour.back,
        },
        {
          classes: 'shepherd-button-primary',
          text: 'Done',
          action: this.tour.complete,
        },
      ],
    });
    if (this.eventAdded == false) {
      document.addEventListener('click', this.handleButtonClick);
      this.eventAdded = true;
    }
    this.tour.start();
    this.tour.on('complete',() => {
      this.enableClick();
      localStorage.setItem(`is${page}TourDone`, 'true');
      if(tourGuide[tourGuide.length - 1]?.skipNext == true){
        localStorage.setItem(`is${page}TourDone`, 'false');
      }
      if(page == 'reporttable'){
        localStorage.setItem(`isbuildareportTourDone`, 'true');
      }
      if(page == 'discovery' && this.tour.getCurrentStep().id === 'onboardingEnd'){
        localStorage.setItem(`isdiscoveryTourDone`, 'true');
      }
    });
    this.tour.on('cancel', () => {
      this.enableClick();
      this.hideTourGuide();
      this.setTourStatus('true');
    });
  }
  startTour(page) {
    const isTourEnabled = localStorage.getItem('isTourEnabled');
    const isTourDone = localStorage.getItem('isTourDone');
    const isPageTourDone = localStorage.getItem(`is${page}TourDone`);
    if (isTourEnabled == 'Y') {
      if (isTourDone == 'false' || isTourDone == null) {
        this.WelcomeTour();
      } else {
        if (isPageTourDone == 'false' || isPageTourDone == null) {
          this.pageTour(page);
        }
      }
    } else {
      if (isPageTourDone == 'false') {
        this.pageTour(page);
      }
    }
  }
  WelcomeTour() {
    this.startWelcomeTour();
    this.disableClick();
  }
  pageTour(page) {
    this.startTourForPage(page);
    this.disableClick();
  }
  restartTour() {
    this.WelcomeTour();
  }
  hideTourGuide() {
    const isTourEnabled = localStorage.getItem('isTourEnabled');
    if (isTourEnabled == 'Y') {
      let payload = {
        guidedTour: 'N',
      };
      this.appComponentService.setTourGuideStatus(payload).subscribe((res) => {
        localStorage.setItem('isTourEnabled', payload?.guidedTour);
      });
    }
  }

  getPageList(){
    this.pageList = this.pageList.filter(e => !e.menuName || this.menuList.includes(e.menuName))
    .map(e => e);
    return this.pageList;
  }

  setMenuList(menu:any){
    this.menuList = menu;
  }
  getTourDetails(){
    return this.tour
  }

  getTourEnd(){
    this.tour.addStep({
      id: 'onboardingEnd',
      // text: `<div style="text-align: center;"><p><strong>Thank you for taking the time to complete our tour. We hope you found it informative and helpful.</strong></p><p> If you want to explore further, Go to Help Menu<br><br> Enjoy using TimeBound!</p></div>`,
      text: () => this.getNavButtons(this.tourGuide,''),
      classes: 'tour',
      buttons: [
        {
          classes: 'shepherd-button-secondary',
          text: 'Back',
          action: this.tour.back,
        },
        {
          classes: 'shepherd-button-primary',
          text: 'Done',
          action: this.tour.complete,
        },
      ],
    });
  }
  onBuildaReportComplete(){
    localStorage.setItem('isbuildareportTourDone','true')
  }
  disableClick(): void {
    document.body.classList.add('shepherd-no-click');
  }
  enableClick(): void {
    document.body.classList.remove('shepherd-no-click');
  }
  setTourStatus(status: string) {
    for (let page of this.tourGuide) {
      localStorage.setItem(`is${page.tourName}TourDone`, status);
    }
  }
}
