import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

@Injectable()
export class SettingsService {
    
    constructor(private http: HttpClient) { }
	
	getSavedSettings(){
		return this.http.get('api/v1/user/config',
		{"headers":{"X-Auth-Token": localStorage.getItem("token") }, observe: 'response'})
	}

	getDashboardsList() {
		return this.http.get('api/v1/dashboard/widgets',
		{"headers":{"X-Auth-Token": localStorage.getItem("token") }, observe: 'response'});
	}
	
	saveSettings(payload) {
		return this.http.put('api/v1/user/config',payload,
		{"headers":{"X-Auth-Token": localStorage.getItem("token") }, observe: 'response'})
	}
}

