import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ReportCatalogueService } from './report-catalogue.service'
import { Subject, Subscription } from 'rxjs';
import { Message, MessageService, SortEvent } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from "@angular/router";
import { debounceTime } from 'rxjs/operators';
import { StripHtmlPipe } from '../my-reports/strip-html.pipe';
import { Paginator } from 'primeng/paginator';
import { AppComponentService } from '../app-component.service';
import { Table } from 'primeng/table';

declare var d3: any;

@Component({
	selector: 'app-report-catalogue',
	templateUrl: './report-catalogue.component.html',
	styleUrls: ['./report-catalogue.component.css'],
	providers: [DialogService, ReportCatalogueService]
})

export class ReportCatalogueComponent implements OnInit {
	@ViewChild('paginator', { static: false }) paginator: Paginator;
	@ViewChild('mycatalogtable', { static: true }) table: Table;
	
	reportRecords: any[];
	filterOptions = [
		{ name: 'Start with', value: 'startw' },
		{ name: 'Contains', value: 'contains' },
		{ name: 'Ends with', value: 'endw' },
	];
	clientName_Start!: string;
	clientName_Contains!: string;
	clientName_ends!: string;
	ReportName_start!: string;
	ReportName_Contains!: string;
	ReportName_ends!: string;
	reportDesc_ends!: string;
	reportDesc_Contains!: string;
	reportDesc_Start!: string;
	ReportType_Start!: string;
	ReportType_Contains!: string;
	ReportType_ends!: string;
	location_Start!: string;
	location_Contains!: string;
	location_ends!: string;
	reportInfo: any;
	isLoadingModalInfo: boolean = false;
	errorMsg = '';
	authorizationId: any;
	index: any;
	userId: any;
	filterToolTip = 'Show';
	customSortBool: boolean;
	// tableSortColumn = "authorizationName";
	dir = 1;
	// requestPayload = { sort: [{ property: this.tableSortColumn, dir: 'ASC' }], filters: [], filterType: "and" };



	constructor(private appCompService: AppComponentService, public dialogService: DialogService, private messageService: MessageService, private http: HttpClient, private reportCatalogueService: ReportCatalogueService, private router: Router, private fb: FormBuilder) {
		this.tableSearchUpdate.pipe(debounceTime(3000)).subscribe((value) => {
			this.isLoadingModal = true
			this.onSubmit('table-input');
		});
		this.tableSearchUpdate1.pipe(debounceTime(3000)).subscribe((value) => {
			this.isLoadingModal = true
			this.onSubmit(value);
		});
	}

	totalRecords: any;
	reportCatalogueRecords = [];
	requestPayload = { sort: [{ property: "authorizationName", dir: "ASC" }], filters: [], filterType: "and" };
	tableSortColumn = "authorizationName";
	tableSortDir = 1;
	paginatorPageNo = 1;
	isLoadingModal: boolean = false;
	tableReduceHeight = 240;
	tableSearchUpdate = new Subject<string>();
	tableSearchUpdate1 = new Subject<string>();
	recordsSubscrip: Subscription;
	hideFilter: boolean = false;
	reportDialogVisible: boolean = false;
	searchText = '';
	showEditReport: boolean = false;
	reportValue = 'mmm';
	ref: DynamicDialogRef;
	id: any;
	editReportForm = new FormGroup({
		reportName: new FormControl(''),
		reportDesc: new FormControl(''),
		reportCriteria: new FormControl(''),
		reportParameter: new FormControl(''),
		fieldMap: new FormControl('')
	});
	startIndex = 0;
	endIndex = 0;
	hasAdminRole: any = false;

	ngOnInit(): void {
		this.appCompService.currentUser.subscribe((res) => {
			this.userId = res.userId;
			this.appCompService.getRoleDetails(this.userId).subscribe((res: any) => {
				if (res) {
					let roledetails = res.body;
					this.hasAdminRole = roledetails.assignedRoles.some(r => {
						if (r.roleId === 1) {
							return true; // Stop the iteration once an admin role is found.
						}
						return false;
					});
				}
			});
		})
		this.ReportCatalogueRecords()

	}
	onSubmit(value) {
		this.searchText = '';
		this.requestPayload.filters = [];
		this.requestPayload.filterType = 'and';
		if (value === 'table-input') {
			// if(this.searchText!='' &&  !this.clientName_Contains){
			// 	let obj={
			// 		property: "authorizationName",
			// 		operator: "contains",
			// 		value: this.searchText
			// 	}
			// 	this.requestPayload.filters.push(obj);
			// }
			if (this.clientName_Start) {
				const obj = {
					property: '',
					operator: '',
					value: '',
				};
				obj.property = 'clientName';
				obj.operator = 'startw';
				obj.value = this.clientName_Start.trim();
				this.requestPayload.filters.push(obj);
			}
			if (this.clientName_Contains) {
				const obj = {
					property: '',
					operator: '',
					value: '',
				};
				obj.property = 'clientName';
				obj.operator = 'contains';
				obj.value = this.clientName_Contains.trim();
				this.requestPayload.filters.push(obj);
			}
			if (this.clientName_ends) {
				const obj = {
					property: '',
					operator: '',
					value: '',
				};
				obj.property = 'clientName';
				obj.operator = 'endw';
				obj.value = this.clientName_ends.trim();
				this.requestPayload.filters.push(obj);
			}
			if (this.ReportName_start) {
				const obj = {
					property: '',
					operator: '',
					value: '',
				};
				obj.property = 'authorizationName';
				obj.operator = 'startw';
				obj.value = this.ReportName_start.trim();
				this.requestPayload.filters.push(obj);
			}
			if (this.ReportName_Contains) {
				const obj = {
					property: '',
					operator: '',
					value: '',
				};
				obj.property = 'authorizationName';
				obj.operator = 'contains';
				obj.value = this.ReportName_Contains.trim();
				this.requestPayload.filters.push(obj);
			}
			if (this.ReportName_ends) {
				const obj = {
					property: '',
					operator: '',
					value: '',
				};
				obj.property = 'authorizationName';
				obj.operator = 'endw';
				obj.value = this.ReportName_ends.trim();
				this.requestPayload.filters.push(obj);
			}
			if (this.reportDesc_Start) {
				const obj = {
					property: '',
					operator: '',
					value: '',
				};
				obj.property = 'authorizationDesc';
				obj.operator = 'startw';
				obj.value = this.reportDesc_Start.trim();
				this.requestPayload.filters.push(obj);
			}
			if (this.reportDesc_Contains) {
				const obj = {
					property: '',
					operator: '',
					value: '',
				};
				obj.property = 'authorizationDesc';
				obj.operator = 'contains';
				obj.value = this.reportDesc_Contains.trim();
				this.requestPayload.filters.push(obj);
			}
			if (this.reportDesc_ends) {
				const obj = {
					property: '',
					operator: '',
					value: '',
				};
				obj.property = 'authorizationDesc';
				obj.operator = 'endw';
				obj.value = this.reportDesc_ends.trim();
				this.requestPayload.filters.push(obj);
			}
			if (this.ReportType_Start) {
				const obj = {
					property: '',
					operator: '',
					value: '',
				};
				obj.property = 'reportType';
				obj.operator = 'startw';
				obj.value = this.ReportType_Start.trim();
				this.requestPayload.filters.push(obj);
			}
			if (this.ReportType_Contains) {
				const obj = {
					property: '',
					operator: '',
					value: '',
				};
				obj.property = 'reportType';
				obj.operator = 'contains';
				obj.value = this.ReportType_Contains.trim();
				this.requestPayload.filters.push(obj);
			}
			if (this.ReportType_ends) {
				const obj = {
					property: '',
					operator: '',
					value: '',
				};
				obj.property = 'reportType';
				obj.operator = 'endw';
				obj.value = this.ReportType_ends.trim();
				this.requestPayload.filters.push(obj);
			}
			if (this.location_Start) {
				const obj = {
					property: '',
					operator: '',
					value: '',
				};
				obj.property = 'location';
				obj.operator = 'startw';
				obj.value = this.location_Start.trim();
				this.requestPayload.filters.push(obj);
			}
			if (this.location_Contains) {
				const obj = {
					property: '',
					operator: '',
					value: '',
				};
				obj.property = 'location';
				obj.operator = 'contains';
				obj.value = this.location_Contains.trim();
				this.requestPayload.filters.push(obj);
			}
			if (this.location_ends) {
				const obj = {
					property: '',
					operator: '',
					value: '',
				};
				obj.property = 'location';
				obj.operator = 'endw';
				obj.value = this.location_ends.trim();
				this.requestPayload.filters.push(obj);
			}
		}
		else if (value != '') {
			// let obj={
			// 	property: "authorizationName",
			// 	operator: "contains",
			// 	value: value
			// }
			// this.requestPayload.filters.push(obj);
			const obj = {
				property: '',
				operator: '',
				value: '',
			}
			obj.property = 'clientName';
			obj.operator = 'contains';
			obj.value = value.trim();
			this.requestPayload.filters.push(obj);
			const obj1 = {
				property: '',
				operator: '',
				value: '',
			};
			obj1.property = 'authorizationName';
			obj1.operator = 'contains';
			obj1.value = value.trim();
			this.requestPayload.filters.push(obj1);
			const obj2 = {
				property: '',
				operator: '',
				value: '',
			};
			obj2.property = 'authorizationDesc';
			obj2.operator = 'contains';
			obj2.value = value.trim();
			this.requestPayload.filters.push(obj2);
			const obj3 = {
				property: '',
				operator: '',
				value: '',
			};
			obj3.property = 'reportType';
			obj3.operator = 'contains';
			obj3.value = value.trim();
			this.requestPayload.filters.push(obj3);
			this.requestPayload.filterType = "or";
		}
		this.paginator.first = 0;
		this.ReportCatalogueRecords();
	}
	ngOnDestroy() {
		if (this.recordsSubscrip) {
			this.recordsSubscrip.unsubscribe();
		}
		if (this.ref) {
			this.ref.close();
		}
	}
	ReportCatalogueRecords() {
		this.paginatorPageNo = 1;
		this.isLoadingModal = true
		this.reportCatalogueService.ReportrecordsService(this.paginatorPageNo, this.requestPayload).subscribe((res: any) => {
			res.body?.records ? (this.reportRecords = res.body?.records) : (this.reportRecords = []);
			// this.reportRecords = res?.body?.records.length>0?res?.body?.records:[];
			this.totalRecords = res?.body?.totalRecords;
			this.startIndex = this.reportRecords.length > 0 ? 1 : 0;
			this.endIndex = this.reportRecords.length > 0 ? this.reportRecords.length : 0;
			this.isLoadingModal = false
		},
			(error) => {
				this.isLoadingModal = false;
				if (Number(error.status) == 304) {
					this.errorMsg = 'Please Try After Some Time!';
				} else if (Number(error.status) == 500) {
					this.errorMsg = 'Internal Error';
				} else {
					this.errorMsg = 'Please Try After Some Time.';
				}
				this.startIndex = 0;
				this.endIndex = 0;
				this.addSingle();
			},
			() => { }
		);
	}
	showFilter() {
		if (this.hideFilter === false) {
			this.tableReduceHeight = 370;
			this.hideFilter = true;
			this.filterToolTip = 'Hide';
		} else if (this.hideFilter === true) {
			this.tableReduceHeight = 240;
			this.hideFilter = false;
			this.filterToolTip = 'Show';
		}

	}
	HideFilter() {
		// this.searchText='';
		this.clientName_Start = '';
		this.clientName_Contains = '';
		this.clientName_ends = '';
		this.ReportName_start = '';
		this.ReportName_Contains = '';
		this.ReportName_ends = '';
		this.reportDesc_ends = '';
		this.reportDesc_Contains = '';
		this.reportDesc_Start = '';
		this.ReportType_Start = '';
		this.ReportType_Contains = '';
		this.ReportType_ends = '';
		this.location_Start = '';
		this.location_Contains = '';
		this.location_ends = '';
		this.requestPayload = { sort: [{ property: "authorizationName", dir: "ASC" }], filters: [], filterType: "and" };
		// if (this.searchText != '') {
		// 	this.onSubmit(this.searchText);
		// }
		// else {
		this.ReportCatalogueRecords()
		// }
	}
	onReportInfoButton(data) {
		this.reportInfo = []
		this.isLoadingModalInfo = true;
		this.reportDialogVisible = true;
		this.reportCatalogueService.reportInfoService(data.authorizationId).subscribe((res) => {
			this.reportInfo = res.body;
			this.isLoadingModalInfo = false;
		},
			(error) => {
				this.isLoadingModal = false;
				if (Number(error.status) == 304) {
					this.errorMsg = 'Please Try After Some Time!';
				} else if (Number(error.status) == 500) {
					this.errorMsg = 'Internal Error';
				} else {
					this.errorMsg = 'Please Try After Some Time.';
				}

				this.addSingle();
			},
			() => { }
		);
	}
	EditReport(data, index) {
		this.index = index;
		this.authorizationId = data.authorizationId;
		this.showEditReport = true;
		this.editReportForm.patchValue({
			reportName: data.authorizationName == 'null' ? '' : data.authorizationName,
			reportDesc: data.authorizationDesc == 'null' ? '' : data.authorizationDesc,
			reportCriteria: data.reportCriteria == 'null' ? '' : data.reportCriteria,
			reportParameter: data.parameters == 'null' ? '' : data.parameters,
			fieldMap: data.fieldMapping == 'null' ? '' : data.fieldMapping
		})
	}
	addSingle() {
		this.messageService.add({
			severity: 'error',
			summary: '',
			detail: this.errorMsg,
		});
	}
	paginate(event: any, value: string) {
		this.isLoadingModal = true;
		this.paginatorPageNo = event.page + 1;
		this.reportCatalogueService.ReportrecordsService(this.paginatorPageNo, this.requestPayload).subscribe(
			(recordsResp: any) => {
				recordsResp.body?.records ? (this.reportRecords = recordsResp.body?.records) : (this.reportRecords = []);
				this.totalRecords = recordsResp.body?.totalRecords;
				this.startIndex = this.reportRecords.length > 0 ? (150 * (this.paginatorPageNo - 1)) + 1 : 0;
				this.endIndex = this.reportRecords.length > 0 ? (this.startIndex - 1) + this.reportRecords.length : 0;
				this.isLoadingModal = false;
			},
			(error) => {
				this.isLoadingModal = false;
				if (Number(error.status) == 401) {
					this.errorMsg = 'Please Try After Some Time!';
				} else if (Number(error.status) == 500) {
					this.errorMsg = 'Internal Error';
				} else {
					this.errorMsg = 'Please Try After Some Time.';
				}
				this.startIndex = 0;
				this.endIndex = 0;
				this.addSingle();
			},
			() => { }
		);
	}
	updateReportCatelogue() {
		const requestPayload = {
			// authorizationDesc:this.reportRecords[this.index].authorizationDesc,
			authorizationDesc: this.editReportForm.value.reportDesc,
			authorizationId: this.authorizationId,
			authorizationName: this.reportRecords[this.index].authorizationName,
			clientCode: this.reportRecords[this.index].clientCode,
			clientName: this.reportRecords[this.index].clientName,
			fieldMapping: this.editReportForm.value.fieldMap,
			location: this.reportRecords[this.index].location,
			parameters: this.editReportForm.value.reportParameter,
			reportCriteria: this.editReportForm.value.reportCriteria,
			reportType: this.reportRecords[this.index].reportType,
			selected: "selected"
		}
		this.reportCatalogueService.updateReportCatelogue(this.authorizationId, requestPayload).subscribe((res) => {
			this.ReportCatalogueRecords()
		})
		this.messageService.add({
			severity: 'info',
			summary: 'Update',
			detail: 'Successfully Updated',
		});
		this.showEditReport = false;
	}
	theadClick(event: any) {
		//console.log(event)
		event.target.attributes.class.value.includes('pi-filter-icon')
			? (this.customSortBool = false)
			: (this.customSortBool = true);
	}
	customSort(event: SortEvent) {
		//console.log(event)
		this.tableSortColumn = event.field;
		this.dir = event.order;
		this.table.reset();
		this.table.sortField = this.tableSortColumn;
		this.table._sortOrder = this.dir;
		if (this.customSortBool) {
			this.requestPayload.sort = [];
			if (event.order == 1) {
				this.requestPayload.sort.push({ property: event.field, dir: 'ASC' });
			} else if (event.order == -1) {
				this.requestPayload.sort.push({ property: event.field, dir: 'DESC' });
			}
			// this.isLoadingModal=true;
			this.reportCatalogueService.ReportrecordsService(this.paginatorPageNo, this.requestPayload)
				.subscribe(
					(recordsResp: any) => {
						let sortedData = [];
						recordsResp.body?.records
							? (sortedData = recordsResp.body.records)
							: (sortedData = []);
						event.data.splice(0, event.data.length);
						sortedData.forEach((d) => {
							event.data.push(d);
						});
						// this.isLoadingModal=false;
						this.customSortBool = false;
					},
					(error) => {
						this.customSortBool = false;
						// this.isLoadingModal=false;
						if (Number(error.status) == 401) {
							this.errorMsg = 'Please Try After Some Time!';
						} else if (Number(error.status) == 500) {
							this.errorMsg = 'Internal Error';
						} else {
							this.errorMsg = 'Please Try After Some Time.';
						}
						this.addSingle();
					},
					() => { }
				);
		}

	}
}
