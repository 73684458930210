import { Component, OnInit, OnDestroy, ElementRef, HostListener, NgZone } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MyReportsService } from './my-reports.service';
import { Subject, Subscription } from 'rxjs';
import { Message, MessageService } from 'primeng/api';
import { SortEvent } from 'primeng/api';
import { Table } from 'primeng/table';
import { ViewChild } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { AllOutputFiles } from './all-output-files';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { AppComponentService } from '../app-component.service';
import { Paginator } from 'primeng/paginator';
import { TourGuideService } from '../tour-guide.service';

interface TreeNode {
  label?: string;
  data?: any;
  icon?: any;
  expandedIcon?: any;
  collapsedIcon?: any;
  children?: any;
  leaf?: boolean;
  authorizationId?: any;
  hasChildren?: boolean;
  expanded?: boolean;
  loader?:any;
  desc?:any
}

declare var d3: any;

@Component({
  selector: 'app-my-reports',
  templateUrl: './my-reports.component.html',
  styleUrls: ['./my-reports.component.css'],
  providers: [MyReportsService, DialogService, AllOutputFiles],
})
export class MyReportsComponent implements OnInit, OnDestroy {
  showView1: boolean;
  @ViewChild('paginator', { static: false }) paginator: Paginator;
  // @ViewChild('myreportstable', { static: true }) table: Table;
  files: TreeNode[];
  
  selectedFile: TreeNode;
  errorMsg = '';
  alertMessage: Message[];
  folderObjParent = {};
  folderObjChild = {};
  subFolderObjChild = {};
  directoryArray = [];
  directoryChildrenArray = [];
  totalRecords = 0;
  myReportRecords = [];
  
  authorizationId?: any;
  folderPanelToggleBool: boolean = false;
  stateOptions: any[];
  listOrTileView:any;
  settingsResponse!: any;
  requestPayload = { sort: [], filters: [], filterType: 'or' };
  paginatorPageNo: number = 1;
  tableSortColumn = '';
  tableSortDir = -1;
  customSortBool!: boolean;
  tableEmptyMessage: string = '';
  repoCritPanelToggleBool: boolean = false;
  sideMenuCollapse: boolean = false;
  isLoading: boolean = false;
  disabledData: boolean = true;
  disabledData1: boolean = true;
  isSelected: boolean = false;
  addSubscription: Subscription;
  showAddDialog: boolean = false;
  showDeleteDialog: boolean = false;
  title: string;
  AddClick: boolean = false;
  editHide: boolean = false;
  showAddReport: boolean = false;
  addForm: FormGroup;
  addFileForm = new FormGroup({
    reportName: new FormControl('', [Validators.required]),
    resourcesId: new FormControl('', [Validators.required]),
    description: new FormControl(''),
    file: new FormControl('', [Validators.required]),
    dataSource:new FormControl(''),
    reportCriteria: new FormControl(''),
    reportParameter: new FormControl(''),
    reportType: new FormControl('', [Validators.required]),
    exportType: new FormControl('', [Validators.required]),
    client: new FormControl(''),
    schedule: new FormControl(''),
    run: new FormControl(''),
    eventBased: new FormControl(''),
    header:new FormControl('')
  });
  reportsList: any = [];
  maincontent: boolean = true;
  clientList: any = [];
  fileTypes: any = [];
  datatoedit: any = {};
  showDeleteReportDialog: boolean = false;
  uploadedFile: any = '';
  ButtonName: string;
  dataSource:any;
  resouId: string = '';
  // addForm = new FormGroup({
  //   folderName: new FormControl('', [
  //     Validators.required,
  //     Validators.minLength(4),
  //     Validators.maxLength(100),
  //   ]),
  //   resourceidName: new FormControl('', [
  //     Validators.required,
  //     Validators.minLength(4),
  //     Validators.maxLength(100),
  //   ]),
  //   descriptionName: new FormControl('', [Validators.maxLength(100)]),
  // });
  foldName: any = '';
  resId: any = '';
  ResourceId: any;
  FolderName: any;
  subName: any;
  reportName: any = '';
  resourcesId: any = '';
  description: any = '';
  fileName: any = '';
  showFile: boolean = true;
  sowFileName: boolean = false;
  reportCriteria: any = '';
  reportParameter: any = '';
  reportType: any = [];
  exportType: any;
  client: any = [];
  reqPayload = {
    authorizationName: '',
    reportCriteria: '',
    parameters: '',
    resourceId: '',
    dataSourceId:'',
    reportType: '',
    isSchedulable: '',
    isRunnable: '',
    isEventBased: '',
    clientName: '',
    uploadFile: '',
    exportType: '',
    authorizationDesc: '',
    isHeader:'',
    clientCode: '',
    // flag: ""
  };
  schedule: any = false;
  run: any = false;
  eventBased: any = false;
  isTileSelected: boolean = false;
  tableSearchUpdate = new Subject<string>();
  searchText: any = '';
  getTableRowIndex: number = -1;
  tableSelectArray: any = [];
  isLoadingModal!: boolean;
  filedata: any;
  folderResp: any;
  searchDropDownList = [
    { name: 'By Report Name', code: 'report-name' },
    { name: 'By Report Type', code: 'report-type' },
  ];
  searchDropDownSelected = ['report-name', 'report-type'];
  depth = 0;
  searchChecked: boolean = true;
  searchFieldDisable: boolean = false;
  showPermissionDialog: boolean = false;
  roleShow: boolean = true;
  userShow: boolean = false;
  permissionOptions: any[] = [
    { label: 'Role', value: 'role' },
    { label: 'User', value: 'user' },
  ];
  selectedRole: string = this.permissionOptions[0].value;
  items1: any = [];
  itemList1 = [];
  items2: any = [];
  itemList2 = [];
  selectedList1 = [];
  selectedList2 = [];
  showPermissionReportDialog: boolean = false;
  roleReportShow: boolean = true;
  userReportShow: boolean = false;
  permissionReportOptions: any[] = [
    { label: 'Role', value: 'role' },
    { label: 'User', value: 'user' },
  ];
  selectedReportRole: string = this.permissionOptions[0].value;
  itemsReport1: any = [];
  itemReportList1 = [];
  itemsReport2: any = [];
  itemReportList2 = [];
  selectedReportList1 = [];
  selectedReportList2 = [];
  checked1 = false;
  checked2 = false;
  checkedReport1 = false;
  checkedReport2 = false;
  directoryView :any;
  showFolder = false;
  userId: any;
  btnSpinner = '';
  data: any;
  isLoading1: boolean=false;
  showAdminfunctionality: boolean=false;
  reporCatalogueInfotDialogVisible: boolean = false;
  isLoadingModalInfo: boolean = false;
  reportCatalogueInfo: any;
  showReportInformationDialog:boolean=false;
  specificationReport: any[];
  specificationHistoryReport: any[];
  specificationWhoElseUsesReport: any[];
  showActionIcons: boolean = false;
  actionIconsIndex = -1;
  showView: boolean=false;
  Rid: any;
  Rname: any;
  Rtype: any;
  uniqueName: any;
  @ViewChild('myreportstable', { static: true }) table: Table;
  globalID: any;
  authID: any;
  SelFile: TreeNode;
  authorizationName: any;
  clickedIndex: number;
  showDataSource: boolean=false;
  header: boolean=false;
  showHeader: boolean=false;
  isEditMode: any=false;
  isEditMode1: boolean=false;
  tableReduceHeight = 290;
  startIndex: number=0;
  endIndex: number=0;
  filteredFiles: TreeNode[];
  load: boolean=false;
  fileloader: boolean=false;
  fileloaderDisable: boolean=false;
  isRoleDisable: boolean=false;
  isUserDisable: boolean=false;
  first: number=0;
  selectedOption: any;
  uploadedFile1: string;
  selectedFileName: string;
  uploadForm: any;
  conflictError: boolean;
  fileUploadResourceId: any;
  ReportId: any;
  title1: string;
  subName1: string;

  constructor(
    public activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
    private messageService: MessageService,
    public dialogService: DialogService,
    private http: HttpClient,
    private myRepoService: MyReportsService,
    private router: Router,
    private appCompService: AppComponentService,
    private ngZone: NgZone,
    private formBuilder: FormBuilder,
    private tourGuideService: TourGuideService

  ) {
    this.isLoading = true;
    this.tableSearchUpdate.pipe(debounceTime(3000)).subscribe((value) => {
      let val=value.trim()
      this.initialCallForSearch(val);
      // this.onSubmit('table-input');
    });
    this.globalID=this.appCompService.getGlobalVariable1();
    console.log(this.globalID)
  //   if(this.globalID !=undefined)
  //   {
  //   this.files=this.globalID?.files;
  //   this.totalRecords=this.globalID?.total;
  //   this.myReportRecords =this.globalID?.record;
  // }
  }

  directoryListSubscrip0: Subscription;
  directoryListSubscrip1: Subscription;
  directoryListSubscrip2: Subscription;
  recordsSubscrip: Subscription;
  settingsGetSubscrip: Subscription;
  settingsPostSubscrip: Subscription;
  addToFavoritesSubscrip: Subscription;
  userSubscription: Subscription;
  ref: DynamicDialogRef;
  totRecord;
  storedFiles;
  myRecord;
  dataSourceList:any=[]
  ngAfterViewInit() {
    // Now, tables is a QueryList containing references to all matched elements
    // this.table.forEach(table => {
    // });
  }
  ngOnInit(): void {
    // d3.select('.tibo-topbar-title').text('Home/My Reports');
    this.showHeader=false;
    this.header=false;
    this.uploadForm = this.fb.group({
      filename: ['', Validators.required],
      description: [''],
      file: [null, Validators.required]
    });
    this.reportCriteria="";
    this.reportParameter="";
    this.description="";
    this.showDataSource=false;
    this.dataSourceList=[]
    // this.dataSource='';
    this.activatedRoute.queryParams.subscribe((params) => {
      if(params.length>0){
      this.authorizationId=params?.reportId;
      this.authorizationName=params?.name;
      this.searchText=this.authorizationName.replaceAll("_","").split('.')[0];
      this.initialCallForSearch(this.searchText);}
    })
    this.addForm = new FormGroup({
      folderName: new FormControl('', [
        Validators.required,
        Validators.minLength(4),
        Validators.maxLength(100),
      ]),
      resourceidName: new FormControl('', [
        Validators.required,
        Validators.minLength(4),
        Validators.maxLength(100),
      ]),
      descriptionName: new FormControl('', [Validators.maxLength(100)]),
    });
    this.showView1=true;
    this.appCompService.currentUser.subscribe((res) => {
      this.userId = res.userId;
      this.appCompService.getRoleDetails(this.userId).subscribe((res:any)=>{
        this.showAdminfunctionality=false;
        if(res){
          let roledetails=res;
          roledetails.assignedRoles.forEach(r=>{
            if(r.roleId==1){
              this.showAdminfunctionality=true;
            }
          })
        }
      },
     (err) => {
      },)
    });
    this.isLoading = true;
    this.selectedRole = this.permissionOptions[0].value;
    for (let i = 0; 2 > i; i++) {
      const obj = {
        property: i == 0 ? 'reportType' : 'authorizationName',
        dir: 'ASC',
      };
      this.requestPayload.sort.push(obj);
    }
    d3.select('.tibo-topbar-title').text('Home / My Reports'); // setting title
    this.showAddDialog = false;
    this.showDeleteDialog = false;
    this.FolderName = '';
    this.ResourceId = '';
    this.isTileSelected = false;
    this.title = '';
    // d3.select('.my-reports-tile-view').style('display', 'block');
    // d3.select('.my-reports-list-view').style('display', 'block');
    // d3.select('.my-reports-tile-view').style('display', 'none');
    this.addForm = new FormGroup({
      folderName: new FormControl('', Validators.required),
      resourceidName: new FormControl('', Validators.required),
      descriptionName: new FormControl(''),
    });
    // this.depth=1
    // this.myRepoService.getDefaultDashboard().subscribe((res) => {
    //   this.settingsResponse = res;
    //   if (this.settingsResponse?.directoryView == 'TreeView') {
    //     this.directoryView = 'TreeView';
    //     this.showFolder = true;
    //     this.depth = 0;
    //   } else if (this.settingsResponse?.directoryView == 'ClientView') {
    //     this.directoryView = 'ClientView';
    //     this.showFolder = false;
    //     this.depth = 1;
    //   } else if (!this.settingsResponse.directoryView) {
    //     this.directoryView = 'TreeView';
    //     this.showFolder = true;
    //     this.depth = 0;
    //   }
    //   if (this.settingsResponse.viewStyle == 'Tile') {
    //     this.listOrTileView = 'Tile View';
    //     d3.select('.my-reports-tile-view').style('display', 'block');
    //   } else if (this.settingsResponse.viewStyle == 'List') {
    //     this.listOrTileView = 'List View';
    //     d3.select('.my-reports-list-view').style('display', 'block');
    //   }
    //   else if (!this.settingsResponse.viewStyle) {
    //     this.listOrTileView = 'Tile View';
    //     d3.select('.my-reports-tile-view').style('display', 'block');
    //   }

    // });
    this.stateOptions = [
      { icon: 'pi pi-th-large', value: 'Tile View', label: 'Tile View', tooltip: 'Switch To Tile View' },
      { icon: 'pi pi-list', value: 'List View', label: 'List View', tooltip: 'Switch To List View' }
  ];
    this.settingsGetSubscrip = this.myRepoService
      .settingsGetService()
      .subscribe(
        (response: any) => {
          // settings
          this.settingsResponse = response;
          if (this.settingsResponse.directoryView == 'TreeView') {
            this.directoryView = 'TreeView';
            this.showFolder = true;
            this.depth = 0;
          } else if (this.settingsResponse.directoryView == 'ClientView') {
            this.directoryView = 'ClientView';
            this.showFolder = false;
            this.depth = 1;
          } else if (!this.settingsResponse.directoryView) {
            this.directoryView = 'TreeView';
            this.showFolder = true;
            this.depth = 0;
          }
          if (this.settingsResponse.viewStyle == 'Tile') {
            this.listOrTileView = 'Tile View';
            d3.select('.my-reports-tile-view').style('display', 'block');
            d3.select('.my-reports-list-view').style('display', 'none');
          } else if (this.settingsResponse.viewStyle == 'List') {
            this.listOrTileView = 'List View';
            d3.select('.my-reports-list-view').style('display', 'block');
            d3.select('.my-reports-tile-view').style('display', 'none');
          }
          else  if (!this.settingsResponse.viewStyle){
            this.listOrTileView = 'List View';
            d3.select('.my-reports-list-view').style('display', 'none');
            d3.select('.my-reports-tile-view').style('display', 'block');
          }
        },
        (error) => {
          this.isLoading = false;
        },
        () => {}
      );

    
if(this.globalID==undefined){
    this.directoryListSubscrip0 = this.myRepoService
      .directoryService(0)
      .subscribe(
        (folderResp: any) => {
          // directory list
          this.folderResp = folderResp.body[0].authorizationId;
          this.folderObjParent = {
            label: folderResp.body[0].authorizationName,
            data: '',
            expandedIcon: 'pi pi-folder-open',
            collapsedIcon: 'pi pi-folder',
            children: [],
            authorizationId: folderResp.body[0].authorizationId,
            expanded: true,
            hasChildren: folderResp.body[0].hasChildren,
            resourceId: folderResp.body[0].resourceId,
            desc:folderResp.body[0].authorizationDesc

          };
          this.selectedFile = this.folderObjParent;
          //console.log(this.selectedFile)
          if (folderResp.body[0].hasChildren == true) {
            this.directoryListSubscrip1 = this.myRepoService
              .directoryService(folderResp.body[0].authorizationId)
              .subscribe(
                (subFolderResp: any) => {
                  // directorys list
                  this.authorizationId = folderResp.body[0].authorizationId; // save authorization id globally
                  subFolderResp.body?.forEach((subFolder) => {
                    if (subFolder.hasChildren) {
                      this.folderObjChild = {
                        label: subFolder.authorizationName,
                        data: '',
                        expandedIcon: 'pi pi-folder-open',
                        collapsedIcon: 'pi pi-folder',
                        children: [{ label: '' }],
                        authorizationId: subFolder.authorizationId,
                        hasChildren: subFolder.hasChildren,
                        resourceId: subFolder.resourceId,
                        desc:subFolder.authorizationDesc
                      };

                      this.folderObjParent['children'].push(
                        this.folderObjChild
                      );
                      this.folderObjChild = {};
                    } else {
                      this.folderObjChild = {
                        label: subFolder.authorizationName,
                        data: '',
                        expandedIcon: 'pi pi-folder-open',
                        collapsedIcon: 'pi pi-folder',
                        children: [],
                        authorizationId: subFolder.authorizationId,
                        hasChildren: subFolder.hasChildren,
                        resourceId: subFolder.resourceId,
                        desc:subFolder.authorizationDesc
                      };

                      this.folderObjParent['children'].push(
                        this.folderObjChild
                      );
                      this.folderObjChild = {};
                    }
                  });

                  this.files = [this.folderObjParent];
                   this.filteredFiles= this.files;
                   //this.storedFiles=this.files;

                  this.FolderName = this.files[0]['label'];
                  this.ResourceId = this.files[0]['resourceId'];
                },
                (error) => {
                  this.isLoading = false;
                  if (Number(error.status) == 401) {
                    this.errorMsg = 'Please Try After Some Time!!';
                  } else if (Number(error.status) == 500) {
                    this.errorMsg = 'Internal Error';
                  } else {
                    this.errorMsg = 'Please Try After Some Time!.';
                  }

                  this.addSingle();
                },
                () => { }
              ); // directoryService
          }
          this.paginatorPageNo=1;
          this.recordsSubscrip = this.myRepoService
            .recordsService(
              folderResp.body[0].authorizationId,
              this.paginatorPageNo,
              this.requestPayload,
              this.depth
            )
            .subscribe(
              (recordsResp: any) => {
                this.totalRecords = recordsResp.body?.totalRecords;
                
                recordsResp.body?.records
                  ? (this.myReportRecords = recordsResp.body.records)
                  : (this.myReportRecords = []);
                this.myReportRecords;
                this.totRecord=this.totalRecords;
                this.myRecord=this.myReportRecords;
                this.myReportRecords.length > 0
                  ? (this.tableEmptyMessage = '')
                  : (this.tableEmptyMessage = 'No Records Found');
                this.isLoading = false;
                this.startIndex=this.myReportRecords.length>0?1:0;
                this.endIndex=this.myReportRecords.length>0?this.myReportRecords.length:0;
                setTimeout(()=>{     
                  this.tourGuideService.startTour('report');
                },2000)
              },
              (error) => {
                if (Number(error.status) == 401) {
                  this.errorMsg = 'Please Try After Some Time!!';
                } else if (Number(error.status) == 500) {
                  this.errorMsg = 'Internal Error';
                } else {
                  this.errorMsg = 'Please Try After Some Time!.';
                }
                this.startIndex=0;
                this.endIndex=0;
                this.isLoading = false;

                this.addSingle();
              },
              () => { }
            );
        },
        (error) => {
          if (Number(error.status) == 401) {
            this.errorMsg = 'Please Try After Some Time!';
          } else if (Number(error.status) == 500) {
            this.errorMsg = 'Internal Error';
          } else {
            this.errorMsg = 'Please Try After Some Time!.';
          }

          this.addSingle();
        },
        () => { }
      );
    this.myRepoService.getreportType().subscribe(
      (Response) => {
        this.reportsList = Response.body;
      },
      (error) => {
        this.isLoading = false;
        if (Number(error.status) == 401) {
          this.errorMsg = 'Please Try After Some Time!!';
        } else if (Number(error.status) == 500) {
          this.errorMsg = 'Internal Error';
        } else {
          this.errorMsg = 'Please Try After Some Time!.';
        }

        this.addSingle();
      }
    );
 
}
else{
  if(this.globalID?.page>1){
    this.directoryListSubscrip0 = this.myRepoService
      .directoryService(0)
      .subscribe(
        (folderResp: any) => {
          // directory list
          this.folderResp = folderResp.body[0].authorizationId;
          this.folderObjParent = {
            label: folderResp.body[0].authorizationName,
            data: '',
            expandedIcon: 'pi pi-folder-open',
            collapsedIcon: 'pi pi-folder',
            children: [],
            authorizationId: folderResp.body[0].authorizationId,
            expanded: true,
            hasChildren: folderResp.body[0].hasChildren,
            resourceId: folderResp.body[0].resourceId,
            desc:folderResp.body[0].authorizationDesc

          };
          this.selectedFile = this.folderObjParent;
          //console.log(this.selectedFile)
          if (folderResp.body[0].hasChildren == true) {
            this.directoryListSubscrip1 = this.myRepoService
              .directoryService(folderResp.body[0].authorizationId)
              .subscribe(
                (subFolderResp: any) => {
                  // directorys list
                  this.authorizationId = folderResp.body[0].authorizationId; // save authorization id globally
                  subFolderResp.body?.forEach((subFolder) => {
                    if (subFolder.hasChildren) {
                      this.folderObjChild = {
                        label: subFolder.authorizationName,
                        data: '',
                        expandedIcon: 'pi pi-folder-open',
                        collapsedIcon: 'pi pi-folder',
                        children: [{ label: '' }],
                        authorizationId: subFolder.authorizationId,
                        hasChildren: subFolder.hasChildren,
                        resourceId: subFolder.resourceId,
                        desc:subFolder.authorizationDesc
                      };

                      this.folderObjParent['children'].push(
                        this.folderObjChild
                      );
                      this.folderObjChild = {};
                    } else {
                      this.folderObjChild = {
                        label: subFolder.authorizationName,
                        data: '',
                        expandedIcon: 'pi pi-folder-open',
                        collapsedIcon: 'pi pi-folder',
                        children: [],
                        authorizationId: subFolder.authorizationId,
                        hasChildren: subFolder.hasChildren,
                        resourceId: subFolder.resourceId,
                        desc:subFolder.authorizationDesc
                      };

                      this.folderObjParent['children'].push(
                        this.folderObjChild
                      );
                      this.folderObjChild = {};
                    }
                  });

                  this.files = [this.folderObjParent];
                   this.filteredFiles= this.files;
                   //this.storedFiles=this.files;

                  this.FolderName = this.files[0]['label'];
                  this.ResourceId = this.files[0]['resourceId'];
                },
                (error) => {
                  this.isLoading = false;
                  if (Number(error.status) == 401) {
                    this.errorMsg = 'Please Try After Some Time!!';
                  } else if (Number(error.status) == 500) {
                    this.errorMsg = 'Internal Error';
                  } else {
                    this.errorMsg = 'Please Try After Some Time!.';
                  }

                  this.addSingle();
                },
                () => { }
              ); // directoryService
          }
          this.paginatorPageNo=1;
          this.recordsSubscrip = this.myRepoService
            .recordsService(
              folderResp.body[0].authorizationId,
              this.paginatorPageNo,
              this.requestPayload,
              this.depth
            )
            .subscribe(
              (recordsResp: any) => {
                this.totalRecords = recordsResp.body?.totalRecords;
                
                recordsResp.body?.records
                  ? (this.myReportRecords = recordsResp.body.records)
                  : (this.myReportRecords = []);
                this.myReportRecords;
                this.totRecord=this.totalRecords;
                this.myRecord=this.myReportRecords;
                this.myReportRecords.length > 0
                  ? (this.tableEmptyMessage = '')
                  : (this.tableEmptyMessage = 'No Records Found');
                this.isLoading = false;
                this.startIndex=this.myReportRecords.length>0?1:0;
                this.endIndex=this.myReportRecords.length>0?this.myReportRecords.length:0;
                setTimeout(()=>{     
                  this.tourGuideService.startTour('report');
                },2000)
              },
              (error) => {
                if (Number(error.status) == 401) {
                  this.errorMsg = 'Please Try After Some Time!!';
                } else if (Number(error.status) == 500) {
                  this.errorMsg = 'Internal Error';
                } else {
                  this.errorMsg = 'Please Try After Some Time!.';
                }
                this.startIndex=0;
                this.endIndex=0;
                this.isLoading = false;

                this.addSingle();
              },
              () => { }
            );
        },
        (error) => {
          if (Number(error.status) == 401) {
            this.errorMsg = 'Please Try After Some Time!';
          } else if (Number(error.status) == 500) {
            this.errorMsg = 'Internal Error';
          } else {
            this.errorMsg = 'Please Try After Some Time!.';
          }

          this.addSingle();
        },
        () => { }
      );
  }
  else{
  //console.log(this.globalID)
  this.files=this.globalID?.files;
  this.totalRecords=this.globalID?.total;
  this.myReportRecords =this.globalID?.record;
  this.selectedFile=this.globalID?.selFile
  let index=this.globalID?.index;
  this.isLoading = false;
  this.authorizationId=this.globalID?.id;
  this.startIndex=this.globalID?.start;
  this.endIndex=this.globalID?.end;
  // this.depth=1;
  // this.requestPayload=this.globalID?.payload;
  // this.first=(this.globalID?.page - 1) * 150;
  this.onTileSelect(index);
  }
}
this.disabledData1 = true;
  }

  ngOnDestroy() {
    //if(this.directoryListSubscrip0){
    this.directoryListSubscrip0?.unsubscribe();
    //}
    if (this.directoryListSubscrip1) {
      this.directoryListSubscrip1.unsubscribe();
    }
    if (this.directoryListSubscrip2) {
      this.directoryListSubscrip2.unsubscribe();
    }
    if (this.recordsSubscrip) {
      this.recordsSubscrip.unsubscribe();
    }
    if (this.settingsGetSubscrip) {
      this.settingsGetSubscrip.unsubscribe();
    }
    if (this.settingsPostSubscrip) {
      this.settingsPostSubscrip.unsubscribe();
    }
    if (this.addToFavoritesSubscrip) {
      this.addToFavoritesSubscrip.unsubscribe();
    }
    if (this.ref) {
      this.ref.close();
    }
  }

  initialCallForSearch(value: any) {
    this.datatoedit = {};
    this.isSelected = false;
    this.disabledData = true;
    this.isTileSelected = false;
    this.tableSelectArray = [];
    this.getTableRowIndex = -1;
    this.paginator.first=0;
    this.paginatorPageNo=1;
    this.folderResp=this.authorizationId;
    if(value == '' || value == null){
      this.isLoading = true;
      this.requestPayload.filters = [];
      this.depth = 0;
      this.folderResp=this.authorizationId;
      this.paginatorPageNo= 1;
      this.recordsSubscrip = this.myRepoService
      .recordsService(
        this.folderResp,
        this.paginatorPageNo,
        this.requestPayload,
        this.depth
      )
      .subscribe(
        (recordsResp: any) => {
          this.myReportRecords = recordsResp.body?.records ?recordsResp.body?.records:[];
          this.totalRecords = recordsResp.body?.totalRecords?recordsResp.body?.totalRecords:0;
          // this.totRecord=this.totalRecords;
          // this.myRecord=this.myReportRecords;
          this.myReportRecords.length > 0
            ? (this.tableEmptyMessage = '')
            : (this.tableEmptyMessage = 'No Records Found');
          this.isLoading = false;
          this.startIndex=this.myReportRecords.length>0?1:0;
          this.endIndex=this.myReportRecords.length>0?this.myReportRecords.length:0;
        },
        (error) => {
          if (Number(error.status) == 401) {
            this.errorMsg = 'Please Try After Some Time!!';
          } else if (Number(error.status) == 500) {
            this.errorMsg = 'Internal Error';
          } else {
            this.errorMsg = 'Please Try After Some Time!.';
          }
          this.startIndex=0;
          this.endIndex=0;
          this.isLoading = false;
          this.addSingle();
        }
      );
    }
    else{
    this.isLoading = true;
    this.requestPayload.filters = [];
    if (this.searchChecked) {
      this.depth = 1;
    } else {
      this.depth = 0;
    }
    for (let i = 0; this.searchDropDownSelected.length > i; i++) {
      const obj = {
        property: '',
        operator: '',
        value: '',
      };
      if (this.searchDropDownSelected[i] === 'report-name') {
        obj.property = 'authorizationName';
        obj.operator = 'like';
        obj.value = value;
        this.requestPayload.filters.push(obj);
      }
      if (this.searchDropDownSelected[i] === 'report-type') {
        obj.property = 'reportType';
        obj.operator = 'like';
        obj.value = value;
        this.requestPayload.filters.push(obj);
      }
    }
    // }
    
    this.recordsSubscrip = this.myRepoService
      .recordsService(
        this.folderResp,
        this.paginatorPageNo,
        this.requestPayload,
        this.depth
      )
      .subscribe(
        (recordsResp: any) => {
          this.myReportRecords = recordsResp.body?.records ?recordsResp.body?.records:[];
          this.totalRecords = recordsResp.body?.totalRecords?recordsResp.body?.totalRecords:0;
          // this.totRecord=this.totalRecords;
          // this.myRecord=this.myReportRecords;
          this.myReportRecords.length > 0
            ? (this.tableEmptyMessage = '')
            : (this.tableEmptyMessage = 'No Records Found');
          this.isLoading = false;
          this.startIndex=this.myReportRecords.length>0?1:0;
          this.endIndex=this.myReportRecords.length>0?this.myReportRecords.length:0;
        },
        (error) => {
          if (Number(error.status) == 401) {
            this.errorMsg = 'Please Try After Some Time!!';
          } else if (Number(error.status) == 500) {
            this.errorMsg = 'Internal Error';
          } else {
            this.errorMsg = 'Please Try After Some Time!.';
          }
          this.startIndex=0;
          this.endIndex=0;
          this.isLoading = false;
          this.addSingle();
        }
      );}
  }

  theadClick(event: any) {
    event.target.attributes.class.value.includes('pi-filter-icon')
      ? (this.customSortBool = false)
      : (this.customSortBool = true);
  }

  customSort(event: SortEvent) {
    this.getTableRowIndex=-1;
    this.disabledData=true;
    this.tableSortColumn = event.field;
    this.tableSortDir = event.order;
    this.table.reset();
    this.table.sortField = this.tableSortColumn;
    this.table._sortOrder = this.tableSortDir;
    if (this.customSortBool) {
      this.requestPayload.sort = [];
      if (event.order == 1) {
        this.requestPayload.sort.push({ property: event.field, dir: 'ASC' });
      } else if (event.order == -1) {
        this.requestPayload.sort.push({ property: event.field, dir: 'DESC' });
      }
      this.recordsSubscrip = this.myRepoService
        .recordsService(
          this.authorizationId,
          this.paginatorPageNo,
          this.requestPayload,
          this.depth
        )
        .subscribe(
          (recordsResp: any) => {
            this.customSortBool = false;
            let sortedData = [];
            recordsResp.body?.records
              ? (sortedData = recordsResp.body.records)
              : (sortedData = []);
            event.data.splice(0, event.data.length);
            sortedData.forEach((d) => {
              event.data.push(d);
            });
          },
          (error) => {
            this.customSortBool = false;
            this.isLoading = false;
            if (Number(error.status) == 401) {
              this.errorMsg = 'Please Try After Some Time!!';
            } else if (Number(error.status) == 500) {
              this.errorMsg = 'Internal Error';
            } else {
              this.errorMsg = 'Please Try After Some Time!.';
            }
            this.addSingle();
          },
          () => { }
        );
    }
  }

  nodeExpand(event) {
    // if (event instanceof Event) {
    //   event.preventDefault();
    //   // Your code here
    // }
    this.nodeSelect(event);
    this.isLoading1=true;
    event.node.loading = true;
    let iconChange='pi pi-spin pi-spinner';
    if (event.node.hasChildren == true) {
     
      this.directoryListSubscrip2 = this.myRepoService
        .directoryService(event.node.authorizationId)
        .subscribe(
          (subFolderResp: any) => {
            // directorys list
            this.isLoading1=false;
            event.node.loading = false;
            if (subFolderResp.body != null) {
              let arr = [];
              subFolderResp.body.forEach((subFolder) => {
                if (subFolder.hasChildren) {
                  this.folderObjChild = {
                    label: subFolder.authorizationName,
                    data: '',
                    expandedIcon:  'pi pi-folder-open',
                    collapsedIcon: 'pi pi-folder',
                    children: [{ label: '' }],
                    authorizationId: subFolder.authorizationId,
                    hasChildren: subFolder.hasChildren,
                    resourceId: subFolder.resourceId,
                    desc:subFolder.authorizationDesc
                    //loader:'pi pi-spin pi-spinner'
                  };
                  arr.push(this.folderObjChild);
                  this.folderObjChild = {};
                  //event.node.expanded = true;
                } else {
                  this.folderObjChild = {
                    label: subFolder.authorizationName,
                    data: '',
                    expandedIcon: 'pi pi-folder-open',
                    collapsedIcon: 'pi pi-folder',
                    children: [],
                    authorizationId: subFolder.authorizationId,
                    hasChildren: subFolder.hasChildren,
                    resourceId: subFolder.resourceId,
                    desc:subFolder.authorizationDesc
                  };

                  arr.push(this.folderObjChild);
                  this.folderObjChild = {};
                  //event.node.expanded = true;
                }
              });

              event.node.children = arr;
              event.node.expanded = true;
            }
          },
          (error) => {
            if (Number(error.status) == 401) {
              this.errorMsg = 'Please Try After Some Time!!';
            } else if (Number(error.status) == 500) {
              this.errorMsg = 'Internal Error';
            } else {
              this.errorMsg = 'Please Try After Some Time!.';
            }

            this.addSingle();
          },
          () => { }
        ); // directoryService
    }
  }
  
  nodeSelect(event) {
    // if (event instanceof Event) {
    //   event.preventDefault();
    //   // Your code here
    // }
    //this.onTileSelect(null)
    this.paginator.first=0;
    //console.log(this.selectedFile)
    //console.log(event)
    this.getTableRowIndex=-1;
    this.clickedIndex=-1;
    this.paginatorPageNo=1
    this.searchText='';
    this.startIndex=0;
    this.endIndex=0;
    this.depth=0;
    this.myReportRecords = []
    this.totalRecords=0;
    this.requestPayload.filters=[];
    this.folderResp = event.node.authorizationId;
    this.showAddDialog = false;
    this.table.clear();
    this.isTileSelected = false;
    this.tableEmptyMessage = '';
    this.authorizationId = event.node.authorizationId; // save authorization id globally
    if (this.authorizationId == '1') {
      this.disabledData1 = true;
    } else {
      this.disabledData1 = false;
    }
    this.ResourceId = event.node.resourceId;
    this.FolderName = event.node.label;
    if (event.node.hasChildren == true) {
      this.directoryListSubscrip2 = this.myRepoService
        .directoryService(event.node.authorizationId)
        .subscribe(
          (subFolderResp: any) => {
            // directorys list

            if (subFolderResp.body != null) {
              let arr = [];
              subFolderResp.body.forEach((subFolder) => {
                if (subFolder.hasChildren) {
                  this.folderObjChild = {
                    label: subFolder.authorizationName,
                    data: '',
                    expandedIcon: 'pi pi-folder-open',
                    collapsedIcon: 'pi pi-folder',
                    children: [{ label: '' }],
                    authorizationId: subFolder.authorizationId,
                    hasChildren: subFolder.hasChildren,
                    resourceId: subFolder.resourceId,
                    desc:subFolder.authorizationDesc
                  };
                  arr.push(this.folderObjChild);
                  this.folderObjChild = {};
                  //event.node.expanded = true;
                } else {
                  this.folderObjChild = {
                    label: subFolder.authorizationName,
                    data: '',
                    expandedIcon: 'pi pi-folder-open',
                    collapsedIcon: 'pi pi-folder',
                    children: [],
                    authorizationId: subFolder.authorizationId,
                    hasChildren: subFolder.hasChildren,
                    resourceId: subFolder.resourceId,
                    desc:subFolder.authorizationDesc
                  };

                  arr.push(this.folderObjChild);
                  this.folderObjChild = {};
                  //event.node.expanded = true;
                }
              });

              event.node.children = arr;
              event.node.expanded = true;
            }
          },
          (error) => {
            d3.select('.tibo-myrepo-reports-spinner').style('display', 'none'); // hidding reports container spinner
            if (Number(error.status) == 401) {
              this.errorMsg = 'Please Try After Some Time!!';
            } else if (Number(error.status) == 500) {
              this.errorMsg = 'Internal Error';
            } else {
              this.errorMsg = 'Please Try After Some Time!.';
            }

            this.addSingle();
          },
          () => { }
        ); // directoryService
    }
    this.isLoading = true;

    this.recordsSubscrip = this.myRepoService
      .recordsService(
        event.node.authorizationId,
        this.paginatorPageNo,
        this.requestPayload,
        this.depth
      )
      .subscribe(
        (recordsResp: any) => {
          // records
          d3.select('.tibo-myrepo-reports-spinner').style('display', 'none'); // hidding reports container spinner

          this.totalRecords = recordsResp.body.totalRecords;
          recordsResp.body?.records
            ? (this.myReportRecords = recordsResp.body.records)
            : (this.myReportRecords = []);
          this.myReportRecords;
          // this.totRecord=this.totalRecords;
          //       this.myRecord=this.myReportRecords;
          this.myReportRecords.length > 0
            ? (this.tableEmptyMessage = '')
            : (this.tableEmptyMessage = 'No Records Found');
          this.isLoading = false;
          this.startIndex=this.myReportRecords.length>0?1:0;
          this.endIndex=this.myReportRecords.length>0?this.myReportRecords.length:0;
          this.disabledData = true;
        },
        (error) => {
          d3.select('.tibo-myrepo-reports-spinner').style('display', 'none'); // hidding reports container spinner
          if (Number(error.status) == 401) {
            this.errorMsg = 'Please Try After Some Time!!';
          } else if (Number(error.status) == 500) {
            this.errorMsg = 'Internal Error';
          } else {
            this.errorMsg = 'Please Try After Some Time!.';
          }
          this.isLoading = false;
          this.startIndex=0;
          this.endIndex=0;
          this.addSingle();
        },
        () => { }
      );
  }

  nodeUnselect(event) { }

  paginate(event) {
    d3.select('.tibo-myrepo-reports-spinner').style('display', 'block'); // showing reports container spinner

    this.paginatorPageNo = event.page + 1;
    this.first = event.first;
    //this.table.clear();
    // this.myReportRecords = [];
    // this.paginator.changePage((this.paginatorPageNo-1));
    this.isLoading = true;
    this.getTableRowIndex=-1;
    this.recordsSubscrip = this.myRepoService
      .recordsService(
        this.authorizationId,
        event.page + 1,
        this.requestPayload,
        this.depth
      )
      .subscribe(
        (recordsResp: any) => {
          // records

          d3.select('.tibo-myrepo-reports-spinner').style('display', 'none'); // hidding reports container spinner
          this.totalRecords = recordsResp.body.totalRecords;
          recordsResp.body?.records
            ? (this.myReportRecords = recordsResp.body.records)
            : (this.myReportRecords = []);
          this.myReportRecords;
          // this.totRecord=this.totalRecords;
          // this.myRecord=this.myReportRecords;
          this.isLoading = false;
          this.startIndex=this.myReportRecords.length>0?(150*(this.paginatorPageNo-1))+1:0;
           this.endIndex=this.myReportRecords.length>0?(this.startIndex-1)+this.myReportRecords.length:0;
        },
        (error) => {
          d3.select('.tibo-myrepo-reports-spinner').style('display', 'none'); // hidding reports container spinner
          if (Number(error.status) == 401) {
            this.errorMsg = 'Please Try After Some Time!!';
          } else if (Number(error.status) == 500) {
            this.errorMsg = 'Internal Error';
          } else {
            this.errorMsg = 'Please Try After Some Time!.';
          }
          this.startIndex=0;
          this.endIndex=0;
          this.isLoading = false;

          this.addSingle();
        },
        () => { }
      );
  }

  changeViewFunc(event) {
    if (event.value == 'Tile View') {
      // this.isLoading = true;
      d3.select('.my-reports-tile-view').style('display', 'block');
      d3.select('.my-reports-list-view').style('display', 'none');
      this.settingsResponse['viewStyle'] = 'Tile';
      // this.isLoading = true;
      this.settingsPostSubscrip = this.myRepoService
        .settingsPostService(this.settingsResponse)
        .subscribe(
          (response: any) => {
            this.isLoading = false;
          },
          (error) => {
            this.isLoading = false;
            // if (Number(error.status) == 401) {
            //   this.errorMsg = 'Please Try After Some Time!!';
            // } else if (Number(error.status) == 500) {
            //   this.errorMsg = 'Internal Error';
            // } else {
            //   this.errorMsg = 'Please Try After Some Time!.';
            // }
            // this.addSingle();
          },
          () => { }
        );
    } 
    else if (event.value == 'List View') {
      d3.select('.my-reports-tile-view').style('display', 'none');
      d3.select('.my-reports-list-view').style('display', 'block');
      this.settingsResponse['viewStyle'] = 'List';
      this.settingsPostSubscrip = this.myRepoService
        .settingsPostService(this.settingsResponse)
        .subscribe(
          (response: any) => {
          },
          (error) => {
            // if (Number(error.status) == 401) {
            //   this.errorMsg = 'Please Try After Some Time!!';
            // } else if (Number(error.status) == 500) {
            //   this.errorMsg = 'Internal Error';
            // } else {
            //   this.errorMsg = 'Please Try After Some Time!.';
            // }

            // this.addSingle();
          },
          () => { }
        );
    }
  }

  tabelResetFunc(table) {
    table.clear();
  }

  folderPanelToggle() {
    //this.files = this.directoryArray;
    this.sideMenuCollapse = !this.sideMenuCollapse;
    if (this.folderPanelToggleBool == false) {
      d3.selectAll('#myreport-folder-panel-scroll-div').style(
        'display',
        'none'
      );
      // d3.select("#myreports-folderview-panel").transition().delay(200).duration(500).style("width","40px")
      // d3.select("#myreports-tile-list-scroll-view").transition().delay(200).duration(500).style({"left":"50px",'width':'96%','height':'100%','animation-name':'reducetime','float':'right'})//.style("width","calc(100% - 50px)")
      // d3.select("#myreports-folderview-panel").select("button").style("transform","rotate(180deg)")
      d3.select('#all-reports-id')
        .transition()
        .delay(100)
        .duration(100)
        .style({ left: '-14px', top: '70px' })
        .style('transform', 'rotate(-90deg)');
      this.folderPanelToggleBool = true;
    } else {
      d3.select('#myreport-folder-panel-scroll-div').style('display', 'block');
      //d3.select("#submitbutton-id").transition().delay(500).duration(400).style("display","block")
      // d3.select("#myreports-folderview-panel").transition().delay(200).duration(500).style("width","350px")
      // d3.select("#myreports-tile-list-scroll-view").transition().delay(200).duration(500).style({"left":"360px",'width':'75%','height':'100%'})//.style("width","calc(100% - 360px)")
      // d3.select("#myreports-folderview-panel").select("button").style("transform","rotate(0deg)")
      d3.select('#all-reports-id')
        .transition()
        .delay(250)
        .duration(100)
        .style({ left: '10px', top: '10px' })
        .style('transform', 'rotate(0deg)');
      this.folderPanelToggleBool = false;
    }
  }

  repoCriteriaPanelToggle() {
    if (this.repoCritPanelToggleBool == false) {
      d3.selectAll('#myrepo-output-paramspanl-scrollpanel, #submitbutton-id')
        .transition()
        .delay(200)
        .duration(400)
        .style('display', 'none');
      d3.select('#myrepo-output-parameters-panel')
        .transition()
        .delay(200)
        .duration(500)
        .style('width', '40px');
      d3.select('#myrepo-output-container')
        .transition()
        .delay(200)
        .duration(500)
        .style('left', '50px'); //.style("width","calc(100% - 50px)")
      d3.select('#myrepo-output-parameters-panel')
        .select('button')
        .style('transform', 'rotate(180deg)');
      d3.select('#report-criteria-id')
        .transition()
        .delay(100)
        .duration(100)
        .style({ left: '-25px', top: '85px' })
        .style('transform', 'rotate(-90deg)');
      this.repoCritPanelToggleBool = true;
    } else {
      d3.select('#myrepo-output-paramspanl-scrollpanel')
        .transition()
        .delay(300)
        .duration(400)
        .style('display', 'block');
      d3.select('#submitbutton-id')
        .transition()
        .delay(500)
        .duration(400)
        .style('display', 'block');
      d3.select('#myrepo-output-parameters-panel')
        .transition()
        .delay(200)
        .duration(500)
        .style('width', '350px');
      d3.select('#myrepo-output-container')
        .transition()
        .delay(200)
        .duration(500)
        .style('left', '360px'); //.style("width","calc(100% - 360px)")
      d3.select('#myrepo-output-parameters-panel')
        .select('button')
        .style('transform', 'rotate(0deg)');
      d3.select('#report-criteria-id')
        .transition()
        .delay(250)
        .duration(100)
        .style({ left: '10px', top: '10px' })
        .style('transform', 'rotate(0deg)');
      this.repoCritPanelToggleBool = false;
    }
  }

  onRunButtonClick(reportId, reportName, reportType,name,index) {
    //console.log(index,this.clickedIndex)
    d3.select('#showNew').style('display', 'none');
    let ind;
    if (reportType == null || reportType == undefined) {
      reportType = ''; // setting report type as string if type is null
    }
    if(this.clickedIndex==undefined){
      ind=index;
    }
    else{
      ind=this.clickedIndex
    }
    this.SelFile=this.selectedFile;
    this.storedFiles=this.files;
    this.totRecord=this.totalRecords;
    this.myRecord=this.myReportRecords;
    let value={
      files:this.storedFiles,
      total:this.totRecord,
      record:this.myRecord,
      selFile:this.SelFile,
      index:index,
      id:this.authorizationId,
      start:this.startIndex,
      end:this.endIndex,
      page:this.paginatorPageNo,
      payload:this.requestPayload
    }
    this.appCompService.setGlobalVariable1(value);
    // this.showView=true;
    // this.showView1=false;
    // this.Rid=reportId;
    // this.Rname=reportName;
    // this.Rtype=reportType;
    // this.uniqueName=name;
   
    this.router.navigate(['/view'], {
      queryParams: { id: reportId, type: reportType, name: reportName,uniqueName:name },
    });
  }
  onScheduleBtnClick(reportId, reportName, authDesc,index) {
    this.SelFile=this.selectedFile;
    this.storedFiles=this.files;
    this.totRecord=this.totalRecords;
    this.myRecord=this.myReportRecords;
    let value={
      files:this.storedFiles,
      total:this.totRecord,
      record:this.myRecord,
      selFile:this.SelFile,
      index:index,
      id:this.authorizationId,
      start:this.startIndex,
      end:this.endIndex,
      page:this.paginatorPageNo,
      payload:this.requestPayload
    }
    this.appCompService.setGlobalVariable1(value);
    this.router.navigate(['./scheduler'], {
      queryParams: { reportId: reportId, name: reportName, authDesc: authDesc,id:1 ,component:"my-reports" },
    });
  }
  onSecureScheduleBtnClick(reportId, reportName, authDesc,index) {
    this.SelFile=this.selectedFile;
    this.storedFiles=this.files;
    this.totRecord=this.totalRecords;
    this.myRecord=this.myReportRecords;
    let value={
      files:this.storedFiles,
      total:this.totRecord,
      record:this.myRecord,
      selFile:this.SelFile,
      index:index,
      id:this.authorizationId,
      start:this.startIndex,
      end:this.endIndex,
      page:this.paginatorPageNo,
      payload:this.requestPayload
    }
    this.appCompService.setGlobalVariable1(value);
    this.router.navigate(['./scheduler'], {
      queryParams: { reportId: reportId, name: reportName, authDesc: authDesc,id:2,component:"my-reports" },
    });
  }
  onAddToMyFavoritesBtnClick(reportId, reportName, obj) {
    this.addToFavoritesSubscrip = this.myRepoService
      .reportAddToFavoritesService(reportId)
      .subscribe(
        (recordsResp: any) => {
        //   if (obj.favourite.toLowerCase() === 'y') {
        //     obj.favourite = 'n';
        // } else {
        //   obj.favourite = 'y'; 
        // }
          // records
          obj.favourite = 'Y'
          this.messageService.add({
            severity: 'success',
            summary: '',
            detail: 'Successfully Added To Your Favorites!',
          });
        },
        (error) => {
          if (Number(error.status) == 401) {
            this.errorMsg = 'Please Try After Some Time!!';
          } else if (Number(error.status) == 500) {
            this.errorMsg = 'Internal Error';
          } else {
            this.errorMsg = 'Please Try After Some Time!.';
          }

          // this.addSingle();
        },
        () => { }
      );
  }

  addSingle() {
    this.messageService.add({
      severity: 'error',
      summary: '',
      detail: this.errorMsg,
    });
  }

  clear() {
    this.messageService.clear();
  }

  showAllOutputFilesBtnClick(reportId) {
    const style = {
      color: 'red',
    };
    this.ref = this.dialogService.open(AllOutputFiles, {
      data: {
        reportId: reportId,
      },
      header: 'Output Files',
      width: '70%',
      styleClass: 'dynamic-dialog-typescript-class',
      contentStyle: {
        'background-color': '#f1f1f4',
        'border-bottom-left-radius': '7px',
        'border-bottom-right-radius': '7px',
      },
      style: {
        position: 'absolute',
        top: '2%',
      },
      baseZIndex: 10000,
    });
  }

  onTileSelect(index: number) {
    //console.log(index)

    this.getTableRowIndex = index;
    this.tableSelectArray.push(index);
    if (this.tableSelectArray[0] === this.tableSelectArray[1]) {
      this.datatoedit = {};
      this.isSelected = false;
      this.disabledData = true;
      this.isTileSelected = false;
      this.tableSelectArray = [];
      this.getTableRowIndex = -1;
    } else {
      this.isSelected = true;
      this.isTileSelected = true;
      this.datatoedit = this.myReportRecords[index];
      this.disabledData = false;
      this.tableSelectArray.length === 2
        ? this.tableSelectArray.splice(0, 1)
        : '';
        this.SelFile=this.selectedFile;
    this.storedFiles=this.files;
    this.totRecord=this.totalRecords;
    this.myRecord=this.myReportRecords;
    let value={
      files:this.storedFiles,
      total:this.totRecord,
      record:this.myRecord,
      selFile:this.SelFile,
      index:index,
      id:this.authorizationId,
      start:this.startIndex,
      end:this.endIndex,
      page:this.paginatorPageNo,
      payload:this.requestPayload
    }
    this.appCompService.setGlobalVariable1(value); 
    }
  }

  setClick(event) {
    this.isSelected = true;
    this.disabledData = false;
  }
  addfolder() {
    this.addForm.reset();
    this.addForm.controls.resourceidName.enable();
    this.showAddDialog = true;
    this.editHide = false;
    this.isEditMode1=false;
    this.ResourceId = this.addForm.value.folderName
    this.subName = 'Add';
    this.title = 'Add a New Folder In ' + this.selectedFile?.label;
  }
  change(event) {
    if(!this.isEditMode1){
      if(event){
    this.addForm.controls['resourceidName'].setValue(event.trim()?.toLowerCase().replaceAll(" ","_"));
      }
    }
  }
  editFolder() {
    //console.log(this.selectedFile)
    this.showAddDialog = true;
    this.editHide = true;
    this.subName = 'Update';
    this.isEditMode1=true;
    this.FolderName=this.selectedFile.label;
    this.ResourceId=this.selectedFile['resourceId'];
    let desc='';
    if(this.selectedFile['desc']== "null"){
      desc='';}
      else{
        desc=this.selectedFile['desc'];
      }
    this.title = 'Edit Folder ' + this.selectedFile?.label;
    this.addForm.controls['folderName'].setValue(this.selectedFile.label);
    this.addForm.controls['resourceidName'].setValue(this.selectedFile['resourceId']);
    this.addForm.controls['descriptionName'].setValue(desc);
    this.addForm.controls.resourceidName.disable();
  }
  onAddSubmit(val, name) {
    if(this.addForm.valid){
      if(this.addForm.value.folderName.trim()=='' ||this.addForm.value.resourceidName.trim()==''){
        this.addForm.reset();
        this.messageService.add({
          severity: 'error',
          summary: 'Required',
          detail: 'Please Enter Valid Name or Resource ID',
        });
      }
      else{
          if (name == 'Add') {
          // this.showAddDialog=false;
            var payload = {
              authorizationName: this.addForm.value.folderName.trim(),
              resourceId: this.addForm.value.resourceidName.trim(),
              authorizationDesc: this.addForm.value.descriptionName?this.addForm.value.descriptionName.trim():'',
            };
            this.myRepoService.AddFolder(this.authorizationId, payload).subscribe(
              (response) => {
                this.showAddDialog = false;
                this.messageService.add({
                  severity: 'info',
                  summary: 'Added',
                  detail: 'Successfully Added',
                });
                this.ngOnInit();
              },
              (error) => {
                //d3.selectAll(".tibo-mydash-drillthru-spinner").style("display","none") // hidding reports container spinner
                //if(Number(error.status) == 401){ this.errorMsg = "Please Try After Some Time!!" }
                if (Number(error.status) == 409) {
                  this.messageService.add({
                    severity: 'error',
                    summary: 'The Resource ID Was Already Taken',
                    detail: 'Please Try With Different Resource ID',
                  });
                }
                // this.addSingle();
              }
            );
          } 
          else if (name == 'Update') {
            //this.showAddDialog=false;
            var payload = {
              authorizationName: this.addForm.value.folderName.trim(),
              resourceId: this.ResourceId,
              authorizationDesc: this.addForm.value.descriptionName?this.addForm.value.descriptionName.trim():'',
            };
            this.myRepoService
              .updateFolderData(this.authorizationId, payload)
              .subscribe(
                (Response) => {
                  this.showAddDialog = false;
                  this.ngOnInit();
                  this.messageService.add({
                    severity: 'info',
                    summary: 'Updated',
                    detail: 'Successfully Updated',
                  });
                },
                (error) => {
                  d3.selectAll('.tibo-mydash-drillthru-spinner').style(
                    'display',
                    'none'
                  ); // hidding reports container spinner
                  if (Number(error.status) == 401) {
                    this.errorMsg = 'Please Try After Some Time!!';
                  } else if (Number(error.status) == 500) {
                    this.errorMsg = 'Internal Error';
                  } else {
                    this.errorMsg = 'Please Try After Some Time!.';
                  }

                  this.addSingle();
                }
              );
          }
      }
  }
  }
 deleteFolder() {
  this.showDeleteDialog = true;
  this.title = `Are You Sure You Want To Delete The "${this.selectedFile?.label}" Folder?`;
  this.subName = 'Delete';
}
  ondeleteClick() {
    this.myRepoService.deleteFolderData(this.authorizationId).subscribe(
      (Response) => {
        this.showDeleteDialog = false;
        // this.ngOnInit();
        this.messageService.add({
          severity: 'info',
          summary: 'Deleted',
          detail: 'Successfully Deleted',
        });
        this.ngOnInit();
      },
      (error) => {
        d3.selectAll('.tibo-mydash-drillthru-spinner').style('display', 'none'); // hidding reports container spinner
        if (Number(error.status) == 401) {
          this.errorMsg = 'Please Try After Some Time!!';
        } else if (Number(error.status) == 500) {
          this.errorMsg = 'Internal Error';
        } else {
          this.errorMsg = 'Please Try After Some Time!.';
        }

        this.addSingle();
      }
    );
  }
  deleteReport() {
    this.showDeleteReportDialog = true;
    this.title =
      `Are You Sure You Want To Delete The 
      "${this.datatoedit['authorizationName'].replaceAll("_"," ").split('.')[0]}" 
      Report?`;
    this.subName = 'Delete';
  }
  ondelete() {
    this.myRepoService
      .deleteReportData(this.datatoedit['authorizationId'])
      .subscribe(
        (Response) => {
          this.showDeleteReportDialog = false;
          // this.ngOnInit();
          // this.messageService.add({
          //   severity: 'info',
          //   summary: 'Deleted',
          //   detail: 'Successfully Deleted',
          // });
          this.recordsSubscrip = this.myRepoService
          .recordsService(
            this.authorizationId,
            this.paginatorPageNo,
            this.requestPayload,
            this.depth
          )
          .subscribe(
            (recordsResp: any) => {
              this.totalRecords = recordsResp.body?.totalRecords;
              recordsResp.body?.records
                ? (this.myReportRecords = recordsResp.body.records)
                : (this.myReportRecords = []);
              this.myReportRecords;
            //   this.totRecord=this.totalRecords;
            // this.myRecord=this.myReportRecords;
              this.myReportRecords.length > 0
                ? (this.tableEmptyMessage = '')
                : (this.tableEmptyMessage = 'No Records Found');
                this.startIndex=this.myReportRecords.length>0?1:0;
            this.endIndex=this.myReportRecords.length>0?this.myReportRecords.length:0;
              this.isLoading = false;
              this.getTableRowIndex=-1;
              this.messageService.add({
                severity: 'info',
                summary: 'Deleted',
                detail: 'Successfully Deleted',
              });
              this.btnSpinner = '';
            },
            (error) => {
              this.btnSpinner = '';
              if (Number(error.status) == 401) {
                this.errorMsg = 'Please Try After Some Time!!';
              } else if (Number(error.status) == 500) {
                this.errorMsg = 'Internal Error';
              } else {
                this.errorMsg = 'Please Try After Some Time!.';
              }
              this.isLoading = false;
              this.startIndex=0;
              this.endIndex=0;
              this.addSingle();
            },
            () => { }
          );
        },
        (error) => {
          d3.selectAll('.tibo-mydash-drillthru-spinner').style(
            'display',
            'none'
          ); // hidding reports container spinner
          if (Number(error.status) == 401) {
            this.errorMsg = 'Please Try After Some Time!!';
          } else if (Number(error.status) == 500) {
            this.errorMsg = 'Internal Error';
          } else {
            this.errorMsg = 'Please Try After Some Time!.';
          }

          this.addSingle();
        }
      );
  }
  addReport() {
    this.fileloader=false;
    this.fileloaderDisable=false
    this.fileTypes = [];
    this.title = 'Add a Report In ' + this.selectedFile?.label + ' Folder';
    this.addFileForm.get('file').setValidators([Validators.required]);
    this.addFileForm.get('file').updateValueAndValidity();
    this.addFileForm.get('exportType').setValidators([Validators.required]);
    this.addFileForm.get('exportType').updateValueAndValidity();
    this.addFileForm.reset();
    this.addFileForm.controls.resourcesId.enable();
    this.maincontent = false;
    this.fileName = '';
    this.isEditMode=false;
    this.uploadedFile = '';
    this.showFile = true;
    this.ButtonName = 'Add';
    this.showAddReport = true;
    this.load=true;
    this.showHeader =false;
    this.clientList =  [{userId:"0",clientCode:"0",clientName:'ALL'}];
    this.client=this.clientList[0].clientCode;
    this.myRepoService.getSearch().subscribe((res:any)=>{
      this.load=false;
      if(res?.body?.length>0){
          this.clientList.push(...res?.body);
      }
      //console.log(this.clientList,this.client);
    },(error)=>{
      this.load=false;
    });
    this.myRepoService.getreportType().subscribe(
      (Response) => {
        this.reportsList = Response.body;
      },
      (error) => {
        d3.selectAll('.tibo-mydash-drillthru-spinner').style('display', 'none'); // hidding reports container spinner
        // if (Number(error.status) == 401) {
        //   this.errorMsg = 'Please Try After Some Time!!';
        // } else if (Number(error.status) == 500) {
        //   this.errorMsg = 'Internal Error';
        // } else {
        //   this.errorMsg = 'Please Try After Some Time!.';
        // }

        // this.addSingle();
      }
    );
    
  }
  change1(event) {
    if(!this.isEditMode){
      if(event){
    this.addFileForm.controls['resourcesId'].setValue(event.trim()?.toLowerCase().replaceAll(" ","_"));
      }
    }
  }
  EditReport() {
    this.title ='';
    this.fileloader=false;
    this.fileloaderDisable=false
    // this.addFileForm.get('exportType').setValue('pdf')
    this.showDataSource=false;
    this.isLoadingModal = true;
    this.addFileForm.reset();
    this.isEditMode=true;
    this.load=true;
    this.title = 'Edit Report ' + this.datatoedit['authorizationName'];
    this.addFileForm.get('file').setValidators([]);
    this.addFileForm.get('file').updateValueAndValidity();
    this.addFileForm.get('exportType').setValidators([Validators.required]);
    this.addFileForm.get('exportType').updateValueAndValidity();
    //this.addFileForm.reset();
    this.fileName = '';
    this.cancel('addReport');
    let ResponseData = [];
    this.showAddReport = true;
    this.exportType = [];
    this.uploadedFile = '';
    this.schedule = false;
    this.run = false;
    this.eventBased = false;
    this.fileTypes = [
      { name: 'pdf', code: 'pdf' },
      { name: 'xls', code: 'xls' },
      { name: 'xlsx', code: 'xlsx' },
      { name: 'doc', code: 'doc' },
      { name: 'ppt', code: 'pptx' },
      { name: 'csv', code: 'csv' },
      { name: 'text(tab delimited)', code: 'txt' },
      { name: 'textpipe delimited)', code: 'txt-pipe' },
      { name: 'log', code: 'log' },
    ];
    
    this.myRepoService.getreportType().subscribe(
      (Response) => {
        this.reportsList = Response.body;
        this.isLoadingModal = false;
      },
      (error) => {
        // d3.selectAll('.tibo-mydash-drillthru-spinner').style('display', 'none'); // hidding reports container spinner
        // if (Number(error.status) == 401) {
        //   this.errorMsg = 'Please Try After Some Time!!';
        // } else if (Number(error.status) == 500) {
        //   this.errorMsg = 'Internal Error';
        // } else {
        //   this.errorMsg = 'Please Try After Some Time!.';
        // }
        this.isLoadingModal = false;
        // this.addSingle();
      }
    );
    this.myRepoService
      .editReportData(this.datatoedit['authorizationId'])
      .subscribe(
        (Response) => {
          ResponseData = Response.body['dynamicFields'];
          this.ButtonName = 'Update';
          this.title =
            'Edit Report ' +
            this.datatoedit['authorizationName'];
            this.reportName= this.datatoedit['authorizationName']
          
          ResponseData.forEach((d) => {
            if (d.name == 'exportType') {
              const result = d.value.toLowerCase().replace(/_/g, ' ').split(',');
              this.exportType = result;
            }
          });
          ResponseData.forEach((d) => {
            if (d.name == 'reportCriteria') {
              this.reportCriteria = d.value;
            }
          });
          ResponseData.forEach((d) => {
            if (d.name == 'parameters') {
              this.reportParameter = d.value;
            }
          });
          ResponseData.forEach((d) => {
            if (d.name == 'reportType') {
              this.reportType = d.value;
            }
          });
          if(this.datatoedit['authorizationName'].endsWith('.sql')){
            this.showDataSource=true;
            let reqpayload={filterType:"and",filters:[],sort:[]}
            this.myRepoService.getDataSource(reqpayload).subscribe((res:any)=>{
              this.dataSourceList=res?.body?.records;
              ResponseData.forEach((d) => {
                if (d.name == 'dataSourceId'){
                  this.dataSource=this.dataSourceList.filter(item=>item.dataSourceId==d.value);
                  //console.log(this.dataSource)
                  this.addFileForm.controls['dataSource'].setValue(this.dataSource[0].dataSourceId);
                }
              })
             })
             const includesXls = this.exportType.includes('xls');
             const includesXlsx = this.exportType.includes('xlsx');
             this.showHeader = includesXls || includesXlsx;
          }
          ResponseData.forEach((d) => {
            if (d.name == 'clientCode') {
              this.clientList = [{userId:"0",clientCode:"0",clientName:'ALL'}];
              if(d.value == 0){
                this.client=this.clientList[0].clientCode;
              }
              this.myRepoService.getSearch().subscribe((res:any)=>{
                  this.load=false;
                  if(res?.body?.length>0){
                    this.clientList.push(...res?.body)
                    this.client=this.clientList.filter(item=>item.clientCode==d.value);
                    //console.log(this.client)
                    this.addFileForm.controls['client'].setValue(this.client[0].clientCode);
                  }
                  },(error)=>{
                    this.load=false;
                  });
                 
            }
          });
          
          ResponseData.forEach((d) => {
            if (d.name == 'isEventBased') {
              if (d.value === 'Y') {
                this.eventBased = true;
              } else {
                this.eventBased = false;
              }
            }
          });
          ResponseData.forEach((d) => {
            if (d.name == 'isSchedulable') {
              if (d.value === 'Y') {
                this.schedule = true;
              } else {
                this.schedule = false;
              }
            }
          });
          ResponseData.forEach((d) => {
            if (d.name == 'isHeader') {
              if (d.value === 'Y') {
                this.header = true;
              } else {
                this.header = false;
              }
            }
          });
          ResponseData.forEach((d) => {
            if (d.name == 'isRunnable') {
              if (d.value === 'Y') {
                this.run = true;
              } else {
                this.run = false;
              }
            }
          });
          this.addFileForm.controls['exportType'].setValue(this.exportType);
          this.addFileForm.controls['reportName'].setValue(
            this.datatoedit['authorizationName'].split('.')[0]
          );
          this.addFileForm.controls['resourcesId'].setValue(
            this.datatoedit['resourceId']
          );
          if(this.datatoedit['authorizationDesc']==null || this.datatoedit['authorizationDesc']=="null" ){
            this.datatoedit['authorizationDesc']="";
          }
          this.addFileForm.controls['description'].setValue(
            this.datatoedit['authorizationDesc']?this.datatoedit['authorizationDesc']:""
          );
          this.addFileForm.controls['reportType'].setValue(this.reportType);
          this.addFileForm.controls['run'].setValue(this.run);
          this.addFileForm.controls['schedule'].setValue(this.schedule);
          this.addFileForm.controls['eventBased'].setValue(this.eventBased);
          this.addFileForm.controls['header'].setValue(this.header);
          this.addFileForm.controls['reportCriteria'].setValue(
            this.reportCriteria
          );
          this.addFileForm.controls['reportParameter'].setValue(
            this.reportParameter
          );
          this.addFileForm.controls.resourcesId.disable();
          //console.log(this.addFileForm)
        },
        (error) => {
          // d3.selectAll('.tibo-mydash-drillthru-spinner').style(
          //   'display',
          //   'none'
          // ); // hidding reports container spinner
          // if (Number(error.status) == 401) {
          //   this.errorMsg = 'Please Try After Some Time!!';
          // } else if (Number(error.status) == 500) {
          //   this.errorMsg = 'Internal Error';
          // } else {
          //   this.errorMsg = 'Please Try After Some Time!.';
          // }

          // this.addSingle();
        }
      );
  }
  onFileChange(event) {
    this.uploadedFile = '';
    this.fileloaderDisable=true
    this.addFileForm.get('dataSource').setValidators([]);
    this.addFileForm.get('dataSource').updateValueAndValidity();
    this.fileName = event.target.files[0].name;
    var ext = this.fileName.substring(this.fileName.lastIndexOf('.') + 1);
    //console.log(ext)
    // if (ext != 'rptdesign') {
    //   alert(this.fileName + 'cannot be uploaded');
    //   this.fileTypes = [];
    //   this.showFile = true;
    //   this.sowFileName = false;
    // } 
    // else {
      if(ext=='sql'){
        this.showDataSource=true;
        this.addFileForm.get('dataSource').setValidators([Validators.required]);
        this.addFileForm.get('dataSource').updateValueAndValidity();
        let reqpayload={filterType:"and",filters:[],sort:[]}
        this.myRepoService.getDataSource(reqpayload).subscribe((res:any)=>{
          this.dataSourceList=res?.body?.records;
        })
        // const includesXls = this.exportType.includes('xls');
        // const includesXlsx = this.exportType.includes('xlsx');
        // this.showHeader = includesXls || includesXlsx;
      }
      const formdata = new FormData();
      formdata.append('uploadFile', event.target.files[0]);
      var payload = {
        uploadFile: formdata,
      };
      this.showFile = false;
      
      //this.fileName=event.target.files[0].name;
      this.fileTypes = [
        { name: 'pdf', code: 'pdf' },
        { name: 'xls', code: 'xls' },
        { name: 'xlsx', code: 'xlsx' },
        { name: 'doc', code: 'doc' },
        { name: 'ppt', code: 'pptx' },
        { name: 'csv', code: 'csv' },
        { name: 'TEXT(tab delimited)', code: 'txt' },
        { name: 'TEXT(pipe delimited)', code: 'txt-pipe' },
        { name: 'log', code: 'log' },
      ];
      if (this.ButtonName === 'Add') {
        if(ext.toLowerCase()=='rptdesign'){
          this.fileTypes = [
            { name: 'pdf', code: 'pdf' },
            { name: 'xls', code: 'xls' },
            { name: 'xlsx', code: 'xlsx' },
            { name: 'doc', code: 'doc' },
            { name: 'ppt', code: 'pptx' },
            { name: 'csv', code: 'csv' },
            { name: 'TEXT(tab delimited)', code: 'txt' },
            { name: 'TEXT(pipe delimited)', code: 'txt-pipe' }
          ];
          this.exportType = ['pdf', 'xls', 'xlsx', 'doc', 'pptx'];
        }
        if(ext.toLowerCase()=='sh'){
          this.fileTypes = [
            { name: 'log', code: 'log' },
          ];
          this.exportType = ['log'];
        }
        if(ext.toLowerCase()=='sql'){
          this.fileTypes = [
            { name: 'pdf', code: 'pdf' },
            { name: 'xls', code: 'xls' },
            { name: 'xlsx', code: 'xlsx' },
            { name: 'doc', code: 'doc' },
            { name: 'ppt', code: 'pptx' },
            { name: 'csv', code: 'csv' },
            { name: 'TEXT(tab delimited)', code: 'txt' },
            { name: 'TEXT(pipe delimited)', code: 'txt-pipe' }
          ];
          this.exportType = ['pdf', 'xls', 'xlsx', 'doc', 'pptx'];
          const includesXls = this.exportType.includes('xls');
          const includesXlsx = this.exportType.includes('xlsx');
          this.showHeader = includesXls || includesXlsx;
        }
      }
      this.fileloader=true;
      this.myRepoService.setfileType(formdata).subscribe((Response) => {
        if(Response){
        this.fileloader=false;
        this.fileloaderDisable=false;
        this.sowFileName = true;
        this.uploadedFile = Response.body['uniqueName'];
        }else{
          this.fileloader=false;
          this.fileloaderDisable=false
        this.uploadedFile ="";
        this.fileName ='';
        }
      },
    (error)=>{
      this.fileloader=false;
        this.fileloaderDisable=false
        this.uploadedFile ="";
        this.fileName ='';
    });
    // }
    event.target.value = null;
  }
  changereport(event) { }
  changeExport(event) { 
    if(this.showDataSource===true){
      const includesXls = this.exportType.includes('xls');
      const includesXlsx = this.exportType.includes('xlsx');
      this.showHeader = includesXls || includesXlsx;
    }
    if(this.showHeader===true ){
      this.header=true;
    }
    else{
      this.header=false;
    }

  }
  changeClient(event) {
   }
  sceduleChange(event) { }
  runChange(event) { }
  eventChange(event) { }
  headerChange(event){}
  changeSource(event){}
  addReportData() {
    this.fileloader=false;
    if(this.addFileForm.valid){
    this.btnSpinner = 'pi pi-spin pi-spinner';
    if (this.ButtonName == 'Add') {
      this.reportName = this.addFileForm.controls.reportName.value.trim();
      this.reqPayload.authorizationName = this.reportName;
      this.reportCriteria = this.addFileForm.controls.reportCriteria.value;
      this.reqPayload.reportCriteria = this.reportCriteria;
      this.resourcesId = this.addFileForm.controls.resourcesId.value.trim();
      this.reqPayload.resourceId = this.resourcesId;
      this.reportParameter = this.addFileForm.controls.reportParameter.value;
      this.reqPayload.parameters = this.reportParameter;
      this.description = this.addFileForm.controls.description.value;
      this.reqPayload.authorizationDesc= this.description?this.description.trim():'';
      this.reportType = this.addFileForm.controls.reportType.value;
      this.reqPayload.reportType = this.reportType;
      if(this.showDataSource==true){
        this.dataSource = this.addFileForm.controls.dataSource.value;
        //console.log(this.dataSource)
        this.reqPayload.dataSourceId= this.dataSource;
      }
      // const includesXls = this.addFileForm.controls.exportType.value.includes('xls');
      // const includesXlsx = this.addFileForm.controls.exportType.value.includes('xlsx');
      // this.showHeader = includesXls || includesXlsx;
     // this.exportType = this.addFileForm.controls.exportType.value.toString();
      this.reqPayload.exportType = this.addFileForm.controls.exportType.value.toString();
      this.client = this.addFileForm.controls.client.value;
      this.reqPayload.clientCode = this.client;
      this.clientList.forEach(element => {
        if(element.clientCode==this.client){
          this.reqPayload.clientName = element.clientName;
        }
      });
      this.schedule = this.addFileForm.controls.schedule.value;
     
      if (this.schedule === true) {
        this.reqPayload.isSchedulable = 'Y';
      } else {
        this.reqPayload.isSchedulable = 'N';
      }
      this.run = this.addFileForm.controls.run.value;
      if (this.run === true) {
        this.reqPayload.isRunnable = 'Y';
      } else {
        this.reqPayload.isRunnable = 'N';
      }
      if (this.header === true) {
        this.reqPayload.isHeader = 'Y';
      } else {
        this.reqPayload.isHeader = 'N';
      }
      this.eventBased = this.addFileForm.controls.eventBased.value;
      if (this.eventBased === true) {
        this.reqPayload.isEventBased = 'Y';
      } else {
        this.reqPayload.isEventBased = 'N';
      }
      this.reqPayload.uploadFile = this.uploadedFile;
      this.myRepoService
        .setAddReport(this.authorizationId, this.reqPayload)
        .subscribe(
          (Response) => {
            this.showAddReport = false;
            this.recordsSubscrip = this.myRepoService
              .recordsService(
                this.authorizationId,
                this.paginatorPageNo,
                this.reqPayload,
                this.depth
              )
              .subscribe(
                (recordsResp: any) => {
                  // records
                  d3.select('.tibo-myrepo-reports-spinner').style(
                    'display',
                    'none'
                  ); // hidding reports container spinner
                  this.totalRecords = recordsResp.body?.totalRecords;
                  recordsResp.body?.records
                    ? (this.myReportRecords = recordsResp.body.records)
                    : (this.myReportRecords = []);
                  this.myReportRecords;
                //   this.totRecord=this.totalRecords;
                // this.myRecord=this.myReportRecords;
                  this.myReportRecords.length > 0
                    ? (this.tableEmptyMessage = '')
                    : (this.tableEmptyMessage = 'No Records Found');
                    this.startIndex=this.myReportRecords.length>0?1:0;
                this.endIndex=this.myReportRecords.length>0?this.myReportRecords.length:0;
                  this.isLoading = false;
                  this.messageService.add({
                    severity: 'info',
                    summary: 'Added',
                    detail: 'Successfully Added',
                  });
                  this.btnSpinner = '';
                },
                (error) => {
                  this.btnSpinner = '';
                  this.exportType = [];
                  if (Number(error.status) == 401) {
                    this.errorMsg = 'Please Try After Some Time!!';
                  } else if (Number(error.status) == 500) {
                    this.errorMsg = 'Internal Error';
                  } else {
                    this.errorMsg = 'Please Try After Some Time!.';
                    
                  }
                  this.isLoading = false;
                  this.startIndex=0;
                  this.endIndex=0;
                  this.addSingle();
                },
                () => { }
              );
          },
          (error) => {
            this.btnSpinner = '';
            if (Number(error.status) == 409) {
              this.messageService.add({
                severity: 'error',
                summary: 'The Resource ID was already taken',
                detail: 'Please try with different Resource ID',
              });
            }
          }
        );
    } else if (this.ButtonName == 'Update') {
      this.reqPayload.authorizationName = this.addFileForm.controls.reportName.value.trim();
      this.reqPayload.reportCriteria = this.addFileForm.controls.reportCriteria.value;
      this.reqPayload.resourceId = this.resourcesId;
      this.reqPayload.parameters = this.addFileForm.controls.reportParameter.value;
      this.reqPayload.authorizationDesc = this.addFileForm.controls.description.value?this.addFileForm.controls.description.value.trim():'';
      this.reqPayload.reportType = this.addFileForm.controls.reportType.value;
      this.reqPayload.exportType = this.addFileForm.controls.exportType.value.toString();
      this.reqPayload.clientCode = this.addFileForm.controls.client.value;
      // this.reqPayload.clientCode = this.client;
      this.clientList.forEach(element => {
        if(element.clientCode==this.addFileForm.controls.client.value){
          this.reqPayload.clientName = element.clientName;
        }
      });
      if(this.showDataSource==true){
        this.dataSource = this.addFileForm.controls.dataSource.value;
        //console.log(this.dataSource)
        this.reqPayload.dataSourceId= this.dataSource;
      }
      this.schedule = this.addFileForm.controls.schedule.value;
      if (this.schedule === true) {
        this.reqPayload.isSchedulable = 'Y';
      } else {
        this.reqPayload.isSchedulable = 'N';
      }
      this.run = this.addFileForm.controls.run.value;
      if (this.run === true) {
        this.reqPayload.isRunnable = 'Y';
      } else {
        this.reqPayload.isRunnable = 'N';
      }
      this.eventBased = this.addFileForm.controls.eventBased.value;
      if (this.eventBased === true) {
        this.reqPayload.isEventBased = 'Y';
      } else {
        this.reqPayload.isEventBased = 'N';
      }
      this.header = this.addFileForm.controls.header.value;
      if (this.header === true) {
        this.reqPayload.isHeader = 'Y';
      } else {
        this.reqPayload.isHeader = 'N';
      }
      this.reqPayload.uploadFile = this.uploadedFile;
      this.myRepoService
        .UpdateAddReport(this.datatoedit['authorizationId'], this.reqPayload)
        .subscribe(
          (Response) => {
            this.showAddReport = false;
            this.recordsSubscrip = this.myRepoService
              .recordsService(
                this.authorizationId,
                this.paginatorPageNo,
                this.requestPayload,
                this.depth
              )
              .subscribe(
                (recordsResp: any) => {
                  this.totalRecords = recordsResp.body?.totalRecords;
                  recordsResp.body?.records
                    ? (this.myReportRecords = recordsResp.body.records)
                    : (this.myReportRecords = []);
                  this.myReportRecords;
                //   this.totRecord=this.totalRecords;
                // this.myRecord=this.myReportRecords;
                  this.myReportRecords.length > 0
                    ? (this.tableEmptyMessage = '')
                    : (this.tableEmptyMessage = 'No Records Found');
                    this.startIndex=this.myReportRecords.length>0?1:0;
                this.endIndex=this.myReportRecords.length>0?this.myReportRecords.length:0;
                  this.isLoading = false;
                  this.datatoedit = this.myReportRecords[this.getTableRowIndex];
                  this.messageService.add({
                    severity: 'success',
                    summary: 'Updated',
                    detail: 'Successfully Updated',
                  });
                  this.btnSpinner = '';
                },
                (error) => {
                  this.btnSpinner = '';
                  if (Number(error.status) == 401) {
                    this.errorMsg = 'Please Try After Some Time!!';
                  } else if (Number(error.status) == 500) {
                    this.errorMsg = 'Internal Error';
                  } else {
                    this.errorMsg = 'Please Try After Some Time!.';
                  }
                  this.isLoading = false;
                  this.startIndex=0;
                  this.endIndex=0;
                  this.addSingle();
                },
                () => { }
              );
          },
          (error) => {
            this.btnSpinner = '';
            if (Number(error.status) == 401) {
              this.errorMsg = 'Please Try After Some Time!!';
            } else if (Number(error.status) == 500) {
              this.errorMsg = 'Internal Error';
            } else {
              this.errorMsg = 'Please Try After Some Time!.';
            }

            this.addSingle();
          }
        );
    }
    // else{
    //   this.messageService.add({
    //     severity: 'info',
    //     summary: 'error',
    //     detail: 'Successfully Added',
    //   });
    // }
  }
  }
  cancel(value) {
    if (value === 'addReport') {
      this.addFileForm.reset();
      this.fileName = '';
      this.fileTypes = [];
      this.showFile = true;
      this.sowFileName = false;
      this.showAddReport = false;
    }
    if (value === 'addFolder') {
      this.addForm.reset();
    }
  }
  RemoveFile() {
    this.fileName = '';
    this.fileTypes = [];
    this.showFile = true;
    this.sowFileName = false;
    //this.addFileForm.controls['file'].reset();
    this.addFileForm.controls['file'].setValue('');
    this.showDataSource=false;
  }
  removeDuplicates(data: any) {
    return data.filter(
      (value: any, index: any) => data.indexOf(value) === index
    );
  }
  dropDownFun(value: any) {
    if (value.length === 0) {
      this.searchFieldDisable = true;
    } else {
      this.searchFieldDisable = false;
    }
    
  }
  PermissionFolder() {
    this.checked1 = false;
    this.checked2 = false;
    this.showPermissionDialog = true;
    this.title = 'Manage The Permissions For ' + this.selectedFile?.label;
    this.selectedRole = this.permissionOptions[0].value;
    this.permissionFunc();
    // this.isLoadingModal = true;
  }
  permissionFunc() {
    this.isLoadingModal = true;
    this.isRoleDisable=true;
      this.isUserDisable=true;
    if (this.selectedRole == 'role') {
      this.selectedList1 = [];
      this.items1 = [];
      var arr = [];
      this.userShow = false;
      this.isRoleDisable=true;
      this.isUserDisable=false;
      this.roleShow = true;
      this.myRepoService
        .getPermission(this.authorizationId, this.selectedRole)
        .subscribe(
          (res: any) => {
            this.items1 = !res.body
              ? (this.selectedList1 = [])
              : (this.selectedList1 = res.body);
            this.isLoadingModal = false;
            this.isRoleDisable=false;
          },
          (error) => {
            this.isLoadingModal = false;
            this.isRoleDisable=false;
          }
        );
    } else if (this.selectedRole == 'user') {
      this.selectedList2 = [];
      this.items2 = [];
      var arr = [];
      this.roleShow = false;
      this.userShow = true;
      this.isRoleDisable=false;
      this.isUserDisable=true;
      this.myRepoService
        .getPermission(this.authorizationId, this.selectedRole)
        .subscribe(
          (res: any) => {
            this.items2 = !res.body
              ? (this.selectedList2 = [])
              : (this.selectedList2 = res.body);
            this.isLoadingModal = false;
            this.isUserDisable=false;
          },
          (error) => {
            this.isLoadingModal = false;
            this.isUserDisable=false;
          }
        );
    }
  }
  filterItems1(event) {
    let filtered: any[] = [];
    let query = event.query;
    let searchPayload = {
      sort: [
        {
          property: 'roleName',
          dir: 'ASC',
        },
      ],
      filters: [
        {
          property: 'roleName',
          operator: 'startw',
          value: query,
        },
      ],
    };
    this.myRepoService.searchRole(searchPayload).subscribe((res) => {
      let arr: [] = res.body['records'];
      for (let i = 0; i < arr.length; i++) {
        let country: {} = arr[i];
        if (
          country['roleName'].toLowerCase().indexOf(query.toLowerCase()) == 0
        ) {
          filtered.push(country);
        }
      }
      let result = filtered.filter(
        (o1) => !this.selectedList1.some((o2) => o1.roleId === o2.roleId)
      );
      this.itemList1 = result;
    },
    (error)=>{
      this.isLoadingModal=false;
      this.itemList1 =[]
    });
  }
  filterItems2(event) {
    let filtered: any[] = [];
    let query = event.query;
    let searchPayload = {
      sort: [
        {
          property: 'userName',
          dir: 'ASC',
        },
      ],
      filters: [
        {
          property: 'userName',
          operator: 'startw',
          value: query,
        },
      ],
    };
    this.myRepoService.searchUser(searchPayload).subscribe((res) => {
      let arr: [] = res.body['records'];
      for (let i = 0; i < arr.length; i++) {
        let country: {} = arr[i];
        if (
          country['userName'].toLowerCase().indexOf(query.toLowerCase()) == 0
        ) {
          filtered.push(country);
        }
      }
      let result = filtered.filter(
        (o1) => !this.selectedList2.some((o2) => o1.userId === o2.userId)
      );
      this.itemList2 = result;
    },
  (error)=>{
    this.isLoadingModal=false;
    this.itemList2 =[]
  });
  }
  onAssignClick1() {
    if(!this.isRoleDisable){
    let payload = {};
    let arr = [];
    this.selectedList1.forEach((e) => {
      arr.push(e.roleId);
    });
    if (this.checked1 == true) {
      payload = {
        type: 'role',
        id: arr,
        authorizationId: [this.authorizationId],
        applyToChildren: 'Y',
      };
    } else {
      payload = {
        type: 'role',
        id: arr,
        authorizationId: [this.authorizationId],
      };
    }
    this.myRepoService.assignReport(payload).subscribe((res) => { });
    this.showPermissionDialog = false;
  }
  }
  onAssignClick2() {
    if(!this.isUserDisable){
    let payload = {};
    let arr = [];
    this.selectedList2.forEach((e) => {
      arr.push(e.userId);
    });
    if (this.checked2 == true) {
      payload = {
        type: 'user',
        id: arr,
        authorizationId: [this.authorizationId],
        applyToChildren: 'Y',
      };
    } else {
      payload = {
        type: 'user',
        id: arr,
        authorizationId: [this.authorizationId],
      };
    }
    this.myRepoService.assignReport(payload).subscribe((res) => { });
    this.showPermissionDialog = false;
  }
  }
  PermissionReport() {
    this.showPermissionReportDialog = true;
    this.title =
      'Manage The Permissions For ' +
      this.datatoedit['authorizationName'].replaceAll("_"," ").split('.')[0] +" "+
      ' Report';
    this.selectedReportRole = this.permissionReportOptions[0].value;
    this.isLoadingModal = true;
    this.permissionReportFunc();
  }
  permissionReportFunc() {
    this.isLoadingModal = true;
    if (this.selectedReportRole == 'role') {
      this.selectedReportList1 = [];
      this.itemsReport1 = [];
      var arr = [];
      this.userReportShow = false;
      this.roleReportShow = true;
      this.myRepoService
        .getPermission(this.datatoedit.authorizationId, this.selectedReportRole)
        .subscribe(
          (res: any) => {
            this.itemsReport1 = !res.body
              ? (this.selectedReportList1 = [])
              : (this.selectedReportList1 = res.body);
            this.isLoadingModal = false;
          },
          (error) => {
            this.isLoadingModal = false;
          }
        );
    } else if (this.selectedReportRole == 'user') {
      this.selectedReportList2 = [];
      this.itemsReport2 = [];
      var arr = [];
      this.userReportShow = true;
      this.roleReportShow = false;
      this.myRepoService
        .getPermission(this.datatoedit.authorizationId, this.selectedReportRole)
        .subscribe(
          (res: any) => {
            this.itemsReport2 = !res.body
              ? (this.selectedReportList2 = [])
              : (this.selectedReportList2 = res.body);
            this.isLoadingModal = false;
          },
          (error) => {
            this.isLoadingModal = false;
          }
        );
    }
  }
  filterReportItems1(event) {
    let filtered: any[] = [];
    let query = event.query;
    let searchPayload = {
      sort: [
        {
          property: 'roleName',
          dir: 'ASC',
        },
      ],
      filters: [
        {
          property: 'roleName',
          operator: 'startw',
          value: query,
        },
      ],
    };
    this.myRepoService.searchRole(searchPayload).subscribe((res) => {
      let arr: [] = res.body['records'];
      for (let i = 0; i < arr.length; i++) {
        let country: {} = arr[i];
        if (
          country['roleName'].toLowerCase().indexOf(query.toLowerCase()) == 0
        ) {
          filtered.push(country);
        }
      }
      let result = filtered.filter(
        (o1) => !this.selectedReportList1.some((o2) => o1.roleId === o2.roleId)
      );
      this.itemReportList1 = result;
    });
  }
  filterReportItems2(event) {
    let filtered: any[] = [];
    let query = event.query;
    let searchPayload = {
      sort: [
        {
          property: 'userName',
          dir: 'ASC',
        },
      ],
      filters: [
        {
          property: 'userName',
          operator: 'startw',
          value: query,
        },
      ],
    };
    this.myRepoService.searchUser(searchPayload).subscribe((res) => {
      let arr: [] = res.body['records'];
      for (let i = 0; i < arr.length; i++) {
        let country: {} = arr[i];
        if (
          country['userName'].toLowerCase().indexOf(query.toLowerCase()) == 0
        ) {
          filtered.push(country);
        }
      }
      let result = filtered.filter(
        (o1) => !this.selectedReportList2.some((o2) => o1.userId === o2.userId)
      );
      this.itemReportList2 = result;
    });
  }
  onReportAssignClick1() {
    let payload = {};
    let arr = [];
    this.selectedReportList1.forEach((e) => {
      arr.push(e.roleId);
    });
    if (this.checkedReport1 == true) {
      payload = {
        type: 'role',
        id: arr,
        authorizationId: [this.datatoedit['authorizationId']],
        applyToChildren: 'Y',
      };
    } else {
      payload = {
        type: 'role',
        id: arr,
        authorizationId: [this.datatoedit['authorizationId']],
      };
    }
    this.myRepoService.assignReport(payload).subscribe((res) => { });
    this.showPermissionReportDialog = false;
  }
  onReportAssignClick2() {
    let payload = {};
    let arr = [];
    this.selectedReportList2.forEach((e) => {
      arr.push(e.userId);
    });
    if (this.checkedReport2 == true) {
      payload = {
        type: 'user',
        id: arr,
        authorizationId: [this.datatoedit['authorizationId']],
        applyToChildren: 'Y',
      };
    } else {
      payload = {
        type: 'user',
        id: arr,
        authorizationId: [this.datatoedit['authorizationId']],
      };
    }
    this.myRepoService.assignReport(payload).subscribe((res) => { });
    this.showPermissionReportDialog = false;
  }
  onScheduleBtnEditClick(col) {
    col['type'] = 'Edit';
    this.router.navigate(['scheduler'], { queryParams: { reportId: col.reportId, name: col.reportName, authDesc: col.authDesc,component:"my-reports" }});
  }
  onScheduleBtnCopyClick(col) {
    col['type'] = 'Copy';
    this.router.navigate(['scheduler'], { queryParams: { reportId: col.reportId, name: col.reportName, authDesc: col.authDesc,component:"my-reports" }});
  }
  showScheduledHistory(jabName: string) {
    this.router.navigate(['schedule-history'], {
      queryParams: { jb: jabName },
    });
  }
  showReportInfoBtnClick(reportId,resourceId) {
    this.fileUploadResourceId=resourceId;
    this.ReportId = reportId
    // this.isLoadingModalInfo = true;
    this.selectedOption = this.showAdminfunctionality?'upload':'specification';
    this.selectedFileName = '';
    this.title1 ='';
    this.subName1 = '';
    this.uploadForm.reset();
    this.showReportInformationDialog = true;
    this.getSpecificationReport(reportId);
    this.getSpecificationHistoryReport(reportId);
    this.getspecificationWhoElseUsesReport(reportId);
    this.reportCatalogueInfo = [];
    // this.reporCatalogueInfotDialogVisible = true;
    this.myRepoService.reportInfoService(reportId).subscribe((res:any) => {
      this.reportCatalogueInfo = res?.body;
      this.isLoadingModalInfo = false;
    })
  }
  setOption(option) {
    this.selectedOption = option;
    this.uploadedFile1 = "";
    this.selectedFileName='';
    this.uploadForm.reset();
    this.conflictError=false;
  }
  getSpecificationReport(reportId) {
    this.specificationReport =[]
    const requestPayload = {
      sort: [{ property: "createdDate", dir: "DESC" }],
      filters: []
    }
    this.myRepoService.specificationReport(reportId, requestPayload).subscribe((res) => {
      this.specificationReport = res.body['records'];
    })
  }
  getSpecificationHistoryReport(reportId) {
    this.specificationHistoryReport =[]
    const requestPayload = {
      sort: [{ property: "createdDate", dir: "DESC" }],
      filters: [], filterType: "and"
    }
    this.myRepoService.specificationHistoryReport(reportId, requestPayload).subscribe((res) => {
      this.specificationHistoryReport = res.body['records'];
    })
  }
  getspecificationWhoElseUsesReport(reportId) {
    const requestPayload = {
      sort: [{ property: "userName", dir: "ASC" }],
      filters: []
    }
    this.myRepoService.specificationWhoElseUsesReport(reportId, requestPayload).subscribe((res) => {
      this.specificationWhoElseUsesReport = res.body['values'];
    })
  }
  showActionIconsFun(value: string, index: number) {
    if (value === 'show') {
      this.showActionIcons = true;
      this.clickedIndex=index;
    } else {
      this.showActionIcons = false;
    }
    this.actionIconsIndex = index;
    
  }
  @HostListener('click') clicked() {
    if (this.showActionIcons) {
      this.showActionIconsFun('hide', -1);
    }
  }
  // dynamicWidth(field: string, headerName: string): string {
  //   const minWidth = 180; 
  //   const contentWidth = Math.max(
  //     ...this.myReportRecords.map(item => String(item[field]).length)
  //   );
  //   const headerWidth = headerName.length;
  //   const maxWidth = Math.max(contentWidth, headerWidth);
  //   const calculatedWidth = maxWidth * 8;
  //   //console.log(calculatedWidth, minWidth)
  //   return `${Math.max(calculatedWidth, minWidth)}px`;
  // }
}
