<div class="tibo-myreport-container-wrapper tibo-right-container build-report-parent-container">
    <div id="myrepo-scroll-wrapper">
        <div *ngIf="showreport">
            <!-- <img src="././assets/images/dualBallSpinner.svg" alt="" class="spinner-img" *ngIf="isLoading"> -->
            <!-- <div *ngIf="headerbtn" class="tibo-mydash-dashboard-title" id="full-screen-mode-btn">{{ dashboardName }} -->
                <button class="tibo-mydash-chartcoloroptions-btn" type="button" pButton icon="pi pi-angle-left"
                    (click)="back()" pTooltip="Back to Search"></button>
            <!-- </div> -->
        </div>
        <div class="container-build-report p-d-flex">
            <div *ngIf="isLoading2" class="spinner-overlay">
                <img src="././assets/images/dualBallSpinner.svg" alt="" class="modal-spinner-img">
            </div>
            <div class="side-menu" [ngClass]="{'side-menu-collapsed':sideMenuCollapse || isLoading2}">
                <img src="././assets/images/dualBallSpinner.svg" alt="" class="modal-spinner-img" *ngIf="isLoading">
                <div class="tibo-myrepo-folderview-panel">
                    <div class="side-menu-header"
                        [ngClass]="{'p-d-flex':!sideMenuCollapse, 'side-menu-header-collapsed': sideMenuCollapse}">
                        <div class="header-first-section">
                            <h1 class="side-menu-h1" [ngClass]="{'side-menu-collapsed-h1':sideMenuCollapse}">Report
                                Parameter</h1>
                        </div>
                        <div class="header-second-section">
                            <i class='pi pi-angle-double-left side-menu-left-icon' *ngIf="!sideMenuCollapse"
                                (click)="folderPanelToggle()"></i>
                            <i class='pi pi-angle-double-right side-menu-right-icon' *ngIf="sideMenuCollapse"
                                (click)="folderPanelToggle()"></i>
                        </div>
                    </div>
                    <p-scrollPanel id="myreport-folder-panel-scroll-div"
                        styleClass="tibo-myrepo-folderview-panel-scrollpanel" *ngIf="!sideMenuCollapse">
                        <div class="scrollable pagecontent" resize="" *ngIf="!folderCollapse">
                            <ng-container>
                                <div class="tibo-buildrepo-repofolders-tree-cont">
                                    <div class="rows">
                                        <div class="list">
                                            <div> <label class="list-group-item title">Build A Report For<span
                                                        class="required">*</span></label>
                                            </div>
                                            <div class="p-field p-col-12" id="shepherd-reportFor">
                                                <p-dropdown [options]="listDomains" appendTo="body"
                                                    optionLabel="authorizationName" optionValue="authorizationId"
                                                    [(ngModel)]="selectedDomain" [attr.selectInfoIndex]=i
                                                    [filter]="true" (onChange)="dropdownChange1($event)"
                                                    placeholder="Select"
                                                    [dropdownIcon]="load? 'pi pi-spinner pi-spin' : 'pi pi-chevron-down'"
                                                    #refElm>
                                                </p-dropdown>
                                            </div>
                                        </div>
                                        <div class="textClass1" *ngIf="showDemand" id="shepherd-reportType"> <label> <input type="radio"
                                                    [(ngModel)]="selectedDemandValue"
                                                    class="ng-valid ng-dirty ng-touched" name="button"
                                                    [checked]="demand" value="onDemand"
                                                    (change)="DemandChange($event.target.value)" #demandRadio>
                                                On
                                                Demand
                                            </label>&nbsp;&nbsp;&nbsp; <label> <input type="radio"
                                                    [(ngModel)]="selectedDemandValue" name="button" value="schedule"
                                                    (click)="confirm()" #scheduleRadio>
                                                Schedule </label> </div>
                                        <div *ngIf="showText" class="textClass">No Report Parameters Found</div>
                                    </div>
                                </div>
                            </ng-container>
                            <ng-container>
                                <div class="tibo-buildrepo-repofolders-tree-cont">
                                    <div class="rows">

                                        <div class="list" *ngIf="showParameter">
                                            <form [formGroup]="form" novalidate id="shepherd-runReport">
                                                <div *ngFor="let d of mainData;index as i">
                                                    <div *ngIf="d.showElement">
                                                        <label class="title" [for]="d.MainLabel">
                                                            {{pascalCaseWithSpace(d.MainLabel)}}<span
                                                                class="required">*</span></label>
                                                        <div [ngSwitch]=d.inputtype>
                                                            <div *ngSwitchCase="'select'">
                                                                <div class="p-field p-col-12">
                                                                    <p-dropdown [attr.id]="d.id" appendTo="body"
                                                                        [options]="d.options" optionLabel="displayName"
                                                                        optionValue="value" [(ngModel)]="d.selectedData"
                                                                        [formControlName]="d.formname"
                                                                        placeholder="{{d.MainLabel}}"
                                                                        [attr.selectInfoIndex]=i id="d.id"
                                                                        [filter]="true"
                                                                        (onChange)="onSelected(d.id,d.selectedData,i,d.inputtype)"
                                                                        required #refElm>
                                                                    </p-dropdown>
                                                                </div>
                                                            </div>
                                                            <div *ngSwitchCase="'radio'" class="radio-class-side-menu">
                                                                <div *ngFor="let option of  d.options"
                                                                    class="p-field-checkbox">
                                                                    <p-radioButton [attr.id]="d.id" appendTo="body"
                                                                        [formControlName]="d.formname"
                                                                        [name]="d.formname" [value]="option.value"
                                                                        [(ngModel)]="d.selectedData" id="d.id"
                                                                        (onClick)="onSelected(option.id,d.selectedData,i,d.inputtype)"
                                                                        required #refElm>
                                                                    </p-radioButton>
                                                                    <label [for]="option.id">{{option.value}}</label>
                                                                </div>
                                                            </div>
                                                            <div *ngSwitchCase="'text'">
                                                                <div class="p-field p-col-12">
                                                                    <input pInputText [formControlName]="d.formname"
                                                                        [type]="d.inputtype" appendTo="body"
                                                                        [name]="d.formname" id="d.id"
                                                                        [(ngModel)]="d.selectedData"
                                                                        (change)="onSelected(d.id,d.formname,i,d.inputtype)"
                                                                        class="form-input input-lg ">
                                                                </div>
                                                            </div>
                                                            <div *ngSwitchCase="'date'">
                                                                <div class="p-field p-col-12">
                                                                    <!-- <p-calendar [attr.id]="d.id" appendTo="body"
                                                                        [formControlName]="d.formname"
                                                                        [type]="d.inputtype" showButtonBar="true"
                                                                        [monthNavigator]="true" [yearNavigator]="true"
                                                                        yearRange="1000:3000"
                                                                        [(ngModel)]="d.selectedData" [showIcon]="true"
                                                                        [name]="d.formname" placeholder="MM/DD/YYYY"
                                                                        id="d.id" [readonlyInput]="false"
                                                                        [inputStyle]="{'pointer-events': 'none', 'background-color': '#f9f9f9'}"
                                                                        (onSelect)="onSelected(d.id,d.selectedData,i,d.inputtype)"
                                                                        (onClearClick)="onSelected(d.id,d.selectedData,i,d.inputtype)"
                                                                        (ngModelChange)="onManualInputChange(d.id,d.selectedData,i,d.inputtype)"
                                                                        required #refElm>
                                                                    </p-calendar> -->
                                                                    <p-calendar [attr.id]="d.id" appendTo="body"
                                                                        [formControlName]="d.formname"
                                                                        [type]="d.inputtype" showButtonBar="true"
                                                                        [monthNavigator]="true" [yearNavigator]="true"
                                                                        yearRange="1000:3000"
                                                                        [(ngModel)]="d.selectedData" [showIcon]="true"
                                                                        [name]="d.formname" placeholder="MM/DD/YYYY"
                                                                        id="d.id"
                                                                        (onSelect)="onSelected(d.id,d.selectedData,i,d.inputtype)"
                                                                        (onClearClick)="onSelected(d.id,d.selectedData,i,d.inputtype)"
                                                                        (onInput)="onManualInputChange(d.id,d.selectedData,i,d.inputtype)"
                                                                        required #refElm>
                                                                    </p-calendar>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div *ngIf=dateError style="color:red">End Date Should Be Greater Than Or Equal To Start Date</div>
                                                <div class="rows btn-row" id="shepherd-runAction">
                                                    <div class="list btn-list">
                                                        <p-button class="cancel-btn btn-class"
                                                            label="Save Report Parameters"
                                                            (click)="saveDialog()"></p-button>
                                                        <p-button class="cancel-btn btn-class" label=" Clear"
                                                            (click)="resetForm()"></p-button>
                                                        <p-button class="save-btn btn-class" label="Run"
                                                            (click)="run()"></p-button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>

                                    </div>
                                </div>
                                <!-- <div class="tibo-buildrepo-repofolders-tree-cont">
                                    <div class="rows btn-row">
                                        <div class="list btn-list">
                                            <p-button class="clear-btn btn-class" label="Save Report Parameters"
                                                (click)="saveDialog()"></p-button>
                                            <p-button class="clear-btn btn-class" label=" Clear"
                                                (click)="resetForm()"></p-button>
                                            <p-button class="save-btn btn-class" label="Run" [disabled]="!submit"
                                                (click)="run()"></p-button>
                                        </div>
                                    </div>
                                </div> -->
                            </ng-container>
                        </div>
                        <div *ngIf="showFilter">
                            <div class="side-menu-filter-list-div"
                                *ngIf="!sideMenuCollapse && savedFilterData?.length > 0">
                                <p-table [value]="savedFilterData" selectionMode="single" scrollable="true"
                                    scrollHeight="140px">
                                    <ng-template pTemplate="header">
                                        <tr>
                                            <th>Saved Report Parameters</th>
                                            <th class="action-class-th">Action</th>
                                        </tr>
                                    </ng-template>
                                    <ng-template let-savedFilterData pTemplate="body" let-i="rowIndex">
                                        <tr [pSelectableRow]="savedFilterData">
                                            <td (click)="FillFilterToForm(i)">
                                                <b>{{i+1}}. </b> <span class="filter-list-name">
                                                    {{savedFilterData.adhocSaveFilterName}}</span>
                                            </td>
                                            <td class="action-class-td">
                                                <i class="pi pi-trash exit-filter-icon" (click)="deleteFilter(i)"
                                                    pTooltip="Remove" tooltipPosition="top"></i>
                                            </td>
                                        </tr>
                                    </ng-template>
                                </p-table>
                            </div>
                        </div>
                    </p-scrollPanel>
                </div>
            </div>
            <div class="second-section">
                <!-- <div *ngIf="isLoading2" class="spinner-overlay">
                    <img src="././assets/images/dualBallSpinner.svg" alt="" class="modal-spinner-img">
                </div> -->
                <!-- <img src="././assets/images/dualBallSpinner.svg" alt="" class="spinner-img" *ngIf="isLoading2"> -->
                <div *ngIf="showGrid">
                    <div class="dropdownTitle">
                        <div class="p-field p-col-12">
                            <p-dropdown [options]="view" appendTo="body" optionLabel="label" optionValue="label"
                                [filter]="true" [(ngModel)]="selectedRow" [attr.selectInfoIndex]=i
                                (onChange)="ChangeVal($event)" placeholder="Select">
                            </p-dropdown>
                        </div>
                    </div>
                    <div class="drpFilterChkbox" style="float:right">
                        <i class="pi pi-filter pi-filter-icon table-filter-icons" (click)="showFilterFields()"
                            pTooltip="{{filterToolTip}} Filter" tooltipPosition="top" id="shepherd-reportTableFilter"></i>
                        <i class="pi pi-filter-slash table-filter-icons" (click)="clearTableFilter()"
                            pTooltip="Reset/Clear Filter" tooltipPosition="top" id="shepherd-reportTableResetFilter"></i>
                        <p-multiSelect class="search-multiselect" defaultLabel="Search" [options]="coldataSelectAll"
                            [(ngModel)]="coldataTemp" optionValue="field" optionLabel="headerName"
                            (onChange)="binSelect($event,'option')" dropdownIcon="bx bx-low-vision" pTooltip="Show/Hide Column"
                            tooltipPosition="top" id="shepherd-reportColHide">
                        </p-multiSelect>
                        <button class="drpButton" (click)="Refresh(coldataSelectAll)" pTooltip="Refresh"
                            tooltipPosition="top" id="shepherd-reportRefresh"><i class="pi pi-refresh"></i></button>
                        <button class="drpButton" (click)="showmenu=!showmenu" (click)="downloadMenu.toggle($event)"
                            pTooltip="Export" tooltipPosition="top" id="shepherd-reportDownload"><i
                                [class]="laodings ? 'pi pi-spinner pi-spin' : 'pi pi-download'"></i></button>
                        <!-- <span class="p-input-icon-right">
                            <i class="pi pi-search"></i>
                            <input type="text" pInputText [(ngModel)]="globalFilter"
                                (ngModelChange)="this.tableSearchUpdate.next($event,'')" placeholder="Search" [type]="'search'"/>
                        </span> -->
                        <p-tieredMenu #downloadMenu [model]="downloadButtonList" [popup]="true" my="left top"
                            appendTo="body" at="left bottom"></p-tieredMenu>
                    </div>
                    <div style="clear: both;"></div>
                    <div class="main-content">
                        <p-card class="table-card" [ngClass]="{'empty-table-card':rowData.length === 0}">
                            <p-table #buildAreportTable [columns]="this.coldata" [value]="this.rowData"
                                [scrollable]="true" [reorderableColumns]="true" [autoLayout]="true"
                                selectionMode="single" [sortField]="sqlTableSortColumn" [sortOrder]="sqlTableSortDir"
                                (sortFunction)="customSort($event)" [customSort]="true"
                                scrollHeight="calc(100vh - {{tableReduceHeight+'px'}})"
                                [tableStyle]="{'min-width':'50rem'}" (onColReorder)="handleColReorder($event)" id="shepherd-reportTable">
                                <ng-template pTemplate="header" let-columns>
                                    <tr>
                                        <ng-container *ngFor="let col of columns; trackBy: trackByFn">
                                            <th title=" {{col.headerName}}" *ngIf="col.checked"
                                                [pSortableColumn]="col.field" pReorderableColumn
                                                [@smoothDragIn]="col.field" style="width: 150px;" [style.width]="dynamicWidth(col.field,col.headerName)">
                                               <i class="pi pi-times" (click)="binSelect(col,'table')" style="font-size: 12px !important;color: #818183"
                                                pTooltip="Remove Column" tooltipPosition="top"></i>
                                                {{col.headerName}}
                                                <p-sortIcon [field]="col.field"></p-sortIcon>
                                                 
                                            </th>
                                        </ng-container>
                                    </tr>
                                    <tr *ngIf="showFilterFieldsBoo " class="input-tr animateH5">
                                        <ng-container *ngFor="let col of columns">
                                            <th class="filter-input-th" *ngIf="col.type === 'text' && col.checked">
                                                <input class="filter-input-table" pInputText type="text"
                                                    id="{{ '1'+col.id }}" [(ngModel)]="col.selectedDataStart"
                                                   
                                                    placeholder="Starts With" />
                                                <input class="filter-input-table" pInputText type="text"
                                                    id="{{ '2'+col.id }}" [(ngModel)]="col.selectedDataLike"
                                                    (ngModelChange)="this.tableSearchUpdate1.next([$event,this.coldata,'like'])"
                                                    placeholder="Contains" />
                                                <input class="filter-input-table" pInputText type="text"
                                                    id="{{ '3'+col.id }}" [(ngModel)]="col.selectedDataEnd"
                                                    (ngModelChange)="this.tableSearchUpdate1.next([$event,this.coldata,'endw'])"
                                                    placeholder="Ends With" />
                                            </th>
                                            <th class="filter-input-th" *ngIf="col.type === 'date'&& col.checked ">
                                                <p-calendar class="filter-input-table" appendTo="body"
                                                    (ngModelChange)="this.tableSearchUpdate1.next([$event,this.coldata,'dtlt'])"
                                                    [showIcon]="true" [(ngModel)]="col.selectedDataStart"
                                                    placeholder="Before" hourFormat="12" 
                                                    [monthNavigator]="true" id="{{ '1'+col.id }}" [yearNavigator]="true"
                                                    yearRange="1000:3000"></p-calendar>
                                                <p-calendar class="filter-input-table" appendTo="body"
                                                    (ngModelChange)="this.tableSearchUpdate1.next([$event,this.coldata,'dtgt'])"
                                                    [showIcon]="true" [(ngModel)]="col.selectedDataLike"
                                                    placeholder="After" hourFormat="12" 
                                                    [monthNavigator]="true" id="{{ '1'+col.id }}" [yearNavigator]="true"
                                                    yearRange="1000:3000"></p-calendar>
                                                <p-calendar class="filter-input-table" appendTo="body"
                                                    (ngModelChange)="this.tableSearchUpdate1.next([$event,this.coldata,'dton'])"
                                                    [(ngModel)]="col.selectedDataEnd" [showIcon]="true" placeholder="On"
                                                    hourFormat="12" id="{{ '1'+col.id }}"
                                                    [monthNavigator]="true" [yearNavigator]="true"
                                                    yearRange="1000:3000"></p-calendar>
                                            </th>
                                            <th class="filter-input-th"
                                                *ngIf="col.type.toLowerCase() === 'number' && col.checked">
                                                <input class="filter-input-table" pInputText type="text"
                                                    id="{{ '1'+col.id }}" [(ngModel)]="col.selectedDataStart"
                                                    (ngModelChange)="this.tableSearchUpdate1.next([$event,this.coldata,'lt'])"
                                                    placeholder="<" />
                                                <input class="filter-input-table" pInputText type="text"
                                                    id="{{ '2'+col.id }}" [(ngModel)]="col.selectedDataLike"
                                                    (ngModelChange)="this.tableSearchUpdate1.next([$event,this.coldata,'gt'])"
                                                    placeholder=">" />
                                                <input class="filter-input-table" pInputText type="text"
                                                    id="{{ '3'+col.id }}" [(ngModel)]="col.selectedDataEnd"
                                                    (ngModelChange)="this.tableSearchUpdate1.next([$event,this.coldata,'eq'])"
                                                    placeholder="=" />
                                            </th>

                                            <!--  <th>
                                            <input class="texts" pInputText type=text
                                                (input)="triggerColumnFilter($event.target.value,col.field)" placeholder="Search"
                                                id="searchInput" />
                                        </th>-->
                                        </ng-container>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-rowData let-columns="columns">
                                    <tr>
                                        <ng-container *ngFor="let col of columns">
                                            <td *ngIf="col.checked" style="width: 150px;"
                                                title="{{rowData[col.field]}}" [style.width]="dynamicWidth(col.field,col.headerName)">
                                                {{rowData[col.field]}}
                                            </td>
                                        </ng-container>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="emptymessage">
                                    <tr>
                                        <td>{{ tableEmptyMessage }}</td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </p-card>
                        <div class="p-d-flex pagination-container" *ngIf="showGrid">
                            <div class="pagination-first-part">
                                <p class="p-mx-2">Showing <b>{{startIndex}}-{{endIndex}}</b> of <b>{{totalRecords}}</b>
                                    Records
                                </p>
                            </div>
                            <div class="pagination-second-part">
                                <p-paginator #paginator styleClass="tibo-schedrepo-listView-p-paginator" [rows]="150"
                                    [totalRecords]="totalRecords" (onPageChange)="paginate($event)"
                                    [showJumpToPageDropdown]="true" [showPageLinks]="false"></p-paginator>
                            </div>
                        </div>
                    </div>
                    <!-- primeng table ends-->
                </div>
            </div>
        </div>
    </div>
</div>

<div class="build-report-dialog-parent-container">
    <!-- save filter modal -->
    <p-dialog [style]="{width:'30vw'}" [baseZIndex]="10000" header="Save Report Parameter" [draggable]="false"
        [(visible)]="showSaveDialog" [modal]="true" [position]="'top'" class="confirm-dialog">
        <input type="text" pInputText [(ngModel)]="fileName" placeholder="Enter Filter Name" required
            class="form-input input-lg">
            <div class="error" *ngIf="showFileNameError && !fileName">Filter Name is mandatory.</div>
            <input type="text" pInputText [(ngModel)]="fileDesc" placeholder="Enter Filter Description" class="form-input input-lg ng-pristine">
        <ng-template pTemplate="footer">
            <p-button type="close" class="cancel-btn" label="Close" value="Update"
                (click)="showSaveDialog = false "></p-button>
            <p-button type="button" class="save-btn" label="Save" (click)="saveFilter()"></p-button>
        </ng-template>
    </p-dialog>
    <!-- delete modal -->
    <p-dialog [modal]="true" [(visible)]="showDeleteDialog" [style]="{ width: '800px' }" [position]="'top'"
        [draggable]="false" class="delete-dialog" [header]="' '">
        <i class='pi pi-exclamation-triangle stop-modal-bx-error'></i> <span class="header-text">Are You Sure You Want
            To Delete
            This Saved Report Parameter?</span>
        <ng-template pTemplate="footer">
            <p-button type="close" class="cancel-btn" label="Close" value="Update"
                (click)="showDeleteDialog = false "></p-button>
            <p-button type="button" class="save-btn" label="Delete" (click)="ondeleteClick()"></p-button>
        </ng-template>
    </p-dialog>
    <p-dialog [modal]="true" [(visible)]="showScheduleBox" [style]="{ width: '800px' }" [position]="'top'"
        [draggable]="false" class="delete-dialog" [header]="' '" (onHide)="close()">
        <span class="header-text">Do You Want To Schedule ?</span>
        <ng-template pTemplate="footer">
            <p-button type="close" class="cancel-btn" label="Cancel" value="Update" (click)="close()"></p-button>
            <p-button type="button" class="save-btn" label="Ok" (click)="GotoScheduler()"></p-button>
        </ng-template>
    </p-dialog>
</div>